import React from 'react';
import {
  Router as ReactRouter,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import globalStationOptionsUtils from '../../utils/globalStationOptionsUtils';
import PrivateRoute from '../PrivateRoute';
import LogIn from '../logIn/LogIn';
import ResetPassword from '../resetPassword/ResetPassword';
import SetPassword from '../setPassword/SetPassword';
import Dashboard from '../dashboard/Dashboard';
import Devices from '../devices/Devices';
import DeviceDetails from '../devices/DeviceDetails';
import Containers from '../containers/Containers';
import Packages from '../packages/Packages';
import Manifests from '../manifests/Manifests';
import ManifestsDetails from '../manifests/ManifestsDetails';
import DockDoors from '../dockDoors/DockDoors';
import DockDoorsDetails from '../dockDoors/DockDoorsDetails';
import Stops from '../stops/Stops';
import SchemeDetails from '../schemes/SchemeDetails';
import Loadplans from '../loadplans/Loadplans';
import LoadplansList from '../loadplans/LoadplansList';
import Routes from '../routes/Routes';
import RoutesDetails from '../routes/RoutesDetails';
import StationStaff from '../stationStaff/StationStaff';
import StationStaffDetails from '../stationStaff/StationStaffDetails';
import StationStaffPermissions from '../stationStaff/StationStaffPermissions';
import Areas from '../areas/Areas';
import AreasDetails from '../areas/AreaDetails';
import AreaSettings from '../areas/settings/AreaSettings';
import AddCorporateModule from '../areas/settings/AddCorporateModule';
import AddStationModule from '../areas/settings/AddStationModule';
import ContainerDetails from '../containers/ContainerDetails';
import PageNotFound from '../pageNotFound/PageNotFound';
import TrackingPage from '../trackingPage/TrackingPage';
import LoadingGlobalData from '../loadingGlobalData/LoadingGlobalData';
import RedirectToDashboard from '../pageNotFound/RedirectToDashboard';
import RefreshComponent from '../refreshComponent/RefreshComponent';
import browserHistory from '../../utils/browserHistory';
import {
  AuthRoutes,
  NonAuthRoutes,
  ModulesKeys,
  isModuleActive,
  resetDynamicRoutes,
  stationDashboardUrlId,
  TrackableContainersRoutes,
} from '../../interfaces/routes';
import PackageDetails from '../packages/PackageDetails';
import OutboundLoads from '../outboundLoads/OutboundLoads';
import OutboundLoadDetails from '../outboundLoads/OutboundLoadDetails';
import StationLayout from '../stationLayout/StationLayout';
import Profile from '../profile/Profile';
import ChangeEmail from '../changeEmail/ChangeEmail';

// Services
import Auth from '../../services/Auth.service';

//Flow -- test
import Flow from '../stationLayout/throughput/Flow';

//Station Planner
import StationPlanner from '../stationPlanner/StationPlanner';
import ShiftPresets from '../stationPlanner/shiftPresets/ShiftPresets';
import ShiftPresetDetails from '../stationPlanner/shiftPresets/ShiftPresetDetails';
import ShiftCalendar from '../stationPlanner/ShiftCalendar';
import Schemes from '../schemes/Schemes';

import InboundLoads from '../inboundLoads/InboundLoads';
import InboundLoadDetails from '../inboundLoads/InboundLoadDetails';
import Webhooks from '../webhooks/Webhooks';
import WebhookDetails from '../webhooks/WebhookDetails';
import Customers from '../configuration/Customers/Customers';
import Settings from '../configuration/settings/Settings';
import Modules from '../configuration/modules/Modules';
import Roles from '../configuration/roles/Roles';
import CustomerDetails from '../configuration/Customers/CustomerDetails';
import Staff from '../configuration/Staff/Staff';
import { store } from '../../redux/store';
import { setSholdUpdateDynamicRoutes } from '../../redux/slices/navigationSlice';
import { Unsubscribe } from 'redux';
import ConfigurationDetails from '../configuration/Customers/ConfigurationDetails';
import CustomApps from '../configuration/CustomApps/CustomApps';
import TrackableContainersMap from '../trackableContainers/TrackableContainersMap';
import TrackableContainersDetails from '../trackableContainers/TrackableContainersDetails';
import LocalStorageService from '../../services/LocalStorage.service';
import TrackableContainersList from '../trackableContainers/TrackableContainersList';
import HeldPackages from '../heldPackages/HeldPackages';
import TrackableContainerDetailsPrivateRoute from '../trackableContainers/TrackableContainerDetailsPrivateRoute';

interface State {
  globalStationData: any;
}
class Router extends React.Component<{}, State> {
  unsubscribe: Unsubscribe;
  constructor(props: any) {
    super(props);
    this.state = { globalStationData: undefined };
    this.unsubscribe = store.subscribe(this.onStoreChange);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  onStoreChange = () => {
    const shouldUpdateRoutes =
      store.getState().navigation.shouldUpdateDynamicRoutes;
    if (shouldUpdateRoutes) {
      resetDynamicRoutes();
      this.forceUpdate();
      store.dispatch(setSholdUpdateDynamicRoutes(false));
    }
  };

  private setStationData = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const stationId = urlParams.get(stationDashboardUrlId);
      const globalData =
        await globalStationOptionsUtils.setStationData(
          stationId ? parseInt(stationId) : undefined,
        );

      resetDynamicRoutes();
      this.setState({ globalStationData: globalData });
    } catch (e) {
      console.error(e);
    }
  };

  private getPrivateRoutes = () => {
    return [
      {
        key: ModulesKeys.DASHBOARD,
        path: AuthRoutes.DASHBOARD,
        component: Dashboard,
      },
      {
        key: null,
        path: AuthRoutes.FLOW,
        component: Flow,
      },
      {
        key: ModulesKeys.DEVICE,
        path: AuthRoutes.DEVICE,
        component: Devices,
      },
      {
        key: ModulesKeys.DEVICE,
        path: `${AuthRoutes.DEVICE}/:id`,
        component: DeviceDetails,
      },
      {
        key: ModulesKeys.CONTAINER,
        path: AuthRoutes.CONTAINER,
        component: Containers,
      },
      {
        key: ModulesKeys.CONTAINER,
        path: `${AuthRoutes.CONTAINER}/:id`,
        component: ContainerDetails,
      },
      {
        key: ModulesKeys.PACKAGE,
        path: AuthRoutes.PACKAGE,
        component: Packages,
      },
      {
        key: ModulesKeys.PACKAGE,
        path: `${AuthRoutes.PACKAGE}/:id`,
        component: PackageDetails,
      },
      {
        key: ModulesKeys.HELD_PACKAGE,
        path: AuthRoutes.HELD_PACKAGE,
        component: HeldPackages,
      },
      {
        key: ModulesKeys.INBOUND_LOAD,
        path: AuthRoutes.INBOUND_LOAD,
        component: InboundLoads,
      },
      {
        key: ModulesKeys.INBOUND_LOAD,
        path: `${AuthRoutes.INBOUND_LOAD}/:id`,
        component: InboundLoadDetails,
      },
      {
        key: ModulesKeys.OUTBOUND_LOAD,
        path: AuthRoutes.OUTBOUND_LOAD,
        component: OutboundLoads,
      },
      {
        key: ModulesKeys.OUTBOUND_LOAD,
        path: `${AuthRoutes.OUTBOUND_LOAD}/:id`,
        component: OutboundLoadDetails,
      },
      {
        key: ModulesKeys.ROUTE,
        path: AuthRoutes.ROUTE,
        component: Routes,
      },
      {
        key: ModulesKeys.ROUTE,
        path: `${AuthRoutes.ROUTE}/:id`,
        component: RoutesDetails,
      },
      {
        key: ModulesKeys.STAFF,
        path: AuthRoutes.STAFF,
        component: StationStaff,
      },
      {
        key: ModulesKeys.STAFF,
        path: `${AuthRoutes.STAFF}/:id`,
        component: StationStaffDetails,
      },
      {
        key: ModulesKeys.STAFF,
        path: `${AuthRoutes.STAFF}/:permissions/:id`,
        component: StationStaffPermissions,
      },
      {
        key: ModulesKeys.AREA,
        path: AuthRoutes.AREA,
        component: Areas,
      },
      {
        key: ModulesKeys.AREA,
        path: `${AuthRoutes.AREA}/:id`,
        component: AreasDetails,
      },
      {
        key: ModulesKeys.AREA,
        path: `${AuthRoutes.AREA}/:id/settings/:areaType`,
        component: AreaSettings,
      },
      {
        key: ModulesKeys.AREA,
        path: `${AuthRoutes.AREA}/:id/settings/:areaType/add-corporate-module/:zoneId`,
        component: AddCorporateModule,
      },
      {
        key: ModulesKeys.AREA,
        path: `${AuthRoutes.AREA}/:id/settings/:areaType/add-station-module/:zoneId`,
        component: AddStationModule,
      },
      {
        key: ModulesKeys.STATION_LAYOUT,
        path: AuthRoutes.STATION_LAYOUT,
        component: StationLayout,
      },
      {
        key: ModulesKeys.STATION_PLANNER,
        path: AuthRoutes.STATION_PLANNER,
        component: StationPlanner,
      },
      {
        key: null,
        path: AuthRoutes.SHIFT_PRESETS,
        component: ShiftPresets,
      },
      {
        key: null,
        path: `${AuthRoutes.SHIFT_PRESETS}/:id`,
        component: ShiftPresetDetails,
      },
      {
        key: null,
        path: AuthRoutes.SHIFT_CALENDAR,
        component: ShiftCalendar,
      },
      {
        key: ModulesKeys.MANIFEST,
        path: AuthRoutes.MANIFEST,
        component: Manifests,
      },
      {
        key: ModulesKeys.MANIFEST,
        path: `${AuthRoutes.MANIFEST}/:id`,
        component: ManifestsDetails,
      },
      {
        key: ModulesKeys.DOCK_DOOR,
        path: AuthRoutes.DOCK_DOOR,
        component: DockDoors,
      },
      {
        key: ModulesKeys.DOCK_DOOR,
        path: `${AuthRoutes.DOCK_DOOR}/:id`,
        component: DockDoorsDetails,
      },
      {
        key: ModulesKeys.STOP,
        path: AuthRoutes.STOP,
        component: Stops,
      },
      {
        key: null,
        path: AuthRoutes.SCHEME,
        component: Schemes,
      },
      {
        key: null,
        path: `${AuthRoutes.SCHEME}/:id`,
        component: SchemeDetails,
      },
      {
        key: ModulesKeys.STOP,
        path: `${AuthRoutes.STOP}/default-open/:defaultOpenIds`,
        component: Stops,
      },
      {
        key: ModulesKeys.LOADPLAN,
        path: AuthRoutes.LOADPLAN,
        component: Loadplans,
      },
      {
        key: ModulesKeys.LOADPLAN,
        path: `${AuthRoutes.LOADPLAN}/:id`,
        component: Loadplans,
      },
      {
        key: ModulesKeys.LOADPLAN,
        path: `${AuthRoutes.LOADPLAN}/:id/:imported`,
        component: Loadplans,
      },
      {
        key: ModulesKeys.LOADPLAN,
        path: `${AuthRoutes.LOADPLAN}_list`,
        component: LoadplansList,
      },
      {
        key: null,
        path: [
          `${AuthRoutes.CONFIGURATION}/customers`,
          `${AuthRoutes.CONFIGURATION}/organization/:orgId/customers`,
          `${AuthRoutes.CONFIGURATION}/organization/:orgId/station/:stationId/customers`,
        ],
        component: Customers,
      },
      {
        key: null,
        path: [
          `${AuthRoutes.CONFIGURATION}/customers/:customerId`,
          `${AuthRoutes.CONFIGURATION}/organization/:orgId/customers/:customerId`,
          `${AuthRoutes.CONFIGURATION}/organization/:orgId/station/:stationId/customers/:customerId`,
        ],
        component: CustomerDetails,
      },
      {
        key: null,
        path: [
          `${AuthRoutes.CONFIGURATION}/customers/:customerId/newConfiguration`,
          `${AuthRoutes.CONFIGURATION}/organization/:orgId/customers/:customerId/newConfiguration`,
          `${AuthRoutes.CONFIGURATION}/organization/:orgId/station/:stationId/customers/:customerId/newConfiguration`,
        ],
        component: ConfigurationDetails,
      },
      {
        key: null,
        path: [
          `${AuthRoutes.CONFIGURATION}/customers/:customerId/:configurationId`,
          `${AuthRoutes.CONFIGURATION}/organization/:orgId/customers/:customerId/:configurationId`,
          `${AuthRoutes.CONFIGURATION}/organization/:orgId/station/:stationId/customers/:customerId/:configurationId`,
        ],
        component: ConfigurationDetails,
      },
      {
        key: null,
        path: [
          `${AuthRoutes.CONFIGURATION}/staff`,
          `${AuthRoutes.CONFIGURATION}/organization/:orgId/staff`,
          `${AuthRoutes.CONFIGURATION}/organization/:orgId/station/:stationId/staff`,
        ],
        component: Staff,
      },
      {
        key: null,
        path: [
          `${AuthRoutes.CONFIGURATION}/staff/:userId`,
          `${AuthRoutes.CONFIGURATION}/organization/:orgId/staff/:userId`,
          `${AuthRoutes.CONFIGURATION}/organization/:orgId/station/:stationId/staff/:userId`,
        ],
        component: Profile,
      },
      {
        key: null,
        path: [
          `${AuthRoutes.CONFIGURATION}/customers/:customerId`,
          `${AuthRoutes.CONFIGURATION}/organization/:orgId/customers/:customerId`,
          `${AuthRoutes.CONFIGURATION}/organization/:orgId/station/:stationId/customers/:customerId`,
        ],
        component: Customers,
      },
      {
        key: null,
        path: [
          `${AuthRoutes.CONFIGURATION}/modules`,
          `${AuthRoutes.CONFIGURATION}/organization/:orgId/modules`,
          `${AuthRoutes.CONFIGURATION}/organization/:orgId/station/:stationId/modules`,
        ],
        component: Modules,
      },
      {
        key: null,
        path: [
          `${AuthRoutes.CONFIGURATION}/roles`,
          `${AuthRoutes.CONFIGURATION}/organization/:orgId/roles`,
          `${AuthRoutes.CONFIGURATION}/organization/:orgId/station/:stationId/roles`,
        ],
        component: Roles,
      },
      {
        key: null,
        path: [
          `${AuthRoutes.CONFIGURATION}/customApps`,
          `${AuthRoutes.CONFIGURATION}/organization/:orgId/customApps`,
          `${AuthRoutes.CONFIGURATION}/organization/:orgId/station/:stationId/customApps`,
        ],
        component: CustomApps,
      },
      {
        key: null,
        path: [
          AuthRoutes.CONFIGURATION,
          `${AuthRoutes.CONFIGURATION}/organization/:orgId`,
          `${AuthRoutes.CONFIGURATION}/organization/:orgId/:created`,
          `${AuthRoutes.CONFIGURATION}/organization/:orgId/station/:stationId`,
          `${AuthRoutes.CONFIGURATION}/organization/:orgId/station/:stationId/:created`,
        ],
        component: Settings,
      },
      {
        key: null,
        path: AuthRoutes.PROFILE,
        component: Profile,
      },
      {
        key: null,
        path: AuthRoutes.WEBHOOKS,
        component: Webhooks,
      },
      {
        key: null,
        path: `${AuthRoutes.WEBHOOKS}/:webhookId`,
        component: WebhookDetails,
      },
    ];
  };

  render() {
    const privateRoutes = this.getPrivateRoutes();

    const isNotNonAuthPath = Object.values(NonAuthRoutes)
      .map((route) => route.split('/')[1])
      .filter((route) => route)
      .every(
        (path) => !browserHistory.location.pathname.includes(path),
      );

    const isNotTrackableContainersPath = Object.values(
      TrackableContainersRoutes,
    )
      .map((route) => route.split('/')[1])
      .filter((route) => route)
      .every(
        (path) => !browserHistory.location.pathname.includes(path),
      );

    if (
      isNotNonAuthPath &&
      isNotTrackableContainersPath &&
      (Auth.isLoggedIn() || Auth.hasRefreshToken()) &&
      this.state.globalStationData === undefined
    ) {
      this.setStationData();
    }

    const isCustomer = LocalStorageService.getIsCustomer();

    if (
      isNotNonAuthPath &&
      isNotTrackableContainersPath &&
      (Auth.isLoggedIn() || Auth.hasRefreshToken()) &&
      !this.state.globalStationData &&
      !privateRoutes[0].path &&
      !isCustomer
    ) {
      return this.state.globalStationData === null ? (
        <PageNotFound />
      ) : (
        <LoadingGlobalData />
      );
    }

    return (
      <ReactRouter history={browserHistory}>
        <Switch>
          <Redirect
            exact
            path={NonAuthRoutes.HOME}
            to={{
              pathname: NonAuthRoutes.LOGIN,
              state: { from: NonAuthRoutes.HOME },
            }}
          />
          {privateRoutes
            .filter((route) => {
              if (route.key) {
                return isModuleActive(route.key);
              }
              return true;
            })
            .map((route, index) => {
              return (
                <PrivateRoute
                  exact
                  key={`${route.key}-${index}`}
                  path={route.path}
                  component={route.component}
                />
              );
            })}
          <PrivateRoute
            exact
            path={TrackableContainersRoutes.MAP}
            component={TrackableContainersMap}
          />
          <PrivateRoute
            exact
            path={TrackableContainersRoutes.LIST}
            component={TrackableContainersList}
          />
          <TrackableContainerDetailsPrivateRoute
            path={TrackableContainersRoutes.DETAILS}
            component={TrackableContainersDetails}
          />
          <Route exact path={NonAuthRoutes.LOGIN} component={LogIn} />
          <Route
            exact
            path={NonAuthRoutes.LOGIN_ALERT}
            component={LogIn}
          />

          <Route
            path={NonAuthRoutes.RESET_PASSWORD}
            component={ResetPassword}
          />
          <Route
            path={NonAuthRoutes.SET_PASSWORD}
            component={SetPassword}
          />
          <Route
            path={NonAuthRoutes.CHANGE_EMAIL}
            component={ChangeEmail}
          />
          <Route
            path={NonAuthRoutes.TRACKING}
            component={TrackingPage}
          />
          <Route
            path={NonAuthRoutes.REFRESH_ROUTE}
            component={RefreshComponent}
          />
          <Route path='*' component={RedirectToDashboard} />
        </Switch>
      </ReactRouter>
    );
  }
}

export default Router;
