import React, { useState, useEffect } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { CSButton, AlertBanner, CSTextField } from '../../primitives';
import { Grid, Fade, Paper, Typography } from '@material-ui/core';
import detailsPageStyles from '../../commonStyles/detailsPage.style';
import PaginatedTable, {
  filterObj,
} from '../../paginatedTable/PaginatedTable';
import { useParams } from 'react-router-dom';
import globalStationOptionsUtils from '../../../utils/globalStationOptionsUtils';
import browserHistory from '../../../utils/browserHistory';
import { AuthRoutes } from '../../../interfaces/routes';
import Layout from '../../layout/Layout';
import {
  getOrganizationStationData,
  getInteraction,
  getMode,
  Mode,
  INTERACTION,
  patchOrganizationStation,
  configurationUrlParams,
} from '../utils';
import Navbar, { TabsEnum } from '../Navbar';
import ProgressIndicator from '../../progressIndicator/ProgressIndicator';
import ErrorHandler from '../../../utils/ErrorHandler';
import { AxiosError } from 'axios';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import AppsIcon from '@material-ui/icons/Apps';
import SettingsIcon from '@material-ui/icons/Settings';
import OrganizationsService from '../../../services/Organizations.service';
import StationsService from '../../../services/Stations.service';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { useAppDispatch } from '../../../redux/store';
import {
  setSelectedEntity,
  setShouldUpdateStationsData,
} from '../../../redux/slices/navigationSlice';
import CSSectionTitleSeparator from '../../primitives/csSectionTitleSeparator/CSSectionTitleSeparator';
import { SELECTOR_ENTITY_TYPE } from '../../navigation/types';
import EphemeralStateService from '../../../services/EphemeralState.service';
import { CSSingleDetailMonoColumnContainer } from '../../primitives/singleDetail/singleDetailMonoColumnContainer';
import CSDialogAlert from '../../primitives/csDialogAlert/CSDialogAlert';
import clsx from 'clsx';
import { CSCountrySelect } from '../../primitives/csCountrySelect';
import HoldingReasons from './holdingReasons/HoldingReasons';

interface Props {
  classes: { [key: string]: string };
}

const Settings: React.FC<Props> = ({ classes }) => {
  //Data & Behavior
  const [data, setData] = useState<any>();
  const [initialData, setInitialData] = useState<any>();
  const [error, setError] = useState<string>();
  const [showProgress, setShowProgress] = useState<boolean>(false);

  //Misc
  const [showCreatedBanner, setShowCreatedBanner] =
    useState<boolean>(false);
  const [lastUpdate, setLastUpdate] = useState<number>(0);
  const urlParams = useParams<configurationUrlParams>();
  const [initialMode, setInitialMode] = useState<Mode>();
  const mode = getMode(urlParams.orgId, urlParams.stationId);
  const interaction = getInteraction(mode);
  const dispatch = useAppDispatch();

  const handleError = async (err: AxiosError) => {
    setError(await ErrorHandler.getLabel(err));
  };

  const createOrg = async () => {
    try {
      setShowProgress(true);
      const res = await OrganizationsService.create({
        name: 'New Organization',
      });
      browserHistory.push(
        `/configuration/organization/${res.data.id}/true`,
      );
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  const createStation = async () => {
    try {
      setShowProgress(true);
      const res = await StationsService.create({
        name: 'New Station',
        organization: Number(urlParams.orgId),
      });
      browserHistory.push(
        `/configuration/organization/${urlParams.orgId}/station/${res.data.id}/true`,
      );
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  const getItemTypeLabel = (capitalize?: boolean) => {
    if (mode === Mode.ORGANIZATION) {
      return capitalize ? 'Organization' : 'organization';
    } else if (mode === Mode.STATION) {
      return capitalize ? 'Station' : 'station';
    }
    return '';
  };

  const getAndSetData = async () => {
    try {
      setShowProgress(true);
      const myData = await getOrganizationStationData(
        mode,
        Number(urlParams.orgId),
        Number(urlParams.stationId),
      );
      setError(undefined);
      setData(myData);
      setInitialData(cloneDeep(myData));
      setInitialMode(mode);
      setLastUpdate(lastUpdate + 1);
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  useEffect(() => {
    getAndSetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAndSetData();
    //Navigation between entities, reset the showCreatedBanner state.
    setShowCreatedBanner(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParams.orgId, urlParams.stationId]);

  useEffect(() => {
    if (urlParams.created) {
      setShowCreatedBanner(true);
      dispatch(
        setSelectedEntity({
          id: urlParams.stationId
            ? Number(urlParams.stationId)
            : Number(urlParams.orgId),
          type:
            mode === Mode.ORGANIZATION
              ? SELECTOR_ENTITY_TYPE.ORGANIZATION
              : SELECTOR_ENTITY_TYPE.STATION,
        }),
      );

      if (mode === Mode.STATION) {
        EphemeralStateService.setMyStationId(
          Number(urlParams.stationId),
        );
      }
      dispatch(setShouldUpdateStationsData(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParams]);

  const fetchOrgs = async (
    pageIndex: number,
    rowsPerPage: number,
    filterOptions?: filterObj[],
    filterByString?: string,
    sortedBy?: string,
  ) => {
    const res = await OrganizationsService.getAll(
      pageIndex,
      rowsPerPage,
    );
    return res;
  };

  const fetchStations = async (
    pageIndex: number,
    rowsPerPage: number,
    filterOptions?: filterObj[],
    filterByString?: string,
    sortedBy?: string,
  ) => {
    const res = await StationsService.getStationByOrg(
      Number(urlParams.orgId),
      pageIndex,
      rowsPerPage,
    );
    return res;
  };

  const renderOrganizationSettings = () => {
    return (
      <>
        <Grid item xs={12} sm={6} className={classes.displayGrid}>
          <Paper
            className={clsx(classes.paper, classes.paperPadding)}
          >
            <CSSingleDetailMonoColumnContainer
              header={`Organization Info`}
              elements={[
                <CSTextField
                  containerSize='fullHorizontal'
                  label='Organization Name'
                  data-testid={'station-name-input'}
                  value={data?.name || ''}
                  className={classes.marginTop}
                  onFocus={(event) => {
                    handleNameBlurFocus(event, 'Organization');
                  }}
                  onBlur={(event) => {
                    handleNameBlurFocus(event, 'Organization');
                  }}
                  disabled={interaction === INTERACTION.READ}
                  onChange={(e) => {
                    setData({
                      ...data,
                      name: e.target.value,
                    });
                  }}
                />,
              ]}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.displayGrid}>
          <Paper
            className={clsx(classes.paper, classes.paperPadding)}
          >
            <CSSingleDetailMonoColumnContainer
              header={`Admin Info`}
              elements={[
                <CSTextField
                  containerSize='fullHorizontal'
                  label='Admin'
                  data-testid={'admin-input'}
                  className={classes.marginTop}
                />,
                <CSTextField
                  containerSize='fullHorizontal'
                  label='Admin Email'
                  data-testid={'admin-email-input'}
                  className={classes.marginTop}
                />,
              ]}
            />
          </Paper>
        </Grid>
      </>
    );
  };

  const renderStationSettings = () => {
    return (
      <>
        <Grid item xs={12} sm={6} className={classes.displayGrid}>
          <Paper
            className={clsx(classes.paper, classes.paperPadding)}
          >
            <CSSingleDetailMonoColumnContainer
              header={`Station Info`}
              elements={[
                <CSTextField
                  containerSize='fullHorizontal'
                  label='Station Name'
                  data-testid={'station-name-input'}
                  value={data?.name || ''}
                  className={classes.marginTop}
                  onFocus={(event) => {
                    handleNameBlurFocus(event, 'Station');
                  }}
                  onBlur={(event) => {
                    handleNameBlurFocus(event, 'Station');
                  }}
                  disabled={interaction === INTERACTION.READ}
                  onChange={(e) => {
                    setData({
                      ...data,
                      name: e.target.value,
                    });
                  }}
                />,
                <CSTextField
                  containerSize='fullHorizontal'
                  label='Admin'
                  data-testid={'admin-input'}
                  className={classes.marginTop}
                />,
                <CSTextField
                  containerSize='fullHorizontal'
                  label='Admin Email'
                  data-testid={'admin-email-input'}
                  className={classes.marginTop}
                />,
              ]}
            />
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Paper
            className={clsx(classes.paper, classes.paperPadding)}
          >
            <CSSingleDetailMonoColumnContainer
              header='Station Location'
              elements={[
                <CSCountrySelect
                  label='Country'
                  className={classes.marginTop}
                  data-testid={'station-country-input'}
                  value={data?.country || ''}
                  isDisabled={interaction === INTERACTION.READ}
                  setValue={(value) => {
                    setData({
                      ...data,
                      country: value,
                    });
                  }}
                />,
                <CSTextField
                  containerSize='fullHorizontal'
                  label='City'
                  className={classes.marginTop}
                  data-testid={'station-city-input'}
                  value={data?.city || ''}
                  disabled={interaction === INTERACTION.READ}
                  onChange={(e) => {
                    setData({
                      ...data,
                      city: e.target.value,
                    });
                  }}
                />,
                <CSTextField
                  containerSize='fullHorizontal'
                  label='State'
                  className={classes.marginTop}
                  data-testid={'station-state-input'}
                  value={(data?.state || '').toUpperCase()}
                  disabled={interaction === INTERACTION.READ}
                  onChange={(e) => {
                    setData({
                      ...data,
                      state: e.target.value
                        .replace(/[^a-zA-Z]/, '')
                        .toUpperCase(),
                    });
                  }}
                />,
                <CSTextField
                  containerSize='fullHorizontal'
                  label='Address'
                  className={classes.marginTop}
                  data-testid={'station-address-input'}
                  value={data?.address || ''}
                  disabled={interaction === INTERACTION.READ}
                  onChange={(e) => {
                    setData({
                      ...data,
                      address: e.target.value,
                    });
                  }}
                />,
                <CSTextField
                  containerSize='fullHorizontal'
                  label='ZIP Code'
                  className={classes.marginTop}
                  data-testid={'station-zipcode-input'}
                  value={data?.zipcode || ''}
                  disabled={interaction === INTERACTION.READ}
                  onChange={(e) => {
                    setData({
                      ...data,
                      zipcode: e.target.value,
                    });
                  }}
                />,
                <CSTextField
                  containerSize='fullHorizontal'
                  label='GPS coordinates'
                  className={classes.marginTop}
                  data-testid={'station-geolocation-input'}
                  value={
                    data?.geo_location &&
                    data?.geo_location[0] &&
                    data?.geo_location[1]
                      ? `${data.geo_location[0]}, ${data.geo_location[1]}`
                      : ''
                  }
                  disabled={true}
                />,
              ]}
            />
          </Paper>
        </Grid>
      </>
    );
  };

  const handleNameBlurFocus = (
    event: React.FocusEvent,
    entity: 'Station' | 'Organization',
  ) => {
    let newNameValue: string | null = null;

    if (event.type === 'focus' && data?.name === `New ${entity}`) {
      newNameValue = '';
    }

    if (event.type === 'blur' && data?.name === '') {
      newNameValue = `New ${entity}`;
    }

    if (newNameValue !== null) {
      setData({
        ...data,
        name: newNameValue,
      });
    }
  };

  const handleGoToDashboard = async (id: number) => {
    if (mode === Mode.ORGANIZATION) {
      await globalStationOptionsUtils.setStationData(id);
      dispatch(
        setSelectedEntity({ id, type: SELECTOR_ENTITY_TYPE.STATION }),
      );
      EphemeralStateService.setMyStationId(id);
      setTimeout(() => {
        browserHistory.push(AuthRoutes.DASHBOARD);
      }, 0);
    } else if (mode === Mode.CLOUDSORT) {
      browserHistory.push(
        `${AuthRoutes.CONFIGURATION}/organization/${id}`,
      );
      dispatch(
        setSelectedEntity({
          id,
          type: SELECTOR_ENTITY_TYPE.ORGANIZATION,
        }),
      );
    }
  };

  const compareIdsAfterNavigation = () => {
    /*
    The hasUnsavedChanges flag depends on the initial data and the current data. However, when navigating between entitites
    these 2 can become out of sync and cause the navigation blocker modal to show up twice. To prevent this from happening we
    do additional check to see if the user navigated between entitties of the same type (org -> org, station -> station) or
    between station & org.
    */
    if (mode === initialMode) {
      return mode === Mode.ORGANIZATION
        ? Number(data.id) === Number(urlParams.orgId)
        : Number(data.id) === Number(urlParams.stationId);
    }
    return false;
  };
  const hasUnsavedChanges =
    !isEqual(initialData, data) && compareIdsAfterNavigation();

  return (
    <Layout navCurrent='CONFIGURATION'>
      {showProgress && <ProgressIndicator />}
      <Navbar
        hasUnsavedChanges={hasUnsavedChanges}
        activeTab={TabsEnum.SETTINGS}
        onSaveCallback={async () => {
          try {
            setShowProgress(true);
            setError(undefined);
            await patchOrganizationStation(mode, data);
            await globalStationOptionsUtils.setStationData(
              Number(urlParams.stationId),
            );
            setInitialData(data);
            dispatch(setShouldUpdateStationsData(true));
          } catch (e) {
            handleError(e as AxiosError);
          } finally {
            setShowProgress(false);
          }
        }}
        onResetDataCallback={() => {
          setData(cloneDeep(initialData));
          setLastUpdate(lastUpdate + 1);
        }}
      />
      {error && (
        <AlertBanner
          className={classes.banner}
          severity='error'
          alertTitle={'Error'}
          alertMsg={error}
        />
      )}
      {Boolean(lastUpdate) && (
        <Fade in={!showProgress}>
          <div>
            <Grid direction='row' xs={12} container item>
              {showCreatedBanner && (
                <CSDialogAlert
                  bottomMargin={20}
                  classes={{ root: classes.entityCreatedBanner }}
                  alertMessage={`New ${getItemTypeLabel(
                    true,
                  )} Created. Please fill the information to start.`}
                />
              )}
            </Grid>
            <Grid
              spacing={4}
              container
              item
              data-testid={'settings-csorg-org-info'}
            >
              {mode === Mode.ORGANIZATION &&
                renderOrganizationSettings()}
              {mode === Mode.STATION && renderStationSettings()}
            </Grid>
            {(mode === Mode.ORGANIZATION ||
              mode === Mode.CLOUDSORT) && (
              <Grid
                spacing={2}
                container
                data-testid={'settings-module'}
                className={classes.marginTop20}
              >
                <Grid item xs={12} sm={6}>
                  <Typography variant='h3' component='h2'>
                    {mode === Mode.CLOUDSORT
                      ? 'Organizations'
                      : 'Stations'}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  className={classes.alignRight}
                >
                  <CSButton
                    data-testid='add-new-btn'
                    id='add-new-button'
                    fullWidth={false}
                    variant='contained'
                    color={{
                      buttonColor: 'secondary',
                      iconColor: 'primary',
                    }}
                    onClick={() => {
                      if (mode === Mode.CLOUDSORT) {
                        createOrg();
                      } else if (mode === Mode.ORGANIZATION) {
                        createStation();
                      }
                    }}
                    startIcon={<AddBoxOutlinedIcon />}
                  >
                    Add{' '}
                    {mode === Mode.CLOUDSORT
                      ? 'Organization'
                      : 'Station'}
                  </CSButton>
                </Grid>
                <Grid item xs={12}>
                  <CSSectionTitleSeparator
                    topMargin={0}
                    bottomMargin={0}
                  />
                </Grid>
                <Grid item xs={12}>
                  <PaginatedTable
                    key={mode + '_' + data?.id || ''}
                    title=''
                    columns={
                      mode === Mode.CLOUDSORT
                        ? [
                            {
                              id: 'name',
                              label: 'Name',
                              width: 'auto',
                              align: 'left',
                            },
                            {
                              id: 'total_stations',
                              label: 'Stations',
                              width: 'auto',
                              align: 'left',
                            },
                            {
                              id: 'admin',
                              label: 'Admin',
                              width: 'auto',
                              align: 'left',
                            },
                          ]
                        : [
                            {
                              id: 'name',
                              label: 'Name',
                              width: 'auto',
                              align: 'left',
                            },
                            {
                              id: 'total_areas',
                              label: 'Areas',
                              width: 'auto',
                              align: 'left',
                            },
                            {
                              id: 'admin',
                              label: 'Admin',
                              width: 'auto',
                              align: 'left',
                            },
                          ]
                    }
                    dataTestIdPrefix={'config-details-table'}
                    fetch={
                      mode === Mode.CLOUDSORT
                        ? fetchOrgs
                        : fetchStations
                    }
                    actions={[
                      {
                        tableLabel: ' ',
                        columnLabel:
                          mode === Mode.CLOUDSORT ? (
                            <SettingsIcon
                              className={classes.tableInlineButton}
                            />
                          ) : (
                            <AppsIcon
                              className={classes.tableInlineButton}
                            />
                          ),
                        callbackProperty: 'id',
                        qualifier: 'name',
                        callback: handleGoToDashboard,
                        columnColor: 'secondary',
                        columnVariant: 'outlined',
                      },
                    ]}
                  />
                </Grid>
              </Grid>
            )}
            <HoldingReasons
              data={data['config']}
              key={'holding-reasons-' + lastUpdate}
              setData={(newData) => {
                setData({ ...data, config: newData });
                setLastUpdate(lastUpdate + 1);
              }}
            />
          </div>
        </Fade>
      )}
    </Layout>
  );
};

export default withStyles(
  createStyles(() => ({
    ...detailsPageStyles,
    paperPadding: { marginRight: 18, padding: 16 },
    entityCreatedBanner: {
      width: '100%',
    },
    alignRight: { textAlign: 'right' },
    displayGrid: {
      display: 'grid',
    },
    marginTop: {
      marginTop: 10,
    },
    marginTop20: {
      marginTop: 20,
    },
    tableInlineButton: {
      width: 16,
      height: 'auto',
      margin: '5px 10px',
    },
  })),
)(Settings);
