import { ThemeOptions } from '@material-ui/core';
import { useState } from 'react';
import token from '../../theming/tokens.json';
import goldToken from '../../theming/gold_token.json';
import {
  AvailableModifiers,
  useKeyPress,
} from '../../utils/useKeyPress';

export interface AvailableThemes {
  green: ThemeOptions;
  gold: ThemeOptions;
}
export const useThemeSwitcher = (
  keys: string[] = ['t'],
  modifier: AvailableModifiers = 'ctrlKey',
) => {
  // We are forcing the token to unknown type because the typescript definition for shadows is not an interface
  // shadows is a type and types cannot be extended, however new shadows are still available because shadows
  // is an array
  const availableThemes = {
    green: token,
    gold: goldToken,
  } as unknown as AvailableThemes;

  const [currentThemeName, setCurrentTheme] =
    useState<keyof AvailableThemes>('green');
  const onKeyPress = (event: KeyboardEvent) => {
    currentThemeName === 'green'
      ? setCurrentTheme('gold')
      : setCurrentTheme('green');
  };
  useKeyPress(onKeyPress, keys, modifier);
  return availableThemes[currentThemeName];
};
