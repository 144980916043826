import {
  ManifestsApi,
  ManifestSerializerDetails,
} from 'cloudsort-client';
import axios from '../utils/axios';
import dateUtils from './utils/date';
import EphemeralStateService from './EphemeralState.service';

class Manifests {
  private api: ManifestsApi;

  constructor() {
    this.api = new ManifestsApi(undefined, undefined, axios as any);

    this.getAll = this.getAll.bind(this);
    this.getById = this.getById.bind(this);
  }

  public async getAll(params: {
    page?: number;
    pageSize?: number;
    outbound?: boolean;
    manifestType?: 'inbound' | 'outbound';
    sortBy?: 'id' | 'created_on' | '-id' | '-created_on';
    owner?: number[];
  }) {
    return this.api.manifestsList({
      ...params,
      station: EphemeralStateService.getMyStationId(),
      fromDate: dateUtils.fromDate(),
      toDate: dateUtils.toDate(),
    });
  }

  public async getById(id: number) {
    return this.api.manifestsRead({ id });
  }

  public async getCSVExport(params: {
    id?: string;
    fromDate: string;
    toDate: string;
    manifestType?: 'inbound' | 'outbound';
    owner?: number[];
  }) {
    return this.api.manifestsCsv({
      fromDate: params.fromDate,
      toDate: params.toDate,
      manifestIds: params.id ? [params.id] : undefined,
      station: EphemeralStateService.getMyStationId(),
      manifestType: params.manifestType,
      owner: params.owner,
    });
  }

  public async update(id: number, data: ManifestSerializerDetails) {
    return this.api.manifestsPartialUpdate({
      id,
      data,
    });
  }

  public async importInboundManifest(params: {
    owner: number;
    file: any;
    options?: any;
  }) {
    return this.api.manifestsInboundInboundUpload({
      ...params,
      defaultEntryStation: EphemeralStateService.getMyStationId(),
    });
  }
}

export default new Manifests();
