import React, { useEffect, useState } from 'react';
import DashboardStatsService from '../../../services/DashboardStats.service';
import { createStyles, withStyles } from '@material-ui/core/styles';
import {
  humanReadableNull,
  renderVolumeString,
} from '../../DetailsPagesFunctions';
import clsx from 'clsx';

// Types
import { NestedProcessStepSerializerPackageProcessStepEnum } from 'cloudsort-client';
import { Grid, GridSize, Paper, Theme } from '@material-ui/core';
import Typography from '../../primitives/typography/Typography';
import configurationUtils from '../../../utils/configurationUtils';
import CSSectionTitleSeparator from '../../primitives/csSectionTitleSeparator/CSSectionTitleSeparator';
import SingleDetail from '../../primitives/singleDetail/SingleDetail';
import { CSLink } from '../../primitives/csLink';
import { AuthRoutes } from '../../../interfaces/routes';
import capitalize from 'lodash/capitalize';

interface Props {
  classes: { [key: string]: string };
  areaId: number;
  packagesStatusToFetch: NestedProcessStepSerializerPackageProcessStepEnum;
}

const styles = createStyles((theme: Theme) => ({
  paper: {
    width: '100%',
    height: 'auto',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    padding: '20px 30px',
    margin: 10,
    boxShadow: theme.shadows[2],
  },
  greyPaper: {
    backgroundColor: theme.palette.grey.A200,
    boxShadow: 'none',
  },
  resetMargin: {
    margin: '-24px',
  },
  largeValueDetail: {
    '& .detail-value': {
      ...theme.typography.h5,
    },
  },
}));

const PrimaryAreaPackagesOverview: React.FC<Props> = ({
  classes,
  areaId,
  packagesStatusToFetch,
}) => {
  const [packageCount, setPackageCount] = useState<number>();
  const [volume, setVolume] = useState<number>();

  useEffect(() => {
    const fetchStats = async () => {
      const {
        data: { volume },
      } = await DashboardStatsService.getVolume({ area: areaId });

      const {
        data: { count },
      } = await DashboardStatsService.getPackagesByStatus({
        processStep: [packagesStatusToFetch],
        area: areaId,
      });

      setVolume(volume);
      setPackageCount(count);
    };

    areaId && fetchStats();
  }, [areaId, packagesStatusToFetch]);

  const commonColumnSingleDetail = {
    labelColumns: 6 as GridSize,
    valueColumns: 6 as GridSize,
  };

  return (
    <Paper
      className={classes.paper}
      data-testid='areas-packages-overview'
    >
      <Typography
        variant='h6'
        component='h2'
        className={classes.titleSpacing}
      >
        {configurationUtils.getPageTitle(false, 'PACKAGE')} Overview
      </Typography>
      <CSSectionTitleSeparator
        borderWidth='border_thin'
        bottomMargin={10}
        topMargin={5}
      />
      <Grid
        item
        container
        spacing={3}
        className={classes.resetMargin}
      >
        <Grid item xs={12} sm={6}>
          <Paper className={clsx(classes.paper, classes.greyPaper)}>
            <SingleDetail
              label={capitalize(packagesStatusToFetch)}
              className={classes.largeValueDetail}
              value={
                packageCount ? (
                  <CSLink
                    to={`${AuthRoutes.PACKAGE}/?processStep=PRIMARY&area=${areaId}`}
                  >
                    {humanReadableNull(packageCount)}
                  </CSLink>
                ) : (
                  humanReadableNull(packageCount)
                )
              }
              {...commonColumnSingleDetail}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper className={clsx(classes.paper, classes.greyPaper)}>
            <SingleDetail
              label='Volume'
              className={classes.largeValueDetail}
              value={renderVolumeString(volume)}
              {...commonColumnSingleDetail}
            />
          </Paper>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default withStyles(styles)(PrimaryAreaPackagesOverview);
