import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './stackedStatsOneRow.styles';
import { Typography } from '../primitives';
import Paper from '@material-ui/core/Paper';
import {
  Box,
  Grid,
  CircularProgress,
  CircularProgressProps,
} from '@material-ui/core';
import clx from 'classnames';
import { Link } from 'react-router-dom';
import {
  CardContainerVariants,
  CardItemVariants,
  StatData,
} from '../stackedStats/StackedStats';
import NoDataAvailableIcon from '../../components/dashboard/dashboardModules/utils/NoDataAvailableIcon';

interface Props {
  classes: { [key: string]: string };
  containerStyleVariant?: CardContainerVariants;
  itemsStyleVariant?: CardItemVariants;
  dataTestId?: string;
  style?: React.CSSProperties;
  stats: StatData;
  linkBase: string;
  title: string;
  percentage?: number;
}

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number },
) {
  return (
    <Box position='relative' display='inline-flex'>
      <CircularProgress
        color='primary'
        variant='determinate'
        {...props}
      />
      <CircularProgress
        variant='determinate'
        color='secondary'
        value={100}
        style={{ position: 'absolute' }}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position='absolute'
        display='flex'
        alignItems='center'
        justifyContent='center'
      >
        <Typography variant='caption'>{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const SingleRowStats: React.FC<Props> = ({
  classes,
  dataTestId,
  style,
  linkBase,
  containerStyleVariant,
  itemsStyleVariant,
  stats,
  title,
  percentage,
}) => {
  const containerClasses = clx({
    [classes.paper]: true,
    [classes.containerDark]:
      containerStyleVariant === CardContainerVariants.DARK,
    [classes.containerWhite]:
      containerStyleVariant === CardContainerVariants.WHITE,
    [classes.containerGold]:
      containerStyleVariant === CardContainerVariants.GOLD,
  });

  const renderItem = (key: string) => {
    const getstatsValue = (value: string | number) => {
      switch (value) {
        case 'pending':
          return <CircularProgress size={18} style={{ margin: 5 }} />;

        case 'error':
          return <NoDataAvailableIcon />;

        default:
          return (
            <Typography
              variant='body1'
              className={classes.itemCountLarge}
            >
              {stats[key].filter ? (
                <Link
                  data-testid={`stats:${stats[key].label}:value`}
                  to={
                    linkBase +
                    '?' +
                    stats[key].filter!.name +
                    '=' +
                    stats[key].filter!.value
                  }
                >
                  {stats[key].value}
                </Link>
              ) : (
                stats[key].value
              )}
            </Typography>
          );
      }
    };

    return (
      <>
        <Typography
          variant='h4'
          className={classes.itemTitleSmall}
          data-testid={`stats:${stats[key].label}:title`}
        >
          {stats[key].label}
        </Typography>
        {getstatsValue(stats[key].value)}
      </>
    );
  };

  return (
    <Paper
      style={style}
      className={containerClasses}
      data-testid={dataTestId}
    >
      <Box className={classes.cardHeader}>
        <Typography
          variant='h3'
          className={classes.cardTitle}
          data-testid={`stats:${title}:card-title`}
        >
          {title}
        </Typography>
        <Box className={classes.percentageIndicator}>
          {percentage !== undefined ? (
            <CircularProgressWithLabel value={percentage} />
          ) : undefined}
        </Box>
      </Box>

      <Grid container>
        {stats !== undefined &&
          Object.keys(stats).map((key, index) => (
            <Grid item xs={4} key={`${stats[key]?.label}-${index}`}>
              {renderItem(key)}
            </Grid>
          ))}
      </Grid>
    </Paper>
  );
};

export default withStyles(styles)(SingleRowStats);
