import { PermissionsPermissionsEnum } from 'cloudsort-client';
import { AuthRoutes } from '../../interfaces/routes';

export const NAV_PERMISSION_REQUIRED = {
  AREA: PermissionsPermissionsEnum.STATION_READ,
  CONTAINER: PermissionsPermissionsEnum.CONTAINER_READ,
  DASHBOARD: PermissionsPermissionsEnum.STATION_READ,
  DEVICE: PermissionsPermissionsEnum.DEVICE_READ,
  DOCK_DOOR: PermissionsPermissionsEnum.STATION_READ,
  LOADPLAN: PermissionsPermissionsEnum.LOADPLAN_READ,
  MANIFEST: PermissionsPermissionsEnum.MANIFEST_READ,
  OUTBOUND_LOAD: PermissionsPermissionsEnum.OUTBOUNDLOAD_READ,
  INBOUND_LOAD: PermissionsPermissionsEnum.INBOUNDLOAD_READ,
  PACKAGE: PermissionsPermissionsEnum.PACKAGE_READ,
  HELD_PACKAGE: PermissionsPermissionsEnum.PACKAGE_READ,
  ROUTE: PermissionsPermissionsEnum.ROUTE_READ,
  STAFF: PermissionsPermissionsEnum.USER_READ,
  STOP: PermissionsPermissionsEnum.STATION_READ,
  SCHEME: PermissionsPermissionsEnum.LOADPLAN_READ,
};

export enum DASHBOARD_NAVIGATION_SECTION {
  DISTRIBUTION = 'DISTRIBUTION',
  SHIPPING = 'SHIPPING',
  MANAGEMENT = 'MANAGEMENT',
}

export enum NAV_ITEM_SECTION {
  AREA = DASHBOARD_NAVIGATION_SECTION.SHIPPING,
  CONTAINER = DASHBOARD_NAVIGATION_SECTION.DISTRIBUTION,
  DEVICE = DASHBOARD_NAVIGATION_SECTION.MANAGEMENT,
  DOCK_DOOR = DASHBOARD_NAVIGATION_SECTION.SHIPPING,
  LOADPLAN = DASHBOARD_NAVIGATION_SECTION.DISTRIBUTION,
  MANIFEST = DASHBOARD_NAVIGATION_SECTION.DISTRIBUTION,
  OUTBOUND_LOAD = DASHBOARD_NAVIGATION_SECTION.DISTRIBUTION,
  INBOUND_LOAD = DASHBOARD_NAVIGATION_SECTION.DISTRIBUTION,
  PACKAGE = DASHBOARD_NAVIGATION_SECTION.DISTRIBUTION,
  HELD_PACKAGE = DASHBOARD_NAVIGATION_SECTION.DISTRIBUTION,
  ROUTE = DASHBOARD_NAVIGATION_SECTION.SHIPPING,
  STAFF = DASHBOARD_NAVIGATION_SECTION.MANAGEMENT,
  STOP = DASHBOARD_NAVIGATION_SECTION.SHIPPING,
  SCHEME = DASHBOARD_NAVIGATION_SECTION.SHIPPING,
}

export enum TOP_LEVEL_NAV_ITEM {
  DASHBOARD = 'DASHBOARD',
  STATION_LAYOUT = 'STATION_LAYOUT',
  STATION_PLANNER = 'STATION_PLANNER',
  CONFIGURATION = 'CONFIGURATION',
}

export enum SELECTOR_ENTITY_TYPE {
  CLOUDSORT = 'CLOUDSORT',
  ORGANIZATION = 'ORGANIZATION',
  STATION = 'STATION',
}

export interface NavItems {
  id: keyof typeof AuthRoutes;
  order: number;
  active: boolean;
  divider_after: boolean;
}
