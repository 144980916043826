import { SystemApi } from 'cloudsort-client';
import axios from '../utils/axios';

class StationStaffService {
  private api: SystemApi;

  constructor() {
    this.api = new SystemApi(undefined, undefined, axios as any);
  }

  public async resetDB(options = {}) {
    return this.api.systemResetCreate(options);
  }
}

export default new StationStaffService();
