import React from 'react';
import SingleRowStats, {
  StatData,
} from '../../singleRowStats/SingleRowStats';
import DashboardStatsService from '../../../services/DashboardStats.service';
import { AuthRoutes } from '../../../interfaces/routes';
import { NestedProcessStepSerializerContainerProcessStepEnum } from 'cloudsort-client';
import { withStyles } from '@material-ui/core/styles';
import utils from '../../../utils/stats';
import configurationUtils from '../../../utils/configurationUtils';

interface Props {
  areaId: number;
}

const ContainersOverview: React.FC<Props> = ({ areaId }) => {
  const fetchStats = async () => {
    const calls = [
      DashboardStatsService.getContainersByStatus({
        processStep: [
          NestedProcessStepSerializerContainerProcessStepEnum.OPENED,
        ],
        area: areaId,
      }),
      DashboardStatsService.getContainersByStatus({
        processStep: [
          NestedProcessStepSerializerContainerProcessStepEnum.CLOSED,
        ],
        area: areaId,
      }),
    ];
    const responses = await Promise.all(calls);
    return responses.map(
      ({ data: { count, results }, request: { responseURL } }) => {
        let customFilterValue = '';
        let label = '';
        if (
          responseURL.includes(
            NestedProcessStepSerializerContainerProcessStepEnum.OPENED,
          )
        ) {
          label = `Open ${configurationUtils.getPageTitle(
            false,
            'CONTAINER',
          )}`;
          customFilterValue =
            NestedProcessStepSerializerContainerProcessStepEnum.OPENED;
        } else if (
          responseURL.includes(
            NestedProcessStepSerializerContainerProcessStepEnum.CLOSED,
          )
        ) {
          label = `Closed ${configurationUtils.getPageTitle(
            false,
            'CONTAINER',
          )}`;
          customFilterValue =
            NestedProcessStepSerializerContainerProcessStepEnum.CLOSED;
        }
        return {
          label,
          value: String(count),
          longestDwellId: results.length ? results[0].id : undefined,
          longestDwellTime: results.length
            ? utils.getLongestDwellTimeLabel(
                results[0].process_step_time,
              )
            : undefined,
          filterDisabled: false,
          customFilters: [
            {
              filter_name: 'containerProcessStep',
              filter_value: customFilterValue,
            },
            {
              filter_name: 'area',
              filter_value: areaId,
            },
          ],
        };
      },
    ) as StatData[];
  };

  return (
    <SingleRowStats
      dataTestId='area-containers-overview'
      fetch={fetchStats}
      link_base={AuthRoutes.CONTAINER}
      filter_name='containerProcessStep'
      equalColumns
      disableActiveMarker
    />
  );
};

export default withStyles({})(ContainersOverview);
