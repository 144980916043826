import * as React from 'react';
import Typography from '../typography/Typography';
import { Link, LinkProps } from 'react-router-dom';

const CSLink: React.FC<LinkProps> = ({ children, ...others }) => {
  return (
    <Link
      component={({ href }) => (
        <Typography
          color='tertiary'
          variant='body2'
          component='a'
          underlined
          href={href}
        >
          {children}
        </Typography>
      )}
      {...others}
    />
  );
};

export default CSLink;
