import {
  Grid,
  GridSize,
  Paper,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import clsx from 'clsx';
import * as React from 'react';
import Typography from '../typography/Typography';
import styles from './CSTimeContainer.styles';
import { CSTimeContainerProps } from './CSTimeContainerType';

const CSTimeContainer: React.FC<CSTimeContainerProps> = ({
  elements,
  hideArrowIndicator = false,
  'data-testid': dataTestId,
}) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down('md'));
  const classes = styles();
  const MATERIALS_UI_MAX_COLUMNS = 12;
  const columns = (MATERIALS_UI_MAX_COLUMNS /
    elements.length) as GridSize;
  return (
    <Paper
      data-testid={dataTestId}
      className={classes.paperContainer}
    >
      <Grid container alignItems='center'>
        {elements.map((v, index, array) => (
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={12}
            lg={columns}
            direction='column'
            justifyContent='center'
            key={`${index}-${v.title}`}
          >
            <Grid container item xs={12} justifyContent='center'>
              <Grid item>
                <Typography
                  className={classes.textContent}
                  variant='subtitle1'
                  data-testid={v.titleTestId}
                  color={{ color: 'text', variant: 'icon' }}
                >
                  {v.title}
                </Typography>
              </Grid>
            </Grid>
            {array.length === 1 && !hideArrowIndicator && (
              <ArrowDropDown
                className={classes.activeEventTypeMarker}
              />
            )}
            {array.length > 1 &&
              index === array.length - 1 &&
              !isMd && <hr className={classes.separator} />}
            <Grid container item xs={12} justifyContent='center'>
              <Grid item>
                <Typography
                  className={classes.textContent}
                  data-testid={v.contentTestId}
                  variant='h4'
                  component='span'
                >
                  {v.content}
                </Typography>
              </Grid>
            </Grid>
            {v.subContent && (
              <Grid container item xs={12} justifyContent='center'>
                <Grid item>
                  <Typography
                    className={clsx(
                      classes.textContent,
                      classes.subContentSeparation,
                    )}
                    data-testid={v.subcontentTestId}
                    variant='subtitle2'
                    color={{ color: 'text', variant: 'icon' }}
                  >
                    {v.subContent}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

export default CSTimeContainer;
