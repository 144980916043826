import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import withStyles from '@material-ui/core/styles/withStyles';
import clsx from 'clsx';
import { navigationConfig } from '../../redux/slices/navigationSlice';
import { useAppSelector } from '../../redux/store';
import browserHistory from '../../utils/browserHistory';
import styles from './navigation.styles';

type Props = {
  classes: { [key: string]: string };
  currentlyActive: boolean;
  linkDestination: string;
  icon: JSX.Element;
  text: string;
  onClick?: () => void;
};

const TopLevelNavItem = ({
  classes,
  currentlyActive,
  linkDestination,
  icon,
  text,
  onClick,
}: Props) => {
  const { selectedEntityId } = useAppSelector(navigationConfig);

  return (
    <MenuItem
      className={clsx(classes.menuItem, classes.topLevelMenuItem, {
        [classes.current]: currentlyActive,
      })}
      onClick={() => {
        if (onClick) {
          onClick();
        } else {
          browserHistory.push(
            `${linkDestination}?stationId=${selectedEntityId}`,
          );
        }
      }}
    >
      {icon} {text}
    </MenuItem>
  );
};

export default withStyles(styles)(TopLevelNavItem);
