import {
  ButtonProps,
  SimplePaletteColorOptions,
  StandardProps,
} from '@material-ui/core';
import {
  ExtendedPaletteKeys,
  ExtendedSubPaletteKeys,
} from '../../../theming/material-ui';

export interface ButtonWithIconColor {
  buttonColor: ButtonExtendedColors;
  iconColor?: ButtonExtendedColors;
  iconColorVariant?: ExtendedSubPaletteKeys;
}
export interface ButtonColorStyles {
  buttonColor: SimplePaletteColorOptions;
  iconColor?: Record<ExtendedPaletteKeys, unknown>;
}
export type ButtonExtendedColors =
  | ExtendedPaletteKeys
  | 'inherit'
  | 'default';
export type MaterialButtonColor =
  | 'primary'
  | 'secondary'
  | 'inherit'
  | 'default';
export interface CSButtonProps extends StandardProps<Omit<ButtonProps, 'color'>, ''> {
  color?: ButtonExtendedColors | ButtonWithIconColor;
  target? : '_blank' | '_self' | '_parent'| '_top' | 'framename';
  component?: string | 'button' | 'span' | 'div' | 'p';
  rel?: string;
  height?: 'auto' | string;
}
export const knownButtonColors = [
  'inherit',
  'default',
  'primary',
  'secondary',
];
