import { useState, useEffect } from 'react';

export interface NetworkStatsErrors {
  [key: string]: boolean;
}

function useNetworkStatsErrorRegistry(errorKeys: string[]) {
  const [registeredError, registerError] = useState<string>();
  const [networkStatsErrors, setNetworkStatsErrors] =
    useState<NetworkStatsErrors>(
      errorKeys.reduce((acc, key) => {
        return {
          ...acc,
          [key]: false,
        };
      }, {}),
    );

  useEffect(() => {
    if (registeredError) {
      setNetworkStatsErrors({
        ...networkStatsErrors,
        [registeredError]: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registeredError]);

  return { networkStatsErrors, registerError };
}

export default useNetworkStatsErrorRegistry;
