import { createStyles, Theme } from '@material-ui/core/styles';
import selectStyles from '../select/select.styles';
import { getDetailsPageStylesWithTheme } from '../commonStyles/detailsPage.style';
import sectionPageBaseStyle from '../commonStyles/sectionPageBase.style';
import { getFiltersStylesWithTheme } from '../filtersDrawer/filterBadge.style';

export default createStyles((theme: Theme) => ({
  ...getDetailsPageStylesWithTheme(theme),
  ...selectStyles,
  ...sectionPageBaseStyle,
  ...getFiltersStylesWithTheme(theme),
  headerFilterButton: {
    minWidth: '40px',
    marginLeft: '20px',
  },
  planIcon: {
    '&> path': {
      fill: 'currentColor',
    },
    '&:hover > path': {
      fill: theme.palette.tertiary?.main,
    },
  },
  unitAdornmentBox: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '2px',
    height: '24px',
    width: '14px',
  },
  dialogPaper: {
    width: '488px',
    maxHeight: '100%',
  },
  dialogActions: {
    padding: '16px 23px 25px 8px',
  },
  mb5px: {
    [theme.breakpoints.down('md')]: {
      marginBottom: '5px',
    },
  },
}));
