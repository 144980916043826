import { useEffect, useState } from 'react';
import { DEFAULT_POLLING_INTERVAL } from '../services/utils/constants';
import TasksService from '../services/Tasks.service';
import { TaskStatusEnum } from 'cloudsort-client';
import saveAs from 'file-saver';

const usePolling = () => {
  const [intervalIdState, setIntervalIdState] =
    useState<ReturnType<typeof setInterval>>();

  let intervalId: ReturnType<typeof setInterval>;

  const startPolling = (callback: Function) => {
    intervalId = setInterval(() => {
      callback();
    }, DEFAULT_POLLING_INTERVAL);

    setIntervalIdState(intervalId);
  };

  useEffect(() => {
    return () => {
      intervalIdState && clearInterval(intervalIdState);
    };
  }, [intervalIdState]);

  const stopPolling = () => {
    intervalId && clearInterval(intervalId);
  };

  const checkTaskId = async (
    taskId: number | undefined,
    fileNameStr: string,
    setError: Function,
    setShowProgress: Function,
  ) => {
    if (taskId) {
      const res = (await TasksService.getById(taskId)).data;

      if (res.status === TaskStatusEnum.SUCCEEDED) {
        const fileRes = await TasksService.getOutputById(taskId);

        const blob = new Blob([fileRes.data], {
          type: 'text/csv;charset=utf-8',
        });

        saveAs(blob, fileNameStr);
      } else if (res.status === TaskStatusEnum.FAILED) {
        setError(res.failure_reason);
      }

      if (
        res.status &&
        (
          [
            TaskStatusEnum.SUCCEEDED,
            TaskStatusEnum.FAILED,
          ] as string[]
        ).includes(res.status)
      ) {
        stopPolling();
        setShowProgress(false);
      }
    }
  };

  return { startPolling, stopPolling, checkTaskId };
};

export default usePolling;
