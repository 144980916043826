import { Theme, makeStyles } from '@material-ui/core';

export default () =>
  makeStyles(
    (theme: Theme) => ({
      paperContainer: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        position: 'relative',
        width: '100%',
        borderRadius: theme?.shape.borderRadius,
        backgroundColor: theme?.palette.background.paper,
        padding: '20px 30px',
        boxShadow: theme?.shadows[2],
      },
      paperStandardHeight: {
        minHeight: 105,
      },
    }),
    { name: 'CSSingleDetailMultiColumnContainer' },
  )();
