import React, { useEffect, useState } from 'react';

import { AuthRoutes } from '../../../interfaces/routes';
import { withStyles } from '@material-ui/core/styles';
import {
  CardContainerVariants,
  StatData,
} from '../../stackedStats/StackedStats';
import { LoaderRing } from '../../primitives';
import StackedStatsOneRow from '../../stackedStatsOneRow/StackedStatsOneRow';
import { CARD_LOAD_DELAY } from '../Dashboard';
import useMountedStatus from '../../../hooks/useMountedStatus';
import { setErrorByPS } from './utils/utils';

//Services
import DevicesService from '../../../services/Devices.service';

interface Props {
  title: string;
  index: number;
  classes: { [key: string]: string };
}

const DevicesStats: React.FC<Props> = ({ title, index, classes }) => {
  const [data, setData] = useState<StatData>({});
  const [percentageUpToDate, setPercentageUpToDate] =
    useState<number>();

  const componentMounted = useMountedStatus();

  const fetchStats = async () => {
    const deviceLabels: {
      key: any;
      label: string;
    }[] = [
      {
        key: 'TOTAL',
        label: 'Total',
      },
      {
        key: 'LIVE',
        label: 'Up-to-date',
      },
      {
        key: 'DEPRECATED',
        label: 'Deprecated',
      },
    ];

    let results: StatData = deviceLabels.reduce((acc, step) => {
      return {
        ...acc,
        [step.key]: {
          label: step.label,
          value: 'pending',
        },
      };
    }, {});

    setData(results);

    let numberOfStatsFetched = 0;

    setTimeout(() => {
      deviceLabels.forEach((ps) => {
        DevicesService.getAll({
          page: 1,
          pageSize: 1,
          deviceDetails: false,
          versionStatus: ps.key !== 'TOTAL' ? ps.key : undefined,
        })
          .then((res) => {
            results = {
              ...results,
              [ps.key]: {
                ...results[ps.key],
                value: res.data.count,
                filter: {
                  name: 'VersionStatus',
                  value: ps.label,
                },
              },
            };

            if (componentMounted) {
              setData(results);
            }

            numberOfStatsFetched++;

            if (
              numberOfStatsFetched === deviceLabels.length &&
              componentMounted
            ) {
              //all calls are finished
              setPercentageUpToDate(
                Number(results['TOTAL'].value) !== 0
                  ? Math.round(
                      (Number(results['LIVE'].value) /
                        Number(results['TOTAL'].value)) *
                        100,
                    )
                  : 100,
              );
            }
          })
          .catch((e) => {
            if (componentMounted) {
              setData(
                setErrorByPS(
                  (results = {
                    ...results,
                    [ps.key]: {
                      label: ps.label,
                      value: 'error',
                    },
                  }),
                  ps.key,
                  ps.label,
                ),
              );
            }
          });
      });
    }, index * CARD_LOAD_DELAY);
  };

  useEffect(() => {
    fetchStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return data ? (
    <>
      <StackedStatsOneRow
        title={title}
        containerStyleVariant={CardContainerVariants.WHITE}
        dataTestId='deshboard-devices-stats'
        stats={data}
        linkBase={AuthRoutes.DEVICE}
        percentage={percentageUpToDate}
      />
    </>
  ) : (
    <LoaderRing />
  );
};

export default withStyles({})(DevicesStats);
