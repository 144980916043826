import React, { useEffect, useState } from 'react';
import {
  withStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles';
import { common } from '../../utils/strings';
import Layout from '../layout/Layout';
import {
  StaffPermissionPermissionsEnum,
  StaffPermissionRoleTypeEnum,
  WebHookSubscription,
  WebHookSubscriptionFilter,
  WebHookSubscriptionFilterFilterTypeEnum,
  WebHookSubscriptionFilterResourceTypeEnum,
  WebHookSubscriptionHistory,
} from 'cloudsort-client';
import PaginatedTable from '../paginatedTable/PaginatedTable';
import { Column } from '../../interfaces/components';
import {
  AlertBanner,
  CSButton,
  Typography,
} from '../primitives/index';
import { Box, Grid, Paper, Tooltip } from '@material-ui/core';
import sectionPageBaseStyle from '../commonStyles/sectionPageBase.style';
import PermissionsService from '../../services/Permissions.service';
import { Helmet } from 'react-helmet';
import WebhooksService from '../../services/Webhooks.service';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import detailsPageStyles from '../commonStyles/detailsPage.style';
import ErrorHandler from '../../utils/ErrorHandler';
import SingleDetail from '../primitives/singleDetail/SingleDetail';
import enumToLabel from '../../utils/enumToLabel';
import { AuthRoutes } from '../../interfaces/routes';
import { AxiosError } from 'axios';
import EphemeralStateService from '../../services/EphemeralState.service';
import CSBreadcrumbs from '../primitives/CSBreadcrumbs/CSBreadcrumbs';
import CSSectionTitleSeparator from '../primitives/csSectionTitleSeparator/CSSectionTitleSeparator';
import { CSSingleDetailMultiColumnContainer } from '../primitives/singleDetail/singleDetailMultiColumnContainer';
import { CSTimeContainer } from '../primitives/csTimeContainer';
import SingleRowStats, {
  StatData,
} from '../singleRowStats/SingleRowStats';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import ConfirmationDialog from '../confirmationDialog/ConfirmationDialog';
import TaskService from '../../services/Task.service';
import BatchResendDialog from './batchResendDialog/BatchResendDialog';
import capitalize from 'lodash/capitalize';
import CreateIcon from '@material-ui/icons/Create';
import AddEditWebhookDialog from './addEditWebhookDialog/AddEditWebhookDialog';

interface Props {
  classes: { [key: string]: string };
  match: any;
}

interface ExtededWebHookSubscriptionHistory
  extends WebHookSubscriptionHistory {
  modified_on_formatted: string;
  filter_type_formatted: string;
  resource_type_formatted: string;
}

type EnchancedWebHookSubscriptionFilter =
  WebHookSubscriptionFilter & {
    event_types_count: string | number | undefined;
    resource_type_label: string;
    filter_type_label: string;
  };

enum ActiveDialog {
  BATCH_RESEND = 'BATCH_RESEND',
  SINGLE_ACTION_RESEND_PROMPT = 'SINGLE_ACTION_RESEND_PROMPT',
  RESEND_CONFIRMATION = 'RESEND_CONFIRMATION',
  EDIT_WEBHOOK = 'EDIT_WEBHOOK',
}

const WebhookDetails: React.FC<Props> = ({ classes, match }) => {
  const [webhookData, setWebhookData] =
    useState<WebHookSubscription>();
  const [showProgress, setShowProgress] = useState(false);
  const [error, setError] = useState();
  const [activeDialog, setActiveDialog] = useState<
    ActiveDialog | undefined
  >();
  const [resendTaskId, setResendTaskId] = useState<number>();

  const thisWebhookId = match.params.webhookId;

  const fetchAndSetWebhookData = async () => {
    try {
      setShowProgress(true);
      const webhookDetails = await WebhooksService.getById(
        thisWebhookId,
      );
      for (const event of webhookDetails.data
        .filter_events as EnchancedWebHookSubscriptionFilter[]) {
        event.event_types_count = event.event_types?.length;
        if (
          (event.filter_type ===
            WebHookSubscriptionFilterFilterTypeEnum.STAR ||
            event.filter_type ===
              WebHookSubscriptionFilterFilterTypeEnum.OUTBOUND_MANIFEST ||
            event.resource_type ===
              WebHookSubscriptionFilterResourceTypeEnum.STAR) &&
          !event.event_types
        ) {
          event.event_types_count = '*';
        }
        event.resource_type_label = enumToLabel(
          event.resource_type as string,
        );
        event.filter_type_label = enumToLabel(event.filter_type);
      }
      setWebhookData(webhookDetails.data);
    } catch (e) {
      setError(await ErrorHandler.getLabel(e as AxiosError));
    } finally {
      setShowProgress(false);
    }
  };

  useEffect(() => {
    PermissionsService.redirectIfNoPermission(
      StaffPermissionPermissionsEnum.WEBHOOK_READ,
    );
    PermissionsService.redirectIfNoRoles([
      StaffPermissionRoleTypeEnum.ORG_ADMIN,
      StaffPermissionRoleTypeEnum.SUPER_USER,
    ]);
    fetchAndSetWebhookData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const COLUMNS_EVENTS: Column[] = [
    {
      id: 'id',
      label: 'ID',
      hide: true,
    },
    {
      id: 'task_id',
      label: 'Task ID',
      hide: true,
    },
    {
      id: 'station_id',
      label: 'Station ID',
    },
    {
      id: 'modified_on_formatted',
      label: 'Time',
    },
    {
      id: 'filter_type_formatted',
      label: 'Filter Type',
    },
    {
      id: 'resource_type_formatted',
      label: 'Resource Type',
    },
    {
      id: 'tracking_update_event_type',
      label: 'Event Type',
    },
    {
      id: 'status',
      label: 'Status',
    },
  ];

  const fetchHistory = async (
    pageIndex: number,
    rowsPerPage: number,
  ) => {
    const res = await WebhooksService.getHistory({
      id: thisWebhookId,
      page: pageIndex,
      pageSize: rowsPerPage,
    });

    for (const historyItem of res.data
      .results as ExtededWebHookSubscriptionHistory[]) {
      historyItem.modified_on_formatted = historyItem.modified_on
        ? new Date(historyItem.modified_on).toLocaleString()
        : common.emptyValue;
      historyItem.resource_type_formatted = capitalize(
        historyItem.resource_type,
      );
      historyItem.filter_type_formatted = historyItem.type
        ? historyItem.type
            .split('_')
            .map((word) => capitalize(word))
            .join(' ')
        : common.emptyValue;
    }

    return res;
  };

  const processResendSingleTask = async () => {
    try {
      if (resendTaskId) {
        TaskService.resubmit({
          data: { task_ids: [resendTaskId], all: true },
        });
        setActiveDialog(ActiveDialog.RESEND_CONFIRMATION);
      }
    } catch (e) {
      setError(await ErrorHandler.getLabel(e as AxiosError));
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort -
Webhook ${webhookData?.id || ''}`}
        </title>
      </Helmet>
      <Layout>
        {error && (
          <AlertBanner
            severity='error'
            alertTitle={'Error'}
            alertMsg={error}
          />
        )}
        {showProgress && <ProgressIndicator />}

        {activeDialog === ActiveDialog.EDIT_WEBHOOK && (
          <AddEditWebhookDialog
            webhookToEdit={webhookData}
            closeDialog={() => {
              setActiveDialog(undefined);
            }}
            onAfterSave={(updated) => {
              setActiveDialog(undefined);
              setWebhookData(updated);
            }}
          />
        )}

        <ConfirmationDialog
          title='Resend Webhook Payload'
          msg='Are you sure you want to resend webhook payload?'
          primaryActionLabel='Confirm'
          onPrimaryAction={() => {
            processResendSingleTask();
          }}
          cancelLabel='Cancel'
          onCancel={() => {
            setActiveDialog(undefined);
          }}
          isOpen={
            activeDialog === ActiveDialog.SINGLE_ACTION_RESEND_PROMPT
          }
        />

        <ConfirmationDialog
          title='Webhook Payloads Resent'
          msg='Your request to re-execute webhook payload has been sent.'
          primaryActionLabel='OK'
          onPrimaryAction={() => {
            setActiveDialog(undefined);
          }}
          onCancel={() => {
            setActiveDialog(undefined);
          }}
          hideCancelButton
          isOpen={activeDialog === ActiveDialog.RESEND_CONFIRMATION}
        />

        {activeDialog === ActiveDialog.BATCH_RESEND && (
          <BatchResendDialog
            objectId={webhookData?.task_object_summary?.object_id}
            objectTypeId={
              webhookData?.task_object_summary?.object_type_id
            }
            closeDialog={() => {
              setActiveDialog(undefined);
            }}
            onAfterSave={() => {
              setActiveDialog(ActiveDialog.RESEND_CONFIRMATION);
            }}
          />
        )}

        {webhookData && (
          <>
            <Grid container>
              <Grid item xs={12}>
                <Box mb={5}>
                  <CSBreadcrumbs
                    breadcrumbs={[
                      {
                        label: 'Webhooks',
                        link:
                          AuthRoutes.WEBHOOKS +
                          '?stationId=' +
                          EphemeralStateService.getMyStationId(),
                      },
                      {
                        label: `
                    Webhook ID ${webhookData?.id || ''}`,
                        selected: true,
                      },
                    ]}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Typography variant='h3' component='h1'>
                  {`Webhook ID ${webhookData?.id || ''}`}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                className={classes.nonMobileAlignRight}
              >
                <CSButton
                  variant='outlined'
                  color='secondary'
                  onClick={() => {
                    setActiveDialog(ActiveDialog.EDIT_WEBHOOK);
                  }}
                  startIcon={<CreateIcon />}
                >
                  Edit
                </CSButton>
              </Grid>
              <Grid item xs={12}>
                <CSSectionTitleSeparator topMargin={8} />
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginBottom: 8 }}>
              <Grid item xs={8}>
                <CSSingleDetailMultiColumnContainer
                  elements={[
                    <SingleDetail
                      inline={true}
                      label='Customer'
                      value={
                        webhookData?.owner_name || common.emptyValue
                      }
                    />,
                    <SingleDetail
                      inline={true}
                      label='Name'
                      value={webhookData?.name || common.emptyValue}
                    />,
                    <SingleDetail
                      inline={true}
                      label='Station'
                      value={
                        webhookData?.filter_events[0].station_id ||
                        common.emptyValue
                      }
                    />,
                    <SingleDetail
                      inline={true}
                      label='Resource Type'
                      value={
                        webhookData?.filter_events[0].resource_type
                          ? capitalize(
                              webhookData.filter_events[0]
                                .resource_type,
                            )
                          : common.emptyValue
                      }
                    />,
                    <SingleDetail
                      inline={true}
                      label='Times Triggered'
                      value={
                        webhookData?.task_object_summary
                          ?.total_started || common.emptyValue
                      }
                    />,
                    <SingleDetail
                      inline={true}
                      label='Organization'
                      value={
                        webhookData?.organization_name ||
                        common.emptyValue
                      }
                    />,
                    <SingleDetail
                      inline={true}
                      label='Listener URL'
                      customValueComponent
                      value={
                        webhookData?.url ? (
                          <Tooltip
                            title={webhookData.url}
                            placement='top'
                            arrow
                          >
                            <span className={classes.urlCell}>
                              {webhookData.url}
                            </span>
                          </Tooltip>
                        ) : (
                          common.emptyValue
                        )
                      }
                    />,
                    <SingleDetail
                      inline={true}
                      label='Webhook Type'
                      value={
                        webhookData?.filter_events[0].filter_type
                          ? webhookData.filter_events[0].filter_type
                              .split('_')
                              .map((word) => capitalize(word))
                              .join(' ')
                          : common.emptyValue
                      }
                    />,
                    <SingleDetail
                      inline={true}
                      label={'Events'}
                      value={
                        (
                          webhookData?.filter_events[0].event_types ||
                          []
                        ).length || common.emptyValue
                      }
                    />,
                  ]}
                />
              </Grid>
              <Grid item xs={4}>
                <CSTimeContainer
                  elements={[
                    {
                      title: 'Last Triggered',
                      content: webhookData?.task_object_summary
                        ?.last_completed
                        ? new Date(
                            webhookData.task_object_summary.last_completed,
                          ).toLocaleString()
                        : common.emptyValue,
                    },
                  ]}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                {webhookData && (
                  <Paper className={classes.paperContainer}>
                    <Typography variant='h6'>Events</Typography>
                    <CSSectionTitleSeparator
                      topMargin={10}
                      borderWidth='border_thin'
                    />
                    {webhookData?.filter_events[0].event_types
                      ?.length ? (
                      webhookData.filter_events[0].event_types?.map(
                        (type) => (
                          <CSButton
                            key={type}
                            size='small'
                            variant='contained'
                            color='secondary'
                            height='24px'
                            className={classes.badgeButton}
                          >
                            {type}
                          </CSButton>
                        ),
                      )
                    ) : (
                      <Typography>No events available.</Typography>
                    )}
                  </Paper>
                )}
              </Grid>
              <Grid item xs={6}>
                <Typography variant={'h3'}>Event Log</Typography>
              </Grid>
              <Grid
                item
                xs={6}
                className={classes.nonMobileAlignRight}
              >
                <CSButton
                  variant='contained'
                  color={{
                    buttonColor: 'secondary',
                    iconColor: 'primary',
                  }}
                  onClick={() => {
                    setActiveDialog(ActiveDialog.BATCH_RESEND);
                  }}
                  disabled={
                    !webhookData?.task_object_summary
                      ?.object_type_id ||
                    !webhookData?.task_object_summary?.object_id
                  }
                  startIcon={<AutorenewIcon />}
                >
                  Batch Resend
                </CSButton>
              </Grid>
              <Grid item xs={12}>
                <CSSectionTitleSeparator topMargin={10} />
              </Grid>
              <Grid item xs={12}>
                <PaginatedTable
                  title=''
                  columns={COLUMNS_EVENTS}
                  dataTestIdPrefix={'webhook-events'}
                  fetch={fetchHistory}
                  rowsLoadDetailPages={false}
                  actions={[
                    {
                      tableLabel: ' ',
                      columnLabel: <AutorenewIcon />,
                      callbackProperty: 'task_id',
                      qualifier: 'task_id',
                      callback: (id) => {
                        setResendTaskId(id);
                        setActiveDialog(
                          ActiveDialog.SINGLE_ACTION_RESEND_PROMPT,
                        );
                      },
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </>
        )}
        <Grid container>
          <Grid item xs={12}>
            <Typography
              variant='h6'
              className={classes.headingMargin}
            >
              Health Stats
            </Typography>
            {webhookData && (
              <SingleRowStats
                link_base={''}
                disableActiveMarker
                equalColumns
                fetch={() => {
                  return [
                    {
                      label: 'Count',
                      value:
                        webhookData?.task_object_summary
                          ?.health_count || common.emptyValue,
                    },
                    {
                      label: 'Failura Rate',
                      value: webhookData?.task_object_summary
                        ?.health_failure_rate
                        ? webhookData.task_object_summary.health_failure_rate.toFixed(
                            2,
                          )
                        : common.emptyValue,
                    },
                    {
                      label: 'Events per Minute',
                      value: webhookData?.task_object_summary
                        ?.health_epm
                        ? webhookData.task_object_summary.health_epm.toFixed(
                            2,
                          )
                        : common.emptyValue,
                    },
                    {
                      label: 'Last Updated',
                      value: webhookData?.task_object_summary
                        ?.health_modified_on
                        ? new Date(
                            webhookData.task_object_summary.health_modified_on,
                          ).toLocaleString()
                        : common.emptyValue,
                    },
                    {
                      label: 'Last Reset',
                      value: webhookData?.task_object_summary
                        ?.health_reset_on
                        ? new Date(
                            webhookData?.task_object_summary.health_reset_on,
                          ).toLocaleString()
                        : common.emptyValue,
                    },
                  ] as StatData[];
                }}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant='h6'
              className={classes.headingMargin}
            >
              Execution Stats
            </Typography>
            {webhookData && (
              <SingleRowStats
                link_base={''}
                disableActiveMarker
                equalColumns
                fetch={() => {
                  return [
                    {
                      label: 'Last Completed',
                      value: webhookData?.task_object_summary
                        ?.last_completed
                        ? new Date(
                            webhookData.task_object_summary.last_completed,
                          ).toLocaleString()
                        : common.emptyValue,
                    },
                    {
                      label: 'Total Tasks',
                      value:
                        webhookData?.task_object_summary
                          ?.total_tasks || common.emptyValue,
                    },
                    {
                      label: 'Total Pending',
                      value:
                        webhookData?.task_object_summary
                          ?.total_pending || common.emptyValue,
                    },
                    {
                      label: 'Total Started',
                      value:
                        webhookData?.task_object_summary
                          ?.total_started || common.emptyValue,
                    },
                    {
                      label: 'Total Success',
                      value:
                        webhookData?.task_object_summary
                          ?.total_success || common.emptyValue,
                    },
                    {
                      label: 'Total Failed',
                      value:
                        webhookData?.task_object_summary
                          ?.total_failed || common.emptyValue,
                    },
                  ] as StatData[];
                }}
              />
            )}
          </Grid>
        </Grid>
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...sectionPageBaseStyle,
    headingMargin: {
      margin: '20px 0',
    },
    urlCell: {
      display: 'block',
      maxWidth: 100,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    paperContainer: {
      padding: '20px 30px',
      marginBottom: 20,
      width: '100%',
      borderRadius: theme?.shape.borderRadius,
      backgroundColor: theme?.palette.background.paper,
      boxShadow: theme?.shadows[2],
    },
    badgeButton: {
      margin: '0 5px 5px 0',
    },
    nonMobileAlignRight: {
      [theme.breakpoints.down('xs')]: {
        marginTop: 20,
      },
      [theme.breakpoints.up('sm')]: {
        textAlign: 'right',
      },
    },
  })),
)(WebhookDetails);
