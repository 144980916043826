import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Theme,
  Typography,
  createStyles,
  withStyles,
} from '@material-ui/core';
import { useCallback, useEffect, useState } from 'react';
import CSDialogTitleWithIcon from '../primitives/csDialogTitleWithIcon/CSDialogTitleWithIcon';
import { DialogIcons } from '../primitives/csDialogTitleWithIcon/CSDialogTitleWithIconTypes';
import { CSMonoGridContainer } from '../primitives/csMonoGridContainer';
import { CSButton, CSTextField } from '../primitives';
import CSSelect from '../primitives/csSelect/CSSelect';

import CSAsyncSelect from '../primitives/csAsyncSelect/CSAsyncSelect';

import {
  Package,
  PackageDetails,
  PackageWithHoldReasons,
} from 'cloudsort-client';

import debounce from 'lodash/debounce';
import CustomersService from '../../services/Customers.service';
import { MAX_PAGE_SIZE } from '../../services/utils/constants';
import EphemeralStateService from '../../services/EphemeralState.service';
import ErrorHandler from '../../utils/ErrorHandler';

import CSDialogAlert from '../primitives/csDialogAlert/CSDialogAlert';

import { useForm, Controller, FieldError } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { AxiosError } from 'axios';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import configurationUtils from '../../utils/configurationUtils';
import HeldPackagesService from '../../services/HeldPackages.service';
import PackagesService from '../../services/Packages.service';

interface Props {
  classes: { [key: string]: string };
  closeDialog: () => void;
  onSave: (
    packageData: PackageWithHoldReasons | PackageDetails | Package,
    reason: string,
    csNotes?: string,
  ) => void;
  packageData?: PackageDetails;
}

enum DialogStep {
  TRACKING_ID = 'TRACKING_ID',
  FORM = 'FORM',
  NO_PACKAGE = 'NO_PACKAGE',
}

const AddHeldReasonDialog = ({
  classes,
  closeDialog,
  onSave,
  packageData,
}: Props) => {
  const [dialogStep, setDialogStep] = useState<DialogStep>(
    DialogStep.TRACKING_ID,
  );

  const [dialogPackage, setDialogPackage] = useState<
    PackageWithHoldReasons | PackageDetails | Package | undefined
  >(packageData);

  const [error, setError] = useState<string | undefined>();

  const handleError = async (e: any) => {
    setError(await ErrorHandler.getLabel(e as AxiosError));
  };

  const typeAheadItemZodObject = {
    value: z.any(),
    label: z.any(),
  };

  const formSchema = z.object({
    trackingId: z.string().optional(),
    inboundLoad: z.string().optional(),
    packageOwner: z.object(typeAheadItemZodObject),
    reason: z.string().min(2),
    csNotes: z.string().optional(),
  });

  type FormSchemaType = z.infer<typeof formSchema>;

  const getDefaultValues = (
    pck?: PackageWithHoldReasons | Package,
  ) => {
    const p = pck || dialogPackage;

    return {
      trackingId: p?.tracking_number || '',
      inboundLoad:
        p && 'inbound_load_name' in p && !!p.inbound_load_name
          ? (p.inbound_load_name as string)
          : '',
      packageOwner: p?.owner
        ? {
            value: p.owner,
            label: p.owner_full_name,
          }
        : undefined,
      reason: '',
      csNotes: '',
    };
  };

  const {
    control,
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
    errors,
  } = useForm<FormSchemaType>({
    resolver: zodResolver(formSchema),
    defaultValues: getDefaultValues(),
    mode: 'onChange',
  });

  const [showProgress, setShowProgress] = useState<boolean>(false);

  const onSubmit = async (data: FormSchemaType) => {
    setError(undefined);
    if (!dialogPackage) return;

    try {
      onSave(dialogPackage, data.reason, data.csNotes);

      setError(undefined);
    } catch (e) {
      handleError(e as AxiosError);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadCustomerOptions = useCallback(
    debounce((inputValue: string, callback: any) => {
      CustomersService.getAll(
        undefined,
        inputValue,
        MAX_PAGE_SIZE,
        EphemeralStateService.getMyStationId(),
        EphemeralStateService.getMyOrgId(),
      )
        .then((data) => {
          callback(
            data.data.results.map((dataEl) => {
              return {
                value: dataEl.id,
                label: dataEl.identifier,
              };
            }),
          );
        })
        .catch((e) => {
          handleError(e as AxiosError);
        });
    }, 500),
    [],
  );

  const holdingReasonsOptions =
    configurationUtils.getHoldingReasons(true);

  const onCancel = () => {
    setError(undefined);
    closeDialog();
  };

  const onNextStepClick = async () => {
    if (!watch('trackingId')) {
      return;
    }

    setShowProgress(true);

    const setDialogPackageToState = (
      pck: Package | PackageWithHoldReasons,
    ) => {
      setDialogPackage(pck);

      setDialogStep(DialogStep.FORM);

      reset(getDefaultValues(pck));
    };

    const packagesWithHoldReasons = await HeldPackagesService.getAll({
      page: 1,
      pageSize: 1,
      trackingNumber: watch('trackingId'),
    });

    if (!packagesWithHoldReasons.data.results.length) {
      const packages = await PackagesService.getAll({
        page: 1,
        pageSize: 1,
        trackingNumber: watch('trackingId'),
      });

      if (!packages.data.results.length) {
        setDialogStep(DialogStep.NO_PACKAGE);
      } else {
        setDialogPackageToState(packages.data.results[0]);
      }
    } else {
      setDialogPackageToState(
        packagesWithHoldReasons.data.results[0],
      );
    }

    setShowProgress(false);
  };

  useEffect(() => {
    // Register Async Select fields that have no ref and values are set manually
    register('packageOwner');
  }, [register]);

  useEffect(() => {
    if (packageData) {
      setDialogStep(DialogStep.FORM);
    }
  }, [packageData, setDialogStep]);

  return (
    <Dialog open data-testid='add-held-reason-dialog'>
      {showProgress && <ProgressIndicator />}
      <DialogTitle>
        <CSDialogTitleWithIcon
          title='Add New reason'
          icon={DialogIcons.ADD}
        />
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent className={classes.dialogContent}>
          <CSMonoGridContainer>
            {error ? (
              <CSDialogAlert alertMessage={error || ''} />
            ) : (
              <></>
            )}

            {dialogStep === DialogStep.NO_PACKAGE && (
              <>
                <Typography variant='h4'>
                  Package Not Found
                </Typography>
                <Typography
                  variant='body1'
                  className={classes.marginTop10}
                >
                  We couldn't locate any package with the tracking
                  number {watch('trackingId')}. Please double-check
                  the number or create a new package.
                </Typography>
              </>
            )}

            {(dialogStep === DialogStep.TRACKING_ID ||
              dialogStep === DialogStep.FORM) && (
              <Controller
                name='trackingId'
                control={control}
                disabled={dialogStep !== DialogStep.TRACKING_ID}
                render={(field) => (
                  <CSTextField
                    label='Tracking ID'
                    placeholder='123000000'
                    containerSize='fullHorizontal'
                    disabled={dialogStep !== DialogStep.TRACKING_ID}
                    error={!!errors.trackingId?.message}
                    helperText={errors.trackingId?.message}
                    {...field}
                  />
                )}
              />
            )}

            {dialogStep === DialogStep.FORM && (
              <CSMonoGridContainer>
                <Controller
                  name='inboundLoad'
                  control={control}
                  render={(field) => (
                    <CSTextField
                      label='Inbound Load'
                      placeholder='Inbound Load'
                      containerSize='fullHorizontal'
                      disabled
                      error={!!errors.inboundLoad?.message}
                      helperText={errors.inboundLoad?.message}
                      {...field}
                    />
                  )}
                />
                <CSAsyncSelect
                  label='Package Owner'
                  name='packageOwner'
                  placeholder='Package Owner'
                  containerSize='fullHorizontal'
                  loadOptions={loadCustomerOptions}
                  menuPortalTarget={document.body}
                  value={watch('packageOwner')}
                  disabled={!!dialogPackage?.owner}
                  error={
                    !!(errors.packageOwner as FieldError)?.message
                  }
                  helperText={
                    (errors.packageOwner as FieldError)?.message
                  }
                  isClearable
                  onChange={(option) => {
                    setValue(
                      'packageOwner',
                      option
                        ? {
                            ...option,
                            value: option.value.toString(),
                          }
                        : undefined,
                    );
                  }}
                />
                <Controller
                  name='reason'
                  control={control}
                  render={(field) => (
                    <CSSelect
                      label='Reason'
                      placeholder='Select Holding reason'
                      containerSize='fullHorizontal'
                      error={!!errors.reason?.message}
                      helperText={errors.reason?.message}
                      {...field}
                    >
                      {holdingReasonsOptions?.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </CSSelect>
                  )}
                />
                <Controller
                  name='csNotes'
                  control={control}
                  render={(field) => (
                    <CSTextField
                      label='Cloudsort Notes'
                      placeholder='Cloudsort Notes'
                      containerSize='fullHorizontal'
                      error={!!errors.csNotes?.message}
                      helperText={errors.csNotes?.message}
                      {...field}
                    />
                  )}
                />
              </CSMonoGridContainer>
            )}
          </CSMonoGridContainer>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <CSButton
            variant='outlined'
            color='secondary'
            className={classes.containedButton}
            onClick={onCancel}
          >
            Cancel
          </CSButton>
          {dialogStep === DialogStep.TRACKING_ID && (
            <CSButton
              variant='contained'
              color='secondary'
              className={classes.containedButton}
              type='button'
              onClick={() => {
                onNextStepClick();
              }}
            >
              Next
            </CSButton>
          )}
          {dialogStep === DialogStep.FORM && (
            <CSButton
              variant='contained'
              color='secondary'
              className={classes.containedButton}
              type='submit'
            >
              Save
            </CSButton>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    dialogContent: {
      width: 480,
      maxWidth: '100%',
    },
    dialogActions: {
      padding: 24,
    },
    marginTop10: {
      marginTop: 10,
    },
  })),
)(AddHeldReasonDialog);
