import { createStyles, Theme } from '@material-ui/core/styles';
import { getDetailsPageStylesWithTheme } from '../commonStyles/detailsPage.style';
import filterBadgeStyle from '../filtersDrawer/filterBadge.style';

export default createStyles((theme: Theme) => {
  const detailsPageStyles = getDetailsPageStylesWithTheme(theme);
  return {
    ...detailsPageStyles,
    ...filterBadgeStyle,
    layout: {
      paddingBottom: 50,
      '& .MuiPaper-root': {
        boxShadow: '0 4px 16px 0 rgba(0, 0, 0, 0.08)',
      },
      '& h3': {
        margin: '35px 0 16px 0',
      },
    },
    flexRow: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    headerButtons: {
      height: 42,
      margin: '0 0 10px 15px',
      [theme.breakpoints.down('sm')]: {
        margin: '0 15px 15px 0',
      },
    },
    headerFilterButton: {
      minWidth: '40px',
    },
    dndContainer: {
      columns: 4,
      columnGap: '24px',
      [theme.breakpoints.down('lg')]: {
        columns: 3,
      },
      [theme.breakpoints.down('md')]: {
        columns: 2,
      },
      [theme.breakpoints.down('sm')]: {
        columns: 1,
      },
    },
    muiSwitch: {
      margin: 0,
      '& .MuiIconButton-label': {
        color: theme.palette.tertiary?.main,
      },
    },
  };
});
