import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './singleRowStats.styles';
import { Typography, LoaderRing } from '../primitives';
import Paper from '@material-ui/core/Paper';
import { Grid } from '@material-ui/core';
import clx from 'classnames';
import {
  FETCH_INTERVAL_TIME,
  FETCH_INTERVAL_ACTIVE,
} from '../dashboard/Dashboard';
import dateUtils from '../../services/utils/date';
import { Link } from 'react-router-dom';
import { ExtendedPaletteKeys } from '../../theming/material-ui';
import {
  TypographyColors,
  TypographyColorVariant,
} from '../primitives/typography/TypographyTypes';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

export interface CustomFilter {
  filter_name: string;
  filter_value: string;
}
export interface StatData {
  label: string;
  longestDwellTime?: string;
  longestDwellId?: string;
  value: string;
  // TODO: Check the usage of this value as there are many cases where it is used as a number
  linkTo?: string;
  customFilters?: CustomFilter[];
  filterDisabled?: boolean;
  textColor?:
    | ExtendedPaletteKeys
    | TypographyColors
    | TypographyColorVariant;
}

interface Props {
  classes: { [key: string]: string };
  dataTestId?: string;
  fetch?: () => Promise<StatData[] | undefined> | StatData[];
  borderDividers?: boolean;
  link_base: string;
  filter_name?: string;
  className?: string;
  containerClassName?: string;
  style?: React.CSSProperties;
  equalColumns?: boolean;
  disableActiveMarker?: boolean;
}

const SingleRowStats: React.FC<Props> = ({
  classes,
  dataTestId,
  fetch,
  borderDividers = true,
  link_base,
  filter_name,
  className,
  containerClassName,
  style,
  equalColumns = false,
  disableActiveMarker = false,
}) => {
  const [stats, setStats] = useState<StatData[] | undefined>(
    undefined,
  );

  const fetchData = async () => {
    const data = await fetch?.();
    setStats(data);
  };

  useEffect(() => {
    fetchData();

    if (dateUtils.isSystemDateToday() && FETCH_INTERVAL_ACTIVE) {
      const interval = setInterval(() => {
        fetchData();
      }, FETCH_INTERVAL_TIME);

      return () => {
        clearInterval(interval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const parseCustomFilters = (
    customFilters: CustomFilter[],
    baseFilter: CustomFilter,
  ) => {
    let link = customFilters.reduce(
      (accumulated, current, currentIndex) => {
        return `${accumulated}${currentIndex === 0 ? '' : '&'}${
          current.filter_name || baseFilter.filter_name
        }=${current.filter_value || baseFilter.filter_value}`;
      },
      '',
    );
    return link;
  };

  return (
    <Grid xs={12} item>
      <Paper
        style={style}
        className={containerClassName + ' ' + classes.paper}
        data-testid={dataTestId}
      >
        {stats === undefined && (
          <div className={classes.singleStat}>
            <LoaderRing />
          </div>
        )}
        {stats !== undefined && (
          <Grid container spacing={3} className={className}>
            <Grid
              container
              item
              xs={12}
              className={clx({
                [classes.borderDividers]: borderDividers,
              })}
            >
              {stats.map((stat, index, array) => {
                return (
                  <Grid
                    item
                    xs={
                      equalColumns
                        ? undefined
                        : stats.length === 1
                        ? 12
                        : 6
                    }
                    sm={equalColumns ? undefined : 3}
                    className={classes.singleStat}
                    key={`${stat?.label}-${index}`}
                  >
                    {index === array.length - 1 &&
                      index <= array.length - 1 &&
                      !disableActiveMarker && (
                        <ArrowDropDownIcon
                          data-testid={'events-snapshot-active'}
                          className={classes.activeEventTypeMarker}
                        />
                      )}
                    <Typography
                      variant='subtitle1'
                      component='h3'
                      className={classes.titleSeparation}
                      color={{ color: 'text', variant: 'icon' }}
                      data-testid={`${stat?.label}:title`}
                    >
                      {stat?.label}
                    </Typography>
                    {stat.filterDisabled === true ||
                    stat?.filterDisabled === undefined ? (
                      <Typography
                        variant='h4'
                        component='p'
                        className={classes.bigNumber}
                        color={stat.textColor}
                        data-testid={`${stat?.label}:value`}
                        title={stat?.value || ''}
                      >
                        {stat?.value}
                      </Typography>
                    ) : (
                      <Link
                        className={classes.singleStatLink}
                        to={
                          link_base +
                          '?' +
                          (stat?.customFilters
                            ? parseCustomFilters(stat.customFilters, {
                                filter_name: filter_name || '',
                                filter_value: stat?.value,
                              })
                            : `${filter_name}=${stat?.label}`) +
                          '&sortBy=process_step_time'
                        }
                      >
                        <Typography
                          variant='h4'
                          component='p'
                          className={classes.bigNumber}
                          color={stat.textColor}
                          data-testid={`${stat?.label}:value`}
                          title={stat?.value || ''}
                        >
                          {stat?.value}
                        </Typography>
                      </Link>
                    )}
                    {stat?.longestDwellId && (
                      <Link
                        className={classes.singleStatLink}
                        to={`${link_base}/${stat?.longestDwellId}`}
                      >
                        <Typography variant='label' component='p'>
                          {stat?.longestDwellTime}
                        </Typography>
                      </Link>
                    )}
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        )}
      </Paper>
    </Grid>
  );
};

export default withStyles(styles, { name: 'CsSingleStat' })(
  SingleRowStats,
);
