import {
  ShiftPlan,
  ShiftPlansApi,
  ShiftPlanShiftPlanTypeEnum,
} from 'cloudsort-client';
import moment from 'moment';
import axios from '../utils/axios';
import EphemeralStateService from './EphemeralState.service';

export const getDefaultConfig = (date: Date) => {
  const currentDate = moment(date).format('YYYY-MM-DD');

  return {
    station: 0,
    valid_on: '',
    start_time: moment(currentDate + ' 00:00').toISOString(),
    end_time: moment(currentDate + ' 01:00').toISOString(),
    packages_per_container: 100,
    shifts: [],
  };
};
class StationPlanner {
  private api: ShiftPlansApi;

  constructor() {
    this.api = new ShiftPlansApi(undefined, undefined, axios as any);

    this.getShiftByDate = this.getShiftByDate.bind(this);
  }

  public async getShiftByDate(planDate: Date) {
    return this.api.shiftPlansList({
      station: EphemeralStateService.getMyStationId(),
      planDate: planDate.toISOString(),
    });
  }

  public async createShiftPlan(
    planDate: Date,
    presetId?: number,
    data?: ShiftPlan,
  ) {
    return this.api.shiftPlansCreate(
      data
        ? { data: { ...data, valid_on: planDate.toISOString() } }
        : {
            data: {
              ...getDefaultConfig(planDate),
              name: '', //name is not displayed anywhere
              valid_on: planDate.toISOString(),
              station: EphemeralStateService.getMyStationId(),
              preset: presetId || null,
              shift_plan_type: ShiftPlanShiftPlanTypeEnum.DEFAULT,
            },
          },
    );
  }

  public async updateShiftPlan(id: number, data: ShiftPlan) {
    return this.api.shiftPlansUpdate({
      id,
      data,
    });
  }

  public async getChartData(fromDate: Date, toDate: Date) {
    return this.api.shiftPlansTimeline({
      station: EphemeralStateService.getMyStationId(),
      fromDate: fromDate.toISOString(),
      toDate: toDate.toISOString(),
    });
  }
}

export default new StationPlanner();
