const colors = {
  black: '#000000',
  dark: '#363437',
  white: '#ffffff',
  secondaryWhite: '#F0F1F2',
  lightGold: '#ebdbcb',
  whiteGold: '#f9f8f6',
  gold: '#E1BB9B',
  middleGold: '#dbbc9f',
  darkGold: '#d39c6e',
  ultrLightGold: '#F1E0D1',
  gray: '#a3a3a3',
  lightGray: '#adadad',
  veryLightGray: '#D2D2D2',
  ultraLightGray: '#F2F2F2',
  title: '#3E3B3F',
  red: '#EA3B50',
  green: '#06C497',
};

export default colors;
