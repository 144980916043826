import { Grid } from '@material-ui/core';
import * as React from 'react';
import { SelectedFilters } from '../../filtersDrawer/FiltersDrawer';
import CSButton from '../csButton/CSButton';
import CancelIcon from '@material-ui/icons/Cancel';
import { CSHorizontalFilterBadgesGroupProps } from './csHorizontalFilterBadgesGroupTypes';

const CSHorizontalFilterBadgesGroup: React.FC<CSHorizontalFilterBadgesGroupProps> =
  ({
    selectedFilters,
    'data-testid': dataTestId,
    onRemoveFilter,
    ...others
  }) => {
    const areFiltersEmpty = (selectedFilters?: SelectedFilters) =>
      Object.values(selectedFilters || {})
        .map((v) => v.values.length < 1)
        .reduce((acc, v) => (acc ? v : false), true);
    return (
      <>
        {!areFiltersEmpty(selectedFilters) && (
          <Grid container item xs={12} spacing={1} {...others}>
            {Object.values(selectedFilters || []).map(
              (item) =>
                !!(item.values?.length > 0) && (
                  <Grid item key={item.key}>
                    {item.values.map((value, index) => (
                      <CSButton
                        key={value.id + '-' + index}
                        variant='outlined'
                        color='secondary'
                        size='small'
                        onClick={() => {
                          onRemoveFilter?.({
                            filterKey: item.key,
                            filterValue: value.id,
                          });
                        }}
                        endIcon={<CancelIcon />}
                      >
                        {item.label}: {value.name || value.full_name}
                      </CSButton>
                    ))}
                  </Grid>
                ),
            )}
          </Grid>
        )}
      </>
    );
  };

export default CSHorizontalFilterBadgesGroup;
