export const parseParams = (params: { [key: string]: any }) => {
  for (let paramKey of Object.keys(params)) {
    //When the value is NaN, convert it to undefined. This helps us avoid sending empty payload parameters to the API.
    if (
      typeof params[paramKey] === 'number' &&
      Number.isNaN(params[paramKey])
    ) {
      params[paramKey] = undefined;
    }
  }
  return params;
};
