import { StaffPermissionRoleTypeEnum } from 'cloudsort-client';
import { NonAuthRoutes } from '../interfaces/routes';
import browserHistory from '../utils/browserHistory';

// Services
import LocalStorageService from './LocalStorage.service';
import EphemeralStateService from './EphemeralState.service';

class Permissions {
  public hasPermission(permission: string) {
    const permissions =
      EphemeralStateService.getMyStationPermissions() || [];
    return permissions.includes(permission);
  }

  public redirectIfNoPermission(permission: string) {
    if (!this.hasPermission(permission))
      browserHistory.push(NonAuthRoutes.LOGIN);
  }

  public redirectIfNoRoles(
    allowedRoles: StaffPermissionRoleTypeEnum[] = [],
  ) {
    if (!this.belongsToAnyOfRoles(allowedRoles)) {
      browserHistory.push(NonAuthRoutes.LOGIN);
    }
  }

  public belongsToAnyOfRoles(
    roles: StaffPermissionRoleTypeEnum[] = [],
  ) {
    const myRoleType =
      LocalStorageService.getMyStationData()?.user_data?.role_type;
    return (
      myRoleType &&
      roles.includes(
        myRoleType as unknown as StaffPermissionRoleTypeEnum,
      )
    );
  }

  public getDefaultsPermissionsForRole(
    role: StaffPermissionRoleTypeEnum,
  ) {
    const permissionsMatrix =
      LocalStorageService.getMyStationPermissionsMatrix() || [];

    const roleIndex = permissionsMatrix.role_types.indexOf(role);

    return permissionsMatrix.permissions.filter(
      (permission: string, index: number) => {
        return permissionsMatrix.matrix[roleIndex][index]
          ? permission
          : null;
      },
    );
  }
}

export default new Permissions();
