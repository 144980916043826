import { Theme, createStyles } from '@material-ui/core';

export default createStyles((theme: Theme) => ({
  fileDropZone: {
    margin: '20px 0px 8px 0',
    borderRadius: theme.shape.borderRadius_sm,
    display: 'flex',
    flexDirection: 'column',
    padding: '25px',
    alignItems: 'center',
    border: '2px dashed',
    borderColor: theme.palette.tertiary?.main,
    backgroundColor: theme.palette.background.default,
  },
  invisibleInput: {
    display: 'none',
  },
  fileLabel: {
    marginBottom: 20,
    wordBreak: 'break-all',
  },
  draggingActive: {
    borderColor: theme.palette.grey.A400,
  },
  dropRejected: {
    borderColor: theme.palette.error.main,
  },
}));
