import { withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { INTERACTION } from '../../utils';
import SingleToggleBehavior from '../general/SingleToggleBehavior';
import { processSearchQuery } from '../searchQuery';
import { CurrentView, ModuleName } from '../Modules';

// Redux
import { useSearchResultSections } from '../../useSearchResultSections';

interface Props {
  classes: { [key: string]: string };
  data: any;
  interaction?: INTERACTION;
  setPartialData: (wifi: any) => void;
  currentView: CurrentView;
}

const KioskMode: React.FC<Props> = ({
  classes,
  data,
  interaction,
  setPartialData,
  currentView,
}) => {
  const [searchResult, setSearchResult] = useState<string[]>([]);

  const SECTION_TITLE = 'Kiosk Mode';

  const { searchQuery, updateSections } = useSearchResultSections(
    ModuleName.OPERATOR_TOOL,
    SECTION_TITLE,
  );

  useEffect(() => {
    if (currentView === CurrentView.SEARCH) {
      const results = processSearchQuery(searchQuery, [
        SECTION_TITLE,
        'Active',
      ]);

      setSearchResult(results);

      updateSections(results);
    } else {
      setSearchResult([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, currentView]);

  const renderComponent = () => {
    return (
      <SingleToggleBehavior
        highlight={searchResult}
        data={data}
        title='Kiosk Mode'
        interaction={interaction}
        setPartialData={setPartialData}
      />
    );
  };

  return currentView === CurrentView.SEARCH && !searchResult.length
    ? null
    : renderComponent();
};

export default withStyles({})(KioskMode);
