import { createStyles, Theme } from '@material-ui/core/styles';
import { getDetailsPageStylesWithTheme } from '../../commonStyles/detailsPage.style';

export default createStyles((theme: Theme) => ({
  ...getDetailsPageStylesWithTheme(theme),
  eventsSnapshotOuterContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  eventSnapshotContainer: {
    textAlign: 'center',
    padding: 10,
    flexGrow: 1,
    minWidth: 150,
  },
  activeEventTypeMarker: {
    fontSize: '3em',
    position: 'absolute',
    margin: '-28px 0 0 -0.5em',
    color: theme.palette.tertiary?.main,
  },
  paperHolder: {
    background: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    marginBottom: '6px',
    boxShadow: theme.shadows[2]
  },
}));
