import React, { Fragment, useState, useEffect, useMemo } from 'react';
import { AlertBanner, Checkbox, Typography } from '../../primitives';
import { withStyles, createStyles } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  Paper,
  useTheme,
  useMediaQuery,
  Snackbar,
  Tooltip,
} from '@material-ui/core';
import colors from '../../../utils/colors';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import detailsPageStyles from '../../commonStyles/detailsPage.style';
import enumToLabel from '../../../utils/enumToLabel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import FloatRolesTableHeader from '../modules/FloatRolesTableHeader';
import CorportateService from '../../../services/Corportate.service';
import EphemeralStateService from '../../../services/EphemeralState.service';
import {
  CorporateRoles,
  CorporateRolesDependencies,
  PatchCorporateRoles,
  PatchRolePermissionsEnum,
  PatchRoleRoleTypeEnum,
} from 'cloudsort-client';
import ProgressIndicator from '../../progressIndicator/ProgressIndicator';
import {
  configurationUrlParams,
  getInteraction,
  getMode,
  INTERACTION,
  Mode,
} from '../utils';
import { useParams } from 'react-router-dom';
import ErrorHandler from '../../../utils/ErrorHandler';
import { AxiosError } from 'axios';
import StationsService from '../../../services/Stations.service';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import OrganizationsService from '../../../services/Organizations.service';
import Layout from '../../layout/Layout';
import Navbar, { TabsEnum } from '../Navbar';
import VisibilitySensor from 'react-visibility-sensor';

interface Props {
  classes: { [key: string]: string };
}

const Roles: React.FC<Props> = ({ classes }) => {
  //Data & Behavior

  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [isViewReady, setIsViewReady] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [roles, setRoles] = useState<CorporateRoles>();
  const [initialRoles, setInitialRoles] = useState<CorporateRoles>();
  const [permissionDependencies, setPermissionDepdendencies] =
    useState<CorporateRolesDependencies[]>([]);
  const [showDependencyNotification, setShowDependencyNotification] =
    useState(false);
  const [
    dependencyNotificationMessage,
    setDependencyNotificationMessage,
  ] = useState('');
  const [showFloatTableHeader, setShowFloatHeader] = useState(false);
  const [activeVisibilitySensor, setActiveVisibilitySensor] =
    useState(false);
  //Misc
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  const urlParams = useParams<configurationUrlParams>();
  const mode = getMode(urlParams.orgId, urlParams.stationId);
  const interaction = getInteraction(mode);

  const modulesData =
    EphemeralStateService.getMyStationConfiguratation()?.GENERAL
      ?.MODULES;

  const keyAndTitleMap = useMemo(
    () => [
      { title: 'Master', key: 'MASTER_' },
      { title: 'Organization', key: 'ORGANIZATION_' },

      { title: 'Station', key: 'STATION_' },
      { title: 'Operator Tool', key: 'OPERATORTOOL_' },
      { title: 'Load Plan', key: 'LOADPLAN_' },
      { title: modulesData?.PACKAGE?.label_plural, key: 'PACKAGE_' },
      {
        title: modulesData?.OUTBOUND_LOAD?.label_plural,
        key: 'OUTBOUNDLOAD_',
      },
      {
        title: modulesData?.INBOUND_LOAD?.label_plural,
        key: 'INBOUNDLOAD_',
      },
      { title: modulesData?.STAFF?.label_plural, key: 'USER_' },
      { title: modulesData?.DEVICE?.label_plural, key: 'DEVICE_' },
      {
        title: modulesData?.CONTAINER?.label_plural,
        key: 'CONTAINER_',
      },
      {
        title: modulesData?.MANIFEST?.label_plural,
        key: 'MANIFEST_',
      },
      {
        title: modulesData?.ROUTE?.label_plural,
        key: 'ROUTE_',
      },
      {
        title: 'Technical Assets',
        key: 'TECHNICAL_ASSETS_',
      },
      {
        title: 'Webhooks',
        key: 'WEBHOOK_',
      },
    ],
    [modulesData],
  );
  const handleError = async (err: AxiosError) => {
    setError(await ErrorHandler.getLabel(err));
  };

  useEffect(() => {
    const onScroll = () => {
      setActiveVisibilitySensor(true);
      window.removeEventListener('scroll', onScroll);
    };
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', onScroll);
      setActiveVisibilitySensor(false);
    };
  }, [urlParams.orgId, urlParams.stationId]);

  const getAndSetPermissionDependencies = async () => {
    try {
      const { data } =
        await CorportateService.getPermissionDependencies();
      setPermissionDepdendencies(data);
    } catch (e) {
      handleError(e as AxiosError);
    }
  };

  const getAndSetRolesData = async () => {
    try {
      if (mode === Mode.STATION) {
        const { data } = await StationsService.getStationRoles(
          Number(urlParams.stationId),
        );
        setRoles({ ...data });
        setInitialRoles(cloneDeep(data));
      } else if (mode === Mode.ORGANIZATION) {
        const { data } = await OrganizationsService.getRoles(
          Number(urlParams.orgId),
        );
        setRoles({ ...data });
        setInitialRoles(cloneDeep(data));
      } else if (mode === Mode.CLOUDSORT) {
        const { data } = await CorportateService.getCorporateRoles();
        setRoles({ ...data });
        setInitialRoles(cloneDeep(data));
      }
    } catch (e) {
      handleError(e as AxiosError);
    }
  };

  useEffect(() => {
    getAndSetPermissionDependencies();
    getAndSetRolesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParams.orgId, urlParams.stationId]);

  const dependencyStatus = useMemo(() => {
    if (permissionDependencies.length === 0) {
      return {};
    }
    let dependencyStatusData: {
      [key: string]: {
        [key: string]: {
          enabled: boolean;
          tooltipMessage: JSX.Element;
        };
      };
    } = {};

    roles?.permissions?.forEach((permission: string) => {
      const dependencies = permissionDependencies.find(
        (dependencyData) => dependencyData.permission === permission,
      );
      dependencyStatusData[permission] = {};
      roles.role_types?.forEach((role: string, rIndex: number) => {
        if (dependencies!.parents.length === 0) {
          //Nothing to check here, this permission doesn't have dependencies.
          dependencyStatusData[permission][role] = {
            enabled: true,
            tooltipMessage: <></>,
          };
        }
        if (dependencies!.parents.length > 0) {
          let hasEnabledParent = false;
          const dependsOn = [];
          for (let parentDependency of dependencies!.parents) {
            const pIndex = roles?.permissions?.findIndex(
              (currentPermission) =>
                currentPermission === parentDependency,
            );
            if (roles.matrix![rIndex][pIndex!]) {
              hasEnabledParent = true;
            } else {
              //Key and title for parent permission.
              const keyAndTitle = keyAndTitleMap.find((configItem) =>
                parentDependency
                  .toLowerCase()
                  .startsWith(configItem.key.toLowerCase()),
              );

              dependsOn.push(
                `
                  ${keyAndTitle?.title} ${enumToLabel(
                  parentDependency.replace(keyAndTitle!.key, ''),
                )}`,
              );
            }
          }
          //Key and title for current permission
          const keyAndTitle = keyAndTitleMap.find((configItem) =>
            permission
              .toLowerCase()
              .startsWith(configItem.key.toLowerCase()),
          );

          const tooltipMsg =
            dependsOn.length > 1 ? (
              <span>
                To activate {keyAndTitle?.title}&nbsp;
                {enumToLabel(
                  permission.replace(keyAndTitle!.key, ''),
                )}
                , at least one of the following needs to be active:
                <ul
                  style={{
                    paddingLeft: '15px',
                    marginBottom: '0px',
                    marginTop: '3px',
                  }}
                >
                  {dependsOn.map((item) => (
                    <li key={item}>{item}</li>
                  ))}
                </ul>
              </span>
            ) : (
              <span>
                You need to activate {dependsOn} first before
                activating {keyAndTitle?.title}&nbsp;
                {enumToLabel(
                  permission.replace(keyAndTitle!.key, ''),
                )}
                .
              </span>
            );
          dependencyStatusData[permission][role] = {
            enabled: hasEnabledParent,
            tooltipMessage: hasEnabledParent ? <></> : tooltipMsg,
          };
        }
      });
    });
    return dependencyStatusData;
  }, [roles, permissionDependencies, keyAndTitleMap]);

  useEffect(() => {
    if (
      permissionDependencies.length > 0 &&
      Object.keys(dependencyStatus).length > 0 &&
      roles !== undefined
    ) {
      setIsViewReady(true);
    }
  }, [dependencyStatus, permissionDependencies, roles]);

  const shouldPermissionStayEnabled = (
    rIndex: number,
    permission: string,
  ) => {
    let shouldStayEnabled = false;
    const dependencies = permissionDependencies.find(
      (dependencyData) => dependencyData.permission === permission,
    );
    if (dependencies!.parents.length === 0) {
      //No parents, the permission is enabled by default.
      return true;
    }
    //Go through list of parents, if atleast 1 parent is enabled, this permission should stay enabled too.
    for (let parentPermission of dependencies!.parents) {
      const parentPermissionIndex = roles?.permissions?.findIndex(
        (currentPermission) => currentPermission === parentPermission,
      );
      if (roles!.matrix![rIndex][parentPermissionIndex!]) {
        shouldStayEnabled = true;
      }
    }
    return shouldStayEnabled;
  };

  const renderEp = (title: string, key: string) => {
    const grid: JSX.Element[] = [];
    roles?.permissions?.forEach(
      (permission: string, pIndex: number) => {
        if (!permission.includes(key)) {
          return;
        }

        grid.push(
          <TableRow key={pIndex}>
            {roles.role_types
              ?.map((role: string, rIndex: number) => {
                if (role === 'BLANK') {
                  return null;
                }
                const isEnabled =
                  dependencyStatus[permission][role].enabled;
                return (
                  <Fragment key={`${role}-${rIndex}`}>
                    {!rIndex && (
                      <TableCell
                        className={classes.tableCell}
                        style={{ width: '20%' }}
                      >
                        <Box className={classes.permissionHolder}>
                          <Typography
                            className={classes.permissionHeader}
                          >
                            {enumToLabel(permission.replace(key, ''))}
                          </Typography>
                        </Box>
                      </TableCell>
                    )}
                    <Tooltip
                      classes={{
                        tooltipArrow: classes.tooltip,
                        arrow: classes.tooltipArrow,
                      }}
                      title={
                        isEnabled
                          ? ''
                          : dependencyStatus[permission][role]
                              .tooltipMessage
                      }
                      arrow
                    >
                      <TableCell
                        className={classes.tableCell}
                        style={{
                          backgroundColor:
                            rIndex % 2
                              ? 'trasparent'
                              : 'rgb(245 245 245)',
                        }}
                      >
                        <Checkbox
                          data-testid={`checkbox-${title}-${role}-${enumToLabel(
                            permission.replace(key, ''),
                          )}`}
                          disableRipple
                          disabled={
                            interaction === INTERACTION.READ ||
                            !isEnabled
                          }
                          className={classes.checkbox}
                          onChange={() => {
                            //Flip checkbox
                            roles.matrix![rIndex][pIndex] =
                              !roles.matrix![rIndex][pIndex];
                            //Check if we're disabling permission
                            if (!roles.matrix![rIndex][pIndex]) {
                              //Find all dependants
                              const disabledPermissions: string[] =
                                [];
                              const dependencies =
                                permissionDependencies.find(
                                  (dependencyData) =>
                                    dependencyData.permission ===
                                    permission,
                                );
                              if (dependencies!.children.length > 0) {
                                //Go through each dependant, decide if dependant should be disabled.
                                for (let dependant of dependencies!
                                  .children) {
                                  const dependantIndex =
                                    roles?.permissions?.findIndex(
                                      (currentPermission) =>
                                        currentPermission ===
                                        dependant,
                                    );
                                  if (
                                    roles.matrix![rIndex][
                                      dependantIndex!
                                    ] &&
                                    !shouldPermissionStayEnabled(
                                      rIndex,
                                      dependant,
                                    )
                                  ) {
                                    //Dependant should be disabled
                                    const keyAndTitle =
                                      keyAndTitleMap.find(
                                        (configItem) =>
                                          dependant
                                            .toLowerCase()
                                            .startsWith(
                                              configItem.key.toLowerCase(),
                                            ),
                                      );
                                    roles.matrix![rIndex][
                                      dependantIndex!
                                    ] = false;

                                    const disabledDependencyName = `${
                                      keyAndTitle?.title
                                    } ${enumToLabel(
                                      dependant.replace(
                                        keyAndTitle!.key,
                                        '',
                                      ),
                                    )}`;
                                    //Check if permission has disabled in one of the previous checks
                                    if (
                                      !disabledPermissions.includes(
                                        disabledDependencyName,
                                      )
                                    ) {
                                      disabledPermissions.push(
                                        disabledDependencyName,
                                      );
                                    }
                                  }
                                }
                              }
                              //All dependencies are disabled, show notification to the user.
                              if (disabledPermissions.length > 0) {
                                setShowDependencyNotification(true);
                                setDependencyNotificationMessage(
                                  `Your selection also disabled the dependant permission${
                                    disabledPermissions.length === 1
                                      ? ''
                                      : 's'
                                  }: ${disabledPermissions.join(
                                    ', ',
                                  )}.`,
                                );
                              }
                            }
                            setRoles({
                              ...roles,
                            });
                          }}
                          checked={roles.matrix![rIndex][pIndex]}
                        />
                      </TableCell>
                    </Tooltip>
                  </Fragment>
                );
              })
              .filter((role) => role)}
          </TableRow>,
        );
      },
    );

    return (
      <Accordion
        defaultExpanded
        className={classes.expansionPanel}
        style={{ marginTop: 0, marginBottom: 0 }}
        data-testid={`roles-permissions-matrix-category-${title}`}
      >
        <AccordionSummary
          className={classes.accordionSummary}
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography
            style={{
              fontSize: 16,
              fontWeight: 500,
              color: colors.dark,
            }}
          >
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{ padding: 0 }}
          classes={{ root: classes.accordionDetails }}
        >
          <Grid
            container
            item
            xs={12}
            style={
              isMobileView ? { paddingLeft: 6 } : { paddingLeft: 24 }
            }
          >
            <Table>
              <TableBody>
                {grid.map((gridEl: JSX.Element) => gridEl)}
              </TableBody>
            </Table>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  };

  const renderHeader = () => {
    return (
      <Paper
        square
        className={classes.paper}
        style={{ boxShadow: 'none' }}
      >
        <Grid
          container
          item
          xs={12}
          style={
            isMobileView ? { paddingLeft: 6 } : { paddingLeft: 24 }
          }
        >
          <Table style={{ maxWidth: '1566px', margin: '0 auto' }}>
            <TableBody>
              <TableRow>
                {roles?.role_types
                  ?.map((role: string, index: number) => {
                    if (role === 'BLANK') {
                      return null;
                    }
                    return (
                      <Fragment key={role}>
                        {!index && (
                          <TableCell
                            className={classes.tableCell}
                            style={{
                              width: '20%',
                              padding: '20px 0',
                            }}
                          />
                        )}
                        <TableCell
                          align='center'
                          className={classes.tableCell}
                          style={{
                            width: roles
                              ? `${80 / roles.role_types!.length}%`
                              : 'auto',
                            padding: '20px 0',
                          }}
                        >
                          <Typography className={classes.roleHeader}>
                            {enumToLabel(role)}
                          </Typography>
                        </TableCell>
                      </Fragment>
                    );
                  })
                  .filter((role) => role)}
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Paper>
    );
  };
  const getPermissionsByRoleMatrixRow = (role: string) => {
    const matrixIndex = roles!.role_types!.indexOf(role);
    if (matrixIndex !== undefined || matrixIndex !== null) {
      const permissions: string[] = [];
      roles!.permissions!.forEach(
        (permission: string, index: number) => {
          if (roles!.matrix![matrixIndex][index]) {
            permissions.push(permission);
          }
        },
      );
      return permissions;
    }
  };

  return (
    <Layout navCurrent='CONFIGURATION'>
      {error && (
        <AlertBanner
          className={classes.banner}
          severity='error'
          alertTitle={'Error'}
          alertMsg={error}
        />
      )}
      {showProgress && <ProgressIndicator />}
      {!error && (
        <Navbar
          hasUnsavedChanges={!isEqual(initialRoles, roles)}
          activeTab={TabsEnum.ROLES}
          onSaveCallback={async () => {
            try {
              setShowProgress(true);
              const payload: PatchCorporateRoles = {
                roles: roles!.role_types!.map((role: string) => ({
                  label: role,
                  role_type: role as PatchRoleRoleTypeEnum,
                  permissions: getPermissionsByRoleMatrixRow(
                    role,
                  ) as Array<PatchRolePermissionsEnum>,
                })),
              };
              if (mode === Mode.CLOUDSORT) {
                await CorportateService.updateCorporateRoles(payload);
              } else if (mode === Mode.ORGANIZATION) {
                await OrganizationsService.updateOrgRoles(
                  Number(urlParams.orgId),
                  payload,
                );
              } else if (mode === Mode.STATION) {
                await StationsService.updateStationRoles(
                  Number(urlParams.stationId),
                  payload,
                );
              }
              setInitialRoles(cloneDeep(roles));
            } catch (e) {
              handleError(e as AxiosError);
            } finally {
              setShowProgress(false);
            }
          }}
          onResetDataCallback={() => {
            setRoles(cloneDeep(initialRoles));
          }}
        />
      )}
      <VisibilitySensor
        active={activeVisibilitySensor}
        delayedCall
        scrollCheck
        offset={{ top: 66 }}
        intervalDelay={50}
      >
        {({ isVisible }) => {
          setTimeout(() => {
            if (isVisible !== null && !isMobileView) {
              setShowFloatHeader(
                !isVisible && activeVisibilitySensor,
              );
            }
          }, 0);
          return <div style={{ width: 1, height: 1 }} />;
        }}
      </VisibilitySensor>
      {isViewReady ? (
        <Fragment>
          <Snackbar
            data-testid='snackbar-notification'
            key={dependencyNotificationMessage}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={showDependencyNotification}
            onClose={(event, reason) => {
              if (reason === 'clickaway') {
                return;
              }
              setShowDependencyNotification(false);
            }}
            autoHideDuration={4000}
            message={dependencyNotificationMessage}
          />
          {isViewReady && showFloatTableHeader && (
            <FloatRolesTableHeader
              offset={'60px 0 0 -23px'}
              renderContent={renderHeader}
            />
          )}
          <Grid direction='row' xs={12} container item>
            <Grid item sm={6} xs={12}>
              <Typography
                className={classes.title}
                style={{ marginBottom: 16 }}
              >
                Roles
              </Typography>
            </Grid>
            <Grid item sm={6} xs={12} />
            <Grid
              item
              xs={12}
              data-testid={'roles-permissions-matrix'}
            >
              <Box>
                {renderHeader()}
                {keyAndTitleMap.map((configItem) => {
                  return (
                    <React.Fragment key={configItem.key}>
                      {renderEp(configItem.title, configItem.key)}
                    </React.Fragment>
                  );
                })}
              </Box>
            </Grid>
          </Grid>
        </Fragment>
      ) : (
        !error && <ProgressIndicator />
      )}
    </Layout>
  );
};

export default withStyles(
  createStyles(() => ({
    ...detailsPageStyles,
    roleHeader: {
      opacity: 0.53,
      color: '#363437',
      fontSize: 12,
      fontWeight: 500,
      textAlign: 'center',
      letterSpacing: '-0.29px',
    },
    permissionHeader: {
      color: colors.dark,
      fontSize: 14,
      letterSpacing: '-0.29px',
    },
    checkbox: {
      display: 'flex',
      '&:hover': {
        backgroundColor: 'transparent !important',
      },
    },
    permissionHolder: {},
    accordionDetails: {
      padding: '0 0 0 24px',
      '& $permissionHolder:last-child': {
        padding: '12px 0',
      },
    },
    accordionSummary: {
      backgroundColor: '#EFEFEF',
      margin: 0,
      minHeight: '20px !important',
      '& div': {
        margin: '8px 0 !important',
      },
      '& .MuiAccordionSummary-expandIcon': {
        padding: 0,
      },
    },
    tableCell: {
      borderBottom: 'none',
      padding: 0,
      wordBreak: 'break-all',
    },
    tooltip: {
      background: colors.dark,
    },
    tooltipArrow: {
      color: colors.dark,
    },
  })),
)(Roles);
