import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  AreaByQueryAreaTypeEnum,
  Container,
  ContainerContainerTypeEnum,
  ContainerLastProcessStepEnum,
  StaffPermissionPermissionsEnum,
} from 'cloudsort-client';
import Layout from '../layout/Layout';
import PaginatedTable, {
  filterObj,
} from '../paginatedTable/PaginatedTable';
import { Column } from '../../interfaces/components';
import { AuthRoutes, ModulesKeys } from '../../interfaces/routes';
import {
  renderContainerLocationString,
  renderTimestampString,
  renderVolumeString,
} from '../DetailsPagesFunctions';
import queryString from 'query-string';
import configurationUtils from '../../utils/configurationUtils';
import utils from '../../utils/stats';
import { common } from '../../utils/strings';
import FiltersDrawer, {
  SelectedFilters,
} from '../filtersDrawer/FiltersDrawer';
import clx from 'classnames';
import { Grid } from '@material-ui/core';
import styles from './Containers.styles';

// Services
import ContainerService, {
  ContainersSortBy,
} from '../../services/Containers.service';
import PermissionsService from '../../services/Permissions.service';
import EphemeralStateService from '../../services/EphemeralState.service';

//Icons
import FilterListIcon from '@material-ui/icons/FilterList';

//Misc
import { Helmet } from 'react-helmet';
import { CSButton, Typography } from '../primitives';
import ContainerTypeSignal from './ContainerTypeSignal';
import { ExtendedPaletteKeys } from '../../theming/material-ui';
import {
  TypographyColors,
  TypographyColorVariant,
} from '../primitives/typography/TypographyTypes';
import CSSectionTitleSeparator from '../primitives/csSectionTitleSeparator/CSSectionTitleSeparator';
import CSHorizontalFilterBadgesGroup from '../primitives/csHorizontalFilterBadgesGroup/csHorizontalFilterBadgesGroup';
import { FilterDescription } from '../primitives/csHorizontalFilterBadgesGroup/csHorizontalFilterBadgesGroupTypes';
import { useAppDispatch } from '../../redux/store';
import { setLastVisitedModule } from '../../redux/slices/navigationSlice';

export const formatLabel = (str?: string) => {
  if (str === 'N/A') return str;
  const label = str
    ? str
        .replace('_', ' ')
        .split(' ')
        .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
        .join(' ')
    : '';
  return label;
};

const getTextStatusColor = (
  status: ContainerLastProcessStepEnum,
):
  | ExtendedPaletteKeys
  | TypographyColors
  | TypographyColorVariant => {
  switch (status) {
    case ContainerLastProcessStepEnum.OPENED:
      return 'success';
    case ContainerLastProcessStepEnum.DISPATCHED:
      return 'tertiary';
    default:
      return { color: 'common', variant: 'black' };
  }
};

export const formatContainer = (
  container: Record<string, unknown>,
) => {
  const containerObj = container as Container;
  container.load_point = containerObj.load_point || common.emptyValue;

  const { station_name } = containerObj;

  container.volume = renderVolumeString(containerObj.volume);

  container.dwell_time = container.last_process_step_time
    ? utils.getLongestDwellTimeLabel(
        container.last_process_step_time as string,
      )
    : common.emptyValue;

  container.type = 'N/A';
  if (containerObj.outbound) {
    container.type = (
      <Grid
        container
        justifyContent='flex-start'
        spacing={1}
        alignItems='center'
        wrap='nowrap'
      >
        <Grid item>
          <Typography variant='table-body'>Outbound</Typography>
        </Grid>
        <Grid container item>
          <ContainerTypeSignal />
        </Grid>
      </Grid>
    );
  } else {
    container.type = (
      <Grid
        container
        justifyContent='flex-start'
        spacing={2}
        alignItems='center'
        wrap='nowrap'
      >
        <Grid item>
          <Typography variant='table-body'>Inbound</Typography>
        </Grid>
        <Grid container item>
          <ContainerTypeSignal status='inbound' />
        </Grid>
      </Grid>
    );
  }

  container.location = renderContainerLocationString(
    station_name,
    containerObj?.area?.name,
    containerObj?.area?.area_type === AreaByQueryAreaTypeEnum.PRIMARY
      ? undefined
      : containerObj?.zone?.name,
    containerObj.container_status,
    containerObj?.load_point?.name,
  );
  container.critical_activity_time = renderTimestampString(
    containerObj.critical_activity_time,
  );

  container.container_type = formatLabel(
    containerObj.container_type || '',
  );
  container.is_parent_container = containerObj.is_parent_container
    ? 'Yes'
    : 'No';

  container.children_count =
    containerObj.children_count || common.emptyValue;

  container.display_status = (
    <Typography
      variant='table-body'
      color={getTextStatusColor(
        container.last_process_step as ContainerLastProcessStepEnum,
      )}
    >
      {formatLabel((container.last_process_step as string) || '')}
    </Typography>
  );
};

interface Props {
  classes: { [key: string]: string };
  location: any;
}

const Containers: React.FC<Props> = ({ classes, location }) => {
  const [stationModules, setStationModules] = useState<string[]>();

  const [showFiltersDrawer, setShowFiltersDrawer] = useState(false);
  const [selectedFilters, setSelectedFilters] =
    useState<SelectedFilters>();
  const [filterToRemove, setFilterToRemove] =
    useState<FilterDescription>();
  const [filterLastUpdate, setFilterLastUpdate] = useState<number>(0);

  //TODO: Update this when CCC is merged
  const isBOLActive = true;

  // Get labels on each render cycle
  const COLUMNS_CONTAINERS: Column[] = [
    {
      id: 'id',
      label: 'ID',
      width: 50,
      align: 'left',
    },
    {
      id: 'type',
      label: 'Type',
      width: 200,
      align: 'left',
      useCustomComponent: true,
    },
    {
      id: 'display_status',
      label: 'Status',
      width: 200,
      align: 'left',
      useCustomComponent: true,
    },
    {
      id: 'critical_activity_time',
      label: 'Critical Time',
      align: 'center',
      width: 'left',
    },
    {
      id: 'tracking_number',
      label: 'Tracking Number',
      width: 'auto',
      align: 'center',
    },
    {
      id: 'dwell_time',
      label: 'Dwell time',
      align: 'center',
      width: 'auto',
    },
    {
      id: 'package_count',
      label: configurationUtils.getPageTitle(false, 'PACKAGE'),
      width: 'auto',
      align: 'left',
    },
    {
      id: 'container_type',
      label: `Type of ${configurationUtils.getModuleLabel(
        true,
        'CONTAINER',
      )}`,
      width: 'auto',
      align: 'left',
    },
    {
      id: 'location',
      label: 'Location',
      align: 'center',
      width: 'left',
    },
    {
      id: 'is_parent_container',
      label: configurationUtils.getModuleLabel(
        true,
        'GROUP_CONTAINER',
      ),
      align: 'center',
      width: 'left',
      hide: !configurationUtils.isModuleActive('GROUP_CONTAINER'),
    },
    {
      id: 'children_count',
      label: `${configurationUtils.getModuleLabel(
        false,
        'CONTAINER',
      )} Count`,
      align: 'center',
      width: 'left',
      hide: !configurationUtils.isModuleActive('GROUP_CONTAINER'),
    },
  ];

  if (isBOLActive) {
    COLUMNS_CONTAINERS.push({
      id: 'bol',
      label: 'BOL',
      align: 'center',
      width: 'left',
    });
  }

  const dispatch = useAppDispatch();

  const getDrawerFilters = (filters: SelectedFilters) => {
    setSelectedFilters(filters);
    setFilterLastUpdate(Date.now());
  };

  const fetchContainers = async (
    pageIndex: number,
    rowsPerPage: number,
    filterOptions?: filterObj[],
    filterByString?: string,
    sortedBy?: string,
  ) => {
    const res = await ContainerService.getAll({
      page: pageIndex,
      pageSize: rowsPerPage,
      sortBy: (sortedBy as ContainersSortBy) || undefined,
      owner:
        selectedFilters &&
        selectedFilters.owner &&
        selectedFilters.owner.values.length
          ? selectedFilters.owner.values.map((item: any) => item.id)
          : undefined,
      carrier:
        selectedFilters &&
        selectedFilters.carrier &&
        selectedFilters.carrier.values.length
          ? selectedFilters.carrier.values.map((item: any) => item.id)
          : undefined,
      route:
        selectedFilters &&
        selectedFilters.route &&
        selectedFilters.route.values.length
          ? selectedFilters.route.values.map((item: any) => item.id)
          : undefined,
      destination:
        selectedFilters &&
        selectedFilters.stop &&
        selectedFilters.stop.values.length
          ? selectedFilters.stop.values.map((item: any) => item.id)
          : undefined,
      area:
        selectedFilters &&
        selectedFilters.area &&
        selectedFilters.area.values.length
          ? Number(selectedFilters.area.values[0].id)
          : undefined,
      hidePending:
        selectedFilters &&
        selectedFilters.hidePending &&
        selectedFilters.hidePending.values.length
          ? selectedFilters.hidePending.values[0].id === 'yes'
            ? true
            : selectedFilters.hidePending.values[0].id === 'no'
            ? false
            : undefined
          : undefined,
      processStep:
        selectedFilters &&
        selectedFilters.containerProcessStep &&
        selectedFilters.containerProcessStep.values.length
          ? selectedFilters.containerProcessStep.values.map(
              (item: any) => item.id,
            )
          : undefined,
      containerType:
        selectedFilters &&
        selectedFilters.format &&
        selectedFilters.format.values.length
          ? (selectedFilters.format.values[0]
              .id as ContainerContainerTypeEnum)
          : undefined,
      outbound:
        selectedFilters &&
        selectedFilters.type &&
        selectedFilters.type.values.length
          ? selectedFilters.type.values[0].id === 'outbound'
            ? true
            : selectedFilters.type.values[0].id === 'inbound'
            ? false
            : undefined
          : undefined,
      defects:
        selectedFilters &&
        selectedFilters.containerDefect &&
        selectedFilters.containerDefect.values.length
          ? selectedFilters.containerDefect.values.map(
              (item: any) => item.id,
            )
          : undefined,

      //outbound: fOutbound,containerType: fType as ContainerContainerTypeEnum,
    });

    res.data.results.forEach((container: any) => {
      formatContainer(container);
    });

    return res;
  };

  useEffect(() => {
    PermissionsService.redirectIfNoPermission(
      StaffPermissionPermissionsEnum.CONTAINER_READ,
    );

    dispatch(
      setLastVisitedModule({
        module: ModulesKeys.CONTAINER,
        permission: StaffPermissionPermissionsEnum.CONTAINER_READ,
      }),
    );

    setStationModules(
      configurationUtils
        .getConfigArray({
          config:
            EphemeralStateService.getMyStationConfiguratation()
              .GENERAL.MODULES,
          filter: { key: 'active', value: true },
        })
        .map((item: any) => item.id),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort -
        ${configurationUtils.getPageTitle(false, 'CONTAINER')} ${
            queryString.parse(location.search)['page']
              ? '- Page ' + queryString.parse(location.search)['page']
              : ''
          }`}
        </title>
      </Helmet>
      <Layout navCurrent='CONTAINER'>
        <FiltersDrawer
          isOpen={showFiltersDrawer}
          onAfterClose={() => {
            setShowFiltersDrawer(false);
          }}
          getFilters={getDrawerFilters}
          removeFilter={filterToRemove}
        />
        <Grid container className={classes.header}>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.title} variant={'h3'}>
              {configurationUtils.getPageTitle()}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
            <CSButton
              variant='outlined'
              color='secondary'
              className={clx(
                classes.sectionButton,
                classes.headerFilterButton,
              )}
              onClick={() => {
                setShowFiltersDrawer(true);
              }}
            >
              <FilterListIcon />
            </CSButton>
          </Grid>
          <Grid container item xs={12} spacing={1}>
            <CSSectionTitleSeparator
              topMargin={5}
              bottomMargin={24}
            />
            <CSHorizontalFilterBadgesGroup
              selectedFilters={selectedFilters}
              onRemoveFilter={(toRemove) =>
                setFilterToRemove(toRemove)
              }
            />
          </Grid>
        </Grid>
        {!!stationModules &&
          !!filterLastUpdate && ( //make sure modules config is loaded
            <PaginatedTable
              key={'table' + filterLastUpdate}
              title={''}
              columns={COLUMNS_CONTAINERS}
              dataTestIdPrefix={'containers'}
              fetch={fetchContainers}
              rowsLoadDetailPages={true}
              detailsPageBasePath={AuthRoutes.CONTAINER}
              defaultSort={
                (queryString.parse(location.search)[
                  'sortBy'
                ] as string) || undefined
              }
              sortableBy={[
                'process_step_time',
                'outbound_load_time',
                'critical_activity_time',
              ]}
            />
          )}
      </Layout>
    </>
  );
};

export default withStyles(styles)(Containers);
