import React, { useEffect } from 'react';

const TrackingPage: React.FC = () => {
  const getTrackUrl = (): string => {
    switch (window.location.host) {
      case 'web.cloudsort.com':
        return 'https://track.cloudsort.com/';
      case 'web-demo.cloudsort.com':
        return 'https://track-demo.cloudsort.com/';
      case 'web-staging.cloudsort.com':
        return 'https://track-staging.cloudsort.com/';
      default:
        return 'https://track-development.cloudsort.com/';
    }
  };

  useEffect(() => {
    window.location.replace(
      `${getTrackUrl()}${window.location.search}`,
    );
  }, []);

  return <></>;
};

export default TrackingPage;
