import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

const initialState: {
  searchQuery: string;
  sections: string[];
} = {
  searchQuery: '',
  sections: [],
};

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    updateSearchQuery: (state, action: PayloadAction<string>) => {
      state.searchQuery = action.payload;
    },
    addSection: (state, action: PayloadAction<string>) => {
      if (!state.sections.includes(action.payload)) {
        state.sections.push(action.payload);
      }
    },
    removeSection: (state, action: PayloadAction<string>) => {
      state.sections = state.sections.filter(
        (section) => section !== action.payload,
      );
    },
  },
});

export const { updateSearchQuery, addSection, removeSection } =
  searchSlice.actions;

export const selectSearchQuery = (state: RootState) =>
  state.search.searchQuery;
export const selectSections = (state: RootState) =>
  state.search.sections;

export default searchSlice.reducer;
