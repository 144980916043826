import EphemeralStateService from '../services/EphemeralState.service';
import configurationUtils from './configurationUtils';
import { IProcessStep } from '../components/packages/Packages';

const getLabel = (label: string): string => {
  const processStepsOptionsConfig: IProcessStep[] =
    configurationUtils.getConfigArray({
      config: EphemeralStateService.getMyStationConfiguratation()
        ? EphemeralStateService.getMyStationConfiguratation()?.GENERAL
            ?.PROCESS_STEPS
        : undefined,
    });

  const formattedLabel = processStepsOptionsConfig.find(
    (l: any) => l.id === (label || '').replace(/_/g, ''),
  )?.label;
  return formattedLabel || formatLabel(label);
};

const formatLabel = (str?: string) => {
  const label = str
    ? str
        .replace(/_/g, ' ')
        .split(' ')
        .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
        .join(' ')
    : '';
  return label;
};

export default {
  getLabel,
  formatLabel,
};
