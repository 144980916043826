import React, { useEffect, useState } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import selectStyles from '../../../select/select.styles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { LoadPoint } from 'cloudsort-client';
import { Theme, Tooltip } from '@material-ui/core';
import cloneDeep from 'lodash/cloneDeep';

// Icons
import { CSButton } from '../../../primitives';
import CSDialogTitleWithIcon from '../../../primitives/csDialogTitleWithIcon/CSDialogTitleWithIcon';
import { DialogIcons } from '../../../primitives/csDialogTitleWithIcon/CSDialogTitleWithIconTypes';
import LoadBalancingSlider from './LoadBalancingSlider';

interface Props {
  classes: { [key: string]: string };
  open: boolean;
  onAfterDialogClose: () => void;
  onEdit: (
    updatedLoadpoints?: LoadPointDetailsWithDisplayName[],
  ) => void;
  loadpoints: LoadPointDetailsWithDisplayName[];
}

export interface LoadPointDetailsWithDisplayName extends LoadPoint {
  display_name: string;
}

export const roundToTwoDeciamals = (num: number): number => {
  return Math.round(num * 100) / 100;
};

export const percentageLabelFormat = (value: number): string => {
  return `${Math.round(value * 100)}`;
};

export const normalizeLBValues = (
  loadpoints: LoadPointDetailsWithDisplayName[],
): LoadPointDetailsWithDisplayName[] => {
  //normalize values so their sum is 100
  const lpsCopy = cloneDeep(loadpoints);

  //if one loadpoint has no LB value, set all vb values to same numbe
  if (
    lpsCopy.some((lp) => lp.load_balancing === null && lp.is_active)
  ) {
    lpsCopy.forEach((lp) => {
      lp.load_balancing = 1;
    });
  }

  const loadBalancingSum = lpsCopy.reduce(
    (acc, lp) => acc + (lp.load_balancing || 0),
    0,
  );

  lpsCopy.forEach((lp) => {
    lp.load_balancing = roundToTwoDeciamals(
      (lp.load_balancing || 0) / loadBalancingSum,
    );
  });

  return lpsCopy;
};

const EditLoadBalancingDialog: React.FC<Props> = ({
  classes,
  open,
  onAfterDialogClose,
  onEdit,
  loadpoints,
}) => {
  const afterDialogClose = () => {
    onAfterDialogClose();
  };

  const [editedLoadpoints, setEditedLoadpoints] =
    useState<LoadPointDetailsWithDisplayName[]>(loadpoints);
  const [totalVolume, setTotalVolume] = useState(1);

  const handleChange = (lpID: number, newValue: number) => {
    const lpsCopy = [...editedLoadpoints].map((lp) => {
      if (lp.id === lpID) {
        lp.load_balancing = newValue;
      }
      return lp;
    });

    let numberOfOtherLPs = lpsCopy.length - 1;

    let currentVolume = lpsCopy.reduce(
      (acc, lp) => acc + (lp.load_balancing || 0),
      0,
    );

    if (currentVolume > 1) {
      lpsCopy
        .filter((lp) => lp.id !== lpID)
        .sort(
          (a, b) => (a.load_balancing || 0) - (b.load_balancing || 0),
        )
        .forEach((lp) => {
          const overflow = roundToTwoDeciamals(
            (currentVolume - 1) / numberOfOtherLPs,
          );

          if ((lp.load_balancing || 0) < overflow) {
            currentVolume += overflow - (lp.load_balancing || 0);
            lp.load_balancing = 0;
            numberOfOtherLPs--;
          } else {
            lp.load_balancing = roundToTwoDeciamals(
              (lp.load_balancing || 0) - overflow,
            );
          }
        });
    }

    const newVolume = lpsCopy.reduce(
      (acc, lp) => acc + (lp.load_balancing || 0),
      0,
    );

    setTotalVolume(roundToTwoDeciamals(newVolume));
    setEditedLoadpoints(lpsCopy);
  };

  const changeCommitted = () => {
    adjustFirstLP();
  };

  const adjustFirstLP = () => {
    if (totalVolume > 0.98 && totalVolume < 1.02) {
      const totalVolumeExcludingFirstLP = editedLoadpoints.reduce(
        (acc, lp, currentIndex) => {
          if (currentIndex > 0) return acc + (lp.load_balancing || 0);
          return acc;
        },
        0,
      );

      const lpsCopy = [...editedLoadpoints];
      lpsCopy[0].load_balancing = roundToTwoDeciamals(
        1 - totalVolumeExcludingFirstLP,
      );

      setEditedLoadpoints(lpsCopy);
      setTotalVolume(1);
    }
  };

  useEffect(() => {
    setEditedLoadpoints(normalizeLBValues(cloneDeep(loadpoints)));
  }, [loadpoints]);

  return (
    <Dialog
      classes={{ paperScrollPaper: classes.dialogRoot }}
      open={open}
      onClose={() => {
        afterDialogClose();
      }}
    >
      <DialogTitle>
        <CSDialogTitleWithIcon
          title='Load Points Balancing'
          icon={DialogIcons.EDIT}
        />
      </DialogTitle>
      <DialogContent>
        {editedLoadpoints.map((loadpoint, index) => (
          <LoadBalancingSlider
            key={'loadbalancing-slider-' + index}
            loadpoint={loadpoint}
            handleChange={handleChange}
            changeCommitted={changeCommitted}
            isLast={index === editedLoadpoints.length - 1}
          />
        ))}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <CSButton
          variant='outlined'
          color='secondary'
          data-testid={'edit-loadbalancing-cancel-button'}
          onClick={() => {
            afterDialogClose();
          }}
        >
          Cancel
        </CSButton>
        {totalVolume === 1 ? (
          <CSButton
            variant='contained'
            color='secondary'
            data-testid={'edit-loadbalancing-edit-button'}
            onClick={() => {
              onEdit(editedLoadpoints);
            }}
            disabled={totalVolume !== 1}
          >
            Save
          </CSButton>
        ) : (
          <Tooltip title='The total must be 100%'>
            <CSButton
              variant='contained'
              color='secondary'
              data-testid={'edit-loadbalancing-edit-button'}
              onClick={() => {}}
            >
              Save
            </CSButton>
          </Tooltip>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...selectStyles,
    dialogRoot: {
      overflow: 'hidden',
      overflowY: 'auto',
      width: 480,
      maxWidth: '100%',
    },
    dialogActions: {
      padding: 24,
    },
  })),
)(EditLoadBalancingDialog);
