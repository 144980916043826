import React, { useEffect, useMemo, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography,
  AlertBanner,
  CSButton,
  Box,
} from '../primitives';
import ManifestsServices from '../../services/Manifests.service';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import Layout from '../layout/Layout';
import ErrorHandler from '../../utils/ErrorHandler';
import { humanReadableNull } from '../DetailsPagesFunctions';
import ConfirmationDialog from '../confirmationDialog/ConfirmationDialog';
import {
  GET_COLUMNS_PACKAGES,
  fetch as fetchPackages,
} from '../packages/Packages';
import configurationUtils from '../../utils/configurationUtils';
import PaginatedTable, {
  filterObj,
} from '../paginatedTable/PaginatedTable';
import { AuthRoutes } from '../../interfaces/routes';
import { Column } from '../../interfaces/components';
import SingleDetail from '../primitives/singleDetail/SingleDetail';
import { common } from '../../utils/strings';
import { createStyles, Grid, Theme } from '@material-ui/core';
import { getDetailsPageStylesWithTheme } from '../commonStyles/detailsPage.style';
import {
  ManifestSerializerDetails,
  ManifestSerializerDetailsStatusEnum,
  StaffPermissionPermissionsEnum,
} from 'cloudsort-client';
import { match } from 'react-router-dom';
import { AxiosError } from 'axios';
import ContainersService from '../../services/Containers.service';
import PermissionsService from '../../services/Permissions.service';
import EphemeralStateService from '../../services/EphemeralState.service';
import { Helmet } from 'react-helmet';
import { formatContainer } from '../containers/Containers';
import CSSectionTitleSeparator from '../primitives/csSectionTitleSeparator/CSSectionTitleSeparator';
import CSBreadcrumbs from '../primitives/CSBreadcrumbs/CSBreadcrumbs';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { CSSingleDetailMultiColumnContainer } from '../primitives/singleDetail/singleDetailMultiColumnContainer';
import CSTimeContainer from '../primitives/csTimeContainer/CSTimeContainer';
import useStationId from '../../hooks/useStationId';
import PackagesCountVolumeBlock from './PackagesCountVolumeBlock';
import InboundLoadsService from '../../services/InboundLoads.service';
import useFormatILTable from '../inboundLoads/useFormatILTable';
import SingleRowStats, {
  StatData,
} from '../singleRowStats/SingleRowStats';

export const formatLabel = (str?: string) => {
  const label = str
    ? str
        .replace('_', ' ')
        .split(' ')
        .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
        .join(' ')
    : '';
  return label;
};

interface Props {
  classes: { [key: string]: string };
  match: match;
}

const ManifestsDetailsComponent: React.FC<Props> = ({
  classes,
  match,
}) => {
  const [manifestsData, setManifestsData] =
    useState<ManifestSerializerDetails>();
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  // It's possible for a user to open manifest details page without station id in the url.
  //In this case we need to get it from the manifest data and set it in the url. The web app (navigation bar) will use it to display the station name and show the correct navigation menu.
  useStationId(Number(manifestsData?.station));
  const [lastUpdated, setLastUpdated] = useState<string>(
    new Date().toISOString(),
  );

  const packageLabels = useMemo(() => {
    return {
      singular: configurationUtils.getPageTitle(true, 'PACKAGE'),
      plural: configurationUtils.getPageTitle(false, 'PACKAGE'),
    };
  }, []);

  const containerLabels = useMemo(() => {
    return {
      singular: configurationUtils.getPageTitle(true, 'CONTAINER'),
      plural: configurationUtils.getPageTitle(false, 'CONTAINER'),
    };
  }, []);

  const ilLabels = useMemo(() => {
    return {
      singular: configurationUtils.getPageTitle(true, 'INBOUND_LOAD'),
      plural: configurationUtils.getPageTitle(false, 'INBOUND_LOAD'),
    };
  }, []);

  const manifestLabels = useMemo(() => {
    return {
      singular: configurationUtils.getPageTitle(true, 'MANIFEST'),
      plural: configurationUtils.getPageTitle(false, 'MANIFEST'),
    };
  }, []);

  // Get labels on each render cycle
  const COLUMNS_CONTAINERS: Column[] = [
    {
      id: 'id',
      label: 'ID',
      width: 50,
      align: 'left',
    },
    {
      id: 'critical_activity_time',
      label: 'Critical Time',
      align: 'center',
      width: 'left',
    },
    {
      id: 'tracking_number',
      label: 'Tracking Number',
      width: 'auto',
      align: 'center',
    },
    {
      id: 'type',
      label: 'Type',
      width: 200,
      align: 'left',
      useCustomComponent: true,
    },
    {
      id: 'display_status',
      label: 'Status',
      width: 'auto',
      align: 'left',
      useCustomComponent: true,
    },
    {
      id: 'dwell_time',
      label: 'Dwell time',
      align: 'center',
      width: 'auto',
    },
    {
      id: 'package_count',
      label: packageLabels.plural,
      width: 'auto',
      align: 'left',
    },
    {
      id: 'volume',
      label: 'Volume',
      width: 'auto',
      align: 'left',
    },
    {
      id: 'container_type',
      label: `Type of ${configurationUtils.getModuleLabel(
        true,
        'CONTAINER',
      )}`,
      width: 'auto',
      align: 'left',
    },
    {
      id: 'location',
      label: 'Location',
      align: 'center',
      width: 'left',
    },
  ];

  const COLUMNS_INBOUND_LOADS: Column[] = [
    {
      id: 'inbound_load_time_formatted',
      label: 'Date',
      useCustomComponent: true,
    },
    {
      id: 'load_status_formatted',
      label: 'Status',
      useCustomComponent: true,
    },
    {
      id: 'name',
      label: 'Load ID',
    },
    {
      id: 'bol',
      label: 'BOL',
    },
    {
      id: 'origin_station',
      label: 'Origin',
    },
    {
      id: 'trailer_id',
      label: 'Trailer ID',
    },
    {
      id: 'owner_full_name',
      label: 'Owner',
    },
    {
      id: 'expected_containers_at_arrival',
      label: configurationUtils.getPageTitle(false, 'CONTAINER'),
    },
    {
      id: 'expected_packages_at_arrival',
      label: configurationUtils.getPageTitle(false, 'PACKAGE'),
    },
    {
      id: 'dockdoor_name',
      label: 'Unload Door',
    },
  ];

  const showArrivalPrediction = useMemo(() => {
    return EphemeralStateService.getMyStationConfiguratation().GENERAL
      .BEHAVIORS.ML_INTEGRATION.arrival_prediction;
  }, []);

  const calculatePercentage = (
    arrived: number,
    predicted: number,
  ) => {
    if (arrived === predicted) return 100;
    return (arrived / predicted) * 100 || 0;
  };

  const getManifestsData = async () => {
    setShowProgress(true);
    try {
      const { data } = await ManifestsServices.getById(
        (match.params as any).id,
      );
      setManifestsData(data);
    } catch (e) {
      handleError(e as AxiosError);
    }
    setShowProgress(false);
  };

  const markAsArrived = async () => {
    setShowProgress(true);
    try {
      const dataObj: ManifestSerializerDetails = { ...manifestsData };
      dataObj.status = ManifestSerializerDetailsStatusEnum.ARRIVED;
      const { data } = await ManifestsServices.update(
        manifestsData!.id!,
        dataObj,
      );
      setManifestsData(data);
      setTimeout(() => {
        setLastUpdated(new Date().toISOString());
      }, 2000);
    } catch (e) {
      handleError(e as AxiosError);
    }
    setShowProgress(false);
  };

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e as AxiosError));
  };

  useEffect(() => {
    PermissionsService.redirectIfNoPermission(
      StaffPermissionPermissionsEnum.MANIFEST_READ,
    );

    if (!manifestsData) {
      getManifestsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchContainers = async (
    pageIndex: number,
    rowsPerPage: number,
  ) => {
    const res = await ContainersService.getAll({
      page: pageIndex,
      manifest: [(match.params as any).id],
      pageSize: rowsPerPage,
      fromDate: '',
      toDate: '',
    });

    res.data.results.forEach((container: any) => {
      formatContainer(container);
    });
    return res;
  };

  const { formatIlItem } = useFormatILTable();

  const fetchInboundLoads = async (
    pageIndex: number,
    rowsPerPage: number,
    filterOptions?: filterObj[],
    filterByString?: string,
  ) => {
    const res = await InboundLoadsService.getAll({
      page: pageIndex,
      manifest: (match.params as any).id,
      pageSize: rowsPerPage,
      fromDate: '',
      toDate: '',
      loadName: filterByString,
    });
    res.data.results.forEach((il) => {
      formatIlItem(il);
    });

    return res;
  };

  const getCountStats = async () => {
    const data: StatData[] = [];

    if (configurationUtils.isModuleActive('INBOUND_LOAD')) {
      data.push({
        label: ilLabels.plural,
        value:
          manifestsData?.total_loads?.toString() || common.emptyValue,
      });
    }

    if (configurationUtils.isModuleActive('CONTAINER')) {
      data.push({
        label: containerLabels.plural,
        value:
          manifestsData?.total_containers?.toString() ||
          common.emptyValue,
      });
    }

    if (configurationUtils.isModuleActive('PACKAGE')) {
      data.push({
        label: packageLabels.plural,
        value:
          manifestsData?.total_packages?.toString() ||
          common.emptyValue,
      });
    }

    return data;
  };

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort - ${manifestLabels.singular} ${
            manifestsData?.id || ''
          }`}
        </title>
      </Helmet>
      <Layout navCurrent='MANIFEST'>
        {showProgress && <ProgressIndicator />}
        {error && (
          <AlertBanner
            data-testid={'manifests-detail-error-banner'}
            className={classes.banner}
            severity='error'
            alertTitle={'Error'}
            alertMsg={error}
          />
        )}
        <ConfirmationDialog
          data-testid={'manifests-detail-confirmation-dialog'}
          title={'Mark as Arrived'}
          msg={
            <Typography
              component='span'
              variant='body1'
              color={{
                color: 'grey',
                variant: 'A400',
              }}
            >
              {`Are you sure you want to mark ${manifestLabels.singular} ${manifestsData?.id} as arrived?`}
            </Typography>
          }
          primaryActionLabel='Confirm'
          onPrimaryAction={() => {
            setShowDialog(false);
            markAsArrived();
          }}
          onCancel={() => {
            setShowDialog(false);
          }}
          isOpen={showDialog}
        />

        <Grid container>
          <Grid item xs={12} className={classes.breadcrumbsContainer}>
            <Box>
              <CSBreadcrumbs
                breadcrumbs={[
                  {
                    label: manifestLabels.plural,
                    link:
                      AuthRoutes.MANIFEST +
                      '?stationId=' +
                      manifestsData?.station,
                  },
                  {
                    label: `
                    ${manifestLabels.singular} ${
                      manifestsData?.id || ''
                    }`,
                    selected: true,
                  },
                ]}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography component='h1' variant='h3'>
              {`${manifestLabels.singular} ${
                manifestsData?.id || ''
              }`}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            className={classes.markAsArrivedButtonContainer}
          >
            {PermissionsService.hasPermission(
              StaffPermissionPermissionsEnum.MANIFEST_WRITE,
            ) &&
              manifestsData?.inbound &&
              manifestsData.status !==
                ManifestSerializerDetailsStatusEnum.ARRIVED && (
                <CSButton
                  variant='contained'
                  color={{
                    buttonColor: 'secondary',
                    iconColor: 'primary',
                  }}
                  style={{ width: 'auto' }}
                  disabled={!manifestsData}
                  onClick={(e) => {
                    e.preventDefault();
                    setShowDialog(true);
                  }}
                  startIcon={<CheckCircleOutlineIcon />}
                >
                  Mark As Arrived
                </CSButton>
              )}
          </Grid>
        </Grid>
        <CSSectionTitleSeparator topMargin={16} bottomMargin={20} />
        <Grid container className={classes.manifestSummaryContainer}>
          <Grid
            item
            xs={12}
            sm={6}
            md={8}
            className={classes.containerMetadataContainer}
          >
            <CSSingleDetailMultiColumnContainer
              data-testid='manifests-detail-view'
              elements={[
                <SingleDetail
                  key='status'
                  inline={true}
                  label='Status'
                  value={humanReadableNull(manifestsData?.status)}
                />,
                <SingleDetail
                  key='Creation Date'
                  inline={true}
                  label='Creation Date'
                  value={
                    manifestsData?.created_on
                      ? new Date(
                          manifestsData?.created_on,
                        ).toLocaleDateString()
                      : common.emptyValue
                  }
                />,
                <SingleDetail
                  key='owner'
                  inline={true}
                  label='Owner'
                  value={humanReadableNull(
                    manifestsData?.owner_full_name,
                  )}
                />,
                <SingleDetail
                  key='Carrier'
                  inline={true}
                  label='Carrier'
                  value={humanReadableNull(
                    manifestsData?.fmc_full_name,
                  )}
                />,
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CSTimeContainer
              elements={[
                {
                  title: 'Received On',
                  content: manifestsData?.received_on
                    ? new Date(
                        manifestsData?.received_on,
                      ).toLocaleTimeString('en-US', {
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true,
                      })
                    : common.emptyValue,
                },
              ]}
            />
          </Grid>
        </Grid>
        {showArrivalPrediction &&
          configurationUtils.isModuleActive('PACKAGE') && (
            <Grid
              container
              spacing={2}
              className={classes.marginBottom20}
            >
              <Grid item xs={12} md={4}>
                <PackagesCountVolumeBlock
                  title={'Manifested ' + packageLabels.plural}
                  packages={manifestsData?.total_packages}
                  volume={manifestsData?.total_volume}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <PackagesCountVolumeBlock
                  title={'Predicted ' + packageLabels.plural}
                  packages={manifestsData?.predicted_arrival_packages}
                  volume={manifestsData?.predicted_arrival_volume}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <PackagesCountVolumeBlock
                  title={'Arrived ' + packageLabels.plural}
                  packages={manifestsData?.total_arrived_packages}
                  volume={manifestsData?.total_arrived_volume}
                  showPercentage
                  packagesPercentage={calculatePercentage(
                    manifestsData?.total_arrived_packages || 0,
                    manifestsData?.predicted_arrival_packages || 0,
                  )}
                  volumePercentage={calculatePercentage(
                    manifestsData?.total_arrived_volume || 0,
                    manifestsData?.predicted_arrival_volume || 0,
                  )}
                />
              </Grid>
            </Grid>
          )}
        {(configurationUtils.isModuleActive('PACKAGE') ||
          configurationUtils.isModuleActive('CONTAINER') ||
          configurationUtils.isModuleActive('INBOUND_LOAD')) && (
          <Grid item xs={12} className={classes.marginBottom20}>
            <SingleRowStats
              link_base={''}
              dataTestId='held-packages-list-stats'
              equalColumns
              disableActiveMarker
              fetch={getCountStats}
            />
          </Grid>
        )}
        {configurationUtils.isModuleActive('INBOUND_LOAD') &&
          PermissionsService.hasPermission(
            StaffPermissionPermissionsEnum.INBOUNDLOAD_READ,
          ) && (
            <div className={classes.marginBottom20}>
              <PaginatedTable
                key={lastUpdated}
                disableUpdateQueryStringUrl
                title={ilLabels.plural}
                columns={COLUMNS_INBOUND_LOADS}
                dataTestIdPrefix={'load-details-inbound-loads'}
                fetch={fetchInboundLoads}
                filterByString={true}
                filterByStringPlaceholder={'Search by Load ID'}
                rowsLoadDetailPages={true}
                detailsPageBasePath={AuthRoutes.INBOUND_LOAD}
              />
            </div>
          )}
        {configurationUtils.isModuleActive('PACKAGE') &&
          PermissionsService.hasPermission(
            StaffPermissionPermissionsEnum.PACKAGE_READ,
          ) && (
            <PaginatedTable
              key={lastUpdated}
              disableUpdateQueryStringUrl
              title={packageLabels.plural}
              columns={GET_COLUMNS_PACKAGES()}
              dataTestIdPrefix={'load-details-packages'}
              fetch={(
                pageIndex: number,
                rowsPerPage: number,
                filterOptions?: filterObj[],
                filterByString?: string,
                sortedBy?: string,
              ) => {
                return fetchPackages(
                  pageIndex,
                  rowsPerPage,
                  {}, // no filters
                  sortedBy,
                  {
                    manifest: [(match.params as any).id],
                    fromDate: '',
                    toDate: '',
                  } as any,
                );
              }}
              rowsLoadDetailPages={true}
              detailsPageBasePath={AuthRoutes.PACKAGE}
            />
          )}

        {configurationUtils.isModuleActive('CONTAINER') &&
          PermissionsService.hasPermission(
            StaffPermissionPermissionsEnum.CONTAINER_READ,
          ) && (
            <div className={classes.containersTableContainer}>
              <PaginatedTable
                key={lastUpdated}
                disableUpdateQueryStringUrl
                title={containerLabels.plural}
                columns={COLUMNS_CONTAINERS}
                dataTestIdPrefix={'load-details-containers'}
                fetch={fetchContainers}
                rowsLoadDetailPages={true}
                detailsPageBasePath={AuthRoutes.CONTAINER}
              />
            </div>
          )}
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...getDetailsPageStylesWithTheme(theme),
    breadcrumbsContainer: {
      marginBottom: '54px',
      [theme.breakpoints.down('sm')]: {
        marginBottom: '20px',
      },
    },
    markAsArrivedButtonContainer: {
      textAlign: 'right',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'left',
      },
    },
    manifestSummaryContainer: {
      marginBottom: '28px',
      maxHeight: '109px',
      [theme.breakpoints.down('sm')]: {
        maxHeight: 'none',
      },
    },
    containerMetadataContainer: {
      paddingRight: '15px',
      [theme.breakpoints.down('sm')]: {
        paddingRight: '0px',
        paddingBottom: '15px',
      },
    },
    containersTableContainer: {
      paddingTop: '20px',
    },
    marginBottom20: {
      marginBottom: 20,
    },
  })),
)(ManifestsDetailsComponent);
