import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import styles from './PaginatedActions.styles';
import { PaginatedActionsProps } from './PaginatedActionsTypes';
import { TablePaginationActionsProps } from '@material-ui/core/TablePagination/TablePaginationActions';
import { CSButton } from '../../primitives';

const PaginatedActions: React.FC<TablePaginationActionsProps> = ({
  count,
  page,
  rowsPerPage,
  onPageChange,
}: PaginatedActionsProps) => {
  const classes = styles();
  const theme = useTheme();
  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange?.(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange?.(event, page + 1);
  };

  return (
    <div className={classes.root}>
      <CSButton
        className={classes.paginationButton}
        variant='text'
        onClick={handleBackButtonClick}
        disabled={page === 0}
        color={{
          buttonColor: 'quaternary',
          iconColor: 'grey',
          iconColorVariant: 'A700',
        }}
        aria-label='previous page'
        title='Previous page'
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </CSButton>
      <CSButton
        className={classes.paginationButton}
        variant='text'
        color={{
          buttonColor: 'quaternary',
          iconColor: 'grey',
          iconColorVariant: 'A700',
        }}
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'
        title='Next page'
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </CSButton>
    </div>
  );
};

export default PaginatedActions;
