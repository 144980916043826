type ParseNumericInput = {
  newValueFromInput: string;
  currentValue: number | null | undefined;
  minimumValue?: number;
};

export const parseNumericInput = ({
  newValueFromInput,
  currentValue,
  minimumValue = 0,
}: ParseNumericInput) => {
  //The input field has been cleared
  if (newValueFromInput !== '0' && !Boolean(newValueFromInput)) {
    return undefined;
  }
  //Check if the value is a number
  const newValue = Number(newValueFromInput);
  if (!isNaN(newValue) && newValue >= minimumValue) {
    return newValue;
  }
  // The new value is not a number, return the old value.
  return currentValue;
};

//Converts a number into a string value. If the input is not a number returns an empty string.
export const numberToString = (
  value: number | null | undefined,
): string => {
  if (value === null || value === undefined || isNaN(value)) {
    return '';
  }
  return value.toString();
};
