import {
  createStyles,
  Tab,
  Theme,
  withStyles,
} from '@material-ui/core';
import * as React from 'react';

interface Props {
  classes: { [key: string]: string };
  label: string;
  // All other props
  [x: string]: any;
}

const CSTab: React.FC<Props> = ({ classes, label, ...props }) => {
  return (
    <Tab
      {...props}
      label={label}
      classes={{
        wrapper: classes.tabWrapper,
        selected: classes.selectedTopMenuTab,
        root: classes.headerTabsRoot,
      }}
      disableRipple
    />
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    tabWrapper: {
      fontSize: '20px',
      textTransform: 'capitalize',
      padding: '12px 0px',
      [theme.breakpoints.down('md')]: {
        fontSize: '14px',
        padding: '0px 12px',
      },
    },
    selectedTopMenuTab: {
      [theme.breakpoints.down('md')]: {
        backgroundColor: theme.palette.background.paper,
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: 6,
      },
      [theme.breakpoints.up('lg')]: {
        textDecoration: 'underline',
        textDecorationThickness: '3px',
        textDecorationColor: theme.palette.primary.main,
        textUnderlineOffset: '7px',
      },
    },
    headerTabsRoot: {
      minWidth: '0px',
    },
  })),
)(CSTab);
