import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';

import { createStyles, Theme } from '@material-ui/core';

//Flow
import ReactFlow, { Node, Position } from 'react-flow-renderer';
import { SmartEdge } from '@tisoap/react-flow-smart-edge';

import PrimaryAreaNode from './nodeTypes/PrimaryAreaNode';
import GhostNode from './nodeTypes/GhostNode';
import flowColors from './flowColors';
import SecondaryAreaNode from './nodeTypes/SecondaryAreaNode';
import StagingAreaNode from './nodeTypes/StagingAreaNode';
import DockAreaNode from './nodeTypes/DockAreaNode';
import {
  filterOutFlowData,
  findFlowMaximumValue,
  generateGhostNode,
  generateNodes,
} from './flowUtils';
import { MappedElement } from '../StationLayout';
import DataAnalytycsService from '../../../services/DataAnalytycs.service';

import { NodeWithData } from './interfaces';
import TimePeriodSelector from './TimePeriodSelector';
import ProgressIndicator from '../../progressIndicator/ProgressIndicator';
import colors from '../../../utils/colors';
import { FlowPackageCount } from 'cloudsort-client';
import { CSSwitch } from '../../primitives';

interface Props {
  classes: { [key: string]: string };
  mappedAreas: MappedElement[];
  mappedZones: MappedElement[];
  mappedLoadpoints: MappedElement[];
  mappedDockdoors: MappedElement[];
}

const Flow: React.FC<Props> = ({
  classes,
  mappedAreas,
  mappedZones,
  mappedLoadpoints,
  mappedDockdoors,
}) => {
  const [availableElements, setAvailableNodes] = useState<Node[]>([]);
  const [observedDuration, setObservedDuration] =
    useState<number>(60);
  const [isThroughputMode, setIsThroughputMode] =
    useState<boolean>(false);
  const [showProgress, setShowProgress] = useState<boolean>(false);

  const getEdgeClassName = (current: number, max: number): string => {
    const ratio = current / max;
    if (ratio > 0.75) {
      return classes.edgeClass4;
    } else if (ratio > 0.5) {
      return classes.edgeClass3;
    } else if (ratio > 0.25) {
      return classes.edgeClass2;
    } else {
      return classes.edgeClass1;
    }
  };

  const loadData = async (
    duration: number = observedDuration,
    showEdges: boolean = isThroughputMode,
  ) => {
    //Show Loader
    setShowProgress(true);

    let flowMax = 0;
    let flow: FlowPackageCount[] | undefined = undefined;

    if (showEdges) {
      // Fetch flow
      flow = filterOutFlowData(
        (
          await DataAnalytycsService.getPackageFlow(
            undefined,
            duration,
          )
        ).data.data || [],
      );

      flowMax = findFlowMaximumValue(flow);
    }

    //Get packages for LPs and exit packages for Areas (by event type)
    const exitPackageCounts = (
      await DataAnalytycsService.getPackageCountsForEventTypes(
        undefined,
        duration,
        ['PRIMARY', 'CONTAINERIZED'],
      )
    ).data;

    //Get current package counts -- exit packages excluded
    const currentPackageCountsWithExitPackagesExcluded = (
      await DataAnalytycsService.getPackageCounts(
        new Date().toISOString(),
        [
          'MANIFESTED_INBOUND',
          'DISPATCHED',
          'MANIFESTED_OUTBOUND',
          'EXCEPTION_UNPROCESSABLE',
          'PRIMARY',
          'CONTAINERIZED',
        ],
      )
    ).data.data;

    //Get current package counts
    const currentPackageCounts = (
      await DataAnalytycsService.getPackageCounts(
        new Date().toISOString(),
        [
          'MANIFESTED_INBOUND',
          'DISPATCHED',
          'MANIFESTED_OUTBOUND',
          'EXCEPTION_UNPROCESSABLE',
        ],
      )
    ).data.data;

    //Get dispatch times and volume
    const dispatchTimes = (
      await DataAnalytycsService.getDispatchTimes()
    ).data;

    const nodesData = generateNodes(
      mappedAreas,
      mappedZones,
      mappedLoadpoints,
      mappedDockdoors,
      flowMax,
      currentPackageCounts!,
      exitPackageCounts,
      showEdges ? undefined : 1,
      dispatchTimes,
      showEdges,
    );

    let { data: nodes, max: ppmMax } = nodesData;

    //Areas
    mappedAreas.forEach((element: MappedElement) => {
      let count = isThroughputMode
        ? currentPackageCountsWithExitPackagesExcluded?.areas?.find(
            (area) => area.area_id === element.id,
          )
        : currentPackageCounts?.areas?.find(
            (area) => area.area_id === element.id,
          );
      if (count) {
        nodes = nodes.map((node) =>
          node.id === 'AREA_' + element.id
            ? ({
                ...node,
                data: {
                  ...node.data,
                  packages: count?.accumulated_count,
                },
              } as NodeWithData)
            : node,
        );
      }
    });
    //Dock doors
    mappedDockdoors.forEach((element: MappedElement) => {
      let count = isThroughputMode
        ? currentPackageCountsWithExitPackagesExcluded?.dockdoors?.find(
            (dd) => dd.dockdoor_id === element.id,
          )
        : currentPackageCounts?.dockdoors?.find(
            (dd) => dd.dockdoor_id === element.id,
          );
      if (count) {
        nodes = nodes.map((node) =>
          node.id === 'DOCKDOOR_' + element.id
            ? ({
                ...node,
                data: {
                  ...node.data,
                  packages: count?.count,
                },
              } as NodeWithData)
            : node,
        );
      }
    });

    const ghostNodes: Node[] = [];
    let edges = [];

    if (showEdges) {
      //Create edges and ghost nodes

      //Determinate handle positions for the nodes
      const findHandlePosition = (from: any, to: any): Position => {
        const xDiff = from.x - to.x;
        const yDiff = from.y - to.y;

        if (Math.abs(xDiff) > Math.abs(yDiff)) {
          return xDiff > 0 ? Position.Right : Position.Left;
        } else {
          return yDiff > 0 ? Position.Bottom : Position.Top;
        }
      };

      nodes = nodes.map((node) => {
        let inHandlePosition = undefined;
        const nodeThatLinksToCurrentNode: any = flow?.find(
          (flowItem: any) =>
            flowItem.to_location[node.data?.entityType + '_id'] ===
            node.data?.entityId,
        );

        if (nodeThatLinksToCurrentNode) {
          const inNode = nodes.find(
            (node) =>
              node.data?.entityId ===
              nodeThatLinksToCurrentNode.from_location[
                node.data?.entityType + '_id'
              ],
          );
          inHandlePosition = findHandlePosition(
            {
              x: inNode?.position.x,
              y: inNode?.position.y,
              node: inNode?.data?.name,
            },
            {
              x: node.position.x,
              y: node.position.y,
              node: node.data?.name,
            },
          );
        }

        let outHandlePosition = undefined;
        const nodeThatCurrentNodeLinksTo: any = flow?.find(
          (flowItem: any) =>
            flowItem.from_location[node.data?.entityType + '_id'] ===
            node.data?.entityId,
        );

        if (nodeThatCurrentNodeLinksTo) {
          const outNode = nodes.find(
            (node) =>
              node.data?.entityId ===
              nodeThatCurrentNodeLinksTo.to_location[
                node.data?.entityType + '_id'
              ],
          );
          outHandlePosition = findHandlePosition(
            {
              x: outNode?.position.x,
              y: outNode?.position.y,
              node: outNode?.data?.name,
            },
            {
              x: node.position.x,
              y: node.position.y,
              node: node.data?.name,
            },
          );
        }

        return {
          ...node,
          targetPosition: node.targetPosition || inHandlePosition,
          sourcePosition: node.sourcePosition || outHandlePosition,
        };
      });

      //Add ghost nodes (for forks and joins)

      nodes.forEach((node) => {
        const from =
          flow?.filter((item: any) => {
            return (
              item.from_location[node.data?.entityType + '_id'] ===
              node.data?.entityId
            );
          }) || [];

        const to =
          flow?.filter((item: any) => {
            return (
              item.to_location[node.data?.entityType + '_id'] ===
              node.data?.entityId
            );
          }) || [];

        if (from.length > 1) {
          // We need to add output fork ghost node
          ghostNodes.push(generateGhostNode(node, 'OUT'));
        }

        if (to.length > 1) {
          // We need to add input fork ghost node
          ghostNodes.push(generateGhostNode(node, 'IN'));
        }
      });

      // Add edges
      edges =
        flow
          ?.map((item, index: number) => {
            const linksToArea = !!item.to_location?.area_id;

            const sourcePrefix = ghostNodes.find(
              (node: any) =>
                node.id === 'OUT_AREA_' + item.from_location?.area_id,
            )
              ? 'OUT_'
              : '';

            const targetPrefix = ghostNodes.find((node: any) => {
              if (linksToArea)
                return (
                  node.id === 'IN_AREA_' + item.to_location?.area_id
                );
              else
                return (
                  node.id ===
                  'IN_DOCKDOOR_' + item.to_location?.dockdoor_id
                );
            })
              ? 'IN_'
              : '';

            return {
              id: 'EDGE_' + index,
              type: 'smart',
              source:
                sourcePrefix + 'AREA_' + item.from_location?.area_id, // source is always AREA
              target: linksToArea
                ? targetPrefix + 'AREA_' + item.to_location?.area_id
                : targetPrefix +
                  'DOCKDOOR_' +
                  item.to_location?.dockdoor_id, // target can be AREA or DD
              label: item.accumulated_count + ' P',
              ...edgeLabelStyles,
              className: getEdgeClassName(
                item.accumulated_count || 0,
                ppmMax,
              ),
            };
          })
          .filter((edge) => !edge.source.includes('_null')) || [];

      // Let's add short edges (edges between main nodes and ghost nodes -- forks)

      ghostNodes.forEach((node, index) => {
        if (node.id.includes('IN')) {
          //it's an input node
          const originalElement = node.id.substring(3);
          const [originalElementType, originalElementId] =
            originalElement.split('_');

          const labelValue = flow?.reduce((acc, item: any) => {
            if (
              item.to_location[
                originalElementType.toLowerCase() + '_id'
              ] === Number(originalElementId)
            ) {
              return acc + item.accumulated_count;
            }
            return acc;
          }, 0);

          edges.push({
            id: 'FORK_EDGE_' + index,
            source: node.id,
            target: originalElement,
            label: labelValue + ' P',
            ...edgeLabelStyles,
            className: getEdgeClassName(labelValue || 0, ppmMax),
          });
        } else {
          //it's an output node
          const originalElement = node.id.substring(4);
          const [originalElementType, originalElementId] =
            originalElement.split('_');

          const labelValue = flow?.reduce((acc, item: any) => {
            if (
              item.from_location[
                originalElementType.toLowerCase() + '_id'
              ] === Number(originalElementId)
            ) {
              return acc + item.accumulated_count;
            }
            return acc;
          }, 0);

          edges.push({
            id: 'FORK_EDGE_' + index,
            source: originalElement,
            target: node.id,
            label: labelValue,
            ...edgeLabelStyles,
            className: getEdgeClassName(labelValue || 0, ppmMax),
          });
        }
      });
    }

    // Let's store everything to the state to be used as ReactFlowElements
    setAvailableNodes([...nodes, ...ghostNodes, ...edges]);

    //Hide loader
    setShowProgress(false);
  };

  useEffect(() => {
    if (
      mappedAreas &&
      mappedZones &&
      mappedLoadpoints &&
      mappedDockdoors
    ) {
      //get all data
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mappedAreas, mappedZones, mappedLoadpoints]);

  const edgeLabelStyles: any = {
    labelStyle: {
      fill: flowColors.black,
      fontSize: '11px',
      strokeWidth: '0',
    },
    labelBgStyle: {
      stroke: flowColors.secondaryBorder,
      fill: flowColors.white,
      strokeWidth: '1px',
    },
    labelBgPadding: [5, 2.5],
    labelBgBorderRadius: 5,
  };

  return (
    <>
      {showProgress && <ProgressIndicator />}
      <ReactFlow
        nodesDraggable={false}
        nodesConnectable={false}
        elements={availableElements}
        style={{ width: '100%', height: 'calc(100% - 69px)' }}
        nodeTypes={{
          primaryArea: PrimaryAreaNode,
          secondaryArea: SecondaryAreaNode,
          stagingArea: StagingAreaNode,
          dockArea: DockAreaNode,
          ghost: GhostNode,
        }}
        edgeTypes={{
          smart: SmartEdge,
        }}
      />
      <div className={classes.legend}>
        <div>
          {isThroughputMode && (
            <div>
              <span
                className={classes.legendLine}
                style={{ backgroundColor: flowColors.edgeBlue1 }}
              ></span>
              <span
                className={classes.legendLine}
                style={{ backgroundColor: flowColors.edgeBlue2 }}
              ></span>
              <span
                className={classes.legendLine}
                style={{ backgroundColor: flowColors.edgeBlue3 }}
              ></span>
              <span
                className={classes.legendLine}
                style={{ backgroundColor: flowColors.edgeBlue4 }}
              ></span>
              <br />
              <span
                className={classes.legendNumber}
                style={{ marginRight: 21 }}
              >
                0%
              </span>
              <span
                className={classes.legendNumber}
                style={{ marginRight: 27 }}
              >
                25%
              </span>
              <span
                className={classes.legendNumber}
                style={{ marginRight: 24 }}
              >
                50%
              </span>
              <span
                className={classes.legendNumber}
                style={{ marginRight: 20 }}
              >
                75%
              </span>
              <span className={classes.legendNumber}>100%</span>
            </div>
          )}
        </div>
        <div>
          <div style={{ display: 'inline-block', marginRight: 10 }}>
            {isThroughputMode && (
              <TimePeriodSelector
                observedDuration={observedDuration}
                osSetObservedDuration={(minutes) => {
                  setObservedDuration(minutes);
                  loadData(minutes);
                }}
                onRefresh={() => {
                  loadData();
                }}
              />
            )}
            <CSSwitch
              checked={isThroughputMode}
              color='default'
              onClick={() => {
                setIsThroughputMode(!isThroughputMode);
                loadData(undefined, !isThroughputMode);
              }}
            />
            Throughput
          </div>
        </div>
      </div>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    edgeClass1: {
      '& path': {
        strokeWidth: 8,
        stroke: flowColors.edgeBlue1,
      },
    },
    edgeClass2: {
      '& path': {
        strokeWidth: 8,
        stroke: flowColors.edgeBlue2,
      },
    },
    edgeClass3: {
      '& path': {
        strokeWidth: 8,
        stroke: flowColors.edgeBlue3,
      },
    },
    edgeClass4: {
      '& path': {
        strokeWidth: 8,
        stroke: flowColors.edgeBlue4,
      },
    },
    legend: {
      height: '69px',
      padding: '0 20px',
      borderTop: `1px solid ${flowColors.edgeGray}`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    legendLine: {
      display: 'inline-block',
      width: '50px',
      height: '10px',
      marginRight: 3,
    },
    switchBase: {
      '&.Mui-checked + .MuiSwitch-track': {
        backgroundColor: colors.gold,
        opacity: 1,
      },
    },
  })),
)(Flow);
