import React, { useEffect, useState } from 'react';
import {
  AuthRoutes,
  getModuleUrl,
  ModulesKeys,
  stationDashboardUrlId,
} from '../../interfaces/routes';
import browserHistory from '../../utils/browserHistory';
import { MenuList } from '@material-ui/core';
import styles from './navigation.styles';
import { withStyles } from '@material-ui/core/styles';
import ErrorHandler from '../../utils/ErrorHandler';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import configurationUtils from '../../utils/configurationUtils';
import globalStationOptionsUtils from '../../utils/globalStationOptionsUtils';
import {
  NavItems,
  SELECTOR_ENTITY_TYPE,
  TOP_LEVEL_NAV_ITEM,
} from './types';
import EntitySelector from './StationSelector';
import Divider from '@material-ui/core/Divider';

import TopLevelNavItem from './TopLevelNavItem';
import queryString from 'query-string';

// Icons
import AppsIcon from '@material-ui/icons/Apps';
import LayersOutlinedIcon from '@material-ui/icons/LayersOutlined';
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import SettingsIcon from '@material-ui/icons/Settings';

// Services
import LocalStorageService from '../../services/LocalStorage.service';
import PermissionsService from '../../services/Permissions.service';
import EphemeralStateService from '../../services/EphemeralState.service';

// Types
import {
  Station,
  StaffPermissionPermissionsEnum,
  Organization,
} from 'cloudsort-client';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import {
  navigationConfig,
  setSelectedEntity,
  setSholdUpdateDynamicRoutes,
  setShouldUpdateUrl,
  setShouldUpdateStationsData,
} from '../../redux/slices/navigationSlice';
import { useLocation, useParams } from 'react-router-dom';
import { configurationUrlParams } from '../configuration/utils';
import StationsService from '../../services/Stations.service';
import OrganizationsService from '../../services/Organizations.service';
import { MAX_PAGE_SIZE } from '../../services/utils/constants';
import StationNavigationMenu from './StationNavigationMenu';
import { AxiosError } from 'axios';

interface Props {
  classes: { [key: string]: string };
  currentPage?: string;
}

const Navigation: React.FC<Props> = ({ classes, currentPage }) => {
  const [stationsData, setStationsData] = useState<Station[]>();
  const [orgData, setOrgData] = useState<Organization[]>([]);
  const [showProgress, setShowProgress] = useState<boolean>(false);

  const {
    selectedEntityId,
    selectedEntityType,
    shouldUpdateUrl,
    shouldUpdateStationsData,
    lastVisitedModule,
    lastVisitedModulePermissionNeeded,
  } = useAppSelector(navigationConfig);
  const dispatch = useAppDispatch();

  const [navItems, setNavItems] = useState<NavItems[]>([]);

  const isConfigurationPage =
    browserHistory.location.pathname.includes(
      AuthRoutes.CONFIGURATION,
    );

  const fetchNavItems = () => {
    setNavItems(
      configurationUtils.getConfigArray({
        config:
          EphemeralStateService.getMyStationConfiguratation()?.WEB
            .NAV_MENU,
        filter: { key: 'active', value: true },
        sortBy: 'order',
      }),
    );
  };

  const fetchOrgData = async () => {
    if (
      PermissionsService.hasPermission(
        StaffPermissionPermissionsEnum.MASTER_READ,
      ) ||
      PermissionsService.hasPermission(
        StaffPermissionPermissionsEnum.ORGANIZATION_READ,
      )
    ) {
      try {
        const {
          data: { results },
        } = await OrganizationsService.getAll(1, MAX_PAGE_SIZE);

        setOrgData(results);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const getConfigurationLink = () => {
    switch (selectedEntityType) {
      case SELECTOR_ENTITY_TYPE.STATION:
        const station = stationsData?.find(
          (station) => station.id === selectedEntityId,
        );
        return `${AuthRoutes.CONFIGURATION}/organization/${station?.organization}/station/${selectedEntityId}`;

      case SELECTOR_ENTITY_TYPE.ORGANIZATION:
        return `${AuthRoutes.CONFIGURATION}/organization/${selectedEntityId}`;
      case SELECTOR_ENTITY_TYPE.CLOUDSORT:
        return AuthRoutes.CONFIGURATION;
    }
  };

  const onSelectEntity = async () => {
    if (!selectedEntityId) {
      return;
    }

    setShowProgress(true);

    if (shouldUpdateUrl) {
      await globalStationOptionsUtils.setStationData(
        selectedEntityId,
      );

      dispatch(setShouldUpdateUrl(false));
      dispatch(setSholdUpdateDynamicRoutes(true));

      if (isConfigurationPage) {
        browserHistory.push(getConfigurationLink());
      } else {
        if (
          lastVisitedModulePermissionNeeded &&
          lastVisitedModule &&
          PermissionsService.hasPermission(
            lastVisitedModulePermissionNeeded,
          ) &&
          configurationUtils.isModuleActive(lastVisitedModule)
        ) {
          browserHistory.push(
            (process.env.REACT_APP_BASENAME || '') +
              getModuleUrl(lastVisitedModule) +
              `?${stationDashboardUrlId}=` +
              selectedEntityId,
          );
          browserHistory.go(0);
        } else
          browserHistory.push(
            (process.env.REACT_APP_BASENAME || '') +
              getModuleUrl(ModulesKeys.DASHBOARD) +
              `?${stationDashboardUrlId}=` +
              selectedEntityId,
          );
      }
    }

    setShowProgress(false);
  };

  const setInitialStationData = async () => {
    try {
      fetchNavItems();
    } catch (e) {
      console.error(ErrorHandler.getLabel(e as AxiosError));
    }
  };

  const setStationsAndOrgsDataState = async () => {
    try {
      const myStations = LocalStorageService.getMyStationsData();
      if (myStations) {
        setStationsData(myStations);
      }

      fetchOrgData();
    } catch (e) {
      console.error(ErrorHandler.getLabel(e as AxiosError));
    }
  };

  useEffect(() => {
    if (currentPage === 'DASHBOARD') {
      setInitialStationData();
    }
    fetchNavItems();
    setStationsAndOrgsDataState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reFetchStationsData = async () => {
    const myStations = (await StationsService.getAllStations()).data
      .results;
    LocalStorageService.setMyStationsData(myStations);
    setStationsData(myStations);
    fetchOrgData();
    fetchNavItems();
    dispatch(setShouldUpdateStationsData(false));
  };

  useEffect(() => {
    if (shouldUpdateStationsData) {
      reFetchStationsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldUpdateStationsData]);

  const urlParams = useParams<configurationUrlParams>();
  const locationSearch = useLocation().search;

  useEffect(() => {
    if (selectedEntityId) {
      onSelectEntity();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEntityId, selectedEntityType]);

  useEffect(() => {
    if (selectedEntityId) {
      return;
    }

    const stationId = queryString.parse(locationSearch)['stationId'];

    if (stationId || urlParams.stationId) {
      dispatch(
        setSelectedEntity({
          id: stationId
            ? Number(stationId)
            : Number(urlParams.stationId),
          type: SELECTOR_ENTITY_TYPE.STATION,
        }),
      );
      EphemeralStateService.setMyStationId(
        stationId ? Number(stationId) : Number(urlParams.stationId),
      );
    } else if (urlParams.orgId) {
      dispatch(
        setSelectedEntity({
          id: Number(urlParams.orgId),
          type: SELECTOR_ENTITY_TYPE.ORGANIZATION,
        }),
      );
    } else if (isConfigurationPage) {
      dispatch(
        setSelectedEntity({
          id: 1,
          type: SELECTOR_ENTITY_TYPE.CLOUDSORT,
        }),
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParams, locationSearch]);

  return (
    <>
      {showProgress && <ProgressIndicator />}
      <EntitySelector
        stations={stationsData}
        orgs={orgData}
        isConfigurationNav={isConfigurationPage}
      />
      <Divider className={classes.sectionDivider} />
      <MenuList>
        {[
          ...(selectedEntityType === SELECTOR_ENTITY_TYPE.STATION
            ? [
                <TopLevelNavItem
                  key='top-level-link-dashboard'
                  currentlyActive={
                    currentPage === TOP_LEVEL_NAV_ITEM.DASHBOARD
                  }
                  linkDestination={AuthRoutes.DASHBOARD}
                  icon={<AppsIcon />}
                  text={configurationUtils.getPageTitle(
                    true,
                    TOP_LEVEL_NAV_ITEM.DASHBOARD,
                  )}
                />,
              ]
            : []),
          ...(selectedEntityType === SELECTOR_ENTITY_TYPE.STATION &&
          configurationUtils.isModuleActive('STATION_LAYOUT')
            ? [
                <TopLevelNavItem
                  key='top-level-link-station-layout'
                  currentlyActive={
                    currentPage === TOP_LEVEL_NAV_ITEM.STATION_LAYOUT
                  }
                  linkDestination={AuthRoutes.STATION_LAYOUT}
                  icon={<LayersOutlinedIcon />}
                  text={configurationUtils.getPageTitle(
                    true,
                    TOP_LEVEL_NAV_ITEM.STATION_LAYOUT,
                  )}
                />,
              ]
            : []),
          ...(selectedEntityType === SELECTOR_ENTITY_TYPE.STATION &&
          configurationUtils.isModuleActive('STATION_PLANNER')
            ? [
                <TopLevelNavItem
                  key='top-level-link-station-planner'
                  currentlyActive={
                    currentPage === TOP_LEVEL_NAV_ITEM.STATION_PLANNER
                  }
                  linkDestination={AuthRoutes.STATION_PLANNER}
                  icon={<AssignmentTurnedInOutlinedIcon />}
                  text={configurationUtils.getPageTitle(
                    true,
                    TOP_LEVEL_NAV_ITEM.STATION_PLANNER,
                  )}
                />,
              ]
            : []),
          ...(PermissionsService.hasPermission(
            StaffPermissionPermissionsEnum.STATION_WRITE,
          )
            ? [
                <TopLevelNavItem
                  key='top-level-link-station-configuration'
                  currentlyActive={
                    currentPage === TOP_LEVEL_NAV_ITEM.CONFIGURATION
                  }
                  linkDestination={AuthRoutes.CONFIGURATION}
                  icon={<SettingsIcon />}
                  text='Configuration'
                  onClick={() => {
                    browserHistory.push(getConfigurationLink());
                  }}
                />,
              ]
            : []),
        ]}
      </MenuList>
      <Divider className={classes.sectionDivider} />
      {selectedEntityType === SELECTOR_ENTITY_TYPE.STATION && (
        <StationNavigationMenu
          navItems={navItems}
          currentPage={currentPage}
        />
      )}
    </>
  );
};

export default withStyles(styles)(Navigation);
