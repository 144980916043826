import React, { useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';

import Router from '../router/Router';
import {
  createTheme,
  Theme,
  ThemeProvider,
} from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import browserHistory from '../../utils/browserHistory';
import {
  AuthRoutes,
  ModulesKeys,
  stationDashboardUrlId,
} from '../../interfaces/routes';
import EphemeralStateService from '../../services/EphemeralState.service';

import './App.css';
import LocalStorageService from '../../services/LocalStorage.service';
import { useThemeSwitcher } from './useThemeSwitcher';
import { store } from '../../redux/store';

const App: React.FC = () => {
  const currentTheme = useThemeSwitcher();
  const theme: Theme = createTheme({
    ...currentTheme,
  });
  const updateUrl = () => {
    // Construct URLSearchParams object instance from current URL querystring.
    const queryParams = new URLSearchParams(window.location.search);

    // Set new or modify existing parameter value.
    queryParams.set(
      stationDashboardUrlId,
      EphemeralStateService.getMyStationId().toString(),
    );

    // Replace current querystring with the new one.

    window.history.replaceState(
      null,
      '',
      '?' + queryParams.toString(),
    );
  };

  useEffect(() => {
    browserHistory.listen((location, action) => {
      // Only add station id url param on module urls, and only for PUSH action
      if (
        ['PUSH'].includes(action) &&
        [...Object.keys(ModulesKeys), 'SCHEME'] //Scheme is not a module but still needs stationId appended to Schemes table
          .map((key) => {
            return AuthRoutes[key as ModulesKeys];
          })
          .some((key) => window.location.pathname.includes(key))
      ) {
        updateUrl();
      }

      // Update last hit time
      if (['PUSH'].includes(action)) {
        LocalStorageService.setLastHitDate();
      }
    });

    // Set last hit time when the app first loads
    LocalStorageService.setLastHitDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router />
      </ThemeProvider>
    </Provider>
  );
};

export default App;
