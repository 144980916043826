import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { TrackableContainersRoutes } from '../../interfaces/routes';
import { CSButton } from '../primitives/index';

import PaginatedTable, {
  filterObj,
} from '../paginatedTable/PaginatedTable';
import { Column } from '../../interfaces/components';
import { Box, Grid, Theme, createStyles } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import CSSectionTitleSeparator from '../primitives/csSectionTitleSeparator/CSSectionTitleSeparator';
import { common } from '../../utils/strings';

// Icons
import MapOutlinedIcon from '@material-ui/icons/MapOutlined';
import ListOutlinedIcon from '@material-ui/icons/ListOutlined';

// Services

import TrackableContainersService from '../../services/TrackableContainers.service';
import TrackableContainersLayout from '../trackableContainersLayout/TrackableContainersLayout';
import { TrackableContainer } from 'cloudsort-client';
import {
  renderBatteryPercentageLabel,
  renderTemperatureLabel,
} from './utils';
import browserHistory from '../../utils/browserHistory';

interface Props {
  classes: { [key: string]: string };
}

const COLUMNS_TRACKABLE_CONTAINERS: Column[] = [
  {
    id: 'id',
    label: 'ID',
    hide: true,
  },
  {
    id: 'identifier',
    label: 'ID',
    width: 'auto',
  },
  {
    id: 'last_event',
    label: 'Last Event',
    width: 'auto',
  },
  {
    id: 'location',
    label: 'Current Location',
    width: 'auto',
  },
  {
    id: 'assigned_on_formatted',
    label: 'Assigned On',
    width: 'auto',
  },
  {
    id: 'battery_life',
    label: 'Battery Life',
    width: 'auto',
  },
  {
    id: 'temperature',
    label: 'Temperature',
    width: 'auto',
  },
];

interface ExtendedTrackableContainer
  extends Omit<TrackableContainer, 'id'> {
  id: string;
  last_event: string;
  location: string;
  assigned_on_formatted: string;
  battery_life: string;
  temperature: string;
}

const TrackableContainersList: React.FC<Props> = ({ classes }) => {
  const fetchTrackableContainers = async (
    pageIndex: number,
    rowsPerPage: number,
    filterOptions?: filterObj[],
    filterByString?: string,
    sortedBy?: string,
  ) => {
    const res = await TrackableContainersService.getAll({
      page: pageIndex,
      pageSize: rowsPerPage,
      assetId: filterByString,
    });

    for (const tc of res.data
      .results as unknown as ExtendedTrackableContainer[]) {
      tc.id = `${tc.identifier}/${tc.id}`;

      tc.last_event = tc.tracker_device.last_location_ts
        ? new Date(
            tc.tracker_device.last_location_ts,
          ).toLocaleString()
        : common.emptyValue;

      tc.location = tc.tracker_device.last_location_lat
        ? `${tc.tracker_device.last_location_lat},${tc.tracker_device.last_location_long}`
        : common.emptyValue;

      tc.assigned_on_formatted = tc.assigned_on
        ? new Date(tc.assigned_on).toLocaleString()
        : common.emptyValue;

      tc.battery_life = renderBatteryPercentageLabel(
        tc.tracker_device,
      );

      tc.temperature = renderTemperatureLabel(tc.tracker_device);
    }

    return res;
  };

  return (
    <TrackableContainersLayout maxWidth='xl'>
      <Helmet>
        <title>CloudSort - Trackable Containers List</title>
      </Helmet>
      <Grid
        container
        className={classes.marginTop20}
        alignItems='center'
      >
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <CSButton
            variant='contained'
            color={{
              buttonColor: 'secondary',
              iconColor: 'primary',
            }}
            onClick={() => {
              browserHistory.push(TrackableContainersRoutes.LIST);
            }}
            startIcon={<ListOutlinedIcon />}
          >
            List View
          </CSButton>
          <CSButton
            variant='outlined'
            color='secondary'
            style={{ marginLeft: 15 }}
            onClick={() => {
              browserHistory.push(TrackableContainersRoutes.MAP);
            }}
            startIcon={<MapOutlinedIcon />}
          >
            Map View
          </CSButton>
        </Grid>
        <CSSectionTitleSeparator topMargin={10} bottomMargin={24} />
      </Grid>

      <Box mb={5}>
        <PaginatedTable
          title='List of Containers'
          filterByStringPlaceholder='Search Container ID'
          filterByString={true}
          columns={COLUMNS_TRACKABLE_CONTAINERS}
          dataTestIdPrefix={'outbound-loads'}
          fetch={fetchTrackableContainers}
          rowsLoadDetailPages={true}
          detailsPageBasePath={TrackableContainersRoutes.LIST}
        />
      </Box>
    </TrackableContainersLayout>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    marginTop20: {
      marginTop: 20,
    },
    marginBottom20: {
      marginBottom: 20,
    },
  })),
)(TrackableContainersList);
