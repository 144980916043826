import React, { useState, Fragment, useEffect, useMemo } from 'react';
import {
  AuthRoutes,
  NonAuthRoutes,
  TrackableContainersRoutes,
} from '../../interfaces/routes';
import { ObtainToken } from 'cloudsort-client';
import {
  AlertBanner,
  Checkbox,
  Typography,
  Container,
  CustomTextField,
  CSButton,
} from '../primitives';
import { Link, RouteComponentProps } from 'react-router-dom';

import styles from './logIn.styles';
import { withStyles } from '@material-ui/core/styles';
import ErrorHandler from '../../utils/ErrorHandler';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import browserHistory from '../../utils/browserHistory';

// Services
import AuthService from '../../services/Auth.service';
import LocalStorageService from '../../services/LocalStorage.service';

// Utils
import globalStationOptionsUtils from '../../utils/globalStationOptionsUtils';
import validateEmail from '../../utils/validateEmail';
import { Paper } from '@material-ui/core';

//Icons
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import { Helmet } from 'react-helmet';
import { useAppDispatch } from '../../redux/store';
import { setSholdUpdateDynamicRoutes } from '../../redux/slices/navigationSlice';
import { AxiosError } from 'axios';

interface Props extends RouteComponentProps {
  classes: { [key: string]: string };
}

const LogIn: React.FC<Props> = ({ match, location, classes }) => {
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [authState, setAuthState] = useState<ObtainToken>({
    email: '',
    password: '',
    device_id: 'web',
  });
  const [alertMessage, setAlertMessage] = useState<string>();
  const [logInError, setLogInError] = useState<string>('');
  const [passwordFieldType, setPasswordFieldType] =
    useState<string>('password');
  const [rememberMe, setRememberMe] = useState<boolean>(false);

  const afterLoginUrlFromLocation = useMemo(() => {
    const queryParam = new URLSearchParams(
      window.location.search,
    ).get('afterLoginUrl');
    if (queryParam) {
      return queryParam;
    }
    return null;
  }, []);

  const afterLoginUrl = afterLoginUrlFromLocation
    ? afterLoginUrlFromLocation
    : LocalStorageService.getAfterLoginUrl();
  const isCustomer = LocalStorageService.getIsCustomer();

  const dispatch = useAppDispatch();

  const canSubmitForm =
    authState.password &&
    authState.email &&
    validateEmail(authState.email) &&
    !showProgress;

  const setStationData = async () => {
    try {
      return globalStationOptionsUtils.setStationData();
    } catch (e) {
      setLogInError(await ErrorHandler.getLabel(e as AxiosError));
    }
  };

  const onLogInClick = async () => {
    setShowProgress(true);
    try {
      AuthService.setRememberMe(rememberMe);
      const isCustomer = await AuthService.logIn(authState);
      if (isCustomer) {
        if (afterLoginUrl) {
          window.location.replace(String(afterLoginUrl));
          LocalStorageService.removeAfterLoginUrl();
        } else {
          browserHistory.push(TrackableContainersRoutes.MAP);
        }
      } else {
        const stationData = await setStationData();
        if (stationData) {
          dispatch(setSholdUpdateDynamicRoutes(true));

          setTimeout(() => {
            if (afterLoginUrl) {
              window.location.replace(String(afterLoginUrl));
              LocalStorageService.removeAfterLoginUrl();
            } else {
              browserHistory.push(AuthRoutes.DASHBOARD);
            }
          }, 0);
        } else {
          // Log out if user has no stations data
          AuthService.logOut({});
          setLogInError(
            `You don't have sufficient permissions to log in to the web app.`,
          );
          setShowProgress(false);
        }
      }
    } catch (e) {
      setLogInError(await ErrorHandler.getLabel(e as AxiosError));
      setShowProgress(false);
    }
  };

  useEffect(() => {
    if ((match.params as any).alertMessage === 'password-set')
      setAlertMessage('Your password has been set.');
    else if (
      (match.params as any).alertMessage ===
      'password-reset-requested'
    )
      setAlertMessage(
        `If you have an account, we'll email you a password reset link`,
      );
    else if ((match.params as any).alertMessage === 'link-resent')
      setAlertMessage(
        `We've just sent you a new link for setting your password. Please check your Inbox.`,
      );
    if ((match.params as any).alertMessage === 'email-updated')
      setAlertMessage(
        `Your email has been updated. Use your new email to log in.`,
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match]);

  const setStationDataAndRedirectToDashboard = async () => {
    const stationData = await setStationData();
    if (stationData) {
      dispatch(setSholdUpdateDynamicRoutes(true));
      setTimeout(() => {
        browserHistory.push(AuthRoutes.DASHBOARD);
      }, 0);
    }
  };

  useEffect(() => {
    if (AuthService.isLoggedIn() && !afterLoginUrl) {
      if (isCustomer) {
        browserHistory.push(TrackableContainersRoutes.MAP);
      } else {
        setStationDataAndRedirectToDashboard();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>{`CloudSort - Login`}</title>
      </Helmet>
      <div
        className={classes.halfWidth + ' ' + classes.loginBackground}
      >
        <img
          className={classes.loginLogo}
          alt='CloudSort'
          src={`${
            process.env.REACT_APP_BASENAME || ''
          }/loginAssets/logo.svg`}
        />
      </div>
      <div
        className={classes.halfWidth}
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {showProgress && (
          <ProgressIndicator data-testid='progress-indicator-component' />
        )}
        <Container
          maxWidth='xs'
          data-testid='login-component'
          style={{
            marginLeft: '20px',
          }}
        >
          <div className={classes.paper}>
            <Typography variant='h2' className={classes.loginHeading}>
              Sign in to CloudSort
            </Typography>
            {alertMessage && (
              <Paper className={classes.alertBox}>
                <CheckCircleOutlineOutlinedIcon />
                <p>{alertMessage}</p>
              </Paper>
            )}
            <form className={classes.form} noValidate>
              <CustomTextField
                inputProps={{
                  'data-testid': 'login-email',
                }}
                required
                label='E-mail address'
                name='email'
                autoComplete='email'
                autoFocus
                value={authState.email}
                onChange={(e: React.BaseSyntheticEvent) => {
                  e.persist();
                  if (logInError) {
                    setLogInError('');
                  }
                  const { name, value } = e.target;
                  setAuthState((prevState) => {
                    return {
                      ...prevState,
                      [name]: value,
                    };
                  });
                }}
                validatorFunction={validateEmail}
                validatorError={`The email address you've entered doesn't seem right. Please check spelling.`}
              />
              <CustomTextField
                inputProps={{
                  'data-testid': 'login-password',
                }}
                buttonProps={{
                  tabIndex: 1,
                }}
                required
                name='password'
                label='Password'
                type={passwordFieldType}
                autoComplete='current-password'
                value={authState.password}
                labelButton={
                  passwordFieldType === 'text' ? 'Hide' : 'Show'
                }
                labelButtonAction={() => {
                  passwordFieldType === 'text'
                    ? setPasswordFieldType('password')
                    : setPasswordFieldType('text');
                }}
                onChange={(e: React.BaseSyntheticEvent) => {
                  e.persist();
                  if (logInError) {
                    setLogInError('');
                  }
                  const { name, value } = e.target;
                  setAuthState((prevState) => {
                    return {
                      ...prevState,
                      [name]: value,
                    };
                  });
                }}
              />
              <Link
                to={NonAuthRoutes.RESET_PASSWORD}
                className={classes.forgotPasswordLink}
              >
                Forgot your password?
              </Link>
              <br />
              <br />
              <Checkbox
                data-testid='login-remember-me'
                checked={rememberMe}
                onChange={(e) => {
                  e.preventDefault();
                  setRememberMe(!rememberMe);
                }}
                value='rememberMe'
                label={'Remember me'}
                includeFormControlLabel
              />
              {logInError && (
                <AlertBanner
                  severity='error'
                  data-testid='login-error'
                  alertMsg={logInError}
                  alertTitle={'Error'}
                />
              )}
              <br />
              <CSButton
                data-testid='login-button'
                color='primary'
                variant='contained'
                disabled={!canSubmitForm}
                className={classes.submitButton}
                onClick={(e) => {
                  e.preventDefault();
                  onLogInClick();
                }}
              >
                Sign In
              </CSButton>
              {canSubmitForm && (
                <div className={classes.pressEnter}>
                  or press enter <KeyboardReturnIcon />
                </div>
              )}
            </form>
          </div>
        </Container>
      </div>
    </Fragment>
  );
};

export default withStyles(styles)(LogIn);
