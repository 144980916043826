import {
  AreasApi,
  AreaAreaTypeEnum,
  Area,
  AreaDetails,
  AreaDetailsAreaTypeEnum,
} from 'cloudsort-client';
import axios from '../utils/axios';
import {
  MAX_PAGE_SIZE,
  LABEL_DPI,
  LABEL_SIZE,
} from './utils/constants';
import EphemeralStateService from './EphemeralState.service';

class Areas {
  private api: AreasApi;

  constructor() {
    this.api = new AreasApi(undefined, undefined, axios as any);
  }

  public async getAll(
    page?: number,
    pageSize?: number,
    search?: string,
  ) {
    return this.api.areasList({
      page,
      pageSize,
      search,
      station: EphemeralStateService.getMyStationId(),
      sortBy: 'name',
    });
  }

  public async getByType(areaType: AreaAreaTypeEnum) {
    return this.api.areasList({
      station: EphemeralStateService.getMyStationId(),
      areaType: [areaType],
      pageSize: MAX_PAGE_SIZE,
    });
  }

  public async getById(id: number) {
    return this.api.areasRead({ id });
  }

  public async create(data: Area) {
    return this.api.areasCreate({ data });
  }

  public async delete(id: number) {
    return this.api.areasDelete({ id });
  }

  public async update(id: number, data: AreaDetails) {
    return this.api.areasPartialUpdate({ id, data });
  }

  public async search(
    search: string,
    areaType?: AreaDetailsAreaTypeEnum,
  ) {
    return this.api.areasList({
      search,
      areaType: areaType ? [areaType] : undefined,
      station: EphemeralStateService.getMyStationId(),
    });
  }

  public async getLabel(
    id: number,
    size: string = LABEL_SIZE,
    dpi: number = LABEL_DPI,
  ) {
    return this.api.areasLabelLabelPng(
      {
        id,
        size,
        dpi,
      },
      { responseType: 'blob' },
    );
  }
}

export default new Areas();
