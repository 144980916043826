import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import OperatorToolDesktop from './OperatorTool.desktop';
import OperatorToolSearch from './OperatorTool.search';
import OperatorToolMobile from './OperatorTool.mobile';
import { CurrentView } from '../Modules';
import { INTERACTION } from '../../utils';

interface Props {
  classes: { [key: string]: string };
  data: any;
  modulesData: any;
  interaction?: INTERACTION;
  setPartialData: (timers: any) => void;
  currentView: CurrentView;
  goToPreviousPage: () => void;
}

const OperatorToolModule: React.FC<Props> = ({
  classes,
  data,
  modulesData,
  interaction,
  setPartialData,
  currentView,
  goToPreviousPage,
}) => {
  const selectViewToRender = () => {
    if (currentView === CurrentView.SEARCH) {
      return (
        <OperatorToolSearch
          data={data}
          modulesData={modulesData}
          interaction={interaction}
          setPartialData={setPartialData}
        />
      );
    }

    if (currentView === CurrentView.MOBILE) {
      return (
        <OperatorToolMobile
          data={data}
          modulesData={modulesData}
          interaction={interaction}
          setPartialData={setPartialData}
          goToPreviousPage={goToPreviousPage}
        />
      );
    }

    if (currentView === CurrentView.DESKTOP) {
      return (
        <OperatorToolDesktop
          data={data}
          modulesData={modulesData}
          interaction={interaction}
          setPartialData={setPartialData}
        />
      );
    }
    return <></>;
  };

  return <>{data ? selectViewToRender() : null}</>;
};

export default withStyles({})(OperatorToolModule);
