import {
  FilledInput,
  Input,
  InputLabelProps,
  InputProps,
  OutlinedInput,
} from '@material-ui/core';

import { ExtendedPaletteKeys } from '../../../theming/material-ui';
export type CSTextFieldSize =
  | 'standard'
  | 'fullHorizontal'
  | 'fullVertical'
  | 'full';

export interface CSTextFieldProps
  extends Omit<InputProps, 'color' | 'variant'> {
  color?: ExtendedPaletteKeys;
  variant?: ExtendedVariants;
  label?: string;
  value?: string;
  defaultValue?: string;
  onEnter?: (value?: string) => void;
  onClear?: () => void;
  helperText?: string;
  hideClear?: boolean;
  InputLabelProps?: InputLabelProps;
  customContent?: JSX.Element | undefined | false;
  containerSize?: CSTextFieldSize;
  'data-testid'?: string;
}
export interface MaterialInputConfiguration {
  formControlVariant: MaterialVariants;
  formLabelVariant: MaterialVariants;
  notched: boolean | undefined;
}
export interface VariantSelector {
  standard: MaterialInputComponent | Record<string, unknown>;
  filled: MaterialInputComponent | Record<string, unknown>;
  outlined: MaterialInputComponent | Record<string, unknown>;
  standardOutlined: MaterialInputComponent | Record<string, unknown>;
  filledOutlined: MaterialInputComponent | Record<string, unknown>;
}
export type MaterialInputComponent =
  | typeof Input
  | typeof FilledInput
  | typeof OutlinedInput;
export type MaterialVariants = 'standard' | 'filled' | 'outlined';
export type ExtendedVariants =
  | 'standard'
  | 'filled'
  | 'outlined'
  | 'standard-outlined'
  | 'filled-outlined';
export type TextFieldColor = 'primary' | 'secondary';

export const knownTextFieldVariants = [
  'standard',
  'filled',
  'outlined',
];
export const ENTER = 13;
export const COMPONENT_NAME = 'CSTextField';
