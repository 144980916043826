import React, { useEffect, useState } from 'react';
import {
  withStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles';
import { CSButton, Typography } from '../../primitives';
import ProgressIndicator from '../../progressIndicator/ProgressIndicator';
import Layout from '../../layout/Layout';
import { AlertBanner } from '../../primitives';
import ErrorHandler from '../../../utils/ErrorHandler';
import browserHistory from '../../../utils/browserHistory';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import ConfirmationDialog from '../../confirmationDialog/ConfirmationDialog';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

// Types
import { Module, NestedModule } from 'cloudsort-client';
import { AxiosError } from 'axios';

// Services
import ZoneModulesService from '../../../services/ZoneModules.service';
import { AuthRoutes } from '../../../interfaces/routes';
import { Helmet } from 'react-helmet';
import configurationUtils from '../../../utils/configurationUtils';
import { IconButton } from '@material-ui/core';
import CSSectionTitleSeparator from '../../primitives/csSectionTitleSeparator/CSSectionTitleSeparator';

interface Props {
  classes: { [key: string]: string };
  match: any;
}

const AddStationModule: React.FC<Props> = ({ classes, match }) => {
  const [modules, setModules] = useState<Module[]>();
  const [selectedModuleId, setSelectedModuleId] = useState<number>();
  const [lpSortAsc, setLpSortAsc] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [showConfirmationDialog, setShowConfirmationDialog] =
    useState<boolean>(false);

  const fetchModules = async () => {
    setShowProgress(true);
    try {
      const data = (
        await ZoneModulesService.getAll({
          sortBy: lpSortAsc
            ? 'load_point_count'
            : '-load_point_count',
          areaType: match.params.areaType,
        })
      ).data.results;
      setModules(data);
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  const onAddClick = async () => {
    setShowProgress(true);
    try {
      const selectedModule = modules?.find(
        (m) => m.id === selectedModuleId!,
      );
      if (selectedModule) {
        const data = (
          await ZoneModulesService.getAll({
            zone: (match.params as any).zoneId,
            sortBy: lpSortAsc
              ? 'load_point_count'
              : '-load_point_count',
          })
        ).data.results;
        if (
          data.some((module) => module.name === selectedModule.name)
        ) {
          setShowConfirmationDialog(true);
          return;
        } else {
          add();
        }
      }
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  const add = async () => {
    setShowProgress(true);
    try {
      const selectedModule = modules?.find(
        (m) => m.id === selectedModuleId!,
      );
      await ZoneModulesService.assignModuleToAZone(
        match.params.zoneId,
        selectedModuleId!,
        selectedModule! as NestedModule,
      );
      setTimeout(() => {
        browserHistory.push(
          `${AuthRoutes.AREA}/${match.params.id}/settings/${match.params.areaType}`,
        );
      }, 0);
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e as AxiosError));
  };

  useEffect(() => {
    fetchModules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lpSortAsc]);

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort -
${configurationUtils.getPageTitle(
  true,
  'AREA',
)} - Add Station Module`}
        </title>
      </Helmet>
      <Layout navCurrent='AREA'>
        {showProgress && <ProgressIndicator />}
        {error && (
          <AlertBanner
            severity='error'
            alertTitle={'Error'}
            alertMsg={error}
          />
        )}
        {showConfirmationDialog && (
          <ConfirmationDialog
            dataTestIdPrefix={'add-station-module-'}
            title={'Duplicate name'}
            msg={
              'There is already a module with the same name on the zone, are you sure you want to add this module?'
            }
            onPrimaryAction={() => {
              add();
            }}
            onCancel={() => {
              setShowConfirmationDialog(false);
            }}
            isOpen={showConfirmationDialog}
          />
        )}
        <Typography component='h1' variant='h3'>
          Add Station Module
        </Typography>
        <CSSectionTitleSeparator topMargin={16} />
        <Typography variant='h6'>
          Please select a module to start
        </Typography>
        {!!modules?.length ? (
          <>
            <TableContainer
              component={Paper}
              className={classes.tableContainer}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Typography variant='h4'>Modules</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow className={classes.tableHead}>
                    <TableCell
                      align='center'
                      style={{ width: 100 }}
                    ></TableCell>
                    <TableCell align='center'>Name</TableCell>
                    <TableCell align='center'>
                      Load Points{' '}
                      <IconButton
                        onClick={() => {
                          setLpSortAsc(!lpSortAsc);
                        }}
                      >
                        {lpSortAsc ? (
                          <ArrowDownwardIcon
                            className={classes.sortIcon}
                          />
                        ) : (
                          <ArrowUpwardIcon
                            className={classes.sortIcon}
                          />
                        )}
                      </IconButton>
                    </TableCell>
                    <TableCell align='center'>Location</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {modules.map((module) => (
                    <TableRow key={module.id}>
                      <TableCell align='center'>
                        <Radio
                          className={classes.radioButton}
                          checked={module.id === selectedModuleId}
                          color={'primary'}
                          onChange={(e) => {
                            setSelectedModuleId(
                              parseInt(e.target.value),
                            );
                          }}
                          value={module.id}
                          inputProps={{
                            // @ts-ignore
                            'data-testid': 'select-module-radio',
                          }}
                        />
                      </TableCell>
                      <TableCell align='center'>
                        {!!module.name
                          ? module.name
                          : module.id?.toString().length === 1
                          ? `M 0${module.id}`
                          : `M ${module.id}`}
                      </TableCell>
                      <TableCell align='center'>{`${module.load_point_count} Loadpoints`}</TableCell>
                      <TableCell align='center'>{`${module.area_name} Z${module.zone_name}`}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          modules?.length === 0 && (
            <AlertBanner
              severity='info'
              alertTitle={
                'There is no information to display at the moment'
              }
            />
          )
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <CSButton
            variant='outlined'
            color='secondary'
            onClick={() => {
              browserHistory.push({
                pathname: `${AuthRoutes.AREA}/${match.params.id}/settings/${match.params.areaType}`,
              });
            }}
          >
            Cancel
          </CSButton>
          <CSButton
            variant='contained'
            color='secondary'
            data-testid={'add-station-module-button'}
            disabled={!selectedModuleId || !!error}
            onClick={() => {
              onAddClick();
            }}
          >
            Add
          </CSButton>
        </div>
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    tableContainer: {
      margin: '30px 0',
    },
    tableHead: {
      backgroundColor: theme.palette.grey.A100,
      '& .MuiTableCell-head': {
        color: theme.palette.grey.A400,
      },
    },
    sortIcon: {
      color: theme.palette.grey.A400,
      width: '16px',
      height: '16px',
    },
    radioButton: {
      padding: 0,
      color: theme.palette.grey.A400,
    },
  })),
)(AddStationModule);
