import React, { useEffect, useState } from 'react';
import { Line, Group, Text } from 'react-konva';
import colors from '../../../utils/colors';
import {
  findMinMaxCoordinates,
  flattenPoints,
  getSides,
  rotatePoint,
} from '../helpers';
import { ElementType, METERS_TO_YARDS } from '../StationLayout';
import pointInPolygon from 'point-in-polygon';
import { Vector2d } from 'konva/types/types';
import ContainerTypeIcon from './ContainerTypeIcon';

interface Props {
  id: number;
  points: number[][];
  pointsNoAngleOffset: number[][];
  isSelected: boolean;
  draggingItem: any;
  type?: ElementType;
  containerType?: string;
  parentArea?: number;
  parentZone?: number;
  fill: string;
  onSelect: (id: number) => void;
  setDraggingItem: (id?: number) => void;
  onMove: (x: number, y: number) => void;
  onTransform: (
    roation: number,
    sclaseX: number,
    scaleY: number,
  ) => void;
  rotation?: number;
  innerRef: any;
  draggable?: boolean;
  dragBounds: number[][];
  stageScale: number;
  stagePosition: Vector2d;
  stageOffset: Vector2d;
  stageRotation: number;
  label: string;
  labelColor?: string;
  metersPerPixel: number;
}

const TransformablePolygon: React.FC<Props> = ({
  id,
  points,
  pointsNoAngleOffset,
  isSelected,
  draggable,
  draggingItem,
  type,
  containerType,
  parentArea,
  parentZone,
  setDraggingItem,
  fill,
  onSelect,
  onMove,
  onTransform,
  rotation = 0,
  innerRef,
  dragBounds,
  stageScale,
  stagePosition,
  stageOffset,
  stageRotation,
  label,
  labelColor = colors.black,
  metersPerPixel,
}) => {
  const [opacity, setOpacity] = useState<number>(1);

  const [inPosition, setInPosition] = useState<Vector2d>({
    x: 0,
    y: 0,
  });

  const [rotatedLabelPosition, setRotatedLabelPosition] = useState<
    number[]
  >([0, 0]);
  const [lpTypeIconPosition, setLpTypeIconPosition] = useState<
    number[]
  >([0, 0]);
  const [lpTypeIconSize, setLpTypeIconSize] = useState<number>(24);
  const [sideLengths, setSideLengths] = useState<string[]>();
  const [sizelabel, setSizelabel] = useState<string>('');

  //hide item if parent item is being dragged or rotated
  useEffect(() => {
    if (draggingItem && type) {
      if (
        type === 'ZONE' &&
        draggingItem.type === 'AREA' &&
        parentArea === draggingItem.id
      ) {
        setOpacity(0);
      } else if (
        type === 'LOADPOINT' &&
        ((draggingItem.type === 'AREA' &&
          parentArea === draggingItem.id) ||
          (draggingItem.type === 'ZONE' &&
            parentZone === draggingItem.id))
      )
        setOpacity(0);
    } else setOpacity(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draggingItem]);

  //update inPosition -- the default position of the element if trying to drag it out of boundaries
  useEffect(() => {
    setInPosition({
      x: stagePosition.x - stageOffset.x * stageScale,
      y: stagePosition.y - stageOffset.y * stageScale,
    });
  }, [stagePosition, stageOffset, stageScale]);

  //update rotatedLabelCoords
  useEffect(() => {
    const minMax = findMinMaxCoordinates(points);
    const rotatedTop = rotatePoint(
      [minMax.x.min, minMax.y.min - 12],
      [0, 0],
      rotation,
    );
    setRotatedLabelPosition(rotatedTop);

    //set position for loadpoint type icon
    let maxSize = Math.round((minMax.x.max - minMax.x.min) * 0.8);

    //check height
    const height = (minMax.y.max - minMax.y.min) * 0.8;

    if (height < maxSize) maxSize = height;

    if (maxSize > 24) maxSize = 24;

    setLpTypeIconSize(maxSize);

    const rotatedlpIcon = rotatePoint(
      [minMax.x.mid - maxSize / 2, minMax.y.mid - maxSize / 2],
      [0, 0],
      rotation,
    );
    setLpTypeIconPosition(rotatedlpIcon);

    //set side lengths
    const lengths = getSides(points, metersPerPixel);
    setSideLengths(lengths.slice(0, 2));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [points]);

  return (
    <Group>
      <Line
        points={flattenPoints(points)}
        ref={innerRef}
        opacity={opacity}
        onClick={() => {
          onSelect(id);
        }}
        onTap={() => {
          onSelect(id);
        }}
        fill={fill}
        stroke={colors.darkGold}
        strokeWidth={isSelected ? 2 : 0}
        closed={true}
        rotation={rotation}
        draggable={draggable}
        onTransformStart={() => {
          setDraggingItem(id);
        }}
        onTransform={(e) => {
          const {
            rotation: newRotation,
            scaleX,
            scaleY,
          } = e.currentTarget.attrs;

          if (
            newRotation &&
            rotation.toFixed(1) !== newRotation.toFixed(1)
          ) {
            //it's rotation
            setSizelabel(`${newRotation.toFixed(1)} degrees`);
          } else {
            //it's resize
            const rightOrientation =
              points[0][0].toFixed(1) !== points[1][0].toFixed(1);

            setSizelabel(
              `${(
                Number(
                  rightOrientation
                    ? sideLengths![0]
                    : sideLengths![1],
                ) *
                METERS_TO_YARDS *
                scaleX
              ).toFixed(1)} X ${(
                Number(
                  rightOrientation
                    ? sideLengths![1]
                    : sideLengths![0],
                ) *
                METERS_TO_YARDS *
                scaleY
              ).toFixed(1)} yards`,
            );
          }
        }}
        dragBoundFunc={(pos) => {
          const bounds = dragBounds.map((point) =>
            rotatePoint(point, [0, 0], stageRotation),
          );
          if (
            pointsNoAngleOffset
              .map((point) =>
                rotatePoint(point, [0, 0], stageRotation),
              )
              .map((point) => [
                point[0] +
                  stageOffset.x +
                  (pos.x - stagePosition.x) / stageScale,
                point[1] +
                  stageOffset.y +
                  (pos.y - stagePosition.y) / stageScale,
              ])

              .map((point) => pointInPolygon(point, bounds))
              .every(Boolean)
          ) {
            setInPosition(pos);
            return pos;
          } else return inPosition;
        }}
        onTransformEnd={(e) => {
          setDraggingItem(undefined);
          const { rotation, scaleX, scaleY } = e.currentTarget.attrs;

          onTransform(rotation, scaleX, scaleY);

          // reset position, scale and rotation
          e.currentTarget.x(0);
          e.currentTarget.y(0);
          e.currentTarget.scaleX(1);
          e.currentTarget.scaleY(1);

          //remove size label and temp. offset
          setSizelabel('');
        }}
        onDragStart={() => {
          setDraggingItem(id);
        }}
        onDragEnd={(e) => {
          setDraggingItem(undefined);

          const { x, y } = e.currentTarget.attrs;
          onMove(x, y);

          //reset position
          e.currentTarget.x(0);
          e.currentTarget.y(0);
        }}
      ></Line>
      {!draggingItem && (
        <Text
          fontSize={10}
          x={rotatedLabelPosition[0]}
          y={rotatedLabelPosition[1]}
          text={label}
          fill={labelColor}
          rotation={rotation}
        />
      )}
      {isSelected && (
        <Text
          fontSize={10}
          shadowColor={colors.white}
          shadowOffset={{ x: 1, y: 1 }}
          shadowBlur={2}
          x={rotatedLabelPosition[0]}
          y={rotatedLabelPosition[1]}
          text={sizelabel}
          fontVariant={'italic'}
          fill={colors.black}
          rotation={rotation}
        />
      )}
      {!draggingItem && type === 'LOADPOINT' && containerType && (
        <ContainerTypeIcon
          type={containerType}
          width={lpTypeIconSize}
          x={lpTypeIconPosition[0]}
          y={lpTypeIconPosition[1]}
          rotation={rotation}
        />
      )}
    </Group>
  );
};

export default TransformablePolygon;
