import { createStyles, Theme } from '@material-ui/core/styles';
import colors from '../../utils/colors';
import selectStyles from '../select/select.styles';

export default createStyles((theme: Theme) => ({
  ...selectStyles,
  container: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
  },
  detailInfoHolder: {
    marginTop: theme.spacing(0),
    padding: theme.spacing(4),
    display: 'flex',
    alignItems: 'start',
    textAlign: 'left',
    color: '#222222',
    '& b': {
      opacity: 1,
    },
    '& p': {
      opacity: 0.7,
      marginTop: theme.spacing(1),
    },
  },
  schemeInfoHolder: {
    display: 'flex',
    alignItems: 'start',
    textAlign: 'left',
    color: '#222222',
    justifyContent: 'space-between',
    padding: '24px 30px',
    '& p': {
      opacity: 0.7,
      padding: '14px 0',
    },
    '& b': {
      opacity: 1,
    },
  },
  detailHeader: {
    display: 'flex',
    '& button': {
      marginTop: 10,
    },
  },
  detailInfo: {
    color: '#222222',
    padding: '20px 30px',
    width: '100%',
    '& p': {
      marginBottom: 5,
      opacity: 0.7,
      fontWeight: 'bold',
    },
    '& p:first-child': {
      fontWeight: 'bold',
      opacity: 1,
    },
  },
  detailInfoButton: {
    marginLeft: 10,
    '& svg': {
      marginLeft: 5,
    },
  },
  infoTitle: {
    opacity: '0.7',
    color: '#222222',
    flexGrow: 1,
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: '22px',
  },
  banner: {
    width: '100%',
    borderRadius: 4,
    margin: '10px 0',
  },
  schemesEdit: {
    display: 'flex',
    minWidth: '100%',
    padding: '7px 0',
    '& p:first-child': {
      margin: '5px 5px 0 0',
    },
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
  },
  half: {
    width: '50%',
  },
  containerDetails: {
    display: 'flex',
    flexDirection: 'row',
  },
  tableHeader: {
    background: '#fafafa',
  },
  visible: {
    display: 'block',
  },
  hidden: {
    display: 'none',
  },
  map: {
    '& a[href*="leafletjs.com"]': {
      display: 'none',
    },
  },
  areaCountMarker: {
    border: '1px solid black',
    pointerEvents: 'none',
    opacity: '1 !important',
    backgroundColor: 'white',
    borderRadius: '20%',
    height: 'auto !important',
    width: 'auto !important',
    left: '-15px',
    top: '-5px',
  },
  containerTypeMarler: {
    backgroundColor: '#FFF',
    borderRadius: '20%',
    padding: 2,
    pointerEvents: 'none !important',
    border: '1px solid black',
  },

  header: {
    position: 'relative',
    zIndex: 10,
    padding: '20px',
    backgroundColor: colors.dark,
    color: colors.white,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },

  headerTitle: {
    color: colors.white,
    fontSize: '24px',
  },

  notificationBox: {
    zIndex: 20,
    background: colors.white,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    padding: '20px',
    maxWidth: '80%',
  },

  navigationList: {
    listStyleType: 'none',

    '& li': {
      display: 'inline-block',
      float: 'left',
      color: colors.white,
      padding: 'auto 10px',
    },
  },

  stationLayoutContainer: {
    display: 'flex',
    width: '100%',
    height: 'calc(100vh - 66px)',
  },

  mainContainer: {
    height: 'calc(100% - 68px)',
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
  },

  konvaContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    margin: '20px',
    width: 'calc(100% - 40px)',
    height: 'calc(100% - 40px)',
    backgroundColor: '#f0f0f0',
    zIndex: 1,
    outline: 0,
  },

  compassContainer: {
    position: 'absolute',
    left: '10px',
    bottom: '10px',
    zIndex: 10,
  },

  gridInfoContainer: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    zIndex: 10,
    background: colors.gray,
    color: colors.white,
    padding: '3px 6px',
    borderTopLeftRadius: '6px',
  },

  gridScale: {
    display: 'inline-block',
    border: `2px solid ${colors.black}`,
    height: '5px',
    margin: '10px 5px 0 10px',
    borderTop: 0,
  },

  sidebar: {
    position: 'relative',
    backgroundColor: colors.dark,
    color: colors.white,
    padding: '10px',
  },

  sidebarContent: {
    height: 'calc(100% - 140px)',
    overflowX: 'hidden',
    overflowY: 'auto',
  },

  sidebarElementType: {
    color: colors.gold,
    fontSize: '9px',
    letterSpacing: '1px',
    textTransform: 'uppercase',
  },

  sidebarElementName: {
    fontSize: '21px',
  },

  editModeLabel: {
    fontSize: '18px',
    display: 'inline-block',
  },

  statLabel: {
    color: colors.veryLightGray,
    fontSize: '14px',
  },

  statValue: {
    color: colors.white,
    fontSize: '15px',
  },

  sidebarSubheading: {
    color: colors.white,
    fontSize: '16px',
    padding: '10px 0',
  },

  createIcon: {
    display: 'inline-block',
    marginLeft: '10px',
    backgroundColor: colors.dark,
    color: colors.white,
    width: '26px',
    height: '26px',
    padding: '4px 7px',
    borderRadius: '50%',

    '& svg': {
      width: '12px',
      height: '12px',
    },
  },

  sidebarBottomButtons: {
    position: 'absolute',
    bottom: '10px',
    width: 'calc(100% - 18px)',
  },

  sideLengthBadge: {
    border: `1px solid ${colors.white}`,
    borderRadius: '5px',
    padding: '10px',
    marginTop: '5px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    '& span': {
      padding: '10px',
    },
  },

  flexCenter: {
    position: 'absolute',
    backgroundColor: 'transparent',
    width: '100%',
    height: 'calc(100% - 68px)',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    zIndex: 33,
  },

  flexCenterContainer: {
    background: '#F2F2F2',
    border: `1px solid ${colors.black}`,
    padding: '20px',
    borderRadius: '10px',
  },

  flexCenterRow: {
    position: 'absolute',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: '50px',
  },

  flexCenterTitle: {
    color: colors.black,
    fontSize: '18px',
    marginBottom: '10px',
    textAlign: 'center',
  },

  flexCenterDesc: {
    fontSize: '14px',
    textAlign: 'center',
  },

  containedBtnDark: {
    backgroundColor: `${colors.dark} !important`,
    color: `${colors.white} !important`,
    marginTop: '10px',
    boxShadow: 'none',
    textTransform: 'none',
    padding: '10px 16px',
    '& :disabled': {
      cursor: 'not-allowed',
    },
  },

  containedBtnGold: {
    backgroundColor: `${colors.gold} !important`,
    color: `${colors.white} !important`,
    marginTop: '10px',
    boxShadow: 'none',
    textTransform: 'none',
    padding: '10px 16px',
    '& :disabled': {
      cursor: 'not-allowed',
    },
  },

  containedBtnWhite: {
    backgroundColor: `${colors.white} !important`,
    color: colors.dark,
    marginTop: '10px',
    boxShadow: 'none',
    textTransform: 'none !important',
    padding: '10px 16px',
  },
  outlinedBtnDark: {
    backgroundColor: 'transparent !important',
    color: colors.dark,
    borderColor: colors.dark,
    marginTop: '10px',
    boxShadow: 'none',
    textTransform: 'none',
    padding: '10px 16px',
  },

  outlinedBtnGold: {
    backgroundColor: 'transparent !important',
    color: colors.gold,
    borderColor: colors.gold,
    marginTop: '10px',
    boxShadow: 'none',
    textTransform: 'none',
    padding: '10px 16px',
  },

  muiSwitch: {
    margin: 0,
    '& .MuiIconButton-label': {
      color: colors.gold,
    },
  },

  viewNavigation: {
    display: 'block',
    listStyleType: 'none',
    margin: 0,

    '& > li': {
      display: 'inline-block',
      padding: '5px 30px',
      cursor: 'pointer',
      position: 'relative',
      '&.active': {
        fontWeight: 'bold',
      },

      '& > ul': {
        listStyleType: 'none',
        display: 'none',
        position: 'absolute',
        left: '15px',
        top: '15px',
        zIndex: 10,
        background: colors.white,
        color: colors.dark,
        margin: 0,
        padding: '10px',
        boxShadow: '0px 4px 4px rgb(0 0 0 / 25%)',
        '& > li': {
          '&:hover': {
            color: colors.darkGold,
          },
        },
      },

      '&:hover': {
        '& > ul': {
          display: 'block',
        },
      },
    },
  },

  lightHr: {
    width: '100%',
    border: 'none',
    borderBottom: `1px solid ${colors.white}`,
  },

  switchTrack: {
    backgroundColor: colors.gold,
    opacity: '1!important',
  },
}));
