import {
  LoadpointsApi,
  LoadPointDetails,
  AreaAreaTypeEnum,
} from 'cloudsort-client';
import axios from '../utils/axios';
import EphemeralStateService from './EphemeralState.service';

class LoadPoints {
  private api: LoadpointsApi;

  constructor() {
    this.api = new LoadpointsApi(undefined, undefined, axios as any);
  }

  public async getAll(params: {
    page?: number;
    scheme?: number;
    fmc?: number;
    module?: number;
    areaType?: AreaAreaTypeEnum;
    zone?: number;
    isActive?: boolean;
    pageSize?: number;
    targetStation?: number;
    search?: string;
  }) {
    return this.api.loadpointsList({
      ...params,
      station: EphemeralStateService.getMyStationId(),
    });
  }

  public async getById(id: number) {
    return this.api.loadpointsRead({ id });
  }

  public async updateById(id: number, data: LoadPointDetails) {
    return this.api.loadpointsPartialUpdate({ id, data });
  }
}

export default new LoadPoints();
