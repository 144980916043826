import React from 'react';
import { AuthRoutes } from '../../interfaces/routes';
import browserHistory from '../../utils/browserHistory';
import { MenuList, MenuItem } from '@material-ui/core';
import styles from './navigation.styles';
import { withStyles } from '@material-ui/core/styles';

import {
  DASHBOARD_NAVIGATION_SECTION,
  NavItems,
  NAV_ITEM_SECTION,
  NAV_PERMISSION_REQUIRED,
} from './types';
import clsx from 'clsx';

import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

// Icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import BuildIcon from '@material-ui/icons/Build';

// Services
import PermissionsService from '../../services/Permissions.service';
import EphemeralStateService from '../../services/EphemeralState.service';

interface Props {
  classes: { [key: string]: string };
  currentPage?: string;
  navItems?: NavItems[];
}

const StationNavigationMenu: React.FC<Props> = ({
  classes,
  currentPage,
  navItems = [],
}) => {
  const accordionClasses = {
    root: classes.accordionRoot,
    expanded: classes.accordionExpanded,
  };

  const accordionSummaryClasses = {
    root: classes.accordionSummary,
    content: classes.accordionSummaryContent,
    expandIcon: classes.accordionSummaryExpandIcon,
  };

  const modulesData =
    EphemeralStateService.getMyStationConfiguratation()?.GENERAL
      ?.MODULES;

  const renderNavItems = (section: DASHBOARD_NAVIGATION_SECTION) => {
    return navItems
      .filter(
        (item) =>
          // @ts-ignore
          NAV_ITEM_SECTION[item.id] === section,
      )
      .map((item) => {
        return PermissionsService.hasPermission(
          NAV_PERMISSION_REQUIRED[
            item.id as keyof typeof NAV_PERMISSION_REQUIRED
          ],
        ) ? (
          <MenuItem
            key={item.id}
            data-testid={'navigation-link-' + item.id}
            id={'navigation-link-' + item.id}
            className={clsx({
              [classes.menuItem]: true,
              [classes.current]: currentPage === item.id,
            })}
            onClick={(e) => {
              e.preventDefault();
              const newPageUrl = AuthRoutes[item.id];
              const currentPageUrl = window.location.href;
              // Target only main route(ex: should stop redirect on /packages, but not on /packages/:id)
              if (!currentPageUrl.includes(`${newPageUrl}?`)) {
                browserHistory.push(newPageUrl);
              }
            }}
          >
            {item.id === 'SCHEME'
              ? 'Schemes' //Schemes is not a module, the plural is not available anywhere in the config.
              : modulesData[item.id].label_plural}
          </MenuItem>
        ) : null;
      });
  };

  return (
    <>
      <Accordion classes={accordionClasses} defaultExpanded>
        <AccordionSummary
          classes={accordionSummaryClasses}
          expandIcon={
            <ExpandMoreIcon className={classes.expandMoreIcon} />
          }
        >
          <CallSplitIcon />{' '}
          <Typography className={classes.heading}>
            Distribution
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          classes={{ root: classes.accordionDetailsRoot }}
        >
          <MenuList
            className={classes.menuList}
            data-testid='navigation-menu'
          >
            {renderNavItems(
              DASHBOARD_NAVIGATION_SECTION.DISTRIBUTION,
            )}
          </MenuList>
        </AccordionDetails>
      </Accordion>
      <Accordion classes={accordionClasses} defaultExpanded>
        <AccordionSummary
          classes={accordionSummaryClasses}
          expandIcon={
            <ExpandMoreIcon className={classes.expandMoreIcon} />
          }
        >
          <LocalShippingOutlinedIcon />{' '}
          <Typography className={classes.heading}>
            Shipping
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          classes={{ root: classes.accordionDetailsRoot }}
        >
          <MenuList
            className={classes.menuList}
            data-testid='navigation-menu'
          >
            {renderNavItems(DASHBOARD_NAVIGATION_SECTION.SHIPPING)}
          </MenuList>
        </AccordionDetails>
      </Accordion>
      <Accordion classes={accordionClasses} defaultExpanded>
        <AccordionSummary
          classes={accordionSummaryClasses}
          expandIcon={
            <ExpandMoreIcon className={classes.expandMoreIcon} />
          }
        >
          <BuildIcon />{' '}
          <Typography className={classes.heading}>
            Management
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          classes={{ root: classes.accordionDetailsRoot }}
        >
          <MenuList
            className={classes.menuList}
            data-testid='navigation-menu'
          >
            {renderNavItems(DASHBOARD_NAVIGATION_SECTION.MANAGEMENT)}
          </MenuList>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default withStyles(styles)(StationNavigationMenu);
