import { createStyles, Theme } from '@material-ui/core/styles';

const errorColor = 'rgb(97, 26, 21)';

export default createStyles((theme: Theme) => ({
  root: {
    '& $container': {
      margin: 0,
    },
    '& svg': {
      alignSelf: 'center',
    },
  },
  alert: {
    width: '100%',
    borderRadius: theme.shape.borderRadius_sm,
    '& $container': {
      width: '100%',
      alignSelf: 'center',
    },
  },
  message: {
    flexDirection: 'row',
    width: '100%',
    '& button': {
      width: 100,
      padding: 0,
      color: errorColor,
      border: `1px solid ${errorColor}`,
    },
    '& a': {
      color: 'inherit',
      fontWeight: 'bold',
    },
    '& ul': {
      margin: '5px 0 0 0',
      paddingLeft: '0',
    },
  },
  flexColumn: {
    flexDirection: 'column',
  },
  container: {},
}));

export const AlertOverrides = createStyles((theme: Theme) => {
  const commonOverride = {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.text.primary,
    ...theme.typography.body2,
    '& > $icon': {
      color: theme.palette.text.secondary,
    },
  };
  return {
    root: {},
    message: {},
    standardSuccess: {
      ...commonOverride,
    },
    standardInfo: {
      ...commonOverride,
    },
    standardWarning: {
      ...commonOverride,
    },
    standardError: {
      ...commonOverride,
    },
    icon: {},
  };
});
