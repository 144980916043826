import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { INTERACTION } from '../../utils';
import { CurrentView } from '../Modules';
import OperatorToolOnboardingDesktop from './OperatorToolOnboarding.desktop';
import OperatorToolOnboardingSearch from './OperatorToolOnboarding.search';
import OperatorToolOnboardingMobile from './OperatorToolOnboarding.mobile';

interface Props {
  classes: { [key: string]: string };
  data: any;
  interaction?: INTERACTION;
  setPartialData: (timers: any) => void;
  currentView: CurrentView;
  goToPreviousPage: () => void;
}

const OperatorToolOnboardingModule: React.FC<Props> = ({
  classes,
  data,
  interaction,
  setPartialData,
  currentView,
  goToPreviousPage,
}) => {
  const selectViewToRender = () => {
    if (currentView === CurrentView.SEARCH) {
      return (
        <OperatorToolOnboardingSearch
          data={data}
          onboardingData={data.config.OT.ONBOARDING}
          interaction={interaction}
          setPartialData={setPartialData}
        />
      );
    }

    if (currentView === CurrentView.MOBILE) {
      return (
        <OperatorToolOnboardingMobile
          goToPreviousPage={goToPreviousPage}
          data={data}
          onboardingData={data.config.OT.ONBOARDING}
          interaction={interaction}
          setPartialData={setPartialData}
        />
      );
    }

    if (currentView === CurrentView.DESKTOP) {
      return (
        <OperatorToolOnboardingDesktop
          data={data}
          onboardingData={data.config.OT.ONBOARDING}
          interaction={interaction}
          setPartialData={setPartialData}
        />
      );
    }
    return <></>;
  };

  return <>{data ? selectViewToRender() : null}</>;
};

export default withStyles({})(OperatorToolOnboardingModule);
