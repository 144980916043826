import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Theme,
  createStyles,
  withStyles,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import CSDialogTitleWithIcon from '../../../primitives/csDialogTitleWithIcon/CSDialogTitleWithIcon';
import { DialogIcons } from '../../../primitives/csDialogTitleWithIcon/CSDialogTitleWithIconTypes';
import { CSMonoGridContainer } from '../../../primitives/csMonoGridContainer';
import { CSButton, CSTextField } from '../../../primitives';
import CSSelect from '../../../primitives/csSelect/CSSelect';

import { ExternalFulfilmentProviderConfig } from 'cloudsort-client';
import capitalize from 'lodash/capitalize';

import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

export enum AddEditDialogAction {
  ADD = 'ADD',
  EDIT = 'EDIT',
}

interface Props {
  classes: { [key: string]: string };
  closeDialog: () => void;
  onSave: (
    action: AddEditDialogAction,
    provider: ExternalFulfilmentProviderConfig,
  ) => void;
  providerToEdit?: ExternalFulfilmentProviderConfig;
  availableProviderTypes: string[];
}

const AddEditFulfilmentProviderDialog = ({
  classes,
  closeDialog,
  onSave,
  providerToEdit,
  availableProviderTypes,
}: Props) => {
  const [dialogAction, setDialogAction] =
    useState<AddEditDialogAction>(AddEditDialogAction.ADD);

  const formSchema = z.object({
    providerType: z.string().min(2),
    host: z.union([z.literal(''), z.string().trim().url()]),
    token: z.string().min(2),
  });

  type FormSchemaType = z.infer<typeof formSchema>;

  const getDefaultValues = () => {
    return {
      providerType: providerToEdit ? providerToEdit.id! : '',
      host: providerToEdit?.properties?.host
        ? providerToEdit.properties.host
        : '',
      token: providerToEdit?.properties?.token
        ? providerToEdit.properties.token
        : '',
    };
  };

  const { control, handleSubmit, reset, errors } =
    useForm<FormSchemaType>({
      resolver: zodResolver(formSchema),
      mode: 'onChange',
      defaultValues: getDefaultValues(),
    });

  const onSubmit = async (data: FormSchemaType) => {
    //reset form
    reset();

    const properties: { [key: string]: string } = {
      token: data.token,
    };

    if (data.host) {
      properties.host = data.host;
    }

    onSave(dialogAction, {
      id: data.providerType,
      properties,
    });
    closeDialog();
  };

  const onCancel = () => {
    closeDialog();
  };

  useEffect(() => {
    if (providerToEdit) {
      setDialogAction(AddEditDialogAction.EDIT);

      reset();
    } else {
      setDialogAction(AddEditDialogAction.ADD);
    }
  }, [providerToEdit, reset]);

  return (
    <Dialog open data-testid='add-edit-provider-dialog'>
      <DialogTitle>
        <CSDialogTitleWithIcon
          title={`${capitalize(dialogAction)} Provider`}
          icon={
            dialogAction === AddEditDialogAction.ADD
              ? DialogIcons.ADD
              : DialogIcons.EDIT
          }
        />
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <CSMonoGridContainer>
            <Controller
              name='providerType'
              control={control}
              render={(field) => (
                <CSSelect
                  label='Provider Type'
                  placeholder='Provider Type'
                  containerSize='fullHorizontal'
                  error={!!errors.providerType?.message}
                  helperText={errors.providerType?.message}
                  {...field}
                >
                  {availableProviderTypes?.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </CSSelect>
              )}
            />
            <Controller
              name='host'
              control={control}
              render={(field) => (
                <CSTextField
                  label='Host (optional)'
                  placeholder='Host (optional)'
                  containerSize='fullHorizontal'
                  error={!!errors.host?.message}
                  helperText={errors.host?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name='token'
              control={control}
              render={(field) => (
                <CSTextField
                  label='Token'
                  placeholder='Token'
                  containerSize='fullHorizontal'
                  error={!!errors.token?.message}
                  helperText={errors.token?.message}
                  {...field}
                />
              )}
            />
          </CSMonoGridContainer>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <CSButton
            variant='outlined'
            color='secondary'
            className={classes.containedButton}
            onClick={onCancel}
          >
            Cancel
          </CSButton>
          <CSButton
            variant='contained'
            color='secondary'
            className={classes.containedButton}
            type='submit'
          >
            {dialogAction === AddEditDialogAction.ADD
              ? 'Add'
              : 'Save'}
          </CSButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    dialogActions: {
      padding: 24,
    },
  })),
)(AddEditFulfilmentProviderDialog);
