import {
  ShiftNested,
  ShiftPlanPreset,
  ShiftPlanPresetRecurrenceEnum,
  ShiftPlanPresetsApi,
  ShiftPlanPresetShiftPlanTypeEnum,
} from 'cloudsort-client';
import axios from '../utils/axios';
import EphemeralStateService from './EphemeralState.service';

class StationPlanner {
  private api: ShiftPlanPresetsApi;

  constructor() {
    this.api = new ShiftPlanPresetsApi(
      undefined,
      undefined,
      axios as any,
    );
  }

  public async getAll(params: { page: number; pageSize: number }) {
    return this.api.shiftPlanPresetsList({
      station: EphemeralStateService.getMyStationId(),
      page: params.page,
      pageSize: params.pageSize,
    });
  }

  public async getById(id: number) {
    return this.api.shiftPlanPresetsRead({
      id,
    });
  }

  public async createShiftPlanPreset(
    name: string,
    recurrence?: ShiftPlanPresetRecurrenceEnum[],
    force?: boolean,
    start_time: string = new Date().toISOString(),
    end_time: string = new Date().toISOString(),
    packages_per_container: number = 100,
    shifts: ShiftNested[] = [],
  ) {
    return this.api.shiftPlanPresetsCreate({
      data: {
        shift_plan_type: ShiftPlanPresetShiftPlanTypeEnum.PRESET,
        station: EphemeralStateService.getMyStationId(),
        valid_on: new Date().toISOString(),
        start_time,
        end_time,
        packages_per_container,
        shifts,
        name,
        recurrence,
      },
      force,
    });
  }

  public async updateShiftPlanPreset(
    id: number,
    data: ShiftPlanPreset,
    force?: boolean,
  ) {
    return this.api.shiftPlanPresetsUpdate({
      id,
      data,
      force,
    });
  }

  public async deleteShiftPlanPreset(id: number) {
    return this.api.shiftPlanPresetsDelete({
      id,
    });
  }
}

export default new StationPlanner();
