import React, { useEffect, useState, useCallback } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography,
  AlertBanner,
  CSButton,
  CSTextField,
} from '../primitives';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import Layout from '../layout/Layout';
import styles from './routes.styles';
import DndTable, { Actions } from '../dndTable/DndTable';
import ConfirmationDialog, {
  Transition,
} from '../confirmationDialog/ConfirmationDialog';
import ErrorHandler from '../../utils/ErrorHandler';
import Box from '@material-ui/core/Box';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import configurationUtils from '../../utils/configurationUtils';
import { common } from '../../utils/strings';
import SingleDetail from '../primitives/singleDetail/SingleDetail';
import debounce from 'lodash/debounce';
import browserHistory from '../../utils/browserHistory';
import { AuthRoutes } from '../../interfaces/routes';
import DockDoorService from '../../services/DockDoors.service';

// Icons
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import CreateIcon from '@material-ui/icons/Create';

// Types
import {
  Route,
  NestedRoute2Station,
  Location,
  Route2StationDetails,
  StaffPermissionPermissionsEnum,
  DockDoorDetails,
} from 'cloudsort-client';
import { Link } from 'react-router-dom';
import { Column, TypeAheadItem } from '../../interfaces/components';
import { SingleValue } from 'react-select';
import { AxiosError } from 'axios';

// Services
import RoutesService from '../../services/Routes.service';
import StationsService from '../../services/Stations.service';
import PermissionsService from '../../services/Permissions.service';
import EphemeralStateService from '../../services/EphemeralState.service';
import { Helmet } from 'react-helmet';
import { noOptionsMessage } from '../asyncSelect/utils';
import { Delete } from '@material-ui/icons';
import CSSectionTitleSeparator from '../primitives/csSectionTitleSeparator/CSSectionTitleSeparator';
import useStationId from '../../hooks/useStationId';
import { CSSingleDetailMultiColumnContainer } from '../primitives/singleDetail/singleDetailMultiColumnContainer';
import CSAsyncSelect from '../primitives/csAsyncSelect/CSAsyncSelect';
import { CSMonoGridContainer } from '../primitives/csMonoGridContainer';
import CSDialogTitleWithIcon from '../primitives/csDialogTitleWithIcon/CSDialogTitleWithIcon';
import { DialogIcons } from '../primitives/csDialogTitleWithIcon/CSDialogTitleWithIconTypes';
import { IMatch } from '../../utils/commonTypes';
import { routerDataToTableData } from './routesUtils';
import CSBreadcrumbs from '../primitives/CSBreadcrumbs/CSBreadcrumbs';

interface Props {
  classes: { [key: string]: string };
  match: IMatch;
}

interface DialogParams {
  msg: string;
  id: number | string;
  data?: Route2StationDetails;
}

const RouteDetailsComponent: React.FC<Props> = ({
  classes,
  match,
}) => {
  // Get labels on each render cycle
  const COLUMNS: Column[] = [
    {
      id: 'station_name_url',
      label: configurationUtils.getPageTitle(true, 'STOP'),
      width: 250,
      align: 'left',
    },
    {
      id: 'fmcs',
      label: 'Carrier',
      width: 150,
      align: 'left',
    },
  ];

  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [routeData, setRouteData] = useState<Route>();
  const [selectedStation, setSelectedStation] =
    useState<TypeAheadItem | null>();
  // It's possible for a user to open route details page without station id in the url.
  //In this case we need to get it from the route data and set it in the url. The web app (navigation bar) will use it to display the station name and show the correct navigation menu.
  useStationId(routeData?.station);
  const [defaultStationOptions, setDefaultStationOptions] =
    useState<TypeAheadItem[]>();
  const [deleteDialogParams, setDeleteDialogParams] = useState<
    DialogParams | undefined
  >();
  const [addDialogParams, setAddDialogParams] = useState<
    DialogParams | undefined
  >();
  const [prevStationOrder, setPrevStationOrder] = useState<Route>();

  const [showEditDialog, setShowEditDialog] =
    useState<boolean>(false);
  const [editName, setEditName] = useState<string>();
  const [editDockDoor, setEditDockDoor] = useState<TypeAheadItem>();
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);

  const isReorderInProgress = !!prevStationOrder;
  const showDialog = !!deleteDialogParams || !!addDialogParams;

  const stopsLabels = {
    singular: configurationUtils.getPageTitle(true, 'STOP'),
    plural: configurationUtils.getPageTitle(false, 'STOP'),
  };

  const getRouteData = async () => {
    setShowProgress(true);
    try {
      const { data } = await RoutesService.getById(match.params.id);
      setRouteData(data);
      setEditName(data.name ? data.name : undefined);
      setEditDockDoor({
        label: data.default_dockdoor_name || '',
        value: String(data.default_dockdoor || ''),
      });
    } catch (e) {
      handleError(e as AxiosError);
    }
    setShowProgress(false);
  };

  const onDragEnd = (
    recentlyOrderedStations: NestedRoute2Station[],
  ) => {
    const route = { ...(routeData as Route) };
    if (!prevStationOrder) {
      setPrevStationOrder({ ...(route as Route) });
    }
    route.route2stations = recentlyOrderedStations;
    setRouteData({ ...(route as Route) });
  };

  const onDeleteIconClick = (id: number | string | null) => {
    const station = routeData?.route2stations!.find(
      (stationData: NestedRoute2Station) =>
        stationData.id?.toString() === id,
    );
    if (id && station) {
      setDeleteDialogParams({
        msg: `Are you sure you want to delete ${
          station.station_name
        } ${configurationUtils.getPageTitle(true, 'STOP')}?`,
        id,
      });
    }
  };

  const onDialogCancel = () => {
    setDeleteDialogParams(undefined);
    setAddDialogParams(undefined);
  };

  const onDeleteDialogOk = () => {
    if (deleteDialogParams?.id) {
      setShowProgress(true);
      RoutesService.removeRouteToStationsById(
        deleteDialogParams?.id as number,
      )
        .then(() => {
          getRouteData();
        })
        .catch((e) => {
          handleError(e as AxiosError);
        })
        .finally(() => {
          setDeleteDialogParams(undefined);
          setShowProgress(false);
        });
    }
  };

  const onReorder = (save: boolean) => {
    if (save && routeData) {
      setShowProgress(true);
      RoutesService.setRoutes(routeData.id!, routeData)
        .then((res) => {
          setRouteData(res.data);
        })
        .catch((e) => {
          handleError(e as AxiosError);
        })
        .finally(() => {
          setShowProgress(false);
        });
    } else {
      setRouteData({ ...(prevStationOrder as Route) });
    }
    setPrevStationOrder(undefined);
  };

  const addStation = () => {
    if (selectedStation && routeData) {
      setShowProgress(true);
      RoutesService.addRouteToStations({
        station: parseInt(selectedStation.value),
        route: routeData?.id!,
      })
        .then(() => {
          getRouteData();
        })
        .catch((e) => {
          handleError(e as AxiosError);
        })
        .finally(() => {
          setSelectedStation(null);
          setShowProgress(false);
          setAddDialogParams(undefined);
          fetchDefaultStationOptions();
        });
    }
  };

  const onAddClick = () => {
    if (selectedStation && routeData) {
      setShowProgress(true);
      RoutesService.getRouteToStationsById(
        parseInt(selectedStation.value),
      )
        .then((res) => {
          const results = res.data.results;
          if (results.length) {
            setShowProgress(false);
            setAddDialogParams({
              msg: `${results[0].station_name} is already in use by other route.`,
              id: -1,
              data: results[0],
            });
          } else {
            addStation();
          }
        })
        .catch((e) => {
          handleError(e as AxiosError);
          setShowProgress(false);
        });
    }
  };

  const patchStation = () => {
    if (routeData && addDialogParams?.data) {
      setShowProgress(true);
      const data = { ...addDialogParams?.data };
      data.route = routeData.id;
      RoutesService.updateRouteToStationsById(
        addDialogParams?.data?.id!,
        data,
      )
        .then(() => {
          getRouteData();
        })
        .catch((e) => {
          handleError(e as AxiosError);
        })
        .finally(() => {
          setSelectedStation(null);
          setAddDialogParams(undefined);
          setShowProgress(false);
        });
    }
  };

  const formatStationOptions = (data: Location[]) => {
    return data.map((location: Location) => {
      return {
        value: location.id?.toString() || '',
        label: location.zipcode
          ? `${location.name} - Zipcode: ${location.zipcode}`
          : `${location.name}`,
      };
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadOptions = useCallback(
    debounce((inputValue: string, callback: any) => {
      StationsService.getByName(
        inputValue,
        routeData?.id,
        undefined,
        undefined,
        EphemeralStateService.getMyStationId(),
      )
        .then((data) => {
          callback(formatStationOptions(data.data.results));
        })
        .catch((e) => {
          handleError(e as AxiosError);
        });
    }, 500),
    [routeData],
  );

  const handleOnStationSelect = (
    option: SingleValue<TypeAheadItem>,
  ) => {
    if (option) {
      setSelectedStation(option as TypeAheadItem);
    } else {
      setSelectedStation(null);
    }
  };

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e as AxiosError));
  };

  const fetchDefaultStationOptions = async () => {
    const { data } = await StationsService.getByName(
      undefined,
      match.params.id,
      10,
      undefined,
      EphemeralStateService.getMyStationId(),
    );
    setDefaultStationOptions(formatStationOptions(data.results));
  };

  const onAfterDialogClose = (update = false) => {
    setShowEditDialog(false);
    setError(undefined);

    if (!update) {
      setEditName(routeData!.name!);
      setEditDockDoor({
        label: routeData?.default_dockdoor_name || '',
        value: String(routeData?.default_dockdoor || ''),
      });
    }
  };

  const editHandler = async () => {
    setShowProgress(true);
    try {
      const { data } = await RoutesService.setRoutes(routeData!.id!, {
        ...routeData!,
        name: editName,
        default_dockdoor: Number(editDockDoor?.value),
      });
      setRouteData(data);
      setEditName(data.name ? data.name : undefined);
      setEditDockDoor({
        label: data?.default_dockdoor_name || '',
        value: String(data?.default_dockdoor || ''),
      });
      onAfterDialogClose(true);
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadDDOptions = useCallback(
    debounce((inputValue: string, callback: any) => {
      DockDoorService.search(inputValue)
        .then((data) => {
          callback(
            data.data.results.map((dataEl: DockDoorDetails) => {
              return {
                value: dataEl.id,
                label: dataEl.name,
              };
            }),
          );
        })
        .catch((e) => {
          handleError(e as AxiosError);
        });
    }, 500),
    [],
  );
  const renderEditDialog = () => {
    return (
      <Dialog
        classes={{
          paperScrollPaper: classes.dialogRoot,
          paper: classes.dialogPaper,
        }}
        open={showEditDialog}
        TransitionComponent={Transition}
        onClose={() => {
          onAfterDialogClose();
        }}
      >
        <DialogTitle>
          <CSDialogTitleWithIcon
            icon={DialogIcons.EDIT}
            title={`Edit ${configurationUtils.getPageTitle(
              true,
              'ROUTE',
            )} ${routeData!.id!}`}
          />
        </DialogTitle>
        <DialogContent>
          {error && (
            <AlertBanner
              severity='error'
              alertTitle={'Error'}
              alertMsg={error}
            />
          )}
          <Grid container>
            <CSMonoGridContainer>
              <CSTextField
                data-testid={'route-dialog-name'}
                autoFocus
                label='Name'
                value={editName || ''}
                onChange={(e) => {
                  setEditName(e.target.value);
                }}
                onClear={() => setEditName('')}
                containerSize='fullHorizontal'
              />
              {configurationUtils.isModuleActive('DOCK_DOOR') ? (
                <CSAsyncSelect<TypeAheadItem>
                  label={`Default ${configurationUtils.getModuleLabel(
                    true,
                    'DOCK_DOOR',
                  )}`}
                  data-testid='select-dock-door'
                  isClearable
                  cacheOptions
                  loadOptions={loadDDOptions}
                  onChange={(option) => {
                    setEditDockDoor(option as TypeAheadItem);
                  }}
                  containerSize='fullHorizontal'
                  isDisabled={!!error}
                  placeholder={'Start Typing...'}
                  value={
                    editDockDoor?.value === '' ? null : editDockDoor
                  }
                  menuPortalTarget={document.body}
                  noOptionsMessage={noOptionsMessage}
                />
              ) : undefined}
            </CSMonoGridContainer>
          </Grid>
        </DialogContent>

        <DialogActions className={classes.dialogActions}>
          <CSButton
            variant='outlined'
            color='secondary'
            data-testid={'route-dialog-cancel'}
            onClick={() => {
              onAfterDialogClose();
            }}
          >
            Cancel
          </CSButton>
          <CSButton
            variant='contained'
            color='secondary'
            onClick={() => {
              editHandler();
            }}
            data-testid={'route-dialog-update'}
            disabled={!editName}
          >
            Update
          </CSButton>
        </DialogActions>
      </Dialog>
    );
  };

  const processDelete = async () => {
    setShowDeleteConfirmation(false);

    try {
      await RoutesService.delete(routeData!.id!);
      browserHistory.push(AuthRoutes.ROUTE);
    } catch (e) {
      handleError(e as AxiosError);
    }
  };

  useEffect(() => {
    PermissionsService.redirectIfNoPermission(
      StaffPermissionPermissionsEnum.ROUTE_READ,
    );

    if (!routeData) {
      getRouteData();
    }
    fetchDefaultStationOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hintBannerMessage = `Edit ${configurationUtils.getPageTitle(
    true,
    'STOP',
  )} order: ${
    !isReorderInProgress
      ? `Drag and drop rows to reorder ${configurationUtils.getPageTitle(
          false,
          'STOP',
        )}.`
      : 'Add and delete functions are disabled until reorder is done.'
  }`;

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort -
${configurationUtils.getPageTitle(true, 'ROUTE')} Details for ${
            routeData?.name || ''
          }`}
        </title>
      </Helmet>
      <Layout navCurrent='ROUTE'>
        {showProgress && <ProgressIndicator />}
        {showEditDialog && renderEditDialog()}
        <ConfirmationDialog
          dataTestIdPrefix={'route-details-'}
          data-testid={'routes-details-confirmation-dialog'}
          title={
            deleteDialogParams
              ? `Delete ${stopsLabels.singular}`
              : `Add ${stopsLabels.singular}`
          }
          msg={
            deleteDialogParams
              ? deleteDialogParams.msg
              : addDialogParams?.msg
          }
          primaryActionLabel={deleteDialogParams ? 'Delete' : 'Add'}
          onPrimaryAction={
            deleteDialogParams ? onDeleteDialogOk : addStation
          }
          secondaryActionLabel={deleteDialogParams ? '' : 'Move'}
          onSecondaryAction={patchStation}
          cancelLabel={'Cancel'}
          onCancel={onDialogCancel}
          isOpen={showDialog}
        />
        <ConfirmationDialog
          dataTestIdPrefix={'delete-route-confirmation-dialog'}
          title={
            'Delete ' + configurationUtils.getPageTitle(true, 'ROUTE')
          }
          msg={`Are you sure you want to delete ${configurationUtils.getPageTitle(
            true,
            'ROUTE',
          )} ${routeData?.name}?`}
          primaryActionLabel={'Confirm'}
          onPrimaryAction={() => {
            processDelete();
          }}
          cancelLabel={'Cancel'}
          onCancel={() => {
            setShowDeleteConfirmation(false);
          }}
          isOpen={showDeleteConfirmation}
        />

        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <Box mb={5}>
              <CSBreadcrumbs
                breadcrumbs={[
                  {
                    label: 'Routes',
                    link:
                      AuthRoutes.ROUTE +
                      '?stationId=' +
                      // TODO: route does not include the station data
                      // Check CLOUD-3077
                      EphemeralStateService.getMyStationId(),
                  },
                  {
                    label: `${routeData?.name}`,
                    selected: true,
                  },
                ]}
              />
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          className={classes.gridGutter}
          alignItems='center'
        >
          <Grid item xs={12} sm={6}>
            <Typography variant='h3' component='h2'>
              {`${routeData?.name || ''}`}
            </Typography>
          </Grid>
          {PermissionsService.hasPermission(
            StaffPermissionPermissionsEnum.ROUTE_WRITE,
          ) && (
            <>
              <Grid
                item
                xs={12}
                sm={6}
                className={classes.nonMobileAlignRight}
              >
                <CSButton
                  data-testid='edit-route-btn'
                  color='secondary'
                  variant='outlined'
                  disabled={!!error}
                  onClick={(e) => {
                    e.preventDefault();
                    setShowEditDialog(true);
                  }}
                  startIcon={<CreateIcon />}
                >
                  Edit
                </CSButton>
                <CSButton
                  data-testid={'delete-route-btn'}
                  variant='outlined'
                  color='secondary'
                  className={classes.buttonSeparation}
                  disabled={!!error}
                  onClick={(e) => {
                    e.preventDefault();
                    setShowDeleteConfirmation(true);
                  }}
                  startIcon={<Delete />}
                >
                  Delete
                </CSButton>
              </Grid>
            </>
          )}
        </Grid>
        <CSSectionTitleSeparator topMargin={5} />
        <Grid container spacing={2} className={classes.gridGutter}>
          <Grid item xs={12}>
            <CSSingleDetailMultiColumnContainer
              disableStandardHeight
              columns={4}
              elements={[
                <SingleDetail
                  inline
                  label={
                    configurationUtils.getPageTitle(true, 'ROUTE') +
                    ' ID'
                  }
                  value={routeData?.id || common.emptyValue}
                />,
                <SingleDetail
                  inline
                  label={
                    configurationUtils.getPageTitle(false, 'STOP') +
                    ' Count'
                  }
                  value={
                    routeData?.stations_count || common.emptyValue
                  }
                />,
                <SingleDetail
                  inline
                  labelColumns={8}
                  valueColumns={4}
                  label={
                    configurationUtils.getPageTitle(
                      false,
                      'OUTBOUND_LOAD',
                    ) + ' Count'
                  }
                  value={
                    routeData?.outboundloads_count ||
                    common.emptyValue
                  }
                />,
                configurationUtils.isModuleActive('DOCK_DOOR') ? (
                  <SingleDetail
                    inline
                    labelColumns={8}
                    valueColumns={4}
                    label={`Default ${configurationUtils.getModuleLabel(
                      true,
                      'DOCK_DOOR',
                    )}`}
                    value={
                      routeData?.default_dockdoor_name ? (
                        <Typography
                          color='tertiary'
                          title={`Go to ${routeData?.default_dockdoor_name}`}
                          component={(props) => {
                            return (
                              <Link
                                to={`${AuthRoutes.DOCK_DOOR}/${routeData?.default_dockdoor}`}
                                {...props}
                              >
                                {props.children}
                              </Link>
                            );
                          }}
                        >
                          {routeData?.default_dockdoor_name}
                        </Typography>
                      ) : (
                        common.emptyValue
                      )
                    }
                  />
                ) : undefined,
              ]}
            />
          </Grid>
        </Grid>

        {!error && !!routeData?.route2stations?.length && (
          <AlertBanner
            data-testid='routes-details-info-banner'
            className={classes.banner}
            severity='info'
            alertMsg={hintBannerMessage}
          />
        )}

        <div
          className={classes.detailHeader}
          data-testid='routes-details'
        >
          <Typography variant='h6' component='h3'>
            Add {stopsLabels.singular} to Route
          </Typography>
          {isReorderInProgress && (
            <div>
              <CSButton
                variant='outlined'
                color='secondary'
                data-testid='routes-details-cancel-button'
                fullWidth={false}
                onClick={(e) => {
                  e.preventDefault();
                  setError(undefined);
                  onReorder(false);
                }}
              >
                Cancel
              </CSButton>
              <CSButton
                variant='contained'
                color='secondary'
                data-testid='routes-details-done-button'
                className={classes.buttonSeparation}
                fullWidth={false}
                onClick={(e) => {
                  e.preventDefault();
                  onReorder(true);
                }}
              >
                Save
              </CSButton>
            </div>
          )}
        </div>
        <Grid
          container
          wrap='nowrap'
          data-testid='routes-details-add-form-holder'
        >
          <Grid item xs={6} sm={6} md={4}>
            {routeData && (
              <CSAsyncSelect<TypeAheadItem>
                data-testid={'routes-details-async-select'}
                isClearable
                cacheOptions
                containerSize='fullHorizontal'
                defaultOptions={defaultStationOptions}
                loadOptions={loadOptions}
                onChange={handleOnStationSelect}
                isDisabled={isReorderInProgress}
                noOptionsMessage={noOptionsMessage}
                placeholder={`Find ${configurationUtils.getPageTitle(
                  false,
                  'STOP',
                )} by name or zipcode`}
                value={selectedStation}
              />
            )}
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            md={8}
            container
            justifyContent='flex-end'
            alignItems='center'
          >
            {PermissionsService.hasPermission(
              StaffPermissionPermissionsEnum.ROUTE_WRITE,
            ) && (
              <CSButton
                variant='contained'
                color={{
                  buttonColor: 'secondary',
                  iconColor: 'primary',
                }}
                data-testid='routes-details-add-button'
                fullWidth={false}
                disabled={isReorderInProgress || !selectedStation}
                onClick={(e) => {
                  e.preventDefault();
                  onAddClick();
                }}
                startIcon={<AddBoxOutlinedIcon />}
              >
                Add {stopsLabels.singular}
              </CSButton>
            )}
          </Grid>
        </Grid>
        <Box mt={2} />
        {error && (
          <AlertBanner
            data-testid='routes-details-error-banner'
            className={classes.banner}
            severity='error'
            alertTitle={'Error'}
            alertMsg={error}
          />
        )}
        {!error && !routeData?.route2stations?.length && (
          <AlertBanner
            data-testid='routes-details-info-banner'
            className={classes.banner}
            severity='info'
            alertTitle={
              'There is no information to display at the moment'
            }
          />
        )}
        {!error && !!routeData?.route2stations?.length && (
          <DndTable
            title={`${configurationUtils.getPageTitle(
              true,
              'ROUTE',
            )} ${configurationUtils.getPageTitle(false, 'STOP')}`}
            columns={COLUMNS}
            data={routerDataToTableData(
              routeData,
              EphemeralStateService,
              classes,
            )}
            onAfterDragEnd={(items) =>
              onDragEnd(items as unknown as NestedRoute2Station[])
            }
            isDragDisabled={
              !PermissionsService.hasPermission(
                StaffPermissionPermissionsEnum.ROUTE_WRITE,
              )
            }
            action={Actions.DELETE}
            onActionClick={onDeleteIconClick}
            isActionDisabled={isReorderInProgress}
            dataTestIdPrefix={'routes-details'}
          />
        )}
      </Layout>
    </>
  );
};

export default withStyles(styles)(RouteDetailsComponent);
