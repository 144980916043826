import { Bounds } from '../components/map/MapView';
import EphemeralStateService from './EphemeralState.service';
import {
  LocationStationTypeEnum,
  Location,
  StationDetails,
  Station,
  StationRoles,
} from 'cloudsort-client';

export const ACCESS_TOKEN_KEY = 'x-access-token';
export const REFRESH_TOKEN_KEY = 'x-refresh-token';
export const MAP_VIEW_STATE = 'map-view-state';
export const MY_STATIONS_DATA = 'my-stations-data';
export const WEATHER_DATA = 'weather-data';
export const SYSTEM_DATE = 'system-date';
export const LAST_HIT_DATE = 'last-hit-date';
export const PERMISSIONS_MATRIX = 'permissions-matrix';
export const FULL_NAME = 'full-name';
export const DASHBOARD_CARDS_LAYOUT = 'dashboard-cards-layout';
export const AFTER_LOGIN_URL = 'after-login-url';
export const IS_CUSTOMER = 'is-customer';

export interface MapState {
  bounds?: Bounds;
  zoom?: number;
  center?: any;
  filterByType?: LocationStationTypeEnum;
  filterByRoute?: number;
  routesLocation?: Location;
}

class LocalStorage {
  public setAccessToken(token?: string) {
    token && localStorage.setItem(ACCESS_TOKEN_KEY, token);
  }

  public setRefreshToken(token?: string) {
    token && localStorage.setItem(REFRESH_TOKEN_KEY, token);
  }

  public setMapViewState(mapState: MapState) {
    localStorage.setItem(MAP_VIEW_STATE, JSON.stringify(mapState));
  }

  public setMyFullName(name: string) {
    localStorage.setItem(FULL_NAME, JSON.stringify(name));
  }

  public setIsCustomer(isCustomer: boolean) {
    localStorage.setItem(IS_CUSTOMER, JSON.stringify(isCustomer));
  }

  public setDashboardsCardsLayout(layout: string[]) {
    localStorage.setItem(
      DASHBOARD_CARDS_LAYOUT,
      JSON.stringify(layout),
    );
  }

  public setWeatherInfo(weatherData: any) {
    localStorage.setItem(WEATHER_DATA, JSON.stringify(weatherData));
  }

  public setMyStationsData(stationsData: Station[]) {
    localStorage.setItem(
      MY_STATIONS_DATA,
      JSON.stringify(stationsData),
    );
  }

  public setSystemDate(date?: Date) {
    localStorage.setItem(
      SYSTEM_DATE,
      JSON.stringify((date || new Date()).toISOString()),
    );
  }

  public setLastHitDate() {
    const lastHitDateStr = localStorage.getItem(LAST_HIT_DATE);

    //remove system date if no activity (hit) in the last 60 minutes
    if (
      !lastHitDateStr ||
      new Date().getTime() -
        new Date(lastHitDateStr.replace(/"/g, '')).getTime() >
        1000 * 60 * 60
    ) {
      this.removeSystemDate();
    }

    localStorage.setItem(
      LAST_HIT_DATE,
      JSON.stringify(new Date().toISOString()),
    );
  }

  public setPermissionsMatrix(matrix: StationRoles) {
    localStorage.setItem(
      PERMISSIONS_MATRIX,
      JSON.stringify(matrix || []),
    );
  }

  public setAfterLoginUrl(url: string) {
    localStorage.setItem(AFTER_LOGIN_URL, JSON.stringify(url));
  }

  public getMapViewState() {
    const state = localStorage.getItem(MAP_VIEW_STATE);
    if (state) {
      return JSON.parse(state);
    }
    return {};
  }

  public getAccessToken(): string | null {
    return localStorage.getItem(ACCESS_TOKEN_KEY);
  }

  public getMyStationData() {
    const stationId = EphemeralStateService.getMyStationId();
    const stations = localStorage.getItem(MY_STATIONS_DATA);
    if (stations && stationId) {
      const stationsObj: StationDetails[] = JSON.parse(stations);
      return (
        stationsObj.find((station) => station.id === stationId) || {}
      );
    }
    return {};
  }

  public getMyFullName(): string | null {
    const name = localStorage.getItem(FULL_NAME);
    if (name) {
      return JSON.parse(name);
    }
    return null;
  }

  public getIsCustomer(): boolean | null {
    const isCustomer = localStorage.getItem(IS_CUSTOMER);
    if (isCustomer) {
      return JSON.parse(isCustomer);
    }
    return null;
  }

  public getDashboardsCardsLayout() {
    const layout = localStorage.getItem(DASHBOARD_CARDS_LAYOUT);
    if (layout) {
      return JSON.parse(layout);
    }
    return null;
  }

  public getMyStationsData(): Station[] | null {
    const stations = localStorage.getItem(MY_STATIONS_DATA);
    if (stations) {
      return JSON.parse(stations);
    }
    return null;
  }

  public getRefreshToken(): string | null {
    return localStorage.getItem(REFRESH_TOKEN_KEY);
  }

  public getWeatherInfo(): any | null {
    const data = localStorage.getItem(WEATHER_DATA);
    if (data) return JSON.parse(data);
    return null;
  }

  public getSystemDate(): Date {
    const dateStr = localStorage.getItem(SYSTEM_DATE);

    if (dateStr) {
      return new Date(dateStr.replace(/"/g, ''));
    }
    return new Date();
  }

  public getMyStationPermissionsMatrix(): any | null {
    const data = localStorage.getItem(PERMISSIONS_MATRIX);
    return data ? JSON.parse(data) : [];
  }

  public getAfterLoginUrl(): string | null {
    const data = localStorage.getItem(AFTER_LOGIN_URL);
    return data ? JSON.parse(data) : null;
  }

  public removeAccessToken(): void {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
  }

  public removeRefreshToken(): void {
    localStorage.removeItem(REFRESH_TOKEN_KEY);
  }

  public removeMyStationsData() {
    localStorage.removeItem(MY_STATIONS_DATA);
  }

  public removeMapViewState() {
    localStorage.removeItem(MAP_VIEW_STATE);
  }

  public removeWeatherInfo(): void {
    localStorage.removeItem(WEATHER_DATA);
  }

  public removeSystemDate(): void {
    localStorage.removeItem(SYSTEM_DATE);
  }

  public removeMyPermissionsMatrix(): void {
    localStorage.removeItem(PERMISSIONS_MATRIX);
  }

  public removeFullName(): void {
    localStorage.removeItem(FULL_NAME);
  }

  public removeAfterLoginUrl(): void {
    localStorage.removeItem(AFTER_LOGIN_URL);
  }
}

export default new LocalStorage();
