import React, { useEffect, useState } from 'react';
import CSAsyncSelect from '../csAsyncSelect/CSAsyncSelect';
import { TypeAheadItem } from '../../../interfaces/components';
import { noOptionsMessage } from '../../asyncSelect/utils';
import { allCountries } from './csCountryUtils';

interface Props {
  value: string;
  setValue: (countryCode: string) => void;
  label?: string;
  className?: string;
  isDisabled?: boolean;
}

const CSCountrySelect: React.FC<Props> = ({
  value,
  setValue,
  label = 'Country',
  className,
  isDisabled,
}) => {
  const [typeAheadvalue, setTypeAheadValue] =
    useState<TypeAheadItem>();

  useEffect(() => {
    const countryName = allCountries.find(
      (country) => country.code === value,
    )?.name;

    if (countryName) {
      setTypeAheadValue({ label: countryName, value });
    }
  }, [value]);

  const defaultOptions = allCountries.map((country) => {
    return { label: country.name, value: country.code };
  });

  const loadOptions = (inputValue: string) =>
    Promise.resolve(
      allCountries
        .filter(
          (country) =>
            country.name
              .toLocaleLowerCase()
              .includes(inputValue.toLowerCase()) ||
            country.code === inputValue.trim().toUpperCase(),
        )
        .map((country) => {
          return { label: country.name, value: country.code };
        }),
    );

  return (
    <CSAsyncSelect<TypeAheadItem>
      className={className}
      containerSize='fullHorizontal'
      isClearable
      label={label}
      isDisabled={isDisabled}
      loadOptions={loadOptions}
      defaultOptions={defaultOptions}
      onChange={(option) => {
        setValue(option?.value || 'US');
      }}
      placeholder={'Search for countries...'}
      value={typeAheadvalue}
      menuPortalTarget={document.body}
      noOptionsMessage={noOptionsMessage}
    />
  );
};

export default CSCountrySelect;
