import {
  ReportsApi,
  ReportDatasetEventIntervalsOriginEventEnum,
  ReportDatasetEventIntervalsTargetEventEnum,
  ReportDatasetExpectedInfluxInfluxTypeEnum,
  NestedProcessStepSerializerPackageProcessStepEnum,
  NestedProcessStepSerializerContainerProcessStepEnum,
  ReportDatasetPerformanceMetricEnum,
  ReportDatasetPerformanceTimeEnum,
} from 'cloudsort-client';
import axios from '../utils/axios';
import dateUtils from './utils/date';
import LocalStorageService from './LocalStorage.service';
import EphemeralStateService from './EphemeralState.service';

export interface ReportFilters {
  owner?: number[];
  carrier?: number[];
  destination?: number[];
  route?: number[];
}

class Reports {
  private api: ReportsApi;

  constructor() {
    this.api = new ReportsApi(undefined, undefined, axios as any);

    this.getDwellTimes = this.getDwellTimes.bind(this);
    this.getPerformanceStats = this.getPerformanceStats.bind(this);
    this.getExpectedByInfluxType =
      this.getExpectedByInfluxType.bind(this);
    this.getProcessstepsList = this.getProcessstepsList.bind(this);
  }

  public async getProcessstepsList(
    processSteps: (
      | NestedProcessStepSerializerContainerProcessStepEnum
      | NestedProcessStepSerializerPackageProcessStepEnum
    )[],
    metric: ReportDatasetExpectedInfluxInfluxTypeEnum,
    resource: 'ALL' | 'PACKAGES' | 'CONTAINERS',
    area?: number,
    filters?: ReportFilters,
  ) {
    return this.api.reportsDatasetsProcessstepsList({
      processSteps,
      metric,
      resource,
      area,
      fromDate: dateUtils.fromDate(),
      toDate: dateUtils.toDate(),
      groupBy: '15m',
      station: EphemeralStateService.getMyStationId(),
      ...(filters as any),
    });
  }

  public async getExpectedByInfluxType(
    influxType: ReportDatasetExpectedInfluxInfluxTypeEnum,
  ) {
    return this.api.reportsDatasetsExpectedinfluxList({
      influxType,
      fromDate: dateUtils.fromDate(),
      toDate: dateUtils.toDate(),
      groupBy: '1h',
      station: EphemeralStateService.getMyStationId(),
    });
  }

  public async getDwellTimes(
    originEvent: ReportDatasetEventIntervalsOriginEventEnum,
    targetEvent: ReportDatasetEventIntervalsTargetEventEnum,
    lastOnly: boolean = false,
    filters?: ReportFilters,
  ) {
    if (lastOnly) {
      return this.api.reportsDatasetsEventintervalsList({
        originEvent: originEvent,
        targetEvent: targetEvent,
        groupBy: '6m',
        fromDate: new Date(Date.now() - 6 * 60 * 1000).toISOString(), // last 6 minutes -- one bucket
        toDate: new Date().toISOString(),
        station: EphemeralStateService.getMyStationId(),
      });
    } else {
      return this.api.reportsDatasetsEventintervalsList({
        originEvent: originEvent,
        targetEvent: targetEvent,
        groupBy: '1h',
        fromDate: dateUtils.fromDate(),
        toDate: dateUtils.toDate(),
        station: EphemeralStateService.getMyStationId(),
        ...filters,
      });
    }
  }

  public async getPerformanceStats(
    interval: string = '1h',
    metric: ReportDatasetPerformanceMetricEnum,
    lastOnly: boolean = false,
    area?: number,
    filters?: ReportFilters,
  ) {
    const systemDate = LocalStorageService.getSystemDate();

    const now: Date = new Date(Date.now());
    now.setDate(systemDate.getDate());
    now.setMonth(systemDate.getMonth());
    now.setFullYear(systemDate.getFullYear());

    let from: string = dateUtils.fromDate();
    let to: string = dateUtils.toDate();

    if (lastOnly) {
      interval = '6m';
      from = new Date(Date.now() - 6 * 60 * 1000).toISOString(); // last 6 minutes -- one bucket
      to = new Date().toISOString();
    } else {
      switch (interval) {
        case '6m':
          from = new Date(
            Date.parse(from) - 72 * 60 * 1000,
          ).toISOString(); // last 72 minutes
          break;
        case '1d':
          from = new Date(
            Date.parse(from) - 7 * 24 * 60 * 60 * 1000,
          ).toISOString(); // last 7 days
          break;
      }
    }

    //set the 8hr (480m) offset for daily report, based on current timezone
    const offset = 480 + new Date().getTimezoneOffset();
    let intervalWithOffset = '';

    interval === '1d'
      ? (intervalWithOffset = interval + '+' + offset + 'm')
      : (intervalWithOffset = interval);

    return this.api.reportsDatasetsPerformanceList({
      groupBy: intervalWithOffset,
      fromDate: from,
      toDate: to,
      metric,
      time: ReportDatasetPerformanceTimeEnum.MINUTES,
      area,
      station: EphemeralStateService.getMyStationId(),
      ...filters,
    });
  }
}

export default new Reports();
