import { ButtonProps } from '@material-ui/core/Button';
import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './singleDetail.styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import EditIcon from '@material-ui/icons/Edit';
import { common } from '../../../utils/strings';
import CSTextField from '../csTextField/CSTextField';
import CSButton from '../csButton/CSButton';
import Typography from '../typography/Typography';
import {
  TypographyColors,
  TypographyColorVariant,
} from '../typography/TypographyTypes';
import { ExtendedPaletteKeys } from '../../../theming/material-ui';
import { Grid, GridJustification, GridSize } from '@material-ui/core';
import CSDialogTitleWithIcon from '../csDialogTitleWithIcon/CSDialogTitleWithIcon';
import { DialogIcons } from '../csDialogTitleWithIcon/CSDialogTitleWithIconTypes';
import CSAsyncSelect from '../csAsyncSelect/CSAsyncSelect';
import { TypeAheadItem } from '../../../interfaces/components';
import { noOptionsMessage } from '../../asyncSelect/utils';

export interface IProps extends ButtonProps {
  classes: { [key: string]: string };
  labelColumns?: GridSize;
  valueColumns?: GridSize;
  label: string;
  value: any;
  valueColor?:
    | ExtendedPaletteKeys
    | TypographyColors
    | TypographyColorVariant;
  justifyValue?: GridJustification;
  inline?: boolean;
  inlineWidth?: string;
  inputType?: 'string' | 'number';
  hasEditPermission?: boolean;
  onEdit?: (params: any) => any;
  editLoadAsyncOptions?: (inputValue: string, callback: any) => void;
  customValueComponent?: boolean;
}

const SingleDetail: React.FC<IProps> = ({
  classes,
  label,
  value,
  valueColor = { color: 'grey', variant: 'A700' },
  justifyValue = 'flex-start',
  inputType = 'string',
  labelColumns = 6,
  valueColumns = 6,
  hasEditPermission = true,
  editLoadAsyncOptions,
  onEdit,
  customValueComponent = false,
  className,
}) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [editableValue, setEditableValue] = useState<string | null>();

  useEffect(() => {
    setEditableValue(value === common.emptyValue ? undefined : value);
  }, [value, openDialog]);

  const renderDialog = () => {
    const onClose = () => {
      setEditableValue('');
      setOpenDialog(false);
    };

    return (
      <Dialog
        classes={{ paperScrollPaper: classes.dialogRoot }}
        open={openDialog}
        onClose={onClose}
      >
        <DialogTitle>
          <CSDialogTitleWithIcon
            title={`Edit ${label}`}
            icon={DialogIcons.EDIT}
          />
        </DialogTitle>
        <DialogContent>
          {editLoadAsyncOptions ? (
            <CSAsyncSelect<TypeAheadItem>
              containerSize='fullHorizontal'
              label='Select New Value'
              cacheOptions
              loadOptions={editLoadAsyncOptions}
              onChange={(option) => {
                setEditableValue((option as TypeAheadItem).value);
              }}
              placeholder={'Start Typing...'}
              menuPortalTarget={document.body}
              noOptionsMessage={noOptionsMessage}
            />
          ) : (
            <CSTextField
              autoFocus
              containerSize='fullHorizontal'
              label='Value'
              type={inputType}
              defaultValue={
                value === common.emptyValue ? undefined : value
              }
              onChange={(e) => {
                setEditableValue(e.target.value);
              }}
              onClear={() => {
                setEditableValue(null);
              }}
            />
          )}
        </DialogContent>
        <DialogActions className={classes.dialogAction}>
          <CSButton
            variant='outlined'
            color='secondary'
            onClick={onClose}
          >
            Cancel
          </CSButton>
          <CSButton
            variant='contained'
            color='secondary'
            onClick={() => {
              if (onEdit) {
                onEdit(editableValue);
              }
              onClose();
            }}
          >
            Edit
          </CSButton>
        </DialogActions>
      </Dialog>
    );
  };
  return (
    <>
      {!!onEdit && renderDialog()}
      <Grid
        container
        spacing={3}
        alignItems='center'
        className={className}
      >
        <Grid item xs={labelColumns}>
          <Typography
            data-testid={`single-detail:${label}:label`}
            variant='subtitle1'
            color='quaternary'
            className={classes.contentMargin + ' detail-label'}
          >
            {label}
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={valueColumns}
          alignItems='center'
          justifyContent={justifyValue}
        >
          {customValueComponent ? (
            { ...value }
          ) : (
            <Grid item>
              <Typography
                data-testid={`single-detail:${label}:value`}
                variant='body2'
                className={classes.paramValueLight + ' detail-value'}
                color={valueColor}
                title={value}
              >
                {value || common.emptyValue}
              </Typography>
            </Grid>
          )}
          <Grid item>
            {hasEditPermission && !!onEdit && (
              <EditIcon
                className={classes.editIcon}
                fontSize={'small'}
                onClick={() => {
                  setOpenDialog(true);
                }}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(styles, { name: 'CSSigleDetail' })(
  SingleDetail,
);
