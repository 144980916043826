import type { LayerProps } from 'react-map-gl';
import { mapColors } from './constants';

export const clusterCountLayer: LayerProps = {
  id: 'cluster-count',
  type: 'symbol',
  source: 'containers',
  filter: ['!', ['to-boolean', ['get', 'id']]],
  layout: {
    'text-field': '{count}',
    'text-font': ['Arial Unicode MS Bold'],
    'text-size': 20,
  },
  paint: {
    'text-color': mapColors.primary,
  },
};

export const clusteredPointLayer: LayerProps = {
  id: 'cluster',
  type: 'circle',
  source: 'containers',
  filter: ['!', ['to-boolean', ['get', 'id']]],
  paint: {
    'circle-color': mapColors.secondary,
    'circle-radius': 20,
    'circle-stroke-width': 1,
    'circle-stroke-color': mapColors.white,
  },
};

export const unclusteredPointLayer: LayerProps = {
  id: 'single-container',
  type: 'circle',
  source: 'containers',
  filter: ['to-boolean', ['get', 'id']],
  paint: {
    'circle-color': mapColors.secondary,
    'circle-radius': 10,
    'circle-stroke-width': 1,
    'circle-stroke-color': mapColors.white,
  },
};

export const containerPingLocationPointLayer: LayerProps = {
  id: 'ping-location',
  type: 'circle',
  source: 'containers',
  paint: {
    'circle-color': ['get', 'color'],
    'circle-radius': 10,
    'circle-stroke-width': ['get', 'borderWidth'],
    'circle-stroke-color': 'rgba(95,206,132,0.5)',
  },
};
