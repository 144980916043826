import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
  KeyboardDateTimePicker,
  KeyboardDateTimePickerProps,
  KeyboardTimePicker,
  KeyboardTimePickerProps,
} from '@material-ui/pickers';
import {
  CSKeyboardDatePicker,
  DatePickerHandlers,
} from './CSDatepickerTypes';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';

export const generateDatePicker = (
  date: ParsableDate,
  props: CSKeyboardDatePicker,
  overridenProps: Partial<KeyboardDatePickerProps>,
  containerProps: Partial<KeyboardDatePickerProps>,
  handlers: DatePickerHandlers,
) => {
  const { format = 'MM/dd/yyyy', ...extraProps } = props;
  return (
    <KeyboardDatePicker
      value={date}
      format={format}
      {...handlers}
      {...overridenProps}
      {...containerProps}
      {...extraProps}
    />
  );
};

export const generateDatetimePicker = (
  date: ParsableDate,
  props: CSKeyboardDatePicker,
  overridenProps: Partial<KeyboardDateTimePickerProps>,
  containerProps: Partial<KeyboardDateTimePickerProps>,
  handlers: DatePickerHandlers,
) => {
  const { format = 'yyyy/MM/dd hh:mma', ...extraProps } = props;
  return (
    <KeyboardDateTimePicker
      value={date}
      format={format}
      {...handlers}
      {...overridenProps}
      {...containerProps}
      {...extraProps}
    />
  );
};

export const generateTimepicker = (
  date: ParsableDate,
  props: CSKeyboardDatePicker,
  overridenProps: Partial<KeyboardTimePickerProps>,
  containerProps: Partial<KeyboardTimePickerProps>,
  handlers: DatePickerHandlers,
) => {
  const { format = 'hh:mm a', ...extraProps } = props;
  return (
    <KeyboardTimePicker
      format={format}
      {...handlers}
      {...overridenProps}
      {...containerProps}
      {...(extraProps as unknown as KeyboardTimePickerProps)}
      value={date}
    />
  );
};
