import React, { useEffect, useState } from 'react';
import { Image } from 'react-konva';

interface Props {
  type: string;
  width: number;
  x: number;
  y: number;
  rotation: number;
}

const ContainerTypeIcon: React.FC<Props> = ({
  type,
  width,
  x,
  y,
  rotation,
}) => {
  const [image, setImage] = useState<any>(null);

  useEffect(() => {
    const image = new window.Image();
    image.src = `${
      process.env.REACT_APP_BASENAME || ''
    }/stationLayout/${type.toLowerCase()}.png`;
    image.onload = () => {
      setImage(image);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Image
      image={image}
      width={width}
      height={width}
      x={x}
      y={y}
      rotation={rotation}
    />
  );
};

export default ContainerTypeIcon;
