import React from 'react';
import { Rect, Text, Group } from 'react-konva';
import colors from '../../../utils/colors';

interface Props {
  x: number;
  y: number;
  rotation: number;
  width: number;
  text: string;
}

const ShapeLabel: React.FC<Props> = ({
  x,
  y,
  rotation,
  width,
  text,
}) => {
  return (
    <Group>
      <Rect
        x={x}
        y={y}
        rotation={rotation}
        width={width}
        height={20}
        cornerRadius={3}
        fill={colors.dark}
      />
      <Text
        text={text}
        x={x}
        y={y}
        rotation={rotation}
        width={width}
        height={20}
        align='center'
        verticalAlign='middle'
        fill={colors.white}
      />
    </Group>
  );
};

export default ShapeLabel;
