import * as React from 'react';
import styles from './progressBarItem.styles';
import { Grid, LinearProgress, withStyles } from '@material-ui/core';
import Typography from '../typography/Typography';
import SectionTitleSeparator from '../csSectionTitleSeparator/CSSectionTitleSeparator';

interface Props {
  classes: { [key: string]: string };
  label?: string;
  labelElement?: JSX.Element;
  value: number;
  isLast?: boolean;
  isInactive?: boolean;
}

const ProgressBarItem: React.FC<Props> = ({
  classes,
  label,
  labelElement,
  value,
  isLast = false,
  isInactive = false,
}) => {
  return (
    <Grid className={classes.sliderContainer} container spacing={2}>
      <Grid item xs={9}>
        {label && <Typography variant='body2'>{label}</Typography>}
        {labelElement && { ...labelElement }}
      </Grid>
      {!isInactive && (
        <>
          <Grid item xs={3} className={classes.alignRight}>
            {value}%
          </Grid>
          <Grid item xs={12}>
            <LinearProgress
              variant='determinate'
              value={value}
              classes={{
                root: classes.progressRoot,
                colorPrimary: classes.barColorTrack,
                bar1Determinate: classes.barColorMain,
              }}
            />
          </Grid>
        </>
      )}
      {!isLast && (
        <Grid item xs={12}>
          <SectionTitleSeparator
            borderWidth='border_thin'
            bottomMargin={0}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default withStyles(styles)(ProgressBarItem);
