export const MAX_PAGE_SIZE = 999999999;

export const LABEL_DATA_FORMAT = 'PNG';
export const LABEL_SIZE = '4x6';
export const LABEL_DPI = 203;

export const DEFAULT_POLLING_INTERVAL = 3000; // ms

export const MARKETING_WEBSITE_URL =
  'https://www.cloudsort.com/containers';
