import { StandardProps, Theme, withStyles } from '@material-ui/core';
import * as React from 'react';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import clsx from 'clsx';

export interface ContainerTypeSignalProp
  extends StandardProps<
    React.HTMLAttributes<HTMLDivElement>,
    ContainerClassKeys
  > {
  status?: 'outbound' | 'inbound';
}

export type ContainerClassKeys = 'root' | 'rotated';

const styles = (theme: Theme) => ({
  root: {},
  rotated: {
    transform: 'rotate(-180deg)',
    color: theme.palette.common.black,
  },
});

const ContainerTypeSignal: React.FC<ContainerTypeSignalProp> = ({
  classes,
  status = 'outbound',
}) => {
  return (
    <PlayCircleFilledIcon
      className={clsx(classes?.root, {
        [classes?.rotated || '']: status === 'inbound',
      })}
    />
  );
};

export default withStyles(styles, {
  name: 'ContainerTypeSignal',
})(ContainerTypeSignal);
