import { createStyles, Theme } from '@material-ui/core/styles';

export default createStyles((theme: Theme) => ({
  sliderContainer: {
    marginBottom: 10,
  },
  progressRoot: {
    height: 4,
    borderRadius: theme.shape.borderRadius,
  },
  barColorMain: {
    backgroundColor: theme.palette.tertiary?.main,
  },
  barColorTrack: {
    backgroundColor: theme.palette.background?.default,
  },
  alignRight: {
    textAlign: 'right',
  },
}));
