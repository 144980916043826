import React, { useEffect, useState, useMemo } from 'react';
import {
  withStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles';
import {
  CSButton,
  Typography,
  AlertBanner,
  CSTextField,
} from '../../primitives';
import ProgressIndicator from '../../progressIndicator/ProgressIndicator';
import Layout from '../../layout/Layout';
import ErrorHandler from '../../../utils/ErrorHandler';
import { MAX_PAGE_SIZE } from '../../../services/utils/constants';
import browserHistory from '../../../utils/browserHistory';

import Box from '@material-ui/core//Box';
import ZoneSettings from './ZoneModuleTable';
import isEqual from 'lodash/isEqual';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Transition } from '../../confirmationDialog/ConfirmationDialog';
import selectStyles from '../../select/select.styles';

// Icons
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

// Types
import {
  AreaAreaTypeEnum,
  AreaDetailsAreaTypeEnum,
  LoadPoint,
  LoadPointDetails,
  ContainerDetailsContainerTypeEnum,
  Zone,
  AreaDetails,
  ZoneDetails,
  StaffPermissionPermissionsEnum,
} from 'cloudsort-client';
import { AxiosError } from 'axios';

// Services
import AreasService from '../../../services/Areas.service';
import ZonesService from '../../../services/Zones.service';
import LoadPointsService from '../../../services/LoadPoints.service';
import ZoneModulesService from '../../../services/ZoneModules.service';
import configurationUtils from '../../../utils/configurationUtils';
import PermissionsService from '../../../services/Permissions.service';
import { AuthRoutes } from '../../../interfaces/routes';
import { Helmet } from 'react-helmet';
import CSBreadcrumbs from '../../primitives/CSBreadcrumbs/CSBreadcrumbs';
import FiltersDrawer, {
  SelectedFilters,
} from '../../filtersDrawer/FiltersDrawer';
import clx from 'classnames';
import FilterListIcon from '@material-ui/icons/FilterList';
import Grid from '@material-ui/core/Grid';
import CSSectionTitleSeparator from '../../primitives/csSectionTitleSeparator/CSSectionTitleSeparator';
import CSDialogTitleWithIcon from '../../primitives/csDialogTitleWithIcon/CSDialogTitleWithIcon';
import { DialogIcons } from '../../primitives/csDialogTitleWithIcon/CSDialogTitleWithIconTypes';
import CSHorizontalFilterBadgesGroup from '../../primitives/csHorizontalFilterBadgesGroup/csHorizontalFilterBadgesGroup';
import { FilterDescription } from '../../primitives/csHorizontalFilterBadgesGroup/csHorizontalFilterBadgesGroupTypes';

const ALPHABET = [...'abcdefghijklmnopqrstuvwxyz'];

interface Props {
  classes: { [key: string]: string };
  match: any;
}

export interface FilterState {
  fmc?: string;
}

export interface EditFunctions {
  onRemoveModule: (id: number) => void;
  onRemoveZone: (id: number) => void;
  onEditLoadPoint: (lp: LoadPointDetails) => Promise<void>;
  onEditZone: (data: ZoneDetails) => void;
}

export interface UtilsFunctions {
  showProgress: (showProgress: boolean) => void;
  handleError: (e: AxiosError) => void;
  getAllZoneNames: () => (string | null | undefined)[];
  getActiveLoadpointsForZone: (zone: number) => Promise<LoadPoint[]>;
}

export const goToAddStationModule = (
  area: string,
  areaType: string,
  zone: string,
) => {
  browserHistory.push(
    `${AuthRoutes.AREA}/${area}/settings/${areaType}/add-station-module/${zone}`,
  );
};

export const goToAddCorporateModule = (
  area: string,
  areaType: string,
  zone: string,
) => {
  browserHistory.push(
    `${AuthRoutes.AREA}/${area}/settings/${areaType}/add-corporate-module/${zone}`,
  );
};

const AreasSettings: React.FC<Props> = ({ classes, match }) => {
  const [zones, setZones] = useState<Zone[]>();
  const [nameToAdd, setNameToAdd] = useState<string | null>();
  const [lpOptipns, setLpOptipns] = useState<LoadPoint[]>();
  const [areaData, setAreaData] = useState<AreaDetails>();
  const [error, setError] = useState<string>();
  const [dialogError, setDialogError] = useState<string>();
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [allDataLoaded, setAllDataLoaded] = useState<boolean>(false);
  const [filterState, setFilterState] = useState<FilterState>({
    fmc: 'ALL',
  });

  const [showFiltersDrawer, setShowFiltersDrawer] = useState(false);
  const [selectedFilters, setSelectedFilters] =
    useState<SelectedFilters>();
  const [filterToRemove, setFilterToRemove] =
    useState<FilterDescription>();

  const areaLabels = useMemo(() => {
    return {
      singular: configurationUtils.getPageTitle(true, 'AREA'),
      plural: configurationUtils.getPageTitle(false, 'AREA'),
    };
  }, []);

  const fetchZones = async () => {
    try {
      const data = (
        await ZonesService.getAll(
          undefined,
          match.params.id,
          MAX_PAGE_SIZE,
        )
      ).data.results;
      data.sort((a, b) => {
        if (a.name && b.name) {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
        }
        return 0;
      });
      setZones(data);
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  const fetchLoadPoints = async () => {
    try {
      const data = (
        await LoadPointsService.getAll({
          areaType:
            match.params.areaType === AreaAreaTypeEnum.SECONDARY
              ? AreaAreaTypeEnum.SECONDARY
              : undefined,
          pageSize: MAX_PAGE_SIZE,
        })
      ).data.results;
      setLpOptipns(data);
    } catch (e) {
      handleError(e as AxiosError);
    }
  };
  const fetchArea = async () => {
    try {
      const data = (await AreasService.getById(match.params.id)).data;
      setAreaData(data);
    } catch (e) {
      handleError(e as AxiosError);
    }
  };

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e as AxiosError));
  };

  const getAllZoneNames = () => {
    return zones?.map((z) => z.name) || [];
  };

  const getActiveLoadpointsForZone = async (zone: number) => {
    return (
      await LoadPointsService.getAll({
        zone,
        isActive: true,
        pageSize: MAX_PAGE_SIZE,
      })
    ).data.results;
  };

  const addZone = async (name: string) => {
    setShowProgress(true);
    try {
      await ZonesService.create({
        name: name,
        area: match.params.id,
      });
      fetchZones();
    } catch (e) {
      handleError(e as AxiosError);
      setShowProgress(false);
    }
  };

  const onRemoveZone = async (id: number) => {
    setShowProgress(true);
    try {
      if (zones) {
        await ZonesService.delete(id);
        fetchZones();
      }
    } catch (e) {
      handleError(e as AxiosError);
      setShowProgress(false);
    }
  };

  const onRemoveModule = async (id: number) => {
    setShowProgress(true);
    try {
      await ZoneModulesService.delete(id);
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  const onEditLoadPoint = async (lp: LoadPointDetails) => {
    setShowProgress(true);
    try {
      const { data } = await LoadPointsService.updateById(lp.id!, lp);
      const lpToUpdate = lpOptipns?.find(
        (lpOp) => lpOp.id === data.id,
      );
      if (lpToUpdate && !isEqual(lpToUpdate?.fmcs, data.fmcs)) {
        lpToUpdate.fmcs = data.fmcs;
        // @ts-ignore
        setLpOptipns([...lpOptipns]);
      }
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  const onEditZone = async (zone: ZoneDetails) => {
    setShowProgress(true);
    try {
      await ZonesService.update(zone);
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  // Filter drawer
  const getDrawerFilters = (filters: SelectedFilters) => {
    const value = filters.carrier?.values[0]?.name;

    if (value) {
      setFilterState({ fmc: value });
    } else {
      setFilterState({ fmc: 'ALL' });
    }

    fetchZones();

    setSelectedFilters(filters);
  };

  const renderAddZoneDialog = () => {
    return (
      <Dialog
        classes={{ paperScrollPaper: classes.dialogRoot }}
        open={typeof nameToAdd === 'string'}
        TransitionComponent={Transition}
        onClose={() => {
          setNameToAdd(null);
        }}
      >
        <DialogTitle>
          <CSDialogTitleWithIcon
            icon={DialogIcons.ADD}
            title='Add Zone'
          />
        </DialogTitle>
        <DialogContent className={classes.dialogRoot}>
          {dialogError && (
            <>
              <AlertBanner
                severity='info'
                alertTitle={'Duplicate name'}
                alertMsg={dialogError}
              />
              <Box mt={2} />
            </>
          )}

          <CSTextField
            data-testid='area-settings:add-zone-dialog:zone-name'
            autoFocus
            label='Name'
            type={'string'}
            containerSize='fullHorizontal'
            onChange={(e) => {
              setNameToAdd(e.target.value);
            }}
            error={
              configurationUtils.isModuleNameValidated('ZONE') &&
              !/^[A-Z]$/.test(nameToAdd || '')
            }
            helperText={
              configurationUtils.isModuleNameValidated('ZONE')
                ? 'A single capital letter.'
                : undefined
            }
          />
        </DialogContent>

        <DialogActions className={classes.dialogActions}>
          <CSButton
            variant='outlined'
            color='secondary'
            onClick={() => {
              setNameToAdd(null);
            }}
          >
            Cancel
          </CSButton>
          <CSButton
            variant='contained'
            color='secondary'
            data-testid='area-settings:add-zone-dialog:add-zone-button'
            disabled={
              !nameToAdd ||
              (configurationUtils.isModuleNameValidated('ZONE') &&
                !/^[A-Z]$/.test(nameToAdd || ''))
            }
            onClick={() => {
              addZone(nameToAdd!.toUpperCase());
              setNameToAdd(null);
            }}
          >
            Save
          </CSButton>
        </DialogActions>
      </Dialog>
    );
  };

  useEffect(() => {
    if (
      nameToAdd &&
      getAllZoneNames().includes(nameToAdd.toLowerCase())
    ) {
      setDialogError('Name is already in use.');
    } else {
      setDialogError('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameToAdd]);

  const loadData = async () => {
    const promises = [fetchZones(), fetchArea(), fetchLoadPoints()];
    await Promise.all(promises);
    setAllDataLoaded(true);
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort -
${configurationUtils.getPageTitle(true, 'AREA')} Configuration for ${
            areaData?.name || ''
          }`}
        </title>
      </Helmet>
      <Layout navCurrent='AREA'>
        {(showProgress || !allDataLoaded) && <ProgressIndicator />}
        {renderAddZoneDialog()}
        {error && (
          <AlertBanner
            severity='error'
            alertTitle={'Error'}
            alertMsg={error}
          />
        )}
        <FiltersDrawer
          isOpen={showFiltersDrawer}
          onAfterClose={() => {
            setShowFiltersDrawer(false);
          }}
          getFilters={getDrawerFilters}
          removeFilter={filterToRemove}
        />

        <Box mb={5}>
          <CSBreadcrumbs
            breadcrumbs={[
              {
                label: areaLabels.plural,
                link:
                  AuthRoutes.AREA + '?stationId=' + areaData?.station,
              },
              {
                label: `${areaData?.name || ''}`,
                link:
                  AuthRoutes.AREA +
                  '/' +
                  areaData?.id +
                  '?stationId=' +
                  areaData?.station,
              },
              {
                label: 'Configuration',
                selected: true,
              },
            ]}
          />
        </Box>

        <Grid item container>
          <Grid item sm={6} xs={12}>
            <Typography component='h1' variant='h3'>
              {areaData
                ? `${configurationUtils.getPageTitle(true, 'AREA')} ${
                    areaData.name
                  } - Configuration`
                : ''}
            </Typography>
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
            className={classes.nonMobileAlignRight}
          >
            {areaData?.area_type ===
              AreaDetailsAreaTypeEnum.SECONDARY &&
              PermissionsService.hasPermission(
                StaffPermissionPermissionsEnum.STATION_WRITE,
              ) && (
                <CSButton
                  data-testid='area-settings-add-zone-button'
                  color={{
                    buttonColor: 'secondary',
                    iconColor: 'primary',
                  }}
                  disabled={
                    !!error || zones?.length === ALPHABET.length
                  }
                  variant='contained'
                  className={classes.actionButton}
                  onClick={() => {
                    setNameToAdd('');
                  }}
                  startIcon={<AddBoxOutlinedIcon />}
                >
                  Add Zone
                </CSButton>
              )}
            {areaData?.area_type ===
              AreaDetailsAreaTypeEnum.SECONDARY && (
              <CSButton
                variant='outlined'
                color='secondary'
                className={clx(
                  classes.headerButtons,
                  classes.headerActionButton,
                )}
                onClick={() => {
                  setShowFiltersDrawer(true);
                }}
              >
                <FilterListIcon />
              </CSButton>
            )}
            {areaData?.area_type ===
              AreaDetailsAreaTypeEnum.PRIMARY && (
              <>
                <CSButton
                  data-testid='area-settings:copy-module'
                  disabled={!!error}
                  variant='outlined'
                  color='secondary'
                  className={classes.actionButton}
                  onClick={() => {
                    goToAddStationModule(
                      match.params.id,
                      match.params.areaType,
                      zones![0].id?.toString()!,
                    );
                  }}
                  startIcon={<FileCopyOutlinedIcon />}
                >
                  Copy Module
                </CSButton>
                <CSButton
                  data-testid='area-settings:create-module'
                  disabled={!!error}
                  variant='contained'
                  color={{
                    buttonColor: 'secondary',
                    iconColor: 'primary',
                  }}
                  className={classes.actionButton}
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    goToAddCorporateModule(
                      match.params.id,
                      match.params.areaType,
                      zones![0].id?.toString()!,
                    );
                  }}
                  startIcon={<AddBoxOutlinedIcon />}
                >
                  Create Module
                </CSButton>
              </>
            )}
          </Grid>
        </Grid>

        <CSSectionTitleSeparator topMargin={16} bottomMargin={24} />
        <Grid container item xs={12} spacing={1}>
          <CSHorizontalFilterBadgesGroup
            selectedFilters={selectedFilters}
            onRemoveFilter={(toRemove) => setFilterToRemove(toRemove)}
          />
        </Grid>
        {allDataLoaded && zones?.length
          ? zones?.map((zone: Zone) => (
              <ZoneSettings
                match={match}
                key={zone.id}
                area={areaData}
                zone={zone}
                filterState={filterState}
                editFunctions={{
                  onRemoveModule: onRemoveModule,
                  onEditLoadPoint: onEditLoadPoint,
                  onRemoveZone: onRemoveZone,
                  onEditZone: onEditZone,
                }}
                utilsFunctions={{
                  showProgress: (showProgress: boolean) => {
                    setShowProgress(showProgress);
                  },
                  handleError: (e: AxiosError) => {
                    handleError(e as AxiosError);
                  },
                  getAllZoneNames: getAllZoneNames,
                  getActiveLoadpointsForZone:
                    getActiveLoadpointsForZone,
                }}
                lpOptions={lpOptipns}
                containerTypeOptions={Object.values(
                  ContainerDetailsContainerTypeEnum,
                )}
              />
            ))
          : zones?.length === 0 && (
              <AlertBanner
                severity='info'
                alertTitle={
                  'There is no information to display at the moment'
                }
              />
            )}
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...selectStyles,
    dialogRoot: {
      overflow: 'hidden',
      maxWidth: 480,
    },
    dialogActions: {
      padding: 24,
    },
    actionButton: {
      marginRight: 10,
      marginBottom: 10,
      '& svg': {
        marginRight: 10,
      },
    },
    headerActionButton: {
      marginRight: 10,
      marginBottom: 10,
    },
    nonMobileAlignRight: {
      [theme.breakpoints.down('xs')]: {
        marginTop: 20,
      },
      [theme.breakpoints.up('sm')]: {
        textAlign: 'right',
      },
    },
  })),
)(AreasSettings);
