import React from 'react';
import {
  createStyles,
  Box,
  Theme,
  Tooltip,
  CircularProgress,
  TooltipProps,
  withStyles,
} from '@material-ui/core';
import Typography from '../typography/Typography';

type Props = {
  classes: { [key: string]: string };
  value: number;
  tooltipText?: string;
  placement?: TooltipProps['placement'];
};

const CircularProgressWithLabel: React.FC<Props> = ({
  classes,
  value,
  tooltipText = '',
  placement = 'top',
}) => {
  return (
    <Tooltip
      placement={placement}
      title={tooltipText}
      classes={{
        tooltip: classes.tooltip,
        arrow: classes.arrow,
      }}
      arrow
    >
      <Box
        position='relative'
        display='inline-flex'
        className={classes.CircularProgressContainer}
      >
        <CircularProgress
          variant='determinate'
          value={100}
          className={classes.remainingProgress}
          size={'34px'}
        />
        <CircularProgress
          variant='determinate'
          value={value}
          color='primary'
          size={'34px'}
          className={classes.currentProgress}
        />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position='absolute'
          display='flex'
          alignItems='center'
          justifyContent='center'
        >
          <Typography component='span' variant='label'>{`${Math.round(
            value,
          )}%`}</Typography>
        </Box>
      </Box>
    </Tooltip>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    tooltip: {
      background: theme.palette.secondary.main,
      color: theme.palette.grey.A100,
      borderRadius: theme.shape.borderRadius_sm,
      ...theme.typography.body_sm,
      padding: '10px 16px',
      lineHeight: '12px',
      maxWidth: '156px',
    },
    arrow: {
      color: theme.palette.secondary.main,
    },
    CircularProgressContainer: {
      width: '34px',
      height: '34px',
    },
    currentProgress: {
      position: 'absolute',
    },
    remainingProgress: {
      color: theme.palette.grey.A200,
    },
  })),
)(CircularProgressWithLabel);
