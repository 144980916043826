import React from 'react';
import { Typography } from '../../../primitives';
import { withStyles } from '@material-ui/core/styles';
import { Box, Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import detailsPageStyles from '../../../commonStyles/detailsPage.style';
import configPageStyles from '../../../commonStyles/configPage.style';
import PackagesModule from './ProcessSteps';
import ModulesModule from './Modules';
import Sorting from './Sorting';
import GhostResources from './GhostResources';
import LoadOps from './LoadOps';
import ContainerOps from './ContainerOps';
import StatesModule from './StatesModule';
import Entities from './Entities';
import { INTERACTION } from '../../utils';
import { ArrowBackIos } from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { CurrentView } from '../Modules';
import ExceptionProcess from './ExceptionProcess';
import MachineLearningIntegration from './MachineLearningIntegration';
import DimensioningIntegrations from './DimensioningIntegrations';

interface Props {
  classes: { [key: string]: string };
  data: any;
  dataWeb: any;
  setPartialData: (timers: any) => void;
  setPartialWebData: (webData: any) => void;
  interaction?: INTERACTION;
  goToPreviousPage: () => void;
}

const GeneralMobile: React.FC<Props> = ({
  classes,
  data,
  dataWeb,
  interaction,
  setPartialData,
  setPartialWebData,
  goToPreviousPage,
}) => {
  const currentView = CurrentView.MOBILE;
  return (
    <>
      <Typography
        style={{ fontSize: 16, fontWeight: 500 }}
        onClick={goToPreviousPage}
      >
        <ArrowBackIos style={{ fontSize: 14 }} />
        General
      </Typography>
      <Box mt={1}>
        <Accordion defaultExpanded={false}>
          <AccordionSummary
            classes={{
              expanded: classes.panelSummaryExpanded,
              root: classes.panelSummary,
            }}
            expandIcon={
              <ExpandMoreIcon
                className={classes.accordionExpandColapseIcon}
              />
            }
          >
            <Typography className={classes.summaryPanelTitleText}>
              Modules
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            classes={{
              root: classes.panelDetails,
            }}
          >
            <Box>
              <ModulesModule
                currentView={currentView}
                interaction={interaction}
                data={data?.MODULES}
                dataWeb={dataWeb}
                setPartialData={(modes) => {
                  const dataObj = { ...data };
                  dataObj.MODULES = modes;
                  setPartialData(dataObj);
                }}
                setWebData={(data) => {
                  const dataObj = { ...dataWeb, ...data };
                  setPartialWebData(dataObj);
                }}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box mt={1}>
        <Accordion defaultExpanded={false}>
          <AccordionSummary
            classes={{
              expanded: classes.panelSummaryExpanded,
              root: classes.panelSummary,
            }}
            expandIcon={
              <ExpandMoreIcon
                className={classes.accordionExpandColapseIcon}
              />
            }
          >
            <Typography className={classes.summaryPanelTitleText}>
              Entities
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            classes={{
              root: classes.panelDetails,
            }}
          >
            <Box>
              <Entities
                currentView={currentView}
                interaction={interaction}
                data={data?.ENTITIES}
                setPartialData={(entities) => {
                  const dataObj = { ...data };
                  dataObj.ENTITIES = entities;
                  setPartialData(dataObj);
                }}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box mt={1}>
        <Accordion defaultExpanded={false}>
          <AccordionSummary
            classes={{
              expanded: classes.panelSummaryExpanded,
              root: classes.panelSummary,
            }}
            expandIcon={
              <ExpandMoreIcon
                className={classes.accordionExpandColapseIcon}
              />
            }
          >
            <Typography className={classes.summaryPanelTitleText}>
              Process Steps
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            classes={{
              root: classes.panelDetails,
            }}
          >
            <Box>
              <PackagesModule
                currentView={currentView}
                interaction={interaction}
                data={data?.PROCESS_STEPS}
                setPartialData={(modes) => {
                  const dataObj = { ...data };
                  dataObj.PROCESS_STEPS = modes;
                  setPartialData(dataObj);
                }}
              />
            </Box>
            <Box>
              <StatesModule
                currentView={currentView}
                interaction={interaction}
                data={data?.STATES}
                setPartialData={(states) => {
                  const dataObj = { ...data };
                  dataObj.STATES = states;
                  setPartialData(dataObj);
                }}
              />
            </Box>
            <Box>
              <ExceptionProcess
                currentView={currentView}
                interaction={interaction}
                data={data?.BEHAVIORS}
                setPartialData={(behaviorData) => {
                  const dataObj = { ...data };
                  dataObj.BEHAVIORS = behaviorData;
                  setPartialData(dataObj);
                }}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box mt={1}>
        <Accordion defaultExpanded={false}>
          <AccordionSummary
            classes={{
              expanded: classes.panelSummaryExpanded,
              root: classes.panelSummary,
            }}
            expandIcon={
              <ExpandMoreIcon
                className={classes.accordionExpandColapseIcon}
              />
            }
          >
            <Typography className={classes.summaryPanelTitleText}>
              Operations
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            classes={{
              root: classes.panelDetails,
            }}
          >
            <Box>
              <LoadOps
                currentView={currentView}
                interaction={interaction}
                data={data?.BEHAVIORS?.LOAD_OPS}
                dataKey={'LOAD_OPS'}
                setPartialData={(behaviorData) => {
                  const dataObj = { ...data };
                  dataObj.BEHAVIORS.LOAD_OPS = behaviorData;
                  setPartialData(dataObj);
                }}
              />
            </Box>
            <Box>
              <ContainerOps
                currentView={currentView}
                interaction={interaction}
                data={data?.BEHAVIORS?.CONTAINER_OPS}
                dataKey={'CONTAINER_OPS'}
                setPartialData={(behaviorData) => {
                  const dataObj = { ...data };
                  dataObj.BEHAVIORS.CONTAINER_OPS = behaviorData;
                  setPartialData(dataObj);
                }}
              />
            </Box>
            <Box>
              <MachineLearningIntegration
                currentView={currentView}
                interaction={interaction}
                data={data?.BEHAVIORS?.ML_INTEGRATION}
                setPartialData={(behaviorData) => {
                  const dataObj = { ...data };
                  dataObj.BEHAVIORS.ML_INTEGRATION = behaviorData;
                  setPartialData(dataObj);
                }}
              />
            </Box>
            <Box>
              <DimensioningIntegrations
                currentView={currentView}
                interaction={interaction}
                data={data?.BEHAVIORS?.DIMENSIONING_INTEGRATIONS}
                dataKey={'DIMENSIONING_INTEGRATIONS'}
                setPartialData={(behaviorData) => {
                  const dataObj = { ...data };
                  dataObj.BEHAVIORS.DIMENSIONING_INTEGRATIONS =
                    behaviorData;
                  setPartialData(dataObj);
                }}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box mt={1}>
        <Accordion defaultExpanded={false}>
          <AccordionSummary
            classes={{
              expanded: classes.panelSummaryExpanded,
              root: classes.panelSummary,
            }}
            expandIcon={
              <ExpandMoreIcon
                className={classes.accordionExpandColapseIcon}
              />
            }
          >
            <Typography className={classes.summaryPanelTitleText}>
              Sorting
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            classes={{
              root: classes.panelDetails,
            }}
          >
            <Box>
              <Sorting
                currentView={currentView}
                interaction={interaction}
                data={data?.BEHAVIORS?.SORTING}
                dataKey={'SORTING'}
                setPartialData={(behaviorData) => {
                  const dataObj = { ...data };
                  dataObj.BEHAVIORS.SORTING = behaviorData;
                  setPartialData(dataObj);
                }}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box mt={1}>
        <Accordion defaultExpanded={false}>
          <AccordionSummary
            classes={{
              expanded: classes.panelSummaryExpanded,
              root: classes.panelSummary,
            }}
            expandIcon={
              <ExpandMoreIcon
                className={classes.accordionExpandColapseIcon}
              />
            }
          >
            <Typography className={classes.summaryPanelTitleText}>
              Ghost Resources
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            classes={{
              root: classes.panelDetails,
            }}
          >
            <Box>
              <GhostResources
                currentView={currentView}
                interaction={interaction}
                data={data?.BEHAVIORS?.GHOST_RESOURCES_DAYS}
                setPartialData={(behaviorData) => {
                  const dataObj = { ...data };
                  dataObj.BEHAVIORS.GHOST_RESOURCES_DAYS =
                    behaviorData;
                  setPartialData(dataObj);
                }}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...configPageStyles(theme),
  })),
)(GeneralMobile);
