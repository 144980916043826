import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { AlertBanner } from '../../primitives';
import colors from '../../../utils/colors';
import { withStyles } from '@material-ui/core/styles';
import ProgressIndicator from '../../progressIndicator/ProgressIndicator';
import {
  Grid,
  DialogTitle,
  createStyles,
  FormControl,
  Theme,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';

import selectStyles from '../../select/select.styles';
import sectionPageBaseStyle from '../../commonStyles/sectionPageBase.style';

//Icons
import EditIcon from '@material-ui/icons/Edit';
import { ShiftPlanPreset } from 'cloudsort-client';

interface Props {
  classes: { [key: string]: string };
  showProgress?: boolean;
  presets: ShiftPlanPreset[];
  currentPreset: number;
  onClose: () => void;
  onSubmit: (id: number) => void;
  isOpen: boolean;
  error?: string;
}

const SelectPresetDialog: React.FC<Props> = ({
  showProgress = false,
  isOpen,
  presets,
  currentPreset,
  classes,
  onClose,
  onSubmit,
  error,
}) => {
  const [selectedPreset, setSelectedPreset] = useState<number>();

  const handleSubmit = () => {
    selectedPreset && onSubmit(selectedPreset);
  };

  useEffect(() => {
    setSelectedPreset(currentPreset);
  }, [currentPreset]);

  return (
    <>
      {showProgress && <ProgressIndicator />}
      <Dialog open={isOpen} data-testid='select-shift-preset-dialog'>
        <DialogTitle
          style={{ textAlign: 'center', padding: '40px 0 20px 0' }}
        >
          <EditIcon
            style={{
              color: colors.darkGold,
              width: 36,
              height: 'auto',
            }}
          />
          <br />
          Select Preset
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid container item xs={12}>
            <Grid item xs={12}>
              {error && (
                <AlertBanner
                  className={classes.banner}
                  severity='error'
                  alertTitle={'Error'}
                  alertMsg={error}
                  style={{ marginBottom: 20 }}
                />
              )}
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormControl
                    className={classes.formControl}
                    style={{
                      width: '100%',
                      margin: '0 0px 10px 0',
                      height: 50,
                    }}
                  >
                    <InputLabel className={classes.selectLabel}>
                      Preset
                    </InputLabel>
                    <Select
                      data-testid='shift-preset-dialog-recurrence-select'
                      disableUnderline={true}
                      classes={{
                        selectMenu: classes.selectMenu,
                      }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                        classes: {
                          paper: classes.selectPaper,
                          list: classes.selectList,
                        },
                      }}
                      value={selectedPreset}
                      onChange={(e) => {
                        setSelectedPreset(Number(e.target.value));
                      }}
                    >
                      {presets.map((preset) => (
                        <MenuItem value={preset.id} key={preset.id}>
                          {preset.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            className={classes.containedButton}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            disabled={!selectedPreset}
            variant='outlined'
            className={classes.buttonFilled}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...sectionPageBaseStyle,
    ...selectStyles,
    dialogContent: {
      width: '520px',
      maxWidth: '100%',
      overflow: 'hidden',
    },
    inputHolder: {
      width: '100%',
      borderRadius: 2,
    },
    input: {
      width: '100%',
      backgroundColor: '#F1F1F1',
      padding: '10px 12px',
    },
    inputGoldUnderline: {
      width: '100%',
      padding: '3px',
      borderBottom: `3px solid ${colors.darkGold}`,
    },
    button: {
      color: colors.darkGold,
    },
    inputPadding: {
      paddingLeft: '5px',
      paddingRigth: '5px',
    },
    buttonFilled: {
      background: colors.darkGold,
      color: colors.white,
      border: `2px solid ${colors.darkGold}`,
      '& svg': {
        marginRight: 10,
      },
      '& .MuiButton-label': {
        textTransform: 'initial',
      },
      minWidth: '40px',
      height: 42,
      margin: '0 5px 10px 15px',

      '&:hover': {
        color: colors.darkGold,
        background: 'transparent',
      },
    },
  })),
)(SelectPresetDialog);
