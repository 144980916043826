import React, { ChangeEvent, useEffect, useState } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { CSButton, CSTextField, Typography } from '../primitives';
import ErrorHandler from '../../utils/ErrorHandler';
import { AlertBanner } from '../primitives/index';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Transition } from '../confirmationDialog/ConfirmationDialog';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import configurationUtils from '../../utils/configurationUtils';
import selectStyles from '../select/select.styles';
import {
  Grid,
  Theme,
  Box,
  useTheme,
  CardContent,
  Card,
  Paper,
} from '@material-ui/core';
import sectionPageBaseStyle from '../commonStyles/sectionPageBase.style';
import detailsPageStyles from '../commonStyles/detailsPage.style';
import StationsService from '../../services/Stations.service';
import EphemeralStateService from '../../services/EphemeralState.service';
import FlipToBackIcon from '@material-ui/icons/FlipToBack';
import {
  CloudUploadOutlined,
  FileCopyOutlined,
} from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';
import { AxiosError } from 'axios';
import {
  APIException,
  ImportSummaryIssue,
  StationDetailsStationTypeEnum,
  StationStationTypeEnum,
  StopsImportSummary,
} from 'cloudsort-client';
import { CSVLink } from 'react-csv';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import { CSCountrySelect } from '../primitives/csCountrySelect';

interface Props {
  classes: { [key: string]: string };
  isOpen: boolean;
  onAfterClose: () => void;
  updateParent: () => void;
  type: 'add' | 'edit';
  data?: StopParams;
}

interface StopParams {
  name: string;
  station_type: StationStationTypeEnum;
  address: string;
  state: string;
  zipcode: string;
  city: string;
  country: string;
  external_id: string;
}

enum AddStopMethod {
  NONE = 'none',
  CREATE = 'create stop',
  IMPORT = 'import stops',
}

const emptyStopData = {
  name: '',
  station_type: Object.values(StationStationTypeEnum)[0],
  address: '',
  state: '',
  zipcode: '',
  city: '',
  country: '',
  external_id: '',
};

const AddStopsDialog: React.FC<Props> = ({
  classes,
  isOpen,
  onAfterClose,
  updateParent,
  type,
  data,
}) => {
  //General
  const [currentStep, setCurrentStep] = useState<AddStopMethod>(
    AddStopMethod.NONE,
  );
  const [open, setOpen] = useState(false);
  const [error, setError] = useState<{
    message: string;
    data: any;
  }>();
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [stopParams, setStopParams] =
    useState<StopParams>(emptyStopData);
  //Stops upload
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [selectedFileLabel, setSelectedFileLabel] = useState<string>(
    'Drag and Drop Your File Here',
  );
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const [dryRunComplete, setDryRunComplete] =
    useState<boolean>(false);

  const [dryRunResults, setDryRunResults] =
    useState<StopsImportSummary>();
  const [selectedFile, setSelectedFile] = useState<File>();

  //Misc
  const theme = useTheme();

  const stopsLabels = {
    singular: configurationUtils.getPageTitle(true, 'STOP'),
    plural: configurationUtils.getPageTitle(false, 'STOP'),
  };

  useEffect(() => {
    setOpen(isOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (type === 'edit' && data) {
      setStopParams({
        ...data,
      });
    }
    if (type === 'add') {
      setStopParams({
        ...emptyStopData,
      });
    }
  }, [data, type]);

  const handleClose = () => {
    setOpen(false);
    setCurrentStep(AddStopMethod.NONE);
    setDryRunComplete(false);
    setIsUploading(false);
    setSelectedFile(undefined);
    setSelectedFileLabel('Drag and Drop Your File Here');
    setError(undefined);
    setStopParams({
      ...emptyStopData,
    });
    onAfterClose();
  };

  const stopHandler = async () => {
    setShowProgress(true);
    try {
      if (type === 'add') {
        await StationsService.createStation({
          ...stopParams,
          station_type: StationStationTypeEnum.STOP,
          owner: EphemeralStateService.getMyStationId(),
          organization: EphemeralStateService.getMyOrgId(),
        });
      } else {
        await StationsService.editStation({
          ...stopParams,
          station_type: StationDetailsStationTypeEnum.STOP,
          organization: EphemeralStateService.getMyOrgId(),
        });
      }
      updateParent();
      handleClose();
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  const renderModeSelection = () => {
    return (
      <Grid container className={classes.modeSelectionContainer}>
        <Grid item xs={12} sm={6}>
          <Card elevation={2} className={classes.cardRoot}>
            <CardContent
              data-testid='select-create-new-stop'
              className={classes.cardContent}
              onClick={() => {
                setCurrentStep(AddStopMethod.CREATE);
              }}
            >
              <Box className={classes.iconBox}>
                <FlipToBackIcon
                  className={classes.modeSelectionIcon}
                />
              </Box>
              <Box p={2}>
                <Typography variant={'h6'}>Create New</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card elevation={2} className={classes.cardRoot}>
            <CardContent
              data-testid='select-import-new-stops'
              className={classes.cardContent}
              onClick={() => {
                setCurrentStep(AddStopMethod.IMPORT);
              }}
            >
              <Box className={classes.iconBox}>
                <img
                  src={`${
                    process.env.REACT_APP_BASENAME || ''
                  }/icons/csv.svg`}
                  alt={'CSV File'}
                  className={classes.csvFileIconBadge}
                />
                <CloudUploadOutlined
                  className={classes.modeSelectionIcon}
                />
              </Box>
              <Box p={2}>
                <Typography variant={'h6'}>
                  Import {stopsLabels.plural}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  };

  const renderStopForm = () => {
    return (
      <>
        <CSTextField
          className={classes.editStopTextFieldContainer}
          inputProps={{
            className: `${classes.editStopTextField}`,
          }}
          value={stopParams.name}
          autoFocus
          label='Name'
          placeholder={`${stopsLabels.singular} Name`}
          data-testid={'edit-stop-name-field'}
          onChange={(e) => {
            stopParams.name = e.target.value;
            setStopParams({ ...stopParams });
          }}
          onClear={() => {
            stopParams.name = '';
            setStopParams({ ...stopParams });
          }}
        />
        <CSCountrySelect
          className={classes.editStopTextFieldContainer}
          label='Country'
          data-testid={'edit-stop-country-field'}
          value={stopParams.country}
          setValue={(value) => {
            stopParams.country = value;
            setStopParams({ ...stopParams });
          }}
        />
        <CSTextField
          className={classes.editStopTextFieldContainer}
          inputProps={{
            className: `${classes.editStopTextField}`,
          }}
          value={stopParams.address}
          label='Address'
          placeholder='Address'
          data-testid={'edit-stop-address-field'}
          onChange={(e) => {
            stopParams.address = e.target.value;
            setStopParams({ ...stopParams });
          }}
          onClear={() => {
            stopParams.address = '';
            setStopParams({ ...stopParams });
          }}
        />
        <CSTextField
          className={classes.editStopTextFieldContainer}
          inputProps={{
            className: `${classes.editStopTextField}`,
          }}
          label='State'
          placeholder='State'
          value={(stopParams.state || '').toUpperCase()}
          data-testid={'edit-stop-state-field'}
          onChange={(e) => {
            stopParams.state = e.target.value
              .replace(/[^a-zA-Z]/, '')
              .toUpperCase();
            setStopParams({ ...stopParams });
          }}
          onClear={() => {
            stopParams.state = '';
            setStopParams({ ...stopParams });
          }}
        />
        <CSTextField
          className={classes.editStopTextFieldContainer}
          inputProps={{
            className: `${classes.editStopTextField}`,
          }}
          label='ZIP Code'
          placeholder='ZIP Code'
          value={stopParams.zipcode}
          data-testid={'edit-stop-zipcode-field'}
          onChange={(e) => {
            stopParams.zipcode = e.target.value;
            setStopParams({ ...stopParams });
          }}
          onClear={() => {
            stopParams.zipcode = '';
            setStopParams({ ...stopParams });
          }}
        />
        <CSTextField
          className={classes.editStopTextFieldContainer}
          inputProps={{
            className: `${classes.editStopTextField}`,
          }}
          label='City'
          placeholder='City'
          value={stopParams.city}
          data-testid={'edit-stop-city-field'}
          onChange={(e) => {
            stopParams.city = e.target.value;
            setStopParams({ ...stopParams });
          }}
          onClear={() => {
            stopParams.city = '';
            setStopParams({ ...stopParams });
          }}
        />
        <CSTextField
          className={classes.editStopTextFieldContainer}
          inputProps={{
            className: `${classes.editStopTextField}`,
          }}
          value={stopParams.external_id}
          label='External ID (optional)'
          placeholder='External ID'
          data-testid={'edit-stop-external-id-field'}
          onChange={(e) => {
            stopParams.external_id = e.target.value;
            setStopParams({ ...stopParams });
          }}
          onClear={() => {
            stopParams.external_id = '';
            setStopParams({ ...stopParams });
          }}
        />
      </>
    );
  };

  const handleError = async (e: AxiosError) => {
    const errorData = e.response?.data as APIException;
    if (errorData.error_context?.import_issues) {
      setError({
        message: `Found ${errorData.error_context.import_issues.length} issues.`,
        data: errorData.error_context.import_issues.reduce(
          (acc, curr: ImportSummaryIssue) => {
            let newItem: Partial<ImportSummaryIssue> = {
              ...curr,
              ...curr.content,
            };
            delete newItem['content'];
            acc.push(newItem);
            return acc;
          },
          new Array<Partial<ImportSummaryIssue>>(),
        ),
      });
    } else {
      setError({
        message: await ErrorHandler.getLabel(e as AxiosError),
        data: null,
      });
    }
  };

  const onFileChangeHandler = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    if (!event.target.files) {
      return;
    }
    const file = event.target.files[0];
    if (!file.name.includes('csv')) {
      setSelectedFileLabel('Please select CSV file.');
    } else {
      setSelectedFileLabel(file.name);
      setSelectedFile(file);
    }
  };

  const onFileDroppedHandler = (
    event: React.DragEvent<HTMLFormElement>,
  ) => {
    if (!event?.dataTransfer?.files) {
      return;
    }
    const file = event.dataTransfer.files[0];
    if (!file.name.includes('csv')) {
      setSelectedFileLabel('Please select CSV file.');
      setTimeout(() => {
        setSelectedFileLabel('Drag and Drop Your File Here');
      }, 2000);
    } else {
      onFileChangeHandler({
        target: { files: event.dataTransfer.files },
      } as ChangeEvent<HTMLInputElement>);
    }
  };

  const onUploadProgress = (progressEvent: ProgressEvent) => {
    const percentCompleted = Math.floor(
      (progressEvent.loaded * 100) / progressEvent.total,
    );
    setUploadProgress(percentCompleted);
  };

  const uploadStopsFile = async (dryRun: boolean = true) => {
    setShowProgress(true);
    setError(undefined);
    try {
      setIsUploading(true);
      const response = await StationsService.importStops({
        dryRun,
        file: selectedFile,
        options: {
          onUploadProgress,
        },
      });

      if (dryRun) {
        setDryRunResults(response.data);
        setDryRunComplete(true);
      } else {
        updateParent();
        handleClose();
      }
      setShowProgress(false);
      setIsUploading(false);
    } catch (e) {
      setSelectedFile(undefined);
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  const renderFileSelectionForm = () => {
    return (
      <form
        data-testid={'import-stops:drop-zone'}
        className={classes.fileDropZone}
        onDragOver={(e: React.DragEvent<HTMLFormElement>) => {
          e.preventDefault();
        }}
        onDragLeave={(e: React.DragEvent<HTMLFormElement>) => {
          e.preventDefault();
        }}
        onDrop={(e: React.DragEvent<HTMLFormElement>) => {
          e.preventDefault();
          onFileDroppedHandler(e);
        }}
      >
        <input
          accept='.csv'
          style={{ display: 'none' }}
          id='button-file'
          type='file'
          onChange={onFileChangeHandler}
        />
        <p>{selectedFileLabel}</p>
        <label htmlFor='button-file'>
          <CSButton
            component='span'
            variant='contained'
            color={{
              buttonColor: 'secondary',
              iconColor: 'primary',
            }}
            startIcon={<FileCopyOutlined />}
          >
            Browse File
          </CSButton>
        </label>
      </form>
    );
  };

  const renderUploadingProgress = () => {
    return (
      <Paper
        elevation={2}
        className={classes.fileContainer}
        data-testid={'imported-stops-info'}
      >
        <Grid container>
          <Grid item xs={2} className={classes.padding10}>
            <img
              src={`${
                process.env.REACT_APP_BASENAME || ''
              }/icons/csv-file.png`}
              alt={'CSV'}
            />
          </Grid>
          <Grid item xs={10} className={classes.padding10}>
            <p className={classes.uploadFileInfo}>
              <span className={classes.uploadFilename}>
                {selectedFileLabel}
              </span>
              <span className={classes.uploadPercentage}>
                {uploadProgress}%
              </span>
            </p>
            <div className={classes.uploadProgressBar}>
              <div
                className={classes.uploadProgressBarInner}
                style={{ width: `${uploadProgress}%` }}
              ></div>
            </div>
          </Grid>
        </Grid>
      </Paper>
    );
  };

  const renderDryRunResults = () => {
    const importIssuesData = (dryRunResults?.issues || []).reduce(
      (
        acc: Partial<ImportSummaryIssue>[],
        curr: ImportSummaryIssue,
      ) => {
        let newItem: Partial<ImportSummaryIssue> = {
          ...curr,
          ...curr.content,
        };
        delete newItem['content'];
        acc.push(newItem);
        return acc;
      },
      [] as Partial<ImportSummaryIssue>[],
    );
    return (
      <Grid container data-testid='dry-run-results'>
        <Grid item container>
          <Grid item container xs={11}>
            <Typography component='p' variant='body2'>
              New {stopsLabels.plural}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography
              component='p'
              variant='body1'
              color={{
                color: 'grey',
                variant: 'A400',
              }}
              align='right'
              data-testid={'added-stops'}
            >
              {dryRunResults?.new_stops}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container className={classes.marginBottom10}>
          <Grid item container xs={11}>
            <Typography component='p' variant='body2'>
              Updated {stopsLabels.plural}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography
              component='p'
              variant='body1'
              color={{
                color: 'grey',
                variant: 'A400',
              }}
              align='right'
              data-testid={'updated-stops'}
            >
              {dryRunResults?.updated_stops}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          item
          container
          xs={12}
          className={classes.marginBottom10}
        >
          <Grid item container xs={4} direction={'column'}>
            <Typography component='p' variant='body2'>
              Used Fields
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography
              component='p'
              variant='body1'
              color={{
                color: 'grey',
                variant: 'A400',
              }}
              data-testid={'stops-used-fields'}
            >
              {dryRunResults?.used_fields.length
                ? dryRunResults.used_fields.map((field) => (
                    <span
                      className={classes.highlightedField}
                      key={field}
                    >
                      {field}
                    </span>
                  ))
                : 'None'}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          item
          container
          xs={12}
          className={classes.marginBottom10}
        >
          <Grid item container xs={4} direction={'column'}>
            <Typography component='p' variant='body2'>
              Ignored Fields
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography
              component='p'
              variant='body1'
              color={{
                color: 'grey',
                variant: 'A400',
              }}
              data-testid={'ignored-fields-stops'}
            >
              {dryRunResults?.ignored_fields.length
                ? dryRunResults.ignored_fields.map((field) => (
                    <span
                      className={classes.highlightedField}
                      key={field}
                    >
                      {field}
                    </span>
                  ))
                : 'None'}
            </Typography>
          </Grid>
        </Grid>

        {dryRunResults?.issues && dryRunResults?.issues.length > 0 && (
          <Grid
            item
            container
            xs={12}
            className={classes.marginBottom10}
          >
            <Grid item container xs={4} direction={'column'}>
              <Typography component='p' variant='body2'>
                Issues
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography
                component='p'
                variant='body1'
                color={{
                  color: 'grey',
                  variant: 'A400',
                }}
                data-testid={'ignored-fields-stops'}
              >
                Found {dryRunResults?.issues.length}{' '}
                {dryRunResults?.issues.length > 1
                  ? 'issues'
                  : 'issue'}
                <br />
                <CSVLink
                  className={classes.link}
                  style={{
                    fontSize: '15px',
                    color: theme.palette.tertiary?.main,
                  }}
                  data={importIssuesData}
                  filename={`${selectedFileLabel}-import-issues-log.csv`}
                >
                  Download summary
                </CSVLink>
              </Typography>
            </Grid>
          </Grid>
        )}
        <Box m={0.8} />
      </Grid>
    );
  };

  return (
    <>
      {showProgress && <ProgressIndicator />}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={(e) => {
          handleClose();
        }}
      >
        {type === 'add' && ( //Logic related to add stops functionality
          <>
            {currentStep === AddStopMethod.NONE && (
              <>
                <DialogTitle className={classes.dialogTitle}>
                  <AddBoxOutlinedIcon
                    className={classes.dialogIcon}
                  />
                  <Typography component='p' variant='h5'>
                    {`Add a new ${stopsLabels.singular}`}
                  </Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                  {renderModeSelection()}
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                  <CSButton
                    onClick={(e) => {
                      handleClose();
                    }}
                    variant='outlined'
                    color='secondary'
                  >
                    Close
                  </CSButton>
                </DialogActions>
              </>
            )}
            {currentStep === AddStopMethod.CREATE && (
              <>
                <DialogTitle className={classes.dialogTitle}>
                  <AddBoxOutlinedIcon
                    className={classes.dialogIcon}
                  />
                  <Typography component='p' variant='h5'>
                    {`Add ${stopsLabels.singular}`}
                  </Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                  {error && (
                    <AlertBanner
                      className={classes.banner}
                      severity='error'
                      alertTitle={'Error'}
                      alertMsg={error.message}
                    />
                  )}
                  {renderStopForm()}
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                  <CSButton
                    onClick={(e) => {
                      handleClose();
                    }}
                    variant='outlined'
                    color='secondary'
                  >
                    Cancel
                  </CSButton>
                  <CSButton
                    data-testid={'add-stop-save-button'}
                    disabled={
                      !stopParams.name ||
                      !stopParams.address ||
                      !stopParams.city ||
                      !stopParams.state ||
                      !stopParams.zipcode
                    }
                    onClick={(e) => {
                      stopHandler();
                    }}
                    variant='contained'
                    color='secondary'
                  >
                    Save
                  </CSButton>
                </DialogActions>
              </>
            )}
            {currentStep === AddStopMethod.IMPORT && (
              <>
                <DialogTitle className={classes.dialogTitle}>
                  <CloudUploadOutlined
                    className={classes.dialogIcon}
                  />
                  <Typography component='p' variant='h5'>
                    {`Import ${stopsLabels.plural}`}
                  </Typography>
                </DialogTitle>
                {/* Preload the .csv file icon used during upload progress screens*/}
                <link
                  rel='preload'
                  as='image'
                  href={`${
                    process.env.REACT_APP_BASENAME || ''
                  }/icons/csv-file.png`}
                />
              </>
            )}
            {currentStep === AddStopMethod.IMPORT && dryRunComplete && (
              <>
                <DialogContent className={classes.dialogContent}>
                  {!isUploading && (
                    <>
                      {renderUploadingProgress()}
                      <Box mb={2} />
                    </>
                  )}
                  {renderDryRunResults()}
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                  <CSButton
                    variant='outlined'
                    color='secondary'
                    onClick={(e) => {
                      handleClose();
                    }}
                  >
                    Cancel
                  </CSButton>
                  <CSButton
                    variant='contained'
                    color='secondary'
                    data-testid='complete-import'
                    onClick={(e) => {
                      uploadStopsFile(false);
                    }}
                  >
                    Import
                  </CSButton>
                </DialogActions>
              </>
            )}
            {currentStep === AddStopMethod.IMPORT &&
              !isUploading &&
              !dryRunComplete && (
                <>
                  <DialogContent className={classes.dialogContent}>
                    {renderFileSelectionForm()}
                  </DialogContent>
                  <DialogActions className={classes.dialogActions}>
                    <CSButton
                      variant='outlined'
                      color='secondary'
                      onClick={(e) => {
                        handleClose();
                      }}
                    >
                      Cancel
                    </CSButton>
                    <CSButton
                      variant='contained'
                      color='secondary'
                      data-testid='start-import'
                      onClick={(e) => {
                        uploadStopsFile();
                      }}
                      disabled={selectedFile === undefined}
                    >
                      Import
                    </CSButton>
                  </DialogActions>
                </>
              )}
            {currentStep === AddStopMethod.IMPORT && isUploading && (
              <>
                <DialogContent className={classes.dialogContent}>
                  {error && (
                    <AlertBanner
                      className={classes.banner}
                      severity='error'
                      alertTitle={'Error'}
                      alertMsg={error.message}
                    />
                  )}
                  {error?.data && (
                    <Typography>
                      <CSVLink
                        className={classes.link}
                        style={{
                          fontSize: '15px',
                          color: theme.palette.tertiary?.main,
                        }}
                        data={error?.data || ''}
                        filename={`${selectedFileLabel}-import-errors-log.csv`}
                      >
                        Download summary
                      </CSVLink>
                    </Typography>
                  )}
                  {renderUploadingProgress()}
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                  <CSButton
                    variant='outlined'
                    color='secondary'
                    onClick={(e) => {
                      handleClose();
                    }}
                  >
                    Cancel
                  </CSButton>
                  <CSButton
                    variant='outlined'
                    color='secondary'
                    onClick={(e) => {
                      uploadStopsFile();
                    }}
                    disabled={!!error}
                  >
                    Import
                  </CSButton>
                </DialogActions>
              </>
            )}
          </>
        )}
        {type === 'edit' && ( //Logic related to edit stops functionality
          <>
            <DialogTitle className={classes.dialogTitle}>
              <EditIcon className={classes.dialogIcon} />
              <Typography component='p' variant='h5'>
                {`Edit ${stopsLabels.singular}`}
              </Typography>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
              {error && (
                <AlertBanner
                  className={classes.banner}
                  severity='error'
                  alertTitle={'Error'}
                  alertMsg={error.message}
                />
              )}
              {renderStopForm()}
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <CSButton
                variant='outlined'
                color='secondary'
                onClick={(e) => {
                  handleClose();
                }}
              >
                Cancel
              </CSButton>
              <CSButton
                variant='contained'
                color='secondary'
                data-testid={'add-stop-save-button'}
                disabled={
                  !stopParams.name ||
                  !stopParams.address ||
                  !stopParams.city ||
                  !stopParams.state ||
                  !stopParams.zipcode
                }
                onClick={(e) => {
                  stopHandler();
                }}
              >
                Save
              </CSButton>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...selectStyles,
    ...sectionPageBaseStyle,
    dialogContent: {
      width: '487px',
      [theme.breakpoints.down('sm')]: {
        width: 'auto',
      },
      padding: '20px 31px',
    },
    dialogTitle: {
      textAlign: 'center',
      marginTop: '20px',
      padding: '0px 31px',
    },
    dialogActions: {
      paddingBottom: '31px',
      paddingRight: '31px',
      paddingLeft: '31px',
    },
    dialogIcon: {
      width: '24px',
      height: 'auto',
      color: theme.palette.primary.main,
    },
    modeSelectionContainer: {
      paddingTop: '30px',
    },
    editStopTextFieldContainer: {
      width: '100%',
      marginTop: 13,
    },
    editStopTextField: {
      height: '30px',
    },
    fileDropZone: {
      ...theme.typography.h6,
      margin: '20px 0',
      borderRadius: '5px',
      display: 'flex',
      flexDirection: 'column',
      padding: '25px',
      alignItems: 'center',
      border: '2px dashed',
      borderColor: theme.palette.tertiary?.main,
      backgroundColor: theme.palette.background.default,
      '& p': {
        color: theme.palette.tertiary?.main,
      },
    },
    fileContainer: {
      margin: '20px 0',
      padding: '0 10px',
    },
    uploadFileInfo: {
      margin: '0 0 5px 0',
    },
    uploadFilename: {
      ...theme.typography.body2,
      display: 'inline-block',
      maxWidth: '200px',
      overflow: 'hidden',
    },
    uploadPercentage: {
      color: theme.palette.grey.A400,
      float: 'right',
    },
    uploadProgressBar: {
      width: '100%',
      background: theme.palette.grey.A200,
      height: '5px',
      borderRadius: '2px',
      overflow: 'hidden',
    },
    uploadProgressBarInner: {
      background: theme.palette.tertiary?.main,
      height: '5px',
    },
    padding10: {
      padding: '10px',
    },
    marginBottom10: {
      marginBottom: '10px',
    },
    iconBox: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      padding: '50px',
      backgroundColor: theme.palette.grey.A200,
    },
    csvFileIconBadge: {
      position: 'absolute',
      top: 10,
      right: 10,
      width: '30px',
      height: 'auto',
    },
    modeSelectionIcon: {
      margin: '10px auto',
      height: '63px',
      width: 'auto',
      color: theme.palette.tertiary?.main,
    },
    cardRoot: {
      margin: '0 10px 10px 10px',
    },
    cardContent: {
      padding: '0px!important',
      paddingBottom: '0!important',
      cursor: 'pointer',
    },
    highlightedField: {
      display: 'inline-block',
      backgroundColor: theme.palette.secondary.main,
      padding: 5,
      borderRadius: 4,
      margin: '0 5px 5px 0',
      color: theme.palette.common.white,
      ...theme.typography.subtitle1,
    },
  })),
)(AddStopsDialog);
