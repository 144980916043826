import {
  createStyles,
  Radio,
  RadioProps,
  Theme,
  withStyles,
} from '@material-ui/core';
import React from 'react';

interface CSRadioProps extends RadioProps {
  classes: { [key: string]: string };
}

const CSRadio: React.FC<CSRadioProps> = ({ classes, ...props }) => {
  return <Radio color='primary' {...props} />;
};

export default withStyles(createStyles((theme: Theme) => ({})))(
  CSRadio,
);
