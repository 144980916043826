import { Theme, createStyles } from '@material-ui/core';

export default createStyles((theme: Theme) => ({
  dialog: {
    maxWidth: '488px',
  },
  dialogActions: {
    marginRight: '13px',
  },
  datePickerTextField: {
    width: '100%',
  },
  multipleDaysRadioButton: {
    marginLeft: '54px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'auto',
    },
  },
}));
