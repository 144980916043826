import axios from '../utils/axios';

class Mapbox {
  public async getCeocodingData({
    search,
    type = ['place', 'country'],
  }: {
    search: string;
    type?: string[];
  }) {
    return axios.get(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${search}.json?access_token=${
        process.env.REACT_APP_MAPBOX_API_KEY || ''
      }&types=${type.join(',')}`,
    );
  }
}

export default new Mapbox();
