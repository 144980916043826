import React from 'react';
import { Typography, AlertBanner } from '../../primitives';
import styles from './processSteps.styles';
import {
  useTheme,
  withStyles,
  useMediaQuery,
  Grid,
  GridSize,
} from '@material-ui/core';
import {
  NestedProcessStepSerializerPackage,
  NestedProcessStepSerializerContainer,
} from 'cloudsort-client';
import chunk from 'lodash/chunk';

import fedexLabels from '../../../utils/fedexLabels';

// Icons
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

interface Props {
  classes: { [key: string]: string };
  stepsData: (
    | NestedProcessStepSerializerContainer
    | NestedProcessStepSerializerPackage
  )[];
  dataTestPrefix: string;
}

const ProcessSteps: React.FC<Props> = ({
  classes,
  stepsData,
  dataTestPrefix,
}) => {
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down('xs'), {
    noSsr: true,
  });
  const cellsPerRow = isXsScreen ? 2 : 4;
  let lastIndex: number;
  const stepsDataChunks = (
    array: (
      | NestedProcessStepSerializerContainer
      | NestedProcessStepSerializerPackage
    )[],
    chunkSize: number,
  ) => {
    const stepsDataChunksArray = chunk(array, chunkSize);
    lastIndex = stepsDataChunksArray.length - 1;
    return stepsDataChunksArray;
  };
  const renderRow = (
    data: (
      | NestedProcessStepSerializerContainer
      | NestedProcessStepSerializerPackage
    )[],
    isLastRow: boolean,
    cellsPerRow: 2 | 4,
  ) => {
    const MATERIALS_UI_MAX_COLUMNS = 12;
    const containerResponsiveColumns = (data.length *
      Math.round(MATERIALS_UI_MAX_COLUMNS / cellsPerRow)) as GridSize;
    const elementResponsiveColumns = Math.round(
      MATERIALS_UI_MAX_COLUMNS / data.length,
    ) as GridSize;
    return (
      <Grid
        item
        container
        xs={containerResponsiveColumns}
        className={classes.paperHolder}
      >
        {data.map((step, index, array) => (
          <Grid
            className={`${classes.singleStat} ${classes.eventSnapshotContainer}`}
            key={`${step.process_step}-${index}`}
            data-testid={`${dataTestPrefix}-event-snapshots-${step.process_step}`}
            xs={elementResponsiveColumns}
            item
          >
            {index === array.length - 1 && isLastRow && (
              <ArrowDropDownIcon
                data-testid={'events-snapshot-active'}
                className={classes.activeEventTypeMarker}
              />
            )}
            <Typography
              color={{ color: 'text', variant: 'icon' }}
              variant='subtitle1'
              component='h3'
              className={classes.singleStatLabel}
            >
              {fedexLabels.getLabel(step.process_step as string)}
            </Typography>
            <Typography
              variant='h6'
              className={classes.statsValue}
              data-testid={`${dataTestPrefix}-event-snapshots-${step.process_step}:time`}
            >
              {new Date(step.timestamp!)
                .toLocaleString('en-US', {
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: true,
                })
                .toLocaleLowerCase()}
            </Typography>
            <Typography
              className={classes.singleStatLabel}
              data-testid={`${dataTestPrefix}-event-snapshots-${step.process_step}:date`}
            >
              {new Date(step.timestamp!).toLocaleString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: 'numeric',
              })}
            </Typography>
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <Grid
      container
      item
      className={classes.eventsSnapshotOuterContainer}
      data-testid={dataTestPrefix + '-event-snapshots'}
      xs={12}
      spacing={2}
    >
      {stepsData.length === 0 && (
        <AlertBanner
          className={classes.noShadow}
          severity='info'
          alertTitle={
            'There is no information to display at the moment'
          }
        />
      )}
      {stepsDataChunks(stepsData, cellsPerRow).map(
        (stepsDataChunks, index) => {
          const lastRow = index === lastIndex;
          return (
            <Grid key={index} container item xs={12}>
              {renderRow(stepsDataChunks, lastRow, cellsPerRow)}
            </Grid>
          );
        },
      )}
    </Grid>
  );
};

export default withStyles(styles)(ProcessSteps);
