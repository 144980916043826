import {
  FlowPackageCount,
  StationLiveViewDispatchTime,
  StationPackageCountData,
} from 'cloudsort-client';
import { Position } from 'react-flow-renderer';
import { findMinMaxCoordinates } from '../helpers';
import { MappedElement } from '../StationLayout';
import { NodeWithData } from './interfaces';

// Make areas smaller so there's more place for links
// Should be less than 1 = 100%
const RELATIVE_SCALE = 0.75;

const getSide = (points: number[][], index: number) =>
  Math.sqrt(
    Math.pow(Math.abs(points[index][0] - points[index + 1][0]), 2) +
      Math.pow(Math.abs(points[index][1] - points[index + 1][1]), 2),
  );

const getAreaType = (type: string | undefined): string => {
  switch (type) {
    case 'PRIMARY':
      return 'primaryArea';
    case 'SECONDARY':
      return 'secondaryArea';
    case 'STAGING':
      return 'stagingArea';
    case 'DOCKDOOR':
      return 'dockArea';
    default:
      return '';
  }
};

// Generate Nodes from AREAS and DOCKDOORS
const generateNodes = (
  mAreas: MappedElement[],
  mZones: MappedElement[],
  mLoadpoints: MappedElement[],
  mDockdoors: MappedElement[],
  flowMax: number,
  currentPackages: StationPackageCountData,
  exitPackages: StationPackageCountData,
  relativeScale: number = RELATIVE_SCALE,
  dispatchTimes: StationLiveViewDispatchTime,
  isThroughputMode: boolean,
): { data: NodeWithData[]; max: number } => {
  //Find maximum PPM
  let exitPackagesMax = flowMax; //set it initially to max value from flow / edges

  exitPackages.areas?.forEach((area) => {
    area.zones?.forEach((zone) => {
      zone.loadpoints?.forEach((loadpoint) => {
        if (loadpoint.count > exitPackagesMax) {
          exitPackagesMax = loadpoint.count;
        }
      });
    });
  });

  const getExitPackagesForItem = (
    areaId?: number,
    zoneId?: number,
    loadpointId?: number,
  ) => {
    if (!loadpointId) {
      return (
        exitPackages.areas?.find((area) => area.area_id === areaId)
          ?.accumulated_count || 0
      );
    } else {
      return (
        exitPackages.areas
          ?.find((area) => area.area_id === areaId)
          ?.zones?.find((zone) => zone.zone_id === zoneId)
          ?.loadpoints?.find((lp) => lp.loadpoint_id === loadpointId)
          ?.count || 0
      );
    }
  };

  const getCurrentPackagesForItem = (
    areaId?: number,
    zoneId?: number,
    loadpointId?: number,
  ) => {
    if (!loadpointId) {
      return (
        currentPackages.areas?.find((area) => area.area_id === areaId)
          ?.accumulated_count || 0
      );
    } else {
      return (
        currentPackages.areas
          ?.find((area) => area.area_id === areaId)
          ?.zones?.find((zone) => zone.zone_id === zoneId)
          ?.loadpoints?.find((lp) => lp.loadpoint_id === loadpointId)
          ?.count || 0
      );
    }
  };

  const areas: NodeWithData[] = mAreas.map((area) => {
    const minMaxPoints = findMinMaxCoordinates(area.points);

    const isHorizontalOrientation =
      Math.round(area.points[0][0]) !== Math.round(area.points[1][0]);

    const side1 = getSide(area.points, 0) * relativeScale;
    const side2 = getSide(area.points, 1) * relativeScale;

    const x = minMaxPoints.x.min + (side1 * (1 - relativeScale)) / 2;
    const y = minMaxPoints.y.min + (side2 * (1 - relativeScale)) / 2;

    return {
      id: 'AREA_' + area.id.toString(),
      type: getAreaType(area.type),
      className: 'dark-node',
      data: {
        entityType: 'area',
        entityId: area.id,
        name: area.name,
        width: isHorizontalOrientation ? side1 : side2,
        height: isHorizontalOrientation ? side2 : side1,
        packages: 0,
        exitPackages: getExitPackagesForItem(area.id),
        isThroughputMode,
        meta: {
          areaMeta: dispatchTimes.data?.areas?.find(
            (a) => a.area_id === area.id,
          ),
        },
        zones:
          area.type === 'SECONDARY'
            ? mZones.reduce((zoneAcc, zoneItem) => {
                if (zoneItem.area === area.id) {
                  return {
                    ...zoneAcc,
                    [zoneItem.id]: {
                      name: zoneItem.name,
                      loadpoints: mLoadpoints
                        .filter((lp) => lp.isActive)
                        .reduce((lpAcc, lpItem, index) => {
                          if (lpItem.zone === zoneItem.id) {
                            const exitPackagesValue =
                              getExitPackagesForItem(
                                area.id,
                                zoneItem.id,
                                lpItem.id,
                              );
                            return {
                              ...lpAcc,
                              [lpItem.id]: {
                                name: lpItem.name,
                                exitPackages: exitPackagesValue,
                                packages: getCurrentPackagesForItem(
                                  area.id,
                                  zoneItem.id,
                                  lpItem.id,
                                ),
                                additionalCssClass: isThroughputMode
                                  ? getAdditionalCSSClass(
                                      exitPackagesValue,
                                      exitPackagesMax,
                                    )
                                  : '',
                                meta: {
                                  loadpointMeta:
                                    dispatchTimes.data?.areas?.find(
                                      (area) =>
                                        area.loadpoint_id ===
                                        lpItem.id,
                                    ),
                                },
                              },
                            };
                          }
                          return lpAcc;
                        }, {}),
                    },
                  };
                }
                return zoneAcc;
              }, {})
            : null,
        loadpoints:
          area.type === 'PRIMARY'
            ? mLoadpoints
                .filter((lp) => lp.isActive)
                .reduce((acc, item) => {
                  if (item.area === area.id) {
                    const exitPackagesValue = getExitPackagesForItem(
                      item.area,
                      item.zone,
                      item.id,
                    );
                    return {
                      ...acc,
                      [item.id]: {
                        name: item.name,
                        packages: getCurrentPackagesForItem(
                          item.area,
                          item.zone,
                          item.id,
                        ),
                        exitPackages: exitPackagesValue,
                        additionalCssClass: isThroughputMode
                          ? getAdditionalCSSClass(
                              exitPackagesValue,
                              exitPackagesMax,
                            )
                          : '',
                        meta: {
                          loadpointMeta:
                            dispatchTimes.data?.areas?.find(
                              (area) => area.loadpoint_id === item.id,
                            ),
                        },
                      },
                    };
                  }
                  return acc;
                }, {})
            : null,
      },
      position: { x, y },
    };
  });

  const dockdoors: NodeWithData[] = mDockdoors.map((dockdoor) => {
    const minMaxPoints = findMinMaxCoordinates(dockdoor.points);

    const isHorizontalOrientation =
      Math.round(dockdoor.points[0][0]) !==
      Math.round(dockdoor.points[1][0]);

    const side1 = getSide(dockdoor.points, 0) * relativeScale;
    const side2 = getSide(dockdoor.points, 1) * relativeScale;

    const x = minMaxPoints.x.min + (side1 * (1 - relativeScale)) / 2;
    const y = minMaxPoints.y.min + (side2 * (1 - relativeScale)) / 2;

    return {
      id: 'DOCKDOOR_' + dockdoor.id.toString(),

      type: getAreaType('DOCKDOOR'),
      className: 'dark-node',
      data: {
        entityType: 'dockdoor',
        entityId: dockdoor.id,
        name: dockdoor.name,
        width: isHorizontalOrientation ? side1 : side2,
        height: isHorizontalOrientation ? side2 : side1,
        packages: 0,
        ppm: 0,
        meta: {
          isThroughputMode,
          dispatchTime: dispatchTimes.data?.dockdoors?.find(
            (dd) => dd.dockdoor_id === dockdoor.id,
          )?.expected_time,
        },
      },
      position: { x, y },
    };
  });

  return { data: [...areas, ...dockdoors], max: exitPackagesMax };
};

// Filter out flow data -- keep only the data between areas and dock doors
const filterOutFlowData = (
  flow: FlowPackageCount[],
): FlowPackageCount[] => {
  return flow?.filter(
    (flowItem: any) =>
      !flowItem.from_location.zone_id &&
      !flowItem.to_location.zone_id &&
      !flowItem.from_location.loadpoint_id &&
      !flowItem.to_location.loadpoint_id,
  );
};

// Find the maximum value for flow edge -- including summed edges going to/from same destination
const findFlowMaximumValue = (flow: FlowPackageCount[]): number => {
  const max = flow?.map((item) => {
    let a = 0,
      b = 0,
      c = 0;
    a = flow
      .filter(
        (innerItem) =>
          innerItem.from_location?.area_id ===
          item.from_location?.area_id,
      )
      .reduce((acc, it) => {
        return acc + (it.accumulated_ppm || 0);
      }, 0);

    if (item.to_location?.area_id) {
      b = flow
        .filter(
          (innerItem) =>
            innerItem.to_location?.area_id ===
            item.to_location?.area_id,
        )
        .reduce((acc, it) => {
          return acc + (it.accumulated_ppm || 0);
        }, 0);
    }

    if (item.to_location?.dockdoor_id) {
      c = flow
        .filter(
          (innerItem) =>
            innerItem.to_location?.dockdoor_id ===
            item.to_location?.dockdoor_id,
        )
        .reduce((acc, it) => {
          return acc + (it.accumulated_ppm || 0);
        }, 0);
    }

    return Math.max(a, b, c);
  });

  if (max) {
    return Math.max(...max);
  }
  return 0;
};

const findInvertedPosition = (position: Position): Position => {
  switch (position) {
    case Position.Top:
      return Position.Bottom;
    case Position.Bottom:
      return Position.Top;
    case Position.Left:
      return Position.Right;
    case Position.Right:
      return Position.Left;
  }
};

const generateGhostNode = (
  node: NodeWithData,
  direction: 'IN' | 'OUT',
): NodeWithData => {
  const ghostPosition =
    direction === 'IN' ? node.targetPosition : node.sourcePosition;

  const HORIZONTAL_OFFSET = 60;
  const VERTICAL_OFFSET = 40;

  const xyPosition = { x: 0, y: 0 };

  if (ghostPosition === Position.Left) {
    xyPosition.x = node.position.x - HORIZONTAL_OFFSET;
    xyPosition.y = node.position.y + node.data?.height! / 2 - 4;
  } else if (ghostPosition === Position.Right) {
    xyPosition.x =
      node.position.x + node.data?.width! + HORIZONTAL_OFFSET;
    xyPosition.y = node.position.y + node.data?.height! / 2 - 4;
  } else if (ghostPosition === Position.Top) {
    xyPosition.x = node.position.x + node.data?.width! / 2 - 4;
    xyPosition.y = node.position.y - VERTICAL_OFFSET;
  } else if (ghostPosition === Position.Bottom) {
    xyPosition.x = node.position.x + node.data?.width! / 2 - 4;
    xyPosition.y =
      node.position.y + node.data?.height! + VERTICAL_OFFSET;
  }

  return {
    id: direction + '_' + node.id,
    position: xyPosition,
    type: 'ghost',
    sourcePosition:
      direction === 'OUT'
        ? node.sourcePosition
        : findInvertedPosition(node.targetPosition!),
    targetPosition:
      direction === 'OUT'
        ? findInvertedPosition(node.sourcePosition!)
        : node.targetPosition,
  };
};

const getAdditionalCSSClass = (
  current: number = 0,
  max: number,
): string => {
  const ratio = current / max;
  if (ratio > 0.75) {
    return 'shade4';
  } else if (ratio > 0.5) {
    return 'shade3';
  } else if (ratio > 0.25) {
    return 'shade2';
  } else {
    return 'shade1';
  }
};

const getFormattedTimeLeft = (time: string): string => {
  const parts = time.split(' ');

  let days;

  if (parts[0] !== '00') days = Number(parts[0]);

  const hours = Number(parts[1].split(':')[0]);
  const minutes = Number(parts[1].split(':')[1]);

  if (days) {
    return `${days}d ${hours}h ${minutes}m`;
  } else if (Number(hours)) {
    return `${Number(hours)}h ${Number(minutes)}m`;
  } else {
    return `${Number(minutes)}min`;
  }
};

export {
  generateNodes,
  filterOutFlowData,
  findFlowMaximumValue,
  generateGhostNode,
  getFormattedTimeLeft,
};
