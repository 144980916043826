import {
  Paper,
  withStyles,
  createStyles,
  Theme,
  Grid,
} from '@material-ui/core';

import { Typography } from '../../../primitives';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {
  renderLocalTimestamp,
  renderLocalTimezone,
} from '../../utils';
import { TrackableContainer } from 'cloudsort-client';

type Props = {
  classes: { [key: string]: string };
  container: TrackableContainer;
  selected: boolean;
  isUpdating: boolean;
  onSelect: (container: TrackableContainer) => void;
};

const ContainerCard = ({
  classes,
  container,
  isUpdating,
  selected,
  onSelect,
}: Props) => {
  return (
    <Paper
      style={{ opacity: isUpdating ? 0.3 : 1 }}
      className={`${classes.card} ${
        selected ? classes.selectedCard : ''
      }`}
      onClick={() => {
        onSelect(container);
      }}
    >
      <Grid container>
        <Grid item xs={10}>
          <Typography variant='body1'>
            <img
              src={`${
                process.env.REACT_APP_BASENAME || ''
              }/icons/box.svg`}
              alt={container.identifier}
              className={classes.containerIcon}
            />{' '}
            {container.identifier}
          </Typography>
          <Typography
            variant='body2'
            color={{ color: 'grey', variant: 'A400' }}
            className={classes.marginTop10}
          >
            Last Event
          </Typography>
          <Typography variant='body2'>
            {container.tracker_device.last_location_ts
              ? `${renderLocalTimestamp(
                  container.tracker_device.last_location_ts,
                )} ${renderLocalTimezone()}`
              : 'N/A'}
          </Typography>
        </Grid>
        <Grid item xs={2} className={classes.arrowContainer}>
          <ChevronRightIcon />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    card: {
      padding: 10,
      marginTop: 10,
      boxShadow: theme.shadows[2],
      cursor: 'pointer',
    },
    selectedCard: {
      border: '2px solid',
      borderColor: theme.palette.secondary.main,
    },
    arrowContainer: {
      textAlign: 'right',
    },
    containerIcon: {
      marginBottom: -5,
    },
    marginTop10: {
      marginTop: 10,
    },
  })),
)(ContainerCard);
