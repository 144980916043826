import colors from '../../utils/colors';
import { Theme } from '@material-ui/core/styles';
export const getDetailsPageStylesWithTheme = (
  theme: Theme | undefined,
) => ({
  subTitle: {
    color: theme?.palette.quaternary?.main || colors.dark,
    fontSize: 14,
    letterSpacing: 0,
    lineHeight: '16px',
    fontWeight: 'bold',
  },
  paper: {
    width: '100%',
    height: 'auto',
    borderRadius: theme?.shape.borderRadius || '2',
    backgroundColor: theme?.palette.background.paper || '#FFFFF',
    padding: '20px 30px',
    boxShadow:
      theme?.shadows[2] || '0px 0px 9px 0px rgba(0,0,0,0.08)',
  },
  darkMoreVertIcon: {
    height: 28,
    width: 28,
    backgroundColor: theme?.palette.quaternary?.main || colors.dark,
    color: theme?.palette.quaternary?.contrastText || colors.white,
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: theme?.palette.quaternary?.main || colors.dark,
      color: theme?.palette.quaternary?.contrastText || colors.white,
    },
  },
  boldNameParam: {
    color: theme?.palette.quaternary?.main || colors.dark,
    fontSize: 15,
    fontWeight: 500,
    letterSpacing: 0,
    lineHeight: '18px',
  },
  paramValueGold: {
    color: theme?.palette.secondary?.main || colors.darkGold,
    fontSize: 15,
    letterSpacing: 0,
    lineHeight: '18px',
    '& a': {
      color: theme?.palette.secondary?.main || colors.darkGold,
    },
  },
  paramValue: {
    color: colors.gray,
    fontSize: 15,
    letterSpacing: 0,
    lineHeight: '18px',
    '& a': {
      color: theme?.palette.secondary?.main || colors.darkGold,
    },
  },
  paramValueLight: {
    color: colors.gray,
    fontSize: 15,
    letterSpacing: 0,
    lineHeight: '18px',
    '& a': {
      color: colors.gray,
    },
  },
  singleStat: {
    flexGrow: '0',
    minHeight: 88,
    padding: '16px 16px 2px 16px',
    borderRight: theme
      ? `${theme.borderWidth.border_sm} solid ${theme.palette.grey[300]}`
      : `2px solid #F5F6F9`,
    '&:last-child': {
      borderRight: 'none',
    },
  },
  singleStatLabel: {
    marginBottom: 5,
    color: theme?.palette.text.icon || colors.lightGray,
  },
  statsValue: {
    fontSize: '1.75rem',
    fontWeight: 'normal',
    lineHeight: 1,
  },
  containedButton: {
    boxSizing: 'border-box',
    height: 42,
    border: `2px solid ${colors.darkGold}`,
    borderRadius: 4,
    boxShadow: '0 2px 8px 0 rgba(0,0,0,0.02)',
    color: colors.darkGold,
    backgroundColor: 'transparent',
    textTransform: 'none',
    marginBottom: 10,
    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: '0 2px 8px 0 rgba(0,0,0,0.02)',
      border: `2px solid ${colors.darkGold}`,
      color: colors.darkGold,
    },
    '&:disabled': {
      border: 0,
    },
  },
  containedButtonDark: {
    border: `2px solid ${colors.dark}`,
    color: colors.dark,
    '&:hover': {
      border: `2px solid ${colors.dark}`,
      color: colors.dark,
    },
  },
  banner: {
    marginBottom: 15,
  },
  bannerGold: {
    width: '100%',
    borderRadius: 4,
    backgroundColor: '#F1E0D1',
    boxShadow: '0 4px 16px 0 rgba(0,0,0,0.08)',
    color: colors.dark,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '40px 0 20px 0',
    paddingBottom: 15,
  },
  title: {
    opacity: 1,
    margin: '6px 0',
  },
  link: {
    color: theme?.palette.tertiary?.main || colors.darkGold,
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:hover': {
      color: theme?.palette.tertiary?.main || colors.darkGold,
    },
  },
  accordionExpandColapseIcon: {
    color: colors.white,
    fontSize: '2.3rem',
  },
  titleSpacing: {
    marginTop: 15,
    marginBottom: 15,
  },
});
/*
 TODO: Erase the raw export
 This raw export was made as a transitional style for the theming effort
 as to not break the whole app, so we are exporting both the old and new styles
 when the transition ends this should be erased
 */
const rawDetailsPage = getDetailsPageStylesWithTheme(undefined);
export default rawDetailsPage;
