import {
  CorporateConfig,
  Organization,
  PermissionsPermissionsEnum,
  StationDetails,
} from 'cloudsort-client';
import CorportateService from '../../services/Corportate.service';
import OrganizationsService from '../../services/Organizations.service';
import PermissionsService from '../../services/Permissions.service';
import StationsService from '../../services/Stations.service';

export enum Mode {
  CLOUDSORT,
  ORGANIZATION,
  STATION,
}

export enum INTERACTION {
  READ,
  WRITE,
}

export const getMode = (orgId: any, stationId: any) => {
  if (stationId && orgId) {
    return Mode.STATION;
  } else if (orgId) {
    return Mode.ORGANIZATION;
  }
  return Mode.CLOUDSORT;
};

export const getInteraction = (mode: Mode) => {
  if (mode === Mode.CLOUDSORT) {
    if (
      PermissionsService.hasPermission(
        PermissionsPermissionsEnum.MASTER_WRITE,
      )
    ) {
      return INTERACTION.WRITE;
    } else if (
      PermissionsService.hasPermission(
        PermissionsPermissionsEnum.MASTER_READ,
      )
    ) {
      return INTERACTION.READ;
    }
  } else if (mode === Mode.ORGANIZATION) {
    if (
      PermissionsService.hasPermission(
        PermissionsPermissionsEnum.ORGANIZATION_MODIFY,
      )
    ) {
      return INTERACTION.WRITE;
    } else if (
      PermissionsService.hasPermission(
        PermissionsPermissionsEnum.ORGANIZATION_READ,
      )
    ) {
      return INTERACTION.READ;
    }
  } else if (mode === Mode.STATION) {
    if (
      PermissionsService.hasPermission(
        PermissionsPermissionsEnum.STATION_WRITE,
      )
    ) {
      return INTERACTION.WRITE;
    } else if (
      PermissionsService.hasPermission(
        PermissionsPermissionsEnum.STATION_READ,
      )
    ) {
      return INTERACTION.READ;
    }
  }
};

export const getOrganizationStationData = async (
  mode: Mode,
  orgId: number,
  stationId: number,
) => {
  if (mode === Mode.STATION) {
    const { data } = await StationsService.getStationById(stationId);
    return data;
  } else if (mode === Mode.ORGANIZATION) {
    const { data } = await OrganizationsService.getById(orgId);
    return data;
  } else if (
    mode === Mode.CLOUDSORT &&
    PermissionsService.hasPermission(
      PermissionsPermissionsEnum.MASTER_READ,
    )
  ) {
    const { data } = await CorportateService.getCorporateConfig();
    return data;
  }
};

export const patchOrganizationStation = async (
  mode: Mode,
  entityData:
    | Partial<StationDetails>
    | Partial<Organization>
    | Partial<CorporateConfig>,
) => {
  if (mode === Mode.STATION) {
    await StationsService.editStation(entityData);
  } else if (mode === Mode.ORGANIZATION) {
    await OrganizationsService.edit(entityData as Organization);
  } else if (
    mode === Mode.CLOUDSORT &&
    PermissionsService.hasPermission(
      PermissionsPermissionsEnum.MASTER_WRITE,
    )
  ) {
    await CorportateService.edit(entityData as CorporateConfig);
  }
};

export type configurationUrlParams = {
  stationId?: string;
  orgId?: string;
  customerId?: string;
  configurationId?: string;
  created?: string;
};
