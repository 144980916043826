import * as React from 'react';
import { withStyles, createStyles, Theme } from '@material-ui/core';
import { AlertBanner } from '..';

interface Props {
  classes: { [key: string]: string };
  variant?: 'default' | 'outlined';
  inputProps?: any;
  labelProps?: any;
  buttonProps?: any;
  containerProps?: any;
  name: string;
  label: string;
  type?: string;
  autoComplete?: string;
  required?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  value?: string;
  onClick?: (e: React.BaseSyntheticEvent) => any;
  onChange: (e: React.BaseSyntheticEvent) => any;
  labelButton?: string;
  labelButtonAction?: () => any;
  dataTestId?: string;
  validatorFunction?: (value?: string) => boolean;
  validatorError?: string;
}

const CustomTextField: React.FC<Props> = ({
  classes,
  variant = 'default',
  inputProps,
  labelProps,
  buttonProps,
  containerProps,
  name,
  label,
  type = 'text',
  autoComplete,
  required,
  autoFocus,
  disabled,
  value = '',
  onClick,
  onChange,
  labelButton,
  labelButtonAction,
  dataTestId,
  validatorFunction,
  validatorError = 'Invalid data.',
}) => {
  const [hasError, setHasError] = React.useState<boolean>(false);
  return (
    <div className={classes.customInput} {...containerProps}>
      <div className={classes.labelContainer}>
        <label {...labelProps}>{label}</label>{' '}
        {labelButton && (
          <button
            type='button'
            onClick={labelButtonAction}
            {...buttonProps}
          >
            {labelButton}
          </button>
        )}
      </div>
      <input
        className={
          (hasError ? classes.hasError : undefined) +
          ' ' +
          (variant === 'outlined' ? classes.outlined : undefined)
        }
        data-testid={dataTestId}
        required={required}
        autoFocus={autoFocus}
        disabled={disabled}
        name={name}
        type={type}
        onChange={onChange}
        onClick={onClick}
        autoComplete={autoComplete}
        onBlur={() => {
          if (validatorFunction)
            setTimeout(() => {
              validatorFunction(value) || value === ''
                ? setHasError(false)
                : setHasError(true);
            }, 100);
        }}
        value={value}
        {...inputProps}
      />
      {hasError && (
        <AlertBanner
          style={{ marginTop: '10px' }}
          severity='error'
          data-testid='login-error'
          alertMsg={validatorError}
        />
      )}
    </div>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    customInput: {
      marginBottom: '20px',
      '& input': {
        color: theme.palette.text.primary,
        width: '100%',
        fontSize: '1.4rem',
        background: theme.palette.background.paper,
        padding: '15px',
        border: `2px solid ${theme.palette.grey[300]}`,
        '&:focus': {
          borderColor: theme.palette.secondary.main,
          outline: 'none',
        },
      },
    },
    hasError: {
      borderColor: `${theme.palette.error.main} !important`,
    },
    labelContainer: {
      width: '100%',
      marginBottom: '8px',
      '& button': {
        margin: '0',
        padding: '0',
        float: 'right',
        background: 'none',
        border: 'none',
        color: theme.palette.secondary.main,
      },
    },
    outlined: {
      padding: '10px 20px!important',
      borderRadius: '5px',
      border: `1px solid ${theme.palette.grey.A400}!important`,
    },
  })),
)(CustomTextField);
