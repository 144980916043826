import React, { useEffect, useState, Fragment } from 'react';
import { StackedAreaChart } from '../../primitives/index';
import {
  NestedProcessStepSerializerPackageProcessStepEnum,
  ReportDatasetExpectedInfluxInfluxTypeEnum,
} from 'cloudsort-client';
import ReportsService from '../../../services/Reports.service';
import { NestedProcessStepProcessLabels } from '../../../utils/stats';
import { LoaderRing } from '../../primitives';
import {
  FETCH_INTERVAL_TIME,
  FETCH_INTERVAL_ACTIVE,
} from '../../dashboard/Dashboard';
import dateUtils from '../../../services/utils/date';

// Types
import { AreaDetailsAreaTypeEnum } from 'cloudsort-client';
import configurationUtils from '../../../utils/configurationUtils';

const metric = ReportDatasetExpectedInfluxInfluxTypeEnum.COUNT;
const formatAMPM = (date: Date) => {
  let hours = date.getHours();
  let minutes: number | string = date.getMinutes();
  minutes === 0 ? (minutes = ':00') : (minutes = ':' + minutes);
  let ampm = hours >= 12 ? ' PM' : ' AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  return hours + minutes + ampm;
};

interface Props {
  areaId: number;
  areaType?: AreaDetailsAreaTypeEnum;
}

interface configEl {
  dataKey: NestedProcessStepProcessLabels;
  color: string;
}

const TodayPackagesChart: React.FC<Props> = ({
  areaId,
  areaType,
}) => {
  const [chartData, setChartData] = useState<any[]>();
  const [config, setConfig] = useState<configEl[]>();

  const getCallsByType = () => {
    switch (areaType) {
      case AreaDetailsAreaTypeEnum.PRIMARY:
        setConfig([
          {
            dataKey: NestedProcessStepProcessLabels.PRIMARY,
            color: '#000000',
          },
        ]);
        return [
          NestedProcessStepSerializerPackageProcessStepEnum.PRIMARY,
        ];
      case AreaDetailsAreaTypeEnum.SECONDARY:
        setConfig([
          {
            dataKey: NestedProcessStepProcessLabels.SECONDARY,
            color: '#4666E6',
          },
          {
            dataKey: NestedProcessStepProcessLabels.ZONE,
            color: '#A3A3A3',
          },
          {
            dataKey: NestedProcessStepProcessLabels.CONTAINERIZED,
            color: '#03C4D9',
          },
        ]);
        return [
          NestedProcessStepSerializerPackageProcessStepEnum.SECONDARY,
          NestedProcessStepSerializerPackageProcessStepEnum.ZONE,
          NestedProcessStepSerializerPackageProcessStepEnum.CONTAINERIZED,
        ];
      case AreaDetailsAreaTypeEnum.STAGING:
        setConfig([
          {
            dataKey: NestedProcessStepProcessLabels.STAGED,
            color: '#F1E0D1',
          },
        ]);
        return [
          NestedProcessStepSerializerPackageProcessStepEnum.STAGED,
        ];
      default:
        return [];
    }
  };

  const fetchStats = async () => {
    const response = await ReportsService.getProcessstepsList(
      getCallsByType(),
      metric,
      'PACKAGES',
      areaId,
    );
    if (response.data) {
      const data = response.data.data?.data;
      const columns = response.data.data?.columns;
      if (data) {
        const chartData = data.map((dataEl: any) => {
          let formatedDataEl: any = {};
          dataEl.forEach((de: any, index: number) => {
            if (!index) {
              formatedDataEl.name = formatAMPM(
                new Date(de as unknown as string),
              );
            } else {
              const keyLabel = Object.entries(
                NestedProcessStepSerializerPackageProcessStepEnum,
              ).find((enumVal) => enumVal.includes(columns![index]));
              if (
                areaType === AreaDetailsAreaTypeEnum.SECONDARY &&
                keyLabel![0] ===
                  NestedProcessStepSerializerPackageProcessStepEnum.ARRIVED
              ) {
                formatedDataEl[
                  NestedProcessStepProcessLabels.SECONDARY
                ] = de as unknown as number;
              } else {
                formatedDataEl[
                  NestedProcessStepProcessLabels[
                    keyLabel![0] as keyof typeof NestedProcessStepProcessLabels
                  ]
                ] = de as unknown as number;
              }
            }
          });
          return formatedDataEl;
        });
        setChartData(chartData);
      }
    }
  };

  useEffect(() => {
    fetchStats();

    if (dateUtils.isSystemDateToday() && FETCH_INTERVAL_ACTIVE) {
      const interval = setInterval(() => {
        fetchStats();
      }, FETCH_INTERVAL_TIME);

      return () => {
        clearInterval(interval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      {!!chartData ? (
        <StackedAreaChart
          height={250}
          yLabel={`Number of ${configurationUtils.getPageTitle(
            false,
            'PACKAGE',
          )}`}
          config={config || []}
          chartData={chartData}
          dataTestId='today-packages-chart'
        />
      ) : (
        <div style={{ position: 'relative' }}>
          <LoaderRing
            style={{
              position: 'absolute',
              top: 130,
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          />
        </div>
      )}
    </Fragment>
  );
};

export default TodayPackagesChart;
