import {
  InboundLoad,
  InboundLoadLoadStatusEnum,
  InboundloadsApi,
} from 'cloudsort-client';
import axios from '../utils/axios';
import dateUtils from './utils/date';
import EphemeralStateService from './EphemeralState.service';

class InboundLoads {
  private api: InboundloadsApi;

  constructor() {
    this.api = new InboundloadsApi(
      undefined,
      undefined,
      axios as any,
    );
    this.getAll = this.getAll.bind(this);
    this.getById = this.getById.bind(this);
    this.create = this.create.bind(this);
    this.deleteById = this.deleteById.bind(this);
  }

  public async getAll(params: {
    page?: number;
    pageSize?: number;
    inboundLoadStatus?: InboundLoadLoadStatusEnum;
    dockdoor?: number;
    manifest?: number;
    station?: number;
    owner?: Array<number>;
    sortBy?:
      | 'id'
      | 'load_time'
      | 'route_name'
      | 'load_status'
      | '-id'
      | '-load_time'
      | '-route_name'
      | '-load_status';
    hidePending?: boolean;
    fromDate?: string;
    toDate?: string;
    loadName?: string;
    options?: any;
  }) {
    return this.api.inboundloadsList({
      ...params,
      fromDate: params.fromDate || dateUtils.fromDate(),
      toDate: params.toDate || dateUtils.toDate(),
      station: EphemeralStateService.getMyStationId(),
    });
  }

  public async create(data: InboundLoad) {
    data.target_station = EphemeralStateService.getMyStationId();
    return this.api.inboundloadsCreate({ data: data });
  }

  public async getById(id: number) {
    return this.api.inboundloadsRead({ id });
  }

  public async deleteById(id: number) {
    return this.api.inboundloadsDelete({ id });
  }

  public async update(id: number, data: InboundLoad) {
    return this.api.inboundloadsPartialUpdate({ id, data });
  }
}

export default new InboundLoads();
