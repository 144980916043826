export interface SectionTitleSeparatorProps {
  classes: { [key: string]: string };
  icon?: DialogIcons;
  customIcon?: JSX.Element;
  title: string;
  subtitle?: string;
}

export enum DialogIcons {
  ADD = 'ADD',
  PRINT = 'PRINT',
  DOWNLOAD = 'DOWNLOAD',
  UPLOAD = 'UPLOAD',
  EDIT = 'EDIT',
  CHECK = 'CHECK',
  DELETE = 'DELETE',
  RESEND = 'RESEND',
  LOCK = 'LOCK',
}
