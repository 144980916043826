import React, { useEffect, useMemo, useState } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { AuthRoutes } from '../../interfaces/routes';
import { Typography, AlertBanner, CSButton } from '../primitives';
import Paper from '@material-ui/core/Paper';
import Layout from '../layout/Layout';
import { humanReadableNull } from '../DetailsPagesFunctions';
import {
  InboundLoad,
  StaffPermissionPermissionsEnum,
} from 'cloudsort-client';
import EventsPaginatedTable from '../eventsPaginatedTable/EventsPaginatedTable';
import ErrorHandler from '../../utils/ErrorHandler';
import { AxiosError } from 'axios';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import { getDetailsPageStylesWithTheme } from '../commonStyles/detailsPage.style';
import { Box, Grid, Theme } from '@material-ui/core';
import colors from '../../utils/colors';
import configurationUtils from '../../utils/configurationUtils';
import PermissionsService from '../../services/Permissions.service';
import EventsService from '../../services/Events.service';
import SingleDetail from '../primitives/singleDetail/SingleDetail';
import { Helmet } from 'react-helmet';
import InboundLoadsService from '../../services/InboundLoads.service';
import format from 'date-fns/format';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ConfirmationDialog from '../confirmationDialog/ConfirmationDialog';
import browserHistory from '../../utils/browserHistory';
import InboundLoadDialog from './InboundLoadDialog';
import InboundLoadCheckinDialog from './InboundLoadCheckinDialog';
import { formatStatusEnum } from './utils/misc';
import { common } from '../../utils/strings';
import CSSectionTitleSeparator from '../primitives/csSectionTitleSeparator/CSSectionTitleSeparator';
import { CSSingleDetailMultiColumnContainer } from '../primitives/singleDetail/singleDetailMultiColumnContainer';
import { CSTimeContainer } from '../primitives/csTimeContainer';
import { CSSingleDetailMonoColumnContainer } from '../primitives/singleDetail/singleDetailMonoColumnContainer';
import CSBreadcrumbs from '../primitives/CSBreadcrumbs/CSBreadcrumbs';
import EphemeralStateService from '../../services/EphemeralState.service';
import { CSLink } from '../primitives/csLink';
import PaginatedTable, {
  filterObj,
} from '../paginatedTable/PaginatedTable';
import {
  GET_COLUMNS_PACKAGES,
  fetch as fetchPackages,
} from '../packages/Packages';
import ContainersService from '../../services/Containers.service';
import { formatContainer } from '../containers/Containers';
import { Column } from '../../interfaces/components';
import { CSContainerElement } from '../primitives/csTimeContainer/CSTimeContainerType';

interface Props {
  classes: { [key: string]: string };
  match: any;
}

const InboundLoadDetails: React.FC<Props> = ({ classes, match }) => {
  const loadId = match.params.id;
  const [inboundLoadData, setInboundLoadData] =
    useState<InboundLoad>();
  const [error, setError] = useState<string>();
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] =
    useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showCheckinModal, setShowCheckinModal] =
    useState<boolean>(false);

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e as AxiosError));
  };

  const fetchInboundLoadDetails = async () => {
    return InboundLoadsService.getById(loadId);
  };

  const fetchLoadDetailsData = async () => {
    try {
      setShowProgress(true);
      const { data } = await fetchInboundLoadDetails();
      setInboundLoadData(data);
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  useEffect(() => {
    PermissionsService.redirectIfNoPermission(
      StaffPermissionPermissionsEnum.INBOUNDLOAD_READ,
    );

    fetchLoadDetailsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const inboundLoadLabels = {
    singular: configurationUtils.getPageTitle(true, 'INBOUND_LOAD'),
    plural: configurationUtils.getPageTitle(false, 'INBOUND_LOAD'),
  };

  const deleteInboundLoad = async () => {
    try {
      setShowProgress(true);
      await InboundLoadsService.deleteById(
        inboundLoadData?.id || loadId,
      );
      browserHistory.push(AuthRoutes.INBOUND_LOAD);
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
      setShowDeleteModal(false);
    }
  };

  const fetchEvents = async (
    pageIndex: number,
    rowsPerPage: number,
  ) => {
    return EventsService.getAll({
      page: pageIndex,
      inboundLoad: loadId,
      pageSize: rowsPerPage,
    });
  };

  const pageTitle = `${inboundLoadLabels.singular} ${
    inboundLoadData?.name || inboundLoadData?.id || ''
  }`;

  const packageLabels = useMemo(() => {
    return {
      singular: configurationUtils.getPageTitle(true, 'PACKAGE'),
      plural: configurationUtils.getPageTitle(false, 'PACKAGE'),
    };
  }, []);

  const containerLabels = useMemo(() => {
    return {
      singular: configurationUtils.getPageTitle(true, 'CONTAINER'),
      plural: configurationUtils.getPageTitle(false, 'CONTAINER'),
    };
  }, []);

  const headerTimes = useMemo(() => {
    const times = [];

    const actualTime = {
      title: 'Actual Arrival Time',
      titleTestId: 'inbound-load:details:Actual Arrival Time:label',
      content: inboundLoadData?.arrival_time
        ? new Date(inboundLoadData?.arrival_time).toLocaleTimeString(
            'en-US',
            {
              hour: '2-digit',
              minute: '2-digit',
              hour12: true,
            },
          )
        : common.emptyValue,
      contentTestId: 'inbound-load:details:Actual Arrival Time:value',
      subContent: inboundLoadData?.arrival_time
        ? format(
            new Date(inboundLoadData?.arrival_time),
            'MM/dd/yyyy',
          )
        : '',
      subcontentTestId:
        'inbound-load:details:Actual Arrival date:value',
    };

    if (inboundLoadData?.load_status !== 'CHECKED_IN') {
      times.push({
        title: 'Planned Arrival Time',
        titleTestId:
          'inbound-load:details:Planned Arrival Time:label',
        content: inboundLoadData?.expected_inbound_load_time
          ? new Date(
              inboundLoadData?.expected_inbound_load_time,
            ).toLocaleTimeString('en-US', {
              hour: '2-digit',
              minute: '2-digit',
              hour12: true,
            })
          : common.emptyValue,
        contentTestId:
          'inbound-load:details:Planned Arrival Time:value',
        subContent: inboundLoadData?.expected_inbound_load_time
          ? format(
              new Date(inboundLoadData?.expected_inbound_load_time),
              'MM/dd/yyyy',
            )
          : '',
        subcontentTestId: 'inbound-load:details:time',
      });

      times.push(actualTime);
    } else {
      times.push(actualTime);
      times.push({
        title: 'Checked-in time',
        titleTestId: 'inbound-load:details:Checked-in time:label',
        content: inboundLoadData?.checked_in_time
          ? new Date(
              inboundLoadData?.checked_in_time,
            ).toLocaleTimeString('en-US', {
              hour: '2-digit',
              minute: '2-digit',
              hour12: true,
            })
          : common.emptyValue,
        contentTestId: 'inbound-load:details:Checked-in time:value',
        subContent: inboundLoadData?.checked_in_time
          ? format(
              new Date(inboundLoadData?.checked_in_time),
              'MM/dd/yyyy',
            )
          : '',
        subcontentTestId: 'inbound-load:details:time',
      });
    }

    return times;
  }, [inboundLoadData]);

  const COLUMNS_CONTAINERS: Column[] = [
    {
      id: 'id',
      label: 'ID',
      width: 50,
      align: 'left',
    },
    {
      id: 'type',
      label: 'Type',
      width: 200,
      align: 'left',
      useCustomComponent: true,
    },
    {
      id: 'display_status',
      label: 'Status',
      width: 'auto',
      align: 'left',
      useCustomComponent: true,
    },
    {
      id: 'critical_activity_time',
      label: 'Critical Time',
      align: 'center',
      width: 'left',
    },
    {
      id: 'tracking_number',
      label: 'Tracking Number',
      width: 'auto',
      align: 'center',
    },
    {
      id: 'dwell_time',
      label: 'Dwell time',
      align: 'center',
      width: 'auto',
    },
    {
      id: 'package_count',
      label: packageLabels.plural,
      width: 'auto',
      align: 'left',
    },
    {
      id: 'container_type',
      label: `Type of ${configurationUtils.getModuleLabel(
        true,
        'CONTAINER',
      )}`,
      width: 'auto',
      align: 'left',
    },
    {
      id: 'bol',
      label: 'BOL',
      align: 'center',
      width: 'left',
    },
  ];

  const fetchContainers = async (
    pageIndex: number,
    rowsPerPage: number,
  ) => {
    const res = await ContainersService.getAll({
      page: pageIndex,
      inboundLoad: loadId,
      pageSize: rowsPerPage,
      fromDate: '',
      toDate: '',
    });

    res.data.results.forEach((container: any) => {
      formatContainer(container);
    });
    return res;
  };

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort -
  ${inboundLoadLabels.singular} ${
            inboundLoadData?.name || loadId || ''
          }`}
        </title>
      </Helmet>
      <Layout navCurrent='INBOUND_LOAD'>
        {showProgress && <ProgressIndicator />}
        {error && (
          <AlertBanner
            className={classes.banner}
            severity='error'
            alertTitle={'Error'}
            alertMsg={error}
          />
        )}
        <InboundLoadDialog
          isOpen={showEditModal}
          onAfterClose={() => setShowEditModal(false)}
          updateParent={() => {
            fetchLoadDetailsData();
          }}
          type={'edit'}
          data={inboundLoadData}
        />
        <ConfirmationDialog
          dataTestIdPrefix='delete-inbound-load-confirmation'
          title={`Delete ${inboundLoadLabels.singular}`}
          msg={`Are you sure you want to delete ${
            inboundLoadLabels.singular
          } ${inboundLoadData?.name || loadId || ''}?`}
          primaryActionLabel='Confirm'
          cancelLabel='Cancel'
          onPrimaryAction={() => {
            deleteInboundLoad();
          }}
          onCancel={() => {
            setShowDeleteModal(false);
          }}
          isOpen={showDeleteModal}
        />
        <InboundLoadCheckinDialog
          isOpen={showCheckinModal}
          onAfterClose={() => setShowCheckinModal(false)}
          updateParent={() => {
            fetchLoadDetailsData();
          }}
          data={inboundLoadData!}
        />
        <Grid item xs={12}>
          <Grid item xs={12} sm={6}>
            <Box mb={5}>
              <CSBreadcrumbs
                breadcrumbs={[
                  {
                    label: inboundLoadLabels.plural,
                    link:
                      AuthRoutes.INBOUND_LOAD +
                      '?stationId=' +
                      // TODO: inboundLoadData does not include the station data
                      // Check CLOUD-3077
                      EphemeralStateService.getMyStationId(),
                  },
                  {
                    label: `${pageTitle}`,
                    selected: true,
                  },
                ]}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={12} sm={4}>
            <Typography
              className={classes.title}
              variant='h3'
              component='h2'
              style={{ marginBottom: 16 }}
            >
              {`${pageTitle}`}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8} style={{ textAlign: 'right' }}>
            {PermissionsService.hasPermission(
              StaffPermissionPermissionsEnum.INBOUNDLOAD_WRITE,
            ) && (
              <>
                <CSButton
                  data-testid='edit-load-btn'
                  variant='outlined'
                  color='secondary'
                  disabled={!inboundLoadData}
                  className={classes.titleButtons}
                  onClick={(e) => {
                    setShowEditModal(true);
                  }}
                  startIcon={<EditIcon />}
                >
                  Edit
                </CSButton>
                <CSButton
                  data-testid='delete-load-btn'
                  variant='outlined'
                  color='secondary'
                  disabled={!inboundLoadData}
                  className={classes.titleButtons}
                  onClick={(e) => {
                    setShowDeleteModal(true);
                  }}
                  startIcon={<DeleteIcon />}
                >
                  Delete
                </CSButton>
                <CSButton
                  data-testid='checkin-load-btn'
                  variant='contained'
                  color={{
                    buttonColor: 'secondary',
                    iconColor: 'primary',
                  }}
                  disabled={
                    inboundLoadData?.load_status === 'CHECKED_IN'
                  }
                  className={classes.titleButtons}
                  onClick={(e) => {
                    setShowCheckinModal(true);
                  }}
                  startIcon={<CheckCircleOutlineIcon />}
                >
                  {inboundLoadLabels.singular}{' '}
                  {inboundLoadData?.load_status === 'PLANNED'
                    ? 'Arrived'
                    : 'Check in'}
                </CSButton>
              </>
            )}
          </Grid>
          <CSSectionTitleSeparator />
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 8 }}>
          <Grid item xs={12} sm={6} md={8}>
            <CSSingleDetailMultiColumnContainer
              elements={[
                <SingleDetail
                  inline={true}
                  label='Status'
                  value={
                    inboundLoadData?.load_status
                      ? formatStatusEnum(inboundLoadData?.load_status)
                      : common.emptyValue
                  }
                />,
                <SingleDetail
                  inline={true}
                  label='Unload Door'
                  value={
                    inboundLoadData?.dockdoor_name ||
                    common.emptyValue
                  }
                />,
                <SingleDetail
                  inline={true}
                  label='Trailer ID'
                  value={
                    inboundLoadData?.trailer_id || common.emptyValue
                  }
                />,
                <SingleDetail
                  label='Owner'
                  value={humanReadableNull(
                    inboundLoadData?.owner_full_name,
                  )}
                />,

                <SingleDetail
                  label='Origin'
                  value={humanReadableNull(
                    inboundLoadData?.origin_station,
                  )}
                />,
                <SingleDetail
                  label='Load ID'
                  value={humanReadableNull(inboundLoadData?.name)}
                />,
                <SingleDetail
                  label={`${configurationUtils.getPageTitle(
                    false,
                    'MANIFEST',
                  )}`}
                  value={
                    inboundLoadData?.manifest ? (
                      <CSLink
                        to={`${AuthRoutes.MANIFEST}/${inboundLoadData?.manifest}`}
                      >
                        {humanReadableNull(
                          inboundLoadData?.manifest_name ||
                            inboundLoadData?.manifest,
                        )}
                      </CSLink>
                    ) : (
                      'N/A'
                    )
                  }
                />,
                <SingleDetail
                  label='BOL'
                  value={humanReadableNull(inboundLoadData?.bol)}
                />,
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CSTimeContainer
              elements={
                headerTimes as [
                  CSContainerElement,
                  CSContainerElement,
                ]
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: 8 }}>
          <Grid item xs={12}>
            <Paper
              className={classes.paper}
              style={{ padding: 16, height: '100%' }}
              data-testid='inbound-load-details-card2'
            >
              <CSSingleDetailMonoColumnContainer
                header={`${inboundLoadLabels.singular} Details`}
                elements={[
                  <SingleDetail
                    labelColumns={3}
                    valueColumns={9}
                    label={`Planned ${configurationUtils.getPageTitle(
                      false,
                      'CONTAINER',
                    )}`}
                    value={humanReadableNull(
                      inboundLoadData?.expected_containers_at_arrival,
                    )}
                  />,
                  <SingleDetail
                    labelColumns={3}
                    valueColumns={9}
                    label={`Planned ${configurationUtils.getPageTitle(
                      false,
                      'PACKAGE',
                    )}`}
                    value={humanReadableNull(
                      inboundLoadData?.expected_packages_at_arrival,
                    )}
                  />,
                ]}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} className={classes.marginBottom10}>
            <EventsPaginatedTable
              dataTestIdPrefix={'load-details-events'}
              fetch={fetchEvents}
              sortableBy={['timestamp']}
            />
          </Grid>
          {configurationUtils.isModuleActive('PACKAGE') &&
            PermissionsService.hasPermission(
              StaffPermissionPermissionsEnum.PACKAGE_READ,
            ) && (
              <Grid item xs={12} className={classes.marginBottom10}>
                <PaginatedTable
                  disableUpdateQueryStringUrl
                  title={packageLabels.plural}
                  columns={GET_COLUMNS_PACKAGES()}
                  dataTestIdPrefix={'load-details-packages'}
                  fetch={(
                    pageIndex: number,
                    rowsPerPage: number,
                    filterOptions?: filterObj[],
                    filterByString?: string,
                    sortedBy?: string,
                  ) => {
                    return fetchPackages(
                      pageIndex,
                      rowsPerPage,
                      {}, // no filters
                      sortedBy,
                      {
                        inboundLoad: [loadId],
                        fromDate: '',
                        toDate: '',
                        inboundLoadDirect: true,
                      } as any,
                    );
                  }}
                  rowsLoadDetailPages={true}
                  detailsPageBasePath={AuthRoutes.PACKAGE}
                />
              </Grid>
            )}
          {configurationUtils.isModuleActive('CONTAINER') &&
            PermissionsService.hasPermission(
              StaffPermissionPermissionsEnum.CONTAINER_READ,
            ) && (
              <Grid item xs={12}>
                <PaginatedTable
                  disableUpdateQueryStringUrl
                  title={containerLabels.plural}
                  columns={COLUMNS_CONTAINERS}
                  dataTestIdPrefix={'load-details-containers'}
                  fetch={fetchContainers}
                  rowsLoadDetailPages={true}
                  detailsPageBasePath={AuthRoutes.CONTAINER}
                />
              </Grid>
            )}
        </Grid>
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...getDetailsPageStylesWithTheme(theme),
    arrivedLoadMarker: {
      fontSize: '3em',
      position: 'absolute',
      margin: '-28px 0 0 -0.5em',
      color: colors.darkGold,
    },
    titleButtons: {
      margin: '2px 2px 2px 8px',
    },
    marginBottom10: {
      marginBottom: 10,
    },
  })),
)(InboundLoadDetails);
