import React, { Ref } from 'react';

const EditIconWithTag = React.forwardRef(
  (props: any, ref: Ref<HTMLOrSVGElement>) => {
    return (
      <svg
        width='31'
        height='25'
        viewBox='0 0 31 25'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
        ref={ref}
      >
        <path
          d='M19 2.58503C18.8695 2.27351 18.6783 1.99046 18.4373 1.75204C18.1963 1.51361 17.9101 1.32448 17.5952 1.19545C17.2802 1.06641 16.9427 1 16.6018 1C16.2609 1 15.9234 1.06641 15.6084 1.19545C15.2935 1.32448 15.0073 1.51361 14.7663 1.75204L2.37663 14.0072L1 19L6.04764 17.6383L8.57146 15.1419L9.83337 13.8937'
          stroke='#A3A3A3'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M12.5885 13.816C12.3686 13.6084 12.2545 13.3124 12.278 13.0109L12.6639 8.07519C12.7005 7.60596 13.0595 7.22582 13.5258 7.16228L18.4313 6.49387C18.7309 6.45304 19.0329 6.55 19.2528 6.75761L28.6142 15.5964C29.0157 15.9755 29.0339 16.6084 28.6547 17.01L23.3634 22.6142C22.9843 23.0157 22.3514 23.0339 21.9498 22.6547L12.5885 13.816Z'
          stroke='#A3A3A3'
          strokeWidth='2'
        />
        <circle
          cx='16.4489'
          cy='10.8535'
          r='1'
          transform='rotate(7.49716 16.4489 10.8535)'
          fill='#A3A3A3'
        />
      </svg>
    );
  },
);
export default EditIconWithTag;
