import { Geometry } from 'wkx';

const getGeoJson = (data?: string) => {
  try {
    if (data) {
      const wkbBuffer = new Buffer(data, 'hex');
      return Geometry.parse(wkbBuffer).toGeoJSON();
    }
    return null;
  } catch (e) {
    console.error(e);
    return null;
  }
};

const getLatLong = (geoShape?: string, padding?: boolean) => {
  const geoShapeJson = getGeoJson(geoShape);
  if (!geoShapeJson || !window.google) {
    return;
  }

  const bounds = new window.google.maps.LatLngBounds();
  (geoShapeJson as any).coordinates[0].forEach(
    (coordinate: number[]) => {
      const latLng = new window.google.maps.LatLng(
        // lng - lat, geoJson specs
        padding ? coordinate[1] * 1.0005 : coordinate[1],
        coordinate[0],
      );
      bounds.extend(latLng);
    },
  );

  return bounds.getCenter();
};

const getEWKBString = (data: any) => {
  const geoJsonObject = Geometry.parseGeoJSON(data);
  return toHexString(geoJsonObject.toEwkb());
};

const toHexString = (byteArray: Buffer) => {
  return Array.prototype.map
    .call(byteArray, (byte) => {
      return ('0' + (byte & 0xff).toString(16)).slice(-2);
    })
    .join('')
    .toUpperCase();
};

const getCoordinates = (bounds: any) => {
  /**
   * (SW-lat, NE-lng)-------(NE-lat, NE-lng)
   * |                            |
   * (SW-lat, SW-lng)-------(NE-lat, SW-lng)
   */
  return [
    // lng - lat, geoJson specs
    [bounds.getSouthWest().lng(), bounds.getSouthWest().lat()], // SW
    [bounds.getSouthWest().lng(), bounds.getNorthEast().lat()],
    [bounds.getNorthEast().lng(), bounds.getNorthEast().lat()], // NE
    [bounds.getNorthEast().lng(), bounds.getSouthWest().lat()],
    [bounds.getSouthWest().lng(), bounds.getSouthWest().lat()], // SW again - close polygon
  ];
};

const decodePolyline = (encoded: string) => {
  const points = [];
  let index = 0,
    len = encoded.length;
  let lat = 0,
    lng = 0;
  while (index < len) {
    let b,
      shift = 0,
      result = 0;
    do {
      b = encoded.charAt(index++).charCodeAt(0) - 63; //finds ascii
      result |= (b & 0x1f) << shift;
      shift += 5;
    } while (b >= 0x20);
    let dlat = (result & 1) !== 0 ? ~(result >> 1) : result >> 1;
    lat += dlat;
    shift = 0;
    result = 0;
    do {
      b = encoded.charAt(index++).charCodeAt(0) - 63;
      result |= (b & 0x1f) << shift;
      shift += 5;
    } while (b >= 0x20);
    let dlng = (result & 1) !== 0 ? ~(result >> 1) : result >> 1;
    lng += dlng;

    points.push({ lat: lat / 1e5, lng: lng / 1e5 });
  }
  return points;
};

const durationString = (sec: number) => {
  let days: number = Math.floor(sec / (3600 * 24));
  let hours: number = Math.floor((sec - days * 86400) / 3600);
  let minutes: number = Math.floor((sec - hours * 3600) / 60);

  if (days) {
    return `${days} day${days > 1 ? 's' : ''} ${hours} hour${
      hours > 1 ? 's' : ''
    }`;
  }
  if (hours) {
    return `${hours} hour${hours > 1 ? 's' : ''} ${minutes} minute${
      minutes > 1 ? 's' : ''
    }`;
  }
  return `${minutes} minute${minutes > 1 ? 's' : ''}`;
};

const routesColors = [
  '#DC143C',
  '#FF8C00',
  '#556B2F',
  '#008B8B',
  '#00BFFF',
  '#1E90FF',
  '#DA70D6',
  '#9400D3',
  '#A9A9A9',
  '#D2B48C',
  '#DAA520',
  '#A52A2A',
];

export default {
  getGeoJson,
  getLatLong,
  getEWKBString,
  toHexString,
  getCoordinates,
  decodePolyline,
  routesColors,
  durationString,
};
