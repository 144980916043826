import React, { useMemo, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import { Transition } from '../confirmationDialog/ConfirmationDialog';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import { AlertBanner, CSButton } from '../primitives';
import ErrorHandler from '../../utils/ErrorHandler';
import { AxiosError, AxiosResponse } from 'axios';
import dateUtils from '../../services/utils/date';
import styles from './ExportCSVDialog.styles';
import PackagesService from '../../services/Packages.service';
import { SelectedFilters } from '../filtersDrawer/FiltersDrawer';
import {
  NestedProcessStepSerializerPackageProcessStepEnum,
  TaskResponse,
} from 'cloudsort-client';
import ManifestsService from '../../services/Manifests.service';
import OutboundLoadsService from '../../services/OutboundLoads.service';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import useControlled from '../../utils/useControlled';
import { DialogTitle, Grid } from '@material-ui/core';
import CSRadio from '../primitives/csRadio/CSRadio';
import configurationUtils from '../../utils/configurationUtils';
import CSDialogAlert from '../primitives/csDialogAlert/CSDialogAlert';
import CSDatepicker from '../primitives/csDatepicker/CSDatepicker';
import { CSMonoGridContainer } from '../primitives/csMonoGridContainer';
import CSDialogTitleWithIcon from '../primitives/csDialogTitleWithIcon/CSDialogTitleWithIcon';
import { DialogIcons } from '../primitives/csDialogTitleWithIcon/CSDialogTitleWithIconTypes';
import usePolling from '../../hooks/usePolling';
import moment from 'moment';
import HeldPackagesService, {
  HeldPackageScopeValues,
  HeldPackageSourceValues,
} from '../../services/HeldPackages.service';

interface Props {
  classes: { [key: string]: string };
  onAfterClose: () => void;
  isOpen: boolean;
  areaId?: number;
  downloadName?: string;
  type: 'MANIFESTS' | 'PACKAGES' | 'OUTBOUND_LOADS' | 'HELD_PACKAGES';
  packageFilters?: SelectedFilters;
  exportFilters?: {
    manifestType?: 'inbound' | 'outbound';
    owner?: number[];
  };
}

enum RangeSelection {
  singleDay = 'Single Day',
  multipleDays = 'Multiple Days',
}

const ExportCSVDialog: React.FC<Props> = ({
  isOpen,
  classes,
  type,
  packageFilters,
  onAfterClose,
  exportFilters = {},
  downloadName,
}) => {
  const [open, setOpen] = useControlled<boolean>({
    controlled: isOpen,
    default: false,
    name: 'ExportCSVDialog',
  });
  const [error, setError] = useState<string>();
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [fromDate, setFromDate] = useState<Date>(
    new Date(dateUtils.fromDate()),
  );
  const [toDate, setToDate] = useState<Date>(
    new Date(dateUtils.toDate()),
  );
  const [selectedRange, setSelectedRange] = useState<RangeSelection>(
    RangeSelection.singleDay,
  );

  const exportTypeLabel = useMemo(() => {
    switch (type) {
      case 'MANIFESTS':
        return configurationUtils
          .getPageTitle(false, 'MANIFEST')
          .toLowerCase();
      case 'OUTBOUND_LOADS':
        return `the dispatched ${configurationUtils
          .getPageTitle(false, 'OUTBOUND_LOAD')
          .toLowerCase()}`;
      case 'PACKAGES':
        return configurationUtils
          .getPageTitle(false, 'PACKAGE')
          .toLowerCase();
      case 'HELD_PACKAGES':
        return (
          'Held ' +
          configurationUtils
            .getPageTitle(false, 'PACKAGE')
            .toLowerCase()
        );
      default:
        return '';
    }
  }, [type]);

  const handleClose = () => {
    setOpen(false);
    onAfterClose();
  };

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e as AxiosError));
  };

  const { startPolling, checkTaskId } = usePolling();

  const onExportButtonClick = async () => {
    const startDate = fromDate.toISOString();
    const endDate =
      selectedRange === RangeSelection.singleDay
        ? moment(startDate).add(1, 'day').toISOString()
        : toDate.toISOString();

    setShowProgress(true);
    try {
      let res: AxiosResponse<TaskResponse> | undefined = undefined;
      if (type === 'MANIFESTS') {
        res = await ManifestsService.getCSVExport({
          ...exportFilters,
          fromDate: startDate,
          toDate: endDate,
        });
      } else if (type === 'OUTBOUND_LOADS') {
        res = await OutboundLoadsService.getCSVExport({
          fromDate: startDate,
          toDate: endDate,
        });
      } else if (type === 'PACKAGES' && packageFilters) {
        const isManifested =
          packageFilters.manifested &&
          packageFilters.manifested.values.length
            ? packageFilters.manifested.values[0].id === 'yes'
              ? true
              : packageFilters.manifested.values[0].id === 'no'
              ? false
              : undefined
            : undefined;

        const isScanned =
          packageFilters.scanned &&
          packageFilters.scanned.values.length
            ? packageFilters.scanned.values[0].id === 'yes'
              ? true
              : packageFilters.scanned.values[0].id === 'no'
              ? false
              : undefined
            : undefined;

        const isArrived =
          packageFilters.arrived &&
          packageFilters.arrived.values.length
            ? packageFilters.arrived.values[0].id === 'yes'
              ? true
              : packageFilters.arrived.values[0].id === 'no'
              ? false
              : undefined
            : undefined;

        const inSystem =
          packageFilters.in_system &&
          packageFilters.in_system.values.length
            ? packageFilters.in_system.values[0].id === 'yes'
              ? true
              : packageFilters.in_system.values[0].id === 'no'
              ? false
              : undefined
            : undefined;

        const inHold =
          packageFilters.in_hold &&
          packageFilters.in_hold.values.length
            ? packageFilters.in_hold.values[0].id === 'yes'
              ? true
              : packageFilters.in_hold.values[0].id === 'no'
              ? false
              : undefined
            : undefined;

        const hidePending =
          packageFilters.hidePending &&
          packageFilters.hidePending.values.length
            ? packageFilters.hidePending.values[0].id === 'yes'
              ? true
              : packageFilters.hidePending.values[0].id === 'no'
              ? false
              : undefined
            : undefined;

        const firstScannedInWindow =
          packageFilters.packageScannedToday &&
          packageFilters.packageScannedToday.values.length
            ? packageFilters.packageScannedToday.values[0].id ===
              'yes'
              ? true
              : packageFilters.packageScannedToday.values[0].id ===
                'no'
              ? false
              : undefined
            : undefined;

        res = await PackagesService.getCSVExport({
          //all package filters
          owner:
            packageFilters.owner && packageFilters.owner.values.length
              ? packageFilters.owner.values.map(
                  (item: any) => item.id,
                )
              : undefined,
          carrier:
            packageFilters.carrier &&
            packageFilters.carrier.values.length
              ? packageFilters.carrier.values.map(
                  (item: any) => item.id,
                )
              : undefined,
          route:
            packageFilters.route && packageFilters.route.values.length
              ? packageFilters.route.values.map(
                  (item: any) => item.id,
                )
              : undefined,
          destination:
            packageFilters.stop && packageFilters.stop.values.length
              ? packageFilters.stop.values.map((item: any) => item.id)
              : undefined,
          area:
            packageFilters.area && packageFilters.area.values.length
              ? (packageFilters.area.values[0].id as number)
              : undefined,
          processStep:
            packageFilters.processStep &&
            packageFilters.processStep.values.length
              ? (packageFilters.processStep.values.map(
                  (item: any) => item.id,
                ) as [
                  NestedProcessStepSerializerPackageProcessStepEnum,
                ])
              : undefined,
          isManifested,
          isArrived,
          inSystem,
          inHold,
          hidePending,
          isScanned,
          firstScannedInWindow,
          defects:
            packageFilters.packageDefect &&
            packageFilters.packageDefect.values.length
              ? packageFilters.packageDefect.values.map(
                  (item: any) => item.id,
                )
              : undefined,
          fromDate: startDate,
          toDate: endDate,
        });
      } else if (type === 'HELD_PACKAGES' && packageFilters) {
        res = await HeldPackagesService.getCSVExport({
          fromDate: startDate,
          toDate: endDate,
          carrier: packageFilters?.carrier?.values?.length
            ? (packageFilters?.carrier?.values[0].id as number)
            : undefined,
          owner: packageFilters?.owner?.values?.length
            ? (packageFilters?.owner?.values[0].id as number)
            : undefined,
          reason: packageFilters?.holdingReasons?.values?.length
            ? (packageFilters?.holdingReasons?.values[0].id as string)
            : undefined,
          inboundLoad: packageFilters?.inboundLoad?.values?.length
            ? (packageFilters?.inboundLoad?.values[0].id as number)
            : undefined,
          scope: packageFilters?.scope?.values?.length
            ? (packageFilters?.scope?.values[0]
                .id as HeldPackageScopeValues)
            : undefined,
          source: packageFilters?.source?.values?.length
            ? (packageFilters?.source?.values[0]
                .id as HeldPackageSourceValues)
            : undefined,
        });
      }

      if (res) {
        const fileNameStr = downloadName
          ? `${downloadName}.csv`
          : `${type.toLowerCase()}-export.csv`;

        startPolling(() => {
          checkTaskId(
            res?.data.task_id,
            fileNameStr,
            setError,
            setShowProgress,
          );
        });
      }

      handleClose();
    } catch (e) {
      setShowProgress(false);
      handleError(e as AxiosError);
    }
  };

  return (
    <>
      {showProgress && <ProgressIndicator />}
      <Dialog
        open={Boolean(open)}
        TransitionComponent={Transition}
        keepMounted
        classes={{
          paper: classes.dialog,
        }}
        maxWidth='xl'
        onClose={handleClose}
      >
        <DialogTitle>
          <CSDialogTitleWithIcon
            title={'Batch CSV Export'}
            icon={DialogIcons.DOWNLOAD}
          />
        </DialogTitle>
        <DialogContent>
          <CSMonoGridContainer>
            {Boolean(error) ? (
              <AlertBanner
                className={classes.banner}
                severity='error'
                alertTitle={'Error'}
                alertMsg={error}
              />
            ) : (
              <></>
            )}
            <RadioGroup
              row
              value={selectedRange}
              onChange={(event) => {
                setSelectedRange(
                  event.target.value as RangeSelection,
                );
              }}
            >
              <FormControlLabel
                value={RangeSelection.singleDay}
                control={<CSRadio />}
                label={RangeSelection.singleDay}
              />
              <FormControlLabel
                className={classes.multipleDaysRadioButton}
                value={RangeSelection.multipleDays}
                control={<CSRadio />}
                label={RangeSelection.multipleDays}
              />
            </RadioGroup>
            <Grid container spacing={1}>
              <Grid
                item
                xs={12}
                sm={
                  selectedRange === RangeSelection.multipleDays
                    ? 6
                    : 12
                }
              >
                <CSDatepicker
                  selectionType='date'
                  className={classes.datePicker}
                  disableToolbar
                  format='MM/dd/yyyy'
                  margin='normal'
                  label='Start date'
                  initialValue={fromDate}
                  maxDate={
                    selectedRange === RangeSelection.multipleDays
                      ? toDate
                      : new Date()
                  }
                  onAcceptSelection={(event) => {
                    setFromDate(event as Date);

                    if (moment(toDate).diff(event, 'days') > 7) {
                      setToDate(
                        moment(event).add(7, 'days').toDate(),
                      );
                    }
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change from date',
                  }}
                />
              </Grid>
              {selectedRange === RangeSelection.singleDay && (
                <Grid item xs={12}>
                  <CSDialogAlert
                    alertMessage={`Exporting Today’s CSV: The exported file will contain only the ${exportTypeLabel} in the system until the current time.`}
                  />
                </Grid>
              )}
              {selectedRange === RangeSelection.multipleDays && (
                <Grid item xs={12} sm={6}>
                  <CSDatepicker
                    selectionType='date'
                    className={classes.datePicker}
                    disableToolbar
                    format='MM/dd/yyyy'
                    margin='normal'
                    label='End date'
                    initialValue={toDate}
                    minDate={fromDate}
                    maxDate={moment(fromDate).add(7, 'days')}
                    onAcceptSelection={(e) => {
                      setToDate(e as Date);
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change to date',
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </CSMonoGridContainer>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <CSButton
            variant='outlined'
            color='secondary'
            onClick={handleClose}
          >
            Cancel
          </CSButton>
          <CSButton
            variant='contained'
            color='secondary'
            onClick={onExportButtonClick}
          >
            Export
          </CSButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withStyles(styles)(ExportCSVDialog);
