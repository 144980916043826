// original source: https://devtrium.com/posts/how-keyboard-shortcut
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'react';

export type AvailableModifiers =
  | 'ctrlKey'
  | 'shiftKey'
  | 'altKey'
  | 'metaKey';
export type keyPressCallback = (event: KeyboardEvent) => void;
export const areKeysPressed = (
  keys: string[],
  event: KeyboardEvent,
) => keys.some((key) => event.key === key);
export const useKeyPress = (
  callback: keyPressCallback,
  keys: string[],
  modifier?: AvailableModifiers,
  node = null,
) => {
  // implement the callback ref pattern
  const callbackRef = useRef(callback);
  useLayoutEffect(() => {
    callbackRef.current = callback;
  });

  // handle what happens on key press
  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      // check if one of the key is part of the ones we want
      if (!modifier && areKeysPressed(keys, event)) {
        callbackRef.current(event);
      } else if (
        modifier &&
        areKeysPressed(keys, event) &&
        event[modifier]
      ) {
        callbackRef.current(event);
      }
    },
    [keys, modifier],
  );

  useEffect(() => {
    // target is either the provided node or the document
    const targetNode = node ?? document;
    // attach the event listener
    targetNode &&
      targetNode.addEventListener('keydown', handleKeyPress);

    // remove the event listener
    return () =>
      targetNode &&
      targetNode.removeEventListener('keydown', handleKeyPress);
  }, [handleKeyPress, node]);
};
