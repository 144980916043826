import { createStyles, Theme, withStyles } from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs/Breadcrumbs';
import * as React from 'react';
import { Link } from 'react-router-dom';
import Typography from '../typography/Typography';
import {
  CSBreadcrumbsType,
  CSBreadcrumbItem,
  CSBreadcrumbSelectedItem,
} from './CSBreadcrumbs.types';

const CSBreadcrumbs: React.FC<CSBreadcrumbsType> = ({
  classes,
  breadcrumbs,
}) => {
  const getRegularBreadcrumbItem = (breadcrumb: CSBreadcrumbItem) => {
    return (
      <Typography
        key={`${breadcrumb.label}-${breadcrumb.link}`}
        title={`Go to ${breadcrumb.label}`}
        className={classes.breadcrumbLinkItem}
        component={(props) => {
          return (
            <Link to={breadcrumb.link} {...props}>
              {props.children}
            </Link>
          );
        }}
      >
        {breadcrumb.label}
      </Typography>
    );
  };

  const getSelectedBreadcrumbItem = (
    breadcrumb: CSBreadcrumbSelectedItem,
  ) => {
    return (
      <Typography
        key={`${breadcrumb.label}`}
        variant='h6'
        component='p'
      >
        {breadcrumb.label}
      </Typography>
    );
  };

  return (
    <Breadcrumbs>
      {breadcrumbs.map((breadcrumb, index, array) => {
        return breadcrumb.selected
          ? getSelectedBreadcrumbItem(breadcrumb)
          : getRegularBreadcrumbItem(breadcrumb);
      })}
    </Breadcrumbs>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    breadcrumbLinkItem: {
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  })),
)(CSBreadcrumbs);
