import * as React from 'react';
import styles from './LoaderRing.styles';
import { withStyles } from '@material-ui/core';

interface Props {
  classes: { [key: string]: string };
  style?: any;
  variant?: 'regular' | 'small';
}

const Typography: React.FC<Props> = ({ classes, style, variant }) => {
  return (
    <div
      className={
        classes.ldrRing + (variant !== 'regular' ? ` ${variant}` : '')
      }
      style={style}
    >
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default withStyles(styles)(Typography);
