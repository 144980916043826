import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Layout from '../layout/Layout';
import { AuthRoutes, ModulesKeys } from '../../interfaces/routes';
import PaginatedTable, {
  filterObj,
} from '../paginatedTable/PaginatedTable';
import configurationUtils from '../../utils/configurationUtils';
import {
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Theme,
} from '@material-ui/core';
import Typography from '../primitives/typography/Typography';
import { Transition } from '../confirmationDialog/ConfirmationDialog';
import { AlertBanner, CSButton, CSTextField } from '../primitives';
import ErrorHandler from '../../utils/ErrorHandler';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import sectionPageBaseStyle from '../commonStyles/sectionPageBase.style';
import selectStyles from '../select/select.styles';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import RoutesService from '../../services/Routes.service';
import PermissionsService from '../../services/Permissions.service';
import EphemeralStateService from '../../services/EphemeralState.service';
import DockDoorService from '../../services/DockDoors.service';
import { Column, TypeAheadItem } from '../../interfaces/components';
import {
  DockDoorDetails,
  StaffPermissionPermissionsEnum,
} from 'cloudsort-client';
import { AxiosError } from 'axios';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import debounce from 'lodash/debounce';
import { noOptionsMessage } from '../asyncSelect/utils';
import CSSectionTitleSeparator from '../primitives/csSectionTitleSeparator/CSSectionTitleSeparator';
import CSDialogTitleWithIcon from '../primitives/csDialogTitleWithIcon/CSDialogTitleWithIcon';
import { DialogIcons } from '../primitives/csDialogTitleWithIcon/CSDialogTitleWithIconTypes';
import CSAsyncSelect from '../primitives/csAsyncSelect/CSAsyncSelect';
import { CSMonoGridContainer } from '../primitives/csMonoGridContainer';
import { useAppDispatch } from '../../redux/store';
import { setLastVisitedModule } from '../../redux/slices/navigationSlice';

interface Props {
  classes: { [key: string]: string };
  location: any;
}

const Routes: React.FC<Props> = ({ classes, location }) => {
  const COLUMNS: Column[] = [
    {
      id: 'id',
      label: 'ID',
      width: 50,
      align: 'left',
    },
    {
      id: 'name',
      label: 'Name',
      width: 'auto',
      align: 'center',
    },
    {
      id: 'stations_count',
      label: `${configurationUtils.getPageTitle(
        false,
        'STOP',
      )} Count`,
      width: 200,
      align: 'center',
    },
    {
      id: 'outboundloads_count',
      label: `${configurationUtils.getPageTitle(
        false,
        'OUTBOUND_LOAD',
      )} Count`,
      width: 200,
      align: 'center',
    },
  ];

  const [showAddDialog, setShowAddDialog] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [lastUpdated, setLastUpdated] = useState<string>(
    new Date().toISOString(),
  );
  const [selectedName, setSelectedName] = useState<string>();
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [dockDoor, setDockDoor] = useState<TypeAheadItem>();

  const routeLabels = useMemo(() => {
    return {
      singular: configurationUtils.getPageTitle(true, 'ROUTE'),
      plural: configurationUtils.getPageTitle(false, 'ROUTE'),
    };
  }, []);

  const dispatch = useAppDispatch();

  const fetchRoutes = (
    page: number,
    rowsPerPage: number,
    filterOptions?: filterObj[],
    filterByString?: string,
    sortedBy?: string,
  ) => {
    return RoutesService.getAll({
      page,
      pageSize: rowsPerPage,
      search: filterByString,
    });
  };

  const onAfterDialogClose = () => {
    setShowAddDialog(false);
    setError(undefined);
    setSelectedName('');
    setDockDoor(undefined);
  };

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e as AxiosError));
  };

  const addHandler = async () => {
    setShowProgress(true);
    try {
      if (selectedName) {
        await RoutesService.create({
          name: selectedName,
          station: EphemeralStateService.getMyStationId(),
          default_dockdoor: dockDoor?.value
            ? Number(dockDoor.value)
            : undefined,
        });
        setLastUpdated(new Date().toISOString());
        onAfterDialogClose();
      }
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadDDOptions = useCallback(
    debounce((inputValue: string, callback: any) => {
      DockDoorService.search(inputValue)
        .then((data) => {
          callback(
            data.data.results.map((dataEl: DockDoorDetails) => {
              return {
                value: dataEl.id,
                label: dataEl.name,
              };
            }),
          );
        })
        .catch((e) => {
          handleError(e as AxiosError);
        });
    }, 500),
    [],
  );

  const renderDialog = () => {
    return (
      <Dialog
        open={showAddDialog}
        TransitionComponent={Transition}
        onClose={() => {
          onAfterDialogClose();
        }}
      >
        <DialogTitle>
          <CSDialogTitleWithIcon
            title={`Add ${routeLabels.singular}`}
            icon={DialogIcons.ADD}
          />
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {error && (
            <AlertBanner
              severity='error'
              alertTitle={'Error'}
              alertMsg={error}
            />
          )}
          <CSMonoGridContainer>
            <CSTextField
              value={selectedName || ''}
              label='Name'
              placeholder='Name'
              onChange={(e) => {
                setSelectedName(e.target.value);
              }}
              onClear={() => setSelectedName(undefined)}
              containerSize='fullHorizontal'
            />
            {configurationUtils.isModuleActive('DOCK_DOOR') ? (
              <CSAsyncSelect<TypeAheadItem<string>>
                label={`Default ${configurationUtils.getModuleLabel(
                  true,
                  'DOCK_DOOR',
                )}`}
                isClearable
                cacheOptions
                loadOptions={loadDDOptions}
                onChange={(option) => {
                  setDockDoor(option as TypeAheadItem);
                }}
                placeholder={'Start Typing...'}
                value={dockDoor}
                menuPortalTarget={document.body}
                noOptionsMessage={noOptionsMessage}
                containerSize='fullHorizontal'
              />
            ) : undefined}
          </CSMonoGridContainer>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <CSButton
            variant='outlined'
            color='secondary'
            data-testid={'routes-dialog-cancel'}
            onClick={() => {
              onAfterDialogClose();
            }}
          >
            Cancel
          </CSButton>
          <CSButton
            variant='contained'
            color='secondary'
            onClick={() => {
              addHandler();
            }}
            data-testid={'routes-dialog-add'}
            disabled={!selectedName}
          >
            Save
          </CSButton>
        </DialogActions>
      </Dialog>
    );
  };

  useEffect(() => {
    PermissionsService.redirectIfNoPermission(
      StaffPermissionPermissionsEnum.ROUTE_READ,
    );

    dispatch(
      setLastVisitedModule({
        module: ModulesKeys.ROUTE,
        permission: StaffPermissionPermissionsEnum.ROUTE_READ,
      }),
    );
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort - ${routeLabels.plural} ${
            queryString.parse(location.search)['page']
              ? '- Page ' + queryString.parse(location.search)['page']
              : ''
          }`}
        </title>
      </Helmet>
      <Layout navCurrent='ROUTE'>
        {showProgress && <ProgressIndicator />}
        {renderDialog()}
        <Grid container className={classes.header}>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.title} variant={'h3'}>
              {routeLabels.plural}
            </Typography>
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
            className={classes.nonMobileAlignRight}
          >
            {PermissionsService.hasPermission(
              StaffPermissionPermissionsEnum.ROUTE_WRITE,
            ) && (
              <CSButton
                variant='contained'
                color={{
                  buttonColor: 'secondary',
                  iconColor: 'primary',
                }}
                data-testid='add-route-btn'
                className={classes.containedButton}
                disabled={!!error}
                onClick={(e) => {
                  e.preventDefault();
                  setShowAddDialog(true);
                }}
                startIcon={<AddBoxOutlinedIcon />}
              >
                {`Add ${routeLabels.singular}`}
              </CSButton>
            )}
          </Grid>
          <CSSectionTitleSeparator bottomMargin={0} topMargin={5} />
        </Grid>

        <PaginatedTable
          key={lastUpdated}
          title=''
          columns={COLUMNS}
          filterByString={true}
          filterByStringPlaceholder={`Search ${routeLabels.plural}`}
          dataTestIdPrefix={'routes'}
          fetch={fetchRoutes}
          rowsLoadDetailPages={true}
          detailsPageBasePath={AuthRoutes.ROUTE}
        />
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...selectStyles,
    ...sectionPageBaseStyle,
    nonMobileAlignRight: {
      [theme.breakpoints.up('xs')]: {
        textAlign: 'right',
      },
    },
    dialogRoot: {
      minWidth: '488px',
      width: '488px',
    },
    dialogContent: {
      minWidth: '488px',
      width: '488px',
      paddingRight: '31px',
      paddingLeft: '31px',
      [theme.breakpoints.down('sm')]: {
        minWidth: 'auto',
        width: 'auto',
      },
    },
    dialogActions: {
      paddingRight: '31px',
      paddingLeft: '31px',
      paddingBottom: '31px',
    },
  })),
)(Routes);
