import Warning from '@material-ui/icons/Warning';
import { Tooltip, IconButton } from '@material-ui/core';

export default function NoDataAvailableIcon({
  color,
}: {
  color?: string;
}) {
  return (
    <Tooltip title='Unable to load data at this moment'>
      <IconButton
        disableFocusRipple
        disableRipple
        disableTouchRipple
        style={{ padding: 2, color: color }}
      >
        <Warning fontSize='small' />
      </IconButton>
    </Tooltip>
  );
}
