import MuiBox, { BoxProps } from '@material-ui/core/Box';
import * as React from 'react';

export interface IBoxProps extends BoxProps {}

const Box: React.FC<IBoxProps> = props => {
  const { children, ...MuiProps } = props;
  return <MuiBox {...MuiProps}>{children}</MuiBox>;
};

export default Box;
