import React from 'react';
import styles from './loadingGlobalData.styles';
import { Grid, Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

interface Props {
  classes: { [key: string]: string };
}

const LoadingGlobalData: React.FC<Props> = ({ classes }) => {
  return (
    <Grid>
      <div className={classes.errorBackground}>
        <div className={classes.innerContainer}>
          <Box>
            <img
              className={classes.loadingLogo}
              alt='CloudSort'
              src={`${
                process.env.REACT_APP_BASENAME || ''
              }/loginAssets/logo.svg`}
            />
          </Box>
          <Box>
            <CircularProgress style={{ color: '#fff' }} />
          </Box>
        </div>
      </div>
    </Grid>
  );
};

export default withStyles(styles)(LoadingGlobalData);
