import React, { useEffect, useState } from 'react';
import { CSButton, CSSwitch, Typography } from '../../../primitives';
import { withStyles } from '@material-ui/core/styles';
import { Box, Grid, Divider, Hidden, Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Transition } from '../../../confirmationDialog/ConfirmationDialog';
import TextField from '@material-ui/core/TextField';
import { IconButton } from '@material-ui/core';
import detailsPageStyles from '../../../commonStyles/detailsPage.style';
import { CurrentView, ModuleName } from '../Modules';
import { processSearchQuery } from '../searchQuery';
import Highlighter from 'react-highlight-words';
import configPageStyles from '../../../commonStyles/configPage.style';

// Icons
import EditIcon from '../../../../utils/svgs/EditIcon';

// Redux
import { useSearchResultSections } from '../../useSearchResultSections';
import { INTERACTION } from '../../utils';
import { onlyNumbers } from '../../../../utils/inputHelpers';
interface Props {
  classes: { [key: string]: string };
  data: any;
  interaction?: INTERACTION;
  setPartialData: (timers: any) => void;
  currentView: CurrentView;
}

const initDialogData = {
  title: '',
  msg: '',
  time: 0,
  key: null,
};

const Timers: React.FC<Props> = ({
  classes,
  data,
  interaction,
  setPartialData,
  currentView,
}) => {
  const [timersData, setTimersData] = useState<any[]>([]);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [dialogData, setDialogData] = useState<any>(initDialogData);
  const [searchResult, setSearchResult] = useState<string[]>([]);
  const [showWholeSection, setShowWholeSection] =
    useState<boolean>(true);

  const SECTION_TITLE = 'Timers';

  const { searchQuery, updateSections } = useSearchResultSections(
    ModuleName.OPERATOR_TOOL,
    SECTION_TITLE,
  );

  useEffect(
    function setTimerData() {
      if (data && currentView !== CurrentView.SEARCH) {
        const tmpData: any[] = [];
        for (let item of Object.entries(data)) {
          const itemData = item[1] as {
            msg: string;
            title: string;
            seconds: number;
          };
          tmpData.push(itemData);
        }
        setTimersData(tmpData);
      }
    },
    [data, currentView],
  );

  useEffect(
    function filterTimerDataBySearchResults() {
      if (currentView === CurrentView.SEARCH && data) {
        const tmpData: any[] = [];
        for (let item of Object.entries(data)) {
          const itemData = item[1] as {
            msg: string;
            title: string;
            seconds: number;
          };
          if (
            showWholeSection ||
            searchResult.some(
              (result) =>
                itemData.title
                  .toLowerCase()
                  .includes(result.toLowerCase()) ||
                itemData.msg
                  .toLowerCase()
                  .includes(result.toLowerCase()) ||
                itemData.seconds
                  .toString()
                  .toLowerCase()
                  .includes(result.toLowerCase()),
            )
          )
            tmpData.push(itemData);
        }
        setTimersData(tmpData);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, searchResult],
  );

  useEffect(
    function processSearch() {
      if (currentView === CurrentView.SEARCH) {
        //Show whole section is there are results in a title
        setShowWholeSection(
          processSearchQuery(searchQuery, [SECTION_TITLE]).length !==
            0,
        );

        const dynamicLabels: string[] = [];
        for (let item of Object.entries(data)) {
          const itemData = item[1] as {
            msg: string;
            title: string;
            seconds: number;
          };
          dynamicLabels.push(itemData.msg);
          dynamicLabels.push(itemData.title);
          dynamicLabels.push(itemData.seconds.toString());
        }
        const results = processSearchQuery(searchQuery, [
          SECTION_TITLE,
          ...dynamicLabels,
        ]);

        setSearchResult(results);

        updateSections(results);
      } else {
        setSearchResult([]);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchQuery, currentView],
  );

  const getElKey = (obj: any) => {
    const el = Object.entries(data).find(
      ([key, value]: any) => value === obj,
    );
    return el?.length ? el[0] : '';
  };

  const onAfterDialogClose = () => {
    setShowDialog(false);
    setDialogData(initDialogData);
  };

  const renderDialog = () => {
    return (
      <Dialog
        classes={{ paperScrollPaper: classes.dialogRoot }}
        open={showDialog}
        TransitionComponent={Transition}
        onClose={() => {
          onAfterDialogClose();
        }}
      >
        <DialogTitle>
          <Typography>Edit</Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogRoot}>
          <TextField
            data-testid='timers-dialog-msg-title'
            autoFocus
            label={'Title'}
            InputLabelProps={{
              shrink: true,
              className: classes.inputNameLabel,
            }}
            InputProps={{
              disableUnderline: true,
              className: classes.inputName,
            }}
            classes={{
              root: classes.inputNameHolder,
            }}
            value={dialogData.title}
            onChange={(e) => {
              setDialogData({
                ...dialogData,
                title: e.target.value,
              });
            }}
          />
          <TextField
            data-testid='timers-dialog-msg'
            label={'Message'}
            InputLabelProps={{
              shrink: true,
              className: classes.inputNameLabel,
            }}
            InputProps={{
              disableUnderline: true,
              className: classes.inputName,
            }}
            classes={{
              root: classes.inputNameHolder,
            }}
            value={dialogData.msg}
            onChange={(e) => {
              setDialogData({
                ...dialogData,
                msg: e.target.value,
              });
            }}
          />
          <TextField
            data-testid='timers-dialog-msg-seconds'
            label={'Seconds'}
            type={'number'}
            InputLabelProps={{
              shrink: true,
              className: classes.inputNameLabel,
            }}
            InputProps={{
              disableUnderline: true,
              className: classes.inputName,
            }}
            inputProps={{
              min: '0',
              step: '1',
            }}
            classes={{
              root: classes.inputNameHolder,
            }}
            value={dialogData.time}
            onChange={(e) => {
              const value = e.target.value;
              setDialogData({
                ...dialogData,
                time: onlyNumbers(value),
              });
            }}
          />
        </DialogContent>
        <DialogActions>
          <CSButton
            color='secondary'
            variant='outlined'
            onClick={() => {
              onAfterDialogClose();
            }}
          >
            Cancel
          </CSButton>
          <CSButton
            color='secondary'
            variant='contained'
            data-testid='timers-dialog-save-btn'
            onClick={() => {
              data[dialogData.key].title = dialogData.title;
              data[dialogData.key].msg = dialogData.msg;
              data[dialogData.key].seconds =
                dialogData.time.toString();
              setPartialData({
                ...data,
              });
              onAfterDialogClose();
            }}
          >
            Update
          </CSButton>
        </DialogActions>
      </Dialog>
    );
  };

  const renderComponent = () => {
    return (
      <>
        {renderDialog()}
        <Grid item sm={12}>
          <Box>
            <Box
              className={classNames(
                classes.moduleTitle,
                classes.pLeft,
              )}
              mb={1}
              mt={2}
            >
              <Highlighter
                highlightClassName={classes.searchResultsMark}
                searchWords={searchResult}
                autoEscape={true}
                textToHighlight={SECTION_TITLE}
              />
            </Box>

            <Box mt={2}>
              <Box mb={2}>
                <Grid
                  container
                  item
                  sm={12}
                  className={classes.textHeader}
                >
                  <Grid item sm={2} xs={4} className={classes.pLeft}>
                    Title
                  </Grid>
                  <Hidden xsDown>
                    <Grid item sm={5}>
                      Message
                    </Grid>
                    <Grid item sm={2}>
                      Seconds
                    </Grid>
                  </Hidden>
                  <Grid item sm={1} xs={2} />
                  <Grid item sm={1} xs={3}>
                    Active
                  </Grid>
                  <Grid item sm={1} xs={3}>
                    Repeat
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider className={classes.divider} />
                </Grid>
              </Box>
              {timersData?.map(
                (partialDataEl: any, index: number, array: any[]) => {
                  return (
                    <Grid
                      container
                      item
                      sm={12}
                      key={partialDataEl.title}
                      className={classes.row}
                    >
                      <Grid
                        item
                        sm={2}
                        xs={4}
                        className={classNames(
                          classes.text,
                          classes.pLeft,
                        )}
                      >
                        <Highlighter
                          highlightClassName={
                            classes.searchResultsMark
                          }
                          searchWords={searchResult}
                          autoEscape={true}
                          textToHighlight={partialDataEl.title}
                        />
                      </Grid>
                      <Hidden xsDown>
                        <Grid
                          item
                          sm={5}
                          className={classes.text}
                          style={{ paddingRight: 10 }}
                        >
                          <Highlighter
                            highlightClassName={
                              classes.searchResultsMark
                            }
                            searchWords={searchResult}
                            autoEscape={true}
                            textToHighlight={partialDataEl.msg}
                          />
                        </Grid>
                        <Grid item sm={2} className={classes.text}>
                          <Highlighter
                            highlightClassName={
                              classes.searchResultsMark
                            }
                            searchWords={searchResult}
                            autoEscape={true}
                            textToHighlight={partialDataEl.seconds.toString()}
                          />
                        </Grid>
                      </Hidden>
                      <Grid item sm={1} xs={2}>
                        <IconButton
                          data-testid={`timers-${partialDataEl.title}-edit-btn`}
                          size='small'
                          disabled={interaction === INTERACTION.READ}
                          className={classes.editIcon}
                          onClick={() => {
                            setDialogData({
                              title: partialDataEl.title,
                              msg: partialDataEl.msg,
                              time: partialDataEl.seconds,
                              key: getElKey(partialDataEl),
                            });
                            setShowDialog(true);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </Grid>
                      <Grid
                        item
                        sm={1}
                        xs={3}
                        className={classes.muiSwitchHoler}
                      >
                        <CSSwitch
                          data-testid={`timers-${partialDataEl.title}-active-switch`}
                          color='default'
                          size='small'
                          disabled={interaction === INTERACTION.READ}
                          checked={partialDataEl.active}
                          onChange={() => {
                            data[getElKey(partialDataEl)].active =
                              !partialDataEl.active;
                            setPartialData({ ...data });
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        sm={1}
                        xs={3}
                        className={classes.muiSwitchHoler}
                      >
                        <CSSwitch
                          data-testid={`timers-${partialDataEl.title}-repeat-switch`}
                          color='default'
                          size='small'
                          disabled={interaction === INTERACTION.READ}
                          checked={partialDataEl.repeat}
                          onChange={() => {
                            data[getElKey(partialDataEl)].repeat =
                              !partialDataEl.repeat;
                            setPartialData({ ...data });
                          }}
                        />
                      </Grid>
                      {index !== array.length - 1 && (
                        <Grid item xs={12}>
                          <Divider className={classes.divider} />
                        </Grid>
                      )}
                    </Grid>
                  );
                },
              )}
            </Box>
          </Box>
        </Grid>
      </>
    );
  };

  return currentView === CurrentView.SEARCH && !searchResult.length
    ? null
    : renderComponent();
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...configPageStyles(theme),
  })),
)(Timers);
