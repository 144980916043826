import React, {
  useEffect,
  useState,
  Fragment,
  CSSProperties,
} from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './dndTable.styles';
import { CSButton, Typography } from '../primitives';
import RootRef from '@material-ui/core/RootRef';
import Toolbar from '@material-ui/core/Toolbar';
import { useTheme } from '@material-ui/core/styles';
import { IconButton, Box, Grid } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import DOMPurify from 'dompurify';

// Icons
import DragHandleIcon from '@material-ui/icons/DragHandle';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

// Types
import { Column } from '../../interfaces/components';
import { Cancel } from '@material-ui/icons';

export enum Actions {
  DELETE = 'DELETE',
}

export interface IGenericTableData extends Record<string, unknown> {
  id: number;
}

interface Props {
  classes: { [key: string]: string };
  columns: Column[];
  data: IGenericTableData[];
  onAfterDragEnd?: (items: IGenericTableData[]) => void;
  isDragDisabled?: boolean;
  action?: Actions;
  onActionClick?: (id: number | string | null) => void;
  isActionDisabled?: boolean;
  dataTestIdPrefix?: string;
  title: string;
}

const reorder = (
  list: IGenericTableData[],
  startIndex: number,
  endIndex: number,
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const getItemStyle = (
  isDragging: boolean,
  draggableStyle: CSSProperties,
) => ({
  ...draggableStyle,
  ...{
    height: 50,
  },
  ...(isDragging && {
    borderBottom: 0,
    height: 50,
  }),
});

const DnDTable: React.FC<Props> = ({
  classes,
  columns,
  data,
  onAfterDragEnd,
  isDragDisabled,
  action,
  onActionClick,
  isActionDisabled,
  dataTestIdPrefix,
  title,
  ...props
}) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));

  const [items, setItems] = useState<IGenericTableData[]>();
  const mobileColumns = columns.filter((column, index) =>
    [0].includes(index),
  );
  const [mobileEps, setMobileEps] = useState<Map<number, boolean>>(
    new Map<number, boolean>(),
  );

  const onDragProportion = 100 / (columns.length + 1);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const recentlyOrderedItems = reorder(
      items || [],
      result.source.index,
      result.destination.index,
    );
    setItems(recentlyOrderedItems);
    if (onAfterDragEnd) {
      onAfterDragEnd(recentlyOrderedItems);
    }
  };

  useEffect(() => {
    setItems(data);
  }, [data]);

  return (
    <div
      className={classes.holder}
      data-testid={`${dataTestIdPrefix}-dnd-holder`}
      {...props}
    >
      <Toolbar className={classes.toolBar}>
        <Typography variant='h4'>{title}</Typography>
      </Toolbar>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId='droppable'
          data-testid={`${dataTestIdPrefix}-dnd-droppable`}
        >
          {(provided, snapshot) => (
            <RootRef rootRef={provided.innerRef}>
              <table className={classes.table}>
                <thead>
                  <tr className={classes.tableHeadRow}>
                    <th className={classes.dragHandleHeading}>
                      Reorder
                    </th>
                    {(isMobileView ? mobileColumns : columns).map(
                      (column, index) => (
                        <Fragment key={column.id}>
                          {isMobileView && (
                            <th
                              className={classes.mobileheaderHeight}
                            />
                          )}
                          <th
                            align={column.align}
                            style={
                              isMobileView
                                ? {}
                                : {
                                    width: column.width,
                                    textAlign: column.align,
                                  }
                            }
                          >
                            {column.label}
                          </th>
                        </Fragment>
                      ),
                    )}
                    {action && (
                      <th className={classes.actionsHeading} />
                    )}
                  </tr>
                </thead>
                <tbody>
                  {items?.map((item, index: number) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id?.toString()!}
                      index={index}
                      isDragDisabled={isDragDisabled}
                    >
                      {(provided, snapshot) => (
                        <Fragment>
                          <tr
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            data-testid={`${dataTestIdPrefix}-dnd-row-${item.id}`}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps
                                .style as CSSProperties,
                            )}
                          >
                            <td
                              className={classes.dragHandle}
                              style={{
                                width: snapshot.isDragging
                                  ? `${onDragProportion}%`
                                  : '100%',
                              }}
                            >
                              <DragHandleIcon
                                className={classes.dndIcon}
                              />
                            </td>
                            {isMobileView && (
                              <td
                                className={classes.mobileheaderHeight}
                              >
                                <Box p={1}>
                                  <IconButton
                                    size='small'
                                    onClick={() => {
                                      setMobileEps(
                                        new Map(
                                          mobileEps.set(
                                            item.id,
                                            !mobileEps.get(item.id),
                                          ),
                                        ),
                                      );
                                    }}
                                  >
                                    {mobileEps.get(item.id) ? (
                                      <RemoveCircleOutlineIcon />
                                    ) : (
                                      <AddCircleOutlineIcon />
                                    )}
                                  </IconButton>
                                </Box>
                              </td>
                            )}
                            {(isMobileView
                              ? mobileColumns
                              : columns
                            ).map((column, index) => {
                              return (
                                <td
                                  key={column.id}
                                  align={column.align}
                                  style={
                                    isMobileView
                                      ? {}
                                      : {
                                          width: snapshot.isDragging
                                            ? `${onDragProportion}%`
                                            : column.width,
                                        }
                                  }
                                >
                                  <Typography
                                    variant='table-body'
                                    color={{
                                      color: 'text',
                                      variant: 'primary',
                                    }}
                                  >
                                    <span
                                      /**
                                       * TODO:
                                       * Check if this is still necessary as using this prop is an antipattern
                                       */
                                      dangerouslySetInnerHTML={{
                                        //DOMPurify doesn't work well with non html/svg/mathml types.
                                        __html: DOMPurify.sanitize(
                                          `<span>${
                                            item[column.id]
                                          }</span>`,
                                        ),
                                      }}
                                    ></span>
                                  </Typography>
                                </td>
                              );
                            })}
                            {action === Actions.DELETE && (
                              <td
                                align={'left'}
                                style={{
                                  textAlign: 'center',
                                  width: snapshot.isDragging
                                    ? `${onDragProportion}%`
                                    : '100%',
                                }}
                              >
                                <CSButton
                                  color='quaternary'
                                  className={classes.deleteButton}
                                  data-testid={`${dataTestIdPrefix}-dnd-delete-action-${item.id}`}
                                  data-itemid={item.id}
                                  disabled={isActionDisabled}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (onActionClick) {
                                      onActionClick(
                                        e.currentTarget.getAttribute(
                                          'data-itemid',
                                        ),
                                      );
                                    }
                                  }}
                                >
                                  <Cancel
                                    className={classes.deleteIcon}
                                  />
                                </CSButton>
                              </td>
                            )}
                          </tr>
                          {isMobileView && mobileEps.get(item.id) && (
                            <tr
                              key={`mobile-extra-info-row-${item.id}`}
                            >
                              <td
                                colSpan={columns.length + 2}
                                className={classes.tableCellMobile}
                              >
                                {columns
                                  .filter(
                                    (column, index) =>
                                      ![0].includes(index),
                                  )
                                  .map((column, index) => {
                                    return (
                                      <Grid
                                        className={
                                          classes.tableExpander
                                        }
                                        container
                                        item
                                        xs={12}
                                        key={`${column}-${index}`}
                                      >
                                        <Grid item xs={6}>
                                          <Box
                                            pl={6}
                                            pt={2}
                                            pb={2}
                                            className={
                                              classes.mobileColumnLabel
                                            }
                                          >
                                            {column.label}
                                          </Box>
                                        </Grid>
                                        <Grid item xs={6}>
                                          <Box
                                            pt={2}
                                            pb={2}
                                            className={
                                              classes.mobileColumnValue
                                            }
                                          >
                                            {
                                              item[
                                                column.id
                                              ] as React.ReactNode
                                            }
                                          </Box>
                                        </Grid>
                                      </Grid>
                                    );
                                  })}
                              </td>
                            </tr>
                          )}
                        </Fragment>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </tbody>
              </table>
            </RootRef>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default withStyles(styles)(DnDTable);
