import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import {
  DialogTitle,
  createStyles,
  MenuItem,
} from '@material-ui/core';
import { AxiosError } from 'axios';
import { Staff, StaffPermissionRoleTypeEnum } from 'cloudsort-client';

import ErrorHandler from '../../../../utils/ErrorHandler';
import debounce from 'lodash/debounce';
import ProgressIndicator from '../../../progressIndicator/ProgressIndicator';
import { CSButton, CSTextField } from '../../../primitives';
import selectStyles from '../../../select/select.styles';
import detailsPageStyles from '../../../commonStyles/detailsPage.style';
import { ROLE_LABELS } from '../../../stationStaff/StationStaff';
import validateEmail from '../../../../utils/validateEmail';
import StaffService from '../../../../services/Staff.service';

import CSDialogTitleWithIcon from '../../../primitives/csDialogTitleWithIcon/CSDialogTitleWithIcon';
import { DialogIcons } from '../../../primitives/csDialogTitleWithIcon/CSDialogTitleWithIconTypes';
import CSSelect from '../../../primitives/csSelect/CSSelect';
import CSAsyncSelect from '../../../primitives/csAsyncSelect/CSAsyncSelect';
import { TypeAheadItem } from '../../../../interfaces/components';
import OrganizationsService from '../../../../services/Organizations.service';
import StationsService from '../../../../services/Stations.service';
import { noOptionsMessage } from '../../../asyncSelect/utils';
import { CSMonoGridContainer } from '../../../primitives/csMonoGridContainer';
import CSDialogAlert from '../../../primitives/csDialogAlert/CSDialogAlert';
import ConfirmationDialog from '../../../confirmationDialog/ConfirmationDialog';

interface Props {
  classes: { [key: string]: string };
  isOpen: boolean;
  onCancel: () => void;
  onAddUser: () => void;
}

type DialogStep =
  | 'SelectRoleAndEnterEmail'
  | 'EnterNameAndAssignEntities';

const AddStaffDialog: React.FC<Props> = ({
  classes,
  isOpen,
  onCancel,
  onAddUser,
}) => {
  const [currentStep, setCurrentStep] = useState<DialogStep>(
    'SelectRoleAndEnterEmail',
  );
  const [open, setOpen] = useState(false);
  const [error, setError] = useState<string>();
  const [showProgress, setShowProgress] = useState<boolean>(false);

  const [selectedEntitiesOptions, setSelectedEntitiesOptions] =
    useState<TypeAheadItem[]>([]);
  const [selectedRole, setSelectedRole] =
    useState<StaffPermissionRoleTypeEnum>(
      StaffPermissionRoleTypeEnum.OPERATOR_I,
    );

  const selectedOrgRole = useMemo(() => {
    return selectedRole === StaffPermissionRoleTypeEnum.ORG_ADMIN;
  }, [selectedRole]);

  const [staffEmail, setStaffEmail] = useState<Staff['email']>('');
  const [existingStaff, setExistingStaff] = useState<Staff>();
  const [firstName, setFirstName] = useState<Staff['first_name']>('');
  const [lastName, setLastName] = useState<Staff['last_name']>('');

  const [showSuccessConfirmation, setShowSuccessConfirmation] =
    useState<boolean>(false);

  const hasValidEmailFormat = validateEmail(staffEmail) && staffEmail;
  const hasEmailError = Boolean(staffEmail && !hasValidEmailFormat);
  const handleError = async (err: AxiosError) => {
    setError(await ErrorHandler.getLabel(err));
  };

  const resetState = () => {
    setOpen(false);
    setError(undefined);
    setCurrentStep('SelectRoleAndEnterEmail');
    setSelectedRole(StaffPermissionRoleTypeEnum.OPERATOR_I);
    setStaffEmail(undefined);
    setFirstName(undefined);
    setLastName(undefined);
    setSelectedEntitiesOptions([]);
    setShowSuccessConfirmation(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadEntityOptions = useCallback(
    debounce((inputValue: string, callback: any) => {
      Promise.all([
        OrganizationsService.getAllSearch(inputValue),
        StationsService.getAllStationsSearch(inputValue),
      ]).then(([orgRes, stationsRes]) => {
        selectedOrgRole
          ? callback(
              orgRes.data.results.map((org) => {
                return {
                  value: org.id,
                  label: org.name,
                };
              }),
            )
          : callback(
              stationsRes.data.results.map((station) => {
                return {
                  value: station.id,
                  label: station.name,
                };
              }),
            );
      });
    }, 500),
    [selectedOrgRole],
  );

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const createStaff = async () => {
    try {
      setShowProgress(true);
      const res = await StaffService.create({
        first_name: firstName,
        last_name: lastName,
        email: staffEmail ? staffEmail : undefined,
        permission_data: {
          role_type: selectedRole,
          station: !selectedOrgRole
            ? Number(selectedEntitiesOptions[0].value)
            : undefined,
          organization: selectedOrgRole
            ? Number(selectedEntitiesOptions[0].value)
            : undefined,
        },
      });

      const newStaffId = Number(res.data.id);

      //if multiple entities are selected, loop through them
      for (
        let index = 1;
        index < selectedEntitiesOptions.length;
        index++
      ) {
        const entity = selectedEntitiesOptions[index];

        await StaffService.update(newStaffId, {
          permission_data: {
            role_type: selectedRole,
            station: !selectedOrgRole
              ? Number(entity.value)
              : undefined,
            organization: selectedOrgRole
              ? Number(entity.value)
              : undefined,
          },
        });
      }

      setShowSuccessConfirmation(true);

      onAddUser();
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  const updateStaff = async () => {
    if (!existingStaff) return;
    try {
      setShowProgress(true);
      const staffId = existingStaff.id!;

      selectedEntitiesOptions.forEach(async (option) => {
        if (selectedOrgRole) {
          await StaffService.update(staffId, {
            permission_data: {
              role_type: selectedRole,
              organization: Number(option.value),
            },
          });
        } else {
          //it's station

          await StaffService.update(staffId, {
            permission_data: {
              role_type: selectedRole,
              station: Number(option.value),
            },
          });
        }
      });

      setShowSuccessConfirmation(true);

      onAddUser();
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  const handleAddStaff = () => {
    if (existingStaff) {
      updateStaff();
    } else {
      createStaff();
    }
  };

  const handleNextClick = () => {
    setCurrentStep('EnterNameAndAssignEntities');
  };

  const checkIfEmailIsRegistered = debounce(async (email: string) => {
    try {
      setShowProgress(true);
      const userExist = await StaffService.checkEmail(email);

      if (userExist.data.id) {
        const user = await StaffService.getById(userExist.data.id);

        user && setExistingStaff(user.data);
      }
    } catch (e) {
      setExistingStaff(undefined);
    } finally {
      setShowProgress(false);
    }
  }, 300);

  const entityAutocompleteLabel = useMemo(() => {
    if (selectedOrgRole) {
      return 'Organizations';
    }
    return 'Stations';
  }, [selectedOrgRole]);

  return (
    <>
      {showProgress && <ProgressIndicator />}
      {showSuccessConfirmation ? (
        <ConfirmationDialog
          title={
            existingStaff
              ? 'The staff has been updated sucessfully'
              : 'New staff added sucessfully'
          }
          msg={
            existingStaff
              ? 'Access to the selected entities was granted sucessfully'
              : `We have sent an email to ${staffEmail} to complete the registration process.`
          }
          primaryActionLabel='OK'
          onPrimaryAction={() => {
            resetState();
          }}
          cancelLabel='Cancel'
          hideCancelButton
          onCancel={() => {
            resetState();
          }}
          isOpen={true}
        />
      ) : (
        <Dialog open={open}>
          <DialogTitle>
            <CSDialogTitleWithIcon
              icon={DialogIcons.ADD}
              title='Please add the new staff details'
              subtitle='The user will receive a link in the email to complete the registration.'
            />
          </DialogTitle>

          <DialogContent>
            <CSMonoGridContainer>
              {error ? (
                <CSDialogAlert alertMessage={error} />
              ) : undefined}
              {currentStep === 'SelectRoleAndEnterEmail' && (
                <CSSelect
                  label='Role'
                  containerSize='fullHorizontal'
                  onChange={(e: React.BaseSyntheticEvent) => {
                    const { value } = e.target;
                    selectedRole !== value &&
                      setSelectedEntitiesOptions([]);
                    setSelectedRole(
                      value as StaffPermissionRoleTypeEnum,
                    );
                  }}
                  displayEmpty
                  value={selectedRole || 'none'}
                >
                  <MenuItem
                    key={`fragment-none`}
                    value={'none'}
                    disabled
                  >
                    Please select...
                  </MenuItem>
                  {Object.keys(StaffPermissionRoleTypeEnum)
                    .filter(
                      (key) => key !== 'SUPERUSER' && key !== 'BLANK',
                    )
                    .map((key) => {
                      return {
                        label:
                          ROLE_LABELS[
                            key as keyof typeof ROLE_LABELS
                          ],
                        value:
                          StaffPermissionRoleTypeEnum[
                            key as keyof typeof StaffPermissionRoleTypeEnum
                          ],
                      };
                    })
                    .map((permission) => {
                      return (
                        <MenuItem
                          key={`fragment-${permission.label}`}
                          value={permission.value}
                        >
                          {permission.label}
                        </MenuItem>
                      );
                    })}
                </CSSelect>
              )}

              {currentStep === 'SelectRoleAndEnterEmail' &&
                selectedRole !== 'OPERATOR_I' && (
                  <CSTextField
                    type='email'
                    label='Email'
                    placeholder='email@example.com'
                    containerSize='fullHorizontal'
                    value={staffEmail}
                    onChange={(event) => {
                      const newEmail = event.target.value.trim();
                      setStaffEmail(newEmail);
                      if (validateEmail(newEmail)) {
                        checkIfEmailIsRegistered(newEmail);
                      }
                    }}
                    error={hasEmailError}
                  />
                )}
              {currentStep === 'EnterNameAndAssignEntities' &&
                !existingStaff && (
                  <CSTextField
                    label='First Name'
                    containerSize='fullHorizontal'
                    placeholder='First Name'
                    value={firstName}
                    onChange={(event) =>
                      setFirstName(event.target.value)
                    }
                  />
                )}
              {currentStep === 'EnterNameAndAssignEntities' &&
                !existingStaff && (
                  <CSTextField
                    label='Last Name'
                    containerSize='fullHorizontal'
                    placeholder='Last Name'
                    value={lastName}
                    onChange={(event) =>
                      setLastName(event.target.value)
                    }
                  />
                )}
              {currentStep === 'EnterNameAndAssignEntities' && (
                <CSAsyncSelect<TypeAheadItem, true>
                  isMulti
                  containerSize='fullHorizontal'
                  loadOptions={loadEntityOptions}
                  menuPortalTarget={document.body}
                  onChange={(newValues: readonly TypeAheadItem[]) => {
                    setSelectedEntitiesOptions([...newValues]);
                  }}
                  value={selectedEntitiesOptions}
                  noOptionsMessage={noOptionsMessage}
                  label={`Assigned ${entityAutocompleteLabel}`}
                  placeholder={`Find ${entityAutocompleteLabel} by name`}
                />
              )}
            </CSMonoGridContainer>
          </DialogContent>

          <DialogActions className={classes.dialogActions}>
            {currentStep === 'SelectRoleAndEnterEmail' && (
              <>
                <CSButton
                  variant='outlined'
                  color='secondary'
                  onClick={(e) => {
                    resetState();
                    onCancel();
                  }}
                >
                  Cancel
                </CSButton>
                <CSButton
                  variant='contained'
                  color='secondary'
                  disabled={
                    selectedRole !==
                      StaffPermissionRoleTypeEnum.OPERATOR_I &&
                    (hasEmailError || !staffEmail)
                  }
                  onClick={handleNextClick}
                >
                  Next
                </CSButton>
              </>
            )}

            {currentStep === 'EnterNameAndAssignEntities' && (
              <>
                <CSButton
                  variant='outlined'
                  color='secondary'
                  onClick={(e) => {
                    setCurrentStep('SelectRoleAndEnterEmail');
                  }}
                >
                  Back
                </CSButton>
                <div style={{ flexGrow: 1 }} />
                <CSButton
                  variant='outlined'
                  color='secondary'
                  onClick={(e) => {
                    resetState();
                    onCancel();
                  }}
                >
                  Cancel
                </CSButton>
                <CSButton
                  variant='contained'
                  color='secondary'
                  disabled={
                    selectedEntitiesOptions.length === 0 ||
                    (!existingStaff &&
                      (!Boolean(firstName) || !Boolean(lastName)))
                  }
                  onClick={handleAddStaff}
                >
                  Add User
                </CSButton>
              </>
            )}
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default withStyles(
  createStyles(() => ({
    ...detailsPageStyles,
    ...selectStyles,
    dialogActions: {
      padding: 24,
    },
  })),
)(AddStaffDialog);
