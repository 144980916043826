import { WebHookSubscription, WebhooksApi } from 'cloudsort-client';
import axios from '../utils/axios';

class Webhooks {
  private api: WebhooksApi;

  constructor() {
    this.api = new WebhooksApi(undefined, undefined, axios as any);
  }

  public async getAll(params: {
    page: number;
    page_size: number;
    owner: number | undefined;
    organization: number | undefined;
  }) {
    return this.api.webhooksList({ ...params });
  }

  public async getById(id: number) {
    return this.api.webhooksRead({
      id,
    });
  }

  public async getHistory(params: {
    id: number;
    page?: number;
    pageSize?: number;
  }) {
    return this.api.webhooksTaskhistory(params);
  }

  public async create(data: WebHookSubscription) {
    return this.api.webhooksCreate({
      data,
    });
  }

  public async update(id: number, data: WebHookSubscription) {
    return this.api.webhooksPartialUpdate({
      id,
      data,
    });
  }
}

export default new Webhooks();
