import React, { useEffect, useMemo, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Layout from '../layout/Layout';
import { AuthRoutes, ModulesKeys } from '../../interfaces/routes';
import {
  renderTimestampString,
  renderVolumeString,
} from '../DetailsPagesFunctions';
import ErrorHandler from '../../utils/ErrorHandler';
import { AxiosError } from 'axios';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import { AlertBanner, CSButton, Typography } from '../primitives';
import ExportCSVDialog from '../exportCSVDialog/ExportCSVDialog';

import PaginatedTable, {
  filterObj,
} from '../paginatedTable/PaginatedTable';
import { Column } from '../../interfaces/components';
import { createStyles, Grid, Theme } from '@material-ui/core';
import configurationUtils from '../../utils/configurationUtils';
import {
  Manifest,
  StaffPermissionPermissionsEnum,
} from 'cloudsort-client';
import { getDetailsPageStylesWithTheme } from '../commonStyles/detailsPage.style';
import sectionPageBaseStyle from '../commonStyles/sectionPageBase.style';
import { getFiltersStylesWithTheme } from '../filtersDrawer/filterBadge.style';
import queryString from 'query-string';
import FiltersDrawer, {
  SelectedFilters,
} from '../filtersDrawer/FiltersDrawer';
import clx from 'classnames';
import { Helmet } from 'react-helmet';

//Services
import ManifestsService from '../../services/Manifests.service';
import PermissionsService from '../../services/Permissions.service';

// Icons
import CloudDownloadOutlined from '@material-ui/icons/CloudDownloadOutlined';
import FilterListIcon from '@material-ui/icons/FilterList';
import CreateInboundManifestDialog from './CreateInboundManifestDialog';
import CSSectionTitleSeparator from '../primitives/csSectionTitleSeparator/CSSectionTitleSeparator';
import CloudUploadOutlined from '@material-ui/icons/CloudUploadOutlined';
import EphemeralStateService from '../../services/EphemeralState.service';
import CSHorizontalFilterBadgesGroup from '../primitives/csHorizontalFilterBadgesGroup/csHorizontalFilterBadgesGroup';
import { FilterDescription } from '../primitives/csHorizontalFilterBadgesGroup/csHorizontalFilterBadgesGroupTypes';
import usePolling from '../../hooks/usePolling';
import { useAppDispatch } from '../../redux/store';
import { setLastVisitedModule } from '../../redux/slices/navigationSlice';

interface Props {
  classes: { [key: string]: string };
  location: any;
}

const Manifests: React.FC<Props> = ({ classes, location }) => {
  const [batchExportEnabled, setBatchExportEnabled] =
    useState<boolean>(false);
  const [showBatchExportDialog, setShowBatchExportDialog] =
    useState<boolean>(false);
  const [
    showCreateInboundManifestDialog,
    setShowCreateInboundManifestDialog,
  ] = useState<boolean>(false);
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [showFiltersDrawer, setShowFiltersDrawer] = useState(false);
  const [selectedFilters, setSelectedFilters] =
    useState<SelectedFilters>();
  const [filterToRemove, setFilterToRemove] =
    useState<FilterDescription>();
  const [filterLastUpdate, setFilterLastUpdate] = useState<number>(0);

  const manifestLabels = useMemo(() => {
    return {
      singular: configurationUtils.getPageTitle(true, 'MANIFEST'),
      plural: configurationUtils.getPageTitle(false, 'MANIFEST'),
    };
  }, []);

  const showArrivalPrediction = useMemo(() => {
    return EphemeralStateService.getMyStationConfiguratation().GENERAL
      .BEHAVIORS.ML_INTEGRATION.arrival_prediction;
  }, []);

  // Filter drawer
  const getDrawerFilters = (filters: SelectedFilters) => {
    setSelectedFilters(filters);
    setFilterLastUpdate(Date.now());
  };

  const dispatch = useAppDispatch();

  // Get labels on each render cycle
  const COLUMNS: Column[] = [
    {
      id: 'id',
      label: 'ID',
      width: 120,
      align: 'left',
    },
    {
      id: 'type',
      label: 'Type',
      width: 'auto',
      align: 'left',
    },
    {
      id: 'manifested_formatted',
      label: 'Manifested',
      width: 'auto',
      align: 'left',
    },
    {
      id: 'predicted_formatted',
      label: 'Predicted',
      width: 'auto',
      align: 'left',
      hide: !showArrivalPrediction,
    },
    {
      id: 'owner_full_name',
      label: 'Owner',
      width: 'auto',
      align: 'left',
    },
    {
      id: 'fmc_full_name',
      label: 'Carrier',
      width: 'auto',
      align: 'left',
    },
    {
      id: 'created_on',
      label: 'Creation Date',
      align: 'left',
      width: 'left',
    },
  ];

  interface ExtendedManifest extends Manifest {
    type: string;
    manifested_formatted: string;
    predicted_formatted: string;
  }

  const fetch = async (
    pageIndex: number,
    rowsPerPage: number,
    filterOptions?: filterObj[],
    filterByString?: string,
    sortedBy?: string,
  ) => {
    const res = await ManifestsService.getAll({
      page: pageIndex,
      pageSize: rowsPerPage,
      manifestType:
        (selectedFilters?.type?.values[0]?.id as
          | 'inbound'
          | 'outbound') || undefined,
      owner: selectedFilters?.owner?.values?.length
        ? selectedFilters.owner.values.map((value) =>
            Number(value.id),
          )
        : undefined,
      sortBy: sortedBy as any,
    });

    for (const manifest of res.data.results as ExtendedManifest[]) {
      manifest.created_on = renderTimestampString(
        manifest.created_on,
      );
      manifest.type = manifest.inbound
        ? 'Inbound'
        : manifest.outbound
        ? 'Outbound'
        : 'N/A';

      manifest.manifested_formatted = `${
        manifest.total_packages || 0
      } / ${renderVolumeString(manifest.total_volume)}`;
      manifest.predicted_formatted = `${
        manifest.predicted_arrival_packages || 0
      } / ${renderVolumeString(manifest.predicted_arrival_volume)}`;
    }

    if (pageIndex === 1 && res.data.count > 0) {
      setBatchExportEnabled(true);
    }

    return res;
  };
  const { startPolling, checkTaskId } = usePolling();

  const downloadCSV = async (id: number) => {
    setShowProgress(true);
    try {
      const res = await ManifestsService.getCSVExport({
        id: id.toString(),
        fromDate: new Date().toISOString(),
        toDate: new Date().toISOString(),
      });

      const fileNamStr = `manifest_export_for_manifest_id_${id}.csv`;
      startPolling(() => {
        checkTaskId(
          res.data.task_id,
          fileNamStr,
          setError,
          setShowProgress,
        );
      });
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e as AxiosError));
  };

  useEffect(() => {
    PermissionsService.redirectIfNoPermission(
      StaffPermissionPermissionsEnum.MANIFEST_READ,
    );

    dispatch(
      setLastVisitedModule({
        module: ModulesKeys.MANIFEST,
        permission: StaffPermissionPermissionsEnum.MANIFEST_READ,
      }),
    );
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort -
${configurationUtils.getPageTitle(false, 'MANIFEST')} ${
            queryString.parse(location.search)['page']
              ? '- Page ' + queryString.parse(location.search)['page']
              : ''
          }`}
        </title>
      </Helmet>
      <Layout navCurrent='MANIFEST'>
        <ExportCSVDialog
          exportFilters={{
            manifestType:
              (selectedFilters?.type?.values[0]?.id as
                | 'inbound'
                | 'outbound') || undefined,
            owner: selectedFilters?.owner?.values?.length
              ? selectedFilters.owner.values.map((value) =>
                  Number(value.id),
                )
              : undefined,
          }}
          downloadName={'manifest_export'}
          type='MANIFESTS'
          isOpen={showBatchExportDialog}
          onAfterClose={() => {
            setShowBatchExportDialog(false);
          }}
        />
        <CreateInboundManifestDialog
          isOpen={showCreateInboundManifestDialog}
          onAfterClose={() => {
            setShowCreateInboundManifestDialog(false);
          }}
          updateParent={() => {
            setFilterLastUpdate(Date.now());
          }}
        />
        {showProgress && <ProgressIndicator />}

        <FiltersDrawer
          isOpen={showFiltersDrawer}
          onAfterClose={() => {
            setShowFiltersDrawer(false);
          }}
          getFilters={getDrawerFilters}
          removeFilter={filterToRemove}
        />

        {error && (
          <AlertBanner
            severity='error'
            alertTitle={'Error'}
            alertMsg={error}
            style={{ marginBottom: '20px' }}
          />
        )}

        <Grid container className={classes.header}>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.title} variant={'h3'}>
              {configurationUtils.getPageTitle()}
            </Typography>
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
            className={classes.nonMobileAlignRight}
          >
            {PermissionsService.hasPermission(
              StaffPermissionPermissionsEnum.MANIFEST_WRITE,
            ) && (
              <CSButton
                variant='contained'
                color='primary'
                className={clx(
                  classes.headerButtons,
                  classes.headerActionButton,
                )}
                onClick={() => {
                  setShowCreateInboundManifestDialog(true);
                }}
                startIcon={<CloudUploadOutlined />}
              >
                Import {manifestLabels.singular}
              </CSButton>
            )}
            {PermissionsService.hasPermission(
              StaffPermissionPermissionsEnum.MANIFEST_REPORT_READ,
            ) && (
              <CSButton
                variant='contained'
                color='primary'
                className={clx(
                  classes.headerButtons,
                  classes.headerActionButton,
                )}
                disabled={!batchExportEnabled}
                onClick={() => {
                  setShowBatchExportDialog(true);
                }}
                startIcon={<CloudDownloadOutlined />}
              >
                Batch Export CSVs
              </CSButton>
            )}
            <CSButton
              variant='outlined'
              color='secondary'
              className={clx(
                classes.headerButtons,
                classes.headerActionButton,
              )}
              onClick={() => {
                setShowFiltersDrawer(true);
              }}
            >
              <FilterListIcon />
            </CSButton>
          </Grid>
          <CSSectionTitleSeparator bottomMargin={24} />
          <Grid container item xs={12} spacing={1}>
            <CSHorizontalFilterBadgesGroup
              selectedFilters={selectedFilters}
              onRemoveFilter={(toRemove) =>
                setFilterToRemove(toRemove)
              }
            />
          </Grid>
        </Grid>

        {!!filterLastUpdate && (
          <PaginatedTable
            key={'table' + filterLastUpdate}
            title=''
            columns={COLUMNS}
            dataTestIdPrefix={'manifests'}
            fetch={fetch}
            rowsLoadDetailPages={true}
            detailsPageBasePath={AuthRoutes.MANIFEST.replace('/', '')}
            actions={[
              {
                tableLabel: ' ',
                columnLabel: <CloudDownloadOutlined />,
                callbackProperty: 'id',
                qualifier: 'total_packages',
                callback: downloadCSV,
              },
            ]}
            sortableBy={['created_on']}
            defaultSort='-created_on'
          />
        )}
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...sectionPageBaseStyle,
    ...getDetailsPageStylesWithTheme(theme),
    ...getFiltersStylesWithTheme(theme),
    nonMobileAlignRight: {
      [theme.breakpoints.up('xs')]: {
        textAlign: 'right',
      },
    },
    headerActionButton: {
      minWidth: '40px',
      marginLeft: '20px',
    },
    headerButtons: {
      margin: '0 0 10px 15px',
      [theme.breakpoints.down('sm')]: {
        margin: '0 15px 15px 0',
      },
    },
  })),
)(Manifests);
