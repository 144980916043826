import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import { TransitionProps } from '@material-ui/core/transitions';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { CSButton, Typography } from '../primitives';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';

export const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children?: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

interface Props {
  classes: { [key: string]: string };
  title?: string;
  msg?: string | React.ReactNode;
  primaryActionLabel?: string;
  onPrimaryAction?: () => void;
  secondaryActionLabel?: string;
  onSecondaryAction?: () => void;
  cancelLabel?: string;
  onCancel?: () => void;
  hideCancelButton?: boolean;
  isOpen: boolean;
  dataTestIdPrefix?: string;
}

const ConfirmationDialog: React.FC<Props> = ({
  classes,
  title,
  msg,
  primaryActionLabel = 'OK',
  cancelLabel = 'Cancel',
  isOpen,
  onCancel,
  hideCancelButton = false,
  onPrimaryAction,
  secondaryActionLabel,
  onSecondaryAction,
  dataTestIdPrefix = '',
  ...props
}) => {
  const [open, setOpen] = React.useState(false);
  const handleCancel = () => {
    setOpen(false);
    if (onCancel) {
      onCancel();
    }
  };

  const handlePrimaryAction = () => {
    setOpen(false);
    if (onPrimaryAction) {
      onPrimaryAction();
    }
  };

  const handleSecondaryAction = () => {
    setOpen(false);
    if (onSecondaryAction) {
      onSecondaryAction();
    }
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Dialog
      classes={{
        paper: isMdScreen ? classes.dialogExpanded : '',
      }}
      data-testid={`${dataTestIdPrefix}confirmation-dialog`}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        handleCancel();
        setOpen(false);
      }}
      {...props}
      style={{ zIndex: 2000 }}
    >
      <DialogTitle>
        <Grid
          container
          alignItems='center'
          justifyContent='space-between'
        >
          <Typography component='h3' variant='h5'>
            {title}
          </Typography>
          <CSButton
            className={classes.closeButton}
            onClick={handleCancel}
            color='quaternary'
          >
            <CloseIcon />
          </CSButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Typography
          component='p'
          variant='body1'
          color={{ color: 'text', variant: 'icon' }}
          className={classes.msg}
        >
          {msg}
        </Typography>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {!hideCancelButton && (
          <CSButton
            color='secondary'
            variant='outlined'
            onClick={handleCancel}
            data-testid={`${dataTestIdPrefix}confirmation-dialog-cancel`}
          >
            {cancelLabel}
          </CSButton>
        )}
        {secondaryActionLabel && (
          <CSButton
            color='secondary'
            variant='outlined'
            onClick={handleSecondaryAction}
            data-testid={`${dataTestIdPrefix}confirmation-dialog-secondary-action`}
          >
            {secondaryActionLabel}
          </CSButton>
        )}
        <CSButton
          color='secondary'
          variant='contained'
          onClick={handlePrimaryAction}
          data-testid={`${dataTestIdPrefix}confirmation-dialog-ok`}
        >
          {primaryActionLabel}
        </CSButton>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(
  createStyles(() => ({
    msg: {
      whiteSpace: 'pre-line',
    },
    dialogActions: {
      padding: 24,
    },
    dialogExpanded: {
      minWidth: 600,
    },
    closeButton: {
      minWidth: 'inherit',
      width: '30px',
      height: '30px',
      padding: 0,
    },
  })),
)(ConfirmationDialog);
