import React, { useState, useEffect } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import Layout from '../layout/Layout';
import {
  AlertBanner,
  CSButton,
  Typography,
} from '../primitives/index';
import ErrorHandler from '../../utils/ErrorHandler';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import PaginatedTable, {
  filterObj,
} from '../paginatedTable/PaginatedTable';
import { Column } from '../../interfaces/components';
import configurationUtils from '../../utils/configurationUtils';
import { Grid, Theme } from '@material-ui/core';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';
import clx from 'classnames';
import {
  InboundLoad,
  InboundLoadLoadStatusEnum,
  StaffPermissionPermissionsEnum,
} from 'cloudsort-client';
import EditIcon from '@material-ui/icons/Edit';
import FilterListIcon from '@material-ui/icons/FilterList';
import PermissionsService from '../../services/Permissions.service';
import InboundLoadsService from '../../services/InboundLoads.service';
import { AuthRoutes, ModulesKeys } from '../../interfaces/routes';
import sectionPageBaseStyle from '../commonStyles/sectionPageBase.style';
import FiltersDrawer, {
  SelectedFilters,
} from '../filtersDrawer/FiltersDrawer';
import filterBadgeStyle from '../filtersDrawer/filterBadge.style';
import InboundLoadDialog from './InboundLoadDialog';
import CSSectionTitleSeparator from '../primitives/csSectionTitleSeparator/CSSectionTitleSeparator';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';

import CSHorizontalFilterBadgesGroup from '../primitives/csHorizontalFilterBadgesGroup/csHorizontalFilterBadgesGroup';
import { FilterDescription } from '../primitives/csHorizontalFilterBadgesGroup/csHorizontalFilterBadgesGroupTypes';
import { AxiosError } from 'axios';
import useFormatILTable from './useFormatILTable';
import { useAppDispatch } from '../../redux/store';
import { setLastVisitedModule } from '../../redux/slices/navigationSlice';

interface Props {
  classes: { [key: string]: string };
  location: any;
}

const InboundLoads: React.FC<Props> = ({ classes, location }) => {
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [inboundLoadsData, setInboundLoadsData] = useState<
    InboundLoad[]
  >([]);
  const [showAddInboundLoadDialog, setShowAddInboundLoadDialog] =
    useState(false);
  const [selectedInboundLoadData, setSelectedInboundLoadData] =
    useState<InboundLoad>();
  const [inboundLoadDialogType, setInboundLoadDialogType] = useState<
    'add' | 'edit'
  >('add');
  const [showFiltersDrawer, setShowFiltersDrawer] = useState(false);
  const [selectedFilters, setSelectedFilters] =
    useState<SelectedFilters>();
  const [filterToRemove, setFilterToRemove] =
    useState<FilterDescription>();
  const [filterLastUpdate, setFilterLastUpdate] = useState<number>(0);
  const [lastUpdated, setLastUpdated] = useState<string>(
    new Date().toISOString(),
  );

  const dispatch = useAppDispatch();

  const COLUMNS: Column[] = [
    {
      id: 'inbound_load_time_formatted',
      label: 'Date',
      useCustomComponent: true,
    },
    {
      id: 'load_status_formatted',
      label: 'Status',
      useCustomComponent: true,
    },
    {
      id: 'name',
      label: 'Load ID',
    },
    {
      id: 'bol',
      label: 'BOL',
    },
    {
      id: 'origin_station',
      label: 'Origin',
    },
    {
      id: 'trailer_id',
      label: 'Trailer ID',
    },
    {
      id: 'owner_full_name',
      label: 'Owner',
    },
    {
      id: 'expected_containers_at_arrival',
      label: configurationUtils.getPageTitle(false, 'CONTAINER'),
    },
    {
      id: 'expected_packages_at_arrival',
      label: configurationUtils.getPageTitle(false, 'PACKAGE'),
    },
    {
      id: 'dockdoor_name',
      label: 'Unload Door',
    },
  ];

  const inboundLoadLabels = {
    singular: configurationUtils.getPageTitle(true, 'INBOUND_LOAD'),
    plural: configurationUtils.getPageTitle(false, 'INBOUND_LOAD'),
  };

  useEffect(() => {
    PermissionsService.redirectIfNoPermission(
      StaffPermissionPermissionsEnum.INBOUNDLOAD_READ,
    );

    dispatch(
      setLastVisitedModule({
        module: ModulesKeys.INBOUND_LOAD,
        permission: StaffPermissionPermissionsEnum.INBOUNDLOAD_READ,
      }),
    );
  }, [dispatch]);

  const getDrawerFilters = (filters: SelectedFilters) => {
    setSelectedFilters(filters);
    setFilterLastUpdate(Date.now());
  };

  const { formatIlItem } = useFormatILTable();

  const fetch = async (
    pageIndex: number,
    rowsPerPage: number,
    filterOptions?: filterObj[],
    filterByString?: string,
    sortedBy?: string,
  ) => {
    try {
      setShowProgress(true);
      let hidePending = undefined;
      let loadStatus = undefined;
      const owner: number[] = [];
      if (selectedFilters!.hidePending?.values?.length > 0) {
        hidePending =
          selectedFilters!.hidePending.values[0].id === 'yes';
      }
      if (selectedFilters!.inboundLoadStatus?.values?.length > 0) {
        loadStatus = selectedFilters!.inboundLoadStatus.values[0]
          .id as InboundLoadLoadStatusEnum;
      }
      if (selectedFilters!.owner?.values?.length > 0) {
        for (let selectedOwner of selectedFilters!.owner.values) {
          owner.push(selectedOwner.id as number);
        }
      }
      const res = await InboundLoadsService.getAll({
        page: pageIndex,
        pageSize: rowsPerPage,
        loadName: filterByString,
        hidePending: hidePending,
        inboundLoadStatus: loadStatus,
        owner: owner.length > 0 ? owner : undefined,
      });
      res.data.results.forEach((load) => {
        formatIlItem(load);
      });
      setInboundLoadsData(res.data.results);
      return res;
    } catch (e) {
      setError(await ErrorHandler.getLabel(e as AxiosError));
    } finally {
      setShowProgress(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort -
${configurationUtils.getPageTitle(false, 'INBOUND_LOAD')} ${
            queryString.parse(location.search)['page']
              ? '- Page ' + queryString.parse(location.search)['page']
              : ''
          }`}
        </title>
      </Helmet>
      <Layout navCurrent='INBOUND_LOAD'>
        {showProgress && <ProgressIndicator />}
        {error && (
          <AlertBanner
            className={classes.banner}
            severity='error'
            alertTitle={'Error'}
            alertMsg={error}
          />
        )}
        <InboundLoadDialog
          isOpen={showAddInboundLoadDialog}
          onAfterClose={() => setShowAddInboundLoadDialog(false)}
          updateParent={() => {
            setLastUpdated(new Date().toISOString());
          }}
          type={inboundLoadDialogType}
          data={selectedInboundLoadData}
        />
        <FiltersDrawer
          isOpen={showFiltersDrawer}
          onAfterClose={() => {
            setShowFiltersDrawer(false);
          }}
          getFilters={getDrawerFilters}
          removeFilter={filterToRemove}
        />
        <Grid
          container
          className={classes.header}
          alignItems='center'
        >
          <Grid item xs={12} sm={6}>
            <Typography
              className={classes.title}
              variant='h3'
              component='h2'
            >
              {inboundLoadLabels.plural}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
            {PermissionsService.hasPermission(
              StaffPermissionPermissionsEnum.INBOUNDLOAD_WRITE,
            ) && (
              <CSButton
                variant='contained'
                color={{
                  buttonColor: 'secondary',
                  iconColor: 'primary',
                }}
                onClick={() => {
                  setInboundLoadDialogType('add');
                  setShowAddInboundLoadDialog(true);
                }}
                startIcon={<AddBoxOutlinedIcon />}
              >
                Add {inboundLoadLabels.singular}
              </CSButton>
            )}
            <CSButton
              variant='outlined'
              color='secondary'
              className={clx(
                classes.headerButtons,
                classes.headerFilterButton,
              )}
              onClick={() => {
                setShowFiltersDrawer(true);
              }}
            >
              <FilterListIcon />
            </CSButton>
          </Grid>
          <CSSectionTitleSeparator topMargin={5} bottomMargin={24} />
          <Grid container item xs={12} spacing={1}>
            <CSHorizontalFilterBadgesGroup
              selectedFilters={selectedFilters}
              onRemoveFilter={(toRemove) =>
                setFilterToRemove(toRemove)
              }
            />
          </Grid>
        </Grid>
        {!!filterLastUpdate && (
          <PaginatedTable
            key={lastUpdated + filterLastUpdate}
            title=''
            columns={COLUMNS}
            dataTestIdPrefix={'inbound-loads-list'}
            fetch={fetch}
            filterByString={true}
            filterByStringPlaceholder={'Search by Load ID'}
            rowsLoadDetailPages={true}
            detailsPageBasePath={AuthRoutes.INBOUND_LOAD.replace(
              '/',
              '',
            )}
            actions={[
              {
                cellWidth: 70,
                tableLabel: PermissionsService.hasPermission(
                  StaffPermissionPermissionsEnum.INBOUNDLOAD_WRITE,
                )
                  ? ' '
                  : undefined,
                columnLabel: PermissionsService.hasPermission(
                  StaffPermissionPermissionsEnum.INBOUNDLOAD_WRITE,
                ) ? (
                  <EditIcon />
                ) : undefined,
                callbackProperty: 'id',
                qualifier: 'id',
                callback: (id: number) => {
                  const inboundLoad = inboundLoadsData.find(
                    (load) => load.id === id,
                  );
                  setInboundLoadDialogType('edit');
                  setSelectedInboundLoadData(inboundLoad);
                  setShowAddInboundLoadDialog(true);
                },
              },
            ]}
          />
        )}
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...sectionPageBaseStyle,
    ...filterBadgeStyle,
    headerFilterButton: {
      minWidth: '40px',
      marginLeft: '20px',
    },
  })),
)(InboundLoads);
