import React, { useEffect, useState } from 'react';
import {
  withStyles,
  createStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import { CSButton, Typography } from '../../primitives';
import ProgressIndicator from '../../progressIndicator/ProgressIndicator';
import Layout from '../../layout/Layout';
import { AlertBanner } from '../../primitives';
import ErrorHandler from '../../../utils/ErrorHandler';

import Paper from '@material-ui/core/Paper';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import browserHistory from '../../../utils/browserHistory';
import { Grid } from '@material-ui/core';
import { AuthRoutes } from '../../../interfaces/routes';

// Types
import { Module } from 'cloudsort-client';
import { AxiosError } from 'axios';

// Services
import ZoneModulesService from '../../../services/ZoneModules.service';
import { Helmet } from 'react-helmet';
import configurationUtils from '../../../utils/configurationUtils';
import CSSectionTitleSeparator from '../../primitives/csSectionTitleSeparator/CSSectionTitleSeparator';
import clsx from 'clsx';
interface Props {
  classes: { [key: string]: string };
  match: any;
}

const AddCorporateModule: React.FC<Props> = ({ classes, match }) => {
  const [modules, setModules] = useState<Module[]>();

  const [error, setError] = useState<string>();
  const [showProgress, setShowProgress] = useState<boolean>(false);

  const fetchModules = async () => {
    setShowProgress(true);
    try {
      const data = (
        await ZoneModulesService.getAllCorporateModules(
          undefined,
          match.params.areaType,
          'load_point_count',
          20,
        )
      ).data.results;
      setModules(data);
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  const addToTheZone = async (moduleId: number) => {
    setShowProgress(true);
    try {
      await ZoneModulesService.assignModuleToAZone(
        match.params.zoneId,
        moduleId,
      );
      setTimeout(() => {
        browserHistory.push(
          `${AuthRoutes.AREA}/${match.params.id}/settings/${match.params.areaType}`,
        );
      }, 0);
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e as AxiosError));
  };

  const theme = useTheme();
  const inXsScreen = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    fetchModules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort -
${configurationUtils.getPageTitle(
  true,
  'AREA',
)} - Add Corporate Module`}
        </title>
      </Helmet>
      <Layout navCurrent='AREA'>
        {showProgress && <ProgressIndicator />}
        {error && (
          <AlertBanner
            severity='error'
            alertTitle={'Error'}
            alertMsg={error}
          />
        )}{' '}
        <Typography component='h1' variant='h3'>
          Add Corporate Module
        </Typography>
        <CSSectionTitleSeparator topMargin={16} />
        <Typography variant='h6'>
          Please select a module to start
        </Typography>
        <Grid
          container
          spacing={3}
          className={classes.cardsContainer}
        >
          {modules?.length
            ? modules?.map((module: Module) => (
                <Grid
                  key={module.id}
                  className={classes.flexItem}
                  item
                  lg={4}
                  md={6}
                  xs={12}
                >
                  <Paper
                    className={classes.paper}
                    style={{
                      marginBottom: inXsScreen ? 10 : 0,
                      height: inXsScreen ? 110 : 'auto',
                    }}
                  >
                    <span className={classes.loadPointCount}>
                      {module.load_point_count}
                    </span>
                    <Typography
                      className={clsx(
                        classes.alignLeft,
                        classes.marginTop,
                      )}
                      variant='h5'
                    >
                      {module.name}
                    </Typography>
                    <Typography
                      className={classes.alignLeft}
                      variant='subtitle1'
                      color={{ color: 'text', variant: 'icon' }}
                    >
                      {module.load_point_count} Loadpoints
                    </Typography>
                    <CSButton
                      variant='outlined'
                      color='secondary'
                      data-testid={'add-corporate-module-button'}
                      onClick={(e) => {
                        addToTheZone(module.id!);
                      }}
                    >
                      Select
                    </CSButton>
                  </Paper>
                </Grid>
              ))
            : modules?.length === 0 && (
                <AlertBanner
                  severity='info'
                  alertTitle={
                    'There is no information to display at the moment'
                  }
                />
              )}
        </Grid>
        <CSButton
          variant='contained'
          color='secondary'
          className={classes.cancelButton}
          onClick={() => {
            browserHistory.push({
              pathname: `${AuthRoutes.AREA}/${match.params.id}/settings/${match.params.areaType}`,
            });
          }}
        >
          Cancel
        </CSButton>
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    cardsContainer: {
      marginTop: 30,
    },
    loadPointCount: {
      float: 'left',
      fontSize: '22px',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      borderRadius: '50%',
      width: '40px',
      lineHeight: '40px',
      textAlign: 'center',
      margin: '10px',
      letterSpacing: '0',
      fontWeight: 'bold',
    },
    alignLeft: { textAlign: 'left' },
    marginTop: {
      marginTop: 6,
    },
    cancelButton: {
      marginTop: '20px',
    },
    paper: {
      padding: '10px',
      textAlign: 'right',
    },
  })),
)(AddCorporateModule);
