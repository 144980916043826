import MuiButton, { ButtonProps } from '@material-ui/core/Button';
import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './button.styles';

export interface IButtonProps extends ButtonProps {
  classes: { [key: string]: string };
}

const Button: React.FC<IButtonProps> = ({
  children,
  classes,
  className,
  ...MuiProps
}) => {
  return (
    <MuiButton
      className={`${classes.mainStyle} ${className}`}
      {...MuiProps}
    >
      {children}
    </MuiButton>
  );
};

Button.defaultProps = {
  type: 'submit',
  fullWidth: true,
  variant: 'contained',
  color: 'primary',
};

export default withStyles(styles)(Button);
