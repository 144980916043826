import { createStyles, Theme } from '@material-ui/core/styles';

export default createStyles((theme: Theme) => ({
  heading: {
    fontSize: theme.typography.label?.fontSize,
    fontFamily: theme.typography.label?.fontFamily,
    textTransform: 'uppercase',
    lineHeight: 1,
    letterSpacing: '1px !important',
    padding: '8px 0 0 0px',
  },

  sectionDivider: {
    backgroundColor: theme.palette.grey[700],
    marginTop: 10,
  },

  // menu classes
  menuList: {
    height: '100%',
    width: '100%',
    padding: 0,
  },

  topLevelMenuItem: {
    padding: '9px 20px!important',
    '& > svg': {
      margin: '0 8px -8px 0',
    },
  },
  menuItem: {
    padding: '12px 45px',
    color: theme.palette.quaternary?.contrastText,
    fontSize: theme.typography.body2.fontSize,
    fontFamily: theme.typography.body2.fontFamily,
    letterSpacing: 0,
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.secondary.main,
    },
  },
  current: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
  },
  // accordion classes
  accordionRoot: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.quaternary?.main,
    boxShadow: 'none',
  },

  accordionExpanded: {
    margin: `0 !important`,
  },
  // accordion summary classes
  accordionSummary: {
    flexDirection: 'row-reverse',
    minHeight: '0!important',
    padding: '0 20px',
    marginTop: 10,
    '& svg': {
      margin: '2px 10px 0 0',
    },
  },
  accordionSummaryContent: {
    margin: '10px 0',
    '&.Mui-expanded': {
      margin: '10px 0',
    },
  },

  accordionSummaryExpandIcon: {
    padding: 0,
    margin: 0,
  },
  expandMoreIcon: {
    margin: `0 !important`,
    color: theme.palette.quaternary?.contrastText,
  },

  //accordion details classes
  accordionDetailsRoot: {
    padding: 0,
  },
}));
