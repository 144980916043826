import { Theme, makeStyles } from '@material-ui/core';
import { SeparatorWidth } from './CSSectionTitleSeparatorTypes';

export default (
  borderWidth: SeparatorWidth = 'border_sm',
  bottomMargin: Number = 20,
  topMargin: Number = 0,
) =>
  makeStyles(
    (theme: Theme) => ({
      root: {
        width: '100%',
        border: `${theme.borderWidth[borderWidth]} solid ${theme.palette.grey[300]}`,
        marginBottom: `${bottomMargin}px`,
        marginTop: `${topMargin}px`,
      },
    }),
    { name: 'CSSectionTitleSeparator' },
  );
