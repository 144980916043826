import { Button, useTheme, withStyles } from '@material-ui/core';
import * as React from 'react';
import styles from './CSButton.styles';
import {
  CSButtonProps,
  ButtonExtendedColors,
  knownButtonColors,
  MaterialButtonColor,
  ButtonWithIconColor,
} from './CSButtonTypes';

const toMaterialButtonColor = (
  color: ButtonExtendedColors | ButtonWithIconColor,
): MaterialButtonColor =>
  typeof color === 'string' && knownButtonColors.includes(color)
    ? (color as MaterialButtonColor)
    : 'inherit';

const CSButton: React.FC<CSButtonProps> = React.forwardRef(
  (
    {
      color = 'primary',
      onClick,
      disabled,
      className,
      children,
      height = 'auto',
      ...MuiProps
    },
    ref,
  ) => {
    const theme = useTheme();
    const MaterialButton = React.useMemo(
      () =>
        withStyles(styles(theme, color, height), {
          name: 'CSButton',
        })(Button),
      [theme, color, height],
    );
    return (
      <MaterialButton
        className={`${className}`}
        color={toMaterialButtonColor(color)}
        fullWidth={false}
        onClick={disabled ? undefined : onClick}
        disabled={disabled}
        ref={ref}
        {...MuiProps}
      >
        {children}
      </MaterialButton>
    );
  },
);
CSButton.displayName = 'CSButton';
export default CSButton;
