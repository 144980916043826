import React, { useEffect, useState } from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { AuthRoutes } from '../../interfaces/routes';
import { Typography, AlertBanner, CSButton } from '../primitives';
import Paper from '@material-ui/core/Paper';
import Layout from '../layout/Layout';
import Box from '@material-ui/core/Box';
import {
  humanReadableNull,
  renderVolumeString,
  renderWeightString,
  renderContainerLocationString,
} from '../DetailsPagesFunctions';

import {
  ContainerDetails,
  StaffPermissionPermissionsEnum,
} from 'cloudsort-client';
import PaginatedTable, {
  filterObj,
} from '../paginatedTable/PaginatedTable';
import EventsPaginatedTable from '../eventsPaginatedTable/EventsPaginatedTable';
import ProcessSteps from '../eventsSnapshot/processSteps/ProcessSteps';

import detailsPageStyles from '../commonStyles/detailsPage.style';
import SingleDetail from '../primitives/singleDetail/SingleDetail';
import colors from '../../utils/colors';
import { COLUMNS_PACKAGES } from '../TableColumns';
import { Column } from '../../interfaces/components';
import configurationUtils from '../../utils/configurationUtils';
import { common } from '../../utils/strings';

// Services
import ContainersService from '../../services/Containers.service';
import PackagesService from '../../services/Packages.service';
import EventsService from '../../services/Events.service';
import PermissionsService from '../../services/Permissions.service';

import ErrorHandler from '../../utils/ErrorHandler';
import { AxiosError } from 'axios';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import utils from '../../utils/stats';
import qrCodeUtils from '../../utils/qrCode';
import { Helmet } from 'react-helmet';

// Icons
import FilterCenterFocusIcon from '@material-ui/icons/FilterCenterFocus';
import { Grid, GridSize } from '@material-ui/core';
import { formatLabel, formatContainer } from './Containers';
import { labelConfigOpt } from '../../services/utils/labelDefinitions';
import CSSectionTitleSeparator from '../primitives/csSectionTitleSeparator/CSSectionTitleSeparator';
import { CSSingleDetailMultiColumnContainer } from '../primitives/singleDetail/singleDetailMultiColumnContainer';
import { CSSingleDetailMonoColumnContainer } from '../primitives/singleDetail/singleDetailMonoColumnContainer';
import CSBreadcrumbs from '../primitives/CSBreadcrumbs/CSBreadcrumbs';
import { TypographyColors } from '../primitives/typography/TypographyTypes';
import { CSLink } from '../primitives/csLink';
import CSTimeContainer from '../primitives/csTimeContainer/CSTimeContainer';
import useStationId from '../../hooks/useStationId';

interface Props {
  classes: { [key: string]: string };
  match: any;
}

const ContainerDetailsComponent: React.FC<Props> = ({
  classes,
  match,
}) => {
  const thisContainersId = match.params.id;
  const [container, setContainer] = useState<ContainerDetails>();
  const [error, setError] = useState<string>();
  const [showProgress, setShowProgress] = useState<boolean>(false);
  // It's possible for a user to open container details page without station id in the url.
  //In this case we need to get it from the container data and set it in the url. The web app (navigation bar) will use it to display the station name and show the correct navigation menu.
  useStationId(container?.station);

  const COLUMNS_CONTAINERS: Column[] = [
    {
      id: 'id',
      label: 'ID',
      width: 50,
      align: 'left',
    },
    {
      id: 'tracking_number',
      label: 'Tracking Number',
      width: 'auto',
      align: 'center',
    },
    {
      id: 'type',
      label: 'Type',
      width: 200,
      align: 'left',
      useCustomComponent: true,
    },
    {
      id: 'display_status',
      label: 'Status',
      width: 'auto',
      align: 'left',
      useCustomComponent: true,
    },
    {
      id: 'critical_activity_time',
      label: 'Critical Time',
      align: 'center',
      width: 'left',
    },
    {
      id: 'package_count',
      label: configurationUtils.getPageTitle(false, 'PACKAGE'),
      width: 'auto',
      align: 'left',
    },
    {
      id: 'volume',
      label: 'Volume',
      width: 'auto',
      align: 'left',
    },
    {
      id: 'container_type',
      label: `Type of ${configurationUtils.getModuleLabel(
        true,
        'CONTAINER',
      )}`,
      width: 'auto',
      align: 'left',
    },
    {
      id: 'dwell_time',
      label: 'Dwell time',
      align: 'center',
      width: 'auto',
    },
    {
      id: 'location',
      label: 'Location',
      align: 'center',
      width: 'left',
    },
  ];

  //TODO: Update this when CCC is merged
  const isBOLActive = true;
  const isBOLInherit = false;

  const fetchContainerDetails = async () => {
    setShowProgress(true);
    try {
      const { data } = await ContainersService.getById(
        thisContainersId,
      );
      setContainer(data);
    } catch (e) {
      handleError(e as AxiosError);
    }
    setShowProgress(false);
  };

  const fetchPackages = async (
    pageIndex: number,
    rowsPerPage: number,
  ) => {
    const res = await PackagesService.getAll({
      page: pageIndex,
      container: [thisContainersId],
      pageSize: rowsPerPage,
      fromDate: '',
      toDate: '',
    });
    res.data.results.forEach((pck: any) => {
      pck.process_step_time = utils.getLongestDwellTimeLabel(
        pck.process_step_time,
      );
    });
    return res;
  };

  const fetchContainers = async (
    pageIndex: number,
    rowsPerPage: number,
    filterOptions?: filterObj[],
    filterByString?: string,
    sortedBy?: string,
  ) => {
    const res = await ContainersService.getAll({
      page: pageIndex,
      pageSize: rowsPerPage,
      parentContainer: container!.id,
    });

    res.data.results.forEach((container: any) => {
      formatContainer(container);
    });

    return res;
  };

  const fetchEvents = async (
    pageIndex: number,
    rowsPerPage: number,
  ) => {
    return EventsService.getAll({
      page: pageIndex,
      container: thisContainersId,
      pageSize: rowsPerPage,
    });
  };

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e as AxiosError));
  };

  const onGetQrCick = async () => {
    setShowProgress(true);
    try {
      if (container?.id) {
        const labelOTConfig = await labelConfigOpt();
        qrCodeUtils.download(
          await ContainersService.getLabel(
            container.id,
            labelOTConfig.size,
            labelOTConfig.dpi,
          ),
        );
      }
    } catch (e) {
      handleError(e as AxiosError);
    }
    setShowProgress(false);
  };

  const updateContainerDetailsData = async (
    newData: ContainerDetails,
  ) => {
    setShowProgress(true);
    try {
      const { data } = await ContainersService.update(
        container?.id!,
        newData,
      );
      setContainer(data);
    } catch (e) {
      handleError(e as AxiosError);
    }
    setShowProgress(false);
  };

  useEffect(() => {
    PermissionsService.redirectIfNoPermission(
      StaffPermissionPermissionsEnum.CONTAINER_READ,
    );

    if (!container) {
      fetchContainerDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const commonSingleDetailConf = {
    inline: true,
    valueColor: 'secondary' as TypographyColors,
  };

  const commonColumnSingleDetail = {
    labelColumns: 5 as GridSize,
    valueColumns: 7 as GridSize,
  };

  const areaDetail =
    container?.area && container.area.area_type !== 'PRIMARY'
      ? [
          <SingleDetail
            label='Location'
            value={renderContainerLocationString(
              container?.station_name,
              container?.area?.name,
              container?.zone?.name,
              container?.container_status,
              container?.load_point?.name,
            )}
            {...commonSingleDetailConf}
          />,
          <SingleDetail
            label='Target Load'
            value={
              container?.next_steps?.outbound_load?.id ? (
                <CSLink
                  to={`${AuthRoutes.OUTBOUND_LOAD}/${container.next_steps.outbound_load.id}`}
                >
                  {humanReadableNull(
                    container?.next_steps?.outbound_load?.name,
                  )}
                </CSLink>
              ) : (
                'N/A'
              )
            }
            {...commonSingleDetailConf}
          />,
          <SingleDetail
            label={
              'Target ' +
              configurationUtils.getPageTitle(true, 'STOP')
            }
            value={
              container?.route ? (
                <CSLink
                  to={`${AuthRoutes.STOP}/?search=${container?.stop_name}`}
                >
                  {humanReadableNull(container?.stop_name)}
                </CSLink>
              ) : (
                'N/A'
              )
            }
            {...commonSingleDetailConf}
          />,
        ]
      : [];

  const containerDetailsDetail = [
    <SingleDetail
      label='Status'
      value={
        container?.last_process_step
          ? formatLabel(container.last_process_step || '')
          : common.emptyValue
      }
      {...commonSingleDetailConf}
    />,
    <SingleDetail
      label={'Tracking Number'}
      value={container?.tracking_number || common.emptyValue}
      {...commonSingleDetailConf}
    />,
    <SingleDetail
      label={configurationUtils.getPageTitle(true, 'INBOUND_LOAD')}
      value={
        container?.inbound && container.inbound_load ? (
          <CSLink
            to={`${AuthRoutes.INBOUND_LOAD}/${container.inbound_load}`}
          >
            {humanReadableNull(container.inbound_load_name)}
          </CSLink>
        ) : (
          container?.inbound_load_name || common.emptyValue
        )
      }
      {...commonSingleDetailConf}
    />,
    <SingleDetail
      label={configurationUtils.getPageTitle(true, 'ROUTE')}
      value={
        container?.route ? (
          <CSLink to={`${AuthRoutes.ROUTE}/${container?.route}`}>
            {humanReadableNull(container?.route_name)}
          </CSLink>
        ) : (
          'N/A'
        )
      }
      {...commonSingleDetailConf}
    />,
    isBOLActive ? (
      <SingleDetail
        onEdit={
          !isBOLInherit
            ? (value) => {
                if (container?.bol !== value) {
                  //API Returns error when updating with the same value.
                  updateContainerDetailsData({
                    bol: value,
                  });
                }
              }
            : undefined
        }
        label={'BOL'}
        value={container?.bol || common.emptyValue}
        {...commonSingleDetailConf}
      />
    ) : undefined,
    <SingleDetail //TODO: Once CLOUD-3284 is completed, we will have a way to know for sure when this field can be hidden/shown this field.
      label='AWB'
      onEdit={(value: string) => {
        if (container?.awb !== value) {
          //API Returns error when updating with the same value.
          updateContainerDetailsData({
            awb: value,
          });
        }
      }}
      value={container?.awb || common.emptyValue}
      {...commonSingleDetailConf}
    />,
    container?.is_parent_container ? (
      <SingleDetail
        label={`${configurationUtils.getModuleLabel(
          false,
          'GROUP_CONTAINER',
        )} ${configurationUtils.getPageTitle(false, 'CONTAINER')}`}
        value={container.children_count || common.emptyValue}
        {...commonSingleDetailConf}
      />
    ) : undefined,
    ...areaDetail,
  ];

  const pageTitle = `${
    container?.is_parent_container
      ? configurationUtils.getModuleLabel(true, 'GROUP_CONTAINER')
      : configurationUtils.getPageTitle(true, 'CONTAINER')
  } ${container?.id || ''}`;
  return (
    <>
      <Helmet>
        <title>
          {`CloudSort -
      ${configurationUtils.getPageTitle(true, 'CONTAINER')} ${
            container?.id || ''
          }`}
        </title>
      </Helmet>
      <Layout navCurrent='CONTAINER'>
        {showProgress && <ProgressIndicator />}

        {error && (
          <AlertBanner
            className={classes.banner}
            severity='error'
            alertTitle={'Error'}
            alertMsg={error}
          />
        )}

        {!container?.is_parent_container && (
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <Box mb={5}>
                <CSBreadcrumbs
                  breadcrumbs={[
                    {
                      label: 'Containers',
                      link: AuthRoutes.CONTAINER,
                    },
                    {
                      label: `${pageTitle}`,
                      selected: true,
                    },
                  ]}
                />
              </Box>
            </Grid>
          </Grid>
        )}
        <Grid
          container
          spacing={1}
          style={{ marginBottom: 8 }}
          data-testid='container-detail-view'
          alignItems='center'
        >
          <Grid item xs={12} sm={6}>
            <Typography variant='h3' component='h2'>
              {pageTitle}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
            {PermissionsService.hasPermission(
              StaffPermissionPermissionsEnum.CONTAINER_LABEL_READ,
            ) && (
              <CSButton
                variant='contained'
                color='primary'
                disabled={!container}
                onClick={(e) => {
                  e.preventDefault();
                  setError(undefined);
                  onGetQrCick();
                }}
                startIcon={<FilterCenterFocusIcon />}
              >
                label
              </CSButton>
            )}
          </Grid>
          <Grid item xs={12}>
            <CSSectionTitleSeparator topMargin={8} />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginBottom: 8 }}>
          <Grid item xs={12} sm={6} md={8}>
            <CSSingleDetailMultiColumnContainer
              elements={containerDetailsDetail}
              data-testid='container-detail-details'
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <CSTimeContainer
              elements={[
                {
                  title: 'Target Load Time',
                  content: container?.next_steps?.outbound_load
                    ?.load_time
                    ? new Date(
                        container?.next_steps?.outbound_load?.load_time,
                      ).toLocaleTimeString('en-US', {
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true,
                      })
                    : common.emptyValue,
                },
                {
                  title: 'Time Left',
                  content:
                    container?.next_steps?.outbound_load?.load_time &&
                    new Date() <
                      new Date(
                        container?.next_steps?.outbound_load?.load_time,
                      )
                      ? utils.getLongestDwellTimeLabel(
                          container?.next_steps?.outbound_load
                            ?.load_time,
                        )
                      : common.emptyValue,
                },
              ]}
            />
          </Grid>
        </Grid>

        {!container?.is_parent_container && (
          <Grid container spacing={2} style={{ marginBottom: 8 }}>
            <Grid item xs={12} sm={6}>
              <Paper
                className={classes.paper}
                style={{ marginRight: 18, padding: 16 }}
              >
                <CSSingleDetailMonoColumnContainer
                  header={`${configurationUtils.getPageTitle(
                    true,
                    'CONTAINER',
                  )} Details`}
                  elements={[
                    <SingleDetail
                      label={`Containerized ${configurationUtils.getPageTitle(
                        false,
                        'PACKAGE',
                      )}`}
                      value={humanReadableNull(
                        container?.package_count,
                      )}
                      {...commonColumnSingleDetail}
                    />,
                    <SingleDetail
                      label='Volume'
                      value={renderVolumeString(container?.volume)}
                      {...commonColumnSingleDetail}
                    />,
                    <SingleDetail
                      label='Weight'
                      value={renderWeightString(container?.weight)}
                      {...commonColumnSingleDetail}
                    />,
                  ]}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper
                className={classes.paper}
                style={{ padding: 16 }}
              >
                <CSSingleDetailMonoColumnContainer
                  header={`${configurationUtils.getPageTitle(
                    true,
                    'CONTAINER',
                  )} Details`}
                  elements={[
                    <SingleDetail
                      label='Target Staging Area'
                      value={humanReadableNull(
                        container?.next_steps?.staging_area?.name,
                      )}
                      {...commonColumnSingleDetail}
                    />,
                    <SingleDetail
                      label='Type'
                      value={
                        container?.outbound ? 'Outbound' : 'Inbound'
                      }
                      {...commonColumnSingleDetail}
                    />,
                    <SingleDetail
                      label='Format'
                      value={formatLabel(
                        humanReadableNull(container?.container_type),
                      )}
                      {...commonColumnSingleDetail}
                    />,
                  ]}
                />
              </Paper>
            </Grid>
          </Grid>
        )}

        <Typography
          variant='h6'
          component='h2'
          className={classes.titleSpacing}
        >
          Event Checkpoints
        </Typography>
        {container?.process_steps_history ? (
          <ProcessSteps
            stepsData={container?.process_steps_history}
            dataTestPrefix='container'
          />
        ) : (
          <AlertBanner
            className={classes.banner}
            severity='info'
            alertTitle={
              'There is no information to display at the moment'
            }
          />
        )}

        <br />
        <EventsPaginatedTable
          dataTestIdPrefix={'container-details-events'}
          fetch={fetchEvents}
        />

        <br />
        <PaginatedTable
          disableUpdateQueryStringUrl
          title={configurationUtils.getPageTitle(false, 'PACKAGE')}
          columns={COLUMNS_PACKAGES}
          dataTestIdPrefix={'container-details-packages'}
          fetch={fetchPackages}
          rowsLoadDetailPages={true}
          detailsPageBasePath={AuthRoutes.PACKAGE}
        />

        {container?.is_parent_container && (
          <>
            <br />
            <PaginatedTable
              disableUpdateQueryStringUrl
              title={`${configurationUtils.getPageTitle(
                false,
                'CONTAINER',
              )}`}
              columns={COLUMNS_CONTAINERS}
              dataTestIdPrefix={'nested-container-details-containers'}
              fetch={fetchContainers}
              rowsLoadDetailPages={true}
              detailsPageBasePath={AuthRoutes.CONTAINER}
            />
          </>
        )}
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles(() => ({
    ...detailsPageStyles,
    padding2em: {
      padding: '2em',
    },
    flexRowEvenSpread: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
    half: {
      width: '50%',
      borderRight: '1px solid #fafafa',
    },
    containerDetails: {
      display: 'flex',
      flexDirection: 'row',
    },
    tableHeader: {
      background: '#fafafa',
    },
    back: {
      position: 'relative',
      left: '-8px',
      display: 'flex',
      color: colors.darkGold,
      cursor: 'pointer',
      width: 70,
      '&:hover': {
        textDecoration: 'none',
      },
    },
  })),
)(ContainerDetailsComponent);
