import {
  OrganizationsApi,
  Organization,
  PatchOrganizationRoles,
} from 'cloudsort-client';
import axios from '../utils/axios';
import { MAX_PAGE_SIZE } from './utils/constants';

class Organizations {
  private api: OrganizationsApi;

  constructor() {
    this.api = new OrganizationsApi(
      undefined,
      undefined,
      axios as any,
    );
  }

  public async getAll(
    page?: number,
    pageSize?: number,
    search?: string,
  ) {
    return this.api.organizationsList({ page, pageSize, search });
  }

  public async getAllSearch(search?: string) {
    return this.api.organizationsList({
      page: 1,
      pageSize: MAX_PAGE_SIZE,
      search,
    });
  }

  public async getById(id: number) {
    return this.api.organizationsRead({
      id,
    });
  }

  public async edit(data: Organization) {
    return this.api.organizationsPartialUpdate({
      id: data.id!,
      data,
    });
  }

  public async getRoles(id: number) {
    return this.api.organizationsPermissionsRead({
      id,
    });
  }

  public async updateOrgRoles(
    id: number,
    data: PatchOrganizationRoles,
  ) {
    return this.api.organizationsPermissionsPartialUpdate({
      id,
      data,
    });
  }

  public async create(data: Organization) {
    return this.api.organizationsCreate({
      data,
    });
  }
}

export default new Organizations();
