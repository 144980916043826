import { Grid, Typography } from '@material-ui/core';
import { piped } from 'rambdax';
import * as React from 'react';
import CSSectionTitleSeparator from '../../csSectionTitleSeparator/CSSectionTitleSeparator';
import { addKeys } from '../singleDetailUtils';
import { CSSingleDetailMonoColumnContainerProps } from './CSSingleDetailMonoColumnContainerTypes';

const CSSingleDetailMonoColumnContainer: React.FC<CSSingleDetailMonoColumnContainerProps> =
  ({ elements, header, spacing = 2 }) => {
    const toRender = piped(
      elements,
      (ele) => ele.filter((e) => e),
      (ele) => ele.flat(),
      (ele) => addKeys(ele),
    );
    return (
      <Grid container spacing={spacing}>
        <Grid item xs={12}>
          <Typography variant='h6' component='h2'>
            {header}
          </Typography>
          <CSSectionTitleSeparator
            borderWidth='border_thin'
            bottomMargin={0}
            topMargin={5}
          />
        </Grid>
        <Grid item xs={12}>
          {toRender}
        </Grid>
      </Grid>
    );
  };

export default CSSingleDetailMonoColumnContainer;
