import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '../primitives';
import Paper from '@material-ui/core/Paper';
import {
  createStyles,
  Grid,
  Theme,
  Tooltip,
} from '@material-ui/core';
import detailsPageStyle from '../commonStyles/detailsPage.style';
import CircularProgressWithLabel from '../primitives/circularProgressWithLabel/CircularProgressWithLabel';
import clsx from 'clsx';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { renderVolumeString } from '../DetailsPagesFunctions';

interface Props {
  classes: { [key: string]: string };
  title: string;
  packages: number | undefined;
  volume: number | undefined;
  showPercentage?: boolean;
  packagesPercentage?: number;
  volumePercentage?: number;
}

const PackagesCountVolumeBlock: React.FC<Props> = ({
  classes,
  title,
  packages,
  volume,
  showPercentage = false,
  packagesPercentage,
  volumePercentage,
}) => {
  const renderPercentageBlock = (
    tooltipText: string,
    percentage: number = 0,
  ) => (
    <Grid item xs={4} className={classes.alignRight}>
      {percentage <= 100 ? (
        <CircularProgressWithLabel value={percentage} />
      ) : (
        <Tooltip
          placement='top'
          arrow
          classes={{ tooltip: classes.tooltip }}
          title={`${tooltipText} (${percentage}%)`}
        >
          <ReportProblemOutlinedIcon
            className={classes.warningIcon}
          />
        </Tooltip>
      )}
    </Grid>
  );

  return (
    <Paper className={clsx(classes.paperPadding, classes.paper)}>
      <Grid container>
        <Grid item xs={12}>
          <Typography
            component='h3'
            variant='subtitle1'
            color={{ color: 'text', variant: 'secondary' }}
          >
            {title}
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={6}
          className={clsx(classes.gridItem, classes.leftColumn)}
        >
          <Grid item xs={showPercentage ? 8 : 12}>
            <Typography
              component='p'
              variant='subtitle1'
              color={{
                color: 'grey',
                variant: 'A400',
              }}
            >
              Total{' '}
              {showPercentage && (
                <Tooltip
                  placement='top'
                  arrow
                  classes={{ tooltip: classes.tooltip }}
                  title='Percentage of arrived out of the predicted.'
                >
                  <InfoOutlinedIcon className={classes.infoIcon} />
                </Tooltip>
              )}
            </Typography>
            <Typography component='p' variant='body1'>
              {packages}
            </Typography>
          </Grid>
          {showPercentage &&
            renderPercentageBlock(
              'More packages arrived than what our model predicted',
              packagesPercentage,
            )}
        </Grid>
        <Grid
          item
          container
          xs={6}
          className={clsx(classes.gridItem, classes.rightColumn)}
        >
          <Grid
            xs={showPercentage ? 8 : 12}
            className={clsx(!showPercentage && classes.alignRight)}
          >
            <Typography
              component='p'
              variant='subtitle1'
              color={{
                color: 'grey',
                variant: 'A400',
              }}
            >
              Volume{' '}
              {showPercentage && (
                <Tooltip
                  placement='top'
                  arrow
                  classes={{ tooltip: classes.tooltip }}
                  title='Percentage of arrived out of the predicted.'
                >
                  <InfoOutlinedIcon className={classes.infoIcon} />
                </Tooltip>
              )}
            </Typography>
            <Typography component='p' variant='body1'>
              {renderVolumeString(volume)}
            </Typography>
          </Grid>
          {showPercentage &&
            renderPercentageBlock(
              'Total volume of all packages is larger than what our model predicted',
              volumePercentage,
            )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyle,
    gridItem: {
      margin: '10px 0',
    },
    leftColumn: { paddingRight: 10 },
    rightColumn: {
      paddingLeft: 10,
      borderLeft: `1px solid ${theme.palette.grey[300]}`,
    },
    alignRight: { textAlign: 'right' },
    paperPadding: {
      padding: '13px 20px',
    },
    infoIcon: {
      fontSize: 9,
    },
    tooltip: {
      backgroundColor: theme.palette.secondary.main,
      maxWidth: 100,
      borderRadius: theme.shape.borderRadius_sm,
    },
    warningIcon: {
      fontSize: 34,
      color: theme.palette.warning.main,
    },
  })),
)(PackagesCountVolumeBlock);
