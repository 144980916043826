import { Grid, GridSize, Paper } from '@material-ui/core';
import chunk from 'lodash/chunk';
import { piped } from 'rambdax';
import * as React from 'react';
import { CSSingleDetailMultiColumnContainerProps } from './CSSingleDetailMultiColumnContainerTypes';
import styles from './CSSingleDetailMultiColumnContainer.styles';
import { addKeys } from '../singleDetailUtils';
import clsx from 'clsx';

const CSSingleDetailMultiColumnContainer: React.FC<CSSingleDetailMultiColumnContainerProps> =
  ({
    elements,
    spacing = 2,
    columns = 2,
    disableStandardHeight = false,
    'data-testid': dataTestId,
  }) => {
    const toRender = piped(
      elements,
      (ele) => ele.filter((e) => e),
      (ele) => addKeys(ele),
      (ele) => chunk(ele, Math.ceil(ele.length / columns)),
    );
    const classes = styles();
    return (
      <Paper
        className={clsx(classes.paperContainer, {
          [classes.paperStandardHeight]: !disableStandardHeight,
        })}
        data-testid={dataTestId}
      >
        <Grid container spacing={spacing}>
          {Array(columns)
            .fill('')
            .map((_, index) => (
              <Grid
                key={`column-${index}`}
                item
                xs={12}
                sm={(12 / columns) as GridSize}
              >
                {toRender[index]}
              </Grid>
            ))}
        </Grid>
      </Paper>
    );
  };

export default CSSingleDetailMultiColumnContainer;
