import { Alert, AlertProps } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import styles, { AlertOverrides } from './alertBanner.styles';
import * as React from 'react';
import Typography from '../typography/Typography';

export interface IAlertProps extends AlertProps {
  alertTitle?: string;
  alertMsg?: string | React.ReactNode;
  classes: { [key: string]: string };
  className?: string;
  buttonLabel?: string;
  buttonAction?: (e: React.BaseSyntheticEvent) => any;
}

const AlertBanner: React.FC<IAlertProps> = (props) => {
  const {
    alertTitle,
    alertMsg,
    classes,
    className,
    buttonLabel,
    buttonAction,
    ...muiProps
  } = props;
  const ThemedMuiAlert = React.useMemo(
    () =>
      withStyles(AlertOverrides, {
        name: 'CSAlert',
      })(Alert),
    [],
  );

  return (
    <ThemedMuiAlert
      {...muiProps}
      className={`${classes.alert} ${className}`}
      classes={{
        root: classes.root,
        message: buttonLabel
          ? classes.message + ' ' + classes.flexColumn
          : classes.message,
      }}
    >
      <div className={classes.container}>
        <Typography variant='subtitle1'>
          {alertTitle}
          {alertTitle && !!alertMsg && ': '}
        </Typography>
        {!!alertMsg && typeof alertMsg === 'string' ? (
          <Typography variant='body2' component='span'>
            {alertMsg}
          </Typography>
        ) : (
          alertMsg
        )}
      </div>
      {typeof alertMsg === 'string' &&
        alertMsg?.includes('Network Error') && (
          <Button
            variant={'outlined'}
            onClick={() => {
              window.location.reload();
            }}
          >
            Try Again
          </Button>
        )}
      {buttonLabel && (
        <Button
          variant={'outlined'}
          onClick={buttonAction}
          style={{ marginTop: '10px' }}
        >
          {buttonLabel}
        </Button>
      )}
    </ThemedMuiAlert>
  );
};

export default withStyles(styles, {
  name: 'CSAlert',
})(AlertBanner);
