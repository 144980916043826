import React, { useState, useEffect, useCallback } from 'react';
import {
  AlertBanner,
  CSButton,
  CSSwitch,
  Typography,
} from '../../primitives';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import detailsPageStyles from '../../commonStyles/detailsPage.style';
import ProgressIndicator from '../../progressIndicator/ProgressIndicator';
import {
  configurationUrlParams,
  getInteraction,
  getMode,
  getOrganizationStationData,
  INTERACTION,
  patchOrganizationStation,
} from '../utils';
import { useParams } from 'react-router-dom';
import ErrorHandler from '../../../utils/ErrorHandler';
import { AxiosError } from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import Layout from '../../layout/Layout';
import Navbar, { TabsEnum } from '../Navbar';
import PaginatedTable, {
  filterObj,
} from '../../paginatedTable/PaginatedTable';
import { Column } from '../../../interfaces/components';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import CancelIcon from '@material-ui/icons/Cancel';
import ConfirmationDialog from '../../confirmationDialog/ConfirmationDialog';
import AddCustomAppModal from './AddCustomAppModal';

interface Props {
  classes: { [key: string]: string };
}

const COLUMNS: Column[] = [
  {
    id: 'identifier',
    label: 'Application ID',
    width: 'auto',
  },
  {
    id: 'checked',
    label: 'Active',
    width: 'auto',
    useCustomComponent: true,
  },
];

export type CustomApp = {
  identifier: string;
  active: boolean;
};

const CustomApps: React.FC<Props> = ({ classes }) => {
  const [initialData, setInitialData] = useState<Array<CustomApp>>(
    [],
  );
  const [data, setData] = useState<Array<CustomApp>>([]);
  const [entityId, setEntityId] = useState<number>();
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [lastUpdate, setLastUpdate] = useState<number>(Date.now());
  const [
    showResetConfirmationDialog,
    setShowResetConfirmationDialog,
  ] = useState<boolean>(false);
  const [showAddCustomAppModal, setShowAddCustomAppModal] =
    useState<boolean>(false);
  const [error, setError] = useState<string>();
  const urlParams = useParams<configurationUrlParams>();
  const mode = getMode(urlParams.orgId, urlParams.stationId);
  const interaction = getInteraction(mode);

  const handleError = async (err: AxiosError) => {
    setError(await ErrorHandler.getLabel(err));
  };

  const getAndSetInitialData = useCallback(async () => {
    try {
      setShowProgress(true);
      const configData: any = await getOrganizationStationData(
        mode,
        Number(urlParams.orgId),
        Number(urlParams.stationId),
      );
      const customAppsData =
        (configData?.config?.OT?.ADM?.custom_apps as CustomApp[]) ||
        [];
      setInitialData(cloneDeep(customAppsData));
      setData(customAppsData);
      setLastUpdate(Date.now());
      setEntityId(configData.id);
      setError(undefined);
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  }, [urlParams.orgId, urlParams.stationId, mode]);

  useEffect(() => {
    getAndSetInitialData();
  }, [urlParams.orgId, urlParams.stationId, getAndSetInitialData]);

  const fetchCustomApps = (
    pageIndex: number,
    rowsPerPage: number,
    filterOptions?: filterObj[],
    filterByString?: string,
    sortedBy?: string,
  ) => {
    return {
      data: {
        count: data.length,
        results: data.map((customApp) => {
          return {
            identifier: customApp.identifier,
            checked: (
              <CSSwitch
                color='default'
                checked={customApp.active}
                onChange={() => {
                  customApp.active = !customApp.active;
                  setData(cloneDeep(data));
                  setLastUpdate(Date.now());
                }}
              />
            ),
          };
        }),
        nextPage: null,
      },
    };
  };

  return (
    <Layout navCurrent='CONFIGURATION'>
      {showProgress && <ProgressIndicator />}
      <Navbar
        activeTab={TabsEnum.CUSTOM_APPS}
        hasUnsavedChanges={!isEqual(initialData, data)}
        onSaveCallback={async () => {
          try {
            setShowProgress(true);
            await patchOrganizationStation(mode, {
              id: entityId,
              config: {
                OT: {
                  ADM: {
                    custom_apps: data,
                  },
                },
              },
            });
            setError(undefined);
            setInitialData(cloneDeep(data));
          } catch (error) {
            handleError(error as AxiosError);
          } finally {
            setShowProgress(false);
          }
        }}
        onResetDataCallback={() => {
          setData(cloneDeep(initialData));
          setLastUpdate(Date.now());
        }}
      />
      {error && (
        <AlertBanner
          className={classes.banner}
          severity='error'
          alertTitle={'Error'}
          alertMsg={error}
        />
      )}
      <ConfirmationDialog
        title={'Reset to Default'}
        msg={'Are you sure you want to reset to default?'}
        primaryActionLabel='Confirm'
        onPrimaryAction={async () => {
          try {
            setShowProgress(true);
            await patchOrganizationStation(mode, {
              id: entityId,
              config: {
                OT: {
                  ADM: {
                    custom_apps: undefined,
                  },
                },
              },
            });
            setError(undefined);
            getAndSetInitialData();
          } catch (error) {
            handleError(error as AxiosError);
          } finally {
            setShowProgress(false);
            setShowResetConfirmationDialog(false);
          }
        }}
        onCancel={() => {
          setShowResetConfirmationDialog(false);
        }}
        isOpen={showResetConfirmationDialog}
      />
      <AddCustomAppModal
        isOpen={showAddCustomAppModal}
        onClose={() => {
          setShowAddCustomAppModal(false);
        }}
        onAdd={(customApp) => {
          setData([...data, customApp]);
          setLastUpdate(Date.now());
        }}
      />
      <Grid container spacing={2}>
        <Grid container item xs={12} sm={6} alignItems='center'>
          <Grid item>
            <Typography variant='h3' component='h1'>
              Custom Apps
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          className={classes.actionButtonsWrapper}
        >
          <CSButton
            className={classes.actionButton}
            variant='outlined'
            color='secondary'
            onClick={() => {
              setShowResetConfirmationDialog(true);
            }}
            startIcon={<SettingsBackupRestoreIcon />}
          >
            Reset to Default
          </CSButton>
          <CSButton
            className={classes.actionButton}
            variant='contained'
            color={{
              buttonColor: 'secondary',
              iconColor: 'primary',
            }}
            onClick={() => {
              setShowAddCustomAppModal(true);
            }}
            startIcon={<AddBoxOutlinedIcon />}
          >
            Add Custom App
          </CSButton>
        </Grid>
      </Grid>
      <AlertBanner
        className={classes.otNoticeBanner}
        severity='error'
        alertMsg={
          <Typography variant='body2' component='p'>
            <b>Make sure that the Kiosk Mode is disabled.</b>
            {` You can find this setting in Modules > Operator Tool > Kiosk Mode`}
          </Typography>
        }
      />
      <PaginatedTable
        key={lastUpdate}
        title={''}
        columns={COLUMNS}
        dataTestIdPrefix={'packages'}
        fetch={fetchCustomApps}
        rowsLoadDetailPages={false}
        showPagination={false}
        actions={[
          {
            cellWidth: 70,
            tableLabel: ' ',
            columnLabel:
              interaction === INTERACTION.WRITE ? (
                <CancelIcon />
              ) : undefined,
            callbackProperty: 'identifier',
            qualifier: 'identifier',
            callback: (id: string) => {
              setData(
                cloneDeep(
                  data.filter((app) => app.identifier !== id),
                ),
              );
              setLastUpdate(Date.now());
            },
          },
        ]}
      />
    </Layout>
  );
};

export default withStyles(
  createStyles(() => ({
    ...detailsPageStyles,
    otNoticeBanner: {
      margin: 5,
      marginBottom: 10,
    },
    actionButtonsWrapper: {
      textAlign: 'right',
    },
    actionButton: {
      margin: 5,
    },
  })),
)(CustomApps);
