import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
  indicatorContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100%',
    background: 'rgb(23, 22, 22, 0.2)',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 99999,
  },
}));
