import { ExtendedPaletteKeys } from '../../../theming/material-ui';
import { InputColor, knownInputColors } from './inputUtils.types';
import get from 'lodash/get';
import { Theme } from '@material-ui/core';

export const toMaterialTextFieldColor = (
  color?: ExtendedPaletteKeys,
): InputColor =>
  typeof color === 'string' && knownInputColors.includes(color)
    ? (color as InputColor)
    : 'primary';

export const getInputFieldColor = (
  value: ExtendedPaletteKeys,
  theme: Theme,
): string | undefined =>
  get(theme.palette, `${value}.${'main'}`) || undefined;
