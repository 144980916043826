import React, { useEffect, useState } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { CSButton, CSSwitch, CSTextField } from '../../../primitives';
import {
  Box,
  Grid,
  Divider,
  Theme,
  IconButton,
} from '@material-ui/core';
import classNames from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Transition } from '../../../confirmationDialog/ConfirmationDialog';
import detailsPageStyles from '../../../commonStyles/detailsPage.style';
import configPageStyles from '../../../commonStyles/configPage.style';
import { INTERACTION } from '../../utils';
import EditIcon from '../../../../utils/svgs/EditIcon';
import { processSearchQuery } from '../searchQuery';
import Highlighter from 'react-highlight-words';
import { CurrentView, ModuleName } from '../Modules';
import { useSearchResultSections } from '../../useSearchResultSections';
import CSDialogTitleWithIcon from '../../../primitives/csDialogTitleWithIcon/CSDialogTitleWithIcon';
import { DialogIcons } from '../../../primitives/csDialogTitleWithIcon/CSDialogTitleWithIconTypes';
import { CSMonoGridContainer } from '../../../primitives/csMonoGridContainer';

interface Props {
  classes: { [key: string]: string };
  data: any;
  dataKey: string;
  interaction?: INTERACTION;
  setPartialData: (data: any) => void;
  currentView: CurrentView;
}

const initDialogData = {
  cargo_spectre_username: '',
  cargo_spectre_password: '',
};

const DimensioningIntegrations: React.FC<Props> = ({
  classes,
  data,
  interaction,
  setPartialData,
  currentView,
}) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [dialogData, setDialogData] = useState<any>(initDialogData);
  const [searchResult, setSearchResult] = useState<string[]>([]);

  const SECTION_TITLE = 'Dimensioning Integrations';

  const { searchQuery, updateSections } = useSearchResultSections(
    ModuleName.GENERAL,
    SECTION_TITLE,
  );

  useEffect(() => {
    if (currentView === CurrentView.SEARCH) {
      const results = processSearchQuery(searchQuery, [
        SECTION_TITLE,
        'Cargo Spectre',
      ]);

      setSearchResult(results);
      updateSections(results);
    } else {
      setSearchResult([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, currentView]);

  const onAfterDialogClose = () => {
    setShowDialog(false);
    setDialogData(initDialogData);
  };

  const renderDialog = () => {
    return (
      <Dialog
        classes={{ paperScrollPaper: classes.dialogRoot }}
        open={showDialog}
        TransitionComponent={Transition}
        onClose={() => {
          onAfterDialogClose();
        }}
      >
        <DialogTitle>
          <CSDialogTitleWithIcon
            icon={DialogIcons.EDIT}
            title='Edit'
          />
        </DialogTitle>
        <DialogContent>
          <CSMonoGridContainer>
            <CSTextField
              data-testid='dimensioning-integrations-cargo-spectre-username'
              containerSize='fullHorizontal'
              autoFocus
              label='Username'
              value={dialogData.cargo_spectre_username}
              onChange={(e) => {
                const value = e.target.value;
                setDialogData({
                  ...dialogData,
                  cargo_spectre_username: value,
                });
              }}
            />
            <CSTextField
              data-testid='dimensioning-integrations-cargo-spectre-password'
              containerSize='fullHorizontal'
              label='Password'
              value={dialogData.cargo_spectre_password}
              onChange={(e) => {
                const value = e.target.value;
                setDialogData({
                  ...dialogData,
                  cargo_spectre_password: value,
                });
              }}
            />
          </CSMonoGridContainer>
        </DialogContent>

        <DialogActions className={classes.dialogActions}>
          <CSButton
            color='secondary'
            variant='outlined'
            onClick={() => {
              onAfterDialogClose();
            }}
          >
            Cancel
          </CSButton>
          <CSButton
            color='secondary'
            variant='contained'
            data-testid='dimensioning-integrations-dialog-save-btn'
            onClick={() => {
              data.cargo_spectre_username =
                dialogData.cargo_spectre_username;
              data.cargo_spectre_password =
                dialogData.cargo_spectre_password;
              setPartialData({
                ...data,
              });
              onAfterDialogClose();
            }}
          >
            Update
          </CSButton>
        </DialogActions>
      </Dialog>
    );
  };

  const renderComponent = () => {
    return (
      <>
        {renderDialog()}
        <Grid item sm={12}>
          <Box>
            <Box
              className={classNames(
                classes.moduleTitle,
                classes.pLeft,
              )}
              mb={1}
              mt={2}
            >
              <Highlighter
                highlightClassName={classes.searchResultsMark}
                searchWords={searchResult}
                autoEscape={true}
                textToHighlight={SECTION_TITLE}
              />
            </Box>
            <Box mt={2}>
              <Box mb={2}>
                <Grid
                  container
                  item
                  sm={12}
                  className={classes.textHeader}
                >
                  <Grid item xs={5} sm={4} className={classes.pLeft}>
                    Integration
                  </Grid>
                  <Grid item xs={5} sm={6}>
                    Enabled
                  </Grid>

                  <Grid item xs={2} />
                </Grid>
                <Grid item xs={12}>
                  <Divider className={classes.divider} />
                </Grid>
              </Box>
              <Grid container item sm={12} className={classes.row}>
                <Grid
                  item
                  xs={5}
                  sm={4}
                  className={classNames(classes.text, classes.pLeft)}
                >
                  <Highlighter
                    highlightClassName={classes.searchResultsMark}
                    searchWords={searchResult}
                    autoEscape={true}
                    textToHighlight='Cargo Spectre'
                  />
                </Grid>
                <Grid
                  item
                  xs={5}
                  sm={7}
                  className={classes.text}
                  style={{ paddingRight: 10 }}
                >
                  <CSSwitch
                    data-testid='dimensioning-integrations-cargo-spectre-switch'
                    color='default'
                    size='small'
                    disabled={interaction === INTERACTION.READ}
                    checked={data.cargo_spectre_active}
                    onChange={() => {
                      data.cargo_spectre_active =
                        !data.cargo_spectre_active;
                      setPartialData({ ...data });
                    }}
                  />
                </Grid>
                <Grid item xs={2} sm={1}>
                  <IconButton
                    data-testid='dimensioning-integrations-cargo-spectre-edit-btn'
                    size='small'
                    className={classes.editIcon}
                    disabled={interaction === INTERACTION.READ}
                    onClick={() => {
                      setDialogData({
                        cargo_spectre_username:
                          data.cargo_spectre_username,
                        cargo_spectre_password:
                          data.cargo_spectre_password,
                      });
                      setShowDialog(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </>
    );
  };

  return currentView === CurrentView.SEARCH && !searchResult.length
    ? null
    : renderComponent();
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...configPageStyles(theme),
    dialogActions: {
      padding: 24,
    },
  })),
)(DimensioningIntegrations);
