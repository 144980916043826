import React, { useState } from 'react';
import {
  withStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles';

import generatePassword from 'generate-password';
import PasswordRequirementsIndicator, {
  validatePassword,
} from '../passwordRequirementsIndicator/PasswordRequirementsIndicator';
import validateEmail from '../../utils/validateEmail';

import colors from '../../utils/colors';
import { Box, Grid, Paper } from '@material-ui/core';
import { Typography, CSButton, CSTextField } from '../primitives';

import browserHistory from '../../utils/browserHistory';
import { AuthRoutes } from '../../interfaces/routes';
import globalStationOptionsUtils from '../../utils/globalStationOptionsUtils';
import detailsPageStyle from '../commonStyles/detailsPage.style';

//Types
import { Staff } from 'cloudsort-client';
import { useAppDispatch } from '../../redux/store';
import { setSelectedEntity } from '../../redux/slices/navigationSlice';
import { SELECTOR_ENTITY_TYPE } from '../navigation/types';
import EphemeralStateService from '../../services/EphemeralState.service';
import { CSSingleDetailMonoColumnContainer } from '../primitives/singleDetail/singleDetailMonoColumnContainer';
import clsx from 'clsx';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';

interface Props {
  classes: { [key: string]: string };
  isOwnProfile: boolean;
  staffData: Staff;
  staffEditData: Staff;
  setStaffEditData: (staff: Staff) => void;
  setError: (error: string) => void;
  setPrompt: (prompt?: string) => void;
  setEnableSave: (enabled: boolean) => void;
  setShowAssignDialog: (show: boolean) => void;
}

const PersonalInfo: React.FC<Props> = ({
  classes,
  isOwnProfile,
  staffData,
  staffEditData,
  setStaffEditData,
  setError,
  setPrompt,
  setEnableSave,
  setShowAssignDialog,
}) => {
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [showPasswordSection, setShowPasswordSection] =
    useState<boolean>(false);
  const [usingGeneratedPassword, setUsingGeneratedPassword] =
    useState<boolean>(false);
  const [firstPasswordUpdate, setfirstPasswordUpdate] =
    useState<boolean>(true);

  const onGeneratePasswordClick = () => {
    const password = generatePassword.generate({
      length: 16,
      numbers: true,
      lowercase: true,
      uppercase: true,
    });
    setStaffEditData({
      ...staffEditData,
      password,
    });
    setConfirmPassword(password);
    setUsingGeneratedPassword(true);
    setfirstPasswordUpdate(true);
    setPrompt(undefined);
    setEnableSave(true);
  };

  const onPasswordChange = (e: React.BaseSyntheticEvent) => {
    if (firstPasswordUpdate) {
      setUsingGeneratedPassword(false);
      setConfirmPassword('');
      setfirstPasswordUpdate(false);
    }
    e.persist();
    setError('');
    setPrompt(undefined);
    setStaffEditData({
      ...staffEditData,
      password: e.target.value,
    });
    if (
      e.target.value === confirmPassword &&
      validatePassword(e.target.value)
    )
      setEnableSave(true);
    else setEnableSave(false);
  };

  const dispatch = useAppDispatch();

  const handleGoToDashboard = async (id: number) => {
    await globalStationOptionsUtils.setStationData(id);

    dispatch(
      setSelectedEntity({ id, type: SELECTOR_ENTITY_TYPE.STATION }),
    );
    EphemeralStateService.setMyStationId(id);

    setTimeout(() => {
      browserHistory.push(AuthRoutes.DASHBOARD);
    }, 0);
  };

  const renderAssignedEntity = (
    type: 'station' | 'organization',
    name: string,
    id: number,
  ) => (
    <Grid
      container
      alignItems='center'
      key={`assigned-${type}-${id}`}
      style={{ marginBottom: '10px' }}
    >
      <Grid item xs={type === 'station' ? 6 : 12}>
        <CSButton color='secondary' variant='contained'>
          {name}
        </CSButton>
      </Grid>
      {type === 'station' && (
        <Grid item xs={6}>
          <Typography
            className={classes.dashboardLink}
            color='secondary'
            variant='body1'
            onClick={(e: React.SyntheticEvent) => {
              e.preventDefault();
              handleGoToDashboard(id);
            }}
          >
            Go to {name} Dashboard
          </Typography>
        </Grid>
      )}
    </Grid>
  );

  return (
    <Grid container spacing={6}>
      <Grid
        item
        sm={EphemeralStateService.getIsRoleSuperUser() ? 6 : 12}
        xs={12}
        className={classes.gridItem}
      >
        <Paper
          className={clsx(classes.paper, classes.maxContainer)}
          data-testid='profile-personal-info'
        >
          <CSSingleDetailMonoColumnContainer
            header='Personal Info'
            elements={[
              <CSTextField
                inputProps={{
                  'data-testid': 'update-profile-first-name',
                  type: 'text',
                }}
                containerSize='fullHorizontal'
                required
                label='First Name'
                name='first_name'
                autoComplete='first_name'
                value={staffEditData.first_name}
                onChange={(e: React.BaseSyntheticEvent) => {
                  e.persist();
                  setError('');
                  setPrompt(undefined);
                  setStaffEditData({
                    ...staffEditData,
                    first_name: e.target.value,
                  });
                  if (staffEditData.first_name !== '')
                    setEnableSave(true);
                  else setEnableSave(false);
                }}
              />,

              <CSTextField
                className={classes.marginTop20}
                inputProps={{
                  'data-testid': 'update-profile-last-name',
                  type: 'text',
                }}
                containerSize='fullHorizontal'
                required
                label='Last Name'
                name='last_name'
                autoComplete='last_name'
                value={staffEditData.last_name}
                onChange={(e: React.BaseSyntheticEvent) => {
                  e.persist();
                  setError('');
                  setPrompt(undefined);
                  setStaffEditData({
                    ...staffEditData,
                    last_name: e.target.value,
                  });
                  if (staffEditData.last_name !== '')
                    setEnableSave(true);
                  else setEnableSave(false);
                }}
              />,

              <CSTextField
                className={classes.marginTop20}
                inputProps={{
                  'data-testid': 'update-profile-email',
                  type: 'text',
                }}
                containerSize='fullHorizontal'
                required
                label='Email'
                name='edit_email'
                autoComplete='edit_email'
                value={staffEditData.email}
                onChange={(e: React.BaseSyntheticEvent) => {
                  e.persist();
                  setError('');
                  setPrompt(undefined);
                  setStaffEditData({
                    ...staffEditData,
                    email: e.target.value,
                  });
                  if (
                    staffEditData.email !== '' &&
                    validateEmail(staffEditData.email)
                  )
                    setEnableSave(true);
                  else setEnableSave(false);
                }}
              />,
              <Typography
                variant='body1'
                className={classes.marginTop20}
              >
                If you update your email address, we'll send an email
                to your new address to verify it.
                <br />
                <b>
                  The new address will not become active until
                  confirmed.
                </b>
              </Typography>,
            ]}
          />
          <Box className={classes.marginTop20}>
            <CSSingleDetailMonoColumnContainer
              header='Password'
              elements={[
                !showPasswordSection
                  ? [
                      <CSButton
                        data-testid='profile-set-password-button'
                        color='secondary'
                        variant='outlined'
                        startIcon={<AutorenewIcon />}
                        onClick={(e) => {
                          e.preventDefault();
                          setShowPasswordSection(true);
                        }}
                      >
                        Set new password
                      </CSButton>,
                    ]
                  : undefined,
                showPasswordSection
                  ? [
                      <CSTextField
                        inputProps={{
                          'data-testid': 'update-profile-password1',
                          type: usingGeneratedPassword
                            ? 'text'
                            : 'password',
                        }}
                        containerSize='fullHorizontal'
                        required
                        label='New Password'
                        name='password'
                        autoComplete='password'
                        value={staffEditData.password}
                        onChange={onPasswordChange}
                        onClick={(e: React.BaseSyntheticEvent) => {
                          usingGeneratedPassword && e.target.select();
                        }}
                      />,
                      <CSButton
                        className={classes.marginTop20}
                        size='small'
                        variant='outlined'
                        color='secondary'
                        onClick={onGeneratePasswordClick}
                      >
                        Generate Random Password
                      </CSButton>,
                    ]
                  : undefined,
                showPasswordSection && !usingGeneratedPassword
                  ? [
                      <CSTextField
                        className={classes.marginTop20}
                        inputProps={{
                          'data-testid': 'update-profile-password2',
                          type: 'password',
                        }}
                        containerSize='fullHorizontal'
                        required
                        label='Confirm Password'
                        name='confirmPassword'
                        autoComplete='password'
                        value={confirmPassword}
                        onChange={(e: React.BaseSyntheticEvent) => {
                          e.persist();
                          setError('');
                          setPrompt(undefined);
                          setConfirmPassword(e.target.value);
                          if (
                            staffEditData.password === e.target.value
                          )
                            setEnableSave(true);
                          else setEnableSave(false);
                        }}
                      />,
                      <PasswordRequirementsIndicator
                        password1={staffEditData.password}
                        password2={confirmPassword}
                        checkForMatchingPasswords={true}
                      ></PasswordRequirementsIndicator>,
                    ]
                  : undefined,
              ]}
            />
          </Box>
        </Paper>
      </Grid>

      {EphemeralStateService.getIsRoleSuperUser() && (
        <Grid item sm={6} xs={12} className={classes.gridItem}>
          <Paper
            className={clsx(classes.paper, classes.maxContainer)}
            data-testid='profile-assigned-sattions'
          >
            <CSSingleDetailMonoColumnContainer
              header='Assigned Stations and Organizations'
              elements={[
                staffData.assigned_stations?.length === 0 &&
                staffData.assigned_organizations?.length === 0 ? (
                  <Typography
                    variant='body1'
                    style={{ marginBottom: '20px' }}
                  >
                    No stations has been assigned to this user.
                  </Typography>
                ) : (
                  [
                    ...(staffData?.assigned_organizations || []).map(
                      (org) =>
                        renderAssignedEntity(
                          'organization',
                          org.name || '',
                          org.id || 0,
                        ),
                    ),
                    ...(staffData?.assigned_stations || []).map(
                      (station) =>
                        renderAssignedEntity(
                          'station',
                          station.name || '',
                          station.id || 0,
                        ),
                    ),
                  ]
                ),
                !isOwnProfile ? (
                  <CSButton
                    data-testid='profile-set-password-button'
                    variant='outlined'
                    color='secondary'
                    startIcon={<AddBoxOutlinedIcon />}
                    onClick={(e) => {
                      e.preventDefault();
                      setShowAssignDialog(true);
                    }}
                    style={{ marginLeft: '0' }}
                  >
                    Assign New Entity
                  </CSButton>
                ) : undefined,
              ]}
            />
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyle,
    stationBadge: {
      border: `2px solid ${colors.black}`,
      borderRadius: '5px',
      color: colors.black,
      padding: theme.spacing(1, 2),
      marginRight: theme.spacing(1),
      display: 'inline-block',
    },
    marginTop20: {
      marginTop: 20,
    },
    gridItem: {
      [theme.breakpoints.up('sm')]: {
        display: 'grid',
        minHeight: 300,
      },
    },
    dashboardLink: {
      cursor: 'pointer',
      textAlign: 'right',
    },
  })),
)(PersonalInfo);
