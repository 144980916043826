import React, { useEffect } from 'react';
import { Typography } from '../primitives';
import styles from './pageNotFound.styles';
import { Grid } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import browserHistory from '../../utils/browserHistory';
import { AuthRoutes, NonAuthRoutes } from '../../interfaces/routes';

// Services
import Auth from '../../services/Auth.service';
import LocalStorageService from '../../services/LocalStorage.service';

interface Props {
  classes: { [key: string]: string };
}

const RedirectToDashboard: React.FC<Props> = ({ classes }) => {
  useEffect(() => {
    setTimeout(() => {
      browserHistory.push(AuthRoutes.DASHBOARD);
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    !Auth.isLoggedIn() &&
    browserHistory.location.pathname !== NonAuthRoutes.LOGIN
  ) {
    LocalStorageService.setAfterLoginUrl(window.location.href);
    return (
      <Redirect
        to={{
          pathname: NonAuthRoutes.LOGIN,
        }}
      />
    );
  }

  return (
    <Grid>
      <div className={classes.errorBackground}>
        <div className={classes.innerContainer}>
          <img
            className={classes.errorLogo}
            alt='CloudSort'
            src={`${
              process.env.REACT_APP_BASENAME || ''
            }/loginAssets/logo.svg`}
          />
          <Grid className={classes.errorMessage}>
            <Typography
              variant='h1'
              color={{ color: 'common', variant: 'white' }}
            >
              404
            </Typography>
            <Typography
              variant='h3'
              color={{ color: 'common', variant: 'white' }}
            >
              Oops, looks like the URL you are trying to access has
              changed or is no longer available.
              <br />
              <br />
              {`You will be redirected to the ${
                Auth.isLoggedIn()
                  ? 'station dashboard'
                  : 'log in page'
              } in 3
              seconds.`}
            </Typography>
          </Grid>
        </div>
      </div>
    </Grid>
  );
};

export default withStyles(styles)(RedirectToDashboard);
