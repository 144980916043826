import { createStyles, Theme } from '@material-ui/core/styles';
import { getDetailsPageStylesWithTheme } from '../commonStyles/detailsPage.style';
import selectStyles from '../select/select.styles';
import sectionPageBaseStyle from '../commonStyles/sectionPageBase.style';

export default createStyles((theme: Theme) => ({
  ...getDetailsPageStylesWithTheme(theme),
  ...sectionPageBaseStyle,
  ...selectStyles,
  detailHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 13,
    minHeight: 30,
  },
  detailAddHolder: {
    display: 'flex',
    marginBottom: 20,
    maxWidth: '60%',
  },
  gridGutter: {
    marginBottom: 8,
  },
  buttonSeparation: {
    marginLeft: 10,
  },
  detailAsyncSelect: {
    flexGrow: 1,
  },
  banner: {
    width: '100%',
    borderRadius: 4,
    margin: '24px 0 30px 0',
    backgroundColor: theme.palette.secondary.light,
    boxShadow: theme.shadows[2],
    color: theme.palette.text.primary,
  },
  nonMobileAlignRight: {
    [theme.breakpoints.up('xs')]: {
      textAlign: 'right',
    },
  },
  dialogActions: {
    padding: '16px 23px 25px 8px',
  },
  dialogRoot: {
    overflow: 'hidden',
  },
  dialogPaper: {
    width: 488,
    maxHeight: '100%',
  },
  inputPadding: {
    paddingLeft: '5px',
    paddingRigth: '5px',
  },
}));
