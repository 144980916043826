import { createStyles, Theme } from '@material-ui/core/styles';

export default createStyles((theme: Theme) => ({
  flexTidyArrangement: {
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    marginBottom: 16,
  },
  filter: {
    height: 56,
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-notchedOutline:hover':
      {
        borderColor: `${theme.palette.secondary.main} !important`,
        borderWidth: 2,
      },
    '& .MuiFormLabel-root': {
      top: '-6px',
      left: 15,
      zIndex: 2,
      backgroundColor: '#f1f1f1',
      padding: `0 ${theme.spacing(1)}px`,
      whiteSpace: 'nowrap',
      height: '1rem',
      overflow: 'hidden',
      maxWidth: '140px',
      textOverflow: 'ellipsis',
    },
    width: 175,
    [theme.breakpoints.down('md')]: {
      width: 140,
    },
    [theme.breakpoints.down('sm')]: {
      width: 120,
    },
    [theme.breakpoints.down('xs')]: {
      width: 175,
    },
  },
  muiSelect: {
    '& .MuiSelect-select:focus': {
      backgroundColor: theme.palette.common.transparent,
    },
  },
  selectIcon: {
    color: 'inherit',
    marginRight: '10px',
  },
  roundedContainer: {
    borderTopRightRadius: theme.shape.borderRadius,
    borderTopLeftRadius: theme.shape.borderRadius,
  },
  tableOuterContainer: {
    boxShadow: theme.shadows[2],
    borderRadius: theme.shape.borderRadius,
    scrollbarColor: `${theme.palette.secondary.main} ${theme.palette.secondary.main}`,
    scrollbarWidth: 'thin !important',
    '& ::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      width: 10,
      height: 10,
      backgroundColor: theme.palette.common.white,
    },

    '& ::-webkit-scrollbar-thumb': {
      borderRadius: 4,
      backgroundColor: theme.palette.secondary.main,
    },
  },
  toolBar: {
    backgroundColor: theme.palette.background.paper,
    minHeight: 56,
    justifyContent: 'space-between',
    borderTopRightRadius: theme.shape.borderRadius,
    borderTopLeftRadius: theme.shape.borderRadius,
  },
  tableHeadRow: {
    '& th': {
      backgroundColor: theme.palette.grey.A100,
      lineHeight: theme.typography.table?.title?.lineHeight,
    },
  },
  tableRow: {
    backgroundColor: theme.palette.background.paper,
    '&:hover td': {
      backgroundColor: theme.palette.grey.A200,
    },
  },
  removeFilters: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.14,
    letterSpacing: '1.25px',
    color: theme.palette.grey.A400,
    '&:hover': {
      backgroundColor: theme.palette.common.transparent,
    },
  },
  link: {
    '&:hover': {
      color: theme.palette.quaternary?.main,
    },
  },
  sortableLink: {
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tableTitle: {
    marginTop: '10px',
  },
  sortIcon: {
    color: 'inherit',
    width: '16px',
    height: '16px',
  },
  paginationCaption: {
    borderBottomRightRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
  paginationCaptionDisabled: {
    color: theme.palette.grey.A400,
  },
  filterByStrInput: {
    padding: '0 7px',
  },
  filterByStrHolder: {
    minWidth: '12em',
    marginRight: theme.spacing(2),
    border: '1px solid #ddd',
    borderRadius: '3px',
    backgroundColor: theme.palette.background.paper,
  },
  filterByStrLabel: {
    padding: 7,
  },
  expanderContainer: {
    backgroundColor: theme.palette.background.paper,
  },
  expandedButton: {
    color: theme.palette.text.primary,
    '&:hover > span > svg': {
      color: theme.palette.tertiary?.main,
    },
  },
  expandedButtonHidden: {
    opacity: 0,
  },
  accordion: {
    boxShadow: 'none',
    '& .MuiAccordionSummary-root': {
      flexDirection: 'row-reverse',
      padding: 0,
      '& .MuiAccordionSummary-expandIcon': {
        padding: 0,
        margin: 12,
      },
    },
    '& .MuiAccordionSummary-content': {
      margin: 0,
    },
    '& .MuiTableRow-root': {
      backgroundColor: `${theme.palette.common.transparent} !important`,
    },
    '& .MuiTableRow-root.MuiTableRow-hover:hover': {
      backgroundColor: `${theme.palette.common.transparent} !important`,
    },
    '& .MuiTableRow-root.MuiTableRow-hover:hover td': {
      backgroundColor: `${theme.palette.common.transparent} !important`,
    },
    '& td.MuiTableCell-root': {
      border: 0,
    },
    '& .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded': {
      backgroundColor: theme.palette.grey[300],
    },
    '& .MuiAccordionDetails-root': {
      padding: 0,
      backgroundColor: theme.palette.grey['A200'],
    },
  },
  actionColumn: {
    textAlign: 'right',
  },
  actionColumnButton: {
    display: 'inline-flex',
    minWidth: 0,
    padding: 0,
    '&:hover': {
      color: theme.palette.tertiary?.main,
      backgroundColor: theme.palette.common.transparent,
    },
    '&:disabled': {
      opacity: '0.5',
    },
  },
  marginLeft10: {
    marginLeft: 10,
  },
}));
