import {
  createStyles,
  Theme,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Position } from 'react-flow-renderer';
import flowColors from '../flowColors';
import flowStyles from '../flowStyles';
import { NodeData } from '../interfaces';
import Handles from './parts/Handles';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { ReturnDataStation } from 'cloudsort-client';
import {
  renderVolumeString,
  renderWeightString,
} from '../../../DetailsPagesFunctions';

interface Props {
  classes: { [key: string]: string };
  data: NodeData;
  sourcePosition: Position;
  targetPosition: Position;
}

const PrimaryAreaNode = ({
  classes,
  data,
  sourcePosition,
  targetPosition,
}: Props) => {
  const { isThroughputMode } = data;

  const renderLoadPoint = (loadpoint: any, index: number) => {
    return (
      <div
        className={
          classes.loadPoint + ' ' + loadpoint.additionalCssClass
        }
        key={`loadpoint-${index}`}
      >
        <h4>{loadpoint.name}</h4>
        <div className={classes.flexContainerCentered}>
          <p>
            {isThroughputMode
              ? loadpoint.exitPackages
              : loadpoint.packages}{' '}
            P
          </p>
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        className={classes.nodeContainer}
        style={{ width: data.width, height: data.height }}
      >
        <div className={classes.title}>
          {data.name}
          <span>
            {isThroughputMode ? data.exitPackages : data.packages} P
            {isThroughputMode && (
              <ExitToAppIcon
                style={{ width: 16, height: 16, marginBottom: -3 }}
              />
            )}
          </span>
        </div>
        <div className={classes.content}>
          <div className={classes.loadPoints}>
            {Object.values(data.loadpoints!).map(
              (loadpoint: any, index) => {
                const {
                  loadpointMeta,
                }: { loadpointMeta: ReturnDataStation } =
                  loadpoint?.meta;
                return loadpointMeta && !isThroughputMode ? (
                  <Tooltip
                    arrow
                    classes={{
                      tooltipArrow: classes.tooltip,
                      arrow: classes.tooltipArrow,
                    }}
                    PopperProps={{
                      popperOptions: {
                        modifiers: {
                          offset: {
                            enabled: true,
                            offset: '0, -5px',
                          },
                        },
                      },
                    }}
                    title={
                      <>
                        <Typography
                          variant='h4'
                          className={classes.tooltipTitle}
                        >
                          Current Volume
                        </Typography>
                        <Typography
                          variant='body1'
                          className={classes.tooltipValue}
                        >
                          {renderVolumeString(loadpointMeta.volume)}
                        </Typography>
                        <Typography
                          variant='h4'
                          className={classes.tooltipTitle}
                        >
                          Current Weight
                        </Typography>
                        <Typography
                          variant='body1'
                          className={classes.tooltipValue}
                        >
                          {renderWeightString(loadpointMeta.weight)}
                        </Typography>
                      </>
                    }
                    placement='left'
                    key={`loadpoint-tooltip-${index}`}
                  >
                    {renderLoadPoint(loadpoint, index)}
                  </Tooltip>
                ) : (
                  renderLoadPoint(loadpoint, index)
                );
              },
            )}
          </div>
        </div>
      </div>
      <Handles
        sourcePosition={sourcePosition}
        targetPosition={targetPosition}
      />
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...flowStyles,
    nodeContainer: {
      padding: '5px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'strech',
      flexDirection: 'column',
      color: 'white',
      background: flowColors.white,
      border: `1px solid ${flowColors.primaryBorder}`,
      borderRadius: 5,
    },
    invisibleHandle: {
      opacity: 0,
      width: 1,
      height: 1,
      border: 0,
    },
    title: {
      color: flowColors.black,
      fontSize: '15px',
      lineHeight: '15px',
      marginBottom: 5,
      fontWeight: 'bold',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& span': {
        fontSize: '11px',
        float: 'right',
      },
    },
    content: {
      flexGrow: 1,
    },
    loadPoints: {
      width: '100%',
      height: '100%',
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gridRowGap: 5,
      gridColumnGap: 5,
      gridAutoRows: '1fr',
    },

    flexContainerCentered: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',

      '& p': {
        padding: 0,
        margin: 0,
        color: flowColors.white,
        fontSize: 10,
      },
    },
  })),
)(PrimaryAreaNode);
