import { useEffect, useMemo, useState } from 'react';
import { Box, Typography, useTheme } from '@material-ui/core';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  Cell,
} from 'recharts';
import colors from '../../utils/colors';
import moment from 'moment';

import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import StationPlannerService from '../../services/ShiftPlanner.service';
import { AggregatedShiftPlan } from 'cloudsort-client';
import configurationUtils from '../../utils/configurationUtils';

type ChartData = {
  time: number;
  capacity: number;
  inStation: number;
};

function VolumeOfPackages({
  date,
  fromDate,
  toDate,
}: {
  date: Date;
  fromDate: string | null;
  toDate: string | null;
}) {
  const [chartData, setChartData] = useState<ChartData[]>([]);
  const theme = useTheme();
  const packageLabels = useMemo(() => {
    return {
      singular: configurationUtils.getPageTitle(true, 'PACKAGE'),
      plural: configurationUtils.getPageTitle(false, 'PACKAGE'),
    };
  }, []);

  const currentHour = Number(moment().format('H'));

  const formatTimeLabel = (label: number) => {
    if (label === 0) {
      return '12 AM';
    } else if (label < 12) {
      return `${label} AM`;
    } else if (label === 12) {
      return '12 PM';
    }
    return `${label - 12} PM`;
  };

  const formatNumberLabel = (label: number) => {
    if (label < 1000) {
      return label.toString();
    } else if (label < 100000) {
      return `${(label / 1000).toFixed(1)}K`;
    }

    return `${(label / 1000).toFixed()}K`;
  };

  const populateChartData = (
    data: AggregatedShiftPlan[] | undefined,
  ) => {
    if (data) {
      const temp = data?.map((item) => {
        return {
          time: Number(moment(item.timestamp).format('H')),
          capacity: item.capacity,
          inStation: item.packages_in_station,
        };
      });

      setChartData(temp);
    }
  };

  const loadData = () => {
    if (fromDate && toDate) {
      StationPlannerService.getChartData(
        new Date(fromDate),
        new Date(toDate),
      ).then((res) => {
        populateChartData(res.data.aggregation);
      });
    }
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, toDate]);

  return (
    <Box
      style={{ padding: '40px 20px 20px 15px', position: 'relative' }}
      data-testid='volume-of-packages'
    >
      {(!fromDate || !toDate || !chartData) && (
        <Typography
          style={{
            position: 'absolute',
            top: 'calc( 50% - 16px)',
            left: 'calc( 50% - 50px)',
            backgroundColor: colors.secondaryWhite,
            padding: '4px 8px',
            borderRadius: 4,
          }}
        >
          No data yet
        </Typography>
      )}
      <Typography
        style={{
          position: 'absolute',
          top: 'calc( 50% + 75px)',
          left: 10,
          transform: 'rotate(-90deg)',
          transformOrigin: '0 0',
        }}
      >
        {`Volume of ${packageLabels.plural}`}
      </Typography>
      <Typography
        style={{
          position: 'absolute',
          top: 10,
          right: 30,
        }}
      >
        <FiberManualRecordIcon
          style={{
            color: theme.palette.tertiary?.main,
            margin: '0 0 -6px 0',
          }}
        />
        Capacity
        <FiberManualRecordIcon
          style={{
            color: theme.palette.quaternary?.main,
            margin: '0 0 -6px 0',
          }}
        />
        {`${packageLabels.plural} in Station`}
      </Typography>

      <ResponsiveContainer height={320}>
        <BarChart data={chartData}>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis
            dataKey='time'
            minTickGap={-10}
            angle={45}
            dy={10}
            height={40}
            tickFormatter={(label) => formatTimeLabel(label)}
          />
          <YAxis
            tickFormatter={(label) => formatNumberLabel(label)}
          />

          {chartData.length > 0 && (
            <Tooltip
              labelFormatter={(label) =>
                formatTimeLabel(Number(label))
              }
              formatter={(label) => formatNumberLabel(Number(label))}
              cursor={{ fill: colors.secondaryWhite }}
            />
          )}
          <Bar
            dataKey='capacity'
            name='Capacity'
            fill={theme.palette.tertiary?.main}
          >
            {chartData.map((item, index) => (
              <Cell
                key={`cell-capacity-${index}`}
                fill={
                  (moment(date).isSame(new Date(), 'day') &&
                    item.time < currentHour) ||
                  moment(date).isBefore(new Date(), 'day')
                    ? colors.lightGold
                    : colors.gold
                }
              />
            ))}
          </Bar>
          <Bar
            dataKey='inStation'
            name={`${packageLabels.plural} in Station`}
            fill={theme.palette.tertiary?.main}
          >
            {chartData.map((item, index) => (
              <Cell
                key={`cell-ins-tation-${index}`}
                fill={
                  (moment(date).isSame(new Date(), 'day') &&
                    item.time < currentHour) ||
                  moment(date).isBefore(new Date(), 'day')
                    ? colors.gray
                    : theme.palette.quaternary?.main
                }
              />
            ))}
          </Bar>
          {moment(date).isSame(new Date(), 'day') && (
            <ReferenceLine
              x={currentHour}
              stroke={colors.black}
              strokeDasharray='6 3'
              strokeWidth={2}
            />
          )}
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
}

export default VolumeOfPackages;
