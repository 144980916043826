const initialMapViewWholeUS = {
  longitude: -100,
  latitude: 40,
  zoom: 4,
};

const mapColors = {
  primary: '#5FCE84',
  secondary: '#1C4650',
  white: '#FFFFFF',
  gradient: {
    start: '#7DCB8B',
    end: '#27454F',
    endStroke: 'rgba(95,206,132,0.5)',
  },
};

export { initialMapViewWholeUS, mapColors };
