import EphemeralStateService from '../services/EphemeralState.service';
import queryString from 'query-string';
import { useAppDispatch } from '../redux/store';
import {
  setSelectedEntity,
  setShouldUpdateStationsData,
} from '../redux/slices/navigationSlice';
import { SELECTOR_ENTITY_TYPE } from '../components/navigation/types';
import browserHistory from '../utils/browserHistory';
import { useEffect } from 'react';

// It's possible for a user to open a entity details page without station id in the url.
//In this case we need to get it from the entity data as returned by the API and set it in the url. The web app (navigation bar) will use it to display the station name and show the correct navigation menu.
export default function useStationId(entityId?: number) {
  const dispatch = useAppDispatch();
  useEffect(() => {
    //Do nothing if entity ID is missing
    if (!entityId) {
      return;
    }

    //If entity ID is different from current station, update the station in the navigation bar
    const currentStationId = EphemeralStateService.getMyStationId();
    if (currentStationId !== entityId) {
      EphemeralStateService.setMyStationId(entityId);
      dispatch(
        setSelectedEntity({
          id: entityId,
          type: SELECTOR_ENTITY_TYPE.STATION,
        }),
      );
      dispatch(setShouldUpdateStationsData(true));
    }

    //Update query string to include stationId
    const stationIdFromQueryString = queryString.parse(
      window.location.search,
    )?.stationId;
    if (stationIdFromQueryString !== String(entityId)) {
      let currentQueryString = queryString.parse(
        window.location.search,
      );
      currentQueryString.stationId = String(entityId);
      browserHistory.replace(
        '?' + queryString.stringify(currentQueryString),
      );
    }
  }, [entityId, dispatch]);
}
