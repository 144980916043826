const getLongestDwellTimeLabel = (time?: string) => {
  if (!time) return '';

  const diff = Math.abs(
    new Date().getTime() - new Date(time).getTime(),
  );

  const days = diff / (1000 * 60 * 60 * 24);
  const hours = diff / (1000 * 60 * 60);
  const minutes = diff / (1000 * 60);
  const seconds = diff / 1000;

  if (days >= 1) {
    return `${Math.trunc(days)}d`;
  } else if (hours >= 1) {
    return `${Math.trunc(hours)}h`;
  } else if (minutes >= 1) {
    return `${Math.trunc(minutes)}m`;
  } else {
    return `${Math.trunc(seconds)}s`;
  }
};

export enum NestedProcessStepProcessLabels {
  MANIFESTEDINBOUND = 'Manifested Inbound',
  ARRIVED = 'Arrived',
  SCANNED = 'Scanned',
  ZONE = 'Zone',
  CONTAINERIZED = 'Containerized',
  MOVED = 'Moved',
  PRIMARY = 'Primary',
  SECONDARY = 'Secondary',
  STAGED = 'Staged',
  LOADED = 'Loaded',
  OPENED = 'Opened',
  CLOSED = 'Closed',
  DISPATCHED = 'Dispatched',
  MANIFESTEDOUTBOUND = 'Manifested Outbound',
}

export default {
  getLongestDwellTimeLabel,
};
