import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { withStyles } from '@material-ui/core/styles';
import { AuthRoutes, ModulesKeys } from '../../interfaces/routes';
import {
  AlertBanner,
  CSButton,
  CSTextField,
  Typography,
} from '../primitives/index';
import styles from './OutboundLoads.styles';
import ErrorHandler from '../../utils/ErrorHandler';
import PaginatedTable, {
  filterObj,
} from '../paginatedTable/PaginatedTable';
import Layout from '../layout/Layout';
import { Column, TypeAheadItem } from '../../interfaces/components';
import {
  formatFirstNItemsAndMore,
  renderTimestampString,
} from '../DetailsPagesFunctions';
import { RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Transition } from '../confirmationDialog/ConfirmationDialog';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import { STRING_UNITS_VOLUME } from '../UIStrings';
import fedexLabels from '../../utils/fedexLabels';
import ExportCSVDialog from '../exportCSVDialog/ExportCSVDialog';
import FiltersDrawer, {
  SelectedFilters,
} from '../filtersDrawer/FiltersDrawer';
import configurationUtils from '../../utils/configurationUtils';
import { Grid } from '@material-ui/core';
import { noOptionsMessage } from '../../components/asyncSelect/utils';
import debounce from 'lodash/debounce';
import { common } from '../../utils/strings';
import clx from 'classnames';
import { Helmet } from 'react-helmet';

// Types
import {
  Route,
  StaffPermissionPermissionsEnum,
  DockDoorDetails,
} from 'cloudsort-client';
import { AxiosError } from 'axios';

// Icons
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import CloudDownloadOutlined from '@material-ui/icons/CloudDownloadOutlined';
import FilterListIcon from '@material-ui/icons/FilterList';

// Services
import DockDoorService from '../../services/DockDoors.service';
import RoutesService from '../../services/Routes.service';
import OutboundLoadsService from '../../services/OutboundLoads.service';
import LocalStorageService from '../../services/LocalStorage.service';
import PermissionsService from '../../services/Permissions.service';
import EphemeralStateService from '../../services/EphemeralState.service';
import CSSectionTitleSeparator from '../primitives/csSectionTitleSeparator/CSSectionTitleSeparator';
import PlanIcon from '../../utils/svgs/PlanIcon';
import AlertDialog from '../alertDialog/AlertDialog';
import { downloadFile } from '../../utils/fileDownloads';
import { CSDualGridContainer } from '../primitives/csDualGridContainer';
import CSAsyncSelect from '../primitives/csAsyncSelect/CSAsyncSelect';
import { CSDatepicker } from '../primitives/csDatepicker';
import CSDialogTitleWithIcon from '../primitives/csDialogTitleWithIcon/CSDialogTitleWithIcon';
import { DialogIcons } from '../primitives/csDialogTitleWithIcon/CSDialogTitleWithIconTypes';
import {
  numberToString,
  parseNumericInput,
} from '../../utils/inputValidation';
import usePolling from '../../hooks/usePolling';

import CSHorizontalFilterBadgesGroup from '../primitives/csHorizontalFilterBadgesGroup/csHorizontalFilterBadgesGroup';
import { FilterDescription } from '../primitives/csHorizontalFilterBadgesGroup/csHorizontalFilterBadgesGroupTypes';
import { useAppDispatch } from '../../redux/store';
import { setLastVisitedModule } from '../../redux/slices/navigationSlice';

interface Props extends RouteComponentProps {
  classes: { [key: string]: string };
}

const OutboundLoads: React.FC<Props> = ({ location, classes }) => {
  const [batchExportEnabled, setBatchExportEnabled] =
    useState<boolean>(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showFiltersDrawer, setShowFiltersDrawer] = useState(false);
  const [selectedFilters, setSelectedFilters] =
    useState<SelectedFilters>();

  const [filterToRemove, setFilterToRemove] =
    useState<FilterDescription>();

  //TODO: Update this when CCC is merged
  const isBOLActive = true;

  const dispatch = useAppDispatch();

  // Filter drawer
  const getDrawerFilters = (filters: SelectedFilters) => {
    setSelectedFilters(filters);
    setLastUpdated(Date.now());
  };

  useEffect(() => {
    PermissionsService.redirectIfNoPermission(
      StaffPermissionPermissionsEnum.PACKAGE_READ,
    );

    dispatch(
      setLastVisitedModule({
        module: ModulesKeys.OUTBOUND_LOAD,
        permission: StaffPermissionPermissionsEnum.PACKAGE_READ,
      }),
    );
  }, [dispatch]);

  const trailerIdIsActive = useMemo(() => {
    return EphemeralStateService.getMyStationConfiguratation().GENERAL
      ?.BEHAVIORS?.LOAD_OPS?.trailer_id_active;
  }, []);

  const trailerIdLabel = useMemo(() => {
    return configurationUtils.getPropertylabel(
      'OUTBOUND_LOAD',
      'trailer_id',
    );
  }, []);

  // Get labels on each render cycle
  const COLUMNS_OUTBOUND_LOADS: Column[] = [
    {
      id: 'id',
      label: 'ID',
      width: 30,
      align: 'left',
    },
    {
      id: 'stops',
      label: configurationUtils.getPageTitle(false, 'STOP'),
      align: 'center',
      width: 'auto',
    },
    {
      id: 'containers_loaded_count',
      label: `Loaded ${configurationUtils.getPageTitle(
        false,
        'CONTAINER',
      )}`,
      align: 'center',
      width: 'auto',
      hide: !EphemeralStateService.getMyStationConfiguratation()
        .GENERAL.MODULES.CONTAINER.active,
    },
    {
      id: 'packages_loaded_count',
      label: `Loaded ${configurationUtils.getPageTitle(
        false,
        'PACKAGE',
      )}`,
      align: 'center',
      width: 'auto',
    },
    {
      id: 'load_status',
      label: 'Status',
      align: 'center',
      width: 'auto',
    },
    {
      id: 'dispatched_time',
      label: 'Close time',
      align: 'center',
      width: 'auto',
    },
    {
      id: 'dockdoor_name',
      label: configurationUtils.getPageTitle(false, 'DOCK_DOOR'),
      align: 'center',
      width: 'auto',
    },
    {
      id: 'trailer_id',
      label: trailerIdLabel,
      align: 'center',
      width: 'auto',
      hide: !trailerIdIsActive,
    },
    {
      id: 'is_dispatched',
      label: 'Is Dispatched',
      hide: true,
    },
    {
      id: 'bol',
      label: 'BOL',
      align: 'center',
      width: 'left',
      hide: !isBOLActive,
    },
  ];

  const fetchOutboundLoads = async (
    pageIndex: number,
    rowsPerPage: number,
    filterOptions?: filterObj[],
    filterByString?: string,
    sortedBy?: string,
  ) => {
    const res = await OutboundLoadsService.getAll({
      page: pageIndex,
      pageSize: rowsPerPage,
      owner:
        selectedFilters?.owner && selectedFilters.owner.values.length
          ? selectedFilters.owner.values.map((item: any) => item.id)
          : undefined,
      carrier:
        selectedFilters?.carrier &&
        selectedFilters.carrier.values.length
          ? selectedFilters.carrier.values.map((item: any) => item.id)
          : undefined,
      route:
        selectedFilters?.route && selectedFilters.route.values.length
          ? selectedFilters.route.values.map((item: any) => item.id)
          : undefined,
      stop:
        selectedFilters?.stop && selectedFilters.stop.values.length
          ? selectedFilters.stop.values.map((item: any) => item.id)
          : undefined,
      hidePending:
        selectedFilters?.hidePending &&
        selectedFilters.hidePending.values.length
          ? selectedFilters.hidePending.values[0].id === 'yes'
            ? true
            : selectedFilters.hidePending.values[0].id === 'no'
            ? false
            : undefined
          : undefined,
      outboundLoadStatus:
        selectedFilters?.loadStatus &&
        selectedFilters?.loadStatus.values.length
          ? (selectedFilters.loadStatus?.values[0].id as
              | 'OPEN'
              | 'LOADING'
              | 'DISPATCHED')
          : undefined,
      defects:
        selectedFilters?.loadDefect &&
        selectedFilters.loadDefect.values.length
          ? selectedFilters.loadDefect.values.map(
              (item: any) => item.id,
            )
          : undefined,
      sortBy: sortedBy as '-load_time' | 'load_time' | undefined,
    });

    res.data.results.forEach((ol: any) => {
      ol.load_time = renderTimestampString(ol.load_time);
      ol.dockdoor_name = ol.dockdoor_name || common.emptyValue;
      ol.dispatched_time = renderTimestampString(ol.dispatched_time);
      ol.is_dispatched = ol.load_status === 'DISPATCHED' ? 1 : 0;
      ol.trailer_id = ol.trailer_id || common.emptyValue;
      ol.stops = ol.stops
        ? formatFirstNItemsAndMore(ol.stops, 1)
        : common.emptyValue;

      ol.load_status = fedexLabels.getLabel(
        fedexLabels.formatLabel(ol.load_status),
      );
    });

    if (pageIndex === 1 && res.data.count > 0) {
      setBatchExportEnabled(true);
    }

    return res;
  };

  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [showAddDialog, setShowAddDialog] = useState<boolean>(false);
  const [
    showNoReportAvailableDialog,
    setShowNoReportAvailableDialog,
  ] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  //Add form fields
  const [capacity, setCapacity] = useState<number | null | undefined>(
    0,
  );
  const [route, setRoute] = useState<TypeAheadItem>();
  const [loadName, setLoadName] = useState<string>();
  const [loadTime, setLoadTime] = useState<Date>();
  const [dockDoor, setDockDoor] = useState<TypeAheadItem>();
  const [isDockDoorSetByUser, setIsDockDoorSetByUser] =
    useState<boolean>(false);
  const [lastUpdated, setLastUpdated] = useState<number>(0);
  const [trailerId, setTrailerId] = useState<string>();
  const [bol, setBol] = useState<string>();

  //Get available dock doors and routes -- run on first render
  useEffect(() => {
    PermissionsService.redirectIfNoPermission(
      StaffPermissionPermissionsEnum.OUTBOUNDLOAD_READ,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { startPolling, checkTaskId } = usePolling();

  const downloadCSV = async (outboundLoad: number) => {
    setShowProgress(true);
    try {
      const res = await OutboundLoadsService.getCSVExport({
        id: outboundLoad,
        fromDate: new Date().toISOString(),
        toDate: new Date().toISOString(),
      });

      const fileNamStr = `outbound_load_export_for_id_${outboundLoad}.csv`;
      startPolling(() => {
        checkTaskId(
          res.data.task_id,
          fileNamStr,
          setError,
          setShowProgress,
        );
      });
    } catch (e) {
      handleError(e as AxiosError);
    }
  };

  const downloadReport = async (outboundLoad: number) => {
    setShowProgress(true);
    try {
      downloadFile(
        await OutboundLoadsService.getReports(outboundLoad),
      );
    } catch (err) {
      if ((err as AxiosError)?.response?.status === 404) {
        setShowNoReportAvailableDialog(true);
      } else {
        handleError(err as AxiosError);
      }
    } finally {
      setShowProgress(false);
    }
  };

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e as AxiosError));
  };

  const onAfterDialogClose = () => {
    setShowAddDialog(false);
    setLoadTime(undefined);
    setDockDoor(undefined);
    setBol(undefined);
    setCapacity(0);
    setError(undefined);
    setRoute(undefined);
  };

  const addLoadHandler = async () => {
    setShowProgress(true);
    try {
      if (route) {
        let dd;
        const loadTimeVal = !loadTime ? getSystemDateNow() : loadTime;
        dockDoor?.value === '0'
          ? (dd = undefined)
          : (dd = Number(dockDoor?.value));
        await OutboundLoadsService.create(
          parseInt(route.value!),
          loadTimeVal,
          dd,
          capacity && isNaN(capacity) ? capacity : 0,
          loadName,
          trailerId || null,
          bol,
        );
        setLastUpdated(Date.now());
        onAfterDialogClose();
        setShowProgress(false);
      }
    } catch (e) {
      handleError(e as AxiosError);
      setShowProgress(false);
    }
  };

  const getSystemDateNow = () => {
    const systemDate = LocalStorageService.getSystemDate();
    const systemDateNow: Date = new Date(Date.now());
    systemDateNow.setDate(systemDate.getDate());
    systemDateNow.setMonth(systemDate.getMonth());
    systemDateNow.setFullYear(systemDate.getFullYear());
    return systemDateNow;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadRouteOptions = useCallback(
    debounce((inputValue: string, callback: any) => {
      RoutesService.getAll({ search: inputValue })
        .then((data) => {
          callback(
            data.data.results.map((dataEl: Route) => {
              return {
                value: dataEl.id,
                label: dataEl.name,
                routeData: dataEl,
              };
            }),
          );
        })
        .catch((e) => {
          handleError(e as AxiosError);
        });
    }, 500),
    [],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadDDOptions = useCallback(
    debounce((inputValue: string, callback: any) => {
      DockDoorService.search(inputValue)
        .then((data) => {
          callback(
            data.data.results.map((dataEl: DockDoorDetails) => {
              return {
                value: dataEl.id,
                label: dataEl.name,
              };
            }),
          );
        })
        .catch((e) => {
          handleError(e as AxiosError);
        });
    }, 500),
    [],
  );

  const renderDialog = () => {
    return (
      <Dialog
        open={showAddDialog}
        classes={{
          paper: classes.dialogPaper,
        }}
        maxWidth={false}
        TransitionComponent={Transition}
        onClose={() => {
          onAfterDialogClose();
        }}
      >
        <DialogTitle>
          <CSDialogTitleWithIcon
            title={`Add ${configurationUtils.getPageTitle(
              true,
              'OUTBOUND_LOAD',
            )}`}
            icon={DialogIcons.ADD}
          />
        </DialogTitle>
        {error && (
          <AlertBanner
            data-testid='outbound-loads-error-banner'
            className={classes.banner}
            severity='error'
            alertTitle={'Error'}
            alertMsg={error}
          />
        )}
        <DialogContent>
          <CSDualGridContainer
            childrenColumnConfiguration={{ 0: 12 }}
          >
            <CSAsyncSelect<TypeAheadItem>
              cacheOptions
              containerSize='fullHorizontal'
              label={`Select ${configurationUtils.getPageTitle(
                true,
                'ROUTE',
              )}`}
              placeholder={'Start Typing...'}
              value={route}
              isDisabled={!!error}
              loadOptions={loadRouteOptions}
              onChange={(value) => {
                if (value) {
                  const valuObj = value as TypeAheadItem & {
                    routeData: any;
                  };
                  setRoute(valuObj);
                  setLoadName(
                    valuObj.label ? 'OL-' + valuObj.label : '',
                  );
                  //Set default dockdoor
                  if (!isDockDoorSetByUser) {
                    if (
                      //Check if selected route has default dock door
                      valuObj.routeData.default_dockdoor &&
                      valuObj.routeData.default_dockdoor_name
                    ) {
                      setDockDoor({
                        label:
                          valuObj.routeData.default_dockdoor_name,
                        value: valuObj.routeData.default_dockdoor,
                      });
                    } else {
                      //Route without dockdoor, reset the dock door selection
                      setDockDoor(undefined);
                    }
                  }
                }
              }}
              menuPortalTarget={document.body}
              noOptionsMessage={noOptionsMessage}
            />
            {trailerIdIsActive && (
              <CSTextField
                containerSize='fullHorizontal'
                label={trailerIdLabel}
                placeholder={trailerIdLabel}
                type='text'
                value={trailerId || ''}
                onChange={(e) => {
                  setTrailerId(e.target.value.trim());
                }}
                InputLabelProps={{
                  shrink: true,
                  className: classes.selectLabel,
                }}
                inputProps={{ min: '0' }}
              />
            )}
            <CSTextField
              containerSize='fullHorizontal'
              data-testid='outbound-load-name'
              label='Name'
              placeholder='Name'
              type='text'
              value={loadName || ''}
              onChange={(e) => {
                setLoadName(e.target.value);
              }}
              onBlur={(e) => {
                if (e.target.value.trim() === '')
                  setLoadName(
                    route?.label ? 'OL-' + route?.label : '',
                  );
              }}
              InputLabelProps={{
                shrink: true,
                className: classes.selectLabel,
              }}
              inputProps={{ min: '0' }}
            />
            <CSDatepicker
              selectionType='datetime'
              label='Dispatch time'
              placeholder='Dispatch Time'
              variant={'inline'}
              initialValue={getSystemDateNow()}
              onAcceptSelection={(date) => {
                date
                  ? setLoadTime(date as Date)
                  : setLoadTime(undefined);
              }}
              id={'date-picker-new-load'}
            />
            <CSAsyncSelect<TypeAheadItem>
              containerSize='fullHorizontal'
              isClearable
              cacheOptions
              label={
                configurationUtils.isModuleActive('DOCK_DOOR')
                  ? configurationUtils.getPageTitle(true, 'DOCK_DOOR')
                  : 'Destination'
              }
              loadOptions={loadDDOptions}
              onChange={(option) => {
                setDockDoor(option as TypeAheadItem);
                setIsDockDoorSetByUser(Boolean(option));
              }}
              isDisabled={!!error}
              placeholder={'Start Typing...'}
              value={dockDoor}
              menuPortalTarget={document.body}
              noOptionsMessage={noOptionsMessage}
            />
            <CSTextField
              containerSize='fullHorizontal'
              data-testid='load-capacity'
              label='Capacity'
              placeholder='Capacity'
              value={numberToString(capacity)}
              onChange={(e) => {
                setCapacity(
                  parseNumericInput({
                    newValueFromInput: e.target.value,
                    currentValue: capacity,
                    minimumValue: 0,
                  }),
                );
              }}
              InputLabelProps={{
                shrink: true,
                className: classes.selectLabel,
              }}
              inputProps={{
                min: '0',
                step: '1',
              }}
              hideClear
              endAdornment={
                <div className={classes.unitAdornmentBox}>
                  {STRING_UNITS_VOLUME}
                </div>
              }
            />
            {isBOLActive && (
              <CSTextField
                containerSize='fullHorizontal'
                label='BOL'
                placeholder='BOL'
                value={bol || ''}
                onChange={(e) => {
                  setBol(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                  className: classes.selectLabel,
                }}
                inputProps={{ min: '0' }}
              />
            )}
          </CSDualGridContainer>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <CSButton
            variant='outlined'
            color='secondary'
            data-testid={'outbound-loads-dialog-cancel'}
            onClick={() => {
              onAfterDialogClose();
            }}
          >
            Cancel
          </CSButton>
          <CSButton
            variant='contained'
            color='secondary'
            onClick={() => {
              addLoadHandler();
            }}
            data-testid={'outbound-loads-dialog-save'}
            disabled={!route}
          >
            Save
          </CSButton>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort -
    ${configurationUtils.getPageTitle(false, 'OUTBOUND_LOAD')} ${
            queryString.parse(location.search)['page']
              ? '- Page ' + queryString.parse(location.search)['page']
              : ''
          }`}
        </title>
      </Helmet>
      <Layout navCurrent='OUTBOUND_LOAD'>
        {showProgress && <ProgressIndicator />}
        {renderDialog()}
        <ExportCSVDialog
          downloadName={'outbound_load_export'}
          key={'dialog' + lastUpdated}
          type='OUTBOUND_LOADS'
          isOpen={showDialog}
          onAfterClose={() => {
            setShowDialog(false);
          }}
        />
        <AlertDialog
          title={`No files available yet`}
          msg={`No files available yet, please allow for a few minutes of processing time.`}
          onPrimaryAction={() => {
            setShowNoReportAvailableDialog(false);
          }}
          isOpen={showNoReportAvailableDialog}
        />

        <FiltersDrawer
          isOpen={showFiltersDrawer}
          onAfterClose={() => {
            setShowFiltersDrawer(false);
          }}
          getFilters={getDrawerFilters}
          removeFilter={filterToRemove}
        />

        <Grid
          container
          className={classes.header}
          alignItems='center'
        >
          <Grid item xs={12} sm={6}>
            <Typography variant='h3' component='h2'>
              {configurationUtils.getPageTitle()}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
            {PermissionsService.hasPermission(
              StaffPermissionPermissionsEnum.OUTBOUNDLOAD_WRITE,
            ) && (
              <CSButton
                className={classes.mb5px}
                disabled={!!error}
                variant='contained'
                color={{
                  buttonColor: 'secondary',
                  iconColor: 'primary',
                }}
                data-testid={'load-add-button'}
                onClick={(e) => {
                  e.preventDefault();
                  setShowAddDialog(true);

                  setLoadName(
                    route?.label ? 'OL-' + route?.label : '',
                  );
                }}
                startIcon={<AddBoxOutlinedIcon />}
              >
                Add{' '}
                {configurationUtils.getPageTitle(
                  true,
                  'OUTBOUND_LOAD',
                )}
              </CSButton>
            )}
            {PermissionsService.hasPermission(
              StaffPermissionPermissionsEnum.OUTBOUNDLOAD_REPORT_READ,
            ) && (
              <CSButton
                className={classes.mb5px}
                variant='contained'
                color='primary'
                style={{ marginLeft: 15 }}
                disabled={!batchExportEnabled}
                onClick={() => {
                  setShowDialog(true);
                }}
              >
                <CloudDownloadOutlined style={{ marginRight: 10 }} />
                Batch Export CSVs
              </CSButton>
            )}
            <CSButton
              variant='outlined'
              color='secondary'
              className={clx(
                classes.headerButtons,
                classes.headerFilterButton,
              )}
              onClick={() => {
                setShowFiltersDrawer(true);
              }}
            >
              <FilterListIcon />
            </CSButton>
          </Grid>
          <CSSectionTitleSeparator topMargin={10} bottomMargin={24} />
          <Grid container item xs={12} spacing={1}>
            <CSHorizontalFilterBadgesGroup
              selectedFilters={selectedFilters}
              onRemoveFilter={(toRemove) =>
                setFilterToRemove(toRemove)
              }
            />
          </Grid>
        </Grid>

        {PermissionsService.hasPermission(
          StaffPermissionPermissionsEnum.OUTBOUNDLOAD_READ,
        ) &&
          !!lastUpdated && (
            <PaginatedTable
              key={'table' + lastUpdated}
              title=''
              columns={COLUMNS_OUTBOUND_LOADS}
              dataTestIdPrefix={'outbound-loads'}
              fetch={fetchOutboundLoads}
              rowsLoadDetailPages={true}
              detailsPageBasePath={AuthRoutes.OUTBOUND_LOAD}
              defaultSort={
                (queryString.parse(location.search)[
                  'sortBy'
                ] as string) || undefined
              }
              sortableBy={['load_time']}
              actions={[
                {
                  tableLabel: ' ',
                  columnLabel: <CloudDownloadOutlined />,
                  callbackProperty: 'id',
                  qualifier: 'is_dispatched',
                  callback: downloadCSV,
                },
                {
                  tableLabel: ' ',
                  columnLabel: (
                    <PlanIcon className={classes.planIcon} />
                  ),
                  callbackProperty: 'id',
                  qualifier: 'is_dispatched',
                  callback: downloadReport,
                },
              ]}
            />
          )}
      </Layout>
    </>
  );
};

export default withStyles(styles)(OutboundLoads);
