import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SELECTOR_ENTITY_TYPE } from '../../components/navigation/types';
import { RootState } from '../store';
import { ModulesKeys } from '../../interfaces/routes';
import { StaffPermissionPermissionsEnum } from 'cloudsort-client';

const initialState: {
  shouldUpdateUrl: boolean;
  shouldUpdateDynamicRoutes: boolean;
  shouldUpdateStationsData: boolean;
  selectedEntityId: number;
  selectedEntityType: SELECTOR_ENTITY_TYPE;
  lastVisitedModule: ModulesKeys | null;
  lastVisitedModulePermissionNeeded: StaffPermissionPermissionsEnum | null;
} = {
  shouldUpdateUrl: false,
  shouldUpdateDynamicRoutes: false,
  shouldUpdateStationsData: false,
  selectedEntityId: 0,
  selectedEntityType: SELECTOR_ENTITY_TYPE.STATION,
  lastVisitedModule: null,
  lastVisitedModulePermissionNeeded: null,
};

export const navigationSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setSelectedEntity: (
      state,
      action: PayloadAction<{
        id: number;
        type: SELECTOR_ENTITY_TYPE;
      }>,
    ) => {
      state.selectedEntityId = action.payload.id;
      state.selectedEntityType = action.payload.type;
    },
    setShouldUpdateUrl: (state, action: PayloadAction<boolean>) => {
      state.shouldUpdateUrl = action.payload;
    },
    setSholdUpdateDynamicRoutes: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.shouldUpdateDynamicRoutes = action.payload;
    },
    setShouldUpdateStationsData: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.shouldUpdateStationsData = action.payload;
    },
    setLastVisitedModule: (
      state,
      action: PayloadAction<{
        module: ModulesKeys;
        permission: StaffPermissionPermissionsEnum;
      }>,
    ) => {
      state.lastVisitedModule = action.payload.module;
      state.lastVisitedModulePermissionNeeded =
        action.payload.permission;
    },
  },
});

export const {
  setSelectedEntity,
  setShouldUpdateUrl,
  setSholdUpdateDynamicRoutes,
  setShouldUpdateStationsData,
  setLastVisitedModule,
} = navigationSlice.actions;

export const navigationConfig = (state: RootState) =>
  state.navigation;

export default navigationSlice.reducer;
