import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import { INTERACTION } from '../../utils';
import WebAppDesktop from './WebApp.desktop';
import WebAppSearch from './WebApp.search';
import WebAppMobile from './WebApp.mobile';
import { CurrentView } from '../Modules';

interface Props {
  classes: { [key: string]: string };
  data: any;
  setData: (data: any) => void;
  interaction?: INTERACTION;
  currentView: CurrentView;
  goToPreviousPage: () => void;
}

const WebApp: React.FC<Props> = ({
  classes,
  data,
  setData,
  interaction,
  currentView,
  goToPreviousPage,
}) => {
  const selectViewToRender = () => {
    if (currentView === CurrentView.SEARCH) {
      return (
        <WebAppSearch
          data={data}
          interaction={interaction}
          setData={setData}
        />
      );
    }
    if (currentView === CurrentView.MOBILE) {
      return (
        <WebAppMobile
          data={data}
          interaction={interaction}
          setData={setData}
          goToPreviousPage={goToPreviousPage}
        />
      );
    }
    if (currentView === CurrentView.DESKTOP) {
      return (
        <WebAppDesktop
          data={data}
          interaction={interaction}
          setData={setData}
        />
      );
    }
    return <></>;
  };

  return <>{data ? selectViewToRender() : null}</>;
};

export default withStyles({})(WebApp);
