import {
  NestedProcessStepSerializerPackageProcessStepEnum,
  NestedProcessStepSerializerContainerProcessStepEnum,
} from 'cloudsort-client';
//Services
import ContainersService from './Containers.service';
import PackagesService from './Packages.service';

class DashboardStats {
  constructor() {
    this.getPackagesByStatus = this.getPackagesByStatus.bind(this);
    this.getContainersByStatus =
      this.getContainersByStatus.bind(this);
  }

  public async getPackagesByStatus(params: {
    owner?: number[];
    carrier?: number[];
    route?: number[];
    destination?: number[];
    container?: number[];
    isManifested?: boolean;
    processStep?: [NestedProcessStepSerializerPackageProcessStepEnum];
    excludeProcessStep?: [
      NestedProcessStepSerializerPackageProcessStepEnum,
    ];
    isScanned?: boolean;
    area?: number;
    manifest?: number[];
    inSystem?: boolean;
    isArrived?: boolean;
    hidePending?: boolean;
  }) {
    return PackagesService.getAll({
      ...params,
      ...{
        pageSize: 1,
        sortBy: 'process_step_time',
      },
    });
  }

  public async getVolume(params: {
    area?: number;
    inSystem?: boolean;
    owner?: number[];
    carrier?: number[];
    route?: number[];
    destination?: number[];
    isManifested?: boolean;
    isScanned?: boolean;
    isArrived?: boolean;
    hidePending?: boolean;
    firstScannedInWindow?: boolean;
  }) {
    return PackagesService.getVolume({
      ...params,
    });
  }

  public async getContainersByStatus(params: {
    processStep: NestedProcessStepSerializerContainerProcessStepEnum[];
    area?: number;
    owner?: number[];
    carrier?: number[];
    route?: number[];
    destination?: number[];
    hidePending?: boolean;
  }) {
    return ContainersService.getAll({
      ...params,
      pageSize: 1,
      sortBy: 'process_step_time',
    });
  }
}

export default new DashboardStats();
