import { TrackableContainersApi } from 'cloudsort-client';
import axios from '../utils/axios';

class TrackableContainers {
  private api: TrackableContainersApi;

  constructor() {
    this.api = new TrackableContainersApi(
      undefined,
      undefined,
      axios as any,
    );
  }

  public async getAll(params: {
    page: number;
    pageSize: number;
    assetId?: string;
    cornerLl?: string;
    cornerRu?: string;
  }) {
    return this.api.trackableContainersList(params);
  }

  public async getMapPoints(params: {
    cornerLl: string;
    cornerRu: string;
    assetId?: string;
  }) {
    return this.api.trackableContainersMap(params);
  }

  public async getById(id: number) {
    return this.api.trackableContainersRead({ id });
  }

  public async getPingHistoryById(id: number) {
    return this.api.trackableContainersHistory({ id });
  }
}

export default new TrackableContainers();
