import { createStyles, Theme, withStyles } from '@material-ui/core';
import { Position } from 'react-flow-renderer';
import flowColors from '../flowColors';
import Handles from './parts/Handles';

interface Props {
  classes: { [key: string]: string };
  sourcePosition: Position;
  targetPosition: Position;
}

const GhostNode = ({
  classes,
  sourcePosition,
  targetPosition,
}: Props) => {
  return (
    <div
      style={{
        width: '8px',
        height: '8px',
        position: 'relative',
      }}
    >
      <span className={classes.circle}></span>
      <Handles
        sourcePosition={sourcePosition}
        targetPosition={targetPosition}
      />
    </div>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    circle: {
      display: 'block',
      width: 12,
      height: 12,
      borderRadius: '50%',
      backgroundColor: flowColors.black,
      position: 'absolute',
      top: '50%',
      marginTop: -6,
      left: '-2px',
    },
  })),
)(GhostNode);
