import React, { useEffect, Dispatch, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  Divider,
  Tooltip,
  Theme,
} from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import {
  DragDropContext,
  Droppable,
  Draggable,
} from 'react-beautiful-dnd';
import enumToLabel from '../../../../utils/enumToLabel';
import detailsPageStyles from '../../../commonStyles/detailsPage.style';
import { INTERACTION } from '../../utils';
import classNames from 'classnames';
import configPageStyles from '../../../commonStyles/configPage.style';
import { processSearchQuery } from '../searchQuery';
import Highlighter from 'react-highlight-words';
import { CurrentView, ModuleName } from '../Modules';

// Icons
import DragHandleIcon from '@material-ui/icons/DragHandle';

// Redux
import { useSearchResultSections } from '../../useSearchResultSections';
import { CSSwitch } from '../../../primitives';

interface Props {
  classes: { [key: string]: string };
  data: any;
  dataModules: any;
  interaction?: INTERACTION;
  setPartialData: Dispatch<any>;
  currentView: CurrentView;
}

const Navigation: React.FC<Props> = ({
  classes,
  data,
  dataModules,
  interaction,
  setPartialData,
  currentView,
}) => {
  const [webAppData, setWebAppData] = useState<any[]>([]);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [searchResult, setSearchResult] = useState<string[]>([]);
  const [showWholeSection, setShowWholeSection] =
    useState<boolean>(true);

  const SECTION_TITLE = 'Navigation Menu';

  const { searchQuery, updateSections } = useSearchResultSections(
    ModuleName.WEB,
    SECTION_TITLE,
  );

  const getElKey = (obj: any) => {
    const el = Object.entries(data).find(
      ([key, value]: any) => value === obj,
    );
    return el?.length ? el[0] : '';
  };

  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    result.forEach((el: any, index) => {
      el.order = index + 1;
    });

    return result;
  };

  const updatePartialData = () => {
    if (data) {
      const dataObj = { ...data };
      Object.entries(data).forEach(([key, value]: any) => {
        const webEl = webAppData.find(
          (el: any) => el.label === value.label,
        );
        data[key] = webEl;
      });
      setPartialData(dataObj);
      setHasChanges(false);
    }
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    setWebAppData(
      reorder(
        webAppData,
        result.source.index,
        result.destination.index,
      ),
    );
    setHasChanges(true);
  };

  const renderActiveToggle = (
    webAppDataEl: any,
    disabled: boolean,
  ) => {
    return (
      <CSSwitch
        data-testid={`navigation-switch-active-${getElKey(
          webAppDataEl,
        )}`}
        color='default'
        size='small'
        disabled={interaction === INTERACTION.READ || disabled}
        checked={webAppDataEl.active}
        onChange={() => {
          data[getElKey(webAppDataEl)].active = !webAppDataEl.active;
          setPartialData({
            ...data,
          });
          setHasChanges(true);
        }}
      />
    );
  };

  useEffect(
    function filterNavigationDataBySearchResults() {
      if (currentView === CurrentView.SEARCH && data) {
        const tmpData: { [item: string]: any } = {};
        for (let item of Object.entries(data)) {
          if (item[0] === 'DASHBOARD') continue;
          if (
            showWholeSection ||
            searchResult.some((result) =>
              enumToLabel(item[0])
                .toLowerCase()
                .includes(result.toLowerCase()),
            )
          ) {
            tmpData[item[0]] = item[1];
          }
        }
        setWebAppData(
          Object.entries(tmpData)
            .map(([key, value]: any) => value)
            .sort((a, b) => a.order - b.order),
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, searchResult],
  );

  useEffect(
    function setNavigationData() {
      if (data && currentView !== CurrentView.SEARCH) {
        setWebAppData(
          Object.entries(data)
            .filter(([key, value]: any) => key !== 'DASHBOARD')
            .map(([key, value]: any) => value)
            .sort((a, b) => a.order - b.order),
        );
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, currentView],
  );

  useEffect(() => {
    if (hasChanges) {
      updatePartialData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webAppData]);

  useEffect(
    function processSearch() {
      if (currentView === CurrentView.SEARCH) {
        const dynamicLabels: string[] = [];
        for (let item of Object.entries(data)) {
          if (item[0] === 'DASHBOARD') continue;
          dynamicLabels.push(enumToLabel(item[0]));
        }

        //Show whole section is there are results in a title
        setShowWholeSection(
          processSearchQuery(searchQuery, [SECTION_TITLE]).length !==
            0,
        );

        const results = processSearchQuery(searchQuery, [
          SECTION_TITLE,
          ...dynamicLabels,
        ]);
        setSearchResult(results);
        updateSections(results);
      } else {
        setSearchResult([]);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchQuery, currentView],
  );

  const renderComponent = () => {
    return (
      <>
        <Grid item sm={12} style={{ width: '100%' }}>
          <Box>
            <Box
              className={classNames(
                classes.moduleTitle,
                classes.pLeft,
              )}
              mb={1}
              mt={2}
            >
              <Highlighter
                highlightClassName={classes.searchResultsMark}
                searchWords={searchResult}
                autoEscape={true}
                textToHighlight={SECTION_TITLE}
              />
            </Box>

            <Box mt={2}>
              <Box mb={2}>
                <Grid
                  container
                  item
                  sm={12}
                  className={classes.textHeader}
                >
                  <Grid item sm={1} xs={2} className={classes.pLeft}>
                    Order
                  </Grid>
                  <Grid item sm={9} xs={4}>
                    Item
                  </Grid>
                  <Grid item sm={1} xs={3}>
                    Active
                  </Grid>
                  <Grid item sm={1} xs={3}>
                    Divider After
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider className={classes.divider} />
                </Grid>
              </Box>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId='droppable'>
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {webAppData?.map(
                        (
                          webAppDataEl: any,
                          index: number,
                          array: any[],
                        ) => {
                          const elKey = getElKey(webAppDataEl);
                          return (
                            <Draggable
                              key={`${webAppDataEl.order}-${index}`}
                              draggableId={webAppDataEl.order.toString()}
                              isDragDisabled={
                                interaction === INTERACTION.READ ||
                                !showWholeSection
                              }
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <Grid
                                    container
                                    item
                                    sm={12}
                                    className={classes.row}
                                    style={{
                                      display: !webAppDataEl.order
                                        ? 'none'
                                        : '',
                                    }}
                                  >
                                    <Grid
                                      item
                                      sm={1}
                                      xs={2}
                                      className={classes.dndIcon}
                                    >
                                      <DragHandleIcon />
                                    </Grid>
                                    <Grid
                                      item
                                      sm={9}
                                      xs={4}
                                      className={classes.text}
                                    >
                                      <Highlighter
                                        highlightClassName={
                                          classes.searchResultsMark
                                        }
                                        searchWords={searchResult}
                                        autoEscape={true}
                                        textToHighlight={enumToLabel(
                                          elKey,
                                        )}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      sm={1}
                                      xs={3}
                                      className={
                                        classes.muiSwitchHoler
                                      }
                                    >
                                      {dataModules[elKey]?.active ? (
                                        renderActiveToggle(
                                          webAppDataEl,
                                          false,
                                        )
                                      ) : (
                                        <Tooltip
                                          title={`${enumToLabel(
                                            elKey,
                                          )} module is currently disabled`}
                                          placement='top'
                                          PopperProps={{
                                            popperOptions: {
                                              modifiers: {
                                                offset: {
                                                  enabled: true,
                                                  offset: '0, -65px',
                                                },
                                              },
                                            },
                                          }}
                                        >
                                          <div
                                            style={{
                                              maxWidth: 'fit-content',
                                            }}
                                          >
                                            {renderActiveToggle(
                                              webAppDataEl,
                                              true,
                                            )}
                                          </div>
                                        </Tooltip>
                                      )}
                                    </Grid>
                                    <Grid
                                      item
                                      sm={1}
                                      xs={3}
                                      className={
                                        classes.muiSwitchHoler
                                      }
                                    >
                                      <CSSwitch
                                        data-testid={`navigation-switch-divider-after-${getElKey(
                                          webAppDataEl,
                                        )}`}
                                        color='default'
                                        size='small'
                                        disabled={
                                          interaction ===
                                          INTERACTION.READ
                                        }
                                        checked={
                                          webAppDataEl.divider_after
                                        }
                                        onChange={() => {
                                          data[elKey].divider_after =
                                            !webAppDataEl.divider_after;
                                          setPartialData({
                                            ...data,
                                          });
                                          setHasChanges(true);
                                        }}
                                      />
                                    </Grid>
                                    {index !== array.length - 1 && (
                                      <Grid item xs={12}>
                                        <Divider
                                          className={classes.divider}
                                        />
                                      </Grid>
                                    )}
                                  </Grid>
                                </div>
                              )}
                            </Draggable>
                          );
                        },
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Box>
          </Box>
        </Grid>
      </>
    );
  };

  return currentView === CurrentView.SEARCH && !searchResult.length
    ? null
    : renderComponent();
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...configPageStyles(theme),
  })),
)(Navigation);
