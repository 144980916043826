import { createStyles, Theme } from '@material-ui/core/styles';
import colors from '../../utils/colors';

export default createStyles((theme: Theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 15,
    boxShadow: '0 4px 16px 0 rgba(0,0,0,0.08)',
    padding: 16,
  },

  //Header
  cardHeader: {
    position: 'relative',
    width: '100%',
    margin: '10px 0',
    minHeight: '40px',
    '&  .MuiCircularProgress-colorPrimary': {
      color: `${theme.palette.primary.main} !important`,
      zIndex: 2,
    },
    '&  .MuiCircularProgress-colorSecondary': {
      color: `${theme.palette.grey[200]} !important`,
    },
  },
  cardTitle: {
    margin: '0!important',
    fontSize: '18px',
    paddingRight: '64px',
  },
  percentageIndicator: {
    position: 'absolute',
    top: '-10px',
    right: 0,
  },
  highlighedNumber: {
    color: theme.palette.error?.contrastText,
    width: '40px',
    height: '40px',
    fontSize: '16px',
    lineHeight: '16px',
    backgroundColor: theme.palette.error?.main,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  //Container styles
  containerDark: {
    backgroundColor: theme.palette.quaternary?.main,
    color: theme.palette.quaternary?.contrastText,

    '& $cardTitle': {
      color: theme.palette.quaternary?.contrastText,
    },

    '& $percentageIndicator': {
      color: theme.palette.quaternary?.contrastText,
    },
  },
  containerWhite: {
    backgroundColor: theme.palette.background.paper,
  },
  containerGold: {
    backgroundColor: theme.palette.quaternary?.main,
  },

  //Single stat

  singleStat: {
    width: '100%',
    padding: 10,
    margin: '5px 0',
  },

  //Heading before items
  headingBefore: {
    textAlign: 'left',
    fontSize: '12px',
    width: '100%',
    margin: '10px 0',
  },

  //Layouts

  //Regular
  itemTitle: {
    fontSize: '14px',
    '& a': {
      color: 'inherit',
      textDecoration: 'none',
      cursor: 'pointer',
    },
  },
  itemCountRight: {
    textAlign: 'right',
    fontSize: '18px',
    '& a': {
      color: 'inherit',
      textDecoration: 'none',
      cursor: 'pointer',
      margin: '-10px',
      padding: '10px 10px 10px 40px',
    },
  },

  // Title and count
  itemTitleSmall: {
    fontSize: '12px',
    marginBottom: 0,
    '& a': {
      color: 'inherit',
      textDecoration: 'none',
      cursor: 'pointer',
    },
  },
  itemCountLarge: {
    fontSize: '24px',
    '& a': {
      color: 'inherit',
      textDecoration: 'none',
      cursor: 'pointer',
    },
  },

  //Variant styles
  noBgWhite: {
    backgroundColor: 'transparent',
    color: colors.white,
    boxShadow: 'none!important',
    margin: 0,
    //Loader color
    '&  .MuiCircularProgress-colorPrimary': {
      color: `${colors.white} !important`,
    },
  },
  noBgBlack: {
    backgroundColor: 'transparent',
    color: colors.black,
    boxShadow: 'none!important',
    margin: 0,
  },
  whitePaper: {
    color: colors.dark,
    //Loader color
    '&  .MuiCircularProgress-colorPrimary': {
      color: `${colors.dark} !important`,
    },
  },
  goldPaper: {
    backgroundColor: theme.palette.secondary.light,
  },

  //Dwell Badge

  itemDwellBadge: {
    fontSize: '12px',
    background: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    padding: '0px 10px',
    borderRadius: theme.shape.borderRadius,
    '& a': {
      color: 'inherit',
      textDecoration: 'none',
    },
  },

  //SwitchBox
  switchBox: {
    width: '100%',
    textAlign: 'right',
  },

  switchTrack: {
    backgroundColor: theme.palette.tertiary?.main,
    opacity: 1,
  },

  //Filter tooltip
  filterTooltip: {
    '& p': {
      fontSize: '12px',
    },
    '& ul': {
      fontSize: '12px',
      padding: '0 0 0 20px',
    },
  },
  filterIcon: {
    fontSize: '18px',
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: '2px',
    borderRadius: '50%',
    margin: '0 5px -2px 0',
  },
}));
