import React, { Ref } from 'react';

const PlanIcon = React.forwardRef(
  (
    props: React.PropsWithRef<JSX.IntrinsicElements['svg']>,
    ref: Ref<SVGSVGElement>,
  ) => {
    return (
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
        ref={ref}
        fill='none'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M16.6667 3.80952H13.7895C13.4095 2.76 12.4143 2 11.2381 2C10.0619 2 9.06667 2.76 8.68667 3.80952H5.80952C4.81429 3.80952 4 4.62381 4 5.61905V19.1905C4 20.1857 4.81429 21 5.80952 21H11.3376C10.8038 20.4843 10.3695 19.869 10.0529 19.1905H5.80952V5.61905H7.61905V8.33333H14.8571V5.61905H16.6667V10.2152C17.309 10.3057 17.9152 10.4957 18.4762 10.7581V5.61905C18.4762 4.62381 17.6619 3.80952 16.6667 3.80952ZM11.2381 16.4765C11.2381 13.9794 13.2647 11.9527 15.7619 11.9527C18.259 11.9527 20.2857 13.9794 20.2857 16.4765C20.2857 18.9737 18.259 21.0004 15.7619 21.0004C13.2647 21.0004 11.2381 18.9737 11.2381 16.4765ZM15.3095 16.6589L17.2548 18.6041L17.8881 17.9618L16.2143 16.288V13.7637H15.3095V16.6589ZM10.3334 4.7157C10.3334 5.21332 10.7405 5.62046 11.2381 5.62046C11.7357 5.62046 12.1429 5.21332 12.1429 4.7157C12.1429 4.21808 11.7357 3.81094 11.2381 3.81094C10.7405 3.81094 10.3334 4.21808 10.3334 4.7157Z'
        />
        <mask
          id='mask0_559_8382'
          style={{ maskType: 'alpha' }}
          maskUnits='userSpaceOnUse'
          x='4'
          y='2'
          width='17'
          height='20'
        >
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M16.6667 3.80952H13.7895C13.4095 2.76 12.4143 2 11.2381 2C10.0619 2 9.06667 2.76 8.68667 3.80952H5.80952C4.81429 3.80952 4 4.62381 4 5.61905V19.1905C4 20.1857 4.81429 21 5.80952 21H11.3376C10.8038 20.4843 10.3695 19.869 10.0529 19.1905H5.80952V5.61905H7.61905V8.33333H14.8571V5.61905H16.6667V10.2152C17.309 10.3057 17.9152 10.4957 18.4762 10.7581V5.61905C18.4762 4.62381 17.6619 3.80952 16.6667 3.80952ZM11.2381 16.4765C11.2381 13.9794 13.2647 11.9527 15.7619 11.9527C18.259 11.9527 20.2857 13.9794 20.2857 16.4765C20.2857 18.9737 18.259 21.0004 15.7619 21.0004C13.2647 21.0004 11.2381 18.9737 11.2381 16.4765ZM15.3095 16.6589L17.2548 18.6041L17.8881 17.9618L16.2143 16.288V13.7637H15.3095V16.6589ZM10.3334 4.7157C10.3334 5.21332 10.7405 5.62046 11.2381 5.62046C11.7357 5.62046 12.1429 5.21332 12.1429 4.7157C12.1429 4.21808 11.7357 3.81094 11.2381 3.81094C10.7405 3.81094 10.3334 4.21808 10.3334 4.7157Z'
          />
        </mask>
        <g mask='url(#mask0_559_8382)'></g>
      </svg>
    );
  },
);
export default PlanIcon;
