import { AxiosError } from 'axios';
import { APIException } from 'cloudsort-client';

class ErrorHandler {
  public async getLabel(error: AxiosError) {
    const data = error.response?.data as APIException;

    if (error?.config?.responseType === 'blob' && data) {
      try {
        const msg = await this.readFileAsync(data);
        const errorObj = JSON.parse(msg as string);
        if (errorObj.non_field_errors) {
          return errorObj.non_field_errors[0];
        } else if (errorObj.length) {
          return errorObj[0];
        }
      } catch (e) {
        console.error(e);
      }
    }

    // BE error
    if (data?.message) {
      return data.message;
    } else if (error.response?.statusText) {
      return error.response.statusText;
    } else if (error.message) {
      // Network error
      return error.message;
    }
  }

  public getConflictingZipcodes(errorData: APIException) {
    return (
      errorData.error_context?.conflicts?.map(
        (item: any) => item.zipcode,
      ) || []
    );
  }

  private readFileAsync(file: any) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = reject;
      reader.readAsText(file);
    });
  }
}

export default new ErrorHandler();
