import React from 'react';
import {
  withStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles';
import { Container } from '../primitives';

import Header from '../header/Header';

//MapBox styles
import 'mapbox-gl/dist/mapbox-gl.css';

interface Props {
  classes: { [key: string]: string };
  children: any;
  maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs';
}

const TrackableContainersLayout: React.FC<Props> = ({
  classes,
  children,
  maxWidth = 'xl',
}) => {
  return (
    <>
      <Header
        type='trackableContainers'
        navActive={false}
        setNavActive={() => {}}
      />
      <Container
        className={classes.mainContainer}
        maxWidth={maxWidth}
      >
        {children}
      </Container>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    mainContainer: {
      overflow: 'hidden',
      minHeight: 'calc(100vh - 100px)',
      paddingTop: 68,
      [theme.breakpoints.down('sm')]: {
        paddingTop: 100,
      },
    },
  })),
)(TrackableContainersLayout);
