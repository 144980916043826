import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

type SerializableBounds = {
  _sw: {
    lng: number;
    lat: number;
  };
  _ne: {
    lng: number;
    lat: number;
  };
};

const initialState: {
  savedBounds: SerializableBounds;
} = {
  savedBounds: {
    _sw: {
      lng: -120,
      lat: 48,
    },
    _ne: {
      lng: -80,
      lat: 32,
    },
  },
};

export const trcSlice = createSlice({
  name: 'trc',
  initialState,
  reducers: {
    setSavedBounds: (
      state,
      action: PayloadAction<SerializableBounds>,
    ) => {
      state.savedBounds = action.payload;
    },
  },
});

export const { setSavedBounds } = trcSlice.actions;

export const selectSavedBounds = (state: RootState) =>
  state.trc.savedBounds;

export default trcSlice.reducer;
