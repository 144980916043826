import EphemeralStateService from '../services/EphemeralState.service';

export const stationDashboardUrlId = 'stationId';

export enum ModulesKeys {
  AREA = 'AREA',
  CONTAINER = 'CONTAINER',
  DASHBOARD = 'DASHBOARD',
  DEVICE = 'DEVICE',
  DOCK_DOOR = 'DOCK_DOOR',
  LOADPLAN = 'LOADPLAN',
  MANIFEST = 'MANIFEST',
  INBOUND_LOAD = 'INBOUND_LOAD',
  OUTBOUND_LOAD = 'OUTBOUND_LOAD',
  PACKAGE = 'PACKAGE',
  HELD_PACKAGE = 'HELD_PACKAGE',
  ROUTE = 'ROUTE',
  STAFF = 'STAFF',
  STOP = 'STOP',
  STATION_LAYOUT = 'STATION_LAYOUT',
  STATION_PLANNER = 'STATION_PLANNER',
}

const getMyStationConfiguratation = () => {
  return EphemeralStateService.getMyStationConfiguratation();
};

export const getModuleUrl = (key: ModulesKeys) => {
  const modulesConfig =
    getMyStationConfiguratation()?.GENERAL?.MODULES || null;
  return modulesConfig
    ? `/${modulesConfig[key]?.label_plural
        .toLowerCase()
        .replace(/ /g, '_')}`
    : '';
};

export const isModuleActive = (key: ModulesKeys) => {
  const modulesConfig =
    getMyStationConfiguratation()?.GENERAL?.MODULES || null;
  return modulesConfig ? modulesConfig[key]?.active : false;
};

export let AuthRoutes = {
  DASHBOARD: getModuleUrl(ModulesKeys.DASHBOARD),
  DEVICE: getModuleUrl(ModulesKeys.DEVICE),
  CONTAINER: getModuleUrl(ModulesKeys.CONTAINER),
  PACKAGE: getModuleUrl(ModulesKeys.PACKAGE),
  HELD_PACKAGE: getModuleUrl(ModulesKeys.HELD_PACKAGE),
  INBOUND_LOAD: getModuleUrl(ModulesKeys.INBOUND_LOAD),
  OUTBOUND_LOAD: getModuleUrl(ModulesKeys.OUTBOUND_LOAD),
  ROUTE: getModuleUrl(ModulesKeys.ROUTE),
  STAFF: getModuleUrl(ModulesKeys.STAFF),
  AREA: getModuleUrl(ModulesKeys.AREA),
  MANIFEST: getModuleUrl(ModulesKeys.MANIFEST),
  DOCK_DOOR: getModuleUrl(ModulesKeys.DOCK_DOOR),
  STOP: getModuleUrl(ModulesKeys.STOP),
  STATION_LAYOUT: getModuleUrl(ModulesKeys.STATION_LAYOUT),
  STATION_PLANNER: getModuleUrl(ModulesKeys.STATION_PLANNER),
  LOADPLAN:
    getModuleUrl(ModulesKeys.STOP) +
    getModuleUrl(ModulesKeys.LOADPLAN),
  // Not mapped by BE
  MAP: '/map',
  SHIFT_PRESETS: '/shift-presets',
  SHIFT_CALENDAR: '/shift-calendar',
  SCHEME: '/schemes',
  WEBHOOKS: '/webhooks',
  // Constants urls
  PROFILE: '/profile',
  CONFIGURATION: '/configuration',
  FLOW: '/flow',
};

export const resetDynamicRoutes = () => {
  AuthRoutes = {
    ...AuthRoutes,
    ...{
      DASHBOARD: getModuleUrl(ModulesKeys.DASHBOARD),
      DEVICE: getModuleUrl(ModulesKeys.DEVICE),
      CONTAINER: getModuleUrl(ModulesKeys.CONTAINER),
      PACKAGE: getModuleUrl(ModulesKeys.PACKAGE),
      HELD_PACKAGE: getModuleUrl(ModulesKeys.HELD_PACKAGE),
      INBOUND_LOAD: getModuleUrl(ModulesKeys.INBOUND_LOAD),
      OUTBOUND_LOAD: getModuleUrl(ModulesKeys.OUTBOUND_LOAD),
      ROUTE: getModuleUrl(ModulesKeys.ROUTE),
      STAFF: getModuleUrl(ModulesKeys.STAFF),
      AREA: getModuleUrl(ModulesKeys.AREA),
      MANIFEST: getModuleUrl(ModulesKeys.MANIFEST),
      DOCK_DOOR: getModuleUrl(ModulesKeys.DOCK_DOOR),
      STOP: getModuleUrl(ModulesKeys.STOP),
      LOADPLAN: getModuleUrl(ModulesKeys.LOADPLAN),
      STATION_LAYOUT: getModuleUrl(ModulesKeys.STATION_LAYOUT),
      STATION_PLANNER: getModuleUrl(ModulesKeys.STATION_PLANNER),
    },
  };
};

export enum NonAuthRoutes {
  LOGIN = '/login',
  LOGIN_ALERT = '/login/:alertMessage',
  HOME = '/',
  RESET_PASSWORD = '/reset-password',
  SET_PASSWORD = '/set-password/:code/:email',
  CHANGE_EMAIL = '/change-email/:code',
  TRACKING = '/track',
  REFRESH_ROUTE = '/refresh-route',
}

export enum TrackableContainersRoutes {
  LIST = '/trc',
  MAP = '/trc/map',
  DETAILS = '/trc/:asset_id/:id?',
}
