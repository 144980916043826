import React from 'react';
import { Typography } from '../primitives';
import styles from './pageNotFound.styles';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

interface Props {
  classes: { [key: string]: string };
}

const PageNotFound: React.FC<Props> = ({ classes }) => {
  return (
    <Grid data-testid={'page-not-found-component'}>
      <div className={classes.errorBackground}>
        <div className={classes.innerContainer}>
          <img
            className={classes.errorLogo}
            alt='CloudSort'
            src={`${
              process.env.REACT_APP_BASENAME || ''
            }/loginAssets/logo.svg`}
          />
          <Grid className={classes.errorMessage}>
            <Typography
              variant='h1'
              color={{ color: 'common', variant: 'white' }}
            >
              404
            </Typography>
            <Typography
              variant='h2'
              color={{ color: 'common', variant: 'white' }}
            >
              Page not found!
            </Typography>
          </Grid>
        </div>
      </div>
    </Grid>
  );
};

export default withStyles(styles)(PageNotFound);
