import { LoadplansApi, LoadPlan } from 'cloudsort-client';
import axios from '../utils/axios';
import EphemeralStateService from './EphemeralState.service';

class LoadPlans {
  private api: LoadplansApi;

  constructor() {
    this.api = new LoadplansApi(undefined, undefined, axios as any);
  }

  public async getAll(params: {
    active?: boolean | undefined;
    page?: number | undefined;
    pageSize?: number | undefined;
  }) {
    return this.api.loadplansList({
      ...params,
      station: EphemeralStateService.getMyStationId(),
    });
  }

  public async getById(id: number) {
    return this.api.loadplansRead({ id });
  }

  public async create() {
    return this.api.loadplansCreate({
      data: { station: EphemeralStateService.getMyStationId() },
    });
  }

  public async updateById(id: number, data: LoadPlan) {
    return this.api.loadplansPartialUpdate({ id, data });
  }

  public async delete(id: number) {
    return this.api.loadplansDelete({ id });
  }

  public async importSchemeSet(params: {
    id: number;
    dryRun: boolean;
    carrier?: string;
    file: any;
    fileId?: string;
    options?: any;
  }) {
    return this.api.loadplansFileImport({
      ...params,
    });
  }
  public async import(params: {
    dryRun: boolean;
    setActive?: boolean | undefined;
    fileId?: string | undefined;
    file?: any;
    options?: any;
  }) {
    return this.api.loadplansLoadplanImport({
      ...params,
      stationId: EphemeralStateService.getMyStationId(),
    });
  }
  public async export(id: number) {
    return this.api.loadplansFileExport({ id });
  }

  public async deleteSchemeSet(
    id: number,
    params: {
      carrier?: string;
    },
  ) {
    return this.api.loadplansDeleteSchemes({ id, ...params });
  }
}

export default new LoadPlans();
