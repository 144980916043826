import { AxiosResponse } from 'axios';
import { saveAs } from 'file-saver';

export const downloadFile = (
  serverResponse: AxiosResponse,
  customFileName?: string,
) => {
  if (customFileName) {
    saveAs(serverResponse.data, customFileName);
  } else {
    const filename = serverResponse.request
      .getResponseHeader('content-disposition')
      .split('filename=')[1]
      .replace(/"/g, '');
    saveAs(serverResponse.data, filename);
  }
};
