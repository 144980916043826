import {
  Organization,
  Station,
  StationDetails,
} from 'cloudsort-client';
import { AuthRoutes } from '../../../interfaces/routes';
import OrganizationsService from '../../../services/Organizations.service';
import StationsService from '../../../services/Stations.service';
import {
  CSBreadcrumbItem,
  CSBreadcrumbSelectedItem,
} from '../../primitives/CSBreadcrumbs/CSBreadcrumbs.types';
import { getMode, getOrganizationStationData, Mode } from '../utils';
import { TypeAheadItem } from '../../../interfaces/components';
import CustomerConfigurationsService from '../../../services/CustomerConfigurationsService';
//Types
type AssignedEntityType = 'Organization' | 'Station';
export type AssignedOrganization = Pick<
  Organization,
  'id' | 'name'
> & {
  type: AssignedEntityType;
};
export type AssignedStation = Pick<Station, 'id' | 'name'> & {
  type: AssignedEntityType;
};
export type AssignedEntity = AssignedStation | AssignedOrganization;

//Data fetching
export const getAllOrganizationsStationsData = async (
  search?: string,
): Promise<AssignedEntity[]> => {
  const organizationsRes = await OrganizationsService.getAllSearch(
    search,
  );
  for (let organization of organizationsRes.data
    .results as AssignedOrganization[]) {
    organization.type = 'Organization';
  }
  const stations = (await getAllStationsData(
    search,
  )) as AssignedStation[];
  for (let station of stations) {
    station.type = 'Station';
  }
  return [
    ...(organizationsRes.data.results as AssignedOrganization[]),
    ...stations,
  ];
};

export const getAllStationsData = async (
  search?: string,
  orgId?: number,
) => {
  const stationsRes = await StationsService.getAllStationsSearch(
    search,
    orgId,
  );
  return stationsRes?.data?.results || [];
};

export const getStationsAutocompleteSuggestions = async (
  search: string,
  orgId: number | undefined,
  selectedStations: TypeAheadItem<number>[],
) => {
  const data = await getAllStationsData(search, orgId);
  return data
    .filter((entity) => {
      return !selectedStations.find(
        (selectedEntity) => selectedEntity.value === entity.id,
      );
    })
    .map((entity: Station) => {
      return {
        label: entity.name,
        value: entity.id,
      };
    });
};

export const checkForConflictingCustomerConfigs = async (
  selectedStations: TypeAheadItem<number>[],
  newStations: TypeAheadItem<number>[],
  customerId: number,
) => {
  const stationIds = newStations
    .filter(
      (station) =>
        station.value && !selectedStations.includes(station),
    )
    .map((station) => station.value);
  if (stationIds.length === 0) {
    return []; //There are no new stations to check for conflicts.
  }
  const res = await CustomerConfigurationsService.getByStationIds(
    stationIds,
    customerId,
  );
  const errors: {
    name: string;
    conflict: string;
  }[] = [];
  res.data.results.forEach((config) => {
    const conflictStations: {
      name: string;
      conflict: string;
    }[] = newStations
      .filter((station) => stationIds.includes(station.value))
      .map((station) => {
        return {
          name: station.label,
          conflict: config.name,
        };
      });
    errors.push(...conflictStations);
  });
  return errors;
};

export const getBaseNavigationItems = async (
  organizationId: string | undefined,
  stationId: string | undefined,
) => {
  const mode = getMode(organizationId, stationId);
  const data = await getOrganizationStationData(
    mode,
    Number(organizationId),
    Number(stationId),
  );
  let organizationName = null;
  let stationName = null;
  if (mode === Mode.ORGANIZATION) {
    organizationName = (data as Organization).name;
  }
  if (mode === Mode.STATION) {
    stationName = (data as StationDetails).name;
    organizationName = (data as StationDetails).organization_name;
  }
  const baseURL = AuthRoutes.CONFIGURATION;
  const orgURL = `${baseURL}${
    organizationId ? '/organization/' + organizationId : ''
  }`;
  const stationURL = `${orgURL}${
    stationId ? '/station/' + stationId : ''
  }`;

  const navItems: Array<CSBreadcrumbItem | CSBreadcrumbSelectedItem> =
    [];

  navItems.push({
    label: 'Configurations',
    link: baseURL,
  });

  if (mode === Mode.CLOUDSORT) {
    navItems.push({
      label: 'Cloudsort',
      link: baseURL,
    });
  }

  if (organizationName) {
    navItems.push({
      label: organizationName,
      link: orgURL,
    });
  }
  if (stationName) {
    navItems.push({
      label: stationName,
      link: stationURL,
    });
  }

  return {
    baseURL: stationURL,
    navItems: navItems,
  };
};
