import { createStyles, Theme } from '@material-ui/core/styles';
import sectionPageBaseStyle from '../commonStyles/sectionPageBase.style';
import selectStyles from '../select/select.styles';

export default createStyles((theme: Theme) => ({
  ...selectStyles,
  ...sectionPageBaseStyle,
  dialogContent: {
    width: '400px',
    maxWidth: '100%',
  },
  dialogActions: {
    padding: 24,
  },
  nonMobileAlignRight: {
    [theme.breakpoints.up('xs')]: {
      textAlign: 'right',
    },
  },
  textRight: {
    textAlign: 'right',
  },
  marginBottom: {
    marginBottom: 10,
  },
}));
