import React, { Fragment, useState, useEffect } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import OperatorTool from './operatorTool/OperatorTool';
import GeneralModule from './general/General';
import detailsPageStyles from '../../commonStyles/detailsPage.style';
import { AlertBanner, CSButton, Typography } from '../../primitives';
import clx from 'classnames';
import UploadConfigDialog from './UploadConfigDialog';
import WebApp from './webApp/WebApp';
import colors from '../../../utils/colors';
import OperatorToolOnboarding from './operatorToolOnboarding/OperatorToolOnboarding';

// Icons
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudDownloadIcon from '../../../utils/svgs/CloudDownloadIcon';
import {
  configurationUrlParams,
  getInteraction,
  getMode,
  getOrganizationStationData,
  patchOrganizationStation,
} from '../utils';
import Layout from '../../layout/Layout';
import { useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import ErrorHandler from '../../../utils/ErrorHandler';
import { Organization, StationDetails } from 'cloudsort-client';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import ProgressIndicator from '../../progressIndicator/ProgressIndicator';
import Navbar, { TabsEnum } from '../Navbar';
// Redux
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import {
  selectSearchQuery,
  selectSections,
} from '../../../redux/slices/searchSlice';
import globalStationOptionsUtils from '../../../utils/globalStationOptionsUtils';
import {
  setSholdUpdateDynamicRoutes,
  setShouldUpdateStationsData,
} from '../../../redux/slices/navigationSlice';
import saveAs from 'file-saver';

interface Props {
  classes: { [key: string]: string };
}

export enum CurrentView {
  DESKTOP = 'Desktop',
  MOBILE = 'Mobile',
  SEARCH = 'Search',
}

export enum SelectedModule {
  GENERAL = 'General',
  OT = 'Operator Tool',
  OT_ONBOARDING = 'Operator Tool Onboarding',
  WEB = 'Web App',
}

export enum ModuleName {
  GENERAL = 'GENERAL',
  OPERATOR_TOOL = 'OT',
  OT_ONBOARDING = 'OPERATOR_TOOL_ONBOARDING',
  WEB = 'WEB',
}

const Modules: React.FC<Props> = ({ classes }) => {
  //Data & behavior
  const [currentView, setCurrentView] = useState<CurrentView>(
    CurrentView.DESKTOP,
  );
  const [data, setData] = useState<any>();
  const [initialData, setInitialData] = useState<any>();
  const [error, setError] = useState<string>();
  const [isViewReady, setIsViewReady] = useState<boolean>(false);
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [selectedModule, setSelectedModule] =
    useState<SelectedModule | null>(null);

  const searchQuery = useAppSelector(selectSearchQuery);
  const sectionsWithResults = useAppSelector(selectSections);
  const [showUploadDialog, setShowUploadDialog] =
    useState<boolean>(false);
  const [showJustImportedBanner, setshowJustImportedBanner] =
    useState<boolean>(false);
  //Theme
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));
  //Misc
  const urlParams = useParams<configurationUrlParams>();
  const mode = getMode(urlParams.orgId, urlParams.stationId);
  const interaction = getInteraction(mode);
  const dispatch = useAppDispatch();

  const handleError = async (err: AxiosError) => {
    setError(await ErrorHandler.getLabel(err));
  };

  const getAndSetData = async () => {
    try {
      setIsViewReady(false);
      const myData = await getOrganizationStationData(
        mode,
        Number(urlParams.orgId),
        Number(urlParams.stationId),
      );
      setData(myData);
      setInitialData(cloneDeep(myData));
      setIsViewReady(true);
    } catch (e) {
      handleError(e as AxiosError);
    }
  };

  useEffect(() => {
    getAndSetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParams.orgId, urlParams.stationId]);

  const importConfig = async (configToImport: any) => {
    try {
      const dataObj = { ...data };
      dataObj.config = configToImport;
      setData(dataObj);
      setshowJustImportedBanner(true);
    } catch (e) {
      handleError(e as AxiosError);
    }
  };

  const exportConfig = async () => {
    setShowProgress(true);
    try {
      const dataObj = (await getOrganizationStationData(
        mode,
        Number(urlParams.orgId),
        Number(urlParams.stationId),
      )) as Organization | StationDetails;
      const blob = new Blob([JSON.stringify(dataObj!.config!)], {
        type: 'application/json',
      });
      saveAs(
        blob,
        `${dataObj.name || 'CloudSort'}-configuration-${Date.now()}`,
      );
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  useEffect(() => {
    const hasSearchQuery = searchQuery.trim().length > 0;
    if (hasSearchQuery && currentView !== CurrentView.SEARCH) {
      setCurrentView(CurrentView.SEARCH);
    }
    if (
      !hasSearchQuery &&
      isMobileView &&
      currentView !== CurrentView.MOBILE
    ) {
      setCurrentView(CurrentView.MOBILE);
    }
    if (
      !hasSearchQuery &&
      !isMobileView &&
      currentView !== CurrentView.DESKTOP
    ) {
      setCurrentView(CurrentView.DESKTOP);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, isMobileView]);

  const renderDesktopView = () => {
    return (
      <Fragment>
        <UploadConfigDialog
          isOpen={showUploadDialog}
          data={data}
          importConfig={(configToImport: any) => {
            importConfig(configToImport);
          }}
          onAfterClose={() => {
            setShowUploadDialog(false);
          }}
        />
        <Grid
          direction='row'
          xs={12}
          container
          item
          data-testid='modules-config-page'
        >
          {!isMobileView && (
            <Grid item sm={6} xs={12}>
              <Box mb={2} mt={2}>
                <Box m={0} mb={-2}>
                  <CSButton
                    fullWidth={false}
                    color='secondary'
                    variant='contained'
                    className={clx(classes.actionButton)}
                    onClick={() => {
                      setShowUploadDialog(true);
                    }}
                    startIcon={<CloudUploadIcon />}
                  >
                    Import Configuration
                  </CSButton>
                  <CSButton
                    color='primary'
                    fullWidth={false}
                    variant='contained'
                    className={classes.actionButton}
                    style={{ marginLeft: 10 }}
                    onClick={() => {
                      exportConfig();
                    }}
                    startIcon={<CloudDownloadIcon />}
                  >
                    Export Configuration
                  </CSButton>
                </Box>
              </Box>
            </Grid>
          )}
          <Grid direction='row' xs={12} container item></Grid>
          {showJustImportedBanner && (
            <Grid direction='row' xs={12} container item>
              <Box mt={2} width={'100%'}>
                <AlertBanner
                  className={classes.bannerGold}
                  severity='info'
                  alertMsg={
                    'Module Configuration imported successfully. Please save to confirm the changes.'
                  }
                />
              </Box>
            </Grid>
          )}

          <Grid
            item
            xs={12}
            style={{
              display:
                currentView === CurrentView.SEARCH &&
                !sectionsWithResults.some((section) =>
                  section.includes(`${ModuleName.GENERAL}_`),
                )
                  ? 'none'
                  : 'block',
            }}
          >
            <GeneralModule
              currentView={currentView}
              goToPreviousPage={() => {}}
              interaction={interaction}
              data={data?.config?.GENERAL}
              dataWeb={data?.config?.WEB}
              setPartialData={(partialData) => {
                const partialDataObj = { ...data };
                partialDataObj.config.GENERAL = partialData;
                setData(partialDataObj);
              }}
              setPartialWebData={(partialData) => {
                const partialDataObj = { ...data };
                partialDataObj.config.WEB = partialData;
                setData(partialDataObj);
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display:
                currentView === CurrentView.SEARCH &&
                !sectionsWithResults.some((section) =>
                  section.includes(`${ModuleName.OPERATOR_TOOL}_`),
                )
                  ? 'none'
                  : 'block',
            }}
          >
            <OperatorTool
              currentView={currentView}
              goToPreviousPage={() => {}}
              interaction={interaction}
              data={data?.config?.OT}
              modulesData={data?.config?.GENERAL?.MODULES}
              setPartialData={(partialData) => {
                const partialDataObj = { ...data };
                partialDataObj.config.OT = partialData;
                setData(partialDataObj);
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display:
                currentView === CurrentView.SEARCH &&
                !sectionsWithResults.some((section) =>
                  section.includes(`${ModuleName.OT_ONBOARDING}_`),
                )
                  ? 'none'
                  : 'block',
            }}
          >
            <OperatorToolOnboarding
              currentView={currentView}
              goToPreviousPage={() => {}}
              interaction={interaction}
              data={data}
              setPartialData={(partialData) => {
                const partialDataObj = { ...data };
                partialDataObj.config.OT.ONBOARDING = partialData;
                setData(partialDataObj);
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display:
                currentView === CurrentView.SEARCH &&
                !sectionsWithResults.some((section) =>
                  section.includes(`${ModuleName.WEB}_`),
                )
                  ? 'none'
                  : 'block',
            }}
          >
            <WebApp
              currentView={currentView}
              goToPreviousPage={() => {}}
              interaction={interaction}
              data={data}
              setData={(newData) => {
                const newDataObj = { ...data };
                setData(newDataObj);
              }}
            />
          </Grid>
          {currentView === CurrentView.SEARCH &&
            sectionsWithResults.length === 0 &&
            searchQuery.length > 0 && (
              <Grid
                item
                xs={12}
                style={{
                  textAlign: 'center',
                }}
                data-testid='empty-search-results'
              >
                <Typography variant='h3'>
                  Your search for{' '}
                  <pre className={classes.highlightedSearchTerm}>
                    {searchQuery}
                  </pre>{' '}
                  didn't return any results.
                </Typography>
              </Grid>
            )}
        </Grid>
      </Fragment>
    );
  };

  const renderModuleSelection = () => {
    return (
      <Grid
        container
        spacing={2}
        alignItems='center'
        justifyContent='flex-start'
      >
        <Grid item xs={6}>
          <Box
            className={classes.categoryCard}
            onClick={() => setSelectedModule(SelectedModule.GENERAL)}
          >
            General
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            className={classes.categoryCard}
            onClick={() => setSelectedModule(SelectedModule.OT)}
          >
            Operator Tool
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            className={classes.categoryCard}
            onClick={() =>
              setSelectedModule(SelectedModule.OT_ONBOARDING)
            }
          >
            Operator Tool Onboarding
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            className={classes.categoryCard}
            onClick={() => setSelectedModule(SelectedModule.WEB)}
          >
            Web App
          </Box>
        </Grid>
      </Grid>
    );
  };

  const renderMobileView = () => {
    if (selectedModule === null) {
      return renderModuleSelection();
    }

    if (selectedModule === SelectedModule.GENERAL) {
      return (
        <GeneralModule
          currentView={currentView}
          goToPreviousPage={() => {
            setSelectedModule(null);
          }}
          interaction={interaction}
          data={data?.config?.GENERAL}
          dataWeb={data?.config?.WEB}
          setPartialData={(partialData) => {
            const partialDataObj = { ...data };
            partialDataObj.config.GENERAL = partialData;
            setData(partialDataObj);
          }}
          setPartialWebData={(partialData) => {
            const partialDataObj = { ...data };
            partialDataObj.config.WEB = partialData;
            setData(partialDataObj);
          }}
        />
      );
    }

    if (selectedModule === SelectedModule.OT) {
      return (
        <OperatorTool
          currentView={currentView}
          goToPreviousPage={() => {
            setSelectedModule(null);
          }}
          interaction={interaction}
          data={data?.config?.OT}
          modulesData={data?.config?.GENERAL?.MODULES}
          setPartialData={(partialData) => {
            const partialDataObj = { ...data };
            partialDataObj.config.OT = partialData;
            setData(partialDataObj);
          }}
        />
      );
    }

    if (selectedModule === SelectedModule.OT_ONBOARDING) {
      return (
        <OperatorToolOnboarding
          currentView={currentView}
          goToPreviousPage={() => {
            setSelectedModule(null);
          }}
          interaction={interaction}
          data={data}
          setPartialData={(partialData) => {
            const partialDataObj = { ...data };
            partialDataObj.config.OT.ONBOARDING = partialData;
            setData(partialDataObj);
          }}
        />
      );
    }

    if (selectedModule === SelectedModule.WEB) {
      return (
        <WebApp
          currentView={currentView}
          goToPreviousPage={() => {
            setSelectedModule(null);
          }}
          interaction={interaction}
          data={data}
          setData={(newData) => {
            const newDataObj = { ...data };
            setData(newDataObj);
          }}
        />
      );
    }
    return <></>;
  };

  const selectViewToRender = () => {
    if (currentView === CurrentView.MOBILE) {
      return renderMobileView();
    }
    return renderDesktopView();
  };

  return (
    <Layout navCurrent='CONFIGURATION'>
      {error && (
        <AlertBanner
          className={classes.banner}
          severity='error'
          alertTitle={'Error'}
          alertMsg={error}
        />
      )}
      {showProgress && <ProgressIndicator />}
      {!error && (
        <Navbar
          hasUnsavedChanges={!isEqual(initialData, data)}
          activeTab={TabsEnum.MODULES}
          onSaveCallback={async () => {
            try {
              setShowProgress(true);
              await patchOrganizationStation(mode, data);
              await globalStationOptionsUtils.setStationData(
                Number(urlParams.stationId),
              );
              dispatch(setShouldUpdateStationsData(true));
              dispatch(setSholdUpdateDynamicRoutes(true));
              setInitialData(cloneDeep(data));
            } catch (e) {
              handleError(e as AxiosError);
            } finally {
              setShowProgress(false);
            }
          }}
          onResetDataCallback={() => {
            setData(cloneDeep(initialData));
          }}
        />
      )}
      {isViewReady
        ? selectViewToRender()
        : !error && <ProgressIndicator />}
    </Layout>
  );
};

export default withStyles(
  createStyles(() => ({
    ...detailsPageStyles,
    actionButton: {
      height: '40px',
      marginBottom: 10,
    },
    categoryCard: {
      fontSize: '14px',
      cursor: 'pointer',
      textAlign: 'center',
      minHeight: '73px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      verticalAlign: 'middle',
      boxShadow:
        'rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
      borderRadius: '3px',
    },
    highlightedSearchTerm: {
      display: 'inline-block',
      backgroundColor: colors.lightGold,
      padding: 5,
      borderRadius: 3,
    },
  })),
)(Modules);
