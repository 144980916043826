import { TextFieldProps } from '@material-ui/core';
import reduce from 'lodash/reduce';
import React from 'react';
import {
  ClassNameMap,
  CSSProperties,
} from '@material-ui/core/styles/withStyles';
import CSTextField from '../csTextField/CSTextField';
import Event from '@material-ui/icons/Event';
import {
  DatePickerHandlers,
  DatePopperAlignment,
} from './CSDatepickerTypes';

export const getDatepickerSpawner = (
  children: React.ReactNode,
  handlers: DatePickerHandlers,
  classes: ClassNameMap<'input' | 'paperHidden' | 'backdropHidden'>,
) => {
  return children
    ? ((() => (
        <>{children}</>
      )) as unknown as React.ComponentType<TextFieldProps>)
    : (((props: React.PropsWithChildren<TextFieldProps>) => {
        return (
          <CSTextField
            className={classes.input}
            label={props.label as string}
            value={(props.value as string) || ''}
            inputProps={{ min: '0' }}
            containerSize='fullHorizontal'
            endAdornment={<Event />}
            onClick={() => {
              handlers.onOpen();
            }}
            hideClear
          />
        );
      }) as unknown as React.ComponentType<TextFieldProps>);
};

export const flattenStyles = (style: CSSProperties) =>
  reduce(style, (acc, value, key) => `${acc} ${key}:${value};`, '');

export const calculateCustomPosition = (
  elementToPosition: HTMLElement | null,
  anchorEl: HTMLElement | null,
  alignment: DatePopperAlignment = 'right',
) => {
  if (elementToPosition === null || anchorEl === null) return;
  const rect = anchorEl.getBoundingClientRect();
  switch (alignment) {
    case 'center':
      return flattenStyles({
        margin: 0,
        top: '-48px',
        right: 0,
        display: 'flex',
      });
    case 'left':
      return flattenStyles({
        margin: 0,
        position: 'absolute',
        top: `${rect?.bottom}px`,
        right: `${rect?.left}px`,
        display: 'flex',
      });
    default:
      return flattenStyles({
        margin: 0,
        position: 'absolute',
        top: `${rect?.bottom}px`,
        right: `${window.innerWidth - (rect.left + rect.width)}px`,
        display: 'flex',
      });
  }
};
