import {
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core/styles';
import { ExtendedPaletteKeys } from '../../../theming/material-ui';
import { getInputFieldColor } from '../csCommon/inputUtils';
import { ExtendedAsyncVariants } from './CSAsyncSelectTypes';

export const getInputLabelStyles = (theme: Theme) => {
  return createStyles(() => ({
    root: {
      fontSize: '14px',
      color: theme.palette.grey.A400,
      '&$focused': {
        color: theme.palette.grey.A400,
      },
    },
    formControl: {
      top: -13,
    },
    error: { color: `${theme.palette.grey.A400}!important` },
  }));
};

export const asyncSelectStyles = (
  theme: Theme,
  variant: ExtendedAsyncVariants,
  color?: ExtendedPaletteKeys,
  hasError?: boolean,
) => {
  const selectedColor = color
    ? getInputFieldColor(color, theme)
    : theme.palette.grey[300];
  const backgroundColor =
    variant === 'filled-outlined'
      ? theme.palette.grey.A200
      : theme.palette.background.paper;
  const result = makeStyles((theme: Theme) => ({
    container: {
      position: 'unset',
      boxSizing: 'unset',
      '& .CSAsyncSelect__control': {
        minHeight: 48,
        backgroundColor,
        border: `${theme.borderWidth.border_sm} solid ${
          hasError ? theme.palette.error.main : selectedColor
        }`,
        borderRadius: theme.shape.borderRadius_sm,
        boxShadow: 'none',
        padding: '6px 0 0 0',
        '&:hover': {
          border: `${theme.borderWidth.border_sm} solid ${theme.palette.quaternary?.dark}`,
          '&  .CSAsyncSelect__placeholder': {
            color: theme.palette.grey.A700,
            opacity: 1,
          },
        },
        '&.CSAsyncSelect__control--is-focused': {
          border: `${theme.borderWidth.border_sm} solid ${selectedColor}`,
          '&:hover': {
            border: `${theme.borderWidth.border_sm} solid ${selectedColor}`,
          },
        },
      },
      '& .CSAsyncSelect__clear-indicator': {
        padding: 0,
        color: theme.palette.text.primary,
        '&:hover': {
          color: theme.palette.text.primary,
          cursor: 'pointer',
        },
      },
      '& .CSAsyncSelect__dropdown-indicator': {
        color: theme.palette.text.primary,
        paddingLeft: 0,
        '&:hover': {
          color: theme.palette.text.primary,
          cursor: 'pointer',
        },
      },
      '& .CSAsyncSelect__input-container': {
        font: `${theme.typography.body1.fontSize} ${theme.typography.body1.fontFamily}`,
      },
      '& .CSAsyncSelect__single-value': {
        ...theme.typography.body1,
      },
      '& .CSAsyncSelect__menu': {
        width: '100%',
      },
      '& .CSAsyncSelect__indicators': {
        height: 24,
        marginTop: 5,
      },
      '& .CSAsyncSelect__indicator-separator': {
        display: 'none',
      },
      '& .CSAsyncSelect__option': {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.grey.A400,
        '&:hover': {
          backgroundColor: theme.palette.grey[100],
          cursor: 'pointer',
        },
      },
      '& .CSAsyncSelect__placeholder': {
        color: theme.palette.grey.A400,
      },
      '& .CSAsyncSelect__multi-value': {
        backgroundColor: theme.palette.secondary.main,
        ...theme.typography.caption,
      },
      '& .CSAsyncSelect__multi-value__label': {
        color: theme.palette.primary.contrastText,
      },
      '& .CSAsyncSelect__multi-value__remove': {
        color: theme.palette.primary.contrastText,
        '&:hover': {
          color: theme.palette.primary.contrastText,
          backgroundColor: theme.palette.secondary.dark,
          cursor: 'pointer',
        },
      },
    },
    labelledContainer: {
      '& .CSAsyncSelect__value-container': {
        marginTop: '9px',
      },
    },
    labelessContainer: {
      '& .CSAsyncSelect__value-container': {
        marginBottom: '7px',
      },
    },
    removeItemIcon: {
      fontSize: theme.typography.caption.fontSize,
    },
    menuPortal: {
      '&$menuPortalOpen': {
        zIndex: 9999,
      },
      '& .CSAsyncSelect__menu': {
        backgroundColor: backgroundColor,
        boxShadow: theme.shadows[2],
      },
      '& .CSAsyncSelect__menu-list': {
        border: `${theme.borderWidth.border_sm} solid ${selectedColor}`,
        borderRadius: theme.shape.borderRadius_sm,
      },
      '& .CSAsyncSelect__option--is-focused': {
        backgroundColor: theme.palette.grey.A200,
      },
    },
    // this class exist to increase the specificity of menuPortal avoiding an !important
    menuPortalOpen: {},
  }))();
  return result;
};

export const getFormHelperTextStyles = () => {
  return createStyles(() => ({
    root: { marginLeft: 0 },
  }));
};
