import React from 'react';
import { Typography } from '../../../primitives';
import { withStyles } from '@material-ui/core/styles';
import { Box, Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import detailsPageStyles from '../../../commonStyles/detailsPage.style';
import Timers from './Timers';
import Tsm from './Tsm';
import ScanModules from './ScanModules';
import WifiConfig from './WifiConfig';
import { INTERACTION } from '../../utils';
import PrintedLabel from './PrintedLabel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import KioskMode from './KioskMode';
import configPageStyles from '../../../commonStyles/configPage.style';
import { ArrowBackIos } from '@material-ui/icons';
import { CurrentView } from '../Modules';
import Session from './Session';

interface Props {
  classes: { [key: string]: string };
  data: any;
  modulesData: any;
  interaction?: INTERACTION;
  setPartialData: (timers: any) => void;
  goToPreviousPage: () => void;
}

const OperatorToolMobile: React.FC<Props> = ({
  classes,
  data,
  modulesData,
  interaction,
  setPartialData,
  goToPreviousPage,
}) => {
  const currentView = CurrentView.MOBILE;
  return (
    <>
      <Typography
        style={{ fontSize: 16, fontWeight: 500 }}
        onClick={goToPreviousPage}
      >
        <ArrowBackIos style={{ fontSize: 14 }} />
        Operator Tool
      </Typography>
      <Box mt={1}>
        <Accordion defaultExpanded={false}>
          <AccordionSummary
            classes={{
              expanded: classes.panelSummaryExpanded,
              root: classes.panelSummary,
            }}
            expandIcon={
              <ExpandMoreIcon
                className={classes.accordionExpandColapseIcon}
              />
            }
          >
            <Typography className={classes.summaryPanelTitleText}>
              Scan Modes
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            classes={{
              root: classes.panelDetails,
            }}
          >
            <Box>
              <ScanModules
                currentView={currentView}
                data={data?.MODES}
                interaction={interaction}
                setPartialData={(modes) => {
                  const dataObj = { ...data };
                  dataObj.MODES = modes;
                  setPartialData(dataObj);
                }}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box mt={1}>
        <Accordion defaultExpanded={false}>
          <AccordionSummary
            classes={{
              expanded: classes.panelSummaryExpanded,
              root: classes.panelSummary,
            }}
            expandIcon={
              <ExpandMoreIcon
                className={classes.accordionExpandColapseIcon}
              />
            }
          >
            <Typography className={classes.summaryPanelTitleText}>
              Timers
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            classes={{
              root: classes.panelDetails,
            }}
          >
            <Box>
              <Timers
                currentView={currentView}
                data={data?.TIMERS}
                interaction={interaction}
                setPartialData={(timers) => {
                  const dataObj = { ...data };
                  dataObj.TIMERS = timers;
                  setPartialData(dataObj);
                }}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box mt={1}>
        <Accordion defaultExpanded={false}>
          <AccordionSummary
            classes={{
              expanded: classes.panelSummaryExpanded,
              root: classes.panelSummary,
            }}
            expandIcon={
              <ExpandMoreIcon
                className={classes.accordionExpandColapseIcon}
              />
            }
          >
            <Typography className={classes.summaryPanelTitleText}>
              WiFi
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            classes={{
              root: classes.panelDetails,
            }}
          >
            <Box>
              <WifiConfig
                currentView={currentView}
                data={data?.ADM.wifi}
                interaction={interaction}
                setPartialData={(c) => {
                  const dataObj = { ...data };
                  dataObj.ADM.wifi = c;
                  setPartialData(dataObj);
                }}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box mt={1}>
        <Accordion defaultExpanded={false}>
          <AccordionSummary
            classes={{
              expanded: classes.panelSummaryExpanded,
              root: classes.panelSummary,
            }}
            expandIcon={
              <ExpandMoreIcon
                className={classes.accordionExpandColapseIcon}
              />
            }
          >
            <Typography className={classes.summaryPanelTitleText}>
              Kiosk Mode
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            classes={{
              root: classes.panelDetails,
            }}
          >
            <Box>
              <KioskMode
                currentView={currentView}
                data={data?.ADM.kiosk}
                interaction={interaction}
                setPartialData={(state) => {
                  const dataObj = { ...data };
                  dataObj.ADM.kiosk = state;
                  setPartialData(dataObj);
                }}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box mt={1}>
        <Accordion defaultExpanded={false}>
          <AccordionSummary
            classes={{
              expanded: classes.panelSummaryExpanded,
              root: classes.panelSummary,
            }}
            expandIcon={
              <ExpandMoreIcon
                className={classes.accordionExpandColapseIcon}
              />
            }
          >
            <Typography className={classes.summaryPanelTitleText}>
              Printed Label
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            classes={{
              root: classes.panelDetails,
            }}
          >
            <Box>
              <PrintedLabel
                currentView={currentView}
                data={data?.LABEL_DEFINITION}
                interaction={interaction}
                setPartialData={(state) => {
                  const dataObj = { ...data };
                  dataObj.LABEL_DEFINITION = state;
                  setPartialData(dataObj);
                }}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box mt={1}>
        <Accordion defaultExpanded={false}>
          <AccordionSummary
            classes={{
              expanded: classes.panelSummaryExpanded,
              root: classes.panelSummary,
            }}
            expandIcon={
              <ExpandMoreIcon
                className={classes.accordionExpandColapseIcon}
              />
            }
          >
            <Typography className={classes.summaryPanelTitleText}>
              Kiosk Mode
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            classes={{
              root: classes.panelDetails,
            }}
          >
            <Box>
              <KioskMode
                currentView={currentView}
                data={data?.ADM.kiosk}
                interaction={interaction}
                setPartialData={(state) => {
                  const dataObj = { ...data };
                  dataObj.ADM.kiosk = state;
                  setPartialData(dataObj);
                }}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box mt={1}>
        <Accordion defaultExpanded={false}>
          <AccordionSummary
            classes={{
              expanded: classes.panelSummaryExpanded,
              root: classes.panelSummary,
            }}
            expandIcon={
              <ExpandMoreIcon
                className={classes.accordionExpandColapseIcon}
              />
            }
          >
            <Typography className={classes.summaryPanelTitleText}>
              TSM
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            classes={{
              root: classes.panelDetails,
            }}
          >
            <Box>
              <Tsm
                currentView={currentView}
                data={data?.TSM_OBJECT_STRUCTURE}
                modulesData={modulesData}
                interaction={interaction}
                setPartialData={(tsm) => {
                  const dataObj = { ...data };
                  dataObj.TSM_OBJECT_STRUCTURE = tsm;
                  setPartialData(dataObj);
                }}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box mt={1}>
        <Accordion defaultExpanded={false}>
          <AccordionSummary
            classes={{
              expanded: classes.panelSummaryExpanded,
              root: classes.panelSummary,
            }}
            expandIcon={
              <ExpandMoreIcon
                className={classes.accordionExpandColapseIcon}
              />
            }
          >
            <Typography className={classes.summaryPanelTitleText}>
              Session
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            classes={{
              root: classes.panelDetails,
            }}
          >
            <Box>
              <Session
                currentView={currentView}
                data={data?.SESSION}
                interaction={interaction}
                setPartialData={(sessionData) => {
                  const dataObj = { ...data };
                  dataObj.SESSION = sessionData;
                  setPartialData(dataObj);
                }}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...configPageStyles(theme),
  })),
)(OperatorToolMobile);
