import { PermissionsPermissionsEnum } from 'cloudsort-client';
import { useMemo } from 'react';
import {
  OTHER_PERMISSIONS_LABELS,
  OT_PERMISSIONS_LABELS,
} from '../components/stationStaff/StationStaff';

const usePermissionLabels = () => {
  const permissionLabels: { [permission: string]: string } =
    useMemo(() => {
      const getLabels = (
        permissionLabels:
          | typeof OTHER_PERMISSIONS_LABELS
          | typeof OT_PERMISSIONS_LABELS,
      ) => {
        const labelData = Object.keys(PermissionsPermissionsEnum)
          .filter((permission) =>
            Object.keys(permissionLabels).includes(permission),
          )
          .map((key) => {
            const displayLabel =
              //@ts-ignore -- it's complaining because permissionLabels only has a subset of  permissions.
              permissionLabels[
                key as keyof typeof PermissionsPermissionsEnum
              ];
            return {
              label:
                displayLabel[0].toUpperCase() +
                displayLabel.substring(1).toLowerCase(),
              value: PermissionsPermissionsEnum[
                key as keyof typeof PermissionsPermissionsEnum
              ] as string,
            };
          });
        let data: { [key: string]: string } = {};
        for (let permissionData of labelData) {
          data[permissionData.value] = permissionData.label;
        }
        return data;
      };
      const permissionData = {
        ...getLabels(OT_PERMISSIONS_LABELS),
        ...getLabels(OTHER_PERMISSIONS_LABELS),
      };
      return permissionData;
    }, []);
  return permissionLabels;
};

export default usePermissionLabels;
