import { useState } from 'react';
import CSDialogTitleWithIcon from '../primitives/csDialogTitleWithIcon/CSDialogTitleWithIcon';
import { Transition } from '../confirmationDialog/ConfirmationDialog';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  createStyles,
  withStyles,
} from '@material-ui/core';
import { DialogIcons } from '../primitives/csDialogTitleWithIcon/CSDialogTitleWithIconTypes';
import { CSButton, CSTextField } from '../primitives';
import { CSMonoGridContainer } from '../primitives/csMonoGridContainer';
import CSDialogAlert from '../primitives/csDialogAlert/CSDialogAlert';

interface Props {
  classes: { [key: string]: string };
  currentNote: string;
  onSave: (note: string, id?: number) => void;
  onCancel: () => void;
}

const EditCloudsortNoteDialog = ({
  classes,
  currentNote,
  onSave,
  onCancel,
}: Props) => {
  const [note, setNote] = useState(currentNote);

  return (
    <Dialog
      open={true}
      TransitionComponent={Transition}
      onClose={onCancel}
      data-testid='edit-cloudsort-note-dialog'
    >
      <DialogTitle>
        <CSDialogTitleWithIcon
          icon={DialogIcons.EDIT}
          title='Edit Note'
        />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <CSMonoGridContainer>
          <CSTextField
            containerSize='fullHorizontal'
            label='Cloudsort Notes'
            value={note}
            onChange={(e) => {
              setNote(e.target.value);
            }}
          />
          <CSDialogAlert alertMessage='Webhook trigger: Saving this field will send a webhook payload if the customer is subscribing HOLD_REASON_UPDATED events.' />
        </CSMonoGridContainer>
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <CSButton
          color='secondary'
          variant='outlined'
          onClick={onCancel}
        >
          Cancel
        </CSButton>
        <CSButton
          color='secondary'
          variant='contained'
          data-testid='save-cloudsort-note'
          onClick={() => {
            onSave(note);
          }}
          disabled={!note}
        >
          Save
        </CSButton>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(
  createStyles(() => ({
    dialogContent: {
      width: 480,
      maxWidth: '100%',
    },
    dialogActions: {
      padding: 24,
    },
  })),
)(EditCloudsortNoteDialog);
