import { createStyles, Theme } from '@material-ui/core/styles';

export default createStyles((theme: Theme) => ({
  mainStyle: {
    boxSizing: 'border-box',
    height: 40,
    border: '0.8px solid #E1E1E1',
    borderRadius: 4,
    backgroundColor: '#FFFFFF',
    boxShadow: 'none',
    color: '#222222',
    textTransform: 'none',
    fontWeight: 'normal',
    '&:hover': {
      border: '0.8px solid #E1E1E1',
      backgroundColor: '#FFFFFF',
      color: '#222222',
      textTransform: 'none',
      fontWeight: 'normal',
    },
  },
}));
