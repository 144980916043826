import { createStyles, Theme } from '@material-ui/core/styles';
import {
  ButtonExtendedColors,
  ButtonWithIconColor,
} from './CSButtonTypes';
import { getButtonColorStyles } from './CSButtonUtils';

export default (
  theme: Theme,
  color: ButtonExtendedColors | ButtonWithIconColor,
  height: string,
) => {
  const colorStyles = getButtonColorStyles(color, theme);
  return createStyles(() => ({
    root: {
      height: height,
      textTransform: 'Capitalize',
      borderRadius: theme?.shape.borderRadius_sm,
      '&:disabled': {
        pointerEvents: 'all',
        cursor: 'not-allowed',
      },
      '& svg': {
        color: colorStyles?.iconColor || 'inherit',
      },
    },
    contained: {
      backgroundColor: colorStyles.buttonColor.main,
      color: colorStyles.buttonColor.contrastText,
      '&:hover': {
        backgroundColor: colorStyles.buttonColor.dark,
        boxShadow: 'none',
      },
      '&:disabled': {
        backgroundColor: theme.palette.grey[300],
        color: theme.palette.grey.A400,
        '&:hover': {
          backgroundColor: theme.palette.grey[300],
        },
        '& svg': {
          color: theme.palette.grey.A400,
        },
      },
    },
    text: {
      color: colorStyles.buttonColor.main,
      '&:disabled': {
        backgroundColor: 'transparent',
        color: theme.palette.grey.A400,
        '& svg': {
          color: theme.palette.grey.A400,
        },
      },
    },
    outlined: {
      color: colorStyles.buttonColor.main,
      border: `${theme.borderWidth.border_md} solid ${colorStyles.buttonColor.main}`,
      '&:hover': {
        border: `${theme.borderWidth.border_md} solid ${colorStyles.buttonColor.dark}`,
        borderRadius: theme?.shape.borderRadius_sm,
        boxShadow: 'none',
        '&:disabled': {
          border: `${theme.borderWidth.border_md} solid ${theme.palette.grey.A200}`,
          backgroundColor: theme.palette.grey.A200,
          color: theme.palette.grey.A400,
          '& svg': {
            color: theme.palette.grey.A400,
          },
        },
      },
      '&:disabled': {
        border: `${theme.borderWidth.border_md} solid ${theme.palette.grey.A200}`,
        backgroundColor: theme.palette.grey.A200,
        color: theme.palette.grey.A400,
        '& svg': {
          color: theme.palette.grey.A400,
        },
      },
      '&$outlinedSizeSmall': {
        ...theme.typography.subtitle1,
        border: `${theme.borderWidth.border_sm} solid ${colorStyles.buttonColor.main}`,
      },
    },
    outlinedSizeSmall: {},
  }));
};
