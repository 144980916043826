import { CustomerConfig, CustomerConfigsApi } from 'cloudsort-client';
import axios from '../utils/axios';

class CustomerConfigurations {
  private api: CustomerConfigsApi;

  constructor() {
    this.api = new CustomerConfigsApi(
      undefined,
      undefined,
      axios as any,
    );
  }

  public async updateConfiguration(
    customerId: string,
    newConfig: CustomerConfig,
    force?: boolean,
  ) {
    return this.api.customerConfigsPartialUpdate({
      id: customerId,
      data: newConfig,
      force: force ? force : undefined, //TODO: When force=false, the API doesn't work.This bug is tracked in CLOUD-3276.
    });
  }

  public async createFromConfig(
    config: CustomerConfig,
    force?: boolean,
  ) {
    return this.api.customerConfigsCreate({
      data: config,
      force: force ? force : undefined, //TODO: When force=false, the API doesn't work.This bug is tracked in CLOUD-3276.
    });
  }

  public async createNewConfiguration({
    customerId,
    organizationId,
    stationId,
    name = 'New Configuration',
  }: {
    customerId: number;
    organizationId?: number;
    stationId?: number;
    name: string;
  }) {
    return this.api.customerConfigsCreate({
      data: {
        customer: customerId,
        name: name,
        organization: organizationId,
        stations: stationId ? [stationId] : [],
        config: {
          outbound_integrations: {
            FEDEX: {
              active: false,
            },
            USPS: {
              active: false,
            },
            FDC: {
              active: false,
            },
          },
          dispatch_integrations: {
            AWB: {
              active: false,
            },
            BOL: {
              active: false,
            },
          },
          external_fulfilment: {
            providers: [],
          },
          relabel_options: {},
        },
      },
    });
  }

  public async getDefaultConfiguration({
    customerId,
    organizationId,
  }: {
    customerId: number;
    organizationId: number;
    stationId?: number;
  }) {
    return this.api.customerConfigsList({
      customer: customerId,
      organization: organizationId,
    });
  }

  public async getConfigurations({
    customerId,
    organizationId,
    stations = [],
    pageIndex,
    rowsPerPage,
  }: {
    customerId: number;
    organizationId?: number;
    stations?: number[];
    pageIndex?: number;
    rowsPerPage?: number;
  }) {
    return this.api.customerConfigsList({
      customer: customerId,
      organization: organizationId,
      station: stations.length > 0 ? stations : undefined,
      page: pageIndex,
      pageSize: rowsPerPage,
    });
  }

  public async getByStationIds(
    stationIds: number[],
    customerId: number,
  ) {
    return this.api.customerConfigsList({
      customer: customerId,
      station: stationIds,
    });
  }
}

export default new CustomerConfigurations();
