import { Theme, createStyles } from '@material-ui/core';
import sectionPageBaseStyle from '../commonStyles/sectionPageBase.style';
import filterBadgeStyle from '../filtersDrawer/filterBadge.style';

export default createStyles((theme: Theme) => ({
  ...filterBadgeStyle,
  ...sectionPageBaseStyle,
  headerFilterButton: {
    minWidth: '40px',
    marginLeft: '20px',
  },
  defaultFontSize: {
    fontSize: 14,
  },
  iconContainer: {
    display: 'flex',
  },
}));
