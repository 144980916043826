import { DockdoorsApi, DockDoorDetails } from 'cloudsort-client';
import axios from '../utils/axios';
import EphemeralStateService from './EphemeralState.service';
import { LABEL_DPI, LABEL_SIZE } from './utils/constants';

class DockDoors {
  private api: DockdoorsApi;

  constructor() {
    this.api = new DockdoorsApi(undefined, undefined, axios as any);

    this.getAll = this.getAll.bind(this);
    this.getById = this.getById.bind(this);
  }

  public async getAll(params?: { page?: number; pageSize?: number }) {
    return this.api.dockdoorsList({
      ...params,
      station: EphemeralStateService.getMyStationId(),
    });
  }

  public async search(search: string) {
    return this.api.dockdoorsList({
      search,
      station: EphemeralStateService.getMyStationId(),
    });
  }

  public async getById(id: number) {
    return this.api.dockdoorsRead({ id });
  }

  public async create(name: string) {
    return this.api.dockdoorsCreate({
      data: {
        name,
        station: EphemeralStateService.getMyStationId(),
      },
    });
  }

  public async edit(id: number, data: DockDoorDetails) {
    return this.api.dockdoorsPartialUpdate({
      id,
      data,
    });
  }

  public async getLabel(
    id: number,
    size: string = LABEL_SIZE,
    dpi: number = LABEL_DPI,
  ) {
    return this.api.dockdoorsLabelLabelPng(
      {
        id,
        size,
        dpi,
      },
      { responseType: 'blob' },
    );
  }
}

export default new DockDoors();
