import { Box } from '@material-ui/core';
export function TabPanel({
  children,
  currentTab,
  index,
  label,
  ...other
}: any) {
  return (
    <div
      role='tabpanel'
      hidden={currentTab !== index}
      data-testid={`tab-panel-${label}-${index}`}
      {...other}
    >
      {currentTab === index && <Box>{children}</Box>}
    </div>
  );
}
