import { TaskApi, TaskResubmission } from 'cloudsort-client';
import axios from '../utils/axios';

class Tasks {
  private api: TaskApi;

  constructor() {
    this.api = new TaskApi(undefined, undefined, axios as any);
  }

  public async resubmit(params: {
    data: TaskResubmission;
    dryRun?: boolean;
  }) {
    return this.api.taskResubmit(params);
  }
}

export default new Tasks();
