import { Location } from 'history';
import { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import ConfirmationDialog from '../confirmationDialog/ConfirmationDialog';
import { createStyles, withStyles } from '@material-ui/core/styles';
import detailsPageStyles from '../commonStyles/detailsPage.style';

interface Props {
  classes: { [key: string]: string };
  when?: boolean | undefined;
  forceOpen: boolean;
  navigate: (path: string) => void;
  shouldBlockNavigation: (location: Location) => boolean;
  resetGlobalUnsavedChanges: () => void;
  onSave: () => void;
  onAfterCancel: () => void;
}
const RouteLeavingGuard = ({
  classes,
  when,
  forceOpen,
  onSave,
  navigate,
  shouldBlockNavigation,
  resetGlobalUnsavedChanges,
  onAfterCancel,
}: Props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(
    null,
  );
  const [confirmedNavigation, setConfirmedNavigation] =
    useState(false);

  const closeModal = () => {
    setModalVisible(false);
    setLastLocation(null);
    setConfirmedNavigation(false);
    onAfterCancel();
  };

  const handleBlockedNavigation = (
    nextLocation: Location,
  ): boolean => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
    resetGlobalUnsavedChanges();
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      navigate(lastLocation.pathname);
      setConfirmedNavigation(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmedNavigation, lastLocation]);

  useEffect(() => {
    if (forceOpen) {
      setModalVisible(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceOpen]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <ConfirmationDialog
        dataTestIdPrefix='config-page-save-dialog'
        title='Unsaved Changes'
        msg='You didn’t save your new configurations. Are you sure you want to leave without saving?'
        primaryActionLabel='Save and Exit'
        onPrimaryAction={() => {
          onSave();
          handleConfirmNavigationClick();
        }}
        secondaryActionLabel='Discard Changes'
        onSecondaryAction={handleConfirmNavigationClick}
        onCancel={closeModal}
        hideCancelButton
        isOpen={modalVisible}
      />
    </>
  );
};

export default withStyles(
  createStyles(() => ({
    ...detailsPageStyles,
  })),
)(RouteLeavingGuard);
