import React, { useState, Fragment, useEffect } from 'react';
import { AlertBanner, Typography, Container } from '../primitives';
import { withStyles } from '@material-ui/core/styles';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import { NonAuthRoutes } from '../../interfaces/routes';
import styles from '../logIn/logIn.styles';
import browserHistory from '../../utils/browserHistory';

//Services
import AuthService from '../../services/Auth.service';
import { Helmet } from 'react-helmet';

interface Props {
  classes: { [key: string]: string };
  match: any;
}
const ChangeEmail: React.FC<Props> = ({ classes, match }) => {
  const [showProgress, setShowProgress] = useState<boolean>(false);

  const [error, setError] = useState<string>('');

  const updateEmail = async (code: string) => {
    setShowProgress(true);
    try {
      if (AuthService.isLoggedIn()) await AuthService.logOut({});

      //Update password
      await AuthService.changeEmail({
        token: code,
      });
      //redirect to login
      browserHistory.push(NonAuthRoutes.LOGIN + '/email-updated');
    } catch (e) {
      setError(
        'The link you are using is invalid or has expired. Try again or update your email again to receive a new link.',
      );
    } finally {
      setShowProgress(false);
    }
  };

  useEffect(() => {
    updateEmail(match.params.code);
  }, [match.params.code]);

  return (
    <Fragment>
      <Helmet>
        <title>{`CloudSort - Change Email`}</title>
      </Helmet>
      <div
        className={classes.halfWidth + ' ' + classes.loginBackground}
      >
        <a href='/'>
          <img
            className={classes.loginLogo}
            alt='CloudSort'
            src={`${
              process.env.REACT_APP_BASENAME || ''
            }/loginAssets/logo.svg`}
          />
        </a>
      </div>
      <div
        className={classes.halfWidth}
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {showProgress && (
          <ProgressIndicator data-testid='progress-indicator-component' />
        )}
        <Container
          maxWidth='xs'
          data-testid='update-email--component'
          style={{
            marginLeft: '20px',
          }}
        >
          <div className={classes.paper}>
            <Typography variant='h2' className={classes.loginHeading}>
              {error ? 'Email update failed' : 'Please wait'}
            </Typography>

            {error && (
              <AlertBanner
                severity='error'
                data-testid='update-email-error'
                alertMsg={error}
                alertTitle={'Error'}
              />
            )}
          </div>
        </Container>
      </div>
    </Fragment>
  );
};

export default withStyles(styles)(ChangeEmail);
