import React, { useState, useCallback } from 'react';
import {
  withStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles';
import { CSButton } from '../../primitives';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Transition } from '../../confirmationDialog/ConfirmationDialog';

import detailsPageStyles from '../../commonStyles/detailsPage.style';

import { MenuItem } from '@material-ui/core';
import { TypeAheadItem } from '../../../interfaces/components';
import { SingleValue } from 'react-select';
import selectStyles from '../../select/select.outlined.styles';
import debounce from 'lodash/debounce';

// Types
import { StaffPermissionRoleTypeEnum, Staff } from 'cloudsort-client';
import { ROLE_LABELS } from '../../stationStaff/StationStaff';

//Services
import StationsService from '../../../services/Stations.service';
import OrganizationsService from '../../../services/Organizations.service';

import { noOptionsMessage } from '../../asyncSelect/utils';
import CSDialogAlert from '../../primitives/csDialogAlert/CSDialogAlert';
import CSDialogTitleWithIcon from '../../primitives/csDialogTitleWithIcon/CSDialogTitleWithIcon';
import { DialogIcons } from '../../primitives/csDialogTitleWithIcon/CSDialogTitleWithIconTypes';
import CSSelect from '../../primitives/csSelect/CSSelect';
import CSAsyncSelect from '../../primitives/csAsyncSelect/CSAsyncSelect';
import { CSMonoGridContainer } from '../../primitives/csMonoGridContainer';

interface Props {
  classes: { [key: string]: string };
  isOpen: boolean;
  onAfterDialogClose: () => void;
  onAddStaff: (
    staffParams: Staff,
    onAfterDialogClose: () => void,
    updateUser: boolean,
  ) => void;
  error?: string;
  initialStaff?: Staff;
  preselectedEntity?: TypeAheadItem;
}

enum RoleType {
  STATION,
  ORGANIZATION,
}

const initAddParams: Staff = {
  first_name: '',
  last_name: '',
  email: '',
  permission_data: {},
};

const AssignUserToEntityDialog: React.FC<Props> = ({
  classes,
  isOpen,
  onAfterDialogClose,
  onAddStaff,
  error,
  initialStaff,
  preselectedEntity,
}) => {
  const [staffParams, setStaffParams] =
    useState<Staff>(initAddParams);

  const [roleType, setRoleType] = useState<RoleType>();
  const [selectedEntity, setSelectedEntity] = useState<
    TypeAheadItem | undefined
  >();

  const onClose = () => {
    onAfterDialogClose();
    setTimeout(() => {
      setStaffParams(initAddParams);
      setRoleType(undefined);

      setSelectedEntity(undefined);
    }, 300);
  };

  const selectRole = (role: StaffPermissionRoleTypeEnum) => {
    if (initialStaff) {
      setStaffParams({
        ...initialStaff,
        permission_data: {
          role_type: role,
        },
      });
    } else {
      if (preselectedEntity && !role.includes('ORG')) {
        setStaffParams({
          ...staffParams,
          permission_data: {
            role_type: role,
            station: Number(preselectedEntity.value),
          },
        });
        setSelectedEntity(preselectedEntity);
      } else
        setStaffParams({
          ...staffParams,
          permission_data: {
            role_type: role,
          },
        });
    }
  };

  const fetchStations = async (search: string) => {
    const { data } = await StationsService.getAllStationsSearch(
      search,
    );
    return data.results;
  };
  const fetchOrgs = async (search: string) => {
    const { data } = await OrganizationsService.getAllSearch(search);
    return data.results;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadOptions = useCallback(
    debounce((inputValue: string, callback: any) => {
      if (roleType === RoleType.ORGANIZATION) {
        fetchOrgs(inputValue).then((data) => {
          callback(
            data.map((entity) => {
              return {
                value: entity.id?.toString() || '',
                label: entity.name,
              };
            }),
          );
        });
      } else {
        fetchStations(inputValue).then((data) => {
          callback(
            data.map((entity) => {
              return {
                value: entity.id?.toString() || '',
                label: entity.name,
              };
            }),
          );
        });
      }
    }, 500),
    [roleType],
  );

  const handleOnEntitySelect = (
    option: SingleValue<TypeAheadItem>,
  ) => {
    if (option) {
      if (roleType === RoleType.ORGANIZATION) {
        setStaffParams({
          ...staffParams,
          permission_data: {
            ...staffParams.permission_data,
            organization: Number((option as TypeAheadItem).value),
          },
        });
      } else {
        setStaffParams({
          ...staffParams,
          permission_data: {
            ...staffParams.permission_data,
            station: Number((option as TypeAheadItem).value),
          },
        });
      }

      setSelectedEntity(option as TypeAheadItem);
    } else {
      setSelectedEntity(undefined);
    }
  };

  return (
    <Dialog
      data-testid={'god-view-users-add-dialog'}
      open={isOpen}
      TransitionComponent={Transition}
      classes={{
        paper: classes.paper,
        paperScrollPaper: classes.paperScrollPaper,
      }}
      onClose={() => {
        onClose();
      }}
    >
      <DialogTitle>
        <CSDialogTitleWithIcon
          icon={DialogIcons.ADD}
          title='Assign an entity'
          subtitle='Please select a station or organization and the role for that entity.'
        />
      </DialogTitle>
      <DialogContent>
        <CSMonoGridContainer>
          {error ? (
            <CSDialogAlert
              data-testid={'station-staff-error-banner'}
              alertMessage={error}
            />
          ) : undefined}
          <CSSelect
            label='Select Role'
            onChange={(e: React.BaseSyntheticEvent) => {
              if (e.target.value !== '') {
                selectRole(e.target.value);
              }
              if (e.target.value.includes('ORG'))
                setRoleType(RoleType.ORGANIZATION);
              else setRoleType(RoleType.STATION);
            }}
            containerSize='fullHorizontal'
            value={staffParams?.permission_data!.role_type ?? 'none'}
          >
            <MenuItem key={`fragment-none`} value={'none'} disabled>
              Please select...
            </MenuItem>
            {Object.keys(StaffPermissionRoleTypeEnum)
              .filter((key) => key !== 'SUPERUSER' && key !== 'BLANK')
              .map((key) => {
                return {
                  label:
                    ROLE_LABELS[
                      key as keyof typeof StaffPermissionRoleTypeEnum
                    ],
                  value:
                    StaffPermissionRoleTypeEnum[
                      key as keyof typeof StaffPermissionRoleTypeEnum
                    ],
                };
              })
              .map((permission) => {
                return (
                  <MenuItem
                    key={`fragment-${permission.label}`}
                    value={permission.value}
                  >
                    {permission.label}
                  </MenuItem>
                );
              })}
          </CSSelect>
          <CSAsyncSelect<TypeAheadItem>
            data-testid={'add-user-dialog-async-select'}
            isClearable
            cacheOptions
            containerSize='fullHorizontal'
            menuPortalTarget={document.body}
            label={`Assigned ${
              roleType === RoleType.ORGANIZATION
                ? 'Organization'
                : 'Station'
            }`}
            loadOptions={loadOptions}
            onChange={handleOnEntitySelect}
            noOptionsMessage={noOptionsMessage}
            placeholder={
              roleType === RoleType.ORGANIZATION
                ? 'Find organizations by name'
                : 'Find stations by name'
            }
            value={selectedEntity ? selectedEntity : null}
          />
        </CSMonoGridContainer>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <CSButton
          className={classes.cancelButton}
          data-testid={'station-staff-dialog-close'}
          variant='outlined'
          color='secondary'
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </CSButton>
        <CSButton
          data-testid={'station-staff-dialog-assign'}
          variant='outlined'
          color='secondary'
          disabled={
            !selectedEntity || !staffParams.permission_data?.role_type
          }
          onClick={() => {
            onAddStaff(staffParams!, () => {}, true);
            onClose();
          }}
        >
          Assign
        </CSButton>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...selectStyles,
    cancelButton: {
      marginRight: 10,
    },
    paper: {
      padding: 0,
      margin: 0,
      width: 560,
      maxWidth: '100%',
    },
    paperScrollPaper: {
      maxHeight: '100%',
    },
    dialogActions: {
      padding: 24,
    },
  })),
)(AssignUserToEntityDialog);
