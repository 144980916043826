import { TaskApi } from 'cloudsort-client';
import axios from '../utils/axios';

class Tasks {
  private api: TaskApi;

  constructor() {
    this.api = new TaskApi(undefined, undefined, axios as any);
  }

  public async getById(id: number) {
    return this.api.taskRead({
      id,
    });
  }

  public async getOutputById(id: number) {
    return this.api.taskOutput({
      id,
    });
  }
}

export default new Tasks();
