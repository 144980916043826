import { Grid } from '@material-ui/core';
import * as React from 'react';
import { CSMonoGridContainerProps } from './csMonolGridContainerTypes';

const CSMonoGridContainer: React.FC<CSMonoGridContainerProps> = ({
  children,
  containerSpacing = 2,
  'data-testid': dataTestId,
}) => {
  const toRender = children.filter((e) => e);
  return (
    <Grid
      data-testid={dataTestId}
      container
      spacing={containerSpacing}
    >
      {toRender.map((v, index) => {
        return v ? (
          <Grid key={`${index}-${v?.key}`} item xs={12}>
            {v}
          </Grid>
        ) : (
          <></>
        );
      })}
    </Grid>
  );
};

export default CSMonoGridContainer;
