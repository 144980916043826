import {
  CorporateApi,
  PatchCorporateRoles,
  CorporateConfig,
} from 'cloudsort-client';
import axios from '../utils/axios';

class Areas {
  private api: CorporateApi;

  constructor() {
    this.api = new CorporateApi(undefined, undefined, axios as any);
  }

  public async getCorporateConfig() {
    return this.api.corporateConfigList({});
  }

  public async getCorporateRoles() {
    return this.api.corporatePermissionsList({});
  }

  public async updateCorporateRoles(data: PatchCorporateRoles) {
    return this.api.corporatePermissionsPartialUpdate({
      data,
    });
  }

  public async edit(data: CorporateConfig) {
    return this.api.corporateConfigUpdate({
      data,
    });
  }

  public async getPermissionDependencies() {
    return this.api.corporatePermissionsDependenciesList({});
  }
}

export default new Areas();
