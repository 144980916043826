import React, { useEffect, useState } from 'react';
import {
  withStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles';
import { AuthRoutes } from '../../interfaces/routes';
import Layout from '../layout/Layout';
import {
  StaffPermissionPermissionsEnum,
  StaffPermissionRoleTypeEnum,
  WebHookSubscription,
} from 'cloudsort-client';
import PaginatedTable from '../paginatedTable/PaginatedTable';
import queryString from 'query-string';
import { Column } from '../../interfaces/components';
import {
  AlertBanner,
  CSButton,
  CSSwitch,
  Typography,
} from '../primitives/index';
import { Box, Grid, Tooltip } from '@material-ui/core';
import sectionPageBaseStyle from '../commonStyles/sectionPageBase.style';
import clx from 'classnames';
import FiltersDrawer, {
  SelectedFilters,
} from '../filtersDrawer/FiltersDrawer';
import filterBadgeStyle from '../filtersDrawer/filterBadge.style';
import PermissionsService from '../../services/Permissions.service';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Helmet } from 'react-helmet';
import WebhooksService from '../../services/Webhooks.service';
import CSSectionTitleSeparator from '../primitives/csSectionTitleSeparator/CSSectionTitleSeparator';
import CSHorizontalFilterBadgesGroup from '../primitives/csHorizontalFilterBadgesGroup/csHorizontalFilterBadgesGroup';
import { FilterDescription } from '../primitives/csHorizontalFilterBadgesGroup/csHorizontalFilterBadgesGroupTypes';
import AddEditWebhookDialog from './addEditWebhookDialog/AddEditWebhookDialog';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import EditIcon from '@material-ui/icons/Edit';
import ConfirmationDialog from '../confirmationDialog/ConfirmationDialog';
import cloneDeep from 'lodash/cloneDeep';
import capitalize from 'lodash/capitalize';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import ErrorHandler from '../../utils/ErrorHandler';
import { common } from '../../utils/strings';
import { AxiosError } from 'axios';

interface Props {
  classes: { [key: string]: string };
  location: any;
}

interface ExtendedWebhookSubscription extends WebHookSubscription {
  events_count: string;
  health_count_formatted: string;
  health_failure_rate_formatted: string;
  health_epm_formatted: string;
  last_triggered_formatted: string;
  times_triggered_formatted: string;
  status_switch: JSX.Element;
  filter_type_formatted: string;
  resource_type_formatted: string;
  url_formatted: JSX.Element;
  station_id?: string;
}

const COLUMNS_WEBHOOKS: Column[] = [
  {
    id: 'id',
    label: 'ID',
  },
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'url_formatted',
    label: 'Listener URL',
    useCustomComponent: true,
  },
  {
    id: 'station_id',
    label: 'Station',
  },
  {
    id: 'filter_type_formatted',
    label: 'Webhhok Type',
  },
  {
    id: 'resource_type_formatted',
    label: 'Resource Type',
  },
  {
    id: 'events_count',
    label: 'Events',
  },
  {
    id: 'health_count_formatted',
    label: 'Health Count',
  },
  {
    id: 'health_failure_rate_formatted',
    label: 'Health Failure Rate',
  },
  {
    id: 'health_epm_formatted',
    label: 'Health EPM',
  },
  {
    id: 'last_triggered_formatted',
    label: 'Last Triggered',
  },
  {
    id: 'times_triggered_formatted',
    label: 'Times Triggered',
  },
  {
    id: 'status_switch',
    label: 'Status',
    useCustomComponent: true,
  },
];

const Webhooks: React.FC<Props> = ({ classes, location }) => {
  const [showFiltersDrawer, setShowFiltersDrawer] = useState(false);
  const [showAddEditDialog, setShowAddEditDialog] = useState(false);
  const [webHookToEdit, setWebhookToEdit] =
    useState<WebHookSubscription>();
  const [selectedFilters, setSelectedFilters] =
    useState<SelectedFilters>();
  const [filterToRemove, setFilterToRemove] =
    useState<FilterDescription>();
  const [lastUpdated, setLastUpdated] = useState<string>(
    new Date().toISOString(),
  );
  const [webhooksData, setWebhooksData] =
    useState<WebHookSubscription[]>();

  const [webhookIdForStatusToggle, setWebhookIdForStatusToggle] =
    useState<number>();

  const [showProgress, setShowProgress] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    if (webhookIdForStatusToggle) {
      const webhook = webhooksData?.find(
        (w) => w.id === webhookIdForStatusToggle,
      );
      if (webhook) {
        setWebhookToEdit(webhook);
      }
    }
  }, [webhookIdForStatusToggle, webhooksData]);

  const processWebhookStatusChange = async () => {
    setShowProgress(true);
    const payload = cloneDeep(webHookToEdit);

    if (!payload) return;

    payload.disabled = !webHookToEdit?.disabled;
    if (!payload.organization_id) payload.organization_id = undefined;
    if (!payload.owner_id) payload.owner_id = undefined;

    try {
      await WebhooksService.update(payload.id!, payload);

      setLastUpdated(new Date().toISOString());
      setShowProgress(false);
      setWebhookIdForStatusToggle(undefined);
    } catch (e) {
      setError(await ErrorHandler.getLabel(e as AxiosError));
    }
  };

  const fetch = async (pageIndex: number, rowsPerPage: number) => {
    const owner = selectedFilters?.owner?.values[0]?.id as number;
    const organization = selectedFilters?.organization?.values[0]
      ?.id as number;
    const res = await WebhooksService.getAll({
      page: pageIndex,
      page_size: rowsPerPage,
      owner: owner,
      organization: organization,
    });

    setWebhooksData(res.data.results);

    for (const webhook of res.data
      .results as ExtendedWebhookSubscription[]) {
      webhook.events_count = (
        webhook.filter_events[0].event_types || []
      ).length.toString();
      webhook.health_count_formatted =
        webhook.task_object_summary?.health_count?.toString() || '';
      webhook.health_failure_rate_formatted = webhook
        .task_object_summary?.health_failure_rate
        ? webhook.task_object_summary.health_failure_rate.toFixed(2)
        : '';
      webhook.health_epm_formatted = webhook.task_object_summary
        ?.health_epm
        ? webhook.task_object_summary.health_epm.toFixed(2)
        : '';
      webhook.last_triggered_formatted = webhook.task_object_summary
        ?.last_completed
        ? new Date(
            webhook.task_object_summary.last_completed,
          ).toLocaleString()
        : '';
      webhook.times_triggered_formatted =
        webhook.task_object_summary?.total_tasks?.toString() || '';

      webhook.resource_type_formatted = capitalize(
        webhook.filter_events[0].resource_type,
      );
      webhook.filter_type_formatted = webhook.filter_events[0]
        .filter_type
        ? webhook.filter_events[0].filter_type
            .split('_')
            .map((word) => capitalize(word))
            .join(' ')
        : common.emptyValue;

      webhook.station_id = webhook.filter_events[0].station_id
        ? webhook.filter_events[0].station_id.toString()
        : common.emptyValue;

      webhook.url_formatted = (
        <Tooltip title={webhook.url} placement='top' arrow>
          <span className={classes.urlCell}>{webhook.url}</span>
        </Tooltip>
      );

      webhook.status_switch = (
        <Box className='actions-cell'>
          <CSSwitch
            checked={!webhook.disabled}
            onChange={() => {
              setWebhookIdForStatusToggle(webhook.id);
            }}
          />
        </Box>
      );
    }
    return res;
  };

  useEffect(() => {
    PermissionsService.redirectIfNoPermission(
      StaffPermissionPermissionsEnum.WEBHOOK_READ,
    );
    PermissionsService.redirectIfNoRoles([
      StaffPermissionRoleTypeEnum.ORG_ADMIN,
      StaffPermissionRoleTypeEnum.SUPER_USER,
    ]);
  }, []);

  const getDrawerFilters = (filters: SelectedFilters) => {
    setSelectedFilters(filters);
    setLastUpdated(new Date().toISOString());
  };

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort -
      Webhooks ${
        queryString.parse(location.search)['page']
          ? '- Page ' + queryString.parse(location.search)['page']
          : ''
      }`}
        </title>
      </Helmet>

      {showProgress && <ProgressIndicator />}

      {showAddEditDialog && (
        <AddEditWebhookDialog
          webhookToEdit={webHookToEdit}
          closeDialog={() => {
            setWebhookToEdit(undefined);
            setShowAddEditDialog(false);
          }}
          onAfterSave={() => {
            setLastUpdated(new Date().toISOString());
            setShowAddEditDialog(false);
            setWebhookToEdit(undefined);
          }}
        />
      )}
      <ConfirmationDialog
        title='Are you sure?'
        msg={`Changing staus of the ${
          webHookToEdit?.name
        } webhook to ${
          webHookToEdit?.disabled ? 'ENABLED' : 'DISABLED'
        }`}
        primaryActionLabel='Confirm'
        onPrimaryAction={() => {
          processWebhookStatusChange();
        }}
        cancelLabel='Cancel'
        onCancel={() => {
          setWebhookIdForStatusToggle(undefined);
        }}
        isOpen={!!webhookIdForStatusToggle}
      />
      <Layout>
        <FiltersDrawer
          isOpen={showFiltersDrawer}
          onAfterClose={() => {
            setShowFiltersDrawer(false);
          }}
          getFilters={getDrawerFilters}
          removeFilter={filterToRemove}
        />
        <Grid container className={classes.header}>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.title} variant={'h3'}>
              Webhooks
            </Typography>
          </Grid>
          {PermissionsService.belongsToAnyOfRoles([
            StaffPermissionRoleTypeEnum.SUPER_USER,
          ]) && (
            <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
              <CSButton
                variant='contained'
                color={{
                  buttonColor: 'secondary',
                  iconColor: 'primary',
                }}
                startIcon={<AddBoxOutlinedIcon />}
                className={clx(classes.headerButtons)}
                onClick={() => {
                  setShowAddEditDialog(true);
                }}
              >
                Add Webhook
              </CSButton>
              <CSButton
                variant='outlined'
                color='secondary'
                className={clx(
                  classes.headerButtons,
                  classes.headerFilterButton,
                )}
                onClick={() => {
                  setShowFiltersDrawer(true);
                }}
              >
                <FilterListIcon />
              </CSButton>
            </Grid>
          )}
          <CSSectionTitleSeparator topMargin={5} bottomMargin={24} />
          <Grid container item xs={12} spacing={1}>
            <CSHorizontalFilterBadgesGroup
              selectedFilters={selectedFilters}
              onRemoveFilter={(toRemove) =>
                setFilterToRemove(toRemove)
              }
            />
          </Grid>
          {error && (
            <Grid item xs={12}>
              <AlertBanner
                severity='error'
                alertTitle={'Error'}
                alertMsg={error}
              />
            </Grid>
          )}
        </Grid>

        {!!lastUpdated && (
          <PaginatedTable
            key={'webhooks-table' + lastUpdated}
            title={''}
            columns={COLUMNS_WEBHOOKS}
            dataTestIdPrefix={'webhooks'}
            fetch={fetch}
            rowsLoadDetailPages={true}
            detailsPageBasePath={AuthRoutes.WEBHOOKS}
            actions={
              PermissionsService.hasPermission(
                StaffPermissionPermissionsEnum.WEBHOOK_WRITE,
              )
                ? [
                    {
                      cellWidth: 70,
                      tableLabel: '',
                      columnLabel: <EditIcon />,
                      qualifier: 'id',
                      callback: (id: number) => {
                        const webhook = webhooksData?.find(
                          (webhook) => webhook.id === id,
                        );
                        setWebhookToEdit(webhook);
                        setShowAddEditDialog(true);
                      },
                    },
                  ]
                : undefined
            }
          />
        )}
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...sectionPageBaseStyle,
    ...filterBadgeStyle,
    headerFilterButton: {
      minWidth: '40px',
      marginLeft: '20px',
    },
    urlCell: {
      display: 'block',
      maxWidth: 100,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  })),
)(Webhooks);
