import { FormControlProps, StandardProps } from '@material-ui/core';
import {
  KeyboardDatePickerProps,
  KeyboardTimePickerProps,
  KeyboardDateTimePickerProps,
} from '@material-ui/pickers';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

export type SelectionType = 'date' | 'time' | 'datetime';
export type datepickerMode = 'dialog' | 'inline' | 'standard';

export interface DatePickerHandlers {
  onChange: (
    date: MaterialUiPickersDate,
    value?: string | null | undefined,
  ) => void;
  onClose: (() => void) | undefined;
  onAccept: ((date: ParsableDate) => void) | undefined;
  onCancel: () => void;
  onOpen: () => void;
}

export interface CSBasepickerProps
  extends Omit<
    StandardProps<FormControlProps, ContainerClassKeys>,
    | 'onFocus'
    | 'onBlur'
    | 'defaultValue'
    | 'onChange'
    | 'onError'
    | 'onAccept'
    | 'variant'
    | 'value'
  > {
  classes?: { [key: string]: string };
  isOpen?: boolean;
  initialValue: ParsableDate;
  onAcceptSelection?: (date: ParsableDate) => void;
  selectionType: SelectionType;
  responsiveModeOn?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  'data-testid'?: string;
}

export interface CSKeyboardTimepickerProps
  extends CSBasepickerProps,
    Omit<KeyboardTimePickerProps, 'value' | 'onChange'> {}
export interface CSKeyboardDatePicker
  extends CSBasepickerProps,
    Omit<KeyboardDatePickerProps, 'value' | 'onChange'> {}
export interface CSKeyboardDateTimePickerProps
  extends CSBasepickerProps,
    Omit<KeyboardDateTimePickerProps, 'value' | 'onChange'> {}

export type ContainerClassKeys = '';
export type DatePopperAlignment = 'center' | 'left' | 'right';
export const COMPONENT_NAME = 'CSDatepicker';
