export const STRING_PACKAGE_SCAN = 'PACKAGE_SCANNED';
export const STRING_PACKAGE_CONTAINERIZE = 'PACKAGE_CONTAINERIZED';
export const STRING_PACKAGE_STAGED = 'PACKAGE_STAGED';
export const STRING_PACKAGE_LOADED = 'PACKAGE_LOADED';
export const STRING_PACKAGE_UNLOADED = 'PACKAGE_UNLOADED';
export const STRING_PACKAGE_DISPATCHED = 'PACKAGE_DISPATCHED';
export const STRING_CONTAINER_SCAN = 'CONTAINER_SCANNED';
//why does this work with both CONTAINER_OPEN and CONTAINER_OPENED?
export const STRING_CONTAINER_OPEN = 'CONTAINER_OPENED';
export const STRING_CONTAINER_CLOSED = 'CONTAINER_CLOSED';
export const STRING_CONTAINER_STAGED = 'CONTAINER_STAGED';
export const STRING_CONTAINER_LOADED = 'CONTAINER_LOADED';
export const STRING_CONTAINER_DISPATCHED = 'CONTAINER_DISPATCHED';
export const STRING_CONTAINER_LABELED = 'CONTAINER_LABELED';
export const STRING_OUTBOUNDLOAD_SCAN = 'OUTBOUNDLOAD_SCANNED';
export const STRING_OUTBOUNDLOAD_DISPATCH = 'OUTBOUNDLOAD_DISPATCHED';

export const STRING_TARGET_PRIMARY_SORTATION =
  'Target Primary Sortation';
export const STRING_TARGET_SECONDARY_SORTATION =
  'Target Secondary Sortation';
export const STRING_TARGET_STAGING_AREA = 'Target Staging Area';
export const STRING_TARGET_LOAD = 'Target Load';
export const STRING_TARGET_LOAD_TIME = 'Target Load Time';

export const STRING_UNITS_VOLUME = 'ft\xB3';
export const STRING_UNITS_MEASUREMENT = 'in';
export const STRING_UNITS_WEIGHT = 'lbs';

export const STRING_CONTAINERIZED = 'Containerize';

export const STRING_MAPPING_EVENT_TYPES = {
  STRING_PACKAGE_CONTAINERIZE: STRING_CONTAINERIZED,
};

export const STRING_LOCATION = 'Location';

export const STRING_EVENTS_SNAPSHOT_OPEN = 'Open';
export const STRING_EVENTS_SNAPSHOT_CLOSED = 'Closed';
export const STRING_EVENTS_SNAPSHOT_STAGED = 'Staged';
export const STRING_EVENTS_SNAPSHOT_LOADED = 'Loaded';
export const STRING_EVENTS_SNAPSHOT_DISPATCHED = 'Dispatched';
export const STRING_EVENTS_SNAPSHOT_PRIMARY_SORTED = 'Primary Sorted';
export const STRING_EVENTS_SNAPSHOT_SECONDARY_SORTED =
  'Secondary Sorted';
export const STRING_EVENTS_SNAPSHOT_CONTAINERIZED = 'Containerized';
export const STRING_EVENTS_SNAPSHOT_ARRIVED = 'Arrived';
export const STRING_EVENTS_SNAPSHOT_EXPECTED = 'Expected';

export const STRING_LOADED_PACKAGES = 'Loaded Packages';
export const STRING_LABELED = 'Labeled';
export const STRING_LOADED_CONTAINERS = 'Loaded Containers';

export const STRING_LOAD = 'Load ID';
export const STRING_CONTAINER = 'Container';
export const STRING_PACKAGE = 'Package';

export const STRING_SIZE = 'Size (W x L x H)';
