import React, { useState } from 'react';
import {
  withStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles';
import { Container } from '../primitives';
import {
  Grid,
  Box,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import Navigation from '../navigation/Navigation';
import colors from '../../utils/colors';
import Header from '../header/Header';
import { ElementType } from '../stationLayout/StationLayout';

interface Props {
  classes: { [key: string]: string };
  className?: string;
  navCurrent?: string;
  children: any;
  layoutType?: string;
  expandToWholeScreen?: boolean;
  fullName?: string;
  showNavigation?: boolean;
  stationLayoutData?: any;
  selectStationLayoutItem?: (params: {
    type: ElementType;
    id: number;
  }) => void;
  addStationLayoutItem?: (params: {
    type: ElementType;
    id: number;
  }) => void;
  newElementClick?: number;
}

const Layout: React.FC<Props> = ({
  classes,
  className,
  children,
  navCurrent,
  layoutType = 'default',
  expandToWholeScreen = false,

  showNavigation = true,
}) => {
  const [navActive, setNavActive] = useState<boolean>(false);
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <Box
        className={
          navActive
            ? classes.mainNavHolder +
              ' ' +
              classes.mainNavHolderActive
            : classes.mainNavHolder
        }
      >
        <Grid
          container
          direction='row'
          style={{ height: '100vh' }}
          wrap={'nowrap'}
        >
          <Grid
            item
            xs={'auto'}
            className={classes.navigationHolder}
            id='main-navigation'
          >
            {showNavigation && (
              <Navigation currentPage={navCurrent} />
            )}
          </Grid>
        </Grid>
      </Box>
      <Header
        type={layoutType}
        navActive={navActive}
        setNavActive={setNavActive}
      />
      {expandToWholeScreen ? (
        <Box
          className={
            className +
            ' ' +
            classes.mainContainerFull +
            ' ' +
            (!showNavigation ? classes.mainContainerNoNav : undefined)
          }
        >
          {children}
        </Box>
      ) : (
        <Container
          className={
            className +
            ' ' +
            classes.mainContainer +
            ' ' +
            (!showNavigation ? classes.mainContainerNoNav : undefined)
          }
          maxWidth={'xl'}
        >
          <Grid
            container
            direction='row'
            style={{ minHeight: '100vh' }}
            wrap={'nowrap'}
          >
            <Grid item xs>
              <Grid item xs={12} id={'layout-portal-holder'}></Grid>
              <Grid item xs={12} className={classes.layoutMain}>
                <Box m={4} mt={isMobileView ? 14 : 12}>
                  {children}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    iconLeftNav: {
      width: 56,
      backgroundColor: '#222123',
      boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)',
    },
    icon: {
      width: 56,
      height: 56,
      borderRadius: 0,
      '&:hover': {
        backgroundColor: colors.gold,
        opacity: 0.8,
      },
      '& svg': {
        color: `${colors.white} !important`,
        fill: `${colors.white} !important`,
        stroke: `${colors.white} !important`,
        strokeWidth: '.3',
      },
    },
    selectedIcon: {
      backgroundColor: colors.gold,
    },
    navigationHolder: {
      backgroundColor: theme.palette.quaternary?.main,
      overflow: 'auto',
      paddingBottom: '100px',
    },
    navigationHolderProfile: {
      backgroundColor: colors.middleGold,
    },
    mainContainer: {
      padding: 0,
      overflow: 'hidden',
      [theme.breakpoints.up('md')]: {
        paddingLeft: '300px',
      },
    },
    mainContainerNoNav: {
      [theme.breakpoints.up('md')]: {
        paddingLeft: '56px',
      },
    },
    mainNavHolder: {
      [theme.breakpoints.down('sm')]: {
        transform: 'translateX(-100%)',
      },
      position: 'fixed',
      top: '64px',
      left: 0,
      zIndex: 10,
      transition: 'all 0.3s',
    },
    mainNavHolderActive: {
      transform: 'translateX(0)!important',
    },

    mainContainerFull: {
      position: 'fixed',
      top: '66px',
      left: 0,
      right: 0,
      bottom: 0,

      [theme.breakpoints.up('md')]: {
        left: '300px',
      },

      '& $mainContainerNoNav': {
        left: '56px',
      },
    },
  })),
)(Layout);
