export default {
  formControl: {
    minWidth: '12em',
    marginRight: 16,
    border: '1px solid #a3a3a3',
    borderRadius: '3px',
    backgroundColor: 'transparent',
    textTransform: 'none',
    padding: '10px',
    height: 'auto',
  },
  selectLabel: {
    top: '5px',
    left: '5px',
  },
  selectPaper: {},
  selectList: {},
  selectMenu: {
    backgroundColor: 'transparent!important',
    padding: '5px',
    border: 'none',
  },
};
