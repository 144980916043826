import MuiContainer, {
  ContainerProps,
} from '@material-ui/core/Container';
import * as React from 'react';

export interface IContainerProps extends ContainerProps {}

const Container: React.FC<IContainerProps> = props => {
  const { children, ...MuiProps } = props;
  return <MuiContainer {...MuiProps}>{children}</MuiContainer>;
};

export default Container;
