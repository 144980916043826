import ClearIcon from '@material-ui/icons/Clear';
import clsx from 'clsx';
import { getClearButtonStyles } from './CSSelect.styles';
import { ExtendedVariants, VariantSelector } from './CSSelectTypes';

export const selectVariantProperty = (
  valuesToReturn: VariantSelector,
  variant?: ExtendedVariants,
): Record<string, unknown> => {
  switch (variant) {
    case 'filled-outlined':
      return valuesToReturn.filledOutlined;
    case 'standard-outlined':
      return valuesToReturn.standardOutlined;
    default:
      return {};
  }
};

export const getEndAdornment = (
  value: string | undefined,
  onClear: () => void,
  hideClear: boolean = true,
) => {
  const classes = getClearButtonStyles();
  return (
    <div
      className={clsx(
        classes.singleAdornmentBox,
        classes.cursorPointer,
      )}
    >
      {!hideClear && !!value && (
        <div
          className={classes.cursorPointer}
          tabIndex={-1}
          onClick={onClear}
          role='button'
          aria-label='clear'
        >
          <ClearIcon />
        </div>
      )}
    </div>
  );
};
