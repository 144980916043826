import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Theme,
  Typography,
  createStyles,
  withStyles,
} from '@material-ui/core';
import { useCallback, useEffect, useState, useMemo } from 'react';
import CSDialogTitleWithIcon from '../../primitives/csDialogTitleWithIcon/CSDialogTitleWithIcon';
import { DialogIcons } from '../../primitives/csDialogTitleWithIcon/CSDialogTitleWithIconTypes';
import { CSMonoGridContainer } from '../../primitives/csMonoGridContainer';
import { CSButton, CSTextField } from '../../primitives';
import CSSelect from '../../primitives/csSelect/CSSelect';
import configurationUtils from '../../../utils/configurationUtils';
import CSAsyncSelect from '../../primitives/csAsyncSelect/CSAsyncSelect';
import { CSLink } from '../../primitives/csLink';
import {
  WebHookSubscription,
  WebHookSubscriptionFilterEventTypesEnum,
  WebHookSubscriptionFilterFilterTypeEnum,
  WebHookSubscriptionFilterResourceTypeEnum,
} from 'cloudsort-client';

import debounce from 'lodash/debounce';
import CustomersService from '../../../services/Customers.service';
import { MAX_PAGE_SIZE } from '../../../services/utils/constants';
import EphemeralStateService from '../../../services/EphemeralState.service';
import ErrorHandler from '../../../utils/ErrorHandler';
import { TypeAheadItem } from '../../../interfaces/components';
import OrganizationsService from '../../../services/Organizations.service';
import CSDialogAlert from '../../primitives/csDialogAlert/CSDialogAlert';
import capitalize from 'lodash/capitalize';
import WebhooksService from '../../../services/Webhooks.service';

import { useForm, Controller, FieldError } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { CSDualGridContainer } from '../../primitives/csDualGridContainer';
import CSSectionTitleSeparator from '../../primitives/csSectionTitleSeparator/CSSectionTitleSeparator';
import LocalStorageService from '../../../services/LocalStorage.service';
import { AxiosError } from 'axios';

interface Props {
  classes: { [key: string]: string };
  closeDialog: () => void;
  onAfterSave: (updatedWebhook: WebHookSubscription) => void;
  webhookToEdit?: WebHookSubscription;
}

enum EventTypeEnum {
  ALL = 'ALL',
  CUSTOM = 'CUSTOM',
  STANDARD = 'STANDARD',
}

enum DialogAction {
  ADD = 'ADD',
  EDIT = 'EDIT',
}

const AddEditWebhookDialog = ({
  classes,
  closeDialog,
  onAfterSave,
  webhookToEdit,
}: Props) => {
  const [error, setError] = useState<string | undefined>();
  const [dialogAction, setDialogAction] = useState<DialogAction>(
    DialogAction.ADD,
  );

  const stationOptions = useMemo(() => {
    return LocalStorageService.getMyStationsData();
  }, []);

  const handleError = async (e: any) => {
    setError(await ErrorHandler.getLabel(e as AxiosError));
  };

  const typeAheadItemZodObject = {
    value: z.string(),
    label: z.string(),
  };

  const formSchema = z
    .object({
      name: z.string().min(2),
      customer: z.object(typeAheadItemZodObject).optional(),
      organization: z.object(typeAheadItemZodObject).optional(),
      station: z.string().optional(),
      listenerURL: z.string().url(),
      webhookType: z.string().nonempty('Please select an option'),
      resourceType: z.string().nonempty('Please select an option'),
      eventsType: z.string().optional(),
      customEvents: z
        .array(z.object(typeAheadItemZodObject))
        .optional(),
    })
    .refine((data) => data.customer !== data.organization, {
      message: 'Either customer or organization are required',
      path: ['customer'],
    })
    .refine(
      (data) => !(data.customer?.value && data.organization?.value),
      {
        message: 'You can`t select both customer and organization',
        path: ['customer'],
      },
    );

  type FormSchemaType = z.infer<typeof formSchema>;

  const getDefaultValues = () => {
    const webhookToEditEvents = webhookToEdit?.filter_events.length
      ? webhookToEdit?.filter_events[0]
      : undefined;

    const parsedEventTypes = webhookToEdit?.filter_events.length
      ? webhookToEdit.filter_events[0].event_types
        ? webhookToEdit.filter_events[0].event_types.length
          ? EventTypeEnum.CUSTOM // non-empty array => Custom events
          : EventTypeEnum.ALL // empty array => All Events
        : EventTypeEnum.STANDARD // undefined => Standard Events
      : '';

    return {
      customer: webhookToEdit?.owner_id
        ? {
            label: webhookToEdit.owner_name,
            value: webhookToEdit.owner_id?.toString(),
          }
        : undefined,
      name: webhookToEdit ? webhookToEdit.name : '',
      organization: webhookToEdit?.organization_id
        ? {
            label: webhookToEdit.organization_name,
            value: webhookToEdit.organization_id?.toString(),
          }
        : undefined,
      station: webhookToEditEvents?.station_id
        ? webhookToEditEvents.station_id.toString()
        : 'all',
      listenerURL: webhookToEdit ? webhookToEdit.url : '',
      webhookType: webhookToEditEvents
        ? webhookToEditEvents.filter_type
        : '',
      resourceType: webhookToEditEvents
        ? webhookToEditEvents.resource_type
        : '',
      eventsType: parsedEventTypes,
      customEvents: webhookToEditEvents
        ? webhookToEditEvents.event_types?.map((type) => {
            return { label: type, value: type };
          })
        : undefined,
    };
  };

  const {
    control,
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
    errors,
  } = useForm<FormSchemaType>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    defaultValues: getDefaultValues(),
  });

  const onSubmit = async (data: FormSchemaType) => {
    setError(undefined);

    const parsedEventTypes =
      data.eventsType === EventTypeEnum.CUSTOM
        ? (data.customEvents?.map(
            (event) => event.value,
          ) as WebHookSubscriptionFilterEventTypesEnum[]) // manual selection
        : data.eventsType === EventTypeEnum.ALL
        ? [] // when empty array is sent, BE assumes ALL events
        : undefined; // whe undefined is sent, BE assumes only STANDARD events

    const payload: WebHookSubscription = {
      name: data.name,
      url: data.listenerURL,
      owner_id: data.customer?.value
        ? Number(data.customer.value)
        : undefined,
      organization_id: data.organization?.value
        ? Number(data.organization.value)
        : undefined,
      filter_events: [
        {
          station_id:
            data.station !== 'all' ? Number(data.station) : undefined,
          filter_type:
            data.webhookType as WebHookSubscriptionFilterFilterTypeEnum,
          resource_type:
            data.resourceType as WebHookSubscriptionFilterResourceTypeEnum,
          event_types: parsedEventTypes,
        },
      ],
    };

    try {
      if (dialogAction === DialogAction.ADD) {
        const res = (await WebhooksService.create(payload)).data;
        onAfterSave(res);
      } else if (webhookToEdit?.id) {
        const res = (
          await WebhooksService.update(webhookToEdit.id, payload)
        ).data;
        onAfterSave(res);
      }

      //reset form
      reset();

      setError(undefined);
    } catch (e) {
      handleError(e as AxiosError);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadCustomerOptions = useCallback(
    debounce((inputValue: string, callback: any) => {
      CustomersService.getAll(
        undefined,
        inputValue,
        MAX_PAGE_SIZE,
        EphemeralStateService.getMyStationId(),
        EphemeralStateService.getMyOrgId(),
      )
        .then((data) => {
          callback(
            data.data.results.map((dataEl) => {
              return {
                value: dataEl.id,
                label: dataEl.identifier,
              };
            }),
          );
        })
        .catch((e) => {
          handleError(e as AxiosError);
        });
    }, 500),
    [],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadOrganizationOptions = useCallback(
    debounce((inputValue: string, callback: any) => {
      OrganizationsService.getAll(
        undefined,
        MAX_PAGE_SIZE,
        inputValue,
      )
        .then((data) => {
          callback(
            data.data.results.map((dataEl) => {
              return {
                value: dataEl.id,
                label: dataEl.name,
              };
            }),
          );
        })
        .catch((e) => {
          handleError(e as AxiosError);
        });
    }, 500),
    [],
  );

  const loadEventOptions = (inputValue: string, callback: any) => {
    const filtered = Object.values(
      WebHookSubscriptionFilterEventTypesEnum,
    ).filter((key) =>
      key.toLowerCase().includes(inputValue.toLowerCase()),
    );

    callback(
      filtered.map((option) => {
        return {
          value: option,
          label: option,
        };
      }),
    );
  };

  const onCancel = () => {
    setError(undefined);
    closeDialog();
  };

  useEffect(() => {
    // Register Async Select fields that have no ref and values are set manually
    register('customer');
    register('organization');
    register('customEvents');
  }, [register]);

  useEffect(() => {
    if (webhookToEdit) {
      setDialogAction(DialogAction.EDIT);

      reset();
    } else {
      setDialogAction(DialogAction.ADD);
    }
  }, [webhookToEdit, reset]);

  return (
    <Dialog open data-testid='add-edit-webhook-dialog'>
      <DialogTitle>
        <CSDialogTitleWithIcon
          title={`${capitalize(dialogAction)} Webhook`}
          icon={
            dialogAction === DialogAction.ADD
              ? DialogIcons.ADD
              : DialogIcons.EDIT
          }
        />
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <CSDualGridContainer
            childrenColumnConfiguration={{
              0: 12,
              1: 12,
              4: 12,
            }}
          >
            {error ? (
              <CSDialogAlert alertMessage={error || ''} />
            ) : (
              <></>
            )}
            <>
              <Typography variant='h6'>Scope</Typography>
              <CSSectionTitleSeparator
                borderWidth='border_thin'
                topMargin={10}
                bottomMargin={0}
              />
            </>
            <CSAsyncSelect
              label='Customer'
              name='customer'
              placeholder='Customer'
              containerSize='fullHorizontal'
              loadOptions={loadCustomerOptions}
              menuPortalTarget={document.body}
              value={watch('customer')}
              disabled={dialogAction === DialogAction.EDIT}
              error={!!(errors.customer as FieldError)?.message}
              helperText={(errors.customer as FieldError)?.message}
              isClearable
              onChange={(option) => {
                setValue(
                  'customer',
                  option
                    ? {
                        ...option,
                        value: option.value.toString(),
                      }
                    : undefined,
                );
              }}
            />

            <CSAsyncSelect
              label='Organization'
              placeholder='Organization'
              containerSize='fullHorizontal'
              loadOptions={loadOrganizationOptions}
              menuPortalTarget={document.body}
              value={watch('organization')}
              disabled={dialogAction === DialogAction.EDIT}
              error={!!(errors.customer as FieldError)?.message}
              isClearable
              onChange={(option) => {
                setValue(
                  'organization',
                  option
                    ? {
                        ...option,
                        value: option.value.toString(),
                      }
                    : undefined,
                );
              }}
            />
            {dialogAction === DialogAction.ADD ? (
              <CSSectionTitleSeparator
                borderWidth='border_thin'
                topMargin={0}
                bottomMargin={10}
              />
            ) : (
              <>
                <CSDialogAlert alertMessage='The scope of the existing webhook subscription can not be modified.' />
                <CSSectionTitleSeparator
                  borderWidth='border_thin'
                  topMargin={10}
                  bottomMargin={10}
                />
              </>
            )}
          </CSDualGridContainer>
          <CSMonoGridContainer>
            <Controller
              name='name'
              control={control}
              render={(field) => (
                <CSTextField
                  label='Name'
                  placeholder='Name'
                  containerSize='fullHorizontal'
                  error={!!errors.name?.message}
                  helperText={errors.name?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name='listenerURL'
              control={control}
              render={(field) => (
                <CSTextField
                  label='Listener URL'
                  placeholder='Listener URL'
                  containerSize='fullHorizontal'
                  error={!!errors.listenerURL?.message}
                  helperText={errors.listenerURL?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name='station'
              control={control}
              render={(field) => (
                <CSSelect
                  label='Station'
                  placeholder='Station'
                  containerSize='fullHorizontal'
                  error={!!errors.station?.message}
                  helperText={errors.station?.message}
                  {...field}
                >
                  <MenuItem value='all'>All</MenuItem>
                  {stationOptions?.map((option) => (
                    <MenuItem
                      key={option.id}
                      value={option.id?.toString()}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </CSSelect>
              )}
            />
            <Controller
              name='webhookType'
              control={control}
              render={(field) => (
                <CSSelect
                  label='Webhook Type'
                  placeholder='Webhook Type'
                  containerSize='fullHorizontal'
                  error={!!errors.webhookType?.message}
                  helperText={errors.webhookType?.message}
                  {...field}
                >
                  <MenuItem
                    value={
                      WebHookSubscriptionFilterFilterTypeEnum.STAR
                    }
                  >
                    All
                  </MenuItem>
                  <MenuItem
                    value={
                      WebHookSubscriptionFilterFilterTypeEnum.OUTBOUND_MANIFEST
                    }
                  >
                    Outbound Manifests
                  </MenuItem>
                  <MenuItem
                    value={
                      WebHookSubscriptionFilterFilterTypeEnum.TRACKING_UPDATE
                    }
                  >
                    Tracking Updates
                  </MenuItem>
                </CSSelect>
              )}
            />

            <Controller
              name='resourceType'
              control={control}
              render={(field) => (
                <CSSelect
                  label='Resource Type'
                  placeholder='Resource Type'
                  containerSize='fullHorizontal'
                  error={!!errors.resourceType?.message}
                  helperText={errors.resourceType?.message}
                  {...field}
                >
                  <MenuItem
                    value={
                      WebHookSubscriptionFilterResourceTypeEnum.STAR
                    }
                  >
                    All
                  </MenuItem>
                  <MenuItem
                    value={
                      WebHookSubscriptionFilterResourceTypeEnum.CONTAINER
                    }
                  >
                    {configurationUtils.getPageTitle(
                      false,
                      'CONTAINER',
                    )}
                  </MenuItem>
                  <MenuItem
                    value={
                      WebHookSubscriptionFilterResourceTypeEnum.PACKAGE
                    }
                  >
                    {configurationUtils.getPageTitle(
                      false,
                      'PACKAGE',
                    )}
                  </MenuItem>
                </CSSelect>
              )}
            />
            {watch('webhookType') ===
            WebHookSubscriptionFilterFilterTypeEnum.TRACKING_UPDATE ? (
              <Controller
                name='eventsType'
                control={control}
                render={(field) => (
                  <CSSelect
                    label='Events'
                    placeholder='Events'
                    containerSize='fullHorizontal'
                    error={!!errors.eventsType?.message}
                    helperText={errors.eventsType?.message}
                    {...field}
                  >
                    <MenuItem value={EventTypeEnum.ALL}>All</MenuItem>
                    <MenuItem value={EventTypeEnum.STANDARD}>
                      Standard
                    </MenuItem>
                    <MenuItem value={EventTypeEnum.CUSTOM}>
                      Custom
                    </MenuItem>
                  </CSSelect>
                )}
              />
            ) : (
              <></>
            )}
            {watch('eventsType') === EventTypeEnum.CUSTOM ? (
              <>
                <CSAsyncSelect<TypeAheadItem, true>
                  isMulti
                  placeholder='Search Event Types'
                  containerSize='fullHorizontal'
                  menuPortalTarget={document.body}
                  loadOptions={loadEventOptions}
                  isClearable
                  value={watch('customEvents')}
                  onChange={(newValues: readonly TypeAheadItem[]) => {
                    setValue('customEvents', newValues);
                  }}
                />
                <Typography
                  variant='body2'
                  className={classes.marginTop10}
                >
                  You can find the full list of events{' '}
                  <CSLink
                    to={{
                      pathname: `https://${window.location.host.replace(
                        'web',
                        'api',
                      )}/api-docs/#tag/Webhooks-API/operation/webhooks_list`,
                    }}
                    target='_blank'
                  >
                    here.
                  </CSLink>
                </Typography>
              </>
            ) : (
              <></>
            )}
          </CSMonoGridContainer>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <CSButton
            variant='outlined'
            color='secondary'
            className={classes.containedButton}
            onClick={onCancel}
          >
            Cancel
          </CSButton>
          <CSButton
            variant='contained'
            color='secondary'
            className={classes.containedButton}
            type='submit'
          >
            {dialogAction === DialogAction.ADD ? 'Add' : 'Save'}
          </CSButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    dialogActions: {
      padding: 24,
    },
    marginTop10: {
      marginTop: 10,
    },
  })),
)(AddEditWebhookDialog);
