import React from 'react';
import { renderTimestampString } from '../DetailsPagesFunctions';
import { Event } from 'cloudsort-client';
import PaginatedTable, {
  filterObj,
} from '../paginatedTable/PaginatedTable';
import { Column } from '../../interfaces/components';
import fedexLabels from '../../utils/fedexLabels';
import configurationUtils from '../../utils/configurationUtils';
import { common } from '../../utils/strings';

interface Props {
  fetch: (
    pageIndex: number,
    rowsPerPage: number,
    sortedBy?: string,
  ) => any;
  dataTestIdPrefix?: string;
  columns?: Column[];
  sortableBy?: string[];
  defaultSort?: string;
}

const EventsPaginatedTable: React.FC<Props> = ({
  fetch,
  dataTestIdPrefix,
  columns,
  sortableBy,
  defaultSort,
}) => {
  // Get labels on each render cycle
  let COLUMNS_EVENTS: Column[] = [
    {
      id: 'timestamp',
      label: 'Time',
      width: 'auto',
      align: 'center',
    },
    {
      id: 'event_type',
      label: 'Event Type',
      width: 'auto',
      align: 'center',
    },
    {
      id: 'user_full_name',
      label: configurationUtils.getPageTitle(true, 'STAFF'),
      width: 'auto',
      align: 'center',
    },
    {
      id: 'device_id',
      label: configurationUtils.getPageTitle(true, 'DEVICE'),
      width: 'auto',
      align: 'center',
    },
    {
      id: 'zone_name',
      label: configurationUtils.getPageTitle(true, 'ZONE'),
      width: 'auto',
      align: 'center',
    },
    {
      id: 'loadpoint_name',
      label: configurationUtils.getPageTitle(true, 'LOADPOINT'),
      width: 'auto',
      align: 'center',
    },
    {
      id: 'dockdoor_name',
      label: configurationUtils.getPageTitle(true, 'DOCK_DOOR'),
      width: 'auto',
      align: 'center',
    },
  ];

  if (configurationUtils.isModuleActive('AREA'))
    COLUMNS_EVENTS.splice(4, 0, {
      id: 'area_name',
      label: 'Area',
      width: 'auto',
      align: 'center',
    });

  const fetchEvents = async (
    pageIndex: number,
    rowsPerPage: number,
    filterOptions?: filterObj[],
    filterByString?: string,
    sortedBy?: string,
  ) => {
    const res = await fetch(pageIndex, rowsPerPage, sortedBy);
    res.data.results.forEach((event: any) => {
      event.timestamp = renderTimestampString(
        (event as Event).timestamp,
      ).toLocaleLowerCase();
      event.area_name =
        (event as Event).area_name || common.emptyValue;
      event.user_full_name =
        (event as Event).user_full_name || common.emptyValue;
      event.device_id =
        (event as Event).device_id || common.emptyValue;
      event.event_type = fedexLabels.getLabel(event.event_type);
      event.dockdoor_name =
        (event as Event).dockdoor_name || common.emptyValue;
      event.loadpoint_name =
        (event as Event).loadpoint_name || common.emptyValue;
      event.zone_name =
        (event as Event).zone_name || common.emptyValue;
    });
    return res;
  };

  return (
    <PaginatedTable
      title={'Event Log'}
      columns={columns || COLUMNS_EVENTS}
      dataTestIdPrefix={dataTestIdPrefix}
      fetch={fetchEvents}
      sortableBy={sortableBy}
      defaultSort={defaultSort}
      disableUpdateQueryStringUrl
    />
  );
};

export default EventsPaginatedTable;
