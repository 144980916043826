import React, { useEffect, useState } from 'react';

import DashboardStatsService from '../../../services/DashboardStats.service';
import { AuthRoutes } from '../../../interfaces/routes';
import { withStyles } from '@material-ui/core/styles';
import {
  createFiltersQuery,
  getAppliedFilters,
  SelectedFilters,
} from '../../filtersDrawer/FiltersDrawer';
import StackedStats, {
  CardContainerVariants,
  CardItemVariants,
  CardLayoutVariants,
  StatData,
} from '../../stackedStats/StackedStats';
import { LoaderRing } from '../../primitives';
import { renderVolumeString } from '../../DetailsPagesFunctions';
import { CARD_LOAD_DELAY } from '../Dashboard';
import queryString from 'query-string';
import useMountedStatus from '../../../hooks/useMountedStatus';
import useNetworkStatsErrorRegistry from '../../../hooks/useNetworkStatsErrorRegistry';
import { setErrorsOnData } from './utils/utils';

interface Props {
  title: string;
  filters?: SelectedFilters;
  index: number;
  classes: { [key: string]: string };
}

const packageLabels: {
  key: any;
  label: any;
}[] = [
  {
    key: 'IN_SYSTEM',
    label: 'In System',
  },
  {
    key: 'SCANNED_TODAY',
    label: 'Scanned Today',
  },
  {
    key: 'MANIFESTED',
    label: 'Manifested',
  },
  {
    key: 'MANIFESTED_NOT_SCANNED',
    label: 'Manifested, Not Scanned',
  },
  {
    key: 'NOT_MANIFESTED',
    label: 'Not Manifested',
  },
];

const SummaryStats: React.FC<Props> = ({ title, filters, index }) => {
  const [key, setKey] = useState<'count' | 'volume'>('count');
  const [data, setData] = useState<StatData>({});
  const [dataVolume, setDataVolume] = useState<StatData>({});

  const componentMounted = useMountedStatus();
  const { networkStatsErrors, registerError } =
    useNetworkStatsErrorRegistry(
      packageLabels.map((label) => label.key),
    );

  const fetchStats = async () => {
    const parsedFilters = {
      owner:
        filters && filters.owner && filters.owner.values.length
          ? filters.owner.values.map((item: any) => item.id)
          : undefined,
      carrier:
        filters && filters.carrier && filters.carrier.values.length
          ? filters.carrier.values.map((item: any) => item.id)
          : undefined,
      route:
        filters && filters.route && filters.route.values.length
          ? filters.route.values.map((item: any) => item.id)
          : undefined,
      destination:
        filters && filters.stop && filters.stop.values.length
          ? filters.stop.values.map((item: any) => item.id)
          : undefined,
      hidePending:
        filters &&
        filters.hidePending &&
        filters.hidePending.values.length
          ? filters.hidePending.values[0].id === 'yes'
            ? true
            : filters.hidePending.values[0].id === 'no'
            ? false
            : undefined
          : undefined,
    };

    let results: StatData = packageLabels.reduce((acc, step) => {
      return {
        ...acc,
        [step.key]: {
          label: step.label,
          value: 'pending',
        },
      };
    }, {});

    let resultsVolume = results;

    setData(results);
    setDataVolume(resultsVolume);

    const filtersQuery = createFiltersQuery(filters);

    setTimeout(() => {
      // Fetch data

      DashboardStatsService.getVolume({
        inSystem: true,
        ...parsedFilters,
      })
        .then((res) => {
          results = {
            ...results,
            IN_SYSTEM: {
              ...results['IN_SYSTEM'],
              value: res.data.count.toString(),
              filter: {
                name:
                  queryString.stringify(filtersQuery) + '&in_system',
                value: 'yes',
              },
            },
          };
          if (componentMounted) {
            setData(results);
          }
          resultsVolume = {
            ...resultsVolume,
            IN_SYSTEM: {
              ...resultsVolume['IN_SYSTEM'],
              value: renderVolumeString(res.data.volume),
              filter: {
                name:
                  queryString.stringify(filtersQuery) + '&in_system',
                value: 'yes',
              },
            },
          };
          if (componentMounted) {
            setDataVolume(resultsVolume);
          }
        })
        .catch((e) => {
          registerError('IN_SYSTEM');
        });

      DashboardStatsService.getVolume({
        firstScannedInWindow: true,
        ...parsedFilters,
      })
        .then((res) => {
          results = {
            ...results,
            SCANNED_TODAY: {
              ...results['SCANNED_TODAY'],
              value: res.data.count.toString(),
              filter: {
                name:
                  queryString.stringify(filtersQuery) +
                  '&packageScannedToday',
                value: 'yes',
              },
            },
          };
          if (componentMounted) {
            setData(results);
          }
          resultsVolume = {
            ...resultsVolume,
            SCANNED_TODAY: {
              ...resultsVolume['SCANNED_TODAY'],
              value: renderVolumeString(res.data.volume),
              filter: {
                name:
                  queryString.stringify(filtersQuery) +
                  '&packageScannedToday',
                value: 'yes',
              },
            },
          };
          if (componentMounted) {
            setDataVolume(resultsVolume);
          }
        })
        .catch((e) => {
          registerError('SCANNED_TODAY');
        });

      DashboardStatsService.getVolume({
        isManifested: true,
        ...parsedFilters,
      })
        .then((res) => {
          results = {
            ...results,
            MANIFESTED: {
              ...results['MANIFESTED'],
              value: res.data.count.toString(),
              filter: {
                name:
                  queryString.stringify(filtersQuery) + '&manifested',
                value: 'yes',
              },
            },
          };
          if (componentMounted) {
            setData(results);
          }
          resultsVolume = {
            ...resultsVolume,
            MANIFESTED: {
              ...resultsVolume['MANIFESTED'],
              value: renderVolumeString(res.data.volume),
              filter: {
                name:
                  queryString.stringify(filtersQuery) + '&manifested',
                value: 'yes',
              },
            },
          };
          if (componentMounted) {
            setDataVolume(resultsVolume);
          }
        })
        .catch((e) => {
          registerError('MANIFESTED');
        });

      DashboardStatsService.getVolume({
        isManifested: true,
        isScanned: false,
        ...parsedFilters,
      })
        .then((res) => {
          results = {
            ...results,
            MANIFESTED_NOT_SCANNED: {
              ...results['MANIFESTED_NOT_SCANNED'],
              value: res.data.count.toString(),
              filter: {
                name:
                  queryString.stringify(filtersQuery) +
                  '&manifested=yes&scanned',
                value: 'no',
              },
            },
          };
          if (componentMounted) {
            setData(results);
          }
          resultsVolume = {
            ...resultsVolume,
            MANIFESTED_NOT_SCANNED: {
              ...resultsVolume['MANIFESTED_NOT_SCANNED'],
              value: renderVolumeString(res.data.volume),
              filter: {
                name:
                  queryString.stringify(filtersQuery) +
                  '&manifested=yes&scanned',
                value: 'no',
              },
            },
          };
          if (componentMounted) {
            setDataVolume(resultsVolume);
          }
        })
        .catch((e) => {
          registerError('MANIFESTED_NOT_SCANNED');
        });

      DashboardStatsService.getVolume({
        isManifested: false,
        ...parsedFilters,
      })
        .then((res) => {
          results = {
            ...results,
            NOT_MANIFESTED: {
              ...results['NOT_MANIFESTED'],
              value: res.data.count.toString(),
              filter: {
                name:
                  queryString.stringify(filtersQuery) + '&manifested',
                value: 'no',
              },
            },
          };
          if (componentMounted) {
            setData(results);
          }
          resultsVolume = {
            ...resultsVolume,
            NOT_MANIFESTED: {
              ...resultsVolume['NOT_MANIFESTED'],
              value: renderVolumeString(res.data.volume),
              filter: {
                name:
                  queryString.stringify(filtersQuery) + '&manifested',
                value: 'no',
              },
            },
          };
          if (componentMounted) {
            setDataVolume(resultsVolume);
          }
        })
        .catch((e) => {
          registerError('NOT_MANIFESTED');
        });
    }, index * CARD_LOAD_DELAY);
  };

  useEffect(() => {
    fetchStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return data ? (
    <>
      <StackedStats
        title={title}
        containerStyleVariant={CardContainerVariants.DARK}
        itemsStyleVariant={CardItemVariants.NO_BG_WHITE}
        itemsLayoutVariant={CardLayoutVariants.TITLE_AND_COUNT}
        dataTestId='packages-stats'
        stats={setErrorsOnData(
          key === 'count' ? data : dataVolume,
          networkStatsErrors,
        )}
        linkBase={AuthRoutes.PACKAGE}
        twoWaySwitchLabels={['Count', 'Volume']}
        twoWaySwitchAction={(active: boolean) => {
          if (active) setKey('count');
          else setKey('volume');
        }}
        filters={getAppliedFilters(filters)}
      />
    </>
  ) : (
    <LoaderRing />
  );
};

export default withStyles({})(SummaryStats);
