import { createStyles, Theme, withStyles } from '@material-ui/core';
import flowColors from '../flowColors';
import { getFormattedTimeLeft } from '../flowUtils';
import Handles from './parts/Handles';

const StagingAreaNode = ({
  classes,
  data,
  sourcePosition,
  targetPosition,
}: any) => {
  const { isThroughputMode } = data;

  return (
    <>
      <div
        className={classes.nodeContainer}
        style={{ width: data.width, height: data.height }}
      >
        <h4>{data.name}</h4>
        <p>{data.packages} P</p>
        {data?.meta?.areaMeta.dispatch_time && !isThroughputMode && (
          <p style={{ marginTop: 10 }}>
            {getFormattedTimeLeft(data.meta.areaMeta.dispatch_time)}
          </p>
        )}
      </div>
      <Handles
        sourcePosition={sourcePosition}
        targetPosition={targetPosition}
      />
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    nodeContainer: {
      padding: '5px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'strech',
      flexDirection: 'column',
      color: 'white',
      background: flowColors.white,
      border: `1px solid ${flowColors.primaryBorder}`,
      borderRadius: 5,

      '& h4': {
        color: flowColors.black,
        fontSize: '15px',
        lineHeight: '15px',
        margin: 0,
        marginBottom: 5,
        fontWeight: 'bold',
      },

      '& p': {
        padding: 0,
        margin: 0,
        color: flowColors.black,
        fontSize: 10,
      },
    },
    invisibleHandle: {
      opacity: 0,
      width: 1,
      height: 1,
      border: 0,
    },
  })),
)(StagingAreaNode);
