import React, {
  useEffect,
  useState,
  useCallback,
  PropsWithChildren,
  useMemo,
  CSSProperties,
} from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import Layout from '../layout/Layout';
import PaginatedTable, {
  filterObj,
} from '../paginatedTable/PaginatedTable';
import {
  AuthRoutes,
  ModulesKeys,
  stationDashboardUrlId,
} from '../../interfaces/routes';
import queryString from 'query-string';
import { CSButton, Typography } from '../primitives';
import { MAX_PAGE_SIZE } from '../../services/utils/constants';
import ErrorHandler from '../../utils/ErrorHandler';
import { common } from '../../utils/strings';
import { AlertBanner } from '../primitives/index';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {
  Link,
  RouteComponentProps,
  useParams,
} from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ConfirmationDialog from '../confirmationDialog/ConfirmationDialog';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import configurationUtils from '../../utils/configurationUtils';
import selectStyles from '../select/select.styles';
import {
  Grid,
  Theme,
  Box,
  useTheme,
  useMediaQuery,
  Divider,
} from '@material-ui/core';
import sectionPageBaseStyle from '../commonStyles/sectionPageBase.style';
import detailsPageStyles from '../commonStyles/detailsPage.style';
import AsyncSelect from 'react-select/async';
import { components, CSSObjectWithLabel } from 'react-select';
import { TypeAheadItem, Column } from '../../interfaces/components';
import debounce from 'lodash/debounce';
import AddStopsDialog from './AddStopDialog';

import StationsService from '../../services/Stations.service';
import LoadPointsService from '../../services/LoadPoints.service';
import LoadPlansService from '../../services/LoadPlans.service';
import RoutesService from '../../services/Routes.service';
import SchemesService from '../../services/Schemes.service';
import PermissionsService from '../../services/Permissions.service';
import EphemeralStateService from '../../services/EphemeralState.service';

import AddToPhotosOutlinedIcon from '@material-ui/icons/AddToPhotosOutlined';
import AppsIcon from '@material-ui/icons/Apps';
import SearchIcon from '@material-ui/icons/Search';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import FilterListIcon from '@material-ui/icons/FilterList';

import { AxiosError } from 'axios';
import {
  Route2StationDetails,
  Scheme,
  LoadPoint,
  Location,
  StaffPermissionPermissionsEnum,
  APIExceptionErrorCodeEnum,
} from 'cloudsort-client';
import browserHistory from '../../utils/browserHistory';
import { Helmet } from 'react-helmet';
import { noOptionsMessage } from '../asyncSelect/utils';
import CloudDownloadOutlined from '@material-ui/icons/CloudDownloadOutlined';
import CSSectionTitleSeparator from '../primitives/csSectionTitleSeparator/CSSectionTitleSeparator';
import clx from 'classnames';
import FiltersDrawer, {
  SelectedFilters,
} from '../filtersDrawer/FiltersDrawer';
import filterBadgeStyle from '../filtersDrawer/filterBadge.style';
import CSHorizontalFilterBadgesGroup from '../primitives/csHorizontalFilterBadgesGroup/csHorizontalFilterBadgesGroup';
import usePolling from '../../hooks/usePolling';
import { useAppDispatch } from '../../redux/store';
import { setLastVisitedModule } from '../../redux/slices/navigationSlice';

const DEFAULT_ALIGN = 'left';

interface Props extends RouteComponentProps {
  match: any;
  classes: { [key: string]: string };
}

interface StopsData {
  lpData?: LoadPoint[];
  routesData?: Route2StationDetails[];
  schemesData: Scheme[];
}

const Stops: React.FC<Props> = ({ match, classes, location }) => {
  //Data
  const [locations, setLocation] = useState<Location[]>([]);
  const [stopsData, setStopsData] = useState<Map<number, StopsData>>(
    new Map<number, StopsData>(),
  );
  const [activeLoadPlan, setActiveLoadPlan] = useState<number>();
  //State
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [lastUpdated, setLastUpdated] = useState<string>(
    new Date().toISOString(),
  );
  const [error, setError] = useState<string>();
  const [batchExportEnabled, setBatchExportEnabled] =
    useState<boolean>(false);
  const [showAddDialog, setShowAddDialog] = useState<boolean>(false);
  const [addDialogActionType, setAddDialogActionType] = useState<
    'add' | 'edit'
  >('add');
  //State related delete actions
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>();
  const [deleteName, setDeleteName] = useState<string>();
  // State related edit schemes actions
  const [showSchemeConflictDialog, setShowSchemeConflictDialog] =
    useState<boolean>(false);
  const [selectedSchemesToAdd, setSelectedSchemesToAdd] = useState<
    TypeAheadItem[]
  >([]);
  const [selectedSchemesToDelete, setSelectedSchemesToDelete] =
    useState<Scheme[]>([]);
  //State related edit stops actions
  const [stopToEdit, setStopToEdit] = useState<any>();
  const [editingStopData, setEditingStopData] = useState<any>();
  //Filters
  const [showFiltersDrawer, setShowFiltersDrawer] = useState(false);
  const [selectedFilters, setSelectedFilters] =
    useState<SelectedFilters>({});
  const [filterToRemove, setFilterToRemove] = useState<{
    filterKey: string;
    filterValue: string | number;
  }>();
  const [filterLastUpdate, setFilterLastUpdate] = useState<number>(0);
  //Misc
  const urlParams: any = useParams();
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const isXsScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const stopsLabels = useMemo(() => {
    return {
      singular: configurationUtils.getPageTitle(true, 'STOP'),
      plural: configurationUtils.getPageTitle(false, 'STOP'),
    };
  }, []);

  const dispatch = useAppDispatch();

  const preLoadStops = async () => {
    setShowProgress(true);
    setTimeout(async () => {
      const preloadIds = urlParams.defaultOpenIds
        ?.split('-')
        .map((id: string) => parseInt(id));
      const res: Map<number, StopsData>[] = await Promise.all(
        preloadIds?.map(async (id: number) => {
          return await loadExpandedContentData({ id }, () => {});
        }),
      );
      let mapState: Map<number, StopsData> = new Map();
      res.forEach((r) => {
        if (r) {
          Array.from(r.keys()).forEach((key) => {
            mapState.set(key, r.get(key)!);
          });
        }
      });
      if (mapState.size) {
        setStopsData(mapState);
      }
      setShowProgress(false);
    }, 250);
  };

  useEffect(() => {
    if (urlParams?.defaultOpenIds) {
      preLoadStops();
      setTimeout(() => {
        window.history.replaceState(
          null,
          '',
          AuthRoutes.STOP +
            '?stationId=' +
            EphemeralStateService.getMyStationId(),
        );
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParams]);

  // Get labels on each render cycle
  let COLUMNS_STOPS: Column[] = [
    {
      id: 'name',
      label: configurationUtils.getPageTitle(true, 'STOP'),
      width: isSmScreen ? 200 : 300,
      align: DEFAULT_ALIGN,
    },
  ];

  if (configurationUtils.isModuleActive('LOADPOINT'))
    COLUMNS_STOPS.push({
      id: 'load_points_count',
      label: 'Load Points',
      width: isSmScreen ? 120 : 150,
      align: DEFAULT_ALIGN,
    });

  if (configurationUtils.isModuleActive('ROUTE'))
    COLUMNS_STOPS.push({
      id: 'routes_count',
      label: configurationUtils.getPageTitle(false, 'ROUTE'),
      width: isSmScreen ? 120 : 150,
      align: DEFAULT_ALIGN,
    });

  COLUMNS_STOPS.push(
    {
      id: 'fmcs_label',
      label: 'Carrier',
      align: DEFAULT_ALIGN,
      width: isSmScreen ? 120 : 150,
    },
    {
      id: 'schemes_count',
      label: 'Schemes',
      align: DEFAULT_ALIGN,
      width: isSmScreen ? 120 : 150,
    },
    {
      id: 'geo_location',
      label: 'GPS Coordinates',
      width: isSmScreen ? 200 : 300,
      align: DEFAULT_ALIGN,
    },
  );

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e as AxiosError));
  };

  const getActiveLoadPlan = async () => {
    try {
      const {
        data: { results },
      } = await LoadPlansService.getAll({ active: true });
      if (results.length) {
        setActiveLoadPlan(results[0].id);
      }
    } catch (e) {
      handleError(e as AxiosError);
    }
  };

  useEffect(() => {
    PermissionsService.redirectIfNoPermission(
      StaffPermissionPermissionsEnum.STATION_READ,
    );

    dispatch(
      setLastVisitedModule({
        module: ModulesKeys.STOP,
        permission: StaffPermissionPermissionsEnum.STATION_READ,
      }),
    );

    getActiveLoadPlan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadSchemeSearchOptions = useCallback(
    debounce((inputValue: string, callback: any) => {
      const stopSchemesIds = stopToEdit.data.schemesData.map(
        (scheme: Scheme) => scheme.id,
      );
      SchemesService.getByName(inputValue, activeLoadPlan)
        .then((data) => {
          callback(
            data.data.results
              .filter((result) => {
                return !stopSchemesIds.includes(result.id);
              })
              .map((result) => {
                return {
                  value: result,
                  label: result.name,
                };
              }),
          );
        })
        .catch((e) => {
          handleError(e as AxiosError);
          setStopToEdit(undefined);
        });
    }, 500),
    [stopToEdit],
  );

  const processDelete = async () => {
    setShowDeleteConfirmation(false);
    try {
      await StationsService.deleteStation(deleteId!);
      setLastUpdated(new Date().toISOString());
    } catch (e) {
      handleError(e as AxiosError);
    }
  };

  const getDrawerFilters = (filters: SelectedFilters) => {
    setSelectedFilters(filters);
    setFilterLastUpdate(Date.now());
  };

  const onCloseEditDestinationSchemesDialog = () => {
    setStopToEdit(undefined);
    setSelectedSchemesToAdd([]);
    setSelectedSchemesToDelete([]);
  };

  const { startPolling, checkTaskId } = usePolling();

  const exportStops = async () => {
    let fmc, route;
    if (selectedFilters.carrier?.values?.length > 0) {
      fmc = selectedFilters?.carrier.values[0].name as string;
    }
    if (selectedFilters.route?.values?.length > 0) {
      route = selectedFilters?.route.values[0].id as string;
    }
    try {
      setShowProgress(true);
      const res = await StationsService.locationsExport({
        route: route ? parseInt(route) : undefined,
        fmc: fmc,
      });

      const fileNamStr = `${stopsLabels.plural}-export.csv`;

      startPolling(() => {
        checkTaskId(
          res.data.task_id,
          fileNamStr,
          setError,
          setShowProgress,
        );
      });
    } catch (e) {
      handleError(e as AxiosError);
    }
  };

  const fetchLocations = async (
    pageIndex: number,
    rowsPerPage: number,
    filterOptions?: filterObj[],
    filterByString?: string,
    sortedBy?: string,
  ) => {
    let fmc, route;
    if (selectedFilters.carrier?.values?.length > 0) {
      fmc = selectedFilters?.carrier.values[0].name as string;
    }
    if (selectedFilters.route?.values?.length > 0) {
      route = selectedFilters?.route.values[0].id as string;
    }

    const res = await StationsService.getLocations({
      page: pageIndex,
      pageSize: rowsPerPage,
      search: filterByString,
      sortBy: sortedBy as any,
      route: route ? parseInt(route) : undefined,
      fmc,
      stationOwner: EphemeralStateService.getMyStationId(),
    });

    setLocation(res.data.results);

    res.data.results.forEach((stop: any) => {
      stop.rules = common.emptyValue;
      stop.fmcs_label = stop.fmcs.length
        ? stop.fmcs.join(', ')
        : common.emptyValue;
      stop.geo_location =
        stop.geo_location[0] && stop.geo_location[1]
          ? `${stop.geo_location[0]}, ${stop.geo_location[1]}`
          : common.emptyValue;
    });

    if (res.data.count > 0) {
      setBatchExportEnabled(true);
    } else {
      setBatchExportEnabled(false);
    }

    return res;
  };

  const loadExpandedContentData = async (
    params: any,
    onAfterLoadExpandedContentData: () => void,
  ) => {
    try {
      if (!stopsData.get(params.id)) {
        let lpData, routesData;

        if (configurationUtils.isModuleActive('LOADPOINT'))
          lpData = await LoadPointsService.getAll({
            targetStation: params.id,
            pageSize: MAX_PAGE_SIZE,
            isActive: true,
          });
        if (configurationUtils.isModuleActive('ROUTE'))
          routesData = await RoutesService.getRouteToStationsById(
            params.id,
            MAX_PAGE_SIZE,
          );
        const schemesData = await SchemesService.getAll({
          station: params.id,
          stationOwner: EphemeralStateService.getMyStationId(),
          isActive: true,
        });
        const mapState = new Map(stopsData);
        mapState.set(params.id, {
          lpData: configurationUtils.isModuleActive('LOADPOINT')
            ? lpData?.data.results
            : undefined,
          routesData: configurationUtils.isModuleActive('ROUTE')
            ? routesData?.data.results
            : undefined,
          schemesData: schemesData.data.results,
        });
        setStopsData(mapState);
        return mapState;
      }
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      onAfterLoadExpandedContentData();
    }
  };

  const editSchemes = async (conflictResolve?: boolean) => {
    setShowProgress(true);
    try {
      const schemesToAdd = (
        selectedSchemesToAdd as unknown as any[]
      )?.map((el: any) => el.value);
      const calls: Promise<any>[] = [];
      if (selectedSchemesToDelete?.length) {
        selectedSchemesToDelete.forEach(async (scheme) => {
          const station2Scheme =
            await SchemesService.getStation2Scheme({
              station: stopToEdit.id,
              scheme: scheme.id,
            });
          if (station2Scheme) {
            calls.push(
              SchemesService.deleteStation2Scheme(
                station2Scheme?.data?.results[0]?.id!,
              ),
            );
          }
        });
      }
      const conflictsAll: any = [];
      if (schemesToAdd?.length) {
        for (const scheme of schemesToAdd) {
          try {
            await SchemesService.addStation2Scheme(
              {
                station: stopToEdit.id,
                scheme: scheme.id,
              },
              conflictResolve,
            );
          } catch (e: any) {
            if (
              e.response?.data?.error_code ===
              APIExceptionErrorCodeEnum.RESOURCE_CONFLICT
            ) {
              e.response?.data?.error_context.conflicts.forEach(
                (conflict: any) => {
                  conflictsAll.push({
                    label: conflict.scheme_name,
                    value: {
                      id: conflict.scheme_id,
                      name: conflict.scheme_name,
                      station_id: conflict.station_id,
                      station_name: conflict.station_name,
                    },
                  });
                },
              );
            }
          } finally {
            if (conflictsAll.length) {
              setTimeout(() => {
                setSelectedSchemesToAdd(conflictsAll);
                setShowSchemeConflictDialog(true);
                setShowProgress(false);
              }, 250);
            }
          }
        }
      }

      await Promise.all(calls);
      if (!conflictsAll.length) {
        onCloseEditDestinationSchemesDialog();
        browserHistory.replace({
          pathname: `${AuthRoutes.STOP}/default-open/${Array.from(
            stopsData.keys(),
          ).join(
            '-',
          )}?${stationDashboardUrlId}=${EphemeralStateService.getMyStationId()}`,
        });
      }
    } catch (e) {
      handleError(e as AxiosError);
      onCloseEditDestinationSchemesDialog();
    }
  };

  const renderEditDestinationSchemes = () => {
    return (
      <Dialog open={!!stopToEdit}>
        <aside>
          <DialogTitle
            className={classes.editDestinationSchemesDialogTitle}
          >
            <EditIcon
              className={classes.editDestinationSchemesDialogIcon}
            />
            <Typography component='p' variant='h5'>
              Edit Destination Schemes
            </Typography>
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <Box pb={3}>
              <AsyncSelect<TypeAheadItem, true>
                isClearable
                cacheOptions
                isMulti
                styles={{
                  control: (styles) => {
                    return {
                      ...styles,
                      ...(theme.typography.body1 as CSSProperties),
                      backgroundColor: theme.palette.grey.A200,
                      padding: '7px 12px',
                      border: 'none',
                      borderRadius: 4,
                      boxShadow: 'none !important',
                      minWidth: isXsScreen ? 290 : 410,
                    };
                  },
                  menu: (styles) => {
                    return {
                      ...styles,
                      top: 0,
                      transform: 'translateY(-8px)',
                    };
                  },
                  menuPortal: (styles) => {
                    return {
                      ...styles,
                      zIndex: 1301,
                    };
                  },
                  indicatorSeparator: (styles) => {
                    return {
                      ...styles,
                      display: 'none',
                    };
                  },
                  option: (styles) => {
                    return {
                      ...styles,
                      backgroundColor: theme.palette.common.white,
                      padding: '14px 0 14px 20px',
                      color: theme.palette.common.black,
                      '&:hover': {
                        backgroundColor: theme.palette.grey.A200,
                        cursor: 'pointer',
                      },
                    };
                  },
                  placeholder: (styles) => {
                    return {
                      ...styles,
                      color: theme.palette.grey.A400,
                      ...(theme.typography.body1 as CSSProperties),
                    };
                  },
                  dropdownIndicator: (styles) => {
                    return {
                      ...styles,
                      color: theme.palette.grey.A400,
                      padding: 0,
                    };
                  },
                  multiValue: (styles) => {
                    return {
                      ...styles,
                      backgroundColor: theme.palette.secondary.main,
                      ...theme.typography.caption,
                    } as CSSObjectWithLabel;
                  },
                  multiValueLabel: (styles) => ({
                    ...styles,
                    color: theme.palette.common.white,
                  }),
                  multiValueRemove: (styles, { data }) => ({
                    ...styles,
                    color: theme.palette.common.white,
                    ':hover': {
                      backgroundColor: theme.palette.common.black,
                    },
                  }),
                }}
                menuPortalTarget={document.body}
                loadOptions={loadSchemeSearchOptions}
                onChange={(newValues: readonly TypeAheadItem[]) => {
                  setSelectedSchemesToAdd([...newValues]);
                }}
                noOptionsMessage={noOptionsMessage}
                placeholder={'Search Schemes'}
                components={{
                  DropdownIndicator: (props) => {
                    return (
                      <components.DropdownIndicator {...props}>
                        <SearchIcon fontSize={'small'} />
                      </components.DropdownIndicator>
                    );
                  },
                  MenuList: (menuListprops) => {
                    return (
                      <components.MenuList {...menuListprops}>
                        <div>{menuListprops.children}</div>
                      </components.MenuList>
                    );
                  },
                  Option: (optionProps: PropsWithChildren<any>) => {
                    return (
                      <components.Option {...optionProps}>
                        <Grid container>
                          <Grid item xs={5}>
                            <Typography component='p' variant='body2'>
                              {optionProps.children}
                            </Typography>
                          </Grid>
                          <Grid item xs={7}>
                            <Typography
                              component='p'
                              variant='body2'
                              color={{
                                color: 'grey',
                                variant: 'A400',
                              }}
                            >
                              {optionProps.value.used_on
                                ? 'Used on ' +
                                  optionProps.value.used_on
                                : 'Unused'}
                            </Typography>
                          </Grid>
                        </Grid>
                      </components.Option>
                    );
                  },
                  MultiValueRemove: (props) => {
                    return (
                      <components.MultiValueRemove {...props}>
                        <CancelIcon
                          className={
                            classes.editDestinationSchemesDialogRemoveSchemeBadgeIcon
                          }
                        />
                      </components.MultiValueRemove>
                    );
                  },
                }}
              />
            </Box>
            <Box pl={1} pr={1}>
              <Grid container>
                <Grid item xs={12}>
                  {!!stopToEdit?.data?.schemesData?.length && (
                    <Box pb={2}>
                      <Typography component='h5' variant='h6'>
                        Schemes
                      </Typography>
                    </Box>
                  )}
                </Grid>
                {stopToEdit &&
                  stopToEdit?.data?.schemesData?.map(
                    (scheme: Scheme) => {
                      return (
                        <Grid
                          container
                          key={scheme.id}
                          className={
                            classes.editDestinationSchemesDialogSchemeItem
                          }
                        >
                          <Grid item xs={8} sm={10}>
                            <Typography component='p' variant='body2'>
                              {scheme.name}
                            </Typography>
                          </Grid>
                          <Grid item xs={4} sm={2}>
                            <div
                              className={
                                classes.editDestinationSchemesDialogRemoveSchemeContainer
                              }
                              onClick={() => {
                                selectedSchemesToDelete.push(scheme);
                                setSelectedSchemesToDelete([
                                  ...selectedSchemesToDelete,
                                ]);
                                const filteredSchemesData =
                                  stopToEdit.data.schemesData.filter(
                                    (sc: Scheme) =>
                                      sc.id !== scheme.id,
                                  );
                                stopToEdit.data.schemesData =
                                  filteredSchemesData;
                                setStopToEdit({ ...stopToEdit });
                              }}
                            >
                              <CancelIcon
                                className={
                                  classes.editDestinationSchemesDialogRemoveSchemeIcon
                                }
                              />
                              <Typography
                                variant='body2'
                                component='a'
                              >
                                Remove
                              </Typography>
                            </div>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider />
                          </Grid>
                        </Grid>
                      );
                    },
                  )}
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <CSButton
              variant='outlined'
              color='secondary'
              onClick={() => {
                onCloseEditDestinationSchemesDialog();
              }}
            >
              Cancel
            </CSButton>
            <CSButton
              variant='contained'
              color='secondary'
              onClick={() => {
                editSchemes();
              }}
            >
              Save
            </CSButton>
          </DialogActions>
        </aside>
      </Dialog>
    );
  };

  const renderTableValues = (
    keyPrefix: string,
    data: any[] | undefined,
    stopId: number,
    width: string | number,
  ) => {
    const stopData = stopsData.get(stopId);
    return (
      <TableCell
        align={DEFAULT_ALIGN}
        style={{
          width: width,
        }}
        key={`${keyPrefix}-${stopId}`}
      >
        <div className={classes.tableValuesContainer}>
          {data?.length ? (
            data.map((dataEl) => {
              if (keyPrefix === 'lp') {
                return (
                  <Typography
                    component='p'
                    variant='body2'
                    key={`${keyPrefix}-${dataEl.id}-${stopId}`}
                  >
                    {[
                      dataEl.area_name,
                      dataEl.zone_name,
                      dataEl.name,
                    ].join(' ')}
                  </Typography>
                );
              }

              if (keyPrefix === 'scheme') {
                return (
                  <Link
                    to={`${AuthRoutes.SCHEME}/${dataEl.id}`}
                    key={`${keyPrefix}-${dataEl.id}`}
                    component={({ href }) => (
                      <Typography
                        color='tertiary'
                        variant='subtitle1'
                        component='a'
                        underlined
                        href={href}
                        className={classes.tableValuesSchemeName}
                      >
                        {dataEl.name}
                      </Typography>
                    )}
                  />
                );
              }

              return (
                <Typography
                  component='p'
                  variant='body2'
                  key={`${keyPrefix}-${dataEl.id}`}
                >
                  {dataEl.route_name || dataEl.name}
                </Typography>
              );
            })
          ) : (
            <Typography
              component='p'
              variant='body2'
              key={`${keyPrefix}-${stopId}`}
            >
              {common.emptyValue}
            </Typography>
          )}
          {keyPrefix === 'scheme' &&
            PermissionsService.hasPermission(
              StaffPermissionPermissionsEnum.LOADPLAN_WRITE,
            ) && (
              <Typography
                color='tertiary'
                variant='subtitle1'
                component='a'
                underlined
                className={classes.tableValuesEditSchemesLink}
                onClick={() => {
                  setStopToEdit({
                    data: { ...stopData },
                    id: stopId,
                  });
                }}
              >
                Edit Schemes
              </Typography>
            )}
        </div>
      </TableCell>
    );
  };

  const expandedContentRenderer = (data: any) => {
    const stopData = stopsData.get(data.id);
    const tableHeaders: Column[] = [
      {
        id: 'icon-expand-colapse',
        label: 'icon-expand-colapse',
        width: 24,
      },
      ...COLUMNS_STOPS,
      {
        id: 'icon-edit',
        label: 'icon-edit',
      },
      {
        id: 'icon-delete',
        label: 'icon-delete',
        width: 24,
      },
    ];

    const getWidth = (column: Column) => {
      if (column.width) {
        return column.width;
      }
      return isSmScreen ? 120 : 150;
    };

    const idsToRender: Array<string | number> = [
      'load_points_count',
      'schemes_count',
      'routes_count',
    ];

    return (
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {tableHeaders.map((header, index) => {
                return (
                  <TableCell
                    key={`ep-table-head-${index}}`}
                    align={DEFAULT_ALIGN}
                    style={{
                      ...{
                        width: getWidth(header),
                      },
                    }}
                  >
                    {idsToRender.includes(header.id)
                      ? header.label
                      : ''}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {tableHeaders.map((column, index) => {
                if (column.id === 'name') {
                  return (
                    <TableCell
                      key={`ep-table-column-data-${index}}`}
                      style={{
                        ...{
                          width: getWidth(column),
                        },
                      }}
                    >
                      <Typography component='p' variant='body2'>
                        {`${data.address ? `${data.address},` : ''} ${
                          data.state || ''
                        } ${data.country ? `(${data.country})` : ''}`}
                      </Typography>
                      <Typography component='p' variant='body2'>
                        {data.zipcode}
                      </Typography>
                      <Typography component='p' variant='body2'>
                        {data.city}
                      </Typography>
                      <br />
                      <Typography component='p' variant='body2'>
                        {data.external_id}
                      </Typography>
                    </TableCell>
                  );
                }

                if (column.id === 'load_points_count') {
                  return renderTableValues(
                    'lp',
                    stopData?.lpData,
                    data.id,
                    getWidth(column),
                  );
                }

                if (column.id === 'routes_count') {
                  return renderTableValues(
                    'routes',
                    stopData?.routesData,
                    data.id,
                    getWidth(column),
                  );
                }

                if (column.id === 'schemes_count') {
                  return renderTableValues(
                    'scheme',
                    stopData?.schemesData,
                    data.id,
                    getWidth(column),
                  );
                }

                return (
                  <TableCell
                    key={`ep-table-column-data-${index}}`}
                    align={DEFAULT_ALIGN}
                    style={{
                      ...{
                        width: getWidth(column),
                      },
                    }}
                  />
                );
              })}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort - ${stopsLabels.plural} ${
            queryString.parse(location.search)['page']
              ? '- Page ' + queryString.parse(location.search)['page']
              : ''
          }`}
        </title>
      </Helmet>
      <Layout navCurrent='STOP'>
        {showProgress && <ProgressIndicator />}
        {error && (
          <AlertBanner
            className={classes.banner}
            severity='error'
            alertTitle={'Error'}
            alertMsg={error}
          />
        )}
        <ConfirmationDialog
          title={'Please confirm the changes'}
          msg={
            <>
              {(selectedSchemesToAdd as unknown as any[])?.map(
                (scheme, index) => {
                  const stopName = locations.find(
                    (loc) => loc?.id === stopToEdit?.id,
                  )?.name;
                  const { label, value } = scheme;
                  return (
                    <Typography
                      key={index}
                      component='span'
                      variant='body1'
                      color={{
                        color: 'grey',
                        variant: 'A400',
                      }}
                      style={{ display: 'block' }}
                    >
                      {`You are about to move ${label} from ${value.station_name} to ${stopName}.`}
                    </Typography>
                  );
                },
              )}
            </>
          }
          primaryActionLabel={'Confirm'}
          onPrimaryAction={() => {
            editSchemes(true);
          }}
          onCancel={() => {
            setError(undefined);
            setShowSchemeConflictDialog(false);
          }}
          isOpen={showSchemeConflictDialog}
        />
        <ConfirmationDialog
          dataTestIdPrefix={'delete-confirmation-dialog'}
          title={
            'Delete ' + configurationUtils.getPageTitle(true, 'STOP')
          }
          msg={`Are you sure you want to delete ${configurationUtils.getPageTitle(
            true,
            'STOP',
          )} ${deleteName}?`}
          primaryActionLabel={'Yes'}
          onPrimaryAction={() => {
            processDelete();
          }}
          cancelLabel={'No'}
          onCancel={() => {
            setShowDeleteConfirmation(false);
          }}
          isOpen={showDeleteConfirmation}
        />
        <FiltersDrawer
          isOpen={showFiltersDrawer}
          onAfterClose={() => {
            setShowFiltersDrawer(false);
          }}
          getFilters={getDrawerFilters}
          removeFilter={filterToRemove}
        />
        <AddStopsDialog
          isOpen={showAddDialog}
          onAfterClose={() => {
            setShowAddDialog(false);
            setEditingStopData(undefined);
            setError(undefined);
          }}
          updateParent={() => {
            setShowAddDialog(false);
            setLastUpdated(new Date().toISOString());
          }}
          type={addDialogActionType}
          data={editingStopData}
        />
        {renderEditDestinationSchemes()}
        <Grid container className={classes.header}>
          <Grid item xs={12} sm={6}>
            <Typography variant='h3' component='h1'>
              {configurationUtils.getPageTitle()}
            </Typography>
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
            className={classes.nonMobileAlignRight}
          >
            <CSButton
              disabled={!!error}
              style={{ marginBottom: '10px' }}
              variant='outlined'
              color='secondary'
              data-testid={'stops-loadplans-button'}
              onClick={() => {
                browserHistory.push(`${AuthRoutes.LOADPLAN}_list`);
              }}
              startIcon={<AppsIcon />}
            >
              {configurationUtils.getPageTitle(false, 'LOADPLAN')}
            </CSButton>
            {PermissionsService.hasPermission(
              StaffPermissionPermissionsEnum.STATION_WRITE,
            ) && (
              <CSButton
                disabled={!!error}
                variant='contained'
                color={{
                  buttonColor: 'secondary',
                  iconColor: 'primary',
                }}
                data-testid={'stops-add-button'}
                onClick={(e) => {
                  e.preventDefault();
                  setAddDialogActionType('add');
                  setShowAddDialog(true);
                }}
                style={{ marginLeft: '10px', marginBottom: '10px' }}
                startIcon={<AddToPhotosOutlinedIcon />}
              >
                Add {stopsLabels.plural}
              </CSButton>
            )}
            {PermissionsService.hasPermission(
              StaffPermissionPermissionsEnum.STATION_READ,
            ) && (
              <CSButton
                variant='contained'
                color='primary'
                disabled={!batchExportEnabled}
                style={{ marginLeft: '10px', marginBottom: '10px' }}
                onClick={() => {
                  exportStops();
                }}
                startIcon={<CloudDownloadOutlined />}
              >
                Export CSVs
              </CSButton>
            )}
            <CSButton
              variant='outlined'
              color='secondary'
              className={clx(
                classes.sectionButton,
                classes.headerFilterButton,
              )}
              onClick={() => {
                setShowFiltersDrawer(true);
              }}
            >
              <FilterListIcon />
            </CSButton>
          </Grid>
          <CSSectionTitleSeparator bottomMargin={24} />
          <Grid container item xs={12} spacing={1}>
            <CSHorizontalFilterBadgesGroup
              selectedFilters={selectedFilters}
              onRemoveFilter={(toRemove) =>
                setFilterToRemove(toRemove)
              }
            />
          </Grid>
        </Grid>

        <PaginatedTable
          key={lastUpdated + filterLastUpdate}
          title=''
          columns={COLUMNS_STOPS}
          dataTestIdPrefix={'stops'}
          fetch={fetchLocations}
          rowsLoadDetailPages={false}
          isExpandedPanel
          expandedContentRenderer={expandedContentRenderer}
          loadExpandedContentData={loadExpandedContentData}
          detailsPageBasePath={AuthRoutes.STOP}
          defaultSort={
            (queryString.parse(location.search)[
              'sortBy'
            ] as string) || undefined
          }
          sortableBy={['routes_count']}
          filterByString={true}
          filterByStringPlaceholder={`Search ${stopsLabels.plural}`}
          actions={[
            {
              cellWidth: 70,
              tableLabel: PermissionsService.hasPermission(
                StaffPermissionPermissionsEnum.STATION_WRITE,
              )
                ? ' '
                : undefined,
              columnLabel: PermissionsService.hasPermission(
                StaffPermissionPermissionsEnum.STATION_WRITE,
              ) ? (
                <EditIcon />
              ) : undefined,
              callbackProperty: 'id',
              qualifier: 'id',
              callback: (id: number) => {
                const stop = locations.find((loc) => loc?.id === id);
                setError(undefined);
                setEditingStopData(stop);
                setAddDialogActionType('edit');
                setShowAddDialog(true);
              },
            },
            {
              cellWidth: 70,
              tableLabel: PermissionsService.hasPermission(
                StaffPermissionPermissionsEnum.STATION_WRITE,
              )
                ? ' '
                : undefined,
              columnLabel: PermissionsService.hasPermission(
                StaffPermissionPermissionsEnum.STATION_WRITE,
              ) ? (
                <CancelIcon />
              ) : undefined,
              callbackProperty: 'id',
              qualifier: 'id',
              callback: (id: number) => {
                const stopName = locations.find(
                  (loc) => loc?.id === id,
                )?.name as string;
                setDeleteName(stopName);
                setDeleteId(id);
                setError(undefined);
                setShowDeleteConfirmation(true);
              },
            },
          ]}
        />
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...selectStyles,
    ...sectionPageBaseStyle,
    ...detailsPageStyles,
    ...filterBadgeStyle,
    editDestinationSchemesDialogTitle: {
      textAlign: 'center',
      marginTop: '20px',
    },
    editDestinationSchemesDialogIcon: {
      color: theme.palette.primary.main,
      width: '24px',
      height: 'auto',
    },
    editDestinationSchemesDialogRemoveSchemeBadgeIcon: {
      fontSize: '14px',
    },
    editDestinationSchemesDialogSchemeItem: {
      height: '40px',
    },
    editDestinationSchemesDialogRemoveSchemeContainer: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    editDestinationSchemesDialogRemoveSchemeIcon: {
      fontSize: '14px',
      marginRight: '5px',
    },
    tableValuesContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    tableValuesSchemeName: {
      marginBottom: 5,
    },
    tableValuesEditSchemesLink: {
      cursor: 'pointer',
    },
    header: {
      marginBottom: 24,
    },
    nonMobileAlignRight: {
      [theme.breakpoints.up('xs')]: {
        textAlign: 'right',
      },
    },
    dialogContent: {
      maxWidth: 487,
      [theme.breakpoints.up('sm')]: {
        paddingRight: '31px',
        paddingLeft: '31px',
      },
    },
    dialogActions: {
      paddingBottom: '31px',
      paddingRight: '31px',
      paddingLeft: '31px',
    },
  })),
)(Stops);
