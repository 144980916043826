import { makeStyles } from '@material-ui/core';

export default makeStyles(
  () => ({
    paperHidden: {
      display: 'none',
    },
    backdropHidden: {
      backgroundColor: 'transparent',
    },
    input: {
      cursor: 'pointer',
      '& > div, & > input, & > label, & > div > input': {
        cursor: 'pointer',
      },
    },
  }),
  { name: 'CSDatepicker' },
);
