import React, { useState } from 'react';
import { CSButton, Typography } from '../../primitives';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid, Theme } from '@material-ui/core';
import colors from '../../../utils/colors';
import detailsPageStyles from '../../commonStyles/detailsPage.style';
import { AuthRoutes } from '../../../interfaces/routes';
import { Column } from '../../../interfaces/components';
import PaginatedTable, {
  filterObj,
} from '../../paginatedTable/PaginatedTable';
import queryString from 'query-string';
import Layout from '../../layout/Layout';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import AddToPhotosOutlinedIcon from '@material-ui/icons/AddToPhotosOutlined';
import sectionPageBaseStyle from '../../commonStyles/sectionPageBase.style';
import Navbar, { TabsEnum } from '../Navbar';
import { ROLE_LABELS } from '../../stationStaff/StationStaff';
import { renderDuration } from '../../DetailsPagesFunctions';
import { common } from '../../../utils/strings';
import StaffService from '../../../services/Staff.service';
import FilterListIcon from '@material-ui/icons/FilterList';
import clx from 'classnames';
import filterBadgeStyle from '../../filtersDrawer/filterBadge.style';
import FiltersDrawer, {
  SelectedFilters,
} from '../../filtersDrawer/FiltersDrawer';
import AddStaffDialog from './addStaff/AddStaffDialog';
import { paginatedTablelocationProp } from '../../paginatedTable/paginatedTable.types';
import { configurationUrlParams } from '../utils';
import {
  AssignedStation,
  Staff as StaffType,
} from 'cloudsort-client';
import CSSectionTitleSeparator from '../../primitives/csSectionTitleSeparator/CSSectionTitleSeparator';
import CSHorizontalFilterBadgesGroup from '../../primitives/csHorizontalFilterBadgesGroup/csHorizontalFilterBadgesGroup';
import EphemeralStateService from '../../../services/EphemeralState.service';

interface Props {
  classes: { [key: string]: string };
  location: paginatedTablelocationProp;
}

type StaffResult = StaffType & {
  assigned_stations: string;
  status: string;
  station: string;
  assigned_organizations: string;
  org: string;
  role: ROLE_LABELS | string;
};

const Staff: React.FC<Props> = ({ classes, location }) => {
  const urlParams = useParams<configurationUrlParams>();
  const [showAddStaffModal, setShowAddStaffModal] = useState(false);
  const [lastUpdated, setLastUpdated] = useState<string>(
    new Date().toISOString(),
  );
  const [showFiltersDrawer, setShowFiltersDrawer] = useState(false);
  const [selectedFilters, setSelectedFilters] =
    useState<SelectedFilters>({});
  const [filterToRemove, setFilterToRemove] = useState<{
    filterKey: string;
    filterValue: string | number;
  }>();
  const [filterLastUpdate, setFilterLastUpdate] = useState<number>(
    Date.now(),
  );

  const COLUMNS_STAFF: Column[] = [
    {
      id: 'full_name',
      label: 'Name',
      width: 'auto',
      align: 'left',
    },
    {
      id: 'station',
      label: 'Station',
      width: 'auto',
      align: 'left',
      hide: !EphemeralStateService.getIsRoleSuperUser(),
    },

    {
      id: 'id',
      label: 'ID',
      width: 100,
      align: 'center',
    },
    {
      id: 'status',
      label: 'Status',
      width: 'auto',
      align: 'left',
    },
    {
      id: 'role',
      label: 'Role',
      width: 'auto',
      align: 'left',
    },
  ];

  const getDrawerFilters = (filters: SelectedFilters) => {
    setSelectedFilters(filters);
    setFilterLastUpdate(Date.now());
  };

  const fetchStaffData = async (
    pageIndex: number,
    rowsPerPage: number,
    filterOptions?: filterObj[],
    filterByString?: string,
    sortedBy?: string,
  ) => {
    const sortedByValue = sortedBy?.replace('status', 'active');
    let fRole = undefined;
    if (
      selectedFilters!.roleType?.values?.length > 0 &&
      selectedFilters!.roleType.values[0].id !== 'all'
    ) {
      fRole = selectedFilters?.roleType.values[0].id;
    }
    const res = await StaffService.getAll({
      page: pageIndex,
      pageSize: rowsPerPage,
      sortBy: sortedByValue as
        | 'active'
        | 'seconds_per_package'
        | '-active'
        | '-seconds_per_package'
        | undefined,
      organizationId: Number(urlParams.orgId),
      stationId: Number(urlParams.stationId),
      roleType: fRole as
        | undefined
        | 'OPERATOR_I'
        | 'OPERATOR_II'
        | 'OPERATOR_III'
        | 'STATION_ADMIN'
        | 'ORG_ADMIN'
        | 'SUPER_USER',
    });

    (res.data.results as StaffResult[]).forEach(
      (staff: StaffResult) => {
        staff.device_id = staff.device_id || common.emptyValue;
        staff.active_since =
          staff?.active_since && staff?.active
            ? renderDuration(
                (new Date().getTime() -
                  new Date(staff?.active_since!).getTime()) /
                  1000,
              )
            : common.emptyValue;

        const stationArray = staff.assigned_stations.map(
          (station: AssignedStation) => station.name,
        );
        staff.status = staff.active ? 'Active' : 'Inactive';
        staff.station = stationArray.length
          ? stationArray.join(', ')
          : common.emptyValue;

        staff.role = staff.permission_data
          ? ROLE_LABELS[
              staff.permission_data
                .role_type as keyof typeof ROLE_LABELS
            ] ?? common.emptyValue
          : common.emptyValue;
      },
    );

    return res;
  };

  return (
    <Layout navCurrent='CONFIGURATION'>
      <Helmet>
        <title>
          {`CloudSort - Staff ${
            queryString.parse(location.search)['page']
              ? '- Page ' + queryString.parse(location.search)['page']
              : ''
          }`}
        </title>
      </Helmet>
      <AddStaffDialog
        isOpen={showAddStaffModal}
        onCancel={() => {
          setShowAddStaffModal(false);
        }}
        onAddUser={() => {
          setLastUpdated(new Date().toISOString());
          setShowAddStaffModal(false);
        }}
      />
      <FiltersDrawer
        isOpen={showFiltersDrawer}
        onAfterClose={() => {
          setShowFiltersDrawer(false);
        }}
        getFilters={getDrawerFilters}
        removeFilter={filterToRemove}
      />
      <Navbar
        hasUnsavedChanges={false}
        activeTab={TabsEnum.STAFF}
        onSaveCallback={() => {}}
        onResetDataCallback={() => {}}
      />
      <Grid container className={classes.header}>
        <Grid item xs={12} sm={6}>
          <Typography className={classes.title} variant={'h3'}>
            CloudSort Staff
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
          <CSButton
            variant='contained'
            color={{ buttonColor: 'secondary', iconColor: 'primary' }}
            onClick={() => {
              setShowAddStaffModal(true);
            }}
            startIcon={<AddToPhotosOutlinedIcon />}
          >
            Add Staff
          </CSButton>
          <CSButton
            variant='outlined'
            color='secondary'
            className={clx(
              classes.headerButtons,
              classes.headerFilterButton,
            )}
            onClick={() => {
              setShowFiltersDrawer(true);
            }}
          >
            <FilterListIcon />
          </CSButton>
        </Grid>
        <Grid item xs={12}>
          <CSSectionTitleSeparator bottomMargin={24} topMargin={16} />
          <CSHorizontalFilterBadgesGroup
            selectedFilters={selectedFilters}
            onRemoveFilter={(toRemove) => setFilterToRemove(toRemove)}
            className={classes.marginBottom16}
          />
        </Grid>
        <Grid item xs={12}>
          <PaginatedTable
            key={lastUpdated + filterLastUpdate + 'staff'}
            title={''}
            columns={COLUMNS_STAFF}
            dataTestIdPrefix={'staff'}
            fetch={fetchStaffData}
            sortableBy={['seconds_per_package', 'status']}
            rowsLoadDetailPages={true}
            detailsPageBasePath={`${AuthRoutes.CONFIGURATION}${
              urlParams.orgId
                ? '/organization/' + urlParams.orgId
                : ''
            }${
              urlParams.stationId
                ? '/station/' + urlParams.stationId
                : ''
            }/staff`}
          />
        </Grid>
      </Grid>
    </Layout>
  );
};
export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...sectionPageBaseStyle,
    ...filterBadgeStyle,
    headerFilterButton: {
      minWidth: '40px',
      marginLeft: '20px',
    },
    buttonFilled: {
      background: colors.darkGold,
      color: colors.white,
      border: `2px solid ${colors.darkGold}`,
      marginBottom: 10,
      height: 42,
      '& svg': {
        marginRight: 10,
      },
      '& .MuiButton-label': {
        textTransform: 'initial',
      },
    },
    marginBottom16: {
      marginBottom: 16,
    },
  })),
)(Staff);
