export default {
  title: {
    opacity: 1,
    margin: '6px 0',
  },
  header: {
    margin: '40px 0 20px 0',
    paddingBottom: 15,
  },
  sectionButton: {
    height: 42,
    marginBottom: 10,
    marginLeft: 10,
  },
  headerFilterButton: {
    minWidth: '40px',
    marginLeft: '20px',
  },
  headerButtonContainer: {
    textAlign: 'right',
  },
};
