import {
  KeyboardDatePickerProps,
  KeyboardDateTimePickerProps,
  KeyboardTimePickerProps,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { switcher } from 'rambdax';
import DateFnsUtils from '@date-io/date-fns';
import * as React from 'react';
import {
  CSKeyboardDatePicker,
  CSKeyboardDateTimePickerProps,
  CSKeyboardTimepickerProps,
  COMPONENT_NAME,
} from './CSDatepickerTypes';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import {
  Backdrop,
  Paper,
  PaperProps,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import clsx from 'clsx';
import getStyles from './CSDatepicker.styles';
import { getDatepickerSpawner } from './CSDatepicker.utils';
import { useWrapperDatepicker } from './useWrapperDatepicker';
import useControlled from '../../../utils/useControlled';
import {
  generateDatePicker,
  generateDatetimePicker,
  generateTimepicker,
} from './pickergeneratorUtils';

const CSDatepicker = (
  props:
    | CSKeyboardTimepickerProps
    | CSKeyboardDatePicker
    | CSKeyboardDateTimePickerProps,
) => {
  const {
    initialValue,
    isOpen,
    selectionType,
    children,
    onClose,
    onOpen,
    onAcceptSelection,
    responsiveModeOn = 'sm',
  } = props;
  const classes = getStyles();
  const [open, setOpen] = useControlled<boolean>({
    controlled: isOpen,
    default: false,
    name: COMPONENT_NAME,
  });
  const [date, setDate] = React.useState(initialValue);
  const theme = useTheme();
  const isXsScreen = useMediaQuery(
    theme.breakpoints.down(responsiveModeOn),
  );
  const alignment = isXsScreen ? 'center' : 'right';
  const { anchorRef, popperRef } = useWrapperDatepicker(
    !!isOpen,
    initialValue,
    !!children,
    alignment,
  );

  const handleDateChange = (
    date: MaterialUiPickersDate,
    value?: string | null | undefined,
  ) => {
    setDate(date);
  };

  const handleCancel = () => {
    setDate(initialValue);
    setOpen(false);
    onClose?.();
  };

  const handleAccept = (date: ParsableDate) => {
    setDate(date);
    onAcceptSelection?.(date);
  };

  const handleOpen = () => {
    setOpen(true);
    onOpen?.();
  };

  const handleClose = () => {
    setOpen(false);
    onClose?.();
  };

  const handlers = {
    onChange: handleDateChange,
    onClose: handleClose,
    onOpen: handleOpen,
    onAccept: handleAccept,
    onCancel: handleCancel,
  };
  const DatePickerSpawner = getDatepickerSpawner(
    children,
    handlers,
    classes,
  );
  const overridenProps = {
    showTodayButton: true,
    variant: children ? 'dialog' : 'inline',
    open,
    value: date,
    TextFieldComponent: DatePickerSpawner,
    inputProps: { disableUnderline: true },
  };
  const containerProps = !children
    ? {
        PopoverProps: {
          anchorEl: anchorRef.current,
        },
      }
    : {
        DialogProps: {
          PaperComponent: (
            props: React.PropsWithChildren<PaperProps>,
          ) => (
            <Paper
              {...props}
              ref={popperRef}
              className={clsx(props.className, classes.paperHidden)}
            />
          ),
          BackdropComponent: (
            props: React.PropsWithChildren<PaperProps>,
          ) => (
            <Backdrop
              {...props}
              open={open || false}
              className={clsx(
                props.className,
                !(isXsScreen && children)
                  ? classes.backdropHidden
                  : '',
              )}
            />
          ),
        },
      };

  const result = switcher<JSX.Element>(selectionType)
    .is(
      'date',
      generateDatePicker(
        date,
        props as CSKeyboardDatePicker,
        overridenProps as Partial<KeyboardDatePickerProps>,
        containerProps as Partial<KeyboardDatePickerProps>,
        handlers,
      ),
    )
    .is(
      'time',
      generateTimepicker(
        date,
        props as CSKeyboardDatePicker,
        overridenProps as Partial<KeyboardTimePickerProps>,
        containerProps as Partial<KeyboardTimePickerProps>,
        handlers,
      ),
    )
    .is(
      'datetime',
      generateDatetimePicker(
        date,
        props as CSKeyboardDatePicker,
        overridenProps as Partial<KeyboardDateTimePickerProps>,
        containerProps as Partial<KeyboardDateTimePickerProps>,
        handlers,
      ),
    )
    .default(
      generateDatePicker(
        date,
        props as CSKeyboardDatePicker,
        overridenProps as Partial<KeyboardDatePickerProps>,
        containerProps as Partial<KeyboardDatePickerProps>,
        handlers,
      ),
    );
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div ref={anchorRef}>{result}</div>
    </MuiPickersUtilsProvider>
  );
};

export default CSDatepicker;
