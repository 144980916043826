import React, {
  useState,
  Fragment,
  BaseSyntheticEvent,
  useMemo,
} from 'react';
import { withStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  CSButton,
  Checkbox,
  Typography,
  CSTextField,
} from '../primitives';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ConfirmationDialog, {
  Transition,
} from '../confirmationDialog/ConfirmationDialog';
import { createStyles, Theme } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { AlertBanner } from '../primitives';
import detailsPageStyles from '../commonStyles/detailsPage.style';
import validateEmail from '../../utils/validateEmail';
import {
  Card,
  CardContent,
  Grid,
  Snackbar,
  Tooltip,
} from '@material-ui/core';
import debounce from 'lodash/debounce';
import {
  OTHER_PERMISSIONS_LABELS,
  OT_PERMISSIONS_LABELS,
} from './StationStaff';

// Types
import {
  StaffPermissionPermissionsEnum,
  StaffPermissionRoleTypeEnum,
  Staff,
} from 'cloudsort-client';

//Services
import PermissionsService from '../../services/Permissions.service';
import EphemeralStateService from '../../services/EphemeralState.service';
import configurationUtils from '../../utils/configurationUtils';
import StaffService from '../../services/Staff.service';
import usePermissionLabels from '../../hooks/usePermissionLabels';
import usePermissionDependencies from '../../hooks/usePermissionDependencies';
import { transformDependencyData } from '../../utils/permissionDependenciesUtils';
import CSDialogTitleWithIcon from '../primitives/csDialogTitleWithIcon/CSDialogTitleWithIcon';
import { DialogIcons } from '../primitives/csDialogTitleWithIcon/CSDialogTitleWithIconTypes';
import StaffOperatorIcon from './staffOperatorIcon/StaffOperatorIcon';

interface Props {
  classes: { [key: string]: string };
  isOpen: boolean;
  onAfterDialogClose: () => void;
  onAddStaff: (
    staffParams: Staff,
    onAfterDialogClose: () => void,
    updateUser: boolean,
  ) => void;
  error?: string;
}

enum DialogType {
  MAIN = 'MAIN',
  USER_CREATED = 'USER_CREATED',
  USER_UPDATED = 'USER_UPDATED',
}

const initAddParams: Staff = {
  first_name: '',
  last_name: '',
  email: '',
  permission_data: {
    role_type: StaffPermissionRoleTypeEnum.OPERATOR_I,
    permissions: [],
  },
};

const AddStaffDialog: React.FC<Props> = ({
  classes,
  isOpen,
  onAfterDialogClose,
  onAddStaff,
  error,
}) => {
  const PLURAL_TITLE = useMemo(
    () => configurationUtils.getPageTitle(false, 'STAFF'),
    [],
  );
  const SINGULAR_TITLE = useMemo(
    () => configurationUtils.getPageTitle(true, 'STAFF'),
    [],
  );
  const addStaffLabels = {
    addStaffTitle: `Add ${PLURAL_TITLE}`,
    addStaffSubitle: `The ${SINGULAR_TITLE} will receive a link in the email to complete the registration.`,
    staffCreatedTitle: `New ${SINGULAR_TITLE.toLowerCase()} added sucessfully`,
    staffUpdatedTitle: `The ${SINGULAR_TITLE.toLowerCase()} has been updated sucessfully`,
  };
  const [staffParams, setStaffParams] =
    useState<Staff>(initAddParams);
  const [roleSelected, setRoleSelected] = useState<Boolean>(false);
  const [emailAlreadyRegistered, setEmailAlreadyRegistered] =
    useState<boolean>(true);

  const [dialogType, setDialogType] = useState<DialogType>(
    DialogType.MAIN,
  );

  const [showDependencyNotification, setShowDependencyNotification] =
    useState(false);
  const [
    dependencyNotificationMessage,
    setDependencyNotificationMessage,
  ] = useState('');
  const permissionLabels = usePermissionLabels();
  const {
    calculateDependencyStatus,
    disablePermissionAndDependants,
  } = usePermissionDependencies();

  const isValidEmail = validateEmail(staffParams.email || '');
  const hasNoName =
    !staffParams?.first_name?.trim() ||
    !staffParams?.last_name?.trim();

  const hasNoEmail = !staffParams.email?.trim();

  const onEmailChange = (e?: BaseSyntheticEvent) => {
    debouncedCheck(e?.target.value);
  };

  const debouncedCheck = debounce(function (email: string) {
    if (validateEmail(email)) {
      checkIfEmailIsRegistered(email);
    } else
      setStaffParams({
        ...staffParams,
        id: undefined,
        email: undefined,
      });
  }, 300);

  const onClose = () => {
    onAfterDialogClose();
    setTimeout(() => {
      setRoleSelected(false);
      setStaffParams(initAddParams);
      setDialogType(DialogType.MAIN);
    }, 300);
  };

  const selectRole = (role: StaffPermissionRoleTypeEnum) => {
    const defaultPermissions =
      PermissionsService.getDefaultsPermissionsForRole(role);
    setStaffParams({
      ...staffParams,
      permission_data: {
        role_type: role,
        permissions: defaultPermissions,
        station: EphemeralStateService.getMyStationId(),
      },
    });
    setRoleSelected(true);

    if (role === StaffPermissionRoleTypeEnum.OPERATOR_I)
      setEmailAlreadyRegistered(false);
    else setEmailAlreadyRegistered(true);
  };

  const checkIfEmailIsRegistered = async (email: string) => {
    try {
      const { data } = await StaffService.checkEmail(email);
      setStaffParams({
        ...staffParams,
        id: data.id,
        email: data.email,
      });
      setEmailAlreadyRegistered(true);
    } catch (e) {
      setStaffParams({
        ...staffParams,
        id: undefined,
        email,
      });
      setEmailAlreadyRegistered(false);
    }
  };

  const theme = useTheme();
  const inXsScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const renderRoleSelection = () => {
    return (
      <Grid container item direction={'row'} xs={12} spacing={2}>
        <Grid
          className={classes.container}
          item
          xs={inXsScreen ? 6 : 4}
        >
          <Card
            elevation={5}
            onClick={() => {
              selectRole(StaffPermissionRoleTypeEnum.OPERATOR_I);
            }}
          >
            <CardContent className={classes.cardContent}>
              <Box className={classes.staffIconContainer}>
                <StaffOperatorIcon
                  color='tertiary'
                  alt={'Operator I'}
                />
              </Box>
              <Box p={2}>
                <Typography
                  className={classes.roleName}
                  variant='h6'
                  gutterBottom
                >
                  Operator I
                </Typography>
                <Typography
                  variant='body1'
                  className={classes.roleDescription}
                  color={{ color: 'text', variant: 'icon' }}
                >
                  Sort and Containerize Packages
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          className={classes.container}
          item
          xs={inXsScreen ? 6 : 4}
        >
          <Card elevation={5}>
            <CardContent
              data-testid='station-staff-add-select-role'
              className={classes.cardContent}
              onClick={() => {
                selectRole(StaffPermissionRoleTypeEnum.OPERATOR_II);
              }}
            >
              <Box className={classes.staffIconContainer}>
                <StaffOperatorIcon
                  color='tertiary'
                  alt={'Operator II'}
                />
              </Box>
              <Box fontWeight='fontWeightBold' p={2}>
                <Typography
                  className={classes.roleName}
                  variant='h6'
                  gutterBottom
                >
                  Operator II
                </Typography>
                <Typography
                  variant='body1'
                  color={{ color: 'text', variant: 'icon' }}
                  className={classes.roleDescription}
                >
                  Sort, Containerize Packages, Container Ops & Webapp
                  access
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid
          className={classes.container}
          item
          xs={inXsScreen ? 6 : 4}
        >
          <Card elevation={5}>
            <CardContent
              className={classes.cardContent}
              onClick={() => {
                selectRole(StaffPermissionRoleTypeEnum.OPERATOR_III);
              }}
            >
              <Box>
                <StaffOperatorIcon
                  className={classes.staffIconContainer}
                  color='tertiary'
                  alt={'Operator III'}
                />
              </Box>
              <Box fontWeight='fontWeightBold' p={2}>
                <Typography
                  className={classes.roleName}
                  variant='h6'
                  gutterBottom
                >
                  Operator III
                </Typography>
                <Typography
                  variant='body1'
                  color={{ color: 'text', variant: 'icon' }}
                  className={classes.roleDescription}
                >
                  Full visibility of station performance and access to
                  control ops.
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  };

  const renderPermissions = (
    permissionsToRender:
      | typeof OTHER_PERMISSIONS_LABELS
      | typeof OT_PERMISSIONS_LABELS,
  ) => {
    const dependencyStatus = transformDependencyData(
      calculateDependencyStatus(
        permissionsToRender,
        staffParams!.permission_data!.permissions as string[],
      ),
      permissionLabels,
    );
    return (
      <>
        {Object.keys(StaffPermissionPermissionsEnum)
          .filter((permission) =>
            Object.keys(permissionsToRender).includes(permission),
          )
          .map((key) => {
            return StaffPermissionPermissionsEnum[
              key as keyof typeof StaffPermissionPermissionsEnum
            ];
          })
          .map((permission) => {
            return (
              <Fragment
                key={`fragment-${permissionLabels[permission]}`}
              >
                <Tooltip
                  classes={{
                    tooltip: classes.tooltip,
                  }}
                  title={
                    dependencyStatus[permission].enabled
                      ? ''
                      : dependencyStatus[permission].tooltipMessage
                  }
                  placement='top-start'
                >
                  <span>
                    <FormControlLabel
                      key={permissionLabels[permission]}
                      className={classes.formControl}
                      control={
                        <Checkbox
                          key={`checkbox-${permissionLabels[permission]}`}
                          disabled={
                            !dependencyStatus[permission].enabled
                          }
                          checked={staffParams.permission_data?.permissions!.some(
                            (selectedPermission) =>
                              selectedPermission === permission,
                          )}
                          onChange={(e) => {
                            const isAlreadySelected =
                              staffParams.permission_data?.permissions!.some(
                                (selectedPermission) =>
                                  selectedPermission === permission,
                              );
                            if (isAlreadySelected) {
                              const {
                                newPermissionsState,
                                disabledPermissions,
                              } = disablePermissionAndDependants(
                                permission,
                                staffParams.permission_data
                                  ?.permissions as string[],
                              );

                              //All dependencies are disabled, show notification to the user.
                              if (disabledPermissions.length > 0) {
                                setShowDependencyNotification(true);
                                setDependencyNotificationMessage(
                                  `Your selection also disabled the dependant permission${
                                    disabledPermissions.length === 1
                                      ? ''
                                      : 's'
                                  }: ${disabledPermissions.join(
                                    ', ',
                                  )}.`,
                                );
                              }

                              setStaffParams({
                                ...staffParams,
                                permission_data: {
                                  role_type:
                                    staffParams.permission_data
                                      ?.role_type!,
                                  station:
                                    staffParams.permission_data
                                      ?.station,
                                  permissions: [
                                    ...newPermissionsState,
                                  ],
                                },
                              });
                            } else {
                              setStaffParams({
                                ...staffParams,
                                permission_data: {
                                  role_type:
                                    staffParams.permission_data
                                      ?.role_type!,
                                  station:
                                    staffParams.permission_data
                                      ?.station,
                                  permissions: [
                                    ...(staffParams.permission_data
                                      ?.permissions || []),
                                    permission,
                                  ],
                                },
                              });
                            }
                          }}
                        />
                      }
                      label={permissionLabels[permission]}
                    />
                  </span>
                </Tooltip>
              </Fragment>
            );
          })}
      </>
    );
  };

  const renderForm = () => {
    return (
      <Grid container item xs={12}>
        <Snackbar
          data-testid='snackbar-notification'
          key={dependencyNotificationMessage}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={showDependencyNotification}
          onClose={(event, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            setShowDependencyNotification(false);
          }}
          autoHideDuration={4000}
          message={dependencyNotificationMessage}
        />
        <Grid item xs={12}>
          {staffParams.permission_data?.role_type! !==
            StaffPermissionRoleTypeEnum.OPERATOR_I && (
            <Box pb={2}>
              <CSTextField
                label='Email Address'
                placeholder='Email Address'
                containerSize='fullHorizontal'
                onChange={onEmailChange}
                onClear={onEmailChange}
                helperText={
                  !!staffParams.email && !isValidEmail
                    ? 'Please enter a valid email.'
                    : undefined
                }
                error={!!staffParams.email && !isValidEmail}
              />
            </Box>
          )}
          {(staffParams.permission_data?.role_type! ===
            StaffPermissionRoleTypeEnum.OPERATOR_I ||
            !emailAlreadyRegistered) && (
            <>
              <Box pb={2}>
                <CSTextField
                  autoFocus
                  label='First Name'
                  containerSize='fullHorizontal'
                  placeholder='First Name'
                  onChange={(e) => {
                    setStaffParams({
                      ...staffParams,
                      first_name: e.target.value,
                    });
                  }}
                  onClear={() => {
                    setStaffParams({
                      ...staffParams,
                      first_name: undefined,
                    });
                  }}
                />
              </Box>
              <Box pb={2}>
                <CSTextField
                  label='Last Name'
                  placeholder='Last Name'
                  containerSize='fullHorizontal'
                  onChange={(e) => {
                    setStaffParams({
                      ...staffParams,
                      last_name: e.target.value,
                    });
                  }}
                  onClear={() => {
                    setStaffParams({
                      ...staffParams,
                      last_name: undefined,
                    });
                  }}
                />
              </Box>
            </>
          )}
        </Grid>
        <Grid item>
          <Box
            data-testid={'operator-tool-permissions-box'}
            pt={1}
            p={0.5}
            pl={0}
          >
            <Box mb={3} mt={1}>
              <Typography variant='h6'>
                Operator Tool Permissions
              </Typography>
            </Box>
            {renderPermissions(OT_PERMISSIONS_LABELS)}

            <Box mb={3} mt={3}>
              <Typography variant='h6'>Other Permissions</Typography>
            </Box>
            {renderPermissions(OTHER_PERMISSIONS_LABELS)}
          </Box>
        </Grid>
      </Grid>
    );
  };

  const getDialogClass = (
    classes: {
      [key: string]: string;
    },
    roleSelected: Boolean,
  ) => {
    /**
     * TODO:
     * The addstaff dialog has a series of steps which are represented by
     * a series of interlocking booleans, this should be refactored as to be able
     * to represent the correct state unequivocally.
     *
     * This function is a best effort way of representing these states as to assign the correct styling
     * without causing unnecessary regressions, by refactoring the interlocking states
     */
    if (roleSelected) return classes.paperScrollRoleSelected;
    return classes.paperScrollPaper;
  };

  return (
    <>
      {dialogType === DialogType.USER_CREATED && (
        <ConfirmationDialog
          isOpen
          title={addStaffLabels.staffCreatedTitle}
          msg={`We have sent an email to ${staffParams.email} to complete the registration process.`}
          primaryActionLabel='Close'
          onPrimaryAction={() => {
            onClose();
          }}
          onCancel={() => {
            onClose();
          }}
          hideCancelButton
        />
      )}
      {dialogType === DialogType.USER_UPDATED && (
        <ConfirmationDialog
          isOpen
          title={addStaffLabels.staffUpdatedTitle}
          msg={`The roles and permissions for selected ${SINGULAR_TITLE.toLowerCase()} have been updated sucesfully.`}
          primaryActionLabel='Close'
          onPrimaryAction={() => {
            onClose();
          }}
          onCancel={() => {
            onClose();
          }}
          hideCancelButton
        />
      )}
      {dialogType === DialogType.MAIN && (
        <Dialog
          data-testid={'station-staff-add-dialog'}
          open={isOpen}
          className={classes.dialog}
          maxWidth={false}
          TransitionComponent={Transition}
          classes={{
            paper: classes.paper,
            paperScrollPaper: getDialogClass(classes, roleSelected),
          }}
          onClose={() => {
            onClose();
          }}
        >
          {error && (
            <Box mt={2} ml={2} mr={2}>
              <AlertBanner
                data-testid={'station-staff-error-banner'}
                className={classes.banner}
                severity='error'
                alertTitle={'Error'}
                alertMsg={error}
                style={{ marginBottom: 0 }}
              />
            </Box>
          )}
          <DialogTitle>
            <Box p={4} pt={2} pb={0.5}>
              <CSDialogTitleWithIcon
                title={addStaffLabels.addStaffTitle}
                subtitle={addStaffLabels.addStaffSubitle}
                icon={DialogIcons.ADD}
              />
            </Box>
          </DialogTitle>
          <DialogContent
            className={
              roleSelected
                ? classes.dialogContentSm
                : classes.dialogContent
            }
          >
            <Fragment>
              {!roleSelected && renderRoleSelection()}
              {roleSelected && renderForm()}
            </Fragment>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <CSButton
              data-testid={'station-staff-dialog-close'}
              variant='outlined'
              color='secondary'
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </CSButton>

            <CSButton
              data-testid={'station-staff-dialog-add'}
              variant='contained'
              color='secondary'
              disabled={
                !emailAlreadyRegistered
                  ? hasNoName ||
                    (hasNoEmail &&
                      staffParams.permission_data!.role_type! !==
                        StaffPermissionRoleTypeEnum.OPERATOR_I) ||
                    (!hasNoEmail && !isValidEmail)
                  : hasNoEmail
              }
              onClick={() => {
                onAddStaff(
                  staffParams!,
                  () => {
                    if (!hasNoEmail) {
                      if (emailAlreadyRegistered) {
                        setDialogType(DialogType.USER_UPDATED);
                      } else {
                        setDialogType(DialogType.USER_CREATED);
                      }
                    }
                  },
                  emailAlreadyRegistered,
                );
                if (hasNoEmail) onClose();
              }}
            >
              Add
            </CSButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    dialogActions: {
      padding: '16px 23px 25px 8px',
      '& > :not(:first-child)': {
        marginLeft: '16px',
      },
    },
    dialog: {
      margin: '30px 0 30px 0',
    },
    dialogContent: {
      padding: '8px 6px 14px 24px',
    },
    dialogContentSm: {
      padding: '14px 21px',
    },
    container: {
      minWidth: '135px',
    },
    formControl: {
      width: '100%',
      borderBottom: `${theme.borderWidth.border_thin} solid ${theme.palette.grey[300]}`,
    },
    cardContent: {
      padding: 0,
      cursor: 'pointer',
    },
    roleName: {
      [theme.breakpoints.down('xs')]: {
        fontSize: '1rem',
      },
    },
    roleDescription: {
      minHeight: '55px',
    },
    paper: {
      padding: 0,
      margin: 0,
    },
    staffIconContainer: {
      width: '100%',
      backgroundColor: theme.palette.background.default,
      minHeight: '174px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paperScrollRoleSelected: {
      maxHeight: '100%',
      maxWidth: '488px',
    },
    paperScrollEmailSent: {
      maxHeight: '100%',
      maxWidth: '600px',
    },
    paperScrollPaper: {
      maxHeight: '100%',
      maxWidth: '824px',
    },
    closeButton: {
      minWidth: 'inherit',
      width: '30px',
      height: '30px',
      padding: 0,
    },
    tooltip: {
      background: theme.palette.secondary?.main,
      color: theme.palette.secondary?.contrastText,
      padding: '10px',
      borderRadius: theme.shape.borderRadius_sm,
      ...theme.typography.body2,
      boxShadow: theme.shadows[7],
    },
  })),
)(AddStaffDialog);
