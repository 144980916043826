import React, { useEffect, useState, useCallback } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { CSButton, CSTextField } from '../../primitives';

import { withStyles } from '@material-ui/core/styles';
import ProgressIndicator from '../../progressIndicator/ProgressIndicator';
import { Grid, DialogTitle, createStyles } from '@material-ui/core';
import detailsPageStyles from '../../commonStyles/detailsPage.style';
import ErrorHandler from '../../../utils/ErrorHandler';
import { formatFirstNItemsAndMore } from '../../DetailsPagesFunctions';

import debounce from 'lodash/debounce';
import sanitizeHtml from 'sanitize-html';
import { MAX_PAGE_SIZE } from '../../../services/utils/constants';

//Types
import { AxiosError } from 'axios';
import {
  APIException,
  APIExceptionErrorCodeEnum,
  Customer,
  SchemeDetails,
  User,
} from 'cloudsort-client';
import { TypeAheadItem } from '../../../interfaces/components';

// Services
import FmcService from '../../../services/Fmc.service';
import SchemesService from '../../../services/Schemes.service';

//Icons

import { noOptionsMessage } from '../../asyncSelect/utils';
import CustomersService from '../../../services/Customers.service';
import EphemeralStateService from '../../../services/EphemeralState.service';
import CSDialogTitleWithIcon from '../../primitives/csDialogTitleWithIcon/CSDialogTitleWithIcon';
import { DialogIcons } from '../../primitives/csDialogTitleWithIcon/CSDialogTitleWithIconTypes';
import CSDialogAlert from '../../primitives/csDialogAlert/CSDialogAlert';
import CSAsyncSelect from '../../primitives/csAsyncSelect/CSAsyncSelect';
import { CSMonoGridContainer } from '../../primitives/csMonoGridContainer';

interface Props {
  loadplanId?: number;
  classes: { [key: string]: string };
  onAfterClose: () => void;
  isOpen: boolean;
  updateParent: () => void;
}

const AddSchemeDialog: React.FC<Props> = ({
  loadplanId,
  isOpen,
  classes,
  onAfterClose,
  updateParent,
}) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState<string>();
  const [showProgress, setShowProgress] = useState<boolean>(false);

  const [addSchemeData, setAddSchemeData] = useState<SchemeDetails>({
    name: '',
    fmc: undefined,
    sort_param: '',
    owner: undefined,
  });

  const formatAsyncOptions = (data: User[]) => {
    return data.map((dataEl: User) => {
      return {
        value: dataEl.id,
        label: dataEl.full_name,
      };
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadCarrierOptions = useCallback(
    debounce((inputValue: string, callback: any) => {
      FmcService.search(inputValue)
        .then((data) => {
          callback(formatAsyncOptions(data.data.results));
        })
        .catch((e) => {
          handleError(e as AxiosError);
        });
    }, 500),
    [],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadCustomerOptions = useCallback(
    debounce((inputValue: string, callback: any) => {
      CustomersService.getAll(
        undefined,
        inputValue,
        MAX_PAGE_SIZE,
        EphemeralStateService.getMyStationId(),
        EphemeralStateService.getMyOrgId(),
      )
        .then((data) => {
          callback(
            data.data.results.map((dataEl: Customer) => {
              return {
                value: dataEl.id,
                label: dataEl.identifier,
              };
            }),
          );
        })
        .catch((e) => {
          handleError(e as AxiosError);
        });
    }, 500),
    [],
  );

  const handleClose = () => {
    setError(undefined);

    setAddSchemeData({
      name: '',
      fmc: undefined,
      sort_param: '',
      owner: undefined,
    });
    onAfterClose();
  };

  const handleError = async (e: AxiosError) => {
    const errorData = e.response?.data as APIException;
    if (
      errorData?.error_code ===
      APIExceptionErrorCodeEnum.RESOURCE_CONFLICT
    ) {
      const conflictingZipcodes =
        ErrorHandler.getConflictingZipcodes(errorData);
      setError(
        'The following Zip Codes are duplicated in the Scheme Set: ' +
          formatFirstNItemsAndMore(conflictingZipcodes, 10), //show first 10
      );
    } else setError(await ErrorHandler.getLabel(e as AxiosError));
  };

  const processAddScheme = async () => {
    setShowProgress(true);
    try {
      await SchemesService.create({
        ...addSchemeData!,
        load_plan: loadplanId,
      });

      updateParent();
      handleClose();
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);
  return (
    <>
      {showProgress && <ProgressIndicator />}
      <Dialog open={open}>
        <DialogTitle>
          <CSDialogTitleWithIcon
            icon={DialogIcons.ADD}
            title='Add New Scheme'
          />
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <CSMonoGridContainer>
                {error ? (
                  <CSDialogAlert alertMessage={error} />
                ) : (
                  <></>
                )}
                <CSTextField
                  label='Name'
                  placeholder='Name'
                  autoFocus
                  containerSize='fullHorizontal'
                  value={addSchemeData?.name || ''}
                  onChange={(e) => {
                    setAddSchemeData({
                      ...addSchemeData,
                      name: e.target.value,
                    });
                  }}
                />
                <CSTextField
                  label='Sortation Parameter'
                  placeholder='Sortation Parameter'
                  containerSize='fullHorizontal'
                  value={
                    addSchemeData?.sort_param
                      ? sanitizeHtml(addSchemeData.sort_param, {
                          allowedTags: [],
                        })
                      : ''
                  }
                  onChange={(e) => {
                    setAddSchemeData({
                      ...addSchemeData,
                      sort_param: e.target.value,
                    });
                  }}
                />
                <CSAsyncSelect<TypeAheadItem>
                  containerSize='fullHorizontal'
                  label='Carrier'
                  isClearable
                  cacheOptions
                  loadOptions={loadCarrierOptions}
                  onChange={(option) => {
                    setAddSchemeData({
                      ...addSchemeData,
                      fmc: option
                        ? Number((option as TypeAheadItem).value)
                        : undefined,
                    });
                  }}
                  isDisabled={!!error}
                  placeholder={'Start Typing...'}
                  menuPortalTarget={document.body}
                  noOptionsMessage={noOptionsMessage}
                />
                <CSAsyncSelect<TypeAheadItem>
                  containerSize='fullHorizontal'
                  label='Owner'
                  isClearable
                  cacheOptions
                  loadOptions={loadCustomerOptions}
                  onChange={(option) => {
                    setAddSchemeData({
                      ...addSchemeData,
                      owner: option
                        ? Number((option as TypeAheadItem).value)
                        : undefined,
                    });
                  }}
                  isDisabled={!!error}
                  placeholder={'Start Typing...'}
                  menuPortalTarget={document.body}
                  noOptionsMessage={noOptionsMessage}
                />
              </CSMonoGridContainer>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <CSButton
            className={classes.cancelButton}
            variant='outlined'
            color='secondary'
            data-testid={'scheme-add-dialog-cancel'}
            onClick={() => {
              handleClose();
            }}
          >
            Cancel
          </CSButton>

          <CSButton
            variant='contained'
            color='secondary'
            data-testid={'scheme-add-dialog-save'}
            disabled={!addSchemeData.name}
            onClick={() => {
              processAddScheme();
            }}
          >
            Add
          </CSButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withStyles(
  createStyles(() => ({
    ...detailsPageStyles,
    dialogContent: { width: '500px', maxWidth: '100%' },

    cancelButton: {
      marginRight: 10,
    },
    dialogActions: {
      padding: 24,
    },
  })),
)(AddSchemeDialog);
