import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import DockDoorsService from '../../services/DockDoors.service';
import styles from './dockDoors.styles';
import Layout from '../layout/Layout';
import { AuthRoutes, ModulesKeys } from '../../interfaces/routes';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Transition } from '../confirmationDialog/ConfirmationDialog';
import {
  CSButton,
  CSTextField,
  Typography,
} from '../primitives/index';
import ErrorHandler from '../../utils/ErrorHandler';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import PaginatedTable from '../paginatedTable/PaginatedTable';
import { Column } from '../../interfaces/components';
import configurationUtils from '../../utils/configurationUtils';
import { Grid } from '@material-ui/core';
import queryString from 'query-string';

// Types
import { AxiosError } from 'axios';
import { StaffPermissionPermissionsEnum } from 'cloudsort-client';

// Icons
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';

//Services
import PermissionsService from '../../services/Permissions.service';
import { Helmet } from 'react-helmet';
import CSSectionTitleSeparator from '../primitives/csSectionTitleSeparator/CSSectionTitleSeparator';
import CSDialogAlert from '../primitives/csDialogAlert/CSDialogAlert';
import CSDialogTitleWithIcon from '../primitives/csDialogTitleWithIcon/CSDialogTitleWithIcon';
import { DialogIcons } from '../primitives/csDialogTitleWithIcon/CSDialogTitleWithIconTypes';
import { useAppDispatch } from '../../redux/store';
import { setLastVisitedModule } from '../../redux/slices/navigationSlice';

const COLUMNS: Column[] = [
  {
    id: 'id',
    label: 'ID',
    width: 50,
    align: 'left',
  },
  {
    id: 'name',
    label: 'Name',
    width: 'auto',
    align: 'center',
  },
  {
    id: 'station_name',
    label: 'Station',
    width: 'auto',
    align: 'center',
  },
];

interface Props {
  classes: { [key: string]: string };
  location: any;
}

const DockDoors: React.FC<Props> = ({ classes, location }) => {
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [showAddDialog, setShowAddDialog] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [name, setName] = useState<string>();
  const [lastUpdated, setLastUpdated] = useState<string>(
    new Date().toISOString(),
  );

  const dispatch = useAppDispatch();

  const fetch = async (pageIndex: number, rowsPerPage: number) => {
    return DockDoorsService.getAll({
      page: pageIndex,
      pageSize: rowsPerPage,
    });
  };

  const onAfterDialogClose = () => {
    setShowAddDialog(false);
    setName('');
    setError(undefined);
  };

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e as AxiosError));
  };

  const addDockDoorHandler = async () => {
    setShowProgress(true);
    try {
      if (name) {
        await DockDoorsService.create(name);
        onAfterDialogClose();
        setLastUpdated(new Date().toISOString());
      }
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  const renderDialog = () => {
    return (
      <Dialog
        open={showAddDialog}
        TransitionComponent={Transition}
        onClose={onAfterDialogClose}
      >
        {error && (
          <CSDialogAlert alertMessage={error} bottomMargin={20} />
        )}
        <DialogTitle>
          <CSDialogTitleWithIcon
            icon={DialogIcons.ADD}
            title={`Add ${configurationUtils.getPageTitle(
              true,
              'DOCK_DOOR',
            )}`}
          />
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <CSTextField
            containerSize='fullHorizontal'
            data-testid='new-dock-door-name'
            label='Name'
            placeholder='Name'
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </DialogContent>

        <DialogActions className={classes.dialogActions}>
          <CSButton
            color='secondary'
            variant='outlined'
            onClick={onAfterDialogClose}
          >
            Cancel
          </CSButton>
          <CSButton
            color='secondary'
            variant='contained'
            data-testid='save-new-dock-door'
            onClick={() => {
              addDockDoorHandler();
            }}
            disabled={!name}
          >
            Save
          </CSButton>
        </DialogActions>
      </Dialog>
    );
  };

  useEffect(() => {
    PermissionsService.redirectIfNoPermission(
      StaffPermissionPermissionsEnum.STATION_READ,
    );

    dispatch(
      setLastVisitedModule({
        module: ModulesKeys.DOCK_DOOR,
        permission: StaffPermissionPermissionsEnum.STATION_READ,
      }),
    );
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort -
${configurationUtils.getPageTitle(false, 'DOCK_DOOR')} ${
            queryString.parse(location.search)['page']
              ? '- Page ' + queryString.parse(location.search)['page']
              : ''
          }`}
        </title>
      </Helmet>
      <Layout navCurrent='DOCK_DOOR'>
        {showProgress && <ProgressIndicator />}
        {renderDialog()}

        <Grid
          container
          className={classes.header}
          alignItems='center'
        >
          <Grid item xs={12} sm={6}>
            <Typography variant='h3' component='h2'>
              {configurationUtils.getPageTitle()}
            </Typography>
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
            className={classes.nonMobileAlignRight}
          >
            {PermissionsService.hasPermission(
              StaffPermissionPermissionsEnum.STATION_WRITE,
            ) && (
              <CSButton
                variant='contained'
                color={{
                  buttonColor: 'secondary',
                  iconColor: 'primary',
                }}
                data-testid='add-dock-door'
                disabled={!!error}
                onClick={(e) => {
                  e.preventDefault();
                  setShowAddDialog(true);
                }}
                startIcon={<AddBoxOutlinedIcon />}
              >
                Add{' '}
                {configurationUtils.getPageTitle(true, 'DOCK_DOOR')}
              </CSButton>
            )}
          </Grid>
          <CSSectionTitleSeparator bottomMargin={0} topMargin={15} />
        </Grid>
        <PaginatedTable
          key={lastUpdated}
          title=''
          columns={COLUMNS}
          dataTestIdPrefix={'dock-doors-lists'}
          fetch={fetch}
          rowsLoadDetailPages={true}
          detailsPageBasePath={AuthRoutes.DOCK_DOOR.replace('/', '')}
        />
      </Layout>
    </>
  );
};

export default withStyles(styles)(DockDoors);
