import React, { useState, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { withStyles, useTheme } from '@material-ui/core/styles';
import styles from './dashboard.styles';

import Layout from '../layout/Layout';
import { Typography } from '../primitives/index';
import { CSButton } from '../primitives';
import { Grid, Box } from '@material-ui/core';
import clx from 'classnames';
import configurationUtils from '../../utils/configurationUtils';
import PrintQrCodesDialog from '../printQrCodesDialog/PrintQrCodesDialog';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import FiltersDrawer from '../filtersDrawer/FiltersDrawer';
import isEmpty from 'lodash/isEmpty';

//Dashboard cards
import {
  SummaryStats,
  PackagesStats,
  StaffStats,
  DevicesStats,
  ContainersStats,
  DwellTimeCharts,
  ExceptionsStats,
  LoadsStats,
  PerformanceStats,
  TodayPackagesChart,
} from './dashboardModules';

//DND
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { DraggableCard } from './DraggableCard';

//Redux
import { useAppSelector, useAppDispatch } from '../../redux/store';
import {
  selectGlobalFilters,
  removeFilter,
} from '../../redux/slices/filtersSlice';

// Types
import { StaffPermissionPermissionsEnum } from 'cloudsort-client';

//Services
import LocalStorageService from '../../services/LocalStorage.service';
import PermissionsService from '../../services/Permissions.service';
import EphemeralStateService from '../../services/EphemeralState.service';

// Icons
import FilterCenterFocusIcon from '@material-ui/icons/FilterCenterFocus';
import FilterListIcon from '@material-ui/icons/FilterList';
import CancelIcon from '@material-ui/icons/Cancel';
import { setLastVisitedModule } from '../../redux/slices/navigationSlice';
import { ModulesKeys } from '../../interfaces/routes';

export const FETCH_INTERVAL_TIME = 60000;
export const FETCH_INTERVAL_ACTIVE = false;
export const CARD_LOAD_DELAY = 1000;

interface Props {
  classes: { [key: string]: string };
}

enum DashbaordSections {
  SUMMARY_COUNTERS = 'SUMMARY_COUNTERS',
  STAFF_COUNTERS = 'STAFF_COUNTERS',
  DEVICES_COUNTERS = 'DEVICES_COUNTERS',
  PACKAGE_COUNTERS = 'PACKAGE_COUNTERS',
  CONTAINER_COUNTERS = 'CONTAINER_COUNTERS',
  LOAD_COUNTERS = 'LOAD_COUNTERS',
  MISSORT_COUNTERS = 'MISSORT_COUNTERS',
  PERFORMANCE_CHART = 'PERFORMANCE_CHART',
  DWELL_P2S_CHART = 'DWELL_P2S_CHART',
  TODAY_CHART = 'TODAY_CHART',
}

const Dashboard: React.FC<Props> = ({ classes }) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));
  const [openQrDialog, setOpenQrDialog] = useState(false);
  const [showFiltersDrawer, setShowFiltersDrawer] = useState(false);

  //Loading Config
  const layoutConfig =
    EphemeralStateService.getMyStationConfiguratation()?.WEB?.LAYOUTS
      ?.DASHBOARD;

  //Redux
  const dispatch = useAppDispatch();
  const filtersReduxStore = useAppSelector(selectGlobalFilters);

  // Generate a key based on filter values -- to be used as key for individual components, to make them reload when filters update
  const keyHash = isEmpty(filtersReduxStore.appliedFilters)
    ? 'none'
    : Object.entries(filtersReduxStore.appliedFilters).reduce(
        (total, item: any) =>
          (total =
            total +
            item[1].values.map(
              (value: any) => value.name || value.full_name,
            )),
        'filters-',
      );

  //Cards

  const [cards, setCards] = useState(
    LocalStorageService.getDashboardsCardsLayout() || [
      DashbaordSections.SUMMARY_COUNTERS,
      DashbaordSections.STAFF_COUNTERS,
      DashbaordSections.DEVICES_COUNTERS,
      DashbaordSections.PACKAGE_COUNTERS,
      DashbaordSections.CONTAINER_COUNTERS,
      DashbaordSections.LOAD_COUNTERS,
      DashbaordSections.MISSORT_COUNTERS,
      DashbaordSections.PERFORMANCE_CHART,
      DashbaordSections.DWELL_P2S_CHART,
      DashbaordSections.TODAY_CHART,
    ],
  );

  const allComponents: any = {
    TODAY_CHART: {
      component: (
        <TodayPackagesChart
          title={layoutConfig?.TODAY_CHART?.title}
          isMobileView={isMobileView}
          index={cards.findIndex(
            (item: string) => item === 'TODAY_CHART',
          )}
          filters={filtersReduxStore.appliedFilters}
        />
      ),
      fullWidth: true,
    },
    LOAD_COUNTERS: {
      component: (
        <LoadsStats
          title={layoutConfig?.LOAD_COUNTERS?.title}
          filters={filtersReduxStore.appliedFilters}
          key={'loads-stats-' + keyHash}
          index={cards.findIndex(
            (item: string) => item === 'LOAD_COUNTERS',
          )}
        />
      ),
      fullWidth: false,
    },
    DWELL_P2S_CHART: {
      component: (
        <DwellTimeCharts
          title_p2s={layoutConfig?.DWELL_P2S_CHART?.title}
          title_s2c={layoutConfig?.DWELL_S2S_CHART?.title}
          key={'dwell-stats-' + keyHash}
          index={cards.findIndex(
            (item: string) => item === 'DWELL_P2S_CHART',
          )}
          filters={filtersReduxStore.appliedFilters}
        />
      ),
      fullWidth: true,
    },
    DEVICES_COUNTERS: {
      component: (
        <DevicesStats
          title={layoutConfig?.DEVICES_COUNTERS?.title}
          key={'devices-stats-' + keyHash}
          index={cards.findIndex(
            (item: string) => item === 'DEVICES_COUNTERS',
          )}
        />
      ),
      fullWidth: false,
    },
    MISSORT_COUNTERS: {
      component: (
        <ExceptionsStats
          title={layoutConfig?.MISSORT_COUNTERS?.title}
          filters={filtersReduxStore.appliedFilters}
          key={'exception-stats-' + keyHash}
          index={cards.findIndex(
            (item: string) => item === 'MISSORT_COUNTERS',
          )}
        />
      ),
      fullWidth: false,
    },
    PACKAGE_COUNTERS: {
      component: (
        <PackagesStats
          title={layoutConfig?.PACKAGE_COUNTERS?.title}
          filters={filtersReduxStore.appliedFilters}
          key={'package-stats-' + keyHash}
          index={cards.findIndex(
            (item: string) => item === 'PACKAGE_COUNTERS',
          )}
        />
      ),
      fullWidth: false,
    },
    PERFORMANCE_CHART: {
      component: (
        <PerformanceStats
          title={layoutConfig?.PERFORMANCE_CHART?.title}
          filters={filtersReduxStore.appliedFilters}
          key={'performance-chart-' + keyHash}
          index={cards.findIndex(
            (item: string) => item === 'PERFORMANCE_CHART',
          )}
        />
      ),
      fullWidth: true,
    },
    CONTAINER_COUNTERS: {
      component: (
        <ContainersStats
          title={layoutConfig?.CONTAINER_COUNTERS?.title}
          filters={filtersReduxStore.appliedFilters}
          key={'container-stats-' + keyHash}
          index={cards.findIndex(
            (item: string) => item === 'CONTAINER_COUNTERS',
          )}
        />
      ),
      fullWidth: false,
    },
    //These two are not in the BE array
    STAFF_COUNTERS: {
      component: (
        <StaffStats
          title='Staff Stats'
          key={'staff-stats-' + keyHash}
          index={cards.findIndex(
            (item: string) => item === 'STAFF_COUNTERS',
          )}
        />
      ),
      fullWidth: false,
    },
    SUMMARY_COUNTERS: {
      component: (
        <SummaryStats
          title='Summary'
          filters={filtersReduxStore.appliedFilters}
          key={'summary-stats-' + keyHash}
          index={cards.findIndex(
            (item: string) => item === 'SUMMARY_COUNTERS',
          )}
        />
      ),
      fullWidth: false,
    },
  };

  const myStation = LocalStorageService.getMyStationData();

  // Leave for now may be useful later
  // const areaOrRouteFiltersNotActive =
  //   filtersReduxStore &&
  //   filtersReduxStore.appliedFilters.stop &&
  //   !filtersReduxStore.appliedFilters.stop.values.length &&
  //   filtersReduxStore.appliedFilters.route &&
  //   !filtersReduxStore.appliedFilters.route.values.length;

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragCard = cards[dragIndex];
      const newOrder = update(cards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard],
        ],
      });
      LocalStorageService.setDashboardsCardsLayout(newOrder);
      setCards(newOrder);
    },
    [cards],
  );

  const renderCard = (
    card: { id: string; content: JSX.Element },
    index: number,
  ) => {
    return (
      <DraggableCard
        key={card.id}
        index={index}
        id={card.id}
        content={card.content}
        moveCard={moveCard}
        fullWidth={allComponents[card.id].fullWidth}
      />
    );
  };

  useEffect(() => {
    dispatch(
      setLastVisitedModule({
        module: ModulesKeys.DASHBOARD,
        permission: StaffPermissionPermissionsEnum.STATION_READ,
      }),
    );
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort -
          ${configurationUtils.getPageTitle(true, 'DASHBOARD')}`}
        </title>
      </Helmet>
      <Layout
        navCurrent='DASHBOARD'
        className={classes.layout}
        key={(myStation as any).id}
      >
        <PrintQrCodesDialog
          isOpen={openQrDialog}
          onAfterClose={() => {
            setOpenQrDialog(false);
          }}
        />
        <FiltersDrawer
          isOpen={showFiltersDrawer}
          onAfterClose={() => {
            setShowFiltersDrawer(false);
          }}
        />
        <Grid container wrap='wrap'>
          <Grid item container xs={12} sm={4} md={6} lg={6} xl={6}>
            <Box mb={2}>
              <Typography
                variant='h3'
                component='h1'
                className={classes.title}
              >
                {configurationUtils.getPageTitle(true)}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={8}
            md={6}
            lg={6}
            xl={6}
            alignContent='flex-end'
            direction='column'
          >
            <Box pt={1} pb={1} style={{ textAlign: 'right' }}>
              {PermissionsService.hasPermission(
                StaffPermissionPermissionsEnum.STATION_QRCODE_READ,
              ) && (
                <CSButton
                  variant='contained'
                  color='primary'
                  className={clx(classes.headerButtons)}
                  id='dashboard-button-qr-codes'
                  onClick={() => {
                    setOpenQrDialog(true);
                  }}
                >
                  <FilterCenterFocusIcon
                    style={{ marginRight: 10 }}
                  />
                  QR Codes
                </CSButton>
              )}
              <CSButton
                data-testid='dashboard:open-filters-drawer'
                variant='outlined'
                color='secondary'
                className={clx(
                  classes.headerButtons,
                  classes.headerFilterButton,
                )}
                onClick={() => {
                  setShowFiltersDrawer(true);
                }}
              >
                <FilterListIcon />
              </CSButton>
            </Box>
          </Grid>
          <Grid
            item
            container
            xs={12}
            style={{ marginBottom: '10px' }}
            data-testid='dashboard:applied-filters'
          >
            {!isEmpty(filtersReduxStore.appliedFilters) && (
              <>
                {keyHash !== '' && (
                  <hr className={classes.filterHR} />
                )}

                {Object.values(filtersReduxStore.appliedFilters).map(
                  (item: any) =>
                    item.values.length ? (
                      <Grid
                        item
                        data-testid={`dashboard:filters:${item.key}:badge`}
                        key={item.key}
                      >
                        {item.values.map((value: any) => (
                          <CSButton
                            data-testid={`dashboard:filters:${
                              item.key
                            }:${
                              value.name || value.full_name
                            }:remove`}
                            key={`${value.name || value.full_name}-${
                              value.id
                            }`}
                            variant='outlined'
                            color='secondary'
                            size='small'
                            onClick={() => {
                              dispatch(
                                removeFilter({
                                  key: item.key,
                                  id: value.id,
                                }),
                              );
                            }}
                            endIcon={<CancelIcon />}
                          >
                            {item.label}:{' '}
                            {value.name || value.full_name}
                          </CSButton>
                        ))}
                      </Grid>
                    ) : undefined,
                )}
              </>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.dndContainer}>
            <DndProvider backend={HTML5Backend}>
              {keyHash !== 'none' &&
                cards.map((card: string, i: number) =>
                  layoutConfig[card]?.active ||
                  !Object.keys(layoutConfig).includes(card) // Some cards are not in the config -- always show them
                    ? renderCard(
                        {
                          id: card,
                          content: allComponents[card].component,
                        },
                        i,
                      )
                    : undefined,
                )}
            </DndProvider>
          </div>
        </Grid>
      </Layout>
    </>
  );
};

export default withStyles(styles)(Dashboard);
