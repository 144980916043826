import React, { Fragment, useState } from 'react';
import {
  withStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles';

import colors from '../../utils/colors';
import {
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  Snackbar,
  Tooltip,
} from '@material-ui/core';
import { Checkbox } from '../primitives';

import selectStyles from '../select/select.outlined.styles';

//Types
import {
  Staff,
  StaffPermissionPermissionsEnum,
  StaffPermissionRoleTypeEnum,
} from 'cloudsort-client';
import {
  ROLE_LABELS,
  OT_PERMISSIONS_LABELS,
  OTHER_PERMISSIONS_LABELS,
} from '../stationStaff/StationStaff';

//Services

import PermissionsService from '../../services/Permissions.service';

//Icons

import { permissionEntity } from './Profile';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import usePermissionLabels from '../../hooks/usePermissionLabels';
import usePermissionDependencies from '../../hooks/usePermissionDependencies';
import { transformDependencyData } from '../../utils/permissionDependenciesUtils';
import clsx from 'clsx';
import { CSSingleDetailMonoColumnContainer } from '../primitives/singleDetail/singleDetailMonoColumnContainer';
import CSSelect from '../primitives/csSelect/CSSelect';
import detailsPageStyle from '../commonStyles/detailsPage.style';

interface Props {
  classes: { [key: string]: string };
  isOwnProfile: boolean;
  staffData: Staff;
  setStaffData: (staff: Staff) => void;
  setEnableSave: (enabled: boolean) => void;
  getProfileData: (stationId?: number, orgId?: number) => void;
  assignedEntities: permissionEntity[];
  selectedEntity?: permissionEntity;
  setSelectedEntity: (entity: permissionEntity) => void;
}

const RolesPermissions: React.FC<Props> = ({
  classes,
  isOwnProfile,
  staffData,
  setStaffData,
  setEnableSave,
  getProfileData,
  assignedEntities,
  selectedEntity,
  setSelectedEntity,
}) => {
  const [showDependencyNotification, setShowDependencyNotification] =
    useState(false);
  const [
    dependencyNotificationMessage,
    setDependencyNotificationMessage,
  ] = useState('');
  const permissionLabels = usePermissionLabels();
  const {
    calculateDependencyStatus,
    disablePermissionAndDependants,
  } = usePermissionDependencies();

  const selectRole = (role: StaffPermissionRoleTypeEnum) => {
    const defaultPermissions =
      PermissionsService.getDefaultsPermissionsForRole(role);
    setStaffData({
      ...staffData,
      permission_data: {
        ...staffData?.permission_data,
        role_type: role,
        permissions: defaultPermissions,
      },
    });
  };

  const renderPermissionsListAsCheckBoxes = (
    title: string,
    permissionsToRender:
      | typeof OTHER_PERMISSIONS_LABELS
      | typeof OT_PERMISSIONS_LABELS,
  ) => {
    const dependencyStatus = transformDependencyData(
      calculateDependencyStatus(
        permissionsToRender,
        staffData!.permission_data!.permissions as string[],
      ),
      permissionLabels,
    );
    return (
      <CSSingleDetailMonoColumnContainer
        header={title}
        elements={Object.keys(StaffPermissionPermissionsEnum)
          .filter((permission) =>
            Object.keys(permissionsToRender).includes(permission),
          )
          .map((key) => {
            return StaffPermissionPermissionsEnum[
              key as keyof typeof StaffPermissionPermissionsEnum
            ];
          })
          .map((permission) => {
            return (
              <Fragment
                key={`fragment-${permissionLabels[permission]}`}
              >
                <Tooltip
                  classes={{
                    tooltip: classes.tooltip,
                  }}
                  title={
                    dependencyStatus[permission].enabled
                      ? ''
                      : dependencyStatus[permission].tooltipMessage
                  }
                  placement='top-start'
                >
                  <span>
                    {staffData?.permission_data?.permissions && (
                      <FormControlLabel
                        key={permissionLabels[permission]}
                        style={{ width: '100%' }}
                        control={
                          <Checkbox
                            disabled={
                              !selectedEntity?.id ||
                              isOwnProfile ||
                              !dependencyStatus[permission].enabled
                            }
                            color={undefined}
                            key={`checkbox-${permissionLabels[permission]}`}
                            classes={{
                              root: classes.checkbox,
                              checked: classes.checked,
                            }}
                            checked={staffData.permission_data?.permissions.some(
                              (selectedPermission) =>
                                selectedPermission === permission,
                            )}
                            onChange={(e) => {
                              const isAlreadySelected =
                                staffData.permission_data?.permissions!.some(
                                  (selectedPermission) =>
                                    selectedPermission === permission,
                                );
                              if (isAlreadySelected) {
                                const {
                                  newPermissionsState,
                                  disabledPermissions,
                                } = disablePermissionAndDependants(
                                  permission,
                                  staffData!.permission_data!
                                    .permissions as string[],
                                );

                                //All dependencies are disabled, show notification to the user.
                                if (disabledPermissions.length > 0) {
                                  setShowDependencyNotification(true);
                                  setDependencyNotificationMessage(
                                    `Your selection also disabled the dependant permission${
                                      disabledPermissions.length === 1
                                        ? ''
                                        : 's'
                                    }: ${disabledPermissions.join(
                                      ', ',
                                    )}.`,
                                  );
                                }
                                setStaffData({
                                  ...staffData,
                                  permission_data: {
                                    ...staffData.permission_data,
                                    permissions: [
                                      ...newPermissionsState,
                                    ],
                                  },
                                });
                              } else {
                                setStaffData({
                                  ...staffData,
                                  permission_data: {
                                    ...staffData.permission_data,
                                    permissions: [
                                      ...(staffData.permission_data
                                        ?.permissions || []),
                                      permission,
                                    ],
                                  },
                                });
                              }
                              setEnableSave(true);
                            }}
                          />
                        }
                        label={permissionLabels[permission]}
                      />
                    )}
                  </span>
                </Tooltip>
              </Fragment>
            );
          })}
      />
    );
  };

  return staffData?.permission_data?.permissions ? (
    <Grid container>
      <Snackbar
        data-testid='snackbar-notification'
        key={dependencyNotificationMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={showDependencyNotification}
        onClose={(event, reason) => {
          if (reason === 'clickaway') {
            return;
          }
          setShowDependencyNotification(false);
        }}
        autoHideDuration={4000}
        message={dependencyNotificationMessage}
      />

      <Grid item xs={12}>
        <Paper
          className={clsx(classes.paper, classes.maxContainer)}
          data-testid='profile-roles-permissions'
        >
          <CSSingleDetailMonoColumnContainer
            header='Entity'
            elements={[
              <CSSelect
                containerSize='fullHorizontal'
                className={classes.halfWidthSelect}
                onChange={(e: React.BaseSyntheticEvent) => {
                  if (e.target.value !== 'none') {
                    const selected = assignedEntities?.find(
                      (entity) => entity.name === e.target.value,
                    );
                    if (selected) {
                      setSelectedEntity(selected);
                      if (selected.type === 'STATION') {
                        getProfileData(selected.id);
                      } else {
                        getProfileData(undefined, selected.id);
                      }
                    }
                  }
                }}
                value={selectedEntity?.name ?? 'none'}
              >
                <MenuItem key={`fragment-undefined`} value='none'>
                  Select Entity
                </MenuItem>
                {assignedEntities.map((entity) => {
                  return (
                    <MenuItem
                      key={`fragment-${entity.type}-${entity.id}`}
                      value={entity.name}
                    >
                      {entity.name}
                    </MenuItem>
                  );
                })}
              </CSSelect>,
            ]}
          />
          <CSSingleDetailMonoColumnContainer
            header='Role'
            elements={[
              <CSSelect
                disabled={!selectedEntity?.id || isOwnProfile}
                onChange={(e: React.BaseSyntheticEvent) => {
                  if (e.target.value !== '') {
                    selectRole(e.target.value);
                    setEnableSave(true);
                  }
                }}
                containerSize='fullHorizontal'
                className={classes.halfWidthSelect}
                value={
                  staffData?.permission_data!.role_type ?? 'none'
                }
              >
                <MenuItem
                  key={`fragment-undefined`}
                  value='none'
                  disabled
                >
                  No Role
                </MenuItem>
                {Object.keys(StaffPermissionRoleTypeEnum)
                  .filter(
                    (key) => key !== 'SUPERUSER' && key !== 'BLANK',
                  )
                  .map((key) => {
                    return {
                      label:
                        ROLE_LABELS[
                          key as keyof typeof StaffPermissionRoleTypeEnum
                        ],
                      value:
                        StaffPermissionRoleTypeEnum[
                          key as keyof typeof StaffPermissionRoleTypeEnum
                        ],
                    };
                  })
                  .map((permission) => {
                    return (
                      <MenuItem
                        key={`fragment-${permission.label}`}
                        value={permission.value}
                      >
                        {permission.label}
                      </MenuItem>
                    );
                  })}
              </CSSelect>,
            ]}
          />

          {renderPermissionsListAsCheckBoxes(
            'Operator Tool permissions',
            OT_PERMISSIONS_LABELS,
          )}
          {renderPermissionsListAsCheckBoxes(
            'Other permissions',
            OTHER_PERMISSIONS_LABELS,
          )}
        </Paper>
      </Grid>
    </Grid>
  ) : (
    <ProgressIndicator />
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...selectStyles,
    ...detailsPageStyle,
    tooltip: {
      background: colors.dark,
    },
    halfWidthSelect: {
      maxWidth: '50%',
    },
  })),
)(RolesPermissions);
