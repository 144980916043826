import React, { useEffect, useState } from 'react';
import ConfirmationDialog from '../../confirmationDialog/ConfirmationDialog';
import ProgressIndicator from '../../progressIndicator/ProgressIndicator';

// Services
import SchemesService from '../../../services/Schemes.service';

interface Props {
  schemeId: number;
  schemeName?: string;
  onAfterClose: () => void;
  isOpen: boolean;
  updateParent: () => void;
}

const DeleteSchemeDialog: React.FC<Props> = ({
  schemeId,
  schemeName,
  isOpen,
  onAfterClose,
  updateParent,
}) => {
  const [open, setOpen] = useState(false);
  const [showProgress, setShowProgress] = useState<boolean>(false);

  const handleClose = () => {
    onAfterClose();
  };

  const processDeleteScheme = async () => {
    setShowProgress(true);
    try {
      await SchemesService.delete(schemeId);

      updateParent();
      onAfterClose();
    } catch (e) {
      console.error(e);
    } finally {
      setShowProgress(false);
    }
  };

  useEffect(() => {
    setOpen(isOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <>
      {showProgress && <ProgressIndicator />}
      <ConfirmationDialog
        data-testid={'delete-scheme-confirmation'}
        title={'Delete Scheme'}
        msg={`Are you sure you want to delete scheme ${
          schemeName || schemeId
        }?`}
        primaryActionLabel={'Yes'}
        onPrimaryAction={() => {
          processDeleteScheme();
        }}
        cancelLabel={'No'}
        onCancel={() => {
          handleClose();
        }}
        isOpen={open}
      />
    </>
  );
};

export default DeleteSchemeDialog;
