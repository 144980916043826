import React, { useEffect, useMemo, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, CSButton, CSTextField } from '../primitives';
import styles from './dockDoors.styles';
import DockDoorsService from '../../services/DockDoors.service';
import OutboundLoadsService from '../../services/OutboundLoads.service';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import Layout from '../layout/Layout';
import ErrorHandler from '../../utils/ErrorHandler';
import PaginatedTable from '../paginatedTable/PaginatedTable';
import { Column } from '../../interfaces/components';
import { renderTimestampString } from '../DetailsPagesFunctions';
import { AuthRoutes } from '../../interfaces/routes';
import qrCodeUtils from '../../utils/qrCode';
import configurationUtils from '../../utils/configurationUtils';
import SingleDetail from '../primitives/singleDetail/SingleDetail';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import { Transition } from '../confirmationDialog/ConfirmationDialog';
import { common } from '../../utils/strings';

// Types
import { DockDoor, DockDoorDetails } from 'cloudsort-client';
import { match } from 'react-router-dom';
import { AxiosError } from 'axios';

// Icons
import FilterCenterFocusIcon from '@material-ui/icons/FilterCenterFocus';
import CreateIcon from '@material-ui/icons/Create';
import { Helmet } from 'react-helmet';
import clsx from 'clsx';
import CSSectionTitleSeparator from '../primitives/csSectionTitleSeparator/CSSectionTitleSeparator';
import useStationId from '../../hooks/useStationId';
import CSDialogAlert from '../primitives/csDialogAlert/CSDialogAlert';
import CSDialogTitleWithIcon from '../primitives/csDialogTitleWithIcon/CSDialogTitleWithIcon';
import { DialogIcons } from '../primitives/csDialogTitleWithIcon/CSDialogTitleWithIconTypes';
import { CSSingleDetailMultiColumnContainer } from '../primitives/singleDetail/singleDetailMultiColumnContainer';
import CSBreadcrumbs from '../primitives/CSBreadcrumbs/CSBreadcrumbs';

interface Props {
  classes: { [key: string]: string };
  match: match;
}

const DockDoorsDetailsComponent: React.FC<Props> = ({
  classes,
  match,
}) => {
  const [dockDoorsData, setDockDoorsData] = useState<DockDoor>();
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [showEditDialog, setShowEditDialog] =
    useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [editName, setEditName] = useState<string | undefined>(
    undefined,
  );
  // It's possible for a user to open dock door page without station id in the url.
  //In this case we need to get it from the dock door data and set it in the url. The web app (navigation bar) will use it to display the station name and show the correct navigation menu.
  useStationId(dockDoorsData?.station);

  const dockDoorLabels = useMemo(() => {
    return {
      singular: configurationUtils.getPageTitle(true, 'DOCK_DOOR'),
      plural: configurationUtils.getPageTitle(false, 'DOCK_DOOR'),
    };
  }, []);

  // Get labels on each render cycle
  const COLUMNS_OUTBOUND_LOADS: Column[] = [
    {
      id: 'id',
      label: 'ID',
      width: 50,
      align: 'left',
    },
    {
      id: 'route_name',
      label: configurationUtils.getPageTitle(true, 'ROUTE'),
      align: 'center',
      width: 150,
    },
    {
      id: 'load_time',
      label: 'Dispatch time',
      align: 'center',
      width: 'auto',
    },
    {
      id: 'load_status',
      label: 'Status',
      align: 'center',
      width: 'auto',
    },
    {
      id: 'dockdoor_name',
      label: dockDoorLabels.singular,
      align: 'center',
      width: 100,
    },
    {
      id: 'containers_loaded_count',
      label: `Loaded ${configurationUtils.getPageTitle(
        false,
        'CONTAINER',
      )}`,
      align: 'center',
      width: 160,
    },
    {
      id: 'packages_loaded_count',
      label: `Loaded ${configurationUtils.getPageTitle(
        false,
        'PACKAGE',
      )}`,
      align: 'center',
      width: 150,
    },
  ];

  const getDockDoorsData = async () => {
    setShowProgress(true);
    try {
      const { data } = await DockDoorsService.getById(
        (match.params as any).id,
      );
      setDockDoorsData(data);
    } catch (e) {
      handleError(e as AxiosError);
    }
    setShowProgress(false);
  };

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e as AxiosError));
  };

  const onGetQrCick = async () => {
    setShowProgress(true);
    try {
      if (dockDoorsData?.id) {
        qrCodeUtils.download(
          await DockDoorsService.getLabel(dockDoorsData.id),
        );
      }
    } catch (e) {
      handleError(e as AxiosError);
    }
    setShowProgress(false);
  };

  const editDockDoor = async () => {
    try {
      setShowProgress(true);
      await DockDoorsService.edit(dockDoorsData!.id!, {
        ...(dockDoorsData as DockDoorDetails),
        name: editName,
      });
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      getDockDoorsData();
      onAfterDialogClose();
      setShowProgress(false);
    }
  };

  const fetchLoads = async (
    pageIndex: number,
    rowsPerPage: number,
  ) => {
    const res = await OutboundLoadsService.getAll({
      page: pageIndex,
      dockdoor: (match.params as any).id,
      pageSize: rowsPerPage,
      fromDate: '',
      toDate: '',
    });
    res.data.results.map((ol: any) => {
      ol.load_time = renderTimestampString(ol.load_time);
      return ol;
    });

    return res;
  };

  const onAfterDialogClose = () => {
    setEditName(undefined);
    setError(undefined);
    setShowEditDialog(false);
  };

  const renderEditDialog = () => {
    return (
      <Dialog
        open={showEditDialog}
        TransitionComponent={Transition}
        onClose={() => {
          onAfterDialogClose();
        }}
      >
        <DialogTitle>
          <CSDialogTitleWithIcon
            icon={DialogIcons.EDIT}
            title={`Edit ${dockDoorLabels.singular} ${dockDoorsData?.name}`}
          />
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {error && (
            <CSDialogAlert alertMessage={error} bottomMargin={20} />
          )}

          <CSTextField
            autoFocus
            label='Name'
            value={editName}
            onChange={(e) => {
              setEditName(e.target.value);
            }}
            containerSize='fullHorizontal'
          />
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <CSButton
            variant='outlined'
            color='secondary'
            data-testid={'route-dialog-cancel'}
            onClick={() => {
              onAfterDialogClose();
            }}
          >
            Cancel
          </CSButton>
          <CSButton
            variant='contained'
            color='secondary'
            onClick={() => {
              editDockDoor();
            }}
            data-testid={'route-dialog-update'}
            disabled={!editName}
          >
            Update
          </CSButton>
        </DialogActions>
      </Dialog>
    );
  };

  useEffect(() => {
    if (!dockDoorsData) {
      getDockDoorsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort -
${dockDoorLabels.singular} details for ${dockDoorsData?.name || ''}`}
        </title>
      </Helmet>
      <Layout navCurrent='DOCK_DOOR'>
        {renderEditDialog()}
        {showProgress && <ProgressIndicator />}

        {error && (
          <CSDialogAlert
            data-testid='dock-doors-error-banner'
            alertMessage={error}
            bottomMargin={20}
          />
        )}

        <Grid
          container
          spacing={2}
          alignItems='center'
          className={classes.marginBottom}
        >
          <Grid item xs={12}>
            <Box mb={2}>
              <CSBreadcrumbs
                breadcrumbs={[
                  {
                    label: dockDoorLabels.plural,
                    link:
                      AuthRoutes.DOCK_DOOR +
                      '?stationId=' +
                      dockDoorsData?.station,
                  },
                  {
                    label: dockDoorsData?.name || '',
                    selected: true,
                  },
                ]}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant='h3' component='h2'>
              {dockDoorsData?.name}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.textRight}>
            <CSButton
              data-testid='edit-dock-door'
              className={classes.sectionButton}
              variant='outlined'
              color='secondary'
              fullWidth={false}
              disabled={!dockDoorsData}
              onClick={(e) => {
                e.preventDefault();
                setError(undefined);
                setEditName(dockDoorsData!.name || '');
                setShowEditDialog(true);
              }}
              startIcon={<CreateIcon />}
            >
              Edit
            </CSButton>
            <CSButton
              className={clsx(
                classes.sectionButton,
                classes.headerFilterButton,
              )}
              data-testid='QR-code'
              variant='contained'
              color='primary'
              fullWidth={false}
              disabled={!dockDoorsData}
              onClick={(e) => {
                e.preventDefault();
                setError(undefined);
                onGetQrCick();
              }}
              startIcon={<FilterCenterFocusIcon />}
            >
              QR Code
            </CSButton>
          </Grid>
        </Grid>
        <CSSectionTitleSeparator />
        <Grid container spacing={2} className={classes.marginBottom}>
          <Grid item xs={12}>
            <CSSingleDetailMultiColumnContainer
              data-testid='dock-doors-detail-view'
              disableStandardHeight
              columns={3}
              elements={[
                <SingleDetail
                  inline={true}
                  label={dockDoorLabels.singular + ' ID'}
                  value={dockDoorsData?.id || common.emptyValue}
                />,
                <SingleDetail
                  inline={true}
                  label='Name'
                  placeholder='Name'
                  value={dockDoorsData?.name || common.emptyValue}
                />,
                <SingleDetail
                  inline={true}
                  label='Station Name'
                  value={
                    dockDoorsData?.station_name || common.emptyValue
                  }
                />,
              ]}
            />
          </Grid>
        </Grid>

        <PaginatedTable
          disableUpdateQueryStringUrl
          title={configurationUtils.getPageTitle(
            false,
            'OUTBOUND_LOAD',
          )}
          columns={COLUMNS_OUTBOUND_LOADS}
          dataTestIdPrefix={'dock-doors-loads'}
          fetch={fetchLoads}
          rowsLoadDetailPages={true}
          detailsPageBasePath={AuthRoutes.OUTBOUND_LOAD}
        />
      </Layout>
    </>
  );
};

export default withStyles(styles)(DockDoorsDetailsComponent);
