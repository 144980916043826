import React, { useState, useEffect } from 'react';
import browserHistory from '../../utils/browserHistory';
import { Route } from 'react-router-dom';
import Auth from '../../services/Auth.service';
import { MARKETING_WEBSITE_URL } from '../../services/utils/constants';

const PrivateRoute: React.FC<any> = ({
  component: Component,
  ...rest
}) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(
    Auth.isLoggedIn(),
  );

  const refreshTokenAndPushToLocation: (
    location: string,
  ) => Promise<void> = async (location: string) => {
    const isTokenRefreshed = await Auth.refreshToken();
    if (isTokenRefreshed) {
      browserHistory.push(location);
    }
    setIsLoggedIn(Auth.isLoggedIn());
  };

  useEffect(() => {
    if (!isLoggedIn) {
      if (Auth.hasRefreshToken()) {
        refreshTokenAndPushToLocation(
          browserHistory.location.pathname,
        );
      } else {
        window.location.href = `${MARKETING_WEBSITE_URL}?redirect=${window.location.href}`;
      }
    }
  }, [isLoggedIn]);

  return (
    <Route
      {...rest}
      render={(props) => isLoggedIn && <Component {...props} />}
    />
  );
};

export default PrivateRoute;
