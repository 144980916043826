import { useState } from 'react';
import {
  Grid,
  Theme,
  Typography,
  createStyles,
  withStyles,
} from '@material-ui/core';
import CSSectionTitleSeparator from '../../../primitives/csSectionTitleSeparator/CSSectionTitleSeparator';
import {
  ConfigurationSchema,
  StationDetails,
  PreDefinedHoldReasonConfigurationSchema,
} from 'cloudsort-client';
import { CSButton, CSSwitch } from '../../../primitives';
import PaginatedTable from '../../../paginatedTable/PaginatedTable';
import { Column } from '../../../../interfaces/components';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import CancelIcon from '@material-ui/icons/Cancel';
import AddHoldingReasonDialog from './AddHoldingReasonDialog';
import capitalize from 'lodash/capitalize';

export interface HoldReasonTableItem {
  code?: string | null;
  reason?: string | null;
  scope?: string | null;
  scope_formatted?: string;
  is_not_predefined: boolean;
  active?: boolean | null;
  active_toggle?: JSX.Element;
}

interface Props {
  classes: { [key: string]: string };
  data: ConfigurationSchema;
  setData: (data: StationDetails['config']) => void;
}

const COLUMNS: Column[] = [
  {
    id: 'code',
    label: 'Code',
    hide: true,
  },
  {
    id: 'reason',
    label: 'Reason',
  },
  {
    id: 'scope_formatted',
    label: 'Scope',
  },
  {
    id: 'active_toggle',
    label: 'Active',
    useCustomComponent: true,
  },
  {
    id: 'is_predefined',
    label: 'Is Predefined',
    hide: true,
  },
];

const HoldingReasons = ({ classes, data, setData }: Props) => {
  const [lastUpdated, setLastUpdated] = useState<string>(
    new Date().toISOString(),
  );

  const [showDialog, setShowDialog] = useState(false);

  const toggleActive = (
    type: 'CUSTOM' | 'PRE_DEFINED',
    code: string,
    value: boolean,
  ) => {
    if (!data?.GENERAL?.HOLD_REASONS?.PRE_DEFINED) return;

    const updatedData = {
      ...data,
      GENERAL: {
        ...data?.GENERAL,
        HOLD_REASONS: {
          ...data?.GENERAL.HOLD_REASONS,
          [type]:
            type === 'CUSTOM'
              ? data?.GENERAL.HOLD_REASONS.CUSTOM?.map((reason) =>
                  reason.code === code
                    ? { ...reason, active: value }
                    : reason,
                )
              : {
                  ...data.GENERAL.HOLD_REASONS.PRE_DEFINED,
                  [code]: {
                    ...(data.GENERAL.HOLD_REASONS.PRE_DEFINED[
                      code as keyof PreDefinedHoldReasonConfigurationSchema
                    ] ?? {}),
                    active: value,
                  },
                },
        },
      },
    };

    setData(updatedData);
  };

  const getTableData = async (
    pageIndex: number,
    rowsPerPage: number,
  ) => {
    const predefined: HoldReasonTableItem[] = Object.entries(
      data?.GENERAL?.HOLD_REASONS?.PRE_DEFINED ?? {},
    )
      .map((part) => part[1])
      .map((reason) => {
        return {
          ...reason,
          is_not_predefined: false,
          scope_formatted: capitalize(reason.scope),
          active_toggle: (
            <CSSwitch
              defaultChecked={reason.active}
              onChange={(e) => {
                toggleActive(
                  'PRE_DEFINED',
                  reason.code,
                  e.target.checked,
                );
              }}
            />
          ),
        };
      });

    const custom: HoldReasonTableItem[] =
      data?.GENERAL?.HOLD_REASONS?.CUSTOM?.map((reason) => {
        return {
          ...reason,
          is_not_predefined: true,
          scope_formatted: capitalize(reason.scope || ''),
          active_toggle: (
            <CSSwitch
              defaultChecked={reason.active ?? false}
              onChange={(e) => {
                toggleActive(
                  'CUSTOM',
                  reason.code || '',
                  e.target.checked,
                );
              }}
            />
          ),
        };
      }) || [];

    const results = {
      data: {
        results: [...predefined, ...custom],
        count: predefined.length + custom.length,
      },
    };

    return results;
  };

  const onSave = (reason?: HoldReasonTableItem) => {
    if (!reason) return;

    setData({
      ...data,
      GENERAL: {
        ...data?.GENERAL,
        HOLD_REASONS: {
          ...data?.GENERAL?.HOLD_REASONS,
          CUSTOM: [
            ...(data?.GENERAL?.HOLD_REASONS?.CUSTOM ?? []),
            {
              code: reason?.code,
              reason: reason?.reason,
              scope: reason?.scope,
              active: reason?.active,
            },
          ],
        },
      },
    });

    setLastUpdated(new Date().toISOString());
    setShowDialog(false);
  };

  const onDelete = (code: string) => {
    setData({
      ...data,
      GENERAL: {
        ...data?.GENERAL,
        HOLD_REASONS: {
          ...data?.GENERAL?.HOLD_REASONS,
          CUSTOM: data?.GENERAL?.HOLD_REASONS?.CUSTOM?.filter(
            (c) => c.code !== code,
          ),
        },
      },
    });
    setLastUpdated(new Date().toISOString());
  };

  return (
    <>
      {showDialog && (
        <AddHoldingReasonDialog
          closeDialog={() => {
            setShowDialog(false);
          }}
          onSave={onSave}
        />
      )}
      <Grid container className={classes.marginTop20}>
        <Grid item xs={12} sm={6}>
          <Typography variant='h3'>Holding Reasons</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          className={classes.nonMobileAlignRight}
        >
          <CSButton
            startIcon={<AddBoxOutlinedIcon />}
            variant='contained'
            color={{
              buttonColor: 'secondary',
              iconColor: 'primary',
            }}
            onClick={() => {
              setShowDialog(true);
            }}
          >
            Add Reason
          </CSButton>
        </Grid>
        <Grid item xs={12}>
          <CSSectionTitleSeparator topMargin={10} bottomMargin={20} />
        </Grid>
        <Grid item xs={12}>
          <PaginatedTable
            key={lastUpdated}
            title=''
            columns={COLUMNS}
            dataTestIdPrefix={'hold-reasons-list'}
            fetch={getTableData}
            groupActions={true}
            actions={[
              {
                cellWidth: 70,
                tableLabel: ' ',
                columnLabel: <CancelIcon />,
                callbackProperty: 'code',
                qualifier: 'is_not_predefined',
                callback: onDelete,
              },
            ]}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    inlineBlock: { display: 'inline-block' },
    marginTop20: {
      marginTop: 20,
    },
    nonMobileAlignRight: {
      [theme.breakpoints.down('xs')]: {
        marginTop: 20,
      },
      [theme.breakpoints.up('sm')]: {
        textAlign: 'right',
      },
    },
  })),
)(HoldingReasons);
