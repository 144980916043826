import { Theme, createStyles } from '@material-ui/core';
import { getDetailsPageStylesWithTheme } from '../commonStyles/detailsPage.style';

export default createStyles((theme: Theme) => ({
  ...getDetailsPageStylesWithTheme(theme),
  padding2em: {
    padding: '2em',
  },
  maxContainer: {
    height: '100%',
  },
  timeContainer: {
    display: 'flex',
  },
  elementsSpacing: {
    marginBottom: 5,
  },
  marginLeft10: {
    marginLeft: 10,
  },
  marginTop20: {
    marginTop: 20,
  },
  elementsSpacingHorizontal: {
    marginRight: 5,
  },
  flexRowEvenSpread: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  half: {
    width: '50%',
    borderRight: `${theme.borderWidth.border_sm} solid ${theme.palette.grey[300]}`,
  },
  containerDetails: {
    display: 'flex',
    flexDirection: 'row',
  },
  tableHeader: {
    background: theme.palette.grey[300],
  },
  nonMobileAlignRight: {
    [theme.breakpoints.down('xs')]: {
      marginTop: 20,
    },
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right',
    },
  },
  checkIcon: {
    margin: '0 9px -5px 9px',
  },
}));
