import { useTheme } from '@material-ui/core';
import {
  InboundLoad,
  InboundLoadLoadStatusEnum,
} from 'cloudsort-client';
import { formatStatusEnum } from './utils/misc';
import { Typography } from '../primitives';
import { renderTimeString } from '../DetailsPagesFunctions';
import { format } from 'date-fns';
import { common } from '../../utils/strings';

interface ExtendedInboundLoad extends InboundLoad {
  inbound_load_time_formatted: JSX.Element;
  load_status_formatted: JSX.Element;
}

const useFormatILTable = () => {
  const theme = useTheme();

  const getInboundStatusColor = (
    status: InboundLoadLoadStatusEnum,
  ) => {
    switch (status) {
      case InboundLoadLoadStatusEnum.PLANNED:
        return theme.palette.grey['A400'];
      default:
        return theme.palette.text.primary;
    }
  };

  const formatStatus = (status?: InboundLoadLoadStatusEnum) => {
    if (!status) return <></>;
    const formattedStatus = formatStatusEnum(status);
    return (
      <Typography
        component='p'
        variant='body2'
        style={{ color: getInboundStatusColor(status) }}
      >
        {formattedStatus}
      </Typography>
    );
  };

  const formatScheduledArrival = (scheduledArrival?: string) => {
    if (!scheduledArrival) return <>{common.emptyValue}</>;

    const isToday =
      new Date().toDateString() ===
      new Date(scheduledArrival).toDateString();
    if (isToday) {
      return (
        <Typography>{renderTimeString(scheduledArrival)}</Typography>
      );
    } else {
      return (
        <Typography>
          {`${renderTimeString(scheduledArrival)} on ${format(
            new Date(scheduledArrival),
            'MM/dd/yyyy',
          )}`}
        </Typography>
      );
    }
  };

  const formatIlItem = (load: InboundLoad) => {
    if (load.load_status === InboundLoadLoadStatusEnum.PLANNED) {
      (load as ExtendedInboundLoad).inbound_load_time_formatted =
        formatScheduledArrival(load.expected_inbound_load_time);
    } else if (
      load.load_status === InboundLoadLoadStatusEnum.ARRIVED
    ) {
      (load as ExtendedInboundLoad).inbound_load_time_formatted =
        formatScheduledArrival(load.arrival_time || '');
    } else if (
      load.load_status === InboundLoadLoadStatusEnum.CHECKED_IN
    ) {
      (load as ExtendedInboundLoad).inbound_load_time_formatted =
        formatScheduledArrival(load.checked_in_time || '');
    }

    (load as ExtendedInboundLoad).load_status_formatted =
      formatStatus(load.load_status);
  };

  return { formatIlItem };
};

export default useFormatILTable;
