import React, { Ref } from 'react';
import SEE_ERROR_MESSAGE from './onboarding/SEE_ERROR_MESSAGE.svg';
import NEW_LABEL_CONFIRMED from './onboarding/NEW_LABEL_CONFIRMED.svg';
import CONFIRM_RELABEL from './onboarding/confirm_relabel.svg';
import CHANGE_ITEM_DETAILS from './onboarding/change_item_details.svg';
import CONFIRM_DISPATCH from './onboarding/confirm_dispatch.svg';
import ITEM_LOCATION_CHANGED from './onboarding/item_location_changed.svg';
import SELECT_FORCE_CHANGE from './onboarding/Select_force_change.svg';
import CHECK_ITEM_DETAILS from './onboarding/check_item_details.svg';
import SPECIFY_ID from './onboarding/SPECIFY_ID.svg';
import DISPATCH_LOAD from './onboarding/DISPATCH_LOAD.svg';
import START_LOADING_NEXT_CONTAINER from './onboarding/START_LOADING_NEXT_CONTAINER.svg';
import CONFIRM_LOADING_THE_CONTAINER from './onboarding/CONFIRM_LOADING_THE_CONTAINER.svg';
import SELECT_NEXT_CONTAINER from './onboarding/SELECT_NEXT_CONTAINER.svg';
import SEE_LIST from './onboarding/SEE_LIST.svg';
import PRINT_LABEL from './onboarding/PRINT_LABEL.svg';
import CLOSE_CONTAINER from './onboarding/CLOSE_CONTAINER.svg';
import DESTINATION from './onboarding/DESTINATION.svg';
import SCAN_PACKAGE_TARGET from './onboarding/SCAN_package_target.svg';
import SCAN_LOADED_ITEM_TO_CONFIRM from './onboarding/scan_loaded_item_to_confirm.svg';
import SCAN_LOCATION from './onboarding/SCAN_LOCATION.svg';
import SCAN_ITEM from './onboarding/SCAN_ITEM.svg';
import MOVE_CONTAINER_TO_LOAD from './onboarding/move_container_to_load.svg';
import MOVE_ITEM_to_DESTINATION from './onboarding/move_ITEM_to_DESTINATION.svg';
import MOVE_CONTAINER_to_DESTINATION from './onboarding/move_CONTAINER_to_DESTINATION.svg';
import MOVE_ITEM_to_LOADPOINT from './onboarding/move_ITEM_to_LOADPOINT.svg';
import MOVE_CONTAINER from './onboarding/move_CONTAINER.svg';
import READY_TO_SCAN_ITEMS from './onboarding/READY_TO_SCAN_ITEMS.svg';
import READY_TO_SCAN_CONTAINERS from './onboarding/READY_TO_SCAN_CONTAINERS.svg';
import READY_TO_SCAN_PACKAGES from './onboarding/READY_TO_SCAN_PACKAGES.svg';

const OnboardingStepImage = React.forwardRef(
  (props: any, ref: Ref<HTMLOrSVGElement>) => {
    const iconIdToIcon = (iconId: string) => {
      if (OnboardingIconRefsMap[iconId]) {
        return OnboardingIconRefsMap[iconId].src;
      }
      return null;
    };
    return (
      <img
        src={iconIdToIcon(props.iconid.toUpperCase().trim())}
        style={{
          width: '46px',
          height: '31px',
        }}
        alt={
          OnboardingIconRefsMap[props.iconid.toUpperCase().trim()]
            .title
        }
        {...props}
        ref={ref}
      />
    );
  },
);
export default OnboardingStepImage;

export const isValidOnboardingIconRef = (ref: string) => {
  return Object.keys(OnboardingIconRefsMap).includes(
    ref.toUpperCase().trim(),
  );
};

export const OnboardingIconRefsMap: {
  [key: string]: {
    title: string;
    src: SVGElement;
  };
} = {
  READY_TO_SCAN_PACKAGES: {
    title: 'Ready to Scan Packages',
    src: READY_TO_SCAN_PACKAGES,
  },
  READY_TO_SCAN_CONTAINERS: {
    title: 'Ready to Scan Containers',
    src: READY_TO_SCAN_CONTAINERS,
  },
  READY_TO_SCAN_ITEMS: {
    title: 'Ready to Scan Items',
    src: READY_TO_SCAN_ITEMS,
  },
  MOVE_CONTAINER: {
    title: 'Move Container',
    src: MOVE_CONTAINER,
  },
  MOVE_ITEM_TO_LOADPOINT: {
    title: 'Move Item to Load Point',
    src: MOVE_ITEM_to_LOADPOINT,
  },
  MOVE_CONTAINER_TO_DESTINATION: {
    title: 'Move Container to Destination',
    src: MOVE_CONTAINER_to_DESTINATION,
  },
  MOVE_ITEM_TO_DESTINATION: {
    title: 'Move Item to Destination',
    src: MOVE_ITEM_to_DESTINATION,
  },
  MOVE_CONTAINER_TO_LOAD: {
    title: 'Move Container to Load',
    src: MOVE_CONTAINER_TO_LOAD,
  },
  SEE_ERROR_MESSAGE: {
    title: 'See Error Message',
    src: SEE_ERROR_MESSAGE,
  },
  NEW_LABEL_CONFIRMED: {
    title: 'New Label Confirmed',
    src: NEW_LABEL_CONFIRMED,
  },
  CONFIRM_RELABEL: {
    title: 'Confirm Relabel',
    src: CONFIRM_RELABEL,
  },
  CHANGE_ITEM_DETAILS: {
    title: 'Change Item Details',
    src: CHANGE_ITEM_DETAILS,
  },
  CONFIRM_DISPATCH: {
    title: 'Confirm Dispatch',
    src: CONFIRM_DISPATCH,
  },
  ITEM_LOCATION_CHANGED: {
    title: 'Item Location Changed',
    src: ITEM_LOCATION_CHANGED,
  },
  SELECT_FORCE_CHANGE: {
    title: 'Select Force Change',
    src: SELECT_FORCE_CHANGE,
  },
  CHECK_ITEM_DETAILS: {
    title: 'Check Item Details',
    src: CHECK_ITEM_DETAILS,
  },
  SPECIFY_ID: {
    title: 'Specify ID',
    src: SPECIFY_ID,
  },
  DISPATCH_LOAD: {
    title: 'Dispatch Load',
    src: DISPATCH_LOAD,
  },
  START_LOADING_NEXT_CONTAINER: {
    title: 'Start Loading Next Container',
    src: START_LOADING_NEXT_CONTAINER,
  },
  CONFIRM_LOADING_THE_CONTAINER: {
    title: 'Confirm Loading the Container',
    src: CONFIRM_LOADING_THE_CONTAINER,
  },
  SELECT_NEXT_CONTAINER: {
    title: 'Select Next Container',
    src: SELECT_NEXT_CONTAINER,
  },
  SEE_LIST: {
    title: 'See List',
    src: SEE_LIST,
  },
  PRINT_LABEL: {
    title: 'Print Label',
    src: PRINT_LABEL,
  },
  CLOSE_CONTAINER: {
    title: 'Close Container',
    src: CLOSE_CONTAINER,
  },
  DESTINATION: {
    title: 'Destination',
    src: DESTINATION,
  },
  SCAN_PACKAGE_TARGET: {
    title: 'Scan Package Target',
    src: SCAN_PACKAGE_TARGET,
  },
  SCAN_LOADED_ITEM_TO_CONFIRM: {
    title: 'Scan Loaded Item to Confirm',
    src: SCAN_LOADED_ITEM_TO_CONFIRM,
  },
  SCAN_LOCATION: {
    title: 'Scan Location',
    src: SCAN_LOCATION,
  },
  SCAN_ITEM: {
    title: 'Scan Item',
    src: SCAN_ITEM,
  },
};
