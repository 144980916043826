import { InputLabelProps } from '@material-ui/core';
import { GroupBase } from 'react-select';
import { AsyncProps } from 'react-select/dist/declarations/src/useAsync';

import { ExtendedPaletteKeys } from '../../../theming/material-ui';

export type CSAsyncSelectSize =
  | 'standard'
  | 'fullHorizontal'
  | 'fullVertical'
  | 'full';

export interface CSAsyncSelectProps<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> extends AsyncProps<Option, IsMulti, Group> {
  id?: string;
  className?: string;
  name?: string;
  color?: ExtendedPaletteKeys;
  variant?: ExtendedAsyncVariants;
  startAdornment?: JSX.Element;
  endAdornment?: JSX.Element;
  label?: string;
  disabled?: boolean;
  error?: boolean;
  onEnter?: (value?: string) => void;
  onClear?: () => void;
  helperText?: string;
  hideClear?: boolean;
  InputLabelProps?: InputLabelProps;
  customContent?: JSX.Element;
  containerSize?: CSAsyncSelectSize;
  'data-testid'?: string;
}
export interface MaterialInputConfiguration {
  formControlVariant: MaterialVariants;
  formLabelVariant: MaterialVariants;
  notched: boolean | undefined;
}
export type MaterialVariants = 'standard' | 'filled' | 'outlined';
export type ExtendedAsyncVariants =
  | 'standard-outlined'
  | 'filled-outlined';
export type TextFieldColor = 'primary' | 'secondary';
export const COMPONENT_NAME = 'CSAsyncSelect';
