import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { AuthRoutes, ModulesKeys } from '../../interfaces/routes';
import Layout from '../layout/Layout';
import {
  NestedProcessStepSerializerPackageProcessStepEnum,
  StaffPermissionPermissionsEnum,
} from 'cloudsort-client';
import PaginatedTable, {
  filterObj,
} from '../paginatedTable/PaginatedTable';
import queryString from 'query-string';
import utils from '../../utils/stats';
import { common } from '../../utils/strings';
import { Column } from '../../interfaces/components';
import fedexLabels from '../../utils/fedexLabels';
import { CSButton, Typography } from '../primitives/index';
import ExportCSVDialog from '../exportCSVDialog/ExportCSVDialog';
import configurationUtils from '../../utils/configurationUtils';
import { Grid } from '@material-ui/core';
import clx from 'classnames';
import FiltersDrawer, {
  SelectedFilters,
} from '../filtersDrawer/FiltersDrawer';

import sanitizeHtml from 'sanitize-html';
import styles from './Packages.styles';

// Services
import PackagesService, {
  DefectsValues,
  PackagesSortByValues,
} from '../../services/Packages.service';
import PermissionsService from '../../services/Permissions.service';

// Icons
import CloudDownloadOutlined from '@material-ui/icons/CloudDownloadOutlined';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Helmet } from 'react-helmet';
import PackagePrediction from './utils/PackagePrediction';
import EphemeralStateService from '../../services/EphemeralState.service';
import { ExtendedPackages } from './PackagesTypes';
import { RouteComponentProps } from 'react-router-dom';
import CSSectionTitleSeparator from '../primitives/csSectionTitleSeparator/CSSectionTitleSeparator';
import CSHorizontalFilterBadgesGroup from '../primitives/csHorizontalFilterBadgesGroup/csHorizontalFilterBadgesGroup';
import { useAppDispatch } from '../../redux/store';
import { setLastVisitedModule } from '../../redux/slices/navigationSlice';

export const GET_COLUMNS_PACKAGES = (): Column[] => {
  return [
    {
      id: 'prediction_icon',
      label: 'Prediction',
      width: 'auto',
      useCustomComponent: true,
      hide: !EphemeralStateService.getMyStationConfiguratation()
        .GENERAL.BEHAVIORS.ML_INTEGRATION.arrival_prediction,
    },
    {
      id: 'tracking_number',
      label: 'Tracking ID',
      width: 150,
    },
    {
      id: 'stop_name',
      label: 'Destination',
      width: 'auto',
    },
    {
      id: 'zipcode_with_country',
      label: 'ZIP Code',
      width: 100,
    },
    {
      id: 'id',
      label: 'ID',
      width: 'auto',
    },

    {
      id: 'process_step_label',
      label: 'Process Step',

      width: 'auto',
    },
    {
      id: 'dwell_time',
      label: 'Dwell time',
      width: 'auto',
    },
    {
      id: 'sort_param',
      label: 'Sortation Parameter',
      width: 'auto',
    },
    {
      id: 'last_event',
      label: 'Last Event',
      width: 'auto',
    },
    {
      id: 'origin_station_name',
      label: 'Origin Station',
      width: 'auto',
    },
    {
      id: 'hold_reasons_count',
      label: 'Holding Reasons',
      width: 'auto',
      useCustomComponent: true,
      hide: !configurationUtils.isModuleActive('HELD_PACKAGE'),
    },
  ];
};
export const fetch = async (
  pageIndex: number,
  rowsPerPage: number,
  filters: SelectedFilters,
  sortedBy?: string,
  options?: Record<string, unknown>,
) => {
  let sortByVal = sortedBy;
  if (sortByVal?.includes('fmc_full_name')) {
    sortByVal = sortByVal.replace('fmc_full_name', 'fmc__first_name');
  }
  if (sortByVal?.includes('last_event')) {
    sortByVal = sortByVal.replace('last_event', 'process_step_time');
  }

  const isManifested =
    filters.manifested && filters.manifested.values.length
      ? filters.manifested.values[0].id === 'yes'
        ? true
        : filters.manifested.values[0].id === 'no'
        ? false
        : undefined
      : undefined;

  const isScanned =
    filters.scanned && filters.scanned.values.length
      ? filters.scanned.values[0].id === 'yes'
        ? true
        : filters.scanned.values[0].id === 'no'
        ? false
        : undefined
      : undefined;

  const isArrived =
    filters.scanned && filters.arrived.values.length
      ? filters.arrived.values[0].id === 'yes'
        ? true
        : filters.arrived.values[0].id === 'no'
        ? false
        : undefined
      : undefined;

  const inSystem =
    filters.in_system && filters.in_system.values.length
      ? filters.in_system.values[0].id === 'yes'
        ? true
        : filters.in_system.values[0].id === 'no'
        ? false
        : undefined
      : undefined;

  const inHold =
    filters.in_hold && filters.in_hold.values.length
      ? filters.in_hold.values[0].id === 'yes'
        ? true
        : filters.in_hold.values[0].id === 'no'
        ? false
        : undefined
      : undefined;

  const hidePending =
    filters.hidePending && filters.hidePending.values.length
      ? filters.hidePending.values[0].id === 'yes'
        ? true
        : filters.hidePending.values[0].id === 'no'
        ? false
        : undefined
      : undefined;

  const firstScannedInWindow =
    filters.packageScannedToday &&
    filters.packageScannedToday.values.length
      ? filters.packageScannedToday.values[0].id === 'yes'
        ? true
        : filters.packageScannedToday.values[0].id === 'no'
        ? false
        : undefined
      : undefined;

  const res = await PackagesService.getAll({
    page: pageIndex,
    pageSize: rowsPerPage,
    sortBy: (sortByVal as PackagesSortByValues) || undefined,
    owner:
      filters.owner && filters.owner.values.length
        ? filters.owner.values.map((item) => Number(item.id))
        : undefined,
    carrier:
      filters.carrier && filters.carrier.values.length
        ? filters.carrier.values.map((item) => Number(item.id))
        : undefined,
    route:
      filters.route && filters.route.values.length
        ? filters.route.values.map((item) => Number(item.id))
        : undefined,
    destination:
      filters.stop && filters.stop.values.length
        ? filters.stop.values.map((item) => Number(item.id))
        : undefined,
    area:
      filters.area && filters.area.values.length
        ? (filters.area.values[0].id as number)
        : undefined,
    processStep:
      filters.processStep && filters.processStep.values.length
        ? (filters.processStep.values.map((item) => item.id) as [
            NestedProcessStepSerializerPackageProcessStepEnum,
          ])
        : undefined,
    isManifested,
    isScanned,
    isArrived,
    inSystem,
    inHold,
    hidePending,
    firstScannedInWindow,
    defects:
      filters.packageDefect && filters.packageDefect.values.length
        ? (filters.packageDefect.values.map(
            (item) => item.id,
          ) as DefectsValues)
        : undefined,
    ...options,
  });

  res.data.results.forEach((pck: ExtendedPackages) => {
    pck.prediction_icon = (
      <PackagePrediction prediction={pck.predicted_arrival} />
    );

    pck.tracking_number =
      pck.tracking_number_alias ||
      pck.tracking_number ||
      common.emptyValue;

    pck.process_step_label = pck.process_step
      ? fedexLabels.getLabel(pck.process_step)
      : common.emptyValue;

    pck.sort_param = pck.sort_param
      ? sanitizeHtml(pck.sort_param, {
          allowedTags: [],
        })
      : common.emptyValue;

    pck.dwell_time = pck.last_process_step_time
      ? utils.getLongestDwellTimeLabel(pck.last_process_step_time)
      : common.emptyValue;
    pck.hold_reasons_count =
      (pck.hold_for?.total || 0) > 0 ? (
        <Typography
          color={
            (pck.hold_for?.unresolved || 0) > 0 ? 'error' : undefined
          }
        >
          {pck.hold_for?.resolved || 0} / {pck.hold_for?.total || 0}
        </Typography>
      ) : (
        <>{common.emptyValue}</>
      );

    pck.zipcode_with_country = `${pck.zipcode} ${
      pck.consignee_address_country
        ? `(${pck.consignee_address_country})`
        : ''
    }`;

    if (pck.last_event_data?.event_type) {
      const eventLabel = fedexLabels.getLabel(
        (pck.last_event = pck.last_event_data?.event_type),
      );
      if (pck.last_event_data?.user_full_name) {
        pck.last_event =
          eventLabel + ' by ' + pck.last_event_data?.user_full_name;
      } else {
        pck.last_event = eventLabel;
      }
    } else {
      pck.last_event = common.emptyValue;
    }
  });

  return res;
};

export interface Props extends RouteComponentProps {
  classes: { [key: string]: string };
}
export interface IProcessStep {
  id: string;
  label: string;
  active: boolean;
}
const Packages: React.FC<Props> = ({ classes, location }) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [batchExportEnabled, setBatchExportEnabled] =
    useState<boolean>(false);

  const [showFiltersDrawer, setShowFiltersDrawer] = useState(false);
  const [selectedFilters, setSelectedFilters] =
    useState<SelectedFilters>();
  const [filterToRemove, setFilterToRemove] = useState<{
    filterKey: string;
    filterValue: string | number;
  }>();
  const [filterLastUpdate, setFilterLastUpdate] = useState<number>(0);

  const dispatch = useAppDispatch();

  const fetchPackages = async (
    pageIndex: number,
    rowsPerPage: number,
    filterOptions?: filterObj[],
    filterByString?: string,
    sortedBy?: string,
  ) => {
    const res = await fetch(
      pageIndex,
      rowsPerPage,
      selectedFilters || {},
      sortedBy,
      { trackingNumber: filterByString },
    );
    if (res.data.count > 0) {
      setBatchExportEnabled(true);
    } else {
      setBatchExportEnabled(false);
    }
    return res;
  };

  const getDrawerFilters = (filters: SelectedFilters) => {
    setSelectedFilters(filters);
    setFilterLastUpdate(Date.now());
  };

  useEffect(() => {
    PermissionsService.redirectIfNoPermission(
      StaffPermissionPermissionsEnum.PACKAGE_READ,
    );

    dispatch(
      setLastVisitedModule({
        module: ModulesKeys.PACKAGE,
        permission: StaffPermissionPermissionsEnum.PACKAGE_READ,
      }),
    );
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort -
      ${configurationUtils.getPageTitle(false, 'PACKAGE')} ${
            queryString.parse(location.search)['page']
              ? '- Page ' + queryString.parse(location.search)['page']
              : ''
          }`}
        </title>
      </Helmet>
      <Layout navCurrent='PACKAGE'>
        <ExportCSVDialog
          exportFilters={{}} // Packages export doesn't require filters.
          key={'dialog' + filterLastUpdate}
          type='PACKAGES'
          isOpen={showDialog}
          onAfterClose={() => {
            setShowDialog(false);
          }}
          packageFilters={selectedFilters}
        />
        <FiltersDrawer
          isOpen={showFiltersDrawer}
          onAfterClose={() => {
            setShowFiltersDrawer(false);
          }}
          getFilters={getDrawerFilters}
          removeFilter={filterToRemove}
        />
        <Grid container className={classes.header}>
          <Grid container item xs={12} sm={6} alignItems='center'>
            <Grid item>
              <Typography variant='h3' component='h1'>
                {configurationUtils.getPageTitle()}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            className={classes.headerButtonContainer}
          >
            {PermissionsService.hasPermission(
              StaffPermissionPermissionsEnum.PACKAGE_REPORT_READ,
            ) && (
              <CSButton
                variant='contained'
                className={classes.sectionButton}
                disabled={!batchExportEnabled}
                onClick={() => {
                  setShowDialog(true);
                }}
                startIcon={<CloudDownloadOutlined />}
              >
                Export CSVs
              </CSButton>
            )}
            <CSButton
              variant='outlined'
              color='secondary'
              className={clx(
                classes.sectionButton,
                classes.headerFilterButton,
              )}
              onClick={() => {
                setShowFiltersDrawer(true);
              }}
            >
              <FilterListIcon />
            </CSButton>
          </Grid>
          <CSSectionTitleSeparator bottomMargin={24} />
          <CSHorizontalFilterBadgesGroup
            selectedFilters={selectedFilters}
            onRemoveFilter={(toRemove) => setFilterToRemove(toRemove)}
          />
        </Grid>
        {!!filterLastUpdate && (
          <PaginatedTable
            key={'table' + filterLastUpdate}
            title={''}
            columns={GET_COLUMNS_PACKAGES()}
            dataTestIdPrefix={'packages'}
            filterByString={true}
            filterByStringPlaceholder={'Search by Tracking ID'}
            fetch={fetchPackages}
            rowsLoadDetailPages={true}
            detailsPageBasePath={AuthRoutes.PACKAGE}
            defaultSort={
              (queryString.parse(location.search)[
                'sortBy'
              ] as string) || '-last_event'
            }
            sortableBy={['current_load', 'last_event']}
          />
        )}
      </Layout>
    </>
  );
};

export default withStyles(styles, { name: 'CsPackages' })(Packages);
