import { createStyles, Theme } from '@material-ui/core/styles';

export default createStyles((theme: Theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    marginBottom: 10,
    boxShadow: theme.shadows[2],
  },
  root: {},
  button: {
    float: 'right',
  },
  bigNumber: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  borderDividers: {
    '& $singleStat': {
      borderRight: `${theme.borderWidth.border_sm} solid ${theme.palette.grey[300]}`,
      [theme.breakpoints.down('sm')]: {
        borderBottom: `${theme.borderWidth.border_sm} solid ${theme.palette.grey[300]}`,
      },
    },
    '& $singleStat:last-child': {
      borderRight: 'none',
      [theme.breakpoints.down('sm')]: {
        borderRight: `${theme.borderWidth.border_sm} solid ${theme.palette.grey[300]}`,
      },
    },
  },
  titleSeparation: {
    marginBottom: '5px',
  },
  activeEventTypeMarker: {
    fontSize: '3em',
    position: 'absolute',
    margin: '-34px 0 0 -0.5em',
    color: theme.palette.tertiary?.main,
  },
  singleStat: {
    flexGrow: 1,
    minHeight: 88,
    padding: '16px 16px 2px 16px',
  },
  singleStatLink: {
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));
