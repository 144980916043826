import React, {
  useEffect,
  useState,
  Fragment,
  useCallback,
  useMemo,
} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { CSButton, CSTextField, Typography } from '../../primitives';
import { withStyles } from '@material-ui/core/styles';
import ConfirmationDialog from '../../confirmationDialog/ConfirmationDialog';
import ProgressIndicator from '../../progressIndicator/ProgressIndicator';
import {
  Grid,
  DialogTitle,
  createStyles,
  RadioGroup,
  FormControlLabel,
  Radio,
  Theme,
} from '@material-ui/core';
import detailsPageStyles from '../../commonStyles/detailsPage.style';
import ErrorHandler from '../../../utils/ErrorHandler';
import { formatFirstNItemsAndMore } from '../../DetailsPagesFunctions';
import sanitizeHtml from 'sanitize-html';
import debounce from 'lodash/debounce';
import { common } from '../../../utils/strings';
//Types
import { AxiosError } from 'axios';
import {
  APIException,
  APIExceptionErrorCodeEnum,
  Customer,
  SchemeDetails,
  User,
} from 'cloudsort-client';
import { MAX_PAGE_SIZE } from '../../../services/utils/constants';
// Services
import SchemesService from '../../../services/Schemes.service';

//Icons
import SettingsIcon from '@material-ui/icons/Settings';
import AddToPhotosOutlinedIcon from '@material-ui/icons/AddToPhotosOutlined';
import CancelIcon from '@material-ui/icons/Cancel';
import { noOptionsMessage } from '../../asyncSelect/utils';
import { TypeAheadItem } from '../../../interfaces/components';
import CustomersService from '../../../services/Customers.service';
import FmcService from '../../../services/Fmc.service';
import EphemeralStateService from '../../../services/EphemeralState.service';
import CSDialogTitleWithIcon from '../../primitives/csDialogTitleWithIcon/CSDialogTitleWithIcon';
import { DialogIcons } from '../../primitives/csDialogTitleWithIcon/CSDialogTitleWithIconTypes';
import CSDialogAlert from '../../primitives/csDialogAlert/CSDialogAlert';
import CSAsyncSelect from '../../primitives/csAsyncSelect/CSAsyncSelect';
import { CSMonoGridContainer } from '../../primitives/csMonoGridContainer';
import clsx from 'clsx';
import { GroupBase, OptionsOrGroups } from 'react-select';
import { onlyNumbers } from '../../../utils/inputHelpers';
import { CSCountrySelect } from '../../primitives/csCountrySelect';
import {
  ZipcodesArray,
  zipcodesArrayToObject,
  zipcodesObjectToArray,
} from '../utils';

interface Props {
  classes: { [key: string]: string };
  onAfterClose: () => void;
  isOpen: boolean;
  updateParent: () => void;
  editSchemeData: SchemeDetails;
}

enum EditSchemeState {
  LIST = 'LIST',
  ADD = 'ADD',
  EDIT = 'EDIT',
}

const EditSchemeDialog: React.FC<Props> = ({
  isOpen,
  classes,
  onAfterClose,
  updateParent,
  editSchemeData,
}) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [editSchemeState, setEditSchemeState] =
    useState<EditSchemeState>(EditSchemeState.LIST);

  const [schemeCurrentlyEditing, setSchemeCurrentlyEditing] =
    useState<SchemeDetails>(editSchemeData);
  const [
    zipCodeIndexCurrentlyEditing,
    setZipCodeIndexCurrentlyEditing,
  ] = useState<number>();
  const [zipcodesArray, setZipcodesArray] = useState<ZipcodesArray>(
    [],
  );
  const [
    zipCodeValuesCurrentlyEditing,
    setZipCodeValuesCurrentlyEditing,
  ] = useState<{ country: string; zipcodes: string[] }>({
    country: 'US',
    zipcodes: ['', ''],
  });
  const [zipCodesMode, setZipCodesMode] = useState<string>('single');

  const [
    showMoveZipCodeConfirmation,
    setShowMoveZipCodeConfirmation,
  ] = useState<boolean>(false);

  const schemeLabels = {
    singular: 'Scheme',
    plural: 'Schemes',
  };

  const zipcodesAlertMessage = useMemo(() => {
    if (zipCodeValuesCurrentlyEditing.country !== 'US')
      // no validation for non-US zipcodes
      return '';

    if (zipCodeValuesCurrentlyEditing.zipcodes.length === 0)
      return '';

    if (
      zipCodeValuesCurrentlyEditing?.zipcodes.some((zipcode) => {
        return zipcode && ![3, 5, 9, 11].includes(zipcode.length);
      })
    )
      return 'ZIP Codes must have 3, 5, 9 or 11 digits.';

    if (zipCodesMode === 'range') {
      if (
        zipCodeValuesCurrentlyEditing.zipcodes[0].length !==
        zipCodeValuesCurrentlyEditing.zipcodes[1].length
      )
        return 'Both ZIP codes in range must have the same number of digits.';

      if (
        Number(zipCodeValuesCurrentlyEditing.zipcodes[0]) >=
        Number(zipCodeValuesCurrentlyEditing.zipcodes[1])
      )
        return 'End ZIP code must be higher than start zip code';
    }
  }, [zipCodeValuesCurrentlyEditing, zipCodesMode]);

  const zipCodeEditingDisabled = useMemo(
    () =>
      zipCodeValuesCurrentlyEditing.country !== 'US' // no validation for non-US zipcodes
        ? zipCodeValuesCurrentlyEditing.zipcodes![0].length === 0
        : zipCodesMode === 'single'
        ? ![3, 5, 9, 11].includes(
            zipCodeValuesCurrentlyEditing.zipcodes![0].length,
          )
        : ![3, 5, 9, 11].includes(
            zipCodeValuesCurrentlyEditing.zipcodes![0].length,
          ) ||
          zipCodeValuesCurrentlyEditing!.zipcodes[0].length !==
            zipCodeValuesCurrentlyEditing!.zipcodes[1].length ||
          Number(zipCodeValuesCurrentlyEditing!.zipcodes[0]) >=
            Number(zipCodeValuesCurrentlyEditing!.zipcodes[1]),
    [zipCodeValuesCurrentlyEditing, zipCodesMode],
  );

  const handleClose = () => {
    setError(undefined);
    setEditSchemeState(EditSchemeState.LIST);
    onAfterClose();
  };

  const handleError = async (e: AxiosError) => {
    const errorData = e.response?.data as APIException;
    if (
      errorData?.error_code ===
      APIExceptionErrorCodeEnum.RESOURCE_CONFLICT
    ) {
      const conflictingZipcodes =
        ErrorHandler.getConflictingZipcodes(errorData);
      setError(
        'The following Zip Codes are duplicated in the Scheme Set: ' +
          formatFirstNItemsAndMore(conflictingZipcodes, 10), //show first 10
      );
    } else setError(await ErrorHandler.getLabel(e as AxiosError));
  };

  const totalNumberOfZipcodes = useMemo(() => {
    return zipcodesArray.reduce((acc, item) => {
      if (item.country === 'US' && item.zipcodes.length === 2) {
        acc += Number(item.zipcodes[1]) - Number(item.zipcodes[0]);
      }
      acc++;

      return acc;
    }, 0);
  }, [zipcodesArray]);

  const processUpdateScheme = async (moveZipCode = false) => {
    setShowProgress(true);

    try {
      const updatedScheme = {
        ...schemeCurrentlyEditing,
        zipcodes: zipcodesArrayToObject(zipcodesArray),
      };

      if (moveZipCode) {
        await SchemesService.update(updatedScheme, true);
        setShowMoveZipCodeConfirmation(false);
      } else {
        await SchemesService.update(updatedScheme);
      }

      updateParent();
      handleClose();
    } catch (e) {
      if (
        (e as any).response.data?.error_code ===
        APIExceptionErrorCodeEnum.RESOURCE_CONFLICT
      ) {
        setShowMoveZipCodeConfirmation(true);
      } else handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (editSchemeData) {
      setSchemeCurrentlyEditing(editSchemeData);
      setZipcodesArray(
        zipcodesObjectToArray(editSchemeData.zipcodes),
      );
    }
  }, [editSchemeData]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadCustomerOptions = useCallback(
    debounce(
      (
        inputValue: string,
        callback: (
          options: OptionsOrGroups<
            TypeAheadItem<string>,
            GroupBase<TypeAheadItem<string>>
          >,
        ) => void,
      ) => {
        CustomersService.getAll(
          undefined,
          inputValue,
          MAX_PAGE_SIZE,
          EphemeralStateService.getMyStationId(),
          EphemeralStateService.getMyOrgId(),
        )
          .then((data) => {
            callback(
              data.data.results.map((dataEl: Customer) => {
                return {
                  value: dataEl.id?.toString() || '',
                  label: dataEl.identifier || '',
                };
              }),
            );
          })
          .catch((e) => {
            handleError(e as AxiosError);
          });
      },
      500,
    ),
    [],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadFmcOptions = useCallback(
    debounce(
      (
        inputValue: string,
        callback: (
          options: OptionsOrGroups<
            TypeAheadItem<string>,
            GroupBase<TypeAheadItem<string>>
          >,
        ) => void,
      ) => {
        FmcService.search(inputValue)
          .then((data) => {
            callback(
              data.data.results.map((dataEl: User) => {
                return {
                  value: dataEl.id?.toString() || '',
                  label: dataEl.full_name || '',
                };
              }),
            );
          })
          .catch((e) => {
            handleError(e as AxiosError);
          });
      },
      500,
    ),
    [],
  );
  return (
    <>
      {showProgress && <ProgressIndicator />}
      <Dialog open={open}>
        <DialogTitle>
          {error && <CSDialogAlert alertMessage={error} />}
          <CSDialogTitleWithIcon
            icon={DialogIcons.EDIT}
            title={`Edit ${schemeCurrentlyEditing?.name} ${schemeLabels.singular}`}
          />
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {editSchemeState === EditSchemeState.LIST && (
            <>
              <CSMonoGridContainer>
                <CSTextField
                  label='Sortation Parameter'
                  placeholder='Sortation Parameter'
                  containerSize='fullHorizontal'
                  value={
                    schemeCurrentlyEditing?.sort_param
                      ? sanitizeHtml(
                          schemeCurrentlyEditing.sort_param ||
                            common.emptyValue,
                          {
                            allowedTags: [],
                          },
                        )
                      : ''
                  }
                  onChange={(e) => {
                    setSchemeCurrentlyEditing({
                      ...schemeCurrentlyEditing,
                      sort_param: e.target.value,
                    });
                  }}
                />
                <CSAsyncSelect<TypeAheadItem>
                  isClearable
                  cacheOptions
                  containerSize='fullHorizontal'
                  label='Carrier'
                  loadOptions={loadFmcOptions}
                  onChange={(option) => {
                    setSchemeCurrentlyEditing({
                      ...schemeCurrentlyEditing,
                      fmc: option
                        ? Number((option as TypeAheadItem).value)
                        : null,
                      fmc_full_name: option
                        ? (option as TypeAheadItem).label
                        : undefined,
                    });
                  }}
                  value={
                    schemeCurrentlyEditing?.fmc
                      ? {
                          label:
                            schemeCurrentlyEditing?.fmc_full_name ||
                            '',
                          value: String(schemeCurrentlyEditing?.fmc),
                        }
                      : null
                  }
                  isDisabled={!!error}
                  placeholder='Start Typing...'
                  menuPortalTarget={document.body}
                  noOptionsMessage={noOptionsMessage}
                />
                <CSAsyncSelect<TypeAheadItem>
                  label='Owner'
                  isClearable
                  containerSize='fullHorizontal'
                  cacheOptions
                  loadOptions={loadCustomerOptions}
                  onChange={(option) => {
                    setSchemeCurrentlyEditing({
                      ...schemeCurrentlyEditing,
                      owner: option
                        ? Number((option as TypeAheadItem).value)
                        : null,
                      owner_identifier: option
                        ? (option as TypeAheadItem).label
                        : undefined,
                    });
                  }}
                  value={
                    schemeCurrentlyEditing?.owner
                      ? {
                          label:
                            schemeCurrentlyEditing?.owner_identifier ||
                            '',
                          value: String(
                            schemeCurrentlyEditing?.owner,
                          ),
                        }
                      : null
                  }
                  isDisabled={!!error}
                  placeholder='Start Typing...'
                  menuPortalTarget={document.body}
                  noOptionsMessage={noOptionsMessage}
                />
                <Typography variant='h6'>ZIP Codes</Typography>
                {zipcodesArray.length === 0 ? (
                  <CSDialogAlert
                    alertMessage={'This scheme has no ZIP Codes'}
                  />
                ) : (
                  <></>
                )}
              </CSMonoGridContainer>
              <Grid item xs={12} container>
                {zipcodesArray.map((zipcode, index) => {
                  return (
                    <Fragment key={index}>
                      <Grid
                        className={classes.zipCodesRow}
                        item
                        xs={12}
                        sm={6}
                      >
                        <Typography
                          variant='body1'
                          className={classes.zipCodeTypography}
                        >
                          {zipcode.zipcodes
                            .filter((zipcode) => zipcode)
                            .join('-')}{' '}
                          ({zipcode.country})
                        </Typography>
                      </Grid>
                      <Grid
                        className={clsx(
                          classes.zipCodesRow,
                          classes.textAlignRight,
                        )}
                        item
                        xs={12}
                        sm={6}
                      >
                        <CSButton
                          color='quaternary'
                          variant='text'
                          onClick={() => {
                            setZipCodeIndexCurrentlyEditing(index);
                            setZipCodeValuesCurrentlyEditing(zipcode);
                            if (
                              zipcode.zipcodes.filter(
                                (zipcode) => zipcode,
                              ).length > 1
                            ) {
                              setZipCodesMode('range');
                            } else {
                              setZipCodesMode('single');
                            }
                            setEditSchemeState(EditSchemeState.EDIT);
                          }}
                          startIcon={<SettingsIcon />}
                        >
                          Edit
                        </CSButton>

                        <CSButton
                          color='quaternary'
                          onClick={() => {
                            setZipcodesArray((prev) =>
                              prev.filter((_, i) => i !== index),
                            );
                          }}
                          startIcon={<CancelIcon />}
                        >
                          Remove
                        </CSButton>
                      </Grid>
                    </Fragment>
                  );
                })}
              </Grid>
              {totalNumberOfZipcodes > 0 && (
                <Grid item xs={12} className={classes.textAlignRight}>
                  <Typography
                    variant='body1'
                    className={classes.zipCodeTypography}
                  >
                    Total ZIP Codes{' '}
                    <span className={classes.totalZipCount}>
                      {totalNumberOfZipcodes}
                    </span>
                  </Typography>
                </Grid>
              )}
            </>
          )}
          {editSchemeState !== EditSchemeState.LIST && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <RadioGroup
                  className={classes.modeSelectionRadio}
                  value={zipCodesMode}
                  onChange={(e) => {
                    setZipCodesMode(e.target.value);
                    // if (e.target.value === 'single')
                    setZipCodeValuesCurrentlyEditing({
                      ...zipCodeValuesCurrentlyEditing,
                      zipcodes:
                        e.target.value === 'single'
                          ? [
                              zipCodeValuesCurrentlyEditing!
                                .zipcodes[0],
                            ]
                          : zipCodeValuesCurrentlyEditing.zipcodes
                              .length === 1
                          ? [
                              zipCodeValuesCurrentlyEditing
                                .zipcodes[0],
                              '',
                            ]
                          : zipCodeValuesCurrentlyEditing.zipcodes,
                    });
                  }}
                >
                  <FormControlLabel
                    value='single'
                    control={<Radio color='primary' />}
                    label='Single ZIP Code'
                  />
                  {zipCodeValuesCurrentlyEditing.country === 'US' && (
                    <FormControlLabel
                      value='range'
                      control={<Radio color='primary' />}
                      label='Range of ZIP Codes'
                    />
                  )}
                </RadioGroup>
              </Grid>

              <Grid item xs={12}>
                <CSCountrySelect
                  label='Country'
                  value={zipCodeValuesCurrentlyEditing.country}
                  setValue={(value) => {
                    setZipCodeValuesCurrentlyEditing((prev) => {
                      return {
                        country: value,
                        zipcodes: prev.zipcodes.fill(''), // reset value when country is changed
                      };
                    });

                    if (value !== 'US') {
                      setZipCodesMode('single');
                    }
                  }}
                />
              </Grid>

              <Grid item xs={zipCodesMode === 'range' ? 6 : 12}>
                <CSTextField
                  autoFocus
                  label='Start ZIP Code'
                  containerSize='fullHorizontal'
                  value={
                    zipCodeValuesCurrentlyEditing.zipcodes &&
                    zipCodeValuesCurrentlyEditing.zipcodes[0]
                  }
                  onChange={(event) => {
                    const isUS =
                      zipCodeValuesCurrentlyEditing.country === 'US';
                    const { value } = event?.target;
                    zipCodeValuesCurrentlyEditing!.zipcodes.length > 1
                      ? setZipCodeValuesCurrentlyEditing({
                          ...zipCodeValuesCurrentlyEditing,
                          zipcodes: [
                            isUS ? onlyNumbers(value) : value,
                            zipCodeValuesCurrentlyEditing.zipcodes![1],
                          ],
                        })
                      : setZipCodeValuesCurrentlyEditing({
                          ...zipCodeValuesCurrentlyEditing,
                          zipcodes: [
                            isUS ? onlyNumbers(value) : value,
                            '',
                          ],
                        });
                  }}
                />
              </Grid>
              {zipCodesMode === 'range' && (
                <Grid item xs={6}>
                  <CSTextField
                    label='End ZIP Code'
                    containerSize='fullHorizontal'
                    value={
                      zipCodeValuesCurrentlyEditing &&
                      zipCodeValuesCurrentlyEditing.zipcodes[1]
                    }
                    onChange={(event) => {
                      const isUS =
                        zipCodeValuesCurrentlyEditing.country ===
                        'US';
                      const { value } = event?.target;
                      setZipCodeValuesCurrentlyEditing({
                        ...zipCodeValuesCurrentlyEditing,
                        zipcodes: [
                          zipCodeValuesCurrentlyEditing.zipcodes![0],
                          isUS ? onlyNumbers(value) : value,
                        ],
                      });
                    }}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                {zipcodesAlertMessage && (
                  <CSDialogAlert
                    alertMessage={zipcodesAlertMessage}
                  />
                )}
              </Grid>
            </Grid>
          )}
        </DialogContent>
        {editSchemeState === EditSchemeState.LIST && (
          <DialogActions className={classes.dialogActions}>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <CSButton
                  variant='outlined'
                  color='secondary'
                  onClick={() => {
                    setEditSchemeState(EditSchemeState.ADD);
                    setZipCodesMode('single');
                    setZipCodeValuesCurrentlyEditing({
                      country: 'US',
                      zipcodes: ['', ''],
                    });
                  }}
                  startIcon={<AddToPhotosOutlinedIcon />}
                >
                  Add ZIP Codes
                </CSButton>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                className={classes.textAlignRight}
              >
                <CSButton
                  variant='outlined'
                  color='secondary'
                  className={classes.cancelButton}
                  data-testid={'scheme-edit-dialog-add-zip-codes'}
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Cancel
                </CSButton>

                <CSButton
                  variant='contained'
                  color='secondary'
                  data-testid={'scheme-edit-dialog-save'}
                  disabled={false}
                  onClick={() => {
                    processUpdateScheme();
                  }}
                >
                  Save
                </CSButton>
              </Grid>
            </Grid>
          </DialogActions>
        )}
        {editSchemeState === EditSchemeState.ADD && (
          <DialogActions className={classes.dialogActions}>
            <CSButton
              variant='outlined'
              color='secondary'
              data-testid={'scheme-edit-dialog-add-zipcode-cancel'}
              className={classes.cancelButton}
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </CSButton>
            <CSButton
              variant='contained'
              color='secondary'
              data-testid={'scheme-edit-dialog-add-zipcode-save'}
              disabled={zipCodeEditingDisabled}
              onClick={() => {
                setZipcodesArray((prev) => [
                  ...prev,
                  zipCodeValuesCurrentlyEditing,
                ]);
                setEditSchemeState(EditSchemeState.LIST);
              }}
            >
              Add
            </CSButton>
          </DialogActions>
        )}
        {editSchemeState === EditSchemeState.EDIT && (
          <DialogActions className={classes.dialogActions}>
            <CSButton
              variant='outlined'
              color='secondary'
              data-testid={'scheme-edit-dialog-edit-zipcode-cancel'}
              className={classes.cancelButton}
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </CSButton>

            <CSButton
              variant='contained'
              color='secondary'
              data-testid={'scheme-edit-dialog-edit-zipcode-save'}
              disabled={zipCodeEditingDisabled}
              onClick={() => {
                setZipcodesArray((prev) =>
                  prev.map((item, index) => {
                    return index === zipCodeIndexCurrentlyEditing
                      ? zipCodeValuesCurrentlyEditing
                      : item;
                  }),
                );
                setEditSchemeState(EditSchemeState.LIST);
              }}
            >
              Edit
            </CSButton>
          </DialogActions>
        )}
      </Dialog>
      <ConfirmationDialog
        data-testid={'move-zip-code-confirmation'}
        title={
          'Some ZIP Codes are already part of the other ' +
          schemeLabels.singular
        }
        msg={`Are you sure you want to move conflicting ZIP Codes to ${schemeLabels.singular} ${schemeCurrentlyEditing?.name}?`}
        primaryActionLabel={'Move'}
        onPrimaryAction={() => {
          processUpdateScheme(true);
        }}
        cancelLabel={'Discard Changes'}
        onCancel={() => {
          handleClose();
        }}
        isOpen={showMoveZipCodeConfirmation}
      />
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    dialogContent: { width: '500px', maxWidth: '100%' },
    cancelButton: {
      marginRight: 10,
    },
    textAlignRight: {
      textAlign: 'right',
    },
    modeSelectionRadio: {
      textAlign: 'center',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    zipCodesRow: {
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      padding: '3px 0',
    },
    dialogActions: {
      padding: 24,
    },
    zipCodeTypography: {
      padding: '8px 0',
    },
    totalZipCount: {
      marginLeft: 20,
      fontWeight: 'bold',
    },
  })),
)(EditSchemeDialog);
