import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  createStyles,
  withStyles,
} from '@material-ui/core';
import CSDialogTitleWithIcon from '../../../primitives/csDialogTitleWithIcon/CSDialogTitleWithIcon';
import { DialogIcons } from '../../../primitives/csDialogTitleWithIcon/CSDialogTitleWithIconTypes';
import { CSMonoGridContainer } from '../../../primitives/csMonoGridContainer';
import { CSButton, CSTextField } from '../../../primitives';
import CSSelect from '../../../primitives/csSelect/CSSelect';

import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { HoldReasonTableItem } from './HoldingReasons';

interface Props {
  classes: { [key: string]: string };
  closeDialog: () => void;
  onSave: (reason: HoldReasonTableItem) => void;
}

const AddHoldReasonDialog = ({
  classes,
  closeDialog,
  onSave,
}: Props) => {
  const formSchema = z.object({
    reason: z.string().min(2),
    scope: z.string().min(2),
  });

  type FormSchemaType = z.infer<typeof formSchema>;

  const { control, handleSubmit, errors } = useForm<FormSchemaType>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    defaultValues: { reason: '', scope: '' },
  });

  const onSubmit = async (data: FormSchemaType) => {
    onSave({
      reason: data.reason,
      scope: data.scope,
      code: data.reason.replaceAll(' ', '_').toUpperCase(),
      is_not_predefined: true,
      active: true,
    });
  };

  const onCancel = () => {
    closeDialog();
  };

  const renderActions = () => (
    <DialogActions className={classes.dialogActions}>
      <CSButton
        variant='outlined'
        color='secondary'
        className={classes.containedButton}
        onClick={onCancel}
      >
        Cancel
      </CSButton>

      <CSButton
        variant='contained'
        color='secondary'
        className={classes.containedButton}
        type='submit'
      >
        Add
      </CSButton>
    </DialogActions>
  );

  return (
    <Dialog open data-testid='add-holding-reason-dialog'>
      <DialogTitle>
        <CSDialogTitleWithIcon
          title='Add Holding Reason'
          icon={DialogIcons.ADD}
        />
      </DialogTitle>

      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent className={classes.dialogContent}>
          <CSMonoGridContainer>
            <Controller
              name='reason'
              control={control}
              render={(field) => (
                <CSTextField
                  label='Reason'
                  placeholder='Reason'
                  containerSize='fullHorizontal'
                  error={!!errors.reason?.message}
                  helperText={errors.reason?.message}
                  {...field}
                />
              )}
            />
            <Controller
              name='scope'
              control={control}
              render={(field) => (
                <CSSelect
                  label='Scope'
                  placeholder='Scope'
                  containerSize='fullHorizontal'
                  error={!!errors.scope?.message}
                  helperText={errors.scope?.message}
                  {...field}
                >
                  <MenuItem value='SYSTEM'>System</MenuItem>
                  <MenuItem value='CUSTOMER'>Customer</MenuItem>
                </CSSelect>
              )}
            />
          </CSMonoGridContainer>
        </DialogContent>
        {renderActions()}
      </form>
    </Dialog>
  );
};

export default withStyles(
  createStyles(() => ({
    dialogContent: {
      width: 360,
      maxWidth: '100%',
    },
    dialogActions: {
      padding: 24,
    },
    marginTop10: {
      marginTop: 10,
    },
  })),
)(AddHoldReasonDialog);
