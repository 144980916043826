import {
  ContainersApi,
  ContainerContainerStatusEnum,
  ContainerContainerTypeEnum,
  NestedProcessStepSerializerContainerProcessStepEnum,
  ContainerDetails,
} from 'cloudsort-client';
import axios from '../utils/axios';
import dateUtils from './utils/date';
import EphemeralStateService from './EphemeralState.service';

export type ContainersSortBy =
  | 'outbound_load_time'
  | 'critical_activity_time'
  | 'process_step_time'
  | '-outbound_load_time'
  | '-critical_activity_time'
  | '-process_step_time';

class Containers {
  private api: ContainersApi;

  constructor() {
    this.api = new ContainersApi(undefined, undefined, axios as any);

    this.getAll = this.getAll.bind(this);
    this.getById = this.getById.bind(this);
  }

  public async getAll(params: {
    area?: number;
    owner?: number[];
    carrier?: number[];
    route?: number[];
    destination?: number[];
    outbound?: boolean;
    manifest?: number[];
    targetOutboundLoad?: number;
    outboundLoad?: number;
    inboundLoad?: number;
    containerType?: ContainerContainerTypeEnum;
    processStep?: NestedProcessStepSerializerContainerProcessStepEnum[];
    excludeProcessStep?: NestedProcessStepSerializerContainerProcessStepEnum[];
    fromDate?: string;
    toDate?: string;
    containerStatus?: ContainerContainerStatusEnum[];
    sortBy?: ContainersSortBy;
    page?: number;
    pageSize?: number;
    hidePending?: boolean;
    module?: number;
    zone?: number;
    parentContainer?: number;
    defects?: Array<
      | 'EXCEPTION_MISMOVE_FORCED'
      | 'EXCEPTION_PRINT_FORCED'
      | 'EXCEPTION_MISLOAD_FORCED'
    >;
    excludeParentContainers?: boolean;
  }) {
    return this.api.containersList({
      ...params,
      station: EphemeralStateService.getMyStationId(),
      fromDate:
        params.fromDate === '' ? undefined : dateUtils.fromDate(),
      toDate: params.toDate === '' ? undefined : dateUtils.toDate(),
    });
  }

  public async getById(id: number) {
    return this.api.containersRead({ id });
  }

  public async getLabel(id: number, size: string, dpi: number) {
    return this.api.containersLabelLabelPng(
      {
        id,
        size,
        dpi,
      },
      { responseType: 'blob' },
    );
  }

  public async update(id: number, data: ContainerDetails) {
    return this.api.containersPartialUpdate({
      id,
      data,
    });
  }
}

export default new Containers();
