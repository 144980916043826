import React, { useEffect, useMemo, useState } from 'react';
import {
  withStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles';
import Layout from '../layout/Layout';
import PaginatedTable, {
  filterObj,
} from '../paginatedTable/PaginatedTable';
import { CSButton, CSTextField, Typography } from '../primitives';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ConfirmationDialog, {
  Transition,
} from '../confirmationDialog/ConfirmationDialog';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import { COLUMNS_DEVICES } from '../TableColumns';
import detailsPageStyles from '../commonStyles/detailsPage.style';
import moment from 'moment';
import queryString from 'query-string';
import fedexLabels from '../../utils/fedexLabels';
import {
  Device,
  StaffPermissionPermissionsEnum,
  DeviceDetails,
} from 'cloudsort-client';
import { AuthRoutes, ModulesKeys } from '../../interfaces/routes';
import configurationUtils from '../../utils/configurationUtils';
import { common } from '../../utils/strings';

// Services
import DevicesService from '../../services/Devices.service';
import ErrorHandler from '../../utils/ErrorHandler';
import PermissionsService from '../../services/Permissions.service';

//Types
import { AxiosError } from 'axios';

// Icons
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import FilterCenterFocusIcon from '@material-ui/icons/FilterCenterFocus';
import FilterListIcon from '@material-ui/icons/FilterList';

import { Grid } from '@material-ui/core';
import sectionPageBaseStyle from '../commonStyles/sectionPageBase.style';
import { Helmet } from 'react-helmet';
import CSSectionTitleSeparator from '../primitives/csSectionTitleSeparator/CSSectionTitleSeparator';
import clsx from 'clsx';
import FiltersDrawer, {
  SelectedFilters,
} from '../filtersDrawer/FiltersDrawer';
import { FilterDescription } from '../primitives/csHorizontalFilterBadgesGroup/csHorizontalFilterBadgesGroupTypes';
import CSHorizontalFilterBadgesGroup from '../primitives/csHorizontalFilterBadgesGroup/csHorizontalFilterBadgesGroup';
import CSDialogTitleWithIcon from '../primitives/csDialogTitleWithIcon/CSDialogTitleWithIcon';
import { DialogIcons } from '../primitives/csDialogTitleWithIcon/CSDialogTitleWithIconTypes';
import CSDialogAlert from '../primitives/csDialogAlert/CSDialogAlert';
import { CSMonoGridContainer } from '../primitives/csMonoGridContainer';
import saveAs from 'file-saver';
import { useAppDispatch } from '../../redux/store';
import { setLastVisitedModule } from '../../redux/slices/navigationSlice';

interface Props {
  classes: { [key: string]: string };
  location: any;
}

const initRequestTokenConfirmationDialog = {
  open: false,
  cb: undefined,
};

export const getBateryLabel = (device?: Device | DeviceDetails) => {
  if (device) {
    const battery_info = device.device_info?.battery_info;
    if (
      battery_info &&
      battery_info?.percentage &&
      battery_info?.timestamp
    ) {
      return `${battery_info.percentage}% - ${moment(
        battery_info.timestamp,
      ).fromNow()}`;
    }
  }
  return common.emptyValue;
};

export const getLastUsedLabel = (device: Device) => {
  if (device.last_event && device.last_event.timestamp) {
    return `${fedexLabels.getLabel(
      device.last_event.event_type as string,
    )} ${
      device.last_event.area_name
        ? 'in ' + device.last_event.area_name
        : ''
    } by ${device.last_event.user_full_name} at ${moment(
      device.last_event.timestamp,
    ).format('h:mm a, MMMM Do YYYY')}`;
  } else {
    return common.emptyValue;
  }
};

const Devices: React.FC<Props> = ({ classes, location }) => {
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [showAddDialog, setShowAddDialog] = useState<boolean>(false);
  const [showFiltersDrawer, setShowFiltersDrawer] = useState(false);
  const [showEnrolmentTokenDialog, setShowEnrolmentTokenDialog] =
    useState<boolean>(false);
  const [
    enrolmentTokenDiaogAlreadyShown,
    setEnrolmentTokenDiaogAlreadyShown,
  ] = useState<boolean>(false);
  const [deviceId, setDeviceId] = useState<string>();
  const [error, setError] = useState<string>();
  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e as AxiosError));
  };
  const [lastUpdated, setLastUpdated] = useState<string>(
    new Date().toISOString(),
  );
  const [
    requestTokenConfirmationDialog,
    setRequestTokenConfirmationDialog,
  ] = useState<any>(initRequestTokenConfirmationDialog);

  const [selectedFilters, setSelectedFilters] =
    useState<SelectedFilters>();
  const [filterToRemove, setFilterToRemove] =
    useState<FilterDescription>();

  const devicessLabels = useMemo(() => {
    return {
      singular: configurationUtils.getPageTitle(true, 'DEVICE'),
      plural: configurationUtils.getPageTitle(false, 'DEVICE'),
    };
  }, []);

  const dispatch = useAppDispatch();

  const getVersionLabel = (device: Device) => {
    let label = '';
    if (device.version) {
      label += device.version;
      if (device.version_status === 'OUTDATED')
        label += `<span class='${clsx(
          classes.versionBadge,
          classes.versionBadgeOutdated,
        )}'>Outdated</span>`;
      else if (device.version_status === 'DEPRECATED')
        label += `<span class='${clsx(
          classes.versionBadge,
          classes.versionBadgeDeprecated,
        )}'>Deprecated</span>`;
      else if (device.version_status === 'LIVE')
        label += `<span class='${clsx(
          classes.versionBadge,
          classes.versionBadgeLive,
        )}'>Live</span>`;
      return label;
    } else {
      return common.emptyValue;
    }
  };

  const onAfterDialogClose = () => {
    setShowAddDialog(false);
    setError(undefined);
  };

  const onAddDevice = async () => {
    setShowProgress(true);
    try {
      await DevicesService.addDevice(deviceId!);
      setLastUpdated(new Date().toISOString());
      if (!enrolmentTokenDiaogAlreadyShown)
        setShowEnrolmentTokenDialog(true);
      setEnrolmentTokenDiaogAlreadyShown(true);
      onAfterDialogClose();
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  const getDrawerFilters = (filters: SelectedFilters) => {
    setSelectedFilters(filters);
    setLastUpdated(new Date().toISOString());
  };

  const renderDialog = () => {
    return (
      <Dialog
        data-testid={'devices-add-dialog'}
        classes={{ paperScrollPaper: classes.dialogRoot }}
        open={showAddDialog}
        TransitionComponent={Transition}
        onClose={() => {
          onAfterDialogClose();
        }}
      >
        <DialogTitle>
          <CSDialogTitleWithIcon
            icon={DialogIcons.ADD}
            title={`Add New ${devicessLabels.singular}`}
          />
        </DialogTitle>
        <DialogContent className={classes.dialogRoot}>
          <CSMonoGridContainer>
            {error ? (
              <CSDialogAlert
                data-testid={'devices-error-banner'}
                alertMessage={error}
              />
            ) : (
              <></>
            )}
            <CSTextField
              autoFocus
              containerSize='fullHorizontal'
              data-testid={'devices-device-id-input'}
              label={`${devicessLabels.singular} ID`}
              onChange={(e) => {
                setDeviceId(e.target.value.toUpperCase());
              }}
            />
          </CSMonoGridContainer>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <CSButton
            color='secondary'
            variant='outlined'
            data-testid={'devices-dialog-close'}
            onClick={() => {
              onAfterDialogClose();
            }}
          >
            Cancel
          </CSButton>
          <CSButton
            color='secondary'
            variant='contained'
            data-testid={'devices-dialog-add'}
            disabled={!deviceId}
            onClick={() => {
              onAddDevice();
            }}
          >
            Add
          </CSButton>
        </DialogActions>
      </Dialog>
    );
  };

  const fetchDevices = async (
    pageIndex: number,
    rowsPerPage: number,
    filterOptions?: filterObj[],
    filterByString?: string,
    sortedBy?: string,
  ) => {
    const versionStatus =
      selectedFilters?.versionStatus?.values[0]?.id === 'ALL'
        ? undefined
        : selectedFilters?.versionStatus?.values[0]?.id;

    sortedBy = sortedBy?.replace('_id', '');

    const res = await DevicesService.getAll({
      page: pageIndex,
      pageSize: rowsPerPage,
      deviceDetails: false,
      sortBy: sortedBy as 'version' | '-version' | undefined,
      versionStatus: versionStatus as
        | 'LIVE'
        | 'OUTDATED'
        | 'DEPRECATED',
    });

    res.data.results.forEach((device: any) => {
      device.active = device.active ? `Active` : 'Inactive';
      device.last_used = getLastUsedLabel(device);
      device.version_id = getVersionLabel(device);
      device.battery_label = getBateryLabel(device);
    });
    return res;
  };

  const getEnrolmentToken = async () => {
    setShowProgress(true);
    try {
      const file = await DevicesService.getEnrolmentToken();
      const blob = new Blob([file.data as unknown as string], {
        type: 'application/pdf',
      });
      const fileNamStr = file.request.getResponseHeader(
        'content-disposition',
      );
      saveAs(
        blob,
        fileNamStr.split('filename=')[1].replace(/"/g, ''),
      );
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  useEffect(() => {
    PermissionsService.redirectIfNoPermission(
      StaffPermissionPermissionsEnum.DEVICE_READ,
    );

    dispatch(
      setLastVisitedModule({
        module: ModulesKeys.DEVICE,
        permission: StaffPermissionPermissionsEnum.DEVICE_READ,
      }),
    );
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort -
          ${devicessLabels.plural}  ${
            queryString.parse(location.search)['page']
              ? '- Page ' + queryString.parse(location.search)['page']
              : ''
          }`}
        </title>
      </Helmet>
      <Layout navCurrent='DEVICE'>
        {showProgress && <ProgressIndicator />}
        <FiltersDrawer
          isOpen={showFiltersDrawer}
          onAfterClose={() => {
            setShowFiltersDrawer(false);
          }}
          getFilters={getDrawerFilters}
          removeFilter={filterToRemove}
        />
        <ConfirmationDialog
          dataTestIdPrefix={'download-enrolment-token-dialog'}
          title='Download enrollment token'
          msg='Would you like to download the enrollment token?'
          primaryActionLabel={'Yes'}
          onPrimaryAction={() => {
            setRequestTokenConfirmationDialog({
              open: true,
              cb: getEnrolmentToken,
            });
          }}
          cancelLabel={'No'}
          onCancel={() => {
            setShowEnrolmentTokenDialog(false);
          }}
          isOpen={showEnrolmentTokenDialog}
        />
        <ConfirmationDialog
          dataTestIdPrefix={'confirmation-enrolment-token-dialog'}
          title='Request New Token'
          msg='Requesting a new Token will invalidate all previously downloaded Tokens'
          primaryActionLabel={'Yes'}
          onPrimaryAction={() => {
            requestTokenConfirmationDialog.cb();
            setRequestTokenConfirmationDialog(
              initRequestTokenConfirmationDialog,
            );
          }}
          cancelLabel={'No'}
          onCancel={() => {
            setRequestTokenConfirmationDialog(
              initRequestTokenConfirmationDialog,
            );
          }}
          isOpen={requestTokenConfirmationDialog.open}
        />
        {renderDialog()}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Typography variant='h3' component='h2'>
              {devicessLabels.plural}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            className={classes.nonMobileAlignRight}
          >
            {PermissionsService.hasPermission(
              StaffPermissionPermissionsEnum.DEVICE_WRITE,
            ) && (
              <>
                <CSButton
                  data-testid={'devices-add-button'}
                  variant='contained'
                  color={{
                    buttonColor: 'secondary',
                    iconColor: 'primary',
                  }}
                  fullWidth={false}
                  disabled={!!error}
                  onClick={(e) => {
                    e.preventDefault();
                    setShowAddDialog(true);
                  }}
                  startIcon={<AddBoxOutlinedIcon />}
                  className={classes.headerButton}
                >
                  Add{' '}
                  {configurationUtils.getPageTitle(true, 'DEVICE')}
                </CSButton>

                <CSButton
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    setRequestTokenConfirmationDialog({
                      open: true,
                      cb: getEnrolmentToken,
                    });
                  }}
                  startIcon={<FilterCenterFocusIcon />}
                  className={classes.headerButton}
                >
                  {`Enroll new ${configurationUtils.getPageTitle(
                    true,
                    'DEVICE',
                  )}`}
                </CSButton>
              </>
            )}
            <CSButton
              variant='outlined'
              color='secondary'
              className={clsx(classes.headerButton)}
              onClick={() => {
                setShowFiltersDrawer(true);
              }}
            >
              <FilterListIcon />
            </CSButton>
          </Grid>
          <Grid item xs={12}>
            <CSSectionTitleSeparator
              bottomMargin={10}
              topMargin={10}
            />
          </Grid>
          <Grid item xs={12} className={classes.marginBottom20}>
            <CSHorizontalFilterBadgesGroup
              selectedFilters={selectedFilters}
              onRemoveFilter={(toRemove) =>
                setFilterToRemove(toRemove)
              }
            />
          </Grid>
        </Grid>

        <PaginatedTable
          key={lastUpdated}
          title=''
          columns={COLUMNS_DEVICES}
          dataTestIdPrefix={'devices'}
          rowsLoadDetailPages={true}
          detailsPageBasePath={AuthRoutes.DEVICE}
          fetch={fetchDevices}
          sortableBy={['version_id']}
          defaultSort={
            (queryString.parse(location.search)[
              'sortBy'
            ] as string) || undefined
          }
        />
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...sectionPageBaseStyle,
    versionBadge: {
      ...theme.typography.body2,
      border: '1px solid',
      marginLeft: 5,
      padding: '3px 10px',
      borderRadius: theme.shape.borderRadius_sm,
    },
    versionBadgeLive: {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
    versionBadgeOutdated: {
      color: theme.palette.grey.A400,
      borderColor: theme.palette.grey.A400,
    },
    versionBadgeDeprecated: {
      color: theme.palette.error.main,
      borderColor: theme.palette.error.main,
    },
    dialogRoot: {
      width: '100%',
      maxWidth: 400,
    },
    nonMobileAlignRight: {
      [theme.breakpoints.down('xs')]: {
        marginTop: 20,
      },
      [theme.breakpoints.up('sm')]: {
        textAlign: 'right',
      },
    },
    headerButton: { marginLeft: 10 },
    marginBottom20: { marginBottom: 20 },
    dialogActions: {
      padding: 24,
    },
  })),
)(Devices);
