import { ZonesApi, Zone, ZoneDetails } from 'cloudsort-client';
import axios from '../utils/axios';
import EphemeralStateService from './EphemeralState.service';

class Zones {
  private api: ZonesApi;

  constructor() {
    this.api = new ZonesApi(undefined, undefined, axios as any);
  }

  public async create(data: Zone) {
    return this.api.zonesCreate({ data });
  }

  public async delete(id: number) {
    return this.api.zonesDelete({ id });
  }

  public async getAll(
    page?: number,
    area?: number,
    pageSize?: number,
  ) {
    return this.api.zonesList({
      page,
      area,
      pageSize,
      station: EphemeralStateService.getMyStationId(),
    });
  }

  public async search(search: string, area?: number) {
    return this.api.zonesList({
      search,
      area,
      station: EphemeralStateService.getMyStationId(),
    });
  }

  public async getById(id: number) {
    return this.api.zonesRead({ id });
  }

  public async update(data: ZoneDetails) {
    return this.api.zonesPartialUpdate({
      id: data.id!,
      data,
    });
  }
}

export default new Zones();
