import React, { useState, useEffect, useMemo } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import Layout from '../layout/Layout';
import {
  AlertBanner,
  CSButton,
  Typography,
} from '../primitives/index';
import ErrorHandler from '../../utils/ErrorHandler';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import PaginatedTable, {
  filterObj,
} from '../paginatedTable/PaginatedTable';
import { Column } from '../../interfaces/components';
import configurationUtils from '../../utils/configurationUtils';
import { Grid, Paper, Tabs, Theme } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import clx from 'classnames';
import {
  HoldReason,
  Package,
  PackageDetails,
  PackageWithHoldReasons,
  StaffPermissionPermissionsEnum,
} from 'cloudsort-client';

import CloudDownloadOutlined from '@material-ui/icons/CloudDownloadOutlined';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import FilterListIcon from '@material-ui/icons/FilterList';
import PermissionsService from '../../services/Permissions.service';
import sectionPageBaseStyle from '../commonStyles/sectionPageBase.style';
import FiltersDrawer, {
  SelectedFilters,
} from '../filtersDrawer/FiltersDrawer';
import filterBadgeStyle from '../filtersDrawer/filterBadge.style';

import CSSectionTitleSeparator from '../primitives/csSectionTitleSeparator/CSSectionTitleSeparator';

import CSHorizontalFilterBadgesGroup from '../primitives/csHorizontalFilterBadgesGroup/csHorizontalFilterBadgesGroup';
import { FilterDescription } from '../primitives/csHorizontalFilterBadgesGroup/csHorizontalFilterBadgesGroupTypes';
import { AxiosError } from 'axios';
import CSTab from '../configuration/CSTab/CSTab';
import SingleRowStats, {
  StatData,
} from '../singleRowStats/SingleRowStats';
import HeldPackageEPContent from './HeldPackageEPContent';
import HeldPackagesService, {
  HeldPackageScopeValues,
  HeldPackageSourceValues,
} from '../../services/HeldPackages.service';
import ExportCSVDialog from '../exportCSVDialog/ExportCSVDialog';
import { common } from '../../utils/strings';
import AddHeldReasonDialog from './AddHeldReasonDialog';
import PackagesService from '../../services/Packages.service';
import ResolveHoldingReasonsDialog from '../packages/ResolveHoldingReasonsDialog';
import ConfirmationDialog from '../confirmationDialog/ConfirmationDialog';
import EditCloudsortNoteDialog from '../packages/EditCloudsortNoteDialog';
import { useAppDispatch } from '../../redux/store';
import { ModulesKeys } from '../../interfaces/routes';
import { setLastVisitedModule } from '../../redux/slices/navigationSlice';

interface Props {
  classes: { [key: string]: string };
  location: any;
}

const COLUMNS: Column[] = [
  {
    id: 'tracking_number',
    label: 'Tracking ID',
    width: 200,
  },
  {
    id: 'inbound_load_id',
    label: 'Inbound Load',
    width: 200,
  },
  {
    id: 'owner_full_name',
    label: 'Owner',
    width: 200,
  },
  {
    id: 'fmc_full_name',
    label: 'Carrier',
    width: 200,
  },
  {
    id: 'dwell_time',
    label: 'Dwell Time',
    width: 200,
  },
  {
    id: 'hold_reasons_count',
    label: 'Holding Reasons',
    width: 200,
    useCustomComponent: true,
  },
  {
    id: 'last_update',
    label: 'Last Update',
    width: 300,
  },
];

enum PageDialogs {
  HOLD_RESOLVE_CONFIMATION = 'HOLD_RESOLVE_CONFIMATION',
  RESOLVE_CONFIRMATION_PROMPT = 'RESOLVE_CONFIRMATION_PROMPT',
  EDIT_NOTE = 'EDIT_NOTE',
  ADD_REASON = 'ADD_REASON',
  EXPORT_CSV = 'EXPORT_CSV',
}

enum SelectedTab {
  UNRESOLVED = 'UNRESOLVED',
  RESOLVED = 'RESOLVED',
  RESOLVED_AND_DISPATCHED = 'RESOLVED_DISPATCHED',
}

interface ExtendedPackageWithHoldReason
  extends PackageWithHoldReasons {
  hold_reasons_count?: JSX.Element;
}

const HoldedPackages: React.FC<Props> = ({ classes, location }) => {
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const [activeDialog, setActiveDialog] = useState<
    PageDialogs | undefined
  >();

  const [showFiltersDrawer, setShowFiltersDrawer] = useState(false);
  const [selectedFilters, setSelectedFilters] =
    useState<SelectedFilters>();
  const [filterToRemove, setFilterToRemove] =
    useState<FilterDescription>();
  const [filterLastUpdate, setFilterLastUpdate] = useState<number>(0);
  const [lastUpdated, setLastUpdated] = useState<string>(
    new Date().toISOString(),
  );

  const dispatch = useAppDispatch();

  const [selectedTab, setSelectedTab] = useState<SelectedTab>(
    SelectedTab.UNRESOLVED,
  );

  const [expandedRowData, setExpandedRowData] =
    useState<PackageWithHoldReasons>();
  const [selectedReasons, setSelectedReasons] = useState<string[]>(
    [],
  );

  const [reasonToEdit, setReasonToEdit] = useState<HoldReason>();

  const heldPackageLabels = {
    singular: 'Held Package',
    plural: 'Held Packages',
  };

  const packageLabels = {
    singular: configurationUtils.getPageTitle(true, 'PACKAGE'),
    plural: configurationUtils.getPageTitle(false, 'PACKAGE'),
  };

  useEffect(() => {
    PermissionsService.redirectIfNoPermission(
      StaffPermissionPermissionsEnum.PACKAGE_WRITE,
    );

    dispatch(
      setLastVisitedModule({
        module: ModulesKeys.HELD_PACKAGE,
        permission: StaffPermissionPermissionsEnum.PACKAGE_WRITE,
      }),
    );
  }, [dispatch]);

  const getDrawerFilters = (filters: SelectedFilters) => {
    setSelectedFilters(filters);
    setFilterLastUpdate(Date.now());
  };

  const apiFormattedFilterDrawerValues = useMemo(() => {
    return {
      carrier: selectedFilters?.carrier?.values?.length
        ? (selectedFilters?.carrier?.values[0].id as number)
        : undefined,
      owner: selectedFilters?.owner?.values?.length
        ? (selectedFilters?.owner?.values[0].id as number)
        : undefined,
      reason: selectedFilters?.holdingReasons?.values?.length
        ? (selectedFilters?.holdingReasons?.values[0].id as string)
        : undefined,
      inboundLoad: selectedFilters?.inboundLoad?.values?.length
        ? (selectedFilters?.inboundLoad?.values[0].id as number)
        : undefined,
      scope: selectedFilters?.scope?.values?.length
        ? (selectedFilters?.scope?.values[0]
            .id as HeldPackageScopeValues)
        : undefined,
      source: selectedFilters?.source?.values?.length
        ? (selectedFilters?.source?.values[0]
            .id as HeldPackageSourceValues)
        : undefined,
    };
  }, [selectedFilters]);

  const fetchHoldingReasons = async (
    pageIndex: number,
    rowsPerPage: number,
    filterOptions?: filterObj[],
    filterByString?: string,
    sortedBy?: string,
  ) => {
    try {
      setShowProgress(true);
      const res = await HeldPackagesService.getAll({
        page: pageIndex,
        pageSize: rowsPerPage,
        status: selectedTab,
        trackingNumber: filterByString,
        ...apiFormattedFilterDrawerValues,
      });

      res.data.results.forEach(
        (pck: ExtendedPackageWithHoldReason) => {
          pck.hold_reasons_count =
            (pck.hold_for?.total || 0) > 0 ? (
              <Typography
                color={
                  (pck.hold_for?.unresolved || 0) > 0
                    ? 'error'
                    : undefined
                }
              >
                {pck.hold_for?.resolved || 0} /{' '}
                {pck.hold_for?.total || 0}
              </Typography>
            ) : (
              <>{common.emptyValue}</>
            );
        },
      );

      return res;
    } catch (e) {
      setError(await ErrorHandler.getLabel(e as AxiosError));
    } finally {
      setShowProgress(false);
    }
  };

  const onEditNoteClick = (reason: HoldReason) => {
    setReasonToEdit(reason);
    setActiveDialog(PageDialogs.EDIT_NOTE);
  };

  const handleTabChange = (event: any, newValue: SelectedTab) => {
    setSelectedTab(newValue);
  };

  const fetchHoldReasonsStats = async () => {
    try {
      const res = (
        await HeldPackagesService.getStats({
          ...apiFormattedFilterDrawerValues,
        })
      ).data;

      return [
        {
          label: 'On Hold',
          value: res.count_on_hold || common.emptyValue,
        },
        {
          label: 'Resolved',
          value: res.count_resolved || common.emptyValue,
        },
        {
          label: 'Resolved & Dispatched',
          value: res.count_resolved_dispatched || common.emptyValue,
        },
      ] as StatData[];
    } catch (e) {
      setError(await ErrorHandler.getLabel(e as AxiosError));
    }
  };

  const updatePackageDetailsData = async (
    newData: PackageWithHoldReasons,
    callback?: Function,
    packageId?: number,
  ) => {
    setShowProgress(true);
    setError(undefined);
    setActiveDialog(undefined);
    try {
      await PackagesService.update({
        id: packageId || expandedRowData?.id!,
        data: newData,
      });

      setLastUpdated(new Date().toISOString());
      callback && callback();
    } catch (e) {
      setError(await ErrorHandler.getLabel(e as AxiosError));
    }
    setShowProgress(false);
  };

  const processAddReason = async (
    packageData: Package | PackageWithHoldReasons,
    reason: string,
    csNotes?: string,
  ) => {
    setShowProgress(true);
    setError(undefined);

    updatePackageDetailsData(
      {
        hold_for: {
          reasons: [
            ...((packageData as PackageWithHoldReasons)?.hold_for
              ?.reasons || []),
            {
              code: reason,
              reason: reason,
              resolved: false,
              note: csNotes !== '' ? csNotes : undefined,
            },
          ],
        },
      },
      undefined,
      packageData.id,
    );
  };

  const processResolveReasons = async (note?: string) => {
    updatePackageDetailsData(
      {
        hold_for: {
          reasons:
            expandedRowData?.hold_for?.reasons.map((reason: any) => {
              if (selectedReasons.includes(reason.reason)) {
                return {
                  ...reason,
                  resolved: true,
                  note: note !== '' ? note : undefined,
                };
              }
              return reason;
            }) || [],
        },
      },
      () => {
        setActiveDialog(PageDialogs.HOLD_RESOLVE_CONFIMATION);
      },
    );
  };

  const processEditNote = async (note: string) => {
    updatePackageDetailsData({
      hold_for: {
        reasons:
          expandedRowData?.hold_for?.reasons.map((reason: any) => {
            if (reason.reason === reasonToEdit?.reason) {
              return {
                ...reason,
                note: note,
              };
            }
            return reason;
          }) || [],
      },
    });
  };

  return (
    <>
      <Helmet>
        <title>CloudSort - {heldPackageLabels.plural}</title>
      </Helmet>
      <Layout navCurrent='HELD_PACKAGE'>
        <ExportCSVDialog
          exportFilters={{}}
          key={'dialog' + filterLastUpdate}
          type='HELD_PACKAGES'
          isOpen={activeDialog === PageDialogs.EXPORT_CSV}
          onAfterClose={() => {
            setActiveDialog(undefined);
          }}
          packageFilters={{
            ...selectedFilters,
          }}
        />
        {activeDialog === PageDialogs.ADD_REASON && (
          <AddHeldReasonDialog
            onSave={(
              packageData: Package | PackageDetails | Package,
              reason: string,
              csNotes?: string,
            ) => {
              processAddReason(
                packageData as PackageWithHoldReasons | Package,
                reason,
                csNotes,
              );
            }}
            closeDialog={() => {
              setActiveDialog(undefined);
            }}
          />
        )}
        {activeDialog === PageDialogs.RESOLVE_CONFIRMATION_PROMPT && (
          <ResolveHoldingReasonsDialog
            onSave={(note: string) => {
              processResolveReasons(note);
            }}
            reasonsToResolve={selectedReasons}
            trackingId={expandedRowData?.tracking_number || ''}
            onCancel={() => {
              setActiveDialog(undefined);
            }}
          />
        )}
        {activeDialog === PageDialogs.EDIT_NOTE && reasonToEdit && (
          <EditCloudsortNoteDialog
            currentNote={reasonToEdit?.system_note || ''}
            onSave={(note: string) => {
              processEditNote(note);
            }}
            onCancel={() => {
              setActiveDialog(undefined);
            }}
          />
        )}
        <ConfirmationDialog
          dataTestIdPrefix='package-hold-resolve-dialog'
          title='Holding reason marked as resolved'
          msg={selectedReasons.join('\n')}
          primaryActionLabel='OK'
          onPrimaryAction={() => {
            setActiveDialog(undefined);
            setSelectedReasons([]);
          }}
          cancelLabel='Cancel'
          hideCancelButton
          onCancel={() => {
            setActiveDialog(undefined);
            setSelectedReasons([]);
          }}
          isOpen={
            activeDialog === PageDialogs.HOLD_RESOLVE_CONFIMATION
          }
        />
        {showProgress && <ProgressIndicator />}
        {error && (
          <AlertBanner
            className={classes.banner}
            severity='error'
            alertTitle={'Error'}
            alertMsg={error}
          />
        )}
        <FiltersDrawer
          isOpen={showFiltersDrawer}
          onAfterClose={() => {
            setShowFiltersDrawer(false);
          }}
          getFilters={getDrawerFilters}
          removeFilter={filterToRemove}
        />
        <Grid
          container
          className={classes.header}
          alignItems='center'
        >
          <Grid item xs={12}>
            <Typography
              variant='h6'
              className={classes.marginBottom20}
            >
              Held Packages
            </Typography>
            <Typography
              variant='h6'
              className={classes.marginBottom20}
            >
              Packages Status
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.marginBottom20}>
            <SingleRowStats
              link_base={''}
              dataTestId='held-packages-list-stats'
              equalColumns
              disableActiveMarker
              fetch={fetchHoldReasonsStats}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              className={classes.title}
              variant='h3'
              component='h2'
            >
              {packageLabels.plural} with Hold Reason
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
            {PermissionsService.hasPermission(
              StaffPermissionPermissionsEnum.PACKAGE_WRITE,
            ) && (
              <>
                <CSButton
                  variant='contained'
                  onClick={() => {
                    setActiveDialog(PageDialogs.EXPORT_CSV);
                  }}
                  startIcon={<CloudDownloadOutlined />}
                >
                  Export CSVs
                </CSButton>

                <CSButton
                  startIcon={<AddBoxOutlinedIcon />}
                  className={classes.marginLeft10}
                  variant='contained'
                  color={{
                    buttonColor: 'secondary',
                    iconColor: 'primary',
                  }}
                  onClick={() => {
                    setActiveDialog(PageDialogs.ADD_REASON);
                  }}
                >
                  Add Reason
                </CSButton>

                <CSButton
                  variant='contained'
                  className={classes.marginLeft10}
                  color='secondary'
                  disabled={selectedReasons.length === 0}
                  onClick={() => {
                    setActiveDialog(
                      PageDialogs.RESOLVE_CONFIRMATION_PROMPT,
                    );
                  }}
                >
                  Resolve
                </CSButton>
              </>
            )}
            <CSButton
              variant='outlined'
              color='secondary'
              className={clx(
                classes.headerButtons,
                classes.headerFilterButton,
              )}
              onClick={() => {
                setShowFiltersDrawer(true);
              }}
            >
              <FilterListIcon />
            </CSButton>
          </Grid>
          <CSSectionTitleSeparator topMargin={5} bottomMargin={24} />

          <Grid
            container
            item
            xs={12}
            spacing={1}
            className={classes.marginBottom20}
          >
            <CSHorizontalFilterBadgesGroup
              selectedFilters={selectedFilters}
              onRemoveFilter={(toRemove) =>
                setFilterToRemove(toRemove)
              }
            />
          </Grid>

          <Grid item xs={12}>
            <Paper className={classes.paperWithoutShadow}>
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                variant='scrollable'
                classes={{ indicator: classes.tabsIndicator }}
              >
                <CSTab
                  value={SelectedTab.UNRESOLVED}
                  label='Unresolved'
                />
                <CSTab
                  value={SelectedTab.RESOLVED}
                  label='Resolved'
                />
                <CSTab
                  value={SelectedTab.RESOLVED_AND_DISPATCHED}
                  label='Resolved & Dispatched'
                />
              </Tabs>
            </Paper>
          </Grid>
        </Grid>
        {!!filterLastUpdate && (
          <PaginatedTable
            key={lastUpdated + filterLastUpdate + selectedTab}
            title=''
            columns={COLUMNS}
            dataTestIdPrefix={'held-packages'}
            fetch={fetchHoldingReasons}
            filterByString={true}
            filterByStringPlaceholder='Search by Tracking ID'
            isExpandedPanel={true}
            isSingleActiveExpandedPanel={true}
            defaultExpandedPanelId={expandedRowData?.id}
            expandedContentRenderer={(
              data: PackageWithHoldReasons,
            ) => {
              return (
                <HeldPackageEPContent
                  reasons={data.hold_for.reasons}
                  selectedReasons={selectedReasons}
                  setSelectedReasons={setSelectedReasons}
                  onEditNoteClick={onEditNoteClick}
                />
              );
            }}
            expansionPanelClickCallback={(row) => {
              setExpandedRowData(row);
              setSelectedReasons([]);
            }}
          />
        )}
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...sectionPageBaseStyle,
    ...filterBadgeStyle,
    headerFilterButton: {
      minWidth: '40px',
      marginLeft: '20px',
    },
    tabsIndicator: {
      display: 'none',
    },
    paperWithoutShadow: {
      width: '100%',
      height: 'auto',
      borderRadius: 4,
      boxShadow: 'none',
      backgroundColor: theme.palette.common.white,
    },
    marginBottom20: {
      marginBottom: 20,
    },
    marginLeft10: {
      marginLeft: 10,
    },
  })),
)(HoldedPackages);
