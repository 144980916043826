import browserHistory from '../utils/browserHistory';
import { AuthRoutes } from '../interfaces/routes';
import configurationUtils from '../utils/configurationUtils';

//Services
import EphemeralStateService from '../services/EphemeralState.service';
import { NestedProcessStepSerializerPackageProcessStepEnum } from 'cloudsort-client';

export enum GlobalModules {
  'AREA',
  'STOP',
  'ZONE',
  'ROUTE',
  'STAFF',
  'DEVICE',
  'PACKAGE',
  'LOADPLAN',
  'MANIFEST',
  'CONTAINER',
  'DASHBOARD',
  'DOCK_DOOR',
  'LOADPOINT',
  'INBOUND_LOAD',
  'OUTBOUND_LOAD',
  'STATION_LAYOUT',
  'STATION_PLANNER',
  'GROUP_CONTAINER',
  'HELD_PACKAGE',
}

const getConfigArray = (params: {
  config: any;
  filter?: { key: string; value: string | boolean };
  sortBy?: string;
}) => {
  if (params.config) {
    let array = Object.keys(params.config).map((item) => {
      return { id: item, ...params.config[item] };
    });

    if (params.filter) {
      array = array.filter(
        (item) => item[params.filter!.key] === params.filter!.value,
      );
    }

    if (params.sortBy) {
      array.sort(function (a, b) {
        return a[params.sortBy!] - b[params.sortBy!];
      });
    }
    return array;
  }
  return [];
};

const getIsModuleActive = (
  module: keyof typeof AuthRoutes,
): boolean => {
  const activeModules = getConfigArray({
    config:
      EphemeralStateService.getMyStationConfiguratation()?.GENERAL
        .MODULES,
    filter: { key: 'active', value: true },
    sortBy: 'order',
  });
  if (activeModules.find((m) => m.id === module)) return true;
  else return false;
};

const getActiveProcessSteps = () => {
  const psConfig =
    EphemeralStateService.getMyStationConfiguratation()?.GENERAL
      ?.PROCESS_STEPS || null;
  if (!psConfig) {
    return [];
  }
  return Object.entries(psConfig)
    .map(([key, value]: any) => ({
      key,
      label: value.label,
      active: value.active,
    }))
    .filter((el) => el.active);
};

const getPageTitle = (
  isSingular?: boolean,
  path?:
    | keyof typeof AuthRoutes
    | 'ZONE'
    | 'LOADPOINT'
    | 'STATION_LAYOUT',
) => {
  const modulesConfig =
    EphemeralStateService.getMyStationConfiguratation()?.GENERAL
      ?.MODULES || null;

  if (!modulesConfig) {
    return '';
  }
  let keyByPath;
  if (path) {
    keyByPath = path;
  } else {
    keyByPath = Object.entries(AuthRoutes)
      .map(([key, value]: any) => ({
        key,
        path: value,
      }))
      .find(
        (el) => el.path === browserHistory.location.pathname,
      )?.key;
  }
  if (keyByPath)
    return isSingular
      ? modulesConfig[keyByPath].label
      : modulesConfig[keyByPath].label_plural;
  else return '';
};

const getPsOrder = () => {
  const psOrder = [
    NestedProcessStepSerializerPackageProcessStepEnum.MANIFESTED_INBOUND,
    NestedProcessStepSerializerPackageProcessStepEnum.ARRIVED,
    NestedProcessStepSerializerPackageProcessStepEnum.PRIMARY,
    NestedProcessStepSerializerPackageProcessStepEnum.SECONDARY,
    NestedProcessStepSerializerPackageProcessStepEnum.ZONE,
    NestedProcessStepSerializerPackageProcessStepEnum.CONTAINERIZED,
    NestedProcessStepSerializerPackageProcessStepEnum.STAGED,
    NestedProcessStepSerializerPackageProcessStepEnum.LOADED,
    NestedProcessStepSerializerPackageProcessStepEnum.DISPATCHED,
  ];

  const psConfig = configurationUtils
    .getActiveProcessSteps()
    .filter((step) => {
      return (
        step.key !==
        NestedProcessStepSerializerPackageProcessStepEnum.MANIFESTED_OUTBOUND
      );
    });

  psConfig.sort(
    (a, b) => psOrder.indexOf(a.key) - psOrder.indexOf(b.key),
  );
  if (!psConfig) {
    return [];
  }
  return psConfig;
};

const getHoldingReasons = (
  activeOnly = false,
): { id: string | number; name: string }[] => {
  const configHoldReasons =
    EphemeralStateService.getMyStationConfiguratation().GENERAL
      .HOLD_REASONS;

  const preDefined = Object.values(configHoldReasons.PRE_DEFINED)
    .filter((reason: any) => (activeOnly ? reason.active : true))
    .map((reason: any) => {
      return { name: reason.reason, id: reason.code };
    });

  const custom = configHoldReasons.CUSTOM.filter((reason: any) =>
    activeOnly ? reason.active : true,
  ).map((reason: any) => {
    return { name: reason.reason, id: reason.code };
  });

  return [...preDefined, ...custom];
};

const getContainerlStates = () => {
  const statesConfig =
    EphemeralStateService.getMyStationConfiguratation()?.GENERAL
      ?.STATES || null;
  if (!statesConfig) {
    return [];
  }
  return Object.entries(statesConfig)
    .map(([key, value]: any) => ({
      key: key,
      label: value.label,
      label_transition_in: value.label_transition_in,
    }))
    .filter((el) => el.key.includes('CONTAINER_'));
};

const isModuleActive = (module: keyof typeof GlobalModules) => {
  const modulesConfig =
    EphemeralStateService.getMyStationConfiguratation()?.GENERAL
      ?.MODULES || null;

  const _isParentModuleActive = (
    module: keyof typeof GlobalModules,
  ): boolean => {
    if (modulesConfig[module].active) {
      return true;
    } else if (modulesConfig[module].depends_on) {
      return _isParentModuleActive(
        modulesConfig[module]
          .depends_on as keyof typeof GlobalModules,
      );
    }

    return false;
  };

  if (modulesConfig && modulesConfig[module]) {
    if (modulesConfig[module].active) {
      return true;
    } else if (modulesConfig[module].depends_on) {
      return _isParentModuleActive(
        modulesConfig[module]
          .depends_on as keyof typeof GlobalModules,
      );
    }
  }

  return false;
};

const isModuleNameValidated = (
  module: keyof typeof GlobalModules,
) => {
  const modulesConfig =
    EphemeralStateService.getMyStationConfiguratation()?.GENERAL
      ?.MODULES || null;

  if (
    modulesConfig &&
    modulesConfig[module] &&
    modulesConfig[module].validate_name
  )
    return true;
  else return false;
};

const getPropertylabel = (
  module: keyof typeof GlobalModules,
  key: string,
  isSingular: boolean = true,
) => {
  const modulesConfig =
    EphemeralStateService.getMyStationConfiguratation()?.GENERAL
      ?.MODULES || null;

  if (
    modulesConfig &&
    modulesConfig[module] &&
    modulesConfig[module].property_labels &&
    modulesConfig[module].property_labels.hasOwnProperty(key)
  )
    if (isSingular)
      return modulesConfig[module].property_labels[key].label;
    else
      return modulesConfig[module].property_labels[key].label_plural;
  else return '(Missing Label)';
};

const getModuleLabel = (
  isSingular: boolean,
  path: keyof typeof GlobalModules,
) => {
  const modulesConfig =
    EphemeralStateService.getMyStationConfiguratation()?.GENERAL
      ?.MODULES || null;

  if (!modulesConfig) {
    return '';
  }
  if (modulesConfig[path])
    return isSingular
      ? modulesConfig[path].label
      : modulesConfig[path].label_plural;
  else return '';
};
export default {
  getConfigArray,
  getIsModuleActive,
  getActiveProcessSteps,
  getPageTitle,
  getPsOrder,
  getContainerlStates,
  isModuleActive,
  isModuleNameValidated,
  getPropertylabel,
  getModuleLabel,
  getHoldingReasons,
};
