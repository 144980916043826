import {
  AreaPackageCount,
  DockdoorPackageCount,
  LoadpointPackageCount,
  StationPackageCountData,
  ZonePackageCount,
} from 'cloudsort-client';
import React, { useEffect, useState } from 'react';
import { Group } from 'react-konva';
import { findMinMaxCoordinates, rotatePoint } from '../helpers';
import { MappedElement } from '../StationLayout';
import ShapeLabel from './ShapeLabel';

//Services
import DataAnalytycsService from '../../../services/DataAnalytycs.service';

interface Props {
  currentView: string;
  mappedAreas: MappedElement[];
  mappedZones: MappedElement[];
  mappedLoadpoints: MappedElement[];
  mappedDockdoors: MappedElement[];
  countOrPercentage: 'COUNT' | 'PERCENTAGE';
}

interface Badge {
  x: number;
  y: number;
  rotation: number;
  width: number;
  text: string;
}

const PackageCounts: React.FC<Props> = ({
  currentView,
  mappedAreas,
  mappedZones,
  mappedLoadpoints,
  mappedDockdoors,
  countOrPercentage,
}) => {
  const [counts, setCounts] = useState<StationPackageCountData>();
  const [badges, setBadges] = useState<Badge[]>();

  const fetchCounts = async () => {
    const data = (await DataAnalytycsService.getPackageCounts()).data
      .data;

    setCounts(data);
  };

  useEffect(() => {
    fetchCounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBadgeProperties = (
    element: MappedElement,
    count:
      | AreaPackageCount
      | ZonePackageCount
      | LoadpointPackageCount
      | DockdoorPackageCount
      | undefined,
  ): Badge => {
    const minMax = findMinMaxCoordinates(element.points);

    const getFormattedCount = (count: any): string => {
      if (countOrPercentage === 'COUNT') {
        const total = count.hasOwnProperty('accumulated_count')
          ? count.accumulated_count
          : count.count;

        if (total < 1000) {
          return '📦' + total.toString();
        } else if (total < 100000) {
          return '📦' + (total / 1000).toFixed(1) + 'K';
        } else {
          return '📦' + (total / 1000).toFixed() + 'K';
        }
      } else {
        return (count.percentage * 100).toFixed() + '%';
      }
    };

    const formattedCount = count
      ? getFormattedCount(count)
      : countOrPercentage === 'COUNT'
      ? '📦0'
      : '0%';

    const labelWidth = formattedCount.length * 6 + 12;

    const rotatedPosition = rotatePoint(
      [minMax.x.mid - labelWidth / 2, minMax.y.mid - 12],
      [0, 0],
      element.rotation,
    );

    return {
      x: rotatedPosition[0],
      y: rotatedPosition[1],
      rotation: element.rotation,
      width: labelWidth,
      text: formattedCount,
    };
  };

  useEffect(() => {
    const currentBadges: Badge[] = [];

    if (currentView === 'COUNT_AREAS_DD') {
      //Areas
      mappedAreas.forEach((element) => {
        const count = counts?.areas?.find(
          (area) => area.area_id === element.id,
        );
        currentBadges.push(getBadgeProperties(element, count));
      });
      //Dock doors
      mappedDockdoors.forEach((element) => {
        const count = counts?.dockdoors?.find(
          (dd: any) => dd.dockdoor_id === element.id,
        );

        currentBadges.push(getBadgeProperties(element, count));
      });
    } else if (currentView === 'COUNT_ZONES') {
      //Zones
      const zones = counts?.areas?.map((area) => area.zones).flat();

      mappedZones.forEach((element) => {
        const count = zones?.find(
          (zone) => zone?.zone_id === element.id,
        );

        currentBadges.push(getBadgeProperties(element, count));
      });
    } else if (currentView === 'COUNT_LOADPOINTS') {
      //Loadpoints
      const loadpoints = counts?.areas
        ?.map((area) => area.zones)
        .flat()
        ?.map((zone) => zone?.loadpoints)
        .flat();

      mappedLoadpoints.forEach((element) => {
        const count = loadpoints?.find(
          (lp) => lp?.loadpoint_id === element.id,
        );

        currentBadges.push(getBadgeProperties(element, count));
      });
    }
    setBadges(currentBadges.reverse());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentView, countOrPercentage]);
  return (
    <Group>
      {badges
        ? badges.map((badge, index) => {
            return (
              <ShapeLabel
                key={index}
                x={badge.x}
                y={badge.y}
                rotation={badge.rotation}
                width={badge.width}
                text={badge.text}
              />
            );
          })
        : null}
    </Group>
  );
};

export default PackageCounts;
