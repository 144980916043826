import {
  createStyles,
  Divider,
  Grid,
  InputAdornment,
  Paper,
  Tabs,
  TextField,
  Theme,
  useMediaQuery,
  useTheme,
  withStyles,
} from '@material-ui/core';
import classNames from 'classnames';
import React, { useState, useEffect, useCallback } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { AuthRoutes } from '../../interfaces/routes';
import { Box, CSButton, CSTextField } from '../primitives';
import { Search as SearchIcon } from '@material-ui/icons';
import VisibilitySensor from 'react-visibility-sensor';
import debounce from 'lodash/debounce';
import RouteLeavingGuard from '../routeLeavingGuard/RouteLeavingGuard';
import browserHistory from '../../utils/browserHistory';
import {
  configurationUrlParams,
  getInteraction,
  getMode,
  getOrganizationStationData,
  INTERACTION,
  Mode,
} from './utils';
import ConfirmationDialog from '../confirmationDialog/ConfirmationDialog';
import { Helmet } from 'react-helmet';
import { Organization, StationDetails } from 'cloudsort-client';
import enumToLabel from '../../utils/enumToLabel';
import { navbarStyles } from './navbar.styles';
// Redux
import { useAppDispatch } from '../../redux/store';
import { updateSearchQuery } from '../../redux/slices/searchSlice';
import CSTab from './CSTab/CSTab';

interface Props {
  classes: { [key: string]: string };
  hasUnsavedChanges: boolean;
  activeTab: TabsEnum;
  onSaveCallback: () => void;
  onResetDataCallback: () => void;
}

export enum TabsEnum {
  SETTINGS,
  MODULES,
  ROLES,
  STAFF,
  CUSTOMERS,
  CUSTOM_APPS,
}
interface ConfirmationDialogParams {
  title: string;
  msg: string;
  onPrimaryAction: () => void;
  onCancel: () => void;
}

const Navbar: React.FC<Props> = ({
  classes,
  hasUnsavedChanges,
  activeTab,
  onSaveCallback,
  onResetDataCallback,
}) => {
  //Org,station data
  const urlParams = useParams<configurationUrlParams>();
  const [organizationName, setOrganizationName] = useState<string>();
  const [stationName, setStationName] = useState<string>();
  //Navigation
  const [tab, setTab] = React.useState<TabsEnum>(activeTab);
  const [blockedTab, setBlockedTab] = useState<
    TabsEnum | undefined
  >();
  const [confirmationDialogParams, setConfirmationDialogParams] =
    useState<ConfirmationDialogParams | null>(null);
  //Theme
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));
  const [showFloatHeader, setShowFloatHeader] = useState(false);
  const [activeVisibilitySensor, setActiveVisibilitySensor] =
    useState(false);
  //Search
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [searchQueryValue, setSearchQueryValue] =
    useState<string>('');
  const dispatch = useAppDispatch();
  //Get the type of the current page
  const mode: Mode = getMode(urlParams.orgId, urlParams.stationId);

  const getAndSetOrganizationStationName = async () => {
    const data = await getOrganizationStationData(
      mode,
      Number(urlParams.orgId),
      Number(urlParams.stationId),
    );

    if (mode === Mode.ORGANIZATION) {
      setOrganizationName((data as Organization).name);
    }

    if (mode === Mode.STATION) {
      setStationName((data as StationDetails).name || undefined);
      setOrganizationName((data as StationDetails).organization_name);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateSearchDebounced = useCallback(
    debounce((query: string) => {
      dispatch(updateSearchQuery(query));
    }, 250),
    [],
  );

  useEffect(() => {
    updateSearchDebounced(searchQueryValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQueryValue]);

  useEffect(() => {
    getAndSetOrganizationStationName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParams.orgId, urlParams.stationId]);

  useEffect(() => {
    const onScroll = () => {
      setActiveVisibilitySensor(true);
      window.removeEventListener('scroll', onScroll);
    };
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', onScroll);
      setActiveVisibilitySensor(false);
    };
  }, [urlParams.orgId, urlParams.stationId]);

  const handleTabChange = (event: any, newValue: number) => {
    if (hasUnsavedChanges) {
      setBlockedTab(newValue);
    } else {
      setTab(newValue);
    }
  };

  const discardChanges = () => {
    if (blockedTab) {
      setTab(blockedTab);
      setBlockedTab(undefined);
    }
  };

  const onSaveButtonCallback = () => {
    setConfirmationDialogParams({
      title: 'Save',
      msg: 'Are you sure you want to save?',
      onPrimaryAction: () => {
        onSaveCallback();
        setConfirmationDialogParams(null);
      },
      onCancel: () => {
        setConfirmationDialogParams(null);
      },
    });
  };

  const onCancelButtonCallback = () => {
    setConfirmationDialogParams({
      title: 'Cancel',
      msg: 'Are you sure you want to cancel? All unsaved changes will be lost.',
      onPrimaryAction: () => {
        onResetDataCallback();
        setConfirmationDialogParams(null);
      },
      onCancel: () => {
        setConfirmationDialogParams(null);
      },
    });
  };

  const getPageTitle = () => {
    if (mode === Mode.CLOUDSORT) {
      return `CloudSort ${enumToLabel(
        TabsEnum[activeTab],
      )} Configuration`;
    }
    if (mode === Mode.ORGANIZATION) {
      return `CloudSort ${enumToLabel(
        TabsEnum[activeTab],
      )} Configuration for ${organizationName}`;
    }
    if (mode === Mode.STATION) {
      return `CloudSort ${enumToLabel(
        TabsEnum[activeTab],
      )} Configuration for ${stationName}`;
    }
    return '';
  };

  const renderDesktopNavbar = () => {
    return (
      tab !== TabsEnum.CUSTOMERS &&
      tab !== TabsEnum.STAFF && (
        <Grid
          item
          xs={4}
          style={{
            alignSelf: 'center',
          }}
        >
          <Box
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              alignSelf: 'center',
            }}
          >
            <Box
              fontWeight={400}
              style={{
                fontSize: 14,
                color: theme.palette.quaternary?.main,
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                alignContent: 'center',
              }}
              className={
                isSearchFocused
                  ? classes.hideCancelSaveButtons
                  : classes.showCancelSaveButtons
              }
            >
              {getInteraction(mode) === INTERACTION.WRITE && (
                <>
                  <CSButton
                    variant='outlined'
                    color='secondary'
                    data-testid='top-navigation-cancel-changes-btn'
                    disabled={!hasUnsavedChanges}
                    onClick={() => {
                      if (hasUnsavedChanges) {
                        onCancelButtonCallback();
                      }
                    }}
                    style={{
                      marginRight: '15px',
                      cursor: hasUnsavedChanges
                        ? 'pointer'
                        : 'not-allowed',
                    }}
                  >
                    Cancel
                  </CSButton>

                  <CSButton
                    variant='contained'
                    color='secondary'
                    className={classes.saveButton}
                    data-testid='top-navigation-save-changes-btn'
                    disabled={!hasUnsavedChanges}
                    style={{
                      cursor: hasUnsavedChanges
                        ? 'pointer'
                        : 'not-allowed',
                    }}
                    onClick={() => {
                      if (hasUnsavedChanges) {
                        onSaveButtonCallback();
                      }
                    }}
                  >
                    Save
                  </CSButton>
                </>
              )}
            </Box>
            {tab === TabsEnum.MODULES && (
              <CSTextField
                className={
                  isSearchFocused
                    ? classes.searchExpanded
                    : classes.search
                }
                data-testid='search-input'
                placeholder='Search'
                variant='filled-outlined'
                onFocus={() => setIsSearchFocused(true)}
                onBlur={() => setIsSearchFocused(false)}
                startAdornment={<SearchIcon />}
                value={searchQueryValue}
                onChange={(event: any) => {
                  setSearchQueryValue(event.target.value);
                }}
                onClear={() => {
                  setSearchQueryValue('');
                }}
              />
            )}
          </Box>
        </Grid>
      )
    );
  };
  const renderMobileNavbar = () => {
    return (
      tab !== TabsEnum.CUSTOMERS &&
      tab !== TabsEnum.STAFF && (
        <Grid
          container
          justifyContent='space-between'
          className={classes.mobileSearchContainer}
        >
          <Grid
            item
            xs={6}
            style={{
              alignSelf: 'center',
            }}
          >
            {tab === TabsEnum.MODULES && (
              <TextField
                placeholder='Configuration Search'
                className={classes.mobileSearchField}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchIcon style={{ color: '#8E8E93' }} />
                    </InputAdornment>
                  ),
                  style: {
                    fontSize: '13px',
                    maxHeight: '30px',
                    width: 'auto',
                  },
                }}
                variant='outlined'
                value={searchQueryValue}
                onChange={(event: any) => {
                  setSearchQueryValue(event.target.value);
                }}
              />
            )}
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            {getInteraction(mode) === INTERACTION.WRITE && (
              <>
                <CSButton
                  className={classes.mobileSearchButton}
                  variant='contained'
                  color='secondary'
                  disabled={!hasUnsavedChanges}
                  onClick={() => {
                    if (hasUnsavedChanges) {
                      onSaveButtonCallback();
                    }
                  }}
                >
                  Save
                </CSButton>
                <CSButton
                  color='secondary'
                  variant='outlined'
                  disabled={!hasUnsavedChanges}
                  onClick={() => {
                    if (hasUnsavedChanges) {
                      onCancelButtonCallback();
                    }
                  }}
                >
                  Cancel
                </CSButton>
              </>
            )}
          </Grid>
          <Grid item xs={12} style={{ marginTop: 10 }}>
            <Divider className={classes.divider} />
          </Grid>
        </Grid>
      )
    );
  };
  return (
    <>
      <Helmet>
        <title>{getPageTitle()}</title>
      </Helmet>
      <RouteLeavingGuard
        when={true}
        navigate={(path) => browserHistory.push(path)}
        resetGlobalUnsavedChanges={discardChanges}
        forceOpen={blockedTab !== undefined}
        onAfterCancel={() => {
          setBlockedTab(undefined);
        }}
        onSave={() => {
          onSaveCallback();
        }}
        shouldBlockNavigation={(location) => {
          return (
            browserHistory.location !== location && hasUnsavedChanges
          );
        }}
      />

      {confirmationDialogParams && (
        <ConfirmationDialog
          dataTestIdPrefix='config-page-save-dialog'
          title={confirmationDialogParams.title}
          msg={confirmationDialogParams.msg}
          onPrimaryAction={confirmationDialogParams.onPrimaryAction}
          onCancel={confirmationDialogParams.onCancel}
          isOpen={true}
        />
      )}
      <Box mb={2} style={{ fontSize: '14px' }}>
        <b>Configurations </b>
        {mode === Mode.CLOUDSORT && 'CloudSort'}
        {mode === Mode.ORGANIZATION &&
          `CloudSort / ${organizationName || '...'}`}
        {mode === Mode.STATION &&
          `CloudSort / ${organizationName || '...'} / `}
        {mode === Mode.STATION && (
          <span
            style={{
              color: theme.palette.quaternary?.main,
              fontWeight: 700,
              textDecoration: 'underline',
            }}
          >{`${stationName || '...'}`}</span>
        )}
      </Box>

      <Box
        className={
          showFloatHeader ? classes.spacerOn : classes.spacerOff
        }
      />
      <Box mt={2} mb={3}>
        <Paper
          className={classNames(
            classes.paperWithoutShadow,
            { [classes.floatPaper]: showFloatHeader },
            { [classes.noShadow]: tab === TabsEnum.ROLES },
          )}
        >
          <Grid container justifyContent='space-between'>
            <Grid
              item
              xs={isMobileView ? 12 : 6}
              data-testid={'navigation-menu'}
            >
              <Tabs
                value={tab}
                onChange={handleTabChange}
                variant={isMobileView ? 'fullWidth' : 'scrollable'}
                classes={{
                  indicator: classes.tabsIndicator,
                }}
                centered={isMobileView}
              >
                <CSTab label='Settings' />
                <CSTab label='Modules' />
                <CSTab label='Roles' />
                {mode !== Mode.CLOUDSORT && <CSTab label='Staff' />}
                <CSTab label='Customers' />
                <CSTab label='Custom Apps' />
              </Tabs>
            </Grid>
            {!isMobileView && renderDesktopNavbar()}
          </Grid>
        </Paper>
        {isMobileView && renderMobileNavbar()}
        {tab === TabsEnum.SETTINGS && (
          <Redirect
            to={`${AuthRoutes.CONFIGURATION}${
              urlParams.orgId
                ? '/organization/' + urlParams.orgId
                : ''
            }${
              urlParams.stationId
                ? '/station/' + urlParams.stationId
                : ''
            }`}
          />
        )}
        {tab === TabsEnum.MODULES && (
          <Redirect
            to={`${AuthRoutes.CONFIGURATION}${
              urlParams.orgId
                ? '/organization/' + urlParams.orgId
                : ''
            }${
              urlParams.stationId
                ? '/station/' + urlParams.stationId
                : ''
            }/modules`}
          />
        )}
        {tab === TabsEnum.ROLES && (
          <Redirect
            to={`${AuthRoutes.CONFIGURATION}${
              urlParams.orgId
                ? '/organization/' + urlParams.orgId
                : ''
            }${
              urlParams.stationId
                ? '/station/' + urlParams.stationId
                : ''
            }/roles`}
          />
        )}
        {tab === TabsEnum.STAFF && (
          <Redirect
            to={`${AuthRoutes.CONFIGURATION}${
              urlParams.orgId
                ? '/organization/' + urlParams.orgId
                : ''
            }${
              urlParams.stationId
                ? '/station/' + urlParams.stationId
                : ''
            }/staff`}
          />
        )}
        {tab === TabsEnum.CUSTOMERS && (
          <Redirect
            to={`${AuthRoutes.CONFIGURATION}${
              urlParams.orgId
                ? '/organization/' + urlParams.orgId
                : ''
            }${
              urlParams.stationId
                ? '/station/' + urlParams.stationId
                : ''
            }/customers`}
          />
        )}
        {tab === TabsEnum.CUSTOM_APPS && (
          <Redirect
            to={`${AuthRoutes.CONFIGURATION}${
              urlParams.orgId
                ? '/organization/' + urlParams.orgId
                : ''
            }${
              urlParams.stationId
                ? '/station/' + urlParams.stationId
                : ''
            }/customApps`}
          />
        )}
      </Box>
      <VisibilitySensor
        active={activeVisibilitySensor}
        delayedCall
        scrollCheck
        offset={{ top: 66 }}
        intervalDelay={50}
      >
        {({ isVisible }) => {
          setTimeout(() => {
            if (isVisible !== null && !isMobileView) {
              setShowFloatHeader(
                !isVisible && activeVisibilitySensor,
              );
            }
          }, 0);
          return <div style={{ width: 1, height: 1 }} />;
        }}
      </VisibilitySensor>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...navbarStyles(theme),
  })),
)(Navbar);
