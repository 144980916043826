import {
  FlowPackageCount,
  LocationPackageCount,
} from 'cloudsort-client';
import React, { Fragment, useEffect, useState } from 'react';
import { Circle, Group, Line } from 'react-konva';
import DataAnalytycsService from '../../../services/DataAnalytycs.service';
import {
  addCenterPointToLine,
  findMinMaxCoordinates,
} from '../helpers';
import { ElementType, MappedElement } from '../StationLayout';
import ShapeLabel from './ShapeLabel';

interface Props {
  selectedElement?: { type: ElementType; id: number };
  mappedAreas: MappedElement[];
  mappedZones: MappedElement[];
  mappedLoadpoints: MappedElement[];
}

interface ConnectionLine {
  packageCount: string;
  line: number[];
}

const Connections: React.FC<Props> = ({
  selectedElement,
  mappedAreas,
  mappedZones,
  mappedLoadpoints,
}) => {
  const [points, setPoints] = useState<number[][]>();
  const [lines, setLines] = useState<ConnectionLine[]>();
  const [flowData, setFlowData] = useState<FlowPackageCount[]>([]);
  const [strokeWidth, setStrokeWidth] = useState<number>(5);

  const getFormattedCount = (
    item: FlowPackageCount | undefined,
  ): string => {
    if (item) {
      const count =
        (item.hasOwnProperty('accumulated_count')
          ? item.accumulated_count
          : item.number_of_packages) || 0;

      const mpp =
        (item.hasOwnProperty('accumulated_ppm')
          ? item.accumulated_ppm
          : item.minutes_per_package) || 0;

      if (count < 1000) {
        return '📦' + count.toString() + ' ⏱' + mpp.toFixed(1);
      } else if (count < 100000) {
        return (
          '📦' +
          (count / 1000).toFixed(1) +
          'K' +
          ' ⏱' +
          mpp.toFixed(1)
        );
      } else {
        return (
          '📦' +
          (count / 1000).toFixed() +
          'K' +
          ' ⏱' +
          mpp.toFixed(1)
        );
      }
    }
    return '📦0';
  };

  const filterOutFlowData = (
    data: FlowPackageCount[],
  ): FlowPackageCount[] => {
    const removeExtraIDs = (
      item: LocationPackageCount,
    ): LocationPackageCount => {
      if (item.dockdoor_id) {
        return { dockdoor_id: item.dockdoor_id };
      } else if (item.loadpoint_id) {
        return { loadpoint_id: item.loadpoint_id };
      } else if (item.zone_id) {
        return { zone_id: item.zone_id };
      } else {
        return { area_id: item.area_id };
      }
    };

    return data
      .filter(
        (item: FlowPackageCount) =>
          item.from_location &&
          Object.values(item.from_location).find((item) => item) &&
          item.to_location &&
          Object.values(item.to_location).find((item) => item),
      )
      .map((item) => {
        return {
          ...item,
          from_location: removeExtraIDs(item.from_location!),
          to_location: removeExtraIDs(item.to_location!),
        };
      });
  };

  useEffect(() => {
    //Fetch Flow Data
    DataAnalytycsService.getPackageFlow().then((data) => {
      setFlowData(filterOutFlowData(data.data.data || []));
    });
  }, []);

  useEffect(() => {
    if (mappedAreas) {
      const connectionPoints: number[][] = [];
      const connectionLines: ConnectionLine[] = [];
      if (selectedElement) {
        if (selectedElement.type === 'AREA') {
          setStrokeWidth(5);
          const selected = mappedAreas.filter(
            (item) =>
              item.id === selectedElement.id ||
              item.targetAreas?.includes(selectedElement.id),
          );

          selected.forEach((area) => {
            if (area.targetAreas?.length) {
              area.targetAreas.forEach((targetArea) => {
                if (
                  area.id === selectedElement.id ||
                  targetArea === selectedElement.id
                ) {
                  const { x: x1, y: y1 } = findMinMaxCoordinates(
                    area.pointsNoAngleOffset,
                  );
                  const { x: x2, y: y2 } = findMinMaxCoordinates(
                    mappedAreas.find(
                      (area) => area.id === targetArea,
                    )!.pointsNoAngleOffset,
                  );

                  connectionPoints.push(
                    [x1.mid, y1.mid],
                    [x2.mid, y2.mid],
                  );

                  connectionLines.push({
                    packageCount: getFormattedCount(
                      flowData.find(
                        (item) =>
                          item.from_location!.area_id === area.id &&
                          item.to_location!.area_id === targetArea,
                      ),
                    ),
                    line: [x1.mid, y1.mid, x2.mid, y2.mid],
                  });
                }
              });
            }
          });
        } else if (selectedElement.type === 'ZONE') {
          setStrokeWidth(3);
          const selected = mappedZones.filter(
            (item) =>
              item.id === selectedElement.id ||
              item.targetZones?.includes(selectedElement.id),
          );

          selected.forEach((zone) => {
            if (zone.targetZones?.length) {
              zone.targetZones.forEach((targetZone) => {
                if (
                  zone.id === selectedElement.id ||
                  targetZone === selectedElement.id
                ) {
                  const { x: x1, y: y1 } = findMinMaxCoordinates(
                    zone.pointsNoAngleOffset,
                  );

                  const endPoint = mappedZones.find(
                    (zone) => zone.id === targetZone,
                  );

                  if (endPoint) {
                    const { x: x2, y: y2 } = findMinMaxCoordinates(
                      endPoint.pointsNoAngleOffset,
                    );

                    connectionPoints.push(
                      [x1.mid, y1.mid],
                      [x2.mid, y2.mid],
                    );

                    connectionLines.push({
                      packageCount: getFormattedCount(
                        flowData.find(
                          (item) =>
                            item.from_location!.zone_id === zone.id &&
                            item.to_location!.zone_id === targetZone,
                        ),
                      ),
                      line: [x1.mid, y1.mid, x2.mid, y2.mid],
                    });
                  }
                }
              });
            }
          });
        } else if (selectedElement.type === 'LOADPOINT') {
          setStrokeWidth(3);
          const selectedLp = mappedLoadpoints.find(
            (item) => item.id === selectedElement.id,
          );

          if (selectedLp) {
            const { x: x1, y: y1 } = findMinMaxCoordinates(
              selectedLp.pointsNoAngleOffset,
            );

            let endNodeType: ElementType | undefined = undefined;

            const findEndNode = () => {
              if (selectedLp.targetLoadpoints?.length) {
                endNodeType = 'LOADPOINT';
                return mappedLoadpoints.find((lp) =>
                  selectedLp?.targetLoadpoints?.includes(lp.id),
                );
              } else if (selectedLp.targetZones?.length) {
                endNodeType = 'ZONE';
                return mappedZones.find((zone) =>
                  selectedLp?.targetZones?.includes(zone.id),
                );
              } else if (selectedLp.targetAreas?.length) {
                endNodeType = 'AREA';
                return mappedAreas.find((area) =>
                  selectedLp?.targetAreas?.includes(area.id),
                );
              }
            };

            const endNode = findEndNode();

            if (endNode) {
              const { x: x2, y: y2 } = findMinMaxCoordinates(
                endNode?.pointsNoAngleOffset,
              );

              connectionPoints.push(
                [x1.mid, y1.mid],
                [x2.mid, y2.mid],
              );

              const getPackageCount = () => {
                if (endNodeType === 'LOADPOINT') {
                  return getFormattedCount(
                    flowData.find(
                      (item) =>
                        item.from_location!.loadpoint_id ===
                          selectedLp.id &&
                        item.to_location!.loadpoint_id === endNode.id,
                    ),
                  );
                } else if (endNodeType === 'ZONE') {
                  return getFormattedCount(
                    flowData.find(
                      (item) =>
                        item.from_location!.loadpoint_id ===
                          selectedLp.id &&
                        item.to_location!.zone_id === endNode.id,
                    ),
                  );
                } else if (endNodeType === 'AREA') {
                  return getFormattedCount(
                    flowData.find(
                      (item) =>
                        item.from_location!.loadpoint_id ===
                          selectedLp.id &&
                        item.to_location!.area_id === endNode.id,
                    ),
                  );
                }
                return getFormattedCount(undefined);
              };

              connectionLines.push({
                packageCount: getPackageCount(),
                line: [x1.mid, y1.mid, x2.mid, y2.mid],
              });
            }
          }

          const pointToSelected = mappedLoadpoints.filter((item) =>
            item.targetLoadpoints?.includes(selectedElement.id),
          );

          pointToSelected.forEach((loadpoint) => {
            loadpoint.targetLoadpoints!.forEach((targetLp) => {
              const { x: x1, y: y1 } = findMinMaxCoordinates(
                loadpoint.pointsNoAngleOffset,
              );

              const endPoint = mappedLoadpoints.find(
                (lp) => lp.id === targetLp,
              );

              if (endPoint) {
                const { x: x2, y: y2 } = findMinMaxCoordinates(
                  endPoint.pointsNoAngleOffset,
                );

                connectionPoints.push(
                  [x1.mid, y1.mid],
                  [x2.mid, y2.mid],
                );

                connectionLines.push({
                  packageCount: getFormattedCount(
                    flowData.find(
                      (item) =>
                        item.from_location!.loadpoint_id ===
                          loadpoint.id &&
                        item.to_location!.loadpoint_id === targetLp,
                    ),
                  ),
                  line: [x1.mid, y1.mid, x2.mid, y2.mid],
                });
              }
            });
          });
        }
      } else {
        //there is no selected element, show connections between all areas
        setStrokeWidth(5);
        mappedAreas.forEach((area) => {
          if (area.targetAreas?.length) {
            area.targetAreas.forEach((targetArea) => {
              const { x: x1, y: y1 } = findMinMaxCoordinates(
                area.pointsNoAngleOffset,
              );
              const { x: x2, y: y2 } = findMinMaxCoordinates(
                mappedAreas.find((area) => area.id === targetArea)!
                  .pointsNoAngleOffset,
              );

              connectionPoints.push(
                [x1.mid, y1.mid],
                [x2.mid, y2.mid],
              );

              connectionLines.push({
                packageCount: getFormattedCount(
                  flowData.find(
                    (item) =>
                      item.from_location?.area_id === area.id &&
                      item.to_location?.area_id === targetArea,
                  ),
                ),
                line: [x1.mid, y1.mid, x2.mid, y2.mid],
              });
            });
          }
        });
      }
      setPoints(connectionPoints);
      setLines(connectionLines);
    }
  }, [
    selectedElement,
    mappedAreas,
    mappedZones,
    mappedLoadpoints,
    flowData,
  ]);
  return (
    <Group>
      {lines &&
        lines.map((link, index) => {
          const width = link.packageCount.length * 6 + 16;
          return (
            <Fragment key={'connectionsGroup+' + index}>
              <Line
                points={addCenterPointToLine(link.line, {
                  x: 10,
                  y: 10,
                })}
                strokeWidth={strokeWidth}
                stroke={'black'}
                tension={0.5}
                key={'connectionsLine+' + index}
              />
              <ShapeLabel
                key={'connectionsPackageCount+' + index}
                x={(link.line[0] + link.line[2] - width) / 2}
                y={(link.line[1] + link.line[3]) / 2}
                rotation={0}
                width={width}
                text={link.packageCount}
              />
            </Fragment>
          );
        })}

      {points &&
        [...new Set(points)].map((point, index) => (
          <Circle
            x={point[0]}
            y={point[1]}
            radius={strokeWidth * 2}
            fill='#ffffff'
            shadowEnabled
            shadowColor='rgba(0,0,0,0.33)'
            shadowBlur={5}
            key={'connectionsPoint+' + index}
          />
        ))}
    </Group>
  );
};

export default Connections;
