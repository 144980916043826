import {
  Customer,
  CustomerConfigsApi,
  CustomersApi,
} from 'cloudsort-client';
import axios from '../utils/axios';
import { parseParams } from './utils/parseParams';

class Customers {
  private api: CustomersApi;
  private configsApi: CustomerConfigsApi;
  constructor() {
    this.api = new CustomersApi(undefined, undefined, axios as any);
    this.configsApi = new CustomerConfigsApi(
      undefined,
      undefined,
      axios as any,
    );
    this.getAll = this.getAll.bind(this);
    this.getConfigurations = this.getConfigurations.bind(this);
  }

  public async getAll(
    page?: number,
    search?: string,
    pageSize?: number,
    stationId?: number,
    organizationId?: number,
  ) {
    return this.api.customersList({
      ...parseParams({
        search,
        page,
        pageSize,
        stationId,
        organizationId,
      }),
    });
  }

  public async getById(
    id: number,
    stationId?: number,
    organizationId?: number,
  ) {
    return this.api.customersRead({
      id: id,
      ...parseParams({
        stationId: stationId,
        organizationId: organizationId,
      }),
    });
  }

  public async checkEmail(email: string) {
    return this.api.customersLookup({ data: { email } });
  }

  public async createCustomer(
    email: Customer['email'],
    name: Customer['identifier'],
  ) {
    return this.api.customersCreate({
      data: {
        email: email,
        identifier: name,
      },
    });
  }

  public async updateNameEmail(
    customerId: number,
    name: Customer['identifier'],
    email: Customer['email'],
  ) {
    return this.api.customersPartialUpdate({
      id: customerId,
      data: {
        identifier: name,
        email: email,
      },
    });
  }

  public async assignToOrganizationOrStation(
    customerId: number,
    organizationId: number | undefined,
    stationId: number | undefined,
  ) {
    return this.api.customersPartialUpdate({
      id: customerId,
      data: {
        permission_data: {
          station: stationId,
          organization: organizationId,
        },
      },
    });
  }

  public async removeFromOrganizationOrStation(
    customerId: number,
    organizationId: number | undefined,
    stationId: number | undefined,
  ) {
    return this.api.customersDelete({
      id: customerId,
      organizationId: organizationId,
      stationId: stationId,
    });
  }

  public async deleteCustomer(customerId: number) {
    return this.api.customersDelete({
      id: customerId,
    });
  }

  public async getConfigurations(
    customerId: number,
    stationId?: number,
    organizationId?: number,
    pageIndex?: number,
    rowsPerPage?: number,
  ) {
    return this.configsApi.customerConfigsList({
      ...parseParams({
        organization: organizationId,
        customer: customerId,
      }),
      station: stationId ? [stationId] : [],
      page: pageIndex,
      pageSize: rowsPerPage,
    });
  }

  public async getConfigurationById(configurationId: string) {
    return this.configsApi.customerConfigsRead({
      id: configurationId,
    });
  }

  public async deleteConfiguration(configurationId: string) {
    return this.configsApi.customerConfigsDelete({
      id: configurationId,
    });
  }
}

export default new Customers();
