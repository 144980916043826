import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { Checkbox, CSButton, Typography } from '../primitives';
import { Transition } from '../confirmationDialog/ConfirmationDialog';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import {
  Paper,
  Grid,
  FormControlLabel,
  Box,
  Card,
  CardContent,
  DialogTitle,
  Theme,
} from '@material-ui/core';
import detailsPageStyles from '../commonStyles/detailsPage.style';
import ErrorHandler from '../../utils/ErrorHandler';
import { formatFirstNItemsAndMore } from '../DetailsPagesFunctions';

//Types
import { AxiosError } from 'axios';
import {
  APIExceptionErrorCodeEnum,
  LoadPlanImportSummary,
  StaffPermissionPermissionsEnum,
} from 'cloudsort-client';

// Icons
import CloudUploadOutlined from '@material-ui/icons/CloudUploadOutlined';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';

// Services
import browserHistory from '../../utils/browserHistory';
import { AuthRoutes } from '../../interfaces/routes';
import PermissionsService from '../../services/Permissions.service';
import configurationUtils from '../../utils/configurationUtils';
import LoadPlansService from '../../services/LoadPlans.service';
import { CSVLink } from 'react-csv';
import { CommonPropTypes } from 'react-csv/components/CommonPropTypes';
import CSDialogTitleWithIcon from '../primitives/csDialogTitleWithIcon/CSDialogTitleWithIcon';
import { DialogIcons } from '../primitives/csDialogTitleWithIcon/CSDialogTitleWithIconTypes';
import CSDialogAlert from '../primitives/csDialogAlert/CSDialogAlert';
import CSFileDropZone from '../primitives/csUploadDialog/csFileDropZone/CSFileDropZone';

interface Props {
  classes: { [key: string]: string };
  onAfterClose: () => void;
  isOpen: boolean;
  updateParent: () => void;
}

const UploadCSVDialog: React.FC<Props> = ({
  isOpen,
  classes,
  onAfterClose,
  updateParent,
}) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState<{
    message: string;
    data: CommonPropTypes['data'] | null;
  }>();
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [isUpload, setIsUpload] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const [dryRunComplete, setDryRunComplete] =
    useState<boolean>(false);
  const [activateImmediately, setActivateImmediately] =
    useState<boolean>(true);
  const [dryRunResults, setDryRunResults] =
    useState<LoadPlanImportSummary>();
  const [selectedFile, setSelectedFile] = useState<File>();

  const stopsLabels = {
    singular: configurationUtils.getPageTitle(true, 'STOP'),
    plural: configurationUtils.getPageTitle(false, 'STOP'),
  };

  const loadPlanLabels = {
    singular: configurationUtils.getPageTitle(true, 'LOADPLAN'),
    plural: configurationUtils.getPageTitle(false, 'LOADPLAN'),
  };

  const handleClose = () => {
    setDryRunComplete(false);
    setIsUploading(false);
    setSelectedFile(undefined);
    setError(undefined);
    setIsUpload(false);
    onAfterClose();
  };

  const onUploadProgress = (progressEvent: any) => {
    const percentCompleted = Math.floor(
      (progressEvent.loaded * 100) / progressEvent.total,
    );
    setUploadProgress(percentCompleted);
  };

  const handleError = async (e: AxiosError) => {
    const errorData = e.response?.data as any; // There is no csv_import_issues on the api-js
    const knownError =
      errorData.error_code ===
        APIExceptionErrorCodeEnum.RESOURCE_CONFLICT ||
      ['csv_parser_failure', 'import_failure'].includes(
        errorData.error_code,
      );
    if (errorData && knownError) {
      //Handle resource conflict
      if (
        errorData.error_code ===
        APIExceptionErrorCodeEnum.RESOURCE_CONFLICT
      ) {
        const conflictingZipcodes =
          errorData.error_context?.conflicts?.map(
            (item: any) => item.zipcode,
          ) || [];
        setError({
          message:
            'The following Zip Codes are part of multiple schemes from the same carrier: ' +
            formatFirstNItemsAndMore(conflictingZipcodes, 10), //show first 10
          data: null,
        });
      }
      //Handle csv parser failure
      else if (errorData.error_code === 'csv_parser_failure') {
        const singleProblem = `Failed to parse the CSV file. We found 1 problem (${
          errorData.error_context?.csv_import_issues[0][0]
        }) at row ${
          Object.keys(errorData.error_context.csv_import_issues)[0]
        }.`;
        const multipleProblems = `Failed to parse the CSV file. We found ${errorData.error_context.csv_import_issues.length} errors.`;
        setError({
          message:
            errorData.error_context.csv_import_issues.length > 1
              ? multipleProblems
              : singleProblem,
          data:
            errorData.error_context.csv_import_issues.length > 1
              ? errorData.error_context.csv_import_issues
              : null,
        });
      }
      //Handle import failure
      else if (errorData.error_code === 'import_failure') {
        const numberOfIssues =
          errorData.error_context.import_issues.length;

        setError({
          message: `Failed to import the CSV file. There ${
            numberOfIssues > 1 ? 'were' : 'was'
          } ${numberOfIssues} issues.`,
          data:
            errorData.error_context.import_issues.length > 1
              ? errorData.error_context.import_issues?.map(
                  (issue: any) => {
                    return {
                      index: issue.index,
                      error_message: issue.error_message,
                    };
                  },
                )
              : null,
        });
      }
    } else
      setError({
        message: await ErrorHandler.getLabel(e as AxiosError),
        data: null,
      });
  };

  const createNewLoadPlan = async () => {
    setShowProgress(true);
    setError(undefined);
    try {
      const lp = await LoadPlansService.create();
      browserHistory.push(
        AuthRoutes.LOADPLAN + '/' + lp.data.id + '/true',
      );
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  const uploadLoadplanFile = async (dryRun: boolean = true) => {
    setShowProgress(true);
    setError(undefined);
    try {
      if (dryRun) {
        setIsUploading(true);
        const response = await LoadPlansService.import({
          dryRun,
          file: selectedFile,
          //generateDestinations: true, TODO -- Remove if implicit ttrue
          options: {
            onUploadProgress,
          },
        });

        setDryRunResults(response.data);
        setDryRunComplete(true);
        setShowProgress(false);
        setIsUploading(false);
      } else {
        const response = await LoadPlansService.import({
          dryRun,
          fileId: dryRunResults?.file_id,
          setActive:
            PermissionsService.hasPermission(
              StaffPermissionPermissionsEnum.LOADPLAN_WRITE,
            ) && activateImmediately,
        });
        if (!activateImmediately) {
          browserHistory.push(
            AuthRoutes.LOADPLAN +
              '/' +
              response.data.loadplan +
              '/true',
          );
        }
        updateParent(); // notify parent component to re-render table
        handleClose();
      }
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  const renderFieldDescription = (text: string) => (
    <Typography
      variant='body2'
      className={classes.marginBottom10}
      color={{ color: 'grey', variant: 'A400' }}
    >
      {text}
    </Typography>
  );

  const renderModeSelection = () => {
    return (
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Card elevation={2} className={classes.cardRoot}>
            <CardContent
              className={classes.cardContent}
              onClick={() => {
                createNewLoadPlan();
              }}
            >
              <Box className={classes.iconBox}>
                <AddBoxOutlinedIcon className={classes.cardIcon} />
              </Box>
              <Box fontWeight='fontWeightBold' p={2}>
                <Typography variant={'h6'}>
                  Start from scratch
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card elevation={2} className={classes.cardRoot}>
            <CardContent
              className={classes.cardContent}
              onClick={() => {
                setIsUpload(true);
              }}
            >
              <Box className={classes.iconBox}>
                <img
                  src={`${
                    process.env.REACT_APP_BASENAME || ''
                  }/icons/csv.svg`}
                  alt={`CS ${loadPlanLabels.singular}`}
                  className={classes.cardIconCsv}
                />
                <CloudUploadOutlined className={classes.cardIcon} />
              </Box>
              <Box fontWeight='fontWeightBold' p={2}>
                <Typography variant={'h6'}>
                  Import {loadPlanLabels.singular}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <div>
      {showProgress && <ProgressIndicator />}
      <Dialog
        data-testid='upload-CSV-dialog'
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={(e) => {
          handleClose();
        }}
      >
        {!isUpload && (
          <>
            <DialogTitle>
              <CSDialogTitleWithIcon
                title={`Add a new ${loadPlanLabels.singular}`}
                icon={DialogIcons.ADD}
              />
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
              {renderModeSelection()}
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <CSButton
                variant='outlined'
                color='secondary'
                onClick={(e) => {
                  handleClose();
                }}
              >
                Close
              </CSButton>
            </DialogActions>
          </>
        )}
        {isUpload && (
          <>
            <DialogTitle>
              <CSDialogTitleWithIcon
                title={`Import  ${loadPlanLabels.singular}`}
                icon={DialogIcons.UPLOAD}
              />
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
              <div data-testid={'import-load-plan-dialog-content'}>
                {!dryRunComplete && !isUploading && (
                  <>
                    <CSFileDropZone
                      acceptedFileTypesLowercase={['csv']}
                      dataTestId='import-load-plan:drop-zone'
                      onSelectFile={setSelectedFile}
                    />
                    <Typography
                      variant='body2'
                      color={{ color: 'grey', variant: 'A400' }}
                      className={classes.marginBottom30}
                    >
                      Supported file types: .CSV
                    </Typography>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Typography
                          variant='h6'
                          className={classes.marginBottom10}
                        >
                          Columns
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          variant='body2'
                          className={classes.marginBottom10}
                        >
                          stop_name
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        {renderFieldDescription(
                          `the name of the ${stopsLabels.singular} this
                          scheme is associated with; if empty will
                          default to the same value as the scheme_name`,
                        )}
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          variant='body2'
                          className={classes.marginBottom10}
                        >
                          scheme_name
                          <span className={classes.requiredHighlight}>
                            {' '}
                            *
                          </span>
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        {renderFieldDescription(
                          'the name of the scheme entry',
                        )}
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          variant='body2'
                          className={classes.marginBottom10}
                        >
                          carrier
                          <span className={classes.requiredHighlight}>
                            {' '}
                            *
                          </span>
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        {renderFieldDescription(
                          'the carrier identifier of this scheme (e.g. USPS)',
                        )}
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          variant='body2'
                          className={classes.marginBottom10}
                        >
                          zipcode
                          <span className={classes.requiredHighlight}>
                            {' '}
                            *
                          </span>
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        {renderFieldDescription(
                          'the zipcode to assign to the scheme',
                        )}
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          variant='body2'
                          className={classes.marginBottom10}
                        >
                          owner
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        {renderFieldDescription(
                          'owner of the scheme',
                        )}
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          variant='body2'
                          className={classes.marginBottom10}
                        >
                          sort_param
                        </Typography>
                      </Grid>
                      <Grid item xs={8}>
                        {renderFieldDescription(
                          'the sort parameter to use on this zipcode  entry',
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        className={classes.marginBottom10}
                      >
                        <Typography
                          variant='body_sm'
                          className={classes.requiredHighlight}
                        >
                          * Required Fields
                        </Typography>
                      </Grid>
                    </Grid>
                    <Typography
                      variant='body_sm'
                      className={classes.marginBottom10}
                    >
                      The selected file will be imported as a new,
                      full {loadPlanLabels.singular} not affecting
                      existing operations until manually activated.
                      Before the
                      {loadPlanLabels.singular}
                      is imported, a short summary will be displayed
                      allowing a review of the data before finalizing
                      the import.
                    </Typography>
                  </>
                )}
                {(isUploading || dryRunComplete) && (
                  <Paper
                    elevation={2}
                    className={classes.fileContainer}
                    data-testid={'imported-plan-info'}
                  >
                    <Grid container>
                      <Grid item xs={2} className={classes.padding10}>
                        <img
                          className={classes.img}
                          src={`${
                            process.env.REACT_APP_BASENAME || ''
                          }/icons/csv-file.png`}
                          alt={`CS ${loadPlanLabels.singular}`}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={10}
                        className={classes.padding10}
                      >
                        <p className={classes.uploadFileInfo}>
                          <span className={classes.uploadFilename}>
                            {selectedFile?.name}
                          </span>

                          <span className={classes.uploadPercentage}>
                            {uploadProgress}%
                          </span>
                        </p>
                        <div className={classes.uploadProgressBar}>
                          <div
                            className={classes.uploadProgressBarInner}
                            style={{
                              width: `${uploadProgress}%`,
                            }}
                          ></div>
                        </div>
                      </Grid>
                    </Grid>
                  </Paper>
                )}
                {error && (
                  <CSDialogAlert alertMessage={error.message} />
                )}
                {error?.data && (
                  <Typography
                    variant='h6'
                    className={classes.marginTop10}
                  >
                    <CSVLink
                      data={error?.data || ''}
                      className={classes.errorLink}
                      filename={`${selectedFile?.name}-import-errors-log.csv`}
                    >
                      Download error log
                    </CSVLink>
                  </Typography>
                )}
                {dryRunComplete && (
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      className={classes.marginBottom20}
                    >
                      <Typography variant='h6'>
                        New {loadPlanLabels.singular}
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      container
                      xs={12}
                      className={classes.marginBottom20}
                    >
                      <Grid
                        item
                        container
                        xs={8}
                        direction={'column'}
                      >
                        <Typography variant='body2'>
                          Added {stopsLabels.plural}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          variant='h6'
                          color={{ color: 'grey', variant: 'A400' }}
                          className={classes.textAlignRight}
                          data-testid={'loadplan-added-stops'}
                        >
                          {dryRunResults?.stops_added.length}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      container
                      xs={12}
                      className={classes.marginBottom20}
                    >
                      <Grid
                        item
                        container
                        xs={8}
                        direction={'column'}
                      >
                        <Typography variant='body2'>
                          Removed {stopsLabels.plural}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          color={{ color: 'grey', variant: 'A400' }}
                          variant='h6'
                          data-testid={'loadplan-removed-stops'}
                          className={classes.textAlignRight}
                        >
                          {dryRunResults?.stops_removed.length}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      container
                      xs={12}
                      className={classes.marginBottom20}
                    >
                      <Grid
                        item
                        container
                        xs={8}
                        direction={'column'}
                      >
                        <Typography variant='body2'>
                          Total {stopsLabels.plural}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          variant='h6'
                          color='primary'
                          data-testid={'loadplan-total-stops'}
                          className={classes.textAlignRight}
                        >
                          {dryRunResults?.stops_total}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      container
                      xs={12}
                      className={classes.marginBottom20}
                    >
                      <Grid
                        item
                        container
                        xs={8}
                        direction={'column'}
                      >
                        <Typography variant='body2'>
                          Moved ZIP Codes
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          variant='h6'
                          color={{ color: 'grey', variant: 'A400' }}
                          data-testid={'loadplan-moved-zip-codes'}
                          className={classes.textAlignRight}
                        >
                          {dryRunResults?.zipcodes_moved}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      container
                      xs={12}
                      className={classes.marginBottom20}
                    >
                      <Grid
                        item
                        container
                        xs={8}
                        direction={'column'}
                      >
                        <Typography variant='body2'>
                          Total ZIP Codes
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography
                          variant='h6'
                          color='primary'
                          data-testid={'loadplan-total-zip-codes'}
                          className={classes.textAlignRight}
                        >
                          {dryRunResults?.zipcodes_total}
                        </Typography>
                      </Grid>
                    </Grid>
                    {dryRunResults?.issues &&
                      (dryRunResults?.issues).length > 0 && (
                        <>
                          <Grid
                            item
                            container
                            xs={12}
                            className={classes.marginBottom20}
                          >
                            <Grid
                              item
                              container
                              xs={8}
                              direction={'column'}
                            >
                              <Typography variant='body2'>
                                Warnings
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography
                                variant='h6'
                                color='error'
                                data-testid={
                                  'loadplan-warnings-count'
                                }
                                className={classes.textAlignRight}
                              >
                                {(dryRunResults?.issues).length}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid item container xs={12}>
                            <Grid
                              item
                              container
                              xs={6}
                              direction={'column'}
                            >
                              <Typography variant='h6'>
                                <CSVLink
                                  data={
                                    (dryRunResults.issues as unknown as CommonPropTypes['data']) ||
                                    ''
                                  }
                                  filename={`${selectedFile?.name}-import-warnings-log.csv`}
                                >
                                  Download warnings log
                                </CSVLink>
                              </Typography>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    {PermissionsService.hasPermission(
                      StaffPermissionPermissionsEnum.LOADPLAN_WRITE,
                    ) && (
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={activateImmediately}
                              onChange={(e) => {
                                setActivateImmediately(
                                  e.target.checked,
                                );
                              }}
                            />
                          }
                          label={`Activate ${loadPlanLabels.singular} immediately`}
                          data-testid='activate-load-plan-immediately'
                        />
                      </Grid>
                    )}

                    <Box m={0.8} />
                  </Grid>
                )}
              </div>
            </DialogContent>
            {!dryRunComplete && (
              <DialogActions className={classes.dialogActions}>
                <CSButton
                  variant='outlined'
                  color='secondary'
                  onClick={(e) => {
                    handleClose();
                  }}
                >
                  Cancel
                </CSButton>
                <CSButton
                  variant='contained'
                  color='secondary'
                  data-testid={'upload-selected-load-plan'}
                  disabled={!selectedFile || !!error}
                  onClick={(e) => {
                    uploadLoadplanFile();
                  }}
                >
                  Import
                </CSButton>
              </DialogActions>
            )}
            {dryRunComplete && (
              <DialogActions className={classes.dialogActions}>
                <CSButton
                  variant='outlined'
                  color='secondary'
                  onClick={(e) => {
                    handleClose();
                  }}
                >
                  Cancel
                </CSButton>
                <CSButton
                  variant='contained'
                  color='secondary'
                  onClick={(e) => {
                    uploadLoadplanFile(false);
                  }}
                  data-testid={'import-load-plan'}
                  disabled={!!error}
                >
                  Import
                </CSButton>
              </DialogActions>
            )}
          </>
        )}
      </Dialog>
    </div>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    dialogContent: {
      maxWidth: '100%',
      width: '500px',
      padding: '0 40px',
    },
    fileContainer: {
      margin: '20px 0',
      padding: '0 10px',
    },
    uploadFileInfo: {
      margin: '0 0 5px 0',
    },
    uploadFilename: {
      ...theme.typography.body2,
      display: 'inline-block',
      maxWidth: '200px',
      overflow: 'hidden',
    },
    uploadPercentage: {
      color: theme.palette.grey.A400,
      float: 'right',
    },
    padding10: { padding: 10 },
    uploadProgressBar: {
      width: '100%',
      background: theme.palette.grey.A200,
      height: '5px',
      borderRadius: '2px',
      overflow: 'hidden',
    },
    uploadProgressBarInner: {
      background: theme.palette.tertiary?.main,
      height: '5px',
    },
    iconBox: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      padding: '30px',
      backgroundColor: theme.palette.grey.A200,
    },
    cardRoot: {
      margin: '0 10px 10px 10px',
    },
    cardContent: {
      padding: '0px!important',
      paddingBottom: '0!important',
      cursor: 'pointer',
    },
    cardIcon: {
      margin: '10px auto',
      height: 'auto',
      width: 64,
      color: theme.palette.tertiary?.main,
    },
    cardIconCsv: {
      position: 'absolute',
      top: 10,
      right: 10,
      width: '30px',
      height: 'auto',
    },
    dialogActions: {
      padding: 24,
    },
    requiredHighlight: {
      color: theme.palette.tertiary?.main,
    },
    errorLink: {
      color: theme.palette.tertiary?.main,
    },
    marginTop10: {
      marginTop: 10,
    },
    marginBottom10: {
      marginBottom: 10,
    },
    marginBottom20: {
      marginBottom: 20,
    },
    marginBottom30: {
      marginBottom: 30,
    },
    textAlignRight: {
      textAlign: 'right',
    },
  })),
)(UploadCSVDialog);
