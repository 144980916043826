import FormControlLabel from '@material-ui/core/FormControlLabel';
import MuiCheckbox, {
  CheckboxProps,
} from '@material-ui/core/Checkbox';
import * as React from 'react';
import { withStyles } from '@material-ui/core';
import styles from './Checkbox.styles';
export interface ICheckboxProps extends CheckboxProps {
  label?: string;
  includeFormControlLabel?: boolean;
}

const Checkbox: React.FC<ICheckboxProps> = React.forwardRef(
  (props, ref) => {
    const {
      label,
      includeFormControlLabel = false,
      ...muiProps
    } = props;
    const ThemedMuiCheckbox = React.useMemo(
      () =>
        withStyles(styles, {
          name: 'CSCheckbox',
        })(MuiCheckbox),
      [],
    );
    return !includeFormControlLabel ? (
      <ThemedMuiCheckbox {...muiProps} ref={ref} />
    ) : (
      <FormControlLabel
        control={<ThemedMuiCheckbox {...muiProps} ref={ref} />}
        label={label}
      />
    );
  },
);

Checkbox.defaultProps = {
  color: 'primary',
};

export default Checkbox;
