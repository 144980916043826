export default {
  formControl: {
    minWidth: '12em',
    marginRight: 16,
    border: '1px solid #ddd',
    borderRadius: '3px',
    backgroundColor: '#fff',
    textTransform: 'none',
  },
  selectLabel: {
    top: '5px',
    left: '5px',
    right: '-20%',
  },
  selectPaper: {},
  selectList: {},
  selectMenu: {
    backgroundColor: 'transparent!important',
    padding: '5px',
    border: 'none',
  },
};
