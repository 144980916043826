//Services
import LocalStorageService from '../../services/LocalStorage.service';

export default {
  fromDate: () =>
    new Date(
      new Date(LocalStorageService.getSystemDate()).setHours(
        0,
        0,
        0,
        0,
      ),
    ).toISOString(),
  toDate: () =>
    new Date(
      new Date(LocalStorageService.getSystemDate()).setHours(
        23,
        59,
        59,
        999,
      ),
    ).toISOString(),
  isSystemDateToday: () =>
    LocalStorageService.getSystemDate().toDateString() ===
    new Date().toDateString(),
};
