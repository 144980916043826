import React, { useEffect, useState, useMemo } from 'react';
import { Typography, CSButton } from '../primitives';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import Layout from '../layout/Layout';
import ErrorHandler from '../../utils/ErrorHandler';
import ConfirmationDialog from '../confirmationDialog/ConfirmationDialog';
import { AuthRoutes } from '../../interfaces/routes';
import browserHistory from '../../utils/browserHistory';
import EventsPaginatedTable from '../eventsPaginatedTable/EventsPaginatedTable';
import { common } from '../../utils/strings';
import detailsPageStyles from '../commonStyles/detailsPage.style';
import {
  createStyles,
  Theme,
  withStyles,
} from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';
import { getLastUsedLabel, getBateryLabel } from './Devices';
import configurationUtils from '../../utils/configurationUtils';

// Icons
import DeleteIcon from '@material-ui/icons/Delete';

// Types
import { match } from 'react-router-dom';
import { AxiosError } from 'axios';
import {
  DeviceDetails,
  StaffPermissionPermissionsEnum,
} from 'cloudsort-client';

// Services
import EventsService from '../../services/Events.service';
import DevicesService from '../../services/Devices.service';
import PermissionsService from '../../services/Permissions.service';
import SingleDetail from '../primitives/singleDetail/SingleDetail';
import { Helmet } from 'react-helmet';
import sectionPageBaseStyle from '../commonStyles/sectionPageBase.style';
import CSSectionTitleSeparator from '../primitives/csSectionTitleSeparator/CSSectionTitleSeparator';
import useStationId from '../../hooks/useStationId';
import { CSSingleDetailMultiColumnContainer } from '../primitives/singleDetail/singleDetailMultiColumnContainer';
import CSBreadcrumbs from '../primitives/CSBreadcrumbs/CSBreadcrumbs';
import { CSTimeContainer } from '../primitives/csTimeContainer';
import CSDialogAlert from '../primitives/csDialogAlert/CSDialogAlert';

interface Props {
  classes: { [key: string]: string };
  match: match;
}

const DeviceDetailsComponent: React.FC<Props> = ({
  classes,
  match,
}) => {
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [showDeleteDialog, setShowDeleteDialog] =
    useState<boolean>(false);
  const [detailsData, setDetailsData] = useState<DeviceDetails>();
  const [error, setError] = useState<string>();
  useStationId(Number(detailsData?.station));

  const devicessLabels = useMemo(() => {
    return {
      singular: configurationUtils.getPageTitle(true, 'DEVICE'),
      plural: configurationUtils.getPageTitle(false, 'DEVICE'),
    };
  }, []);

  const fetchDetailsData = async () => {
    setShowProgress(true);
    try {
      const { data } = await DevicesService.getById(
        (match.params as any).id,
      );
      setDetailsData(data);
    } catch (e) {
      handleError(e as AxiosError);
    }
    setShowProgress(false);
  };

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e as AxiosError));
  };

  const deleteDevice = async () => {
    setShowProgress(true);
    try {
      await DevicesService.deleteDevice((match.params as any).id);
      browserHistory.push(AuthRoutes.DEVICE);
    } catch (e) {
      handleError(e as AxiosError);
      setShowProgress(false);
      setShowDeleteDialog(false);
    }
  };

  const fetchEvents = async (
    pageIndex: number,
    rowsPerPage: number,
    sortBy?: string,
  ) => {
    return EventsService.getAll({
      page: pageIndex,
      device: (match.params as any).id,
      pageSize: rowsPerPage,
      sortBy: sortBy as
        | 'timestamp'
        | 'area__name'
        | '-timestamp'
        | '-area__name',
    });
  };

  useEffect(() => {
    PermissionsService.redirectIfNoPermission('DEVICE_READ');
    if (!detailsData) {
      fetchDetailsData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort -
        ${configurationUtils.getPageTitle(
          true,
          'DEVICE',
        )} Details for ${detailsData?.device_id || ''}`}
        </title>
      </Helmet>
      <Layout navCurrent='DEVICE'>
        {showProgress && <ProgressIndicator />}

        <ConfirmationDialog
          dataTestIdPrefix={'device-details-'}
          data-testid={'device-details-delete-dialog'}
          title={`Remove ${configurationUtils.getPageTitle(
            true,
            'DEVICE',
          )}`}
          msg={`Are you sure you want to remove ${detailsData?.device_id}?`}
          primaryActionLabel={'Delete'}
          onPrimaryAction={deleteDevice}
          cancelLabel={'Cancel'}
          onCancel={() => {
            setShowDeleteDialog(false);
          }}
          isOpen={showDeleteDialog}
        />

        <Grid container spacing={2} alignItems='center'>
          {error && (
            <Grid item xs={12}>
              <CSDialogAlert
                data-testid='device-details-error-banner'
                alertMessage={error}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Box mb={5}>
              <CSBreadcrumbs
                breadcrumbs={[
                  {
                    label: devicessLabels.plural,
                    link:
                      AuthRoutes.DEVICE +
                      '?stationId=' +
                      detailsData?.station,
                  },
                  {
                    label: `${devicessLabels.singular} ${detailsData?.device_id}`,
                    selected: true,
                  },
                ]}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant='h3' component='h1'>
              {`${configurationUtils.getPageTitle(
                true,
                'DEVICE',
              )} Details for ${detailsData?.device_id || ''}`}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            className={classes.headerButtonContainer}
          >
            {PermissionsService.hasPermission(
              StaffPermissionPermissionsEnum.DEVICE_WRITE,
            ) && (
              <CSButton
                variant='outlined'
                color='secondary'
                data-testid={'device-details-delete-button'}
                fullWidth={false}
                onClick={(e) => {
                  e.preventDefault();
                  setError(undefined);
                  setShowDeleteDialog(true);
                }}
                startIcon={<DeleteIcon />}
              >
                Remove{' '}
                {configurationUtils.getPageTitle(true, 'DEVICE')}
              </CSButton>
            )}
          </Grid>
          <Grid item xs={12}>
            <CSSectionTitleSeparator />
          </Grid>
        </Grid>

        <Grid
          container
          data-testid={'device-details'}
          spacing={2}
          className={classes.marginBottom20}
        >
          <Grid item xs={12} sm={9}>
            <CSSingleDetailMultiColumnContainer
              elements={[
                <SingleDetail
                  inline={true}
                  label='Status'
                  value={detailsData?.active ? 'Active' : 'Inactive'}
                />,
                <SingleDetail
                  inline={true}
                  label={
                    configurationUtils.getPageTitle(true, 'DEVICE') +
                    ' ID'
                  }
                  value={detailsData?.device_id}
                />,
                <SingleDetail
                  inline={true}
                  label={'Battery'}
                  value={getBateryLabel(detailsData)}
                />,
                <SingleDetail
                  inline={true}
                  label={configurationUtils.getPageTitle(
                    true,
                    'STAFF',
                  )}
                  value={
                    detailsData?.current_user_full_name ||
                    common.emptyValue
                  }
                />,
                <SingleDetail
                  inline={true}
                  label='Station'
                  value={
                    detailsData?.station_name || common.emptyValue
                  }
                />,

                <SingleDetail
                  inline={true}
                  label={'Serial ID'}
                  value={
                    detailsData?.serial_id &&
                    detailsData.serial_id !== ''
                      ? detailsData.serial_id
                      : common.emptyValue
                  }
                />,
                <SingleDetail
                  inline={true}
                  label={'ICC ID'}
                  value={
                    detailsData?.icc_id && detailsData.icc_id !== ''
                      ? detailsData.icc_id
                      : common.emptyValue
                  }
                />,

                <SingleDetail
                  inline={true}
                  label='Last Used'
                  inlineWidth={'100%'}
                  value={getLastUsedLabel({
                    device_id: '',
                    last_event: detailsData?.last_event,
                  })}
                />,
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <CSTimeContainer
              hideArrowIndicator
              elements={[
                {
                  title: 'Software Version',
                  titleTestId: 'Software Version:label',
                  content: detailsData?.version || common.emptyValue,
                  contentTestId: 'Software Version:value',
                },
              ]}
            />
          </Grid>
        </Grid>

        {!!detailsData && (
          <EventsPaginatedTable
            sortableBy={['timestamp']}
            defaultSort='-timestamp'
            dataTestIdPrefix={'devices-details-events'}
            fetch={fetchEvents}
          />
        )}
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...sectionPageBaseStyle,
    marginBottom20: {
      marginBottom: 20,
    },
  })),
)(DeviceDetailsComponent);
