import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import { INTERACTION } from '../../utils';
import GeneralDesktop from './General.desktop';
import GeneralSearch from './General.search';
import GeneralMobile from './General.mobile';
import { CurrentView } from '../Modules';
interface Props {
  classes: { [key: string]: string };
  data: any;
  dataWeb: any;
  setPartialData: (timers: any) => void;
  setPartialWebData: (webData: any) => void;
  interaction?: INTERACTION;
  currentView: CurrentView;
  goToPreviousPage: () => void;
}

const General: React.FC<Props> = ({
  classes,
  data,
  dataWeb,
  interaction,
  setPartialData,
  setPartialWebData,
  currentView,
  goToPreviousPage,
}) => {
  const selectViewToRender = () => {
    if (currentView === 'Search') {
      return (
        <GeneralSearch
          data={data}
          dataWeb={dataWeb}
          interaction={interaction}
          setPartialData={setPartialData}
          setPartialWebData={setPartialWebData}
        />
      );
    }
    if (currentView === 'Mobile') {
      return (
        <GeneralMobile
          data={data}
          dataWeb={dataWeb}
          interaction={interaction}
          setPartialData={setPartialData}
          setPartialWebData={setPartialWebData}
          goToPreviousPage={goToPreviousPage}
        />
      );
    }
    if (currentView === 'Desktop') {
      return (
        <GeneralDesktop
          data={data}
          dataWeb={dataWeb}
          interaction={interaction}
          setPartialData={setPartialData}
          setPartialWebData={setPartialWebData}
        />
      );
    }
    return <></>;
  };

  return <>{data ? selectViewToRender() : ''}</>;
};

export default withStyles({})(General);
