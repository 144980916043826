import React, { useEffect, useState } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  Divider,
  Theme,
  IconButton,
} from '@material-ui/core';
import { CSButton, CSSwitch, Typography } from '../../../primitives';
import classNames from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Transition } from '../../../confirmationDialog/ConfirmationDialog';
import TextField from '@material-ui/core/TextField';
import detailsPageStyles from '../../../commonStyles/detailsPage.style';
import configPageStyles from '../../../commonStyles/configPage.style';
import { INTERACTION } from '../../utils';
import EditIcon from '../../../../utils/svgs/EditIcon';
import { processSearchQuery } from '../searchQuery';
import Highlighter from 'react-highlight-words';
import { CurrentView, ModuleName } from '../Modules';

// Redux
import { useSearchResultSections } from '../../useSearchResultSections';
import { onlyNumbers } from '../../../../utils/inputHelpers';
interface Props {
  classes: { [key: string]: string };
  data: any;
  dataKey: string;
  interaction?: INTERACTION;
  setPartialData: (data: any) => void;
  currentView: CurrentView;
}

const initDialogData = {
  primary_screen_reset_seconds: 0,
};

const Sorting: React.FC<Props> = ({
  classes,
  data,
  interaction,
  setPartialData,
  currentView,
}) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [dialogData, setDialogData] = useState<any>(initDialogData);
  const [searchResult, setSearchResult] = useState<string[]>([]);

  const SECTION_TITLE = 'Sorting';

  const { searchQuery, updateSections } = useSearchResultSections(
    ModuleName.GENERAL,
    SECTION_TITLE,
  );

  useEffect(() => {
    if (currentView === CurrentView.SEARCH) {
      const results = processSearchQuery(searchQuery, [
        SECTION_TITLE,
        'Primary Screen Reset',
        'Owner Required for Sort',
        data?.primary_screen_reset_seconds.toString(),
      ]);

      setSearchResult(results);

      updateSections(results);
    } else {
      setSearchResult([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, currentView]);

  const onAfterDialogClose = () => {
    setShowDialog(false);
    setDialogData(initDialogData);
  };

  const renderDialog = () => {
    return (
      <Dialog
        classes={{ paperScrollPaper: classes.dialogRoot }}
        open={showDialog}
        TransitionComponent={Transition}
        onClose={() => {
          onAfterDialogClose();
        }}
      >
        <DialogTitle>
          <Typography>Edit</Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogRoot}>
          <TextField
            data-testid='sorting-primary-screen-reset-dialog-seconds'
            autoFocus
            label={'Seconds'}
            type={'number'}
            InputLabelProps={{
              shrink: true,
              className: classes.inputNameLabel,
            }}
            InputProps={{
              disableUnderline: true,
              className: classes.inputName,
            }}
            inputProps={{
              min: '0',
              step: '1',
            }}
            classes={{
              root: classes.inputNameHolder,
            }}
            value={dialogData?.primary_screen_reset_seconds}
            onChange={(e) => {
              const value = e.target.value;
              setDialogData({
                ...dialogData,
                primary_screen_reset_seconds: onlyNumbers(value),
              });
            }}
          />
        </DialogContent>
        <DialogActions>
          <CSButton
            variant='outlined'
            color='secondary'
            onClick={() => {
              onAfterDialogClose();
            }}
          >
            Cancel
          </CSButton>
          <CSButton
            variant='contained'
            color='secondary'
            data-testid='sorting-primary-screen-reset-dialog-save-btn'
            onClick={() => {
              data.primary_screen_reset_seconds =
                dialogData.primary_screen_reset_seconds;
              setPartialData({
                ...data,
              });
              onAfterDialogClose();
            }}
          >
            Update
          </CSButton>
        </DialogActions>
      </Dialog>
    );
  };

  const renderComponent = () => {
    return (
      <>
        {renderDialog()}
        <Grid item sm={12}>
          <Box>
            <Box
              className={classNames(
                classes.moduleTitle,
                classes.pLeft,
              )}
              mb={1}
              mt={2}
            >
              <Highlighter
                highlightClassName={classes.searchResultsMark}
                searchWords={searchResult}
                autoEscape={true}
                textToHighlight={SECTION_TITLE}
              />
            </Box>

            <Box mt={2}>
              <Box mb={2}>
                <Grid
                  container
                  item
                  sm={12}
                  className={classes.textHeader}
                >
                  <Grid item sm={2} xs={4} className={classes.pLeft}>
                    Behaviour
                  </Grid>
                  <Grid item sm={7} xs={3}>
                    Enabled
                  </Grid>

                  <Grid item sm={2} xs={3}>
                    Reset Time (seconds)
                  </Grid>
                  <Grid item sm={1} xs={2} />
                </Grid>
                <Grid item xs={12}>
                  <Divider className={classes.divider} />
                </Grid>
              </Box>
              <Grid container item sm={12} className={classes.row}>
                <Grid
                  item
                  sm={2}
                  xs={4}
                  className={classNames(classes.text, classes.pLeft)}
                >
                  <Highlighter
                    highlightClassName={classes.searchResultsMark}
                    searchWords={searchResult}
                    autoEscape={true}
                    textToHighlight='Primary Screen Reset'
                  />
                </Grid>
                <Grid
                  item
                  sm={7}
                  xs={3}
                  className={classes.text}
                  style={{ paddingRight: 10 }}
                >
                  <CSSwitch
                    data-testid='sorting-primary-screen-reset-switch'
                    color='default'
                    size='small'
                    disabled={interaction === INTERACTION.READ}
                    checked={data?.primary_screen_reset_active}
                    onChange={() => {
                      data.primary_screen_reset_active =
                        !data.primary_screen_reset_active;
                      setPartialData({ ...data });
                    }}
                  />
                </Grid>
                <Grid
                  item
                  sm={2}
                  xs={3}
                  className={classNames(classes.text, classes.pLeft)}
                >
                  <Highlighter
                    highlightClassName={classes.searchResultsMark}
                    searchWords={searchResult}
                    autoEscape={true}
                    textToHighlight={data?.primary_screen_reset_seconds.toString()}
                  />
                </Grid>
                <Grid item sm={1} xs={2}>
                  <IconButton
                    data-testid='sorting-primary-screen-reset-edit-btn'
                    size='small'
                    className={classes.editIcon}
                    disabled={interaction === INTERACTION.READ}
                    onClick={() => {
                      setDialogData({
                        primary_screen_reset_seconds:
                          data?.primary_screen_reset_seconds,
                      });
                      setShowDialog(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid container item sm={12} className={classes.row}>
                <Grid
                  item
                  sm={2}
                  xs={4}
                  className={classNames(classes.text, classes.pLeft)}
                >
                  <Highlighter
                    highlightClassName={classes.searchResultsMark}
                    searchWords={searchResult}
                    autoEscape={true}
                    textToHighlight='Owner Required for Sort'
                  />
                </Grid>
                <Grid
                  item
                  sm={7}
                  xs={3}
                  className={classes.text}
                  style={{ paddingRight: 10 }}
                >
                  <CSSwitch
                    data-testid='owner-required-switch'
                    color='default'
                    size='small'
                    disabled={interaction === INTERACTION.READ}
                    checked={data?.owner_required}
                    onChange={() => {
                      data.owner_required = !data.owner_required;
                      setPartialData({ ...data });
                    }}
                  />
                </Grid>
                <Grid item sm={2} xs={3} />
                <Grid item sm={1} xs={2} />
              </Grid>
            </Box>
          </Box>
        </Grid>
      </>
    );
  };

  return currentView === CurrentView.SEARCH && !searchResult.length
    ? null
    : renderComponent();
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...configPageStyles(theme),
  })),
)(Sorting);
