import React, { Fragment, useState, useEffect } from 'react';
import clsx from 'clsx';
import browserHistory from '../../utils/browserHistory';
import { withStyles, useTheme } from '@material-ui/core/styles';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import {
  AlertBanner,
  CSButton,
  CSTextField,
  Typography,
} from '../primitives';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import SearchIcon from '@material-ui/icons/Search';
import Select from '@material-ui/core/Select';
import Link from '@material-ui/core/Link';
import styles from './paginatedTable.styles';
import ErrorHandler from '../../utils/ErrorHandler';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { Grid, Box } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import queryString from 'query-string';
import { useParams } from 'react-router-dom';
import { common } from '../../utils/strings';
import DOMPurify from 'dompurify';
import { NonAuthRoutes } from '../../interfaces/routes';

// Icons
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

// Types
import { Column } from '../../interfaces/components';

// Utils
import { isCypressRunning } from '../../utils/cypress';
import { AxiosError } from 'axios';
import PaginatedTableCell from './PaginatedTableCell/PaginatedTableCell';
import PaginatedActions from './PaginationActions/PaginatedActions';
import { ArrowDropDown } from '@material-ui/icons';
import { MaterialButtonColor } from '../primitives/csButton/CSButtonTypes';

const ROWS_PER_PAGE = 10;

export interface filterOption {
  label: string;
  value: string;
  selected: boolean;
}

export interface filterObj {
  name: string;
  options: filterOption[];
  labelWidth?: number; //this can be removed as it's solved with CSS
}

export interface Action {
  tableLabel?: string;
  cellWidth?: number | string;
  columnLabel?: JSX.Element;
  columnColor?: MaterialButtonColor;
  columnVariant?: 'text' | 'outlined' | 'contained';
  callback?: (callbackProperty: any) => void;
  qualifier?: string;
  callbackProperty?: string;
}

interface Props {
  classes: { [key: string]: string };
  columns: Column[];
  dataTestIdPrefix?: string;
  fetch: (
    pageIndex: number,
    rowsPerPage: number,
    filtersValue?: filterObj[],
    filterByString?: string,
    sortedBy?: string,
  ) => any;
  rowsLoadDetailPages?: boolean;
  filterIsEnabled?: boolean;
  filterByString?: boolean;
  filterByStringPlaceholder?: string;
  searchQueryStringPrefix?: string;
  fetchfilters?: () => any;
  detailsPageBasePath?: string;
  sortableBy?: string[];
  enablePrefetch?: boolean;
  defaultSort?: string;
  title: string;
  disableUpdateQueryStringUrl?: boolean;
  isExpandedPanel?: boolean;
  isSingleActiveExpandedPanel?: boolean;
  defaultExpandedPanelId?: number;
  expansionPanelClickCallback?: (row: any) => void;
  expandedContentRenderer?: (data: any) => any;
  loadExpandedContentData?: (
    params: any,
    onAfterLoadExpandedContentData: () => void,
  ) => any;
  actions?: Action[];
  groupActions?: boolean;
  showPagination?: boolean;
  disableNoDataMessage?: boolean;
}

export const getSelectedFilterOption = (
  filterOptions: filterObj[],
  filterName: string,
) => {
  //Set fStatus filter
  return filterOptions
    .filter((obj) => {
      return obj.name === filterName;
    })[0]
    .options.filter((opt) => {
      return opt.selected === true;
    })[0].value;
};

export const selectFilterByQueryString = (
  qs: string | string[],
  options: filterOption[],
) => {
  if (qs) {
    //check if options value provided in QS exists in the array
    const qsOption = options.find((option) => option.label === qs);
    if (qsOption) {
      //get selected option (all)
      const selectedOption = options.find(
        (option) => option.selected === true,
      );

      //deselect selected option
      if (selectedOption) {
        selectedOption.selected = false;
      }

      //select the option from the QS
      qsOption.selected = true;
    }
  }
};

const PaginatedTable: React.FC<Props> = ({
  classes,
  columns,
  dataTestIdPrefix,
  fetch,
  rowsLoadDetailPages = false,
  filterIsEnabled = false,
  fetchfilters = undefined,
  detailsPageBasePath = null,
  filterByString,
  filterByStringPlaceholder,
  searchQueryStringPrefix = '',
  sortableBy = [],
  enablePrefetch = false,
  defaultSort,
  title,
  isExpandedPanel,
  isSingleActiveExpandedPanel = false,
  defaultExpandedPanelId,
  expansionPanelClickCallback,
  disableUpdateQueryStringUrl = false,
  expandedContentRenderer = () => {},
  loadExpandedContentData,
  actions,
  groupActions = false,
  showPagination = true,
  disableNoDataMessage = false,
}) => {
  const pageToFetchByUrl = queryString.parse(
    browserHistory.location.search,
  )['page'];

  const pageToFetchByUrlVal =
    !!pageToFetchByUrl && typeof pageToFetchByUrl === 'string'
      ? parseInt(pageToFetchByUrl) - 1
      : null;

  const rowsPerPageByUrl = queryString.parse(
    browserHistory.location.search,
  )['rowsPerPage'];

  const rowsPerPageByUrlVal =
    !!rowsPerPageByUrl && typeof rowsPerPageByUrl === 'string'
      ? parseInt(rowsPerPageByUrl)
      : null;
  const searchQuery = queryString.parse(
    browserHistory.location.search,
  )[`${searchQueryStringPrefix}search`] as string;
  const [page, setPage] = useState<number>(pageToFetchByUrlVal || 0);
  const [isAllInfoFetched, setIsAllInfoFetched] =
    useState<boolean>(false);
  const [showProgress, setShowProgress] = useState<boolean>(true);
  const [linkClickTime, setLinkClickTime] = useState<Date>();
  const [showNoDataMessage, setShowNoDataMessage] =
    useState<boolean>(false);
  const [redirectClickTime, setRedirectClickTime] = useState<Date>();
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [filterByStringStr, setFilterByStringStr] = useState<string>(
    searchQuery || '',
  );

  const [expandedPanel, setExpandedPanel] = useState<
    number | undefined
  >(defaultExpandedPanelId);

  const [data, setData] = useState<any[]>([]);
  const [initialfiltersValue, setInitialFiltersValue] = useState<
    filterObj[] | undefined
  >();
  const [filtersValue, setFiltersValue] = useState<
    filterObj[] | undefined
  >();
  // These are used only for use effect trigger
  const [applyCount, setApplyCount] = useState<number>(0);
  // These are used only for use effect trigger
  const [clearCount, setClearCount] = useState<number>(0);
  const [rowsPerPageUpdateCount, setRowsPerPageUpdateCount] =
    useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);

  const [sorted, setSorted] = useState<string | undefined>(undefined);
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    rowsPerPageByUrlVal || ROWS_PER_PAGE,
  );

  const sortBy = sorted || defaultSort;

  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));
  const [mobileEps, setMobileEps] = useState<Map<number, boolean>>(
    new Map<number, boolean>(),
  );

  const visibleColumns = columns.filter((column) => !column.hide);
  const mobileColumns = visibleColumns.filter(
    (column) => column.showOnMobile,
  ).length
    ? visibleColumns.filter((column) => column.showOnMobile)
    : visibleColumns.filter((column, index) =>
        actions?.length
          ? [0, 1].includes(index) // if table has actions -- show only first column and the + sign
          : [0, 1, 2, visibleColumns.length - 1].includes(index),
      );
  const isDesktopExpansionPanel = isExpandedPanel && !isMobileView;
  const urlParams: any = useParams();

  const hasActiveFilters = (filters: filterObj[]) => {
    return filters.some((filter) => {
      return filter.options.some(
        (option) => option.value !== 'ALL' && option.selected,
      );
    });
  };

  const checkForFiltersClear = () => {
    // Checks for router updates without in component interaction
    if (
      !browserHistory.location.search &&
      hasActiveFilters(filtersValue || [])
    ) {
      setTimeout(() => {
        filtersClear();
      }, 0);
    }
  };

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
    getData(newPage);

    updateUrlQuery(filtersValue, sorted, newPage);
  };

  const checkIfAllInfoIsFetched = (isLastPage: boolean) => {
    if (!isAllInfoFetched && isLastPage) {
      setIsAllInfoFetched(isLastPage);
    }
  };

  const getData = async (pageToFetch?: number) => {
    let currentPage = 0;
    if (
      !data.length &&
      (pageToFetch === undefined || pageToFetch === null) &&
      !!pageToFetchByUrlVal
    ) {
      handleChangePage(undefined, pageToFetchByUrlVal);
      return;
    } else {
      // match indexes between Mui and BE
      currentPage = pageToFetch === undefined ? 1 : pageToFetch + 1;
    }

    const fetchPageIndex = currentPage;
    const preFetchPageIndex = currentPage + 1;

    const dataObj: any[] = [];
    const mainFetch: any = await fetchData(fetchPageIndex);
    checkIfAllInfoIsFetched(mainFetch.isLastPage);

    dataObj.push(...mainFetch.data);
    if (mainFetch && !mainFetch.isLastPage && !isAllInfoFetched) {
      if (enablePrefetch) {
        const preFetch: any = await fetchData(preFetchPageIndex);
        checkIfAllInfoIsFetched(preFetch.isLastPage);
        dataObj.push(...preFetch.data);
      }
    }

    setData([...dataObj]);
  };

  const fetchData = async (pageIndex: number) => {
    try {
      setShowProgress(true);
      //Avoid sending empty string as search query to the API
      const filterByStringQuery =
        (filterByStringStr || '').trim().length > 0
          ? filterByStringStr
          : undefined;
      let res;
      if (filterIsEnabled && !initialfiltersValue) {
        //fetch filter values then forward filter values to the fetch method
        const fo: filterObj[] = await fetchfilters!();
        res = await fetch(
          pageIndex,
          rowsPerPage,
          fo,
          filterByStringQuery,
          sortBy,
        );

        if (hasActiveFilters(fo)) {
          updateUrlQuery(fo, sorted, page);
        }

        //update the state
        setInitialFiltersValue(fo);
        setFiltersValue(fo);
      } else {
        //forward filter values from the state to the fetch method
        res = await fetch(
          pageIndex,
          rowsPerPage,
          filtersValue,
          filterByStringQuery,
          sortBy,
        );
      }

      const resData = res.data;
      if (totalCount !== res.data.count) {
        setTotalCount(res.data.count);
      }

      if (
        (resData && resData.results.length < rowsPerPage) ||
        !resData.count
      ) {
        if (!resData.count && !data.length) {
          setShowNoDataMessage(true);
        } else {
          setShowNoDataMessage(false);
        }
      } else {
        setShowNoDataMessage(false);
      }

      return {
        data: resData ? resData.results : [],
        isLastPage: !resData.next,
      };
    } catch (e) {
      setErrorMsg(await ErrorHandler.getLabel(e as AxiosError));
    } finally {
      setShowProgress(false);
    }
    return {
      data: [],
      isLastPage: false,
    };
  };

  // Update filter values in the state
  const filterChanged = (filterName: string, filterValue: string) => {
    //make deep copy of array of objects -- kinda hacky
    const filters = JSON.parse(
      JSON.stringify(filtersValue),
    ) as filterObj[];

    const thisFilter = filters.find((obj) => {
      return obj.name === filterName;
    })?.options;

    const oldOption = thisFilter?.find((opt) => {
      return opt.selected === true;
    });

    const newOption = thisFilter?.find((opt) => {
      return opt.value === filterValue;
    });
    if (oldOption && newOption) {
      oldOption.selected = false;
      newOption.selected = true;
      setFiltersValue(filters);

      // filtersApply
      setData([]);
      setApplyCount(applyCount + 1);
      updateUrlQuery(filters, sorted, 0);
    }
  };

  const updateUrlQuery = (
    filtersData: filterObj[] | undefined,
    sortedStr: string | undefined,
    newPage: number,
    rowsPerPage?: number,
  ) => {
    if (disableUpdateQueryStringUrl) {
      return;
    }
    const qss = queryString.parse(window.location.search);

    qss['page'] = (newPage + 1).toString();
    if (rowsPerPage) qss['rowsPerPage'] = rowsPerPage.toString();

    filtersData?.forEach((item, index) => {
      const option = item.options.find(
        (option) => option.selected,
      )?.label;
      if (option) {
        if (option !== 'All')
          qss[item.name.replace(/ /g, '')] = option;
        else delete qss[item.name.replace(/ /g, '')];
      }
    });

    if (sortedStr || sortBy) {
      qss['sortBy'] = sortedStr || sortBy || null;
    }

    if (filterByString) {
      qss[`${searchQueryStringPrefix}search`] = filterByStringStr;
    }
    if (detailsPageBasePath) {
      newPage === 0 // if executed on first page, replace browser history so back button will go to the actual previous page
        ? browserHistory.replace(
            detailsPageBasePath + '?' + queryString.stringify(qss),
          )
        : browserHistory.push(
            detailsPageBasePath + '?' + queryString.stringify(qss),
          );
    }
  };

  useEffect(() => {
    //update data only after state has been updated
    if (applyCount || clearCount !== 0) {
      setPage(0);
      getData(0);
      updateUrlQuery(filtersValue, sorted, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyCount, clearCount]);

  const filtersClear = () => {
    if (filtersValue) {
      const filters = JSON.parse(
        JSON.stringify(filtersValue),
      ) as filterObj[];
      filters.forEach((filter) => {
        filter.options.find((o) => o.selected === true)!.selected =
          false;
        filter.options.find((o) => o.value === 'ALL')!.selected =
          true;
      });
      setFiltersValue(filters); // cancel all filters -- set all option values to 'ALL'
    }
    setFilterByStringStr('');
    setClearCount(clearCount + 1);
  };

  const getRowsPerPageOptions = () => {
    switch (true) {
      case totalCount >= 50:
        return [10, 20, 50];
      case totalCount > 10:
        return [10, 20];
      default:
        return [];
    }
  };

  const onExpansionPanelClick = (event: any, row: any) => {
    if (!['path', 'BUTTON'].includes(event.target.nodeName)) {
      if (loadExpandedContentData) {
        setShowProgress(true);
        loadExpandedContentData({ id: row.id }, () => {
          setShowProgress(false);
        });
      }
      if (isSingleActiveExpandedPanel && expandedPanel !== row.id) {
        setExpandedPanel(row.id);
        expansionPanelClickCallback &&
          expansionPanelClickCallback(row);
      } else {
        setExpandedPanel(undefined);
      }
    }
  };

  useEffect(() => {
    const mobileEpsVal = new Map<number, boolean>();
    data.forEach((row) => {
      mobileEpsVal.set(row.id, false);
    });
    setMobileEps(mobileEpsVal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobileView]);

  useEffect(() => {
    if (clearCount !== 0) {
      setPage(0);
      getData(0);
      updateUrlQuery(filtersValue, sorted, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearCount]);

  useEffect(() => {
    //Page is being loaded for the first time. Use the page number from query string.
    if (rowsPerPageUpdateCount === 0 && pageToFetchByUrlVal) {
      setPage(pageToFetchByUrlVal);
      setData([]);
      getData(pageToFetchByUrlVal);
    } else {
      setPage(0);
      setData([]);
      getData(0);
      updateUrlQuery(filtersValue, sorted, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage]);

  const sortByColumn = (column: string) => {
    const order = sortBy === column ? '-' : '';
    const sortedStr = `${order}${column}`;
    setData([]);
    setSorted(sortedStr);
    updateUrlQuery(filtersValue, sortedStr, 0);
  };

  useEffect(() => {
    if (sorted) {
      setPage(0);
      getData(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorted]);

  const renderPaginatedTableCellValue = (
    column: Column,
    row: any,
  ) => {
    const value = row[column.id];
    return (
      <Fragment>
        {column.link &&
        Boolean(value) &&
        value !== common.emptyValue ? (
          <Link
            underline={'always'}
            className={classes.link}
            onMouseDown={() => {
              setLinkClickTime(new Date());
            }}
            onMouseUp={(event: any) => {
              event.stopPropagation();
              event.preventDefault();
              const timeElapsed =
                new Date().getTime() -
                (linkClickTime?.getTime() || 0);
              if (
                column.link?.path &&
                column.link?.value &&
                linkClickTime &&
                timeElapsed < 200
              ) {
                browserHistory.push(
                  `${column.link.path}/${row[column.link.value]}`,
                );
              }
            }}
          >
            {value}
          </Link>
        ) : column.useCustomComponent ? (
          { ...value }
        ) : value !== null && value !== undefined && value !== '' ? (
          <span
            /**
             * TODO:
             * Check if this is still necessary as using this prop is an antipattern
             */
            dangerouslySetInnerHTML={{
              //DOMPurify doesn't work well with non html/svg/mathml types.
              __html: DOMPurify.sanitize(`<span>${value}</span>`),
            }}
          ></span>
        ) : (
          common.emptyValue
        )}
      </Fragment>
    );
  };

  const renderActionButton = (
    action: Action,
    index: number,
    row: any,
    hasMargin: boolean = false,
  ) => (
    <CSButton
      key={'action-button-' + index}
      variant={action.columnVariant || 'text'}
      color={action.columnColor || 'text'}
      data-testid={`paginated-table:action-${index}:button`}
      className={clsx(classes.actionColumnButton, {
        [classes.marginLeft10]: hasMargin,
      })}
      onClick={() => {
        action.callback!(row[action?.callbackProperty || 'id']); // "ID is the default callback."
      }}
      disabled={
        !row[action.qualifier!] || row[action.qualifier!] === 0
      }
    >
      {action.columnLabel}
    </CSButton>
  );

  const renderTableRow = (row: any, index: number) => {
    const customStyle = isDesktopExpansionPanel
      ? {
          paddingLeft: 16,
          paddingRight: 0,
        }
      : {};
    return (
      <Fragment key={`row-${row.id}-${index}`}>
        <TableRow
          className={classes.tableRow}
          hover
          role='checkbox'
          tabIndex={-1}
          data-testid={`${dataTestIdPrefix}-row-${row.id}`}
          data-id={row.id}
          style={{
            cursor: rowsLoadDetailPages ? 'pointer' : '',
            display: row.id === ' ' ? 'none' : 'table-row',
          }}
          onMouseDown={() => {
            setRedirectClickTime(new Date());
          }}
          onMouseUp={(event: any) => {
            const isInActionsCell = Boolean(
              event.target.closest('.actions-cell'),
            );

            if (isInActionsCell) return;

            if (
              !['svg', 'path', 'BUTTON', 'a'].includes(
                event.target.nodeName,
              )
            ) {
              const timeElapsed = isCypressRunning
                ? 100
                : new Date().getTime() -
                  (redirectClickTime?.getTime() || 0);

              if (
                timeElapsed < 200 &&
                rowsLoadDetailPages &&
                !(event.target instanceof HTMLAnchorElement) &&
                !(
                  event.target instanceof HTMLButtonElement ||
                  event.nativeEvent?.target?.parentElement instanceof
                    HTMLButtonElement
                )
              ) {
                // Force redirect when basePath is the same as window pathname.
                // List to details page
                if (
                  window.location.pathname.includes(
                    `${detailsPageBasePath!}/`,
                  )
                ) {
                  browserHistory.push(NonAuthRoutes.REFRESH_ROUTE);
                  setTimeout(() => {
                    browserHistory.replace(
                      `${detailsPageBasePath}/${row.id}`,
                    );
                  }, 0);
                } else
                  browserHistory.push(
                    `${detailsPageBasePath}/${row.id}`,
                  );
              }
            }
          }}
        >
          {isMobileView && columns.length > 3 && (
            <PaginatedTableCell padding='none'>
              <Box p={1}>
                <IconButton
                  size='small'
                  className={classes.expandedButton}
                  onClick={(event) => {
                    setMobileEps(
                      new Map(
                        mobileEps.set(row.id, !mobileEps.get(row.id)),
                      ),
                    );
                    onExpansionPanelClick(event, row);
                  }}
                >
                  {mobileEps.get(row.id) ? (
                    <RemoveCircleOutlineIcon />
                  ) : (
                    <AddCircleOutlineIcon />
                  )}
                </IconButton>
              </Box>
            </PaginatedTableCell>
          )}
          {(isMobileView ? mobileColumns : columns).map(
            (column, index) => {
              if (isMobileView && !mobileEps.has(row.id)) {
                setMobileEps(new Map(mobileEps.set(row.id, false)));
              }
              if (!column.hide) {
                return (
                  <PaginatedTableCell
                    key={`${column.id}-${index}`}
                    align={column.align}
                    style={{
                      width: !isMobileView ? column.width : 'auto',
                      ...customStyle,
                    }}
                    onClick={(event) => {
                      columns.length > 3 &&
                        setMobileEps(
                          new Map(
                            mobileEps.set(
                              row.id,
                              !mobileEps.get(row.id),
                            ),
                          ),
                        );
                      onExpansionPanelClick(event, row);
                    }}
                  >
                    {renderPaginatedTableCellValue(column, row)}
                  </PaginatedTableCell>
                );
              } else return null;
            },
          )}
          {actions &&
            (groupActions ? (
              <PaginatedTableCell
                className={classes.actionColumn + ' actions-cell'}
                style={{
                  width: actions[0].cellWidth || 'auto',
                }}
              >
                {actions.map((action, index) =>
                  renderActionButton(action, index, row, true),
                )}
              </PaginatedTableCell>
            ) : (
              actions.map((action, index) => (
                <PaginatedTableCell
                  key={'action-' + index}
                  className={classes.actionColumn + ' actions-cell'}
                  style={{
                    width: action.cellWidth || 'auto',
                  }}
                >
                  {renderActionButton(action, index, row)}
                </PaginatedTableCell>
              ))
            ))}
        </TableRow>
        {isMobileView && mobileEps.get(row.id) && (
          <>
            <TableRow key={`mobile-extra-info-row-${row.id}`}>
              <PaginatedTableCell
                colSpan={columns.length + 1}
                style={{ padding: 0 }}
              >
                {columns
                  .filter(
                    (column) =>
                      !mobileColumns.includes(column) && !column.hide,
                  )
                  .map((column, index) => {
                    return (
                      <Grid
                        className={classes.expanderContainer}
                        container
                        item
                        xs={12}
                        key={`${column}-${index}`}
                      >
                        <Grid item xs={6}>
                          <Box pl={10} pt={2} pb={2}>
                            <Typography
                              variant='table-title'
                              color={{
                                color: 'text',
                                variant: 'icon',
                              }}
                            >
                              {column.label}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box pt={2} pb={2}>
                            <Typography
                              variant='table-body'
                              color='textPrimary'
                            >
                              {renderPaginatedTableCellValue(
                                column,
                                row,
                              )}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    );
                  })}
              </PaginatedTableCell>
            </TableRow>
            {isExpandedPanel && (
              <TableRow
                className={classes.expanderContainer}
                key={`mobile-extra-info-expanded-panel-row-${row.id}`}
              >
                <PaginatedTableCell
                  colSpan={columns.length + 1}
                  style={{ padding: 0 }}
                >
                  {expandedContentRenderer(row)}
                </PaginatedTableCell>
              </TableRow>
            )}
          </>
        )}
      </Fragment>
    );
  };

  const renderTableHead = () => {
    return (
      <TableHead>
        <TableRow className={classes.tableHeadRow}>
          {isMobileView && columns.length > 3 && (
            <PaginatedTableCell style={{ width: 16 }} />
          )}
          {isDesktopExpansionPanel && (
            <PaginatedTableCell style={{ width: 48 }} />
          )}
          {(isMobileView ? mobileColumns : columns).map(
            (column, index) =>
              !column.hide && (
                <PaginatedTableCell
                  key={`column-${column.id}`}
                  align={column.align}
                  style={{
                    width: column.width,
                    paddingLeft: 16,
                  }}
                >
                  {sortableBy?.includes(column.id as string) ? (
                    <Typography
                      variant='table-title'
                      component='a'
                      className={classes.sortableLink}
                      color={{
                        color: 'text',
                        variant: 'icon',
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        sortByColumn(column.id as string);
                      }}
                    >
                      {sortBy && sortBy === `-${column.id}` && (
                        <ArrowDownwardIcon
                          className={classes.sortIcon}
                        />
                      )}
                      {sortBy && sortBy === column.id && (
                        <ArrowUpwardIcon
                          className={classes.sortIcon}
                        />
                      )}
                      {column.label}
                    </Typography>
                  ) : (
                    <Typography
                      variant='table-title'
                      color={{
                        color: 'text',
                        variant: 'icon',
                      }}
                    >
                      {column.label}
                    </Typography>
                  )}
                </PaginatedTableCell>
              ),
          )}
          {actions &&
            (groupActions ? (
              <PaginatedTableCell
                style={{
                  width: actions[0].cellWidth || 'auto',
                }}
              >
                {actions[0].tableLabel}
              </PaginatedTableCell>
            ) : (
              actions.map((action, index) => (
                <PaginatedTableCell
                  key={index}
                  style={{
                    width: action.cellWidth || 'auto',
                  }}
                >
                  {action.tableLabel}
                </PaginatedTableCell>
              ))
            ))}
        </TableRow>
      </TableHead>
    );
  };

  const renderEp = (row: any, index: number) => {
    return (
      <TableRow key={`ep-row-${index}`}>
        <PaginatedTableCell
          colSpan={
            columns.length + 1 + (actions ? actions.length : 0)
          }
          style={{ padding: 0 }}
        >
          <Accordion
            square
            expanded={
              isSingleActiveExpandedPanel
                ? expandedPanel === row.id
                : undefined
            }
            className={classes.accordion}
            defaultExpanded={urlParams.defaultOpenIds
              ?.split('-')
              .map((id: string) => parseInt(id))
              .includes(row.id)}
            TransitionProps={{
              unmountOnExit: true,
              mountOnEnter: true,
            }}
          >
            <AccordionSummary
              onClick={(e) => {
                onExpansionPanelClick(e, row);
              }}
              expandIcon={<ArrowDropDownIcon />}
            >
              <Table>
                <TableBody>{renderTableRow(row, index)}</TableBody>
              </Table>
            </AccordionSummary>
            <AccordionDetails>
              {expandedContentRenderer(row)}
            </AccordionDetails>
          </Accordion>
        </PaginatedTableCell>
      </TableRow>
    );
  };

  // Ensures that always clean internal filters value state
  checkForFiltersClear();

  const showRetryResetPaginationButton = (errorMessage: string) => {
    if (page > 0 && !errorMessage?.includes('Network Error')) {
      return {
        buttonLabel: 'RETRY',
        buttonAction: () => {
          setErrorMsg('');
          filtersClear();
        },
      };
    }
    return {};
  };
  const showToolbar = title || filterByString;
  const rowsPerPageOpts = getRowsPerPageOptions();
  return (
    <Fragment>
      {showProgress && <ProgressIndicator />}
      {errorMsg && (
        <AlertBanner
          data-testid={`${dataTestIdPrefix}-error`}
          className={classes.banner}
          severity='error'
          alertMsg={errorMsg}
          alertTitle={'Error'}
          {...showRetryResetPaginationButton(errorMsg)}
        />
      )}
      {!!filtersValue && (
        <Grid
          container
          data-testid='filter-value'
          spacing={2}
          className={classes.flexTidyArrangement}
        >
          {filtersValue.map((filter) => {
            return (
              <Grid item key={filter.name}>
                <FormControl className={classes.filter}>
                  <InputLabel>{filter.name}</InputLabel>
                  <Select
                    key={filter.name}
                    id={
                      'filter-select-' +
                      filter.name.replace(/ /g, '-')
                    }
                    data-testid={`filter:${filter.name}`}
                    name={filter.name}
                    value={getSelectedFilterOption(
                      filtersValue,
                      filter.name,
                    )}
                    onChange={(e: React.BaseSyntheticEvent) => {
                      filterChanged(filter.name, e.target.value);
                    }}
                    variant='outlined'
                    className={classes.muiSelect}
                    IconComponent={() => (
                      <ArrowDropDown className={classes.selectIcon} />
                    )}
                  >
                    {filter.options.map((opt) => (
                      <MenuItem
                        key={filter.name + '_' + opt.value}
                        value={opt.value}
                        data-testid={'filter-option-' + opt.label}
                      >
                        {opt.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            );
          })}
        </Grid>
      )}
      <div className={classes.tableOuterContainer}>
        {showToolbar && (
          <Toolbar className={classes.toolBar}>
            <Grid
              container
              spacing={2}
              justifyContent='space-between'
            >
              <Grid item xs={12} sm={6}>
                <Typography
                  variant='h4'
                  component='h2'
                  className={classes.tableTitle}
                >
                  {title}
                </Typography>
              </Grid>
              <Grid
                container
                item
                xs={12}
                sm={6}
                justifyContent='flex-end'
              >
                <Grid item>
                  {(!!filtersValue || filterByString) && (
                    <CSTextField
                      variant='filled-outlined'
                      data-testid={'paginated-table:search-input'}
                      startAdornment={<SearchIcon />}
                      placeholder={filterByStringPlaceholder}
                      onClear={() => {
                        filtersClear();
                      }}
                      value={filterByStringStr}
                      onEnter={() => {
                        setData([]);
                        setApplyCount(applyCount + 1);
                      }}
                      onChange={(
                        e: React.ChangeEvent<
                          HTMLTextAreaElement | HTMLInputElement
                        >,
                      ) => {
                        setFilterByStringStr(e.target.value);
                      }}
                      id={'filter-select-by-string'}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        )}
        {!!data.length && (
          <Fragment>
            <TableContainer
              className={clsx({
                [classes.roundedContainer]: !showToolbar,
              })}
            >
              <Table
                stickyHeader
                data-testid={`${dataTestIdPrefix}-paginated-table`}
                style={{ overflow: 'hidden' }}
                className={clsx(
                  !showPagination && classes.paginationCaption,
                )}
              >
                {renderTableHead()}
                <TableBody>
                  {data.map((row, index) => {
                    if (isDesktopExpansionPanel) {
                      return renderEp(row, index);
                    }
                    return renderTableRow(row, index);
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {showPagination && (
              <TablePagination
                data-testid={`${dataTestIdPrefix}-pagination`}
                className={clsx(classes.paginationCaption, {
                  [classes.paginationCaptionDisabled]:
                    rowsPerPageOpts.length < 1,
                })}
                rowsPerPageOptions={rowsPerPageOpts}
                component='div'
                count={totalCount || 0}
                rowsPerPage={rowsPerPage}
                ActionsComponent={PaginatedActions}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onRowsPerPageChange={(
                  event: React.ChangeEvent<HTMLInputElement>,
                ) => {
                  const rpp = +event.target.value;
                  setRowsPerPage(rpp);
                  setRowsPerPageUpdateCount(
                    rowsPerPageUpdateCount + 1,
                  );
                  updateUrlQuery(filtersValue, sorted, page, rpp);
                }}
                page={page}
                onPageChange={handleChangePage}
              />
            )}
          </Fragment>
        )}
        {showNoDataMessage && !disableNoDataMessage && (
          <AlertBanner
            data-testid={`${dataTestIdPrefix}-info-banner`}
            severity='info'
            alertMsg={
              filterIsEnabled
                ? 'No results match the current filters'
                : 'There is no information to display at the moment'
            }
          />
        )}
      </div>
    </Fragment>
  );
};

export default withStyles(styles)(PaginatedTable);
