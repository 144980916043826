import { Theme } from '@material-ui/core';
import get from 'lodash/get';
import {
  ButtonColorStyles,
  ButtonExtendedColors,
  ButtonWithIconColor,
} from './CSButtonTypes';

export const getButtonColorStyles = (
  value: ButtonExtendedColors | ButtonWithIconColor,
  theme: Theme,
): ButtonColorStyles =>
  typeof value === 'string'
    ? {
        buttonColor: get(theme.palette, value) as any, //TODO Fix
        iconColor: undefined,
      }
    : {
        buttonColor: get(theme.palette, value.buttonColor),
        iconColor:
          get(
            theme.palette,
            `${value?.iconColor}.${String(
              value?.iconColorVariant || 'main',
            )}`,
          ) || undefined,
      };
