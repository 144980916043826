export const formatDuration = (label: number) => {
  const m = Math.floor(label / 60);
  const s = label % 60;
  if (s < 10) return `${m}:0${s}`;
  else return `${m}:${s}`;
};

export const formatMinutesSeconds = (label: number) => {
  const m = Math.floor(label / 60);
  const s = label % 60;
  if (m > 0) return `${m} m`;
  else return `${s} s`;
};

export const formatDecimals = (label: string) => {
  const decimals = label.toString().match(/^-?\d+(?:\.\d{0,2})?/);
  if (decimals) {
    return decimals[0];
  } else {
    return label;
  }
};

export const formatBigNumber = (label: number) => {
  if (label < 1000) {
    return label.toString();
  } else if (label >= 1000 && label <= 10000) {
    return (label / 1000).toFixed(1) + 'K';
  } else {
    return Math.round(label / 1000) + 'K';
  }
};
