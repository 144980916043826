import {
  FormControl,
  FormHelperText,
  InputLabel,
  useTheme,
  withStyles,
} from '@material-ui/core';
import * as React from 'react';
import {
  COMPONENT_NAME,
  CSTextFieldProps,
  ENTER,
} from './CSTextFieldTypes';
import styles, {
  FILLED_CLASS,
  getFormControlStyles,
  getFormHelperTextStyles,
  getInputLabelStyles,
} from './CSTextField.styles';
import {
  getEndAdornment,
  getTextFieldComponentVariant,
  materialTextFieldConfiguration,
} from './CSTextFieldUtils';
import useStableId from '../../../utils/useStableId';
import useControlled from '../../../utils/useControlled';
import { toMaterialTextFieldColor } from '../csCommon/inputUtils';

const CSTextField: React.FC<CSTextFieldProps> = React.forwardRef(
  (
    {
      id = '',
      className = '',
      label,
      name,
      value: userValue,
      defaultValue = '',
      onChange,
      onKeyDown,
      onEnter,
      onClear,
      disabled,
      error,
      hideClear,
      helperText,
      startAdornment,
      endAdornment,
      InputLabelProps = {},
      containerSize = 'standard',
      color = 'primary',
      variant = 'standard-outlined',
      'data-testid': dataTestId,
      customContent,
      ...others
    },
    ref,
  ) => {
    const theme = useTheme();
    const [value, setValueIfUncontrolled] = useControlled<string>({
      controlled: userValue,
      default: defaultValue,
      name: COMPONENT_NAME,
    });

    const inputId = useStableId(id, COMPONENT_NAME);
    const MaterialComponent = React.useMemo(
      () => getTextFieldComponentVariant(variant),
      [variant],
    );
    const ThemedTextField = React.useMemo(
      () =>
        withStyles(
          styles(theme, color, variant, label, containerSize),
          {
            name: COMPONENT_NAME,
          },
        )(MaterialComponent),
      [
        theme,
        color,
        variant,
        label,
        containerSize,
        MaterialComponent,
      ],
    );

    const ThemedInputLabel = React.useMemo(
      () =>
        withStyles(
          getInputLabelStyles(
            theme,
            color,
            variant,
            startAdornment !== undefined,
          ),
          {
            name: 'CSInputLabel',
          },
        )(InputLabel),
      [theme, color, variant, startAdornment],
    );

    const ThemedFormHelperText = React.useMemo(
      () =>
        withStyles(getFormHelperTextStyles(), {
          name: 'CSFormHelperText',
        })(FormHelperText),
      [],
    );

    const handleOnClear = () => {
      setValueIfUncontrolled('');
      onClear?.();
    };

    const { formControlVariant, formLabelVariant, notched } =
      materialTextFieldConfiguration(variant);
    const ThemedFormControl = React.useMemo(
      () =>
        withStyles(getFormControlStyles(containerSize), {
          name: 'CSTextFormControl',
        })(FormControl),
      [containerSize],
    );

    return (
      <ThemedFormControl
        className={className}
        data-testid={dataTestId}
        variant={formControlVariant}
        disabled={disabled}
        error={error}
      >
        {label && (
          <ThemedInputLabel
            variant={formLabelVariant}
            htmlFor={inputId}
            shrink
            {...InputLabelProps}
          >
            {label}
          </ThemedInputLabel>
        )}
        <ThemedTextField
          ref={ref}
          className={!!value ? FILLED_CLASS : ''}
          label={variant === 'outlined' ? label : undefined}
          id={inputId}
          value={value}
          onChange={(event) => {
            onChange?.(event);
            setValueIfUncontrolled(event.target.value);
          }}
          onKeyDown={(event) => {
            if (event.keyCode === ENTER) {
              onEnter?.(value);
            }
            onKeyDown?.(event);
          }}
          color={toMaterialTextFieldColor(color)}
          notched={notched}
          startAdornment={startAdornment}
          endAdornment={getEndAdornment(
            value,
            handleOnClear,
            endAdornment,
            (userValue && !onClear) || hideClear,
          )}
          {...others}
        />
        {helperText && (
          <ThemedFormHelperText id={`${inputId}Helper-Text`}>
            {helperText}
          </ThemedFormHelperText>
        )}
        {customContent}
      </ThemedFormControl>
    );
  },
);

export default CSTextField;
