import React from 'react';
import { Group, Layer, Line } from 'react-konva';
import { METERS_TO_YARDS } from '../StationLayout';

interface Props {
  gridDraggable: boolean;
  stageScale: number;
  clipingMaskShape: number[][];
  metersPerPixel: number;
}

const GridGuide: React.FC<Props> = ({
  gridDraggable,
  stageScale,
  clipingMaskShape,
  metersPerPixel,
}) => {
  const grid = 5 / (metersPerPixel * METERS_TO_YARDS);
  const gridWidth = 4000;

  const linesVertical = [];
  const linesHorizontal = [];

  for (let i = 0; i < gridWidth / grid; i++) {
    linesVertical.push(
      <Line
        strokeWidth={1 / stageScale}
        stroke={'rgba(0,0,0,0.15)'}
        points={[i * grid, 0, i * grid, gridWidth]}
        hitStrokeWidth={gridDraggable ? 10 : 0}
        key={'vertical-grid-line-' + i}
      />,
    );

    linesHorizontal.push(
      <Line
        strokeWidth={1 / stageScale}
        stroke={'rgba(0,0,0,0.15)'}
        points={[0, i * grid, gridWidth, i * grid]}
        hitStrokeWidth={gridDraggable ? 10 : 0}
        key={'horizontal-grid-line-' + i}
      />,
    );
  }

  return (
    <Layer>
      <Group
        clipFunc={(ctx: any) => {
          ctx.beginPath();
          for (let i = 0; i < clipingMaskShape.length; i++) {
            if (i === 0)
              ctx.moveTo(
                clipingMaskShape[i][0],
                clipingMaskShape[i][1],
              );
            else
              ctx.lineTo(
                clipingMaskShape[i][0],
                clipingMaskShape[i][1],
              );
          }
          ctx.closePath();
        }}
      >
        <Group draggable={gridDraggable} x={-2000} y={-2000}>
          {linesVertical}
          {linesHorizontal}
        </Group>
      </Group>
    </Layer>
  );
};

export default GridGuide;
