import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Typography, CSButton } from '../../primitives';
import colors from '../../..//utils/colors';
import { withStyles } from '@material-ui/core/styles';
import { Transition } from '../../confirmationDialog/ConfirmationDialog';
import ProgressIndicator from '../../progressIndicator/ProgressIndicator';
import {
  Paper,
  Grid,
  Box,
  Card,
  CardContent,
  DialogTitle,
  createStyles,
  Theme,
} from '@material-ui/core';
import detailsPageStyles from '../../commonStyles/detailsPage.style';
import ErrorHandler from '../../../utils/ErrorHandler';
import configurationUtils from '../../../utils/configurationUtils';
import { formatFirstNItemsAndMore } from '../../DetailsPagesFunctions';

//Types
import { AxiosError } from 'axios';
import {
  APIException,
  APIExceptionErrorCodeEnum,
  LoadPlanImportSummary,
} from 'cloudsort-client';

// Icons
import CloudUploadOutlined from '@material-ui/icons/CloudUploadOutlined';
import FlipToBackIcon from '@material-ui/icons/FlipToBack';

// Services
import LoadPlansService from '../../../services/LoadPlans.service';
import { CSVLink } from 'react-csv';
import CSDialogTitleWithIcon from '../../primitives/csDialogTitleWithIcon/CSDialogTitleWithIcon';
import { DialogIcons } from '../../primitives/csDialogTitleWithIcon/CSDialogTitleWithIconTypes';
import CSFileDropZone from '../../primitives/csUploadDialog/csFileDropZone/CSFileDropZone';
import CSDialogAlert from '../../primitives/csDialogAlert/CSDialogAlert';

interface Props {
  loadplanId: string;
  classes: { [key: string]: string };
  onAfterClose: () => void;
  isOpen: boolean;
  updateParent: () => void;
  fileId?: string;
}

const AddSchemeSetDialog: React.FC<Props> = ({
  loadplanId,
  isOpen,
  classes,
  fileId,
  onAfterClose,
  updateParent,
}) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState<{
    message: string;
    data: any;
  }>();
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [isUpload, setIsUpload] = useState<boolean>(true); // TODO: Change to false when we return mode selection
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [dryRunComplete, setDryRunComplete] =
    useState<boolean>(false);
  const [dryRunResults, setDryRunResults] =
    useState<LoadPlanImportSummary>();
  const [selectedFile, setSelectedFile] = useState<File>();

  const stopsLabels = {
    singular: configurationUtils.getPageTitle(true, 'STOP'),
    plural: configurationUtils.getPageTitle(false, 'STOP'),
  };

  const loadPlanLabels = {
    singular: configurationUtils.getPageTitle(true, 'LOADPLAN'),
    plural: configurationUtils.getPageTitle(false, 'LOADPLAN'),
  };

  const handleClose = () => {
    setDryRunComplete(false);
    setIsUploading(false);
    setSelectedFile(undefined);
    setError(undefined);
    //setIsUpload(false); -- TODO: uncomment when we add add from DB mode
    onAfterClose();
  };

  const onUploadProgress = (progressEvent: any) => {
    const percentCompleted = Math.floor(
      (progressEvent.loaded * 100) / progressEvent.total,
    );
    setUploadProgress(percentCompleted);
  };

  const handleError = async (e: AxiosError) => {
    const errorData = e.response?.data as APIException;
    if (
      errorData?.error_code ===
      APIExceptionErrorCodeEnum.RESOURCE_CONFLICT
    ) {
      const conflictingZipcodes =
        ErrorHandler.getConflictingZipcodes(errorData);
      setError({
        message:
          'The following Zip Codes are duplicated in the Scheme Set: ' +
          formatFirstNItemsAndMore(conflictingZipcodes, 10), //show first 10
        data: null,
      });
    } else
      setError({
        message: await ErrorHandler.getLabel(e as AxiosError),
        data: null,
      });

    if (errorData.error_context?.import_issues) {
      setError({
        message: `Found ${errorData.error_context.import_issues.length} issues.`,
        data: errorData.error_context.import_issues.reduce(
          (acc: any, curr: any) => {
            let newItem = {
              ...curr,
              ...curr.content,
            };
            delete newItem['content'];
            acc.push(newItem);
            return acc;
          },
          new Array<any>(),
        ),
      });
    }
  };

  const uploadCSV = async (dryRun: boolean = true) => {
    setShowProgress(true);
    setError(undefined);
    try {
      if (dryRun) {
        setIsUploading(true);
        const response = await LoadPlansService.importSchemeSet({
          id: Number(loadplanId),
          dryRun,
          fileId,
          file: selectedFile,
          options: {
            onUploadProgress,
          },
        });

        setDryRunResults(response.data);
        setShowProgress(false);
        setIsUploading(false);
        response.data.zipcodes_total && response.data.stops_total
          ? setDryRunComplete(true)
          : setError({
              message: `The file you've uploaded doesn't contain any schemes for the selected carrier.`,
              data: null,
            });
      } else {
        await LoadPlansService.importSchemeSet({
          id: Number(loadplanId),
          dryRun,
          fileId,
          file: selectedFile,
          options: {
            onUploadProgress,
          },
        });
        updateParent(); // notify parent component to re-render table
        handleClose();
      }
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  //This part is not in use for now
  const renderModeSelection = () => {
    return (
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Card elevation={2} className={classes.cardRoot}>
            <CardContent
              className={classes.cardContent}
              onClick={() => {
                //TODO: Add Logic for selecting previous scheme sets when available
              }}
            >
              <Box className={classes.iconBox}>
                <FlipToBackIcon className={classes.cardIcon} />
              </Box>
              <Box fontWeight='fontWeightBold' p={2}>
                <Typography variant='h6'>
                  Add set from the DB
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card elevation={2} className={classes.cardRoot}>
            <CardContent
              className={classes.cardContent}
              onClick={() => {
                setIsUpload(true);
              }}
            >
              <Box className={classes.iconBox}>
                <img
                  src={`${
                    process.env.REACT_APP_BASENAME || ''
                  }/icons/csv.svg`}
                  alt={'CSV'}
                  className={classes.cardIconCsv}
                />
                <CloudUploadOutlined className={classes.cardIcon} />
              </Box>
              <Box fontWeight='fontWeightBold' p={2}>
                <Typography variant='h6'>
                  Import Scheme Set
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  };

  useEffect(() => {
    setOpen(isOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (fileId) {
      uploadCSV(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileId]);

  const renderFieldDescription = (text: string) => (
    <Typography
      variant='body2'
      className={classes.marginBottom10}
      color={{ color: 'grey', variant: 'A400' }}
    >
      {text}
    </Typography>
  );

  return (
    <>
      {showProgress && <ProgressIndicator />}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={(e) => {
          handleClose();
        }}
        data-testid='import-scheme-set-dialog'
      >
        {!isUpload && (
          <>
            <DialogTitle>
              <CSDialogTitleWithIcon
                icon={DialogIcons.ADD}
                title='Add Scheme Set'
              />
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
              {renderModeSelection()}
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <CSButton
                color='secondary'
                variant='outlined'
                onClick={(e) => {
                  handleClose();
                }}
              >
                Close
              </CSButton>
            </DialogActions>
          </>
        )}
        {isUpload && (
          <>
            <DialogTitle>
              <CSDialogTitleWithIcon
                icon={DialogIcons.UPLOAD}
                title='Import Scheme Set'
              />
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
              {error && (
                <CSDialogAlert alertMessage={error.message} />
              )}
              {error?.data && (
                <Typography variant='body1' color='tertiary'>
                  <CSVLink
                    className={classes.csvLink}
                    data={error?.data || ''}
                    filename={`${selectedFile?.name}-import-errors-log.csv`}
                  >
                    Download summary
                  </CSVLink>
                </Typography>
              )}

              {!dryRunComplete && !isUploading && (
                <>
                  <CSFileDropZone
                    acceptedFileTypesLowercase={['csv']}
                    onSelectFile={setSelectedFile}
                  />

                  <Typography
                    color={{ color: 'grey', variant: 'A400' }}
                    className={classes.marginBottom30}
                    variant='body2'
                  >
                    Supported file types: .CSV
                  </Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography
                        variant='h6'
                        className={classes.marginBottom10}
                      >
                        Columns
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        variant='body2'
                        className={classes.marginBottom10}
                      >
                        stop_name
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      {renderFieldDescription(`the name of the {stopsLabels.singular} this
                        scheme is associated with; if empty will
                        default to the same value as the scheme_name`)}
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        variant='body2'
                        className={classes.marginBottom10}
                      >
                        scheme_name
                        <span className={classes.requiredHighlight}>
                          {' '}
                          *
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      {renderFieldDescription(
                        'the name of the scheme entry',
                      )}
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        variant='body2'
                        className={classes.marginBottom10}
                      >
                        carrier
                        <span className={classes.requiredHighlight}>
                          {' '}
                          *
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      {renderFieldDescription(
                        'the carrier identifier of this scheme (e.g.  USPS)',
                      )}
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        variant='body2'
                        className={classes.marginBottom10}
                      >
                        zipcode
                        <span className={classes.requiredHighlight}>
                          {' '}
                          *
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      {renderFieldDescription(
                        'the zipcode to assign to the scheme',
                      )}
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        variant='body2'
                        className={classes.marginBottom10}
                      >
                        country
                        <span className={classes.requiredHighlight}>
                          {' '}
                          *
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      {renderFieldDescription(
                        'the country to assign to each zipcode',
                      )}
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        variant='body2'
                        className={classes.marginBottom10}
                      >
                        sort_param
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      {renderFieldDescription(
                        'the sort parameter to use on this zipcode entry',
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant='body_sm'
                        className={classes.requiredHighlight}
                      >
                        * Required Fields
                      </Typography>
                    </Grid>
                  </Grid>
                  <Typography
                    variant='body_sm'
                    className={classes.marginBottom10}
                  >
                    The selected file will be imported into the Load
                    Plan, replacing all existing schemes for the used
                    carriers. Before the Scheme Set is imported, a
                    short summary will be displayed allowing a review
                    of the data before finalizing the import. Example:
                    uploading a Scheme Set file for the carrier USPS,
                    will delete all existing USPS schemes from the{' '}
                    {loadPlanLabels.singular}, before importing the
                    new schemes from the selected file.
                  </Typography>
                </>
              )}

              {isUploading && fileId && (
                <Typography
                  variant='body1'
                  color={{ color: 'grey', variant: 'A400' }}
                >
                  Calculating summary...
                </Typography>
              )}
              {(isUploading || dryRunComplete) && !fileId && (
                <Paper
                  elevation={2}
                  className={classes.fileContainer}
                  data-testid={'imported-plan-info'}
                >
                  <Grid container>
                    <Grid item xs={2} className={classes.padding10}>
                      <img
                        className={classes.img}
                        src={`${
                          process.env.REACT_APP_BASENAME || ''
                        }/icons/csv-file.png`}
                        alt='CSV'
                      />
                    </Grid>
                    <Grid item xs={10} className={classes.padding10}>
                      <p className={classes.uploadFileInfo}>
                        <span className={classes.uploadFilename}>
                          {selectedFile?.name}
                        </span>

                        <span className={classes.uploadPercentage}>
                          {uploadProgress}%
                        </span>
                      </p>
                      <div className={classes.uploadProgressBar}>
                        <div
                          className={classes.uploadProgressBarInner}
                          style={{
                            width: `${uploadProgress}%`,
                          }}
                        ></div>
                      </div>
                    </Grid>
                  </Grid>
                </Paper>
              )}
              {dryRunComplete && (
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    className={classes.marginBottom20}
                  >
                    <Typography variant='h6'>
                      New Scheme Set
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    container
                    xs={12}
                    className={classes.marginBottom20}
                  >
                    <Grid item xs={8} direction={'column'}>
                      <Typography variant='body2'>
                        Added {stopsLabels.plural}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        variant='h6'
                        color={{ color: 'grey', variant: 'A400' }}
                        className={classes.textAlignRight}
                      >
                        {dryRunResults?.stops_added.length}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    container
                    xs={12}
                    className={classes.marginBottom20}
                  >
                    <Grid item xs={8} direction={'column'}>
                      <Typography variant='body2'>
                        Removed {stopsLabels.plural}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        variant='h6'
                        color={{ color: 'grey', variant: 'A400' }}
                        className={classes.textAlignRight}
                      >
                        {dryRunResults?.stops_removed.length}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    container
                    xs={12}
                    className={classes.marginBottom20}
                  >
                    <Grid item xs={8} direction={'column'}>
                      <Typography variant='body2'>
                        Moved ZIP Codes
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        variant='h6'
                        color={{ color: 'grey', variant: 'A400' }}
                        className={classes.textAlignRight}
                      >
                        {dryRunResults?.zipcodes_moved}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    container
                    xs={12}
                    className={classes.marginBottom20}
                  >
                    <Grid item xs={8} direction={'column'}>
                      <Typography variant='body2'>
                        Total {stopsLabels.plural}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        variant='h6'
                        color={{ color: 'grey', variant: 'A400' }}
                        className={classes.textAlignRight}
                      >
                        {dryRunResults?.stops_total}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    container
                    xs={12}
                    className={classes.marginBottom20}
                  >
                    <Grid item xs={8} direction={'column'}>
                      <Typography variant='body2'>
                        Total ZIP Codes
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography
                        variant='h6'
                        color={{ color: 'grey', variant: 'A400' }}
                        className={classes.textAlignRight}
                      >
                        {dryRunResults?.zipcodes_total}
                      </Typography>
                    </Grid>
                  </Grid>
                  {dryRunResults?.issues &&
                    dryRunResults?.issues.length > 0 && (
                      <Grid
                        item
                        container
                        xs={12}
                        className={classes.marginBottom20}
                      >
                        <Grid
                          item
                          container
                          xs={4}
                          direction={'column'}
                        >
                          <Typography variant='body2'>
                            Issues
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography
                            variant='h6'
                            color='error'
                            data-testid={'ignored-fields-stops'}
                          >
                            {dryRunResults?.issues.length}
                          </Typography>
                          <Typography variant='h6'>
                            <CSVLink
                              className={classes.csvLink}
                              data={dryRunResults?.issues}
                              filename={`${selectedFile?.name}-import-issues-log.csv`}
                            >
                              Download summary
                            </CSVLink>
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                </Grid>
              )}
            </DialogContent>
            {!dryRunComplete && (
              <DialogActions className={classes.dialogActions}>
                <CSButton
                  variant='outlined'
                  color='secondary'
                  onClick={(e) => {
                    handleClose();
                  }}
                >
                  Cancel
                </CSButton>
                <CSButton
                  variant='contained'
                  color='secondary'
                  data-testid={'start-scheme-upload'}
                  disabled={!selectedFile}
                  onClick={(e) => {
                    uploadCSV();
                  }}
                >
                  Import
                </CSButton>
              </DialogActions>
            )}
            {dryRunComplete && (
              <DialogActions className={classes.dialogActions}>
                <CSButton
                  color='secondary'
                  variant='outlined'
                  onClick={(e) => {
                    handleClose();
                  }}
                >
                  Cancel
                </CSButton>
                <CSButton
                  color='secondary'
                  variant='contained'
                  data-testid={'start-scheme-import'}
                  onClick={(e) => {
                    uploadCSV(false);
                  }}
                >
                  {fileId ? 'Apply Changes' : 'Import'}
                </CSButton>
              </DialogActions>
            )}
          </>
        )}
      </Dialog>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    dialogContent: {
      maxWidth: '100%',
      width: '500px',
      padding: '0 40px',
    },
    fileContainer: {
      margin: '20px 0',
      padding: '0 10px',
    },
    uploadFileInfo: {
      margin: '0 0 5px 0',
    },
    uploadFilename: {
      ...theme.typography.body2,
      display: 'inline-block',
      maxWidth: '200px',
      overflow: 'hidden',
    },
    uploadPercentage: {
      color: theme.palette.grey.A400,
      float: 'right',
    },
    padding10: { padding: 10 },
    uploadProgressBar: {
      width: '100%',
      background: theme.palette.grey.A200,
      height: '5px',
      borderRadius: '2px',
      overflow: 'hidden',
    },
    uploadProgressBarInner: {
      background: theme.palette.tertiary?.main,
      height: '5px',
    },
    iconBox: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      padding: '30px',
      backgroundColor: colors.secondaryWhite,
    },
    cardRoot: {
      margin: '0 10px 10px 10px',
    },
    cardContent: {
      padding: '0px!important',
      paddingBottom: '0!important',
      cursor: 'pointer',
    },
    cardIcon: {
      margin: '10px auto',
      height: 'auto',
      width: 64,
      color: theme.palette.tertiary?.main,
    },
    cardIconCsv: {
      position: 'absolute',
      top: 10,
      right: 10,
      width: '30px',
      height: 'auto',
    },
    dialogActions: {
      padding: 24,
    },
    requiredHighlight: {
      color: theme.palette.tertiary?.main,
    },
    marginBottom10: {
      marginBottom: 10,
    },
    marginBottom20: {
      marginBottom: 20,
    },
    marginBottom30: {
      marginBottom: 30,
    },
    textAlignRight: {
      textAlign: 'right',
    },
    csvLink: {
      color: 'inherit',
      textDecoration: 'none',
      marginTop: 10,
      display: 'inline-block',
    },
  })),
)(AddSchemeSetDialog);
