import { InputLabelProps, SelectProps } from '@material-ui/core';
import Select from 'react-select/dist/declarations/src/Select';

import { ExtendedPaletteKeys } from '../../../theming/material-ui';
export type CSSelectSize =
  | 'standard'
  | 'fullHorizontal'
  | 'fullVertical'
  | 'full';

export interface CSSelectProps
  extends Omit<SelectProps, 'color' | 'variant'> {
  color?: ExtendedPaletteKeys;
  variant?: ExtendedVariants;
  label?: string;
  value?: string;
  defaultValue?: string;
  onClear?: () => void;
  helperText?: string;
  hideClear?: boolean;
  InputLabelProps?: InputLabelProps;
  customContent?: JSX.Element | undefined | false;
  containerSize?: CSSelectSize;
  'data-testid'?: string;
}
export interface MaterialSelectConfiguration {
  formControlVariant: MaterialVariants;
  formLabelVariant: MaterialVariants;
}

export type MaterialSelectComponent = typeof Select;

export interface VariantSelector {
  filledOutlined: Record<string, unknown>;
  standardOutlined: Record<string, unknown>;
}

export type MaterialVariants = 'standard' | 'filled' | 'outlined';
export type ExtendedVariants =
  | 'standard'
  | 'filled'
  | 'outlined'
  | 'standard-outlined'
  | 'filled-outlined';

export const knownSelectVariants = ['standard', 'filled', 'outlined'];
export const COMPONENT_NAME = 'CSSelect';
