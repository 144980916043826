import React from 'react';
import ReactDOM from 'react-dom';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';

import detailsPageStyles from '../../commonStyles/detailsPage.style';

interface Props {
  classes: { [key: string]: string };
  renderContent: () => any;
  offset?: string;
}

const FloatRolesTableHeader: React.FC<Props> = ({
  classes,
  offset,
  renderContent,
}) => {
  return ReactDOM.createPortal(
    <Grid
      className={classes.holder}
      direction='row'
      container
      style={{ margin: offset || '0 0 0 -23px' }}
    >
      {renderContent()}
    </Grid>,
    document.getElementById('layout-portal-holder')!,
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    holder: {
      top: 66,
      right: 0,
      padding: '0',
      position: 'fixed',
      justifyContent: 'space-between',
      maxWidth: 'calc(100% - 300px)',
      zIndex: 999,
      backgroundColor: '#f1f1f1',
      boxShadow: '0px 10px 5px rgb(0,0,0, 0.2)',
      [theme.breakpoints.down('sm')]: {
        padding: '0 20px',
        marginLeft: 0,
        maxWidth: '100%',
      },
      '& .MuiPaper-elevation1': {
        padding: '0 35px 0 55px',
      },
    },
  })),
)(FloatRolesTableHeader);
