import { createStyles, Theme } from '@material-ui/core/styles';
import { SwitchLabels, SwitchVariants } from './CSSwitchTypes';

export default (
  variant: SwitchVariants,
  labels: SwitchLabels,
  disabled: boolean,
) => {
  const standardStyle = (theme: Theme) => ({
    root: {},
    thumb: {
      backgroundColor: theme.palette.error?.main,
    },
    track: {
      backgroundColor: theme.palette.error?.light,
    },
    checked: {
      '& ~ $track': {
        backgroundColor: theme.palette.primary?.light,
      },
      '& $thumb': {
        backgroundColor: theme.palette.primary?.main,
      },
    },
    disabled: {
      '& ~ $track': {
        backgroundColor: theme.palette.grey[400],
      },
      '& $thumb': {
        backgroundColor: theme.palette.grey.A400,
      },
    },
  });
  const innerStyle = (theme: Theme) => ({
    root: { width: 87, height: 28, padding: 0 },
    switchBase: {
      padding: 2,
      '&$checked': {
        transform: 'translateX(59px)',
        '& + $track': {
          opacity: 1,
        },
      },
      '&$disabled': {
        '& + $track': {
          opacity: 1,
        },
      },
    },
    input: {
      left: 0,
      width: '310%',
    },
    thumb: {
      backgroundColor: theme.palette.error?.main,
      boxShadow: 'none',
      width: 24,
      height: 24,
    },
    track: {
      backgroundColor: theme.palette.error?.light,
      opacity: 1,
      borderRadius: 20,
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: 30,
        transform: 'translateY(-50%)',
      },
      '&:after': {
        content: `"${disabled ? labels.disabled : labels.inactive}"`,
        left: 30,
        top: 14,
        ...theme.typography.overline,
        color: theme.palette.quaternary?.main,
      },
      '&:before': {},
    },
    checked: {
      '& $input': {
        left: '-210%',
      },
      '& ~ $track': {
        backgroundColor: theme.palette.primary?.light,
        '&:before': {
          content: 'none',
        },
        '&:after': {
          content: `"${disabled ? labels.disabled : labels.active}"`,
          left: disabled ? 11 : 20,
          top: 14,
          ...theme.typography.overline,
          color: theme.palette.quaternary?.main,
        },
      },
      '& $thumb': {
        backgroundColor: theme.palette.primary?.main,
      },
    },
    disabled: {
      '& ~ $track': {
        opacity: 1,
        backgroundColor: theme.palette.grey[400],
      },
      '& $thumb': {
        backgroundColor: theme.palette.grey.A400,
      },
    },
  });
  return createStyles((theme: Theme) =>
    variant !== 'inner' ? standardStyle(theme) : innerStyle(theme),
  );
};
