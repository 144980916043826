import { createStyles, Theme } from '@material-ui/core/styles';

export default createStyles((theme: Theme) => ({
  '@keyframes ldrRingAnim': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },

  ldrRing: {
    display: 'inline-block',
    position: 'relative',
    width: '64px',
    height: '64px',
    '& div': {
      boxSizing: 'border-box',
      display: 'block',
      position: 'absolute',
      width: '48px',
      height: '48px',
      margin: '8px',
      border: '8px solid #dedede',
      borderRadius: '50%',
      animation:
        '$ldrRingAnim 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite',
      borderColor: '#dedede transparent transparent transparent',
    },
    '& div:nth-child(1)': {
      animationDelay: '-0.45s',
    },
    '& div:nth-child(2)': {
      animationDelay: '-0.3s',
    },
    '& div:nth-child(3)': {
      animationDelay: '-0.15s',
    },
    '&.small': {
      width: '32px',
      height: '32px',
      '& div': {
        width: '24px',
        height: '24px',
        margin: '4px',
        border: '4px solid #dedede',
      },
    },
  },
}));
