import React, { Dispatch, useEffect, useState } from 'react';
import { CSButton, CSSwitch, Typography } from '../../../primitives';
import { withStyles } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  Divider,
  Theme,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  Hidden,
} from '@material-ui/core';
import clsx from 'clsx';
import { createStyles } from '@material-ui/core/styles';
import colors from '../../../../utils/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Transition } from '../../../confirmationDialog/ConfirmationDialog';
import TextField from '@material-ui/core/TextField';
import { IconButton } from '@material-ui/core';
import detailsPageStyles from '../../../commonStyles/detailsPage.style';
import configPageStyles from '../../../commonStyles/configPage.style';
import { INTERACTION } from '../../utils';
import { CurrentView, ModuleName } from '../Modules';

import EditIcon from '../../../../utils/svgs/EditIcon';
import selectStyles from '../../../select/select.styles';
import { processSearchQuery } from '../searchQuery';
import Highlighter from 'react-highlight-words';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import {
  DragDropContext,
  Draggable,
  Droppable,
} from 'react-beautiful-dnd';
import OnboardingStepImage, {
  isValidOnboardingIconRef,
  OnboardingIconRefsMap,
} from '../../../../utils/svgs/OnboardingStepImage';

// Redux
import { useSearchResultSections } from '../../useSearchResultSections';

type SingleOnboardingStep = {
  title: string;
  description: string;
  icon_ref: string;
  active: boolean;
  order: number;
};

interface Props {
  classes: { [key: string]: string };
  data: any;
  configData: any;
  interaction?: INTERACTION;
  setPartialData: Dispatch<any>;
  currentView: CurrentView;
  sectionTitle: string;
  defaultExpanded: boolean;
  sectionKey: string;
}

const initDialogData = {
  title: '',
  description: '',
  icon_ref: '',
  order: -1,
};

enum DialogSteps {
  EDIT_TEXT,
  SELECT_ICON,
}

const OnboardingStep: React.FC<Props> = ({
  classes,
  data,
  configData,
  interaction,
  setPartialData,
  currentView,
  sectionTitle,
  defaultExpanded,
}) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [dialogData, setDialogData] = useState<any>(initDialogData);
  const [stepData, setStepData] = useState<SingleOnboardingStep[]>(
    [],
  );

  const [showWholeSection, setShowWholeSection] = useState(true);
  const [searchResult, setSearchResult] = useState<string[]>([]);

  const SECTION_TITLE = sectionTitle;

  const { searchQuery, updateSections } = useSearchResultSections(
    ModuleName.OT_ONBOARDING,
    SECTION_TITLE,
  );

  const [currentDialogStep, setCurrentDialogStep] =
    useState<DialogSteps>(DialogSteps.EDIT_TEXT);
  const [selectedIconRefInDialog, setSelectedIconRefInDialog] =
    useState('');

  useEffect(
    function setStepsData() {
      if (data && currentView !== CurrentView.SEARCH) {
        setStepData([...data.items]);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, currentView],
  );

  useEffect(
    function processSearch() {
      if (currentView === CurrentView.SEARCH) {
        setShowWholeSection(
          processSearchQuery(searchQuery, [sectionTitle]).length !==
            0,
        );
        const dynamicLabels: string[] = [];
        for (let item of data.items) {
          dynamicLabels.push(parseText(item.title));
          dynamicLabels.push(parseText(item.description));
        }
        const results = processSearchQuery(searchQuery, [
          sectionTitle,
          ...dynamicLabels,
        ]);
        setSearchResult(results);
        updateSections(results);
      } else {
        setSearchResult([]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchQuery, currentView],
  );

  useEffect(
    function filterStepsDataBySearchResults() {
      if (currentView === CurrentView.SEARCH && data) {
        const tmpData: any[] = [];
        for (let item of data.items) {
          if (
            showWholeSection ||
            searchResult.some(
              (result) =>
                parseText(item.title)
                  .toLowerCase()
                  .includes(result.toLowerCase()) ||
                parseText(item.description)
                  .toLowerCase()
                  .includes(result.toLowerCase()),
            )
          ) {
            tmpData.push(item);
          }
        }
        setStepData(tmpData);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, searchResult],
  );

  const reorder = (list: any[], startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    result.forEach((el: any, index) => {
      el.order = index + 1;
    });

    return result;
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const newStepData = reorder(
      stepData,
      result.source.index,
      result.destination.index,
    );
    setStepData(newStepData);
    setPartialData([...newStepData]);
  };

  const onAfterDialogClose = () => {
    setShowDialog(false);
    setDialogData(initDialogData);
    setCurrentDialogStep(DialogSteps.EDIT_TEXT);
  };

  const renderDialog = () => {
    return (
      <Dialog
        classes={{ paperScrollPaper: classes.dialogRoot }}
        open={showDialog}
        TransitionComponent={Transition}
        onClose={() => {
          onAfterDialogClose();
        }}
      >
        {currentDialogStep === DialogSteps.EDIT_TEXT && (
          <>
            <DialogTitle>
              <Typography
                align='center'
                style={{
                  color: colors.dark,
                  fontWeight: 500,
                  fontSize: '16px',
                }}
              >
                Edit row
              </Typography>
            </DialogTitle>
            <DialogContent
              className={classes.dialogContentTitleAndDescription}
            >
              <Grid
                container
                item
                xs={12}
                alignItems='flex-start'
                alignContent='center'
                justifyContent='flex-start'
              >
                <Grid item xs={12}>
                  <Typography className={classes.dialogInputTitle}>
                    Step Title
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    data-testid='onboarding-step-title'
                    autoFocus
                    InputLabelProps={{
                      shrink: true,
                      className: classes.inputNameLabel,
                    }}
                    InputProps={{
                      disableUnderline: true,
                      className: classes.inputName,
                    }}
                    classes={{
                      root: classes.inputNameHolder,
                    }}
                    value={dialogData.title}
                    onChange={(e) => {
                      setDialogData({
                        ...dialogData,
                        title: e.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.dialogInputTitle}>
                    Description
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    data-testid='onboarding-step-description'
                    InputLabelProps={{
                      shrink: true,
                      className: classes.inputNameLabel,
                    }}
                    InputProps={{
                      disableUnderline: true,
                      className: classes.inputName,
                    }}
                    classes={{
                      root: classes.inputNameHolder,
                    }}
                    value={dialogData.description}
                    onChange={(e) => {
                      setDialogData({
                        ...dialogData,
                        description: e.target.value,
                      });
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.dialogInputTitle}>
                    Image
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  sm={8}
                  xs={12}
                  alignItems='center'
                  alignContent='center'
                  justifyContent='flex-start'
                >
                  <Grid item xs={12}>
                    {isValidOnboardingIconRef(dialogData.icon_ref) ? (
                      <OnboardingStepImage
                        style={{
                          width: 79,
                          height: 53,
                          marginLeft: 6,
                          marginRight: 6,
                          padding: 5,
                          background: '#FAFAFA',
                          borderRadius: 4,
                        }}
                        iconid={dialogData.icon_ref}
                      />
                    ) : (
                      <></>
                    )}
                  </Grid>
                  <Grid item xs={7}>
                    <CSButton
                      variant='outlined'
                      color='secondary'
                      data-testid={`onboarding-dialog-select-image`}
                      style={{
                        marginTop: 10,
                      }}
                      onClick={() => {
                        setSelectedIconRefInDialog(
                          dialogData.icon_ref,
                        );
                        setCurrentDialogStep(DialogSteps.SELECT_ICON);
                      }}
                    >
                      Select image
                    </CSButton>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <CSButton
                color='secondary'
                variant='outlined'
                onClick={() => {
                  onAfterDialogClose();
                }}
              >
                Cancel
              </CSButton>
              <CSButton
                color='secondary'
                variant='contained'
                data-testid='onboarding-steps-dialog-save-btn'
                onClick={() => {
                  const modifiedStep = stepData.find(
                    (step) => step.order === dialogData.order,
                  );
                  //If the old and new content are the same, preserve the path to the original entities.
                  if (
                    parseText(modifiedStep!.description) !==
                    dialogData.description
                  ) {
                    modifiedStep!.description =
                      dialogData.description;
                  }
                  if (
                    parseText(modifiedStep!.title) !==
                    dialogData.title
                  ) {
                    modifiedStep!.title = dialogData.title;
                  }
                  modifiedStep!.icon_ref = dialogData.icon_ref;
                  setStepData([...stepData]);
                  onAfterDialogClose();
                  setPartialData([...stepData]);
                }}
              >
                Save
              </CSButton>
            </DialogActions>
          </>
        )}
        {currentDialogStep === DialogSteps.SELECT_ICON && (
          <>
            <DialogTitle>
              <Typography
                align='center'
                style={{
                  color: colors.dark,
                  fontWeight: 500,
                  fontSize: '16px',
                }}
              >
                Select Image
              </Typography>
            </DialogTitle>
            <DialogContent
              data-testid='onboarding-dialog-images-list'
              className={classes.dialogContentImageSelection}
            >
              <Grid
                container
                item
                xs={12}
                alignItems='flex-start'
                alignContent='center'
                justifyContent='flex-start'
                spacing={1}
              >
                {Object.keys(OnboardingIconRefsMap).map(
                  (onboardingIconRef) => {
                    return (
                      <Grid
                        key={onboardingIconRef}
                        container
                        item
                        alignItems='flex-start'
                        alignContent='flex-start'
                        justifyContent='flex-start'
                        sm={3}
                        xs={4}
                      >
                        <Grid
                          data-testid={`onboarding-dialog-image-selection-${onboardingIconRef}`}
                          container
                          item
                          xs={12}
                          onClick={() =>
                            setSelectedIconRefInDialog(
                              onboardingIconRef,
                            )
                          }
                          className={
                            selectedIconRefInDialog ===
                            onboardingIconRef
                              ? classes.selectedIcon
                              : classes.iconRef
                          }
                          alignItems='center'
                          alignContent='center'
                          justifyContent='center'
                        >
                          <OnboardingStepImage
                            iconid={onboardingIconRef}
                            style={{
                              width: 74,
                              height: 74,
                              padding: 10,
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          container
                          alignItems='center'
                          alignContent='center'
                          justifyContent='center'
                        >
                          <Typography
                            style={{
                              fontSize: 13,
                              whiteSpace: 'normal',
                              overflowWrap: 'break-word',
                            }}
                            className={
                              selectedIconRefInDialog ===
                              onboardingIconRef
                                ? classes.selectedIconTitle
                                : classes.iconTitle
                            }
                          >
                            {
                              OnboardingIconRefsMap[onboardingIconRef]
                                .title
                            }
                          </Typography>
                        </Grid>
                      </Grid>
                    );
                  },
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <CSButton
                color='secondary'
                variant='outlined'
                onClick={() => {
                  setCurrentDialogStep(DialogSteps.EDIT_TEXT);
                }}
                className={classes.button}
              >
                Back
              </CSButton>
              <CSButton
                color='secondary'
                variant='contained'
                data-testid='onboarding-step-dialog-change-image-btn'
                onClick={() => {
                  setDialogData({
                    ...dialogData,
                    icon_ref: selectedIconRefInDialog,
                  });
                  setCurrentDialogStep(DialogSteps.EDIT_TEXT);
                }}
                className={classes.button}
              >
                Change image
              </CSButton>
            </DialogActions>
          </>
        )}
      </Dialog>
    );
  };

  const parseText = (text: string): string => {
    if (text.includes('{') && text.includes('}')) {
      const startIndex = text.indexOf('{');
      const endIndex = text.indexOf('}');
      const valuePath = text.substring(startIndex + 1, endIndex);
      //Use reduce to move down to the correct level in the config object and retreive the value.
      const value = valuePath
        .split('.')
        .reduce((theConfigData, currentKey) => {
          return theConfigData[currentKey];
        }, configData);
      if (value === undefined) {
        //Couldn't find a value, return the original string.
        return text;
      }
      const finalText =
        text.substring(0, startIndex) +
        value +
        text.substring(endIndex + 1);
      //Check if there is more than 1 replacement.
      if (finalText.includes('{') && finalText.includes('}')) {
        return parseText(finalText);
      } else {
        return finalText;
      }
    } else {
      return text;
    }
  };

  const renderComponent = () => {
    return (
      <>
        {renderDialog()}
        <Grid
          item
          sm={12}
          style={{ width: '100%' }}
          data-testid={`onboarding-steps-for-${sectionTitle}`}
        >
          <Box mb={0.5}>
            <Accordion
              defaultExpanded={defaultExpanded}
              elevation={0}
            >
              <AccordionSummary
                classes={{
                  expanded: classes.panelSummaryExpanded,
                  root: classes.panelSummary,
                }}
                expandIcon={
                  <ArrowRightIcon
                    className={classes.accordionExpandColapseIcon}
                  />
                }
              >
                <Typography
                  style={{
                    color: colors.dark,
                    fontWeight: 500,
                    fontSize: '18px',
                  }}
                >
                  {sectionTitle}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                classes={{
                  root: classes.panelDetails,
                }}
              >
                <Box>
                  <Box mb={2}>
                    <Grid
                      container
                      item
                      sm={12}
                      xs={12}
                      className={classes.textHeader}
                    >
                      <Grid
                        item
                        sm={1}
                        xs={3}
                        className={classes.pLeft}
                      >
                        Order
                      </Grid>
                      <Hidden xsDown>
                        <Grid item sm={2}>
                          Step Title
                        </Grid>
                      </Hidden>
                      <Grid item sm={6} xs={5}>
                        Description
                      </Grid>
                      <Hidden xsDown>
                        <Grid item sm={1}>
                          Image
                        </Grid>
                      </Hidden>
                      <Grid item sm={1} xs={2}>
                        &nbsp;
                      </Grid>
                      <Grid item sm={1} xs={2}>
                        Active
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider className={classes.divider} />
                    </Grid>
                  </Box>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId='droppable'>
                      {(provided, snapshot) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {stepData?.map(
                            (
                              onboardingStepEl: any,
                              index: number,
                              array: any[],
                            ) => {
                              return (
                                <Draggable
                                  key={`-${index}`}
                                  draggableId={onboardingStepEl.order.toString()}
                                  isDragDisabled={
                                    interaction ===
                                      INTERACTION.READ ||
                                    !showWholeSection
                                  }
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <Grid
                                        container
                                        item
                                        sm={12}
                                        className={classes.row}
                                        alignItems='center'
                                        alignContent='center'
                                        justifyContent='center'
                                      >
                                        <Grid
                                          item
                                          sm={1}
                                          xs={3}
                                          className={classes.dndIcon}
                                        >
                                          <DragHandleIcon
                                            fontSize='large'
                                            style={{
                                              color: colors.gray,
                                            }}
                                          />
                                        </Grid>
                                        <Hidden xsDown>
                                          <Grid
                                            item
                                            sm={2}
                                            xs={5}
                                            className={clsx(
                                              classes.text,
                                              classes.textOverflow,
                                            )}
                                          >
                                            <Highlighter
                                              data-testid={`${sectionTitle}-${index}-title`}
                                              highlightClassName={
                                                classes.searchResultsMark
                                              }
                                              searchWords={
                                                searchResult
                                              }
                                              autoEscape={true}
                                              textToHighlight={parseText(
                                                onboardingStepEl.title,
                                              )}
                                            />
                                          </Grid>
                                        </Hidden>

                                        <Grid
                                          item
                                          sm={6}
                                          xs={5}
                                          className={clsx(
                                            classes.text,
                                            classes.textOverflow,
                                          )}
                                        >
                                          <Highlighter
                                            data-testid={`${sectionTitle}-${index}-description`}
                                            highlightClassName={
                                              classes.searchResultsMark
                                            }
                                            searchWords={searchResult}
                                            autoEscape={true}
                                            textToHighlight={parseText(
                                              onboardingStepEl.description,
                                            )}
                                          />
                                        </Grid>
                                        <Hidden xsDown>
                                          <Grid item sm={1}>
                                            {isValidOnboardingIconRef(
                                              onboardingStepEl.icon_ref,
                                            ) && (
                                              <OnboardingStepImage
                                                iconid={
                                                  onboardingStepEl.icon_ref
                                                }
                                                style={{
                                                  width: 46,
                                                  height: 30,
                                                  padding: 3,
                                                  background:
                                                    '#FAFAFA',
                                                  borderRadius: 2,
                                                }}
                                              />
                                            )}
                                          </Grid>
                                        </Hidden>
                                        <Grid item sm={1} xs={2}>
                                          <IconButton
                                            data-testid={`${sectionTitle}-${index}-edit-btn`}
                                            onClick={() => {
                                              setDialogData({
                                                title: parseText(
                                                  onboardingStepEl.title,
                                                ),
                                                description:
                                                  parseText(
                                                    onboardingStepEl.description,
                                                  ),
                                                order:
                                                  onboardingStepEl.order,
                                                icon_ref:
                                                  onboardingStepEl.icon_ref,
                                              });
                                              setShowDialog(true);
                                            }}
                                          >
                                            <EditIcon />
                                          </IconButton>
                                        </Grid>
                                        <Grid item sm={1} xs={2}>
                                          <CSSwitch
                                            data-testid={`${sectionTitle}-${index}-active-switch`}
                                            color='default'
                                            size='small'
                                            disabled={
                                              interaction ===
                                              INTERACTION.READ
                                            }
                                            checked={
                                              onboardingStepEl.active
                                            }
                                            onChange={() => {
                                              onboardingStepEl.active =
                                                !onboardingStepEl.active;
                                              setStepData([
                                                ...stepData,
                                              ]);
                                              setPartialData([
                                                ...stepData,
                                              ]);
                                            }}
                                          />
                                        </Grid>
                                        {currentView !==
                                          CurrentView.SEARCH &&
                                          index !==
                                            array.length - 1 && (
                                            <Grid item xs={12}>
                                              <Divider
                                                className={
                                                  classes.divider
                                                }
                                              />
                                            </Grid>
                                          )}
                                      </Grid>
                                    </div>
                                  )}
                                </Draggable>
                              );
                            },
                          )}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Grid>
      </>
    );
  };

  return currentView === CurrentView.SEARCH && !searchResult.length
    ? null
    : renderComponent();
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...selectStyles,
    ...configPageStyles(theme),
    dialogRoot: {
      width: '502px',
      maxHeight: '409px',
    },
    dialogInputTitle: {
      color: colors.gray,
      fontWeight: 400,
      fontSize: '14px',
    },
    panelSummary: {
      backgroundColor: colors.white,
      borderRadius: '4px',
      height: '40px',
      borderBottom: `1px solid ${colors.ultraLightGray}`,
      flexDirection: 'row-reverse',
      [theme.breakpoints.down('md')]: {
        height: '50px',
      },
      paddingLeft: '0px',
    },
    accordionExpandColapseIcon: {
      color: colors.dark,
      fontSize: '2.3rem',
    },
    panelSummaryExpanded: {
      borderRadius: '4px 4px 0px 0px !important',
      minHeight: '0px !important',
      '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
        transform: 'rotate(270deg)',
      },
    },
    iconRef: {
      background: colors.ultraLightGray,
      border: `2px solid transparent`,
      borderRadius: 4,
    },
    selectedIcon: {
      background: colors.ultrLightGold,
      border: `2px solid ${colors.darkGold}`,
      borderRadius: 4,
    },
    iconTitle: {
      fontSize: 13,
      color: colors.gray,
    },
    selectedIconTitle: {
      fontSize: 13,
      color: colors.darkGold,
    },
    dialogContentTitleAndDescription: {
      marginLeft: 56,
      marginRight: 56,
      [theme.breakpoints.down('xs')]: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    dialogContentImageSelection: {
      minWidth: 400,
      [theme.breakpoints.down('xs')]: {
        minWidth: 'auto',
      },
    },
    textOverflow: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  })),
)(OnboardingStep);
