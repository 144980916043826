import { LngLatLike, MapRef } from 'react-map-gl';
import { LatLngMapboxAlike } from '../types';
export const useMap = (mapRef: React.RefObject<MapRef>) => {
  const getPaddedBounds = (
    bounds: mapboxgl.LngLatBounds,
    paddingPixels = 50,
  ): LatLngMapboxAlike | undefined => {
    if (!mapRef.current) return;

    const { width, height } = mapRef.current.getCanvas();

    const horizontalPadding =
      Math.abs(bounds._sw.lat - bounds._ne.lat) *
      (paddingPixels / width);

    const verticalPadding =
      Math.abs(bounds._sw.lng - bounds._ne.lng) *
      (paddingPixels / height);

    return {
      _sw: {
        lng: bounds._sw.lng + horizontalPadding,
        lat: bounds._sw.lat + verticalPadding,
      },
      _ne: {
        lng: bounds._ne.lng - horizontalPadding,
        lat: bounds._ne.lat - verticalPadding,
      },
    };
  };

  const fitMapBounds = (
    sw: LngLatLike,
    ne: LngLatLike,
    padding: number = 50,
    maxZoom: number = 12,
    maxDuration?: number,
  ) => {
    mapRef.current?.fitBounds([sw, ne], {
      padding,
      maxZoom,
      maxDuration,
    });
  };

  const flyToCoordinate = (
    long: number,
    lat: number,
    zoom: number = 10,
  ) => {
    mapRef.current?.flyTo({
      center: [long, lat],
      zoom,
    });
  };

  return { getPaddedBounds, fitMapBounds, flyToCoordinate };
};
