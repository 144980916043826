import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { useEffect, useRef } from 'react';
import { useOnWindowResize } from './useOnWindowResize';
import { calculateCustomPosition } from './CSDatepicker.utils';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { DatePopperAlignment } from './CSDatepickerTypes';

export const useWrapperDatepicker = (
  isOpen: boolean,
  initialValue: ParsableDate,
  enabled: boolean,
  DatePopperAlignment: DatePopperAlignment,
) => {
  const anchorRef = useRef<HTMLDivElement>(null);
  const popperRef = useRef<HTMLDivElement>(null);
  useOnWindowResize(() => {
    if (!enabled) return;
    const styles = calculateCustomPosition(
      popperRef.current,
      anchorRef.current,
      DatePopperAlignment,
    );
    const { current } = popperRef;
    current?.setAttribute('style', styles || '');
  });
  useEffect(() => {
    if (!enabled) return;
    if (isOpen && initialValue)
      requestAnimationFrame(() => {
        const styles = calculateCustomPosition(
          popperRef.current,
          anchorRef.current,
          DatePopperAlignment,
        );
        const { current } = popperRef;
        current?.setAttribute('style', styles || '');
      });
  }, [isOpen, initialValue, enabled, DatePopperAlignment]);
  return { anchorRef, popperRef };
};
