import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import Layout from '../layout/Layout';
import PaginatedTable, {
  filterObj,
} from '../paginatedTable/PaginatedTable';
import { AuthRoutes, ModulesKeys } from '../../interfaces/routes';
import queryString from 'query-string';
import { AlertBanner, CSButton, Typography } from '../primitives';
import ErrorHandler from '../../utils/ErrorHandler';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import browserHistory from '../../utils/browserHistory';

import { Link, RouteComponentProps } from 'react-router-dom';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import detailsPageStyles from '../commonStyles/detailsPage.style';
import selectStyles from '../select/select.styles';
import configurationUtils from '../../utils/configurationUtils';
import { common } from '../../utils/strings';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme,
  useTheme,
} from '@material-ui/core';
import sectionPageBaseStyle from '../commonStyles/sectionPageBase.style';
import SingleDetail from '../primitives/singleDetail/SingleDetail';
import { formatFirstNItemsAndMore } from '../DetailsPagesFunctions';
import { noOptionsMessage } from '../../components/asyncSelect/utils';
import debounce from 'lodash/debounce';
import sanitizeHtml from 'sanitize-html';
import AddSchemeDialog from '../schemes/addSchemeDialog/AddSchemeDialog';
import EditSchemeDialog from '../schemes/editSchemeDialog/EditSchemeDialog';

// Services
import SchemesService from '../../services/Schemes.service';
import PermissionsService from '../../services/Permissions.service';
import LoadPlansService from '../../services/LoadPlans.service';

// Icons
import CloudDownloadOutlined from '@material-ui/icons/CloudDownloadOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import AddToPhotosOutlinedIcon from '@material-ui/icons/AddToPhotosOutlined';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';

// Types
import { Column, TypeAheadItem } from '../../interfaces/components';
import { AxiosError } from 'axios';
import {
  APIExceptionErrorCodeEnum,
  Scheme,
  LoadPlan,
  StaffPermissionPermissionsEnum,
  SchemeDetails,
  User,
  APIException,
} from 'cloudsort-client';
import FmcService from '../../services/Fmc.service';
import ConfirmationDialog from '../confirmationDialog/ConfirmationDialog';
import AddSchemeSetDialog from '../schemes/addSchemeSetDialog/AddSchemeSetDialog';
import { CloudUploadOutlined, Delete } from '@material-ui/icons';
import { Helmet } from 'react-helmet';
import DeleteSchemeDialog from '../schemes/deleteSchemeDialog/DeleteSchemeDialog';
import CSSectionTitleSeparator from '../primitives/csSectionTitleSeparator/CSSectionTitleSeparator';
import useStationId from '../../hooks/useStationId';
import CSBreadcrumbs from '../primitives/CSBreadcrumbs/CSBreadcrumbs';
import CSDialogAlert from '../primitives/csDialogAlert/CSDialogAlert';
import { CSSingleDetailMultiColumnContainer } from '../primitives/singleDetail/singleDetailMultiColumnContainer';
import CSDialogTitleWithIcon from '../primitives/csDialogTitleWithIcon/CSDialogTitleWithIcon';
import { DialogIcons } from '../primitives/csDialogTitleWithIcon/CSDialogTitleWithIconTypes';
import CSAsyncSelect from '../primitives/csAsyncSelect/CSAsyncSelect';
import usePolling from '../../hooks/usePolling';
import { useAppDispatch } from '../../redux/store';
import { setLastVisitedModule } from '../../redux/slices/navigationSlice';

const DEFAULT_ALIGN = 'center';

const COLUMNS_STOPS: Column[] = [
  {
    id: 'id',
    label: 'ID',
    align: 'left',
    hide: true,
  },
  {
    id: 'name_link',
    label: 'Name',
    align: 'left',
    useCustomComponent: true,
  },
  {
    id: 'fmc_full_name',
    label: 'Carrier',
    align: DEFAULT_ALIGN,
  },
  {
    id: 'owner_identifier',
    label: 'Owner',
    align: DEFAULT_ALIGN,
  },
  {
    id: 'zipcode_count',
    label: 'ZIP Codes',
    align: DEFAULT_ALIGN,
  },
  {
    id: 'sort_param',
    label: 'Sortation Parameter',
    align: DEFAULT_ALIGN,
  },
];

interface DeleteParams {
  id: number;
  name: string;
}

interface IMatch extends Record<string, unknown> {
  params: {
    id: number;
    imported: string;
  };
}

interface Props extends Omit<RouteComponentProps, 'match'> {
  match: IMatch;
  classes: { [key: string]: string };
}

interface DeleteSetParams {
  id: number;
  name: string;
}

enum PageDialogs {
  ADD_SCHEME_DIALOG = 'ADD_SCHEME_DIALOG',
  DELETE_SCHEME_SET_DIALOG = 'DELETE_SCHEME_SET_DIALOG',
  DELETE_SCHEME_SET_CONFIRMATION = 'DELETE_SCHEME_SET_CONFIRMATION',
  IMPORT_SCHEME_SET_DIALOG = 'IMPORT_SCHEME_SET_DIALOG',
  DELETE_LOADPLAN_CONFIRMATION = 'DELETE_LOADPLAN_CONFIRMATION',
  EDIT_SCHEME_ROW_DIALOG = 'EDIT_SCHEME_ROW_DIALOG',
  DELETE_SCHEME_SET_ROW_CONFIRMATION = 'DELETE_SCHEME_SET_ROW_CONFIRMATION',
}

const Loadplans: React.FC<Props> = ({ match, classes, location }) => {
  const [batchExportEnabled, setBatchExportEnabled] =
    useState<boolean>(false);
  const [showProgress, setShowProgress] = useState<boolean>(false);

  const [activeDialog, setActiveDialog] = useState<
    PageDialogs | undefined
  >();

  const [schemeCurrentlyEditing, setSchemeCurrentlyEditing] =
    useState<SchemeDetails>();
  const [deleteSchemeSetData, setDeleteSchemeSetData] =
    useState<DeleteSetParams>({
      id: 0,
      name: '',
    });

  const [error, setError] = useState<string>();
  const [loadplanData, setLoadplanData] = useState<LoadPlan>();
  const [fetchedLoadPlanId, setFetchedLoadPlanId] =
    useState<number>();
  const [alertMessage, setAlertMessage] = useState<
    string | undefined
  >();
  // It's possible for a user to open loadplan details page without station id in the url.
  //In this case we need to get it from the loadplan data and set it in the url. The web app (navigation bar) will use it to display the station name and show the correct navigation menu.
  useStationId(loadplanData?.station);

  const loadplanLabels = useMemo(() => {
    return {
      singular: configurationUtils.getPageTitle(true, 'LOADPLAN'),
      plural: configurationUtils.getPageTitle(false, 'LOADPLAN'),
    };
  }, []);

  const [deleteParams, setDeleteParams] = useState<DeleteParams>();
  const [schemes, setSchemes] = useState<Scheme[]>();

  const [lastUpdated, setLastUpdated] = useState<string>(
    new Date().toISOString(),
  );
  const [fileIdByUrl, setFileIdByUrl] = useState<string>();

  const dispatch = useAppDispatch();

  const fetchLoadplan = async () => {
    const id = match.params.id;

    if (id) {
      //loadplan by id
      const res = await LoadPlansService.getById(id);
      setLoadplanData(res.data);
      setFetchedLoadPlanId(res.data.id);
    } else {
      //active loadplan
      const res = await LoadPlansService.getAll({ active: true });
      if (res.data.results.length) {
        setLoadplanData(res.data.results[0]);
        setFetchedLoadPlanId(res.data.results[0].id);
      } else {
        setLoadplanData(undefined);
        setFetchedLoadPlanId(undefined);
        setAlertMessage(
          `This station has no active ${loadplanLabels.singular}`,
        );
      }
    }
  };

  const activateLoadPlan = async () => {
    setShowProgress(true);
    try {
      await LoadPlansService.updateById(fetchedLoadPlanId!, {
        active: true,
        station: loadplanData!.station,
        created_by: loadplanData!.created_by,
      });
      browserHistory.push(
        `${AuthRoutes.LOADPLAN}/${fetchedLoadPlanId!}`,
      );
      setAlertMessage(undefined);
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      //activation is too fast, adding some delay as a feedback that something is happening
      setTimeout(() => {
        setShowProgress(false);
      }, 1500);
    }
  };

  const formatAsyncOptions = (data: User[]) => {
    return data.map((dataEl: User) => {
      return {
        value: dataEl.id,
        label: dataEl.full_name,
      };
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadCarrierOptions = useCallback(
    debounce((inputValue: string, callback: any) => {
      FmcService.search(inputValue)
        .then((data) => {
          callback(formatAsyncOptions(data.data.results));
        })
        .catch((e) => {
          handleError(e as AxiosError);
        });
    }, 500),
    [],
  );

  const fetchSchemes = async (
    pageIndex: number,
    rowsPerPage: number,
    filterOptions?: filterObj[],
    filterByString?: string,
    sortedBy?: string,
  ) => {
    const res = await SchemesService.getAll({
      page: pageIndex,
      pageSize: rowsPerPage,
      loadplan: fetchedLoadPlanId,
      search: filterByString,
    });

    res.data.results.forEach((scheme: any) => {
      scheme.name_link = (
        <Link
          style={{ color: theme.palette.tertiary?.main }}
          to={`${AuthRoutes.SCHEME}/${scheme.id}`}
        >
          {scheme.name}
        </Link>
      );
      scheme.sort_param = scheme.sort_param
        ? sanitizeHtml(scheme.sort_param, {
            allowedTags: [],
          })
        : common.emptyValue;
    });

    if (pageIndex === 1 && res.data.count > 0) {
      setBatchExportEnabled(true);
    }

    setSchemes(res.data.results);

    return res;
  };

  const handleError = async (e: AxiosError) => {
    const errorData = e.response?.data as APIException;
    if (
      errorData?.error_code ===
      APIExceptionErrorCodeEnum.RESOURCE_CONFLICT
    ) {
      const conflictingZipcodes =
        ErrorHandler.getConflictingZipcodes(errorData);
      setError(
        'The following Zip Codes are part of multiple schemes from the same carrier: ' +
          formatFirstNItemsAndMore(conflictingZipcodes, 10), //show first 10
      );
    } else setError(await ErrorHandler.getLabel(e as AxiosError));
  };

  const { startPolling, checkTaskId } = usePolling();

  const downloadBatchCSVs = async () => {
    setShowProgress(true);
    try {
      const res = await LoadPlansService.export(loadplanData!.id!);
      const fileNamStr = `${loadplanLabels.singular}-${loadplanData?.id}-export.csv`;
      startPolling(() => {
        checkTaskId(
          res.data.task_id,
          fileNamStr,
          setError,
          setShowProgress,
        );
      });
    } catch (e) {
      handleError(e as AxiosError);
    }
  };

  const onEditScheme = async (id: number) => {
    setShowProgress(true);
    try {
      const scheme = await SchemesService.getById(id);
      setSchemeCurrentlyEditing(scheme.data);
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setActiveDialog(PageDialogs.EDIT_SCHEME_ROW_DIALOG);
      setShowProgress(false);
    }
  };

  const processDeleteLP = async () => {
    setActiveDialog(undefined);
    setShowProgress(true);
    try {
      await LoadPlansService.delete(match.params.id);
      browserHistory.push(AuthRoutes.LOADPLAN + '_list');
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  const processDeleteSchemeSet = async () => {
    const id = match.params.id;
    setActiveDialog(undefined);
    setShowProgress(true);
    try {
      await LoadPlansService.deleteSchemeSet(id, {
        carrier: deleteSchemeSetData.name,
      });
      setDeleteSchemeSetData({
        id: 0,
        name: '',
      });
      setLastUpdated(new Date().toISOString());
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };
  const theme = useTheme();
  const renderDeleteSchemeSetDialog = () => {
    return (
      <Dialog
        open={activeDialog === PageDialogs.DELETE_SCHEME_SET_DIALOG}
      >
        <DialogTitle>
          <CSDialogTitleWithIcon
            icon={DialogIcons.DELETE}
            title='Delete Scheme Set'
          />
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <CSAsyncSelect<TypeAheadItem>
                label='Carrier'
                containerSize='fullHorizontal'
                isClearable
                cacheOptions
                loadOptions={loadCarrierOptions}
                onChange={(option) => {
                  if (option as TypeAheadItem) {
                    setDeleteSchemeSetData({
                      id: Number((option as TypeAheadItem).value),
                      name: (option as TypeAheadItem).label,
                    });
                  } else {
                    setDeleteSchemeSetData({
                      id: 0,
                      name: '',
                    });
                  }
                }}
                isDisabled={!!error}
                placeholder={'Start Typing...'}
                menuPortalTarget={document.body}
                noOptionsMessage={noOptionsMessage}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <CSButton
            variant='outlined'
            color='secondary'
            className={classes.marginRight10}
            onClick={() => {
              setActiveDialog(undefined);
              setDeleteSchemeSetData({
                id: 0,
                name: '',
              });
            }}
          >
            Cancel
          </CSButton>

          <CSButton
            variant='contained'
            color='secondary'
            data-testid={'scheme-set-delete-dialog-save'}
            onClick={() => {
              setActiveDialog(
                PageDialogs.DELETE_SCHEME_SET_CONFIRMATION,
              );
            }}
          >
            Delete
          </CSButton>
        </DialogActions>
      </Dialog>
    );
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const fileId = urlParams.get('fileId');
    if (fileId) {
      setFileIdByUrl(fileId);
      setActiveDialog(PageDialogs.IMPORT_SCHEME_SET_DIALOG);
    }
  }, []);

  useEffect(() => {
    PermissionsService.redirectIfNoPermission(
      StaffPermissionPermissionsEnum.LOADPLAN_READ,
    );

    dispatch(
      setLastVisitedModule({
        module: ModulesKeys.LOADPLAN,
        permission: StaffPermissionPermissionsEnum.LOADPLAN_READ,
      }),
    );

    fetchLoadplan();
    if (match.params.imported === 'true')
      setAlertMessage(
        `${loadplanLabels.singular} created/imported sucessfully. You can activate it by clicking the button on the top right.`,
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match]);

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort -
${loadplanLabels.singular} ${match.params.id}`}
        </title>
      </Helmet>
      <Layout navCurrent='LOAD_PLAN'>
        <AddSchemeDialog
          loadplanId={loadplanData?.id}
          isOpen={activeDialog === PageDialogs.ADD_SCHEME_DIALOG}
          onAfterClose={() => {
            setActiveDialog(undefined);
          }}
          updateParent={() => {
            setLastUpdated(new Date().toISOString());
          }}
        />
        <EditSchemeDialog
          editSchemeData={schemeCurrentlyEditing!}
          isOpen={activeDialog === PageDialogs.EDIT_SCHEME_ROW_DIALOG}
          onAfterClose={() => {
            setActiveDialog(undefined);
          }}
          updateParent={() => {
            setLastUpdated(new Date().toISOString());
          }}
        />
        {deleteParams && (
          <DeleteSchemeDialog
            schemeId={deleteParams!.id}
            schemeName={deleteParams!.name}
            isOpen={
              activeDialog ===
              PageDialogs.DELETE_SCHEME_SET_ROW_CONFIRMATION
            }
            onAfterClose={() => {
              setActiveDialog(undefined);
            }}
            updateParent={() => {
              setLastUpdated(new Date().toISOString());
            }}
          />
        )}
        {renderDeleteSchemeSetDialog()}
        {loadplanData && loadplanData.id && (
          <AddSchemeSetDialog
            loadplanId={loadplanData.id.toString()}
            isOpen={
              activeDialog === PageDialogs.IMPORT_SCHEME_SET_DIALOG
            }
            fileId={fileIdByUrl}
            onAfterClose={() => {
              setActiveDialog(undefined);
            }}
            updateParent={() => {
              setLastUpdated(new Date().toISOString());
            }}
          />
        )}

        <ConfirmationDialog
          dataTestIdPrefix={'delete-lp-confirmation'}
          title={`Delete ${loadplanLabels.singular}`}
          msg={`Are you sure you want to delete ${loadplanLabels.singular} ${match.params.id}?`}
          primaryActionLabel={'Yes'}
          onPrimaryAction={() => {
            processDeleteLP();
          }}
          cancelLabel={'No'}
          onCancel={() => {
            setActiveDialog(undefined);
          }}
          isOpen={
            activeDialog === PageDialogs.DELETE_LOADPLAN_CONFIRMATION
          }
        />
        <ConfirmationDialog
          dataTestIdPrefix={'delete-scheme-set-confirmation'}
          title={'Delete Scheme Set'}
          msg={`Are you sure you want to delete ${
            deleteSchemeSetData?.name
              ? `the ${deleteSchemeSetData?.name}`
              : 'all'
          } scheme set${deleteSchemeSetData?.name ? '' : 's'} for ${
            loadplanLabels.singular
          } ${match.params.id}?`}
          primaryActionLabel={'Yes'}
          onPrimaryAction={() => {
            processDeleteSchemeSet();
          }}
          cancelLabel={'No'}
          onCancel={() => {
            setActiveDialog(undefined);
          }}
          isOpen={
            activeDialog ===
            PageDialogs.DELETE_SCHEME_SET_CONFIRMATION
          }
        />
        {showProgress && <ProgressIndicator />}
        {error && (
          <AlertBanner
            className={classes.banner}
            severity='error'
            alertTitle={'Error'}
            alertMsg={error}
          />
        )}
        <Grid container alignItems='flex-end'>
          <Grid item xs={12}>
            <Box mb={5}>
              <CSBreadcrumbs
                breadcrumbs={[
                  {
                    label: configurationUtils.getPageTitle(
                      false,
                      'STOP',
                    ),
                    link:
                      AuthRoutes.STOP +
                      '?stationId=' +
                      loadplanData?.station,
                  },
                  {
                    label: loadplanLabels.plural,
                    link:
                      AuthRoutes.LOADPLAN +
                        '_list?stationId=' +
                        loadplanData?.station || '',
                  },
                  {
                    label: `${loadplanLabels.singular} ${loadplanData?.id}`,
                    selected: true,
                  },
                ]}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Typography variant={'h3'} component='h2'>
              {match.params.id
                ? `${loadplanLabels.singular} Details for ${match.params.id}`
                : `Active ${loadplanLabels.singular}`}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={7}
            className={classes.nonMobileAlignRight}
          >
            {PermissionsService.hasPermission(
              StaffPermissionPermissionsEnum.LOADPLAN_WRITE,
            ) &&
              match.params.id && (
                <>
                  <CSButton
                    variant='outlined'
                    color='secondary'
                    className={classes.headerButton}
                    onClick={() => {
                      setActiveDialog(
                        PageDialogs.DELETE_LOADPLAN_CONFIRMATION,
                      );
                    }}
                    disabled={loadplanData?.active}
                    data-testid={'delete-load-plan'}
                    startIcon={<Delete />}
                  >
                    Delete {loadplanLabels.singular}
                  </CSButton>
                  <CSButton
                    variant='contained'
                    color={{
                      buttonColor: 'secondary',
                      iconColor: 'primary',
                    }}
                    className={classes.headerButton}
                    onClick={() => {
                      activateLoadPlan();
                    }}
                    disabled={loadplanData?.active}
                    data-testid={'activate-load-plan'}
                    startIcon={<CheckCircleOutlineIcon />}
                  >
                    Activate {loadplanLabels.singular}
                  </CSButton>
                </>
              )}
            {PermissionsService.hasPermission(
              StaffPermissionPermissionsEnum.LOADPLAN_REPORT_READ,
            ) && (
              <CSButton
                variant='contained'
                color='primary'
                className={classes.headerButton}
                disabled={!batchExportEnabled}
                onClick={() => {
                  downloadBatchCSVs();
                }}
                data-testid={'export-load-plan'}
                startIcon={<CloudDownloadOutlined />}
              >
                Export {loadplanLabels.singular}
              </CSButton>
            )}
          </Grid>
        </Grid>
        <CSSectionTitleSeparator topMargin={10} bottomMargin={24} />
        {alertMessage && (
          <CSDialogAlert
            alertMessage={alertMessage || ''}
            bottomMargin={20}
          />
        )}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CSSingleDetailMultiColumnContainer
              columns={3}
              elements={[
                <SingleDetail
                  inline={true}
                  label={loadplanLabels.singular + ' ID'}
                  value={loadplanData?.id || common.emptyValue}
                />,
                <SingleDetail
                  inline={true}
                  label='Filename'
                  value={loadplanData?.file_name || common.emptyValue}
                />,
                <SingleDetail
                  inline={true}
                  label={
                    'Total ' +
                    configurationUtils.getPageTitle(false, 'STOP')
                  }
                  value={
                    loadplanData?.total_stops || common.emptyValue
                  }
                />,
                <SingleDetail
                  inline={true}
                  label='Imported by'
                  value={
                    loadplanData?.created_by_name || common.emptyValue
                  }
                />,
                <SingleDetail
                  inline={true}
                  label='Imported on'
                  value={
                    loadplanData?.created_on
                      ? new Date(
                          loadplanData.created_on,
                        ).toLocaleString()
                      : common.emptyValue
                  }
                />,
                <SingleDetail
                  inline={true}
                  label='Total ZIP Codes'
                  value={
                    loadplanData?.total_zipcodes || common.emptyValue
                  }
                />,
              ]}
            />
          </Grid>
          <Grid item xs={12} className={classes.textAlignRight}>
            {PermissionsService.hasPermission(
              StaffPermissionPermissionsEnum.LOADPLAN_WRITE,
            ) && (
              <Box mb={3}>
                <CSButton
                  data-testid={'delete-scheme-set-btn'}
                  variant='outlined'
                  color='secondary'
                  className={classes.headerButton}
                  onClick={() => {
                    setActiveDialog(
                      PageDialogs.DELETE_SCHEME_SET_DIALOG,
                    );
                  }}
                  disabled={!loadplanData}
                  startIcon={<Delete />}
                >
                  Delete Scheme Set
                </CSButton>
                <CSButton
                  variant='contained'
                  color={{
                    buttonColor: 'secondary',
                    iconColor: 'primary',
                  }}
                  className={classes.headerButton}
                  onClick={() => {
                    setActiveDialog(PageDialogs.ADD_SCHEME_DIALOG);
                  }}
                  disabled={!loadplanData}
                  startIcon={<AddToPhotosOutlinedIcon />}
                >
                  Add Scheme
                </CSButton>
                <CSButton
                  data-testid={'add-scheme-set'}
                  variant='contained'
                  color='primary'
                  className={classes.headerButton}
                  onClick={() => {
                    setActiveDialog(
                      PageDialogs.IMPORT_SCHEME_SET_DIALOG,
                    );
                  }}
                  disabled={!!error || !loadplanData}
                  startIcon={<CloudUploadOutlined />}
                >
                  Import Scheme Set
                </CSButton>
              </Box>
            )}
          </Grid>
        </Grid>
        {fetchedLoadPlanId && (
          <PaginatedTable
            key={lastUpdated}
            title={'Schemes'}
            columns={COLUMNS_STOPS}
            dataTestIdPrefix={'stops'}
            fetch={fetchSchemes}
            rowsLoadDetailPages={false}
            detailsPageBasePath={
              AuthRoutes.LOADPLAN + '/' + match.params.id
            }
            filterIsEnabled={false}
            filterByString={true}
            filterByStringPlaceholder={`Search Schemes`}
            defaultSort={
              (queryString.parse(location.search)[
                'sortBy'
              ] as string) || undefined
            }
            sortableBy={['routes_count']}
            groupActions
            actions={[
              {
                tableLabel: PermissionsService.hasPermission(
                  StaffPermissionPermissionsEnum.LOADPLAN_WRITE,
                )
                  ? ' '
                  : undefined,
                columnLabel: PermissionsService.hasPermission(
                  StaffPermissionPermissionsEnum.LOADPLAN_WRITE,
                ) ? (
                  <EditIcon className={classes.actionIcon} />
                ) : undefined,
                callbackProperty: 'id',
                qualifier: 'id',
                callback: onEditScheme,
              },
              {
                tableLabel: PermissionsService.hasPermission(
                  StaffPermissionPermissionsEnum.LOADPLAN_WRITE,
                )
                  ? ' '
                  : undefined,
                columnLabel: PermissionsService.hasPermission(
                  StaffPermissionPermissionsEnum.LOADPLAN_WRITE,
                ) ? (
                  <CancelIcon className={classes.actionIcon} />
                ) : undefined,
                callbackProperty: 'id',
                qualifier: 'id',
                callback: (id: number) => {
                  const scheme = schemes?.find(
                    (scheme) => scheme.id === id,
                  );
                  setDeleteParams({
                    id,
                    name: scheme?.name || '',
                  });

                  setActiveDialog(
                    PageDialogs.DELETE_SCHEME_SET_ROW_CONFIRMATION,
                  );
                },
              },
            ]}
          />
        )}
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...selectStyles,
    ...sectionPageBaseStyle,
    nonMobileAlignRight: {
      [theme.breakpoints.up('xs')]: {
        textAlign: 'right',
      },
    },
    dialogContent: { width: '500px', maxWidth: '100%' },
    headerButton: {
      margin: '10px 0 0 15px',
    },
    dialogActions: {
      padding: 24,
    },
    actionIcon: {
      width: 16,
      height: 'auto',
    },
    marginRight10: { marginRight: '10px' },
    textAlignRight: { textAlign: 'right' },
  })),
)(Loadplans);
