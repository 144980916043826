import React, { useCallback, useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { AlertBanner, CSButton } from '../../primitives';
import { withStyles } from '@material-ui/core/styles';
import { DialogTitle, createStyles } from '@material-ui/core';
import detailsPageStyles from '../../commonStyles/detailsPage.style';
import ErrorHandler from '../../../utils/ErrorHandler';
import { AxiosError } from 'axios';
import selectStyles from '../../select/select.styles';
import {
  AssignedEntity,
  getAllOrganizationsStationsData,
} from './utils';
import CSAsyncSelect from '../../primitives/csAsyncSelect/CSAsyncSelect';
import { TypeAheadItem } from '../../../interfaces/components';
import { noOptionsMessage } from '../../asyncSelect/utils';
import useControlled from '../../../utils/useControlled';
import debounce from 'lodash/debounce';
import CSDialogTitleWithIcon from '../../primitives/csDialogTitleWithIcon/CSDialogTitleWithIcon';
import { DialogIcons } from '../../primitives/csDialogTitleWithIcon/CSDialogTitleWithIconTypes';

interface Props {
  classes: { [key: string]: string };
  isOpen: boolean;
  entities: AssignedEntity[];
  onCancel: () => void;
  onSave: (newEntities: AssignedEntity[]) => void;
}

const EditOrganizationsStationsDialog: React.FC<Props> = ({
  classes,
  isOpen,
  onCancel,
  onSave,
  entities,
}) => {
  const [open, setOpen] = useControlled({
    controlled: isOpen,
    default: false,
    name: 'EditOrganizationsStationsDialog',
  });
  const [selectedEntities, setSelectedEntities] = useState<
    TypeAheadItem<AssignedEntity>[]
  >([]);
  const [error, setError] = useState<string>();

  useEffect(() => {
    setSelectedEntities(
      entities.map((entity) => {
        return {
          label: entity.name,
          value: entity,
        } as TypeAheadItem<AssignedEntity>;
      }),
    );
  }, [open, entities]);

  const handleError = async (err: AxiosError) => {
    setError(await ErrorHandler.getLabel(err));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadOrganizationStationDataOptions = useCallback(
    debounce((inputValue: string, callback: any) => {
      getAllOrganizationsStationsData(inputValue)
        .then((orgStationData) => {
          callback(
            orgStationData
              .filter((entity) => {
                return !selectedEntities.find(
                  (selectedEntity) =>
                    selectedEntity.value.id === entity.id,
                );
              })
              .map((entity: AssignedEntity) => {
                return {
                  label: entity.name,
                  value: entity,
                } as TypeAheadItem<AssignedEntity>;
              }),
          );
        })
        .catch((e) => {
          handleError(e as AxiosError);
        });
    }, 500),
    [selectedEntities],
  );

  return (
    <Dialog open={Boolean(open)}>
      <DialogTitle>
        <CSDialogTitleWithIcon
          icon={DialogIcons.EDIT}
          title='Edit Organizations or Stations'
        />
      </DialogTitle>
      {error && (
        <DialogContent className={classes.dialogContent}>
          <AlertBanner
            className={classes.banner}
            severity='error'
            alertTitle={'Error'}
            alertMsg={error}
          />
        </DialogContent>
      )}
      <DialogContent className={classes.dialogContent}>
        <CSAsyncSelect<TypeAheadItem<AssignedEntity>, true>
          isClearable
          isMulti
          containerSize='fullHorizontal'
          menuPortalTarget={document.body}
          loadOptions={loadOrganizationStationDataOptions}
          onChange={(
            newValues: readonly TypeAheadItem<AssignedEntity>[],
          ) => {
            setSelectedEntities([...newValues]);
          }}
          value={selectedEntities}
          noOptionsMessage={noOptionsMessage}
          placeholder={'Search Organizations and Stations'}
        />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <CSButton
          variant='outlined'
          color='secondary'
          onClick={(e) => {
            setOpen(false);
            onCancel();
          }}
          className={classes.button}
        >
          Cancel
        </CSButton>
        <CSButton
          variant='contained'
          color='secondary'
          onClick={(e) => {
            onSave(selectedEntities.map((entity) => entity.value));
            setOpen(false);
          }}
        >
          Save
        </CSButton>
      </DialogActions>
    </Dialog>
  );
};
export default withStyles(
  createStyles(() => ({
    ...detailsPageStyles,
    ...selectStyles,
    dialogContent: {
      width: '500px',
      maxWidth: '100%',
      minHeight: '100px',
    },
    dialogActions: {
      padding: '16px 23px 25px 8px',
    },
  })),
)(EditOrganizationsStationsDialog);
