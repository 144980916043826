import QRCode from 'qrcode';
import { saveAs } from 'file-saver';

export const generateQR = async (text: string) => {
  return QRCode.toDataURL(text);
};

export const download = async (res: any, name?: string) => {
  const fileNamStr = res.request.getResponseHeader(
    'content-disposition',
  );
  saveAs(
    res.data,
    name || fileNamStr.split('filename=')[1].replace(/"/g, ''),
  );
};

export const printOnNewTab = async (qrCode: string) => {
  const image = new Image();
  image.src = qrCode;
  image.style.width = '50%';

  const newWindow = window.open(qrCode);
  if (newWindow) {
    newWindow.document.write(image.outerHTML);
    newWindow.focus();
    setTimeout(() => newWindow.print(), 1000);
  }
};

const exportObject = {
  generateQR,
  printOnNewTab,
  download,
};

export default exportObject;
