import { SchemeDetails } from 'cloudsort-client';

export const zipcodesObjectToArray = (
  zipcodes: SchemeDetails['zipcodes'],
) => {
  if (!zipcodes) return [];
  return Object.keys(zipcodes)
    .map((countryAbbreviation) =>
      zipcodes[countryAbbreviation].map((zipcodesGroup) => {
        return {
          country: countryAbbreviation,
          zipcodes: zipcodesGroup as unknown as string[],
        };
      }),
    )
    .flat();
};

export type ZipcodesArray = ReturnType<typeof zipcodesObjectToArray>;

export const zipcodesArrayToObject = (
  zipcodesArray: ZipcodesArray,
): SchemeDetails['zipcodes'] => {
  return zipcodesArray.reduce((acc, item) => {
    const prev = acc?.hasOwnProperty(item.country)
      ? acc[item.country]
      : [];
    acc![item.country] = [
      ...prev,
      item.zipcodes.filter((zipcode) => zipcode),
    ];
    return acc;
  }, {} as SchemeDetails['zipcodes']);
};
