import React, { useState, useEffect } from 'react';

import {
  withStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles';

import { Paper } from '@material-ui/core';

//Icons
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import colors from '../../utils/colors';

interface Props {
  classes: { [key: string]: string };
  password1?: string;
  password2?: string;
  checkForMatchingPasswords?: boolean;
}

export const validatePassword = (password: string): boolean => {
  return /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{10,}/.test(password);
};

const PasswordRequirementsIndicator: React.FC<Props> = ({
  classes,
  password1 = '',
  password2 = '',
  checkForMatchingPasswords,
}) => {
  const [passwordChecks, setPasswordChecks] = useState<any>({
    chars: false,
    uppercase: false,
    lowercase: false,
    numbers: false,
    match: false,
  });

  useEffect(() => {
    const passwordRequirements = {
      chars: false,
      uppercase: false,
      lowercase: false,
      numbers: false,
      match: false,
    };
    passwordRequirements.chars = password1.length >= 10;
    passwordRequirements.uppercase = /[A-Z]/.test(password1);
    passwordRequirements.lowercase = /[a-z]/.test(password1);
    passwordRequirements.numbers = /\d/.test(password1);

    if (checkForMatchingPasswords && password1) {
      passwordRequirements.match = password1 === password2;
    }

    setPasswordChecks(passwordRequirements);
  }, [password1, password2, checkForMatchingPasswords]);

  return (
    <Paper className={classes.paper}>
      <ul>
        <li
          className={passwordChecks.chars ? classes.pass : undefined}
        >
          {passwordChecks.chars ? (
            <CheckCircleOutlineOutlinedIcon />
          ) : (
            <ErrorOutlineIcon />
          )}
          10 or more characters
        </li>
        <li
          className={
            passwordChecks.uppercase ? classes.pass : undefined
          }
        >
          {passwordChecks.uppercase ? (
            <CheckCircleOutlineOutlinedIcon />
          ) : (
            <ErrorOutlineIcon />
          )}
          1 or more uppercase letters
        </li>
        <li
          className={
            passwordChecks.lowercase ? classes.pass : undefined
          }
        >
          {passwordChecks.lowercase ? (
            <CheckCircleOutlineOutlinedIcon />
          ) : (
            <ErrorOutlineIcon />
          )}
          1 or more lowercase letters
        </li>
        <li
          className={
            passwordChecks.numbers ? classes.pass : undefined
          }
        >
          {passwordChecks.numbers ? (
            <CheckCircleOutlineOutlinedIcon />
          ) : (
            <ErrorOutlineIcon />
          )}
          1 or more numbers
        </li>
        {checkForMatchingPasswords && (
          <li
            className={
              passwordChecks.match ? classes.pass : undefined
            }
          >
            {passwordChecks.match ? (
              <CheckCircleOutlineOutlinedIcon />
            ) : (
              <ErrorOutlineIcon />
            )}
            Passwords Match
          </li>
        )}
      </ul>
    </Paper>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    paper: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
      paddingBottom: theme.spacing(1),
      backgroundColor: colors.whiteGold,
      '& ul': {
        listStyleType: 'none',
        margin: 0,
        padding: 0,
        '& li': {
          color: colors.dark,
          margin: 0,
          marginBottom: theme.spacing(1),
          fontSize: '16px',
          display: 'flex',
          alignItems: 'center',
          '& svg': {
            marginRight: '5px',
          },
        },
      },
    },
    pass: {
      color: 'green!important',
    },
  })),
)(PasswordRequirementsIndicator);
