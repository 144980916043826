import * as React from 'react';
/*
    This hook is used to provide the ability for a component to work both in a controlled and uncontrolled manner
    As this is extracted from material we are keeping the eslint disables as to not disrupt from the source
    the hook is copied because material does not expose this functionality
*/
/* eslint-disable react-hooks/rules-of-hooks, react-hooks/exhaustive-deps */
// source https://github.com/mui/material-ui/blob/512896973499adbbda057e7f3685d1b23cc02de9/packages/mui-utils/src/useControlled.js
export interface UseControlledArgs<T> {
  controlled?: T;
  default?: T;
  name?: string;
  state?: string;
}
export type UseControlledOutput<T> = [T | undefined, (v: T) => void];

const useControlled = <T>({
  controlled,
  default: defaultProp,
  name,
  state = 'value',
}: UseControlledArgs<T>): UseControlledOutput<T> => {
  // isControlled is ignored in the hook dependency lists as it should never change.
  const { current: isControlled } = React.useRef(
    controlled !== undefined,
  );
  const [valueState, setValue] = React.useState(defaultProp);
  const value = isControlled ? controlled : valueState;

  if (process.env.NODE_ENV !== 'production') {
    React.useEffect(() => {
      if (isControlled !== (controlled !== undefined)) {
        console.error(
          [
            `MUI: A component is changing the ${
              isControlled ? '' : 'un'
            }controlled ${state} state of ${name} to be ${
              isControlled ? 'un' : ''
            }controlled.`,
            'Elements should not switch from uncontrolled to controlled (or vice versa).',
            `Decide between using a controlled or uncontrolled ${name} ` +
              'element for the lifetime of the component.',
            'The nature of the state is determined during the first render. It is considered controlled if the value is not `undefined`.',
            'More info: https://fb.me/react-controlled-components',
          ].join('\n'),
        );
      }
    }, [state, name, controlled]);

    const { current: defaultValue } = React.useRef(defaultProp);

    React.useEffect(() => {
      if (!isControlled && defaultValue !== defaultProp) {
        console.error(
          [
            `MUI: A component is changing the default ${state} state of an uncontrolled ${name} after being initialized. ` +
              `To suppress this warning opt to use a controlled ${name}.`,
          ].join('\n'),
        );
      }
    }, [JSON.stringify(defaultProp)]);
  }

  const setValueIfUncontrolled = React.useCallback((newValue) => {
    if (!isControlled) {
      setValue(newValue);
    }
  }, []);

  return [value, setValueIfUncontrolled];
};

export default useControlled;
