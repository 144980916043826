import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from './progressIndicator.styles';

const ProgressIndicator: React.FC = () => {
  const classes = styles();
  return (
    <div
      className={classes.indicatorContainer}
      data-testid='progress-indicator'
    >
      <CircularProgress />
    </div>
  );
};

export default ProgressIndicator;
