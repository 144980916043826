/*

    Details Pages Functions

    A collection of functions to assist with rendering strings for display on details pages.
    The functions here have been fully broken out, it's likely there is overlap between them so we should
    look into removing the duplication in the future.

*/

import {
  STRING_UNITS_MEASUREMENT,
  STRING_UNITS_VOLUME,
  STRING_UNITS_WEIGHT,
} from './UIStrings';
import moment from 'moment';

const STRING_CONTAINER_STATUS_LOADED = 'Loaded';
const STRING_CONTAINER_STATUS_DISPATCHED = 'Dispatched';

//  :outbound_load_details:
//  /loads/:id
export const renderOutboundLoadLocationString = (
  station: string | undefined,
  zone: string | undefined,
  loadPoint: string | undefined,
) => {
  if (!station || !zone || !loadPoint) {
    return 'N/A';
  }
  //  "Load Location"
  return `${station}-${zone}-${loadPoint}`;
};

export const renderOutboundLoadTargetStagingIdString = (
  station: string | undefined,
  area: string | undefined,
) => {
  if (!station || !area) {
    return 'N/A';
  }
  //  "Target Staging ID"
  return `${station}-${area}`;
};

export const renderOutboundLoadTargetOutboundIdString = (
  station: string | undefined,
  area: string | undefined,
) => {
  if (!station || !area) {
    return 'N/A';
  }
  //   "Target Load ID"
  return `${station}-${area}`;
};

//  :container_details:
//  /containers/:id
export const renderContainerLocationString = (
  station: string | undefined,
  area: string | undefined,
  zone: string | undefined,
  containerStatus?: string,
  loadPoint?: string,
) => {
  //  "Container Location"
  //
  //  If the container's status is loaded, the location field is N/A.
  if (
    STRING_CONTAINER_STATUS_DISPATCHED === containerStatus ||
    STRING_CONTAINER_STATUS_LOADED === containerStatus
  ) {
    return containerStatus;
  }
  let location;
  location = station || '';
  location += station && (area || zone || loadPoint) ? '-' : '';
  location += area || '';
  location += area && (zone || loadPoint) ? '-' : '';
  location += zone || '';
  location += zone && loadPoint ? '-' : '';
  location += loadPoint || '';

  if (location === '') location = 'N/A';

  return location;
};

export const renderContainerTargetStagingIdString = (
  station: string | undefined,
  area: string | undefined,
) => {
  if (!station || !area) {
    return 'N/A';
  }
  //  "Target Staging ID"
  return `${station}-${area}`;
};

export const renderContainerTargetOutboundIdString = (
  station: string | undefined,
  area: string | undefined,
) => {
  if (!station || !area) {
    return 'N/A';
  }
  //   "Target Load ID"
  return `${station}-${area}`;
};

//  :package_details:
//  /packages/:id
export const renderPackageContainerLocationString = (
  station: string | undefined,
  area: string | undefined,
  zone: string | undefined,
  loadPoint: string | undefined,
) => {
  if (!station || !area || !zone || !loadPoint) {
    return 'N/A';
  }
  //  "Container Location"
  return `${station}-${area}-${zone}-${loadPoint}`;
};

export const renderPackageTarget1stSortationIdString = (
  area: string | undefined,
  loadPoint: string | undefined,
) => {
  if (area || loadPoint) {
    return [area, loadPoint]
      .filter((item) => item !== undefined && item !== null)
      .join('-');
  } else return 'N/A';
};

export const renderPackageTarget2ndSortationIdString = (
  area: string | undefined,
  zone: string | undefined,
  loadPoint: string | undefined,
) => {
  if (!area || !zone || !loadPoint) {
    return 'N/A';
  }
  //  "Target 2nd Sortation ID"
  return `${area}-${zone}-${loadPoint}`;
};

export const renderPackageTargetStagingIdString = (
  area: string | undefined,
) => {
  if (!area) {
    return 'N/A';
  }
  //  "Target Staging ID"
  return `${area}`;
};

export const renderPackageSizeString = (
  height: number | undefined | null,
  width: number | undefined | null,
  length: number | undefined | null,
) => {
  if (!height || !width || !length) {
    return 'N/A';
  }
  //  "Size"
  return (
    `${width} ${STRING_UNITS_MEASUREMENT} x ` +
    `${length} ${STRING_UNITS_MEASUREMENT} x ` +
    `${height} ${STRING_UNITS_MEASUREMENT}`
  );
};

//  :generic:
//  Generic helper functions.
export const humanReadableBoolean = (
  status: boolean | object | undefined | number | null,
) => {
  if (status === true || status) {
    return 'Yes';
  }
  return 'No';
};

export const humanReadableNull = (value: any) => {
  if (value === null || value === undefined) {
    return 'N/A';
  }
  return value;
};

export const renderWeightString = (
  weight: number | undefined | null,
) => {
  if (!weight && weight !== 0) {
    return 'N/A';
  }
  //
  //  "Weight"
  const weightDecimals = weight
    .toString()
    .match(/^-?\d+(?:\.\d{0,2})?/);
  if (weightDecimals) {
    return `${weightDecimals[0]} ${STRING_UNITS_WEIGHT}`;
  } else {
    return `${weight} ${STRING_UNITS_WEIGHT}`;
  }
};

export const renderVolumeString = (
  volume: number | undefined | null,
) => {
  if (!volume && volume !== 0) {
    return 'N/A';
  }
  //
  //  "Volume"
  const volumeDecimals = volume
    .toString()
    .match(/^-?\d+(?:\.\d{0,2})?/);
  if (volumeDecimals) {
    return `${volumeDecimals[0]} ${STRING_UNITS_VOLUME}`;
  } else {
    return `${volume} ${STRING_UNITS_VOLUME}`;
  }
};

export const renderTimestampString = (
  timestamp: number | undefined | null | Date | string,
) => {
  if (!timestamp) {
    return 'N/A';
  }
  let d = new Date(timestamp);
  return d.toLocaleString();
};

export const renderTimeString = (
  timestamp: number | undefined | null | Date | string,
) => {
  if (!timestamp) {
    return 'N/A';
  }
  let d = new Date(timestamp);
  return moment(d).format('hh:mm A');
};

export const renderTimeWithZoneString = (
  timestamp: number | undefined | null | Date | string,
) => {
  if (!timestamp) {
    return 'N/A';
  }
  const zone = new Date()
    .toLocaleTimeString('en-uk', { timeZoneName: 'long' })
    .split(' ')
    .slice(1)
    .map((word) => word.charAt(0))
    .join('');
  let d = new Date(timestamp);
  return moment(d).format('hh:mm A') + ' ' + zone;
};

export const renderOverdueString = (
  timestamp: number | Date | string | undefined,
  status: string | undefined,
) => {
  if (status === 'DISPATCHED') {
    return 'N/A'; //the load has already been displatched
  } else if (timestamp) {
    const durationMs =
      new Date().getTime() - new Date(timestamp).getTime();
    if (timestamp && durationMs > 0) {
      return renderDuration(Math.round(durationMs / 1000));
    } else {
      return 'NO';
    }
  }
};

export const renderDuration = (seconds: number) => {
  if (seconds < 60) {
    return Math.round(seconds) + 's';
  } else if (seconds >= 60 && seconds < 3600) {
    return Math.round(seconds / 60) + 'm';
  } else if (seconds >= 3600 && seconds < 86400) {
    const hours = Math.floor(seconds / 3600);
    return (
      hours + 'h ' + Math.floor((seconds - hours * 3600) / 60) + 'm'
    );
  } else {
    return '>1d';
  }
};

//Find share of one number in another
export const findShare = (
  smaller?: number,
  larger?: number,
): number => {
  if (smaller && larger) {
    return (smaller / larger) * 10;
  } else return 0;
};

//
//Function to format date into format required by datetime-local html input

export const formatDatetimeInput = (t: Date) => {
  const p = (n: number) => {
    return n.toString().padStart(2, '0');
  }; //number to 2 digit, 0 padded string
  return `${t.getFullYear()}-${p(t.getMonth() + 1)}-${p(
    t.getDate(),
  )}T${p(t.getHours())}:${p(t.getMinutes())}`;
};

// Join first n items of an array

export const formatFirstNItemsAndMore = (
  array: string[],
  limit: number = 3,
): string => {
  if (array.length <= limit) return array.join(', ');
  else
    return (
      array.slice(0, limit).join(', ') +
      ' and ' +
      (array.length - limit) +
      ' more.'
    );
};
