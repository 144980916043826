import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AvailableFilter,
  SelectedFilters,
} from '../../components/filtersDrawer/FiltersDrawer';
import { RootState } from '../store';

interface FilterOptions {
  [key: string]: AvailableFilter['options'];
}

const initialState: {
  filterOptions: FilterOptions;
  appliedFilters: SelectedFilters;
} = {
  filterOptions: {},
  appliedFilters: {},
};

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    updateFiltersState: (
      state,
      action: PayloadAction<SelectedFilters>,
    ) => {
      state.appliedFilters = action.payload;
    },
    removeFilter: (
      state,
      action: PayloadAction<{ key: string; id: number }>,
    ) => {
      state.appliedFilters[action.payload.key].values =
        state.appliedFilters[action.payload.key].values.filter(
          (item) => item.id !== action.payload.id,
        );
    },
    updateFiltersOptions: (
      state,
      action: PayloadAction<FilterOptions>,
    ) => {
      state.filterOptions = action.payload;
    },
  },
});

export const {
  updateFiltersState,
  removeFilter,
  updateFiltersOptions,
} = filtersSlice.actions;

export const selectGlobalFilters = (state: RootState) =>
  state.filters;

export default filtersSlice.reducer;
