import React, { useEffect, useState, useCallback } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { CSButton, CSTextField } from '../../primitives';
import { withStyles } from '@material-ui/core/styles';
import ProgressIndicator from '../../progressIndicator/ProgressIndicator';
import { DialogTitle, createStyles, Theme } from '@material-ui/core';
import detailsPageStyles from '../../commonStyles/detailsPage.style';
import ErrorHandler from '../../../utils/ErrorHandler';
import { AxiosError } from 'axios';
import { Customer } from 'cloudsort-client';
import CustomersService from '../../../services/Customers.service';
import selectStyles from '../../select/select.styles';
import debounce from 'lodash/debounce';

import validateEmail from '../../../utils/validateEmail';

import { TypeAheadItem } from '../../../interfaces/components';
import OrganizationsService from '../../../services/Organizations.service';
import StationsService from '../../../services/Stations.service';
import ConfirmationDialog from '../../confirmationDialog/ConfirmationDialog';
import CSDialogTitleWithIcon from '../../primitives/csDialogTitleWithIcon/CSDialogTitleWithIcon';
import { DialogIcons } from '../../primitives/csDialogTitleWithIcon/CSDialogTitleWithIconTypes';
import { CSMonoGridContainer } from '../../primitives/csMonoGridContainer';
import CSDialogAlert from '../../primitives/csDialogAlert/CSDialogAlert';
import CSAsyncSelect from '../../primitives/csAsyncSelect/CSAsyncSelect';
import { noOptionsMessage } from '../../asyncSelect/utils';

interface Props {
  classes: { [key: string]: string };
  isOpen: boolean;
  onCancel: () => void;
  onAddUser: () => void;
}

type DialogStep = 'EnterEmail' | 'EnterNameAndAssignEntities';

const AddCustomerDialog: React.FC<Props> = ({
  classes,
  isOpen,
  onCancel,
  onAddUser,
}) => {
  const [currentStep, setCurrentStep] =
    useState<DialogStep>('EnterEmail');
  const [open, setOpen] = useState(false);
  const [error, setError] = useState<string>();
  const [showProgress, setShowProgress] = useState<boolean>(false);

  const [selectedEntitiesOptions, setSelectedEntitiesOptions] =
    useState<TypeAheadItem[]>([]);
  const [showSuccessConfirmation, setShowSuccessConfirmation] =
    useState<boolean>(false);

  const [customerEmail, setCustomerEmail] = useState<string>('');
  const [existingCustomer, setExistingCustomer] =
    useState<Customer>();

  const [customerName, setCustomerName] = useState<string>('');

  const hasValidEmailFormat =
    validateEmail(customerEmail) && customerEmail;
  const hasEmailError = Boolean(
    customerEmail && !hasValidEmailFormat,
  );

  const handleError = async (err: AxiosError) => {
    setError(await ErrorHandler.getLabel(err));
  };

  const resetState = () => {
    setOpen(false);
    setError(undefined);
    setCurrentStep('EnterEmail');
    setCustomerEmail('');

    setCustomerName('');
    setSelectedEntitiesOptions([]);
    setShowSuccessConfirmation(false);

    setExistingCustomer(undefined);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadEntityOptions = useCallback(
    debounce((inputValue: string, callback: any) => {
      Promise.all([
        OrganizationsService.getAllSearch(inputValue),
        StationsService.getAllStationsSearch(inputValue),
      ]).then(([orgRes, stationsRes]) => {
        callback([
          ...orgRes.data.results.map((org) => {
            return {
              value: org.id,
              label: 'Organization ' + org.name,
            };
          }),
          ...stationsRes.data.results.map((station) => {
            return {
              value: station.id,
              label: 'Station ' + station.name,
            };
          }),
        ]);
      });
    }, 500),
    [],
  );

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const checkIfEmailIsRegistered = debounce(async (email: string) => {
    try {
      setShowProgress(true);
      const res = await CustomersService.checkEmail(email);

      if (res.data.id) {
        const customer = await CustomersService.getById(res.data.id);

        customer && setExistingCustomer(customer.data);
      }
    } catch (e) {
      setExistingCustomer(undefined);
    } finally {
      setShowProgress(false);
    }
  }, 500);

  const isOptionStation = (option: TypeAheadItem) =>
    option.label?.startsWith('Station');
  const isOptionOrganization = (option: TypeAheadItem) =>
    option.label?.startsWith('Organization');

  const createCustomer = async () => {
    try {
      setShowProgress(true);
      const res = await CustomersService.createCustomer(
        customerEmail,
        customerName ? customerName : undefined,
      );
      const customerId = res.data.id as number;

      selectedEntitiesOptions.forEach(async (option) => {
        await CustomersService.assignToOrganizationOrStation(
          customerId,
          isOptionOrganization(option)
            ? Number(option.value)
            : undefined,
          isOptionStation(option) ? Number(option.value) : undefined,
        );
      });

      setShowSuccessConfirmation(true);
      onAddUser();
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  const updateCustomer = async () => {
    if (!existingCustomer?.id) return;
    try {
      setShowProgress(true);

      selectedEntitiesOptions.forEach(async (option) => {
        if (
          isOptionOrganization(option) &&
          existingCustomer.assigned_organizations
            ?.map((org) => org.id)
            .includes(Number(option.value))
        )
          return;

        if (
          isOptionStation(option) &&
          existingCustomer.assigned_stations
            ?.map((station) => station.id)
            .includes(Number(option.value))
        )
          return;

        await CustomersService.assignToOrganizationOrStation(
          existingCustomer.id!,
          isOptionOrganization(option)
            ? Number(option.value)
            : undefined,
          isOptionStation(option) ? Number(option.value) : undefined,
        );
      });

      setShowSuccessConfirmation(true);
      onAddUser();
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  const handleAddCustomer = () => {
    if (existingCustomer) {
      updateCustomer();
    } else {
      createCustomer();
    }
  };

  return (
    <>
      {showProgress && <ProgressIndicator />}

      {showSuccessConfirmation && (
        <ConfirmationDialog
          title={
            existingCustomer
              ? 'The customer has been updated sucessfully'
              : 'New customer added sucessfully'
          }
          msg={
            existingCustomer
              ? 'Access to the selected entities was granted sucessfully'
              : `We have sent an email to ${customerEmail} to complete the registration process.`
          }
          primaryActionLabel='OK'
          onPrimaryAction={() => {
            resetState();
          }}
          cancelLabel='Cancel'
          hideCancelButton
          onCancel={() => {
            resetState();
          }}
          isOpen={true}
        />
      )}

      <Dialog open={open}>
        <DialogTitle>
          <CSDialogTitleWithIcon
            icon={DialogIcons.ADD}
            title='Please add the new customer details'
            subtitle='The user will receive a link in the email to complete the registration.'
          />
        </DialogTitle>

        <DialogContent>
          <CSMonoGridContainer>
            {error ? (
              <CSDialogAlert alertMessage={error} />
            ) : undefined}
            {currentStep === 'EnterEmail' && (
              <CSTextField
                containerSize='fullHorizontal'
                type='email'
                label='Email'
                placeholder='email@example.com'
                value={customerEmail}
                onChange={(event) => {
                  const newEmail = event.target.value.trim();
                  setCustomerEmail(newEmail);
                  if (validateEmail(newEmail)) {
                    checkIfEmailIsRegistered(newEmail);
                  }
                }}
                error={hasEmailError}
              />
            )}
            {currentStep === 'EnterNameAndAssignEntities' &&
              !existingCustomer && (
                <CSTextField
                  containerSize='fullHorizontal'
                  label='Name'
                  placeholder='Customer Name'
                  value={customerName}
                  onChange={(event) =>
                    setCustomerName(event.target.value)
                  }
                />
              )}
            {currentStep === 'EnterNameAndAssignEntities' && (
              <CSAsyncSelect<TypeAheadItem, true>
                isMulti
                containerSize='fullHorizontal'
                loadOptions={loadEntityOptions}
                menuPortalTarget={document.body}
                onChange={(newValues: readonly TypeAheadItem[]) => {
                  setSelectedEntitiesOptions([...newValues]);
                }}
                label='Assigned Organizations and Stations'
                noOptionsMessage={noOptionsMessage}
              />
            )}
          </CSMonoGridContainer>
        </DialogContent>

        <DialogActions className={classes.dialogActions}>
          {currentStep === 'EnterEmail' && (
            <>
              <CSButton
                onClick={(e) => {
                  resetState();
                  onCancel();
                }}
                variant='outlined'
                color='secondary'
              >
                Cancel
              </CSButton>
              <CSButton
                onClick={(e) => {
                  setCurrentStep('EnterNameAndAssignEntities');
                }}
                disabled={hasEmailError || !customerEmail}
                variant='contained'
                color='secondary'
              >
                Next
              </CSButton>
            </>
          )}
          {currentStep === 'EnterNameAndAssignEntities' && (
            <>
              <CSButton
                variant='outlined'
                color='secondary'
                onClick={(e) => {
                  setCurrentStep('EnterEmail');
                }}
              >
                Back
              </CSButton>
              <div style={{ flexGrow: 1 }} />
              <CSButton
                onClick={(e) => {
                  resetState();
                  onCancel();
                }}
                variant='outlined'
                color='secondary'
              >
                Cancel
              </CSButton>
              <CSButton
                onClick={(e) => {
                  handleAddCustomer();
                }}
                variant='contained'
                color='secondary'
              >
                Add User
              </CSButton>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...selectStyles,
    dialogActions: {
      padding: 24,
    },
  })),
)(AddCustomerDialog);
