import { PackagesApi } from 'cloudsort-client';
import axios from '../utils/axios';
import dateUtils from './utils/date';
import EphemeralStateService from './EphemeralState.service';

export type HeldPackageStatusValues =
  | 'UNRESOLVED'
  | 'RESOLVED'
  | 'RESOLVED_DISPATCHED';
export type HeldPackageScopeValues = 'SYSTEM' | 'CUSTOMER';
export type HeldPackageSourceValues = 'INTERNAL' | 'EXTERNAL';
export type HeldPackagesSortByValues =
  | 'created_on'
  | '-created_on'
  | 'scope'
  | '-scope'
  | 'owner'
  | '-owner'
  | 'carrier'
  | '-carrier'
  | 'station'
  | '-station';
class HeldPackages {
  private api: PackagesApi;

  constructor() {
    this.api = new PackagesApi(undefined, undefined, axios as any);
  }

  public async getAll(params: {
    page?: number;
    pageSize?: number;
    fromDate?: string;
    toDate?: string;
    trackingNumber?: string;
    sortBy?: HeldPackagesSortByValues;
    status?: HeldPackageStatusValues;
    scope?: HeldPackageScopeValues;
    source?: HeldPackageSourceValues;
    inboundLoad?: number;
    owner?: number;
    carrier?: number;
    reason?: string;
  }) {
    return this.api.packagesHoldReasonsList({
      ...params,
      station: EphemeralStateService.getMyStationId(),
      fromDate:
        params.fromDate === '' ? undefined : dateUtils.fromDate(),
      toDate: params.toDate === '' ? undefined : dateUtils.toDate(),
    });
  }

  public async getStats(params?: {
    status?: HeldPackageStatusValues;
    scope?: HeldPackageScopeValues;
    source?: HeldPackageSourceValues;
    inboundLoad?: number;
    owner?: number;
    carrier?: number;
    reason?: string;
    fromDate?: string;
    toDate?: string;
  }) {
    return this.api.packagesHoldReasonsStats({
      ...params,
      station: EphemeralStateService.getMyStationId(),
      fromDate:
        params?.fromDate === '' ? undefined : dateUtils.fromDate(),
      toDate: params?.toDate === '' ? undefined : dateUtils.toDate(),
    });
  }

  public async getCSVExport(params: {
    sortBy?: HeldPackagesSortByValues;
    status?: HeldPackageStatusValues;
    scope?: HeldPackageScopeValues;
    source?: HeldPackageSourceValues;
    inboundLoad?: number;
    owner?: number;
    carrier?: number;
    station?: number;
    reason?: string;
    trackingNumber?: string;
    fromDate: string;
    toDate: string;
  }) {
    return this.api.packagesHoldReasonsCsv({
      ...params,
      station: !params.inboundLoad
        ? EphemeralStateService.getMyStationId()
        : undefined,
    });
  }
}

export default new HeldPackages();
