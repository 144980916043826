import {
  FormControl,
  FormHelperText,
  InputBase,
  InputLabel,
  Select,
  useTheme,
  withStyles,
} from '@material-ui/core';
import * as React from 'react';
import {
  COMPONENT_NAME,
  CSSelectProps,
  MaterialVariants,
} from './CSSelectTypes';
import styles, {
  FIELD_FILLED_CLASS,
  getFormControlStyles,
  getFormHelperTextStyles,
  getInputBaseStyles,
  getInputLabelStyles,
} from './CSSelect.styles';
import { getEndAdornment } from './CSSelectUtils';
import useStableId from '../../../utils/useStableId';
import useControlled from '../../../utils/useControlled';
import { toMaterialTextFieldColor } from '../csCommon/inputUtils';

const CSSelect: React.FC<CSSelectProps> = React.forwardRef(
  (
    {
      id = '',
      className = '',
      label,
      name,
      value: userValue,
      defaultValue = '',
      onChange,
      onKeyDown,
      onClear,
      disabled,
      error,
      helperText,
      startAdornment,
      InputLabelProps,
      containerSize = 'standard',
      color = 'primary',
      variant = 'standard-outlined',
      'data-testid': dataTestId,
      customContent,
      hideClear = true,
      ...others
    },
    ref,
  ) => {
    const theme = useTheme();
    const [value, setValueIfUncontrolled] = useControlled<string>({
      controlled: userValue,
      default: defaultValue,
      name: COMPONENT_NAME,
    });

    const inputId = useStableId(id, COMPONENT_NAME);

    const ThemedFormControl = React.useMemo(
      () =>
        withStyles(getFormControlStyles(containerSize), {
          name: 'CSFormControl',
        })(FormControl),
      [containerSize],
    );

    const ThemedSelect = React.useMemo(
      () =>
        withStyles(styles(theme, variant, label, containerSize), {
          name: COMPONENT_NAME,
        })(Select),
      [theme, variant, label, containerSize],
    );

    const ThemedInputLabel = React.useMemo(
      () =>
        withStyles(
          getInputLabelStyles(
            theme,
            color,
            variant,
            startAdornment !== undefined,
          ),
          {
            name: 'CSInputLabel',
          },
        )(InputLabel),
      [theme, color, variant, startAdornment],
    );

    const ThemedFormHelperText = React.useMemo(
      () =>
        withStyles(getFormHelperTextStyles(), {
          name: 'CSFormHelperText',
        })(FormHelperText),
      [],
    );

    const ThemedBaseInput = React.useMemo(
      () =>
        withStyles(getInputBaseStyles(variant), {
          name: 'CSBaseInput',
        })(InputBase),
      [variant],
    );

    const isMaterialVariant = [
      'standard',
      'filled',
      'outlined',
    ].includes(variant);

    const handleOnClear = () => {
      setValueIfUncontrolled('');
      onClear?.();
    };

    return (
      <>
        <ThemedFormControl
          className={className}
          data-testid={dataTestId}
          disabled={disabled}
          error={error}
          variant={
            isMaterialVariant
              ? (variant as MaterialVariants)
              : 'outlined'
          }
        >
          {label && (
            <ThemedInputLabel
              variant={
                isMaterialVariant
                  ? (variant as MaterialVariants)
                  : 'filled'
              }
              htmlFor={inputId}
              {...InputLabelProps}
            >
              {label}
            </ThemedInputLabel>
          )}

          <ThemedSelect
            ref={ref}
            className={!!value ? FIELD_FILLED_CLASS : ''}
            id={inputId}
            value={value}
            input={
              isMaterialVariant ? undefined : <ThemedBaseInput />
            }
            onChange={(event, child) => {
              onChange?.(event, child);
              setValueIfUncontrolled(String(event.target.value));
            }}
            color={toMaterialTextFieldColor(color)}
            startAdornment={startAdornment}
            endAdornment={getEndAdornment(
              value,
              handleOnClear,
              (userValue && !onClear) || hideClear,
            )}
            {...others}
          />
          {helperText && (
            <ThemedFormHelperText id={`${inputId}Helper-Text`}>
              {helperText}
            </ThemedFormHelperText>
          )}
          {customContent}
        </ThemedFormControl>
      </>
    );
  },
);

export default CSSelect;
