import MuiTypography from '@material-ui/core/Typography';
import * as React from 'react';
import styles, { getClasses } from './Typography.styles';
import { useTheme, withStyles } from '@material-ui/core';
import { ITypographyProps } from './TypographyTypes';
import {
  toMaterialTypographyColor,
  toMaterialTypographyVariant,
} from './TypographyUtils';
import clsx from 'clsx';

const Typography: React.FC<ITypographyProps> = ({
  className,
  variant,
  underlined = false,
  color = 'textPrimary',
  ...others
}) => {
  const theme = useTheme();
  const classes = getClasses();
  const NewTypography = React.useMemo(
    () =>
      withStyles(styles(theme, variant, color), {
        name: 'CSTypography',
      })(MuiTypography),
    [color, theme, variant],
  );
  return (
    <NewTypography
      className={clsx(className, {
        [classes?.underlined]: underlined,
      })}
      color={toMaterialTypographyColor(color)}
      variant={toMaterialTypographyVariant(variant)}
      {...others}
    />
  );
};

export default Typography;
