import React, { Ref } from 'react';

const CloudDownloadIcon = React.forwardRef(
  (props: any, ref: Ref<HTMLOrSVGElement>) => {
    return (
      <svg
        width='26'
        viewBox='0 0 36 36'
        fill='currentColor'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
        ref={ref}
      >
        <path
          fill='currentColor'
          d='M29.025 14.2339C28.005 9.18827 23.46 5.40039 18 5.40039C13.665 5.40039 9.9 7.79889 8.025 11.3089C3.51 11.7769 0 15.5063 0 20.0254C0 24.8663 4.035 28.8004 9 28.8004H28.5C32.64 28.8004 36 25.5244 36 21.4879C36 17.6269 32.925 14.4971 29.025 14.2339ZM28.4999 25.8754H8.99987C5.68487 25.8754 2.99987 23.2575 2.99987 20.0254C2.99987 17.0273 5.29487 14.5264 8.33987 14.2193L9.94487 14.0584L10.6949 12.669C12.1199 9.99264 14.9099 8.32539 17.9999 8.32539C21.9299 8.32539 25.3199 11.0456 26.0849 14.8043L26.5349 16.998L28.8299 17.1589C31.1699 17.3051 32.9999 19.221 32.9999 21.4879C32.9999 23.901 30.9749 25.8754 28.4999 25.8754ZM15.8249 14.1754H20.1749V18.5629H23.9999L17.9999 24.4129L11.9999 18.5629H15.8249V14.1754Z'
        />
      </svg>
    );
  },
);
CloudDownloadIcon.displayName = 'CloudDownloadIcon';
export default CloudDownloadIcon;
