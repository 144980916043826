import {
  withStyles,
  createStyles,
  Theme,
  Box,
} from '@material-ui/core';

import browserHistory from '../../../../utils/browserHistory';
import { TrackableContainersRoutes } from '../../../../interfaces/routes';
import { Typography } from '../../../primitives';
import {
  renderTemperatureLabel,
  renderBatteryPercentageLabel,
  renderLocalTimestamp,
  renderLocalTimezone,
} from '../../utils';
import { TrackableContainer } from 'cloudsort-client';

type Props = {
  classes: { [key: string]: string };
  container: TrackableContainer;
};

const MapTooltip = ({ classes, container }: Props) => {
  const handleGoToDetails = () => {
    browserHistory.push(
      `${TrackableContainersRoutes.LIST}/${container.identifier}/${container.id}`,
    );
  };

  return (
    <Box className={classes.tooltip}>
      <Typography variant='body1'>{container.identifier}</Typography>
      {/* <Typography
        variant='body2'
        color={{ color: 'grey', variant: 'A400' }}
        className={classes.marginTop10}
      >
        Current Location
      </Typography>
      <Typography variant='body2' className={classes.marginTop10}>
        NOT IMPLEMENTED
      </Typography>
      <Typography
        variant='body2'
        color={{ color: 'grey', variant: 'A400' }}
        className={classes.marginTop10}
      >
        Total Miles
      </Typography>
      <Typography variant='body2' className={classes.marginTop10}>
        NOT IMPLEMENTED
      </Typography>
      <Typography
        variant='body2'
        color={{ color: 'grey', variant: 'A400' }}
        className={classes.marginTop10}
      >
        Created On
      </Typography>
      <Typography variant='body2' className={classes.marginTop10}>
        NOT IMPLEMENTED
      </Typography>
      <Typography
        variant='body2'
        color={{ color: 'grey', variant: 'A400' }}
        className={classes.marginTop10}
      >
        Age
      </Typography>
      <Typography variant='body2' className={classes.marginTop10}>
        NOT IMPLEMENTED
      </Typography> */}
      <Typography
        variant='body2'
        color={{ color: 'grey', variant: 'A400' }}
        className={classes.marginTop10}
      >
        Last Event
      </Typography>
      <Typography variant='body2' className={classes.marginTop10}>
        {container.tracker_device.last_location_ts
          ? `${renderLocalTimestamp(
              container.tracker_device.last_location_ts,
            )} ${renderLocalTimezone()}`
          : 'N/A'}
      </Typography>
      <Typography
        variant='body2'
        color={{ color: 'grey', variant: 'A400' }}
        className={classes.marginTop10}
      >
        Battery Life
      </Typography>
      <Typography variant='body2' className={classes.marginTop10}>
        {renderBatteryPercentageLabel(container.tracker_device)}
      </Typography>
      <Typography
        variant='body2'
        color={{ color: 'grey', variant: 'A400' }}
        className={classes.marginTop10}
      >
        Temperature
      </Typography>
      <Typography variant='body2' className={classes.marginTop10}>
        {renderTemperatureLabel(container.tracker_device)}
      </Typography>
      <button
        className={classes.goToDetails}
        onClick={handleGoToDetails}
      >
        Details
      </button>
    </Box>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    tooltip: {
      padding: 10,
      wordBreak: 'break-all',
    },
    goToDetails: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      width: '100%',
      padding: 10,
      border: 'none',
      marginTop: 20,
      cursor: 'pointer',
    },
    marginTop10: {
      marginTop: 10,
    },
  })),
)(MapTooltip);
