import React, { useState, useEffect } from 'react';

import {
  Box,
  Grid,
  IconButton,
  MenuItem,
  MenuList,
  Popover,
  withStyles,
} from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import { Organization, Station } from 'cloudsort-client';
import { CSTextField, Typography } from '../primitives';
import styles from './stationSelector.styles';

//Icons
import SearchIcon from '@material-ui/icons/Search';
import StoreMallDirectoryOutlinedIcon from '@material-ui/icons/StoreMallDirectoryOutlined';
import UnfoldMoreOutlinedIcon from '@material-ui/icons/UnfoldMoreOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import capitalize from 'lodash/capitalize';
import { SELECTOR_ENTITY_TYPE } from './types';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import {
  setSelectedEntity,
  navigationConfig,
  setShouldUpdateUrl,
} from '../../redux/slices/navigationSlice';
import EphemeralStateService from '../../services/EphemeralState.service';
import PermissionsService from '../../services/Permissions.service';

type Props = {
  classes: { [key: string]: string };
  stations?: Station[];
  orgs?: Organization[];
  isConfigurationNav?: boolean;
};

const EntitySelector = ({
  classes,
  stations,
  orgs,
  isConfigurationNav,
}: Props) => {
  const [filterString, setFilterString] = useState<string>('');
  const [filteredGroupedStations, setFilteredGroupedStations] =
    useState<Station[][] | undefined>();
  const [filteredOrgs, setFilteredOrgs] = useState<
    Organization[] | undefined
  >();

  const { selectedEntityId, selectedEntityType } =
    useAppSelector(navigationConfig);
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(
    null,
  );

  const accordionClasses = {
    root: classes.accordionRoot,
    expanded: classes.accordionExpanded,
  };

  const accordionSummaryClasses = {
    root: classes.accordionSummaryRoot,
    content: classes.accordionSummaryContent,
    expandIcon: classes.accordionSummaryExpandIcon,
    disabled: classes.accordionSummaryDisabled,
  };

  const accordionDetailsClasses = {
    root: classes.accordionDetailsRoot,
  };

  const openPopover = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const closePopover = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const stationByOrg = [
      ...new Set(
        stations?.map((station) => station.organization_name || ''),
      ),
    ]
      .sort((a, b) => a.localeCompare(b))
      .map((orgName) =>
        stations?.filter(
          (station) =>
            station.organization_name === orgName &&
            (station.name
              ?.toLowerCase()
              .includes(filterString.toLowerCase()) ||
              station.organization_name
                ?.toLowerCase()
                .includes(filterString.toLowerCase())),
        ),
      )
      .filter((orgArray) => orgArray?.length);

    setFilteredGroupedStations(stationByOrg as Station[][]);

    const filteredOrganizations = orgs?.filter(
      (org) =>
        org.name.toLowerCase().includes(filterString.toLowerCase()) ||
        stations?.some(
          (station) =>
            station.organization === org.id &&
            station.name
              ?.toLowerCase()
              .includes(filterString.toLowerCase()),
        ),
    );

    setFilteredOrgs(filteredOrganizations);
  }, [stations, orgs, filterString]);

  const onAccordionSummaryClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    entityType: SELECTOR_ENTITY_TYPE,
    entityId: number,
  ) => {
    if (
      !['svg', 'path', 'BUTTON'].includes(
        (event.target as HTMLDivElement).nodeName,
      ) &&
      isConfigurationNav &&
      PermissionsService.hasPermission('ORGANIZATION_WRITE')
    ) {
      dispatch(
        setSelectedEntity({
          id: entityId,
          type: entityType,
        }),
      );
      dispatch(setShouldUpdateUrl(true));
      closePopover();
    }
  };

  const renderEntityName = () => {
    if (!selectedEntityId) {
      return '';
    }

    switch (selectedEntityType) {
      case SELECTOR_ENTITY_TYPE.STATION:
        const station = stations?.find(
          (station) => station.id === selectedEntityId,
        );
        return `${station?.name || ''} ${
          station?.city ? ' - ' + station?.city : ''
        }`;

      case SELECTOR_ENTITY_TYPE.ORGANIZATION:
        const stationByOrg = stations?.find(
          (station) => station.organization === selectedEntityId,
        );
        if (stationByOrg) {
          return `${stationByOrg?.organization_name}`;
        } else {
          const org = orgs?.find(
            (org) => org.id === selectedEntityId,
          );
          if (org) {
            return org.name;
          }
        }
        break;
      case SELECTOR_ENTITY_TYPE.CLOUDSORT:
        return 'Global Configuration';
      default:
        return '';
    }
  };

  // Org data will not always be available based on permissions so we have two ways to render list of stations in the picker
  const renderOrganizationsAndStationsFromStationsData = () =>
    filteredGroupedStations?.map((stationGroup) => (
      <Accordion
        key={
          stationGroup[0].organization_name +
          '-' +
          stationGroup[0].organization
        }
        classes={accordionClasses}
        defaultExpanded
      >
        <AccordionSummary
          classes={accordionSummaryClasses}
          expandIcon={
            <ExpandMoreIcon className={classes.expandMoreIcon} />
          }
          onClick={(event) =>
            onAccordionSummaryClick(
              event,
              SELECTOR_ENTITY_TYPE.ORGANIZATION,
              stationGroup[0].organization || 0,
            )
          }
        >
          {stationGroup[0].organization_name}
        </AccordionSummary>
        <AccordionDetails classes={accordionDetailsClasses}>
          <MenuList className={classes.menuList}>
            {stationGroup.map((station) => (
              <MenuItem
                key={station.name + '-' + station.id}
                className={classes.menuItem}
                onClick={() => {
                  dispatch(
                    setSelectedEntity({
                      id: station.id || 0,
                      type: SELECTOR_ENTITY_TYPE.STATION,
                    }),
                  );
                  EphemeralStateService.setMyStationId(
                    Number(station.id),
                  );
                  dispatch(setShouldUpdateUrl(true));

                  closePopover();
                }}
              >
                {station.name}
              </MenuItem>
            ))}
          </MenuList>
        </AccordionDetails>
      </Accordion>
    ));

  const renderOrganizationsAndStationsFromOrgData = () =>
    filteredOrgs?.map((org) => (
      <Accordion
        key={org.name + '-' + org.id}
        classes={accordionClasses}
        defaultExpanded
      >
        <AccordionSummary
          classes={accordionSummaryClasses}
          expandIcon={
            <ExpandMoreIcon className={classes.expandMoreIcon} />
          }
          onClick={(event) =>
            onAccordionSummaryClick(
              event,
              SELECTOR_ENTITY_TYPE.ORGANIZATION,
              org.id || 0,
            )
          }
        >
          {org.name}
        </AccordionSummary>
        <AccordionDetails classes={accordionDetailsClasses}>
          <MenuList className={classes.menuList}>
            {filteredGroupedStations
              ?.find((group) => group[0].organization === org.id)
              ?.map((station) => (
                <MenuItem
                  key={station.name + '-' + station.id}
                  className={classes.menuItem}
                  onClick={() => {
                    dispatch(
                      setSelectedEntity({
                        id: station.id || 0,
                        type: SELECTOR_ENTITY_TYPE.STATION,
                      }),
                    );
                    EphemeralStateService.setMyStationId(
                      Number(station.id),
                    );
                    dispatch(setShouldUpdateUrl(true));

                    closePopover();
                  }}
                >
                  {station.name}
                </MenuItem>
              ))}
          </MenuList>
        </AccordionDetails>
      </Accordion>
    ));

  return (
    <>
      <Box className={classes.selectorContainer}>
        <Grid
          container
          alignItems='center'
          alignContent='space-between'
        >
          <Grid item>
            <StoreMallDirectoryOutlinedIcon style={{ margin: 10 }} />
          </Grid>
          <Grid item style={{ flexGrow: 1 }}>
            <Typography
              color={{ color: 'primary', variant: 'contrastText' }}
              variant='label'
              className={classes.smallLabel}
            >
              {capitalize(selectedEntityType || '')}
            </Typography>
            <Typography
              color={{ color: 'primary', variant: 'contrastText' }}
              variant='subtitle1'
              className={classes.entityName}
            >
              {renderEntityName()}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={openPopover} color='inherit'>
              <UnfoldMoreOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: classes.popoverPaper,
        }}
      >
        <Typography
          color={{ color: 'primary', variant: 'contrastText' }}
          variant='label'
          className={classes.smallLabel}
        >
          Switch Workspace
        </Typography>
        <CSTextField
          autoFocus
          placeholder='Search'
          variant='filled-outlined'
          startAdornment={<SearchIcon />}
          value={filterString}
          onChange={(event) => {
            setFilterString(event.target.value);
          }}
          onClear={() => {
            setFilterString('');
          }}
          className={classes.marginBottom}
        />
        {isConfigurationNav &&
        PermissionsService.hasPermission('MASTER_WRITE') ? (
          <Accordion classes={accordionClasses} defaultExpanded>
            <AccordionSummary
              onClick={(event) =>
                onAccordionSummaryClick(
                  event,
                  SELECTOR_ENTITY_TYPE.CLOUDSORT,
                  1,
                )
              }
              classes={accordionSummaryClasses}
              expandIcon={
                <ExpandMoreIcon className={classes.expandMoreIcon} />
              }
            >
              Cloudsort
            </AccordionSummary>
            <AccordionDetails classes={accordionDetailsClasses}>
              {orgs?.length
                ? renderOrganizationsAndStationsFromOrgData()
                : renderOrganizationsAndStationsFromStationsData()}
            </AccordionDetails>
          </Accordion>
        ) : (
          renderOrganizationsAndStationsFromStationsData()
        )}
      </Popover>
    </>
  );
};

export default withStyles(styles)(EntitySelector);
