import React, { useEffect, useState } from 'react';
import {
  AlertBanner,
  Box,
  CSButton,
  CSTextField,
  Typography,
} from '../../primitives';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid, Theme, Paper } from '@material-ui/core';
import detailsPageStyles from '../../commonStyles/detailsPage.style';
import Layout from '../../layout/Layout';
import CustomersService from '../../../services/Customers.service';
import { useParams, Prompt } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import sectionPageBaseStyle from '../../commonStyles/sectionPageBase.style';
import ErrorHandler from '../../../utils/ErrorHandler';
import { AxiosError } from 'axios';
import ConfirmationDialog from '../../confirmationDialog/ConfirmationDialog';
import {
  configurationUrlParams,
  getInteraction,
  getMode,
  Mode,
  INTERACTION,
} from '../utils';
import ProgressIndicator from '../../progressIndicator/ProgressIndicator';
import ReAuthDialog from '../../reAuthDialog/ReAuthDialog';
import {
  AssignedEntity,
  AssignedOrganization,
  AssignedStation,
  getBaseNavigationItems,
} from './utils';
import validateEmail from '../../../utils/validateEmail';
import EditOrganizationsStationsDialog from './EditOrganizationsStationsDialog';
import globalStationOptionsUtils from '../../../utils/globalStationOptionsUtils';
import browserHistory from '../../../utils/browserHistory';
import { AuthRoutes } from '../../../interfaces/routes';
import EphemeralStateService from '../../../services/EphemeralState.service';
import { setSelectedEntity } from '../../../redux/slices/navigationSlice';
import { SELECTOR_ENTITY_TYPE } from '../../navigation/types';
import { useAppDispatch } from '../../../redux/store';
import CSBreadcrumbs from '../../primitives/CSBreadcrumbs/CSBreadcrumbs';
import { CSSingleDetailMonoColumnContainer } from '../../primitives/singleDetail/singleDetailMonoColumnContainer';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CSSectionTitleSeparator from '../../primitives/csSectionTitleSeparator/CSSectionTitleSeparator';
import {
  CSBreadcrumbItem,
  CSBreadcrumbSelectedItem,
} from '../../primitives/CSBreadcrumbs/CSBreadcrumbs.types';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import PaginatedTable, {
  filterObj,
} from '../../paginatedTable/PaginatedTable';
import { Column } from '../../../interfaces/components';
import CustomerConfigurationsService from '../../../services/CustomerConfigurationsService';
import StationsService from '../../../services/Stations.service';
import { MAX_PAGE_SIZE } from '../../../services/utils/constants';
import { CustomerConfig } from 'cloudsort-client';
import AssignStationsToConfigurationDialog from './AssignStationsToConfigurationDialog';

interface Props {
  classes: { [key: string]: string };
}

const CustomerDetails: React.FC<Props> = ({ classes }) => {
  //Initial data
  const [initialName, setInitialName] = useState('');
  const [initialEmail, setInitialEmail] = useState('');
  const [initialEntities, setInitialEntities] = useState<
    AssignedEntity[]
  >([]);
  const [initialConfigurationsData, setInitialConfigurationsData] =
    useState<CustomerConfig[]>([]);
  //New data
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [entities, setEntities] = useState<AssignedEntity[]>([]);
  const [entitiesToAdd, setEntitiesToAdd] = useState<
    AssignedEntity[]
  >([]);
  const [entitiesToRemove, setEntitiesToRemove] = useState<
    AssignedEntity[]
  >([]);
  //Misc
  const [hasUnsavedChanges, setHasUnsavedChanges] =
    useState<boolean>(false);
  const [showReAuthDialog, setShowReAuthDialog] =
    useState<boolean>(false);
  const [showEditEntitiesDialog, setShowEntitiesDialog] =
    useState(false);
  const [showDeleteDialog, setShowDeleteDialog] =
    useState<boolean>(false);
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [hasEmailError, setHasEmailError] = useState<boolean>(
    validateEmail(initialEmail),
  );
  const [breadcrumbsArray, setBreadcrumbsArray] = useState<
    Array<CSBreadcrumbItem | CSBreadcrumbSelectedItem>
  >([]);
  const urlParams = useParams<configurationUrlParams>();
  const dispatch = useAppDispatch();
  const [hasOrganizationConfig, setHasOrganizationConfig] =
    useState<boolean>(false);
  const [hasStationConfigs, setHasStationConfigs] =
    useState<boolean>(false);
  const [lastUpdate, setLastUpdate] = useState<string>(
    new Date().toISOString(),
  );
  const [
    showAssignStationsToConfigDialog,
    setShowAssignStationsToConfigDialog,
  ] = useState<boolean>(false);
  const [customerConfigUnderEdit, setCustomerConfigUnderEdit] =
    useState<CustomerConfig>({} as CustomerConfig);
  const mode = getMode(urlParams.orgId, urlParams.stationId);
  const interaction = getInteraction(mode);
  const configurationBaseUrl = `${AuthRoutes.CONFIGURATION}${
    urlParams.orgId ? '/organization/' + urlParams.orgId : ''
  }${
    urlParams.stationId ? '/station/' + urlParams.stationId : ''
  }/customers/${urlParams.customerId}`;

  const STATION_CONFIGS_COLUMNS: Column[] = [
    {
      id: 'id',
      label: 'ID',
      hide: true, //Use this to locate the correct config when editing.
    },
    {
      id: 'name',
      label: 'Configuration',
    },
    {
      id: 'organization_name',
      label: 'Organization',
      hide: mode !== Mode.CLOUDSORT,
    },
    {
      id: 'station_names',
      label: 'Stations',
    },
  ];

  const DEFAULT_CONFIG_COLUMNS: Column[] = [
    {
      id: 'name',
      label: 'Configuration',
    },
    {
      id: 'organization_name',
      label: 'Organization',
      hide: mode !== Mode.CLOUDSORT,
    },
  ];

  const fetchStationConfigurations = async (
    pageIndex: number,
    rowsPerPage: number,
    filterOptions?: filterObj[],
    filterByString?: string,
    sortedBy?: string,
  ) => {
    try {
      const stationIds: number[] = [];
      if (mode === Mode.ORGANIZATION) {
        const stationsByOrg = await StationsService.getStationByOrg(
          Number(urlParams.orgId),
          1,
          MAX_PAGE_SIZE,
        );
        stationIds.push(
          ...stationsByOrg.data.results.map((station) =>
            Number(station.id),
          ),
        );
      }

      if (mode === Mode.STATION) {
        stationIds.push(Number(urlParams.stationId));
      }

      const res: any =
        await CustomerConfigurationsService.getConfigurations({
          customerId: Number(urlParams.customerId),
          stations: stationIds,
          pageIndex: pageIndex,
          rowsPerPage: rowsPerPage,
        });

      if (res?.data?.count > 0) {
        setHasStationConfigs(true);
        setInitialConfigurationsData(res.data.results);
        res.data.results = res.data.results.map(
          (
            result: CustomerConfig & {
              station_names: string;
              station_data: [];
            },
          ) => {
            result.station_names = (result.station_data || [])
              .map((station: { name: string }) => {
                return station.name;
              })
              .join(', ');
            return result;
          },
        );
      } else {
        setHasStationConfigs(false);
      }
      return res;
    } catch (err) {
      handleError(err as AxiosError);
    }
  };

  const fetchOrganizationConfiguration = async (
    pageIndex: number,
    rowsPerPage: number,
    filterOptions?: filterObj[],
    filterByString?: string,
    sortedBy?: string,
  ) => {
    try {
      const res =
        await CustomerConfigurationsService.getDefaultConfiguration({
          customerId: Number(urlParams.customerId),
          organizationId: Number(urlParams.orgId),
        });
      if (res?.data?.count > 0) {
        setHasOrganizationConfig(true);
      }
      return res;
    } catch (err) {
      handleError(err as AxiosError);
    }
  };

  const handleGoToDashboard = async (id: number) => {
    await globalStationOptionsUtils.setStationData(id);
    dispatch(
      setSelectedEntity({ id, type: SELECTOR_ENTITY_TYPE.STATION }),
    );
    EphemeralStateService.setMyStationId(id);
    setTimeout(() => {
      browserHistory.push(AuthRoutes.DASHBOARD);
    }, 0);
  };

  const handleError = async (err: AxiosError) => {
    setError(await ErrorHandler.getLabel(err));
  };

  const getAndSetBreadcrumbItems = async () => {
    try {
      const { baseURL, navItems } = await getBaseNavigationItems(
        urlParams.orgId,
        urlParams.stationId,
      );
      const customersURL = `${baseURL}/customers`;
      navItems.push(
        {
          label: 'Customers',
          link: customersURL,
        },
        {
          label: initialName,
          selected: true,
        },
      );
      setBreadcrumbsArray(navItems);
    } catch (err) {
      console.error(err);
      setBreadcrumbsArray([]);
    }
  };

  const getAndSetCustomerData = async () => {
    try {
      setShowProgress(true);
      const res = await CustomersService.getById(
        Number(urlParams.customerId),
        Number(urlParams.stationId),
        Number(Number(urlParams.orgId)),
      );
      //Set name and email
      setName(res.data?.identifier || '');
      setInitialName(res.data?.identifier || '');
      setEmail(res.data.email || '');
      setInitialEmail(res.data.email || '');
      //Set organization and station data
      for (let organization of (res.data?.assigned_organizations ||
        []) as AssignedOrganization[]) {
        organization.type = 'Organization';
      }
      for (let organization of (res.data?.assigned_stations ||
        []) as AssignedStation[]) {
        organization.type = 'Station';
      }
      setEntities([
        ...(res.data
          ?.assigned_organizations as AssignedOrganization[]),
        ...(res.data?.assigned_stations as AssignedStation[]),
      ]);
      setInitialEntities([
        ...(res.data
          ?.assigned_organizations as AssignedOrganization[]),
        ...(res.data?.assigned_stations as AssignedStation[]),
      ]);
      setLastUpdate(new Date().toISOString());
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  useEffect(() => {
    getAndSetCustomerData();
    getAndSetBreadcrumbItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParams.orgId, urlParams.stationId, initialName]);

  const deleteCustomer = async () => {
    try {
      setShowProgress(true);
      await CustomersService.deleteCustomer(
        Number(urlParams.customerId),
      );
      browserHistory.push(
        `${AuthRoutes.CONFIGURATION}${
          urlParams.orgId ? '/organization/' + urlParams.orgId : ''
        }${
          urlParams.stationId ? '/station/' + urlParams.stationId : ''
        }/customers`,
      );
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  const updateCustomer = async () => {
    try {
      setShowProgress(true);
      //Update name and email
      //Send only updated values
      await CustomersService.updateNameEmail(
        Number(urlParams.customerId),
        name !== initialName ? name : undefined,
        email !== initialEmail ? email : undefined,
      );
      //Assign new entities
      for (let entity of entitiesToAdd) {
        await CustomersService.assignToOrganizationOrStation(
          Number(urlParams.customerId),
          entity.type === 'Organization' ? entity.id : undefined,
          entity.type === 'Station' ? entity.id : undefined,
        );
      }
      //Remove old entities
      for (let entity of entitiesToRemove) {
        await CustomersService.removeFromOrganizationOrStation(
          Number(urlParams.customerId),
          entity.type === 'Organization' ? entity.id : undefined,
          entity.type === 'Station' ? entity.id : undefined,
        );
      }
      setHasUnsavedChanges(false);
      getAndSetCustomerData();
      setError(undefined);
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>{`CloudSort - ${initialName} Customer Profile`}</title>
      </Helmet>
      <Layout navCurrent='CONFIGURATION'>
        <Prompt
          when={hasUnsavedChanges}
          message='You have unsaved changes, are you sure you want to leave?'
        />
        {showProgress && <ProgressIndicator />}
        <ConfirmationDialog
          dataTestIdPrefix='delete-customer-confirmation'
          title={`Delete Customer`}
          msg={`Are you sure you want to delete ${initialName}?`}
          primaryActionLabel='Confirm'
          cancelLabel='Cancel'
          onPrimaryAction={() => {
            deleteCustomer();
          }}
          onCancel={() => {
            setShowDeleteDialog(false);
          }}
          isOpen={showDeleteDialog}
        />
        <ReAuthDialog
          isOpen={showReAuthDialog}
          onAfterClose={() => {
            setShowReAuthDialog(false);
          }}
          callbackFn={() => {
            updateCustomer();
            setShowReAuthDialog(false);
          }}
        />
        <EditOrganizationsStationsDialog
          isOpen={showEditEntitiesDialog}
          entities={entities}
          onCancel={() => {
            setShowEntitiesDialog(false);
          }}
          onSave={(newEntities) => {
            const toRemove = entities.filter((entity) => {
              return !newEntities.includes(entity);
            });
            const toAdd = newEntities.filter((entity) => {
              return !initialEntities.includes(entity);
            });
            setEntitiesToAdd(toAdd);
            setEntitiesToRemove(toRemove);
            setEntities(newEntities);
            setHasUnsavedChanges(true);
            setShowEntitiesDialog(false);
          }}
        />
        <AssignStationsToConfigurationDialog
          isOpen={showAssignStationsToConfigDialog}
          config={customerConfigUnderEdit}
          onCancel={() => {
            setShowAssignStationsToConfigDialog(false);
          }}
          onUpdate={() => {
            setShowAssignStationsToConfigDialog(false);
            setLastUpdate(new Date().toISOString());
          }}
        />
        <Box mb={5}>
          <CSBreadcrumbs breadcrumbs={breadcrumbsArray} />
        </Box>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={12} sm={4}>
            <Typography variant='h3' component='h2'>
              {initialName}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8} className={classes.actionButtons}>
            <CSButton
              data-testid='delete-customer-button'
              variant='outlined'
              color='secondary'
              className={classes.titleButtons}
              onClick={(e) => {
                setShowDeleteDialog(true);
              }}
              startIcon={<DeleteIcon />}
            >
              Delete
            </CSButton>
            <CSButton
              data-testid='save-customer-button'
              variant='contained'
              color='secondary'
              disabled={!hasUnsavedChanges}
              className={classes.titleButtons}
              onClick={(e) => {
                if (email !== initialEmail) {
                  setShowReAuthDialog(true);
                } else {
                  updateCustomer();
                }
              }}
            >
              Save
            </CSButton>
          </Grid>
        </Grid>
        <CSSectionTitleSeparator topMargin={5} />
        {error && (
          <AlertBanner
            className={classes.banner}
            severity='error'
            alertTitle={'Error'}
            alertMsg={error}
          />
        )}
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={EphemeralStateService.getIsRoleSuperUser() ? 6 : 12}
          >
            <Paper className={classes.paper}>
              <CSSingleDetailMonoColumnContainer
                spacing={1}
                data-testid='customer-basic=info'
                header='Customer Info'
                elements={[
                  name ? (
                    <CSTextField
                      className={classes.customerInfoInput}
                      key='customer-name'
                      label='Customer Name'
                      placeholder='Customer Name'
                      containerSize='fullHorizontal'
                      required
                      value={name}
                      onChange={(e: React.BaseSyntheticEvent) => {
                        setName(e.target.value);
                        setHasUnsavedChanges(true);
                      }}
                    />
                  ) : undefined,
                  email ? (
                    <CSTextField
                      className={classes.customerInfoInput}
                      key='admin-email'
                      label='Admin Email'
                      placeholder='Admin Email'
                      containerSize='fullHorizontal'
                      required
                      value={email}
                      error={hasEmailError}
                      helperText={
                        hasEmailError
                          ? `The email address you've entered doesn't seem right. Please check spelling.`
                          : ''
                      }
                      onChange={(e: React.BaseSyntheticEvent) => {
                        setEmail(e.target.value);
                        setHasEmailError(
                          !validateEmail(e.target.value),
                        );
                        setHasUnsavedChanges(true);
                      }}
                    />
                  ) : undefined,
                  <Typography
                    component='p'
                    key='new-email-warning'
                    variant='body2'
                    className={classes.padding5}
                  >
                    If you update the email address, we'll send an
                    email to the new address to verify it.
                    <br />
                    <b>
                      The new address will not become active until
                      confirmed.
                    </b>
                  </Typography>,
                ]}
              />
            </Paper>
          </Grid>
          {EphemeralStateService.getIsRoleSuperUser() && (
            <Grid item xs={12} sm={6}>
              <Paper className={classes.paper}>
                <CSSingleDetailMonoColumnContainer
                  data-testid='assigned-entities-info'
                  header='Assigned Organizations and Stations'
                  elements={[
                    ...entities.map((entity) => {
                      return (
                        <Grid
                          container
                          key={
                            'assigned-entity-' +
                            entity.id +
                            entity.name
                          }
                        >
                          <Grid
                            item
                            xs={entity.type === 'Station' ? 7 : 12}
                          >
                            <CSButton
                              className={classes.stationBadge}
                              component='div'
                              size='small'
                              variant='contained'
                              color='secondary'
                            >
                              {entity.name}
                            </CSButton>
                          </Grid>
                          {entity.type === 'Station' && (
                            <Grid item xs={5}>
                              <Typography
                                component='p'
                                variant='h6'
                                className={classes.goToStationLink}
                                onClick={(
                                  e: React.SyntheticEvent,
                                ) => {
                                  e.preventDefault();
                                  handleGoToDashboard(entity.id!);
                                }}
                              >
                                {`Go to ${entity.name} Dashboard`}
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                      );
                    }),
                    <CSButton
                      className={classes.changeAssignedEntitiesButton}
                      key='edit-assigned-entities-button'
                      data-testid='edit-assigned-entities-button'
                      variant='outlined'
                      color='secondary'
                      startIcon={<EditIcon />}
                      onClick={(e) => {
                        setShowEntitiesDialog(true);
                      }}
                    >
                      Edit Organizations or Stations
                    </CSButton>,
                  ]}
                />
              </Paper>
            </Grid>
          )}
        </Grid>

        {
          //The API doesn't support filtering by multiple organizations, so we need to hide this section in cloudsort mode.
          mode !== Mode.CLOUDSORT && (
            <>
              <Grid container spacing={2} alignItems='center'>
                <Grid item xs={12} sm={4}>
                  <Typography
                    className={classes.title}
                    variant='h3'
                    component='h2'
                  >
                    Default Configuration
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={8}
                  className={classes.actionButtons}
                >
                  {interaction === INTERACTION.WRITE &&
                    !hasOrganizationConfig && (
                      <CSButton
                        data-testid='save-customer-button'
                        variant='contained'
                        color={{
                          buttonColor: 'secondary',
                          iconColor: 'primary',
                        }}
                        disabled={hasOrganizationConfig}
                        className={classes.titleButtons}
                        onClick={(e) => {
                          browserHistory.push(
                            `${configurationBaseUrl}/newConfiguration?type=organization`,
                          );
                        }}
                        startIcon={<AddBoxOutlinedIcon />}
                      >
                        Add Configuration
                      </CSButton>
                    )}
                </Grid>
              </Grid>
              <CSSectionTitleSeparator
                topMargin={5}
                bottomMargin={20}
              />
              <PaginatedTable
                key={'config-default'}
                title=''
                columns={DEFAULT_CONFIG_COLUMNS}
                dataTestIdPrefix={'default-configuration'}
                fetch={fetchOrganizationConfiguration}
                showPagination={false}
                rowsLoadDetailPages={true}
                detailsPageBasePath={configurationBaseUrl}
              />
            </>
          )
        }

        <Grid
          container
          spacing={2}
          alignItems='center'
          className={classes.customerConfigSection}
        >
          <Grid item xs={12} sm={4}>
            <Typography
              className={classes.title}
              variant='h3'
              component='h2'
            >
              {mode === Mode.STATION
                ? 'Station Configuration'
                : 'Station Configurations'}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8} className={classes.actionButtons}>
            {interaction === INTERACTION.WRITE && (
              <CSButton
                variant='contained'
                color={{
                  buttonColor: 'secondary',
                  iconColor: 'primary',
                }}
                disabled={hasStationConfigs && mode === Mode.STATION}
                className={classes.titleButtons}
                onClick={(e) => {
                  browserHistory.push(
                    `${configurationBaseUrl}/newConfiguration?type=station`,
                  );
                }}
                startIcon={<AddBoxOutlinedIcon />}
              >
                {mode === Mode.STATION
                  ? 'Add Configuration'
                  : 'Add Configurations'}
              </CSButton>
            )}
          </Grid>
        </Grid>
        <CSSectionTitleSeparator topMargin={5} bottomMargin={24} />
        <PaginatedTable
          key={'station-configs-' + lastUpdate}
          title=''
          columns={STATION_CONFIGS_COLUMNS}
          dataTestIdPrefix={'station-configs-list'}
          fetch={fetchStationConfigurations}
          rowsLoadDetailPages={true}
          detailsPageBasePath={configurationBaseUrl}
          actions={[
            {
              cellWidth: 70,
              tableLabel:
                interaction === INTERACTION.WRITE ? ' ' : undefined,
              columnLabel:
                interaction === INTERACTION.WRITE ? (
                  <EditIcon />
                ) : undefined,
              callbackProperty: 'id',
              qualifier: 'id',
              callback: (id: number) => {
                const configToEdit = initialConfigurationsData.find(
                  (config) => config.id === id,
                );
                setCustomerConfigUnderEdit(
                  configToEdit || ({} as CustomerConfig),
                );
                setShowAssignStationsToConfigDialog(true);
              },
            },
          ]}
        />
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...sectionPageBaseStyle,
    titleButtons: {
      margin: '2px 2px 2px 8px',
    },
    customerInfoInput: {
      margin: '5px 2px',
    },
    stationBadge: {
      margin: '5px 2px',
    },
    changeAssignedEntitiesButton: {
      marginTop: '10px',
    },
    goToStationLink: {
      cursor: 'pointer',
      textAlign: 'right',
    },
    actionButtons: {
      textAlign: 'right',
    },
    customerConfigSection: {
      marginTop: 20,
    },
    padding5: { padding: 5 },
  })),
)(CustomerDetails);
