const colors = {
  white: '#FFFFFF',
  black: '#000000',
  dark: '#363437',
  edgeGray: '#9B9B9B',
  edgeBlue1: '#8AC1FF',
  edgeBlue2: '#5C8EEE',
  edgeBlue3: '#1A67E4',
  edgeBlue4: '#0A4BB4',
  primaryBorder: '#D2D2D2',
  secondaryBorder: '#A3A3A3',
  secondaryBackground: '#F2F2F2',
};

export default colors;
