import { createStyles, Theme } from '@material-ui/core/styles';

export default createStyles((theme: Theme) => ({
  colorPrimary: {
    '&$disabled': {
      color: theme.palette.grey.A400,
    },
  },
  disabled: {},
}));
