import React, { useEffect, useMemo, useState } from 'react';
import { withStyles, Theme } from '@material-ui/core/styles';
import { CSButton, CSTextField, Typography } from '../primitives';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import ContainerService from '../../services/Containers.service';
import Layout from '../layout/Layout';
import { AlertBanner } from '../primitives';
import Paper from '@material-ui/core/Paper';
import ErrorHandler from '../../utils/ErrorHandler';
import browserHistory from '../../utils/browserHistory';
import ConfirmationDialog from '../confirmationDialog/ConfirmationDialog';
import { AuthRoutes } from '../../interfaces/routes';
import PackagesOverview from './details/PackagesOverview';
import ContainersOverview from './details/ContainersOverview';
import AreaTotals from './details/AreaTotals';
import TodayPackagesChart from './details/TodayPackagesChart';
import { createStyles } from '@material-ui/core/styles';
import detailsPageStyles from '../commonStyles/detailsPage.style';
import selectStyles from '../select/select.styles';
import PrintQrCodesDialog from '../printQrCodesDialog/PrintQrCodesDialog';
import configurationUtils from '../../utils/configurationUtils';
import { Box, Grid } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Transition } from '../confirmationDialog/ConfirmationDialog';

// Icons
import SettingsSharpIcon from '@material-ui/icons/SettingsSharp';
import FilterCenterFocusIcon from '@material-ui/icons/FilterCenterFocus';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';

// Types
import {
  AreaDetails,
  AreaDetailsAreaTypeEnum,
  Container,
  NestedProcessStepSerializerPackageProcessStepEnum,
  StaffPermissionPermissionsEnum,
} from 'cloudsort-client';
import { AxiosError } from 'axios';

// Services
import AreasService from '../../services/Areas.service';
import PackagesPerformanceStats from '../packages/packagesStats/PackagesPerformanceStats';
import PermissionsService from '../../services/Permissions.service';

import EphemeralStateService from '../../services/EphemeralState.service';
import { Helmet } from 'react-helmet';
import sectionPageBaseStyle from '../commonStyles/sectionPageBase.style';
import clsx from 'clsx';
import CSSectionTitleSeparator from '../primitives/csSectionTitleSeparator/CSSectionTitleSeparator';
import useStationId from '../../hooks/useStationId';
import CSBreadcrumbs from '../primitives/CSBreadcrumbs/CSBreadcrumbs';
import PrimaryAreaPackagesOverview from './details/PrimaryAreaPackagesOverview';

interface Props {
  classes: { [key: string]: string };
  match: any;
}

enum DashbaordSections {
  PERFORMANCE_CHART = 'PERFORMANCE_CHART',
}

const AreasComponent: React.FC<Props> = ({ classes, match }) => {
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [nameToEdit, setNameToEdit] = useState<string | null>();
  const [areaData, setAreaData] = useState<AreaDetails>();
  // It's possible for a user to open area details page without station id in the url.
  //In this case we need to get it from the area data and set it in the url. The web app (navigation bar) will use it to display the station name and show the correct navigation menu.
  useStationId(areaData?.station);
  const [openQrDialog, setOpenQrDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] =
    useState<boolean>(false);
  const [deleteConfirmationMessage, setDeleteConfirmationMessage] =
    useState<String>('');

  const areaLabels = useMemo(() => {
    return {
      singular: configurationUtils.getPageTitle(true, 'AREA'),
      plural: configurationUtils.getPageTitle(false, 'AREA'),
    };
  }, []);

  const layoutConfig =
    EphemeralStateService.getMyStationConfiguratation()?.WEB?.LAYOUTS
      ?.DASHBOARD;

  const getAreaData = async () => {
    setShowProgress(true);
    try {
      const { data } = await AreasService.getById(match.params.id);
      setAreaData(data);
    } catch (e) {
      handleError(e as AxiosError);
    }
    setShowProgress(false);
  };

  const deleteAreaHandler = async () => {
    setShowProgress(true);
    try {
      if (areaData) {
        await AreasService.delete(areaData.id!);
        browserHistory.push(AuthRoutes.AREA);
      }
    } catch (e) {
      handleError(e as AxiosError);
      setShowProgress(false);
    }
  };

  const renderEditDialog = () => {
    return (
      <Dialog
        classes={{ paperScrollPaper: classes.dialogRoot }}
        open={typeof nameToEdit === 'string'}
        TransitionComponent={Transition}
        onClose={() => {
          setNameToEdit(null);
        }}
      >
        <DialogTitle>Edit Name</DialogTitle>
        <DialogContent className={classes.dialogRoot}>
          <CSTextField
            containerSize='fullHorizontal'
            autoFocus
            label='Name'
            type={'string'}
            value={nameToEdit || ''}
            onChange={(e) => {
              setNameToEdit(e.target.value);
            }}
            InputLabelProps={{
              shrink: true,
              className: classes.selectLabel,
            }}
            error={
              configurationUtils.isModuleNameValidated('AREA') &&
              !/^[A-Z][0-9]{1,3}$/.test(nameToEdit || '')
            }
            helperText={
              configurationUtils.isModuleNameValidated('AREA')
                ? 'A capital letter followed by up to 3 digits.'
                : undefined
            }
          />
        </DialogContent>

        <DialogActions className={classes.dialogActions}>
          <CSButton
            variant='outlined'
            color='secondary'
            onClick={() => {
              setNameToEdit(null);
            }}
          >
            Cancel
          </CSButton>
          <CSButton
            variant='contained'
            color='secondary'
            disabled={
              !nameToEdit ||
              (configurationUtils.isModuleNameValidated('AREA') &&
                !/^[A-Z][0-9]{1,3}$/.test(nameToEdit || ''))
            }
            onClick={() => {
              const areaDataObj: AreaDetails = { ...areaData };
              areaDataObj.name = nameToEdit;
              editArea(areaDataObj);
              setNameToEdit(null);
            }}
          >
            Edit
          </CSButton>
        </DialogActions>
      </Dialog>
    );
  };

  const editArea = async (areaDataObj: AreaDetails) => {
    setShowProgress(true);
    try {
      if (areaData) {
        await AreasService.update(areaData.id!, areaDataObj);
        await getAreaData();
      }
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e as AxiosError));
  };

  const checkContainers = async (area: AreaDetails | undefined) => {
    setShowProgress(true);

    try {
      const res = await ContainerService.getAll({
        area: area?.id,
      });

      let containersIds: string =
        res.data.results[0]?.id?.toString() || '';
      let containersCount: number = res.data.results.length || 1;
      res.data.results.slice(1).forEach((container: Container) => {
        containersIds += ', ' + container.id;
        containersCount += containersCount;
      });

      let loadPoints: string =
        res.data.results[0]?.load_point?.name?.toString() || '';
      let loadPointCount: number = res.data.results.length || 1;
      res.data.results.slice(1).forEach((container: Container) => {
        loadPoints += ', ' + container.load_point?.name;
        loadPointCount += loadPointCount;
      });

      const areaName = area?.name || area?.id;
      const simpleMsg = `Are you sure you want to delete ${configurationUtils.getPageTitle(
        true,
        'AREA',
      )} ${areaName}?`;

      res.data.count === 0
        ? setDeleteConfirmationMessage(simpleMsg)
        : setDeleteConfirmationMessage(`${
            containersCount > 1 ? 'Containers' : 'Container'
          } ${containersIds} ${
            containersCount > 1 ? 'are' : 'is'
          } still on ${
            loadPointCount > 1 ? 'Loadpoints' : 'Loadpoint'
          } ${loadPoints}. After deleting the ${configurationUtils.getPageTitle(
            true,
            'AREA',
          )} ${areaName}, the ${
            containersCount > 1 ? 'containers have' : 'container has'
          } to be manually moved (use Troubleshooting Mode in the Operator Tool) to the new loadpoint.
          ${simpleMsg}`);

      setShowDeleteDialog(true);
    } catch (e) {
      handleError(e as AxiosError);
    }
    setShowProgress(false);
  };

  useEffect(() => {
    if (!areaData) {
      getAreaData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort - ${configurationUtils.getPageTitle(
            true,
            'AREA',
          )} Details for ${areaData?.name || ''}`}
        </title>
      </Helmet>
      <Layout navCurrent='AREA'>
        {showProgress && <ProgressIndicator />}
        {renderEditDialog()}
        {areaData && (
          <PrintQrCodesDialog
            areaId={areaData.id}
            downloadName={`${areaData.name}_area_labels`}
            isOpen={openQrDialog}
            onAfterClose={() => {
              setOpenQrDialog(false);
            }}
          />
        )}
        <ConfirmationDialog
          dataTestIdPrefix={'area-details-delete-dialog'}
          title={`Delete ${configurationUtils.getPageTitle(
            true,
            'AREA',
          )}`}
          msg={deleteConfirmationMessage}
          primaryActionLabel={'Delete'}
          onPrimaryAction={deleteAreaHandler}
          cancelLabel={'Cancel'}
          onCancel={() => {
            setShowDeleteDialog(false);
          }}
          isOpen={showDeleteDialog}
        />
        {error && (
          <AlertBanner
            data-testid='areas-error-banner'
            className={classes.banner}
            severity='error'
            alertTitle={'Error'}
            alertMsg={error}
          />
        )}

        <Grid
          container
          spacing={2}
          style={{ marginBottom: 8 }}
          data-testid='areas-detail-view'
        >
          <Grid item xs={12}>
            <Box mb={5}>
              <CSBreadcrumbs
                breadcrumbs={[
                  {
                    label: areaLabels.plural,
                    link:
                      AuthRoutes.AREA +
                      '?stationId=' +
                      areaData?.station,
                  },
                  {
                    label: `${areaData?.name || ''}`,
                    selected: true,
                  },
                ]}
              />
            </Box>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={6}
            wrap='nowrap'
            alignItems='center'
          >
            <Typography variant='h3' component='h1'>
              {areaData
                ? `${areaLabels.singular} Details for ${
                    areaData?.name || ''
                  }`
                : ''}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            className={classes.nonMobileAlignRight}
          >
            {PermissionsService.hasPermission(
              StaffPermissionPermissionsEnum.STATION_WRITE,
            ) && (
              <>
                <CSButton
                  variant='outlined'
                  color='secondary'
                  className={classes.sectionButton}
                  data-testid='delete-area'
                  onClick={(
                    e: React.MouseEvent<HTMLButtonElement>,
                  ) => {
                    checkContainers(areaData);
                  }}
                >
                  <DeleteIcon /> Remove
                </CSButton>
                <CSButton
                  variant='outlined'
                  color='secondary'
                  className={classes.sectionButton}
                  data-testid='area:edit-name'
                  onClick={(
                    e: React.MouseEvent<HTMLButtonElement>,
                  ) => {
                    setNameToEdit(areaData?.name);
                  }}
                >
                  <CreateIcon /> Edit
                </CSButton>
              </>
            )}
            {areaData?.area_type !==
              AreaDetailsAreaTypeEnum.STAGING && (
              <CSButton
                data-testid='go-to-area-settings-button'
                variant='outlined'
                color='secondary'
                className={classes.sectionButton}
                disabled={!areaData}
                onClick={(e) => {
                  e.preventDefault();
                  browserHistory.push(
                    `${match.params.id}/settings/${areaData?.area_type}`,
                  );
                }}
              >
                <SettingsSharpIcon style={{ marginRight: 10 }} />
                Settings
              </CSButton>
            )}
            <CSButton
              variant='contained'
              color='primary'
              className={clsx(
                classes.sectionButton,
                classes.headerFilterButton,
              )}
              disabled={!areaData}
              style={{ margin: '0 0 8px 8px' }}
              onClick={(e) => {
                e.preventDefault();
                setOpenQrDialog(true);
              }}
              startIcon={<FilterCenterFocusIcon />}
            >
              QR Codes
            </CSButton>
          </Grid>
          <CSSectionTitleSeparator />
        </Grid>

        <Grid container spacing={3}>
          {areaData?.area_type !==
            AreaDetailsAreaTypeEnum.SECONDARY &&
            areaData?.id && (
              <PrimaryAreaPackagesOverview
                areaId={areaData?.id}
                packagesStatusToFetch={
                  areaData.area_type ===
                  AreaDetailsAreaTypeEnum.PRIMARY
                    ? NestedProcessStepSerializerPackageProcessStepEnum.PRIMARY
                    : NestedProcessStepSerializerPackageProcessStepEnum.STAGED
                }
              />
            )}
          {areaData?.area_type ===
            AreaDetailsAreaTypeEnum.SECONDARY && (
            <>
              <Grid item md={8} xs={12}>
                <Typography
                  variant='h6'
                  component='h2'
                  className={classes.titleSpacing}
                >
                  {configurationUtils.getPageTitle(false, 'PACKAGE')}{' '}
                  Overview
                </Typography>
                {areaData && (
                  <PackagesOverview
                    areaId={areaData.id}
                    areaType={areaData.area_type}
                  />
                )}
              </Grid>
              <Grid item md={4} xs={12}>
                <Typography
                  variant='h6'
                  component='h2'
                  className={classes.titleSpacing}
                >
                  {configurationUtils.getPageTitle(
                    false,
                    'CONTAINER',
                  )}{' '}
                  Overview
                </Typography>
                <ContainersOverview areaId={match.params.id} />
              </Grid>
            </>
          )}
        </Grid>
        {layoutConfig?.TODAY_CHART?.active && (
          <>
            <Typography
              variant='h6'
              component='h2'
              className={classes.titleSpacing}
            >
              Today{' '}
              {configurationUtils.getPageTitle(false, 'PACKAGE')}
            </Typography>
            <Paper
              className={classes.paper}
              style={{
                paddingBottom: 15,
                minHeight: 265,
                marginBottom: 25,
              }}
            >
              {areaData && (
                <TodayPackagesChart
                  areaId={match.params.id}
                  areaType={areaData.area_type}
                />
              )}
            </Paper>
          </>
        )}
        {layoutConfig[DashbaordSections.PERFORMANCE_CHART]
          ?.active && (
          <div style={{ marginBottom: 25 }}>
            <PackagesPerformanceStats
              title={`${configurationUtils.getPageTitle(
                true,
                'AREA',
              )} Performance`}
              areaId={match.params.id}
            />
          </div>
        )}
        <Typography
          variant='h6'
          component='h2'
          className={classes.titleSpacing}
        >
          {configurationUtils.getPageTitle(true, 'AREA')} Totals
        </Typography>
        {areaData && <AreaTotals areaId={match.params.id} />}
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...sectionPageBaseStyle,
    ...selectStyles,
    detailInfoButton: {
      margin: '-50px 0 0 auto',
      '& svg': {
        marginLeft: 5,
      },
    },
    dialogRoot: {
      overflow: 'hidden',
      width: '100%',
      maxWidth: 480,
    },
    inputPadding: {
      paddingLeft: '5px',
      paddingRigth: '5px',
    },
    muiSelect: {
      paddingLeft: 5,
      '& .MuiSelect-select:focus': {
        backgroundColor: 'transparent',
      },
    },
    dialogActions: {
      padding: 24,
    },
    nonMobileAlignRight: {
      [theme.breakpoints.down('xs')]: {
        marginTop: 20,
      },
      [theme.breakpoints.up('sm')]: {
        textAlign: 'right',
      },
    },
  })),
)(AreasComponent);
