import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Link,
  RouteComponentProps,
  useHistory,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { AxiosError } from 'axios';
import inRange from 'lodash/inRange';
import isInteger from 'lodash/isInteger';
import { withStyles, createStyles } from '@material-ui/core/styles';
import Layout from '../layout/Layout';
import {
  AlertBanner,
  CSButton,
  Typography,
} from '../primitives/index';
import { common } from '../../utils/strings';
import {
  Theme,
  Grid,
  Paper,
  Box,
  Menu,
  Fade,
  MenuItem,
} from '@material-ui/core';
import detailsPageStyles from '../commonStyles/detailsPage.style';
import PermissionsService from '../../services/Permissions.service';
import ErrorHandler from '../../utils/ErrorHandler';
import {
  LoadPoint,
  LoadPointDetails,
  StaffPermissionPermissionsEnum,
  SchemeDetails,
  Customer,
} from 'cloudsort-client';
import SingleDetail from '../primitives/singleDetail/SingleDetail';
import sanitizeHtml from 'sanitize-html';
import SchemesService from '../../services/Schemes.service';
import RoutesService from '../../services/Routes.service';
import LoadPointsService from '../../services/LoadPoints.service';
import { AuthRoutes } from '../../interfaces/routes';
import { humanReadableNull } from '../DetailsPagesFunctions';
import PaginatedList from '../paginatedList/PaginatedList';
import sectionPageBaseStyle from '../commonStyles/sectionPageBase.style';
import DeleteSchemeDialog from './deleteSchemeDialog/DeleteSchemeDialog';
import EditSchemeDialog from './editSchemeDialog/EditSchemeDialog';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import configurationUtils from '../../utils/configurationUtils';
import EditLoadBalancingDialog, {
  LoadPointDetailsWithDisplayName,
  normalizeLBValues,
} from '../areas/settings/edtLoadBalancingDialog/EditLoadBalancingDialog';
import ProgressBarItem from '../primitives/progressBarItem/ProgressBarItem';
import { MAX_PAGE_SIZE } from '../../services/utils/constants';

//Icons

import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Delete } from '@material-ui/icons';
import { CSSingleDetailMultiColumnContainer } from '../primitives/singleDetail/singleDetailMultiColumnContainer';
import CSBreadcrumbs from '../primitives/CSBreadcrumbs/CSBreadcrumbs';
import EphemeralStateService from '../../services/EphemeralState.service';
import CSSectionTitleSeparator from '../primitives/csSectionTitleSeparator/CSSectionTitleSeparator';
import { zipcodesObjectToArray } from './utils';
import debounce from 'lodash/debounce';
import CustomersService from '../../services/Customers.service';

interface IMatch extends Record<string, unknown> {
  params: {
    id: number;
  };
}

interface Props extends Omit<RouteComponentProps, 'match'> {
  match: IMatch;
  classes: { [key: string]: string };
}

const SchemeDetailsPage: React.FC<Props> = ({ match, classes }) => {
  const [showProgress, setShowProgress] = useState(false);
  const [error, setError] = useState<string>();
  const [schemeDetails, setSchemeDetails] = useState<SchemeDetails>();
  const [schemeRoutes, setSchemeRoutes] = useState<any>();
  const [schemeLoadPoints, setSchemeLoadPoints] =
    useState<LoadPointDetailsWithDisplayName[]>();
  const [loadPointsForBalanceDialog, setLoadPointsForBalanceDialog] =
    useState<LoadPointDetailsWithDisplayName[]>();
  const [isViewReady, setIsViewReady] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showManageZipCodes, setShowManageZipCodes] = useState(false);
  const [lastUpdateTimestamp, setLastUpdateTimestamp] = useState(
    Date.now(),
  );
  const schemeLabels = {
    singular: 'Scheme',
    plural: 'Schemes',
  };
  const schemeId = match.params.id;
  const history = useHistory();

  const activeSchemeLoadpoints = useMemo(
    () =>
      schemeLoadPoints?.filter((loadpoint) => loadpoint.is_active),
    [schemeLoadPoints],
  );

  const inactiveSchemeLoadpoints = useMemo(
    () =>
      schemeLoadPoints?.filter((loadpoint) => !loadpoint.is_active),
    [schemeLoadPoints],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadCustomerOptions = useCallback(
    debounce((inputValue: string, callback: any) => {
      CustomersService.getAll(
        undefined,
        inputValue,
        MAX_PAGE_SIZE,
        EphemeralStateService.getMyStationId(),
        EphemeralStateService.getMyOrgId(),
      )
        .then((data) => {
          callback(
            data.data.results.map((dataEl: Customer) => {
              return {
                value: dataEl.id,
                label: dataEl.identifier,
              };
            }),
          );
        })
        .catch((e) => {
          handleError(e as AxiosError);
        });
    }, 500),
    [],
  );

  const getAndSetSchemeDetailsData = async () => {
    try {
      const scheme = await SchemesService.getById(schemeId);
      setSchemeDetails(scheme.data);
      const routes = await RoutesService.getAll({
        scheme: schemeId,
      });
      setSchemeRoutes(routes.data);

      setLastUpdateTimestamp(Date.now());
    } catch (e) {
      handleError(e as AxiosError);
    }
  };

  const getAndSetSchemeLoadPointsData = async () => {
    const loadPoints = await LoadPointsService.getAll({
      scheme: schemeId,
      pageSize: MAX_PAGE_SIZE,
    });
    const results: LoadPointDetailsWithDisplayName[] =
      loadPoints?.data?.results
        .map((item: LoadPoint) => {
          return {
            ...item,
            display_name: `${item.area_name || ''} ${
              item.zone_name || ''
            } ${item.name || ''}`,
          };
        })
        .sort((a, b) => (a.display_name < b.display_name ? -1 : 1));
    setSchemeLoadPoints(normalizeLBValues(results));
  };

  useEffect(() => {
    getAndSetSchemeDetailsData();
    getAndSetSchemeLoadPointsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schemeId]);

  useEffect(() => {
    if (schemeDetails && schemeRoutes && schemeLoadPoints) {
      setIsViewReady(true);
    }
  }, [schemeDetails, schemeRoutes, schemeLoadPoints]);

  const [optionsMenuAnchorEl, setOptionsMenuAnchorEl] =
    React.useState<null | SVGElement>(null);

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e as AxiosError));
  };

  useEffect(() => {
    PermissionsService.redirectIfNoPermission(
      StaffPermissionPermissionsEnum.LOADPLAN_READ,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateSchemeDetailsData = async (newData: SchemeDetails) => {
    setShowProgress(true);
    try {
      const { data } = await SchemesService.update(newData);
      setSchemeDetails(data);
    } catch (e) {
      handleError(e as AxiosError);
    }
    setShowProgress(false);
  };

  const fetchZipCodes = async (
    pageIndex: number,
    rowsPerPage: number,
    filterByString: string,
  ) => {
    const flattenedZipcodes = zipcodesObjectToArray(
      schemeDetails?.zipcodes,
    );

    const filteredZipcodes = flattenedZipcodes.filter(
      (zipcodeGroup) =>
        !filterByString ||
        zipcodeGroup.zipcodes.some((zipcode) => {
          return zipcode.includes(filterByString);
        }) ||
        (zipcodeGroup.zipcodes.length === 2 &&
        isInteger(filterByString)
          ? inRange(
              Number(filterByString.padEnd(11, '0')),
              Number(zipcodeGroup.zipcodes[0].padEnd(11, '0')),
              Number(zipcodeGroup.zipcodes[1].padEnd(11, '0')),
            )
          : false),
    );

    const rows = filteredZipcodes?.map((zipcodeGroup) => {
      return (
        <Typography
          variant='body2'
          className={classes.marginBottom15}
        >
          {zipcodeGroup.zipcodes.join(' - ')} (
          {zipcodeGroup.country.toUpperCase()})
        </Typography>
      );
    });

    return {
      data: rows?.slice(
        pageIndex * rowsPerPage,
        (pageIndex + 1) * rowsPerPage,
      ) || [<></>],
      count: rows?.length || 0,
    };
  };

  const fetchRoutes = async (
    pageIndex: number,
    rowsPerPage: number,
    filterByString: string,
  ) => {
    try {
      const routes = await RoutesService.getAll({
        scheme: schemeId,
        search: filterByString,
        page: pageIndex + 1,
        pageSize: rowsPerPage,
      });

      const { count, results } = routes.data;

      const fetchedData = results.map((route, index) => {
        return (
          <>
            {index === 0 && (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography
                    color={{ color: 'grey', variant: 'A400' }}
                  >
                    ID
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    color={{ color: 'grey', variant: 'A400' }}
                  >
                    Name
                  </Typography>
                </Grid>
              </Grid>
            )}
            <Grid
              container
              spacing={2}
              className={classes.marginBottom15}
            >
              <Grid item xs={6}>
                <Typography>
                  <Link
                    className={classes.paginatedListLink}
                    to={`${AuthRoutes.ROUTE}/${route.id}`}
                  >
                    {route.id}
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>{route.name}</Typography>
              </Grid>
            </Grid>
          </>
        );
      });

      return { data: fetchedData, count: count, errors: undefined };
    } catch (e) {
      return { data: [], count: 0, errors: e };
    }
  };

  const onEditLoadPoints = async (loadpoints: LoadPoint[]) => {
    setShowProgress(true);

    try {
      const calls = loadpoints?.map((loadpoint) =>
        LoadPointsService.updateById(
          loadpoint.id!,
          loadpoint as unknown as LoadPointDetails,
        ),
      );
      if (calls) {
        await Promise.all(calls);
      }
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };
  return (
    <>
      <Helmet>
        <title>
          {`CloudSort -
${schemeLabels.singular} ${schemeDetails?.name || ''}`}
        </title>
      </Helmet>
      <Layout navCurrent='SCHEME'>
        {error && (
          <AlertBanner
            className={classes.banner}
            severity='error'
            alertTitle={'Error'}
            alertMsg={error}
          />
        )}
        {isViewReady ? (
          <>
            {showProgress && <ProgressIndicator />}
            <DeleteSchemeDialog
              schemeId={schemeId}
              schemeName={schemeDetails?.name || ''}
              isOpen={showDeleteDialog}
              onAfterClose={() => setShowDeleteDialog(false)}
              updateParent={() => {
                history.push(
                  `${AuthRoutes.LOADPLAN}/${schemeDetails?.load_plan}`,
                );
              }}
            />
            <EditSchemeDialog
              editSchemeData={schemeDetails!}
              isOpen={showManageZipCodes}
              onAfterClose={() => {
                setShowManageZipCodes(false);
              }}
              updateParent={() => {
                getAndSetSchemeDetailsData();
              }}
            />
            <Grid container className={classes.marginBottom20}>
              <Grid item xs={12}>
                <Box mb={5}>
                  <CSBreadcrumbs
                    breadcrumbs={[
                      {
                        label: schemeLabels.plural,
                        link:
                          AuthRoutes.SCHEME +
                          '?stationId=' +
                          EphemeralStateService.getMyStationId(),
                      },
                      {
                        label: schemeDetails?.name || '',
                        selected: true,
                      },
                    ]}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant={'h3'}>
                  {schemeDetails?.name}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                className={classes.nonMobileAlignRight}
              >
                {PermissionsService.hasPermission(
                  StaffPermissionPermissionsEnum.LOADPLAN_WRITE,
                ) && (
                  <CSButton
                    fullWidth={false}
                    variant='outlined'
                    color='secondary'
                    onClick={() => {
                      setShowDeleteDialog(true);
                    }}
                    startIcon={<Delete />}
                  >
                    Delete {schemeLabels.singular}
                  </CSButton>
                )}
              </Grid>
              <Grid item xs={12}>
                <CSSectionTitleSeparator
                  topMargin={15}
                  bottomMargin={15}
                />
              </Grid>
              <Grid item xs={12}>
                <CSSingleDetailMultiColumnContainer
                  columns={3}
                  elements={[
                    <SingleDetail
                      inline={true}
                      label={`${configurationUtils.getPageTitle(
                        true,
                        'LOADPLAN',
                      )} ID`}
                      value={
                        <Link
                          className={classes.paginatedListLink}
                          to={`${AuthRoutes.LOADPLAN}/${schemeDetails?.load_plan}`}
                        >
                          {humanReadableNull(
                            schemeDetails?.load_plan,
                          )}
                        </Link>
                      }
                    />,
                    <SingleDetail
                      inline={true}
                      label={'Owner'}
                      value={
                        schemeDetails?.owner_identifier ||
                        common.emptyValue
                      }
                      editLoadAsyncOptions={loadCustomerOptions}
                      hasEditPermission={PermissionsService.hasPermission(
                        StaffPermissionPermissionsEnum.LOADPLAN_WRITE,
                      )}
                      onEdit={(value) => {
                        if (
                          schemeDetails !== undefined &&
                          value !== schemeDetails?.owner
                        ) {
                          updateSchemeDetailsData({
                            ...schemeDetails,
                            owner: value,
                          });
                        }
                      }}
                    />,
                    <SingleDetail
                      inline={true}
                      label={configurationUtils.getPageTitle(
                        false,
                        'ROUTE',
                      )}
                      value={schemeRoutes?.count || common.emptyValue}
                    />,
                    <SingleDetail
                      inline={true}
                      label={'ZIP Codes'}
                      value={
                        schemeDetails?.zipcode_count ||
                        common.emptyValue
                      }
                    />,
                    <SingleDetail
                      inline={true}
                      label={'Sortation Parameter'}
                      value={sanitizeHtml(
                        schemeDetails?.sort_param ||
                          common.emptyValue,
                        {
                          allowedTags: [],
                        },
                      )}
                      onEdit={(value) => {
                        if (
                          schemeDetails !== undefined &&
                          value !== schemeDetails?.sort_param
                        ) {
                          updateSchemeDetailsData({
                            ...schemeDetails,
                            sort_param: value,
                          });
                        }
                      }}
                    />,
                    <SingleDetail
                      inline={true}
                      label={configurationUtils.getPageTitle(
                        false,
                        'STOP',
                      )}
                      value={
                        schemeDetails?.used_on?.length ||
                        common.emptyValue
                      }
                    />,
                    <SingleDetail
                      inline={true}
                      label={'Carrier'}
                      value={
                        schemeDetails?.fmc_full_name ||
                        common.emptyValue
                      }
                    />,
                    <SingleDetail
                      inline={true}
                      label={configurationUtils.getPageTitle(
                        false,
                        'LOADPOINT',
                      )}
                      value={
                        schemeLoadPoints?.length || common.emptyValue
                      }
                    />,
                    <SingleDetail
                      inline={true}
                      inlineWidth='auto'
                      label={'Date Modified'}
                      value={
                        schemeDetails?.modified_on
                          ? new Date(
                              schemeDetails.modified_on,
                            ).toLocaleString()
                          : common.emptyValue
                      }
                    />,
                  ]}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} className={classes.gridItem}>
                <Paper
                  className={classes.paper}
                  data-testid={'scheme-details-loadpoints-card'}
                >
                  <Grid
                    container
                    spacing={0}
                    className={classes.header}
                  >
                    <Grid item xs={9}>
                      <Typography
                        data-testid={
                          'scheme-details-loadpoints-card-title'
                        }
                        variant='h6'
                      >
                        {configurationUtils.getPageTitle(
                          false,
                          'LOADPOINT',
                        )}
                      </Typography>
                    </Grid>

                    <Grid item xs={3} className={classes.alignRight}>
                      <MoreVertIcon
                        data-testid={
                          'scheme-details-loadpoints-card-open-options-menu-btn'
                        }
                        onClick={(event) => {
                          setOptionsMenuAnchorEl(event.currentTarget);
                        }}
                        className={classes.optionsIcon}
                      />
                      <Menu
                        anchorEl={optionsMenuAnchorEl}
                        keepMounted
                        open={Boolean(optionsMenuAnchorEl)}
                        onClose={() => {
                          setOptionsMenuAnchorEl(null);
                        }}
                        TransitionComponent={Fade}
                      >
                        <MenuItem
                          onClick={() => {
                            setOptionsMenuAnchorEl(null);
                            setLoadPointsForBalanceDialog(
                              activeSchemeLoadpoints,
                            );
                          }}
                          data-testid={
                            'scheme-details-loadpoints-card-options-menu-item'
                          }
                        >
                          Change Load Balancing
                        </MenuItem>
                      </Menu>
                    </Grid>
                  </Grid>
                  {(activeSchemeLoadpoints?.length || 0) > 0 && (
                    <>
                      <Typography
                        variant='body2'
                        color={{ color: 'grey', variant: 'A400' }}
                        className={classes.marginBottom15}
                      >
                        Active
                      </Typography>
                      {activeSchemeLoadpoints?.map((item, index) => (
                        <ProgressBarItem
                          key={`load-balancing-${index}`}
                          isLast={
                            index ===
                            activeSchemeLoadpoints.length - 1
                          }
                          labelElement={
                            <Link
                              className={classes.paginatedListLink}
                              to={`${AuthRoutes.AREA}/${item.area}`}
                            >
                              {item.display_name}
                            </Link>
                          }
                          value={Math.round(
                            (item.load_balancing || 0) * 100 || 0,
                          )}
                        />
                      ))}
                    </>
                  )}
                  {(inactiveSchemeLoadpoints?.length || 0) > 0 && (
                    <>
                      <Typography
                        variant='body2'
                        color={{ color: 'grey', variant: 'A400' }}
                        className={classes.marginBottom15}
                      >
                        Inactive
                      </Typography>
                      {inactiveSchemeLoadpoints?.map(
                        (item, index) => (
                          <ProgressBarItem
                            isInactive
                            key={`load-balancing-${index}`}
                            isLast={
                              index ===
                              inactiveSchemeLoadpoints.length - 1
                            }
                            labelElement={
                              <Link
                                className={classes.paginatedListLink}
                                to={`${AuthRoutes.AREA}/${item.area}`}
                              >
                                {item.display_name}
                              </Link>
                            }
                            value={Math.round(
                              (item.load_balancing || 0) * 100 || 0,
                            )}
                          />
                        ),
                      )}
                    </>
                  )}
                </Paper>
              </Grid>
              <Grid item xs={12} sm={4} className={classes.gridItem}>
                <PaginatedList
                  title={configurationUtils.getPageTitle(
                    false,
                    'ROUTE',
                  )}
                  rowsPerPage={10}
                  lastUpdateTimestamp={lastUpdateTimestamp}
                  fetch={fetchRoutes}
                />
              </Grid>
              <Grid item xs={12} sm={4} className={classes.gridItem}>
                <PaginatedList
                  title='ZIP Codes'
                  rowsPerPage={10}
                  fetch={fetchZipCodes}
                  lastUpdateTimestamp={lastUpdateTimestamp}
                  optionsMenu={[
                    {
                      label: 'Manage ZIP Codes',
                      callback: () => {
                        setShowManageZipCodes(true);
                      },
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </>
        ) : (
          <ProgressIndicator />
        )}
        {loadPointsForBalanceDialog && (
          <EditLoadBalancingDialog
            open={!!loadPointsForBalanceDialog}
            loadpoints={loadPointsForBalanceDialog}
            onAfterDialogClose={() => {
              setLoadPointsForBalanceDialog(undefined);
            }}
            onEdit={async (loadpoints) => {
              //update each lp individually
              if (loadpoints) {
                await onEditLoadPoints(loadpoints);
                await getAndSetSchemeLoadPointsData();
                setLoadPointsForBalanceDialog(undefined);
              }
            }}
          />
        )}
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...sectionPageBaseStyle,
    nonMobileAlignRight: {
      [theme.breakpoints.up('sm')]: {
        textAlign: 'right',
      },
    },
    marginBottom15: {
      marginBottom: 15,
    },
    marginBottom20: {
      marginBottom: 20,
    },
    paginatedListLink: {
      color: theme.palette.tertiary?.main,
    },
    optionsButton: {
      padding: 0,
      minWidth: 0,
    },
    gridItem: {
      [theme.breakpoints.up('sm')]: {
        display: 'grid',
        minHeight: 300,
      },
    },
    header: {
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      marginBottom: 10,
    },
    alignRight: { textAlign: 'right' },
    optionsIcon: {
      color: theme.palette.text.primary,
      width: 20,
      cursor: 'pointer',
      marginRight: '-5px',
    },
  })),
)(SchemeDetailsPage);
