import { createStyles, Theme } from '@material-ui/core/styles';

export default createStyles((theme: Theme) => ({
  grow: {
    flexGrow: 1,
  },
  mainBox: { position: 'fixed', width: '100%', zIndex: 10 },
  secondaryBox: { display: 'flex' },
  logoContainer: {
    marginLeft: '20px',
    display: 'flex',
    alignItems: 'center',
  },
  appbar: {
    background: theme.palette.background.paper,
    boxShadow: 'none',
    [theme.breakpoints.down('sm')]: {
      background: theme.palette.quaternary?.main,
      color: theme.palette.quaternary?.contrastText,
      padding: '4px 0',
    },
  },
  toolbar: {
    justifyContent: 'space-between',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  headerLogo: {
    marginBottom: '-5px',
    paddingLeft: 5,
    fill: theme.palette.quaternary?.main,
    [theme.breakpoints.down('sm')]: {
      fill: theme.palette.quaternary?.contrastText,
    },
  },
  headerMenuToggle: {
    color: theme.palette.common.white,
    marginRight: '5px',
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  searchHoder: {
    display: 'flex',
    flexDirection: 'column',
    placeItems: 'flex-start',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: '9%',
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: '11%',
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: '12.5%',
    },
    [theme.breakpoints.up('xl')]: {
      marginLeft: '13%',
    },
  },
  sectionDesktop: {
    display: 'none',
    '& svg': {
      color: theme.palette.common.black,
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  accountNameInitials: {
    ...theme.typography.body2,
    width: 30,
    height: 30,
    lineHeight: '30px',
    marginRight: 6,
    overflow: 'none',
    borderRadius: '50%',
    textAlign: 'center',
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
  },
  borderLeft: {
    borderLeft: `${theme.borderWidth.border_sm} solid ${theme.palette.grey[100]}`,
    borderRadius: '0',
  },
  paddingButton: {
    padding: 21,
  },
  paddingMenu: {
    padding: '0 20px 0 10px',
  },
  timeHolder: {
    minWidth: 136,
    borderLeft: `${theme.borderWidth.border_sm} solid ${theme.palette.grey.A200}`,
    height: '100%',
    display: 'flex',
    placeItems: 'center',
    flexDirection: 'column',
    padding: '10px 0',
    cursor: 'pointer',
  },
  time: {
    textAlign: 'center',
  },
  GMTTime: {
    textAlign: 'center',
  },
  currentDate: {
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  notToday: {
    padding: '0 0 2px 0',
    '& svg': {
      width: 30,
      height: 'auto',
    },
  },
  weatherTooltip: {
    padding: '5px 50px',
    textAlign: 'center',
    '& p': {
      marginTop: '0',
    },
  },
  weatherMoreInfo: {
    marginTop: '5px',
    padding: '5px 10px',
  },
  exitProfileButton: {
    backgroundColor: theme.palette.quaternary?.main,
    color: theme.palette.quaternary?.contrastText,
    paddingLeft: '20px',
    paddingRight: '20px',
    '& svg': {
      color: theme.palette.quaternary?.contrastText,
      width: '30px',
      height: '30px',
      marginLeft: '20px',
    },
    '&:hover': {
      backgroundColor: theme.palette.quaternary?.dark,
    },
  },
}));
