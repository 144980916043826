import React, { useState } from 'react';
import { CSButton, Typography } from '../../primitives';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid, Theme } from '@material-ui/core';
import colors from '../../../utils/colors';
import detailsPageStyles from '../../commonStyles/detailsPage.style';
import { AuthRoutes } from '../../../interfaces/routes';
import { Column } from '../../../interfaces/components';
import PaginatedTable, {
  filterObj,
} from '../../paginatedTable/PaginatedTable';
import queryString from 'query-string';
import Layout from '../../layout/Layout';
import CustomersService from '../../../services/Customers.service';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import AddToPhotosOutlinedIcon from '@material-ui/icons/AddToPhotosOutlined';
import sectionPageBaseStyle from '../../commonStyles/sectionPageBase.style';
import AddCustomerDialog from './AddCustomerDialog';
import Navbar, { TabsEnum } from '../Navbar';
import { Customer } from 'cloudsort-client';
import { paginatedTablelocationProp } from '../../paginatedTable/paginatedTable.types';
import { configurationUrlParams } from '../utils';
import EphemeralStateService from '../../../services/EphemeralState.service';

interface Props {
  classes: { [key: string]: string };
  location: paginatedTablelocationProp;
}

const Customers: React.FC<Props> = ({ classes, location }) => {
  const urlParams = useParams<configurationUrlParams>();
  const [showAddCustomerModal, setShowAddCustomerModal] =
    useState(false);
  const [lastUpdated, setLastUpdated] = useState<string>(
    new Date().toISOString(),
  );

  const COLUMNS_CUSTOMERS: Column[] = [
    {
      id: 'identifier',
      label: 'Name',
      width: 'auto',
    },
    {
      id: 'assigned_organizations',
      label: 'Organization',
      width: 'auto',
      hide: !EphemeralStateService.getIsRoleSuperUser(),
    },
    {
      id: 'assigned_stations',
      label: 'Station',
      width: 'auto',
      hide: !EphemeralStateService.getIsRoleSuperUser(),
    },
    {
      id: 'id',
      label: 'ID',
      width: 'auto',
    },
  ];

  const fetchCustomers = async (
    pageIndex: number,
    rowsPerPage: number,
    filterOptions?: filterObj[],
    filterByString?: string,
    sortedBy?: string,
  ) => {
    const res = await CustomersService.getAll(
      pageIndex,
      filterByString,
      rowsPerPage,
      Number(urlParams.stationId),
      Number(urlParams.orgId),
    );
    const newCustomers = res.data.results.map(
      (customer: Customer) => {
        const newOrganizations =
          customer.assigned_organizations?.reduce(
            (accumulator, org, index, organizations) => {
              accumulator += `${accumulator.length > 0 ? ',' : ''} ${
                org.name
              }`;
              return accumulator;
            },
            '',
          );
        const newStations = customer.assigned_stations?.reduce(
          (accumulator, station, index, stations) => {
            accumulator += `${accumulator.length > 0 ? ',' : ''} ${
              station.name
            }`;
            return accumulator;
          },
          '',
        );

        return {
          ...customer,
          assigned_organizations: newOrganizations,
          assigned_stations: newStations,
        };
      },
    );
    (res.data.results as Customer &
      {
        assigned_organizations?: string;
        assigned_stations?: string;
      }[]) = newCustomers;
    return res;
  };

  return (
    <Layout navCurrent='CONFIGURATION'>
      <Helmet>
        <title>
          {`CloudSort - Customers ${
            queryString.parse(location.search)['page']
              ? '- Page ' + queryString.parse(location.search)['page']
              : ''
          }`}
        </title>
      </Helmet>
      <AddCustomerDialog
        isOpen={showAddCustomerModal}
        onAddUser={() => {
          setLastUpdated(new Date().toISOString());
          setShowAddCustomerModal(false);
        }}
        onCancel={() => {
          setShowAddCustomerModal(false);
        }}
      />
      <Navbar
        hasUnsavedChanges={false}
        activeTab={TabsEnum.CUSTOMERS}
        onSaveCallback={() => {}}
        onResetDataCallback={() => {}}
      />
      <Grid container className={classes.header}>
        <Grid item xs={12} sm={6}>
          <Typography className={classes.title} variant={'h3'}>
            Customers List
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
          <CSButton
            variant='contained'
            color={{ buttonColor: 'secondary', iconColor: 'primary' }}
            onClick={() => {
              setShowAddCustomerModal(true);
            }}
            startIcon={<AddToPhotosOutlinedIcon />}
          >
            Add Customer
          </CSButton>
        </Grid>
      </Grid>
      <PaginatedTable
        key={lastUpdated}
        title={''}
        columns={COLUMNS_CUSTOMERS}
        dataTestIdPrefix={'customers'}
        filterByString={true}
        filterByStringPlaceholder={'Search by Customer name'}
        fetch={fetchCustomers}
        rowsLoadDetailPages={true}
        detailsPageBasePath={`${AuthRoutes.CONFIGURATION}${
          urlParams.orgId ? '/organization/' + urlParams.orgId : ''
        }${
          urlParams.stationId ? '/station/' + urlParams.stationId : ''
        }/customers`}
      />
    </Layout>
  );
};
export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...sectionPageBaseStyle,
    buttonFilled: {
      background: colors.darkGold,
      color: colors.white,
      border: `2px solid ${colors.darkGold}`,
      marginBottom: 10,
      height: 42,
      '& svg': {
        marginRight: 10,
      },
      '& .MuiButton-label': {
        textTransform: 'initial',
      },
    },
  })),
)(Customers);
