import { Theme, makeStyles } from '@material-ui/core';

export default () =>
  makeStyles(
    (theme: Theme) => ({
      separator: {
        position: 'absolute',
        backgroundColor: theme.palette.grey[300],
        border: 'none',
        height: '100%',
        width: 1,
        left: '50%',
        top: '-6px',
      },
      activeEventTypeMarker: {
        fontSize: '3em',
        position: 'absolute',
        top: -18,
        left: 'calc(50% - 20px)',
        color: theme.palette.tertiary?.main,
      },
      paperContainer: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        minHeight: 105,
        position: 'relative',
        width: '100%',
        borderRadius: theme?.shape.borderRadius,
        backgroundColor: theme?.palette.background.paper,
        padding: '15px 30px',
        boxShadow: theme?.shadows[2],
      },
      textContent: {
        textAlign: 'center',
      },
      subContentSeparation: {
        marginBottom: '8px',
      },
    }),
    { name: 'CSDualTimeContainer' },
  )();
