import * as React from 'react';
import styles from './CSSectionTitleSeparator.styles';
import { SectionTitleSeparatorProps } from './CSSectionTitleSeparatorTypes';

const SectionTitleSeparator: React.FC<SectionTitleSeparatorProps> = ({
  borderWidth,
  bottomMargin,
  topMargin,
  ...others
}) => {
  const classes = styles(borderWidth, bottomMargin, topMargin)();
  return <hr className={classes?.root} {...others} />;
};

export default SectionTitleSeparator;
