import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import { Transition } from '../confirmationDialog/ConfirmationDialog';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import { CSButton, CSTextField } from '../primitives';

// Services
import AuthService from '../../services/Auth.service';
import { DialogTitle } from '@material-ui/core';
import { ObtainToken } from 'cloudsort-client';

//Icons
import CSDialogTitleWithIcon from '../primitives/csDialogTitleWithIcon/CSDialogTitleWithIcon';
import { DialogIcons } from '../primitives/csDialogTitleWithIcon/CSDialogTitleWithIconTypes';
import CSDialogAlert from '../primitives/csDialogAlert/CSDialogAlert';

interface Props {
  classes: { [key: string]: string };
  onAfterClose: () => void;
  isOpen: boolean;
  callbackFn?: () => any;
}

const ReAuthDialog: React.FC<Props> = ({
  isOpen,
  classes,
  callbackFn,
  onAfterClose,
}) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState<string>();
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [authState, setAuthState] = useState<ObtainToken>({
    email: AuthService.getMyEmail(),
    password: '',
    device_id: 'web',
  });

  const handleClose = () => {
    onAfterClose();
  };

  const onButtonClick = async () => {
    setShowProgress(true);
    try {
      await AuthService.logIn(authState);
      callbackFn && callbackFn();
    } catch (e) {
      setError(`The password you've entered is not correct`);
    } finally {
      setShowProgress(false);
    }
  };

  useEffect(() => {
    setOpen(isOpen);
    setAuthState((prevState) => {
      return {
        ...prevState,
        password: '',
      };
    });
  }, [isOpen]);

  return (
    <div>
      {showProgress && <ProgressIndicator />}
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
      >
        <DialogTitle>
          <CSDialogTitleWithIcon
            icon={DialogIcons.LOCK}
            title='Please enter your password to continue'
            subtitle='You`re attempting to modify sensitive data.'
          />
        </DialogTitle>
        <DialogContent>
          {error && (
            <CSDialogAlert
              alertMessage={error}
              classes={{ root: classes.marginBottom20 }}
            />
          )}
          <CSTextField
            inputProps={{
              'data-testid': 'reauth-password',
            }}
            containerSize='fullHorizontal'
            required
            name='password'
            label='Your Password'
            type='password'
            autoComplete='password'
            value={authState.password}
            onChange={(e: React.BaseSyntheticEvent) => {
              e.persist();
              if (error) {
                setError('');
              }
              const { value } = e.target;
              setAuthState((prevState) => {
                return {
                  ...prevState,
                  password: value,
                };
              });
            }}
          />
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <CSButton onClick={handleClose} color='secondary'>
            CANCEL
          </CSButton>
          <CSButton onClick={onButtonClick} color='secondary'>
            CONTINUE
          </CSButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withStyles(
  createStyles(() => ({
    marginBottom20: {
      marginBottom: '20px!important',
    },
    dialogActions: {
      padding: 24,
    },
  })),
)(ReAuthDialog);
