import React, { useState, useEffect } from 'react';
import {
  withStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles';
import Layout from '../layout/Layout';
import { AuthRoutes, ModulesKeys } from '../../interfaces/routes';
import PaginatedTable, {
  filterObj,
} from '../paginatedTable/PaginatedTable';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import queryString from 'query-string';
import detailsPageStyles from '../commonStyles/detailsPage.style';
import ErrorHandler from '../../utils/ErrorHandler';
import AddStaffDialog from './AddStaffDialog';
import { renderDuration } from '../DetailsPagesFunctions';
import configurationUtils from '../../utils/configurationUtils';
import { Grid, Typography, useTheme } from '@material-ui/core';
import sectionPageBaseStyle from '../commonStyles/sectionPageBase.style';
import { common } from '../../utils/strings';

// Types
import { RouteComponentProps } from 'react-router-dom';
import { Column } from '../../interfaces/components';
import {
  Staff,
  StaffPermissionPermissionsEnum,
} from 'cloudsort-client';
import { AxiosError } from 'axios';

// Icons
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import FilterListIcon from '@material-ui/icons/FilterList';

// Services

import StaffService from '../../services/Staff.service';
import PermissionsService from '../../services/Permissions.service';
import { Helmet } from 'react-helmet';
import EphemeralStateService from '../../services/EphemeralState.service';
import { CSButton } from '../primitives';
import CSSectionTitleSeparator from '../primitives/csSectionTitleSeparator/CSSectionTitleSeparator';
import clsx from 'clsx';
import FiltersDrawer, {
  SelectedFilters,
} from '../filtersDrawer/FiltersDrawer';
import CSHorizontalFilterBadgesGroup from '../primitives/csHorizontalFilterBadgesGroup/csHorizontalFilterBadgesGroup';
import { switcher } from 'rambdax';
import { useAppDispatch } from '../../redux/store';
import { setLastVisitedModule } from '../../redux/slices/navigationSlice';

export enum ROLE_LABELS {
  BLANK = 'Blank',
  OPERATOR_I = 'Operator I',
  OPERATOR_II = 'Operator II',
  OPERATOR_III = 'Operator III',
  STATION_ADMIN = 'Station Admin',
  ORG_ADMIN = 'Organization Admin',
  SUPER_USER = 'Super User',
}

export type AVAILABLE_ROLE_TYPES =
  | undefined
  | 'OPERATOR_I'
  | 'OPERATOR_II'
  | 'OPERATOR_III'
  | 'STATION_ADMIN'
  | 'ORG_ADMIN'
  | 'SUPER_USER';

export enum OT_PERMISSIONS_LABELS {
  OPERATORTOOL_SORTATION = 'Sortation',
  OPERATORTOOL_MISSORT = 'Missorting',
  OPERATORTOOL_LOADING = 'Loading',
  OPERATORTOOL_MISLOAD = 'Misloading',
  OPERATORTOOL_MISMOVE = 'Mismoving',
  OPERATORTOOL_MEASURING = 'Measuring',
  OPERATORTOOL_CONTAINERS = 'Container Operations',
  OPERATORTOOL_LOADS = 'Load Operations',
  OPERATORTOOL_TROUBLESHOOTING = 'Troubleshooting Mode',
  OPERATORTOOL_RELABEL = 'Relabel Mode',
  OPERATORTOOL_RETURNING = 'Return Mode',
  OPERATORTOOL_INBOUNDLOADS = 'Inbound Loads',
}

export enum OTHER_PERMISSIONS_LABELS {
  TECHNICAL_ASSETS_READ = 'Read technical assets',
  WEBHOOK_READ = 'Read data from webhooks',
  WEBHOOK_WRITE = 'Write data to webhooks',
}

interface Props {
  classes: { [key: string]: string };
}

const StationStaffComponent: React.FC<
  RouteComponentProps & Props
> = ({ classes, location }) => {
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [showFiltersDrawer, setShowFiltersDrawer] = useState(false);
  const [selectedFilters, setSelectedFilters] =
    useState<SelectedFilters>();
  const [filterToRemove, setFilterToRemove] = useState<{
    filterKey: string;
    filterValue: string | number;
  }>();
  const [showAddDialog, setShowAddDialog] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [disabledAdd, setDisabledAdd] = useState<boolean>(true);

  const [lastUpdated, setLastUpdated] = useState<string>(
    new Date().toISOString(),
  );
  const theme = useTheme();
  // Get labels on each render cycle
  const COLUMNS: Column[] = [
    {
      id: 'full_name',
      label: 'Name',
      width: 'auto',
      align: 'left',
      showOnMobile: true,
    },
    {
      id: 'role',
      label: 'Role',
      width: 'auto',
      align: 'left',
      showOnMobile: true,
    },
    {
      id: 'id',
      label: 'ID',
      width: 100,
      align: 'center',
    },

    {
      id: 'device_id',
      label: configurationUtils.getPageTitle(true, 'DEVICE'),
      width: 'auto',
      align: 'left',
    },
    {
      id: 'active_since',
      label: 'Uptime',
      width: 'auto',
      align: 'left',
    },
    {
      id: 'status',
      label: 'Status',
      width: 'auto',
      align: 'left',
    },
    {
      id: 'seconds_per_package',
      label: 'SPP',
      width: 'auto',
      align: 'left',
    },
    {
      id: 'daily_totals',
      label: 'Daily Totals',
      width: 'auto',
      align: 'left',
      showOnMobile: true,
    },
  ];

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e as AxiosError));
  };

  const dispatch = useAppDispatch();

  const onAddStaff = async (
    staffParams: Staff,
    onAfterDialogClose: () => void,
    updateUser: boolean,
  ) => {
    setError(undefined);
    setShowProgress(true);
    try {
      if (updateUser) {
        staffParams.first_name = undefined;
        staffParams.last_name = undefined;
        staffParams.email = undefined;
        await StaffService.update(staffParams.id!, staffParams);
      } else {
        if (staffParams.email === '') staffParams.email = undefined;
        await StaffService.create(staffParams);
      }

      setLastUpdated(new Date().toISOString());
      onAfterDialogClose();
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  const getStaffFilterIsActive = (
    selectedFilters: SelectedFilters | undefined,
  ) =>
    switcher<boolean | undefined>(
      selectedFilters?.staffStatus?.values[0]?.id,
    )
      .is('active', true)
      .is('inactive', false)
      .default(undefined);

  const fetchStaffData = async (
    pageIndex: number,
    rowsPerPage: number,
    filterOptions?: filterObj[],
    filterByString?: string,
    sortedBy?: string,
  ) => {
    const sortedByValue = sortedBy?.replace('status', 'active');
    const res = await StaffService.getAll({
      page: pageIndex,
      pageSize: rowsPerPage,
      sortBy: sortedByValue as
        | 'active'
        | 'seconds_per_package'
        | '-active'
        | '-seconds_per_package'
        | undefined,
      active: getStaffFilterIsActive(selectedFilters),
      roleType: selectedFilters?.roleType?.values[0]
        ?.id as AVAILABLE_ROLE_TYPES,
      stationId: EphemeralStateService.getMyStationId(),
    });

    res.data.results.forEach((staff: any) => {
      staff.device_id = staff.device_id || common.emptyValue;
      staff.active_since =
        staff?.active_since && staff?.active
          ? renderDuration(
              (new Date().getTime() -
                new Date(staff?.active_since!).getTime()) /
                1000,
            )
          : common.emptyValue;
      staff.status = staff.active
        ? `<span style="color:${theme.palette.tertiary?.main}">Active</span>`
        : 'Inactive';
      staff.daily_totals = staff.daily_totals || common.emptyValue;
      staff.seconds_per_package = staff.seconds_per_package
        ? renderDuration(staff.seconds_per_package)
        : common.emptyValue;
      staff.role = staff.permission_data
        ? ROLE_LABELS[
            staff.permission_data
              .role_type as keyof typeof ROLE_LABELS
          ] ?? common.emptyValue
        : common.emptyValue;
    });
    setDisabledAdd(false);
    return res;
  };

  const getDrawerFilters = (filters: SelectedFilters) => {
    setSelectedFilters(filters);
    setLastUpdated(new Date().toISOString());
  };

  useEffect(() => {
    PermissionsService.redirectIfNoPermission(
      StaffPermissionPermissionsEnum.USER_READ,
    );

    dispatch(
      setLastVisitedModule({
        module: ModulesKeys.STAFF,
        permission: StaffPermissionPermissionsEnum.USER_READ,
      }),
    );
  }, [dispatch]);

  const title = configurationUtils.getPageTitle(false, 'STAFF');
  return (
    <>
      <Helmet>
        <title>
          {`CloudSort - ${title} ${
            queryString.parse(location.search)['page']
              ? '- Page ' + queryString.parse(location.search)['page']
              : ''
          }`}
        </title>
      </Helmet>
      <Layout navCurrent='STAFF'>
        <FiltersDrawer
          isOpen={showFiltersDrawer}
          onAfterClose={() => {
            setShowFiltersDrawer(false);
          }}
          getFilters={getDrawerFilters}
          removeFilter={filterToRemove}
        />
        {showProgress && <ProgressIndicator />}
        <AddStaffDialog
          isOpen={showAddDialog}
          onAfterDialogClose={() => {
            setShowAddDialog(false);
          }}
          onAddStaff={onAddStaff}
          error={error}
        />
        {PermissionsService.hasPermission(
          StaffPermissionPermissionsEnum.USER_WRITE,
        ) && (
          <Grid container className={classes.header}>
            <Grid item xs={12} sm={6}>
              <Typography variant='h3' component='h2'>
                {title}
              </Typography>
            </Grid>
            <Grid
              item
              sm={6}
              xs={12}
              className={classes.nonMobileAlignRight}
            >
              <CSButton
                data-testid={'station-staff-add-button'}
                className={classes.sectionButton}
                variant='contained'
                color={{
                  buttonColor: 'secondary',
                  iconColor: 'primary',
                }}
                fullWidth={false}
                disabled={!!error || disabledAdd}
                onClick={(e) => {
                  e.preventDefault();
                  setShowAddDialog(true);
                }}
                startIcon={<AddBoxOutlinedIcon />}
              >
                Add {configurationUtils.getPageTitle(true, 'STAFF')}
              </CSButton>
              <CSButton
                variant='outlined'
                color='secondary'
                className={clsx(
                  classes.sectionButton,
                  classes.headerFilterButton,
                )}
                onClick={() => {
                  setShowFiltersDrawer(true);
                }}
              >
                <FilterListIcon />
              </CSButton>
            </Grid>
            <CSSectionTitleSeparator
              topMargin={10}
              bottomMargin={24}
            />
            <CSHorizontalFilterBadgesGroup
              selectedFilters={selectedFilters}
              onRemoveFilter={(toRemove) =>
                setFilterToRemove(toRemove)
              }
            />
          </Grid>
        )}

        <PaginatedTable
          key={lastUpdated}
          title=''
          columns={COLUMNS}
          dataTestIdPrefix={'station-staff'}
          fetch={fetchStaffData}
          rowsLoadDetailPages={true}
          detailsPageBasePath={AuthRoutes.STAFF}
          sortableBy={['seconds_per_package', 'status']}
          defaultSort={
            (queryString.parse(location.search)[
              'sortBy'
            ] as string) || undefined
          }
        />
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...sectionPageBaseStyle,
    nonMobileAlignRight: {
      [theme.breakpoints.up('xs')]: {
        textAlign: 'right',
      },
    },
  })),
)(StationStaffComponent);
