import { Column } from '../interfaces/components';

export const COLUMNS_PACKAGES: Column[] = [
  {
    id: 'id',
    label: '#',
    width: 50,
    align: 'left',
  },
  {
    id: 'zipcode',
    label: 'ZIP Code',
    width: 150,
    align: 'left',
  },
  {
    id: 'owner_full_name',
    label: 'Owner',
    width: 150,
    align: 'left',
  },
  {
    id: 'fmc_full_name',
    label: 'Carrier',
    width: 150,
    align: 'left',
  },
  {
    id: 'process_step_time',
    label: 'Dwell time',
    align: 'left',
    width: 150,
  },
];

export const COLUMNS_DEVICES: Column[] = [
  {
    id: 'device_id',
    label: 'ID',
    width: 150,
    align: 'left',
  },
  {
    id: 'active',
    label: 'Status',
    width: 100,
    align: 'left',
  },
  {
    id: 'version_id',
    label: 'Version',
    width: 'auto',
    align: 'left',
  },
  {
    id: 'last_used',
    label: 'Last Used',
    width: 'auto',
    align: 'left',
  },
];
