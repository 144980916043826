import React, { useState, useEffect } from 'react';
import browserHistory from '../utils/browserHistory';
import { Route, Redirect } from 'react-router-dom';
import Auth from '../services/Auth.service';
import { NonAuthRoutes, AuthRoutes } from '../interfaces/routes';

const PrivateRoute: React.FC<any> = ({
  component: Component,
  ...rest
}) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(
    Auth.isLoggedIn(),
  );

  const refreshToken: (location: string) => Promise<void> = async (
    location: string,
  ) => {
    const isTokenRefreshed = await Auth.refreshToken();
    if (isTokenRefreshed) {
      browserHistory.push(
        location !== NonAuthRoutes.LOGIN
          ? location
          : AuthRoutes.DASHBOARD,
      );
    }
    setIsLoggedIn(Auth.isLoggedIn());
  };

  useEffect(() => {
    if (!isLoggedIn && Auth.hasRefreshToken()) {
      const { pathname, search } = browserHistory.location;
      refreshToken(`${pathname}${search}`);
    }
  });

  return (
    <Route
      {...rest}
      render={(props) =>
        !isLoggedIn && !Auth.hasRefreshToken() ? (
          <Redirect
            to={{
              pathname: NonAuthRoutes.LOGIN,
              state: { from: props.location },
            }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default PrivateRoute;
