import { FilledInput, Input, OutlinedInput } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import React from 'react';
import { getClearButtonStyles } from './CSTextField.styles';
import {
  ExtendedVariants,
  MaterialInputComponent,
  MaterialInputConfiguration,
  VariantSelector,
} from './CSTextFieldTypes';

export const getTextFieldComponentVariant = (
  variant?: ExtendedVariants,
): MaterialInputComponent =>
  selectVariantProperty(
    {
      standard: Input,
      filled: FilledInput,
      outlined: OutlinedInput,
      filledOutlined: OutlinedInput,
      standardOutlined: OutlinedInput,
    },
    variant,
  ) as MaterialInputComponent;

export const selectVariantProperty = (
  valuesToReturn: VariantSelector,
  variant?: ExtendedVariants,
):
  | MaterialInputComponent
  | MaterialInputConfiguration
  | Record<string, unknown> => {
  switch (variant) {
    case 'standard':
      return valuesToReturn.standard;
    case 'filled':
      return valuesToReturn.filled;
    case 'outlined':
      return valuesToReturn.outlined;
    case 'standard-outlined':
      return valuesToReturn.standardOutlined;
    case 'filled-outlined':
      return valuesToReturn.filledOutlined;
    default:
      return valuesToReturn.standardOutlined;
  }
};

export const materialTextFieldConfiguration = (
  variant?: ExtendedVariants,
): MaterialInputConfiguration =>
  selectVariantProperty(
    {
      standard: {
        formControlVariant: 'standard',
        formLabelVariant: 'standard',
        notched: undefined,
      },
      filled: {
        formControlVariant: 'filled',
        formLabelVariant: 'filled',
        notched: undefined,
      },
      outlined: {
        formControlVariant: 'outlined',
        formLabelVariant: 'outlined',
        notched: undefined,
      },
      filledOutlined: {
        formControlVariant: 'outlined',
        formLabelVariant: 'filled',
        notched: false,
      },
      standardOutlined: {
        formControlVariant: 'outlined',
        formLabelVariant: 'filled',
        notched: false,
      },
    },
    variant,
  ) as MaterialInputConfiguration;

export const getEndAdornment = (
  value: string | undefined,
  onClear: () => void,
  EndAdornment?: React.ReactNode,
  hideClear: boolean = false,
) => {
  const classes = getClearButtonStyles();
  /*
    Clear button is assigned a role of a button,
    however for correct accessibility a button should be focusable,
    we are disabling the focusing of the clear button because it
    creates a weird user experience,
    handicapped users can still erase the input with DEL or backspace
  */
  return (
    <div
      className={
        EndAdornment && !hideClear
          ? classes.multiAdornmentBox
          : classes.singleAdornmentBox
      }
    >
      {EndAdornment}
      {!hideClear && !!value && (
        <div
          className={classes.cursorPointer}
          tabIndex={-1}
          onClick={onClear}
          role='button'
          aria-label='clear'
        >
          <ClearIcon />
        </div>
      )}
    </div>
  );
};
