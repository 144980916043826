import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { CSButton, Typography } from '../primitives';
import ErrorHandler from '../../utils/ErrorHandler';
import { common } from '../../utils/strings';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Transition } from '../confirmationDialog/ConfirmationDialog';
import { CSVLink } from 'react-csv';
import configurationUtils from '../../utils/configurationUtils';
import selectStyles from '../select/select.styles';
import { Grid, Box, Paper, Theme } from '@material-ui/core';
import sectionPageBaseStyle from '../commonStyles/sectionPageBase.style';
import detailsPageStyles from '../commonStyles/detailsPage.style';
import { AxiosError } from 'axios';
import { Customer, InboundManifestResponse } from 'cloudsort-client';
import { noOptionsMessage } from '../asyncSelect/utils';
import { TypeAheadItem } from '../../interfaces/components';
import debounce from 'lodash/debounce';
import CustomersService from '../../services/Customers.service';
import ManifestsService from '../../services/Manifests.service';
import { MAX_PAGE_SIZE } from '../../services/utils/constants';
import EphemeralStateService from '../../services/EphemeralState.service';
import {
  FileCopyOutlined,
  CloudUploadOutlined,
} from '@material-ui/icons';
import CSAsyncSelect from '../primitives/csAsyncSelect/CSAsyncSelect';
import CSDialogAlert from '../primitives/csDialogAlert/CSDialogAlert';

interface Props {
  classes: { [key: string]: string };
  isOpen: boolean;
  onAfterClose: () => void;
  updateParent: () => void;
}

enum CreateInboundManifestSteps {
  OWNER_SELECTION = 'Owner selection',
  FILE_SELECTION = 'File selection',
  RESULTS = 'Results screen',
}

const CreateInboundManifestDialog: React.FC<Props> = ({
  classes,
  isOpen,
  onAfterClose,
  updateParent,
}) => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState<string>();
  const [currentStep, setCurrentStep] =
    useState<CreateInboundManifestSteps>(
      CreateInboundManifestSteps.OWNER_SELECTION,
    );
  const [selectedOwner, setSelectedOwner] =
    useState<TypeAheadItem<number>>();
  //Manifest upload
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [selectedFileLabel, setSelectedFileLabel] = useState<string>(
    'Drag and Drop Your File Here',
  );
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const [uploadResults, setUploadResults] =
    useState<InboundManifestResponse>();
  const [selectedFile, setSelectedFile] = useState<any>();

  const manifestLabels = {
    singular: configurationUtils.getPageTitle(true, 'MANIFEST'),
    plural: configurationUtils.getPageTitle(false, 'MANIFEST'),
  };

  useEffect(() => {
    setOpen(isOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e as AxiosError));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadCustomerOptions = useCallback(
    debounce((inputValue: string, callback: any) => {
      CustomersService.getAll(
        undefined,
        inputValue,
        MAX_PAGE_SIZE,
        EphemeralStateService.getMyStationId(),
        EphemeralStateService.getMyOrgId(),
      )
        .then((data) => {
          callback(
            data.data.results.map((dataEl: Customer) => {
              return {
                value: dataEl.id,
                label: dataEl.identifier,
              };
            }),
          );
        })
        .catch((e) => {
          handleError(e as AxiosError);
        });
    }, 500),
    [],
  );

  const handleClose = () => {
    setIsUploading(false);
    setSelectedFile(undefined);
    setSelectedFileLabel('Drag and Drop Your File Here');
    setError(undefined);
    setSelectedOwner(undefined);
    onAfterClose();
    setCurrentStep(CreateInboundManifestSteps.OWNER_SELECTION);
  };

  const onFileChangeHandler = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    if (!event.target.files) {
      return;
    }
    const file = event.target.files[0];
    if (!file.name.includes('csv')) {
      setSelectedFileLabel('Please select CSV file.');
    } else {
      setSelectedFileLabel(file.name);
      setSelectedFile(file);
    }
  };

  const onFileDroppedHandler = (
    event: React.DragEvent<HTMLFormElement>,
  ) => {
    if (!event?.dataTransfer?.files) {
      return;
    }
    const file = event.dataTransfer.files[0];
    if (!file.name.includes('csv')) {
      setSelectedFileLabel('Please select CSV file.');
      setTimeout(() => {
        setSelectedFileLabel('Drag and Drop Your File Here');
      }, 2000);
    } else {
      onFileChangeHandler({
        target: { files: event.dataTransfer.files },
      } as ChangeEvent<HTMLInputElement>);
    }
  };

  const onUploadProgress = (progressEvent: any) => {
    const percentCompleted = Math.floor(
      (progressEvent.loaded * 100) / progressEvent.total,
    );
    setUploadProgress(percentCompleted);
  };

  const uploadManifestFile = async () => {
    setError(undefined);
    try {
      setIsUploading(true);
      const response = await ManifestsService.importInboundManifest({
        owner: selectedOwner!.value,
        file: selectedFile,
        options: {
          onUploadProgress,
        },
      });
      setUploadResults(response.data);
      setIsUploading(false);
    } catch (e) {
      setSelectedFile(undefined);
      handleError(e as AxiosError);
    }
  };

  const renderSuccessfulImportStat = (
    label: string,
    value?: number | string | null,
  ) => {
    return (
      <>
        <Grid
          item
          container
          xs={12}
          className={classes.marginBottom10}
        >
          <Grid item container xs={8} direction={'column'}>
            <Typography component='p' variant='body2'>
              {label}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              component='p'
              variant='body1'
              color={{
                color: 'grey',
                variant: 'A400',
              }}
              align='right'
            >
              {value || common.emptyValue}
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  };

  const renderFileSelectionForm = () => {
    return (
      <form
        data-testid={'import-manifest:drop-zone'}
        className={classes.fileDropZone}
        onDragOver={(e: React.DragEvent<HTMLFormElement>) => {
          e.preventDefault();
        }}
        onDragLeave={(e: React.DragEvent<HTMLFormElement>) => {
          e.preventDefault();
        }}
        onDrop={(e: React.DragEvent<HTMLFormElement>) => {
          e.preventDefault();
          onFileDroppedHandler(e);
        }}
      >
        <input
          accept='.csv'
          style={{ display: 'none' }}
          id='csv-file-selection'
          type='file'
          onChange={onFileChangeHandler}
        />
        <p>{selectedFileLabel}</p>
        <label htmlFor='csv-file-selection'>
          <CSButton
            component='span'
            variant='contained'
            color={{
              buttonColor: 'secondary',
              iconColor: 'primary',
            }}
            startIcon={<FileCopyOutlined />}
          >
            Browse File
          </CSButton>
        </label>
      </form>
    );
  };
  const renderUploadingProgress = () => {
    return (
      <Paper
        elevation={2}
        className={classes.fileContainer}
        data-testid={'imported-manifests-info'}
      >
        <Grid container>
          <Grid item xs={2} className={classes.padding10}>
            <img
              src={`${
                process.env.REACT_APP_BASENAME || ''
              }/icons/csv-file.png`}
              alt={'CSV'}
            />
          </Grid>
          <Grid item xs={10} className={classes.padding10}>
            <p className={classes.uploadFileInfo}>
              <span className={classes.uploadFilename}>
                {selectedFileLabel}
              </span>
              <span className={classes.uploadPercentage}>
                {uploadProgress}%
              </span>
            </p>
            <div className={classes.uploadProgressBar}>
              <div
                className={classes.uploadProgressBarInner}
                style={{ width: `${uploadProgress}%` }}
              ></div>
            </div>
          </Grid>
        </Grid>
      </Paper>
    );
  };

  const capitalize = (word: string = common.emptyValue) => {
    word = word.trim();
    if (word) {
      return word[0].toUpperCase() + word.toLowerCase().slice(1);
    }
    return common.emptyValue;
  };

  const renderImportResults = () => {
    let errorsCount = 0;
    let warningsCount = 0;
    for (let issue of uploadResults?.issues || []) {
      if (issue.level === 'error') {
        errorsCount += 1;
      }
      if (issue.level === 'warning') {
        warningsCount += 1;
      }
    }
    return (
      <>
        <Typography
          component='p'
          variant='h6'
          align='left'
          className={classes.newManifestLabel}
        >
          New Manifest
        </Typography>
        <Grid container data-testid='imported-file-results'>
          {renderSuccessfulImportStat(
            'Manifest Id',
            uploadResults?.id,
          )}
          {renderSuccessfulImportStat(
            'External ID',
            uploadResults?.external_id,
          )}
          {renderSuccessfulImportStat(
            'Owner',
            capitalize(uploadResults?.owner_full_name),
          )}
          {renderSuccessfulImportStat(
            'Status',
            capitalize(uploadResults?.status),
          )}
          {renderSuccessfulImportStat(
            'Total containers',
            uploadResults?.total_containers,
          )}
          {renderSuccessfulImportStat(
            'Total containerized packages',
            uploadResults?.total_containerized_packages,
          )}
          {renderSuccessfulImportStat(
            'Total packages',
            uploadResults?.total_packages,
          )}
          {(errorsCount > 0 || warningsCount > 0) && (
            <>
              <Typography
                component='p'
                variant='h6'
                align='left'
                className={classes.issuesLabel}
              >
                Issues
              </Typography>
              <Grid
                item
                container
                xs={12}
                className={classes.marginBottom10}
              >
                <Grid item container xs={8} direction={'column'}>
                  <Typography component='p' variant='body2'>
                    Errors
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    component='p'
                    variant='body1'
                    color={
                      errorsCount > 0
                        ? 'error'
                        : {
                            color: 'grey',
                            variant: 'A400',
                          }
                    }
                    align='right'
                  >
                    {errorsCount}
                  </Typography>
                </Grid>
                <Grid item container xs={8} direction={'column'}>
                  <Typography component='p' variant='body2'>
                    Warnings
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    component='p'
                    variant='body1'
                    color={
                      warningsCount > 0
                        ? 'warning'
                        : {
                            color: 'grey',
                            variant: 'A400',
                          }
                    }
                    align='right'
                  >
                    {warningsCount}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={12}
                className={classes.marginBottom10}
              >
                <Grid
                  item
                  container
                  xs={7}
                  direction={'column'}
                ></Grid>
                <Grid item xs={5}>
                  <CSVLink
                    data={uploadResults?.issues || ''}
                    filename={`${selectedFileLabel}-import-issues-log.csv`}
                  >
                    <Typography
                      component='p'
                      variant='body1'
                      color='tertiary'
                      underlined
                      align='right'
                    >
                      Download issues log
                    </Typography>
                  </CSVLink>
                </Grid>
              </Grid>
            </>
          )}
          <Box m={0.8} />
        </Grid>
      </>
    );
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={(e) => {
          handleClose();
        }}
      >
        <DialogTitle className={classes.dialogTitle}>
          <CloudUploadOutlined className={classes.dialogIcon} />
          <Typography component='p' variant='h5'>
            Import New {manifestLabels.singular}
          </Typography>
        </DialogTitle>
        {currentStep ===
          CreateInboundManifestSteps.OWNER_SELECTION && (
          <>
            <DialogContent className={classes.dialogContent}>
              <div>
                <Typography
                  component='p'
                  variant='body1'
                  className={classes.paddingBottom20}
                >
                  Please select an owner from the list to proceed.
                </Typography>
                <CSAsyncSelect<TypeAheadItem<number>>
                  label={'Owner'}
                  cacheOptions
                  loadOptions={loadCustomerOptions}
                  onChange={(option) => {
                    setSelectedOwner(option as TypeAheadItem<number>);
                  }}
                  isDisabled={!!error}
                  placeholder='Start Typing...'
                  value={selectedOwner}
                  containerSize='fullHorizontal'
                  menuPortalTarget={document.body}
                  noOptionsMessage={noOptionsMessage}
                />
              </div>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <CSButton
                color='secondary'
                variant='outlined'
                onClick={(e) => {
                  handleClose();
                }}
                className={classes.button}
              >
                Cancel
              </CSButton>
              <CSButton
                color='secondary'
                variant='contained'
                disabled={!selectedOwner}
                data-testid='go-to-file-selection'
                onClick={(e) => {
                  setCurrentStep(
                    CreateInboundManifestSteps.FILE_SELECTION,
                  );
                }}
                className={classes.button}
              >
                Next
              </CSButton>
            </DialogActions>
          </>
        )}
        {currentStep ===
          CreateInboundManifestSteps.FILE_SELECTION && (
          <>
            {/* Preload the .csv file icon used during upload progress screens*/}
            <link
              rel='preload'
              as='image/png'
              href={`${
                process.env.REACT_APP_BASENAME || ''
              }/icons/csv-file.png`}
            />
            <DialogContent className={classes.dialogContent}>
              {renderFileSelectionForm()}
              {error && <CSDialogAlert alertMessage={error} />}
            </DialogContent>
            <DialogActions>
              <CSButton
                variant='outlined'
                color='secondary'
                onClick={(e) => {
                  handleClose();
                }}
                className={classes.button}
              >
                Cancel
              </CSButton>
              <CSButton
                variant='contained'
                color='secondary'
                disabled={!selectedFileLabel.endsWith('.csv')}
                data-testid='start-import'
                onClick={(e) => {
                  uploadManifestFile();
                  setCurrentStep(CreateInboundManifestSteps.RESULTS);
                }}
                className={classes.button}
              >
                Upload
              </CSButton>
            </DialogActions>
          </>
        )}
        {currentStep === CreateInboundManifestSteps.RESULTS &&
          isUploading && (
            <>
              <DialogContent className={classes.dialogContent}>
                {renderUploadingProgress()}
                {error && <CSDialogAlert alertMessage={error} />}
              </DialogContent>
              <DialogActions>
                <CSButton
                  variant='outlined'
                  color='secondary'
                  onClick={(e) => {
                    handleClose();
                  }}
                  className={classes.button}
                >
                  Cancel
                </CSButton>
              </DialogActions>
            </>
          )}
        {currentStep === CreateInboundManifestSteps.RESULTS &&
          !isUploading && (
            <>
              <DialogContent className={classes.dialogContent}>
                {!isUploading && (
                  <>
                    {renderUploadingProgress()}
                    <Box mb={2} />
                  </>
                )}
                {renderImportResults()}
              </DialogContent>
              <DialogActions>
                <CSButton
                  variant='outlined'
                  color='secondary'
                  onClick={(e) => {
                    updateParent();
                    handleClose();
                  }}
                  className={classes.button}
                >
                  Close
                </CSButton>
              </DialogActions>
            </>
          )}
      </Dialog>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...selectStyles,
    ...sectionPageBaseStyle,
    dialogContent: {
      minWidth: '488px',
      width: '488px',
      paddingTop: '7px',
      paddingRight: '31px',
      paddingLeft: '31px',
      [theme.breakpoints.down('sm')]: {
        minWidth: 'auto',
        width: 'auto',
      },
    },
    dialogTitle: {
      textAlign: 'center',
      paddingRight: '31px',
      paddingLeft: '31px',
    },
    dialogIcon: {
      height: 'auto',
      width: '32px',
      color: theme.palette.primary.main,
    },
    dialogActions: {
      paddingRight: '31px',
      paddingLeft: '31px',
      paddingBottom: '31px',
      paddingTop: '35px',
    },
    fileDropZone: {
      ...theme.typography.h6,
      margin: '20px 0',
      borderRadius: '5px',
      display: 'flex',
      flexDirection: 'column',
      padding: '25px',
      alignItems: 'center',
      border: '2px dashed',
      borderColor: theme.palette.tertiary?.main,
      backgroundColor: theme.palette.background.default,
      '& p': {
        color: theme.palette.tertiary?.main,
      },
    },
    fileContainer: {
      margin: '20px 0',
      padding: '0 10px',
    },
    uploadFileInfo: {
      margin: '0 0 5px 0',
    },
    uploadFilename: {
      ...theme.typography.body2,
      display: 'inline-block',
      maxWidth: '250px',
      overflow: 'hidden',
    },
    uploadPercentage: {
      color: theme.palette.grey.A400,
      float: 'right',
    },
    uploadProgressBar: {
      width: '100%',
      background: theme.palette.grey.A200,
      height: '5px',
      borderRadius: '2px',
      overflow: 'hidden',
    },
    uploadProgressBarInner: {
      background: theme.palette.tertiary?.main,
      height: '5px',
    },
    padding10: {
      padding: '10px',
    },
    paddingBottom20: {
      paddingBottom: '20px',
    },
    newManifestLabel: {
      marginBottom: '24px',
    },
    issuesLabel: {
      marginTop: '24px',
      marginBottom: '12px',
    },
  })),
)(CreateInboundManifestDialog);
