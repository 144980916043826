import React, { useEffect, useState } from 'react';
import { AuthRoutes } from '../../../interfaces/routes';
import { NestedProcessStepSerializerPackageProcessStepEnum } from 'cloudsort-client';
import { withStyles } from '@material-ui/core/styles';
import utils from '../../../utils/stats';
import configurationUtils from '../../../utils/configurationUtils';
import {
  createFiltersQuery,
  getAppliedFilters,
  SelectedFilters,
} from '../../filtersDrawer/FiltersDrawer';
import StackedStats, {
  CardContainerVariants,
  CardItemVariants,
  StatData,
} from '../../stackedStats/StackedStats';
import queryString from 'query-string';
import { LoaderRing } from '../../primitives';
import { CARD_LOAD_DELAY } from '../Dashboard';
import useMountedStatus from '../../../hooks/useMountedStatus';
import { setErrorByPS } from './utils/utils';

//Services
import DashboardStatsService from '../../../services/DashboardStats.service';

interface Props {
  title: string;
  index: number;
  filters?: SelectedFilters;
  classes: { [key: string]: string };
}

const PackageStats: React.FC<Props> = ({
  title,
  index,
  filters,
  classes,
}) => {
  const [data, setData] = useState<StatData>({});
  const [percentDispatched, setPercentDispatched] =
    useState<number>();

  const componentMounted = useMountedStatus();

  const fetchStats = async () => {
    const orderedActivePS = configurationUtils.getPsOrder();

    let results: StatData = orderedActivePS.reduce((acc, step) => {
      if (['PLANNED', 'CLOSED', 'OPENED'].includes(step.key))
        return acc;
      return {
        ...acc,
        [step.key]: {
          label: step.label,
          value: 'pending',
        },
      };
    }, {});

    setData(results);

    let totalPackages = 0;
    let numberOfStatsFetched = 0;

    const filtersQuery = createFiltersQuery(filters);

    setTimeout(() => {
      orderedActivePS.forEach((ps) => {
        if (['PLANNED', 'CLOSED', 'OPENED'].includes(ps.key)) return;

        DashboardStatsService.getPackagesByStatus({
          owner:
            filters && filters.owner && filters.owner.values.length
              ? filters.owner.values.map((item: any) => item.id)
              : undefined,
          carrier:
            filters &&
            filters.carrier &&
            filters.carrier.values.length
              ? filters.carrier.values.map((item: any) => item.id)
              : undefined,
          route:
            filters && filters.route && filters.route.values.length
              ? filters.route.values.map((item: any) => item.id)
              : undefined,
          destination:
            filters && filters.stop && filters.stop.values.length
              ? filters.stop.values.map((item: any) => item.id)
              : undefined,
          hidePending:
            filters &&
            filters.hidePending &&
            filters.hidePending.values.length
              ? filters.hidePending.values[0].id === 'yes'
                ? true
                : filters.hidePending.values[0].id === 'no'
                ? false
                : undefined
              : undefined,
          processStep: [
            ps.key as unknown as NestedProcessStepSerializerPackageProcessStepEnum,
          ],
        })
          .then((res) => {
            results = {
              ...results,
              [ps.key]: {
                ...results[ps.key],
                value: res.data.count,
                longestDwell: res.data.results.length
                  ? {
                      id: res.data.results[0].id,
                      time: utils.getLongestDwellTimeLabel(
                        res.data.results[0].process_step_time,
                      ),
                    }
                  : undefined,
                filter: {
                  name:
                    queryString.stringify(filtersQuery) +
                    '&processStep',
                  value: ps.label,
                },
                itemsStyleVariant:
                  ps.key === 'DISPATCHED'
                    ? CardItemVariants.GOLD_PAPER
                    : undefined,
              },
            };
            if (componentMounted) setData(results);
            totalPackages += res.data.count;
            numberOfStatsFetched++;

            if (
              numberOfStatsFetched === orderedActivePS.length &&
              componentMounted
            ) {
              //all calls are finished
              setPercentDispatched(
                totalPackages !== 0
                  ? Math.round(
                      (Number(results['DISPATCHED'].value) /
                        totalPackages) *
                        100,
                    )
                  : 100,
              );
            }
          })
          .catch((e) => {
            if (componentMounted) {
              setData(
                setErrorByPS(
                  (results = {
                    ...results,
                    [ps.key]: {
                      label: ps.label,
                      value: 'error',
                    },
                  }),
                  ps.key,
                  ps.label,
                ),
              );
            }
          });
      });
    }, index * CARD_LOAD_DELAY);
  };

  useEffect(() => {
    fetchStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return data ? (
    <>
      <StackedStats
        title={title}
        containerStyleVariant={CardContainerVariants.WHITE}
        itemsStyleVariant={CardItemVariants.WHITE_PAPER}
        dataTestId='packages-stats'
        stats={data}
        linkBase={AuthRoutes.PACKAGE}
        percentage={percentDispatched}
        filters={getAppliedFilters(filters)}
      />
    </>
  ) : (
    <LoaderRing />
  );
};

export default withStyles({})(PackageStats);
