import { Grid, withStyles } from '@material-ui/core';
import * as React from 'react';
import styles from './CSDialogTitleWithIcon.styles';
import {
  DialogIcons,
  SectionTitleSeparatorProps,
} from './CSDialogTitleWithIconTypes';
import PrintIcon from '@material-ui/icons/Print';
import EditIcon from '@material-ui/icons/Edit';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import CloudUploaddOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import Delete from '@material-ui/icons/Delete';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import Typography from '../typography/Typography';

const DialogTitleWithIcon: React.FC<SectionTitleSeparatorProps> = ({
  classes,
  title,
  subtitle,
  icon,
  customIcon,
}) => {
  return (
    <Grid item container alignItems='center' direction='column'>
      {icon === DialogIcons.ADD && (
        <AddBoxOutlinedIcon className={classes.icon} />
      )}
      {icon === DialogIcons.PRINT && (
        <PrintIcon className={classes.icon} />
      )}
      {icon === DialogIcons.DOWNLOAD && (
        <CloudDownloadOutlinedIcon className={classes.icon} />
      )}
      {icon === DialogIcons.EDIT && (
        <EditIcon className={classes.icon} />
      )}
      {icon === DialogIcons.CHECK && (
        <CheckCircleOutlineIcon className={classes.icon} />
      )}
      {icon === DialogIcons.UPLOAD && (
        <CloudUploaddOutlinedIcon className={classes.icon} />
      )}
      {icon === DialogIcons.DELETE && (
        <Delete className={classes.icon} />
      )}
      {icon === DialogIcons.RESEND && (
        <AutorenewIcon className={classes.icon} />
      )}
      {icon === DialogIcons.LOCK && (
        <LockOutlinedIcon className={classes.icon} />
      )}
      {customIcon}
      <Typography className={classes.title} variant='h5'>
        {title}
      </Typography>
      {subtitle && (
        <Typography className={classes.subtitle} variant='body1'>
          {subtitle}
        </Typography>
      )}
    </Grid>
  );
};

export default withStyles(styles)(DialogTitleWithIcon);
