import { Theme } from '@material-ui/core';
import colors from '../../utils/colors';

export const navbarStyles = (theme: Theme) => {
  return {
    showCancelSaveButtons: {
      visibility: 'visible',
      opacity: 1,
      transition: 'visibility 0s, opacity 0.8s linear',
    },
    hideCancelSaveButtons: {
      visibility: 'hidden',
      opacity: 0,
      transition: 'visibility 0s, opacity 0.2s linear',
    },
    search: {
      width: '45%',
      marginRight: 10,
      transition: 'width .8s',
    },
    searchExpanded: {
      width: '70%',
      marginRight: 10,
      transition: 'width .8s',
    },
    paperWithoutShadow: {
      width: '100%',
      height: 'auto',
      borderRadius: 4,
      boxShadow: 'none',
      backgroundColor: colors.white,
      [theme.breakpoints.down('md')]: {
        backgroundColor: colors.veryLightGray,
      },
    },
    floatPaper: {
      top: 66,
      zIndex: 999,
      right: 0,
      border: 0,
      position: 'fixed',
      boxShadow: '0px 10px 5px rgb(0,0,0, 0.2)',
      maxWidth: 'calc(100% - 300px)',
      padding: '0 4%',
    },
    noShadow: {
      boxShadow: 'none',
    },
    spacerOn: {
      height: 88,
    },
    spacerOff: {
      height: 1,
    },
    mobileSearchContainer: { marginTop: '20px' },
    mobileSearchField: {
      marginTop: 0,
      marginBottom: 0,
      '& > .MuiInputBase-fullWidth': {
        paddingLeft: '6px',
        paddingRight: '6px',
      },
      '& > .Mui-focused fieldset': {
        border: `2px solid ${colors.darkGold}!important`,
      },
    },
    mobileSearchButton: {
      fontSize: '12px',
      padding: '6px',
      color: colors.darkGold,
      fontWight: 'bold',
      backgroundColor: 'transparent',
      border: `1px solid ${colors.lightGray}`,
      width: 'auto',
      height: '30px',
      marginLeft: '5px',
      marginBottom: 0,
    },
    link: {
      color: theme.palette.tertiary?.main,
      textAlign: 'right',
      float: 'right',
      top: 15,
      position: 'relative',
      cursor: 'pointer',
      textDecoration: 'underline',
      '&:hover': {
        color: theme.palette.tertiary?.dark,
      },
    },
    tabWrapper: {
      fontSize: '20px',
      textTransform: 'capitalize',
      padding: '12px 0px',
      [theme.breakpoints.down('md')]: {
        fontSize: '14px',
        padding: '0px 12px',
      },
    },
    tabsIndicator: {
      display: 'None',
    },
    headerTabsRoot: {
      minWidth: '0px',
    },
    selectedTopMenuTab: {
      [theme.breakpoints.down('md')]: {
        backgroundColor: theme.palette.background.paper,
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: 6,
      },
      [theme.breakpoints.up('lg')]: {
        textDecoration: 'underline',
        textDecorationThickness: '3px',
        textDecorationColor: theme.palette.primary.main,
        textUnderlineOffset: '7px',
      },
    },
    saveButton: {
      textTransform: 'none',
      marginRight: '15px',
    },
  };
};
