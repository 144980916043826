import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './stationPlanner.styles';
import Layout from '../layout/Layout';
import { Typography } from '../primitives/index';
import { Grid } from '@material-ui/core';

import { Helmet } from 'react-helmet';

interface Props {
  classes: { [key: string]: string };
}

const ShiftCalendar: React.FC<Props> = ({ classes }) => {
  return (
    <>
      <Helmet>
        <title>Cloudsort - Shift Calendar</title>
      </Helmet>
      <Layout navCurrent='SHIFT_CALENDAR'>
        <Grid container>
          <Grid item xs={12}>
            <Typography className={classes.title} variant={'h3'}>
              Shift Calendar
            </Typography>
          </Grid>
        </Grid>
      </Layout>
    </>
  );
};

export default withStyles(styles)(ShiftCalendar);
