// Services
import StationsService from '../services/Stations.service';
import LocalStorageService from '../services/LocalStorage.service';
import EphemeralStateService from '../services/EphemeralState.service';
import { PermissionsPermissionsEnum } from 'cloudsort-client';
import { resetDynamicRoutes } from '../interfaces/routes';

const setDataonLocalStore = async (
  config: any,
  stationId: number,
  orgId: number,
  permissions?: string[] | null,
  role?: string | null,
) => {
  EphemeralStateService.setConfiguration(config);
  EphemeralStateService.setPermissions(permissions);
  EphemeralStateService.setMyStationId(stationId);
  EphemeralStateService.setMyOrgId(orgId);
  EphemeralStateService.setRole(role);

  const matrix = await StationsService.getStationRoles(stationId);
  LocalStorageService.setPermissionsMatrix(matrix.data);
  return {
    stationId,
    config,
    permissions,
  };
};

const setStationData = async (stationId?: number) => {
  try {
    const myStations = (await StationsService.getAllStations()).data
      .results;
    LocalStorageService.setMyStationsData(myStations);
    const myStation = stationId
      ? myStations.find((station) => station.id === stationId)
      : myStations.find((station) =>
          station.user_data?.permissions?.includes(
            PermissionsPermissionsEnum.STATION_READ,
          ),
        );

    if (myStation) {
      resetDynamicRoutes();
      const myStationConfig = (
        await StationsService.getStationById(myStation.id!)
      ).data.config;

      return setDataonLocalStore(
        myStationConfig,
        myStation.id!,
        myStation.organization || 0,
        myStation.user_data?.permissions,
        myStation.user_data?.role_type,
      );
    }
    return null;
  } catch (e: any) {
    if (!e.message.includes('401'))
      //if error was due to 401, user should already have been redirected to the login page from the interceptor
      throw new Error('Error while setting configuration');
  }
};

export default { setStationData };
