import React, { useEffect, useState } from 'react';
import {
  withStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles';
import { AuthRoutes } from '../../interfaces/routes';
import Layout from '../layout/Layout';
import {
  LoadPlan,
  StaffPermissionPermissionsEnum,
} from 'cloudsort-client';
import PaginatedTable, {
  filterObj,
} from '../paginatedTable/PaginatedTable';
import queryString from 'query-string';
import { Column } from '../../interfaces/components';
import { CSButton, Typography } from '../primitives/index';
import { Grid } from '@material-ui/core';
import sectionPageBaseStyle from '../commonStyles/sectionPageBase.style';
import { Link } from 'react-router-dom';
import PermissionsService from '../../services/Permissions.service';
import CloudDownloadOutlined from '@material-ui/icons/CloudDownloadOutlined';
import { Helmet } from 'react-helmet';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import SchemesService from '../../services/Schemes.service';
import EphemeralStateService from '../../services/EphemeralState.service';
import LoadPlansService from '../../services/LoadPlans.service';
import AddSchemeDialog from './addSchemeDialog/AddSchemeDialog';
import AddSchemeSetDialog from './addSchemeSetDialog/AddSchemeSetDialog';
import configurationUtils from '../../utils/configurationUtils';
import CSSectionTitleSeparator from '../primitives/csSectionTitleSeparator/CSSectionTitleSeparator';

interface Props {
  classes: { [key: string]: string };
  location: any;
}
export interface IProcessStep {
  id: string;
  label: string;
  active: boolean;
}

const Schemes: React.FC<Props> = ({ classes, location }) => {
  const [showAddNewScheme, setShowAddNewScheme] =
    useState<boolean>(false);
  const [showAddSchemeSet, setShowAddSchemeSet] =
    useState<boolean>(false);
  const [lastUpdate, setLastUpdate] = useState<number>(0);
  const [activeLoadPlan, setActiveLoadPlan] = useState<
    LoadPlan | undefined
  >();

  const schemeLabels = {
    singular: 'Scheme',
    plural: 'Schemes',
  };

  const COLUMNS_SCHEMES: Column[] = [
    {
      id: 'name',
      label: 'Name',
    },
    {
      id: 'zipcode_count',
      label: 'Zip Codes',
    },
    {
      id: 'fmc_full_name',
      label: 'Carrier',
    },
    {
      id: 'routes_count',
      label: configurationUtils.getPageTitle(false, 'ROUTE'),
    },
    {
      id: 'loadpoints_count',
      label: 'Load Points',
    },
    {
      id: 'load_plan_formatted',
      label: configurationUtils.getPageTitle(true, 'LOADPLAN'),
      useCustomComponent: true,
    },
    {
      id: 'loadplan_active',
      label: `${configurationUtils.getPageTitle(
        true,
        'LOADPLAN',
      )} Status`,
      useCustomComponent: true,
    },
  ];

  useEffect(() => {
    PermissionsService.redirectIfNoPermission(
      StaffPermissionPermissionsEnum.LOADPLAN_READ,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAndSetActiveLoadPlanId = async () => {
    const res = await LoadPlansService.getAll({
      active: true,
    });
    setActiveLoadPlan(res.data.results[0]);
  };

  useEffect(() => {
    getAndSetActiveLoadPlanId();
  }, []);

  const fetchLoadPlanStatus = async (loadPlanId: number) => {
    const res = await LoadPlansService.getById(loadPlanId);
    return res.data.active;
  };

  const fetchSchemesData = async (
    pageIndex: number,
    rowsPerPage: number,
    filterOptions?: filterObj[],
    filterByString?: string,
    sortedBy?: string,
  ) => {
    const res = await SchemesService.getAll({
      loadplanStation: EphemeralStateService.getMyStationId(),
      page: pageIndex,
      pageSize: rowsPerPage,
      search: filterByString,
      sortBy:
        sortedBy === 'loadplan_active'
          ? '-load_plan__active'
          : 'load_plan__active',
    });
    //Mini cache for load plans active status.
    const fetchedLoadPlans = new Map();
    for (let scheme of res.data.results as any[]) {
      scheme.load_plan_formatted = (
        <Link
          className={classes.tableLink}
          to={`${AuthRoutes.LOADPLAN}/${scheme.load_plan}`}
          color='tertiary'
        >
          {scheme.load_plan}
        </Link>
      );

      if (!fetchedLoadPlans.has(scheme.load_plan)) {
        fetchedLoadPlans.set(
          scheme.load_plan,
          await fetchLoadPlanStatus(scheme.load_plan),
        );
      }
      scheme.loadplan_active = fetchedLoadPlans.get(
        scheme.load_plan,
      ) ? (
        <Typography color='tertiary'>Active</Typography>
      ) : (
        <Typography>Inactive</Typography>
      );
    }
    return res;
  };

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort -
      ${schemeLabels.plural} ${
            queryString.parse(location.search)['page']
              ? '- Page ' + queryString.parse(location.search)['page']
              : ''
          }`}
        </title>
      </Helmet>
      <Layout navCurrent='SCHEME'>
        <AddSchemeDialog
          isOpen={showAddNewScheme}
          onAfterClose={() => {
            setShowAddNewScheme(false);
          }}
          updateParent={() => {
            setLastUpdate(lastUpdate + 1);
          }}
          loadplanId={activeLoadPlan?.id}
        />

        <AddSchemeSetDialog
          isOpen={showAddSchemeSet}
          onAfterClose={() => {
            setShowAddSchemeSet(false);
          }}
          updateParent={() => {
            setLastUpdate(lastUpdate + 1);
          }}
          loadplanId={String(activeLoadPlan?.id)}
        />
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Typography variant='h3' component='h2'>
              {schemeLabels.plural}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            className={classes.nonMobileAlignRight}
          >
            {PermissionsService.hasPermission(
              StaffPermissionPermissionsEnum.LOADPLAN_WRITE,
            ) && (
              <>
                <CSButton
                  variant='contained'
                  color={{
                    buttonColor: 'secondary',
                    iconColor: 'primary',
                  }}
                  onClick={() => {
                    setShowAddNewScheme(true);
                  }}
                  startIcon={<AddBoxOutlinedIcon />}
                  className={classes.headerButton}
                >
                  Add {schemeLabels.singular}
                </CSButton>
                <CSButton
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    setShowAddSchemeSet(true);
                  }}
                  startIcon={<CloudDownloadOutlined />}
                  className={classes.headerButton}
                >
                  Import {schemeLabels.singular} Set
                </CSButton>
              </>
            )}
          </Grid>
          <CSSectionTitleSeparator topMargin={16} bottomMargin={22} />
        </Grid>
        <PaginatedTable
          key={'table' + lastUpdate}
          title={''}
          columns={COLUMNS_SCHEMES}
          dataTestIdPrefix={'schemes'}
          filterByString={true}
          filterByStringPlaceholder={`Search ${schemeLabels.plural}`}
          fetch={fetchSchemesData}
          rowsLoadDetailPages={true}
          detailsPageBasePath={AuthRoutes.SCHEME}
          defaultSort={
            (queryString.parse(location.search)[
              'sortBy'
            ] as string) || '-loadplan_active'
          }
          sortableBy={['loadplan_active']}
        />
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...sectionPageBaseStyle,
    nonMobileAlignRight: {
      [theme.breakpoints.up('sm')]: {
        textAlign: 'right',
      },
    },
    headerButton: {
      [theme.breakpoints.down('sm')]: {
        margin: '10px 10px 0 0',
      },
      [theme.breakpoints.up('sm')]: {
        margin: '0 0 0 10px',
      },
    },
    tableLink: {
      color: theme.palette.tertiary?.main,
      textDecorationColor: theme.palette.tertiary?.main,
      padding: '0 10px',
    },
  })),
)(Schemes);
