import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Theme,
  Typography,
  createStyles,
  withStyles,
} from '@material-ui/core';
import { useState } from 'react';
import CSDialogTitleWithIcon from '../../primitives/csDialogTitleWithIcon/CSDialogTitleWithIcon';
import { DialogIcons } from '../../primitives/csDialogTitleWithIcon/CSDialogTitleWithIconTypes';
import { CSMonoGridContainer } from '../../primitives/csMonoGridContainer';
import { AlertBanner, CSButton } from '../../primitives';
import ErrorHandler from '../../../utils/ErrorHandler';
import CSDialogAlert from '../../primitives/csDialogAlert/CSDialogAlert';
import { CSDualGridContainer } from '../../primitives/csDualGridContainer';
import { CSDatepicker } from '../../primitives/csDatepicker';
import dateUtils from '../../../services/utils/date';
import TaskService from '../../../services/Task.service';
import { TaskResubmissionDetails } from 'cloudsort-client';
import { AxiosError } from 'axios';

interface Props {
  classes: { [key: string]: string };
  objectId?: number;
  objectTypeId?: string;
  closeDialog: () => void;
  onAfterSave: () => void;
}

enum DialogStep {
  TIME_RANGE_SELECTION = 'TIME_RANGE_SELECTION',
  DRY_RUN_RESULTS = 'DRY_RUN_RESULTS',
}

const BatchResendDialog = ({
  classes,
  objectId,
  objectTypeId,
  closeDialog,
  onAfterSave,
}: Props) => {
  const [error, setError] = useState<string | undefined>();
  const [activeDialogStep, setActiveDialogStep] =
    useState<DialogStep>(DialogStep.TIME_RANGE_SELECTION);

  const [fromDate, setFromDate] = useState<Date>(
    new Date(dateUtils.fromDate()),
  );
  const [toDate, setToDate] = useState<Date>(
    new Date(dateUtils.toDate()),
  );

  const [dryRunResults, setDryRunResults] =
    useState<TaskResubmissionDetails>();

  const handleError = async (e: any) => {
    setError(await ErrorHandler.getLabel(e as AxiosError));
  };

  const onPrimaryAction = async () => {
    setError(undefined);

    if (activeDialogStep === DialogStep.TIME_RANGE_SELECTION) {
      try {
        const res = await TaskService.resubmit({
          data: {
            from_date: fromDate.toISOString(),
            to_date: toDate.toISOString(),
            all: true,
            object_id: objectId,
            object_type_id: Number(objectTypeId),
          },
          dryRun: true,
        });

        setDryRunResults(res.data);
      } catch (e) {
        handleError(e as AxiosError);
      }
      setActiveDialogStep(DialogStep.DRY_RUN_RESULTS);
    } else {
      try {
        TaskService.resubmit({
          data: {
            from_date: fromDate.toISOString(),
            to_date: toDate.toISOString(),
            all: true,
            object_id: objectId,
            object_type_id: Number(objectTypeId),
          },
        });

        onAfterSave();
      } catch (e) {
        handleError(e as AxiosError);
      }
    }
  };

  const onCancel = () => {
    setError(undefined);
    closeDialog();
  };

  return (
    <Dialog open data-testid='batch-resend-dialog'>
      <DialogTitle>
        <CSDialogTitleWithIcon
          title='Batch Resend'
          icon={DialogIcons.RESEND}
        />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {activeDialogStep === DialogStep.TIME_RANGE_SELECTION && (
          <CSDualGridContainer
            childrenColumnConfiguration={{
              0: 12,
            }}
          >
            {error ? (
              <CSDialogAlert alertMessage={error || ''} />
            ) : (
              <></>
            )}
            <CSDatepicker
              selectionType='date'
              className={classes.datePicker}
              disableToolbar
              format='MM/dd/yyyy'
              margin='normal'
              label='Start date'
              initialValue={fromDate}
              maxDate={toDate}
              onAcceptSelection={(e) => {
                setFromDate(e as Date);
              }}
              KeyboardButtonProps={{
                'aria-label': 'change from date',
              }}
            />
            <CSDatepicker
              selectionType='date'
              className={classes.datePicker}
              disableToolbar
              format='MM/dd/yyyy'
              margin='normal'
              label='End date'
              initialValue={toDate}
              minDate={fromDate}
              onAcceptSelection={(e) => {
                setToDate(e as Date);
              }}
              KeyboardButtonProps={{
                'aria-label': 'change to date',
              }}
            />
          </CSDualGridContainer>
        )}
        {activeDialogStep === DialogStep.DRY_RUN_RESULTS && (
          <CSMonoGridContainer>
            <Typography variant='h6'>Events Payload</Typography>
            <Grid container>
              <Grid item xs={10}>
                <Typography>
                  Total events in the time frame selected
                </Typography>
              </Grid>
              <Grid item xs={2} className={classes.alignRight}>
                <Typography
                  className={
                    dryRunResults?.count
                      ? classes.successColor
                      : classes.errorColor
                  }
                >
                  {dryRunResults?.count}
                </Typography>
              </Grid>
            </Grid>

            <AlertBanner
              severity='error'
              alertTitle='Events will be added to a throttled queue'
              alertMsg='This request will be processing at most 60 events per minute.'
            />
          </CSMonoGridContainer>
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Box>
          {activeDialogStep === DialogStep.DRY_RUN_RESULTS && (
            <CSButton
              variant='outlined'
              color='secondary'
              onClick={() => {
                setActiveDialogStep(DialogStep.TIME_RANGE_SELECTION);
              }}
            >
              Back
            </CSButton>
          )}
        </Box>
        <Box>
          <CSButton
            variant='outlined'
            color='secondary'
            className={classes.containedButton}
            onClick={onCancel}
          >
            Cancel
          </CSButton>
          <CSButton
            variant='contained'
            color='secondary'
            className={classes.containedButton}
            type='button'
            onClick={onPrimaryAction}
          >
            {activeDialogStep === DialogStep.TIME_RANGE_SELECTION
              ? 'Next'
              : 'Confirm'}
          </CSButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    dialogContent: { width: 480, maxWidth: '100%' },
    dialogActions: {
      padding: 24,
      justifyContent: 'space-between',
    },
    marginTop10: {
      marginTop: 10,
    },
    containedButton: {
      marginLeft: 10,
    },
    successColor: {
      color: theme.palette.success.main,
    },
    errorColor: {
      color: theme.palette.error.main,
    },
    alignRight: {
      textAlign: 'right',
    },
  })),
)(BatchResendDialog);
