import {
  createStyles,
  Grid,
  Slider,
  Theme,
  Typography,
  withStyles,
} from '@material-ui/core';
import { CSTextField } from '../../../primitives';
import SectionTitleSeparator from '../../../primitives/csSectionTitleSeparator/CSSectionTitleSeparator';
import {
  LoadPointDetailsWithDisplayName,
  percentageLabelFormat,
} from './EditLoadBalancingDialog';
import { onlyNumbers } from '../../../../utils/inputHelpers';

interface Props {
  classes: { [key: string]: string };
  loadpoint: LoadPointDetailsWithDisplayName;
  handleChange: (loadpointId: number, newValue: number) => void;
  changeCommitted: () => void;
  isLast?: boolean;
}

function LoadBalancingSlider({
  classes,
  loadpoint,
  handleChange,
  changeCommitted,
  isLast = false,
}: Props) {
  return (
    <>
      <Grid
        className={classes.sliderContainer}
        container
        spacing={2}
        key={`loadbalancing-slider-${loadpoint.id}`}
      >
        <Grid item className={classes.flexGrow}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant='body1'>
                {loadpoint.display_name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Slider
                classes={{
                  root: classes.sliderRoot,
                  colorPrimary: classes.sliderPrimary,
                  track: classes.sliderTrack,
                  rail: classes.sliderTrackRail,
                  thumb: classes.sliderThumb,
                }}
                min={0}
                max={1}
                step={0.01}
                value={loadpoint.load_balancing || 0}
                onChange={(_, newValue) =>
                  handleChange(loadpoint.id!, newValue as number)
                }
                onChangeCommitted={() => changeCommitted()}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <CSTextField
            value={percentageLabelFormat(
              loadpoint.load_balancing || 0,
            )}
            className={classes.percentageInput}
            endAdornment={
              <span className={classes.percentageAdornment}>%</span>
            }
            onChange={(event) => {
              let newValue = Number(
                onlyNumbers(event.target.value) || 0,
              );

              if (newValue > 100) newValue = 100;

              handleChange(loadpoint.id!, newValue / 100);
            }}
          />
        </Grid>
        {!isLast && (
          <Grid item xs={12}>
            <SectionTitleSeparator
              borderWidth='border_thin'
              topMargin={0}
              bottomMargin={10}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default withStyles(
  createStyles((theme: Theme) => ({
    sliderContainer: {
      padding: '0 20px',
    },
    sliderRoot: {
      height: 9,
      borderRadius: theme.shape.borderRadius,
    },
    sliderPrimary: {
      backgroundColor: theme.palette.tertiary?.contrastText,
    },
    sliderTrack: {
      backgroundColor: theme.palette.tertiary?.main,
      height: 4,
      borderRadius: theme.shape.borderRadius_sm,
    },
    sliderTrackRail: {
      backgroundColor: theme.palette.grey[200],
      height: 4,
      borderRadius: theme.shape.borderRadius_sm,
    },
    sliderThumb: {
      backgroundColor: theme.palette.tertiary?.main,
      marginTop: -4,
    },
    alignRight: {
      textAlign: 'right',
    },
    percentageAdornment: {
      marginTop: 3,
    },
    percentageInput: {
      width: 90,
    },
    flexGrow: {
      flexGrow: 1,
    },
  })),
)(LoadBalancingSlider);
