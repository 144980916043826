import React, { useEffect, useState } from 'react';
import {
  withStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles';
import { AuthRoutes, ModulesKeys } from '../../interfaces/routes';
import { Column } from '../../interfaces/components';
import {
  AlertBanner,
  CSButton,
  CSTextField,
  Typography,
} from '../primitives/index';
import ErrorHandler from '../../utils/ErrorHandler';
import { Grid } from '@material-ui/core';
import PaginatedTable, {
  filterObj,
} from '../paginatedTable/PaginatedTable';
import Layout from '../layout/Layout';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Transition } from '../confirmationDialog/ConfirmationDialog';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import queryString from 'query-string';
import MenuItem from '@material-ui/core/MenuItem';
import configurationUtils from '../../utils/configurationUtils';
import sectionPageBaseStyle from '../commonStyles/sectionPageBase.style';

// Types
import {
  AreaAreaTypeEnum,
  StaffPermissionPermissionsEnum,
} from 'cloudsort-client';
import { AxiosError } from 'axios';

// Icons
import AddToPhotosOutlinedIcon from '@material-ui/icons/AddToPhotosOutlined';

// Services
import AreasService from '../../services/Areas.service';
import PermissionsService from '../../services/Permissions.service';
import EphemeralStateService from '../../services/EphemeralState.service';
import { Helmet } from 'react-helmet';
import CSSectionTitleSeparator from '../primitives/csSectionTitleSeparator/CSSectionTitleSeparator';
import { CSMonoGridContainer } from '../primitives/csMonoGridContainer';
import CSSelect from '../primitives/csSelect/CSSelect';
import CSDialogTitleWithIcon from '../primitives/csDialogTitleWithIcon/CSDialogTitleWithIcon';
import { DialogIcons } from '../primitives/csDialogTitleWithIcon/CSDialogTitleWithIconTypes';
import { useAppDispatch } from '../../redux/store';
import { setLastVisitedModule } from '../../redux/slices/navigationSlice';

const COLUMNS: Column[] = [
  {
    id: 'id',
    label: 'ID',
    width: 50,
    align: 'left',
  },
  {
    id: 'name',
    label: 'Name',
    width: 150,
    align: 'center',
  },
  {
    id: 'area_type',
    label: 'Type',
    width: 'auto',
    align: 'center',
  },
];

interface Props {
  classes: { [key: string]: string };
  location: any;
}

const Areas: React.FC<Props> = ({ classes, location }) => {
  const areaTypes = Object.entries(AreaAreaTypeEnum);

  const [selectedAreaType, setSelectedAreaType] = useState<
    AreaAreaTypeEnum | undefined
  >(AreaAreaTypeEnum.PRIMARY);
  const [selectedName, setSelectedName] = useState<string>();
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [showAddDialog, setShowAddDialog] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [lastUpdated, setLastUpdated] = useState<string>(
    new Date().toISOString(),
  );

  const dispatch = useAppDispatch();

  const addHandler = async () => {
    setShowProgress(true);
    try {
      if (selectedName && selectedAreaType) {
        await AreasService.create({
          name: selectedName,
          area_type: selectedAreaType,
          station: EphemeralStateService.getMyStationId(),
        });
        setLastUpdated(new Date().toISOString());
        onAfterDialogClose();
      }
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  const onAfterDialogClose = () => {
    setShowAddDialog(false);
    setError(undefined);

    setSelectedAreaType(AreaAreaTypeEnum.PRIMARY);
    setSelectedName('');
  };

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e as AxiosError));
  };

  const getEnumLabel = (str: string) => {
    return str.charAt(0) + str.slice(1).toLowerCase();
  };

  useEffect(() => {
    PermissionsService.redirectIfNoPermission(
      StaffPermissionPermissionsEnum.STATION_READ,
    );

    dispatch(
      setLastVisitedModule({
        module: ModulesKeys.AREA,
        permission: StaffPermissionPermissionsEnum.STATION_READ,
      }),
    );
  }, [dispatch]);

  const renderDialog = () => {
    return (
      <Dialog
        open={showAddDialog}
        TransitionComponent={Transition}
        onClose={() => {
          onAfterDialogClose();
        }}
        classes={{ paperScrollPaper: classes.dialogRoot }}
      >
        <DialogTitle>
          <CSDialogTitleWithIcon
            title={
              'New ' + configurationUtils.getPageTitle(true, 'AREA')
            }
            icon={DialogIcons.ADD}
          />
        </DialogTitle>
        <DialogContent>
          {error && (
            <AlertBanner
              severity='error'
              alertTitle={'Error'}
              alertMsg={error}
            />
          )}

          <CSMonoGridContainer>
            <CSTextField
              containerSize='fullHorizontal'
              data-testid='add-area-dialog:name'
              autoFocus
              label='Name'
              onChange={(e) => {
                setSelectedName(e.target.value);
              }}
              error={
                configurationUtils.isModuleNameValidated('AREA') &&
                !/^[A-Z][0-9]{1,3}$/.test(selectedName || '')
              }
              helperText={
                configurationUtils.isModuleNameValidated('AREA')
                  ? 'A capital letter followed by up to 3 digits.'
                  : undefined
              }
            />

            <CSSelect
              containerSize='fullHorizontal'
              label='Type'
              color='secondary'
              data-testid='add-area-dialog:type'
              onChange={(e: React.BaseSyntheticEvent) => {
                setSelectedAreaType(e.target.value);
              }}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
                classes: {
                  paper: classes.selectPaper,
                  list: classes.selectList,
                },
              }}
              value={selectedAreaType}
            >
              {areaTypes.map((type) => (
                <MenuItem
                  key={type[1]}
                  value={type[1]}
                  data-testid={`add-area-dialog:type:${getEnumLabel(
                    type[0],
                  )}`}
                >
                  {getEnumLabel(type[0])}
                </MenuItem>
              ))}
            </CSSelect>
          </CSMonoGridContainer>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <CSButton
            variant='outlined'
            color='secondary'
            data-testid={'areas-dialog-cancel'}
            onClick={() => {
              onAfterDialogClose();
            }}
          >
            Cancel
          </CSButton>
          <CSButton
            variant='contained'
            color='secondary'
            onClick={() => {
              addHandler();
            }}
            data-testid={'areas-dialog-add'}
            disabled={
              !selectedName ||
              (configurationUtils.isModuleNameValidated('AREA') &&
                !/^[A-Z][0-9]{1,3}$/.test(selectedName || '')) ||
              !selectedAreaType
            }
          >
            Add
          </CSButton>
        </DialogActions>
      </Dialog>
    );
  };

  const fetch = async (
    pageIndex: number,
    rowsPerPage: number,
    filterOptions?: filterObj[],
    filterByString?: string,
  ) => {
    const res = await AreasService.getAll(
      pageIndex,
      rowsPerPage,
      filterByString,
    );
    res.data.results.forEach((area: any) => {
      area.area_type = getEnumLabel(area.area_type);
    });

    return res;
  };

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort - ${configurationUtils.getPageTitle(
            false,
            'AREA',
          )} ${
            queryString.parse(location.search)['page']
              ? '- Page ' + queryString.parse(location.search)['page']
              : ''
          }`}
        </title>
      </Helmet>
      <Layout navCurrent='AREA'>
        {showProgress && <ProgressIndicator />}
        {renderDialog()}
        <Grid
          container
          className={classes.header}
          alignItems='center'
        >
          <Grid item xs={12} sm={6}>
            <Typography variant='h3' component='h2'>
              {configurationUtils.getPageTitle()}
            </Typography>
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
            className={classes.nonMobileAlignRight}
          >
            {PermissionsService.hasPermission(
              StaffPermissionPermissionsEnum.STATION_WRITE,
            ) && (
              <CSButton
                variant='contained'
                color={{
                  buttonColor: 'secondary',
                  iconColor: 'primary',
                }}
                data-testid='areas:add-area:dialog-button'
                disabled={!!error}
                onClick={(e) => {
                  e.preventDefault();
                  setShowAddDialog(true);
                }}
                startIcon={<AddToPhotosOutlinedIcon />}
              >
                Add {configurationUtils.getPageTitle(true, 'AREA')}
              </CSButton>
            )}
          </Grid>
          <CSSectionTitleSeparator topMargin={16} bottomMargin={8} />
        </Grid>

        <PaginatedTable
          key={lastUpdated}
          title=''
          columns={COLUMNS}
          dataTestIdPrefix={'areas'}
          fetch={fetch}
          rowsLoadDetailPages={true}
          detailsPageBasePath={AuthRoutes.AREA.replace('/', '')}
          filterByString={true}
          filterByStringPlaceholder={
            'Search ' + configurationUtils.getPageTitle(false, 'AREA')
          }
        />
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...sectionPageBaseStyle,
    nonMobileAlignRight: {
      [theme.breakpoints.down('xs')]: {
        marginTop: 20,
      },
      [theme.breakpoints.up('sm')]: {
        textAlign: 'right',
      },
    },
    dialogRoot: {
      overflow: 'hidden',
      maxWidth: 480,
    },
    dialogActions: {
      padding: 24,
    },
  })),
)(Areas);
