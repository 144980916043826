import {
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { switcher } from 'rambdax';
import { ExtendedPaletteKeys } from '../../../theming/material-ui';
import { getInputFieldColor } from '../csCommon/inputUtils';
import {
  CSTextFieldSize,
  ExtendedVariants,
} from './CSTextFieldTypes';
import { selectVariantProperty } from './CSTextFieldUtils';

export const FILLED_CLASS = 'filled';

export default (
  theme: Theme,
  color: ExtendedPaletteKeys,
  variant?: ExtendedVariants,
  label?: string,
  containerSize?: CSTextFieldSize,
) => {
  const outlinedInputPadding = label
    ? '16px 10px 3px 12px'
    : '10px 10px 8px 12px';
  const colorStyle = getInputFieldColor(color, theme);
  const inputStyles = {
    underline: {
      '&::after': {
        borderBottom: `${theme.borderWidth.border_md} solid ${colorStyle}`,
      },
    },
  };
  const sizeStyles = getSizeStyles(containerSize || 'standard');
  const outlinedInputStyles = {
    root: {
      ...sizeStyles,
      borderRadius: theme.shape.borderRadius_sm,
      '&$focused > $notchedOutline': {
        borderColor: colorStyle,
      },
    },
    focused: {},
    disabled: {},
    notchedOutline: {},
  };
  const standardOutlinedStyles = {
    ...outlinedInputStyles,
    root: {
      ...sizeStyles,
      height: '48px',
      borderRadius: theme.shape.borderRadius_sm,
      borderColor: theme.palette.grey[300],
      color: theme.palette.grey.A400,
      '&$disabled': {
        color: theme.palette.grey[300],
      },
      '&$disabled > $notchedOutline': {
        borderColor: theme.palette.grey[300],
      },
      '&:hover': {
        color: theme.palette.common.black,
        '&$disabled': {
          color: theme.palette.grey[300],
        },
      },
      // This is a hack we should use svg:has(~ $input:not(:placeholder-shown))
      // However the has selector is not available in firefox
      [`&.${FILLED_CLASS} > svg`]: {
        color: theme.palette.common.black,
      },
      '&$focused > $notchedOutline': {
        borderWidth: theme.borderWidth.border_sm,
        borderColor: theme.palette.grey[300],
      },
      '&$focused > svg': {
        color: theme.palette.common.black,
      },
    },
    notchedOutline: {
      borderColor: theme.palette.grey[300],
    },
    input: {
      borderRadius: theme.shape.borderRadius_sm,
      padding: outlinedInputPadding,
      '&:hover': {
        '&::placeholder': {
          color: theme.palette.grey.A700,
          opacity: 1,
        },
      },
      '&::placeholder': {
        color: theme.palette.grey.A400,
        opacity: 1,
      },
      '&:not(:placeholder-shown)': {
        color: theme.palette.common.black,
      },
    },
  };
  const outlinedFilledStyles = {
    ...outlinedInputStyles,
    root: {
      ...sizeStyles,
      height: '48px',
      background: theme.palette.background.default,
      borderRadius: theme.shape.borderRadius_sm,
      color: theme.palette.grey.A400,
      '&$disabled': {
        color: theme.palette.grey[300],
      },
      '&:hover': {
        color: theme.palette.common.black,
        '&$disabled': {
          color: theme.palette.grey[300],
        },
        '& > $notchedOutline': {
          borderColor: theme.palette.background.default,
        },
      },
      '&$focused > $notchedOutline': {
        borderColor: theme.palette.background.default,
      },
      // This is a hack we should use svg:has(~ $input:not(:placeholder-shown))
      // However the has selector is not available in firefox
      [`&.${FILLED_CLASS} > svg`]: {
        color: theme.palette.common.black,
      },
    },
    notchedOutline: {
      borderColor: theme.palette.background.default,
    },
    input: {
      borderRadius: theme.shape.borderRadius_sm,
      padding: outlinedInputPadding,
      background: theme.palette.background.default,
      '&:hover': {
        '&::placeholder': {
          color: theme.palette.grey.A700,
          opacity: 1,
        },
      },
      '&::placeholder': {
        color: theme.palette.grey.A400,
        opacity: 1,
      },
      '&:not(:placeholder-shown)': {
        color: theme.palette.common.black,
      },
    },
  };

  const styles = (
    variant?: ExtendedVariants,
  ): Record<string, unknown> =>
    selectVariantProperty(
      {
        standard: inputStyles,
        filled: inputStyles,
        outlined: outlinedInputStyles,
        filledOutlined: outlinedFilledStyles,
        standardOutlined: standardOutlinedStyles,
      },
      variant,
    ) as Record<string, unknown>;
  return createStyles(() => ({
    ...styles(variant),
  }));
};

export const getInputLabelStyles = (
  theme: Theme,
  color: ExtendedPaletteKeys,
  variant?: ExtendedVariants,
  hasStartAdornment: boolean = false,
) => {
  const horizontalOffset = hasStartAdornment ? 38 : 0;
  const colorStyle = getInputFieldColor(color, theme);
  const generalStyle = {
    root: {
      '&$focused': {
        color: colorStyle,
      },
    },
    focused: {},
  };
  const standardOutlinedStyle = {
    ...generalStyle,
    root: {
      '&$focused': {
        color: theme.palette.grey.A400,
      },
    },
    formControl: {
      top: -9,
      '&$focused': {
        left: horizontalOffset,
        top: -2,
      },
      '&$shrink': {
        color: theme.palette.grey.A400,
        left: horizontalOffset,
        top: -2,
        transform: 'translate(12px, 10px) scale(0.875)',
      },
    },
    shrink: {},
  };
  const styles = (
    variant?: ExtendedVariants,
  ): Record<string, unknown> =>
    selectVariantProperty(
      {
        standard: generalStyle,
        filled: generalStyle,
        outlined: generalStyle,
        filledOutlined: standardOutlinedStyle,
        standardOutlined: standardOutlinedStyle,
      },
      variant,
    ) as Record<string, unknown>;
  return createStyles(() => ({
    ...styles(variant),
  }));
};

export const getClearButtonStyles = () =>
  makeStyles((theme: Theme) => ({
    singleAdornmentBox: {
      display: 'flex',
      flexDirection: 'row-reverse',
      height: 24,
      minWidth: 24,
      [`.${FILLED_CLASS} > &`]: {
        color: theme.palette.text.primary,
      },
    },
    multiAdornmentBox: {
      display: 'flex',
      flexDirection: 'row-reverse',
      height: 24,
      minWidth: 48,
      [`.${FILLED_CLASS} > &`]: {
        color: theme.palette.text.primary,
      },
    },
    cursorPointer: {
      cursor: 'pointer',
    },
  }))();

export const getSizeStyles = (containerSize: CSTextFieldSize) => {
  return switcher<Record<string, CSSProperties>>(containerSize)
    .is('standard', {})
    .is('fullHorizontal', { width: '100%' })
    .is('fullVertical', { height: '100%' })
    .is('full', { width: '100%', height: '100%' })
    .default({});
};

export const getFormControlStyles = (
  containerSize: CSTextFieldSize,
) => {
  const formControlStyles = getSizeStyles(containerSize);
  return createStyles(() => ({
    root: { zIndex: 'auto', ...formControlStyles },
  }));
};

export const getFormHelperTextStyles = () => {
  return createStyles(() => ({
    root: { marginLeft: 0 },
  }));
};
