import { createStyles, Theme } from '@material-ui/core/styles';

export default createStyles((theme: Theme) => ({
  selectorContainer: {
    margin: 16,
    padding: 5,
    minWidth: 268,
    color: theme.palette.primary.contrastText,
    background: 'rgba(255,255,255,0.08)', // TODO -- Ask if we can use defined color instead
    borderRadius: theme.shape.borderRadius_sm,
  },

  popoverPaper: {
    width: 240,
    padding: 16,
    backgroundColor: theme.palette.quaternary?.main,
    borderRadius: theme.shape.borderRadius_sm,
  },
  smallLabel: {
    display: 'block',
    textTransform: 'uppercase',
    marginBottom: 8,
    letterSpacing: '1px!important',
  },

  entityName: {
    maxWidth: 160,
    wordBreak: 'break-all',
  },

  menuList: {
    height: '100%',
    width: '100%',
    padding: 0,
  },

  menuItem: {
    padding: '9px 9px 9px 30px',
    marginLeft: '-30px',
    color: theme.palette.quaternary?.contrastText,
    fontSize: `${theme.typography.body2.fontSize}!important`,
    fontFamily: theme.typography.body2.fontFamily,
    letterSpacing: 0,
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.secondary.main,
    },
  },

  accordionRoot: {
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.quaternary?.main,
    boxShadow: 'none',
  },

  accordionExpanded: {
    margin: `0 !important`,
  },

  accordionSummaryRoot: {
    flexDirection: 'row-reverse',
    padding: 0,
    minHeight: '0!important',
    '& svg': {
      margin: '2px 2px 0 0',
    },
  },
  accordionSummaryDisabled: {
    opacity: '1 !important',
  },
  accordionSummaryContent: {
    margin: '10px 0',
    '&.Mui-expanded': {
      margin: '10px 0',
    },
  },
  accordionSummaryExpandIcon: {
    padding: 0,
    margin: 0,
  },

  accordionDetailsRoot: {
    padding: '0 0 0 30px',
    flexDirection: 'column',
  },
  expandMoreIcon: {
    margin: `0 !important`,
    color: theme.palette.quaternary?.contrastText,
  },

  marginBottom: {
    marginBottom: 10,
  },
}));
