import LocalStorageService from './LocalStorage.service';
import { WeatherApi } from 'cloudsort-client';
import axios from '../utils/axios';

export type iconTypes =
  | string
  | '01d'
  | '01n'
  | '02d'
  | '03d'
  | '04d'
  | '04n'
  | '09d'
  | '09n'
  | '10d'
  | '10n'
  | '11d'
  | '11n'
  | '13d'
  | '13n'
  | '50d'
  | '50n'
  | 'na';

export interface WeatherData {
  icon?: iconTypes;
  title?: string;
  temperature?: number;
  cityID?: string;
  expires?: Date;
}

class Weather {
  private api: WeatherApi;

  constructor() {
    this.api = new WeatherApi(undefined, undefined, axios as any);
  }
  private async fetchWeather(zip: string) {
    return this.api.weatherList({
      q: zip + ',US',
      units: 'Imperial',
    });
  }

  public async getWeatherForStation(
    zipcode: string,
  ): Promise<WeatherData | null> {
    const data = LocalStorageService.getWeatherInfo();
    //if there is cached data
    if (data && new Date(data.expires) > new Date()) {
      return data;
    } else {
      const res = await this.fetchWeather(zipcode.substring(0, 5)); //zip codes in DB have 9 digits but we only need first 5
      if (res) {
        const data = {
          icon: res.data.weather.icon,
          title: res.data.weather.main,
          temperature: Math.round(res.data.temp.temp),
          cityID: res.data.id,
          expires: new Date(new Date().valueOf() + 1000 * 60 * 60), //1hr from now
        };
        LocalStorageService.setWeatherInfo(data);
        return data;
      }
      return null;
    }
  }
}

export default new Weather();
