import { useTheme } from '@material-ui/core';
import get from 'lodash/get';
import * as React from 'react';
import { ExtendedPaletteKeys } from '../../../theming/material-ui';
import useStableId from '../../../utils/useStableId';

export interface StaffOperatorIconProps
  extends React.SVGProps<SVGSVGElement> {
  alt: string;
  color?: ExtendedPaletteKeys | 'none';
}

const StaffOperatorIcon: React.FC<StaffOperatorIconProps> = ({
  id,
  alt,
  className,
  color = 'none',
  ...others
}) => {
  const containerId = useStableId(id || '', 'StaffOperatorIcon');
  const theme = useTheme();
  const colorToApply = get(theme.palette, `${color}.main`);
  return (
    <div id={containerId} className={className} aria-label={alt}>
      <svg
        width='84'
        height='84'
        viewBox='0 0 84 84'
        fill={colorToApply}
        xmlns='http://www.w3.org/2000/svg'
        {...others}
      >
        <path
          d='M42 54.5225C27.9825 54.5225 0 61.1058 0 74.1742V84H84V74.1742C84 61.1058 56.0175 54.5225 42 54.5225Z'
          fill='inherit'
        />
        <path
          d='M21.1522 24.8232H64.4052C65.7743 24.8232 66.8898 23.731 66.8898 22.3905V22.2913C66.8898 20.9508 65.7743 19.8586 64.4052 19.8586H63.0361C63.0361 12.5109 58.9288 6.2058 52.8947 2.73055V7.44697C52.8947 8.83707 51.7792 9.92929 50.3594 9.92929C48.9396 9.92929 47.824 8.83707 47.824 7.44697V0.69505C46.2014 0.297879 44.5281 0 42.7534 0C40.9786 0 39.3053 0.297879 37.6827 0.69505V7.44697C37.6827 8.83707 36.5671 9.92929 35.1473 9.92929C33.7275 9.92929 32.612 8.83707 32.612 7.44697V2.73055C26.5779 6.2058 22.4706 12.5109 22.4706 19.8586H21.1522C19.7832 19.8586 18.6676 20.9508 18.6676 22.2913V22.4402C18.6676 23.731 19.7832 24.8232 21.1522 24.8232Z'
          fill='inherit'
        />
        <path
          d='M42.0014 46.5436C51.7458 46.5436 59.8661 39.924 62.2236 31.0291H21.7792C24.1367 39.924 32.257 46.5436 42.0014 46.5436Z'
          fill='inherit'
        />
      </svg>
    </div>
  );
};

export default StaffOperatorIcon;
