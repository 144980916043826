import React, { useCallback, useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { AlertBanner, CSButton, CSTextField } from '../../primitives';
import { withStyles } from '@material-ui/core/styles';
import {
  DialogTitle,
  Typography,
  createStyles,
} from '@material-ui/core';
import detailsPageStyles from '../../commonStyles/detailsPage.style';
import ErrorHandler from '../../../utils/ErrorHandler';
import { AxiosError } from 'axios';
import selectStyles from '../../select/select.styles';
import {
  checkForConflictingCustomerConfigs,
  getStationsAutocompleteSuggestions,
} from './utils';
import CSAsyncSelect from '../../primitives/csAsyncSelect/CSAsyncSelect';
import { TypeAheadItem } from '../../../interfaces/components';
import { noOptionsMessage } from '../../asyncSelect/utils';
import useControlled from '../../../utils/useControlled';
import debounce from 'lodash/debounce';
import CSDialogTitleWithIcon from '../../primitives/csDialogTitleWithIcon/CSDialogTitleWithIcon';
import { DialogIcons } from '../../primitives/csDialogTitleWithIcon/CSDialogTitleWithIconTypes';
import { CustomerConfig } from 'cloudsort-client';
import { configurationUrlParams } from '../utils';
import { useParams } from 'react-router-dom';
import CustomerConfigurationsService from '../../../services/CustomerConfigurationsService';
import { assignedStationsToConfiguration } from './ConfigurationDetails';
import ProgressIndicator from '../../progressIndicator/ProgressIndicator';

interface Props {
  classes: { [key: string]: string };
  isOpen: boolean;
  config: CustomerConfig;
  onCancel: () => void;
  onUpdate: () => void;
}

const AssignStationsToConfigurationDialog: React.FC<Props> = ({
  classes,
  isOpen,
  onCancel,
  onUpdate,
  config,
}) => {
  const [open, setOpen] = useControlled({
    controlled: isOpen,
    default: false,
    name: 'AssignStationsToConfigurationDialog',
  });
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [error, setError] = useState<string | React.ReactNode>();
  const [selectedStations, setSelectedStations] = useState<
    TypeAheadItem<number>[]
  >([]);
  const [name, setName] = useState<string>('');
  const [id, setId] = useState<number>();
  const urlParams = useParams<configurationUrlParams>();

  const resetLocalState = () => {
    setOpen(false);
    setSelectedStations([]);
    setName('');
    setId(undefined);
    setError(undefined);
  };

  useEffect(() => {
    setName(config.name);
    setId(config.id);
    const selected = (
      (config.station_data as unknown as assignedStationsToConfiguration[]) ||
      []
    ).map((station) => {
      return {
        label: station.name,
        value: station.id,
      };
    });
    setSelectedStations(selected);
  }, [open, config]);

  const handleError = async (err: AxiosError) => {
    setError(await ErrorHandler.getLabel(err));
  };

  const patchConfig = async () => {
    try {
      setShowProgress(true);
      const newConfig = {
        name: name,
        stations: selectedStations.map((station) => station.value),
      } as CustomerConfig;
      await CustomerConfigurationsService.updateConfiguration(
        String(id),
        newConfig,
        true,
      );
      resetLocalState();
      onUpdate();
    } catch (err) {
      handleError(err as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  const checkForConflicts = async (
    stations: TypeAheadItem<number>[],
  ) => {
    try {
      setShowProgress(true);
      setError(undefined); //Reset prev errors.
      const conflicts = await checkForConflictingCustomerConfigs(
        selectedStations,
        stations,
        Number(urlParams.customerId),
      );
      const errors = conflicts.map((conflict) => {
        return (
          <Typography
            variant='body2'
            component='p'
            key={`${conflict.conflict}-${conflict.name}`}
          >
            Station <b>{conflict.name}</b> is currently assigned to{' '}
            {conflict.conflict}.
          </Typography>
        );
      });
      if (errors.length > 0) {
        setError(
          <>
            {errors}
            <Typography
              variant='body2'
              component='p'
              key='overwrite-warning'
            >
              When updating this setting will be overwritten.
            </Typography>
          </>,
        );
      }
    } catch (err) {
      handleError(err as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadStationDataOptions = useCallback(
    debounce((inputValue: string, callback: any) => {
      getStationsAutocompleteSuggestions(
        inputValue,
        Number(urlParams.orgId),
        selectedStations || [],
      )
        .then((autocompleteData) => {
          callback(autocompleteData);
        })
        .catch((e) => {
          handleError(e as AxiosError);
        });
    }, 500),
    [setSelectedStations, selectedStations],
  );

  return (
    <Dialog open={Boolean(open)}>
      {showProgress && <ProgressIndicator />}
      <DialogTitle>
        <CSDialogTitleWithIcon
          icon={DialogIcons.ADD}
          title='Assign Stations to Configuration'
        />
        <Typography variant='body1' component='p' align='center'>
          Assign <b>{name}</b> to stations
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <CSTextField
          style={{ marginBottom: 10 }}
          label='Name'
          placeholder='Name'
          value={name || ''}
          autoFocus
          onChange={(e) => {
            setName(e.target.value);
          }}
          containerSize='fullHorizontal'
        />
        <CSAsyncSelect<TypeAheadItem<number>, true>
          label='Stations'
          isClearable
          isMulti
          containerSize='fullHorizontal'
          menuPortalTarget={document.body}
          loadOptions={loadStationDataOptions}
          onChange={(newValues: readonly TypeAheadItem<number>[]) => {
            const selected = [...newValues];
            setSelectedStations(selected);
            checkForConflicts(selected);
          }}
          value={selectedStations}
          noOptionsMessage={noOptionsMessage}
          placeholder={'Search Stations'}
        />
      </DialogContent>
      {error && (
        <DialogContent className={classes.dialogContent}>
          <AlertBanner
            className={classes.banner}
            severity='error'
            alertMsg={error}
          />
        </DialogContent>
      )}
      <DialogActions className={classes.dialogActions}>
        <CSButton
          variant='outlined'
          color='secondary'
          onClick={(e) => {
            resetLocalState();
            onCancel();
          }}
          className={classes.button}
        >
          Cancel
        </CSButton>
        <CSButton
          disabled={selectedStations.length === 0}
          variant='contained'
          color='secondary'
          onClick={(e) => {
            patchConfig();
          }}
        >
          Update
        </CSButton>
      </DialogActions>
    </Dialog>
  );
};
export default withStyles(
  createStyles(() => ({
    ...detailsPageStyles,
    ...selectStyles,
    dialogContent: {
      width: '500px',
      maxWidth: '100%',
      minHeight: '100px',
    },
    dialogActions: {
      padding: '16px 23px 25px 8px',
    },
  })),
)(AssignStationsToConfigurationDialog);
