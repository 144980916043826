import React, { useState } from 'react';
import {
  withStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles';
import { CustomApp } from './CustomApps';
import Dialog from '@material-ui/core/Dialog';
import { Transition } from '../../confirmationDialog/ConfirmationDialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import CSDialogTitleWithIcon from '../../primitives/csDialogTitleWithIcon/CSDialogTitleWithIcon';
import { DialogIcons } from '../../primitives/csDialogTitleWithIcon/CSDialogTitleWithIconTypes';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CSButton from '../../primitives/csButton/CSButton';
import CSTextField from '../../primitives/csTextField/CSTextField';

interface Props {
  classes: { [key: string]: string };
  isOpen: boolean;
  onAdd: (customApps: CustomApp) => void;
  onClose: () => void;
}

const AddCustomAppModal: React.FC<Props> = ({
  classes,
  isOpen,
  onAdd,
  onClose,
}) => {
  const [appId, setAppId] = useState<string>('');
  return (
    <Dialog
      keepMounted
      open={isOpen}
      className={classes.dialog}
      TransitionComponent={Transition}
      onClose={() => {
        setAppId('');
        onClose();
      }}
    >
      <DialogTitle>
        <CSDialogTitleWithIcon
          title={'Add Custom App'}
          icon={DialogIcons.ADD}
        />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <CSTextField
          label='Application ID'
          placeholder='Application ID'
          value={appId}
          autoFocus
          onChange={(e) => {
            setAppId(e.target.value);
          }}
          onClear={() => {
            setAppId('');
          }}
          containerSize='fullHorizontal'
        />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <CSButton
          color='secondary'
          variant='outlined'
          onClick={(e) => {
            setAppId('');
            onClose();
          }}
        >
          Cancel
        </CSButton>
        <CSButton
          color='secondary'
          variant='contained'
          disabled={!appId}
          onClick={(e) => {
            onAdd({
              identifier: appId,
              active: true,
            });
            setAppId('');
            onClose();
          }}
        >
          Add
        </CSButton>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    dialogActions: {
      paddingRight: '31px',
      paddingLeft: '31px',
      paddingBottom: '31px',
      paddingTop: '35px',
    },
    dialogContent: {
      minWidth: '488px',
      width: '488px',
      paddingTop: '7px',
      paddingRight: '31px',
      paddingLeft: '31px',
      [theme.breakpoints.down('sm')]: {
        minWidth: 'auto',
        width: 'auto',
      },
    },
  })),
)(AddCustomAppModal);
