import React, {
  Dispatch,
  Fragment,
  useEffect,
  useState,
} from 'react';
import {
  Checkbox,
  CSButton,
  CSSwitch,
  Typography,
} from '../../../primitives';
import enumToLabel from '../../../../utils/enumToLabel';
import { withStyles } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  Divider,
  Hidden,
  Tooltip,
  Theme,
} from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import colors from '../../../../utils/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Transition } from '../../../confirmationDialog/ConfirmationDialog';
import TextField from '@material-ui/core/TextField';
import { IconButton } from '@material-ui/core';
import detailsPageStyles from '../../../commonStyles/detailsPage.style';
import configPageStyles from '../../../commonStyles/configPage.style';
import classNames from 'classnames';
import { INTERACTION } from '../../utils';
import { processSearchQuery } from '../searchQuery';
import Highlighter from 'react-highlight-words';
import { CurrentView, ModuleName } from '../Modules';

// Icons
import EditIcon from '../../../../utils/svgs/EditIcon';
import EditIconWithTag from '../../../../utils/svgs/EditIconWithTag';

// Redux
import { useSearchResultSections } from '../../useSearchResultSections';

interface Props {
  classes: { [key: string]: string };
  data: any;
  dataWeb: any;
  interaction?: INTERACTION;
  setPartialData: Dispatch<any>;
  setWebData: Dispatch<any>;
  currentView: CurrentView;
}

const initDialogData = {
  label: '',
  label_plural: '',
  key: null,
  property_labels: null,
};

const Modules: React.FC<Props> = ({
  classes,
  data,
  dataWeb,
  interaction,
  setPartialData,
  setWebData,
  currentView,
}) => {
  const [modulesData, setModulesData] = useState<any[]>([]);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [dialogData, setDialogData] = useState<any>(initDialogData);

  const [searchResult, setSearchResult] = useState<string[]>([]);
  const [showWholeSection, setShowWholeSection] =
    useState<boolean>(true);

  const SECTION_TITLE = 'Modules';

  const { searchQuery, updateSections } = useSearchResultSections(
    ModuleName.GENERAL,
    SECTION_TITLE,
  );

  useEffect(
    function setModuleData() {
      if (data && currentView !== CurrentView.SEARCH) {
        const tmpData: any[] = [];
        for (let item of Object.entries(data)) {
          const itemData = item[1] as {
            label: string;
            label_plural: string;
          };
          tmpData.push(itemData);
        }
        setModulesData(tmpData);
      }
    },
    [data, currentView],
  );

  useEffect(
    function filterModuleDataBySearchResults() {
      if (currentView === CurrentView.SEARCH && data) {
        const tmpData: any[] = [];
        for (let item of Object.entries(data)) {
          const itemData = item[1] as {
            label: string;
            label_plural: string;
          };
          if (
            showWholeSection ||
            searchResult.some(
              (result) =>
                enumToLabel(item[0])
                  .toLowerCase()
                  .includes(result.toLowerCase()) ||
                itemData.label
                  .toLowerCase()
                  .includes(result.toLowerCase()) ||
                itemData.label_plural
                  .toLowerCase()
                  .includes(result.toLowerCase()),
            )
          )
            tmpData.push(itemData);
        }
        setModulesData(tmpData);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, searchResult],
  );

  useEffect(
    function processSearch() {
      if (currentView === CurrentView.SEARCH) {
        //Show whole section is there are results in a title
        setShowWholeSection(
          processSearchQuery(searchQuery, [SECTION_TITLE]).length !==
            0,
        );

        const dynamicLabels: string[] = [];
        for (let item of Object.entries(data)) {
          const itemData = item[1] as {
            label: string;
            label_plural: string;
          };
          dynamicLabels.push(itemData.label);
          dynamicLabels.push(itemData.label_plural);
          dynamicLabels.push(enumToLabel(item[0]));
        }
        const results = processSearchQuery(searchQuery, [
          SECTION_TITLE,
          ...dynamicLabels,
        ]);
        setSearchResult(results);

        updateSections(results);
      } else {
        setSearchResult([]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchQuery, currentView],
  );

  const getElKey = (obj: any) => {
    const el = Object.entries(data).find(
      ([key, value]: any) => value === obj,
    );
    return el?.length ? el[0] : '';
  };

  const onAfterDialogClose = () => {
    setShowDialog(false);
    setDialogData(initDialogData);
  };

  const renderDialog = () => {
    return (
      <Dialog
        classes={{ paperScrollPaper: classes.dialogRoot }}
        open={showDialog}
        TransitionComponent={Transition}
        onClose={() => {
          onAfterDialogClose();
        }}
      >
        <DialogTitle>
          <Typography>Edit</Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogRoot}>
          <TextField
            data-testid={`dialog-label-${dialogData.label}`}
            autoFocus
            label={'Label'}
            InputLabelProps={{
              shrink: true,
              className: classes.inputNameLabel,
            }}
            InputProps={{
              disableUnderline: true,
              className: classes.inputName,
            }}
            classes={{
              root: classes.inputNameHolder,
            }}
            value={dialogData.label}
            onChange={(e) => {
              setDialogData({
                ...dialogData,
                label: e.target.value,
              });
            }}
          />
          <TextField
            data-testid={`dialog-label-plural-${dialogData.label_plural}`}
            label={'Label Plural'}
            InputLabelProps={{
              shrink: true,
              className: classes.inputNameLabel,
            }}
            InputProps={{
              disableUnderline: true,
              className: classes.inputName,
            }}
            classes={{
              root: classes.inputNameHolder,
            }}
            value={dialogData.label_plural}
            onChange={(e) => {
              setDialogData({
                ...dialogData,
                label_plural: e.target.value,
              });
            }}
          />
          {dialogData.property_labels &&
          Object.keys(dialogData.property_labels).length ? (
            <>
              <Typography
                variant='h3'
                style={{
                  borderTop: `1px solid ${colors.lightGray}`,
                  paddingTop: '10px',
                }}
              >
                Property Labels
              </Typography>
              {Object.keys(dialogData.property_labels).map(
                (propertyKey: string) => (
                  <Fragment key={propertyKey}>
                    <Typography
                      variant='body1'
                      style={{ marginBottom: '5px' }}
                    >
                      Key: <i>{propertyKey}</i>
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          data-testid={`dialog-propety-label-${dialogData.property_labels[propertyKey].label}`}
                          label={'Label'}
                          InputLabelProps={{
                            shrink: true,
                            className: classes.inputNameLabel,
                          }}
                          InputProps={{
                            disableUnderline: true,
                            className: classes.inputName,
                          }}
                          classes={{
                            root: classes.inputNameHolder,
                          }}
                          value={
                            dialogData.property_labels[propertyKey]
                              .label
                          }
                          onChange={(e) => {
                            setDialogData({
                              ...dialogData,
                              property_labels: {
                                ...dialogData.property_labels,
                                [propertyKey]: {
                                  ...dialogData.property_labels[
                                    propertyKey
                                  ],
                                  label: e.target.value,
                                },
                              },
                            });
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label={'Label Plural'}
                          data-testid={`dialog-propety-label-plural-${dialogData.property_labels[propertyKey].label_plural}`}
                          InputLabelProps={{
                            shrink: true,
                            className: classes.inputNameLabel,
                          }}
                          InputProps={{
                            disableUnderline: true,
                            className: classes.inputName,
                          }}
                          classes={{
                            root: classes.inputNameHolder,
                          }}
                          value={
                            dialogData.property_labels[propertyKey]
                              .label_plural
                          }
                          onChange={(e) => {
                            setDialogData({
                              ...dialogData,
                              property_labels: {
                                ...dialogData.property_labels,
                                [propertyKey]: {
                                  ...dialogData.property_labels[
                                    propertyKey
                                  ],
                                  label_plural: e.target.value,
                                },
                              },
                            });
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Fragment>
                ),
              )}
            </>
          ) : undefined}
          {dialogData.validate_name !== undefined && (
            <>
              <Checkbox
                data-testid='validate-name-checkbox'
                onChange={(e) => {
                  setDialogData({
                    ...dialogData,
                    validate_name: !dialogData.validate_name,
                  });
                }}
                checked={dialogData.validate_name}
                color='primary'
                inputProps={{
                  'aria-label': 'Enable Name Validation',
                }}
                style={{ paddingLeft: 0 }}
              />{' '}
              Enable Name Validation{' '}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <CSButton
            color='secondary'
            variant='outlined'
            onClick={() => {
              onAfterDialogClose();
            }}
          >
            Cancel
          </CSButton>
          <CSButton
            color='secondary'
            variant='contained'
            data-testid='edit-dialog-modules-save-button'
            onClick={() => {
              data[dialogData.key].label = dialogData.label;
              data[dialogData.key].label_plural =
                dialogData.label_plural;
              data[dialogData.key].validate_name =
                dialogData.validate_name;
              data[dialogData.key].property_labels =
                dialogData.property_labels;
              setPartialData({
                ...data,
              });
              onAfterDialogClose();
            }}
          >
            Update
          </CSButton>
        </DialogActions>
      </Dialog>
    );
  };

  const getDependsOnValue = (partialDataEl: any): any => {
    if (typeof partialDataEl.active === 'boolean') {
      return partialDataEl.active;
    } else if (partialDataEl.depends_on) {
      return getDependsOnValue(data[partialDataEl.depends_on]);
    }
  };

  const DASHBOARD_MODULE_DEPENDECIES: { [key: string]: string[] } = {
    PACKAGE: [
      'TODAY_CHART',
      'AREA_COUNTERS',
      'DWELL_P2S_CHART',
      'DWELL_S2S_CHART',
      'PACKAGE_COUNTERS',
      'PERFORMANCE_CHART',
    ],
    OUTBOUND_LOAD: ['LOAD_COUNTERS'],
    CONTAINER: ['CONTAINER_COUNTERS'],
    DEVICE: ['DEVICES_COUNTERS'],
    STAFF: [],
  };

  const renderSwitch = (partialDataEl: any) => {
    const value = !!partialDataEl.depends_on
      ? getDependsOnValue(data[partialDataEl.depends_on])
      : partialDataEl.active;

    return (
      <CSSwitch
        data-testid={`modules-switch-${enumToLabel(
          getElKey(partialDataEl),
        )}`}
        color='default'
        size='small'
        disabled={
          interaction === INTERACTION.READ ||
          !!partialDataEl.depends_on
        }
        checked={value}
        onChange={() => {
          const key = getElKey(partialDataEl);
          data[key].active = !partialDataEl.active;
          if (dataWeb?.NAV_MENU.hasOwnProperty(key)) {
            dataWeb.NAV_MENU[key].active = data[key].active;
          }
          if (
            DASHBOARD_MODULE_DEPENDECIES.hasOwnProperty(key) &&
            dataWeb
          ) {
            DASHBOARD_MODULE_DEPENDECIES[key].forEach(
              (dashboardWidgetKey) => {
                dataWeb.LAYOUTS.DASHBOARD[dashboardWidgetKey].active =
                  data[key].active;
              },
            );
          }
          setWebData({ ...dataWeb });
          setPartialData({
            ...data,
          });
        }}
      />
    );
  };

  const renderComponent = () => {
    return (
      <>
        {renderDialog()}
        <Grid item sm={12}>
          <Box>
            <Box
              className={classNames(
                classes.moduleTitle,
                classes.pLeft,
              )}
              mb={1}
              mt={2}
            >
              <Highlighter
                highlightClassName={classes.searchResultsMark}
                searchWords={searchResult}
                autoEscape={true}
                textToHighlight={SECTION_TITLE}
              />
            </Box>
            <Box mt={2}>
              <Box>
                <Grid
                  container
                  item
                  sm={12}
                  className={classes.textHeader}
                >
                  <Grid item sm={2} xs={6} className={classes.pLeft}>
                    Item
                  </Grid>
                  <Hidden xsDown>
                    <Grid item sm={3}>
                      Label
                    </Grid>
                    <Grid item sm={5}>
                      Label Plural
                    </Grid>
                  </Hidden>
                  <Grid item sm={1} xs={3} />
                  <Grid item sm={1} xs={3}>
                    Active
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider className={classes.divider} />
                </Grid>
              </Box>
              {modulesData?.map(
                (partialDataEl: any, index: number, array: any[]) => {
                  return (
                    <Grid
                      container
                      item
                      sm={12}
                      key={`${partialDataEl.label}-${index}`}
                      className={classes.row}
                    >
                      <Grid
                        item
                        sm={2}
                        xs={6}
                        className={classNames(
                          classes.text,
                          classes.pLeft,
                        )}
                      >
                        <Highlighter
                          highlightClassName={
                            classes.searchResultsMark
                          }
                          searchWords={searchResult}
                          autoEscape={true}
                          textToHighlight={enumToLabel(
                            getElKey(partialDataEl),
                          )}
                        />
                      </Grid>
                      <Hidden xsDown>
                        <Grid item sm={3} className={classes.text}>
                          <Highlighter
                            highlightClassName={
                              classes.searchResultsMark
                            }
                            searchWords={searchResult}
                            autoEscape={true}
                            textToHighlight={partialDataEl.label}
                          />
                        </Grid>
                        <Grid item sm={5} className={classes.text}>
                          <Highlighter
                            highlightClassName={
                              classes.searchResultsMark
                            }
                            searchWords={searchResult}
                            autoEscape={true}
                            textToHighlight={
                              partialDataEl.label_plural
                            }
                          />
                        </Grid>
                      </Hidden>
                      <Grid item sm={1} xs={3}>
                        <IconButton
                          data-testid={`modules-edit-${enumToLabel(
                            getElKey(partialDataEl),
                          )}`}
                          size='small'
                          disabled={interaction === INTERACTION.READ}
                          className={classes.editIcon}
                          onClick={() => {
                            setDialogData({
                              label: partialDataEl.label,
                              label_plural:
                                partialDataEl.label_plural,
                              key: getElKey(partialDataEl),
                              validate_name:
                                partialDataEl.validate_name,
                              property_labels:
                                partialDataEl.property_labels,
                            });
                            setShowDialog(true);
                          }}
                        >
                          {Object.keys(
                            partialDataEl.property_labels || {},
                          ).length ? (
                            <Tooltip
                              title='This item contains additional property labels'
                              placement='top'
                              PopperProps={{
                                popperOptions: {
                                  modifiers: {
                                    offset: {
                                      enabled: true,
                                      offset: '0, -65px',
                                    },
                                  },
                                },
                              }}
                            >
                              <EditIconWithTag
                                style={{ width: '35px' }}
                              />
                            </Tooltip>
                          ) : (
                            <EditIcon />
                          )}
                        </IconButton>
                      </Grid>
                      <Grid
                        item
                        sm={1}
                        xs={3}
                        className={classes.muiSwitchHoler}
                      >
                        {getElKey(partialDataEl) !== 'DASHBOARD' &&
                          (!partialDataEl.depends_on ? (
                            renderSwitch(partialDataEl)
                          ) : (
                            <Tooltip
                              title={`Depends on: ${partialDataEl.depends_on}`}
                              placement='top'
                              PopperProps={{
                                popperOptions: {
                                  modifiers: {
                                    offset: {
                                      enabled: true,
                                      offset: '0, -65px',
                                    },
                                  },
                                },
                              }}
                            >
                              <div
                                style={{
                                  maxWidth: 'fit-content',
                                }}
                              >
                                {renderSwitch(partialDataEl)}
                              </div>
                            </Tooltip>
                          ))}
                      </Grid>
                      {index !== array.length - 1 && (
                        <Grid item xs={12}>
                          <Divider className={classes.divider} />
                        </Grid>
                      )}
                    </Grid>
                  );
                },
              )}
            </Box>
          </Box>
        </Grid>
      </>
    );
  };

  return currentView === CurrentView.SEARCH && !searchResult.length
    ? null
    : renderComponent();
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...configPageStyles(theme),
  })),
)(Modules);
