import React, { useEffect, useMemo, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { AuthRoutes } from '../../interfaces/routes';
import {
  Typography,
  AlertBanner,
  CSButton,
  Checkbox,
} from '../primitives';

import Paper from '@material-ui/core/Paper';
import { RouteComponentProps } from 'react-router-dom';
import Layout from '../layout/Layout';
import {
  renderPackageTarget1stSortationIdString,
  renderPackageTarget2ndSortationIdString,
  renderPackageTargetStagingIdString,
  humanReadableNull,
  renderWeightString,
  renderPackageContainerLocationString,
  renderVolumeString,
} from '../DetailsPagesFunctions';
import {
  HoldReason,
  PackageDetails,
  StaffPermissionPermissionsEnum,
} from 'cloudsort-client';
import EventsPaginatedTable from '../eventsPaginatedTable/EventsPaginatedTable';
import ProcessSteps from '../eventsSnapshot/processSteps/ProcessSteps';
import ErrorHandler from '../../utils/ErrorHandler';
import { AxiosError } from 'axios';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import {
  Box,
  Grid,
  GridSize,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import utils from '../../utils/stats';
import { common } from '../../utils/strings';
import qrCodeUtils from '../../utils/qrCode';
import SingleRowStats, {
  StatData,
} from '../singleRowStats/SingleRowStats';
import configurationUtils from '../../utils/configurationUtils';
import sanitizeHtml from 'sanitize-html';
import styles from './PackageDetails.styles';
//Services
import PackagesService from '../../services/Packages.service';
import PermissionsService from '../../services/Permissions.service';
import EventsService from '../../services/Events.service';

// Icons
import FilterCenterFocusIcon from '@material-ui/icons/FilterCenterFocus';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import EditIcon from '@material-ui/icons/Edit';
import SingleDetail from '../primitives/singleDetail/SingleDetail';
import { Helmet } from 'react-helmet';
import { labelConfigOpt } from '../../services/utils/labelDefinitions';
import PackagePrediction from './utils/PackagePrediction';
import EphemeralStateService from '../../services/EphemeralState.service';
import CSSectionTitleSeparator from '../primitives/csSectionTitleSeparator/CSSectionTitleSeparator';
import clsx from 'clsx';
import CSBreadcrumbs from '../primitives/CSBreadcrumbs/CSBreadcrumbs';
import { CSSingleDetailMonoColumnContainer } from '../primitives/singleDetail/singleDetailMonoColumnContainer';
import { CSSingleDetailMultiColumnContainer } from '../primitives/singleDetail/singleDetailMultiColumnContainer';
import { CSLink } from '../primitives/csLink';
import CSTimeContainer from '../primitives/csTimeContainer/CSTimeContainer';
import useStationId from '../../hooks/useStationId';
import PaginatedTable from '../paginatedTable/PaginatedTable';
import { Column } from '../../interfaces/components';
import ConfirmationDialog from '../confirmationDialog/ConfirmationDialog';
import saveAs from 'file-saver';
import EditCloudsortNoteDialog from './EditCloudsortNoteDialog';
import ResolveHoldingReasonsDialog from './ResolveHoldingReasonsDialog';
import AddHeldReasonDialog from '../heldPackages/AddHeldReasonDialog';
import capitalize from 'lodash/capitalize';

export interface PackageDetailsRouterProps {
  id: string;
}
export interface PackageDetailsProps
  extends RouteComponentProps<PackageDetailsRouterProps> {
  classes: { [key: string]: string };
}

const HOLD_REASONS_COLUMNS: Column[] = [
  {
    id: 'reason_formatted',
    label: 'Reason',
    width: 'auto',
    useCustomComponent: true,
  },
  {
    id: 'resolved_by_name',
    label: 'Operator',
    width: 'auto',
  },
  {
    id: 'device_type',
    label: 'Device',
    width: 'auto',
  },
  {
    id: 'resolved_on_formatted',
    label: 'Resolution Time',
    width: 'auto',
  },
  {
    id: 'scope_formatted',
    label: 'Scope',
    width: 'auto',
  },
  {
    id: 'hold_time',
    label: 'Hold Time',
    width: 'auto',
  },
  {
    id: 'cs_notes_formatted',
    label: 'Cloudsort Notes',
    width: 'auto',
    useCustomComponent: true,
  },
  {
    id: 'customer_note',
    label: 'Customer Notes',
    width: 'auto',
  },
];

enum PageDialogs {
  HOLD_RESOLVE_CONFIMATION = 'HOLD_RESOLVE_CONFIMATION',
  INVOICE_NOT_AVAILABLE = 'INVOICE_NOT_AVAILABLE',
  EDIT_NOTE = 'EDIT_NOTE',
  RESOLVE_CONFIRMATION_PROMPT = 'RESOLVE_CONFIRMATION_PROMPT',
  ADD_REASON = 'ADD_REASON',
}

interface ExtendedHoldReason extends HoldReason {
  reason_formatted?: JSX.Element;
  resolved_on_formatted?: string;
  scope_formatted?: string;
  cs_notes_formatted?: JSX.Element;
}

const PackageDetailsComponent: React.FC<PackageDetailsProps> = ({
  classes,
  match,
}) => {
  const thisPackagesId = Number(match.params.id);
  const [packageData, setPackageData] = useState<PackageDetails>();
  const [error, setError] = useState<string>();
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [reasonsToResolve, setReasonsToResolve] = useState<string[]>(
    [],
  );
  const [reasonToEdit, setReasonToEdit] =
    useState<ExtendedHoldReason>();
  const [lastUpdated, setLastUpdated] = useState<string>(
    new Date().toISOString(),
  );

  const [activeDialog, setActiveDialog] = useState<
    PageDialogs | undefined
  >();

  // It's possible for a user to open package details page without station id in the url.
  //In this case we need to get it from the package data and set it in the url. The web app (navigation bar) will use it to display the station name and show the correct navigation menu.
  useStationId(packageData?.station);
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down('xs'), {
    noSsr: true,
  });

  const packageLabels = useMemo(() => {
    return {
      singular: configurationUtils.getPageTitle(true, 'PACKAGE'),
      plural: configurationUtils.getPageTitle(false, 'PACKAGE'),
    };
  }, []);

  const fetchPackageDetails = async () => {
    return PackagesService.getById(thisPackagesId);
  };

  const onEditNoteClick = (reason: ExtendedHoldReason) => {
    setReasonToEdit(reason);
    setActiveDialog(PageDialogs.EDIT_NOTE);
  };

  const fetchHoldingReasons = () => {
    const formattedHoldingReasons = (
      packageData?.hold_for?.reasons || []
    ).map((reason: ExtendedHoldReason) => {
      return {
        ...reason,
        cs_notes_formatted: (
          <>
            {reason.system_note || common.emptyValue}{' '}
            <IconButton
              disableRipple
              onClick={() => {
                onEditNoteClick(reason);
              }}
            >
              <EditIcon />
            </IconButton>
          </>
        ),
        resolved_on_formatted: reason.resolved_on
          ? new Date(reason.resolved_on).toLocaleString()
          : common.emptyValue,
        scope_formatted: reason.scope
          ? capitalize(reason.scope)
          : common.emptyValue,
        reason_formatted: (
          <>
            {reason.resolved ? (
              <CheckCircleIcon
                color='primary'
                className={classes.checkIcon}
              />
            ) : (
              <Checkbox
                defaultChecked={
                  reason.resolved ||
                  reasonsToResolve.includes(reason.reason || '')
                }
                onChange={(event) => {
                  const { checked } = event.target;

                  if (checked) {
                    setReasonsToResolve((prev) => [
                      ...prev,
                      reason.reason || '',
                    ]);
                  } else {
                    setReasonsToResolve(
                      reasonsToResolve.filter(
                        (item) => item !== reason.reason,
                      ),
                    );
                  }
                }}
              />
            )}
            {reason.reason}
          </>
        ),
      };
    });
    return {
      data: {
        results: formattedHoldingReasons,
        count: formattedHoldingReasons?.length,
      },
    };
  };

  const processResolveReasons = (note?: string) => {
    updatePackageDetailsData(
      {
        hold_for: {
          reasons:
            packageData?.hold_for?.reasons?.map((reason) => {
              if (
                reason.reason &&
                reasonsToResolve.includes(reason.reason)
              ) {
                return {
                  ...reason,
                  resolved: true,
                  note: note !== '' ? note : undefined,
                };
              }
              return reason;
            }) || [],
        },
      },
      () => {
        setActiveDialog(PageDialogs.HOLD_RESOLVE_CONFIMATION);
        setLastUpdated(new Date().toISOString());
      },
    );
  };

  const processAddReason = (reason: string, csNotes?: string) => {
    updatePackageDetailsData(
      {
        hold_for: {
          reasons: [
            ...(packageData?.hold_for?.reasons || []),
            {
              code: reason,
              reason: reason,
              resolved: false,
              note: csNotes !== '' ? csNotes : undefined,
            },
          ],
        },
      },
      () => {
        setLastUpdated(new Date().toISOString());
      },
    );
  };

  const processEditNote = (note: string) => {
    updatePackageDetailsData(
      {
        hold_for: {
          reasons:
            packageData?.hold_for?.reasons?.map((reason) => {
              if (reason.reason === reasonToEdit?.reason) {
                return {
                  ...reason,
                  note: note,
                };
              }
              return reason;
            }) || [],
        },
      },
      () => {
        setLastUpdated(new Date().toISOString());
      },
    );
    setActiveDialog(undefined);
  };

  const fetchEvents = async (
    pageIndex: number,
    rowsPerPage: number,
    sortedBy?: string,
  ) => {
    let sortedByVal = sortedBy;
    if (sortedByVal && sortedByVal.includes('area_name')) {
      sortedByVal = sortedByVal.replace('area_name', 'area__name');
    }
    return EventsService.getAll({
      page: pageIndex,
      sortBy:
        (sortedByVal as
          | 'timestamp'
          | 'area__name'
          | '-timestamp'
          | '-area__name') || undefined,
      _package: thisPackagesId,
      pageSize: rowsPerPage,
    });
  };

  const fetchPackageDetailsData = async () => {
    setShowProgress(true);
    try {
      const { data } = await fetchPackageDetails();
      setPackageData(data);
    } catch (e) {
      handleError(e as AxiosError);
    }
    setShowProgress(false);
  };

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e as AxiosError));
  };

  const onGetQrCick = async () => {
    setShowProgress(true);
    try {
      if (packageData?.id) {
        const labelOTConfig = await labelConfigOpt();
        qrCodeUtils.download(
          await PackagesService.getLabel(
            packageData.id,
            labelOTConfig.size,
            labelOTConfig.dpi,
          ),
        );
      }
    } catch (e) {
      handleError(e as AxiosError);
    }
    setShowProgress(false);
  };

  const onGetInvoiceClick = async () => {
    setShowProgress(true);
    try {
      if (packageData?.id) {
        const invoice = await PackagesService.getCommercialInvoice(
          packageData.id,
        );

        const fileNamStr = invoice.request.getResponseHeader(
          'content-disposition',
        );
        saveAs(
          invoice.data,
          fileNamStr.split('filename=')[1].replace(/"/g, ''),
        );
      }
    } catch (e) {
      setActiveDialog(PageDialogs.INVOICE_NOT_AVAILABLE);
    }
    setShowProgress(false);
  };

  const updatePackageDetailsData = async (
    newData: PackageDetails,
    callback?: Function,
  ) => {
    setShowProgress(true);
    setError(undefined);
    setActiveDialog(undefined);
    try {
      const { data } = await PackagesService.update({
        id: packageData?.id!,
        data: newData,
      });
      setPackageData(data);
      callback && callback();
    } catch (e) {
      handleError(e as AxiosError);
    }
    setShowProgress(false);
  };

  useEffect(() => {
    PermissionsService.redirectIfNoPermission(
      StaffPermissionPermissionsEnum.PACKAGE_READ,
    );

    fetchPackageDetailsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const commonColumnSingleDetail = {
    labelColumns: 3 as GridSize,
    valueColumns: 8 as GridSize,
  };

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort -
    ${packageLabels.singular} ${packageData?.id || ''}`}
        </title>
      </Helmet>
      <ConfirmationDialog
        dataTestIdPrefix='package-hold-resolve-dialog'
        title='Holding reason marked as resolved'
        msg={reasonsToResolve.join('\n')}
        primaryActionLabel='OK'
        onPrimaryAction={() => {
          setActiveDialog(undefined);
          setReasonsToResolve([]);
        }}
        cancelLabel='Cancel'
        hideCancelButton
        onCancel={() => {
          setActiveDialog(undefined);
          setReasonsToResolve([]);
        }}
        isOpen={activeDialog === PageDialogs.HOLD_RESOLVE_CONFIMATION}
      />
      <ConfirmationDialog
        dataTestIdPrefix='invoice-not-available-dialog'
        title='Commercial invoice not available'
        msg='This package has no commercial invoice available.'
        primaryActionLabel='OK'
        onPrimaryAction={() => {
          setActiveDialog(undefined);
        }}
        cancelLabel='Cancel'
        hideCancelButton
        onCancel={() => {
          setActiveDialog(undefined);
        }}
        isOpen={activeDialog === PageDialogs.INVOICE_NOT_AVAILABLE}
      />
      {activeDialog === PageDialogs.EDIT_NOTE && reasonToEdit && (
        <EditCloudsortNoteDialog
          currentNote={reasonToEdit?.system_note || ''}
          onSave={(note: string) => {
            processEditNote(note);
          }}
          onCancel={() => {
            setActiveDialog(undefined);
          }}
        />
      )}
      {activeDialog === PageDialogs.ADD_REASON && (
        <AddHeldReasonDialog
          onSave={(_, reason: string, csNotes?: string) => {
            processAddReason(reason, csNotes);
            setActiveDialog(undefined);
          }}
          closeDialog={() => {
            setActiveDialog(undefined);
          }}
          packageData={packageData}
        />
      )}
      {activeDialog === PageDialogs.RESOLVE_CONFIRMATION_PROMPT && (
        <ResolveHoldingReasonsDialog
          onSave={(note: string) => {
            processResolveReasons(note);
          }}
          reasonsToResolve={reasonsToResolve}
          trackingId={
            packageData?.tracking_number_alias ||
            packageData?.tracking_number ||
            ''
          }
          onCancel={() => {
            setActiveDialog(undefined);
          }}
        />
      )}
      <Layout navCurrent='PACKAGE'>
        {showProgress && <ProgressIndicator />}
        <Grid
          container
          spacing={1}
          data-testid='package-details-view'
        >
          {error && (
            <AlertBanner
              className={classes.banner}
              severity='error'
              alertTitle={'Error'}
              alertMsg={error}
            />
          )}
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid item xs={12} sm={6}>
                <Box mb={5}>
                  <CSBreadcrumbs
                    breadcrumbs={[
                      {
                        label: packageLabels.plural,
                        link:
                          AuthRoutes.PACKAGE +
                          '?stationId=' +
                          packageData?.station,
                      },
                      {
                        label: `
                    ${packageLabels.singular} ${
                          packageData?.id || ''
                        }`,
                        selected: true,
                      },
                    ]}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={6} alignItems='center'>
              <Grid item>
                <Typography variant='h3' component='h1'>
                  {`${packageLabels.singular} ${
                    packageData?.id || ''
                  }`}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sm={6}
              justifyContent='flex-end'
              spacing={2}
            >
              <Grid item>
                {PermissionsService.hasPermission(
                  StaffPermissionPermissionsEnum.PACKAGE_LABEL_READ,
                ) && (
                  <CSButton
                    data-testid='package-details:label-button'
                    variant='contained'
                    color='primary'
                    disabled={!packageData}
                    onClick={onGetQrCick}
                    startIcon={<FilterCenterFocusIcon />}
                    className={classes.marginLeft10}
                  >
                    Label
                  </CSButton>
                )}
                <CSButton
                  data-testid='package-details:invoice-button'
                  variant='contained'
                  color='primary'
                  disabled={!packageData}
                  onClick={onGetInvoiceClick}
                  startIcon={<ReceiptOutlinedIcon />}
                  className={classes.marginLeft10}
                >
                  Invoice
                </CSButton>
              </Grid>
            </Grid>
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={12}>
                <CSSectionTitleSeparator topMargin={8} />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            alignItems='center'
            spacing={2}
            className={classes.elementsSpacing}
            xs={12}
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={8}
              className={clsx({
                [classes.maxContainer]: !isXsScreen,
              })}
            >
              <CSSingleDetailMultiColumnContainer
                data-testid='package-details-detail'
                elements={[
                  <SingleDetail
                    inline
                    label='Location'
                    value={renderPackageContainerLocationString(
                      packageData?.station_name,
                      packageData?.next_steps?.staging_area?.name,
                      undefined,
                      packageData?.next_steps?.outbound_load?.name,
                    )}
                  />,
                  <SingleDetail
                    inline
                    label={'Sortation Parameter'}
                    value={sanitizeHtml(
                      packageData?.sort_param || common.emptyValue,
                      {
                        allowedTags: [],
                      },
                    )}
                    onEdit={(value: string) => {
                      if (value !== packageData?.sort_param) {
                        //API Returns error when updating with the same value.
                        updatePackageDetailsData({
                          sort_param: value || null,
                        });
                        fetchPackageDetailsData();
                      }
                    }}
                  />,
                  <SingleDetail
                    inline
                    label={configurationUtils.getPageTitle(
                      true,
                      'MANIFEST',
                    )}
                    value={
                      packageData?.last_manifest_id ||
                      common.emptyValue
                    }
                  />,
                  <SingleDetail
                    inline
                    label={'Origin Station'}
                    value={
                      packageData?.origin_station_name ||
                      common.emptyValue
                    }
                  />,
                  <SingleDetail
                    inline
                    label={
                      configurationUtils.getPageTitle(
                        true,
                        'OUTBOUND_LOAD',
                      ) + ' ID'
                    }
                    value={
                      (packageData?.outbound_load &&
                        packageData?.outbound_load?.id) ||
                      common.emptyValue
                    }
                  />,
                  <SingleDetail
                    inline
                    label={configurationUtils.getPageTitle(
                      true,
                      'INBOUND_LOAD',
                    )}
                    value={
                      packageData?.inbound_load ? (
                        <CSLink
                          to={`${AuthRoutes.INBOUND_LOAD}/${packageData.inbound_load}`}
                        >
                          {humanReadableNull(
                            packageData?.inbound_load_name,
                          )}
                        </CSLink>
                      ) : (
                        common.emptyValue
                      )
                    }
                  />,
                  <SingleDetail
                    inline
                    label={
                      configurationUtils.getPageTitle(
                        true,
                        'CONTAINER',
                      ) + ' ID'
                    }
                    value={
                      packageData?.container &&
                      packageData?.container.id ? (
                        <CSLink
                          to={`${AuthRoutes.CONTAINER}/${packageData?.container.id}`}
                        >
                          {humanReadableNull(
                            packageData?.container?.id,
                          )}
                        </CSLink>
                      ) : (
                        common.emptyValue
                      )
                    }
                  />,
                ]}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              className={clsx({
                [classes.maxContainer]: !isXsScreen,
              })}
            >
              <CSTimeContainer
                elements={[
                  {
                    title: 'Target Load Time',
                    content: packageData?.next_steps?.outbound_load
                      ?.load_time
                      ? new Date(
                          packageData?.next_steps?.outbound_load?.load_time,
                        ).toLocaleTimeString('en-US', {
                          hour: '2-digit',
                          minute: '2-digit',
                          hour12: true,
                        })
                      : common.emptyValue,
                  },
                  {
                    title: 'Time Left',
                    content:
                      packageData?.next_steps?.outbound_load
                        ?.load_time &&
                      new Date() <
                        new Date(
                          packageData?.next_steps?.outbound_load?.load_time,
                        )
                        ? utils.getLongestDwellTimeLabel(
                            packageData?.next_steps?.outbound_load
                              ?.load_time,
                          )
                        : common.emptyValue,
                  },
                ]}
              />
            </Grid>
          </Grid>
          <Grid xs={12} item container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Paper
                className={clsx(classes.paper, classes.maxContainer)}
                data-testid='package-details-card'
              >
                <CSSingleDetailMonoColumnContainer
                  header={`${configurationUtils.getPageTitle(
                    true,
                    'PACKAGE',
                  )} Details`}
                  elements={[
                    <SingleDetail
                      label='Tracking ID'
                      value={humanReadableNull(
                        packageData?.tracking_number_alias ||
                          packageData?.tracking_number,
                      )}
                      {...commonColumnSingleDetail}
                    />,
                    <SingleDetail
                      label='BOL'
                      value={humanReadableNull(
                        packageData?.inbound_bol,
                      )}
                      {...commonColumnSingleDetail}
                    />,
                    <SingleDetail
                      label='Owner'
                      value={humanReadableNull(
                        packageData?.owner_full_name,
                      )}
                      {...commonColumnSingleDetail}
                    />,
                    <SingleDetail
                      label='Weight'
                      value={renderWeightString(packageData?.weight)}
                      {...commonColumnSingleDetail}
                    />,
                    <SingleDetail
                      label='Volume'
                      value={renderVolumeString(
                        Number(packageData?.volume),
                      )}
                      {...commonColumnSingleDetail}
                    />,
                    EphemeralStateService.getMyStationConfiguratation()
                      .GENERAL.BEHAVIORS.ML_INTEGRATION
                      .arrival_prediction && (
                      <SingleDetail
                        label='Prediction'
                        value={
                          <PackagePrediction
                            prediction={
                              packageData?.predicted_arrival
                            }
                            arrivalTimestamp={
                              packageData?.predicted_arrival_time
                            }
                            withText
                          />
                        }
                        {...commonColumnSingleDetail}
                      />
                    ),
                  ]}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper
                className={clsx(classes.paper, classes.maxContainer)}
                data-testid='delivery-information-card'
              >
                <CSSingleDetailMonoColumnContainer
                  header='Delivery Information'
                  elements={[
                    <SingleDetail
                      label='ZIP Code'
                      value={
                        packageData?.zipcode
                          ? `${humanReadableNull(
                              packageData.zipcode,
                            )} (${
                              packageData.consignee_address_country
                            })`
                          : common.emptyValue
                      }
                      {...commonColumnSingleDetail}
                    />,
                    <SingleDetail
                      label='Scheme'
                      value={
                        packageData?.scheme ? (
                          <CSLink
                            to={`${AuthRoutes.SCHEME}/${packageData?.scheme}`}
                          >
                            {humanReadableNull(
                              packageData?.scheme_name,
                            )}
                          </CSLink>
                        ) : (
                          common.emptyValue
                        )
                      }
                      {...commonColumnSingleDetail}
                    />,
                    <SingleDetail
                      label={configurationUtils.getPageTitle(
                        true,
                        'STOP',
                      )}
                      value={
                        packageData?.stop
                          ? humanReadableNull(packageData?.stop_name)
                          : common.emptyValue
                      }
                      {...commonColumnSingleDetail}
                    />,
                    <SingleDetail
                      label={`${configurationUtils.getPageTitle(
                        true,
                        'ROUTE',
                      )}`}
                      value={
                        packageData?.route ? (
                          <CSLink
                            to={`${AuthRoutes.ROUTE}/${packageData?.route}`}
                          >
                            {humanReadableNull(
                              packageData?.route_name,
                            )}
                          </CSLink>
                        ) : (
                          common.emptyValue
                        )
                      }
                      {...commonColumnSingleDetail}
                    />,
                    <SingleDetail
                      label='Carrier'
                      value={humanReadableNull(
                        packageData?.fmc_full_name,
                      )}
                      {...commonColumnSingleDetail}
                    />,
                  ]}
                />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className={classes.marginTop20}>
          <Grid item sm={6} xs={12}>
            <Typography variant='h3' component='h1'>
              Holding Reasons
            </Typography>
          </Grid>

          <Grid
            item
            sm={6}
            xs={12}
            className={classes.nonMobileAlignRight}
          >
            {PermissionsService.hasPermission(
              StaffPermissionPermissionsEnum.PACKAGE_WRITE,
            ) &&
              configurationUtils.isModuleActive('HELD_PACKAGE') && (
                <CSButton
                  startIcon={<AddBoxOutlinedIcon />}
                  variant='contained'
                  color={{
                    buttonColor: 'secondary',
                    iconColor: 'primary',
                  }}
                  onClick={() => {
                    setActiveDialog(PageDialogs.ADD_REASON);
                  }}
                >
                  Add Reason
                </CSButton>
              )}
            {PermissionsService.hasPermission(
              StaffPermissionPermissionsEnum.PACKAGE_WRITE,
            ) && (
              <CSButton
                data-testid='package-details:resolve-button'
                variant='contained'
                color='secondary'
                className={classes.marginLeft10}
                disabled={!reasonsToResolve.length}
                onClick={() => {
                  setActiveDialog(
                    PageDialogs.RESOLVE_CONFIRMATION_PROMPT,
                  );
                }}
              >
                Resolve
              </CSButton>
            )}
          </Grid>

          <Grid item xs={12}>
            <CSSectionTitleSeparator topMargin={8} />
          </Grid>

          <Grid item xs={12}>
            {packageData && (
              <PaginatedTable
                key={'hold-reasons-' + lastUpdated}
                columns={HOLD_REASONS_COLUMNS}
                fetch={fetchHoldingReasons}
                title=''
                showPagination={false}
              />
            )}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant='h6'
            component='h2'
            className={classes.titleSpacing}
          >
            Event Checkpoints
          </Typography>
        </Grid>
        <Grid container item xs={12}>
          {packageData?.process_steps_history ? (
            <ProcessSteps
              stepsData={packageData?.process_steps_history}
              dataTestPrefix='package'
            />
          ) : (
            <AlertBanner
              className={classes.banner}
              severity='info'
              alertTitle={
                'There is no information to display at the moment'
              }
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant='h6'
            component='h2'
            className={classes.titleSpacing}
          >
            Station Targets
          </Typography>
        </Grid>
        <Grid container item xs={12} spacing={2}>
          {packageData && (
            <SingleRowStats
              link_base={''}
              dataTestId='package-details-station-targets'
              fetch={() => {
                /*
                   TODO:
                   Single Row stats expects an
                   async function the type definition was altered to support this case
                  */
                return configurationUtils.isModuleActive('AREA')
                  ? ([
                      {
                        label: 'Target 1st Sortation',
                        value:
                          renderPackageTarget1stSortationIdString(
                            packageData?.next_steps?.primary_sort
                              ?.name,
                            packageData?.next_steps?.primary_sort
                              ?.load_point?.name,
                          ),
                      },
                      {
                        label: 'Target 2nd Sortation',
                        value:
                          renderPackageTarget2ndSortationIdString(
                            packageData?.next_steps?.secondary_sort
                              ?.name,
                            packageData?.next_steps?.secondary_sort
                              ?.zone?.name,
                            packageData?.next_steps?.secondary_sort
                              ?.load_point?.name,
                          ),
                      },
                      {
                        label: 'Target Staging Area',
                        value: renderPackageTargetStagingIdString(
                          packageData?.next_steps?.staging_area?.name,
                        ),
                        textColor: 'tertiary',
                      },
                      {
                        label: 'Target Load',
                        value:
                          (packageData?.outbound_load &&
                            packageData?.outbound_load?.name) ||
                          packageData?.next_steps?.outbound_load
                            ?.name ||
                          'N/A',
                        textColor: 'tertiary',
                      },
                    ] as StatData[])
                  : ([
                      {
                        label: 'Target Load',
                        value:
                          (packageData?.outbound_load &&
                            packageData?.outbound_load?.name) ||
                          packageData?.next_steps?.outbound_load
                            ?.name ||
                          'N/A',
                        textColor: 'tertiary',
                      },
                    ] as StatData[]);
              }}
            />
          )}
        </Grid>
        <Grid container item spacing={2} xs={12}>
          <Grid item xs={12}>
            <EventsPaginatedTable
              dataTestIdPrefix={'package-details-events'}
              fetch={fetchEvents}
              sortableBy={['timestamp']}
            />
          </Grid>
        </Grid>
      </Layout>
    </>
  );
};

export default withStyles(styles, { name: 'CsPackageDetails' })(
  PackageDetailsComponent,
);
