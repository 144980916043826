import {
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core/styles';

export const getSingleDetailInlineStyles = (width: string) =>
  makeStyles(() => ({
    inline: {
      display: 'inline-flex',
      flexDirection: 'row',
      padding: '5px 0',
      width: width,
      marginRight: '10px',
    },
  }));

export default createStyles((theme: Theme) => ({
  editIcon: {
    fill: theme.palette.grey.A400,
    cursor: 'pointer',
    marginLeft: 15,
    '&:hover': {
      fill: theme.palette.tertiary?.main,
    },
  },
  contentMargin: {
    marginRight: 15,
  },
  dialogAction: {
    padding: 24,
    '& button:first-child': {
      marginRight: '6px',
    },
  },
  paramValueLight: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    wordBreak: 'break-all',
  },
  dialogRoot: {
    overflow: 'hidden',
    width: 360,
    maxWidth: '100%',
  },
}));
