import { Theme } from '@material-ui/core';
import colors from '../../utils/colors';

export const getFiltersStylesWithTheme = (theme?: Theme) => ({
  filterBadgesHolder: {
    display: 'block',
    marginTop: '10px',
  },
  filterBadge: {
    textTransform: 'none',
    border: `1px solid ${colors.darkGold}`,
    borderRadius: '4px',
    padding: '5px',
    width: 'auto',
    display: 'inline-block',
    margin: '0 5px 5px 0',
  },
  filterBadgeButton: {
    backgroundColor: colors.darkGold,
    color: colors.white,
    width: '0.75em',
    height: '0.75em',
    padding: 0,
    minWidth: 0,
    borderRadius: '50%',
    textAlign: 'center',
    fontSize: '1rem',
    margin: '0 0 2px 5px',
    '& svg': {
      width: '0.5em',
      height: '0.5em',
    },
  },
  filterHR: {
    width: '100%',
    border: theme ? `${theme.borderWidth.border_sm} solid ${theme.palette.grey[300]}` : `2px solid ${colors.veryLightGray}`,
    color: theme ? undefined : colors.lightGray,
    marginBottom: '20px',
  },
});
/*
 TODO: Erase the raw export
 This raw export was made as a transitional style for the theming effort
 as to not break the whole app, so we are exporting both the old and new styles
 when the transition ends this should be erased
 */
const rawDetailsPage = getFiltersStylesWithTheme(undefined);
export default rawDetailsPage;

