import { useState } from 'react';
import CSDialogTitleWithIcon from '../primitives/csDialogTitleWithIcon/CSDialogTitleWithIcon';
import { Transition } from '../confirmationDialog/ConfirmationDialog';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  createStyles,
  withStyles,
} from '@material-ui/core';
import { DialogIcons } from '../primitives/csDialogTitleWithIcon/CSDialogTitleWithIconTypes';
import { CSButton, CSTextField } from '../primitives';
import { CSMonoGridContainer } from '../primitives/csMonoGridContainer';
import CSAsyncSelect from '../primitives/csAsyncSelect/CSAsyncSelect';

interface Props {
  classes: { [key: string]: string };
  onSave: (note: string, id?: number) => void;
  onCancel: () => void;
  trackingId: string;
  reasonsToResolve: string[];
}

const ResolveHoldingReasonsDialog = ({
  classes,
  onSave,
  onCancel,
  trackingId,
  reasonsToResolve,
}: Props) => {
  const [note, setNote] = useState('');

  return (
    <Dialog
      open={true}
      TransitionComponent={Transition}
      onClose={onCancel}
      data-testid='resolve-reasons-dialog'
    >
      <DialogTitle>
        <CSDialogTitleWithIcon
          icon={DialogIcons.CHECK}
          title='Resolve Holding Reasons'
        />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <CSMonoGridContainer>
          <CSTextField
            containerSize='fullHorizontal'
            label='Tracking Id'
            value={trackingId}
            disabled
          />
          <CSAsyncSelect
            isMulti
            label='Reasons'
            value={reasonsToResolve.map((reason) => ({
              label: reason,
              value: reason,
            }))}
            containerSize='fullHorizontal'
            isDisabled
          />
          <CSTextField
            containerSize='fullHorizontal'
            label='Cloudsort Notes'
            value={note}
            onChange={(e) => {
              setNote(e.target.value);
            }}
          />
        </CSMonoGridContainer>
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <CSButton
          color='secondary'
          variant='outlined'
          onClick={onCancel}
        >
          Cancel
        </CSButton>
        <CSButton
          color='secondary'
          variant='contained'
          data-testid='resolve-reasons'
          onClick={() => {
            onSave(note);
          }}
        >
          Resolve
        </CSButton>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(
  createStyles(() => ({
    dialogContent: {
      width: 480,
      maxWidth: '100%',
    },
    dialogActions: {
      padding: 24,
    },
  })),
)(ResolveHoldingReasonsDialog);
