import {
  addSection,
  removeSection,
  selectSearchQuery,
} from '../../redux/slices/searchSlice';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { ModuleName } from './modules/Modules';

export const useSearchResultSections = (
  moduleName: ModuleName,
  sectionTitle: string,
) => {
  const dispatch = useAppDispatch();
  const searchQuery = useAppSelector(selectSearchQuery);

  const updateSections = (results: string[]) => {
    const sectionName = `${moduleName}_${sectionTitle}`;
    if (results.length) {
      dispatch(addSection(sectionName));
    } else {
      dispatch(removeSection(sectionName));
    }
  };

  return {
    searchQuery,
    updateSections,
  };
};
