import { useState, useEffect } from 'react';

const useMountedStatus = (): Boolean => {
  const [isMounted, setIsMounted] = useState(true);

  useEffect(() => {
    return () => {
      setIsMounted(false);
    };
  }, []);

  return isMounted;
};

export default useMountedStatus;
