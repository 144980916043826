import { Theme, createStyles } from '@material-ui/core';

export default createStyles((theme: Theme) => ({
  icon: {
    margin: '10px auto',
    width: 32,
    height: 'auto',
    color: theme.palette.primary.main,
  },
  title: {
    wordBreak: 'break-all',
  },
  subtitle: {
    marginTop: '20px',
    wordBreak: 'break-word',
    textAlign: 'center',
  },
}));
