import colors from '../../utils/colors';

const styles = {
  control: (styles: any, state: any) => {
    return {
      ...styles,
      backgroundColor: 'transparent',
      border: `2px solid ${colors.dark}`,
      borderColor: `${colors.dark} !important`,
      boxShadow: 'none !important',
    };
  },
  menu: (styles: any) => {
    return {
      ...styles,
      width: '100%',
    };
  },
  indicatorSeparator: (styles: any) => {
    return {
      ...styles,
      display: 'none',
    };
  },
  option: (styles: any) => {
    return {
      ...styles,
      backgroundColor: colors.white,
      color: 'rgba(0, 0, 0, 0.87)',
      '&:hover': {
        backgroundColor: 'rgb(100, 100, 100, 0.2)',
        cursor: 'pointer',
      },
    };
  },
  placeholder: (styles: any) => {
    return {
      ...styles,
      color: colors.dark,
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: '16px',
    };
  },
  dropdownIndicator: (styles: any, state: any) => {
    return {
      ...styles,
      color: state.isDisabled ? colors.lightGray : colors.dark,
    };
  },
};

export default styles;
