import React, { useEffect, useState, Fragment, useMemo } from 'react';
import {
  withStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles';
import {
  Typography,
  AlertBanner,
  CSButton,
  Checkbox,
} from '../primitives';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import Layout from '../layout/Layout';
import Paper from '@material-ui/core/Paper';
import ErrorHandler from '../../utils/ErrorHandler';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import detailsPageStyles from '../commonStyles/detailsPage.style';
import {
  Box,
  Grid,
  MenuItem,
  Snackbar,
  Tooltip,
} from '@material-ui/core';
import { AuthRoutes } from '../../interfaces/routes';
import browserHistory from '../../utils/browserHistory';
import selectStyles from '../select/select.styles';
import configurationUtils from '../../utils/configurationUtils';
import { common } from '../../utils/strings';

// Types
import { AxiosError } from 'axios';
import {
  StaffPermissionPermissionsEnum,
  StaffPermissionRoleTypeEnum,
  Staff,
} from 'cloudsort-client';
import {
  OT_PERMISSIONS_LABELS,
  OTHER_PERMISSIONS_LABELS,
  ROLE_LABELS,
} from './StationStaff';

// Services
import StaffService from '../../services/Staff.service';
import PermissionsService from '../../services/Permissions.service';
import EphemeralStateService from '../../services/EphemeralState.service';
import SingleDetail from '../primitives/singleDetail/SingleDetail';
import { Helmet } from 'react-helmet';
import usePermissionLabels from '../../hooks/usePermissionLabels';
import usePermissionDependencies from '../../hooks/usePermissionDependencies';
import { transformDependencyData } from '../../utils/permissionDependenciesUtils';
import { CSSingleDetailMultiColumnContainer } from '../primitives/singleDetail/singleDetailMultiColumnContainer';
import CSSelect from '../primitives/csSelect/CSSelect';
import { CSDualGridContainer } from '../primitives/csDualGridContainer';
import CSSectionTitleSeparator from '../primitives/csSectionTitleSeparator/CSSectionTitleSeparator';
import CSBreadcrumbs from '../primitives/CSBreadcrumbs/CSBreadcrumbs';
import clsx from 'clsx';
import { IMatch } from '../../utils/commonTypes';

interface Props {
  classes: { [key: string]: string };
  match: IMatch;
}

const StationStaffDetailsComponent: React.FC<Props> = ({
  classes,
  match,
}) => {
  const stationStaffPermissionsLabels = useMemo(
    () => ({
      singular: configurationUtils.getPageTitle(true, 'STAFF'),
      plural: configurationUtils.getPageTitle(false, 'STAFF'),
    }),
    [],
  );
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [enableSave, setEnableSave] = useState<boolean>(false);
  const [staffData, setStaffData] = useState<Staff>();
  const [showDependencyNotification, setShowDependencyNotification] =
    useState(false);
  const [
    dependencyNotificationMessage,
    setDependencyNotificationMessage,
  ] = useState('');
  const [arePermissionsReady, setArePermissionsReady] =
    useState<boolean>(false);

  const permissionLabels = usePermissionLabels();
  const {
    calculateDependencyStatus,
    disablePermissionAndDependants,
  } = usePermissionDependencies();
  const [error, setError] = useState<string>();

  const backUrl = `${AuthRoutes.STAFF}/${match.params.id}`;

  const toggleEditMode = () => {
    setEnableSave(!enableSave);
  };

  useEffect(() => {
    if (staffData?.permission_data?.permissions) {
      setArePermissionsReady(true);
    }
  }, [staffData]);

  const onSaveClick = async () => {
    try {
      setShowProgress(true);
      if (staffData?.id) {
        //unset email so we don't need re-auth dialog
        staffData.email = undefined;

        staffData.permission_data!.station =
          EphemeralStateService.getMyStationId();
        await StaffService.update(staffData?.id, staffData);
        await getStaffData();
        toggleEditMode();
        browserHistory.push(backUrl);
      }
    } catch (e) {
      handleError(e as AxiosError);
      setShowProgress(false);
    }
  };

  const getStaffData = async () => {
    setShowProgress(true);
    try {
      const { data } = await StaffService.getById(
        match.params.id,
        EphemeralStateService.getMyStationId(),
      );
      //set permissions if not returned by BE sso page doesn't break
      if (!data.permission_data)
        data.permission_data = {
          role_type: StaffPermissionRoleTypeEnum.OPERATOR_I,
          permissions: [],
        };
      setStaffData(data);
    } catch (e) {
      handleError(e as AxiosError);
    }
    setShowProgress(false);
  };

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e as AxiosError));
  };

  const selectRole = (role: StaffPermissionRoleTypeEnum) => {
    const defaultPermissions =
      PermissionsService.getDefaultsPermissionsForRole(role);
    setStaffData({
      ...staffData,
      permission_data: {
        role_type: role,
        permissions: defaultPermissions,
        station_name: staffData?.permission_data?.station_name,
      },
    });
  };

  useEffect(() => {
    PermissionsService.redirectIfNoPermission(
      StaffPermissionPermissionsEnum.USER_READ,
    );
    if (!staffData) {
      getStaffData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderPermissionsListAsCheckBoxes = (
    title: string,
    permissionsToRender:
      | typeof OTHER_PERMISSIONS_LABELS
      | typeof OT_PERMISSIONS_LABELS,
  ) => {
    const dependencyStatus = transformDependencyData(
      calculateDependencyStatus(
        permissionsToRender,
        staffData!.permission_data!.permissions as string[],
      ),
      permissionLabels,
    );
    return (
      <>
        <Typography
          className={classes.permissionsTitle}
          variant='h6'
          component='h3'
        >
          {title}
        </Typography>
        <CSDualGridContainer containerSpacing={0}>
          {Object.keys(StaffPermissionPermissionsEnum)
            .filter((permission) =>
              Object.keys(permissionsToRender).includes(permission),
            )
            .map((key) => {
              return StaffPermissionPermissionsEnum[
                key as keyof typeof StaffPermissionPermissionsEnum
              ];
            })
            .map((permission) => {
              return (
                <Fragment
                  key={`fragment-${permissionLabels[permission]}`}
                >
                  {staffData?.permission_data?.permissions && (
                    <Tooltip
                      classes={{
                        tooltip: classes.tooltip,
                      }}
                      title={
                        dependencyStatus[permission].enabled
                          ? ''
                          : dependencyStatus[permission]
                              .tooltipMessage
                      }
                      placement='top-start'
                    >
                      <span>
                        <FormControlLabel
                          data-testid='select-user-permissions-checkboxes'
                          key={permissionLabels[permission]}
                          className={classes.formControl}
                          control={
                            <Checkbox
                              color='primary'
                              disabled={
                                !dependencyStatus[permission].enabled
                              }
                              data-testid={`permissions-checkbox-${permissionLabels[permission]}`}
                              key={`checkbox-${permissionLabels[permission]}`}
                              classes={{
                                root: classes.checkbox,
                                checked: classes.checked,
                              }}
                              checked={staffData.permission_data?.permissions.some(
                                (selectedPermission) =>
                                  selectedPermission === permission,
                              )}
                              onChange={(e) => {
                                const isAlreadySelected =
                                  staffData.permission_data?.permissions!.some(
                                    (selectedPermission) =>
                                      selectedPermission ===
                                      permission,
                                  );
                                if (isAlreadySelected) {
                                  const {
                                    newPermissionsState,
                                    disabledPermissions,
                                  } = disablePermissionAndDependants(
                                    permission,
                                    staffData!.permission_data!
                                      .permissions as string[],
                                  );
                                  //All dependencies are disabled, show notification to the user.
                                  if (
                                    disabledPermissions.length > 0
                                  ) {
                                    setShowDependencyNotification(
                                      true,
                                    );
                                    setDependencyNotificationMessage(
                                      `Your selection also disabled the dependant permission${
                                        disabledPermissions.length ===
                                        1
                                          ? ''
                                          : 's'
                                      }: ${disabledPermissions.join(
                                        ', ',
                                      )}.`,
                                    );
                                  }
                                  setStaffData({
                                    ...staffData,
                                    permission_data: {
                                      station_name:
                                        staffData?.permission_data
                                          ?.station_name,
                                      role_type:
                                        staffData.permission_data
                                          ?.role_type!,
                                      permissions: [
                                        ...newPermissionsState,
                                      ],
                                    },
                                  });
                                } else {
                                  setStaffData({
                                    ...staffData,
                                    permission_data: {
                                      station_name:
                                        staffData?.permission_data
                                          ?.station_name,
                                      role_type:
                                        staffData.permission_data
                                          ?.role_type!,
                                      permissions: [
                                        ...(staffData.permission_data
                                          ?.permissions || []),
                                        permission,
                                      ],
                                    },
                                  });
                                }
                                setEnableSave(true);
                              }}
                            />
                          }
                          label={permissionLabels[permission]}
                        />
                      </span>
                    </Tooltip>
                  )}
                </Fragment>
              );
            })}
        </CSDualGridContainer>
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort -
${stationStaffPermissionsLabels.singular}'s Permissions for ${
            staffData?.full_name || ''
          }`}
        </title>
      </Helmet>
      <Layout navCurrent='STAFF'>
        <Snackbar
          data-testid='snackbar-notification'
          key={dependencyNotificationMessage}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={showDependencyNotification}
          onClose={(event, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            setShowDependencyNotification(false);
          }}
          autoHideDuration={4000}
          message={dependencyNotificationMessage}
        />
        {showProgress && <ProgressIndicator />}
        {error && (
          <AlertBanner
            data-testid={'station-staff-permissions-error-banner'}
            className={classes.banner}
            severity='error'
            alertTitle={'Error'}
            alertMsg={error}
          />
        )}

        <Grid item xs={12}>
          <Grid item xs={12} sm={6}>
            <Box mb={5}>
              <CSBreadcrumbs
                breadcrumbs={[
                  {
                    label: stationStaffPermissionsLabels.plural,
                    link:
                      AuthRoutes.STAFF +
                      '?stationId=' +
                      // TODO: staffData does not include the station data
                      // Check CLOUD-3077
                      EphemeralStateService.getMyStationId(),
                  },
                  {
                    label: `${staffData?.full_name}`,
                    link:
                      AuthRoutes.STAFF +
                      '/' +
                      staffData?.id +
                      '?stationId=' +
                      EphemeralStateService.getMyStationId(),
                  },
                  {
                    label: 'Permissions',
                    selected: true,
                  },
                ]}
              />
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2} alignItems='flex-end'>
          <Grid item xs={12} sm={6}>
            <Typography variant='h3' component='h2'>
              {`${
                stationStaffPermissionsLabels.singular
              }'s Permissions for ID ${staffData?.id || ''}`}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            className={classes.titleButtonsContainer}
          >
            <CSButton
              className={classes.titleButtons}
              disabled={!staffData}
              fullWidth={false}
              variant='outlined'
              color='secondary'
              onClick={(e) => {
                e.preventDefault();

                setError(undefined);
                browserHistory.push(backUrl);
              }}
            >
              Cancel
            </CSButton>
            <CSButton
              className={classes.titleButtons}
              data-testid={'station-staff-permissions-save-button'}
              disabled={!staffData || !enableSave || !!error}
              fullWidth={false}
              variant='contained'
              color={{
                buttonColor: 'secondary',
                iconColor: 'primary',
              }}
              onClick={(e) => {
                e.preventDefault();
                setError(undefined);
                onSaveClick();
              }}
            >
              Save
            </CSButton>
          </Grid>
        </Grid>
        <CSSectionTitleSeparator topMargin={13} bottomMargin={25} />
        <Grid
          container
          spacing={2}
          className={classes.singleDetailContainer}
        >
          <Grid item xs={12}>
            <CSSingleDetailMultiColumnContainer
              disableStandardHeight
              columns={3}
              elements={[
                <SingleDetail
                  inline={true}
                  label={
                    stationStaffPermissionsLabels.singular + ' ID'
                  }
                  value={staffData?.id}
                />,
                <SingleDetail
                  inline={true}
                  label='Station'
                  value={
                    staffData?.permission_data?.station_name ||
                    common.emptyValue
                  }
                />,
                <SingleDetail
                  inline={true}
                  label='Label'
                  value={staffData?.full_name || common.emptyValue}
                />,
              ]}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12}>
          {staffData?.permission_data?.role_type && (
            <Paper
              className={clsx(classes.paper, classes.paperContainer)}
            >
              <CSSelect
                label='Role'
                containerSize='fullHorizontal'
                onChange={(e: React.BaseSyntheticEvent) => {
                  selectRole(e.target.value);
                  setEnableSave(true);
                }}
                value={staffData?.permission_data.role_type}
              >
                {Object.keys(StaffPermissionRoleTypeEnum)
                  .filter((key) => key.includes('OPERATOR'))
                  .map((key) => {
                    return {
                      label:
                        ROLE_LABELS[
                          key as keyof typeof StaffPermissionRoleTypeEnum
                        ],
                      value:
                        StaffPermissionRoleTypeEnum[
                          key as keyof typeof StaffPermissionRoleTypeEnum
                        ],
                    };
                  })
                  .map((permission) => {
                    return (
                      <MenuItem
                        key={`fragment-${permission.label}`}
                        value={permission.value}
                      >
                        {permission.label}
                      </MenuItem>
                    );
                  })}
              </CSSelect>
            </Paper>
          )}
          <br />
          {arePermissionsReady ? (
            <>
              <Paper
                className={clsx(
                  classes.paper,
                  classes.paperContainer,
                )}
              >
                {renderPermissionsListAsCheckBoxes(
                  'Operator Tool Permissions',
                  OT_PERMISSIONS_LABELS,
                )}
              </Paper>
              <br />
              <Paper
                className={clsx(
                  classes.paper,
                  classes.paperContainer,
                )}
              >
                {renderPermissionsListAsCheckBoxes(
                  'Other Permissions',
                  OTHER_PERMISSIONS_LABELS,
                )}
              </Paper>
            </>
          ) : (
            <ProgressIndicator />
          )}
        </Grid>
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...selectStyles,
    formControl: {
      width: '100%',
    },
    paperContainer: {
      padding: '16px',
    },
    titleButtons: {
      margin: '2px 2px 0 8px',
    },
    titleButtonsContainer: {
      textAlign: 'right',
    },
    singleDetailContainer: {
      marginBottom: '8px',
    },
    divider: {
      height: 2,
    },
    permissionsTitle: {
      marginTop: '10px',
      marginBottom: '15px',
    },
    tooltip: {
      background: theme.palette.secondary?.main,
      color: theme.palette.secondary?.contrastText,
      padding: '10px',
      borderRadius: theme.shape.borderRadius_sm,
      ...theme.typography.body2,
      boxShadow: theme.shadows[7],
    },
  })),
)(StationStaffDetailsComponent);
