import React, { useEffect, useMemo, useState } from 'react';
import {
  withStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles';
import {
  Typography,
  AlertBanner,
  CSButton,
  CSTextField,
} from '../primitives';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import Layout from '../layout/Layout';
import ErrorHandler from '../../utils/ErrorHandler';
import ConfirmationDialog from '../confirmationDialog/ConfirmationDialog';
import { AuthRoutes } from '../../interfaces/routes';
import browserHistory from '../../utils/browserHistory';
import EventsPaginatedTable from '../eventsPaginatedTable/EventsPaginatedTable';
import qrCodeUtils from '../../utils/qrCode';
import detailsPageStyles from '../commonStyles/detailsPage.style';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import StaffStats from './staffStats/StaffStats';
import validateEmail from '../../utils/validateEmail';
import ReAuthDialog from '../reAuthDialog/ReAuthDialog';
import { renderDuration } from '../DetailsPagesFunctions';
import { common } from '../../utils/strings';

// Icons
import DoneOutlineOutlinedIcon from '@material-ui/icons/DoneOutlineOutlined';
import FilterCenterFocusIcon from '@material-ui/icons/FilterCenterFocus';
import SettingsIcon from '@material-ui/icons/Settings';
import BlockIcon from '@material-ui/icons/Block';
import CreateIcon from '@material-ui/icons/Create';

// Types
import { match } from 'react-router-dom';
import { AxiosError } from 'axios';
import {
  Staff,
  StaffPermissionPermissionsEnum,
} from 'cloudsort-client';

// Services
import StaffService from '../../services/Staff.service';
import AuthService from '../../services/Auth.service';
import EventsService from '../../services/Events.service';
import PermissionsService from '../../services/Permissions.service';
import EphemeralStateService from '../../services/EphemeralState.service';
import configurationUtils from '../../utils/configurationUtils';
import SingleDetail from '../primitives/singleDetail/SingleDetail';
import { Helmet } from 'react-helmet';
import { BlockOutlined, Delete } from '@material-ui/icons';
import CSSectionTitleSeparator from '../primitives/csSectionTitleSeparator/CSSectionTitleSeparator';
import CSDialogTitleWithIcon from '../primitives/csDialogTitleWithIcon/CSDialogTitleWithIcon';
import { DialogIcons } from '../primitives/csDialogTitleWithIcon/CSDialogTitleWithIconTypes';
import { CSSingleDetailMultiColumnContainer } from '../primitives/singleDetail/singleDetailMultiColumnContainer';
import CSBreadcrumbs from '../primitives/CSBreadcrumbs/CSBreadcrumbs';

interface Props {
  classes: { [key: string]: string };
  match: match;
}

const StationStaffDetailsComponent: React.FC<Props> = ({
  classes,
  match,
}) => {
  const stationStaffDetailsLabels = useMemo(
    () => ({
      singular: configurationUtils.getPageTitle(true, 'STAFF'),
      plural: configurationUtils.getPageTitle(false, 'STAFF'),
    }),
    [],
  );
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [showDeleteDialog, setShowDeleteDialog] =
    useState<boolean>(false);
  const [showEditDialog, setShowEditDialog] =
    useState<boolean>(false);
  const [showReAuthDialog, setShowReAuthDialog] =
    useState<boolean>(false);
  const [staffData, setStaffData] = useState<Staff>();
  const [staffEditParams, setStaffEditParams] = useState<Staff>({
    first_name: '',
    last_name: '',
    email: '',
  });
  const [showInvalidateDialog, setShowInvalidateDialog] =
    useState<boolean>(false);
  const [showRedownloadDialog, setShowRedownloadDialog] =
    useState<boolean>(false);
  const [
    showEnableDisableUserDialog,
    setShowEnableDisableUserDialog,
  ] = useState<boolean>(false);

  const [error, setError] = useState<string>();

  const isMyUser = AuthService.getMyId() === staffData?.id;

  const isValidEmail = validateEmail(staffEditParams?.email || '');

  const enableDisableUser = async () => {
    try {
      setShowProgress(true);
      if (staffData?.id) {
        const stateObj = { ...staffData };
        stateObj.is_active = !stateObj.is_active;
        stateObj.email = undefined;
        stateObj.permission_data!.organization = undefined;
        await StaffService.update(stateObj.id!, stateObj);
        await getStaffData();
      }
    } catch (e) {
      handleError(e as AxiosError);
    }
    setShowEnableDisableUserDialog(false);
    setShowProgress(false);
  };

  const getStaffData = async () => {
    setShowProgress(true);
    try {
      const { data } = await StaffService.getById(
        (match.params as any).id,
        EphemeralStateService.getMyStationId(),
      );
      setStaffData(data);
      setStaffEditParams(data);
    } catch (e) {
      handleError(e as AxiosError);
    }
    setShowProgress(false);
  };

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e as AxiosError));
  };

  const deleteStaff = async () => {
    setShowProgress(true);
    try {
      if (staffData?.id) {
        await StaffService.delete(staffData?.id);

        browserHistory.push(AuthRoutes.STAFF);
      }
    } catch (e) {
      handleError(e as AxiosError);
      setShowProgress(false);
    }
  };

  const fetchEvents = async (
    pageIndex: number,
    rowsPerPage: number,
  ) => {
    return EventsService.getAll({
      page: pageIndex,
      user: staffData?.id,
      pageSize: rowsPerPage,
    });
  };

  const onGetQrCick = async () => {
    setShowProgress(true);
    try {
      if (staffData?.id) {
        qrCodeUtils.download(
          await StaffService.getLabel(staffData.id),
        );
      }
    } catch (e) {
      handleError(e as AxiosError);
    }
    setShowProgress(false);
  };

  const invalidateBadge = async () => {
    setShowProgress(true);
    try {
      if (staffData?.id) {
        await StaffService.invalidateBadge(staffData?.id).then(() => {
          setShowRedownloadDialog(true);
        });
      }
    } catch (e) {
      handleError(e as AxiosError);
    }
    setShowProgress(false);
    setShowInvalidateDialog(false);
  };

  const processEditForm = async () => {
    try {
      setShowProgress(true);
      const reqParams = { ...staffEditParams };

      //if not editing email, remove it from the request
      if (staffEditParams.email === staffData?.email) {
        reqParams.email = undefined;
      }
      reqParams.permission_data = undefined;
      if (staffData?.id) {
        await StaffService.update(staffData.id!, reqParams);
        await getStaffData();
      }
    } catch (e) {
      handleError(e as AxiosError);
    }
    setShowEditDialog(false);
    setShowProgress(false);
  };

  useEffect(() => {
    if (!staffData) {
      getStaffData();
    }
    PermissionsService.redirectIfNoPermission(
      StaffPermissionPermissionsEnum.USER_READ,
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderForm = () => {
    const warningText =
      !!staffEditParams?.email && !isValidEmail
        ? 'Please enter a valid email.'
        : `If you update an email, the ${stationStaffDetailsLabels.singular} will need to verify it before they can log in with it.`;

    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <CSTextField
            className={classes.formField}
            label='First Name'
            autoFocus
            containerSize='fullHorizontal'
            placeholder='First Name'
            value={staffEditParams?.first_name}
            onChange={(e) => {
              setStaffEditParams({
                ...staffEditParams,
                first_name: e.target.value,
              });
            }}
            onClear={() => {
              setStaffEditParams({
                ...staffEditParams,
                first_name: '',
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <CSTextField
            className={classes.formField}
            label='Last Name'
            placeholder='Last Name'
            containerSize='fullHorizontal'
            value={staffEditParams?.last_name}
            onChange={(e) => {
              setStaffEditParams({
                ...staffEditParams,
                last_name: e.target.value,
              });
            }}
            onClear={() => {
              setStaffEditParams({
                ...staffEditParams,
                last_name: '',
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <CSTextField
            className={classes.formField}
            label='Email Address'
            placeholder='Email Address'
            containerSize='fullHorizontal'
            value={staffEditParams?.email}
            onChange={(e) => {
              setStaffEditParams({
                ...staffEditParams,
                email: e.target.value,
              });
            }}
            onClear={() => {
              setStaffEditParams({
                ...staffEditParams,
                email: '',
              });
            }}
            customContent={
              <AlertBanner
                className={classes.banner}
                severity='info'
                alertMsg={warningText}
              />
            }
            error={!!staffEditParams?.email && !isValidEmail}
          />
        </Grid>
      </Grid>
    );
  };

  const getConfirmationMessage = (action: string) =>
    `Are you sure you want to ${action} ${stationStaffDetailsLabels.singular.toLowerCase()} ${
      staffData?.full_name
    }?`;

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort -
${stationStaffDetailsLabels.singular} Details for ${
            staffData?.full_name || ''
          }`}
        </title>
      </Helmet>
      <Layout navCurrent='STAFF'>
        {showProgress && <ProgressIndicator />}
        {error && (
          <AlertBanner
            data-testid='station-staff-details-error-banner'
            className={classes.banner}
            severity='error'
            alertTitle={'Error'}
            alertMsg={error}
          />
        )}
        <ConfirmationDialog
          dataTestIdPrefix={'station-staff-details-'}
          data-testid={'station-staff-details-delete-dialog'}
          title={`Delete ${stationStaffDetailsLabels.singular}`}
          msg={getConfirmationMessage('delete')}
          primaryActionLabel='Confirm'
          onPrimaryAction={deleteStaff}
          cancelLabel='Cancel'
          onCancel={() => {
            setShowDeleteDialog(false);
          }}
          isOpen={showDeleteDialog}
        />
        <ConfirmationDialog
          dataTestIdPrefix={'station-staff-details-invalidate'}
          data-testid={'station-staff-details-invalidate-dialog'}
          title={'Invalidate Badge'}
          msg={
            'This will invalidate existing badges and a new one will need to be printed.'
          }
          primaryActionLabel='Confirm'
          onPrimaryAction={() => {
            invalidateBadge();
          }}
          cancelLabel='Cancel'
          onCancel={() => {
            setShowInvalidateDialog(false);
          }}
          isOpen={showInvalidateDialog}
        />
        <ConfirmationDialog
          dataTestIdPrefix={'station-staff-details-redownload'}
          data-testid={'station-staff-details-redownload-dialog'}
          title={'Badge Invalidated'}
          msg={`The badge has been invalidated for the ${stationStaffDetailsLabels.singular}. Would you like to download a new one?`}
          primaryActionLabel='Confirm'
          onPrimaryAction={() => {
            onGetQrCick();
          }}
          cancelLabel='Cancel'
          onCancel={() => {
            setShowRedownloadDialog(false);
          }}
          isOpen={showRedownloadDialog}
        />
        <ConfirmationDialog
          dataTestIdPrefix={
            'station-staff-details-enable-disable-user'
          }
          data-testid={'station-staff-details-enable-disable-user'}
          title={
            staffData?.is_active
              ? `Disable ${stationStaffDetailsLabels.singular}`
              : `Enable ${stationStaffDetailsLabels.singular}`
          }
          msg={getConfirmationMessage(
            staffData?.is_active ? 'disable' : 'enable',
          )}
          primaryActionLabel='Confirm'
          onPrimaryAction={() => {
            enableDisableUser();
          }}
          cancelLabel='Cancel'
          onCancel={() => {
            setShowEnableDisableUserDialog(false);
          }}
          isOpen={showEnableDisableUserDialog}
        />

        <Grid item xs={12}>
          <Grid item xs={12} sm={6}>
            <Box mb={5}>
              <CSBreadcrumbs
                breadcrumbs={[
                  {
                    label: stationStaffDetailsLabels.plural,
                    link:
                      AuthRoutes.STAFF +
                      '?stationId=' +
                      // TODO: staffData does not include the station data
                      // Check CLOUD-3077
                      EphemeralStateService.getMyStationId(),
                  },
                  {
                    label: `${staffData?.full_name}`,
                    selected: true,
                  },
                ]}
              />
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          className={classes.gridSeparation}
          data-testid={'station-staff-details-header'}
        >
          <Grid item xs={12} sm={5}>
            <Typography variant='h3' component='h2'>
              {`${configurationUtils.getPageTitle(
                true,
                'STAFF',
              )} Details for ${staffData?.full_name || ''}`}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={7} className={classes.gridAlignText}>
            {PermissionsService.hasPermission(
              StaffPermissionPermissionsEnum.USER_WRITE,
            ) && (
              <>
                <CSButton
                  variant='outlined'
                  color='secondary'
                  disabled={!staffData || isMyUser}
                  data-testid={'station-staff-details-delete-button'}
                  className={classes.titleButtons}
                  fullWidth={false}
                  onClick={(e) => {
                    e.preventDefault();
                    setError(undefined);
                    setShowDeleteDialog(true);
                  }}
                  startIcon={<Delete />}
                >
                  Delete
                </CSButton>
                <CSButton
                  variant='outlined'
                  color='secondary'
                  disabled={!staffData}
                  data-testid={'station-staff-details-edit-button'}
                  className={classes.titleButtons}
                  fullWidth={false}
                  onClick={(e) => {
                    e.preventDefault();
                    setError(undefined);
                    setShowEditDialog(true);
                  }}
                  startIcon={<CreateIcon />}
                >
                  Edit
                </CSButton>

                <CSButton
                  disabled={!staffData}
                  fullWidth={false}
                  variant='outlined'
                  color='secondary'
                  className={classes.titleButtons}
                  onClick={(e) => {
                    e.preventDefault();
                    browserHistory.push(
                      `${AuthRoutes.STAFF}/permissions/${
                        (match.params as any).id
                      }`,
                    );
                  }}
                  startIcon={<SettingsIcon />}
                >
                  Users Permissions
                </CSButton>
              </>
            )}
            {PermissionsService.hasPermission(
              StaffPermissionPermissionsEnum.USER_LOCK,
            ) && (
              <CSButton
                disabled={!staffData}
                fullWidth={false}
                variant='outlined'
                color='secondary'
                className={classes.titleButtons}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setShowEnableDisableUserDialog(true);
                }}
                startIcon={
                  staffData?.is_active ? (
                    <BlockOutlined />
                  ) : (
                    <DoneOutlineOutlinedIcon />
                  )
                }
              >
                {staffData?.is_active
                  ? `Disable ${stationStaffDetailsLabels.singular}`
                  : `Enable ${stationStaffDetailsLabels.singular}`}
              </CSButton>
            )}
          </Grid>
          <CSSectionTitleSeparator topMargin={5} bottomMargin={10} />
        </Grid>
        <div
          className={classes.subHeader}
          data-testid={'station-staff-details-sub-header'}
        >
          <div className={classes.buttonContainer}>
            {PermissionsService.hasPermission(
              StaffPermissionPermissionsEnum.USER_BADGE_INVALIDATE,
            ) && (
              <CSButton
                data-testid={'station-staff-invalidate-badge'}
                disabled={!staffData}
                fullWidth={false}
                variant='contained'
                color='primary'
                onClick={(e) => {
                  e.preventDefault();
                  setError(undefined);
                  setShowInvalidateDialog(true);
                }}
                startIcon={<BlockIcon />}
              >
                Invalidate Badge
              </CSButton>
            )}
            {PermissionsService.hasPermission(
              StaffPermissionPermissionsEnum.USER_BADGE_READ,
            ) && (
              <CSButton
                data-testid={'station-staff-details-qr'}
                disabled={!staffData}
                fullWidth={false}
                variant='contained'
                color='primary'
                className={classes.buttonSeparation}
                onClick={(e) => {
                  e.preventDefault();
                  setError(undefined);
                  onGetQrCick();
                }}
                startIcon={<FilterCenterFocusIcon />}
              >
                Badge
              </CSButton>
            )}
          </div>
        </div>

        <Grid
          container
          spacing={2}
          className={classes.gridSeparation}
        >
          <Grid item xs={12} data-testid={'station-staff-details'}>
            <CSSingleDetailMultiColumnContainer
              columns={3}
              disableStandardHeight
              elements={[
                <SingleDetail
                  inline={true}
                  label='Name'
                  value={staffData?.full_name}
                />,
                <SingleDetail
                  inline={true}
                  label='Daily Totals'
                  value={staffData?.daily_totals || common.emptyValue}
                />,
                configurationUtils.isModuleActive('AREA') ? (
                  <SingleDetail
                    inline={true}
                    label={configurationUtils.getPageTitle(
                      true,
                      'AREA',
                    )}
                    value={staffData?.area_name || common.emptyValue}
                  />
                ) : undefined,
                <SingleDetail
                  inline={true}
                  label='Station'
                  value={
                    staffData?.permission_data?.station_name ||
                    common.emptyValue
                  }
                />,
                <SingleDetail
                  inline={true}
                  label={configurationUtils.getPageTitle(
                    true,
                    'DEVICE',
                  )}
                  value={staffData?.device_id || common.emptyValue}
                />,
                <SingleDetail
                  inline={true}
                  label='Uptime'
                  value={
                    staffData?.active_since && staffData?.active
                      ? renderDuration(
                          (new Date().getTime() -
                            new Date(
                              staffData?.active_since!,
                            ).getTime()) /
                            1000,
                        )
                      : common.emptyValue
                  }
                />,
                <SingleDetail
                  inline={true}
                  label='ID'
                  value={staffData?.id}
                />,
              ]}
            />
          </Grid>
        </Grid>

        <Typography
          className={classes.titleSpacing}
          variant='h6'
          component='h2'
        >
          {stationStaffDetailsLabels.singular} Statistics
        </Typography>
        <StaffStats staffId={(match.params as any).id} />

        {!!staffData && (
          <div className={classes.tableSeparation}>
            <EventsPaginatedTable
              dataTestIdPrefix={'station-staff-details-events'}
              fetch={fetchEvents}
            />
          </div>
        )}

        <Dialog open={showEditDialog}>
          <DialogTitle>
            <CSDialogTitleWithIcon
              title={`Edit ${configurationUtils.getPageTitle(
                true,
                'STAFF',
              )} ${staffData?.first_name} ${staffData?.last_name}`}
              icon={DialogIcons.EDIT}
            />
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            {renderForm()}
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Box pl={1} pr={1}>
              <CSButton
                variant='outlined'
                color='secondary'
                data-testid={
                  'station-staff-details-edit-dialog-cancel'
                }
                className={classes.cancelButton}
                onClick={() => {
                  setShowEditDialog(false);
                }}
              >
                Cancel
              </CSButton>

              <CSButton
                variant='contained'
                color='secondary'
                data-testid={
                  'station-staff-details-edit-dialog-update'
                }
                disabled={
                  !staffEditParams.first_name ||
                  !staffEditParams.last_name ||
                  !staffEditParams.email ||
                  !validateEmail(staffEditParams.email)
                }
                onClick={() => {
                  //if email is not changed, don't show re-auth dialog
                  if (staffData?.email === staffEditParams.email)
                    processEditForm();
                  else {
                    setShowEditDialog(false);
                    setShowReAuthDialog(true);
                  }
                }}
              >
                Update
              </CSButton>
            </Box>
          </DialogActions>
        </Dialog>
        <ReAuthDialog
          isOpen={showReAuthDialog}
          onAfterClose={() => {
            setShowReAuthDialog(false);
            setShowEditDialog(true);
          }}
          callbackFn={() => {
            setShowReAuthDialog(false);
            processEditForm();
          }}
        />
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    subHeader: {
      width: '100%',
      display: 'flex',
      marginBottom: 20,
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    formField: {
      marginTop: 5,
    },
    dialogContent: { width: '500px', maxWidth: '100%' },
    dialogActions: {
      padding: '16px 23px 25px 8px',
    },
    banner: {
      width: '100%',
      margin: '20px 0 30px 0',
    },
    titleButtons: {
      margin: '2px 2px 2px 8px',
    },
    titleSpacing: {
      margin: '16px 0px',
    },
    gridSeparation: {
      marginBottom: 8,
    },
    gridAlignText: {
      textAlign: 'right',
    },
    tableSeparation: {
      marginTop: 24,
    },
    buttonSeparation: {
      marginLeft: 10,
    },
    buttonContainer: {
      display: 'flex',
    },
    cancelButton: {
      marginRight: 16,
    },
  })),
)(StationStaffDetailsComponent);
