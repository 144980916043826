import {
  FormControl,
  FormHelperText,
  InputLabel,
  useTheme,
  withStyles,
} from '@material-ui/core';
import * as React from 'react';
import {
  COMPONENT_NAME,
  CSAsyncSelectProps,
} from './CSAsyncSelectTypes';
import {
  asyncSelectStyles,
  getFormHelperTextStyles,
  getInputLabelStyles,
} from './CSAsyncSelect.styles';
import useStableId from '../../../utils/useStableId';
import AsyncSelect from 'react-select/async';
import {
  ClearIndicatorProps,
  components,
  DropdownIndicatorProps,
  GroupBase,
  MultiValueRemoveProps,
} from 'react-select';
import clsx from 'clsx';
import { ArrowDropDown, Clear } from '@material-ui/icons';
import { materialTextFieldConfiguration } from '../csTextField/CSTextFieldUtils';
import { getFormControlStyles } from '../csTextField/CSTextField.styles';
import CancelIcon from '@material-ui/icons/Cancel';

const CSAsyncSelect = <T extends {}, IsMulti extends boolean = false>(
  props: CSAsyncSelectProps<T, IsMulti>,
) => {
  const {
    id = '',
    className = '',
    label,
    name,
    onClear,
    styles,
    hideClear,
    helperText,
    endAdornment,
    InputLabelProps,
    disabled,
    onChange,
    loadOptions,
    containerSize = 'standard',
    color,
    variant = 'standard-outlined',
    'data-testid': dataTestId,
    customContent,
    error,
    ...others
  } = props;
  const theme = useTheme();
  const inputId = useStableId(id, COMPONENT_NAME);

  const ThemedInputLabel = React.useMemo(
    () =>
      withStyles(getInputLabelStyles(theme), {
        name: 'CSInputLabel',
      })(InputLabel),
    [theme],
  );

  const ThemedFormControl = React.useMemo(
    () =>
      withStyles(getFormControlStyles(containerSize), {
        name: 'CSTextFormControl',
      })(FormControl),
    [containerSize],
  );

  const { formControlVariant, formLabelVariant } =
    materialTextFieldConfiguration(variant);
  const asyncSelectClassNames = asyncSelectStyles(
    theme,
    variant,
    color,
    error,
  );

  const ClearIndicator = (
    props: React.PropsWithChildren<
      ClearIndicatorProps<T, IsMulti, GroupBase<T>>
    >,
  ) => {
    return (
      <components.ClearIndicator {...props}>
        <Clear />
        <div>{props.children}</div>
      </components.ClearIndicator>
    );
  };

  const DropdownIndicator = (
    props: React.PropsWithChildren<
      DropdownIndicatorProps<T, IsMulti, GroupBase<T>>
    >,
  ) => {
    return (
      <components.DropdownIndicator {...props}>
        <ArrowDropDown />
        {props.children}
      </components.DropdownIndicator>
    );
  };

  const MultiValueRemove = (
    props: React.PropsWithChildren<
      MultiValueRemoveProps<T, IsMulti, GroupBase<T>>
    >,
  ) => {
    return (
      <components.MultiValueRemove {...props}>
        <CancelIcon
          className={asyncSelectClassNames.removeItemIcon}
        />
      </components.MultiValueRemove>
    );
  };

  const ThemedFormHelperText = React.useMemo(
    () =>
      withStyles(getFormHelperTextStyles(), {
        name: 'CSFormHelperText',
      })(FormHelperText),
    [],
  );

  return (
    <ThemedFormControl
      className={className}
      data-testid={dataTestId}
      variant={formControlVariant}
      disabled={disabled}
      error={error}
    >
      {label && (
        <ThemedInputLabel
          variant={formLabelVariant}
          htmlFor={inputId}
          {...InputLabelProps}
        >
          {label}
        </ThemedInputLabel>
      )}
      <AsyncSelect<T, IsMulti>
        className={clsx(asyncSelectClassNames.container, {
          [asyncSelectClassNames.labelledContainer]: label,
          [asyncSelectClassNames.labelessContainer]: !label,
        })}
        classNames={{
          menuPortal: () =>
            clsx(
              asyncSelectClassNames.menuPortal,
              asyncSelectClassNames.menuPortalOpen,
            ),
        }}
        loadOptions={loadOptions}
        onChange={onChange}
        components={{
          ClearIndicator,
          DropdownIndicator,
          MultiValueRemove,
        }}
        classNamePrefix='CSAsyncSelect'
        isDisabled={disabled}
        {...others}
      />
      {helperText && (
        <ThemedFormHelperText id={`${inputId}Helper-Text`}>
          {helperText}
        </ThemedFormHelperText>
      )}
      {customContent}
    </ThemedFormControl>
  );
};

export default CSAsyncSelect;
