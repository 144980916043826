import React, { useEffect, useState } from 'react';
import {
  withStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles';
import Layout from '../layout/Layout';
import ReAuthDialog from '../reAuthDialog/ReAuthDialog';
import colors from '../../utils/colors';
import { Grid, Paper, Tabs } from '@material-ui/core';
import { CSButton } from '../primitives';
import ErrorHandler from '../../utils/ErrorHandler';
import browserHistory from '../../utils/browserHistory';
import { AuthRoutes } from '../../interfaces/routes';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import { useParams } from 'react-router-dom';
import AssignUserToEntityDialog from '../usersGodView/addUser/AddUserDialog';
import detailsPageStyle from '../commonStyles/detailsPage.style';

//Sub-components
import PersonalInfo from './PersonalInfo';
import RolesPermissions from './RolesPermissions';

//Types
import {
  Staff,
  StaffPermissionPermissionsEnum,
} from 'cloudsort-client';

//Services
import StaffService from '../../services/Staff.service';
import AuthService from '../../services/Auth.service';
import PermissionsService from '../../services/Permissions.service';
import LocalStorageService from '../../services/LocalStorage.service';
import EphemeralStateService from '../../services/EphemeralState.service';

//Icons
import { Helmet } from 'react-helmet';
import CSTab from '../configuration/CSTab/CSTab';
import { getBaseNavigationItems } from '../configuration/Customers/utils';
import {
  CSBreadcrumbItem,
  CSBreadcrumbSelectedItem,
} from '../primitives/CSBreadcrumbs/CSBreadcrumbs.types';
import CSBreadcrumbs from '../primitives/CSBreadcrumbs/CSBreadcrumbs';
import CSDialogAlert from '../primitives/csDialogAlert/CSDialogAlert';
import { AxiosError } from 'axios';

interface Props {
  classes: { [key: string]: string };
}

export interface permissionEntity {
  id?: number;
  name?: string;
  type: 'STATION' | 'ORGANIZATION';
}

enum TabsEnum {
  PERSONAL_INFO,
  PERMISSIONS,
  NOTIFICATIONS,
  LANGUAGE,
}

const Profile: React.FC<Props> = ({ classes }) => {
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [showAssignDialog, setShowAssignDialog] =
    useState<boolean>(false);
  const [staffData, setStaffData] = useState<Staff>();
  const [staffEditData, setStaffEditData] = useState<Staff>({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
  });
  const [error, setError] = useState<string>('');
  const [prompt, setPrompt] = useState<string>();
  const [enableSave, setEnableSave] = useState<boolean>(false);

  const [showReAuthDialog, setShowReAuthDialog] =
    useState<boolean>(false);
  const [tab, setTab] = useState<TabsEnum>(TabsEnum.PERSONAL_INFO);
  const [assignedEntities, setAssignedEntities] = useState<
    permissionEntity[]
  >([]);
  const [selectedPermissionEntity, setSelectedPermissionEntity] =
    useState<permissionEntity>();

  const [breadcrumbsArray, setBreadcrumbsArray] = useState<
    Array<CSBreadcrumbItem | CSBreadcrumbSelectedItem>
  >([]);

  const urlParams: any = useParams();
  const isOwnProfile = urlParams.userId === undefined;

  const getProfileData = async (
    stationId?: number,
    orgId?: number,
  ) => {
    setShowProgress(true);

    if (!stationId && urlParams.stationId) {
      stationId = urlParams.stationId;
    } else if (!stationId && isOwnProfile) {
      stationId = EphemeralStateService.getMyStationId();
    }
    if (!orgId && !stationId && urlParams.orgId) {
      orgId = urlParams.orgId;
    }

    try {
      const data = (
        await StaffService.getById(
          isOwnProfile ? AuthService.getMyId() : urlParams.userId,
          stationId,
          orgId,
        )
      ).data;

      if (data.permission_data === null)
        data.permission_data = {
          role_type: undefined,
          permissions: [],
        };

      setStaffData(data);

      const assignedOrgs = data.assigned_organizations?.map((org) => {
        return {
          id: org.id,
          name: `Organization ${org.name}`,
          type: 'ORGANIZATION' as 'STATION' | 'ORGANIZATION',
        };
      });

      const assignedStations = data.assigned_stations?.map(
        (station) => {
          return {
            id: station.id!,
            name: `Station ${station.name}`,
            type: 'STATION' as 'STATION' | 'ORGANIZATION',
          };
        },
      );

      setAssignedEntities([
        ...(assignedOrgs || []),
        ...(assignedStations || []),
      ]);

      if (stationId) {
        const station = assignedStations?.find(
          (station) => station.id === Number(stationId),
        );

        station && setSelectedPermissionEntity(station);
      } else if (orgId) {
        const org = assignedOrgs?.find(
          (org) => org.id === Number(orgId),
        );

        org && setSelectedPermissionEntity(org);
      }

      //edit params
      setStaffEditData({ ...data, password: '' });

      //get breadcrumbs
      getAndSetBreadcrumbItems(
        data?.full_name || data?.first_name || '',
      );
    } catch (e) {
      setError(await ErrorHandler.getLabel(e as AxiosError));
    }
    setShowProgress(false);
  };

  const processUpdateForm = async () => {
    setShowProgress(true);
    try {
      //Update profile
      const data = await StaffService.update(staffData?.id!, {
        ...staffData,
        first_name: staffEditData.first_name,
        last_name: staffEditData.last_name,
        email:
          staffEditData.email !== staffData!.email
            ? staffEditData.email
            : undefined,
        password:
          staffEditData.password !== ''
            ? staffEditData.password
            : undefined,
        permission_data: {
          ...staffData?.permission_data,
          station:
            selectedPermissionEntity?.type === 'STATION'
              ? staffData?.permission_data?.station
              : undefined,
          organization:
            selectedPermissionEntity?.type === 'ORGANIZATION'
              ? staffData?.permission_data?.organization
              : undefined,
        },
      });
      setStaffData(data.data);
      if (isOwnProfile)
        LocalStorageService.setMyFullName(
          data.data.first_name + ' ' + data.data.last_name,
        );

      //edit params
      setStaffEditData({ ...data.data, password: '' });

      //set prompt
      setPrompt(
        `${
          isOwnProfile ? 'Your' : 'The'
        } profile has been updated. If you updated an email address, it'll need to be verified before it becomes active.`,
      );
    } catch (e) {
      setError(await ErrorHandler.getLabel(e as AxiosError));
    } finally {
      setShowProgress(false);
    }
  };

  const onAssignStationStaff = async (
    staffParams: Staff,
    onAfterDialogClose: () => void,
  ) => {
    setError('');
    setShowProgress(true);
    try {
      staffParams.email = undefined;
      await StaffService.update(staffParams.id!, staffParams);

      onAfterDialogClose();
      getProfileData();
    } catch (e) {
      setError(await ErrorHandler.getLabel(e as AxiosError));
    } finally {
      setShowProgress(false);
    }
  };

  const handleTabChange = (event: any, newValue: number) => {
    setTab(newValue);
  };

  const getAndSetBreadcrumbItems = async (staffName: string) => {
    try {
      const { baseURL, navItems } = await getBaseNavigationItems(
        urlParams.orgId,
        urlParams.stationId,
      );
      const staffURL = `${baseURL}/staff`;
      navItems.push(
        {
          label: 'Staff',
          link: staffURL,
        },
        {
          label: staffName || '',
          selected: true,
        },
      );
      setBreadcrumbsArray(navItems);
    } catch (err) {
      console.error(err);
      setBreadcrumbsArray([]);
    }
  };

  useEffect(() => {
    getProfileData();

    PermissionsService.redirectIfNoPermission(
      StaffPermissionPermissionsEnum.STATION_USER_SELF_WRITE,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {`CloudSort
${staffData ? '- ' + staffData.full_name + `'s profile` : ''}`}
        </title>
      </Helmet>
      <Layout
        layoutType={isOwnProfile ? 'profile' : 'default'}
        fullName={staffData?.first_name + ' ' + staffData?.last_name}
      >
        {showProgress && <ProgressIndicator />}

        <AssignUserToEntityDialog
          isOpen={showAssignDialog}
          onAfterDialogClose={() => {
            setShowAssignDialog(false);
          }}
          onAddStaff={onAssignStationStaff}
          error={error}
          initialStaff={staffData}
        />
        {staffData && (
          <>
            <Grid container>
              <Grid item xs={12}>
                <CSBreadcrumbs breadcrumbs={breadcrumbsArray} />
              </Grid>

              <Grid item xs={12} className={classes.marginTop20}>
                {error && (
                  <CSDialogAlert
                    data-testid='update-profile-error'
                    alertMessage={error}
                  />
                )}
                {prompt && (
                  <CSDialogAlert
                    data-testid='update-profile-prompt'
                    alertMessage={prompt}
                  />
                )}
              </Grid>

              <Grid item xs={12} className={classes.marginTop20}>
                <Paper className={classes.paperWithoutShadow}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Tabs
                        value={tab}
                        onChange={handleTabChange}
                        variant='scrollable'
                        classes={{ indicator: classes.tabsIndicator }}
                      >
                        <CSTab label='Staff Info' />
                        {EphemeralStateService.getIsRoleSuperUser() && (
                          <CSTab label='Roles & Permissions' />
                        )}
                      </Tabs>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      className={classes.headerButtonsContainer}
                    >
                      <CSButton
                        data-testid={'profile-cancel-button'}
                        variant='outlined'
                        color='secondary'
                        onClick={(e) => {
                          isOwnProfile
                            ? browserHistory.push(
                                AuthRoutes.DASHBOARD,
                              )
                            : browserHistory.goBack();
                        }}
                        style={{ marginRight: '10px' }}
                      >
                        Cancel
                      </CSButton>
                      {PermissionsService.hasPermission(
                        StaffPermissionPermissionsEnum.STATION_USER_SELF_WRITE,
                      ) && (
                        <CSButton
                          data-testid='profile-save-button'
                          variant='contained'
                          disabled={!showProgress && !enableSave}
                          color={{
                            buttonColor: 'secondary',
                            iconColor: 'primary',
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            if (
                              staffData?.email !==
                                staffEditData.email ||
                              staffEditData.password !== ''
                            )
                              setShowReAuthDialog(true);
                            else processUpdateForm();
                          }}
                        >
                          Save
                        </CSButton>
                      )}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

              <Grid item xs={12} className={classes.marginTop20}>
                {tab === TabsEnum.PERSONAL_INFO && staffData && (
                  <PersonalInfo
                    isOwnProfile={isOwnProfile}
                    staffData={staffData}
                    staffEditData={staffEditData}
                    setStaffEditData={setStaffEditData}
                    setError={setError}
                    setPrompt={setPrompt}
                    setEnableSave={setEnableSave}
                    setShowAssignDialog={setShowAssignDialog}
                  />
                )}
                {tab === TabsEnum.PERMISSIONS && (
                  <RolesPermissions
                    isOwnProfile={isOwnProfile}
                    staffData={staffData}
                    setStaffData={(state) => {
                      setPrompt(undefined);
                      setStaffData(state);
                    }}
                    setEnableSave={setEnableSave}
                    getProfileData={getProfileData}
                    assignedEntities={assignedEntities}
                    selectedEntity={selectedPermissionEntity}
                    setSelectedEntity={(state) => {
                      setPrompt(undefined);
                      setSelectedPermissionEntity(state);
                    }}
                  />
                )}
              </Grid>
            </Grid>
          </>
        )}
        <ReAuthDialog
          isOpen={showReAuthDialog}
          onAfterClose={() => {
            setShowReAuthDialog(false);
          }}
          callbackFn={() => {
            setShowReAuthDialog(false);
            processUpdateForm();
          }}
        />
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyle,

    tabsIndicator: {
      display: 'none',
    },
    paperWithoutShadow: {
      width: '100%',
      height: 'auto',
      borderRadius: 4,
      boxShadow: 'none',
      backgroundColor: colors.white,
      [theme.breakpoints.down('md')]: {
        backgroundColor: colors.veryLightGray,
      },
    },
    marginTop20: {
      marginTop: 20,
    },
    headerButtonsContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'right',
      paddingRight: 20,
    },
  })),
)(Profile);
