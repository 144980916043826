import React, { useState, useEffect } from 'react';
import {
  Box,
  createStyles,
  Fade,
  Grid,
  Menu,
  MenuItem,
  Paper,
  TablePagination,
  Theme,
  Typography,
  withStyles,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import detailsPageStyles from '../commonStyles/detailsPage.style';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import { AlertBanner, CSTextField } from '../primitives';
import ErrorHandler from '../../utils/ErrorHandler';
import { AxiosError } from 'axios';
import clsx from 'clsx';

interface FetchResponse {
  data: JSX.Element[];
  count: number;
  errors?: any;
}
interface Props {
  classes: { [key: string]: string };
  dataTestIdPrefix?: string;
  title: string;
  fetch: (
    pageIndex: number,
    rowsPerPage: number,
    filterByString: string,
  ) => Promise<FetchResponse>;
  rowsPerPage: number;
  optionsMenu?: { label: string; callback: () => void }[];
  lastUpdateTimestamp: number;
}
const PaginatedList: React.FC<Props> = ({
  classes,
  dataTestIdPrefix = '',
  title,
  fetch,
  rowsPerPage = 1,
  optionsMenu,
  lastUpdateTimestamp,
}) => {
  const [data, setData] = useState<any[]>([]);
  const [error, setError] = useState<string>();
  const [showProgress, setShowProgress] = useState(false);
  const [count, setCount] = useState(0);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [optionsMenuAnchorEl, setOptionsMenuAnchorEl] =
    React.useState<null | SVGElement>(null);

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e as AxiosError));
  };

  const fetchAndSetData = async (
    currentIndex: number,
    search: string = '',
  ) => {
    setShowProgress(true);
    const res = await fetch(currentIndex, rowsPerPage, search);
    if (res.errors) {
      handleError(res.errors);
    } else {
      setData(res.data);
      setCount(res.count);
      setError(undefined);
    }
    setShowProgress(false);
  };

  useEffect(() => {
    fetchAndSetData(currentPageIndex, searchQuery);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageIndex]);

  useEffect(() => {
    //Reset the state, then fetch data
    setCurrentPageIndex(0);
    setSearchQuery('');
    setShowSearchInput(false);

    fetchAndSetData(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastUpdateTimestamp]);

  const renderSettingsMenu = () => {
    return (
      <>
        <MoreVertIcon
          data-testid={`${dataTestIdPrefix}-open-options-menu-btn`}
          onClick={(event) => {
            setOptionsMenuAnchorEl(event.currentTarget);
          }}
          className={classes.optionsIcon}
        />
        <Menu
          anchorEl={optionsMenuAnchorEl}
          keepMounted
          open={Boolean(optionsMenuAnchorEl)}
          onClose={() => {
            setOptionsMenuAnchorEl(null);
          }}
          TransitionComponent={Fade}
        >
          {optionsMenu?.map((menuItem, index) => (
            <MenuItem
              key={index}
              onClick={() => {
                setOptionsMenuAnchorEl(null);
                menuItem.callback();
              }}
              data-testid={`${dataTestIdPrefix}-options-menu-item-${menuItem.label}`}
            >
              {menuItem.label}
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  };
  return (
    <>
      {showProgress && <ProgressIndicator />}
      <Paper
        className={clsx(classes.paper, classes.spaceBetween)}
        data-testid={`${dataTestIdPrefix}-card`}
      >
        <Box>
          <Grid container spacing={0} className={classes.header}>
            <Grid item xs={12} sm={9}>
              <Typography
                data-testid={`${dataTestIdPrefix}-card-title`}
                variant='h6'
              >
                {title}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3} className={classes.alignRight}>
              <>
                <SearchIcon
                  className={classes.searchIcon}
                  data-testid={`${dataTestIdPrefix}-card-search-icon`}
                  onClick={() => {
                    setShowSearchInput(!showSearchInput);
                  }}
                />

                {optionsMenu && renderSettingsMenu()}
              </>
            </Grid>
            {showSearchInput && (
              <Grid item xs={12}>
                <CSTextField
                  variant='filled-outlined'
                  containerSize='fullHorizontal'
                  startAdornment={<SearchIcon />}
                  className={classes.searchField}
                  data-testid={`${dataTestIdPrefix}-card-search-input`}
                  value={searchQuery}
                  placeholder='Search'
                  onEnter={() => {
                    setCurrentPageIndex(0);
                    fetchAndSetData(currentPageIndex, searchQuery);
                  }}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                  }}
                />
              </Grid>
            )}
          </Grid>

          {error && (
            <AlertBanner
              className={classes.banner}
              severity='error'
              alertTitle={'Error'}
              alertMsg={error}
            />
          )}
          <Grid container>
            {data.map((element, index) => {
              return (
                <Grid
                  key={index}
                  item
                  xs={12}
                  data-testid={`${dataTestIdPrefix}-card-list-item-${index}`}
                >
                  {element}
                </Grid>
              );
            })}
          </Grid>
        </Box>
        <Box>
          <TablePagination
            className={classes.tablePagination}
            data-testid={`${dataTestIdPrefix}-card-pagination`}
            rowsPerPageOptions={[]}
            count={count}
            rowsPerPage={rowsPerPage}
            page={currentPageIndex}
            classes={{
              toolbar: classes.paginationToolbar,
            }}
            onPageChange={(_, page: number) => {
              setCurrentPageIndex(page);
            }}
            backIconButtonProps={{
              disableRipple: true,
              className: classes.paginationButton,
            }}
            nextIconButtonProps={{
              disableRipple: true,
              className: classes.paginationButton,
            }}
          />
        </Box>
      </Paper>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    spaceBetween: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    header: {
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      marginBottom: 10,
    },
    searchButton: {
      color: theme.palette.grey.A400,
      padding: 0,
      minWidth: 0,
    },
    optionsButton: {
      padding: 0,
      minWidth: 0,
    },
    tablePagination: {
      display: 'block',
      width: '100%',
      borderBottom: 'none',
      overflow: 'hidden',
      ...theme.typography.body2,
      color: theme.palette.grey.A400,
      borderTop: `1px solid ${theme.palette.grey[300]}`,
    },
    paginationToolbar: {
      minHeight: 36,
      marginBottom: -16,
    },
    paginationButton: {
      paddingLeft: 0,
      paddingRight: 0,
      '&:disabled': {
        color: theme.palette.grey.A400,
        opacity: '0.5',
      },
      '&:hover': {
        color: theme.palette.text.primary,
        background: 'transparent',
      },
    },
    alignRight: { textAlign: 'right' },
    searchIcon: {
      width: 20,
      cursor: 'pointer',
      color: theme.palette.grey.A400,
    },
    optionsIcon: {
      color: theme.palette.text.primary,
      width: 20,
      cursor: 'pointer',
      marginRight: '-5px',
    },
    searchField: {
      padding: '10px 0',
    },
  })),
)(PaginatedList);
