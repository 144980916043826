import { createStyles, Theme } from '@material-ui/core/styles';

export default createStyles((theme: Theme) => ({
  root: {
    padding: '15px 20px',
    height: 55,
    borderBottom: `${theme.borderWidth.border_thin} solid ${theme.palette.grey[300]}`,
    ...theme?.typography?.table?.body
  },
}));
