import { withStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import CSButton from '../../csButton/CSButton';
import Typography from '../../typography/Typography';
import styles from './CSFileDropZone.styles';
import { FileCopyOutlined } from '@material-ui/icons';
import useStableId from '../../../../utils/useStableId';

interface Props {
  classes: { [key: string]: string };
  defaultLabel?: string;
  acceptedFileTypesLowercase?: string[];
  onSelectFile: (file: File) => void;
  dataTestId?: string;
}

const CSFileDropZone: React.FC<Props> = ({
  classes,
  defaultLabel = 'Drag and Drop Your File Here',
  acceptedFileTypesLowercase,
  onSelectFile,
  dataTestId,
}) => {
  const [formClass, setFormClass] = useState<string>();
  const inputId = useStableId('fileDropZoneButton', 'CSFileDropZone');

  const [selectedFileLabel, setSelectedFileLabel] =
    useState<string>(defaultLabel);

  const validateFileExtension = (fileName: string): boolean => {
    if (acceptedFileTypesLowercase) {
      return acceptedFileTypesLowercase.some((fileExtension) =>
        fileName.endsWith(fileExtension),
      );
    }

    return true;
  };

  const processFileSelection = (file: File) => {
    setSelectedFileLabel(file.name);
    onSelectFile(file);
  };

  const onFileChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (
      event?.target.files &&
      validateFileExtension(event.target.files[0].name)
    ) {
      processFileSelection(event.target.files[0]);
    }
  };

  const onFileDroppedHandler = (
    event: React.DragEvent<HTMLFormElement>,
  ) => {
    const file = event.dataTransfer.files[0];
    setFormClass(undefined);

    if (
      acceptedFileTypesLowercase &&
      !validateFileExtension(file.name)
    ) {
      setSelectedFileLabel(
        `Please select ${acceptedFileTypesLowercase
          .map((fileExtension) => fileExtension.toUpperCase())
          .join(', ')} file.`,
      );
      setFormClass('dropRejected');
      setTimeout(() => {
        setSelectedFileLabel(defaultLabel);
        setFormClass(undefined);
      }, 2000);
    } else {
      processFileSelection(event.dataTransfer.files[0]);
    }
  };

  return (
    <form
      data-testid={dataTestId}
      className={
        classes.fileDropZone + ' ' + (formClass && classes[formClass])
      }
      onDragOver={(event) => {
        event.preventDefault();
        setFormClass('draggingActive');
      }}
      onDragLeave={(event) => {
        event.preventDefault();
        setFormClass(undefined);
      }}
      onDrop={(event) => {
        event.preventDefault();
        onFileDroppedHandler(event);
      }}
    >
      <input
        accept={
          acceptedFileTypesLowercase
            ? acceptedFileTypesLowercase
                .map((extension) => `.${extension}`)
                .join(', ')
            : undefined
        }
        className={classes.invisibleInput}
        id={inputId}
        type='file'
        onChange={onFileChangeHandler}
      />
      <Typography
        variant='h6'
        color='tertiary'
        className={classes.fileLabel}
      >
        {selectedFileLabel}
      </Typography>
      <label htmlFor={inputId}>
        <CSButton
          component='span'
          variant='contained'
          color={{
            buttonColor: 'secondary',
            iconColor: 'primary',
          }}
          startIcon={<FileCopyOutlined />}
        >
          Browse File
        </CSButton>
      </label>
    </form>
  );
};

export default withStyles(styles)(CSFileDropZone);
