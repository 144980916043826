import React, { useCallback, useEffect, useState } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import ErrorHandler from '../../utils/ErrorHandler';
import {
  AlertBanner,
  CSButton,
  CSTextField,
} from '../primitives/index';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Transition } from '../confirmationDialog/ConfirmationDialog';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import configurationUtils from '../../utils/configurationUtils';
import selectStyles from '../select/select.styles';
import { Theme } from '@material-ui/core';

import sectionPageBaseStyle from '../commonStyles/sectionPageBase.style';
import detailsPageStyles from '../commonStyles/detailsPage.style';
import DockDoorService from '../../services/DockDoors.service';

import {
  DockDoorDetails,
  InboundLoad,
  InboundLoadLoadStatusEnum,
  User,
} from 'cloudsort-client';
import debounce from 'lodash/debounce';
import { noOptionsMessage } from '../asyncSelect/utils';
import { TypeAheadItem } from '../../interfaces/components';
import InboundLoadsService from '../../services/InboundLoads.service';
import CSDialogTitleWithIcon from '../primitives/csDialogTitleWithIcon/CSDialogTitleWithIcon';
import { DialogIcons } from '../primitives/csDialogTitleWithIcon/CSDialogTitleWithIconTypes';
import { CSDualGridContainer } from '../primitives/csDualGridContainer';
import { CSDatepicker } from '../primitives/csDatepicker';
import CSAsyncSelect from '../primitives/csAsyncSelect/CSAsyncSelect';
import { AxiosError } from 'axios';
import FmcService from '../../services/Fmc.service';
import EphemeralStateService from '../../services/EphemeralState.service';

interface Props {
  classes: { [key: string]: string };
  isOpen: boolean;
  onAfterClose: () => void;
  updateParent: () => void;
  data: InboundLoad;
}

const emptyInboundLoadData: InboundLoad = {
  arrival_time: new Date().toISOString(),
  load_status: InboundLoadLoadStatusEnum.PLANNED,
  trailer_id: '',
  dockdoor: undefined,
  dockdoor_name: undefined,
  target_station: EphemeralStateService.getMyStationId(),
  owner: 0,
};

const InboundLoadCheckinDialog: React.FC<Props> = ({
  classes,
  isOpen,
  onAfterClose,
  updateParent,
  data,
}) => {
  const [open, setOpen] = useState(false);
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [error, setError] = useState();
  const [inboundLoadParams, setInboundLoadParams] = useState(
    emptyInboundLoadData,
  );

  const inboundLoadLabels = {
    singular: configurationUtils.getPageTitle(true, 'INBOUND_LOAD'),
    plural: configurationUtils.getPageTitle(false, 'INBOUND_LOAD'),
  };

  useEffect(() => {
    setOpen(isOpen);
    setInboundLoadParams({
      ...data,
      arrival_time: data?.arrival_time
        ? data.arrival_time
        : new Date().toISOString(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, data]);

  const formatAsyncOptions = (data: User[]) => {
    return data.map((dataEl: User) => {
      return {
        value: dataEl.full_name,
        label: dataEl.full_name,
      };
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadCarrierOptions = useCallback(
    debounce((inputValue: string, callback: any) => {
      FmcService.search(inputValue)
        .then((data) => {
          callback(formatAsyncOptions(data.data.results));
        })
        .catch((e) => {
          handleError(e as AxiosError);
        });
    }, 500),
    [],
  );
  const handleError = async (e: any) => {
    setError(await ErrorHandler.getLabel(e as AxiosError));
  };

  const handleClose = () => {
    setOpen(false);
    setError(undefined);
    onAfterClose();
    updateParent();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadDDOptions = useCallback(
    debounce((inputValue: string, callback: any) => {
      DockDoorService.search(inputValue)
        .then((data) => {
          callback(
            data.data.results.map((dataEl: DockDoorDetails) => {
              return {
                value: dataEl.id,
                label: dataEl.name,
              };
            }),
          );
        })
        .catch((e) => {
          handleError(e as AxiosError);
        });
    }, 500),
    [],
  );

  const completeCheckin = async () => {
    try {
      setShowProgress(true);
      await InboundLoadsService.update(inboundLoadParams.id!, {
        arrival_time:
          inboundLoadParams.load_status === 'PLANNED'
            ? inboundLoadParams.arrival_time
            : undefined,
        checked_in_time:
          inboundLoadParams.load_status === 'ARRIVED'
            ? inboundLoadParams.checked_in_time
            : undefined,
        bol: inboundLoadParams.bol,
        dockdoor: inboundLoadParams.dockdoor,
        trailer_id: inboundLoadParams.trailer_id,
        carrier: inboundLoadParams.carrier,
        load_status:
          inboundLoadParams.load_status === 'PLANNED'
            ? 'ARRIVED'
            : 'CHECKED_IN',
        owner: inboundLoadParams.owner,
        target_station: inboundLoadParams.target_station,
      });
      handleClose();
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  return (
    <>
      {showProgress && <ProgressIndicator />}
      <Dialog
        classes={{
          paperScrollPaper: classes.dialogRoot,
          paper: classes.dialogPaper,
        }}
        maxWidth={false}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={(e) => {
          handleClose();
        }}
      >
        <DialogTitle>
          <CSDialogTitleWithIcon
            title={`${inboundLoadLabels.singular} ${
              inboundLoadParams.load_status === 'PLANNED'
                ? 'Arrived'
                : 'Check in'
            }`}
            icon={DialogIcons.CHECK}
          />
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {error && (
            <AlertBanner
              className={classes.banner}
              severity='error'
              alertTitle={'Error'}
              alertMsg={error}
            />
          )}
          <CSDualGridContainer
            childrenColumnConfiguration={{ 0: 12 }}
          >
            <CSDatepicker
              label={
                inboundLoadParams.load_status === 'PLANNED'
                  ? 'Actual Arrival Time'
                  : 'Check-in time'
              }
              selectionType='datetime'
              format='MM/dd/yyyy hh:mmaaa'
              initialValue={
                new Date(
                  (inboundLoadParams.load_status === 'PLANNED'
                    ? inboundLoadParams.arrival_time
                    : inboundLoadParams.checked_in_time) || '',
                )
              }
              onAcceptSelection={(e) => {
                setInboundLoadParams({
                  ...inboundLoadParams,
                  arrival_time:
                    inboundLoadParams.load_status === 'PLANNED'
                      ? (e as unknown as string)
                      : undefined,
                  checked_in_time:
                    inboundLoadParams.load_status === 'ARRIVED'
                      ? (e as unknown as string)
                      : undefined,
                });
              }}
            />
            <CSAsyncSelect<TypeAheadItem<string>>
              label='Carrier'
              isClearable
              cacheOptions
              loadOptions={(inputValue: string, callback: any) => {
                loadCarrierOptions(inputValue, callback);
              }}
              onChange={(option) => {
                const selectedCarrier =
                  option as TypeAheadItem<string>;

                setInboundLoadParams({
                  ...inboundLoadParams,
                  carrier: selectedCarrier
                    ? selectedCarrier.value
                    : undefined,
                });
              }}
              isDisabled={!!error}
              placeholder={'Start Typing...'}
              value={
                inboundLoadParams?.carrier
                  ? {
                      label: inboundLoadParams.carrier,
                      value: inboundLoadParams.carrier,
                    }
                  : null
              }
              menuPortalTarget={document.body}
              noOptionsMessage={noOptionsMessage}
              containerSize='fullHorizontal'
            />
            <CSTextField
              label='Trailer ID'
              data-testid='checkin-inbound-load-trailer-id'
              value={inboundLoadParams.trailer_id || ''}
              autoFocus
              onChange={(e) => {
                setInboundLoadParams({
                  ...inboundLoadParams,
                  trailer_id: e.target.value,
                });
              }}
              onClear={() => {
                setInboundLoadParams({
                  ...inboundLoadParams,
                  trailer_id: '',
                });
              }}
              InputLabelProps={{
                shrink: true,
                className: classes.selectLabel,
              }}
              containerSize='fullHorizontal'
            />

            <CSAsyncSelect<TypeAheadItem>
              data-testid='select-unload-door'
              label='Unload Door'
              isClearable
              loadOptions={(inputValue: string, callback: any) => {
                loadDDOptions(inputValue, callback);
              }}
              onChange={(option) => {
                const selectedDockDoor = option as TypeAheadItem;
                setInboundLoadParams({
                  ...inboundLoadParams,
                  dockdoor: selectedDockDoor
                    ? Number(selectedDockDoor.value)
                    : undefined,
                  dockdoor_name: selectedDockDoor
                    ? selectedDockDoor.label
                    : '',
                });
              }}
              placeholder='Start Typing...'
              value={
                inboundLoadParams.dockdoor
                  ? {
                      value: String(inboundLoadParams.dockdoor),
                      label: inboundLoadParams.dockdoor_name || '',
                    }
                  : {
                      value: '',
                      label: 'Start Typing...',
                    }
              }
              menuPortalTarget={document.body}
              noOptionsMessage={noOptionsMessage}
              containerSize='fullHorizontal'
            />
            <CSTextField
              label='BOL'
              data-testid='checkin-inbound-load-bol'
              value={inboundLoadParams.bol || ''}
              autoFocus
              onChange={(e) => {
                setInboundLoadParams({
                  ...inboundLoadParams,
                  bol: e.target.value,
                });
              }}
              onClear={() => {
                setInboundLoadParams({
                  ...inboundLoadParams,
                  bol: '',
                });
              }}
              InputLabelProps={{
                shrink: true,
                className: classes.selectLabel,
              }}
              containerSize='fullHorizontal'
            />
          </CSDualGridContainer>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <CSButton
            variant='outlined'
            color='secondary'
            onClick={(e) => {
              handleClose();
            }}
          >
            Cancel
          </CSButton>
          <CSButton
            data-testid={'inbound-load-checkin-save-button'}
            variant='contained'
            color='secondary'
            disabled={false}
            onClick={(e) => {
              completeCheckin();
            }}
          >
            Save
          </CSButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...selectStyles,
    ...sectionPageBaseStyle,
    dialogContent: {
      overflow: 'hidden',
    },
    dialogActions: {
      padding: '16px 23px 25px 8px',
    },
    dialogRoot: {
      overflow: 'hidden',
    },
    dialogPaper: {
      width: 493,
      maxHeight: '100%',
    },
    inputPadding: {
      paddingLeft: '5px',
      paddingRigth: '5px',
    },
  })),
)(InboundLoadCheckinDialog);
