import { HoldReason } from 'cloudsort-client';
import {
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  createStyles,
  withStyles,
} from '@material-ui/core';
import capitalize from 'lodash/capitalize';
import { common } from '../../utils/strings';
import EditIcon from '@material-ui/icons/Edit';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

interface Props {
  classes: { [key: string]: string };
  reasons: HoldReason[];
  selectedReasons: string[];
  setSelectedReasons: (reasons: string[]) => void;
  onEditNoteClick: (reason: HoldReason) => void;
}

const HeldPackageEPContent = ({
  classes,
  reasons,
  selectedReasons,
  setSelectedReasons,
  onEditNoteClick,
}: Props) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell className={classes.tableHead}>Reason</TableCell>
          <TableCell className={classes.tableHead}>
            Flag Time
          </TableCell>
          <TableCell className={classes.tableHead}>Device</TableCell>
          <TableCell className={classes.tableHead}>Source</TableCell>
          <TableCell className={classes.tableHead}>Scope</TableCell>
          <TableCell className={classes.tableHead}>
            Hold Time
          </TableCell>
          <TableCell className={classes.tableHead}>
            CloudSort Notes
          </TableCell>
          <TableCell className={classes.tableHead}>
            Customer Notes
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {reasons.map((reason) => (
          <TableRow key={reason.reason}>
            <TableCell>
              {reason.resolved_on ? (
                <CheckCircleIcon
                  color='primary'
                  className={classes.checkIcon}
                />
              ) : (
                <Checkbox
                  checked={selectedReasons.includes(
                    reason.reason || '',
                  )}
                  onChange={(e) => {
                    const { checked } = e.target;

                    if (checked) {
                      setSelectedReasons([
                        ...selectedReasons,
                        reason.reason || '',
                      ]);
                    } else {
                      setSelectedReasons(
                        selectedReasons.filter(
                          (r) => r !== reason.reason,
                        ),
                      );
                    }
                  }}
                />
              )}{' '}
              {capitalize(reason.reason)}
            </TableCell>
            <TableCell>
              {reason.created_on
                ? new Date(reason.created_on).toLocaleString()
                : common.emptyValue}
            </TableCell>

            <TableCell>
              {reason.device_type
                ? capitalize(reason.device_type)
                : common.emptyValue}
            </TableCell>
            <TableCell>
              {reason.source
                ? capitalize(reason.source)
                : common.emptyValue}
            </TableCell>
            <TableCell>
              {reason.scope
                ? capitalize(reason.scope)
                : common.emptyValue}
            </TableCell>
            <TableCell>
              {reason.hold_time || common.emptyValue}
            </TableCell>
            <TableCell>
              {reason.system_note || common.emptyValue}{' '}
              <IconButton
                disableRipple
                onClick={() => {
                  onEditNoteClick(reason);
                }}
              >
                <EditIcon />
              </IconButton>
            </TableCell>
            <TableCell>
              {reason.customer_note || common.emptyValue}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default withStyles(
  createStyles(() => ({
    checkIcon: {
      margin: '8px 8px -8px 8px',
    },
    tableHead: {
      fontWeight: 'bold',
    },
  })),
)(HeldPackageEPContent);
