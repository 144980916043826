import {
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { switcher } from 'rambdax';
import { ExtendedPaletteKeys } from '../../../theming/material-ui';
import { getInputFieldColor } from '../csCommon/inputUtils';
import { CSSelectSize, ExtendedVariants } from './CSSelectTypes';
import { selectVariantProperty } from './CSSelectUtils';

export const FIELD_FILLED_CLASS = 'field-filled';

export default (
  theme: Theme,
  variant?: ExtendedVariants,
  label?: string,
  containerSize?: CSSelectSize,
) => {
  const outlinedInputPadding = label
    ? '16px 10px 3px 12px'
    : '10px 10px 8px 12px';

  const sizeStyles = getSizeStyles(containerSize || 'standard');

  const outlinedStyles = {
    root: {
      ...sizeStyles,
    },
    select: {
      padding: outlinedInputPadding,
      '&:focus': {
        background: 'transparent',
      },
    },
    disabled: {
      color: theme.palette.grey[300],
    },
    icon: {
      cursor: 'pointer',
    },
  };

  const styles = (variant?: ExtendedVariants) =>
    selectVariantProperty(
      {
        filledOutlined: outlinedStyles,
        standardOutlined: outlinedStyles,
      },
      variant,
    );

  return createStyles(() => ({
    ...styles(variant),
  }));
};

export const getInputLabelStyles = (
  theme: Theme,
  color: ExtendedPaletteKeys,
  variant?: ExtendedVariants,
  hasStartAdornment: boolean = false,
) => {
  const horizontalOffset = hasStartAdornment ? 38 : 0;
  const colorStyle = getInputFieldColor(color, theme);
  const generalStyle = {
    root: {
      '&$focused': {
        color: colorStyle,
      },
    },
    focused: {},
  };
  const standardOutlinedStyle = {
    ...generalStyle,
    root: {
      '&$focused': {
        color: theme.palette.grey.A400,
      },
    },
    formControl: {
      top: -5,
      '&$focused': {
        left: horizontalOffset,
        top: -2,
      },
      '&$shrink': {
        color: theme.palette.grey.A400,
        left: horizontalOffset,
        top: -2,
        transform: 'translate(12px, 10px) scale(0.875)',
      },
    },
    error: { color: `${theme.palette.grey.A400}!important` },
    shrink: {},
  };
  const styles = (variant?: ExtendedVariants) =>
    selectVariantProperty(
      {
        filledOutlined: standardOutlinedStyle,
        standardOutlined: standardOutlinedStyle,
      },
      variant,
    );
  return createStyles(() => ({
    ...styles(variant),
  }));
};

export const getClearButtonStyles = () =>
  makeStyles((theme: Theme) => ({
    singleAdornmentBox: {
      display: 'flex',
      flexDirection: 'row-reverse',
      height: 24,
      minWidth: 24,
      marginRight: 12,
      [`.${FIELD_FILLED_CLASS} > &`]: {
        color: theme.palette.text.primary,
      },
    },
    cursorPointer: {
      cursor: 'pointer',
    },
  }))();

export const getSizeStyles = (containerSize: CSSelectSize) => {
  return switcher<Record<string, CSSProperties>>(containerSize)
    .is('standard', {})
    .is('fullHorizontal', { width: '100%' })
    .is('fullVertical', { height: '100%' })
    .is('full', { width: '100%', height: '100%' })
    .default({});
};

export const getFormControlStyles = (containerSize: CSSelectSize) => {
  const formControlStyles = getSizeStyles(containerSize);

  return createStyles((theme: Theme) => ({
    root: {
      cursor: 'pointer',
      ...formControlStyles,
    },
  }));
};

export const getInputBaseStyles = (variant: ExtendedVariants) => {
  const getStylesForVariant = (
    theme: Theme,
    variant: ExtendedVariants,
  ) => {
    if (['standard-outlined', 'filled-outlined'].includes(variant)) {
      return {
        root: {
          height: '48px',
          background:
            variant === 'filled-outlined'
              ? theme.palette.background.default
              : undefined,
          border: `1px solid ${theme.palette.grey[300]}`,
          borderRadius: theme.shape.borderRadius_sm,
          cursor: 'pointer',
          '&:hover': {
            border: `1px solid ${theme.palette.common.black}`,
          },
          '&$disabled': {
            border: `1px solid ${theme.palette.grey[300]}`,
          },
        },
        error: {
          borderColor: theme.palette.error.main,
        },
        input: {
          '&:focus': {
            background: 'transparent',
          },
        },
        disabled: {},
      };
    }

    return {};
  };

  return createStyles((theme: Theme) => ({
    ...getStylesForVariant(theme, variant),
  }));
};

export const getFormHelperTextStyles = () => {
  return createStyles(() => ({
    root: { marginLeft: 0 },
  }));
};
