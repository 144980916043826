import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  AlertBanner,
  Box,
  CSButton,
  CSSwitch,
  CSTextField,
  Typography,
} from '../../primitives';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid, Theme, Paper, MenuItem } from '@material-ui/core';
import detailsPageStyles from '../../commonStyles/detailsPage.style';
import Layout from '../../layout/Layout';
import CustomersService from '../../../services/Customers.service';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import sectionPageBaseStyle from '../../commonStyles/sectionPageBase.style';
import ErrorHandler from '../../../utils/ErrorHandler';
import { AxiosError } from 'axios';
import ConfirmationDialog from '../../confirmationDialog/ConfirmationDialog';
import { configurationUrlParams } from '../utils';
import ProgressIndicator from '../../progressIndicator/ProgressIndicator';
import {
  checkForConflictingCustomerConfigs,
  getBaseNavigationItems,
  getStationsAutocompleteSuggestions,
} from './utils';
import browserHistory from '../../../utils/browserHistory';
import { AuthRoutes } from '../../../interfaces/routes';
import CSBreadcrumbs from '../../primitives/CSBreadcrumbs/CSBreadcrumbs';
import DeleteIcon from '@material-ui/icons/Delete';
import CSSectionTitleSeparator from '../../primitives/csSectionTitleSeparator/CSSectionTitleSeparator';
import {
  CustomerConfig,
  ExternalFulfilmentProviderConfig,
  OutboundIntegrationUSPSProtocolEnum,
  RelabelOptionsConfigProvidersEnum,
} from 'cloudsort-client';
import { TypeAheadItem } from '../../../interfaces/components';
import debounce from 'lodash/debounce';
import CSAsyncSelect from '../../primitives/csAsyncSelect/CSAsyncSelect';
import { noOptionsMessage } from '../../asyncSelect/utils';
import CSSelect from '../../primitives/csSelect/CSSelect';
import {
  CSBreadcrumbItem,
  CSBreadcrumbSelectedItem,
} from '../../primitives/CSBreadcrumbs/CSBreadcrumbs.types';
import CustomerConfigurationsService from '../../../services/CustomerConfigurationsService';
import StationsService from '../../../services/Stations.service';
import queryString from 'query-string';
import OrganizationsService from '../../../services/Organizations.service';
import cloneDeep from 'lodash/cloneDeep';
import { CSMonoGridContainer } from '../../primitives/csMonoGridContainer';
import PaginatedTable from '../../paginatedTable/PaginatedTable';

// Icons
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import AddEditFulfilmentProviderDialog, {
  AddEditDialogAction,
} from './addEditFulfilmentProviderDialog/AddEditFulfilmentProviderDialog';

interface Props {
  classes: { [key: string]: string };
}
//TODO: The backend team declared the wrong type for CustomerConfig.station_data property. This is a temporary fix.
// The bug is tracked in CLOUD-3277.
export type assignedStationsToConfiguration = {
  name: string;
  id: number;
};

const ConfigurationDetails: React.FC<Props> = ({ classes }) => {
  //Configuration data. Items in the UI get their own separate state variable. Others that are used as "readonly" are stored in initialConfigData.
  const [initialConfigData, setInitialConfigData] =
    useState<CustomerConfig>({} as CustomerConfig);
  const [name, setName] = useState<string>('');
  const [assignedStations, setAssignedStations] = useState<
    TypeAheadItem<number>[]
  >([]);
  const [assignedOrganization, setAssignedOrganization] =
    useState<TypeAheadItem<number>>();

  const [showAddEditProviderDialog, setShowAddEditProviderDialog] =
    useState(false);

  const availableProviders = useMemo(() => ['psprt.v1'], []);

  const [availableProvidersUnused, setAvailableProvidersUnused] =
    useState(availableProviders);

  const [providerToEdit, setProviderToEdit] = useState<
    ExternalFulfilmentProviderConfig | undefined
  >();

  const [lastUpdated, setLastUpdated] = useState<string>(
    new Date().toISOString(),
  );

  const [configData, setConfigData] = useState<
    CustomerConfig['config']
  >({
    outbound_integrations: {
      USPS: {
        active: false,
        protocol: OutboundIntegrationUSPSProtocolEnum.EVS,
        evs_sdnd_active: false,
        gss_agent_id: '',
        gss_location_id: '',
        gss_username: '',
        gss_password: '',
        gss_base_path_rest: '',
      },
      FEDEX: {
        active: false,
      },
      FDC: {
        active: false,
        fdc_api: '',
        fdc_api_key: '',
        fdc_client_id: '',
      },
    },
    dispatch_integrations: {
      BOL: {
        active: false,
        required: false,
      },
      AWB: {
        active: false,
        required: false,
      },
    },
    external_fulfilment: {
      providers: [],
    },
    relabel_options: {
      providers: [],
    },
  });
  //UI elements state
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [error, setError] = useState<string | React.ReactNode>();
  const [hasUnsavedChanges, setHasUnsavedChanges] =
    useState<boolean>(false);
  const [showDeleteDialog, setShowDeleteDialog] =
    useState<boolean>(false);
  const urlParams = useParams<configurationUrlParams>();
  const [breadcrumbsArray, setBreadcrumbsArray] = useState<
    Array<CSBreadcrumbItem | CSBreadcrumbSelectedItem>
  >([]);
  //Misc
  const isNewConfiguration = !urlParams.configurationId;
  const newConfigurationType = queryString.parse(
    window.location.search,
  )?.type;
  const baseUrl = `${AuthRoutes.CONFIGURATION}${
    urlParams.orgId ? '/organization/' + urlParams.orgId : ''
  }${
    urlParams.stationId ? '/station/' + urlParams.stationId : ''
  }/customers/${urlParams.customerId}`;

  const handleError = async (err: AxiosError) => {
    setError(await ErrorHandler.getLabel(err));
  };

  const getAndSetBreadcrumbItems = async () => {
    try {
      let customerName = initialConfigData.customer_name || '';
      const customerId =
        initialConfigData.customer || urlParams.customerId;
      const configurationName = urlParams.configurationId
        ? initialConfigData.name || ''
        : 'New Configuration';

      if (!urlParams.configurationId) {
        //New configuration mode. We need to fetch the customer name.
        const customerData = await CustomersService.getById(
          Number(urlParams.customerId),
        );
        customerName = customerData.data.identifier || '';
      }
      const { baseURL, navItems } = await getBaseNavigationItems(
        urlParams.orgId,
        urlParams.stationId,
      );
      const customersURL = `${baseURL}/customers`;
      navItems.push(
        {
          label: 'Customers',
          link: customersURL,
        },

        {
          label: customerName,
          link: `${customersURL}/${customerId}`,
        },
        {
          label: configurationName,
          selected: true,
        },
      );
      setBreadcrumbsArray(navItems);
    } catch (err) {
      console.error(err);
      setBreadcrumbsArray([]);
    }
  };

  const getAndSetConfigurationData = useCallback(async () => {
    try {
      setShowProgress(true);
      const res = await CustomersService.getConfigurationById(
        urlParams.configurationId || '',
      );
      setInitialConfigData(res.data);
      setName(res.data.name);
      setConfigData(res.data.config);
      setAvailableProvidersUnused(
        availableProviders.filter(
          (provider) =>
            !(res.data.config.external_fulfilment?.providers || [])
              .map((p) => p.id)
              .includes(provider),
        ),
      );
      setLastUpdated(new Date().toISOString());
      const assignedStationsData: TypeAheadItem<number>[] = [];
      if (res.data.station_data) {
        for (let station of res.data
          .station_data as unknown as assignedStationsToConfiguration[]) {
          assignedStationsData.push({
            label: station.name,
            value: station.id,
          });
        }
      }
      setAssignedStations(assignedStationsData);
      if (res.data.organization) {
        setAssignedOrganization({
          label: res.data.organization_name || '',
          value: Number(res.data.organization),
        });
      }
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  }, [urlParams.configurationId, availableProviders]);

  const prefillAssignedStation = useCallback(async () => {
    if (!urlParams.stationId) {
      return;
    }
    try {
      setShowProgress(true);
      const res = await StationsService.getStationById(
        Number(urlParams.stationId),
      );
      if (res.data) {
        setAssignedStations([
          {
            label: res.data.name || '',
            value: Number(res.data.id),
          },
        ]);
      }
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  }, [urlParams.stationId]);

  const prefillAssignedOrganization = useCallback(async () => {
    if (!urlParams.orgId) {
      return;
    }
    try {
      setShowProgress(true);
      const res = await OrganizationsService.getById(
        Number(urlParams.orgId),
      );
      if (res.data) {
        setAssignedOrganization({
          label: res.data.name || '',
          value: Number(res.data.id),
        });
      }
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  }, [urlParams.orgId]);

  useEffect(() => {
    if (isNewConfiguration) {
      if (newConfigurationType === 'organization') {
        prefillAssignedOrganization();
      }
      if (newConfigurationType === 'station') {
        prefillAssignedStation();
      }
    } else {
      getAndSetConfigurationData();
    }
  }, [
    urlParams.configurationId,
    getAndSetConfigurationData,
    isNewConfiguration,
    prefillAssignedStation,
    prefillAssignedOrganization,
    newConfigurationType,
  ]);

  useEffect(() => {
    getAndSetBreadcrumbItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialConfigData]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadStationDataOptions = useCallback(
    debounce((inputValue: string, callback: any) => {
      getStationsAutocompleteSuggestions(
        inputValue,
        Number(urlParams.orgId),
        assignedStations || [],
      )
        .then((autocompleteData) => {
          callback(autocompleteData);
        })
        .catch((e) => {
          handleError(e as AxiosError);
        });
    }, 500),
    [setAssignedStations, assignedStations],
  );

  const checkForConflicts = async (
    stations: TypeAheadItem<number>[],
  ) => {
    try {
      setShowProgress(true);
      setError(undefined); //Reset prev errors.
      const conflicts = await checkForConflictingCustomerConfigs(
        assignedStations,
        stations,
        Number(urlParams.customerId),
      );
      const errors = conflicts.map((conflict) => {
        return (
          <Typography
            variant='body2'
            component='p'
            key={`${conflict.conflict}-${conflict.name}`}
          >
            Station <b>{conflict.name}</b> is currently assigned to{' '}
            {conflict.conflict}.
          </Typography>
        );
      });
      if (errors.length > 0) {
        setError(
          <>
            {errors}
            <Typography
              variant='body2'
              component='p'
              key='overwrite-warning'
            >
              When saving the configuration this setting will be
              overwritten.
            </Typography>
          </>,
        );
      }
    } catch (err) {
      handleError(err as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  const saveConfiguration = async () => {
    try {
      setShowProgress(true);
      const newConfig: CustomerConfig = {
        name: name,
        stations:
          isNewConfiguration &&
          newConfigurationType === 'organization' // Send undefined when creating new "organization" config. Otherwise send the list of selected stations.
            ? undefined
            : assignedStations.map((station) => station.value),
        organization:
          isNewConfiguration && newConfigurationType === 'station' // Send undefined when creating new "station" config. Otherwise send the id of the selected organization.
            ? undefined
            : assignedOrganization?.value,
        customer: Number(urlParams.customerId),
        config: {
          outbound_integrations: {
            USPS: {
              active: configData?.outbound_integrations?.USPS?.active,
              protocol:
                configData?.outbound_integrations?.USPS?.protocol,
              ...(configData?.outbound_integrations?.USPS?.active &&
                configData?.outbound_integrations.USPS.protocol ===
                  OutboundIntegrationUSPSProtocolEnum.GSS && {
                  gss_agent_id:
                    configData?.outbound_integrations.USPS
                      .gss_agent_id,
                  gss_location_id:
                    configData?.outbound_integrations.USPS
                      .gss_location_id,
                  gss_password:
                    configData?.outbound_integrations.USPS
                      .gss_password,
                  gss_username:
                    configData?.outbound_integrations.USPS
                      .gss_username,
                  gss_base_path_rest: configData
                    ?.outbound_integrations.USPS.gss_base_path_rest
                    ? configData.outbound_integrations.USPS
                        .gss_base_path_rest
                    : null,

                  gss_force_min_weight:
                    configData?.outbound_integrations.USPS
                      .gss_force_min_weight,
                }),
              ...(configData?.outbound_integrations?.USPS?.active &&
                configData?.outbound_integrations.USPS.protocol ===
                  OutboundIntegrationUSPSProtocolEnum.EVS && {
                  evs_sdnd_active:
                    configData?.outbound_integrations.USPS
                      .evs_sdnd_active,
                }),
            },
            FEDEX: {
              active:
                configData?.outbound_integrations?.FEDEX?.active,
            },
            FDC: {
              active: configData?.outbound_integrations?.FDC?.active,
              ...(configData?.outbound_integrations?.FDC?.active && {
                fdc_api_key:
                  configData?.outbound_integrations.FDC.fdc_api_key,
                fdc_client_id:
                  configData?.outbound_integrations.FDC.fdc_client_id,
                fdc_api:
                  configData?.outbound_integrations.FDC.fdc_api,
              }),
            },
          },
          dispatch_integrations: {
            BOL: {
              active: configData?.dispatch_integrations?.BOL?.active,
              required:
                configData?.dispatch_integrations?.BOL?.required,
            },
            AWB: {
              active: configData?.dispatch_integrations?.AWB?.active,
              required:
                configData?.dispatch_integrations?.AWB?.required,
            },
          },
          external_fulfilment: configData.external_fulfilment,
          relabel_options: configData.relabel_options,
        },
      } as CustomerConfig;

      if (isNewConfiguration) {
        const res =
          await CustomerConfigurationsService.createFromConfig(
            newConfig,
            true,
          );
        browserHistory.push(`${baseUrl}/${res.data.id}/`);
      } else {
        await CustomerConfigurationsService.updateConfiguration(
          urlParams.configurationId || '',
          newConfig,
          true,
        );
      }
      setError(undefined);
      setHasUnsavedChanges(false);
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  const deleteConfiguration = async () => {
    try {
      setShowProgress(true);
      await CustomersService.deleteConfiguration(
        urlParams.configurationId || '',
      );
      browserHistory.push(baseUrl);
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  const onEditProvider = (providerId: string) => {
    setProviderToEdit(
      configData.external_fulfilment?.providers?.find(
        (provider) => provider.id === providerId,
      ),
    );
    setShowAddEditProviderDialog(true);
    setLastUpdated(new Date().toISOString());
    setHasUnsavedChanges(true);
  };

  const onRemoveProvider = (providerId: string) => {
    setConfigData((prev) => {
      return {
        ...prev,
        external_fulfilment: {
          providers: prev.external_fulfilment?.providers?.filter(
            (provider) => provider.id !== providerId,
          ),
        },
      };
    });
    setProviderToEdit(undefined);
    setAvailableProvidersUnused((prev) => [...prev, providerId]);
    setLastUpdated(new Date().toISOString());
    setHasUnsavedChanges(true);
  };

  return (
    <>
      <Helmet>
        <title>{`CloudSort - ${
          initialConfigData.name || 'New'
        } Customer Configuration`}</title>
      </Helmet>
      <Layout navCurrent='CONFIGURATION'>
        {showProgress && <ProgressIndicator />}
        <ConfirmationDialog
          dataTestIdPrefix='delete-customer-confirmation'
          title={`Delete Customer`}
          msg={`Are you sure you want to delete ${name}?`}
          primaryActionLabel='Confirm'
          cancelLabel='Cancel'
          onPrimaryAction={() => {
            deleteConfiguration();
          }}
          onCancel={() => {
            setShowDeleteDialog(false);
          }}
          isOpen={showDeleteDialog}
        />
        {showAddEditProviderDialog && (
          <AddEditFulfilmentProviderDialog
            availableProviderTypes={availableProviders}
            closeDialog={() => {
              setShowAddEditProviderDialog(false);
            }}
            onSave={(action, newProvider) => {
              if (action === AddEditDialogAction.ADD) {
                setConfigData((prev) => {
                  return {
                    ...prev,
                    external_fulfilment: {
                      providers: [
                        ...(prev.external_fulfilment?.providers ||
                          []),
                        newProvider,
                      ],
                    },
                  };
                });

                setAvailableProvidersUnused((prev) =>
                  prev.filter(
                    (provider) => provider !== newProvider.id,
                  ),
                );
              } else {
                setConfigData((prev) => {
                  return {
                    ...prev,
                    external_fulfilment: {
                      providers:
                        prev.external_fulfilment?.providers?.map(
                          (provider) => {
                            if (provider.id === newProvider.id)
                              return newProvider;
                            return provider;
                          },
                        ),
                    },
                  };
                });
              }
              setHasUnsavedChanges(true);
              setLastUpdated(new Date().toISOString());
            }}
            providerToEdit={providerToEdit}
          />
        )}

        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={12}>
            <Box mb={5}>
              <CSBreadcrumbs breadcrumbs={breadcrumbsArray} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant='h3' component='h2'>
              Configuration Details
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            className={classes.actionButtonsWrapper}
          >
            <CSButton
              className={classes.actionButton}
              variant='outlined'
              color='secondary'
              onClick={(e) => {
                browserHistory.push(baseUrl);
              }}
            >
              Cancel
            </CSButton>
            <CSButton
              className={classes.actionButton}
              variant='outlined'
              color='secondary'
              disabled={isNewConfiguration}
              onClick={(e) => {
                setShowDeleteDialog(true);
              }}
              startIcon={<DeleteIcon />}
            >
              Delete
            </CSButton>
            <CSButton
              className={classes.actionButton}
              variant='contained'
              color='secondary'
              disabled={
                !hasUnsavedChanges ||
                (!assignedOrganization?.value &&
                  assignedStations.length === 0)
              }
              onClick={(e) => {
                saveConfiguration();
              }}
            >
              Save Configuration
            </CSButton>
          </Grid>
          {error && (
            <Grid item xs={12}>
              <AlertBanner
                className={classes.banner}
                severity='error'
                alertMsg={error}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <CSSectionTitleSeparator topMargin={5} />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} className={classes.gridItem}>
            <Paper className={classes.paper}>
              <CSMonoGridContainer>
                <>
                  <Typography variant='h6' component='h3'>
                    Configuration Details
                  </Typography>
                  <CSSectionTitleSeparator topMargin={5} />
                </>
                <CSTextField
                  label='Name'
                  placeholder='Configuration Name'
                  containerSize='fullHorizontal'
                  value={name || ''}
                  onChange={(e: React.BaseSyntheticEvent) => {
                    setName(e.target.value);
                    setHasUnsavedChanges(true);
                  }}
                />
                {!(
                  assignedOrganization?.value &&
                  !isNaN(assignedOrganization?.value)
                ) && (
                  <CSAsyncSelect<TypeAheadItem<number>, true>
                    label='Stations'
                    isClearable
                    isMulti
                    containerSize='fullHorizontal'
                    menuPortalTarget={document.body}
                    loadOptions={loadStationDataOptions}
                    onChange={(
                      newValues: readonly TypeAheadItem<number>[],
                    ) => {
                      const newStations = [...newValues];
                      checkForConflicts(newStations);
                      setAssignedStations(newStations);
                      setHasUnsavedChanges(true);
                    }}
                    value={assignedStations}
                    noOptionsMessage={noOptionsMessage}
                    placeholder={'Search Stations'}
                  />
                )}
                {Boolean(
                  assignedOrganization?.value &&
                    !isNaN(assignedOrganization?.value),
                ) && (
                  <CSAsyncSelect<TypeAheadItem<number>, true>
                    label='Organization'
                    isMulti
                    containerSize='fullHorizontal'
                    disabled
                    menuPortalTarget={document.body}
                    value={assignedOrganization}
                    noOptionsMessage={noOptionsMessage}
                  />
                )}
              </CSMonoGridContainer>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.gridItem}>
            <Paper className={classes.paper}>
              <CSMonoGridContainer>
                <>
                  <Typography variant='h6' component='h3'>
                    Downstream Integrations
                  </Typography>
                  <CSSectionTitleSeparator topMargin={5} />
                </>
                <>
                  {' '}
                  <Typography
                    variant='h6'
                    component='h3'
                    className={classes.sectionTitle}
                  >
                    USPS Outbound Integrations
                  </Typography>
                  <CSSectionTitleSeparator topMargin={10} />
                </>
                <>
                  <Typography variant='body2' component='span'>
                    USPS
                  </Typography>
                  <CSSwitch
                    color='default'
                    size='small'
                    checked={Boolean(
                      configData?.outbound_integrations?.USPS?.active,
                    )}
                    onChange={() => {
                      if (configData?.outbound_integrations?.USPS)
                        configData.outbound_integrations.USPS.active =
                          !configData.outbound_integrations?.USPS
                            ?.active;
                      setConfigData(cloneDeep(configData));
                      setHasUnsavedChanges(true);
                    }}
                  />
                </>
                {configData.outbound_integrations?.USPS?.active && (
                  <CSSelect
                    label='Protocol'
                    placeholder='Select USPS Protocol'
                    title='The Protocol to handle the Outbound.'
                    disabled={
                      !configData.outbound_integrations.USPS.active
                    }
                    value={
                      configData.outbound_integrations.USPS
                        .protocol || ''
                    }
                    onChange={(e) => {
                      if (configData.outbound_integrations?.USPS)
                        configData.outbound_integrations.USPS.protocol =
                          e.target
                            .value as OutboundIntegrationUSPSProtocolEnum;
                      setConfigData(cloneDeep(configData));
                      setHasUnsavedChanges(true);
                    }}
                    containerSize='fullHorizontal'
                  >
                    <MenuItem
                      value={OutboundIntegrationUSPSProtocolEnum.GSS}
                      key='gss'
                    >
                      {OutboundIntegrationUSPSProtocolEnum.GSS}
                    </MenuItem>
                    <MenuItem
                      value={OutboundIntegrationUSPSProtocolEnum.EVS}
                      key='evs'
                    >
                      {OutboundIntegrationUSPSProtocolEnum.EVS}
                    </MenuItem>
                  </CSSelect>
                )}
              </CSMonoGridContainer>
              {configData.outbound_integrations?.USPS?.protocol ===
                OutboundIntegrationUSPSProtocolEnum.GSS &&
                configData.outbound_integrations.USPS.active && (
                  <CSMonoGridContainer>
                    <CSTextField
                      label='Agent ID'
                      placeholder='Agent ID'
                      title='The USPS GSS Agent ID to use for Authentication'
                      containerSize='fullHorizontal'
                      value={
                        configData.outbound_integrations.USPS
                          .gss_agent_id || ''
                      }
                      onChange={(e: React.BaseSyntheticEvent) => {
                        if (configData.outbound_integrations?.USPS)
                          configData.outbound_integrations.USPS.gss_agent_id =
                            e.target.value;
                        setConfigData(cloneDeep(configData));
                        setHasUnsavedChanges(true);
                      }}
                      error={
                        !configData.outbound_integrations.USPS
                          .gss_agent_id
                      }
                      helperText={
                        !configData.outbound_integrations.USPS
                          .gss_agent_id
                          ? 'This field may not be blank.'
                          : ''
                      }
                    />

                    <CSTextField
                      label='Username'
                      placeholder='Username'
                      title='The USPS GSS User ID to use for Authentication'
                      containerSize='fullHorizontal'
                      value={
                        configData.outbound_integrations.USPS
                          .gss_username || ''
                      }
                      onChange={(e: React.BaseSyntheticEvent) => {
                        if (configData.outbound_integrations?.USPS)
                          configData.outbound_integrations.USPS.gss_username =
                            e.target.value;
                        setConfigData(cloneDeep(configData));
                        setHasUnsavedChanges(true);
                      }}
                      error={
                        !configData.outbound_integrations.USPS
                          .gss_username
                      }
                      helperText={
                        !configData.outbound_integrations.USPS
                          .gss_username
                          ? 'This field may not be blank.'
                          : ''
                      }
                    />

                    <CSTextField
                      label='Password'
                      placeholder='GSS Password'
                      title='The USPS GSS Password to use for Authentication'
                      type='password'
                      containerSize='fullHorizontal'
                      value={
                        configData.outbound_integrations.USPS
                          .gss_password || ''
                      }
                      onChange={(e: React.BaseSyntheticEvent) => {
                        if (configData.outbound_integrations?.USPS)
                          configData.outbound_integrations.USPS.gss_password =
                            e.target.value;
                        setConfigData(cloneDeep(configData));
                        setHasUnsavedChanges(true);
                      }}
                      error={
                        !configData.outbound_integrations.USPS
                          .gss_password
                      }
                      helperText={
                        !configData.outbound_integrations.USPS
                          .gss_password
                          ? 'This field may not be blank.'
                          : ''
                      }
                    />

                    <CSTextField
                      label='Location ID'
                      placeholder='Location ID'
                      title='The USPS GSS Location ID to use for Authentication'
                      containerSize='fullHorizontal'
                      value={
                        configData.outbound_integrations.USPS
                          .gss_location_id || ''
                      }
                      onChange={(e: React.BaseSyntheticEvent) => {
                        if (configData.outbound_integrations?.USPS)
                          configData.outbound_integrations.USPS.gss_location_id =
                            e.target.value;
                        setConfigData(cloneDeep(configData));
                        setHasUnsavedChanges(true);
                      }}
                      error={
                        !configData.outbound_integrations.USPS
                          .gss_location_id
                      }
                      helperText={
                        !configData.outbound_integrations.USPS
                          .gss_location_id
                          ? 'This field may not be blank.'
                          : ''
                      }
                    />

                    <CSTextField
                      label='REST API Base Path'
                      placeholder='REST API Base Path'
                      title='Optional, a override to the REST API Base Path. Use this to e.g. switch to the test API.'
                      containerSize='fullHorizontal'
                      value={
                        configData.outbound_integrations.USPS
                          .gss_base_path_rest || ''
                      }
                      onChange={(e: React.BaseSyntheticEvent) => {
                        if (configData.outbound_integrations?.USPS)
                          configData.outbound_integrations.USPS.gss_base_path_rest =
                            e.target.value;
                        setConfigData(cloneDeep(configData));
                        setHasUnsavedChanges(true);
                      }}
                    />
                    <>
                      <CSSwitch
                        color='default'
                        size='medium'
                        title='Whether to force minimum weight'
                        checked={Boolean(
                          configData.outbound_integrations.USPS
                            .gss_force_min_weight,
                        )}
                        onChange={() => {
                          if (configData.outbound_integrations?.USPS)
                            configData.outbound_integrations.USPS.gss_force_min_weight =
                              !configData.outbound_integrations.USPS
                                .gss_force_min_weight;
                          setConfigData(cloneDeep(configData));
                          setHasUnsavedChanges(true);
                        }}
                      />
                      <Typography variant='body2' component='span'>
                        Force minimum weight
                      </Typography>
                    </>
                  </CSMonoGridContainer>
                )}
              {configData.outbound_integrations?.USPS?.protocol ===
                OutboundIntegrationUSPSProtocolEnum.EVS &&
                configData.outbound_integrations.USPS.active && (
                  <>
                    <CSSwitch
                      color='default'
                      size='medium'
                      title='Whether to force all packages/containers to be part of the SD/ND program'
                      checked={Boolean(
                        configData.outbound_integrations.USPS
                          .evs_sdnd_active,
                      )}
                      onChange={() => {
                        if (configData.outbound_integrations?.USPS)
                          configData.outbound_integrations.USPS.evs_sdnd_active =
                            !configData.outbound_integrations.USPS
                              .evs_sdnd_active;
                        setConfigData(cloneDeep(configData));
                        setHasUnsavedChanges(true);
                      }}
                    />
                    <Typography variant='body2' component='span'>
                      eVS SD/ND active
                    </Typography>
                  </>
                )}
              <CSMonoGridContainer>
                <>
                  <Typography
                    variant='h6'
                    component='h3'
                    className={classes.sectionTitle}
                  >
                    FedEx Outbound Integrations
                  </Typography>
                  <CSSectionTitleSeparator topMargin={10} />
                </>
                <>
                  <Typography variant='body2' component='span'>
                    FedEx
                  </Typography>
                  <CSSwitch
                    color='default'
                    size='medium'
                    checked={Boolean(
                      configData.outbound_integrations?.FEDEX?.active,
                    )}
                    onChange={() => {
                      if (configData.outbound_integrations?.FEDEX)
                        configData.outbound_integrations.FEDEX.active =
                          !configData.outbound_integrations.FEDEX
                            .active;
                      setConfigData(cloneDeep(configData));
                      setHasUnsavedChanges(true);
                    }}
                  />
                </>
                <>
                  <Typography
                    variant='h6'
                    component='h3'
                    className={classes.sectionTitle}
                  >
                    FDC Outbound Integrations
                  </Typography>
                  <CSSectionTitleSeparator topMargin={10} />
                </>
                <>
                  <Typography variant='body2' component='span'>
                    FDC
                  </Typography>
                  <CSSwitch
                    color='default'
                    size='medium'
                    checked={Boolean(
                      configData.outbound_integrations?.FDC?.active,
                    )}
                    onChange={() => {
                      if (configData.outbound_integrations?.FDC)
                        configData.outbound_integrations.FDC.active =
                          !configData.outbound_integrations.FDC
                            .active;
                      setConfigData(cloneDeep(configData));
                      setHasUnsavedChanges(true);
                    }}
                  />
                </>
              </CSMonoGridContainer>
              {Boolean(
                configData.outbound_integrations?.FDC?.active,
              ) && (
                <CSMonoGridContainer>
                  <CSTextField
                    label='API Key'
                    placeholder='API Key'
                    title='The FDC API key used to authenticate with the FDC API.'
                    containerSize='fullHorizontal'
                    value={
                      configData.outbound_integrations?.FDC
                        ?.fdc_api_key || ''
                    }
                    onChange={(e: React.BaseSyntheticEvent) => {
                      if (configData.outbound_integrations?.FDC)
                        configData.outbound_integrations.FDC.fdc_api_key =
                          e.target.value;
                      setConfigData(cloneDeep(configData));
                      setHasUnsavedChanges(true);
                    }}
                    error={
                      !configData.outbound_integrations?.FDC
                        ?.fdc_api_key
                    }
                    helperText={
                      !configData.outbound_integrations?.FDC
                        ?.fdc_api_key
                        ? 'This field may not be blank.'
                        : ''
                    }
                  />

                  <CSTextField
                    label='Client ID'
                    placeholder='Client ID'
                    title='The FDC Client ID used to interact with the FDC API.'
                    containerSize='fullHorizontal'
                    value={
                      configData.outbound_integrations?.FDC
                        ?.fdc_client_id || ''
                    }
                    onChange={(e: React.BaseSyntheticEvent) => {
                      if (configData.outbound_integrations?.FDC)
                        configData.outbound_integrations.FDC.fdc_client_id =
                          e.target.value;
                      setConfigData(cloneDeep(configData));
                      setHasUnsavedChanges(true);
                    }}
                    error={
                      !configData.outbound_integrations?.FDC
                        ?.fdc_client_id
                    }
                    helperText={
                      !configData.outbound_integrations?.FDC
                        ?.fdc_client_id
                        ? 'This field may not be blank.'
                        : ''
                    }
                  />

                  <CSTextField
                    label='API URL'
                    placeholder='API URL'
                    title='The FDC Api URL used to interact with the FDC API.'
                    containerSize='fullHorizontal'
                    value={
                      configData.outbound_integrations?.FDC
                        ?.fdc_api || ''
                    }
                    onChange={(e: React.BaseSyntheticEvent) => {
                      if (configData.outbound_integrations?.FDC)
                        configData.outbound_integrations.FDC.fdc_api =
                          e.target.value;
                      setConfigData(cloneDeep(configData));
                      setHasUnsavedChanges(true);
                    }}
                    error={
                      !configData.outbound_integrations?.FDC?.fdc_api
                    }
                    helperText={
                      !configData.outbound_integrations?.FDC?.fdc_api
                        ? 'This field may not be blank.'
                        : ''
                    }
                  />
                </CSMonoGridContainer>
              )}
              <CSMonoGridContainer>
                <>
                  <Typography
                    variant='h6'
                    component='h3'
                    className={classes.sectionTitle}
                  >
                    Dispatch Integrations
                  </Typography>
                  <CSSectionTitleSeparator topMargin={10} />
                </>
                <>
                  <Typography variant='h6' component='h3'>
                    BOL
                  </Typography>
                  <Typography variant='body2' component='span'>
                    Active
                  </Typography>
                  <CSSwitch
                    color='default'
                    size='medium'
                    checked={Boolean(
                      configData.dispatch_integrations?.BOL?.active,
                    )}
                    onChange={() => {
                      const newBolValue =
                        !configData.dispatch_integrations?.BOL
                          ?.active;

                      if (configData.dispatch_integrations?.BOL) {
                        configData.dispatch_integrations.BOL.active =
                          newBolValue;
                        if (!newBolValue) {
                          configData.dispatch_integrations.BOL.required =
                            false;
                        }
                      }
                      setConfigData(cloneDeep(configData));
                      setHasUnsavedChanges(true);
                    }}
                  />
                  <Typography variant='body2' component='span'>
                    Required
                  </Typography>
                  <CSSwitch
                    color='default'
                    size='medium'
                    checked={Boolean(
                      configData.dispatch_integrations?.BOL?.required,
                    )}
                    disabled={
                      !configData.dispatch_integrations?.BOL?.active
                    }
                    onChange={() => {
                      if (configData.dispatch_integrations?.BOL)
                        configData.dispatch_integrations.BOL.required =
                          !configData.dispatch_integrations.BOL
                            .required;
                      setConfigData(cloneDeep(configData));
                      setHasUnsavedChanges(true);
                    }}
                  />
                </>
                <>
                  <Typography variant='h6' component='h3'>
                    AWB
                  </Typography>
                  <Typography variant='body2' component='span'>
                    Active
                  </Typography>
                  <CSSwitch
                    color='default'
                    size='medium'
                    checked={Boolean(
                      configData.dispatch_integrations?.AWB?.active,
                    )}
                    onChange={() => {
                      const newAwbValue =
                        !configData.dispatch_integrations?.AWB
                          ?.active;

                      if (configData.dispatch_integrations?.AWB) {
                        configData.dispatch_integrations.AWB.active =
                          newAwbValue;
                        if (!newAwbValue) {
                          configData.dispatch_integrations.AWB.required =
                            false;
                        }
                      }
                      setConfigData(cloneDeep(configData));
                      setHasUnsavedChanges(true);
                    }}
                  />
                  <Typography variant='body2' component='span'>
                    Required
                  </Typography>
                  <CSSwitch
                    color='default'
                    size='medium'
                    disabled={
                      !configData.dispatch_integrations?.AWB?.active
                    }
                    checked={Boolean(
                      configData.dispatch_integrations?.AWB?.required,
                    )}
                    onChange={() => {
                      if (configData.dispatch_integrations?.AWB)
                        configData.dispatch_integrations.AWB.required =
                          !configData.dispatch_integrations.AWB
                            .required;
                      setConfigData(cloneDeep(configData));
                      setHasUnsavedChanges(true);
                    }}
                  />
                </>
              </CSMonoGridContainer>
              <CSMonoGridContainer>
                <>
                  <Typography
                    variant='h6'
                    component='h3'
                    className={classes.sectionTitle}
                  >
                    Relabel Options
                  </Typography>
                  <CSSectionTitleSeparator topMargin={10} />
                </>
                <>
                  {Object.values(
                    RelabelOptionsConfigProvidersEnum,
                  ).map((value) => (
                    <Box key={value}>
                      <Typography variant='body2' component='span'>
                        {value}
                      </Typography>
                      <CSSwitch
                        color='default'
                        size='medium'
                        checked={configData.relabel_options?.providers?.includes(
                          value,
                        )}
                        onChange={(event) => {
                          if (!configData.relabel_options) return;

                          const { checked } = event.target;

                          if (checked) {
                            configData.relabel_options.providers?.push(
                              value,
                            );
                          } else {
                            configData.relabel_options.providers =
                              configData.relabel_options.providers?.filter(
                                (option) => option !== value,
                              );
                          }

                          setConfigData(cloneDeep(configData));
                          setHasUnsavedChanges(true);
                        }}
                      />
                    </Box>
                  ))}
                </>
              </CSMonoGridContainer>
            </Paper>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={12} sm={8}>
              <Typography variant='h3' component='h2'>
                External Data Fulfilment Providers
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              className={classes.actionButtonsWrapper}
            >
              <CSButton
                className={classes.actionButton}
                variant='contained'
                color={{
                  buttonColor: 'secondary',
                  iconColor: 'primary',
                }}
                disabled={availableProvidersUnused.length === 0}
                onClick={() => {
                  setShowAddEditProviderDialog(true);
                }}
                startIcon={<AddBoxOutlinedIcon />}
              >
                Add Provider
              </CSButton>
            </Grid>
            <Grid item xs={12}>
              <CSSectionTitleSeparator topMargin={5} />
            </Grid>
            <Grid item xs={12}>
              {configData && (
                <PaginatedTable
                  key={'fulfilment-providers-' + lastUpdated}
                  columns={[
                    {
                      id: 'providerType',
                      label: 'Provider Type',
                      width: 'auto',
                    },
                  ]}
                  fetch={() => {
                    const selectedProviders =
                      configData.external_fulfilment?.providers;

                    return {
                      data: {
                        results: selectedProviders?.map(
                          (provider) => {
                            return {
                              providerType: provider.id,
                              ...provider.properties,
                            };
                          },
                        ),
                        count: selectedProviders?.length,
                      },
                    };
                  }}
                  groupActions
                  actions={[
                    {
                      tableLabel: ' ',
                      columnLabel: <EditIcon />,
                      callbackProperty: 'providerType',
                      qualifier: 'providerType',
                      callback: onEditProvider,
                    },
                    {
                      tableLabel: ' ',
                      columnLabel: <CancelIcon />,
                      callbackProperty: 'providerType',
                      qualifier: 'providerType',
                      callback: onRemoveProvider,
                    },
                  ]}
                  title=''
                  showPagination={false}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...sectionPageBaseStyle,
    actionButtonsWrapper: {
      textAlign: 'right',
    },
    actionButton: {
      margin: 2,
    },
    sectionTitle: {
      marginTop: 10,
    },
    banner: {
      marginTop: 10,
    },
    gridItem: {
      [theme.breakpoints.up('sm')]: {
        display: 'grid',
        minHeight: 300,
      },
    },
  })),
)(ConfigurationDetails);
