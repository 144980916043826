import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React from 'react';
import Typography from '../typography/Typography';

interface CSDialogAlertProps {
  classes: { [key: string]: string };
  alertMessage: string;
  bottomMargin?: number | string;
  topMargin?: number | string;
  'data-testid'?: string;
}

const CSDialogAlert: React.FC<CSDialogAlertProps> = ({
  classes,
  alertMessage,
  topMargin,
  bottomMargin,
  'data-testid': dataTestId,
}) => {
  const marginClasses = getMarginStyles(bottomMargin, topMargin)();

  return (
    <Alert
      color='warning'
      severity='info'
      classes={{
        standardWarning: classes.alert,
        icon: classes.alertIcon,
        root: classes.root,
      }}
      className={marginClasses.margin}
      data-testid={dataTestId}
    >
      <Typography component='p' variant='body2'>
        {alertMessage}
      </Typography>
    </Alert>
  );
};

const getMarginStyles = (
  marginBottom: number | string = 0,
  marginTop: number | string = 0,
) =>
  makeStyles(
    () => ({
      margin: {
        marginBottom,
        marginTop,
      },
    }),
    { name: 'CSSectionTitleSeparator' },
  );

export default withStyles(
  createStyles((theme: Theme) => ({
    root: {
      '& $alertIcon': {
        color: theme.palette.text.primary,
      },
    },
    alert: {
      borderRadius: theme.shape.borderRadius_sm,
      backgroundColor: theme.palette.secondary.light,
    },
    alertIcon: {},
  })),
)(CSDialogAlert);
