import { AuthApi, ObtainToken, Logout } from 'cloudsort-client';
import axios from '../utils/axios';
import LocalStorage from './LocalStorage.service';
import browserHistory from '../utils/browserHistory';
import { NonAuthRoutes } from '../interfaces/routes';

class Auth {
  private api: AuthApi;
  private rememberMe: boolean;

  constructor() {
    this.setUpInterceptors();
    this.api = new AuthApi(undefined, undefined, axios as any);

    this.rememberMe = false;
  }

  public async logIn(authParams: ObtainToken) {
    const response = await this.api.authLoginCreate({
      data: authParams,
    });

    const payload = this.getPayload(response.data.access);

    if (payload?.full_name) {
      LocalStorage.setMyFullName(payload.full_name);
    }

    LocalStorage.setAccessToken(response.data.access);
    if (this.rememberMe) {
      LocalStorage.setRefreshToken(response.data.refresh);
    }

    if (payload?.is_customer !== undefined) {
      LocalStorage.setIsCustomer(payload.is_customer);
      return payload?.is_customer;
    }

    return undefined;
  }

  public async logOut(authParams: Logout) {
    await this.api.authLogoutCreate({ data: authParams });
    LocalStorage.removeAccessToken();
    LocalStorage.removeRefreshToken();
    LocalStorage.removeMyStationsData();
    LocalStorage.removeWeatherInfo();
    LocalStorage.removeMapViewState();
    LocalStorage.removeSystemDate();
    LocalStorage.removeMyPermissionsMatrix();
    LocalStorage.removeFullName();
  }

  public async resetPassword(email: string) {
    return this.api.authPasswordResetCreate({
      data: { email },
    });
  }

  public async changePassword(params: {
    token: string;
    password: string;
  }) {
    return this.api.authPasswordChangeCreate({
      data: params,
    });
  }

  public async changeEmail(params: { token: string }) {
    return this.api.authEmailChangeCreate({
      data: params,
    });
  }

  public isLoggedIn(): boolean {
    let tk = LocalStorage.getAccessToken();
    if (tk == null) {
      return false;
    }
    const payload = this.getPayload(tk);
    if (this.isTokenExpired(payload)) {
      return false;
    }
    return true;
  }

  public getMyId() {
    const accessToken = LocalStorage.getAccessToken();
    return accessToken
      ? this.getPayload(accessToken).user_id
      : undefined;
  }

  public getMyEmail() {
    const accessToken = LocalStorage.getAccessToken();
    return accessToken ? this.getPayload(accessToken).sub : undefined;
  }

  public setRememberMe(value: boolean) {
    this.rememberMe = value;
  }

  public hasRefreshToken(): boolean {
    const token = LocalStorage.getRefreshToken();
    return !!token && !this.isTokenExpired(token);
  }

  public async refreshToken() {
    const refreshToken = LocalStorage.getRefreshToken();
    if (!refreshToken) return false;
    const payload = this.getPayload(refreshToken);
    if (this.isTokenExpired(payload)) {
      return false;
    }
    if (refreshToken) {
      const res = await this.api.authRefreshCreate({
        data: {
          refresh: refreshToken,
        },
      });
      if (res.status === 200 && res.data.access && res.data.refresh) {
        LocalStorage.setAccessToken(res.data.access);
        LocalStorage.setRefreshToken(res.data.refresh);
        return true;
      }
    }

    return false;
  }

  private isTokenExpired(payload: any) {
    return (
      payload == null ||
      payload['exp'] < Math.round(new Date().getTime() / 1000)
    );
  }

  private getPayload(token: string | undefined) {
    if (token === undefined) {
      return undefined;
    }
    return this.parseJwt(token.replace('Bearer ', ''));
  }

  private parseJwt(token: string) {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(
            (c) =>
              '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2),
          )
          .join(''),
      );

      return JSON.parse(jsonPayload);
    } catch (e) {
      console.error(e);
    }

    return undefined;
  }

  private setUpInterceptors() {
    axios.interceptors.request.use(
      (config) => {
        const token = LocalStorage.getAccessToken();
        if (
          token &&
          config.headers &&
          !config.url?.includes('/tracking/')
        ) {
          config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
      },
      (e) => {
        Promise.reject(e);
      },
    );

    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (e) => {
        const originalRequest = e.config;
        if (e.response && e.response.status === 401) {
          if (originalRequest.url.includes('/auth/refresh/')) {
            LocalStorage.removeRefreshToken();
            console.error(`Unable to refresh the access token: ${e}`);
          } else {
            const isTokenRefreshed = await this.refreshToken();
            if (isTokenRefreshed) {
              axios.defaults.headers.common['Authorization'] =
                'Bearer ' + LocalStorage.getAccessToken();
              return axios(originalRequest);
            } else {
              LocalStorage.removeAccessToken();
              LocalStorage.removeRefreshToken();
              browserHistory.push(NonAuthRoutes.LOGIN);
            }
          }
        }
        return Promise.reject(e);
      },
    );
  }
}

export default new Auth();
