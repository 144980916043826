import { Switch } from '@material-ui/core';
import * as React from 'react';
import { withStyles } from '@material-ui/core';
import styles from './CSSwitch.styles';
import { ISwitchProps } from './CSSwitchTypes';

const CSSwitch: React.FC<ISwitchProps> = React.forwardRef(
  (props, ref) => {
    const {
      variant = 'standard',
      labels = {
        active: 'ACTIVE',
        inactive: 'INACTIVE',
        disabled: 'DISABLED',
      },
      disabled = false,
      ...muiProps
    } = props;
    const ThemedSwitch = React.useMemo(
      () =>
        withStyles(styles(variant, labels, disabled), {
          name: 'CSSwitch',
        })(Switch),
      [variant, disabled, labels],
    );
    return (
      <ThemedSwitch disabled={disabled} {...muiProps} ref={ref} />
    );
  },
);

export default CSSwitch;
