import React from 'react';
import { Typography } from '../../../primitives';
import { withStyles } from '@material-ui/core/styles';
import { Box, Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import detailsPageStyles from '../../../commonStyles/detailsPage.style';
import configPageStyles from '../../../commonStyles/configPage.style';
import { INTERACTION } from '../../utils';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DashboardModule from './DashboardModule';
import Navigation from './Navigation';
import { ArrowBackIos } from '@material-ui/icons';
import { CurrentView } from '../Modules';

interface Props {
  classes: { [key: string]: string };
  data: any;
  setData: (data: any) => void;
  interaction?: INTERACTION;
  goToPreviousPage: () => void;
}

const WebAppMobile: React.FC<Props> = ({
  classes,
  data,
  interaction,
  setData,
  goToPreviousPage,
}) => {
  const currentView = CurrentView.MOBILE;
  return (
    <>
      <Typography
        style={{ fontSize: 16, fontWeight: 500 }}
        onClick={goToPreviousPage}
      >
        <ArrowBackIos style={{ fontSize: 14 }} />
        Web App
      </Typography>
      <Box mt={1}>
        <Accordion defaultExpanded={false}>
          <AccordionSummary
            classes={{
              expanded: classes.panelSummaryExpanded,
              root: classes.panelSummary,
            }}
            expandIcon={
              <ExpandMoreIcon
                className={classes.accordionExpandColapseIcon}
              />
            }
          >
            <Typography className={classes.summaryPanelTitleText}>
              Dashboard
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            classes={{
              root: classes.panelDetails,
            }}
          >
            <Box>
              <DashboardModule
                currentView={currentView}
                interaction={interaction}
                data={data?.config?.WEB?.LAYOUTS?.DASHBOARD}
                setPartialData={(partialData) => {
                  const partialDataObj = { ...data };
                  partialDataObj.config.WEB.LAYOUTS.DASHBOARD =
                    partialData;
                  setData(partialDataObj);
                }}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box mt={1}>
        <Accordion defaultExpanded={false}>
          <AccordionSummary
            classes={{
              expanded: classes.panelSummaryExpanded,
              root: classes.panelSummary,
            }}
            expandIcon={
              <ExpandMoreIcon
                className={classes.accordionExpandColapseIcon}
              />
            }
          >
            <Typography className={classes.summaryPanelTitleText}>
              Navigation
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            classes={{
              root: classes.panelDetails,
            }}
          >
            <Box>
              <Navigation
                currentView={currentView}
                interaction={interaction}
                dataModules={data?.config?.GENERAL?.MODULES}
                data={data?.config?.WEB?.NAV_MENU}
                setPartialData={(partialData) => {
                  const partialDataObj = { ...data };
                  partialDataObj.config.WEB.NAV_MENU = partialData;
                  setData(partialDataObj);
                }}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...configPageStyles(theme),
  })),
)(WebAppMobile);
