import { EventsApi, EventEventTypeEnum } from 'cloudsort-client';
import EphemeralStateService from './EphemeralState.service';
import axios from '../utils/axios';

class Events {
  private api: EventsApi;

  constructor() {
    this.api = new EventsApi(undefined, undefined, axios as any);

    this.getAll = this.getAll.bind(this);
  }

  public async getAll(params: {
    user?: number;
    container?: number;
    _package?: number;
    device?: number;
    zone?: number;
    area?: number;
    outboundLoad?: number;
    inboundLoad?: number;
    page?: number;
    pageSize?: number;
    eventType?: EventEventTypeEnum;
    sortBy?:
      | 'timestamp'
      | 'area__name'
      | '-timestamp'
      | '-area__name';
    fromDate?: string;
    toDate?: string;
  }) {
    return this.api.eventsList({
      ...params,
      station: EphemeralStateService.getMyStationId(),
    });
  }
}

export default new Events();
