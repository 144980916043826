import { Theme } from '@material-ui/core';
import {
  TypographyStyle,
  Variant,
} from '@material-ui/core/styles/createTypography';
import get from 'lodash/get';
import {
  ExtendedPaletteKeys,
  ExtendedTypographyKeys,
} from '../../../theming/material-ui';
import {
  TypographyColors,
  TypographyColorVariant,
} from './TypographyTypes';
export const knownTypographies = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'subtitle1',
  'body1',
  'body2',
  'button',
  'caption',
  'subtitle2',
  'overline',
  'inherit',
  'srOnly',
];
export const knownTypographiesColors = [
  'inherit',
  'initial',
  'primary',
  'secondary',
  'textPrimary',
  'textSecondary',
  'error',
];

export const isMaterialTypographyVariant = (
  typography:
    | 'inherit'
    | ExtendedTypographyKeys
    | 'srOnly'
    | undefined,
) => knownTypographies.includes(typography || '');

export const isMaterialTypographyColor = (
  typographyColor:
    | 'inherit'
    | ExtendedPaletteKeys
    | TypographyColors
    | TypographyColorVariant
    | undefined,
) =>
  typeof typographyColor === 'string' &&
  knownTypographiesColors.includes(typographyColor || '');

export const toMaterialTypographyVariant = (
  typography:
    | 'inherit'
    | ExtendedTypographyKeys
    | 'srOnly'
    | undefined,
): Variant | 'inherit' =>
  isMaterialTypographyVariant(typography)
    ? (typography as Variant)
    : 'inherit';

export const toMaterialTypographyColor = (
  typographyColor:
    | 'inherit'
    | ExtendedPaletteKeys
    | TypographyColors
    | TypographyColorVariant
    | undefined,
): TypographyColors =>
  isMaterialTypographyColor(typographyColor)
    ? (typographyColor as TypographyColors)
    : 'inherit';

export const getTypographyColorStyles = (
  typographyColor:
    | 'inherit'
    | ExtendedPaletteKeys
    | TypographyColors
    | TypographyColorVariant
    | undefined,
  theme: Theme,
) => {
  if (isMaterialTypographyColor(typographyColor)) return {};
  if (typeof typographyColor === 'string')
    return { color: get(theme.palette, `${typographyColor}.main`) };
  return {
    color: get(
      theme.palette,
      `${typographyColor?.color}.${String(typographyColor?.variant)}`,
    ),
  };
};

export const getTypographyStyles = (
  variant:
    | 'inherit'
    | ExtendedTypographyKeys
    | 'srOnly'
    | undefined = 'inherit',
  theme: Theme,
) =>
  !isMaterialTypographyVariant(variant)
    ? (get(
        theme.typography,
        variant.replace('-', '.'),
      ) as TypographyStyle)
    : {};
