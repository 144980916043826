import * as React from 'react';
import useStableId from '../../../utils/useStableId';


export interface HeaderLogoProps extends React.SVGProps<SVGSVGElement> {
 alt: string;
}

const CSHeaderLogo: React.FC<HeaderLogoProps> = ({ id, alt, ...others}) => {
  const containerId = useStableId(id || '', 'CSLogo');
  return (
    <div id={containerId} aria-label={alt}>
      <svg
        width='151'
        height='24'
        viewBox='0 0 151 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        aria-describedby={containerId}
        {...others}
      >
        <g clipPath='url(#clip0_619_40188)'>
          <path
            d='M29.7398 23.8292C27.0429 23.8292 25.249 22.1051 24.8995 21.7323L26.4605 20.2471C26.6178 20.416 28.0681 21.8663 30.1648 21.6566C30.1882 21.6566 32.6462 21.3654 33.6189 19.3268C34.0498 18.4299 34.1021 17.4047 33.7818 16.2748C33.514 15.337 33.0248 14.5682 32.3199 14.0032C31.9764 13.7237 31.2423 13.2344 30.1998 13.0946C29.3377 12.9781 28.4058 13.1528 27.3576 13.6363C26.111 14.2071 25.5926 14.7954 24.451 16.0826C23.8919 16.7116 23.1987 17.4979 22.2377 18.4706C20.1118 20.6257 18.8246 21.9304 16.6113 22.8623C14.5902 23.7127 12.7438 23.8583 11.5556 23.835C9.22582 23.7826 7.45515 23.072 6.60477 22.6526C3.31394 21.0217 0.850182 17.7542 0.180365 14.1139C-0.0409657 12.9199 -0.541872 8.70878 2.30048 4.90537C3.98376 2.6513 6.5174 1.06122 9.42383 0.432171C11.9051 -0.103682 14.0019 0.280733 15.027 0.554484C17.4908 1.21265 19.1682 2.51734 19.9487 3.23958C21.0088 4.21808 21.8649 5.37717 22.4998 6.68768L20.5603 7.62541C20.0477 6.55954 19.3488 5.62179 18.4867 4.82385C17.8519 4.24138 16.4889 3.17551 14.4737 2.63965C13.6291 2.41832 11.9167 2.1038 9.87816 2.54646C7.44351 3.07067 5.42241 4.33458 4.02453 6.20426C1.70638 9.30287 2.11992 12.751 2.30048 13.7295C2.84799 16.7116 4.86326 19.3851 7.56 20.7072C8.25311 21.045 9.69759 21.6217 11.6138 21.6683C13.0001 21.6974 14.4795 21.4178 15.7784 20.8645C17.5199 20.1306 18.5508 19.1288 20.7059 16.9446C21.6262 16.0127 22.296 15.2555 22.8376 14.6439C24.02 13.3101 24.7947 12.4306 26.4605 11.6676C27.8699 11.0211 29.2271 10.7765 30.4911 10.9512C32.0519 11.1667 33.1529 11.9006 33.6712 12.32C34.7253 13.1703 35.4593 14.3061 35.8495 15.6807C36.432 17.7309 36.0067 19.2919 35.5526 20.2471C34.0557 23.3865 30.5202 23.7767 30.3686 23.7942C30.1532 23.8175 29.9377 23.8292 29.7279 23.8292H29.7398Z'
            fill='inherit'
          />
          <path
            d='M45.4102 12.8439C45.4102 9.96216 47.5594 7.99219 50.2619 7.99219C52.2318 7.99219 53.404 8.96901 54.1041 10.1087L52.704 10.825C52.2483 10.0435 51.3527 9.45745 50.2781 9.45745C48.4873 9.45745 47.1198 10.8739 47.1198 12.8439C47.1198 14.8138 48.5035 16.2303 50.2781 16.2303C51.3689 16.2303 52.2645 15.6604 52.704 14.8627L54.1041 15.579C53.3879 16.7024 52.2318 17.6955 50.2619 17.6955C47.5594 17.6955 45.4102 15.7255 45.4102 12.8439Z'
            fill='inherit'
          />
          <path
            d='M57.5225 17.5164V8.13867H59.1669V16.0674H63.3021V17.5164H57.5225Z'
            fill='inherit'
          />
          <path
            d='M70.8574 7.99219C73.674 7.99219 75.6601 10.0436 75.6601 12.8439C75.6601 15.6441 73.674 17.6955 70.8574 17.6955C68.0408 17.6955 66.0547 15.6441 66.0547 12.8439C66.0547 10.0436 68.0408 7.99219 70.8574 7.99219ZM70.8574 9.45746C68.9525 9.45746 67.7479 10.9064 67.7479 12.8439C67.7479 14.7813 68.9525 16.2303 70.8574 16.2303C72.7623 16.2303 73.9671 14.765 73.9671 12.8439C73.9671 10.9227 72.7458 9.45746 70.8574 9.45746Z'
            fill='inherit'
          />
          <path
            d='M79.3398 8.13867H81.0004V13.7555C81.0004 15.2534 81.8307 16.2139 83.4101 16.2139C84.9893 16.2139 85.8034 15.2371 85.8034 13.7555V8.13867H87.464V13.8044C87.464 16.1325 86.1289 17.6792 83.4101 17.6792C80.6911 17.6792 79.3398 16.1162 79.3398 13.8207V8.13867Z'
            fill='inherit'
          />
          <path
            d='M91.5498 17.5164V8.13867H95.0339C97.9643 8.13867 99.9344 10.0761 99.9344 12.8275C99.9344 15.579 97.9481 17.5164 95.0339 17.5164H91.5498ZM93.1942 16.0674H95.0339C97.069 16.0674 98.2574 14.6021 98.2574 12.8275C98.2574 11.0529 97.134 9.57139 95.0339 9.57139H93.1942V16.0511V16.0674Z'
            fill='inherit'
          />
          <path
            d='M103.955 14.9275C104.606 15.6113 105.616 16.2137 106.918 16.2137C108.221 16.2137 108.807 15.5462 108.807 14.9275C108.807 14.0809 107.814 13.8367 106.674 13.5273C105.144 13.1529 103.337 12.697 103.337 10.7108C103.337 9.1641 104.704 7.97559 106.756 7.97559C108.221 7.97559 109.393 8.43145 110.272 9.27806L109.328 10.4991C108.595 9.76647 107.602 9.42459 106.609 9.42459C105.616 9.42459 105.014 9.88046 105.014 10.5968C105.014 11.3132 105.974 11.5736 107.081 11.8504C108.628 12.2412 110.468 12.7296 110.468 14.7647C110.468 16.3276 109.377 17.6627 106.837 17.6627C105.111 17.6627 103.858 17.0603 103.027 16.1648L103.939 14.8949L103.955 14.9275Z'
            fill='inherit'
          />
          <path
            d='M118.462 7.99219C121.279 7.99219 123.265 10.0436 123.265 12.8439C123.265 15.6441 121.279 17.6955 118.462 17.6955C115.646 17.6955 113.676 15.6441 113.676 12.8439C113.676 10.0436 115.662 7.99219 118.462 7.99219ZM118.462 9.45746C116.557 9.45746 115.353 10.9064 115.353 12.8439C115.353 14.7813 116.557 16.2303 118.462 16.2303C120.367 16.2303 121.572 14.765 121.572 12.8439C121.572 10.9227 120.351 9.45746 118.462 9.45746Z'
            fill='inherit'
          />
          <path
            d='M132.318 17.5164L130.234 14.016H128.605V17.5164H126.961V8.13867H131.08C132.936 8.13867 134.141 9.34344 134.141 11.0692C134.141 12.795 133.05 13.6416 131.927 13.8207L134.222 17.5001H132.318V17.5164ZM130.852 9.58765H128.605V12.567H130.852C131.78 12.567 132.448 11.9809 132.448 11.0692C132.448 10.1575 131.78 9.57139 130.852 9.57139V9.58765Z'
            fill='inherit'
          />
          <path
            d='M139.985 17.5164V9.58765H137.152V8.13867H144.479V9.58765H141.646V17.5164H140.001H139.985Z'
            fill='inherit'
          />
          <path
            d='M148.337 12.0501C149.431 12.0501 150.303 11.1691 150.303 10.0841C150.303 8.98988 149.431 8.11816 148.337 8.11816C147.252 8.11816 146.371 8.98988 146.371 10.0841C146.371 11.1691 147.252 12.0501 148.337 12.0501ZM148.337 8.40565C149.264 8.40565 150.015 9.14752 150.015 10.0841C150.015 11.0115 149.264 11.7719 148.337 11.7719C147.4 11.7719 146.649 11.0115 146.649 10.0841C146.649 9.14752 147.4 8.40565 148.337 8.40565ZM149.255 11.2155L148.652 10.316C148.782 10.316 149.227 10.149 149.227 9.64829C149.227 9.23098 148.884 8.96205 148.504 8.96205H147.586V11.2155H147.892V10.3252H148.291L148.875 11.2155H149.255ZM148.504 9.23098C148.699 9.23098 148.912 9.3979 148.912 9.64829C148.912 9.88011 148.699 10.047 148.504 10.047H147.892V9.23098H148.504Z'
            fill='inherit'
          />
        </g>
        <defs>
          <clipPath id='clip0_619_40188'>
            <rect width='150.742' height='24' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default CSHeaderLogo;
