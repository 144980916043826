import axios from '../utils/axios';
import {
  ModulesApi,
  ModuleDetails,
  CorporateApi,
  NestedModule,
  AreaDetailsAreaTypeEnum,
} from 'cloudsort-client';
import EphemeralStateService from './EphemeralState.service';

class ZoneModules {
  private api: ModulesApi;
  private corporateModulesApi: CorporateApi;

  constructor() {
    this.api = new ModulesApi(undefined, undefined, axios as any);
    this.corporateModulesApi = new CorporateApi(
      undefined,
      undefined,
      axios as any,
    );
  }

  public async getAll(params: {
    page?: number;
    isActive?: boolean;
    zone?: number;
    pageSize?: number;
    sortBy?: 'load_point_count' | '-load_point_count';
    areaType?: AreaDetailsAreaTypeEnum;
  }) {
    return this.api.modulesList({
      ...params,
      station: EphemeralStateService.getMyStationId(),
    });
  }

  public async update(id: number, data: ModuleDetails) {
    return this.api.modulesPartialUpdate({
      id,
      data,
    });
  }

  public async delete(id: number) {
    return this.api.modulesDelete({ id });
  }

  public async assignModuleToAZone(
    zone: number,
    module: number,
    assignedModule?: NestedModule,
  ) {
    return this.api.modulesAssign({
      data: {
        zone,
        module,
        assigned_module: assignedModule,
      },
    });
  }

  public async getAllCorporateModules(
    page?: number,
    areaType?: 'PRIMARY' | 'SECONDARY' | 'STAGING',
    sortBy?: 'load_point_count' | '-load_point_count',
    pageSize?: number,
  ) {
    return this.corporateModulesApi.corporateModulesList({
      areaType,
      sortBy,
      page,
      pageSize,
    });
  }
}

export default new ZoneModules();
