import {
  SchemeDetails,
  SchemesApi,
  Station2Scheme,
  Station2schemeApi,
} from 'cloudsort-client';
import axios from '../utils/axios';

class Schemes {
  private schemes: SchemesApi;
  private station2Scheme: Station2schemeApi;
  constructor() {
    this.schemes = new SchemesApi(undefined, undefined, axios as any);
    this.station2Scheme = new Station2schemeApi(
      undefined,
      undefined,
      axios as any,
    );
  }

  public async getAll(params: {
    station?: number;
    loadplan?: number;
    stationOwner?: number;
    loadplanStation?: number;
    page?: number;
    pageSize?: number;
    search?: string;
    isActive?: boolean;
    sortBy?: 'load_plan__active' | '-load_plan__active';
  }) {
    return this.schemes.schemesList({
      ...params,
    });
  }

  public async getByName(search = '', loadplan?: number) {
    return this.schemes.schemesList({ search, loadplan });
  }

  public async getById(id: number) {
    return this.schemes.schemesRead({ id });
  }

  public async create(data: SchemeDetails) {
    return this.schemes.schemesCreate({
      data,
    });
  }

  public async update(
    data: SchemeDetails,
    conflictResolve?: boolean,
  ) {
    return this.schemes.schemesPartialUpdate({
      id: data.id!,
      data,
      conflictResolve,
    });
  }

  public async delete(id: number) {
    return this.schemes.schemesDelete({ id });
  }

  public async getStation2Scheme(params: {
    station?: number;
    scheme?: number;
    loadplan?: number;
    stationOwner?: number;
    page?: number;
    pageSize?: number;
    options?: any;
  }) {
    return this.station2Scheme.station2schemeList({ ...params });
  }

  public async addStation2Scheme(
    data: Station2Scheme,
    conflictResolve?: boolean,
  ) {
    return this.station2Scheme.station2schemeCreate({
      data,
      conflictResolve,
    });
  }

  public async deleteStation2Scheme(id: number) {
    return this.station2Scheme.station2schemeDelete({ id });
  }
}

export default new Schemes();
