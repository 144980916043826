import { FmcsApi } from 'cloudsort-client';
import axios from '../utils/axios';
import { MAX_PAGE_SIZE } from './utils/constants';

class Fmc {
  private api: FmcsApi;

  constructor() {
    this.api = new FmcsApi(undefined, undefined, axios as any);
  }

  public async getAll() {
    return this.api.fmcsList({ pageSize: MAX_PAGE_SIZE });
  }

  public async search(search: string) {
    return this.api.fmcsList({ search });
  }

  public async getById(id: number) {
    const fmcsList = await this.getAll();
    return {
      data: fmcsList.data.results.find((fmc) => fmc.id === id),
    };
  }
}

export default new Fmc();
