import flowColors from './flowColors';

export default {
  loadPoint: {
    position: 'relative',
    background: flowColors.secondaryBorder,
    width: '100%',
    height: '100%',
    minHeight: '64px',
    overflow: 'hidden',

    '&.shade1': {
      background: flowColors.edgeBlue1,
    },
    '&.shade2': {
      background: flowColors.edgeBlue2,
    },
    '&.shade3': {
      background: flowColors.edgeBlue3,
    },
    '&.shade4': {
      background: flowColors.edgeBlue4,
    },

    '& h4': {
      position: 'absolute',
      top: 0,
      left: 0,
      padding: '3px 5px',
      margin: 0,
      fontSize: 6,
      lineHeight: '8px',
      minWidth: 8,
      background: flowColors.white,
      color: flowColors.black,
      borderBottomRightRadius: 5,
      textAlign: 'center',
    },
  },

  tooltip: {
    background: flowColors.dark,
    padding: 10,
  },
  tooltipArrow: {
    color: flowColors.dark,
  },

  tooltipTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  tooltipValue: {
    fontSize: 14,
    marginBottom: 5,
  },
};
