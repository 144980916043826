import { Grid } from '@material-ui/core';
import * as React from 'react';
import { CSDualGridContainerProps } from './csDualGridContainerTypes';

const CSDualGridContainer: React.FC<CSDualGridContainerProps> = ({
  children,
  childrenColumnConfiguration,
  containerSpacing = 2,
  'data-testid': dataTestId,
}) => {
  const toRender = children.filter((e) => e);
  return (
    <Grid
      data-testid={dataTestId}
      container
      spacing={containerSpacing}
    >
      {toRender.map((v, index) => (
        <Grid
          key={`${index}-${v.key}`}
          item
          xs={12}
          sm={childrenColumnConfiguration?.[index] || 6}
        >
          {v}
        </Grid>
      ))}
    </Grid>
  );
};

export default CSDualGridContainer;
