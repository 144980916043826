import {
  RoutesApi,
  Route2stationsApi,
  Route,
  Route2Station,
  Route2StationDetails,
} from 'cloudsort-client';
import axios from '../utils/axios';
import EphemeralStateService from './EphemeralState.service';

class Routes {
  private routes: RoutesApi;
  private routeToStations: Route2stationsApi;

  constructor() {
    this.routes = new RoutesApi(undefined, undefined, axios as any);
    this.routeToStations = new Route2stationsApi(
      undefined,
      undefined,
      axios as any,
    );

    this.getAll = this.getAll.bind(this);
    this.getById = this.getById.bind(this);
  }

  public async getAll(params?: {
    search?: string;
    page?: number;
    pageSize?: number;
    scheme?: number;
  }) {
    return this.routes.routesList({
      ...params,
      station: EphemeralStateService.getMyStationId(),
    });
  }

  public async getById(id: number) {
    return this.routes.routesRead({ id });
  }

  public async create(data: Route) {
    return this.routes.routesCreate({ data });
  }

  public async setRoutes(id: number, data: Route) {
    return this.routes.routesPartialUpdate({ id, data });
  }

  public async delete(id: number) {
    return this.routes.routesDelete({ id });
  }

  public async addRouteToStations(data: Route2Station) {
    if (!data.station) {
      data.station = EphemeralStateService.getMyStationId();
    }
    return this.routeToStations.route2stationsCreate({ data });
  }

  public async removeRouteToStationsById(id: number) {
    return this.routeToStations.route2stationsDelete({ id });
  }

  public async getRouteToStationsById(
    station?: number,
    pageSize?: number,
    originStation = EphemeralStateService.getMyStationId(),
  ) {
    return this.routeToStations.route2stationsList({
      station,
      originStation,
      pageSize,
    });
  }

  public async updateRouteToStationsById(
    id: number,
    data: Route2StationDetails,
  ) {
    return this.routeToStations.route2stationsPartialUpdate({
      id,
      data,
    });
  }
}

export default new Routes();
