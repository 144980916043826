import React, { useEffect, useState } from 'react';

import { AuthRoutes } from '../../../interfaces/routes';
import { withStyles } from '@material-ui/core/styles';
import configurationUtils from '../../../utils/configurationUtils';
import {
  createFiltersQuery,
  getAppliedFilters,
  SelectedFilters,
} from '../../filtersDrawer/FiltersDrawer';
import StackedStats, {
  CardContainerVariants,
  CardItemVariants,
  StatData,
} from '../../stackedStats/StackedStats';
import { LoaderRing } from '../../primitives';
import { CARD_LOAD_DELAY } from '../Dashboard';
import queryString from 'query-string';
import useMountedStatus from '../../../hooks/useMountedStatus';
import useNetworkStatsErrorRegistry from '../../../hooks/useNetworkStatsErrorRegistry';
import { setErrorsOnData } from './utils/utils';

//Services
import EphemeralStateService from '../../../services/EphemeralState.service';
import PackagesService from '../../../services/Packages.service';
import ContainersService from '../../../services/Containers.service';
import OutboundLoadsService from '../../../services/OutboundLoads.service';

interface Props {
  title: string;
  index: number;
  filters?: SelectedFilters;
  classes: { [key: string]: string };
}

interface StatLabel {
  key: any;
  label: any;
  headingBefore?: string;
}

const ExceptionsStats: React.FC<Props> = ({
  title,
  index,
  filters,
}) => {
  const [data, setData] = useState<StatData>({});
  const [highlightedTotal, setHighlightedTotal] = useState<number>();

  const componentMounted = useMountedStatus();
  const { networkStatsErrors, registerError } =
    useNetworkStatsErrorRegistry([]);

  const fetchStats = async () => {
    //Station Behaviours
    const stationBehaviors = configurationUtils
      .getConfigArray({
        config:
          EphemeralStateService.getMyStationConfiguratation().GENERAL
            .BEHAVIORS,
        filter: { key: 'active', value: true },
      })
      .map((item: any) => item.id);

    //OT Modes
    const otModes = configurationUtils
      .getConfigArray({
        config:
          EphemeralStateService.getMyStationConfiguratation().OT
            .MODES,
        filter: { key: 'active', value: true },
      })
      .map((item: any) => item.id);

    //Package labels
    const packageLabels: StatLabel[] = [];

    if (stationBehaviors?.includes('MISSORTING'))
      packageLabels.push({
        key: 'EXCEPTION_MISSORT_FORCED',
        label: 'Missorted',
      });

    if (stationBehaviors?.includes('MISLOADING'))
      packageLabels.push({
        key: 'EXCEPTION_MISLOAD_FORCED',
        label: 'Misloaded',
      });

    packageLabels.push({
      key: 'EXCEPTION_UNPROCESSABLE',
      label: 'Unprocessable',
    });

    if (otModes?.includes('RELABEL'))
      packageLabels.push({
        key: 'EXCEPTION_RELABELED',
        label: 'Relabeled',
      });

    if (otModes?.includes('RETURNING'))
      packageLabels.push({
        key: 'EXCEPTION_RETURNED',
        label: 'Returned',
      });

    //Add smaller heading before first item
    packageLabels[0].headingBefore = configurationUtils.getPageTitle(
      false,
      'PACKAGE',
    );

    //Container labels
    const containerLabels: StatLabel[] = [];

    if (stationBehaviors?.includes('MISMOVING'))
      containerLabels.push({
        key: 'EXCEPTION_MISMOVE_FORCED',
        label: 'Force Moved',
      });

    containerLabels.push({
      key: 'EXCEPTION_PRINT_FORCED',
      label: 'Force Labeled',
    });

    if (stationBehaviors?.includes('MISLOADING'))
      containerLabels.push({
        key: 'EXCEPTION_MISLOAD_FORCED__CONTAINER',
        label: 'Force Loaded',
      });

    //Add smaller heading before first item
    containerLabels[0].headingBefore =
      configurationUtils.getPageTitle(false, 'CONTAINER');

    //Loads labels
    const loadsLabels: StatLabel[] = [];

    loadsLabels.push({
      key: 'EXCEPTION_DISPATCH_FORCED',
      label: 'Force Dispatched',
    });

    //Add smaller heading before first item
    loadsLabels[0].headingBefore = configurationUtils.getPageTitle(
      false,
      'OUTBOUND_LOAD',
    );

    let packageResults: StatData = configurationUtils.isModuleActive(
      'PACKAGE',
    )
      ? packageLabels.reduce((acc, step) => {
          return {
            ...acc,
            [step.key]: {
              label: step.label,
              value: 'pending',
              headingBefore: step.headingBefore,
              itemLinkBase: AuthRoutes.PACKAGE,
            },
          };
        }, {})
      : [];

    let containerResults: StatData =
      configurationUtils.isModuleActive('CONTAINER')
        ? containerLabels.reduce((acc, step) => {
            return {
              ...acc,
              [step.key]: {
                label: step.label,
                value: 'pending',
                headingBefore: step.headingBefore,
                itemLinkBase: AuthRoutes.CONTAINER,
              },
            };
          }, {})
        : [];

    let loadsResults: StatData = configurationUtils.isModuleActive(
      'OUTBOUND_LOAD',
    )
      ? loadsLabels.reduce((acc, step) => {
          return {
            ...acc,
            [step.key]: {
              label: step.label,
              value: 'pending',
              headingBefore: step.headingBefore,
              itemLinkBase: AuthRoutes.OUTBOUND_LOAD,
            },
          };
        }, {})
      : [];

    setData({
      ...packageResults,
      ...containerResults,
      ...loadsResults,
    });

    let totalItems = 0;
    let numberOfStatsFetched = 0;

    const parsedFilters = {
      owner:
        filters && filters.owner && filters.owner.values.length
          ? filters.owner.values.map((item: any) => item.id)
          : undefined,
      carrier:
        filters && filters.carrier && filters.carrier.values.length
          ? filters.carrier.values.map((item: any) => item.id)
          : undefined,
      route:
        filters && filters.route && filters.route.values.length
          ? filters.route.values.map((item: any) => item.id)
          : undefined,
      destination:
        filters && filters.stop && filters.stop.values.length
          ? filters.stop.values.map((item: any) => item.id)
          : undefined,
      hidePending:
        filters &&
        filters.hidePending &&
        filters.hidePending.values.length
          ? filters.hidePending.values[0].id === 'yes'
            ? true
            : filters.hidePending.values[0].id === 'no'
            ? false
            : undefined
          : undefined,
    };

    const filtersQuery = createFiltersQuery(filters);

    setTimeout(() => {
      if (configurationUtils.isModuleActive('PACKAGE')) {
        packageLabels.forEach((ps) => {
          PackagesService.getAll({
            defects: [ps.key],
            pageSize: 1,
            ...parsedFilters,
          })
            .then((res) => {
              packageResults = {
                ...packageResults,
                [ps.key]: {
                  ...packageResults[ps.key],
                  value: res.data.count,
                  filter: {
                    name:
                      queryString.stringify(filtersQuery) +
                      '&packageDefect',
                    value: ps.key,
                  },
                },
              };
              if (componentMounted) {
                setData({
                  ...packageResults,
                  ...containerResults,
                  ...loadsResults,
                });
              }
              totalItems += res.data.count;
              numberOfStatsFetched++;
              if (
                numberOfStatsFetched ===
                  packageLabels.length +
                    containerLabels.length +
                    loadsLabels.length &&
                componentMounted
              ) {
                //all calls are finished
                setHighlightedTotal(totalItems);
              }
            })
            .catch((e) => {
              registerError(ps.key);
            });
        });
      }

      if (configurationUtils.isModuleActive('CONTAINER')) {
        containerLabels.forEach((ps) => {
          ContainersService.getAll({
            defects: [ps.key.replace('__CONTAINER', '')],
            pageSize: 1,
            ...parsedFilters,
          })
            .then((res) => {
              containerResults = {
                ...containerResults,
                [ps.key]: {
                  ...containerResults[ps.key],
                  value: res.data.count,
                  filter: {
                    name:
                      queryString.stringify(filtersQuery) +
                      '&containerDefect',
                    value: ps.key.replace('__CONTAINER', ''),
                  },
                },
              };
              if (componentMounted) {
                setData({
                  ...packageResults,
                  ...containerResults,
                  ...loadsResults,
                });
              }
              totalItems += res.data.count;
              numberOfStatsFetched++;

              if (
                numberOfStatsFetched ===
                  packageLabels.length +
                    containerLabels.length +
                    loadsLabels.length &&
                componentMounted
              ) {
                //all calls are finished
                setHighlightedTotal(totalItems);
              }
            })
            .catch((e) => {
              registerError(ps.key);
            });
        });
      }

      if (configurationUtils.isModuleActive('OUTBOUND_LOAD')) {
        loadsLabels.forEach((ps) => {
          OutboundLoadsService.getAll({
            defects: [ps.key],
            pageSize: 1,
          })
            .then((res) => {
              loadsResults = {
                ...loadsResults,
                [ps.key]: {
                  ...loadsResults[ps.key],
                  value: res.data.count,
                  filter: {
                    name: 'Defect',
                    value:
                      ps.key === 'EXCEPTION_DISPATCH_FORCED'
                        ? 'Force Dispatched'
                        : ps.key,
                  },
                },
              };
              if (componentMounted) {
                setData({
                  ...packageResults,
                  ...containerResults,
                  ...loadsResults,
                });
              }
              totalItems += res.data.count;
              numberOfStatsFetched++;

              if (
                numberOfStatsFetched ===
                  packageLabels.length +
                    containerLabels.length +
                    loadsLabels.length &&
                componentMounted
              ) {
                //all calls are finished
                setHighlightedTotal(totalItems);
              }
            })
            .catch((e) => {
              registerError('EXCEPTION_DISPATCH_FORCED');
            });
        });
      }
    }, index * CARD_LOAD_DELAY);
  };

  useEffect(() => {
    fetchStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return data ? (
    <>
      <StackedStats
        title={title}
        containerStyleVariant={CardContainerVariants.WHITE}
        itemsStyleVariant={CardItemVariants.WHITE_PAPER}
        dataTestId='exceptions-stats'
        stats={setErrorsOnData(data, networkStatsErrors)}
        linkBase={AuthRoutes.PACKAGE}
        highlighedNumber={
          highlightedTotal ? highlightedTotal : undefined
        } // don't display if count is 0
        highlightPositiveValues={true}
        filters={getAppliedFilters(filters)}
      />
    </>
  ) : (
    <LoaderRing />
  );
};

export default withStyles({})(ExceptionsStats);
