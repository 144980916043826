import debounce from 'lodash/debounce';
import { useEffect } from 'react';

export function useOnWindowResize(
  onResizeCallback?: () => void,
  debounceTime: number = 100,
) {
  useEffect(() => {
    const debouncedResizeHandler = debounce(() => {
      onResizeCallback?.();
    }, debounceTime); // 100ms
    window.addEventListener('resize', debouncedResizeHandler);
    return () =>
      window.removeEventListener('resize', debouncedResizeHandler);
  }, [onResizeCallback, debounceTime]);
}
