export const processSearchQuery = (
  searchQuery: string,
  componentText: string[],
): string[] => {
  const searchResult = componentText.filter((text) =>
    text.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  return searchResult.length ? [searchQuery] : [];
};
