import {
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core/styles';
import {
  ExtendedPaletteKeys,
  ExtendedTypographyKeys,
} from '../../../theming/material-ui';
import {
  TypographyColors,
  TypographyColorVariant,
} from './TypographyTypes';
import {
  getTypographyColorStyles,
  getTypographyStyles,
} from './TypographyUtils';

export const getClasses = makeStyles((theme: Theme) => ({
  underlined: {
    textDecoration: 'underline',
  },
}));

export default (
  theme: Theme,
  variant:
    | 'inherit'
    | ExtendedTypographyKeys
    | 'srOnly'
    | undefined = 'inherit',
  color:
    | ExtendedPaletteKeys
    | TypographyColors
    | TypographyColorVariant
    | undefined,
) => {
  const typographyStyles = getTypographyStyles(variant, theme);
  const colorStyle = getTypographyColorStyles(color, theme);
  return createStyles(() => ({
    root: {
      ...colorStyle,
      ...typographyStyles,
    },
  }));
};
