//Flatten points from [[x,y],[x,y]...] to [x,y,x,y...]

import utils from '../map/utils/utils';

const flattenPoints = (points: number[][]) =>
  points.reduce((a, b) => a.concat(b), []);

//Find min and max coordinater for a given array of points
//This works for x,y coordinates system

const findMinMaxCoordinates = (points: number[][]) => {
  const xArray = points.map((c) => c[0]);
  const yArray = points.map((c) => c[1]);
  return {
    x: {
      min: Math.min(...xArray),
      max: Math.max(...xArray),
      mid: (Math.min(...xArray) + Math.max(...xArray)) / 2,
    },
    y: {
      min: Math.min(...yArray),
      max: Math.max(...yArray),
      mid: (Math.min(...yArray) + Math.max(...yArray)) / 2,
    },
  };
};

//Find min and max coordinater for a given array of geo points
//This works for lat, lng coordinates system

const findMinMaxGeoCoordinates = (points: number[][][]) => {
  const lngArray = points[0].map((c) => c[0]);
  const latArray = points[0].map((c) => c[1]);

  return {
    lng: {
      min: Math.min(...lngArray),
      max: Math.max(...lngArray),
      mid: (Math.min(...lngArray) + Math.max(...lngArray)) / 2,
    },
    lat: {
      min: Math.max(...latArray),
      max: Math.min(...latArray),
      mid: (Math.min(...latArray) + Math.max(...latArray)) / 2,
    },
  };
};

// Find center point between two points
// With an optional offset

const addCenterPointToLine = (
  points: number[],
  offset: { x: number; y: number } = { x: 0, y: 0 },
) => {
  return [
    points[0],
    points[1],
    (points[0] + points[2]) / 2 + offset.x,
    (points[1] + points[3]) / 2 + offset.y,
    points[2],
    points[3],
  ];
};

// Rotate a point, based on another point
function rotatePoint(
  point: number[],
  refPoint: number[],
  angle: number,
) {
  angle = angle * (Math.PI / 180); // Convert to radians

  const rotatedX =
    Math.cos(angle) * (point[0] - refPoint[0]) -
    Math.sin(angle) * (point[1] - refPoint[1]) +
    refPoint[0];

  const rotatedY =
    Math.sin(angle) * (point[0] - refPoint[0]) +
    Math.cos(angle) * (point[1] - refPoint[1]) +
    refPoint[1];

  return [rotatedX, rotatedY];
}

// Get side lenths for given polygon

const getSides = (points: number[][], meters_per_pixel: number) => {
  const sideLengths = [];
  for (let index = 0; index < points.length; index++) {
    const nextIndex = index < points.length - 1 ? index + 1 : 0;

    const length = Math.sqrt(
      Math.pow(Math.abs(points[index][0] - points[nextIndex][0]), 2) +
        Math.pow(
          Math.abs(points[index][1] - points[nextIndex][1]),
          2,
        ),
    );
    sideLengths.push((length * meters_per_pixel).toFixed(2));
  }

  return sideLengths;
};

//Convert x,y coordinates to Geo JSON
const convertToGeoJson = (
  points: number[][],
  refPoint: { lng: number; lat: number },
  movement: { x: number; y: number },
) => {
  const geoPoints = points.map((point) => [
    refPoint!.lng + point[0] * movement!.x,
    refPoint!.lat + point[1] * movement!.y,
  ]);

  const geoJson = {
    type: 'Polygon',
    coordinates: [[...geoPoints, geoPoints[0]]],
  };

  return utils.getEWKBString(geoJson);
};

//Convert lat,lng coordinates to x,y
const convertToXY = (
  points: number[][][],
  refPoint: { lng: number; lat: number },
  movement: { x: number; y: number },
) => {
  const mapped = points[0].map((point) => [
    (point[0] - refPoint!.lng) / movement!.x,
    (point[1] - refPoint!.lat) / movement!.y,
  ]);

  mapped.pop(); // last point in Geo JSON is same as first

  return mapped;
};

//Get rotation of a shape
const getShapeRotation = (points: number[][]): number => {
  const m = findMinMaxCoordinates(points);
  const A = {
    x: m.x.min,
    y: points.reverse().find((point) => point[0] === m.x.min)![1],
  };
  const B = {
    x: points.find((point) => point[1] === m.y.max)![0],
    y: m.y.max,
  };
  if (Math.round(B.y - A.y - (B.x - A.x)) === 0) return 0;

  return Math.atan((B.y - A.y) / (B.x - A.x)) * (180 / Math.PI);
};

// Rotate shape
const rotateShape = (
  points: number[][],
  rotation: number,
): number[][] => {
  if (Math.abs(rotation) === 0) return points;
  return points.map((point) => rotatePoint(point, [0, 0], rotation));
};

export {
  flattenPoints,
  findMinMaxCoordinates,
  findMinMaxGeoCoordinates,
  addCenterPointToLine,
  rotatePoint,
  getSides,
  convertToGeoJson,
  convertToXY,
  getShapeRotation,
  rotateShape,
};
