import React, { useCallback, useEffect, useState } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import ErrorHandler from '../../utils/ErrorHandler';
import {
  AlertBanner,
  CSButton,
  CSTextField,
} from '../primitives/index';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Transition } from '../confirmationDialog/ConfirmationDialog';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import configurationUtils from '../../utils/configurationUtils';
import selectStyles from '../select/select.styles';
import { Theme } from '@material-ui/core';
import sectionPageBaseStyle from '../commonStyles/sectionPageBase.style';
import detailsPageStyles from '../commonStyles/detailsPage.style';
import {
  Customer,
  DockDoorDetails,
  InboundLoad,
  InboundLoadLoadStatusEnum,
  User,
} from 'cloudsort-client';
import debounce from 'lodash/debounce';
import CustomersService from '../../services/Customers.service';
import { noOptionsMessage } from '../asyncSelect/utils';
import { TypeAheadItem } from '../../interfaces/components';
import InboundLoadsService from '../../services/InboundLoads.service';
import DockDoorService from '../../services/DockDoors.service';
import EphemeralStateService from '../../services/EphemeralState.service';
import { MAX_PAGE_SIZE } from '../../services/utils/constants';
import CSDialogTitleWithIcon from '../primitives/csDialogTitleWithIcon/CSDialogTitleWithIcon';
import { DialogIcons } from '../primitives/csDialogTitleWithIcon/CSDialogTitleWithIconTypes';
import { CSDualGridContainer } from '../primitives/csDualGridContainer';
import { CSDatepicker } from '../primitives/csDatepicker';
import CSAsyncSelect from '../primitives/csAsyncSelect/CSAsyncSelect';
import {
  numberToString,
  parseNumericInput,
} from '../../utils/inputValidation';
import { AxiosError } from 'axios';
import FmcService from '../../services/Fmc.service';
import moment from 'moment';

interface Props {
  classes: { [key: string]: string };
  isOpen: boolean;
  onAfterClose: () => void;
  updateParent: () => void;
  type: 'add' | 'edit';
  data?: InboundLoad;
}

const emptyInboundLoadData: InboundLoad = {
  name: '',
  expected_inbound_load_time: moment().add(1, 'days').toISOString(),
  arrival_time: undefined,
  expected_containers_at_arrival: null,
  expected_packages_at_arrival: null,
  load_status: InboundLoadLoadStatusEnum.PLANNED,
  origin_station: '',
  owner: 0,
  owner_full_name: undefined,
  trailer_id: null,
  target_station: EphemeralStateService.getMyStationId(),
};

//Add or edit inbound load.
const InboundLoadDialog: React.FC<Props> = ({
  classes,
  isOpen,
  onAfterClose,
  updateParent,
  type,
  data,
}) => {
  const [open, setOpen] = useState(false);
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [error, setError] = useState();
  const [inboundLoadParams, setInboundLoadParams] = useState(
    emptyInboundLoadData,
  );

  const [isOwnerFieldError, setIsOwnerFieldError] = useState(false);
  const [isContainersFieldError, setIsContainersFieldError] =
    useState(false);

  const inboundLoadLabels = {
    singular: configurationUtils.getPageTitle(true, 'INBOUND_LOAD'),
    plural: configurationUtils.getPageTitle(false, 'INBOUND_LOAD'),
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (type === 'edit' && data) {
      setInboundLoadParams({
        ...data,
      });
    }
    if (type === 'add') {
      setInboundLoadParams({
        ...emptyInboundLoadData,
      });
    }
  }, [data, type]);

  const handleError = async (e: any) => {
    setError(await ErrorHandler.getLabel(e as AxiosError));
  };

  const handleClose = () => {
    setOpen(false);
    setError(undefined);
    setIsContainersFieldError(false);
    setIsOwnerFieldError(false);
    onAfterClose();
    setInboundLoadParams({ ...emptyInboundLoadData });
    updateParent();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadCustomerOptions = useCallback(
    debounce((inputValue: string, callback: any) => {
      CustomersService.getAll(
        undefined,
        inputValue,
        MAX_PAGE_SIZE,
        EphemeralStateService.getMyStationId(),
        EphemeralStateService.getMyOrgId(),
      )
        .then((data) => {
          callback(
            data.data.results.map((dataEl: Customer) => {
              return {
                value: dataEl.id,
                label: dataEl.identifier,
              };
            }),
          );
        })
        .catch((e) => {
          handleError(e as AxiosError);
        });
    }, 500),
    [],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadDDOptions = useCallback(
    debounce((inputValue: string, callback: any) => {
      DockDoorService.search(inputValue)
        .then((data) => {
          callback(
            data.data.results.map((dataEl: DockDoorDetails) => {
              return {
                value: dataEl.id,
                label: dataEl.name,
              };
            }),
          );
        })
        .catch((e) => {
          handleError(e as AxiosError);
        });
    }, 500),
    [],
  );

  const formatAsyncOptions = (data: User[]) => {
    return data.map((dataEl: User) => {
      return {
        value: dataEl.full_name,
        label: dataEl.full_name,
      };
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadCarrierOptions = useCallback(
    debounce((inputValue: string, callback: any) => {
      FmcService.search(inputValue)
        .then((data) => {
          callback(formatAsyncOptions(data.data.results));
        })
        .catch((e) => {
          handleError(e as AxiosError);
        });
    }, 500),
    [],
  );

  const checkRequiredFields = () => {
    if (!inboundLoadParams.owner) {
      setIsOwnerFieldError(true);
    } else {
      setIsOwnerFieldError(false);
    }
    if (!inboundLoadParams.expected_containers_at_arrival) {
      setIsContainersFieldError(true);
    } else {
      setIsContainersFieldError(false);
    }

    return (
      inboundLoadParams.owner &&
      inboundLoadParams.expected_containers_at_arrival
    );
  };
  const handleCreate = async () => {
    if (!checkRequiredFields()) return;
    try {
      setShowProgress(true);
      await InboundLoadsService.create(inboundLoadParams);
      handleClose();
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  const handleUpdate = async () => {
    if (!checkRequiredFields()) return;
    try {
      setShowProgress(true);
      await InboundLoadsService.update(inboundLoadParams.id!, {
        ...inboundLoadParams,
        container_count: undefined,
      });
      handleClose();
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  return (
    <>
      {showProgress && <ProgressIndicator />}
      <Dialog
        classes={{
          paperScrollPaper: classes.dialogRoot,
          paper: classes.dialogPaper,
        }}
        maxWidth={false}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={(e) => {
          handleClose();
        }}
      >
        {type === 'add' && (
          <DialogTitle>
            <CSDialogTitleWithIcon
              title={`Add ${inboundLoadLabels.singular}`}
              icon={DialogIcons.ADD}
            />
          </DialogTitle>
        )}
        {type === 'edit' && (
          <DialogTitle>
            <CSDialogTitleWithIcon
              title={`Edit ${inboundLoadLabels.singular} ${
                inboundLoadParams.name || inboundLoadParams.id
              }`}
              icon={DialogIcons.EDIT}
            />
          </DialogTitle>
        )}
        <DialogContent className={classes.dialogContent}>
          {error && (
            <AlertBanner
              className={classes.banner}
              severity='error'
              alertTitle={'Error'}
              alertMsg={error}
            />
          )}
          <CSDualGridContainer
            childrenColumnConfiguration={{ 0: 12 }}
          >
            <CSDatepicker
              label='Scheduled Arrival'
              selectionType='datetime'
              format='MM/dd/yyyy hh:mmaaa'
              margin='normal'
              key={
                inboundLoadParams.expected_inbound_load_time ||
                'inbound_load_dialog_date_picker'
              }
              initialValue={
                type === 'add'
                  ? moment().add(1, 'days')
                  : new Date(
                      inboundLoadParams.expected_inbound_load_time ||
                        '',
                    )
              }
              onAcceptSelection={(e) => {
                setInboundLoadParams({
                  ...inboundLoadParams,
                  expected_inbound_load_time: e as unknown as string,
                });
              }}
              disablePast={type === 'add'} //Disable past dates when adding new loads
            />
            <CSTextField
              value={inboundLoadParams.origin_station || ''}
              label='Origin'
              placeholder='Origin'
              onChange={(e) => {
                setInboundLoadParams({
                  ...inboundLoadParams,
                  origin_station: e.target.value,
                });
              }}
              onClear={() =>
                setInboundLoadParams({
                  ...inboundLoadParams,
                  origin_station: '',
                })
              }
              InputLabelProps={{
                shrink: true,
                className: classes.selectLabel,
              }}
              containerSize='fullHorizontal'
            />
            <CSAsyncSelect<TypeAheadItem<number>>
              data-testid='select-owner'
              label='Owner'
              isClearable
              cacheOptions
              loadOptions={(inputValue: string, callback: any) => {
                loadCustomerOptions(inputValue, callback);
              }}
              error={isOwnerFieldError}
              helperText={
                isOwnerFieldError
                  ? 'This field is required'
                  : undefined
              }
              onChange={(option) => {
                const selectedOwner = option as TypeAheadItem<number>;
                setInboundLoadParams({
                  ...inboundLoadParams,
                  owner: selectedOwner
                    ? Number(selectedOwner.value)
                    : 0,
                  owner_full_name: selectedOwner
                    ? selectedOwner.label
                    : undefined,
                });
                setIsOwnerFieldError(false);
              }}
              isDisabled={!!error}
              placeholder={'Start Typing...'}
              value={
                inboundLoadParams?.owner
                  ? {
                      value: Number(inboundLoadParams.owner),
                      label: String(
                        inboundLoadParams.owner_full_name,
                      ),
                    }
                  : null
              }
              menuPortalTarget={document.body}
              noOptionsMessage={noOptionsMessage}
              containerSize='fullHorizontal'
            />
            <CSTextField
              data-testid='edit-inbound-load-id'
              value={inboundLoadParams.name || ''}
              label='Load ID'
              placeholder='Load ID'
              onChange={(e) => {
                setInboundLoadParams({
                  ...inboundLoadParams,
                  name: e.target.value,
                });
              }}
              onClear={() =>
                setInboundLoadParams({
                  ...inboundLoadParams,
                  name: '',
                })
              }
              InputLabelProps={{
                shrink: true,
                className: classes.selectLabel,
              }}
              containerSize='fullHorizontal'
            />
            <CSAsyncSelect<TypeAheadItem<number>>
              label='Unload Door'
              isClearable
              cacheOptions
              loadOptions={(inputValue: string, callback: any) => {
                loadDDOptions(inputValue, callback);
              }}
              onChange={(option) => {
                const selectedDockDoor =
                  option as TypeAheadItem<number>;
                setInboundLoadParams({
                  ...inboundLoadParams,
                  dockdoor: selectedDockDoor
                    ? Number(selectedDockDoor.value)
                    : undefined,
                  dockdoor_name: selectedDockDoor
                    ? selectedDockDoor.label
                    : '',
                });
              }}
              isDisabled={!!error}
              placeholder={'Start Typing...'}
              value={
                inboundLoadParams?.dockdoor
                  ? {
                      value: Number(inboundLoadParams.dockdoor),
                      label: String(inboundLoadParams.dockdoor_name),
                    }
                  : null
              }
              menuPortalTarget={document.body}
              noOptionsMessage={noOptionsMessage}
              containerSize='fullHorizontal'
            />
            <CSTextField
              value={numberToString(
                inboundLoadParams.expected_containers_at_arrival,
              )}
              label={configurationUtils.getPageTitle(
                false,
                'CONTAINER',
              )}
              placeholder={configurationUtils.getPageTitle(
                false,
                'CONTAINER',
              )}
              error={isContainersFieldError}
              helperText={
                isContainersFieldError
                  ? 'This field is required'
                  : undefined
              }
              onChange={(e) => {
                setInboundLoadParams({
                  ...inboundLoadParams,
                  expected_containers_at_arrival: parseNumericInput({
                    newValueFromInput: e.target.value,
                    currentValue:
                      inboundLoadParams.expected_containers_at_arrival,
                    minimumValue: 0,
                  }),
                });

                setIsContainersFieldError(
                  !!!e.target.value.match(/^\d+$/),
                );
              }}
              InputLabelProps={{
                shrink: true,
                className: classes.selectLabel,
              }}
              containerSize='fullHorizontal'
            />
            <CSTextField
              value={numberToString(
                inboundLoadParams.expected_packages_at_arrival,
              )}
              label={configurationUtils.getPageTitle(
                false,
                'PACKAGE',
              )}
              placeholder={configurationUtils.getPageTitle(
                false,
                'PACKAGE',
              )}
              onChange={(e) => {
                setInboundLoadParams({
                  ...inboundLoadParams,
                  expected_packages_at_arrival: parseNumericInput({
                    newValueFromInput: e.target.value,
                    currentValue:
                      inboundLoadParams.expected_packages_at_arrival,
                    minimumValue: 0,
                  }),
                });
              }}
              InputLabelProps={{
                shrink: true,
                className: classes.selectLabel,
              }}
              inputProps={{
                min: '0',
                step: '1',
              }}
              containerSize='fullHorizontal'
            />
            <CSTextField
              value={inboundLoadParams.trailer_id || ''}
              label='Trailer ID'
              placeholder='Trailer ID'
              onChange={(e) => {
                setInboundLoadParams({
                  ...inboundLoadParams,
                  trailer_id: e.target.value,
                });
              }}
              onClear={() =>
                setInboundLoadParams({
                  ...inboundLoadParams,
                  trailer_id: '',
                })
              }
              InputLabelProps={{
                shrink: true,
                className: classes.selectLabel,
              }}
              containerSize='fullHorizontal'
            />
            <CSTextField
              value={inboundLoadParams.bol || ''}
              label='BOL'
              placeholder='BOL ID'
              onChange={(e) => {
                setInboundLoadParams({
                  ...inboundLoadParams,
                  bol: e.target.value,
                });
              }}
              onClear={() =>
                setInboundLoadParams({
                  ...inboundLoadParams,
                  bol: '',
                })
              }
              InputLabelProps={{
                shrink: true,
                className: classes.selectLabel,
              }}
              containerSize='fullHorizontal'
            />
            <CSAsyncSelect<TypeAheadItem<string>>
              label='Carrier'
              isClearable
              cacheOptions
              loadOptions={(inputValue: string, callback: any) => {
                loadCarrierOptions(inputValue, callback);
              }}
              onChange={(option) => {
                const selectedCarrier =
                  option as TypeAheadItem<string>;

                setInboundLoadParams({
                  ...inboundLoadParams,
                  carrier: selectedCarrier
                    ? selectedCarrier.value
                    : undefined,
                });
              }}
              isDisabled={!!error}
              placeholder={'Start Typing...'}
              value={
                inboundLoadParams?.carrier
                  ? {
                      label: inboundLoadParams.carrier,
                      value: inboundLoadParams.carrier,
                    }
                  : null
              }
              menuPortalTarget={document.body}
              noOptionsMessage={noOptionsMessage}
              containerSize='fullHorizontal'
            />
          </CSDualGridContainer>
        </DialogContent>

        <DialogActions className={classes.dialogActions}>
          <CSButton
            variant='outlined'
            color='secondary'
            onClick={(e) => {
              handleClose();
            }}
          >
            Cancel
          </CSButton>
          <CSButton
            variant='contained'
            color='secondary'
            data-testid={'edit-inbound-load-save-button'}
            disabled={false}
            onClick={(e) => {
              if (type === 'edit') {
                handleUpdate();
              } else {
                handleCreate();
              }
            }}
          >
            Save
          </CSButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...selectStyles,
    ...sectionPageBaseStyle,
    dialogContent: {
      overflow: 'hidden',
    },
    dialogActions: {
      padding: '16px 23px 25px 8px',
    },
    dialogRoot: {
      overflow: 'hidden',
    },
    dialogPaper: {
      width: 493,
      maxHeight: '100%',
    },
    inputPadding: {
      paddingLeft: '5px',
      paddingRigth: '5px',
    },
  })),
)(InboundLoadDialog);
