import EphemeralStateService from '../EphemeralState.service';
import { LABEL_DPI, LABEL_SIZE } from './constants';

const getLabelParams = (format: string) => {
  switch (format) {
    case 'format_4x4_dpi_203':
      return { size: '4x4', dpi: 203 };
    case 'format_4x6_dpi_203':
      return { size: '4x6', dpi: 203 };
    case 'format_4x8_dpi_300':
      return { size: '4x8', dpi: 300 };
    default:
      return {
        size: LABEL_SIZE,
        dpi: LABEL_DPI,
      };
  }
};

export const labelConfigOpt = () => {
  let labelConfig: any;
  const res =
    EphemeralStateService.getMyStationConfiguratation()?.OT
      ?.LABEL_DEFINITION;

  if (res) {
    const keys = Object.keys(res);
    labelConfig = getLabelParams(keys.filter((key) => res[key])[0]);
  } else {
    labelConfig = {
      size: LABEL_SIZE,
      dpi: LABEL_DPI,
    };
  }
  return labelConfig;
};
