import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import WatchLaterIcon from '@material-ui/icons/WatchLater';
import RefreshIcon from '@material-ui/icons/Refresh';

interface Props {
  observedDuration: number;
  osSetObservedDuration: (duration: number) => void;
  onRefresh: () => void;
}

export default function TimePeriodSelector({
  observedDuration,
  osSetObservedDuration,
  onRefresh,
}: Props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const setDuration = (duration: number) => {
    osSetObservedDuration(duration);
    handleClose();
  };

  return (
    <div style={{ display: 'inline-block' }}>
      <Button
        aria-controls='simple-menu'
        aria-haspopup='true'
        onClick={handleClick}
        startIcon={<WatchLaterIcon />}
      >
        {observedDuration === 60
          ? 'Last Hour'
          : observedDuration === 1440
          ? 'Last Day'
          : 'Last ' + observedDuration + ' Minutes'}
      </Button>
      <Button
        onClick={() => {
          onRefresh();
        }}
        startIcon={<RefreshIcon />}
      >
        Refresh
      </Button>

      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            setDuration(1440);
          }}
        >
          Last Day
        </MenuItem>
        <MenuItem
          onClick={() => {
            setDuration(60);
          }}
        >
          Last Hour
        </MenuItem>
        <MenuItem
          onClick={() => {
            setDuration(15);
          }}
        >
          Last 15 Minutes
        </MenuItem>
        <MenuItem
          onClick={() => {
            setDuration(5);
          }}
        >
          Last 5 Minutes
        </MenuItem>
        <MenuItem
          onClick={() => {
            setDuration(1);
          }}
        >
          Last Minute
        </MenuItem>
      </Menu>
    </div>
  );
}
