import { Route } from 'cloudsort-client';
import { IGenericTableData } from '../dndTable/DndTable';
import { getModuleUrl, ModulesKeys } from '../../interfaces/routes';

interface TableNestedRoute2Station extends IGenericTableData {
  fmcs?: string;
  station_name_url?: string;
}

export const routerDataToTableData = (
  data: Route | undefined,
  EphemeralStateService: { getMyStationId: () => number },
  classes: { link?: string },
): IGenericTableData[] =>
  data?.route2stations?.map((r2s) => {
    const result = r2s as unknown as TableNestedRoute2Station;
    result.fmcs = r2s?.station_data?.fmcs?.join(', ');
    const stopUrl = `${
      process.env.REACT_APP_BASENAME || ''
    }${getModuleUrl(ModulesKeys.STOP)}?search=${
      r2s.station_name
    }&stationId=${EphemeralStateService.getMyStationId()}`;
    result.station_name_url = `<a class="${classes?.link}" href="${stopUrl}"> ${r2s.station_name}<a>`;
    return result;
  }) || [];
