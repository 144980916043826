import React, {
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import GoogleMapReact from 'google-map-react';
import { common } from '../../utils/strings';
import Layout from '../layout/Layout';
import styles from './stationLayout.styles';
import { withStyles } from '@material-ui/core/styles';
import { Stage, Layer, Line, Circle, Transformer } from 'react-konva';
import { getGeocode, getLatLng } from 'use-places-autocomplete';
import debounce from 'lodash/debounce';

// Types
import { KonvaEventObject } from 'konva/types/Node';
import {
  Box,
  Button,
  IconButton,
  Grid,
  Typography,
} from '@material-ui/core';
import LocalStorageService from '../../services/LocalStorage.service';
import AreasService from '../../services/Areas.service';
import ZonesService from '../../services/Zones.service';
import LoadPointsService from '../../services/LoadPoints.service';
import {
  convertToGeoJson,
  convertToXY,
  findMinMaxCoordinates,
  findMinMaxGeoCoordinates,
  flattenPoints,
  getShapeRotation,
  getSides,
  rotatePoint,
  rotateShape,
} from './helpers';
import TransformablePolygon from './components/TransformablePolygon';
import colors from '../../utils/colors';
import { Vector2d } from 'konva/types/types';
import { Helmet } from 'react-helmet';
import CircularSlider from '@fseehawer/react-circular-slider';

import {
  LoadPoint,
  Zone,
  Area,
  StationDetails,
  DockDoor,
} from 'cloudsort-client';
import utils from '../map/utils/utils';
import globalStationOptionsUtils from '../../utils/globalStationOptionsUtils';
import configurationUtils from '../../utils/configurationUtils';
import GridGuide from './components/GridGuide';
import Connections from './components/Connections';
import browserHistory from '../../utils/browserHistory';
import { AuthRoutes, NonAuthRoutes } from '../../interfaces/routes';
import { MAX_PAGE_SIZE } from '../../services/utils/constants';
import PackageCounts from './components/PackageCounts';

//Icons
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import MoreVertIcon from '@material-ui/icons/MoreVert';
//Services
import DockDoorsService from '../../services/DockDoors.service';
import StationsService from '../../services/Stations.service';
import PermissionsService from '../../services/Permissions.service';
import Flow from './throughput/Flow';
import { Checkbox, CSSwitch } from '../primitives';

interface Props {
  classes: { [key: string]: string };
}

export interface MappedElement {
  id: number;
  name?: string | null;
  points: number[][];
  pointsNoAngleOffset: number[][];
  area?: number;
  zone?: number;
  containerType?: string;
  rotation: number;
  isActive?: boolean;
  ref: any;
  targetAreas?: number[];
  targetZones?: number[];
  targetLoadpoints?: number[];
  positionMovement?: number[];
  pendingSave?: boolean;
  type?: string;
}

interface ActionHistoryItem {
  id: number;
  element: ElementType;
  action: 'MOVE' | 'TRANSFORM';
  x?: number;
  y?: number;
  scaleX?: number;
  scaleY?: number;
  rotation?: number;
}

export type ElementType = 'AREA' | 'ZONE' | 'LOADPOINT' | 'DOCKDOOR';
type CurrentView =
  | 'VIEW'
  | 'NODES'
  | 'COUNT_AREAS_DD'
  | 'COUNT_ZONES'
  | 'COUNT_LOADPOINTS'
  | 'THROUGHPUT';

type CurrentViewMode = 'LAYOUT' | 'THROUGHPUT';

export const METERS_TO_YARDS = 1.09361;

const StationLayout: React.FC<Props> = ({ classes }) => {
  const [stationOutlinePoints, setStationOutlinePoints] = useState<
    number[][]
  >([]);
  const [isMouseOverStartPoint, setIsMouseOverStartPoint] =
    useState<boolean>(false);
  const [stageScale, setStageScale] = useState<number>(1);
  const [stagePosition, setStagePosition] = useState<Vector2d>({
    x: 0,
    y: 0,
  });

  const [stageOffset, setStageOffset] = useState<Vector2d>({
    x: 0,
    y: 0,
  });

  const [stageRotation, setStageRotation] = useState<number>(0);
  const [stageRotationSnaps, setStageRotationSnaps] = useState<
    number[]
  >([]);

  const [showPoints, setShowPoints] = useState<boolean>(false);
  const [isDrawingStation, setIsDrawingStation] =
    useState<boolean>(false);
  const [isDrawingStationFinished, setIsDrawingStationFinished] =
    useState<boolean>(false);

  //Available elements
  const [allAreas, setAllAreas] = useState<Area[]>();
  const [allZones, setAllZones] = useState<Zone[]>();
  const [allLoadpoints, setAllLoadpoints] = useState<LoadPoint[]>();
  const [allDockdoors, setAllDockdoors] = useState<DockDoor[]>();

  //Action History
  const [actionHistory, setActionHistory] = useState<
    ActionHistoryItem[]
  >([]);

  //Permission check
  const [hasWritePermission, setHasWritePermission] =
    useState<boolean>(false);

  //Konva map
  const [konvaActive, setKonvaActive] = useState<any>(false);

  //Currently selected item (add outline to it and show sidebar for this item)
  const [selectedItem, setSelectedItem] = useState<{
    type: ElementType;
    id: number;
  }>();

  //Attach transformer to this item
  const [transformingItem, setTransformingItem] = useState<{
    type: ElementType;
    id: number;
  }>();

  //Item that currently being dragged or rotated (we keep the track of it so we can hide all chile elements while transform is in progress)
  const [draggingItem, setDraggingItem] = useState<{
    type: ElementType;
    id: number;
  }>();

  //Google map

  //Internals -- used mostly for establishing scale when drawing
  const [mapObject, setMapObject] = useState<any>();
  const [mapDivRef, setMapDivRef] = useState<any>();

  const [mapActive, setMapActive] = useState<boolean>(false);
  const [mapCoordinates, setMapCoordinates] = useState({
    lat: 32,
    lng: -96,
  });
  const [mapZoom, setMapZoom] = useState(18);

  //Elements added to the Konva stage
  const [mappedAreas, setMappedAreas] = useState<MappedElement[]>([]);
  const [mappedZones, setMappedZones] = useState<MappedElement[]>([]);
  const [mappedLoadpoints, setMappedLoadpoints] = useState<
    MappedElement[]
  >([]);
  const [mappedDockdoors, setMappedDockdoors] = useState<
    MappedElement[]
  >([]);

  // Init Elements
  const [mappedAreasInit, setMappedAreasInit] = useState<
    MappedElement[]
  >([]);
  const [mappedZonesInit, setMappedZonesInit] = useState<
    MappedElement[]
  >([]);
  const [mappedLoadpointsInit, setMappedLoadpointsInit] = useState<
    MappedElement[]
  >([]);
  const [mappedDockdoorsInit, setMappedDockdoorsInit] = useState<
    MappedElement[]
  >([]);
  const [initLoadComplete, setInitLoadComplete] =
    useState<boolean>(false);

  //Konva container ref
  const containerRef = useRef<any>();

  //No station outline overlay
  const [noStationOutline, setNoStationOutline] = useState(false);

  //Resizing Child elements
  const [resizingChildElements] = useState(true);

  //Curent view metadata
  const [metersPerPixel, setMetersPerPixel] = useState<number>(0);
  const [refPointCoordinates, setRefPointCoordinates] = useState<{
    lat: number;
    lng: number;
  }>();
  const [coordinatesMovemetPerPixel, setCoordinatesMovemetPerPixel] =
    useState<{ x: number; y: number }>();
  const [newElementClick, setNewElementClick] = useState<number>(0);
  const [showProgress, setShowProgress] = useState<boolean>(true);
  const [station, setStation] = useState<StationDetails>(
    LocalStorageService.getMyStationData(),
  );
  const [cursorPosition, setCursorPosition] = useState<number[]>([
    0, 0,
  ]);
  const [currentView, setCurrentView] = useState<CurrentView>('VIEW');
  const [currentViewMode, setCurrentViewMode] =
    useState<CurrentViewMode>('LAYOUT');
  const [countOrPercentage, setCountOrPercentage] = useState<
    'COUNT' | 'PERCENTAGE'
  >('COUNT');
  const [changesPendingSave, setChangesPendingSave] =
    useState<boolean>(false);

  //Grid
  const [gridVisible, setGridVisible] = useState<boolean>(false);
  const [gridDraggable, setGridDraggable] = useState<boolean>(false);

  //Transformer
  const trRef = useRef<any>();

  // Clear transfroming item on view mode change
  useEffect(() => {
    setTransformingItem(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentView]);

  useEffect(() => {
    if (transformingItem) {
      // we need to attach transformer manually
      let ref;

      if (transformingItem.type === 'AREA') {
        ref = mappedAreas.find(
          (area) => area.id === transformingItem.id,
        )?.ref;
      } else if (transformingItem.type === 'ZONE') {
        ref = mappedZones.find(
          (area) => area.id === transformingItem.id,
        )?.ref;
      } else if (transformingItem.type === 'LOADPOINT') {
        ref = mappedLoadpoints.find(
          (area) => area.id === transformingItem.id,
        )?.ref;
      } else if (transformingItem.type === 'DOCKDOOR') {
        ref = mappedDockdoors.find(
          (area) => area.id === transformingItem.id,
        )?.ref;
      }

      trRef.current.nodes([ref.current]);
      trRef.current.getLayer().batchDraw();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transformingItem]);

  useEffect(() => {
    trRef?.current?.nodes(undefined);
  }, [selectedItem]);

  //Function to get lng and lat coordinates for a given station address
  const getStationCoordinates = () => {
    // Get latitude and longitude via utility functions
    getGeocode({
      address:
        station.address + ' ' + station.city + ' ' + station.state,
    })
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        setMapCoordinates({ lat, lng });
      })
      .catch((error) => {
        console.error(
          'Error while fetching coordinates for an address: ',
          error,
        );
      });
  };

  const loadAvailableStationElements = async () => {
    if (!configurationUtils.getIsModuleActive('AREA')) {
      alert(
        `This station does not support areas, and therefore can't have the station view. Make sure the station configuration is correct.`,
      );
      browserHistory.push(AuthRoutes.DASHBOARD);
      return;
    }

    setShowProgress(true);

    const areas = (
      await AreasService.getAll(undefined, MAX_PAGE_SIZE)
    )?.data.results;
    const zones = (
      await ZonesService.getAll(undefined, undefined, MAX_PAGE_SIZE)
    )?.data.results;
    const loadpoints = (
      await LoadPointsService.getAll({ pageSize: MAX_PAGE_SIZE })
    )?.data.results;
    const dockdoors = (
      await DockDoorsService.getAll({ pageSize: MAX_PAGE_SIZE })
    )?.data.results;

    //Set all available elements
    setAllAreas(areas);
    setAllZones(zones);
    setAllLoadpoints(loadpoints);
    setAllDockdoors(dockdoors);

    //Check if station has geo_shape
    const geoShape = station.geo_shape;

    if (geoShape) {
      const coord = (utils.getGeoJson(geoShape) as any).coordinates;
      const minMax = findMinMaxGeoCoordinates(coord);

      const refPoint = {
        lng: minMax.lng.min,
        lat: minMax.lat.min,
      };
      setRefPointCoordinates(refPoint);

      setMapCoordinates({ lng: minMax.lng.mid, lat: minMax.lat.mid });

      const dX = minMax.lng.max - minMax.lng.min;
      const dY = minMax.lat.max - minMax.lat.min;

      const w = containerRef?.current?.clientWidth - 40; // margin twice
      const h = containerRef?.current?.clientHeight - 40;

      let coordMovement: { x: number; y: number };

      const aspectRatio = Math.abs(dX) / Math.abs(dY);

      const dXRatio = Math.abs(dX / w);
      const dyRatio = Math.abs(dY / h);

      //Try to fit the shape into available space -- works nice when shape is taller than wider, but not occuping the whole available screen when shape is wider than taller. TODO: Revisit later
      if (dXRatio > dyRatio) {
        coordMovement = {
          x: dX / Math.cos((minMax.lat.mid * Math.PI) / 180) / w,
          y: (dY * aspectRatio) / w,
        };
      } else {
        coordMovement = {
          x:
            dX /
            Math.cos((minMax.lat.mid * Math.PI) / 180) /
            (h * aspectRatio),
          y: (dY * aspectRatio) / (h * aspectRatio),
        };
      }

      setCoordinatesMovemetPerPixel(coordMovement);

      // Calculate meters per pixel
      const mpp =
        111111.111 *
        Math.cos((minMax.lat.mid * Math.PI) / 180) *
        coordMovement.x;
      setMetersPerPixel(mpp);

      //Set station points
      const points = convertToXY(coord, refPoint, coordMovement);
      setStationOutlinePoints(points);

      //Shape Rotation -- for compass snaps
      const rotation = getShapeRotation(points);
      setStageRotationSnaps([rotation, 360 - rotation]);

      //Display Konva and station outline
      setKonvaActive(true);
      setIsDrawingStationFinished(true);

      //Add elements with geoShape to the canvas
      const mAreas = areas
        .filter((area) => area.geo_shape)
        .map((area) => {
          const xyPoints = convertToXY(
            (utils.getGeoJson(area.geo_shape) as any).coordinates,
            refPoint,
            coordMovement,
          );
          const rot = getShapeRotation(xyPoints);

          return {
            id: area.id!,
            name: area.name,
            type: area.area_type,
            pointsNoAngleOffset: xyPoints,
            points: rotateShape(xyPoints, 0 - rot),
            rotation: rot,
            ref: createRef(),
          };
        });
      setMappedAreas(mAreas);
      const mZones = zones
        .filter((zone) => zone.geo_shape)
        .map((zone) => {
          const xyPoints = convertToXY(
            (utils.getGeoJson(zone.geo_shape) as any).coordinates,
            refPoint,
            coordMovement,
          );

          const rot = getShapeRotation(xyPoints);
          return {
            id: zone.id!,
            name: zone.name,
            area: zone.area,
            pointsNoAngleOffset: xyPoints,
            points: rotateShape(xyPoints, 0 - rot),
            rotation: rot,
            ref: createRef(),
          };
        });
      setMappedZones(mZones);

      const mLoadpoints = loadpoints
        .filter((lp) => lp.geo_shape)
        .map((lp) => {
          const xyPoints = convertToXY(
            (utils.getGeoJson(lp.geo_shape) as any).coordinates,
            refPoint,
            coordMovement,
          );
          const rot = getShapeRotation(xyPoints);
          return {
            id: lp.id!,
            name: lp.name,
            area: lp.area!,
            zone: lp.zone!,
            pointsNoAngleOffset: xyPoints,
            points: rotateShape(xyPoints, 0 - rot),
            rotation: rot,
            isActive: lp.is_active,
            ref: createRef(),
            containerType: lp.default_container_type,
          };
        });
      setMappedLoadpoints(mLoadpoints);

      const mDockdoors = dockdoors
        .filter((dd) => dd.geo_shape)
        .map((dd) => {
          const xyPoints = convertToXY(
            (utils.getGeoJson(dd.geo_shape) as any).coordinates,
            refPoint,
            coordMovement,
          );
          const rot = getShapeRotation(xyPoints);
          return {
            id: dd.id!,
            name: dd.name,
            pointsNoAngleOffset: xyPoints,
            points: rotateShape(xyPoints, 0 - rot),
            rotation: rot,
            ref: createRef(),
          };
        });

      setMappedDockdoors(mDockdoors);

      //Set init values
      setMappedLoadpointsInit(mLoadpoints);
      setMappedZonesInit(mZones);
      setMappedAreasInit(mAreas);
      setMappedDockdoorsInit(mDockdoors);
      setInitLoadComplete(true);

      //Set no pending changes
      setChangesPendingSave(false);
    } else if (hasWritePermission) {
      //Display Google Map

      setMapActive(true);
      setNoStationOutline(true);
      setShowPoints(true);
    } else {
      alert(
        `This station does not have layout configured, and you don't have permission to create one.`,
      );
      browserHistory.push(AuthRoutes.DASHBOARD);
      return;
    }
    setShowProgress(false);
  };

  const savePendingChanges = async () => {
    setShowProgress(true);

    const promises: any = [];

    //Save loadpoints

    mappedLoadpoints
      .filter((element) => element.pendingSave)
      .forEach((element) => {
        promises.push(
          LoadPointsService.updateById(element.id, {
            geo_shape: convertToGeoJson(
              element.pointsNoAngleOffset,
              refPointCoordinates!,
              coordinatesMovemetPerPixel!,
            ),
          }),
        );
      });

    //Save zones

    mappedZones
      .filter((element) => element.pendingSave)
      .forEach((element) => {
        promises.push(
          ZonesService.update({
            id: element.id,
            geo_shape: convertToGeoJson(
              element.pointsNoAngleOffset,
              refPointCoordinates!,
              coordinatesMovemetPerPixel!,
            ),
          }),
        );
      });

    //Save areas

    mappedAreas
      .filter((element) => element.pendingSave)
      .forEach((element) => {
        promises.push(
          AreasService.update(element.id, {
            geo_shape: convertToGeoJson(
              element.pointsNoAngleOffset,
              refPointCoordinates!,
              coordinatesMovemetPerPixel!,
            ),
          }),
        );
      });

    //Save dock doors

    mappedDockdoors
      .filter((element) => element.pendingSave)
      .forEach((element) => {
        promises.push(
          DockDoorsService.edit(element.id, {
            station: station.id!,
            geo_shape: convertToGeoJson(
              element.pointsNoAngleOffset,
              refPointCoordinates!,
              coordinatesMovemetPerPixel!,
            ),
          }),
        );
      });

    await Promise.all(promises);

    // Reset pendingSave

    setMappedLoadpoints([
      ...mappedLoadpoints.map((lp) => {
        return { ...lp, pendingSave: false };
      }),
    ]);

    setMappedZones([
      ...mappedZones.map((zone) => {
        return { ...zone, pendingSave: false };
      }),
    ]);

    setMappedAreas([
      ...mappedAreas.map((area) => {
        return { ...area, pendingSave: false };
      }),
    ]);

    setMappedDockdoors([
      ...mappedDockdoors.map((dd) => {
        return { ...dd, pendingSave: false };
      }),
    ]);

    //Store saved values as init

    setMappedLoadpointsInit(mappedLoadpoints);
    setMappedZonesInit(mappedZones);
    setMappedAreasInit(mappedAreas);
    setMappedDockdoorsInit(mappedDockdoors);

    setChangesPendingSave(false);
    setShowProgress(false);
  };

  useEffect(() => {
    setHasWritePermission(
      PermissionsService.hasPermission('STATION_LAYOUT_WRITE'),
    );

    if (PermissionsService.hasPermission('STATION_LAYOUT_READ')) {
      loadAvailableStationElements();
    } else {
      browserHistory.push(NonAuthRoutes.LOGIN);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //update connections when elemets change
  useEffect(() => {
    if (
      mappedAreas.length &&
      mappedZones.length &&
      mappedLoadpoints.length
    ) {
      const lpConnections = [...mappedLoadpoints].map((loadpoint) => {
        const lp = allLoadpoints?.find(
          (lp) => lp.id === loadpoint.id,
        );
        return {
          ...loadpoint,
          targetAreas: lp?.target_area ? [lp.target_area] : [],
          targetZones: lp?.target_zone ? [lp.target_zone] : [],
          targetLoadpoints: lp?.target_loadpoint
            ? [lp.target_loadpoint]
            : [],
        };
      });

      const zoneConnections = [...mappedZones].map((zone) => {
        const lp = lpConnections?.filter((lp) => lp.zone === zone.id);
        return {
          ...zone,
          targetZones: lp?.length
            ? [
                ...new Set(
                  lp
                    .filter((lp) => lp.targetZones)
                    .map((lp) => lp.targetZones![0]),
                ),
              ]
            : [],
          targetAreas: lp?.length
            ? [
                ...new Set(
                  lp
                    .filter((lp) => lp.targetAreas)
                    .map((lp) => lp.targetAreas![0]),
                ),
              ]
            : [],
        };
      });

      const areaConnections = [...mappedAreas].map((area) => {
        const lp = lpConnections?.filter(
          (lp) => lp.area === area.id && lp.targetAreas,
        );
        return {
          ...area,
          targetAreas: lp?.length
            ? [...new Set(lp.map((lp) => lp.targetAreas![0]))]
            : [],
        };
      });

      setMappedLoadpoints(lpConnections);
      setMappedZones(zoneConnections);
      setMappedAreas(areaConnections);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    mappedAreas.length,
    mappedZones.length,
    mappedLoadpoints.length,
  ]);

  //Add area and child elements -- auto layout
  const addAreaToLayout = (areaId: number) => {
    const itemWidth = 25 / (metersPerPixel * METERS_TO_YARDS); // 25*25 yards
    let areaShapes: MappedElement[] = [];
    allAreas?.forEach((area) => {
      if (area.id === areaId) {
        const points = [
          [
            -stagePosition.x / stageScale + 0,
            50 - stagePosition.y / stageScale,
          ],
          [
            -stagePosition.x / stageScale + itemWidth,
            50 - stagePosition.y / stageScale,
          ],
          [
            -stagePosition.x / stageScale + itemWidth,
            itemWidth + 50 - stagePosition.y / stageScale,
          ],
          [
            -stagePosition.x / stageScale + 0,
            itemWidth + 50 - stagePosition.y / stageScale,
          ],
        ];
        areaShapes.push({
          name: area.name,
          id: area.id,
          points: points,
          pointsNoAngleOffset: points,
          rotation: 0,
          ref: createRef(),
        });
      }
    });

    setMappedAreas([...mappedAreas, ...areaShapes]);

    const zonePadding = 10;
    let zonesShapes: MappedElement[] = [];

    areaShapes?.forEach((area) => {
      const areaZones = allZones?.filter(
        (zone) => zone.area === area.id,
      );

      const minPoint = findMinMaxCoordinates(area.points);

      areaZones?.forEach((zone, i) => {
        const grid = Math.ceil(Math.sqrt(areaZones.length));
        const ix = i % grid;

        const Xc =
          (ix * 2 + 1) * (itemWidth / (2 * grid)) + minPoint.x.min;
        const Yc =
          Math.floor(i / grid) * (itemWidth / grid) +
          itemWidth / (2 * grid) +
          minPoint.y.min;

        const points = [
          [
            Xc - itemWidth / (2 * grid) + zonePadding,
            Yc - itemWidth / (2 * grid) + zonePadding,
          ],
          [
            Xc + itemWidth / (2 * grid) - zonePadding,
            Yc - itemWidth / (2 * grid) + zonePadding,
          ],
          [
            Xc + itemWidth / (2 * grid) - zonePadding,
            Yc + itemWidth / (2 * grid) - zonePadding,
          ],
          [
            Xc - itemWidth / (2 * grid) + zonePadding,
            Yc + itemWidth / (2 * grid) - zonePadding,
          ],
        ];

        zonesShapes.push({
          name: zone.name,
          id: zone.id!,
          area: area.id,
          points: points,
          pointsNoAngleOffset: points,
          rotation: 0,
          ref: createRef(),
        });
      });
    });

    setMappedZones([...mappedZones, ...zonesShapes]);

    //Loadpoints

    const lpPadding = 5;
    let lpShapes: MappedElement[] = [];

    zonesShapes?.forEach((zone) => {
      const zoneLps = allLoadpoints?.filter(
        (lp) => lp.zone === zone.id,
      );

      const minPoint = findMinMaxCoordinates(zone.points);

      //set width to zone width
      const itemWidth = minPoint.x.max - minPoint.x.min;

      zoneLps?.forEach((loadpoint, i) => {
        const grid = Math.ceil(Math.sqrt(zoneLps.length));
        const ix = i % grid;

        const Xc =
          (ix * 2 + 1) * (itemWidth / (2 * grid)) + minPoint.x.min;
        const Yc =
          Math.floor(i / grid) * (itemWidth / grid) +
          itemWidth / (2 * grid) +
          minPoint.y.min;

        const points = [
          [
            Xc - itemWidth / (2 * grid) + lpPadding,
            Yc - itemWidth / (2 * grid) + lpPadding,
          ],
          [
            Xc + itemWidth / (2 * grid) - lpPadding,
            Yc - itemWidth / (2 * grid) + lpPadding,
          ],
          [
            Xc + itemWidth / (2 * grid) - lpPadding,
            Yc + itemWidth / (2 * grid) - lpPadding,
          ],
          [
            Xc - itemWidth / (2 * grid) + lpPadding,
            Yc + itemWidth / (2 * grid) - lpPadding,
          ],
        ];

        lpShapes.push({
          name: loadpoint.name,
          id: loadpoint.id!,
          zone: zone.id,
          area: zone.area,
          points: points,
          isActive: loadpoint.is_active,
          pointsNoAngleOffset: points,
          rotation: 0,
          ref: createRef(),
        });
      });
    });

    setMappedLoadpoints([...mappedLoadpoints, ...lpShapes]);

    //Auto select added area
    setSelectedItem({
      type: 'AREA',
      id: areaId,
    });
  };

  const addDockDoorToLayout = (ddId: number) => {
    const itemWidth = 10 / (metersPerPixel * METERS_TO_YARDS); // 10*10 yards
    let ddShapes: MappedElement[] = [];
    allDockdoors?.forEach((dd) => {
      if (dd.id === ddId) {
        const points = [
          [
            -stagePosition.x / stageScale + 0,
            50 - stagePosition.y / stageScale,
          ],
          [
            -stagePosition.x / stageScale + itemWidth,
            50 - stagePosition.y / stageScale,
          ],
          [
            -stagePosition.x / stageScale + itemWidth,
            itemWidth + 50 - stagePosition.y / stageScale,
          ],
          [
            -stagePosition.x / stageScale + 0,
            itemWidth + 50 - stagePosition.y / stageScale,
          ],
        ];
        ddShapes.push({
          name: dd.name,
          id: dd.id,
          points: points,
          pointsNoAngleOffset: points,
          rotation: 0,
          ref: createRef(),
        });
      }
    });

    setMappedDockdoors([...mappedDockdoors, ...ddShapes]);

    //Auto select added dockdoor
    setSelectedItem({
      type: 'DOCKDOOR',
      id: ddId,
    });
  };

  //Handle Konva stage click

  const getMousePos = (stage: any): number[] => {
    return [
      stage.getPointerPosition().x,
      stage.getPointerPosition().y,
    ];
  };

  const handleClick = (event: KonvaEventObject<MouseEvent>) => {
    if (isDrawingStation) {
      const stage = event.target.getStage();
      const mousePos =
        cursorPosition[0] !== 0 ? cursorPosition : getMousePos(stage);

      if (isDrawingStationFinished) {
        return;
      }
      if (isMouseOverStartPoint && stationOutlinePoints.length >= 3) {
        setIsDrawingStationFinished(true);
      } else {
        setStationOutlinePoints([...stationOutlinePoints, mousePos]);
      }
    } else {
      // deselect when clicked on empty area
      const clickedOnEmpty = event.target === event.target.getStage();
      if (clickedOnEmpty) {
        setSelectedItem(undefined);
      }
    }
  };

  //Handle Konva stage zoom
  const handleScroll = (event: KonvaEventObject<WheelEvent>) => {
    if (!mapActive) {
      event.evt.preventDefault();

      const stage = event.target.getStage();
      const mousePos = getMousePos(stage);

      var mousePointTo = {
        x: (mousePos[0] - stage!.x()) / stageScale,
        y: (mousePos[1] - stage!.y()) / stageScale,
      };

      const scaleBy = 1.05;

      var newScale =
        event.evt.deltaY > 0
          ? stageScale / scaleBy
          : stageScale * scaleBy;

      setStageScale(newScale);

      var newPos = {
        x: mousePos[0] - mousePointTo.x * newScale,
        y: mousePos[1] - mousePointTo.y * newScale,
      };
      setStagePosition(newPos);

      stage!.position(newPos);
    }
  };

  //Listen for keystrokes
  const m: Vector2d = { x: 0, y: 0 };

  const listenForKeystrokes = (event: any) => {
    //undo
    if (event.ctrlKey && event.code === 'KeyZ') {
      undoLastChange();
      return;
    }

    //moving
    let step = 1;
    if (event.shiftKey) step = 5;
    if (selectedItem) {
      if (event.key === 'ArrowUp') {
        m.y = -step;
      } else if (event.key === 'ArrowDown') {
        m.y = step;
      } else if (event.key === 'ArrowLeft') {
        m.x = -step;
      } else if (event.key === 'ArrowRight') {
        m.x = step;
      }

      const { id, type } = selectedItem;

      if (type === 'AREA') {
        onAreaMove(id, m.x, m.y);
      } else if (type === 'ZONE') {
        onZoneMove([id], m.x, m.y);
      } else if (type === 'LOADPOINT') {
        onLPMove([id], m.x, m.y);
      } else if (type === 'DOCKDOOR') {
        onDockdoorMove(id, m.x, m.y);
      }
    }
  };

  //Drawing station outline points

  //Check if mouse is over starting point
  const handleMouseOverStartPoint = (
    event: KonvaEventObject<MouseEvent>,
  ) => {
    if (isDrawingStationFinished || stationOutlinePoints.length < 3)
      return;
    event.target.scale({ x: 2, y: 2 });
    setIsMouseOverStartPoint(true);
  };
  const handleMouseOutStartPoint = (
    event: KonvaEventObject<MouseEvent>,
  ) => {
    event.target.scale({ x: 1, y: 1 });
    setIsMouseOverStartPoint(false);
  };

  //Moving the dots of the station polygon
  const handleDragMovePoint = (
    event: KonvaEventObject<DragEvent>,
  ) => {
    const index = event.target.index - 1;
    const pos = [event.target.attrs.x, event.target.attrs.y];
    setStationOutlinePoints([
      ...stationOutlinePoints.slice(0, index),
      pos,
      ...stationOutlinePoints.slice(index + 1),
    ]);
  };

  const renderMeasurements = (sides: string[]) => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography className={classes.sidebarSubheading}>
            Measurements
          </Typography>
        </Grid>

        {sides.map((item, index) => (
          <Grid item sm={6} key={'side_length_' + index}>
            <Box className={classes.sideLengthBadge}>
              <span>
                {
                  'A,B,C,D,E,F,G,H,I,J,K,L,M,N,O,P,Q,R,S,T,U,V,W,X,Y,Z'.split(
                    ',',
                  )[index]
                }
              </span>
              {(Number(item) * METERS_TO_YARDS).toFixed(1)} yards
            </Box>
          </Grid>
        ))}
      </Grid>
    );
  };

  const renderSideLengths = () => {
    if (selectedItem) {
      if (selectedItem?.type === 'AREA') {
        //copy to avoid mutations
        const copy = JSON.parse(JSON.stringify(mappedAreas));

        const points = copy.find(
          (area: any) => area.id === selectedItem.id,
        )!.points;

        if (!points) return '';

        const sideLengths = getSides(points, metersPerPixel);

        return renderMeasurements(sideLengths);
      } else if (selectedItem?.type === 'ZONE') {
        //copy to avoid mutations
        const copy = JSON.parse(JSON.stringify(mappedZones));

        const points = copy.find(
          (zone: any) => zone.id === selectedItem.id,
        )!.points;

        if (!points) return '';

        const sideLengths = getSides(points, metersPerPixel);

        return renderMeasurements(sideLengths);
      } else if (selectedItem?.type === 'LOADPOINT') {
        //copy to avoid mutations
        const copy = JSON.parse(JSON.stringify(mappedLoadpoints));

        const points = copy.find(
          (lp: any) => lp.id === selectedItem.id,
        )!.points;

        if (!points) return '';

        const sideLengths = getSides(points, metersPerPixel);

        return renderMeasurements(sideLengths);
      } else if (selectedItem?.type === 'DOCKDOOR') {
        //copy to avoid mutations
        const copy = JSON.parse(JSON.stringify(mappedDockdoors));

        const points = copy.find(
          (lp: any) => lp.id === selectedItem.id,
        )!.points;

        if (!points) return '';

        const sideLengths = getSides(points, metersPerPixel);

        return renderMeasurements(sideLengths);
      }
    } else if (isDrawingStationFinished) {
      //Nothing selected,station view
      const sideLengths = getSides(
        stationOutlinePoints,
        metersPerPixel,
      );

      return renderMeasurements(sideLengths);
    } else return null;
  };

  //Functions for navigation

  const addStationLayoutItem = (params: {
    type: ElementType;
    id: number;
  }) => {
    if (params.type === 'AREA') {
      addAreaToLayout(params.id);

      //add random geoShape to the area to make it disappear from new elements list
      setAllAreas(
        [...allAreas!].map((area) =>
          area.id === params.id
            ? { ...area, geo_shape: 'temp' }
            : area,
        ),
      );
    } else if (params.type === 'DOCKDOOR') {
      addDockDoorToLayout(params.id);

      //add random geoShape to the dock door to make it disappear from new elements list
      setAllDockdoors(
        [...allDockdoors!].map((dd) =>
          dd.id === params.id ? { ...dd, geo_shape: 'temp' } : dd,
        ),
      );
    }
  };

  //Stage rotation update

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const compassChange = useCallback(
    debounce((degrees: number) => {
      //snappping with 5 deg tolerance
      let snap = stageRotationSnaps.find(
        (angle) => angle > degrees - 5 && angle < degrees + 5,
      );

      if (!snap) snap = degrees;
      setStageRotation(snap);

      const shapeOutline = findMinMaxCoordinates(
        stationOutlinePoints,
      );

      const shapeOutlineRotated = findMinMaxCoordinates(
        rotateShape(stationOutlinePoints, snap),
      );

      setStageOffset({
        x: shapeOutlineRotated.x.mid - shapeOutline.x.mid,
        y: shapeOutlineRotated.y.mid - shapeOutline.y.mid,
      });
    }, 10),
    [stationOutlinePoints, stageRotationSnaps],
  );

  const selectStationLayoutItem = (params: {
    type: ElementType;
    id: number;
  }) => {
    setSelectedItem(params);
  };

  //Undo last change
  const undoLastChange = (e?: React.MouseEvent) => {
    e && e.preventDefault();

    if (!actionHistory.length) return;

    const lastAction = actionHistory[actionHistory.length - 1];

    switch (lastAction.element) {
      case 'AREA':
        if (lastAction.action === 'MOVE') {
          onAreaMove(
            lastAction.id,
            -lastAction.x!,
            -lastAction.y!,
            true,
          );
        } else {
          const current = mappedAreas.find(
            (area) => area.id === lastAction.id,
          )!;
          onAreaTransform(
            current,
            lastAction.rotation
              ? current.rotation - lastAction.rotation
              : current.rotation,
            lastAction.scaleX ? 1 / lastAction.scaleX : 1,
            lastAction.scaleY ? 1 / lastAction.scaleY : 1,
            true,
          );
        }
        break;
      case 'ZONE':
        if (lastAction.action === 'MOVE') {
          onZoneMove(
            [lastAction.id],
            -lastAction.x!,
            -lastAction.y!,
            true,
          );
        } else {
          const current = mappedZones.find(
            (zone) => zone.id === lastAction.id,
          )!;
          onZoneTransform(
            [current],
            lastAction.rotation
              ? current.rotation - lastAction.rotation
              : current.rotation,
            lastAction.scaleX ? 1 / lastAction.scaleX : 1,
            lastAction.scaleY ? 1 / lastAction.scaleY : 1,
            undefined,
            undefined,
            undefined,
            undefined,
            true,
          );
        }
        break;
      case 'LOADPOINT':
        if (lastAction.action === 'MOVE') {
          onLPMove(
            [lastAction.id],
            -lastAction.x!,
            -lastAction.y!,
            true,
          );
        } else {
          const current = mappedLoadpoints.find(
            (loadpoint) => loadpoint.id === lastAction.id,
          )!;
          onLPTransform(
            [current],
            lastAction.rotation
              ? current.rotation - lastAction.rotation
              : current.rotation,
            lastAction.scaleX ? 1 / lastAction.scaleX : 1,
            lastAction.scaleY ? 1 / lastAction.scaleY : 1,
            undefined,
            undefined,
            undefined,
            undefined,
            true,
          );
        }
        break;
      case 'DOCKDOOR':
        if (lastAction.action === 'MOVE') {
          onDockdoorMove(
            lastAction.id,
            -lastAction.x!,
            -lastAction.y!,
            true,
          );
        } else {
          const current = mappedDockdoors.find(
            (dockDoor) => dockDoor.id === lastAction.id,
          )!;
          onDockdoorTransform(
            current,
            lastAction.rotation
              ? current.rotation - lastAction.rotation
              : current.rotation,
            lastAction.scaleX ? 1 / lastAction.scaleX : 1,
            lastAction.scaleY ? 1 / lastAction.scaleY : 1,
            true,
          );
        }
    }

    if (actionHistory.length <= 1) setChangesPendingSave(false);
    setActionHistory([...actionHistory.slice(0, -1)]);
  };

  //Functions for canvas elements transformations
  const onLPMove = (
    shapeIds: number[],
    x: number,
    y: number,
    dontSaveAction: boolean = false,
  ) => {
    const updatedLoadpoints = mappedLoadpoints
      .filter((lp) => shapeIds.includes(lp.id))
      .map((lp) => {
        const pointsNoAngleOffset = [...lp.pointsNoAngleOffset].map(
          (point) => [point[0] + x, point[1] + y],
        );

        //store the action
        if (!dontSaveAction) {
          setActionHistory([
            ...actionHistory,
            { id: lp.id, element: 'LOADPOINT', action: 'MOVE', x, y },
          ]);
        }

        setChangesPendingSave(true);

        return {
          ...lp,
          pendingSave: true,
          pointsNoAngleOffset,
          points: pointsNoAngleOffset.map((point) =>
            rotatePoint(point, [0, 0], 0 - lp.rotation),
          ), // rotate back canvas
        };
      });

    //Update in state
    setMappedLoadpoints(
      [...mappedLoadpoints!].map((lp) =>
        shapeIds.includes(lp.id)
          ? updatedLoadpoints.find((l) => l.id === lp.id)!
          : lp,
      ),
    );
  };
  const onLPTransform = (
    shapes: MappedElement[],
    newRotation: number,
    scaleX: number = 1,
    scaleY: number = 1,
    rotationPoint?: number[],
    rotOffset?: number,
    resizingParent: boolean = false,
    updatedZones?: MappedElement[],
    dontSaveAction: boolean = false,
  ) => {
    let updatedLoadpoints: MappedElement[];

    if (
      rotOffset !== undefined
        ? Math.round(rotOffset) !== 0
        : newRotation.toFixed(1) !== shapes[0].rotation.toFixed(1)
    ) {
      // it's rotation
      updatedLoadpoints = mappedLoadpoints
        .filter((lp) =>
          shapes.map((shape) => shape.id).includes(lp.id),
        )
        .map((lp) => {
          //snap to station angle within 1 deg
          const snap = stageRotationSnaps.find(
            (angle) =>
              angle > newRotation - 1 && angle < newRotation + 1,
          );
          if (snap) newRotation = snap;

          const rotationOffset = rotOffset
            ? rotOffset
            : newRotation - lp.rotation;

          //store the action
          if (!dontSaveAction) {
            setActionHistory([
              ...actionHistory,
              {
                id: lp.id,
                element: 'LOADPOINT',
                action: 'TRANSFORM',
                rotation: rotationOffset,
              },
            ]);
          }

          setChangesPendingSave(true);

          const minMax = findMinMaxCoordinates(
            rotateShape(lp.points, lp.rotation),
          );

          const pointsNoAngleOffset = [...lp.points]
            .map((point) => rotatePoint(point, [0, 0], lp.rotation)) //revert back rotation
            .map((point) =>
              rotatePoint(
                point,
                rotationPoint || [minMax.x.mid, minMax.y.mid],
                rotationOffset,
              ),
            );

          return {
            ...lp,
            pendingSave: true,
            pointsNoAngleOffset,
            points: pointsNoAngleOffset.map((point) =>
              rotatePoint(point, [0, 0], 0 - newRotation),
            ), // rotate back canvas
            rotation: newRotation, // setNewRotation
          };
        });
    } else {
      // It's a transform

      updatedLoadpoints = mappedLoadpoints
        .filter((lp) =>
          shapes.map((shape) => shape.id).includes(lp.id),
        )
        .map((lp) => {
          const minMax = findMinMaxCoordinates(lp.points);

          let points;

          if (!resizingParent)
            points = [...lp.points].map((point) => [
              (point[0] - minMax.x.min) * scaleX + minMax.x.min,
              (point[1] - minMax.y.min) * scaleY + minMax.y.min,
            ]);
          else {
            const parentZone = updatedZones!.find(
              (zone) => zone.id === lp.zone,
            );

            const parentMinMax = findMinMaxCoordinates(
              parentZone!.points,
            );

            const positionMovement = [
              (minMax.x.min - parentMinMax.x.min) * (scaleX - 1),
              (minMax.y.min - parentMinMax.y.min) * (scaleY - 1),
            ];

            points = [...lp.points].map((point) => [
              (point[0] -
                minMax.x.min +
                parentZone!.positionMovement![0]) *
                scaleX +
                minMax.x.min +
                positionMovement[0],

              (point[1] -
                minMax.y.min +
                parentZone!.positionMovement![1]) *
                scaleY +
                minMax.y.min +
                positionMovement[1],
            ]);
          }
          //store the action
          if (!dontSaveAction) {
            setActionHistory([
              ...actionHistory,
              {
                id: lp.id,
                element: 'LOADPOINT',
                action: 'TRANSFORM',
                scaleX,
                scaleY,
              },
            ]);
          }

          setChangesPendingSave(true);

          return {
            ...lp,
            pendingSave: true,
            points,
            pointsNoAngleOffset: points.map((point) =>
              rotatePoint(point, [0, 0], lp.rotation),
            ), // rotate back canvas
          };
        });
    }

    //Update in state
    setMappedLoadpoints(
      [...mappedLoadpoints].map((loadpoint) =>
        shapes.map((shape) => shape.id).includes(loadpoint.id)
          ? updatedLoadpoints.find((lp) => lp.id === loadpoint.id)!
          : loadpoint,
      ),
    );
  };
  const onZoneMove = (
    shapeIds: number[],
    x: number,
    y: number,
    dontSaveAction: boolean = false,
  ) => {
    const updatedZones = mappedZones
      .filter((zone) => shapeIds.includes(zone.id))
      .map((zone) => {
        //store the action
        if (!dontSaveAction) {
          setActionHistory([
            ...actionHistory,
            { id: zone.id, element: 'ZONE', action: 'MOVE', x, y },
          ]);
        }

        const pointsNoAngleOffset = [...zone.pointsNoAngleOffset].map(
          (point) => [point[0] + x, point[1] + y],
        );
        setChangesPendingSave(true);
        return {
          ...zone,
          pendingSave: true,
          pointsNoAngleOffset,
          points: pointsNoAngleOffset.map((point) =>
            rotatePoint(point, [0, 0], 0 - zone.rotation),
          ), // rotate back canvas
        };
      });

    //Update in state
    setMappedZones(
      [...mappedZones].map((zone) =>
        shapeIds.includes(zone.id)
          ? updatedZones.find((z) => z.id === zone.id)!
          : zone,
      ),
    );

    //Child Loadpoints

    onLPMove(
      mappedLoadpoints
        .filter((lp) => shapeIds.includes(lp.zone!))
        .map((lp) => lp.id),
      x,
      y,
      true,
    );
  };
  const onZoneTransform = async (
    shapes: MappedElement[],
    newRotation: number,
    scaleX: number = 1,
    scaleY: number = 1,
    rotationPoint?: number[],
    rotOffset?: number,
    resizingParent: boolean = false,
    updatedAreas?: MappedElement[],
    dontSaveAction: boolean = false,
  ) => {
    let minMax: any;

    let updatedZones: MappedElement[] = [];

    if (
      rotOffset !== undefined
        ? Math.round(rotOffset) !== 0
        : newRotation.toFixed(1) !== shapes[0].rotation.toFixed(1)
    ) {
      // it's rotation
      updatedZones = mappedZones
        .filter((zone) =>
          shapes.map((shape) => shape.id).includes(zone.id),
        )
        .map((zone) => {
          //snap to station angle within 1 deg
          const snap = stageRotationSnaps.find(
            (angle) =>
              angle > newRotation - 1 && angle < newRotation + 1,
          );
          if (snap) newRotation = snap;

          const rotationOffset = rotOffset
            ? rotOffset
            : newRotation - zone.rotation;

          minMax = findMinMaxCoordinates(
            rotateShape(zone.points, zone.rotation),
          );

          //store the action
          if (!dontSaveAction) {
            setActionHistory([
              ...actionHistory,
              {
                id: zone.id,
                element: 'ZONE',
                action: 'TRANSFORM',
                rotation: rotationOffset,
              },
            ]);
          }

          const pointsNoAngleOffset = [...zone.points]
            .map((point) => rotatePoint(point, [0, 0], zone.rotation)) //revert back rotation
            .map((point) =>
              rotatePoint(
                point,
                rotationPoint || [minMax.x.mid, minMax.y.mid],
                rotationOffset,
              ),
            );

          setChangesPendingSave(true);

          return {
            ...zone,
            pendingSave: true,
            pointsNoAngleOffset,
            points: pointsNoAngleOffset.map((point) =>
              rotatePoint(point, [0, 0], 0 - newRotation),
            ), // rotate back canvas
            rotation: newRotation, // setNewRotation
          };
        });
    } else {
      // It's a transform

      rotOffset = 0;

      updatedZones = mappedZones
        .filter((zone) =>
          shapes.map((shape) => shape.id).includes(zone.id),
        )
        .map((zone) => {
          minMax = findMinMaxCoordinates(zone.points);

          let points;
          let positionMovement: number[] = [0, 0];

          if (!resizingParent)
            points = [...zone.points].map((point) => [
              (point[0] - minMax.x.min) * scaleX + minMax.x.min,
              (point[1] - minMax.y.min) * scaleY + minMax.y.min,
            ]);
          else {
            const parentPoints = updatedAreas!.find(
              (area) => area.id === zone.area,
            )?.points;

            const parentMinMax = findMinMaxCoordinates(parentPoints!);

            positionMovement = [
              (minMax.x.min - parentMinMax.x.min) * (scaleX - 1),
              (minMax.y.min - parentMinMax.y.min) * (scaleY - 1),
            ];

            points = [...zone.points].map((point) => [
              (point[0] - minMax.x.min) * scaleX +
                minMax.x.min +
                positionMovement[0],
              (point[1] - minMax.y.min) * scaleY +
                minMax.y.min +
                positionMovement[1],
            ]);
          }

          //store the action
          if (!dontSaveAction) {
            setActionHistory([
              ...actionHistory,
              {
                id: zone.id,
                element: 'ZONE',
                action: 'TRANSFORM',
                scaleX,
                scaleY,
              },
            ]);
          }

          setChangesPendingSave(true);

          return {
            ...zone,
            pendingSave: true,
            points,
            pointsNoAngleOffset: points.map((point) =>
              rotatePoint(point, [0, 0], zone.rotation),
            ), // rotate back canvas
            positionMovement,
          };
        });
    }

    //Update in state;
    setMappedZones(
      [...mappedZones].map((zone) =>
        shapes.map((shape) => shape.id).includes(zone.id)
          ? updatedZones.find((z) => zone.id === z.id)!
          : zone,
      ),
    );

    //Child LPs
    if (rotOffset !== 0 || resizingChildElements) {
      const childLPs = mappedLoadpoints.filter((lp) =>
        updatedZones.map((zone) => zone.id).includes(lp.zone || 0),
      );

      childLPs.length &&
        onLPTransform(
          childLPs,
          newRotation,
          scaleX,
          scaleY,
          rotationPoint || [minMax.x.mid, minMax.y.mid],
          rotOffset,
          true,
          updatedZones,
          true,
        );
    }
  };
  const onAreaMove = (
    shapeId: number,
    x: number,
    y: number,
    dontSaveAction: boolean = false,
  ) => {
    //Areas
    const updatedAreas = mappedAreas
      .filter((area) => area.id === shapeId)
      .map((area) => {
        const pointsNoAngleOffset = [...area.pointsNoAngleOffset].map(
          (point) => [point[0] + x, point[1] + y],
        );

        //store the action
        if (!dontSaveAction) {
          setActionHistory([
            ...actionHistory,
            { id: area.id, element: 'AREA', action: 'MOVE', x, y },
          ]);
        }

        setChangesPendingSave(true);

        return {
          ...area,
          pendingSave: true,
          pointsNoAngleOffset,
          points: pointsNoAngleOffset.map((point) =>
            rotatePoint(point, [0, 0], 0 - area.rotation),
          ), // rotate back canvas
        };
      });

    //Update in state
    setMappedAreas(
      [...mappedAreas].map((area) =>
        area.id === shapeId
          ? updatedAreas.find((area) => area.id === shapeId)!
          : area,
      ),
    );

    //Child Zones
    onZoneMove(
      mappedZones
        .filter((zone) => zone.area === shapeId)
        .map((zone) => zone.id),
      x,
      y,
      true,
    );
  };
  const onAreaTransform = (
    shape: MappedElement,
    newRotation: number,
    scaleX: number = 1,
    scaleY: number = 1,
    dontSaveAction: boolean = false,
  ) => {
    const minMax = findMinMaxCoordinates(
      rotateShape(shape.points, shape.rotation),
    );

    let updatedAreas: MappedElement[] = [];
    let rotationOffset = 0;

    if (newRotation.toFixed(1) !== shape.rotation.toFixed(1)) {
      // it's rotation

      updatedAreas = mappedAreas
        .filter((area) => area.id === shape.id)
        .map((area) => {
          //snap to station angle within 1 deg
          const snap = stageRotationSnaps.find(
            (angle) =>
              angle > newRotation - 1 && angle < newRotation + 1,
          );
          if (snap) newRotation = snap;

          rotationOffset = newRotation - area.rotation!;

          const pointsNoAngleOffset = [...area.points]
            .map((point) =>
              rotatePoint(point, [0, 0], area.rotation || 0),
            ) //revert back rotation
            .map((point) =>
              rotatePoint(
                point,
                [minMax.x.mid, minMax.y.mid],
                rotationOffset,
              ),
            );

          //store the action
          if (!dontSaveAction) {
            setActionHistory([
              ...actionHistory,
              {
                id: area.id,
                element: 'AREA',
                action: 'TRANSFORM',
                rotation: rotationOffset,
              },
            ]);
          }

          setChangesPendingSave(true);

          return {
            ...area,
            pendingSave: true,
            pointsNoAngleOffset,
            points: pointsNoAngleOffset.map((point) =>
              rotatePoint(point, [0, 0], 0 - newRotation),
            ), // rotate back canvas
            rotation: newRotation, // setNewRotation
          };
        });
    } else {
      // It's a transform
      const minMax = findMinMaxCoordinates(shape.points);

      const minX = minMax.x.min;
      const minY = minMax.y.min;

      updatedAreas = mappedAreas
        .filter((area) => area.id === shape.id)
        .map((area) => {
          const points = [...area.points].map((point) => [
            (point[0] - minX) * scaleX + minX,
            (point[1] - minY) * scaleY + minY,
          ]);

          //store the action
          if (!dontSaveAction) {
            setActionHistory([
              ...actionHistory,
              {
                id: area.id,
                element: 'AREA',
                action: 'TRANSFORM',
                scaleX,
                scaleY,
              },
            ]);
          }
          setChangesPendingSave(true);

          return {
            ...area,
            pendingSave: true,
            points,
            pointsNoAngleOffset: points.map((point) =>
              rotatePoint(point, [0, 0], area.rotation),
            ), // rotate back canvas
          };
        });
    }

    //Update in state;
    setMappedAreas(
      [...mappedAreas].map((area) =>
        area.id === shape.id
          ? updatedAreas.find((area) => area.id === shape.id)!
          : area,
      ),
    );

    //Update child zones
    if (rotationOffset !== 0 || resizingChildElements) {
      const childZones = mappedZones.filter((zone) =>
        updatedAreas.map((area) => area.id).includes(zone.area || 0),
      );

      childZones.length &&
        onZoneTransform(
          childZones,
          newRotation,
          scaleX,
          scaleY,
          [minMax.x.mid, minMax.y.mid],
          rotationOffset,
          true,
          updatedAreas,
          true,
        );
    }
  };

  const onDockdoorMove = (
    shapeId: number,
    x: number,
    y: number,
    dontSaveAction: boolean = false,
  ) => {
    const updatedDockdoors = mappedDockdoors
      .filter((dd) => dd.id === shapeId)
      .map((dd) => {
        const pointsNoAngleOffset = [...dd.pointsNoAngleOffset].map(
          (point) => [point[0] + x, point[1] + y],
        );

        setChangesPendingSave(true);

        //store the action
        if (!dontSaveAction) {
          setActionHistory([
            ...actionHistory,
            { id: dd.id, element: 'DOCKDOOR', action: 'MOVE', x, y },
          ]);
        }

        return {
          ...dd,
          pendingSave: true,
          pointsNoAngleOffset,
          points: pointsNoAngleOffset.map((point) =>
            rotatePoint(point, [0, 0], 0 - dd.rotation),
          ), // rotate back canvas
        };
      });

    //Update in state
    setMappedDockdoors(
      [...mappedDockdoors!].map((dd) =>
        dd.id === shapeId
          ? updatedDockdoors.find((ddoor) => ddoor.id === dd.id)!
          : dd,
      ),
    );
  };
  const onDockdoorTransform = (
    shape: MappedElement,
    newRotation: number,
    scaleX: number = 1,
    scaleY: number = 1,
    dontSaveAction: boolean = false,
  ) => {
    const minMax = findMinMaxCoordinates(
      rotateShape(shape.points, shape.rotation),
    );

    let updatedDockdoors: MappedElement[];

    if (newRotation.toFixed(1) !== shape.rotation.toFixed(1)) {
      // it's rotation
      updatedDockdoors = mappedDockdoors
        .filter((dd) => shape.id === dd.id)
        .map((dd) => {
          //snap to station angle within 1 deg
          const snap = stageRotationSnaps.find(
            (angle) =>
              angle > newRotation - 1 && angle < newRotation + 1,
          );
          if (snap) newRotation = snap;

          const rotationOffset = newRotation - dd.rotation;

          const pointsNoAngleOffset = [...dd.points]
            .map((point) => rotatePoint(point, [0, 0], dd.rotation)) //revert back rotation
            .map((point) =>
              rotatePoint(
                point,
                [minMax.x.mid, minMax.y.mid],
                rotationOffset,
              ),
            );

          //store the action
          if (!dontSaveAction) {
            setActionHistory([
              ...actionHistory,
              {
                id: dd.id,
                element: 'DOCKDOOR',
                action: 'TRANSFORM',
                rotation: rotationOffset,
              },
            ]);
          }

          setChangesPendingSave(true);

          return {
            ...dd,
            pendingSave: true,
            pointsNoAngleOffset,
            points: pointsNoAngleOffset.map((point) =>
              rotatePoint(point, [0, 0], 0 - newRotation),
            ), // rotate back canvas
            rotation: newRotation, // setNewRotation
          };
        });
    } else {
      // It's a transform
      const minMax = findMinMaxCoordinates(shape.points);

      const minX = minMax.x.min;
      const minY = minMax.y.min;

      updatedDockdoors = mappedDockdoors
        .filter((dd) => shape.id === dd.id)
        .map((dd) => {
          const points = [...dd.points].map((point) => [
            (point[0] - minX) * scaleX + minX,
            (point[1] - minY) * scaleY + minY,
          ]);

          //store the action
          if (!dontSaveAction) {
            setActionHistory([
              ...actionHistory,
              {
                id: dd.id,
                element: 'DOCKDOOR',
                action: 'TRANSFORM',
                scaleX,
                scaleY,
              },
            ]);
          }

          setChangesPendingSave(true);

          return {
            ...dd,
            pendingSave: true,
            points,
            pointsNoAngleOffset: points.map((point) =>
              rotatePoint(point, [0, 0], dd.rotation),
            ), // rotate back canvas
          };
        });
    }

    //Update in state
    setMappedDockdoors(
      [...mappedDockdoors].map((dd) =>
        shape.id === dd.id
          ? updatedDockdoors.find((lp) => lp.id === dd.id)!
          : dd,
      ),
    );
  };

  //Partial Renders

  const renderSaveButton = () => {
    return (
      <Box className={classes.sidebarBottomButtons}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography style={{ textAlign: 'center' }}>
              &#9432; There are unsaved changes.{' '}
              <a
                href='/'
                onClick={(e) => {
                  undoLastChange(e);
                }}
                style={{
                  fontStyle: 'italic',
                  color: colors.lightGold,
                  textDecoration: 'none',
                  cursor: 'pointer',
                }}
              >
                &#8617; Undo
              </a>
            </Typography>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Button
              variant='outlined'
              className={classes.outlinedBtnGold}
              onClick={() => {
                //reset all to init values

                setMappedLoadpoints(mappedLoadpointsInit);
                setMappedZones(mappedZonesInit);
                setMappedAreas(mappedAreasInit);
                setMappedDockdoors(mappedDockdoorsInit);

                setChangesPendingSave(false);
                setActionHistory([]);
              }}
              style={{ width: '100%' }}
            >
              <HighlightOffRoundedIcon
                style={{ marginRight: '5px' }}
              />
              Discard
            </Button>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Button
              variant='contained'
              className={classes.containedBtnGold}
              onClick={() => {
                savePendingChanges();
              }}
              style={{
                width: '100%',
                opacity: isDrawingStationFinished ? 1 : 0.5,
              }}
              disabled={!isDrawingStationFinished}
            >
              <CheckCircleOutlineRoundedIcon
                style={{ marginRight: '5px' }}
              />
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const renderAreaSidebar = () => {
    const area = allAreas?.find(
      (area) => area.id === selectedItem?.id,
    );
    const zones = allZones?.filter(
      (zone) => zone.area === selectedItem!.id,
    );
    const lps = allLoadpoints?.filter(
      (lp) => lp.area === selectedItem!.id,
    );
    return (
      <Grid item xs={3} className={classes.sidebar}>
        <Grid
          container
          style={{
            borderBottom: '2px solid #333',
            marginBottom: '10px',
          }}
        >
          <Grid item xs={6}>
            <Typography className={classes.sidebarElementType}>
              {configurationUtils.getPageTitle(true, 'AREA')}
            </Typography>
            <Typography className={classes.sidebarElementName}>
              {area?.name}
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <IconButton aria-label='Settings'>
              <MoreVertIcon style={{ color: colors.white }} />
            </IconButton>
          </Grid>
          <hr className={classes.lightHr} />
        </Grid>
        <Box className={classes.sidebarContent}>
          <Grid container spacing={1}>
            <Grid item xs={6} style={{ marginBottom: '5px' }}>
              <Typography className={classes.statLabel}>
                Type
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ marginBottom: '5px' }}>
              <Typography className={classes.statValue}>
                {area?.area_type}
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ marginBottom: '5px' }}>
              <Typography className={classes.statLabel}>
                {configurationUtils.getPageTitle(false, 'ZONE')}
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ marginBottom: '5px' }}>
              <Typography className={classes.statValue}>
                {zones?.length}{' '}
                {configurationUtils.getPageTitle(false, 'ZONE')}
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ marginBottom: '5px' }}>
              <Typography className={classes.statLabel}>
                {configurationUtils.getPageTitle(false, 'LOADPOINT')}
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ marginBottom: '5px' }}>
              <Typography className={classes.statValue}>
                {lps?.length}{' '}
                {configurationUtils.getPageTitle(false, 'LOADPOINT')}
              </Typography>
            </Grid>
          </Grid>

          {renderSideLengths()}
          {currentView === 'VIEW' && hasWritePermission && (
            <Grid item xs={12}>
              <Typography className={classes.sidebarSubheading}>
                Element Shape
              </Typography>
              <Button
                variant='contained'
                className={classes.containedBtnWhite}
                onClick={() => {
                  setTransformingItem({
                    type: 'AREA',
                    id: area!.id!,
                  });
                }}
                style={{ width: '100%' }}
              >
                Resize Element
              </Button>
            </Grid>
          )}
        </Box>
        {changesPendingSave && renderSaveButton()}
      </Grid>
    );
  };

  const renderZoneSidebar = () => {
    const zone = allZones?.find(
      (zone) => zone.id === selectedItem?.id,
    );
    const lps = allLoadpoints?.filter(
      (lp) => lp.zone === selectedItem!.id,
    );
    return (
      <Grid item xs={3} className={classes.sidebar}>
        <Grid
          container
          style={{
            borderBottom: '2px solid #333',
            marginBottom: '10px',
          }}
        >
          <Grid item xs={6}>
            <Typography className={classes.sidebarElementType}>
              {configurationUtils.getPageTitle(true, 'ZONE')}
            </Typography>
            <Typography className={classes.sidebarElementName}>
              {zone?.name}
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <IconButton aria-label='Settings'>
              <MoreVertIcon style={{ color: colors.white }} />
            </IconButton>
          </Grid>
          <hr className={classes.lightHr} />
        </Grid>
        <Box className={classes.sidebarContent}>
          <Grid container spacing={1}>
            <Grid item xs={6} style={{ marginBottom: '5px' }}>
              <Typography className={classes.statLabel}>
                {configurationUtils.getPageTitle(false, 'LOADPOINT')}
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ marginBottom: '5px' }}>
              <Typography className={classes.statValue}>
                {lps?.length}{' '}
                {configurationUtils.getPageTitle(false, 'LOADPOINT')}
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ marginBottom: '5px' }}>
              <Typography className={classes.statLabel}>
                Status
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ marginBottom: '5px' }}>
              <Typography className={classes.statValue}>
                Active
              </Typography>
            </Grid>
          </Grid>

          {renderSideLengths()}
          {currentView === 'VIEW' && hasWritePermission && (
            <Grid item xs={12}>
              <Typography className={classes.sidebarSubheading}>
                Element Shape
              </Typography>

              <Button
                variant='contained'
                className={classes.containedBtnWhite}
                onClick={() => {
                  setTransformingItem({
                    type: 'ZONE',
                    id: zone!.id!,
                  });
                }}
                style={{ width: '100%' }}
              >
                Resize Element
              </Button>
            </Grid>
          )}
        </Box>
        {changesPendingSave && renderSaveButton()}
      </Grid>
    );
  };

  const renderLPSidebar = () => {
    const lp = allLoadpoints?.find(
      (lp) => lp.id === selectedItem?.id,
    );
    return (
      <Grid item xs={3} className={classes.sidebar}>
        <Grid
          container
          style={{
            borderBottom: '2px solid #333',
            marginBottom: '10px',
          }}
        >
          <Grid item xs={6}>
            <Typography className={classes.sidebarElementType}>
              {configurationUtils.getPageTitle(true, 'LOADPOINT')}
            </Typography>
            <Typography className={classes.sidebarElementName}>
              {lp?.name}
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <IconButton aria-label='Settings'>
              <MoreVertIcon style={{ color: colors.white }} />
            </IconButton>
          </Grid>
          <hr className={classes.lightHr} />
        </Grid>
        <Box className={classes.sidebarContent}>
          <Grid container spacing={1}>
            <Grid item xs={6} style={{ marginBottom: '5px' }}>
              <Typography className={classes.statLabel}>
                Default{' '}
                {configurationUtils.getPageTitle(true, 'CONTAINER')}{' '}
                type
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ marginBottom: '5px' }}>
              <Typography className={classes.statValue}>
                {lp?.default_container_type}
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ marginBottom: '5px' }}>
              <Typography className={classes.statLabel}>
                Module name
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ marginBottom: '5px' }}>
              <Typography className={classes.statValue}>
                {lp?.module_name || common.emptyValue}
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ marginBottom: '5px' }}>
              <Typography className={classes.statLabel}>
                Carrier
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ marginBottom: '5px' }}>
              <Typography className={classes.statValue}>
                {lp?.fmcs?.length
                  ? lp?.fmcs.join(', ')
                  : common.emptyValue}
              </Typography>
            </Grid>
          </Grid>

          {renderSideLengths()}
          {currentView === 'VIEW' && hasWritePermission && (
            <Grid item xs={12}>
              <Typography className={classes.sidebarSubheading}>
                Element Shape
              </Typography>

              <Button
                variant='contained'
                className={classes.containedBtnWhite}
                onClick={() => {
                  setTransformingItem({
                    type: 'LOADPOINT',
                    id: lp!.id!,
                  });
                }}
                style={{ width: '100%' }}
              >
                Resize Element
              </Button>
            </Grid>
          )}
        </Box>
        {changesPendingSave && renderSaveButton()}
      </Grid>
    );
  };

  const renderDockDoorSidebar = () => {
    const dd = allDockdoors?.find((dd) => dd.id === selectedItem?.id);
    return (
      <Grid item xs={3} className={classes.sidebar}>
        <Grid
          container
          style={{
            borderBottom: '2px solid #333',
            marginBottom: '10px',
          }}
        >
          <Grid item xs={6}>
            <Typography className={classes.sidebarElementType}>
              {configurationUtils.getPageTitle(true, 'DOCK_DOOR')}
            </Typography>
            <Typography className={classes.sidebarElementName}>
              {dd?.name}
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <IconButton aria-label='Settings'>
              <MoreVertIcon style={{ color: colors.white }} />
            </IconButton>
          </Grid>
          <hr className={classes.lightHr} />
        </Grid>

        {renderSideLengths()}
        {currentView === 'VIEW' && hasWritePermission && (
          <Grid item xs={12}>
            <Typography className={classes.sidebarSubheading}>
              Element Shape
            </Typography>

            <Button
              variant='contained'
              className={classes.containedBtnWhite}
              onClick={() => {
                setTransformingItem({
                  type: 'DOCKDOOR',
                  id: dd!.id!,
                });
              }}
              style={{ width: '100%' }}
            >
              Resize Element
            </Button>
          </Grid>
        )}
        {changesPendingSave && renderSaveButton()}
      </Grid>
    );
  };

  const renderSidebar = () => {
    if (selectedItem) {
      switch (selectedItem.type) {
        case 'AREA':
          return renderAreaSidebar();
        case 'ZONE':
          return renderZoneSidebar();
        case 'LOADPOINT':
          return renderLPSidebar();
        case 'DOCKDOOR':
          return renderDockDoorSidebar();
        default:
          return <>No sidebar available for selected element.</>;
      }
    } // no item selected -- station view
    else
      return (
        <Grid item sm={4} lg={3} className={classes.sidebar}>
          <Grid
            container
            style={{
              borderBottom: '2px solid #333',
              marginBottom: '10px',
            }}
          >
            <Grid item xs={6}>
              <Typography className={classes.sidebarElementType}>
                Station
              </Typography>
              <Typography className={classes.sidebarElementName}>
                {station.name}
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'right' }}>
              <IconButton aria-label='Settings'>
                <MoreVertIcon style={{ color: colors.white }} />
              </IconButton>
            </Grid>
            <hr className={classes.lightHr} />
          </Grid>
          <Box className={classes.sidebarContent}>
            <Grid container spacing={1}>
              <Grid item xs={6} style={{ marginBottom: '5px' }}>
                <Typography className={classes.statLabel}>
                  {configurationUtils.getPageTitle(false, 'AREA')}
                </Typography>
              </Grid>
              <Grid item xs={6} style={{ marginBottom: '5px' }}>
                <Typography className={classes.statValue}>
                  {allAreas?.length}{' '}
                  {configurationUtils.getPageTitle(false, 'AREA')}
                </Typography>
              </Grid>
              <Grid item xs={6} style={{ marginBottom: '5px' }}>
                <Typography className={classes.statLabel}>
                  {configurationUtils.getPageTitle(false, 'ZONE')}
                </Typography>
              </Grid>
              <Grid item xs={6} style={{ marginBottom: '5px' }}>
                <Typography className={classes.statValue}>
                  {allZones?.length}{' '}
                  {configurationUtils.getPageTitle(false, 'ZONE')}
                </Typography>
              </Grid>
              <Grid item xs={6} style={{ marginBottom: '5px' }}>
                <Typography className={classes.statLabel}>
                  {configurationUtils.getPageTitle(
                    false,
                    'LOADPOINT',
                  )}
                </Typography>
              </Grid>
              <Grid item xs={6} style={{ marginBottom: '5px' }}>
                <Typography className={classes.statValue}>
                  {allLoadpoints?.length}{' '}
                  {configurationUtils.getPageTitle(
                    false,
                    'LOADPOINT',
                  )}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography className={classes.sidebarSubheading}>
                  Location
                </Typography>
                <Typography className={classes.statLabel}>
                  Address
                </Typography>
                <Box className={classes.sideLengthBadge}>
                  {[
                    station.address,
                    station.city,
                    station.state,
                    'USA',
                  ]
                    .filter(Boolean)
                    .join(', ')}
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Typography className={classes.statLabel}>
                  Latitude
                </Typography>
                <Box className={classes.sideLengthBadge}>
                  {mapCoordinates.lat}
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.statLabel}>
                  Longitude
                </Typography>
                <Box className={classes.sideLengthBadge}>
                  {mapCoordinates.lng}
                </Box>
              </Grid>
            </Grid>

            {renderSideLengths()}
            {!stationOutlinePoints.length && (
              <Grid item xs={12}>
                <Typography className={classes.sidebarSubheading}>
                  Element Shape
                </Typography>

                <Button
                  variant='contained'
                  className={classes.containedBtnWhite}
                  onClick={() => {
                    setKonvaActive(true);
                    setIsDrawingStation(true);

                    //get snapshot of view meta data
                    var bounds = mapObject.getBounds();
                    var ne = bounds.getNorthEast();
                    var sw = bounds.getSouthWest();

                    const movement = {
                      x:
                        (ne.lng() - sw.lng()) / mapDivRef.clientWidth,
                      y:
                        (sw.lat() - ne.lat()) /
                        mapDivRef.clientHeight,
                    };
                    setCoordinatesMovemetPerPixel(movement);

                    const coordinates = {
                      lat: ne.lat(),
                      lng: sw.lng(),
                    };
                    setRefPointCoordinates(coordinates);

                    setMetersPerPixel(
                      (156543.03392 *
                        Math.cos(
                          (((ne.lat() + sw.lat()) / 2) * Math.PI) /
                            180,
                        )) /
                        Math.pow(2, mapZoom),
                    );
                  }}
                  style={{ width: '100%' }}
                >
                  Draw Outline
                </Button>
              </Grid>
            )}
          </Box>
          {!station.geo_shape && (
            <Box className={classes.sidebarBottomButtons}>
              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <Button
                    variant='outlined'
                    className={classes.outlinedBtnGold}
                    onClick={() => {
                      setStationOutlinePoints([]);
                      setIsDrawingStation(false);
                      setIsDrawingStationFinished(false);
                      setKonvaActive(false);
                    }}
                    style={{ width: '100%' }}
                  >
                    <HighlightOffRoundedIcon
                      style={{ marginRight: '5px' }}
                    />
                    Cancel
                  </Button>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Button
                    variant='contained'
                    className={classes.containedBtnGold}
                    onClick={() => {
                      setMapActive(false);
                      setShowPoints(false);
                      setIsDrawingStation(false);

                      //Store station coordinates
                      StationsService.editStation({
                        id: station.id,
                        geo_shape: convertToGeoJson(
                          stationOutlinePoints,
                          refPointCoordinates!,
                          coordinatesMovemetPerPixel!,
                        ),
                      }).then((res) => {
                        setStation(res.data);
                        globalStationOptionsUtils.setStationData(
                          station.id,
                        );
                      });
                    }}
                    style={{
                      width: '100%',
                      opacity: isDrawingStationFinished ? 1 : 0.5,
                    }}
                    disabled={!isDrawingStationFinished}
                  >
                    <CheckCircleOutlineRoundedIcon
                      style={{ marginRight: '5px' }}
                    />
                    Create
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}
          {changesPendingSave && renderSaveButton()}
        </Grid>
      );
  };

  // Render
  return (
    <>
      <Helmet>
        <title>{`CloudSort - Station View`}</title>
      </Helmet>
      <Layout
        navCurrent='STATION_LAYOUT'
        expandToWholeScreen={true}
        stationLayoutData={{
          allAreas,
          allZones,
          allLoadpoints,
          allDockdoors,
          mappedAreas,
          mappedZones,
          mappedLoadpoints,
          mappedDockdoors,
        }}
        selectStationLayoutItem={selectStationLayoutItem}
        addStationLayoutItem={addStationLayoutItem}
        newElementClick={newElementClick}
      >
        {showProgress && <ProgressIndicator />}

        <Grid container>
          <Box className={classes.stationLayoutContainer}>
            <Grid
              item
              sm={
                noStationOutline || currentViewMode === 'THROUGHPUT'
                  ? 12
                  : 8
              }
              lg={
                noStationOutline || currentViewMode === 'THROUGHPUT'
                  ? 12
                  : 9
              }
              style={{ position: 'relative' }}
            >
              <Grid item xs={12}>
                <div className={classes.header}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} lg={4}>
                      <Typography
                        variant='h3'
                        className={classes.headerTitle}
                      >
                        Station View
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      lg={8}
                      style={{ textAlign: 'right' }}
                    >
                      <ul className={classes.viewNavigation}>
                        <li
                          className={
                            currentView === 'VIEW' &&
                            currentViewMode === 'LAYOUT'
                              ? 'active'
                              : undefined
                          }
                          onClick={() => {
                            setCurrentView('VIEW');
                            setCurrentViewMode('LAYOUT');
                          }}
                        >
                          View
                        </li>
                        <li
                          className={
                            currentView === 'NODES' &&
                            currentViewMode === 'LAYOUT'
                              ? 'active'
                              : undefined
                          }
                          onClick={() => {
                            setCurrentView('NODES');
                            setCurrentViewMode('LAYOUT');
                          }}
                        >
                          Nodes
                        </li>
                        <li
                          className={
                            (currentView === 'COUNT_AREAS_DD' ||
                              currentView === 'COUNT_ZONES' ||
                              currentView === 'COUNT_LOADPOINTS') &&
                            currentViewMode === 'LAYOUT'
                              ? 'active'
                              : undefined
                          }
                        >
                          {currentView === 'COUNT_AREAS_DD'
                            ? `${configurationUtils.getPageTitle(
                                false,
                                'PACKAGE',
                              )} in ${configurationUtils.getPageTitle(
                                false,
                                'AREA',
                              )}/${configurationUtils.getPageTitle(
                                false,
                                'DOCK_DOOR',
                              )}`
                            : currentView === 'COUNT_ZONES'
                            ? `${configurationUtils.getPageTitle(
                                false,
                                'PACKAGE',
                              )} in ${configurationUtils.getPageTitle(
                                false,
                                'ZONE',
                              )}`
                            : currentView === 'COUNT_LOADPOINTS'
                            ? `${configurationUtils.getPageTitle(
                                false,
                                'PACKAGE',
                              )} in ${configurationUtils.getPageTitle(
                                false,
                                'LOADPOINT',
                              )}`
                            : `Number of ${configurationUtils.getPageTitle(
                                false,
                                'PACKAGE',
                              )}`}
                          <ul>
                            <li
                              onClick={() => {
                                setCurrentView('COUNT_AREAS_DD');
                                setCurrentViewMode('LAYOUT');
                              }}
                            >
                              {configurationUtils.getPageTitle(
                                false,
                                'PACKAGE',
                              )}{' '}
                              in{' '}
                              {configurationUtils.getPageTitle(
                                false,
                                'AREA',
                              )}
                              /
                              {configurationUtils.getPageTitle(
                                false,
                                'DOCK_DOOR',
                              )}
                            </li>
                            <li
                              onClick={() => {
                                setCurrentView('COUNT_ZONES');
                                setCurrentViewMode('LAYOUT');
                              }}
                            >
                              {configurationUtils.getPageTitle(
                                false,
                                'PACKAGE',
                              )}{' '}
                              in{' '}
                              {configurationUtils.getPageTitle(
                                false,
                                'ZONE',
                              )}
                            </li>
                            <li
                              onClick={() => {
                                setCurrentView('COUNT_LOADPOINTS');
                                setCurrentViewMode('LAYOUT');
                              }}
                            >
                              {configurationUtils.getPageTitle(
                                false,
                                'PACKAGE',
                              )}{' '}
                              in{' '}
                              {configurationUtils.getPageTitle(
                                false,
                                'LOADPOINT',
                              )}
                            </li>
                          </ul>
                        </li>
                        <li
                          className={
                            currentViewMode === 'THROUGHPUT'
                              ? 'active'
                              : undefined
                          }
                          onClick={() => {
                            setCurrentViewMode('THROUGHPUT');
                          }}
                        >
                          Live View
                        </li>
                      </ul>
                      {(currentView === 'COUNT_AREAS_DD' ||
                        currentView === 'COUNT_ZONES' ||
                        currentView === 'COUNT_LOADPOINTS') &&
                        currentViewMode === 'LAYOUT' && (
                          <div
                            style={{
                              color: colors.black,
                              position: 'absolute',
                              bottom: '-50px',
                              right: '30px',
                            }}
                          >
                            Counts
                            <CSSwitch
                              checked={
                                countOrPercentage === 'PERCENTAGE'
                              }
                              color='default'
                              onClick={() => {
                                countOrPercentage === 'COUNT'
                                  ? setCountOrPercentage('PERCENTAGE')
                                  : setCountOrPercentage('COUNT');
                              }}
                            />
                            Percentage
                          </div>
                        )}
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              {currentViewMode === 'LAYOUT' && (
                <>
                  {noStationOutline && (
                    <Box className={classes.flexCenter}>
                      <Typography className={classes.flexCenterTitle}>
                        No Station outline configured
                      </Typography>
                      <Button
                        variant='contained'
                        className={classes.containedBtnDark}
                        onClick={() => {
                          setNoStationOutline(false);
                        }}
                      >
                        Draw station outline
                      </Button>
                    </Box>
                  )}
                  {!mapActive &&
                    konvaActive &&
                    !mappedAreas.length && (
                      <Box className={classes.flexCenter}>
                        <Box className={classes.flexCenterContainer}>
                          <Typography
                            className={classes.flexCenterTitle}
                          >
                            No Elements
                          </Typography>
                          {allAreas?.length ? (
                            <Button
                              variant='contained'
                              className={classes.containedBtnDark}
                              onClick={() => {
                                setNewElementClick(Date.now());
                              }}
                            >
                              Add element
                            </Button>
                          ) : (
                            <Typography
                              className={classes.flexCenterDesc}
                            >
                              This station has no elements in it.
                              <br />
                              Please create some before adding them to
                              the layout.
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    )}
                  {!noStationOutline &&
                    mapActive &&
                    !mappedAreas.length && (
                      <>
                        {!isDrawingStationFinished && (
                          <Box className={classes.flexCenterRow}>
                            <Box className={classes.notificationBox}>
                              <Typography
                                style={{ fontWeight: 'bold' }}
                              >
                                Locate station in map
                              </Typography>
                              <Typography>
                                Browse the map and position the
                                station so it fits exactly in the map
                                and click the "Draw Outline" button to
                                start drawing.
                              </Typography>
                            </Box>
                          </Box>
                        )}
                        {isDrawingStationFinished && (
                          <Box className={classes.flexCenterRow}>
                            <Box className={classes.notificationBox}>
                              <Typography
                                style={{ fontWeight: 'bold' }}
                              >
                                The shape looks complete
                              </Typography>
                              <Typography>
                                You can still drag points to adjust
                                the measurements. When done, click
                                "Create"
                              </Typography>
                            </Box>
                          </Box>
                        )}
                      </>
                    )}
                  {!noStationOutline && (
                    <div
                      className={classes.mainContainer}
                      ref={containerRef}
                    >
                      {konvaActive && (
                        <>
                          {!mapActive && (
                            <>
                              <div
                                className={classes.compassContainer}
                              >
                                <CircularSlider
                                  width={64}
                                  min={0}
                                  max={360}
                                  dataIndex={stageRotation}
                                  direction={1}
                                  knobPosition='top'
                                  knobColor={colors.gold}
                                  knobSize={24}
                                  valueFontSize='0'
                                  trackColor={colors.lightGray}
                                  trackSize={5}
                                  label=''
                                  labelColor={colors.black}
                                  onChange={compassChange}
                                  verticalOffset='0.5rem'
                                >
                                  <></>
                                </CircularSlider>
                              </div>
                              <div
                                className={classes.gridInfoContainer}
                              >
                                <Checkbox
                                  onChange={(e) => {
                                    setGridVisible(!gridVisible);
                                  }}
                                  checked={gridVisible}
                                  color='primary'
                                  inputProps={{
                                    'aria-label': 'Show Grid',
                                  }}
                                  style={{ paddingRight: 0 }}
                                />{' '}
                                Show Grid{' '}
                                {gridVisible && (
                                  <>
                                    <Box
                                      className={classes.gridScale}
                                      style={{
                                        width:
                                          5 /
                                            (metersPerPixel *
                                              METERS_TO_YARDS) +
                                          'px',
                                      }}
                                    ></Box>{' '}
                                    5 yards
                                    <Checkbox
                                      onChange={(e) => {
                                        setGridDraggable(
                                          !gridDraggable,
                                        );
                                      }}
                                      checked={gridDraggable}
                                      color='primary'
                                      inputProps={{
                                        'aria-label':
                                          'Grid Draggable',
                                      }}
                                      style={{ paddingRight: 0 }}
                                    />{' '}
                                    Grid Draggable
                                  </>
                                )}
                              </div>
                            </>
                          )}
                          <div
                            className={classes.konvaContainer}
                            style={
                              mapActive
                                ? {
                                    backgroundColor:
                                      'rgba(255,255,255,0.2)',
                                  }
                                : {}
                            }
                            tabIndex={1}
                            onKeyDown={(e: any) => {
                              e.key !== 'Shift' &&
                                e.key !== 'Control' &&
                                listenForKeystrokes(e);
                            }}
                          >
                            <Stage
                              fill='#f0f0f0'
                              width={window.innerWidth}
                              height={window.innerHeight}
                              onMouseDown={handleClick}
                              onMouseMove={debounce(
                                (e: KonvaEventObject<MouseEvent>) => {
                                  if (
                                    isDrawingStation &&
                                    !isDrawingStationFinished &&
                                    stationOutlinePoints.length > 0
                                  ) {
                                    const snapOffset = 10;
                                    let { offsetX, offsetY } = e.evt;

                                    const lastPoint =
                                      stationOutlinePoints[
                                        stationOutlinePoints.length -
                                          1
                                      ];

                                    if (
                                      offsetX >
                                        lastPoint[0] - snapOffset &&
                                      offsetX <
                                        lastPoint[0] + snapOffset
                                    ) {
                                      offsetX = lastPoint[0];
                                    }

                                    if (
                                      offsetY >
                                        lastPoint[1] - snapOffset &&
                                      offsetY <
                                        lastPoint[1] + snapOffset
                                    ) {
                                      offsetY = lastPoint[1];
                                    }

                                    setCursorPosition([
                                      offsetX,
                                      offsetY,
                                    ]);
                                  }
                                },
                                5,
                              )}
                              scale={{
                                x: stageScale,
                                y: stageScale,
                              }}
                              x={stagePosition.x}
                              y={stagePosition.y}
                              offset={stageOffset}
                              onWheel={handleScroll}
                              draggable={
                                !gridDraggable &&
                                !mapActive &&
                                hasWritePermission
                              }
                              onDragEnd={(e) => {
                                const { x, y } =
                                  e.currentTarget.attrs;
                                if (x && y)
                                  setStagePosition({
                                    x,
                                    y,
                                  });
                              }}
                              style={
                                isDrawingStation &&
                                !isDrawingStationFinished
                                  ? { cursor: 'crosshair' }
                                  : undefined
                              }
                            >
                              <Layer rotation={stageRotation}>
                                {isDrawingStation &&
                                !isDrawingStationFinished &&
                                stationOutlinePoints.length > 0 &&
                                cursorPosition[0] !== 0 ? (
                                  <Line
                                    points={[
                                      ...stationOutlinePoints[
                                        stationOutlinePoints.length -
                                          1
                                      ],
                                      ...cursorPosition,
                                    ]}
                                    stroke={colors.red}
                                    strokeWidth={2}
                                    dash={[2, 5]}
                                  />
                                ) : undefined}
                                <Line
                                  points={flattenPoints(
                                    stationOutlinePoints,
                                  )}
                                  stroke='black'
                                  strokeWidth={3}
                                  closed={isDrawingStationFinished}
                                  fill={'#fcfcfc'}
                                  draggable={
                                    mapActive &&
                                    isDrawingStationFinished &&
                                    hasWritePermission
                                  }
                                  onDragStart={() => {
                                    setShowPoints(false);
                                  }}
                                  onDragEnd={(
                                    e: KonvaEventObject<DragEvent>,
                                  ) => {
                                    setShowPoints(true);

                                    const { x, y } =
                                      e.currentTarget.attrs;

                                    const updatedPoints =
                                      stationOutlinePoints.map(
                                        (pair) => [
                                          pair[0] + Number(x),
                                          pair[1] + Number(y),
                                        ],
                                      );

                                    setStationOutlinePoints(
                                      updatedPoints,
                                    );

                                    //reset x and y after points update
                                    e.currentTarget.setAttrs({
                                      x: 0,
                                      y: 0,
                                    });
                                  }}
                                />

                                {showPoints &&
                                  stationOutlinePoints.map(
                                    (point, index) => {
                                      const width = 6;
                                      const x = point[0];
                                      const y = point[1];
                                      const startPointAttr =
                                        index === 0
                                          ? {
                                              onMouseOver:
                                                handleMouseOverStartPoint,
                                              onMouseOut:
                                                handleMouseOutStartPoint,
                                            }
                                          : null;
                                      return (
                                        <Circle
                                          key={
                                            'STATION_OUTLINE_POINT_' +
                                            index
                                          }
                                          x={x}
                                          y={y}
                                          radius={width}
                                          fill='white'
                                          stroke='black'
                                          strokeWidth={2}
                                          onDragMove={debounce(
                                            (e) => {
                                              //tiny debounce seems to improve performance / reduce lag
                                              handleDragMovePoint(e);
                                            },
                                            5,
                                          )}
                                          draggable
                                          {...startPointAttr}
                                        />
                                      );
                                    },
                                  )}

                                {mappedAreas &&
                                  mappedAreas.map((shape) => (
                                    <TransformablePolygon
                                      key={'AREA_' + shape.id}
                                      label={
                                        configurationUtils.getPageTitle(
                                          true,
                                          'AREA',
                                        ) +
                                        ' ' +
                                        shape.name
                                      }
                                      innerRef={shape.ref}
                                      metersPerPixel={metersPerPixel}
                                      draggingItem={draggingItem}
                                      draggable={
                                        currentView === 'VIEW' &&
                                        selectedItem?.type ===
                                          'AREA' &&
                                        selectedItem?.id ===
                                          shape.id &&
                                        hasWritePermission
                                      }
                                      setDraggingItem={(item) => {
                                        if (item)
                                          setDraggingItem({
                                            id: shape.id,
                                            type: 'AREA',
                                          });
                                        else
                                          setDraggingItem(undefined);
                                      }}
                                      points={shape.points}
                                      pointsNoAngleOffset={
                                        shape.pointsNoAngleOffset
                                      }
                                      dragBounds={
                                        stationOutlinePoints
                                      }
                                      stageScale={stageScale}
                                      stageRotation={stageRotation}
                                      stagePosition={stagePosition}
                                      stageOffset={stageOffset}
                                      id={shape.id}
                                      fill={colors.gold}
                                      rotation={shape.rotation}
                                      isSelected={
                                        selectedItem
                                          ? selectedItem.type ===
                                              'AREA' &&
                                            selectedItem.id ===
                                              shape.id
                                          : false
                                      }
                                      onSelect={(id: number) => {
                                        setSelectedItem({
                                          type: 'AREA',
                                          id,
                                        });
                                        setTransformingItem(
                                          undefined,
                                        );
                                      }}
                                      onMove={(
                                        x: number,
                                        y: number,
                                      ) => {
                                        onAreaMove(shape.id, x, y);
                                      }}
                                      onTransform={(
                                        rotation: number,
                                        scaleX: number,
                                        scaleY: number,
                                      ) => {
                                        onAreaTransform(
                                          shape,
                                          rotation,
                                          scaleX,
                                          scaleY,
                                        );
                                      }}
                                    />
                                  ))}

                                {mappedZones &&
                                  mappedZones.map((shape) => (
                                    <TransformablePolygon
                                      key={'ZONE_' + shape.id}
                                      label={
                                        configurationUtils.getPageTitle(
                                          true,
                                          'ZONE',
                                        ) +
                                        ' ' +
                                        shape.name
                                      }
                                      labelColor={colors.black}
                                      innerRef={shape.ref}
                                      metersPerPixel={metersPerPixel}
                                      points={shape.points}
                                      pointsNoAngleOffset={
                                        shape.pointsNoAngleOffset
                                      }
                                      dragBounds={
                                        mappedAreas.find(
                                          (area) =>
                                            area.id === shape.area,
                                        )!.pointsNoAngleOffset
                                      }
                                      stageScale={stageScale}
                                      stageRotation={stageRotation}
                                      stagePosition={stagePosition}
                                      stageOffset={stageOffset}
                                      draggable={
                                        currentView === 'VIEW' &&
                                        selectedItem?.type ===
                                          'ZONE' &&
                                        selectedItem?.id ===
                                          shape.id &&
                                        hasWritePermission
                                      }
                                      draggingItem={draggingItem}
                                      type={'ZONE'}
                                      rotation={shape.rotation}
                                      parentArea={shape.area}
                                      setDraggingItem={(item) => {
                                        if (item)
                                          setDraggingItem({
                                            id: shape.id,
                                            type: 'ZONE',
                                          });
                                        else
                                          setDraggingItem(undefined);
                                      }}
                                      id={shape.id}
                                      fill={'#e5e5e5'}
                                      isSelected={
                                        selectedItem
                                          ? selectedItem.type ===
                                              'ZONE' &&
                                            selectedItem.id ===
                                              shape.id
                                          : false
                                      }
                                      onSelect={(id: number) => {
                                        setSelectedItem({
                                          type: 'ZONE',
                                          id,
                                        });
                                        setTransformingItem(
                                          undefined,
                                        );
                                      }}
                                      onMove={(
                                        x: number,
                                        y: number,
                                      ) => {
                                        onZoneMove([shape.id], x, y);
                                      }}
                                      onTransform={(
                                        rotation: number,
                                        scaleX: number,
                                        scaleY: number,
                                      ) => {
                                        onZoneTransform(
                                          [shape],
                                          rotation,
                                          scaleX,
                                          scaleY,
                                        );
                                      }}
                                    />
                                  ))}

                                {mappedLoadpoints &&
                                  mappedLoadpoints.map((shape) => (
                                    <TransformablePolygon
                                      key={'LOADPOINT_' + shape.id}
                                      label={shape.name || ''}
                                      innerRef={shape.ref}
                                      metersPerPixel={metersPerPixel}
                                      points={shape.points}
                                      pointsNoAngleOffset={
                                        shape.pointsNoAngleOffset
                                      }
                                      dragBounds={
                                        mappedZones.find(
                                          (zone) =>
                                            zone.id === shape.zone,
                                        )!.pointsNoAngleOffset
                                      }
                                      containerType={
                                        shape.containerType
                                      }
                                      stageScale={stageScale}
                                      stageRotation={stageRotation}
                                      stagePosition={stagePosition}
                                      stageOffset={stageOffset}
                                      draggable={
                                        currentView === 'VIEW' &&
                                        selectedItem?.type ===
                                          'LOADPOINT' &&
                                        selectedItem?.id ===
                                          shape.id &&
                                        hasWritePermission
                                      }
                                      draggingItem={draggingItem}
                                      type={'LOADPOINT'}
                                      rotation={shape.rotation}
                                      parentArea={shape.area}
                                      parentZone={shape.zone}
                                      setDraggingItem={(item) => {
                                        if (item)
                                          setDraggingItem({
                                            id: shape.id,
                                            type: 'LOADPOINT',
                                          });
                                        else
                                          setDraggingItem(undefined);
                                      }}
                                      id={shape.id}
                                      fill={
                                        shape.isActive
                                          ? colors.gray
                                          : colors.veryLightGray
                                      }
                                      isSelected={
                                        selectedItem
                                          ? selectedItem.type ===
                                              'LOADPOINT' &&
                                            selectedItem.id ===
                                              shape.id
                                          : false
                                      }
                                      onSelect={(id: number) => {
                                        setSelectedItem({
                                          type: 'LOADPOINT',
                                          id,
                                        });
                                        setTransformingItem(
                                          undefined,
                                        );
                                      }}
                                      onMove={(
                                        x: number,
                                        y: number,
                                      ) => {
                                        onLPMove([shape.id], x, y);
                                      }}
                                      onTransform={(
                                        rotation: number,
                                        scaleX: number,
                                        scaleY: number,
                                      ) => {
                                        onLPTransform(
                                          [shape],
                                          rotation,
                                          scaleX,
                                          scaleY,
                                        );
                                      }}
                                    />
                                  ))}

                                {mappedDockdoors &&
                                  mappedDockdoors.map((shape) => (
                                    <TransformablePolygon
                                      key={'DOCKDOOR' + shape.id}
                                      label={
                                        configurationUtils.getPageTitle(
                                          true,
                                          'DOCK_DOOR',
                                        ) +
                                        ' ' +
                                        shape.name
                                      }
                                      innerRef={shape.ref}
                                      metersPerPixel={metersPerPixel}
                                      points={shape.points}
                                      pointsNoAngleOffset={
                                        shape.pointsNoAngleOffset
                                      }
                                      stageScale={stageScale}
                                      stageRotation={stageRotation}
                                      stagePosition={stagePosition}
                                      stageOffset={stageOffset}
                                      dragBounds={
                                        stationOutlinePoints
                                      }
                                      draggable={
                                        currentView === 'VIEW' &&
                                        selectedItem?.type ===
                                          'DOCKDOOR' &&
                                        selectedItem?.id ===
                                          shape.id &&
                                        hasWritePermission
                                      }
                                      draggingItem={draggingItem}
                                      type={'DOCKDOOR'}
                                      rotation={shape.rotation}
                                      setDraggingItem={(item) => {
                                        if (item)
                                          setDraggingItem({
                                            id: shape.id,
                                            type: 'DOCKDOOR',
                                          });
                                        else
                                          setDraggingItem(undefined);
                                      }}
                                      id={shape.id}
                                      fill={'#999999'}
                                      isSelected={
                                        selectedItem
                                          ? selectedItem.type ===
                                              'DOCKDOOR' &&
                                            selectedItem.id ===
                                              shape.id
                                          : false
                                      }
                                      onSelect={(id: number) => {
                                        setSelectedItem({
                                          type: 'DOCKDOOR',
                                          id,
                                        });
                                        setTransformingItem(
                                          undefined,
                                        );
                                      }}
                                      onMove={(
                                        x: number,
                                        y: number,
                                      ) => {
                                        onDockdoorMove(
                                          shape.id,
                                          x,
                                          y,
                                        );
                                      }}
                                      onTransform={(
                                        rotation: number,
                                        scaleX: number,
                                        scaleY: number,
                                      ) => {
                                        onDockdoorTransform(
                                          shape,
                                          rotation,
                                          scaleX,
                                          scaleY,
                                        );
                                      }}
                                    />
                                  ))}

                                {currentView === 'NODES' &&
                                mappedAreas.length &&
                                mappedZones.length &&
                                mappedLoadpoints.length ? (
                                  <Connections
                                    selectedElement={selectedItem}
                                    mappedAreas={mappedAreas}
                                    mappedZones={mappedZones}
                                    mappedLoadpoints={
                                      mappedLoadpoints
                                    }
                                  />
                                ) : null}

                                <PackageCounts
                                  currentView={currentView}
                                  mappedAreas={mappedAreas}
                                  mappedZones={mappedZones}
                                  mappedLoadpoints={mappedLoadpoints}
                                  mappedDockdoors={mappedDockdoors}
                                  countOrPercentage={
                                    countOrPercentage
                                  }
                                />

                                {transformingItem && (
                                  <Transformer
                                    resizeEnabled={true}
                                    rotateEnabled={true}
                                    flipEnabled={false}
                                    enabledAnchors={[
                                      'bottom-center',
                                      'bottom-right',
                                      'middle-right',
                                    ]}
                                    ignoreStroke={true}
                                    ref={trRef}
                                    borderDash={[2, 3]}
                                    anchorCornerRadius={5}
                                    anchorStroke={colors.darkGold}
                                    borderStroke={colors.darkGold}
                                  />
                                )}
                              </Layer>
                              {gridVisible && (
                                <GridGuide
                                  clipingMaskShape={rotateShape(
                                    stationOutlinePoints,
                                    stageRotation,
                                  )}
                                  gridDraggable={gridDraggable}
                                  stageScale={stageScale}
                                  metersPerPixel={metersPerPixel}
                                />
                              )}
                            </Stage>
                          </div>
                        </>
                      )}

                      {mapActive && (
                        <GoogleMapReact
                          draggable={true}
                          yesIWantToUseGoogleMapApiInternals
                          onGoogleApiLoaded={({ map, maps, ref }) => {
                            getStationCoordinates();

                            setMapObject(map);
                            setMapDivRef(ref);
                          }}
                          bootstrapURLKeys={{
                            key: 'AIzaSyAGt3pyoNK_lEJKzGqF9Lemc-Jwo8GoPx4',
                            libraries: ['places', 'geometry'],
                          }}
                          options={{
                            disableDefaultUI: true,
                            zoomControl: true,
                            mapTypeControl: false,
                          }}
                          center={mapCoordinates}
                          zoom={mapZoom}
                          onZoomAnimationStart={(zoomLevel) => {
                            setMapZoom(zoomLevel);
                          }}
                        ></GoogleMapReact>
                      )}
                    </div>
                  )}
                </>
              )}
              {currentViewMode === 'THROUGHPUT' && (
                <div className={classes.mainContainer}>
                  {initLoadComplete && (
                    <Flow
                      mappedAreas={mappedAreas}
                      mappedDockdoors={mappedDockdoors}
                      mappedZones={mappedZones}
                      mappedLoadpoints={mappedLoadpoints}
                    />
                  )}
                </div>
              )}
            </Grid>
            {!noStationOutline &&
              currentViewMode !== 'THROUGHPUT' &&
              renderSidebar()}
          </Box>
        </Grid>
      </Layout>
    </>
  );
};

export default withStyles(styles)(StationLayout);
