import {
  createStyles,
  Theme,
  useTheme,
  withStyles,
} from '@material-ui/core';
import React from 'react';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Typography from '../../primitives/typography/Typography';
import moment from 'moment';
import { switcher } from 'rambdax';

type PropsWithText = {
  classes: { [key: string]: string };
  prediction: boolean | null | undefined;
  withText: boolean;
  arrivalTimestamp: string | null | undefined;
};

type PropsWithOutText = {
  classes: { [key: string]: string };
  prediction: boolean | null | undefined;
  withText?: never;
  arrivalTimestamp?: never;
};

const PackagePrediction: React.FC<PropsWithOutText | PropsWithText> =
  ({ classes, prediction = null, withText, arrivalTimestamp }) => {
    const theme = useTheme();

    const getColor = (prediction: boolean | null) =>
      switcher<string>(prediction)
        .is(true, theme.palette.success.main)
        .is(false, theme.palette.error.main)
        .default(theme.palette.grey[300]);
    const getText = (prediction: boolean | null) =>
      switcher<string>(prediction)
        .is(
          true,
          'Arriving at ' +
            moment(arrivalTimestamp).format('MM/DD/YYYY, hh:mm A'),
        )
        .is(false, 'Not arriving')
        .default('N/A');

    return (
      <div className={classes.packagePredictionWrapper}>
        <FiberManualRecordIcon
          style={{
            color: getColor(prediction),
          }}
        />
        {withText && (
          <Typography
            variant='body2'
            color={{ color: 'text', variant: 'disabled' }}
          >
            {getText(prediction)}
          </Typography>
        )}
      </div>
    );
  };

export default withStyles(
  createStyles((theme: Theme) => ({
    packagePredictionWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
  })),
)(PackagePrediction);
