import React, { useEffect, useMemo, useState } from 'react';
import { withStyles, useTheme } from '@material-ui/core/styles';
import Layout from '../layout/Layout';
import PaginatedTable, {
  filterObj,
} from '../paginatedTable/PaginatedTable';
import { AuthRoutes } from '../../interfaces/routes';
import Box from '@material-ui/core/Box';
import { AlertBanner, CSButton, Typography } from '../primitives';
import { createStyles, Theme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import detailsPageStyles from '../commonStyles/detailsPage.style';
import sectionPageBaseStyle from '../commonStyles/sectionPageBase.style';
import configurationUtils from '../../utils/configurationUtils';
import UploadCSVDialog from '../uploadCSVDialog/UploadCSVDialog';
import { Grid } from '@material-ui/core';
import ErrorHandler from '../../utils/ErrorHandler';

// Types
import { Column } from '../../interfaces/components';
import { StaffPermissionPermissionsEnum } from 'cloudsort-client';
import { AxiosError } from 'axios';

// Services
import LoadPlansService from '../../services/LoadPlans.service';
import PermissionsService from '../../services/Permissions.service';

// Icons
import CancelIcon from '@material-ui/icons/Cancel';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import ConfirmationDialog from '../confirmationDialog/ConfirmationDialog';
import { Helmet } from 'react-helmet';
import CSBreadcrumbs from '../primitives/CSBreadcrumbs/CSBreadcrumbs';
import EphemeralStateService from '../../services/EphemeralState.service';
import CSSectionTitleSeparator from '../primitives/csSectionTitleSeparator/CSSectionTitleSeparator';

interface Props {
  classes: { [key: string]: string };
}

const LoaplansList: React.FC<Props> = ({ classes }) => {
  const [showUploadDialog, setShowUploadDialog] =
    useState<boolean>(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>();
  const [lastUpdated, setLastUpdated] = useState<string>(
    new Date().toISOString(),
  );
  const [error, setError] = useState<string>();

  const loadlanLabels = useMemo(() => {
    return {
      singular: configurationUtils.getPageTitle(true, 'LOADPLAN'),
      plural: configurationUtils.getPageTitle(false, 'LOADPLAN'),
    };
  }, []);

  // Get labels on each render cycle
  const COLUMNS_LOADPLANS_LIST: Column[] = [
    {
      id: 'id',
      label: loadlanLabels.singular + ' ID',
      align: 'left',
    },
    {
      id: 'total_zipcodes',
      label: 'Total ZIP Codes',
      align: 'left',
    },
    {
      id: 'total_stops',
      label: `Total ${configurationUtils.getPageTitle(
        false,
        'STOP',
      )}`,

      align: 'left',
    },
    {
      id: 'active',
      label: 'Status',
      align: 'left',
    },
    {
      id: 'imported_by',
      label: 'Imported by',
      align: 'left',
    },
    { id: 'is_inactive_loadplan', label: '', hide: true },
  ];

  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e as AxiosError));
  };

  const fetchTableData = async (
    pageIndex: number,
    rowsPerPage: number,
    filterOptions?: filterObj[],
    filterByString?: string,
    sortedBy?: string,
  ) => {
    const res = await LoadPlansService.getAll({
      page: pageIndex,
      pageSize: rowsPerPage,
    });

    res.data.results.map((lp: any) => {
      lp.is_inactive_loadplan = lp.active ? false : true;
      // TODO: This might be related to the Dangerously innerHTML from the table and should be reviewed
      lp.active = lp.active
        ? `<span style="color: ${theme.palette.tertiary?.main}">Active</span>`
        : 'Inactive';

      lp.imported_by =
        lp.created_by_name +
        ' at ' +
        new Date(lp.created_on).toLocaleString();

      return lp;
    });
    return res;
  };

  const processDelete = async () => {
    setShowDeleteConfirmation(false);

    try {
      await LoadPlansService.delete(deleteId!);
      setLastUpdated(new Date().toISOString());
    } catch (e) {
      handleError(e as AxiosError);
    }
  };

  const theme = useTheme();
  const inXsScreen = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    PermissionsService.redirectIfNoPermission(
      StaffPermissionPermissionsEnum.LOADPLAN_READ,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>{`CloudSort - ${loadlanLabels.plural}`}</title>
      </Helmet>
      <Layout navCurrent='LOAD_PLAN'>
        <UploadCSVDialog
          isOpen={showUploadDialog}
          onAfterClose={() => {
            setShowUploadDialog(false);
          }}
          updateParent={() => {
            setLastUpdated(new Date().toISOString());
          }}
        />
        <ConfirmationDialog
          data-testid={'delete-confirmation-dialog'}
          title={'Delete ' + loadlanLabels.singular}
          msg={`Are you sure you want to delete ${loadlanLabels.singular} ${deleteId}?`}
          primaryActionLabel='Confirm'
          onPrimaryAction={() => {
            processDelete();
          }}
          cancelLabel='Cancel'
          onCancel={() => {
            setShowDeleteConfirmation(false);
          }}
          isOpen={showDeleteConfirmation}
        />
        <>
          <Grid container>
            <Grid item xs={12}>
              <Box mb={5}>
                <CSBreadcrumbs
                  breadcrumbs={[
                    {
                      label: configurationUtils.getPageTitle(
                        false,
                        'STOP',
                      ),
                      link:
                        AuthRoutes.STOP +
                        '?stationId=' +
                        EphemeralStateService.getMyStationId(),
                    },
                    {
                      label: loadlanLabels.plural,
                      selected: true,
                    },
                  ]}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant='h3' component='h1'>
                {loadlanLabels.plural}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              className={classes.nonMobileAlignRight}
            >
              {PermissionsService.hasPermission(
                StaffPermissionPermissionsEnum.LOADPLAN_WRITE,
              ) && (
                <CSButton
                  data-testid={'add-new-load-plan-dialog-btn'}
                  variant='contained'
                  color={{
                    buttonColor: 'secondary',
                    iconColor: 'primary',
                  }}
                  style={{
                    marginLeft: 15,
                    height: inXsScreen ? 'fit-content' : 42,
                  }}
                  onClick={() => {
                    setShowUploadDialog(true);
                  }}
                  startIcon={<AddBoxOutlinedIcon />}
                >
                  Add {loadlanLabels.singular}
                </CSButton>
              )}
            </Grid>
            <Grid item xs={12}>
              <CSSectionTitleSeparator topMargin={8} />
            </Grid>
          </Grid>

          {error && (
            <AlertBanner
              className={classes.banner}
              severity='error'
              alertTitle={'Error'}
              alertMsg={error}
            />
          )}

          <PaginatedTable
            disableUpdateQueryStringUrl
            key={lastUpdated}
            title={''}
            columns={COLUMNS_LOADPLANS_LIST}
            dataTestIdPrefix={'load-plans'}
            rowsLoadDetailPages={true}
            detailsPageBasePath={AuthRoutes.LOADPLAN}
            fetch={fetchTableData}
            actions={[
              {
                tableLabel: PermissionsService.hasPermission(
                  'LOADPLAN_WRITE',
                )
                  ? ' '
                  : undefined,
                columnLabel: PermissionsService.hasPermission(
                  'LOADPLAN_WRITE',
                ) ? (
                  <CancelIcon className={classes.actionIcon} />
                ) : undefined,
                callbackProperty: 'id',
                qualifier: 'is_inactive_loadplan',
                callback: (id: number) => {
                  setDeleteId(id);
                  setError(undefined);
                  setShowDeleteConfirmation(true);
                },
              },
            ]}
          />
        </>
      </Layout>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...sectionPageBaseStyle,
    nonMobileAlignRight: {
      [theme.breakpoints.up('xs')]: {
        textAlign: 'right',
      },
    },
    actionIcon: {
      width: 16,
      height: 'auto',
    },
  })),
)(LoaplansList);
