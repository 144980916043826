import React, { Dispatch, useEffect, useState } from 'react';
import enumToLabel from '../../../../utils/enumToLabel';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Box, Grid, Divider, Theme } from '@material-ui/core';
import detailsPageStyles from '../../../commonStyles/detailsPage.style';
import configPageStyles from '../../../commonStyles/configPage.style';
import classNames from 'classnames';
import Highlighter from 'react-highlight-words';
import { CurrentView, ModuleName } from '../Modules';
import { processSearchQuery } from '../searchQuery';
import { INTERACTION } from '../../utils';
// Redux
import { useSearchResultSections } from '../../useSearchResultSections';
import { CSSwitch } from '../../../primitives';

interface Props {
  classes: { [key: string]: string };
  data: any;
  interaction?: INTERACTION;
  setPartialData: Dispatch<any>;
  currentView: CurrentView;
}

const MachineLearningIntegration: React.FC<Props> = ({
  classes,
  data,
  interaction,
  setPartialData,
  currentView,
}) => {
  const [mlModulesData, setMlModulesData] = useState<{
    [key: string]: boolean;
  }>({});
  const [searchResult, setSearchResult] = useState<string[]>([]);
  const [showWholeSection, setShowWholeSection] =
    useState<boolean>(true);

  const SECTION_TITLE = 'Artificial Intelligence';

  const { searchQuery, updateSections } = useSearchResultSections(
    ModuleName.GENERAL,
    SECTION_TITLE,
  );

  useEffect(
    function setTheMlModulesData() {
      if (data && currentView !== CurrentView.SEARCH) {
        setMlModulesData(data);
      }
    },
    [data, currentView],
  );

  useEffect(
    function filterModuleDataBySearchResults() {
      if (currentView === CurrentView.SEARCH && data) {
        const tmpData: {
          [key: string]: boolean;
        } = {};
        for (let key of Object.keys(data)) {
          if (
            showWholeSection ||
            searchResult.some((result) =>
              enumToLabel(key)
                .toLowerCase()
                .includes(result.toLowerCase()),
            )
          )
            tmpData[key] = data[key];
        }
        setMlModulesData(tmpData);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, searchResult],
  );

  useEffect(
    function processSearch() {
      if (currentView === CurrentView.SEARCH) {
        //Show whole section is there are results in a title
        setShowWholeSection(
          processSearchQuery(searchQuery, [SECTION_TITLE]).length !==
            0,
        );

        const dynamicLabels: string[] = [];
        for (let key of Object.keys(data)) {
          dynamicLabels.push(enumToLabel(key));
        }
        const results = processSearchQuery(searchQuery, [
          SECTION_TITLE,
          ...dynamicLabels,
        ]);

        setSearchResult(results);

        updateSections(results);
      } else {
        setSearchResult([]);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchQuery, currentView],
  );

  const renderComponent = () => {
    return (
      <Grid item sm={12}>
        <Box>
          <Box
            className={classNames(classes.moduleTitle, classes.pLeft)}
            mb={1}
            mt={2}
          >
            <Highlighter
              highlightClassName={classes.searchResultsMark}
              searchWords={searchResult}
              autoEscape={true}
              textToHighlight={SECTION_TITLE}
            />
          </Box>
          <Box mt={2}>
            <Box mb={2}>
              <Grid
                container
                item
                sm={12}
                className={classes.textHeader}
              >
                <Grid item sm={11} xs={9} className={classes.pLeft}>
                  Module
                </Grid>
                <Grid item sm={1} xs={3}>
                  Active
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider className={classes.divider} />
              </Grid>
            </Box>
            {Object.keys(mlModulesData).map(
              (mlmodule, index, array) => {
                return (
                  <Grid
                    container
                    item
                    sm={12}
                    className={classes.row}
                    key={mlmodule}
                  >
                    <Grid
                      item
                      sm={11}
                      xs={9}
                      className={classNames(
                        classes.text,
                        classes.pLeft,
                      )}
                    >
                      <Highlighter
                        highlightClassName={classes.searchResultsMark}
                        searchWords={searchResult}
                        autoEscape={true}
                        textToHighlight={enumToLabel(mlmodule)}
                      />
                    </Grid>
                    <Grid
                      item
                      sm={1}
                      xs={3}
                      className={classes.muiSwitchHoler}
                    >
                      <CSSwitch
                        data-testid={`${SECTION_TITLE}-switch-${enumToLabel(
                          mlmodule,
                        )}`}
                        color='default'
                        size='small'
                        disabled={interaction === INTERACTION.READ}
                        className={
                          mlModulesData[mlmodule]
                            ? classes.muiSwitch
                            : classes.muiSwitchOff
                        }
                        checked={mlModulesData[mlmodule]}
                        onChange={() => {
                          mlModulesData[mlmodule] =
                            !mlModulesData[mlmodule];
                          setPartialData({
                            ...mlModulesData,
                          });
                        }}
                      />
                    </Grid>
                    {index !== array.length - 1 && (
                      <Grid item xs={12}>
                        <Divider className={classes.divider} />
                      </Grid>
                    )}
                  </Grid>
                );
              },
            )}
          </Box>
        </Box>
      </Grid>
    );
  };

  return currentView === CurrentView.SEARCH && !searchResult.length
    ? null
    : renderComponent();
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyles,
    ...configPageStyles(theme),
  })),
)(MachineLearningIntegration);
