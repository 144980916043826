import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import ErrorHandler from '../../utils/ErrorHandler';
import ProgressIndicator from '../progressIndicator/ProgressIndicator';
import {
  Box,
  Drawer,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import { AlertBanner, CSButton } from '../primitives';
import detailsPageStyle from '../commonStyles/detailsPage.style';
import filterBadgeStyle from './filterBadge.style';
import clx from 'classnames';
import configurationUtils from '../../utils/configurationUtils';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';
import browserHistory from '../../utils/browserHistory';
import { formatLabel } from '../containers/Containers';
import {
  AuthRoutes,
  stationDashboardUrlId,
} from '../../interfaces/routes';
import AsyncSelect from 'react-select/async';
import asyncSelectStyles from '../asyncSelect/asyncSelect.styles';
import { noOptionsMessage } from '../../components/asyncSelect/utils';
import { components } from 'react-select';

//Redux
import { useAppSelector, useAppDispatch } from '../../redux/store';
import {
  selectGlobalFilters,
  updateFiltersOptions,
  updateFiltersState,
} from '../../redux/slices/filtersSlice';

//Types
import { AxiosError } from 'axios';
import {
  Route,
  User,
  Location,
  Area,
  ContainerContainerTypeEnum,
  Customer,
  InboundLoadLoadStatusEnum,
  Organization,
  StaffPermissionRoleTypeEnum,
  InboundLoad,
} from 'cloudsort-client';
import { TypeAheadItem } from '../../interfaces/components';

// Icons
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

// Services
import FmcService from '../../services/Fmc.service';
import CustomersService from '../../services/Customers.service';
import StationsService from '../../services/Stations.service';
import RoutesService from '../../services/Routes.service';
import AreasService from '../../services/Areas.service';
import EphemeralStateService from '../../services/EphemeralState.service';
import OrganizationsService from '../../services/Organizations.service';
import { MAX_PAGE_SIZE } from '../../services/utils/constants';
import { ROLE_LABELS } from '../stationStaff/StationStaff';
import { Cancel } from '@material-ui/icons';
import { switcher } from 'rambdax';
import omit from 'lodash/omit';
import InboundLoadsService from '../../services/InboundLoads.service';

interface Props {
  classes: { [key: string]: string };
  onAfterClose: () => void;
  isOpen: boolean;
  removeFilter?: { filterKey: string; filterValue: string | number };
  getFilters?: (filters: SelectedFilters) => void;
}
type availablePages =
  | 'dashboard'
  | 'containers'
  | 'held_packages'
  | 'packages'
  | 'loads'
  | 'manifests'
  | 'inbound_loads'
  | 'webhooks'
  | 'staff'
  | 'stops'
  | 'devices'
  | 'area_settings'
  | 'configuration_staff'
  | '';

export interface FilterItem {
  key: string;
  label: string;
  visible: boolean;
  timeAdded: number;
  values: {
    id: string | number;
    name?: string;
    full_name?: string;
  }[];
}
export interface SelectedFilters {
  [key: string]: FilterItem;
}

export interface AvailableFilter {
  key: string;
  label: string;
  options: (
    | User
    | Route
    | Location
    | Area
    | { id: string | number; name: string }
  )[];
  singleSelection: boolean;
  async?: boolean;
}

const getStationAndOrgId = (
  page: availablePages,
  EphemeralState: {
    getMyOrgId: () => number | undefined;
    getMyStationId: () => number | undefined;
  },
) => {
  // When fetching webhooks, we should avoid filtering by station and org filters.
  // The items of webhooks page are independend of the station/organization.
  if (page === 'webhooks') {
    return {};
  }

  return {
    stationId: EphemeralState.getMyStationId(),
    organizationId: EphemeralState.getMyOrgId(),
  };
};

export const createFiltersQuery = (
  passedInFilters?: SelectedFilters,
  excludeFilters: string[] = [],
) => {
  return passedInFilters
    ? Object.entries(passedInFilters!)
        .map((item) => item[1])
        .filter(
          (item: FilterItem) => !excludeFilters.includes(item.key),
        )
        .reduce(
          (obj: any, item: any) => {
            if (item.values.length)
              return {
                ...obj,
                [item.key]: item.values
                  .map((v: any) => v.id)
                  .join(','),
              };
            else return obj;
          },
          {
            [stationDashboardUrlId]:
              EphemeralStateService.getMyStationId().toString(),
          },
        )
    : [];
};

export const getAppliedFilters = (
  passedInFilters?: SelectedFilters,
  excludeFilters: string[] = [],
): FilterItem[] => {
  return passedInFilters
    ? Object.entries(passedInFilters!)
        .map((item) => item[1])
        .filter(
          (item: FilterItem) =>
            !excludeFilters.includes(item.key) && item.values.length,
        )
    : [];
};

const FiltersDrawer: React.FC<Props> = ({
  isOpen,
  classes,
  onAfterClose,
  removeFilter,
  getFilters,
}) => {
  const [open, setOpen] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [error, setError] = useState<string>();
  const handleError = async (e: AxiosError) => {
    setError(await ErrorHandler.getLabel(e as AxiosError));
  };

  //Redux
  const dispatch = useAppDispatch();
  const filtersReduxStore = useAppSelector(selectGlobalFilters);

  const handleDrawerClose = () => {
    setOpen(false);
    onAfterClose();
    setSelectedFilters(selectedFiltersInitial);
  };

  const theme = useTheme();

  //Station Behaviours
  const stationBehaviors = configurationUtils
    .getConfigArray({
      config:
        EphemeralStateService.getMyStationConfiguratation().GENERAL
          .BEHAVIORS,
      filter: { key: 'active', value: true },
    })
    .map((item: any) => item.id);

  //OT Modes
  const otModes = configurationUtils
    .getConfigArray({
      config:
        EphemeralStateService.getMyStationConfiguratation().OT.MODES,
      filter: { key: 'active', value: true },
    })
    .map((item: any) => item.id);

  // Hold reasons
  const holdingReasonsOptions = useMemo(
    () => configurationUtils.getHoldingReasons(),
    [],
  );

  //States Config
  const statesConfig =
    EphemeralStateService.getMyStationConfiguratation()?.GENERAL
      ?.STATES || null;

  //Available Filters
  const [availableFilters, setAvailableFilers] =
    useState<AvailableFilter[]>();

  const detectPage = (path: string) => {
    return switcher<availablePages>(path)
      .is(
        (path: string) => path.includes(AuthRoutes.DASHBOARD),
        'dashboard',
      )
      .is(
        (path: string) => path.includes(AuthRoutes.CONTAINER),
        'containers',
      )
      .is(
        (path: string) => path.includes(AuthRoutes.PACKAGE),
        'packages',
      )
      .is(
        (path: string) => path.includes(AuthRoutes.HELD_PACKAGE),
        'held_packages',
      )
      .is(
        (path: string) => path.includes(AuthRoutes.OUTBOUND_LOAD),
        'loads',
      )
      .is(
        (path: string) => path.includes(AuthRoutes.MANIFEST),
        'manifests',
      )
      .is(
        (path: string) => path.includes(AuthRoutes.INBOUND_LOAD),
        'inbound_loads',
      )
      .is(
        (path: string) => path.includes(AuthRoutes.WEBHOOKS),
        'webhooks',
      )
      .is((path: string) => path.includes(AuthRoutes.STAFF), 'staff')
      .is((path: string) => path.includes(AuthRoutes.STOP), 'stops')
      .is(
        (path: string) => path.includes(AuthRoutes.DEVICE),
        'devices',
      )
      .is(
        (path: string) =>
          path.includes(AuthRoutes.AREA) && path.includes('settings'),
        'area_settings',
      )
      .is(
        (path: string) =>
          path.includes(AuthRoutes.CONFIGURATION) &&
          path.includes('staff'),
        'configuration_staff',
      )
      .default('');
  };

  //Detect page
  const currentPathName = window.location.pathname;
  const page: availablePages = detectPage(currentPathName);

  const baseQuery = queryString.parse(window.location.search);

  //Initial state
  const [selectedFiltersInitial, setSelectedFiltersInitial] =
    useState<SelectedFilters>();
  const [selectedFilters, setSelectedFilters] =
    useState<SelectedFilters>();

  const DropdownIndicator = (props: any) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <ArrowDropDownIcon
            style={{ color: theme.palette.quaternary?.contrastText }}
          />
        </components.DropdownIndicator>
      )
    );
  };
  const fetchOptionsByQuery = async (
    baseQueryStr: string,
    fetch: (param: any) => any,
  ) => {
    if (baseQueryStr) {
      let data;
      if (baseQueryStr.includes(',')) {
        const calls: any = [];
        baseQueryStr.split(',').forEach((id) => {
          calls.push(fetch(Number(id)));
        });
        const responses = await Promise.all(calls);
        data = responses.map((el: any) => ({
          id: el.data.id,
          name:
            el.data.full_name || el.data.name || el.data.identifier,
        }));
      } else {
        data = (await fetch(Number(baseQueryStr))).data;
        data = data
          ? [
              {
                id: data.id,
                name: data.full_name || data.name || data.identifier,
              },
            ]
          : [];
      }
      return data;
    }
    return [];
  };

  const fetchFilterOptions = async () => {
    try {
      //Carrier
      const fmcData = await fetchOptionsByQuery(
        baseQuery.carrier as string,
        (id) => FmcService.getById(Number(id)),
      );

      //Owner
      const { organizationId, stationId } = getStationAndOrgId(
        page,
        EphemeralStateService,
      );
      const ownerData = await fetchOptionsByQuery(
        baseQuery.owner as string,
        (id) =>
          CustomersService.getById(
            Number(id),
            stationId,
            organizationId,
          ),
      );
      //Organization
      const organizationData = await fetchOptionsByQuery(
        baseQuery.organization as string,
        (id) => OrganizationsService.getById(Number(id)),
      );

      //Stop
      const stopData = await fetchOptionsByQuery(
        baseQuery.stop as string,
        (id) => StationsService.getLocationById(id),
      );

      //Route
      const routeData = await fetchOptionsByQuery(
        baseQuery.route as string,
        (id) => RoutesService.getById(Number(id)),
      );

      //Inbound Load
      const inboundLoadData = await fetchOptionsByQuery(
        baseQuery.inboundLoad as string,
        (id) => InboundLoadsService.getById(Number(id)),
      );

      // Areas
      const areaData = baseQuery.area
        ? (await AreasService.getById(Number(baseQuery.area))).data
        : null;

      //Package Process Steps
      const processStepsOptionsConfig = configurationUtils
        .getConfigArray({
          config: EphemeralStateService.getMyStationConfiguratation()
            ? EphemeralStateService.getMyStationConfiguratation()
                ?.GENERAL?.PROCESS_STEPS
            : undefined,
          filter: { key: 'active', value: true },
        })
        .map((step) => {
          return { id: step.id, name: step.label };
        });

      //Manifested
      const yesNoOptions = [
        { id: 'yes', name: 'Yes' },
        { id: 'no', name: 'No' },
      ];

      //Scope Options
      const scopeOptions = [
        { id: 'SYSTEM', name: 'System' },
        { id: 'CUSTOMER', name: 'Customer' },
      ];

      //Source Options
      const sourceOptions = [
        { id: 'INTERNAL', name: 'Internal' },
        { id: 'EXTERNAL', name: 'External' },
      ];

      //Device version status

      const versionStatusOptions = [
        { name: 'All', id: 'ALL' },
        { name: 'Live', id: 'LIVE' },
        { name: 'Outdated', id: 'OUTDATED' },
        { name: 'Deprecated', id: 'DEPRECATED' },
      ];

      // Package Defects
      const defectOptions = [
        { id: 'EXCEPTION_UNPROCESSABLE', name: 'Unprocessable' },
      ];
      if (stationBehaviors?.includes('MISSORTING'))
        defectOptions.push({
          id: 'EXCEPTION_MISSORT_FORCED',
          name: 'Missorted',
        });
      if (stationBehaviors?.includes('MISLOADING'))
        defectOptions.push({
          id: 'EXCEPTION_MISLOAD_FORCED',
          name: 'Misloaded',
        });

      if (otModes?.includes('RELABEL'))
        defectOptions.push({
          id: 'EXCEPTION_RELABELED',
          name: 'Relabeled',
        });

      if (otModes?.includes('RETURNING'))
        defectOptions.push({
          id: 'EXCEPTION_RETURNED',
          name: 'Returned',
        });

      // Container Defects
      const containerDefectOptions = [
        { id: 'EXCEPTION_PRINT_FORCED', name: 'Force Labeled' },
      ];
      if (stationBehaviors?.includes('MISMOVING'))
        containerDefectOptions.push({
          id: 'EXCEPTION_MISMOVE_FORCED',
          name: 'Force Moved',
        });
      if (stationBehaviors?.includes('MISLOADING'))
        containerDefectOptions.push({
          id: 'EXCEPTION_MISLOAD_FORCED',
          name: 'Force Loaded',
        });

      //Container Status
      const containerProcessStepOptions = configurationUtils
        .getContainerlStates()
        .map((state) => {
          return {
            id: state.key
              .replace('CONTAINER_', '')
              .replace('OPEN', 'OPENED'),
            name: state.label,
          };
        });

      //Container Format
      const formatOptions = [
        ...Object.keys(ContainerContainerTypeEnum).map((key) => {
          return {
            name: formatLabel(key),
            id: key,
          };
        }),
      ];

      //Container Type & Manifest Type
      const typeOptions = [
        { id: 'inbound', name: 'Inbound' },
        { id: 'outbound', name: 'Outbound' },
      ];

      //Outbound Load Status
      const loadStatusOptions = [
        { id: 'LOADING', name: statesConfig?.LOAD_LOADING?.label },
        {
          id: 'DISPATCHED',
          name: statesConfig?.LOAD_DISPATCHED?.label,
        },
      ];

      //Outbound Loads Defect
      const loadDefectOptions = [
        { id: 'EXCEPTION_DISPATCH_FORCED', name: 'Force Dispatched' },
      ];

      //Inbound Load Status

      const inboundLoadStatusOptions = [];
      for (let status of Object.keys(InboundLoadLoadStatusEnum)) {
        if (!['UNLOADING'].includes(status))
          inboundLoadStatusOptions.push({
            id: status,
            name:
              status.substring(0, 1).toUpperCase() +
              status.substring(1).toLowerCase(),
          });
      }

      //Staff roles
      const staffRoleOptions = [
        ...Object.keys(ROLE_LABELS)
          .filter((role) => role !== 'SUPERUSER' && role !== 'BLANK')
          .map((o) => {
            return {
              name: ROLE_LABELS[o as keyof typeof ROLE_LABELS],
              id: StaffPermissionRoleTypeEnum[
                o as keyof typeof StaffPermissionRoleTypeEnum
              ],
            };
          }),
      ];

      const staffStatusOptions = [
        { id: 'active', name: 'Active' },
        { id: 'inactive', name: 'Inactive' },
      ];

      const filterOptions: {
        [key: string]: AvailableFilter['options'];
      } = {
        carrier: fmcData,
        owner: ownerData,
        organization: organizationData,
        stop: stopData,
        route: routeData,
        inboundLoad: inboundLoadData,
        area: areaData
          ? [{ id: areaData.id, name: areaData.name }]
          : [],
        processStep: processStepsOptionsConfig,
        packageManifested: yesNoOptions,
        packageScanned: yesNoOptions,
        packageScannedToday: yesNoOptions,
        packageArrived: yesNoOptions,
        packageInSystem: yesNoOptions,
        packageInHold: yesNoOptions,
        packageDefect: defectOptions,
        containerDefect: containerDefectOptions,
        containerProcessStep: containerProcessStepOptions,
        containerFormat: formatOptions,
        containerType: typeOptions,
        hidePending: yesNoOptions,
        loadStatus: loadStatusOptions,
        loadDefect: loadDefectOptions,
        staffStatus: staffStatusOptions,
        manifestType: typeOptions,
        inboundLoadStatus: inboundLoadStatusOptions,
        roleType: staffRoleOptions,
        versionStatus: versionStatusOptions,
        holdingReasons: holdingReasonsOptions,
        scope: scopeOptions,
        source: sourceOptions,
      };

      //Store filter options
      dispatch(updateFiltersOptions(filterOptions));

      return filterOptions;
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  const initFilters = async () => {
    setShowProgress(true);

    const availableOptions = isEmpty(filtersReduxStore.filterOptions)
      ? await fetchFilterOptions()
      : filtersReduxStore.filterOptions;

    const availableFilters = [];
    const selectedFilters: any = {};
    try {
      if (
        page === 'containers' ||
        page === 'packages' ||
        page === 'held_packages' ||
        page === 'loads' ||
        page === 'dashboard' ||
        page === 'stops' ||
        page === 'area_settings'
      ) {
        //Carrier
        availableFilters.push({
          key: 'carrier',
          label: 'Carrier',
          options: availableOptions!.carrier,
          singleSelection: ['stops', 'area_settings'].includes(page),
          async: true,
        });
        selectedFilters.carrier = {
          key: 'carrier',
          label: 'Carrier',
          visible: ['stops', 'area_settings'].includes(page),
          timeAdded: Date.now(),
          values: baseQuery.carrier
            ? availableOptions!.carrier.filter((option: any) =>
                (baseQuery.carrier as string)!
                  .split(',')
                  .includes(option.id!.toString()),
              )
            : [],
        };
      }
      if (
        page === 'containers' ||
        page === 'packages' ||
        page === 'loads' ||
        page === 'dashboard' ||
        page === 'stops'
      ) {
        //Route
        if (configurationUtils.isModuleActive('ROUTE')) {
          availableFilters.push({
            key: 'route',
            label: configurationUtils.getPageTitle(true, 'ROUTE'),
            options: availableOptions!.route,
            singleSelection: page === 'stops', //Only allow single selection on stops page
            async: true,
          });
          selectedFilters.route = {
            key: 'route',
            label: configurationUtils.getPageTitle(true, 'ROUTE'),
            visible: page === 'stops',
            timeAdded: Date.now(),
            values: baseQuery.route
              ? availableOptions!.route.filter((option: any) =>
                  (baseQuery.route as string)!
                    .split(',')
                    .includes(option.id!.toString()),
                )
              : [],
          };
        }
      }

      if (
        page === 'containers' ||
        page === 'packages' ||
        page === 'loads' ||
        page === 'dashboard'
      ) {
        //Stop
        if (configurationUtils.isModuleActive('STOP')) {
          availableFilters.push({
            key: 'stop',
            label: configurationUtils.getPageTitle(true, 'STOP'),
            options: availableOptions!.stop,
            singleSelection: false,
            async: true,
          });
          selectedFilters.stop = {
            key: 'stop',
            label: configurationUtils.getPageTitle(true, 'STOP'),
            visible: false,
            timeAdded: Date.now(),
            values: baseQuery.stop
              ? availableOptions!.stop.filter((option: any) =>
                  (baseQuery.stop as string)!
                    .split(',')
                    .includes(option.id!.toString()),
                )
              : [],
          };
        }
      }

      if (
        page === 'packages' ||
        page === 'dashboard' ||
        page === 'containers' ||
        page === 'loads' ||
        page === 'manifests' ||
        page === 'inbound_loads' ||
        page === 'webhooks' ||
        page === 'held_packages'
      ) {
        availableFilters.push({
          key: 'owner',
          label: 'Owner',
          options: availableOptions!.owner,
          singleSelection: page === 'webhooks', //Single owner selection happens only on webhooks page
          async: true,
        });
        selectedFilters.owner = {
          key: 'owner',
          label: 'Owner',
          visible: [
            'webhooks',
            'inbound_loads',
            'manifests',
          ].includes(page),
          timeAdded: Date.now(),
          values: baseQuery.owner
            ? availableOptions!.owner.filter((option: any) =>
                (baseQuery.owner as string)!
                  .split(',')
                  .includes(option.id!.toString()),
              )
            : [],
        };
      }

      if (page === 'staff' || page === 'configuration_staff') {
        availableFilters.push({
          key: 'roleType',
          label: 'Role',
          options: availableOptions!.roleType,
          singleSelection: true,
        });
        selectedFilters.roleType = {
          key: 'roleType',
          label: 'Role',
          visible: true,
          timeAdded: Date.now(),
          values: baseQuery.roleType
            ? availableOptions!.roleType.filter((option: any) =>
                (baseQuery.roleType as string)!
                  .split(',')
                  .includes(option.id),
              )
            : [],
        };
      }
      if (page === 'staff') {
        availableFilters.push({
          key: 'staffStatus',
          label: 'Status',
          options: availableOptions!.staffStatus,
          singleSelection: true,
        });
        selectedFilters.staffStatus = {
          key: 'staffStatus',
          label: 'Staff',
          visible: true,
          timeAdded: Date.now(),
          values: baseQuery.staffStatus
            ? availableOptions!.staffStatus.filter((option: any) =>
                (baseQuery.staffStatus as string)!
                  .split(',')
                  .includes(option.id),
              )
            : [],
        };
      }
      if (page === 'webhooks') {
        //Organization
        availableFilters.push({
          key: 'organization',
          label: 'Organization',
          options: availableOptions!.organization,
          singleSelection: true,
          async: true,
        });
        selectedFilters.organization = {
          key: 'organization',
          label: 'Organization',
          visible: true,
          timeAdded: Date.now(),
          values: baseQuery.organization
            ? availableOptions!.organization.filter((option: any) =>
                (baseQuery.organization as string)!
                  .split(',')
                  .includes(option.id!.toString()),
              )
            : [],
        };
      }

      // Hide Pending
      if (
        page === 'packages' ||
        page === 'dashboard' ||
        page === 'containers' ||
        page === 'loads' ||
        page === 'inbound_loads'
      ) {
        availableFilters.push({
          key: 'hidePending',
          label: 'Hide Pending',
          options: availableOptions!.hidePending,
          singleSelection: true,
        });
        selectedFilters.hidePending = {
          key: 'hidePending',
          label: 'Hide Pending',
          visible: true,
          timeAdded: Date.now(),
          values: baseQuery.hidePending
            ? availableOptions!.hidePending.filter((option: any) =>
                (baseQuery.hidePending as string)!
                  .split(',')
                  .includes(option.id),
              )
            : [],
        };
      }

      // Status
      if (page === 'inbound_loads') {
        availableFilters.push({
          key: 'inboundLoadStatus',
          label: 'Status',
          options: availableOptions!.inboundLoadStatus,
          singleSelection: true,
        });
        selectedFilters.inboundLoadStatus = {
          key: 'inboundLoadStatus',
          label: 'Status',
          visible: true,
          timeAdded: Date.now(),
          values: baseQuery.inboundLoadStatus
            ? availableOptions!.inboundLoadStatus.filter(
                (option: any) =>
                  (baseQuery.inboundLoadStatus as string)!
                    .split(',')
                    .includes(option.id),
              )
            : [],
        };
      }

      // Version Status
      if (page === 'devices') {
        availableFilters.push({
          key: 'versionStatus',
          label: 'Version Status',
          options: availableOptions!.versionStatus,
          singleSelection: true,
        });
        selectedFilters.versionStatus = {
          key: 'versionStatus',
          label: 'Version Status',
          visible: true,
          timeAdded: Date.now(),
          values: baseQuery.versionStatus
            ? availableOptions!.versionStatus.filter((option: any) =>
                (baseQuery.versionStatus as string)!
                  .split(',')
                  .includes(option.id),
              )
            : [],
        };
      }

      // Holding Reasons
      if (page === 'held_packages') {
        // Reason
        availableFilters.push({
          key: 'holdingReasons',
          label: 'Reason',
          options: availableOptions!.holdingReasons,
          singleSelection: true,
        });
        selectedFilters.holdingReasons = {
          key: 'holdingReasons',
          label: 'Reason',
          visible: false,
          timeAdded: Date.now(),
          values: baseQuery.holdingReasons
            ? availableOptions!.holdingReasons.filter((option: any) =>
                (baseQuery.holdingReasons as string)!
                  .split(',')
                  .includes(option.id),
              )
            : [],
        };

        // Inbound Load
        if (configurationUtils.isModuleActive('INBOUND_LOAD')) {
          availableFilters.push({
            key: 'inboundLoad',
            label: configurationUtils.getPageTitle(
              true,
              'INBOUND_LOAD',
            ),
            options: availableOptions!.inboundLoad,
            singleSelection: true,
            async: true,
          });
          selectedFilters.inboundLoad = {
            key: 'inboundLoad',
            label: configurationUtils.getPageTitle(
              true,
              'INBOUND_LOAD',
            ),
            visible: false,
            timeAdded: Date.now(),
            values: baseQuery.inboundLoad
              ? availableOptions!.inboundLoad.filter((option: any) =>
                  (baseQuery.inboundLoad as string)!
                    .split(',')
                    .includes(option.id!.toString()),
                )
              : [],
          };
        }

        // Scope
        availableFilters.push({
          key: 'scope',
          label: 'Scope',
          options: availableOptions!.scope,
          singleSelection: true,
        });
        selectedFilters.scope = {
          key: 'scope',
          label: 'Scope',
          visible: false,
          timeAdded: Date.now(),
          values: baseQuery.scope
            ? availableOptions!.scope.filter((option: any) =>
                (baseQuery.scope as string)!
                  .split(',')
                  .includes(option.id),
              )
            : [],
        };

        // Source
        availableFilters.push({
          key: 'source',
          label: 'Source',
          options: availableOptions!.source,
          singleSelection: true,
        });
        selectedFilters.source = {
          key: 'source',
          label: 'Source',
          visible: true,
          timeAdded: Date.now(),
          values: baseQuery.source
            ? availableOptions!.source.filter((option: any) =>
                (baseQuery.source as string)!
                  .split(',')
                  .includes(option.id),
              )
            : [],
        };
      }

      //Packages and containers filters
      // Areas
      if (page === 'packages' || page === 'containers') {
        if (configurationUtils.isModuleActive('AREA')) {
          availableFilters.push({
            key: 'area',
            label: configurationUtils.getPageTitle(true, 'AREA'),
            options: availableOptions!.area,
            singleSelection: true,
            async: true,
          });
          selectedFilters.area = {
            key: 'area',
            label: configurationUtils.getPageTitle(true, 'AREA'),
            visible: true,
            timeAdded: Date.now(),
            values: baseQuery.area
              ? availableOptions!.area.filter((option: any) =>
                  (baseQuery.area as string)!
                    .split(',')
                    .includes(option.id!.toString()),
                )
              : [],
          };
        }
      }

      //Packages only filters

      //Process Steps
      if (page === 'packages') {
        availableFilters.push({
          key: 'processStep',
          label: 'Process Step',
          options: availableOptions!.processStep,
          singleSelection: false,
        });
        selectedFilters.processStep = {
          key: 'processStep',
          label: 'Process Step',
          visible: true,
          timeAdded: Date.now(),
          values: baseQuery.processStep
            ? availableOptions!.processStep.filter(
                (option: any) =>
                  (baseQuery.processStep as string)!
                    .split(',')
                    .includes(option.name) ||
                  (baseQuery.processStep as string)!
                    .split(',')
                    .includes(option.id),
              )
            : [],
        };

        //Manifested
        if (configurationUtils.isModuleActive('MANIFEST')) {
          availableFilters.push({
            key: 'manifested',
            label: 'Manifested',
            options: availableOptions!.packageManifested,
            singleSelection: true,
          });
          selectedFilters.manifested = {
            key: 'manifested',
            label: 'Manifested',
            visible: true,
            timeAdded: Date.now(),
            values: baseQuery.manifested
              ? availableOptions!.packageManifested.filter(
                  (option: any) =>
                    (baseQuery.manifested as string)!
                      .split(',')
                      .includes(option.id),
                )
              : [],
          };
        }

        //Scanned
        availableFilters.push({
          key: 'scanned',
          label: 'Scanned',
          options: availableOptions!.packageScanned,
          singleSelection: true,
        });
        selectedFilters.scanned = {
          key: 'scanned',
          label: 'Scanned',
          visible: true,
          timeAdded: Date.now(),
          values: baseQuery.scanned
            ? availableOptions!.packageScanned.filter((option: any) =>
                (baseQuery.scanned as string)!
                  .split(',')
                  .includes(option.id),
              )
            : [],
        };

        //Scanned Today
        availableFilters.push({
          key: 'packageScannedToday',
          label: 'Scanned Today',
          options: availableOptions!.packageScannedToday,
          singleSelection: true,
        });
        selectedFilters.packageScannedToday = {
          key: 'packageScannedToday',
          label: 'Scanned Today',
          visible: true,
          timeAdded: Date.now(),
          values: baseQuery.packageScannedToday
            ? availableOptions!.packageScannedToday.filter(
                (option: any) =>
                  (baseQuery.packageScannedToday as string)!
                    .split(',')
                    .includes(option.id),
              )
            : [],
        };

        //Arrived
        availableFilters.push({
          key: 'arrived',
          label: 'Arrived',
          options: availableOptions!.packageArrived,
          singleSelection: true,
        });
        selectedFilters.arrived = {
          key: 'arrived',
          label: 'Arrived',
          visible: true,
          timeAdded: Date.now(),
          values: baseQuery.arrived
            ? availableOptions!.packageArrived.filter((option: any) =>
                (baseQuery.arrived as string)!
                  .split(',')
                  .includes(option.id),
              )
            : [],
        };

        //In System
        availableFilters.push({
          key: 'in_system',
          label: 'In System',
          options: availableOptions!.packageInSystem,
          singleSelection: true,
        });
        selectedFilters.in_system = {
          key: 'in_system',
          label: 'In System',
          visible: true,
          timeAdded: Date.now(),
          values: baseQuery.in_system
            ? availableOptions!.packageInSystem.filter(
                (option: any) =>
                  (baseQuery.in_system as string)!
                    .split(',')
                    .includes(option.id),
              )
            : [],
        };

        // Packages defects

        availableFilters.push({
          key: 'packageDefect',
          label: 'Defects',
          options: availableOptions!.packageDefect,
          singleSelection: false,
        });
        selectedFilters.packageDefect = {
          key: 'packageDefect',
          label: 'Defects',
          visible: true,
          timeAdded: Date.now(),
          values: baseQuery.packageDefect
            ? availableOptions!.packageDefect.filter((option: any) =>
                (baseQuery.packageDefect as string)!
                  .split(',')
                  .includes(option.id),
              )
            : [],
        };

        //In Hold
        availableFilters.push({
          key: 'in_hold',
          label: 'In Hold',
          options: availableOptions!.packageInHold,
          singleSelection: true,
        });
        selectedFilters.in_hold = {
          key: 'in_hold',
          label: 'In Hold',
          visible: true,
          timeAdded: Date.now(),
          values: baseQuery.in_hold
            ? availableOptions!.packageInHold.filter((option: any) =>
                (baseQuery.in_hold as string)!
                  .split(',')
                  .includes(option.id),
              )
            : [],
        };
      }

      //Containers and manifests filters

      //Type
      if (page === 'containers' || page === 'manifests') {
        availableFilters.push({
          key: 'type',
          label: 'Type',
          options: availableOptions!.containerType,
          singleSelection: true,
        });
        selectedFilters.type = {
          key: 'type',
          label: 'Type',
          visible: true,
          timeAdded: Date.now(),
          values: baseQuery.type
            ? availableOptions!.containerType.filter((option: any) =>
                (baseQuery.type as string)!
                  .split(',')
                  .includes(option.id),
              )
            : [],
        };
      }

      //Containers only filters

      //Process Step
      if (page === 'containers') {
        availableFilters.push({
          key: 'containerProcessStep',
          label: 'Process Step',
          options: availableOptions!.containerProcessStep,
          singleSelection: false,
        });
        selectedFilters.containerProcessStep = {
          key: 'containerProcessStep',
          label: 'Process Step',
          visible: true,
          timeAdded: Date.now(),
          values: baseQuery.containerProcessStep
            ? availableOptions!.containerProcessStep.filter(
                (option: any) =>
                  (baseQuery.containerProcessStep as string)!
                    .split(',')
                    .includes(option.id) ||
                  (baseQuery.containerProcessStep as string)!
                    .split(',')
                    .includes(option.name),
              )
            : [],
        };

        //Format
        availableFilters.push({
          key: 'format',
          label: 'Format',
          options: availableOptions!.containerFormat,
          singleSelection: true,
        });
        selectedFilters.format = {
          key: 'format',
          label: 'Format',
          visible: true,
          timeAdded: Date.now(),
          values: baseQuery.format
            ? availableOptions!.containerFormat.filter(
                (option: any) =>
                  (baseQuery.format as string)!
                    .split(',')
                    .includes(option.id),
              )
            : [],
        };

        // Container defects

        availableFilters.push({
          key: 'containerDefect',
          label: 'Defects',
          options: availableOptions!.containerDefect,
          singleSelection: false,
        });
        selectedFilters.containerDefect = {
          key: 'containerDefect',
          label: 'Defects',
          visible: true,
          timeAdded: Date.now(),
          values: baseQuery.containerDefect
            ? availableOptions!.containerDefect.filter(
                (option: any) =>
                  (baseQuery.containerDefect as string)!
                    .split(',')
                    .includes(option.id),
              )
            : [],
        };
      }

      //Set available filters
      setAvailableFilers(availableFilters);

      if (page === 'dashboard') {
        const combinedFilters = {
          ...selectedFilters,
          ...filtersReduxStore.appliedFilters,
        };
        setSelectedFilters(combinedFilters);
        saveFilters(combinedFilters);
      } else {
        setSelectedFilters(selectedFilters);
        saveFilters(selectedFilters);
      }
    } catch (e) {
      handleError(e as AxiosError);
    } finally {
      setShowProgress(false);
    }
  };

  //Anchor el for the add filter menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openAddNewFilterMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const closeAddNewFilterMenu = () => {
    setAnchorEl(null);
  };

  const updateQueryParams = async (
    passedInFilters: SelectedFilters,
    resetPage: boolean = false,
  ) => {
    const currentQueryString = queryString.parse(
      window.location.search,
    );
    //Get list of potentital filters that might be included in the query string
    const availableOptions = isEmpty(filtersReduxStore.filterOptions)
      ? await fetchFilterOptions()
      : filtersReduxStore.filterOptions;

    const availableFilterKeys = Object.keys(availableOptions || {});
    const filtersQuery = {
      ...omit(currentQueryString, availableFilterKeys),
      ...createFiltersQuery(passedInFilters),
    };

    //Update URL -- preserve page, sortBy and rows_per_page
    if (baseQuery.page)
      filtersQuery.page = resetPage ? 1 : baseQuery.page;
    if (baseQuery.sortBy) filtersQuery.sortBy = baseQuery.sortBy;
    if (baseQuery.rowsPerPage)
      filtersQuery.rowsPerPage = baseQuery.rowsPerPage;
    browserHistory.replace('?' + queryString.stringify(filtersQuery));
  };

  const saveFilters = (
    passedInFilters = selectedFilters,
    resetPage: boolean = false,
  ) => {
    if (page === 'dashboard') {
      //update global state
      dispatch(updateFiltersState(passedInFilters!));
    } else {
      //logic to send back to main component
      getFilters && getFilters(passedInFilters!);
    }

    updateQueryParams(passedInFilters!, resetPage);
    setOpen(false);
    onAfterClose();
  };

  const loadOptions = (
    inputValue: string,
    callback: any,
    filterKey: string,
  ) => {
    switch (filterKey) {
      case 'route':
        loadRouteOptions(inputValue, callback);
        break;

      case 'carrier':
        loadCarrierOptions(inputValue, callback);
        break;

      case 'stop':
        loadStopOptions(inputValue, callback);
        break;

      case 'owner':
        loadCustomerOptions(inputValue, callback);
        break;

      case 'organization':
        loadOrganizationOptions(inputValue, callback);
        break;

      case 'area':
        loadAreaOptions(inputValue, callback);
        break;

      case 'inboundLoad':
        loadInboundLoadOptions(inputValue, callback);
        break;

      default:
        break;
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadOrganizationOptions = useCallback(
    debounce((inputValue: string, callback: any) => {
      const addedIds = selectedFilters?.organization.values.map(
        (value) => value?.id,
      );
      OrganizationsService.getAllSearch(inputValue)
        .then((data) => {
          callback(
            data.data.results
              .map((dataEl: Organization) => {
                return {
                  value: dataEl.id,
                  label: dataEl.name,
                };
              })
              .filter(
                (option) =>
                  !addedIds?.includes(Number(option?.value)),
              ),
          );
        })
        .catch((e) => {
          handleError(e as AxiosError);
        });
    }, 500),
    [selectedFilters],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadAreaOptions = useCallback(
    debounce((inputValue: string, callback: any) => {
      const addedIds = selectedFilters?.area.values.map(
        (value) => value?.id,
      );
      AreasService.search(inputValue)
        .then((data) => {
          callback(
            data.data.results
              .map((dataEl: Area) => {
                return {
                  value: dataEl.id,
                  label: dataEl.name,
                };
              })
              .filter(
                (option) =>
                  !addedIds?.includes(Number(option?.value)),
              ),
          );
        })
        .catch((e) => {
          handleError(e as AxiosError);
        });
    }, 500),
    [selectedFilters],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadCustomerOptions = useCallback(
    debounce((inputValue: string, callback: any) => {
      const addedIds = selectedFilters?.owner.values.map(
        (value) => value?.id,
      );
      const { organizationId, stationId } = getStationAndOrgId(
        page,
        EphemeralStateService,
      );
      CustomersService.getAll(
        undefined,
        inputValue,
        MAX_PAGE_SIZE,
        stationId,
        organizationId,
      )
        .then((data) => {
          callback(
            data.data.results
              .map((dataEl: Customer) => {
                return {
                  value: dataEl.id,
                  label: dataEl.identifier,
                };
              })
              .filter(
                (option) =>
                  !addedIds?.includes(Number(option?.value)),
              ),
          );
        })
        .catch((e) => {
          handleError(e as AxiosError);
        });
    }, 500),
    [selectedFilters],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadStopOptions = useCallback(
    debounce((inputValue: string, callback: any) => {
      const addedIds = selectedFilters?.stop.values.map(
        (value) => value?.id,
      );
      StationsService.getLocations({
        search: inputValue,
        station: EphemeralStateService.getMyStationId(),
      })
        .then((data) => {
          callback(
            data.data.results
              .map((dataEl: Location) => {
                return {
                  value: dataEl.id,
                  label: dataEl.name,
                };
              })
              .filter(
                (option) =>
                  !addedIds?.includes(Number(option?.value)),
              ),
          );
        })
        .catch((e) => {
          handleError(e as AxiosError);
        });
    }, 500),
    [selectedFilters],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadCarrierOptions = useCallback(
    debounce((inputValue: string, callback: any) => {
      const addedIds = selectedFilters?.carrier.values.map(
        (value) => value?.id,
      );
      FmcService.search(inputValue)
        .then((data) => {
          callback(
            data.data.results
              .map((dataEl: User) => {
                return {
                  value: dataEl.id,
                  label: dataEl.full_name,
                };
              })
              .filter(
                (option) =>
                  !addedIds?.includes(Number(option?.value)),
              ),
          );
        })
        .catch((e) => {
          handleError(e as AxiosError);
        });
    }, 500),
    [selectedFilters],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadRouteOptions = useCallback(
    debounce((inputValue: string, callback: any) => {
      const addedIds = selectedFilters?.route.values.map(
        (value) => value?.id,
      );
      RoutesService.getAll({ search: inputValue })
        .then((data) => {
          callback(
            data.data.results
              .map((dataEl: Route) => {
                return {
                  value: dataEl.id,
                  label: dataEl.name,
                };
              })
              .filter(
                (option) =>
                  !addedIds?.includes(Number(option?.value)),
              ),
          );
        })
        .catch((e) => {
          handleError(e as AxiosError);
        });
    }, 500),
    [selectedFilters],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadInboundLoadOptions = useCallback(
    debounce((inputValue: string, callback: any) => {
      const addedIds = selectedFilters?.inboundLoad.values.map(
        (value) => value?.id,
      );
      InboundLoadsService.getAll({ loadName: inputValue })
        .then((data) => {
          callback(
            data.data.results
              .map((dataEl: InboundLoad) => {
                return {
                  value: dataEl.id,
                  label: dataEl.name,
                };
              })
              .filter(
                (option) =>
                  !addedIds?.includes(Number(option?.value)),
              ),
          );
        })
        .catch((e) => {
          handleError(e as AxiosError);
        });
    }, 500),
    [selectedFilters],
  );

  useEffect(() => {
    setOpen(isOpen);
    isOpen && setSelectedFiltersInitial(selectedFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    const combinedFilters = {
      ...selectedFilters,
      ...filtersReduxStore.appliedFilters,
    };
    setSelectedFilters(combinedFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersReduxStore.appliedFilters]);

  useEffect(() => {
    if (removeFilter && !isEmpty(removeFilter)) {
      const updated = {
        ...selectedFilters,
        [removeFilter?.filterKey!]: {
          ...selectedFilters![removeFilter?.filterKey!],
          values: selectedFilters![
            removeFilter?.filterKey!
          ].values.filter((v) => v.id !== removeFilter?.filterValue),
        },
      };

      setSelectedFilters(updated);
      saveFilters(updated);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeFilter]);

  useEffect(() => {
    initFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {showProgress && <ProgressIndicator />}

      <Drawer
        anchor='right'
        open={open}
        onClose={handleDrawerClose}
        classes={{
          paper: classes.drawerPaper,
        }}
        BackdropProps={{ invisible: true }}
      >
        <Typography
          style={{ fontSize: '0.75em', textTransform: 'uppercase' }}
        >
          Station
        </Typography>
        <Typography variant='h5'>Filters</Typography>
        <hr
          style={{ width: 'calc(100% + 40px)', margin: '20px -20px' }}
        />
        {error && (
          <AlertBanner
            className={classes.banner}
            severity='error'
            alertTitle={'Error'}
            alertMsg={error}
          />
        )}
        {!Boolean(
          selectedFilters &&
            availableFilters?.length ===
              Object.values(selectedFilters).filter(
                (filter) => filter.visible,
              ).length,
        ) && (
          <CSButton
            data-testid='filters-drawer:add-new-filter-button'
            variant='contained'
            color='quaternary'
            className={classes.addFilterButton}
            aria-controls='dasboard-filters-menu'
            aria-haspopup='true'
            onClick={openAddNewFilterMenu}
            style={{
              float: 'right',
            }}
            aria-label='Add new filter'
          >
            <AddCircleIcon style={{ width: '24px' }} />
          </CSButton>
        )}
        <Menu
          id='dasboard-filters-menu'
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          getContentAnchorEl={undefined}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={closeAddNewFilterMenu}
        >
          {availableFilters &&
            availableFilters.map(
              (filter) =>
                selectedFilters &&
                selectedFilters[filter.key] &&
                !selectedFilters[filter.key].visible &&
                !selectedFilters[filter.key].values.length && (
                  <MenuItem
                    data-testid={`filters-drawer:add-new:${filter.label}`}
                    key={filter.key}
                    onClick={(e) => {
                      setSelectedFilters({
                        ...selectedFilters,
                        [filter.key]: {
                          ...selectedFilters[filter.key],
                          visible: true,
                          timeAdded: Date.now(),
                        },
                      });
                      closeAddNewFilterMenu();
                    }}
                  >
                    {filter.label}
                  </MenuItem>
                ),
            )}
        </Menu>

        <Box className={classes.filtersHoler}>
          {availableFilters &&
            selectedFilters &&
            availableFilters
              .sort(
                (a, b) =>
                  selectedFilters[a.key].timeAdded -
                  selectedFilters[b.key].timeAdded,
              )
              .map(
                (filter) =>
                  (selectedFilters[filter.key].visible ||
                    selectedFilters[filter.key].values.length !==
                      0) && (
                    <FormControl
                      data-testid={`filters-drawer:${filter.label}`}
                      variant='outlined'
                      className={classes.formControl}
                      key={filter.key}
                    >
                      {filter.async ? (
                        <>
                          <InputLabel
                            className={classes.selectLabel}
                            style={{ fontSize: 12, top: '-25px' }}
                          >
                            {filter.label}
                          </InputLabel>
                          <AsyncSelect<TypeAheadItem>
                            styles={{
                              ...asyncSelectStyles,
                              menuPortal: (styles: any) => {
                                return {
                                  ...styles,
                                  zIndex: 9999,
                                };
                              },
                              control: (styles: any, state: any) => {
                                return {
                                  ...styles,
                                  backgroundColor: 'transparent',
                                  border: '1px solid white',
                                  color:
                                    theme.palette.quaternary
                                      ?.contrastText,
                                  padding: '10px 0',
                                  boxShadow: 'none !important',
                                  outline: `none !important`,
                                  '&:hover': {
                                    border: `1px solid ${theme.palette.quaternary?.contrastText}`,
                                  },
                                  '&:focus': {
                                    border: `1px solid ${theme.palette.quaternary?.contrastText}`,
                                  },
                                };
                              },
                              placeholder: (styles: any) => {
                                return {
                                  ...styles,
                                  color:
                                    theme.palette.quaternary
                                      ?.contrastText,
                                };
                              },
                              loadingIndicator: (styles: any) => {
                                return {
                                  ...styles,
                                  color:
                                    theme.palette.quaternary
                                      ?.contrastText,
                                };
                              },
                              input: (styles: any) => {
                                return {
                                  ...styles,
                                  color:
                                    theme.palette.quaternary
                                      ?.contrastText,
                                };
                              },
                              singleValue: (styles: any) => {
                                return {
                                  ...styles,
                                  color:
                                    theme.palette.quaternary
                                      ?.contrastText,
                                };
                              },
                            }}
                            loadOptions={(
                              inputValue: string,
                              callback: any,
                            ) => {
                              loadOptions(
                                inputValue,
                                callback,
                                filter.key,
                              );
                            }}
                            onChange={(option) => {
                              if (
                                option &&
                                !filtersReduxStore.filterOptions[
                                  filter.key
                                ].some(
                                  (opt) => opt.id === option.value,
                                )
                              ) {
                                //update available filter options in store
                                dispatch(
                                  updateFiltersOptions({
                                    ...filtersReduxStore.filterOptions,
                                    [filter.key]: [
                                      ...filtersReduxStore
                                        .filterOptions[filter.key],
                                      {
                                        id: (option as TypeAheadItem)
                                          .value,
                                        name: (
                                          option as TypeAheadItem
                                        ).label,
                                      },
                                    ],
                                  }),
                                );
                              }

                              //Does filter allow only 1 selection
                              const singleSelection =
                                availableFilters.find(
                                  (f) => f.key === filter.key,
                                )?.singleSelection;
                              const value = option as TypeAheadItem;
                              setSelectedFilters({
                                ...selectedFilters,
                                [filter.key]: {
                                  ...selectedFilters[filter.key],
                                  values: singleSelection
                                    ? [
                                        {
                                          id: isNaN(
                                            Number(value?.value),
                                          )
                                            ? value.value
                                            : Number(value?.value),
                                          name: value.label,
                                        },
                                      ]
                                    : selectedFilters[
                                        filter.key
                                      ].values.concat({
                                        id: value?.value,
                                        name: value?.label,
                                      }),
                                },
                              });
                            }}
                            placeholder={'Start Typing...'}
                            value={{
                              value: '0',
                              label: 'Start Typing...',
                            }}
                            menuPortalTarget={document.body}
                            components={{ DropdownIndicator }}
                            noOptionsMessage={noOptionsMessage}
                          />
                        </>
                      ) : (
                        <>
                          <InputLabel className={classes.selectLabel}>
                            {filter.label}
                          </InputLabel>
                          <Select
                            value={0}
                            onChange={(e) => {
                              //Cancel if 'Please select' is selected
                              if (e.target.value === '0') return;

                              //Does filter allow only 1 selection
                              const singleSelection =
                                availableFilters.find(
                                  (f) => f.key === filter.key,
                                )?.singleSelection;
                              //Parse id and value
                              const value = (e.target
                                .value as string)!.split('___');
                              setSelectedFilters({
                                ...selectedFilters,
                                [filter.key]: {
                                  ...selectedFilters[filter.key],
                                  values: singleSelection
                                    ? [
                                        {
                                          id: isNaN(Number(value[0]))
                                            ? value[0]
                                            : Number(value[0]),
                                          name: value[1],
                                        },
                                      ]
                                    : selectedFilters[
                                        filter.key
                                      ].values.concat({
                                        id: value[0],
                                        name: value[1],
                                      }),
                                },
                              });
                            }}
                            label={filter.label}
                            className={classes.formSelect}
                            classes={{ icon: classes.selectArrow }}
                          >
                            <MenuItem value='0'>
                              Please select...
                            </MenuItem>

                            {filter.options
                              .filter((item: any) => {
                                return !selectedFilters[
                                  filter.key
                                ].values
                                  .map((value) => value.id.toString())
                                  .includes(item.id!.toString());
                              })
                              .map((item: any) => {
                                const name =
                                  'name' in item
                                    ? item.name
                                    : 'full_name' in item
                                    ? item.full_name
                                    : '';
                                return (
                                  <MenuItem
                                    data-testid={`filters-drawer:${filter.label}:${name}`}
                                    key={item.id}
                                    value={item.id + '___' + name}
                                  >
                                    {name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </>
                      )}

                      <Box className={classes.filterBadgesHolder}>
                        {selectedFilters[filter.key].values.map(
                          (value) => (
                            <Box
                              data-testid={`filters-drawer:${
                                filter.key
                              }:${
                                value.name || value.full_name
                              }:remove`}
                              key={value.id}
                            >
                              <CSButton
                                key={value.id}
                                variant='outlined'
                                color='primary'
                                size='small'
                                onClick={() => {
                                  setSelectedFilters({
                                    ...selectedFilters,
                                    [filter.key]: {
                                      ...selectedFilters[filter.key],
                                      values: selectedFilters[
                                        filter.key
                                      ].values.filter(
                                        (v) => v.id !== value.id,
                                      ),
                                    },
                                  });
                                }}
                                endIcon={<Cancel />}
                              >
                                {value.name || value.full_name}
                              </CSButton>
                            </Box>
                          ),
                        )}
                      </Box>
                    </FormControl>
                  ),
              )}
        </Box>
        <Box className={classes.filtersButtons}>
          <CSButton
            variant='outlined'
            color='error'
            disabled={false}
            onClick={(e) => {
              handleDrawerClose();
            }}
          >
            Cancel
          </CSButton>
          <CSButton
            data-testid='filters-drawer:save-filters'
            variant='contained'
            color='primary'
            className={clx(classes.saveButton)}
            disabled={!selectedFilters}
            onClick={(e) => {
              saveFilters(selectedFilters, true);
            }}
          >
            Save
          </CSButton>
        </Box>
      </Drawer>
    </>
  );
};

export default withStyles(
  createStyles((theme: Theme) => ({
    ...detailsPageStyle,
    ...filterBadgeStyle,
    drawerPaper: {
      backgroundColor: theme.palette.quaternary?.main,
      padding: '20px',
      maxWidth: '100%',
      width: '320px',
      color: theme.palette.quaternary?.contrastText,
    },
    addFilterButton: {
      textTransform: 'none',
      marginLeft: 'auto',
      marginBottom: '20px',
    },
    filtersHoler: {
      overflowY: 'auto',
      paddingTop: '5px',
      paddingRight: '10px',
      marginBottom: '100px',
    },
    filtersButtons: {
      position: 'fixed',
      bottom: 0,
      right: 0,
      padding: '20px',
      backgroundColor: theme.palette.quaternary?.main,
      textAlign: 'right',
      width: '300px;',
    },
    saveButton: {
      marginLeft: '8px',
    },
    formControl: {
      width: '100%',
      borderColor: theme.palette.quaternary?.contrastText,
      marginBottom: '20px',
    },
    formSelect: {
      color: theme.palette.quaternary?.contrastText,
      border: `1px solid ${theme.palette.quaternary?.contrastText}`,
    },
    selectLabel: {
      color: `${theme.palette.quaternary?.contrastText}!important`,
      background: theme.palette.quaternary?.main,
      padding: '0 5px',
    },
    selectArrow: {
      color: theme.palette.quaternary?.main,
    },
  })),
)(FiltersDrawer);
