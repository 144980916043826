import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { CSButton } from '../primitives';
import { Typography } from '@material-ui/core';
import useControlled from '../../utils/useControlled';

export const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children?: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

interface Props {
  classes: { [key: string]: string };
  title?: string;
  msg?: string | React.ReactNode;
  primaryActionLabel?: string;
  onPrimaryAction?: () => void;
  onCancel?: () => void;
  isOpen: boolean;
  dataTestIdPrefix?: string;
}

const AlertDialog: React.FC<Props> = ({
  classes,
  title,
  msg,
  primaryActionLabel = 'OK',
  isOpen,
  onCancel,
  onPrimaryAction,
  dataTestIdPrefix = '',
  ...props
}) => {
  const [open, setOpen] = useControlled<boolean>({
    controlled: isOpen,
    default: false,
    name: 'Alert Dialog',
  });

  const handleCancel = () => {
    setOpen(false);
    if (onCancel) {
      onCancel();
    }
  };

  const handlePrimaryAction = () => {
    setOpen(false);
    if (onPrimaryAction) {
      onPrimaryAction();
    }
  };

  return (
    <Dialog
      data-testid={`${dataTestIdPrefix}-alert-dialog`}
      open={Boolean(open)}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        handleCancel();
        setOpen(false);
      }}
      className={classes.dialog}
      {...props}
    >
      <DialogTitle>
        <Typography component='p' variant='h5'>
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.msg}>
          {msg}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <CSButton
          color='secondary'
          variant='contained'
          onClick={handlePrimaryAction}
          data-testid={`${dataTestIdPrefix}confirmation-dialog-ok`}
        >
          {primaryActionLabel}
        </CSButton>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(
  createStyles(() => ({
    msg: {
      whiteSpace: 'pre-line',
    },
    dialog: {
      zIndex: 2000,
    },
  })),
)(AlertDialog);
