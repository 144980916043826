import { createStyles, Theme } from '@material-ui/core/styles';

export default createStyles((theme: Theme) => ({
  holder: {
    width: '100%',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
  },
  table: {
    width: '100%',
    tableLayout: 'fixed',
    borderSpacing: 0,
    '& tbody td': {
      background: theme.palette.background.paper,
      borderBottom: '1px solid rgba(224, 224, 224, 1)',
      height: 50,
    },
    '& tbody tr:last-child td:last-child': {
      borderBottomRightRadius: theme.shape.borderRadius,
    },
    '& tbody tr:last-child td:first-child': {
      borderBottomLeftRadius: theme.shape.borderRadius,
    },
  },
  dragHandle: {
    paddingLeft: 20,
  },
  dragHandleHeading: { paddingLeft: 20, width: 60 },
  actionsHeading: { width: 60 },
  toolBar: {
    backgroundColor: theme.palette.background.paper,
    minHeight: 56,
    justifyContent: 'space-between',
    borderTopRightRadius: theme.shape.borderRadius,
    borderTopLeftRadius: theme.shape.borderRadius,
  },
  tableHeadRow: {
    '& th': {
      backgroundColor: theme.palette.grey.A100,
      color: theme.palette.text.icon,
      textAlign: 'left',
      verticalAlign: 'middle',
      borderBottom: `1px solid ${theme.palette.grey.A100}`,
      height: 50,
      ...theme.typography.table?.title,
    },
    '& span': {
      opacity: 0.2,
      display: 'none',
    },
  },
  mobileheaderHeight: {
    width: 48,
  },
  standardHeader: {
    width: 'auto',
  },
  editHeaderMobile: {
    width: 250,
  },
  reorderHeaderMobile: {
    width: 60,
  },
  tableCellMobile: {
    padding: 0,
  },
  tableExpander: {
    backgroundColor: theme.palette.background.paper,
  },
  dndIcon: {
    color: theme.palette.quaternary?.main,
  },
  deleteButton: {
    minWidth: 'auto',
  },
  deleteIcon: {
    width: 15,
    height: 15,
  },
}));
