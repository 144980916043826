import { DataAnalyticsApi } from 'cloudsort-client';
import axios from '../utils/axios';
import EphemeralStateService from './EphemeralState.service';

class DataAnalyticService {
  private api: DataAnalyticsApi;

  constructor() {
    this.api = new DataAnalyticsApi(
      undefined,
      undefined,
      axios as any,
    );
  }

  public async getPackageCounts(
    dateTime: string = new Date().toISOString(),
    cutOffEventTypes?: Array<
      | 'MANIFESTED_INBOUND'
      | 'ARRIVED'
      | 'SCANNED'
      | 'ZONE'
      | 'CONTAINERIZED'
      | 'TMP_CONTAINERIZED'
      | 'MOVED'
      | 'PRIMARY'
      | 'SECONDARY'
      | 'STAGED'
      | 'LOADED'
      | 'OPENED'
      | 'CLOSED'
      | 'DISPATCHED'
      | 'MANIFESTED_OUTBOUND'
      | 'EXCEPTION_MISSORT'
      | 'EXCEPTION_MISSORT_FORCED'
      | 'EXCEPTION_MISLOAD'
      | 'EXCEPTION_MISLOAD_FORCED'
      | 'EXCEPTION_MISMOVE'
      | 'EXCEPTION_MISMOVE_FORCED'
      | 'EXCEPTION_PRINT_FORCED'
      | 'EXCEPTION_DISPATCH_FORCED'
      | 'EXCEPTION_UNIDENTIFIED'
      | 'EXCEPTION_UNPROCESSABLE'
      | 'EXCEPTION_TROUBLESHOOT'
      | 'EXCEPTION_RELABELED'
      | 'EXCEPTION_RETURNED'
      | 'CARRIER_ARRIVED_AT_FACILITY'
      | 'CARRIER_OUT_FOR_DELIVERY'
      | 'CARRIER_DELIVERED'
      | 'CARRIER_DELIVERY_FAILED'
      | 'CARRIER_IN_TRANSIT'
    >,
  ) {
    return this.api.dataAnalyticsPackageCountsList({
      dateTime,
      cutOffEventTypes,
      stationId: EphemeralStateService.getMyStationId(),
    });
  }

  public async getPackageFlow(
    dateTime: string = new Date().toISOString(),
    duration?: number,
  ) {
    return this.api.dataAnalyticsPackageFlowList({
      dateTime,
      duration,
      stationId: EphemeralStateService.getMyStationId(),
    });
  }

  public async getDispatchTimes() {
    return this.api.dataAnalyticsStationLiveViewDispatchTimeList({
      station: EphemeralStateService.getMyStationId(),
    });
  }

  public async getPackageCountsForEventTypes(
    dateTime: string = new Date().toISOString(),
    duration?: number,
    eventTypes?: Array<
      | 'MANIFESTED_INBOUND'
      | 'ARRIVED'
      | 'SCANNED'
      | 'ZONE'
      | 'CONTAINERIZED'
      | 'TMP_CONTAINERIZED'
      | 'MOVED'
      | 'PRIMARY'
      | 'SECONDARY'
      | 'STAGED'
      | 'LOADED'
      | 'OPENED'
      | 'CLOSED'
      | 'DISPATCHED'
      | 'MANIFESTED_OUTBOUND'
      | 'EXCEPTION_MISSORT'
      | 'EXCEPTION_MISSORT_FORCED'
      | 'EXCEPTION_MISLOAD'
      | 'EXCEPTION_MISLOAD_FORCED'
      | 'EXCEPTION_MISMOVE'
      | 'EXCEPTION_MISMOVE_FORCED'
      | 'EXCEPTION_PRINT_FORCED'
      | 'EXCEPTION_DISPATCH_FORCED'
      | 'EXCEPTION_UNIDENTIFIED'
      | 'EXCEPTION_UNPROCESSABLE'
      | 'EXCEPTION_TROUBLESHOOT'
      | 'EXCEPTION_RELABELED'
      | 'EXCEPTION_RETURNED'
      | 'CARRIER_ARRIVED_AT_FACILITY'
      | 'CARRIER_OUT_FOR_DELIVERY'
      | 'CARRIER_DELIVERED'
      | 'CARRIER_DELIVERY_FAILED'
      | 'CARRIER_IN_TRANSIT'
    >,
  ) {
    return this.api.dataAnalyticsPackageCountsForEventTypesList({
      dateTime,
      duration,
      eventTypes,
      stationId: EphemeralStateService.getMyStationId(),
    });
  }
}

export default new DataAnalyticService();
